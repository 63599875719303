import { ButtonGroupProps, HStack, StackDivider, StackProps } from '@chakra-ui/react'
import React from 'react'

type SegmentedControlProps = StackProps & { size?: ButtonGroupProps['size'] }

export function SegmentedControl({ size = 'md', ...props }: React.PropsWithChildren<SegmentedControlProps>) {
  const children = React.Children.toArray(props.children).map((child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { size, variant: 'segmentedControl' } as any)
    }

    return child
  })

  return (
    <HStack
      flex="none"
      role="group"
      alignSelf="flex-start"
      alignItems="center"
      spacing="0"
      padding="3px"
      bg="gray.100"
      rounded="lg"
      divider={
        <StackDivider
          alignSelf="stretch"
          marginTop="5px !important"
          marginBottom="5px !important"
          borderColor="gray.200"
        />
      }
      {...props}
    >
      {children}
    </HStack>
  )
}
