import { Center, Heading, Skeleton, Stack } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React, { useMemo } from 'react'
import { useRepOnboarding } from '../../data/use-rep-onboarding'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import useLocation from '../../ui/useLocation'
import { Step } from '../welcome/components/SetupProject'
import { RepOnboarding } from '../welcome/rep_onboarding'
import Mine, { MyAccountsProps } from './mine'

export default function Assigned(props: MyAccountsProps) {
  const loc = useLocation()
  const params = React.useMemo(() => new URLSearchParams(loc.search), [loc.search])

  const showOnboarding = useMemo(() => {
    return props.could_onboard || params.get('step') || params.get('onboarding')
  }, [props, params])

  if (showOnboarding) {
    return <InlineOnboarding />
  }

  return <Mine {...props} />
}

function InlineOnboarding() {
  const onboarding = useRepOnboarding()

  return (
    <PageLayout
      size="full"
      bg="gray.100"
      css={`
        --max-container-width: 100vw;
      `}
    >
      <PageTitle skipRendering>My Accounts</PageTitle>
      <Stack spacing="12">
        <Center p="4">
          <Heading fontWeight={'semibold'} size="sm">
            👋🏻 Let's get your territory set up...
          </Heading>
        </Center>
        <Center w="100%" h="100%">
          <motion.div
            layout
            transition={{
              layout: { duration: 0.15, ease: 'easeInOut' }
            }}
          >
            {onboarding.isLoading && (
              <>
                <Step>
                  <Stack p="8" spacing="4" w="800">
                    <Skeleton h="12" />
                    <Skeleton h="12" />
                    <Skeleton h="12" />
                    <Skeleton h="12" />
                    <Skeleton h="12" />
                  </Stack>
                </Step>
              </>
            )}
            {onboarding.data && <RepOnboarding {...onboarding.data} />}
          </motion.div>
        </Center>
      </Stack>
    </PageLayout>
  )
}
