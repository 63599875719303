import { Button, Flex } from '@chakra-ui/react'
import { IconClockPlay } from '@tabler/icons-react'
import omit from 'lodash/omit'
import ms from 'ms'
import pluralize from 'pluralize'
import React from 'react'
import { Facet } from '../..'
import { getFacetOperator, getFacetOperatorLabel, getFacetValues } from '../../../../data/use-facets'
import { getItemDisplay } from '../../facets/categories'
import { getRangeValues, periodShorthands, quickRanges } from '../../facets/filter-cloud'
import FilterPopover from '../../facets/filter-popover'
import FilterTag from '../FilterTag'
import { FilterPreviewProps } from './types'
import dayjs from '../../../../../lib/dayjs'

const MAX_FILTER_VALUES_TO_SHOW = 2

type FormattedFilterValue = {
  label: string
  overflow: Array<string | number | boolean> | null
}

export function formatFilterValues(value: Facet | string): FormattedFilterValue {
  let values: Array<string | number | boolean> = []

  if (typeof value === 'string') {
    values = [value]
  } else {
    // Grab nested values from `not`, `gte`, `lte`, `all`. For example:
    // {
    //   "not": ['a', 'b']
    // }
    value = getFacetValues(value)

    if (typeof value === 'string') {
      values = [value]
    }

    if (Array.isArray(value)) {
      values = value.map((val) => {
        if (Array.isArray(val)) {
          return val.join('..')
        }

        return val
      })
    }
  }

  const isOverflowing = values.length > MAX_FILTER_VALUES_TO_SHOW

  const label =
    values.length === MAX_FILTER_VALUES_TO_SHOW
      ? values.join(' or ')
      : values.slice(0, MAX_FILTER_VALUES_TO_SHOW).join(', ') +
        (values.length > MAX_FILTER_VALUES_TO_SHOW
          ? `, or ${values.length - MAX_FILTER_VALUES_TO_SHOW} ${pluralize('other', values.length - 2)}`
          : '')

  return {
    label,
    overflow: isOverflowing ? values.slice(MAX_FILTER_VALUES_TO_SHOW, values.length) : null
  }
}

export const FilterPreview = (props: FilterPreviewProps) => {
  const {
    apps = {},
    facetFilters,
    onFilterChange,
    setFacetFilters,
    toggleFilterOperator,
    range,
    focusTime,
    setRange,
    applyFilters,
    facetMappings,
    topFilters,
    setPage,
    setFocusTime,
    isEditable = true,
    formatLabel,
    usePortal = true,
    shouldShowSourceFilter,
    shouldShowLastSeenFilter = true
  } = props

  let colorScheme = props.colorScheme
  if (!colorScheme) {
    colorScheme = props.kind === 'profile' ? 'blue' : 'purple'
  }

  return (
    <Flex direction="column" width="100%" gap={6} alignItems="flex-start">
      <Flex direction="row" alignItems="center" gap={2.5} flexWrap="wrap" maxWidth="100%">
        {isEditable && (
          <>
            <FilterPopover
              apps={apps}
              setRange={setRange}
              setPage={setPage}
              setFocusTime={setFocusTime}
              range={range}
              focusTime={focusTime}
              applyFilters={applyFilters}
              facetMappings={facetMappings}
              topFilters={topFilters}
              facetFilters={facetFilters}
              onFilterChange={onFilterChange}
              facetValuesPath={props.facetValuesPath}
              shouldShowCompanyFilters={props.shouldShowCompanyFilters}
              shouldShowUserAttributeFilters={props.shouldShowUserAttributeFilters}
              shouldShowActiveVisitorsFilters={props.shouldShowActiveVisitorsFilters}
              shouldShowIntentFilters={props.shouldShowIntentFilters}
              shouldShow3rdPartyFilters={props.shouldShow3rdPartyFilters}
              shouldShowStaticListFilters={props.shouldShowStaticListFilters}
              shouldShowListFilters={props.shouldShowListFilters}
              shouldShowICPFilters={props.shouldShowICPFilters}
              shouldAllowFreeEntry={props.shouldAllowFreeEntry}
              shouldShowTraits={props.shouldShowTraits}
              formatLabel={formatLabel}
              allowedKeys={props.allowedKeys}
              excludedKeys={props.excludedKeys}
              colorScheme={colorScheme}
              kind={props.kind}
              facetCloudLoading={props.facetCloudLoading}
              toggleFilterOperator={props.toggleFilterOperator}
              usePortal={usePortal}
              hideFacetCounts={props.hideFacetCounts}
            >
              {props.children}
            </FilterPopover>
          </>
        )}
        {shouldShowSourceFilter && (
          <FilterTag
            facet="sources"
            label={formatLabel ? formatLabel(getItemDisplay('sources', Object.values(apps), props.kind)) : 'Source'}
            icon={getItemDisplay('sources', Object.values(apps), props.kind)?.icon}
            formatter={(values) => formatFilterValues(values).label}
            operator={
              getFacetValues(facetFilters['sources'])?.length
                ? getFacetOperatorLabel(facetFilters['sources'], facetMappings['sources']?.type)
                : undefined
            }
            value={facetFilters['sources']}
            isEditable={isEditable}
            shouldAllowFreeEntry={false}
            {...props}
          />
        )}
        {range && range !== 'any' && shouldShowLastSeenFilter && (
          <FilterTag
            label="Last Seen"
            operator="in"
            facet="range"
            isEditable={isEditable}
            value={quickRanges[range]}
            onRemove={() => {
              setRange(null)
            }}
            {...props}
          />
        )}
        {focusTime && (
          <FilterTag
            label="Active Session Time"
            icon={IconClockPlay}
            operator={getFacetOperatorLabel(focusTime)}
            formatter={(value) => {
              const range = getRangeValues(value)

              if (range.eq) {
                return ms(range.eq, { long: true })
              } else if (range.gte && range.lte) {
                return [ms(range.gte), ms(range.lte)].join('-')
              } else if (range.gte) {
                return ms(range.gte, { long: true })
              } else if (range.lte) {
                return ms(range.lte, { long: true })
              } else {
                return ''
              }
            }}
            facet="focus_time"
            isEditable={isEditable}
            value={focusTime}
            onRemove={() => {
              setFocusTime(null)
            }}
            {...props}
          />
        )}
        {Object.keys(facetFilters).map((filter) => {
          const values = facetFilters[filter]

          if (Array.isArray(values) && values.length === 0) {
            return null
          }

          if (shouldShowSourceFilter && filter === 'sources') {
            return null
          }

          const mapping = facetMappings[filter]
          const dataType = mapping?.type
          const { overflow } = formatFilterValues(values)
          const operator = getFacetOperator(values)
          const operatorLabel = getFacetOperatorLabel(values, dataType)
          const display = getItemDisplay(filter, Object.values(apps), props.kind, mapping)
          let displayValues = values

          if (dataType !== 'date' && (operator == 'exists' || operator == 'not_exists')) {
            displayValues = ['empty']
          }

          return (
            <FilterTag
              key={filter}
              overflow={overflow}
              facet={filter}
              label={formatLabel ? formatLabel(display) : display.label}
              icon={display.icon}
              formatter={
                dataType === 'date'
                  ? (values) => {
                      const vals = getFacetValues(values)
                      const val = Array.isArray(vals) ? vals[0] : vals
                      let formatted = periodShorthands[val]

                      if (!formatted) {
                        if (operator === 'exists') {
                          formatted = val === 'true' ? 'anytime' : 'not set'
                        } else if (operator === 'not_exists') {
                          formatted = 'not set'
                        } else {
                          formatted = String(val)

                          const [first, last] = formatted.split('..')
                          if (first && last) {
                            formatted = [first, last].map((d) => dayjs(d).format('MMM D, YYYY')).join(' - ')
                          }
                        }
                      }
                      return formatted
                    }
                  : (values) => formatFilterValues(values).label
              }
              operator={operatorLabel}
              value={displayValues}
              onEditOperator={
                toggleFilterOperator && [null, 'not'].includes(operator)
                  ? (operator) => {
                      toggleFilterOperator(filter, values, operator)
                    }
                  : undefined
              }
              isEditable={isEditable}
              onRemove={() => {
                setFacetFilters((filters) => omit(filters, filter))
              }}
              {...props}
            />
          )
        })}

        {props.canClearFilters && isEditable && (
          <Button size="sm" variant="link" onClick={props.clearFilters}>
            Reset Filters
          </Button>
        )}
      </Flex>
    </Flex>
  )
}
