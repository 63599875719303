import { Stack } from '@chakra-ui/react'
import React from 'react'
import { PrivateAPIKey } from '../../../types/PrivateAPIKey'
import PageLayout from '../../ui/PageLayout'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import PageDescription from '../../ui/PageDescription'
import PageTitle from '../../ui/PageTitle'
import { PrivateAPIKeys } from './components/private-keys'
import { PublicAPIKeys } from '../sdk_settings/components/public-keys'
import { PublicAPIKey } from '../../../types/PublicAPIKey'
import { Card } from '../../ui/Card'

interface Props {
  public_api_keys: PublicAPIKey[]
  api_keys: PrivateAPIKey[]
}

export default function Show(props: Props) {
  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        paths={[
          {
            path: projectPath('/settings/api-keys'),
            title: 'API Keys'
          }
        ]}
        offscreen
      />

      <SettingsHeader>
        <PageTitle>API Keys</PageTitle>
        <PageDescription>Configure public and private API keys for your workspace.</PageDescription>
      </SettingsHeader>

      <Stack spacing={8}>
        <Card>
          <PublicAPIKeys keys={props.public_api_keys} />
        </Card>
        <Card>
          <PrivateAPIKeys keys={props.api_keys} />
        </Card>
      </Stack>
    </PageLayout>
  )
}
