const pluralRules = new Intl.PluralRules('en-US')

export function pluralize(count, singular, plural, includeCount = true) {
  const grammaticalNumber = pluralRules.select(count)
  const parts = includeCount ? [count] : []

  if (grammaticalNumber === 'one') {
    parts.push(singular)
  } else {
    parts.push(plural)
  }

  return parts.join(' ')
}
