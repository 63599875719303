import PageTitle from '@app/components/ui/PageTitle'
import { Text } from '@chakra-ui/react'
import React from 'react'
import PageLayout from '../../ui/PageLayout'
import { AdminBreadcrumb } from '../admin'

interface Props {
  deletion_request_id: string
  deleted_by: string
  deleted_at: string
  deletion_status: string
  deletion_messages?: string
  deleted_receipt: { [key: string]: any }
}

export default function Show(props: Props) {
  return (
    <PageLayout size="sm">
      <AdminBreadcrumb
        paths={[
          { path: '/admin/subject-data-deletion-request', title: 'Deletion Requests' },
          { path: window.location.pathname, title: props.deletion_request_id }
        ]}
      />

      <PageTitle size="lg">Subject Data Deletion Request</PageTitle>
      <Text>
        Deleted by {props.deleted_by} on {props.deleted_at}
      </Text>
      <Text>Status: {props.deletion_status}</Text>
      {props.deletion_status == 'failed' && <Text>{props.deletion_messages}</Text>}
      <pre>{JSON.stringify(props.deleted_receipt, null, 2)}</pre>
    </PageLayout>
  )
}
