import {
  Box,
  Button,
  Circle,
  Divider,
  Flex,
  HStack,
  Icon,
  Link,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useTheme
} from '@chakra-ui/react'
import { IconArrowRight, IconCircle, IconPlus } from '@tabler/icons-react'
import React from 'react'
import type { PageMeta } from '../../../types/PageMeta'
import CompanyAvatar from '../../ui/CompanyAvatar'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import router from '../../../lib/router'
import { mergeParams } from '../icps/types'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import { TableFooter } from '../../ui/TableFooter'
import { TimeAgo } from '../../ui/TimeAgo'
import { Trendline } from '../icps/icp/breakdown'
import { profilePath } from '../profiles/lib/path'
import { OptionsMenu } from './components/DefinitionForm'
import { IntentSignalEmptyState } from './components/EmptyState'
import { SignalType } from './components/SignalType'
import { SuggestedSignals } from './components/SuggestedSignals'
import { kqlDefinitionPath } from './path-helpers'
import { SearchBar } from '../accounts/facets/search-bar'
import { SortableHeader } from '../../ui/Table'
import type { KqlDefinition } from './types'
import { Apps } from '../../../types/App'

interface EnhancedDefinition extends KqlDefinition {
  total_30_days: number
  timeseries?: Record<string, number>
  latest_kql_at?: string
  latest_signal?: {
    id: string
    kql_definition_id: string
    triggered_at: string
    profile?: {
      id: string
      name?: string
      email?: string
      display_name?: string
      anonymous_name?: string
    }
    company?: {
      id: string
      name?: string
      domain: string
      logo?: string
    }
  }
}

interface Props {
  search_query: string
  sort_by: string
  definitions: EnhancedDefinition[]
  page_meta: PageMeta
  apps?: Apps
}

export default function Index(props: Props) {
  const doSearch = (term) => {
    router.visit(mergeParams(window.location.href, { search_query: term, page: '1' }))
  }

  const doSort = (sortBy) => {
    router.visit(mergeParams(window.location.href, { sort_by: sortBy, page: '1' }))
  }

  const paginationUrl = (page) => {
    return mergeParams(window.location.href, { page: page })
  }

  return (
    <PageLayout size="full">
      <SettingsBreadCrumb offscreen rootPath={{ title: 'Intent Signals', path: projectPath('/signals') }} />

      <Stack spacing={8}>
        <HStack justifyContent="space-between">
          <Box>
            <PageTitle>Intent Signals</PageTitle>
            <PageDescription>Create and manage the signals that are most relevant to your sales team.</PageDescription>
          </Box>
          {props.definitions.length > 0 && (
            <Button
              flex="none"
              size="sm"
              colorScheme="purple"
              as={Link}
              href={projectPath('/signals/new')}
              leftIcon={<IconPlus size={14} />}
            >
              New Intent Signal
            </Button>
          )}
        </HStack>

        <HStack w="100%">
          <SearchBar
            size="sm"
            value={props.search_query}
            onChange={doSearch}
            debounce={500}
            placeholder="Search by name"
          />
        </HStack>

        <Stack width="100%" spacing={6}>
          {props.definitions.length > 0 ? (
            <>
              <DefinitionsList definitions={props.definitions} currentSort={props.sort_by} onSortChange={doSort} />
              <TableFooter
                pageMeta={props.page_meta}
                page={props.page_meta.current_page}
                prevPath={paginationUrl(props.page_meta.prev_page)}
                nextPath={paginationUrl(props.page_meta.next_page)}
              />
            </>
          ) : props.search_query.length > 0 ? (
            <>
              <p>No results</p>
            </>
          ) : (
            <>
              <IntentSignalEmptyState />
              <Divider />
              <SuggestedSignals apps={props.apps || {}} />
            </>
          )}
        </Stack>
      </Stack>
    </PageLayout>
  )
}

function DefinitionsList(props: {
  definitions: EnhancedDefinition[]
  currentSort?: string
  onSortChange?: (string) => void
}) {
  return (
    <TableContainer>
      <Table size="sm">
        <Thead>
          <Tr>
            <SortableHeader
              sortBy="name"
              columnKey="name"
              currentSort={props.currentSort}
              onSortChange={props.onSortChange}
            >
              Name
            </SortableHeader>
            <Th width="1px">Status</Th>
            <SortableHeader
              colSpan={2}
              sortBy="total_30_days"
              columnKey="total_30_days"
              currentSort={props.currentSort}
              onSortChange={props.onSortChange}
            >
              Recent signals (30d)
            </SortableHeader>
            <Th pl="8">Last Signal</Th>
            <SortableHeader
              sortBy="latest_kql_at"
              columnKey="latest_kql_at"
              currentSort={props.currentSort}
              onSortChange={props.onSortChange}
            >
              Time
            </SortableHeader>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {props.definitions.map((definition) => {
            return (
              <Tr key={definition.id} _hover={{ bg: 'gray.50' }}>
                <Td py="4" filter={definition.enabled ? undefined : 'grayscale(80%)'}>
                  <Link href={kqlDefinitionPath(definition)}>
                    <SignalType
                      signalType={definition.signal_type}
                      label={definition.name}
                      description={
                        <HStack spacing={1}>
                          <Text>
                            {definition.updated_at == definition.created_at ? 'Created' : 'Updated'}{' '}
                            <TimeAgo canToggle={false} time={definition.updated_at} />
                          </Text>
                          {definition.updated_by_user ? (
                            <Text>by {definition.updated_by_user.name || definition.updated_by_user.email}</Text>
                          ) : definition.created_by_user ? (
                            <Text>by {definition.created_by_user.name || definition.created_by_user.email}</Text>
                          ) : null}
                        </HStack>
                      }
                    />
                  </Link>
                </Td>
                <Td width="1px">
                  <DefinitionStatus enabled={definition.enabled} />
                </Td>
                <Td>
                  <Trendline
                    color="purple"
                    range={'month'}
                    trend={{
                      trends: Object.entries(definition.timeseries ?? {}).map(([day, value]) => ({
                        day,
                        value
                      }))
                    }}
                    width={160}
                    svgWidth={160}
                    height={24}
                    svgHeight={24}
                  />
                </Td>
                <Td isNumeric>
                  {definition.total_30_days ? (
                    <Text fontSize="sm" css={{ fontVariantNumeric: 'tabular-nums' }}>
                      {definition.total_30_days?.toLocaleString()}
                    </Text>
                  ) : (
                    <Text fontSize="sm" color="gray.400">
                      &ndash;
                    </Text>
                  )}
                </Td>
                <Td pl="8" maxW="200px" isTruncated>
                  {definition.latest_signal && (
                    <HStack
                      as="a"
                      href={
                        definition.latest_signal.profile
                          ? profilePath(definition.latest_signal.profile)
                          : projectPath(`/accounts/${definition.latest_signal.company?.domain}`)
                      }
                      target="_blank"
                      spacing={2}
                      maxW="200px"
                      rounded="lg"
                      padding={2}
                      marginX={-2}
                      _hover={{
                        background: 'white',
                        shadow: 'sm',
                        '& .hover-icon': {
                          opacity: 1
                        }
                      }}
                    >
                      <CompanyAvatar
                        size="28px"
                        rounded="md"
                        name={definition.latest_signal.company?.name || definition.latest_signal.profile?.display_name}
                        domain={definition.latest_signal.company?.domain}
                      />
                      <Text flex="1" fontSize="sm" isTruncated>
                        {definition.latest_signal.profile?.display_name ||
                          definition.latest_signal.profile?.anonymous_name ||
                          definition.latest_signal.company?.name ||
                          definition.latest_signal.company?.domain}
                      </Text>
                      <Circle flex="none" className="hover-icon" opacity={0} size="20px" bg="gray.100">
                        <Icon as={IconArrowRight} boxSize={4} color="gray.600" />
                      </Circle>
                    </HStack>
                  )}
                </Td>
                <Td>
                  {definition.latest_kql_at && (
                    <Text fontSize="sm">
                      <TimeAgo time={definition.latest_kql_at} />
                    </Text>
                  )}
                </Td>
                <Td width="1px">
                  <OptionsMenu id={definition.id} deletePath={projectPath(`/signals/${definition.id}`)} showEdit />
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

interface DefinitionStatusProps {
  enabled: boolean
}

function DefinitionStatus(props: DefinitionStatusProps) {
  const theme = useTheme()
  const colorKey = props.enabled ? 'green' : 'gray'
  const status = props.enabled ? 'Live' : 'Draft'

  const color = theme.colors[colorKey]['300']

  return (
    <Flex alignItems="center" gap={2}>
      <Icon stroke={color} fill={color} as={IconCircle} w="10px" />
      <Text fontSize="sm">{status}</Text>
    </Flex>
  )
}
