import { Flex, Heading, HStack, Icon, Image, Link, Stack, Tag, Text, Tooltip } from '@chakra-ui/react'
import { IconExternalLink, IconInfoCircle } from '@tabler/icons-react'
import React from 'react'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { MiddotDivider } from '../../../ui/Middot'

type Maybe<T> = T | null

interface CrmMatchSummaryProps {
  name?: Maybe<string>
  logo?: Maybe<string>
  domain?: Maybe<string>
  accountType?: Maybe<string>
  matchType?: Maybe<'domain' | 'name' | 'account_id'>
  permalink?: Maybe<string>
  owner?: Maybe<string>
  crm?: { title: string; logo: string }
  showCrm?: boolean
}

const matchTooltip = {
  domain: 'Exact match by domain. This is what Koala uses for "In CRM" matching because domain is a stable identifier.',
  account_id:
    'Exact match by domain. This is what Koala uses for "In CRM" matching because domain is a stable identifier.',
  name: "Fuzzy matched by name. These are potential matching accounts, but did not match the company's domain or domain aliases."
}

export function CrmMatchSummary(props: CrmMatchSummaryProps) {
  const size = 'xs'
  const isStrongMatch = props.matchType == 'domain' || props.matchType == 'account_id'

  return (
    <HStack flex="1" spacing="2" overflow="hidden">
      <CompanyAvatar size="sm" name={props.name} domain={props.domain} />
      <Stack flex="1" alignItems="stretch" spacing="-0.5" overflow="hidden">
        {props.name && (
          <HStack width="100%" justify="space-between" alignItems="center">
            <Heading title={props.name} size="xs" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {props.name}
            </Heading>
            <Flex flex="none" gap={2} alignItems="center">
              {props.matchType && (
                <Tooltip label={matchTooltip[props.matchType]}>
                  <Tag
                    display="inline-flex"
                    gap={1}
                    alignItems="center"
                    colorScheme={isStrongMatch ? 'purple' : 'orange'}
                    variant="subtle"
                    size={'sm'}
                    fontSize="xs"
                  >
                    {isStrongMatch ? 'Domain Match' : 'Fuzzy Match'}
                    {props.matchType && <Icon as={IconInfoCircle} boxSize={3.5} />}
                  </Tag>
                </Tooltip>
              )}
              {props.permalink && (
                <Tooltip label={`View in ${props.crm?.title}`}>
                  <Link flex="none" href={props.permalink} isExternal fontSize={size} color="gray.500" marginRight={1}>
                    <HStack as="span" spacing={1}>
                      {props.showCrm && props.crm?.logo && <Image src={props.crm.logo} boxSize={4} />}
                      <IconExternalLink size={14} />
                    </HStack>
                  </Link>
                </Tooltip>
              )}
            </Flex>
          </HStack>
        )}
        <HStack fontSize={size} spacing={1} divider={<MiddotDivider />}>
          {props.domain ? (
            <Link isExternal href={`https://${props.domain}`} color="gray.600">
              {props.domain}
            </Link>
          ) : (
            <Text color="gray.400">no domain</Text>
          )}
          {props.owner && props.owner !== 'Unknown' && <Text color="gray.600">Owned by {props.owner}</Text>}
          {props.accountType && props.accountType !== 'Unknown' && (
            <Text color="gray.600">Type {props.accountType}</Text>
          )}
        </HStack>
      </Stack>
    </HStack>
  )
}
