import { Flex, FlexProps, List, ListItem, Popover, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react'
import React from 'react'
import { BubbleTag } from './BubbleTag'

function getValidChildren(children: React.ReactNode) {
  return React.Children.toArray(children).filter((child) => React.isValidElement(child)) as React.ReactElement[]
}

interface TagGroupProps extends FlexProps {
  limit?: number
  trigger?: 'hover' | 'click'
}

// group tags, with limit of N tags, and overflow into popover.
export function TagGroup({ children, limit, trigger, ...props }: React.PropsWithChildren<TagGroupProps>) {
  const tags = getValidChildren(children)
  const displayed = tags.slice(0, limit || 3)
  const hiddenCount = tags.length - displayed.length

  if (hiddenCount) {
    return (
      <Popover trigger={trigger || 'hover'} isLazy lazyBehavior="keepMounted">
        <PopoverTrigger>
          <Wrapper {...props}>
            {displayed}
            <BubbleTag>+{hiddenCount}</BubbleTag>
          </Wrapper>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody>
            <List spacing={2} padding={2}>
              {tags.map((tag) => (
                <ListItem key={tag.key}>{tag}</ListItem>
              ))}
            </List>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    )
  }

  return <Wrapper {...props}>{tags}</Wrapper>
}

const Wrapper = React.forwardRef(function Wrapper(props: FlexProps, ref: React.Ref<HTMLDivElement>) {
  return <Flex ref={ref} wrap="nowrap" gap="1.5" alignItems="center" {...props} />
})
