import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Stack
} from '@chakra-ui/react'
import * as React from 'react'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'

interface Props {
  errors?: {
    [key: string]: string[]
  }
  token?: string
}

export default function New(props: Props) {
  // reset_password_token
  const errors = React.useMemo(() => props.errors ?? window.component_props.errors, [props])
  const token = React.useMemo(() => props.token ?? window.component_props.token, [props])

  return (
    <Stack
      marginX="auto"
      marginY="5vh"
      maxWidth="100%"
      width="520px"
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={6}
      paddingX={5}
    >
      <Box textAlign="center">
        <Heading>Change your Password</Heading>
      </Box>

      <Stack spacing={6} direction="column" as="form" action="/users/password" method="post">
        <input type="hidden" name="_method" value="put" />
        <input type="hidden" name="user[reset_password_token]" value={token} />
        <AuthenticityToken />

        <FormControl id="password" isInvalid={errors?.password?.length > 0 ?? false}>
          <FormLabel>Password</FormLabel>
          <Input type="password" name="user[password]" isRequired autoFocus />
          <FormErrorMessage>{errors?.password?.join(', ')}</FormErrorMessage>
          <FormHelperText>Must be at least 10 characters.</FormHelperText>
        </FormControl>

        <FormControl id="password_confirmation" isInvalid={errors?.password_confirmation?.length > 0 ?? false}>
          <FormLabel>Confirm password</FormLabel>
          <Input type="password" name="user[password_confirmation]" isRequired />
          <FormErrorMessage>{errors?.password_confirmation?.join(', ')}</FormErrorMessage>
        </FormControl>

        <Button type="submit" colorScheme="purple" size="lg" fontSize="md">
          Change my Password
        </Button>
      </Stack>
    </Stack>
  )
}
