import { Avatar, Box, Button, Collapse, HStack, Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { JSONTree } from '@app/components/ui/json-tree'
import React, { useState } from 'react'
import { SampleAccount } from './types'

interface BucketTableProps {
  buckets: Array<{
    key: string
    sample_accounts: SampleAccount[]
  }>
  disqualified: any[]
}

export default function CollapsibleBucketTable({ buckets, disqualified }: BucketTableProps) {
  const [openBucket, setOpenBucket] = useState<{ [key: string]: boolean }>({})
  const [openProperties, setOpenProperties] = useState<{ [id: string]: boolean }>({})

  const toggleBucket = (key: string) => {
    setOpenBucket((prev) => ({ ...prev, [key]: !prev[key] }))
  }

  const toggleProperties = (id: string) => {
    setOpenProperties((prev) => ({ ...prev, [id]: !prev[id] }))
  }

  return (
    <Stack spacing={2}>
      {buckets.map((bucket) => (
        <Box key={bucket.key} border="1px solid" borderColor="gray.200" borderRadius="md" p={2}>
          <Button size="sm" onClick={() => toggleBucket(bucket.key)} mb={1}>
            {bucket.key} ({bucket.sample_accounts.length} samples)
          </Button>
          <Collapse in={openBucket[bucket.key]} animateOpacity>
            <Table size="sm" variant="simple">
              <Thead>
                <Tr>
                  <Th p={2} textAlign="left">
                    Score
                  </Th>
                  <Th p={2} textAlign="left">
                    Name
                  </Th>
                  <Th p={2} textAlign="left">
                    Domain
                  </Th>
                  <Th p={2} textAlign="left">
                    Company Details
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {bucket.sample_accounts.map((account) => (
                  <Tr key={account.id}>
                    <Td p={2} textAlign="left">
                      {account.score}
                    </Td>
                    <Td p={2} textAlign="left" fontSize="sm">
                      <HStack>
                        <Avatar size="sm" src={account.company.logo} name={account.company.domain} />
                        <Text>{account.company.name || 'Unnamed Account'}</Text>
                      </HStack>
                    </Td>
                    <Td p={2} textAlign="left" fontSize="sm">
                      {account.company.domain}
                    </Td>
                    <Td p={2} textAlign="left" fontSize="sm">
                      <Button size="xs" onClick={() => toggleProperties(account.id)} variant="link" colorScheme="blue">
                        Properties
                      </Button>
                      <Collapse in={openProperties[account.id]} animateOpacity>
                        <Box
                          minWidth="300px"
                          maxWidth="300px"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                        >
                          <JSONTree data={account.company} />
                        </Box>
                      </Collapse>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Collapse>
        </Box>
      ))}
      <Box key="Disqualified" border="1px solid" borderColor="gray.200" borderRadius="md" p={2}>
        <Button size="sm" onClick={() => toggleBucket('Disqualified')} mb={1}>
          Disqualified ({disqualified.length} samples)
        </Button>
        <Collapse in={openBucket['Disqualified']} animateOpacity>
          <Table size="sm" variant="simple">
            <Thead>
              <Tr>
                <Th p={2} textAlign="left">
                  Score
                </Th>
                <Th p={2} textAlign="left">
                  Name
                </Th>
                <Th p={2} textAlign="left">
                  Domain
                </Th>
                <Th p={2} textAlign="left">
                  Company Details
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {disqualified.map((account) => (
                <Tr key={account.id}>
                  <Td p={2} textAlign="left">
                    {account.score}
                  </Td>
                  <Td p={2} textAlign="left" fontSize="sm">
                    <HStack>
                      <Avatar size="sm" src={account.company.logo} name={account.company.domain} />
                      <Text>{account.company.name || 'Unnamed Account'}</Text>
                    </HStack>
                  </Td>
                  <Td p={2} textAlign="left" fontSize="sm">
                    {account.company.domain}
                  </Td>
                  <Td p={2} textAlign="left" fontSize="sm">
                    <Button size="xs" onClick={() => toggleProperties(account.id)} variant="link" colorScheme="blue">
                      Properties
                    </Button>
                    <Collapse in={openProperties[account.id]} animateOpacity>
                      <Box
                        minWidth="300px"
                        maxWidth="300px"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                      >
                        <JSONTree data={account.company} />
                      </Box>
                    </Collapse>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Collapse>
      </Box>
    </Stack>
  )
}
