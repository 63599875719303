import { Heading, Icon, Link, Stack, Text } from '@chakra-ui/react'
import { IconArrowRight, IconBuildingBroadcastTower, IconTriangleSquareCircle } from '@tabler/icons-react'
import React from 'react'
import { Card } from '../../../../ui/Card'
import EmptyState from '../../../../ui/EmptyState'
import { openWishForm } from '../../../../ui/FeedbackForm'
import { SfOpportunityIcon2 } from '../../../../ui/icons/SalesforceIcons'
import SquareIcon from '../../../../ui/SquareIcon'
import { ReportAvailability, ReportKind } from '../types'

interface KindSelectorProps {
  availability: ReportAvailability[]
  onSelect: (k: ReportKind) => void
}

export default function KindSelector(props: KindSelectorProps) {
  const noneAvailable = props.availability.length === 0 || props.availability.every((a) => !a.available)

  return (
    <Stack spacing={6} w="100%">
      <Stack>
        <Heading size="sm">Create a new Content Report</Heading>
        <Text fontSize="sm" color="gray.600">
          Select the type of content report you'd like to explore. You can always generate additional reports later.
        </Text>
      </Stack>

      <Stack spacing={[2, 3]}>
        {props.availability.map((reportAvailability) => (
          <Card
            key={reportAvailability.kind}
            role="group"
            paddingY={3}
            paddingX={4}
            cursor={reportAvailability.available ? 'pointer' : 'not-allowed'}
            bg={reportAvailability.available ? 'white' : 'gray.100'}
            filter={reportAvailability.available ? undefined : 'grayscale(50%)'}
            borderColor={reportAvailability.available ? 'gray.200' : 'transparent'}
            opacity={reportAvailability.available ? 1 : 0.65}
            pointerEvents={reportAvailability.available ? 'auto' : 'none'}
            onClick={() => {
              props.onSelect(reportAvailability.kind)
            }}
            transition="all 175ms cubic-bezier(0, 0, 0.2, 1)"
            _hover={{
              borderColor: 'purple.500',
              bg: 'purple.50',
              shadow: 'sm'
            }}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={3}
            rounded="lg"
          >
            {reportAvailability.kind === 'opportunities' ? (
              <SquareIcon icon={SfOpportunityIcon2} iconSize={6} padding={2} colorScheme="orange" rounded="xl" />
            ) : (
              <SquareIcon icon={IconTriangleSquareCircle} iconSize={6} padding={2} colorScheme="pink" rounded="xl" />
            )}

            <Stack flex="1 1 auto" spacing={0.5}>
              <Text fontSize="sm" fontWeight="medium" lineHeight={1.2}>
                {reportAvailability.label}
              </Text>
              <Text fontSize="xs" color="gray.600">
                {reportAvailability.description}
              </Text>
            </Stack>
            <Icon
              as={IconArrowRight}
              boxSize={5}
              color="gray.400"
              opacity={0}
              transform="translateX(-5px)"
              transition="all 175ms cubic-bezier(0, 0, 0.2, 1)"
              _groupHover={{ opacity: 1, color: 'purple.500', transform: 'translateX(0px)' }}
            />
          </Card>
        ))}

        {noneAvailable && (
          <EmptyState
            size="sm"
            heading="No reports are available"
            description={
              <Text maxW="550px">
                Looks like your workspace doesn't have enough data to generate reports yet. Check back in later, or{' '}
                <Link
                  href="mailto:support@getkoala.com"
                  isExternal
                  onClick={(e) => {
                    // open wish feedback unless open-in-new-tab was clicked
                    if (e.ctrlKey || e.metaKey || e.shiftKey) {
                      return true
                    }

                    openWishForm()
                    e.preventDefault()
                    return false
                  }}
                >
                  reach out to us
                </Link>
              </Text>
            }
            icon={IconBuildingBroadcastTower}
          />
        )}
      </Stack>
    </Stack>
  )
}
