import { Heading, Link, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { useCurrentUser } from '../../ui/UserContext'
import useTitle from '../../ui/useTitle'
import { ErrorPage } from './components/ErrorPage'

export default function PageNotFound() {
  const user = useCurrentUser()
  useTitle('Page not found | Koala')

  return (
    <ErrorPage
      showLogIn
      title={
        <Stack>
          <Heading size="4xl">404</Heading>
          <Heading as="h1" size="lg">
            Page not found
          </Heading>
        </Stack>
      }
      description={
        <Text maxWidth="540px" fontSize="md" py={4} lineHeight={1.6}>
          🤨 You found a page that doesn't exist or that you don't have access to. Try navigating home
          {user?.email && (
            <>
              {' '}
              or{' '}
              <Link
                href="/logout"
                textDecoration="underline"
                whiteSpace="nowrap"
                onClick={() => {
                  window.ko?.reset()
                }}
              >
                log in with another account
              </Link>
            </>
          )}
        </Text>
      }
    />
  )
}
