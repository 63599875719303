import { Button, FormControl, Input, Stack } from '@chakra-ui/react'
import React from 'react'
import { AdminBreadcrumb } from '..'
import { Card } from '../../../ui/Card'
import { JSONTree } from '../../../ui/json-tree'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'

interface Props {
  ip: string
  geo_data?: any
}

export default function Show(props: Props) {
  return (
    <PageLayout>
      <AdminBreadcrumb paths={[{ path: '/admin/ip_geo', title: 'IP Lookup' }]} />

      <PageTitle>IP Lookup</PageTitle>

      <Stack>
        <FormControl as="form">
          <Input placeholder="IP to lookup" isRequired={true} name="ip" defaultValue={props.ip} />
          <Button mt={4} colorScheme="purple" type="submit">
            Lookup
          </Button>
        </FormControl>

        {props.ip && (
          <Card>
            <JSONTree data={props} />
          </Card>
        )}
      </Stack>
    </PageLayout>
  )
}
