import { Box, Button, Divider, Heading, Stack, StackProps, Text, useDisclosure } from '@chakra-ui/react'
import { IconTrash } from '@tabler/icons-react'
import * as React from 'react'
import type { Project } from '../../../types/Project'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { usePermission } from '../../ui/PermissionsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { ConfirmDeletion } from './components/ConfirmDeletion'
import { ProjectForm } from './components/Form'

interface ShowProps {
  project: Project
}

export const DangerZone = (props: StackProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Stack as="section" spacing={6} {...props}>
      <Stack spacing="1">
        <Heading size="md">Delete workspace</Heading>

        <Text fontSize="sm" color="gray.600">
          If you want to permanently delete this workspace and all of its data.
        </Text>
      </Stack>

      <Box>
        <Button type="submit" size="sm" colorScheme="red" leftIcon={<IconTrash size={16} />} onClick={onOpen}>
          Delete workspace…
        </Button>
      </Box>

      <ConfirmDeletion isOpen={isOpen} onClose={onClose} />
    </Stack>
  )
}

export default function Show(props: ShowProps) {
  const { hasPermission: canDeleteProject } = usePermission({ on: 'project', action: 'can_destroy' })

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb offscreen />

      <SettingsHeader>
        <PageTitle>Workspace Settings</PageTitle>
        <PageDescription>Manage your workspace settings.</PageDescription>
      </SettingsHeader>

      <Stack spacing={10} divider={<Divider />}>
        <Stack as="section" spacing="6">
          <Heading size="md">General</Heading>
          <ProjectForm {...props} method="PATCH" />
        </Stack>

        {canDeleteProject && <DangerZone />}
      </Stack>
    </PageLayout>
  )
}
