import { useQuery } from 'react-query'
import { get } from '../../lib/api'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Data {
  top_k: Array<{ value: string; count?: number }>
}

export function useTopK(property: string) {
  const project = useCurrentProject()
  const path = projectPath(`/top-k?key=${property}`)

  return useQuery<Data>(['top-k', { projectId: project?.id, property }], () => get<Data>(path), {
    enabled: Boolean(project?.id)
  })
}
