import { Box, Button, Flex, IconButton, Text } from '@chakra-ui/react'
import { IconX } from '@tabler/icons-react'
import React, { ComponentProps } from 'react'
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, TooltipRenderProps } from 'react-joyride'

export type { Step } from 'react-joyride'

export function TourComponent({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  skipProps,
  primaryProps,
  tooltipProps
}: TooltipRenderProps) {
  return (
    <Box
      {...tooltipProps}
      position="relative"
      background="purple.800"
      fontSize="sm"
      rounded="base"
      maxW="min-content"
      minW="350px"
    >
      <IconButton
        icon={<IconX size={20} />}
        size="xs"
        color="whiteAlpha.700"
        _hover={{ bg: 'transparent', color: 'white' }}
        _active={{ bg: 'whiteAlpha.400' }}
        variant="ghost"
        position="absolute"
        right={1.5}
        top={1.5}
        {...skipProps}
      />

      <Flex flexDirection="column" gap={3} paddingX={6} paddingY={4}>
        {step.title && (
          <Text color="white" fontWeight="semibold">
            {step.title}
          </Text>
        )}
        {step.content && <Text color="whiteAlpha.900">{step.content}</Text>}
      </Flex>
      <Flex gap={2} alignItems="center" justifyContent="flex-end" paddingX={6} paddingY={4}>
        {index > 0 && (
          <Button size="sm" colorScheme="purple" {...backProps}>
            {backProps.title || 'Next'}
          </Button>
        )}
        {continuous ? (
          <Button size="sm" bg="white" color="purple.800" {...primaryProps}>
            {primaryProps.title || 'Next'}
          </Button>
        ) : (
          <Button size="sm" colorScheme="whiteAlpha" {...closeProps}>
            {closeProps.title || 'Done'}
          </Button>
        )}
      </Flex>
    </Box>
  )
}

interface TourProps extends ComponentProps<typeof Joyride> {
  onEnded?: (data: CallBackProps) => void
  onFinished?: (data: CallBackProps) => void
  onNextStep?: (data: CallBackProps) => void
  onSkipped?: (data: CallBackProps) => void
}

export function Tour({ onEnded, onFinished, onNextStep, onSkipped, ...props }: TourProps) {
  return (
    <Joyride
      disableOverlayClose
      disableScrolling
      spotlightClicks
      continuous
      tooltipComponent={TourComponent}
      locale={{
        back: 'Back',
        close: 'Close',
        last: 'Done',
        next: 'Next',
        skip: 'Skip'
      }}
      spotlightPadding={12}
      floaterProps={{
        disableAnimation: true,
        offset: 16,
        options: {
          preventOverflow: {
            boundariesElement: 'scrollParent'
          }
        },
        styles: {
          arrow: {
            color: 'var(--chakra-colors-purple-800)',
            length: 8,
            spread: 16
          }
        }
      }}
      styles={{
        options: {
          overlayColor: 'transparent'
        },
        // no visible overlay though
        overlay: {
          height: 'unset',
          pointerEvents: 'auto',
          zIndex: 'unset',
          inset: 'unset',
          overflow: 'unset',
          position: 'unset',
          backgroundColor: 'unset',
          mixBlendMode: 'unset'
        },
        // highlight border around element
        spotlight: {
          border: '2px solid var(--chakra-colors-purple-800)',
          borderRadius: '10px',
          background: 'transparent',
          zIndex: 10000
        }
      }}
      callback={(data) => {
        const { action, status, type } = data

        if (type === EVENTS.STEP_AFTER && action == ACTIONS.NEXT) {
          onNextStep?.(data)
        } else if (status === STATUS.FINISHED) {
          onFinished?.(data)
        } else if (type === EVENTS.TOUR_END) {
          onEnded?.(data)
        } else if (status === STATUS.SKIPPED) {
          onSkipped?.(data)
        }
      }}
      {...props}
    />
  )
}
