import React from 'react'

import { projectPath } from '../../../ui/ProjectsContext'

import PageLayout from '@app/components/ui/PageLayout'
import PageTitle from '@app/components/ui/PageTitle'
import type { Project } from '@app/types/Project'
import PageDescription from '../../../ui/PageDescription'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'

import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import { Badge, Box, Button, HStack, Image, Link, Stack, Text } from '@chakra-ui/react'
import { Introduction } from '../components/Introduction'
import { PostsList, TrackedPost } from './posts-list'
import { IconChevronRight } from '@tabler/icons-react'

interface Props {
  app_id: string
  project: Project
  title: string
  description: string
  logo: string
  valid?: boolean
  connected?: boolean
  settings: {}
  deps: {
    tracked_posts: TrackedPost[]
  }
}

export default function Show(props: Props) {
  return (
    <PageLayout size={props.deps.tracked_posts.length > 0 ? 'md' : 'sm'}>
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/linkedin_xt'),
            title: 'Chrome Extension for LinkedIn'
          }
        ]}
        offscreen
      />
      <HStack w="100%">
        <Box w="100%">
          <HStack>
            <HStack marginRight="auto" alignItems="center" spacing={2}>
              <Image src={props.logo} maxW="6" />
              <PageTitle>{props.title}</PageTitle>
              {props.connected && props.valid && <Badge colorScheme="green">Connected</Badge>}
            </HStack>
            {props.connected && (
              <DisconnectAppDialog appTitle="Chrome Extension for LinkedIn" showRemoveCachesOption={false} />
            )}
          </HStack>

          <PageDescription>{props.description}</PageDescription>
        </Box>
      </HStack>

      <Stack spacing={16}>
        <Introduction app="Chrome Extension for LinkedIn" icon={props.logo}>
          <Stack spacing={3}>
            <Text color="gray.600">
              The Koala Chrome Extension makes it easy to track reactions and comments on any LinkedIn post. To get
              started, simply install the extension in your browser from the Chrome Web Store. Once installed, you'll
              see a Koala icon in the top right corner of your browser or in the extensions menu. Clicking the icon
              while browsing LinkedIn will open the extension, prompting you to input a post URL to track.
            </Text>
            <Text color="gray.600">
              The extension will automatically detect when you are on a LinkedIn post page to let you seamlessly track
              the people interacting with it. You'll see the Koala icon next to posts in your feed that you can track as
              well. Tracked posts can be synced to a list in Koala, allowing you to view all interactions on a post in
              one place.
            </Text>
            <Text color="gray.600">
              Note: for the extension to work, you must be logged in to LinkedIn when you use it, and you must also be
              logged in to your Koala workspace.
            </Text>
            <Box pt={2}>
              <Button
                as={Link}
                variant="ghost"
                size="xs"
                ml={-1.5}
                isExternal
                href="https://chromewebstore.google.com/detail/koala/ejgkoecoplficfiknpppeppjlendbnph"
                iconSpacing={1}
                rightIcon={<IconChevronRight size={14} />}
              >
                Open in the Chrome Web Store
              </Button>
            </Box>
          </Stack>
        </Introduction>

        {props.deps.tracked_posts.length > 0 && <PostsList trackedPosts={props.deps.tracked_posts} />}
      </Stack>
    </PageLayout>
  )
}
