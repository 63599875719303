import {
  Box,
  Heading,
  Icon,
  IconButton,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Spinner,
  Stack,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import { IconBolt } from '@tabler/icons-react'
import React from 'react'
import { useIntentSignals } from '../../../data/use-intent-signals'
import { BubbleTag } from '../../../ui/BubbleTag'
import { kqlDefinitionPath } from '../path-helpers'
import { KqlDefinition } from '../types'
import { DefinitionFormModal } from './DefinitionFormModal'

interface Props {
  intentType?: 'form' | 'page' | 'event'
  conditions?: KqlDefinition['trigger']['conditions']
  title?: string
}

interface TrackButtonProps {
  isPathMatch: boolean
  disclosure: ReturnType<typeof useDisclosure>
}

export function TrackButton(props: TrackButtonProps) {
  const { isPathMatch, disclosure } = props

  return (
    <Tooltip label={isPathMatch ? 'Tracking as Intent Signal' : 'Track as Intent Signal'}>
      <IconButton
        aria-label="Track intent signal"
        icon={<Icon as={IconBolt} boxSize={4} fill={isPathMatch ? 'currentColor' : 'none'} />}
        size="xs"
        variant={'ghost'}
        onClick={(e) => {
          e.stopPropagation()
          disclosure.onOpen()
        }}
        isActive={disclosure.isOpen}
        opacity={isPathMatch ? 1 : 0}
        color="orange.300"
        _groupHover={{
          opacity: 1,
          pointerEvents: 'auto'
        }}
      />
    </Tooltip>
  )
}

export function TrackIntent(props: Props) {
  const disclosure = useDisclosure()
  const { data, isLoading, refetch } = useIntentSignals()

  const incomingPaths = React.useMemo(() => props.conditions?.flatMap((c) => c.value ?? []) ?? [], [props.conditions])

  const matchingDefinitions = React.useMemo(() => {
    const definitions = data?.definitions ?? []
    return definitions.filter((d) => {
      const conditions = (d.trigger?.conditions ?? []).map((c) => c.value ?? '').flat()
      return incomingPaths.some((i) => conditions.includes(i))
    })
  }, [incomingPaths, data?.definitions])

  const isPathMatch = React.useMemo(() => {
    if (!data) {
      return false
    }

    const existingPaths =
      data.definitions.flatMap((d) => (d.trigger?.conditions ?? []).flatMap((c) => c.value ?? [])) ?? []
    return incomingPaths.some((p) => existingPaths.includes(p))
  }, [data, incomingPaths])

  const initialFocusRef = React.useRef()

  if (isLoading) {
    return <Spinner size="sm" thickness="1px" />
  }

  return (
    <>
      {isPathMatch && (
        <Popover
          isOpen={disclosure.isOpen}
          onClose={disclosure.onClose}
          initialFocusRef={initialFocusRef as any}
          isLazy
          lazyBehavior="keepMounted"
        >
          <PopoverTrigger>
            <Box display="flex" flex="none" paddingX={1}>
              <TrackButton disclosure={disclosure} isPathMatch={isPathMatch} />
            </Box>
          </PopoverTrigger>
          <Portal>
            <PopoverContent
              borderColor={'orange.500'}
              w="auto"
              minW="300px"
              _focus={{
                outline: 'none'
              }}
            >
              <PopoverArrow bg="orange.400" borderColor={'orange.500'} />
              <PopoverHeader bg="orange.400" color="white" roundedTop={'4'}>
                <Heading size="xs">Matching Intent Signals</Heading>
              </PopoverHeader>
              <PopoverCloseButton color={'white'} />
              <PopoverBody>
                <Stack>
                  {matchingDefinitions.map((d) => (
                    <Link key={d.id} href={kqlDefinitionPath(d)}>
                      <BubbleTag
                        size="md"
                        value={d.name}
                        border="none"
                        maxW="400px"
                        overflow={'hidden'}
                        textOverflow="ellipsis"
                        overflowWrap={'break-word'}
                      />
                    </Link>
                  ))}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      )}
      {!isPathMatch && (
        <>
          <Box display="flex" flex="none" paddingX={1}>
            <TrackButton disclosure={disclosure} isPathMatch={isPathMatch} />
          </Box>
          <DefinitionFormModal {...disclosure} name={props.title} conditions={props.conditions} onComplete={refetch} />
        </>
      )}
    </>
  )
}
