import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack
} from '@chakra-ui/react'
import { IconCopy, IconDotsVertical, IconListDetails } from '@tabler/icons-react'
import React, { useRef } from 'react'
import { toast } from 'sonner'
import { put } from '../../../lib/api'
import router from '../../../lib/router'
import { Apps } from '../../../types/App'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import { Breadcrumb } from '../../ui/Breadcrumb'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { useCurrentUser } from '../../ui/UserContext'
import { FollowRule } from '../notifications'
import { automationPath, automationsPath } from '../notifications/lib/path-helper'
import { NotificationRuleForm } from './components/notification-rule-form'
import { NotificationRulePreview } from './components/preview'
import { NotificationRulePreviewUser } from './components/preview-user'

interface Props {
  follow_rule: FollowRule
  errors?: Record<string, string[]>
  apps: Apps
}

export default function EditFollowRule(props: Props) {
  const user = useCurrentUser()
  const formRef = useRef<HTMLFormElement>(null)
  const isDeleted = props.follow_rule?.deleted_at
  const [loading, setLoading] = React.useState(false)

  return (
    <PageLayout size="md">
      <PageTitle skipRendering>Actions</PageTitle>

      <form
        id="follow-rule"
        action={automationPath(props.follow_rule.id)}
        method="POST"
        ref={formRef}
        data-koala-collect="off"
      >
        <HStack pb="4" justifyContent={'space-between'} flexWrap="wrap">
          <Breadcrumb
            paths={[
              { path: projectPath('/automations/overview'), title: 'Automations' },
              { path: automationsPath(), title: 'Actions' },
              { path: automationPath(props.follow_rule.id), title: props.follow_rule.name }
            ]}
          />

          {!isDeleted && (
            <>
              <Flex alignItems="center" gap={1}>
                <NotificationRulePreview
                  formRef={formRef}
                  buttonProps={{
                    size: 'sm',
                    colorScheme: 'gray',
                    variant: 'ghost'
                  }}
                />

                <Button
                  as={Link}
                  href={automationPath(props.follow_rule.id, '/logs')}
                  variant="ghost"
                  size="sm"
                  leftIcon={<IconListDetails size={16} />}
                >
                  Logs
                </Button>

                <Menu size={'sm'}>
                  <MenuButton
                    as={IconButton}
                    aria-label="Actions"
                    icon={<IconDotsVertical size={16} />}
                    variant="ghost"
                    size="sm"
                  />

                  <MenuList zIndex="popover">
                    {user.isInternalUser && (
                      <NotificationRulePreviewUser
                        formRef={formRef}
                        buttonProps={{
                          size: 'sm'
                        }}
                      />
                    )}

                    <MenuItem
                      as="a"
                      icon={<IconCopy size={16} />}
                      iconSpacing={1.5}
                      href={automationsPath(`/new?from_rule=${props.follow_rule.id}`)}
                    >
                      Clone
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </>
          )}
        </HStack>

        <input type="hidden" name="_method" value="PATCH" />
        <AuthenticityToken />

        <Stack>
          {isDeleted && (
            <Alert status="warning" w="100%">
              <AlertIcon />
              <HStack w="100%" justifyContent={'space-between'}>
                <Stack spacing="-1" fontSize={'sm'}>
                  <AlertTitle>This Automation Action has been deleted.</AlertTitle>
                  <AlertDescription>
                    Restoring it will re-enable all channels and intent tracking for this automation.
                  </AlertDescription>
                </Stack>
                <Button
                  size="sm"
                  colorScheme={'orange'}
                  isLoading={loading}
                  onClick={async (e) => {
                    e.preventDefault()
                    setLoading(true)

                    await put(automationPath(props.follow_rule.id, '/restore'), {}).then(() => {
                      toast.success('Notification Rule Restored')
                      router.visit(automationPath(props.follow_rule.id))
                      setLoading(false)
                    })
                  }}
                >
                  Restore
                </Button>
              </HStack>
            </Alert>
          )}
          <Flex
            data-disabled={isDeleted}
            _disabled={{
              opacity: 0.4,
              pointerEvents: 'none'
            }}
          >
            <NotificationRuleForm {...props} formRef={formRef} errors={props.errors} />
          </Flex>
        </Stack>
      </form>
    </PageLayout>
  )
}
