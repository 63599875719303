import { Heading } from '@chakra-ui/react'
import React from 'react'
import { ErrorPage } from './components/ErrorPage'

interface Props {
  message?: string
}

export default function Unacceptable(props: Props) {
  const message =
    props.message ||
    "Unfortunately, the change you wanted was rejected. Maybe you tried to change something you didn't have access to."

  return (
    <ErrorPage
      title={
        <Heading as="h1" size="lg">
          Unable to process request
        </Heading>
      }
      description={message}
    />
  )
}
