import { Text } from '@chakra-ui/react'
import React from 'react'

interface Props {
  children: React.ReactNode
}

export default function Label(props: Props) {
  return (
    <Text display="inline-flex" isTruncated fontWeight="medium" fontSize="sm" alignItems="center" paddingX={2}>
      {props.children}
    </Text>
  )
}
