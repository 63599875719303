import { Button, Heading, Icon, Link, Stack } from '@chakra-ui/react'
import { IconArrowLeft } from '@tabler/icons-react'
import * as React from 'react'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import DefinitionForm from './components/DefinitionForm'
import { KqlDefinition } from './types'

interface Props {
  definition: KqlDefinition
  errors?: Record<string, string[]>
}

export default function New(props: Props) {
  return (
    <PageLayout size="sm">
      <PageTitle skipRendering>New Intent Signal</PageTitle>
      <Button
        alignSelf="flex-start"
        as={Link}
        href={projectPath('/signals')}
        leftIcon={<Icon as={IconArrowLeft} boxSize={3.5} />}
        variant="link"
        size="xs"
        flex="none"
        color="gray.500"
      >
        Back to all intent signals
      </Button>

      <Stack>
        <Heading size="md">Create an Intent Signal</Heading>
        <PageDescription>
          Intent Signals highlight important moments in the customer journey, so you can react quickly. You should set
          up Intent Signals for any pageview or event that signifies potential buying intent.
        </PageDescription>
      </Stack>

      <DefinitionForm {...props} />
    </PageLayout>
  )
}
