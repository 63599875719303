import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import set from 'lodash/set'
import { DateRange } from 'react-day-picker'
import dayjs from '../../../../lib/dayjs'

export interface TimeseriesData {
  value: number
  timestamp: string | number
  current?: boolean
  [key: string]: any
}

export type Period =
  | 'day'
  | 'yesterday'
  | 'week'
  | 'two-weeks'
  | 'month'
  | 'month-to-date'
  | 'quarter'
  | 'quarter-to-date'
  | 'year'
  | '5 years'

export type SmoothingOptions =
  | 'identity'
  | 'auto'
  | 'moving'
  | 'gaussian'
  | 'exponential'
  | 'savitzkyGolay'
  | 'cumulative'
  | 'max'

function exponentialMovingAverage(data: number[], alpha = 0.3): number[] {
  const smoothedData: number[] = []
  let previous: number | null = null
  for (const point of data) {
    if (previous === null) {
      smoothedData.push(point)
      previous = point
    } else {
      const smoothedPoint = alpha * point + (1 - alpha) * previous
      smoothedData.push(smoothedPoint)
      previous = smoothedPoint
    }
  }
  return smoothedData
}

function gaussianSmoothing(data: number[], sigma = 1): number[] {
  const gaussianKernel = (x: number) => Math.exp(-(x * x) / (2 * sigma * sigma))
  const kernelRadius = Math.ceil(3 * sigma)
  const smoothedData: number[] = []

  for (let i = 0; i < data.length; i++) {
    let weightedSum = 0
    let weightSum = 0
    for (let j = -kernelRadius; j <= kernelRadius; j++) {
      const dataIndex = i + j
      if (dataIndex >= 0 && dataIndex < data.length) {
        const weight = gaussianKernel(j / sigma)
        weightedSum += data[dataIndex] * weight
        weightSum += weight
      }
    }
    smoothedData.push(weightedSum / weightSum)
  }

  return smoothedData
}

function movingAverage(data: number[], windowSize = 5): number[] {
  if (windowSize < 1) {
    throw new Error('Window size must be greater than 0')
  }

  const smoothedData: number[] = []
  let sum = 0

  for (let i = 0; i < data.length; i++) {
    sum += data[i]

    if (i < windowSize - 1) {
      smoothedData.push(data[i]) // or you could push the current point or some other placeholder value
    } else {
      if (i >= windowSize) {
        sum -= data[i - windowSize]
      }
      smoothedData.push(sum / windowSize)
    }
  }

  return smoothedData
}

function autoSmooth(data: number[]): number[] {
  if (data.length < 3) {
    return movingAverage(data, 1)
  }

  const estimatedWindowSize = estimateWindowSize(data)

  // Check if the conditions for Savitzky-Golay are met
  if (estimatedWindowSize % 2 === 1 && estimatedWindowSize >= 3) {
    return savitzkyGolaySmoothing(data, estimatedWindowSize, 1)
  } else {
    // If not, fall back to Simple Moving Average with an adjusted window size
    const adjustedWindowSize = Math.max(5, estimatedWindowSize - (estimatedWindowSize % 2 === 0 ? 1 : 0))
    return movingAverage(data, adjustedWindowSize)
  }
}

function estimateWindowSize(data: number[]): number {
  // This is a simplified example. In a real-world scenario, you might want to
  // analyze the variance, frequency, or other statistical properties of the data.
  // Here, we're just basing the window size on the length of the data.

  const dataSize = data.length
  let windowSize = Math.floor(dataSize / 10)

  // Ensure the window size is odd (required for Savitzky-Golay) and at least 3
  windowSize = windowSize % 2 === 0 ? windowSize - 1 : windowSize
  windowSize = Math.max(3, windowSize)

  return windowSize
}

function savitzkyGolaySmoothing(data: number[], windowSize = 5, degree = 2): number[] {
  if (windowSize % 2 === 0 || windowSize < degree + 2) {
    throw new Error('Invalid window size or degree for Savitzky-Golay')
  }

  const coefficients = savitzkyGolayCoefficients(windowSize, degree)
  const halfWindow = Math.floor(windowSize / 2)
  const smoothedData: number[] = []

  for (let i = 0; i < data.length; i++) {
    let smoothedPoint = 0
    for (let j = -halfWindow; j <= halfWindow; j++) {
      const dataIndex = i + j
      if (dataIndex >= 0 && dataIndex < data.length) {
        smoothedPoint += data[dataIndex] * coefficients[j + halfWindow]
      }
    }
    smoothedData.push(smoothedPoint)
  }

  return smoothedData
}

function savitzkyGolayCoefficients(windowSize: number, _degree: number): number[] {
  // This function calculates the Savitzky-Golay coefficients.
  // In a real-world implementation, you would need to solve a linear equation system here.
  // This is a simplified version and may not be accurate for all cases.
  // You might want to use a numerical library like math.js to solve the linear system.
  return Array(windowSize).fill(1 / windowSize)
}

function parseRange(value: Date | DateRange | string | undefined) {
  if (!value) return

  if (typeof value === 'object' && 'from' in value) {
    return value
  }

  if (typeof value === 'string') {
    if (value.includes('..')) {
      const [from, to] = value.split('..').map((d) => dayjs(d).toDate())
      return { from, to }
    }
  }
}

function getCutoffDate(value?: string | DateRange) {
  const range = parseRange(value)
  const start = range?.from ?? (value as string)
  const cutoffDate = dayjs.utc()

  switch (start) {
    case 'day':
      return cutoffDate.startOf('hour').subtract(24, 'hours')
    case 'yesterday':
      return cutoffDate.startOf('day').subtract(2, 'days')
    case 'week':
      return cutoffDate.startOf('day').subtract(7, 'days')
    case 'two-weeks':
      return cutoffDate.startOf('day').subtract(14, 'days')
    case 'month':
      return cutoffDate.startOf('day').subtract(1, 'month')
    case 'month-to-date':
      return cutoffDate.startOf('month')
    case 'quarter':
      return cutoffDate.startOf('day').subtract(3, 'months')
    case 'quarter-to-date':
      return cutoffDate.startOf('quarter')
    case 'year':
      return cutoffDate.startOf('day').subtract(1, 'year')
    case '5 years':
      return cutoffDate.startOf('day').subtract(5, 'years')
    default:
      return start ? dayjs.utc(start).startOf('day') : null
  }
}

function applySmoothing(data: number[], smoothing: SmoothingOptions) {
  switch (smoothing) {
    case 'auto':
      return autoSmooth(data)
    case 'moving':
      return movingAverage(data, 7)
    case 'gaussian':
      return gaussianSmoothing(data)
    case 'exponential':
      return exponentialMovingAverage(data)
    case 'savitzkyGolay':
      return savitzkyGolaySmoothing(data)
    case 'max': {
      const clone: number[] = []

      data.forEach((value, index) => {
        if (index === 0) {
          clone.push(value)
        } else {
          if (value < clone[index - 1]) {
            clone.push(clone[index - 1])
          } else {
            clone.push(value)
          }
        }
      })

      return clone
    }
    case 'cumulative':
      return data.map((value, index) => {
        if (index === 0) {
          return value
        } else {
          return value + data.slice(0, index).reduce((a, b) => a + b, 0)
        }
      })
    default:
      return data
  }
}

export function prepareTimeseriesData(
  data: TimeseriesData[],
  keys: string[],
  smoothing: SmoothingOptions = 'identity',
  range?: string | DateRange
) {
  const cutoffDate = getCutoffDate(range)

  // exclude data points that are before the cutoff date
  const trimmed = data.filter((item) => {
    const itemDate = dayjs.utc(item.timestamp)
    return !cutoffDate || itemDate.isAfter(cutoffDate) || itemDate.isSame(cutoffDate)
  })

  const length = trimmed.length - 1

  const moved = {}

  for (const key of keys) {
    // apply smoothing over entire data set (includes padded data points beyond the time frame)
    const values = data.map((item) => get(item, key))
    let smoothed = applySmoothing(values, smoothing)

    // remove padded data points
    if (smoothed.length > trimmed.length) {
      smoothed = smoothed.slice(smoothed.length - trimmed.length)
    }

    moved[key] = smoothed
  }

  return orderBy(trimmed, ['timestamp'], ['asc']).map(({ timestamp, current }, index) => {
    const copy = { timestamp }

    // grab the smoothed value for each key
    for (const key of keys) {
      const val = moved[key][index] || 0
      const obj: Record<string, any> = { value: val }

      if (current) {
        obj.current = val
      } else if (index === length - 1) {
        obj.current = val
        obj.previous = val
      } else {
        obj.previous = val
      }

      set(copy, key, obj)
    }

    return copy
  })
}

// detect whether the range should be hourly buckets, daily buckets, or monthly buckets
// if the range is a preset period like 'day', 'yesterday' use 'hour'
// if the range is a preset period like 'year', '5 years' use 'month'
// if the range is a DateRange determine the spread between the `from` and `to` dates and if it's less than 4 days use 'hour
export function getGranularity(value: string | DateRange | undefined): 'hour' | 'day' | 'month' {
  if (!value) return 'day'

  const range = parseRange(value)
  if (range?.from) {
    const to = dayjs(range.to)
    const diff = to.diff(range.from, 'day')
    if (diff <= 4) {
      return 'hour'
    } else {
      return 'day'
    }
  }

  if (typeof value === 'string') {
    switch (value) {
      case 'day':
      case 'yesterday':
        return 'hour'
      case 'year':
      case '5 years':
        return 'month'
      default:
        return 'day'
    }
  }

  return 'day'
}
