import {
  Box,
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import pluralize from 'pluralize'
import * as React from 'react'
import { toast } from 'sonner'
import { postForm } from '../../../../lib/api'
import { Space } from '../../../../types/Space'
import { CheckboxBlankCircleIcon, CheckboxCircleIcon } from '../../../ui/icons'
import { projectPath } from '../../../ui/ProjectsContext'
import { SpaceMembers } from '../../spaces/components/Members'

interface SpacesModalProps {
  projectSlug: string
  isOpen: boolean
  onClose: () => void
  spaces: Space[]
}

export function SpacesModal({ projectSlug, isOpen, onClose, spaces }: SpacesModalProps) {
  const [submitting, setSubmitting] = React.useState(false)
  const [selected, setSelected] = React.useState<string[]>([])
  const toggleSelection = React.useCallback((id: string) => {
    setSelected((prev) => {
      if (prev.includes(id)) {
        return prev.filter((selection) => selection !== id)
      } else {
        return [...prev, id]
      }
    })
  }, [])

  const onSubmit = React.useCallback(
    (event) => {
      event.preventDefault()
      const formData = new FormData(event.target)

      setSubmitting(true)

      postForm(`/projects/${projectSlug}/spaces/join`, formData)
        .then(() => {
          toast.success('Successfully joined selected spaces')
          onClose()
        })
        .catch(() => {
          toast.error('Failed to join selected spaces')
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
    [projectSlug, onClose]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" preserveScrollBarGap>
      <form data-koala-collect="off" action={projectPath(`/spaces/join`)} method="POST" onSubmit={onSubmit}>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(6px)" />
        <ModalContent>
          <ModalHeader>Join Spaces</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={8} marginBottom={8}>
              <Stack spacing={4}>
                <Text fontSize="sm" color="gray.700">
                  Spaces are collections of lists that you and your teammates can share to organize your accounts and
                  contacts.
                </Text>
                <Text fontSize="sm" color="gray.700">
                  This workspace has {spaces.length === 1 ? 'a shared space' : 'some shared spaces'} you can join. This
                  is optional and you can always do this later, or leave a space at any time.
                </Text>
              </Stack>

              <Stack spacing={2}>
                <Text fontSize="xs" color="gray.500">
                  Select the spaces you want to join:
                </Text>
                {spaces.map((space) => (
                  <SpaceItem
                    key={space.id}
                    space={space}
                    selected={selected.includes(space.id)}
                    onSelect={() => toggleSelection(space.id)}
                  />
                ))}
              </Stack>
            </Stack>
          </ModalBody>

          <ModalFooter justifyContent="stretch" gap={3}>
            <Button flex="1 1 50%" variant="outline" onClick={onClose}>
              Skip this
            </Button>

            <Button
              flex="1 1 50%"
              type="submit"
              colorScheme="purple"
              isLoading={submitting}
              isDisabled={selected.length === 0}
            >
              Join {selected.length} selected {pluralize('space', selected.length)}
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}

interface SpaceItemProps {
  space: Space
  selected?: boolean
  onSelect: () => void
}

function SpaceItem(props: SpaceItemProps) {
  return (
    <HStack
      spacing={2}
      border="1px solid"
      borderColor={props.selected ? 'purple.500' : 'gray.200'}
      rounded="md"
      paddingX={3}
      paddingY={3}
      cursor="pointer"
      onClick={props.onSelect}
    >
      {props.selected ? (
        <Icon as={CheckboxCircleIcon} boxSize={5} color="purple.500" />
      ) : (
        <Icon as={CheckboxBlankCircleIcon} boxSize={5} color="gray.300" />
      )}
      <Box flex="1">
        {props.selected && <input type="hidden" name="spaces[id][]" value={props.space.id} />}
        <Text fontSize="sm" fontWeight="medium">
          {props.space.name}
        </Text>
      </Box>
      <SpaceMembers members={props.space.members} />
    </HStack>
  )
}
