import { Icon, IconProps } from '@chakra-ui/react'
import React from 'react'

interface Props extends IconProps {
  size?: string | number
}

export function BuildingIcon({ size, ...props }: Props) {
  size = size ? `${size}px` : size
  return (
    <Icon viewBox="0 0 14 14" fill="none" width={size} height={size} {...props}>
      <path
        d="M11.0833 1.16666H5.25C4.60658 1.16666 4.08333 1.68991 4.08333 2.33332V5.83332H2.91667C2.27325 5.83332 1.75 6.35657 1.75 6.99999V12.25C1.75 12.4047 1.81146 12.5531 1.92085 12.6625C2.03025 12.7719 2.17862 12.8333 2.33333 12.8333H11.6667C11.8214 12.8333 11.9698 12.7719 12.0791 12.6625C12.1885 12.5531 12.25 12.4047 12.25 12.25V2.33332C12.25 1.68991 11.7268 1.16666 11.0833 1.16666ZM2.91667 6.99999H6.41667V11.6667H2.91667V6.99999ZM11.0833 11.6667H7.58333V6.99999C7.58333 6.35657 7.06008 5.83332 6.41667 5.83332H5.25V2.33332H11.0833V11.6667Z"
        fill="currentColor"
      />
      <path
        d="M6.41671 3.5H7.58337V4.66667H6.41671V3.5ZM8.75004 3.5H9.91671V4.66667H8.75004V3.5ZM8.75004 5.85142H9.91671V7H8.75004V5.85142ZM8.75004 8.16667H9.91671V9.33333H8.75004V8.16667ZM4.08337 8.16725H5.25004V9.33392H4.08337V8.16725Z"
        opacity={0.75}
        fill="currentColor"
      />
    </Icon>
  )
}
