import throttle from 'lodash/throttle'
import { useLayoutEffect, useRef, useState } from 'react'

export function useOverflow(deps: any[] = []) {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const [overflowTop, setOverflowTop] = useState(false)
  const [overflowBottom, setOverflowBottom] = useState(false)
  const [overflowLeft, setOverflowLeft] = useState(false)
  const [overflowRight, setOverflowRight] = useState(false)

  useLayoutEffect(() => {
    const el = scrollRef.current
    if (!el) {
      return
    }

    let rafId: number | null = null

    const requestRaf = () => {
      if (!rafId) {
        rafId = window.requestAnimationFrame(() => {
          checkOverflow()
          rafId = null
        })
      }
    }

    const resizeObserver = ResizeObserver ? new ResizeObserver(requestRaf) : null
    if (resizeObserver) {
      resizeObserver.observe(el)
    }

    const checkOverflow = () => {
      if (el.scrollHeight > el.clientHeight) {
        setOverflowTop(el.scrollTop > 0)
        setOverflowBottom(Math.round(el.scrollTop + el.clientHeight) < el.scrollHeight)
      } else {
        setOverflowTop(false)
        setOverflowBottom(false)
      }

      if (el.scrollWidth > el.clientWidth) {
        setOverflowLeft(el.scrollLeft > 0)
        setOverflowRight(Math.round(el.scrollLeft + el.clientWidth) < el.scrollWidth)
      } else {
        setOverflowLeft(false)
        setOverflowRight(false)
      }
    }

    const throttledScroll = throttle(checkOverflow, 100)

    el.addEventListener('scroll', throttledScroll, { passive: true })
    checkOverflow()

    return () => {
      el.removeEventListener('scroll', throttledScroll)
      throttledScroll.cancel()
      resizeObserver?.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return { scrollRef, overflowTop, overflowBottom, overflowLeft, overflowRight }
}
