import React from 'react'
import { Apps } from '../../../types/App'
import { Territory } from '../../../types/Territory'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { TerritoryForm } from './components/TerritoryForm'

interface Props {
  apps: Apps
  territory: Territory
  errors?: {
    [key: string]: string[]
  }
}

export default function ShowTerritory(props: Props) {
  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        paths={[
          { path: projectPath('/territories'), title: 'Territories' },
          { path: window.location.toString(), title: props.territory.name }
        ]}
      />

      <SettingsHeader divider={false}>
        <PageTitle size="md">{props.territory.name}</PageTitle>
      </SettingsHeader>

      <TerritoryForm {...props} />
    </PageLayout>
  )
}
