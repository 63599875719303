import { useQuery } from 'react-query'
import { concurrentGET } from '../../lib/api'
import { AccountView } from '../../types/AccountView'
import { PageMeta } from '../../types/PageMeta'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Data {
  account_views: AccountView[]
  page_meta: PageMeta
}

type MaybeArray<T> = T | T[]

interface Props {
  perPage?: number
  page?: number
  ownership?: MaybeArray<'private' | 'shared' | 'team' | 'space'>
  includePrivate?: boolean
  kind?: 'account' | 'profile'
  teamId?: string
  spaceId?: string
}

export function useAccountViews(props: Props = {}) {
  const { perPage = 1000, page = 1, kind, ownership, teamId, spaceId, includePrivate } = props
  const project = useCurrentProject()
  const search = new URLSearchParams()

  search.set('per_page', String(perPage))
  search.set('page', String(page))

  if (Array.isArray(ownership)) {
    ownership.forEach((o) => search.append('ownership[]', o))
  } else if (ownership) {
    search.set('ownership', ownership)
  }

  if (includePrivate) {
    search.set('include_private', 'true')
  }

  if (kind) {
    search.set('kind', kind)
  }

  if (teamId) {
    search.set('team_id', teamId)
  }

  if (spaceId) {
    search.set('space_id', spaceId)
  }

  const path = projectPath(`/views.json?${search.toString()}`)

  return useQuery<Data>(
    ['account_views', { projectId: project?.id, includePrivate, kind, ownership, teamId, spaceId, page, perPage }],
    () => concurrentGET<Data>(path),
    {
      enabled: Boolean(project?.id)
    }
  )
}
