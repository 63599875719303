import { Box, Button, Flex, Link, ScaleFade, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { useCurrentUser } from '../../../ui/UserContext'

interface Props {
  title: React.ReactNode
  description?: React.ReactNode
  body?: React.ReactNode
  showLogIn?: boolean
}

export function ErrorPage(props: Props) {
  const user = useCurrentUser()

  return (
    <Flex position="relative" flex="1" w="100%" h="100%" justifyContent="center" alignItems="center" p={8}>
      {props.showLogIn && user && (
        <Stack
          position="absolute"
          top={[4, 6, 8]}
          right={[4, 6, 8]}
          alignItems="flex-end"
          textAlign="right"
          spacing={2}
        >
          <Box>
            <Text fontSize="xs" color="gray.600">
              Logged in as
            </Text>
            <Text fontSize="sm" fontWeight="medium">
              {user.email}
            </Text>
          </Box>
          <Button
            as={Link}
            variant="link"
            size="sm"
            href="/logout"
            colorScheme="purple"
            onClick={() => {
              window.ko?.reset()
            }}
          >
            Switch user
          </Button>
        </Stack>
      )}
      <ScaleFade in unmountOnExit initialScale={0.7}>
        <Flex direction="column" gap={2} textAlign="center">
          {props.title}
          {props.description && <Box fontSize="lg">{props.description}</Box>}
          {props.body}
        </Flex>
      </ScaleFade>
    </Flex>
  )
}
