import React from 'react'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import PageDescription from '../../../ui/PageDescription'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import { Introduction } from '../components/Introduction'
import { LightBgCard } from '../../../ui/Card'
import { StepIcon } from '../../../ui/StepIcon'
import { AuthenticityToken } from '@app/components/ui/AuthenticityToken'
import PlanTierAlert from '@app/components/ui/PlanTierAlert'

import { projectPath } from '../../../ui/ProjectsContext'
import { usePermission } from '../../../ui/PermissionsContext'

import {
  Box,
  HStack,
  Image,
  Badge,
  Link,
  Stack,
  Heading,
  Icon,
  Text,
  Input,
  FormControl,
  FormLabel,
  Button
} from '@chakra-ui/react'
import { IconArrowUpRight } from '@tabler/icons-react'

interface Props {
  title: string
  description: string
  logo: string
  settings: Record<string, string | null | undefined>
  connected?: boolean
  valid?: boolean
  deps: {}
  plan_tier?: string
}

export default function Show(props: Props) {
  const { hasPermission: canEditProject } = usePermission({
    on: 'project',
    action: 'can_edit'
  })
  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/g2'),
            title: 'G2'
          }
        ]}
        offscreen
      />

      <Box>
        <HStack>
          <HStack marginRight="auto" alignItems="center" spacing={2}>
            <Image src={props.logo} maxW="6" />
            <PageTitle>{props.title}</PageTitle>
            {props.connected && props.valid && <Badge colorScheme="green">Connected</Badge>}
            {props.connected && !props.valid && <Badge colorScheme="orange">Needs attention</Badge>}
          </HStack>
          {props.connected && <DisconnectAppDialog appTitle="G2" showRemoveCachesOption={false} />}
        </HStack>
        <PageDescription>{props.description}</PageDescription>
      </Box>

      {props.plan_tier && (
        <PlanTierAlert planTier={props.plan_tier} description="Upgrade your plan to connect your G2 account" />
      )}

      <Introduction
        app="g2"
        icon={props.logo}
        description="Connect G2 to sync their buyer intent data into Koala, which you can use to trigger automations, filter accounts, and more."
        docsLink="https://getkoala.com/docs/integrations/g2"
      />

      <Box as="form" w="100%" method="POST">
        <AuthenticityToken />
        <input type="hidden" name="_method" value="PUT" />
        <LightBgCard gap={6} p={5}>
          <Heading size="sm">Connect your G2 Account</Heading>

          <Stack fontSize="sm">
            <HStack alignItems="flex-start">
              <StepIcon step={1} />
              <Text>
                <Link
                  display="inline-flex"
                  gap={0.5}
                  alignItems="center"
                  href="https://my.g2.com/signup?login=true"
                  isExternal
                >
                  Log in to G2
                  <Icon as={IconArrowUpRight} boxSize={4} color="gray.500" />
                </Link>{' '}
              </Text>
            </HStack>
            <HStack alignItems="flex-start">
              <StepIcon step={2} />
              <Text>
                Navigate to the <strong>API Tokens</strong> section in the G2 dashboard. On the left sidebar, click on{' '}
                <strong>Integrations </strong> &gt; <strong>Api Tokens & Apps</strong>.
              </Text>
            </HStack>
            <HStack alignItems="flex-start">
              <StepIcon step={3} />
              <Text>
                Give your token a label and select Integration Partner as <strong>"My organization / Product"</strong>.
              </Text>
            </HStack>
            <HStack alignItems="flex-start">
              <StepIcon step={4} />
              <Text>Copy the Token and paste it here</Text>
            </HStack>
          </Stack>

          <FormControl id="token" isRequired>
            <FormLabel>Your G2 API Token</FormLabel>
            <Input
              name="app_instance_settings[api_key]"
              placeholder="Enter your G2 Api Key"
              defaultValue={props.settings['api_key'] ?? ''}
              type="password"
              autoComplete="off"
            />
          </FormControl>

          <Button
            size="sm"
            colorScheme="purple"
            type="submit"
            w="100%"
            isDisabled={!canEditProject || Boolean(props.plan_tier)}
          >
            Save
          </Button>
        </LightBgCard>
      </Box>
    </PageLayout>
  )
}
