import React from 'react'
import { useMutation } from 'react-query'
import { toast } from 'sonner'
import { post, put } from '../../lib/api'
import { projectPath } from '../ui/ProjectsContext'

interface Params {
  domain: string
  assigneeId: string
}

export function useClaimAccount() {
  const onSuccess = React.useCallback(() => {
    toast.success('You claimed this account!')
  }, [])

  const onError = React.useCallback(() => {
    toast.error('There was an issue claiming this account.')
  }, [])

  return useMutation(
    ({ domain, assigneeId }: Params) => {
      const path = projectPath(`/accounts/${domain}`)
      return put(path, {
        account: {
          status: 'prospect',
          assignee_id: assigneeId
        }
      })
    },
    {
      onSuccess,
      onError
    }
  )
}

interface AssignAccountParams {
  accountIds: string[]
  assigneeId: string | null
}

export function useAssignAccounts() {
  return useMutation(({ accountIds, assigneeId }: AssignAccountParams) => {
    const path = projectPath(`/accounts/assign`)
    return post(path, {
      account_ids: accountIds,
      assignee_id: assigneeId
    })
  })
}
