import { Tabs, TabList, Tab, Flex, Button } from '@chakra-ui/react'
import { IconCoin, IconPlus, IconUsers } from '@tabler/icons-react'
import React, { useMemo } from 'react'
import { BuildingIcon } from '../../../ui/icons'
import SquareIcon from '../../../ui/SquareIcon'
import { TextEllipsis } from '../../../ui/text-ellipsis'

interface Props {
  spaceId: string
  viewId?: string | null
  views: any[]
  onAddNewList?: () => void
}

const colors = {
  account: 'purple',
  profile: 'blue',
  opportunity: 'orange'
}

const icons = {
  account: BuildingIcon,
  profile: IconUsers,
  opportunity: IconCoin
}

export function ViewsTabList(props: Props) {
  const activeIndex = useMemo(() => {
    return props.views.findIndex((view) => view.id === props.viewId)
  }, [props.viewId, props.views])

  const contextPath = window.location.pathname.split('/views')[0]

  return (
    <Flex alignSelf="stretch" alignItems="center" gap={1.5}>
      <Flex alignSelf="stretch">
        <Tabs display="flex" variant="line" size="sm" marginBottom="-1px" index={activeIndex}>
          <TabList>
            {props.views.map((view) => (
              <Tab
                key={view.id}
                as={view.id !== props.viewId ? 'a' : undefined}
                // replace the base path except for the slug, to stay in the current context
                href={view.id !== props.viewId ? `${contextPath}/views/${view.slug}` : undefined}
              >
                <Flex flex="1 1 auto" maxW="200px" alignItems="center" gap={1} py={1}>
                  <SquareIcon
                    colorScheme={colors[view.kind] || 'gray'}
                    icon={icons[view.kind] || BuildingIcon}
                    iconSize="14px"
                    padding="2px"
                    {...(view.id !== props.viewId && { bg: 'transparent', filter: 'grayscale(90%)' })}
                  />
                  <TextEllipsis
                    tooltip={view.name}
                    flex="1 1 auto"
                    fontSize="13px"
                    fontWeight="medium"
                    color="gray.800"
                    isTruncated
                  >
                    {view.name}
                  </TextEllipsis>
                </Flex>
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </Flex>

      <Button
        size="sm"
        variant="ghost"
        color="gray.500"
        _hover={{ color: 'gray.800', bg: 'gray.100' }}
        _active={{ color: 'gray.800', bg: 'gray.100' }}
        leftIcon={<IconPlus size={14} />}
        iconSpacing={1}
        fontSize="13px"
        px={2}
        onClick={props.onAddNewList}
        className="add-lists-button"
      >
        Add list
      </Button>
    </Flex>
  )
}
