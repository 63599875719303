import { Flex, HStack, Icon, Progress, Stack, Text, Tooltip } from '@chakra-ui/react'
import { IconCircleCheck, IconRefreshAlert } from '@tabler/icons-react'
import React from 'react'
import { subscribeToChannel, SubscriptionEmitter } from '../../../../channels/generic_channel'
import dayjs from '../../../../lib/dayjs'
import { useCurrentProject } from '../../../ui/ProjectsContext'

interface Props {
  saved?: string
  started?: string
}

interface Status {
  status: 'done' | 'canceled' | 'processing'
  total: number
  processed: number
  conflicts: number
  duration_ms: number
  started_at: string
}

export function SyncStatus({ saved, started }: Props) {
  const project = useCurrentProject()
  const subscription = React.useRef<SubscriptionEmitter>()
  const [status, setStatus] = React.useState<Status | null>(null)

  React.useEffect(() => {
    setStatus(null)

    const timer = setTimeout(() => {
      subscription.current?.perform('send_status')
    }, 1000)

    return () => {
      window.clearTimeout(timer)
    }
  }, [saved, started])

  const onData = React.useCallback((message: any) => {
    if (message?.action === 'status' && message.data) {
      setStatus(message.data)
    }
  }, [])

  React.useEffect(() => {
    if (!saved || !project || subscription.current) {
      return
    }

    subscription.current = subscribeToChannel({
      channel: 'ScoringStatusChannel',
      project_slug: project.slug
    })

    subscription.current?.on('received', onData)

    return () => {
      subscription.current?.off('received', onData)
      subscription.current?.unsubscribe()
      subscription.current = undefined
    }
  }, [onData, project, saved])

  const startTime = status?.started_at || started
  if (!saved || !startTime) {
    return null
  }

  const lottaConflicts = status ? status.conflicts > status.processed : false
  const processed = (status?.processed || 0) + (status?.conflicts || 0)

  return (
    <Stack spacing={1}>
      <HStack>
        <Text fontSize="xs" color="gray.600">
          {status?.status === 'done' ? 'Scored' : 'Started'} {dayjs(startTime).calendar()}
        </Text>

        {status?.conflicts ? (
          <Tooltip
            label={
              lottaConflicts
                ? `There were ${status.conflicts.toLocaleString()} conflicts while updating accounts. ${status.processed.toLocaleString()} accounts have been scored. You can try again after waiting a few minutes, but they should get rescored automatically soon.`
                : `${status.processed.toLocaleString()} accounts have been scored. A few accounts couldn't be scored immediately, but we will rescore them automatically.`
            }
          >
            <Flex flex="none">
              <Icon
                as={lottaConflicts ? IconRefreshAlert : IconCircleCheck}
                color={lottaConflicts ? 'orange.500' : 'gray.500'}
                boxSize={4}
              />
            </Flex>
          </Tooltip>
        ) : status?.status === 'done' ? (
          <Flex flex="none">
            <Icon as={IconCircleCheck} color="gray.500" boxSize={4} />
          </Flex>
        ) : null}
      </HStack>

      {status?.status === 'done' && status.duration_ms ? (
        <Text fontSize="xs">Sync time: {dayjs.duration(status.duration_ms, 'milliseconds').humanize()}</Text>
      ) : status?.status === 'processing' ? (
        <Progress
          flex="none"
          width="100%"
          marginX="auto"
          colorScheme={status.conflicts > status.processed ? 'orange' : 'gray'}
          value={processed || 1}
          max={status.total || 1}
          size="xs"
          rounded="full"
          isAnimated
          hasStripe={processed < status.total}
          css={{
            '& div[role=progressbar]': {
              transition: processed > 0 ? 'width 150ms cubic-bezier(0, 0, 0.2, 1)' : undefined
            }
          }}
        />
      ) : null}
    </Stack>
  )
}
