import React from 'react'
import { Stack, FormControl, FormLabel, Select, InputGroup, InputLeftAddon, Input } from '@chakra-ui/react'
import { TimeTrigger } from '../time-trigger'
import { FollowRule } from '../../../notifications'
interface EmailSetupProps {
  delivery_rules?: FollowRule['delivery_rules']
}

function isCustomTo(to?: string) {
  if (!to) {
    return false
  }

  if (to === '{{team_member.email}}') {
    return false
  }

  const recipients = to.split(',')
  return recipients.filter((r) => r.includes('@')).length > 0
}

export function EmailSetup(props: EmailSetupProps) {
  const [channelOption, setChannelOption] = React.useState(
    isCustomTo(props.delivery_rules?.email?.to) ? 'custom' : 'dm'
  )

  return (
    <Stack spacing="4">
      <FormControl>
        <FormLabel>To</FormLabel>
        <Stack>
          <Select
            size="sm"
            rounded="md"
            value={channelOption}
            onChange={(e) => setChannelOption(e.target.value)}
            disabled={!props.delivery_rules?.email}
          >
            <option value="dm">Direct Message</option>
            <option value="custom">Custom Recipient List</option>
          </Select>

          {channelOption === 'custom' && (
            <>
              <InputGroup size="sm">
                <InputLeftAddon borderLeftRadius={'md'}>@</InputLeftAddon>
                <Input
                  rounded="md"
                  disabled={!props.delivery_rules?.email}
                  size="sm"
                  required={!!props.delivery_rules?.email}
                  bg="white"
                  placeholder="sales@example.com,sdrs@example.com"
                  name="follow_rule[delivery_rules][email][to]"
                  defaultValue={props.delivery_rules?.email?.to}
                />
              </InputGroup>
            </>
          )}
          {channelOption === 'dm' && (
            <input
              disabled={!props.delivery_rules?.email}
              type="hidden"
              name="follow_rule[delivery_rules][email][to]"
              value={'{{team_member.email}}'}
            />
          )}
        </Stack>
      </FormControl>
      <FormControl>
        <FormLabel>Subject</FormLabel>
        <Input
          name="follow_rule[delivery_rules][email][subject]"
          rounded="md"
          size="sm"
          bg="white"
          placeholder="Subject"
          defaultValue={props.delivery_rules?.email?.subject}
          disabled={!props.delivery_rules?.email}
        />
      </FormControl>
      <TimeTrigger
        disabled={!props.delivery_rules?.email}
        namespace="follow_rule[delivery_rules][email]"
        trigger={props.delivery_rules?.email?.trigger}
      />
    </Stack>
  )
}
