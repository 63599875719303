import { Box, Button, Collapse, FormControl, FormLabel, Heading, Icon, Stack, Text } from '@chakra-ui/react'
import { IconChevronDown, IconChevronRight, IconChevronUp } from '@tabler/icons-react'
import React, { useCallback } from 'react'
import { toast } from 'sonner'
import { postForm } from '../../../../lib/api'
import { useIntentSignals } from '../../../data/use-intent-signals'
import { projectPath } from '../../../ui/ProjectsContext'
import { slackAlertsPath } from '../../notifications/lib/path-helper'
import { SlackChannel, SlackMessagePreview, TriggerMultiSelect } from '../../slack_alerts/components/DefinitionForm'
import { IntegrationButton, IntegrationButtonProps } from './IntegrationButton'

interface Props {
  connected: boolean
  skip: () => void
  catalog: Record<'salesforce' | 'hubspot' | 'slack', IntegrationButtonProps>
}

export function SlackForm(props: Props) {
  const slackConnected = props.connected
  const [atMentions, setAtMentions] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const [previewing, setPreviewing] = React.useState(false)
  const { data } = useIntentSignals()

  const onCreate = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const data = new FormData(e.target as HTMLFormElement)
      setLoading(true)
      const path = slackAlertsPath()

      postForm(path, data)
        .then(() => {
          props.skip()
        })
        .catch((e) => {
          toast.error('Failed to create Slack Alert', { description: e.message })
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [props]
  )

  return (
    <Stack as={'form'} onSubmit={onCreate as any}>
      {!slackConnected && (
        <>
          <IntegrationButton
            {...props.catalog['slack']}
            returnTo={projectPath('/welcome?step=slack')}
            buttonProps={{
              size: 'md',
              colorScheme: 'purple',
              variant: 'solid'
            }}
            rightLogoProps={{
              color: 'white'
            }}
            logoProps={{
              boxSize: 4
            }}
          />
          <Button
            variant="ghost"
            flex="none"
            size="md"
            marginTop={8}
            onClick={props.skip}
            rightIcon={<IconChevronRight size={12} />}
          >
            Connect Later
          </Button>
        </>
      )}

      {slackConnected && data && data.definitions && data.definitions.length === 0 && (
        <Stack justifyContent={'center'}>
          <Heading size="sm">🎉 Slack Connected!</Heading>
          <Text fontSize="sm" color="gray.600" marginY={6}>
            You're all set with Slack. You can now get Intent Signals flowing into Slack channels and enable your Sales
            Team to react to customer intent in real time.
          </Text>
          <Stack pt="4">
            <Button colorScheme={'purple'} onClick={props.skip}>
              Continue
            </Button>
          </Stack>
        </Stack>
      )}

      {slackConnected && data && data.definitions && data.definitions.length > 0 && (
        <Stack spacing="6">
          <Heading size="sm">Send your Intent Signals into Slack</Heading>

          <Text fontSize="sm" color="gray.600" marginY={6}>
            Get your Intent Signals flowing into Slack and enable your Sales Team to react to customer intent in real
            time.
          </Text>

          <Stack spacing="1">
            {data?.definitions && (
              <TriggerMultiSelect
                skipCreation
                disabled={loading}
                selectedSignalIds={data.definitions.map((d) => d.id!) ?? []}
                selectedAudienceKind={'profile'}
              />
            )}
          </Stack>

          <FormControl p="2">
            <FormLabel>Choose a Slack channel</FormLabel>
            <SlackChannel
              compact
              atMentions={atMentions}
              setAtMentions={setAtMentions}
              channelRouting={'channel'}
              setChannelRouting={() => {}}
              apps={{}}
            />
          </FormControl>

          <Box>
            <Button
              size="xs"
              variant={'link'}
              textDecoration="none"
              colorScheme={'purple'}
              style={{
                textDecoration: 'none'
              }}
              onClick={() => setPreviewing(!previewing)}
              rightIcon={<Icon as={previewing ? IconChevronUp : IconChevronDown} size="12px" />}
            >
              {previewing ? 'Hide' : 'See'} sample message
            </Button>
            <Collapse in={previewing} animateOpacity>
              <Box py={2}>
                <SlackMessagePreview
                  mentions
                  title={'Pricing Page Visit'}
                  variables={{
                    actor: 'Someone from Dunder Mifflin',
                    account: {
                      name: 'Dunder Mifflin',
                      domain: 'dundermifflin.com',
                      logo_url:
                        'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Dunder_Mifflin%2C_Inc.svg/480px-Dunder_Mifflin%2C_Inc.svg.png'
                    },
                    signal: {
                      name: 'Pricing Page Visit'
                    }
                  }}
                />
              </Box>
            </Collapse>
          </Box>

          <Stack pt="4">
            <Button colorScheme={'purple'} type="submit" isLoading={loading}>
              Continue
            </Button>
            <Button variant={'ghost'} onClick={props.skip} disabled={loading}>
              Setup later
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
