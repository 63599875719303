import React from 'react'
import { Box, HStack, Icon, Link, Stack, Text } from '@chakra-ui/react'
import { IconAlertCircleFilled } from '@tabler/icons-react'
import { projectPath } from './ProjectsContext'

export default function PlanTierAlert({ planTier, description }: { planTier: string; description?: string }) {
  return (
    <Box background="blue.50" padding={4} borderRadius="5px">
      <HStack>
        <Icon as={IconAlertCircleFilled} boxSize={6} color="blue.500" />
        <Stack fontSize="sm" spacing={0}>
          <Text fontWeight="bold">{planTier} Only</Text>
          <Text fontSize="sm" color="gray.600">
            {description || 'Upgrade your plan to access this feature'}
          </Text>
        </Stack>

        <Link
          href={projectPath('/settings/plans')}
          color="blackAlpha.700"
          variant="ghost"
          fontSize="xs"
          background="white"
          padding={1.5}
          borderRadius="5px"
          style={{ marginLeft: 'auto' }}
        >
          See Plans
        </Link>
      </HStack>
    </Box>
  )
}
