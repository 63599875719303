import { Flex, FlexProps, Icon } from '@chakra-ui/react'
import { IconInfoCircle } from '@tabler/icons-react'
import React from 'react'

interface InfoBoxProps extends FlexProps {
  icon?: React.ReactNode | false
  colorScheme?: string
}

export function InfoBox({ colorScheme = 'gray', icon, children, ...props }: React.PropsWithChildren<InfoBoxProps>) {
  return (
    <Flex
      fontSize="xs"
      color={colorScheme === 'gray' ? 'gray.700' : `${colorScheme}.800`}
      bg={`${colorScheme}.50`}
      border="1px solid"
      borderColor={`${colorScheme}.200`}
      py={3}
      px={4}
      gap={3}
      alignItems="center"
      rounded="lg"
      {...props}
    >
      {icon ?? <Icon as={IconInfoCircle} flex="none" boxSize={5} color={`${colorScheme}.500`} />}
      {children}
    </Flex>
  )
}
