import {
  Badge,
  Box,
  Button,
  Checkbox,
  Code,
  Divider,
  Flex,
  FlexProps,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Link,
  ListItem,
  OrderedList,
  Progress,
  Stack,
  Text,
  UnorderedList
} from '@chakra-ui/react'
import { IconCheck, IconCircleCheck, IconExternalLink, IconInfoSquare, IconMailForward } from '@tabler/icons-react'
import pick from 'lodash/pick'
import React, { FormEvent, useState } from 'react'
import { post } from '../../../../lib/api'
import { Project } from '../../../../types/Project'
import {
  HealthChecklist,
  Skips,
  useInstrumentationContext,
  WebsiteSettings
} from '../../../context/instrumentation-context'
import { LightBgCard } from '../../../ui/Card'
import { CodeBlock } from '../../../ui/CodeBlock'
import { usePermission } from '../../../ui/PermissionsContext'
import { projectPath, useCurrentProject } from '../../../ui/ProjectsContext'
import Pulse from '../../../ui/Pulse'
import { useCurrentUser, User } from '../../../ui/UserContext'
import { Toggle } from '../../accounts/components/Toggle'
import { snippet } from '../../sdk_settings'

interface TodoProps {
  title: React.ReactNode
  description: React.ReactNode
  complete?: boolean
  instructions?: React.ReactNode
  cardProps?: FlexProps
  status?: React.ReactNode
  skipped?: boolean
  onSkip: (val: boolean) => void
}

function Todo(props: TodoProps) {
  return (
    <LightBgCard
      position="relative"
      borderLeftWidth={'thick'}
      borderLeftColor={props.skipped ? 'gray.300' : props.complete ? 'green.300' : 'orange.300'}
      shadow="sm"
      px="4"
      bg={props.skipped ? 'gray.50' : 'unset'}
      {...props.cardProps}
    >
      <Toggle title={<Heading size="xs">{props.title}</Heading>}>
        <Stack spacing={'4'} pt="4">
          {props.description}
          <Box w="100%" pt="4">
            {props.instructions}
          </Box>

          <FormControl as={HStack} justifyContent={'flex-end'}>
            <Checkbox
              name="skip"
              size="sm"
              fontSize={'xs'}
              color="GrayText"
              colorScheme={props.skipped ? 'gray' : 'purple'}
              onChange={(e) => props.onSkip(e.target.checked)}
              isChecked={props.skipped}
            >
              Skip this step
            </Checkbox>
          </FormControl>
        </Stack>
      </Toggle>
      <HStack flex="1" justifyContent={'flex-end'} position="absolute" right="8">
        {props.status && props.status}
        {!props.status && (
          <Stack spacing="0">
            {!props.skipped && (
              <HStack>
                <Text fontSize="sm" color={props.complete ? 'green.400' : 'orange.400'}>
                  {props.complete ? 'Connected!' : 'Waiting for events'}
                </Text>
                <Pulse size={2.5} background={props.complete ? 'green.300' : 'orange.300'} />
              </HStack>
            )}
            {props.skipped && (
              <Text fontSize="sm" color={'gray.600'}>
                Skipped
              </Text>
            )}
          </Stack>
        )}
      </HStack>
    </LightBgCard>
  )
}

const identifySnippet = `
/*
* Use this snippet on a logged in page.
*
* You can use the identify function to send the user's email to Koala,
* so your sales team can see user activity in Koala.
*
* note: currentUser is where you store your user content.
*/

ko.identify({ email: currentUser.email, name: 'Joey' })
`.trim()

interface Props {
  checks: HealthChecklist
  website_settings: WebsiteSettings | null
  website_skips: Skips | null
}

function toHost(baseDomain: string) {
  try {
    const url = new URL(`https://${baseDomain.replace(/https?:\/\//, '')}`)
    return url.host
  } catch (e) {
    return undefined
  }
}

interface WebsiteConfigProps {
  website_settings: WebsiteSettings | null
  skips: Skips
  onSkip: (key: keyof Skips, val: boolean) => void
  onUpdate: () => void
  canEditProject: boolean
}

function WebsiteEntry(props: {
  title: string
  val: string
  placeholder?: string
  onChange: (val: string) => void
  onSkip: (val: boolean) => void
  skipped: boolean
  canEdit: boolean
}) {
  return (
    <FormControl>
      <HStack justifyContent={'space-between'}>
        <FormLabel m="0" pb="1">
          {props.title}
        </FormLabel>
        <Checkbox
          color={'GrayText'}
          size="sm"
          disabled={!props.canEdit}
          onChange={(e) => props.onSkip(e.target.checked)}
          isChecked={props.skipped}
        >
          Skip
        </Checkbox>
      </HStack>
      <HStack>
        <Input
          value={props.val}
          onChange={(v) => {
            props.onChange(v.target.value)
          }}
          required={!props.skipped}
          disabled={props.skipped || !props.canEdit}
          placeholder={props.placeholder}
          bg="white"
          fontSize={'sm'}
        />
      </HStack>
    </FormControl>
  )
}

function validWebsiteConfig(settings: WebsiteSettings, skips: Skips) {
  if (!settings) {
    return false
  }

  const nonSkipped = Object.keys(settings).filter((k) => !skips[`${k}_website` as keyof Skips])
  const nonSkippedSettings = pick(settings, nonSkipped)
  const validURLs = Object.values(nonSkippedSettings).filter((val) => toHost(val))
  const numEntries = Object.keys(nonSkippedSettings).length
  return validURLs.length === numEntries
}

function validTasksConfig(checks: HealthChecklist, skips: Skips) {
  const nonSkipped = Object.keys(checks).filter((k) => !skips[k as keyof Skips])
  const nonSkippedSettings = pick(checks, nonSkipped)
  return Object.values(nonSkippedSettings).every((val) => val)
}

function WebsiteConfig(props: WebsiteConfigProps) {
  const [settings, setSettings] = useState(props.website_settings)

  const [baseDomain, setBaseDomain] = useState<string>(props.website_settings?.base ?? '')
  const [app, setApp] = useState<string>(props.website_settings?.app ?? '')
  const [docs, setDocs] = useState<string>(props.website_settings?.docs ?? '')
  const [blog, setBlog] = useState<string>(props.website_settings?.blog ?? '')
  const [saving, setSaving] = useState<boolean>(false)

  const host = toHost(baseDomain)

  const onSubmit = React.useCallback(
    async (e: FormEvent) => {
      e.preventDefault()

      setSaving(true)
      post<WebsiteSettings>(projectPath('/instrumentation/website'), {
        website_settings: {
          base: baseDomain,
          app,
          docs,
          blog
        }
      })
        .then((settings) => {
          setSettings(settings)
          setSaving(false)
          props.onUpdate()
        })
        .catch(() => {
          setSaving(false)
        })
    },
    [baseDomain, app, docs, blog, props]
  )

  const complete = React.useMemo(() => {
    if (!settings) {
      return false
    }

    return validWebsiteConfig(settings, props.skips)
  }, [settings, props.skips])

  const progress = React.useMemo(() => {
    const skips = props.skips
    if (!settings) {
      return [0, 4]
    }

    const nonSkipped = Object.keys(settings).filter((k) => !skips[`${k}_website` as keyof Skips])
    const nonSkippedSettings = pick(settings, nonSkipped)
    const validURLs = Object.values(nonSkippedSettings).filter((val) => toHost(val))
    const numEntries = Object.keys(nonSkippedSettings).length
    return [validURLs.length, numEntries]
  }, [settings, props.skips])

  return (
    <Toggle
      title={
        <HStack justifyContent={'space-between'} w="100%">
          <Heading size="sm">Website Configuration</Heading>
          <Box>
            {!complete && (
              <Progress
                w="100px"
                colorScheme={progress[0] < progress[1] ? 'yellow' : 'green'}
                value={progress[0]}
                max={progress[1]}
                rounded="md"
              />
            )}

            {complete && (
              <Badge colorScheme={'green'}>
                <HStack>
                  <IconCheck size="14" />
                  <Text pt="0.5">Complete</Text>
                </HStack>
              </Badge>
            )}
          </Box>
        </HStack>
      }
    >
      <Stack
        bg="gray.50"
        borderWidth="1px"
        rounded="md"
        w="100%"
        p="6"
        fontSize={'sm'}
        spacing="8"
        shadow={'sm'}
        as={'form'}
        onSubmit={onSubmit}
        my="6"
      >
        <Stack>
          <Text>
            Enter your website URLs, so we can help you understand which ones are instrumented and alert your admins if
            instrumentation breaks.
          </Text>
        </Stack>

        <Stack w="100%" flex="2" spacing="4">
          <FormControl>
            <HStack justifyContent={'space-between'}>
              <FormLabel m="0" pb="1">
                Marketing Website URL
              </FormLabel>
            </HStack>
            <HStack>
              <Input
                required
                value={baseDomain}
                onChange={(v) => {
                  setBaseDomain(v.target.value)
                }}
                placeholder="https://yourwebsite.com"
                bg="white"
                fontSize={'sm'}
              />
            </HStack>
          </FormControl>
          <WebsiteEntry
            onSkip={(val) => props.onSkip('docs_website', val)}
            skipped={props.skips.docs_website}
            val={docs}
            placeholder={`https://${host ?? 'website.com'}/docs`}
            onChange={setDocs}
            title="Docs Website URL"
            canEdit={props.canEditProject}
          />
          <WebsiteEntry
            onSkip={(val) => props.onSkip('blog_website', val)}
            skipped={props.skips.blog_website}
            val={blog}
            placeholder={`https://${host ?? 'website.com'}/blog`}
            onChange={setBlog}
            title="Blog Website URL"
            canEdit={props.canEditProject}
          />
          <WebsiteEntry
            onSkip={(val) => props.onSkip('app_website', val)}
            skipped={props.skips.app_website}
            val={app}
            placeholder={`https://app.${host ?? 'website.com'}`}
            onChange={setApp}
            title="App URL"
            canEdit={props.canEditProject}
          />
        </Stack>

        <Flex>
          <Button size="sm" colorScheme={'purple'} type="submit" isLoading={saving} disabled={!props.canEditProject}>
            Save
          </Button>
        </Flex>
      </Stack>
    </Toggle>
  )
}

const inviteMessage = (project: Project, user: User) => `
Hello, <engineer>,

I would like your help finishing our Koala Instrumentation for ${project?.name ?? 'our company'}.
You can access their developer checklist once you log in to Koala on https://app.getkoala.com/signup.

Thanks,
${user?.name}
`

export function ProjectChecklist(props: Props) {
  const project = useCurrentProject()
  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })
  const { fetch, instrumentation, progress: todoProgress, setSkip, skips, loading } = useInstrumentationContext()
  const user = useCurrentUser()

  // This is safe because we seeded the project with a default instrumentation
  const checks = instrumentation?.checks as HealthChecklist

  const complete = React.useMemo(() => {
    if (!props.website_settings || !skips) {
      return false
    }

    const validWebsite = validWebsiteConfig(props.website_settings, skips)
    const validTasks = validTasksConfig(checks, skips)

    return validWebsite && validTasks
  }, [props.website_settings, skips, checks])

  if (loading || !skips || !checks) {
    return null
  }

  return (
    <Stack spacing="8" w="100%" pb="2">
      {!canEditProject && (
        <Box bg="orange.50" p="6" borderWidth="1px" borderColor="orange.200" rounded="md">
          <HStack alignItems="flex-start">
            <Icon as={IconInfoSquare} boxSize={6} marginTop={1} />
            <Stack spacing={6} alignItems="flex-start">
              <Stack spacing={1}>
                <Heading size="md">Not a developer?</Heading>
                <Text fontSize="sm">
                  Invite your favorite developer or Google Tag Manager admin to help you finish instrumenting Koala.
                </Text>
              </Stack>
              <Button
                as={Link}
                size="sm"
                colorScheme="purple"
                leftIcon={<IconMailForward size={16} />}
                href={`mailto:?subject=Koala Instrumentation Setup&body=${encodeURIComponent(
                  inviteMessage(project!, user as unknown as User)
                )}`.trim()}
              >
                Invite a Developer
              </Button>
            </Stack>
          </HStack>
        </Box>
      )}
      <Stack>
        <Heading size="md">👋 Welcome to Koala!</Heading>
        <Stack spacing="6" bg="gray.50" p="4" borderWidth="1px" rounded="md">
          <Box fontSize="sm">
            <Link target="_blank" href="https://getkoala.com/">
              Koala
            </Link>{' '}
            surfaces first-party intent to the Sales team. Sales teams use this for two broad use-cases:
            <OrderedList p="4">
              <ListItem>to ensure that they are always up-to-speed on their prospects' interests and</ListItem>
              <ListItem>to find net new logos to start working.</ListItem>
            </OrderedList>
            Believe it or not, there are many visitors on your marketing site website right now that are not even on
            Sales' radar. This instrumentation closes that gap.
          </Box>
          <Text fontSize="sm">
            Typically the most important intent for Sales falls into four broad categories — visitors on the marketing
            site, visitors reading the docs, visitors reading the blog (or other content), and self-service customers
            using the app.
          </Text>
          <Text fontSize="sm">
            This checklist will walk you through setting up an SDK to do data collection for each of these categories.
            The SDK only works client-side, but we've kept it very light (~14kb), so it shouldn't have an outsized
            impact on app performance, especially if you install the SDK lower in the page.
          </Text>
          <Text fontSize="sm">
            The most helpful thing you can do is to ensure that as much traffic is deanonymized as possible.
            Implementation-wise, this means ensuring that as many identify() calls are happening as possible. Koala's
            SDK does the heavy lifting around session management and identity merging, so all of the context about the
            session when it was anonymous will become attached to the known user as soon as you identify them.
          </Text>
        </Stack>
      </Stack>

      <Stack>
        <HStack>
          <Heading size="sm">Setup</Heading>
          <Text fontSize="sm">
            {todoProgress[0]}/{todoProgress[1]} tasks completed
          </Text>
        </HStack>
        <Progress
          colorScheme={todoProgress[0] < todoProgress[1] ? 'yellow' : 'green'}
          value={todoProgress[0]}
          max={todoProgress[1]}
          rounded="md"
        />
      </Stack>

      <Divider />

      <WebsiteConfig
        canEditProject={canEditProject}
        onUpdate={fetch}
        website_settings={props.website_settings}
        skips={skips}
        onSkip={setSkip}
      />

      <Toggle
        defaultIsOpen
        title={
          <HStack w="100%" justifyContent={'space-between'}>
            <Heading size="sm">Implementation Checklist</Heading>
            <Box>
              {!complete && (
                <Progress
                  w="100px"
                  colorScheme={todoProgress[0] < todoProgress[1] ? 'yellow' : 'green'}
                  value={todoProgress[0]}
                  max={todoProgress[1]}
                  rounded="md"
                />
              )}

              {complete && (
                <Badge colorScheme={'green'}>
                  <HStack>
                    <IconCheck size="14" />
                    <Text pt="0.5">Complete</Text>
                  </HStack>
                </Badge>
              )}
            </Box>
          </HStack>
        }
      >
        <Stack spacing="8" my="6">
          <Todo
            onSkip={(val) => setSkip('data_flowing', val)}
            skipped={skips.data_flowing}
            complete={checks.data_flowing}
            title="Marketing Website Pixel"
            description={
              <Stack color="GrayText" fontSize={'sm'}>
                <Text>
                  Koala tracks all intent happening from your Marketing Website. Typically, this can be done using
                  Google Tag Manager, Webflow, Segment, or whatever you use for pixel management.
                </Text>
                <Text>You can also install this code directly.</Text>
              </Stack>
            }
            instructions={
              <Stack fontSize={'sm'} spacing="4">
                <Heading size="xs">Code Snippet</Heading>
                <Text>Paste the following snippet on your Marketing Website:</Text>
                <CodeBlock language="markup" code={snippet(project!)} />
                <Link
                  display="flex"
                  alignItems="center"
                  gap={1}
                  isExternal
                  color="purple.500"
                  href="https://getkoala.com/docs/integrations/google-tag-manager"
                >
                  Google Tag Manager / Webflow docs
                  <IconExternalLink size={14} />
                </Link>
              </Stack>
            }
          />

          <Todo
            complete={checks.app}
            onSkip={(val) => setSkip('app', val)}
            skipped={skips.app}
            title={
              <HStack>
                <Text>App SDK</Text>
                {!props.checks.app && <Badge colorScheme="orange">Important</Badge>}
              </HStack>
            }
            description={
              <Stack color="GrayText" fontSize={'sm'}>
                <Text>
                  In-app instrumentation allows your team to follow intent activity after customers are logged in to
                  your website. If you are using Segment, the easiest way to turn this on is adding Koala as a
                  destination:{' '}
                  <a href="https://getkoala.com/docs/integrations/segment" target="blank">
                    https://getkoala.com/docs/integrations/segment
                  </a>
                </Text>
                <Text>Otherwise, you can install this code directly.</Text>
              </Stack>
            }
            instructions={
              <Stack fontSize={'sm'} spacing="4">
                <Heading size="xs">Code Snippet</Heading>
                <Text>Paste the following snippet in your App codebase</Text>
                <CodeBlock language="markup" code={snippet(project!)} />
                <Link
                  display="flex"
                  alignItems="center"
                  gap={1}
                  isExternal
                  color="purple.500"
                  href="https://getkoala.com/docs/integrations/google-tag-manager"
                >
                  Google Tag Manager / Webflow docs
                  <IconExternalLink size={14} />
                </Link>
              </Stack>
            }
          />

          <Todo
            onSkip={(val) => setSkip('docs', val)}
            skipped={skips.docs}
            complete={checks.docs}
            title="Docs Website Pixel"
            description={
              <Stack color="GrayText" fontSize={'sm'}>
                <Text color="GrayText" fontSize={'sm'}>
                  Lots of good intent happens on any technical docs pages you might have. Typically, this can be done
                  using Google Tag Manager, Webflow, Segment, or whatever you use for pixel management.
                </Text>
                <Text>You can also install this code directly.</Text>
              </Stack>
            }
            instructions={
              <Stack fontSize={'sm'} spacing="4">
                <Heading size="xs">Code Snippet</Heading>
                <Text>Paste the following snippet on your Docs Website:</Text>
                <CodeBlock language="markup" code={snippet(project!)} />
                <Link
                  display="flex"
                  alignItems="center"
                  gap={1}
                  isExternal
                  color="purple.500"
                  href="https://getkoala.com/docs/integrations/google-tag-manager"
                >
                  Google Tag Manager / Webflow docs
                  <IconExternalLink size={14} />
                </Link>
              </Stack>
            }
          />

          <Todo
            onSkip={(val) => setSkip('blog', val)}
            skipped={skips.blog}
            complete={checks.blog}
            title="Blog Website Pixel"
            description={
              <Stack color="GrayText" fontSize={'sm'}>
                <Text>
                  Blogs and other content rich pages are a great way to collect intent signals. Typically, this can be
                  done using Google Tag Manager, Webflow, Segment, or whatever you use for pixel management.
                </Text>
                <Text>You can also install this code directly.</Text>
              </Stack>
            }
            instructions={
              <Stack fontSize={'sm'} spacing="8">
                <Heading size="xs">Code Snippet</Heading>
                <Text>Paste the following snippet on your Blog Website:</Text>
                <CodeBlock language="markup" code={snippet(project!)} />
                <Link
                  display="flex"
                  alignItems="center"
                  gap={1}
                  isExternal
                  color="purple.500"
                  href="https://getkoala.com/docs/integrations/google-tag-manager"
                >
                  Google Tag Manager / Webflow docs
                  <IconExternalLink size={14} />
                </Link>
              </Stack>
            }
          />

          <Todo
            onSkip={(val) => setSkip('identities', val)}
            skipped={skips.identities}
            complete={checks.identities}
            title="Identify Calls"
            description={
              <Stack color="GrayText" fontSize={'sm'} spacing="4">
                <Text>
                  Identify Calls allow you to tie an anonymous visit from your website to a Customer or Account.
                </Text>
                <Text>
                  Instrumenting identify calls when an email is present will empower your Sales Team to prospect an
                  Account or Contact based on their full journey on your website, docs, and app.
                </Text>
                <Heading size="xs">You should instrument identify in the following ways:</Heading>
                <UnorderedList px="8" spacing="2">
                  <ListItem>Adding the Koala Pixel to your Log in and Sign Up pages</ListItem>
                  <ListItem>Adding the Koala Pixel to your web App</ListItem>
                  <ListItem>
                    Instrumenting{' '}
                    <Code bg="purple.50" px="2">
                      {'ko.identify(email)'}
                    </Code>{' '}
                    calls whenever you have the users' email in the browser.
                  </ListItem>
                </UnorderedList>
              </Stack>
            }
            instructions={
              <Stack fontSize={'sm'} spacing="2">
                <Heading size="xs">Code Sample</Heading>
                <Text>Identify your visitors after they provide an email, or in a logged in page</Text>
                <CodeBlock language="javascript" code={identifySnippet} />
              </Stack>
            }
          />

          <Todo
            onSkip={(val) => setSkip('anonymous_companies', val)}
            skipped={skips.anonymous_companies}
            complete={checks.anonymous_companies}
            title="Reveal Anonymous Companies"
            description={
              <Stack color="GrayText" fontSize={'sm'}>
                <Text>
                  Tie IP addresses to a Company using Clearbit Reveal. You'll need a Clearbit Reveal key for this, or we
                  can provision a limited Clearbit Reveal key on your behalf.
                </Text>
                <Text>
                  This will allow your Sales Team to see the Company associated to anonymous visitors on your website.
                </Text>
              </Stack>
            }
            instructions={
              <Stack fontSize={'sm'} spacing="8">
                <Button
                  size="sm"
                  colorScheme={props.checks.anonymous_companies ? 'gray' : 'purple'}
                  variant={props.checks.anonymous_companies ? 'outline' : 'solid'}
                  leftIcon={
                    props.checks.anonymous_companies ? <IconCircleCheck size="16" /> : <IconExternalLink size="16" />
                  }
                  as={Link}
                  href={projectPath('/apps/clearbit')}
                >
                  {props.checks.anonymous_companies ? 'Clearbit App Enabled' : 'Enable Clearbit App'}
                </Button>
              </Stack>
            }
          />
        </Stack>
      </Toggle>
    </Stack>
  )
}
