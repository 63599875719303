import {
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Stack,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  TagLeftIcon,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr
} from '@chakra-ui/react'
import {
  IconAt,
  IconBuilding,
  IconCopy,
  IconHash,
  IconMailCheck,
  IconMailQuestion,
  IconMailSearch,
  IconMailX,
  IconMapPin,
  IconPhoneCheck,
  IconPhonePlus,
  IconPhoneX,
  IconSparkles,
  IconUser
} from '@tabler/icons-react'
import { uniq } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'sonner'
import { post } from '../../../lib/api'
import { Apps } from '../../../types/App'
import { Prospect } from '../../data/use-prospects'
import Avatar from '../../ui/Avatar'
import { HelpTooltip } from '../../ui/HelpTooltip'
import { HoverCard } from '../../ui/HoverCard'
import { KoalaIcon, LinkedinBoxIcon, LinkedinIcon, SalesforceIcon } from '../../ui/icons'
import { HubSpotIcon } from '../../ui/icons/HubspotIcons'
import { projectPath } from '../../ui/ProjectsContext'
import { TextEllipsis } from '../../ui/text-ellipsis'
import { humanize } from '../accounts/facets/filter-cloud'
import { profilePath } from '../profiles/lib/path'
import { ActionMenu } from './action-menu'
import { Persona } from './personas'

function wrap(input: string | string[] | React.ReactChild[] | React.ReactChild) {
  if (Array.isArray(input)) {
    return uniq(input)
  }

  return [input]
}

export function ProspectsTable({
  prospects,
  domain,
  isLoading,
  apps,
  actions,
  persona,
  context
}: {
  prospects: Prospect[]
  persona?: Persona
  domain: string
  isLoading?: boolean
  apps: Apps
  actions?: boolean
  context?: 'explore' | 'account_page' | 'triangulation'
}) {
  const [localProspects, setProspects] = useState<Prospect[]>(prospects)
  const [emailLoadingState, setEmailLoadingState] = useState({})
  const [phoneLoadingState, setPhoneLoadingState] = useState({})
  const [verificationLoadingState, setVerificationLoadingState] = useState({})

  useEffect(() => {
    setProspects(prospects)
  }, [prospects])

  const onVerifyEmail = useCallback((prospect: Prospect) => {
    setVerificationLoadingState((prev) => ({ ...prev, [prospect.id]: true }))
    post<{ prospect: Prospect }>(projectPath(`/prospects/${prospect.id}/verify-email`))
      .then((res) => {
        if (res.prospect.verification_status === 'verified') {
          toast.success('Email verified')
        } else {
          toast.warning('Email could not be verified')
        }

        setProspects((prev) => {
          return prev.map((p) => {
            if (p.id === prospect.id) {
              return {
                ...p,
                verification_status: res.prospect.verification_status
              }
            }
            return p
          })
        })
      })
      .catch(() => {
        toast.error('Failed to verify email')
      })
      .finally(() => {
        setVerificationLoadingState((prev) => ({ ...prev, [prospect.id]: false }))
      })
  }, [])

  const onUnlockPhone = useCallback(
    (prospect: Prospect) => {
      setPhoneLoadingState((prev) => ({ ...prev, [prospect.id]: true }))
      post<{ prospect: Prospect }>(projectPath(`/prospects/${prospect.id}/unlock-phone`), {
        context
      })
        .then((res) => {
          setProspects((prev) => {
            return prev.map((p) => {
              if (p.id === prospect.id) {
                return {
                  ...p,
                  ...res.prospect
                }
              }
              return p
            })
          })

          if (res.prospect.phone_state === 'unlocked') {
            toast.success('Phone number unlocked')
          } else if (res.prospect.phone_state === 'not_found') {
            toast.warning('Phone number not found')
          }
        })
        .catch(() => {
          toast.error('Failed to unlock phone number')
        })
        .finally(() => {
          setPhoneLoadingState((prev) => ({ ...prev, [prospect.id]: false }))
        })
    },
    [context]
  )

  const onUnlock = useCallback(
    (prospect: Prospect) => {
      setEmailLoadingState((prev) => ({ ...prev, [prospect.id]: true }))
      let toastId: string | number | undefined

      const timer = setTimeout(() => {
        toastId = toast('Searching for prospect data against multiple data sources. This may take a few seconds...', {
          duration: 10_000,
          dismissible: true
        })
      }, 2500)

      post<{ prospect: Prospect }>(projectPath(`/prospects/${prospect.id}/unlock`), {
        context
      })
        .then((res) => {
          clearTimeout(timer)

          setProspects((prev) => {
            return prev.map((p) => {
              if (p.id === prospect.id) {
                return {
                  ...p,
                  ...res.prospect
                }
              }
              return p
            })
          })

          if (toastId) {
            toast.dismiss(toastId)
            toastId = undefined
          }

          if (res.prospect.email) {
            toast.success('Prospect unlocked')
          } else {
            toast.warning(`Could not find email for ${res.prospect.first_name} ${res.prospect.last_name}`)
          }
        })
        .catch((err) => {
          toast.error("Couldn't find email for prospect: " + err.message)
        })
        .finally(() => {
          clearTimeout(timer)
          if (toastId) {
            toast.dismiss(toastId)
            toastId = undefined
          }

          setEmailLoadingState((prev) => ({ ...prev, [prospect.id]: false }))
        })
    },
    [context]
  )

  const hasMetadata = useMemo(() => {
    return localProspects.find((p) => !!p['reason'] || !!p['highlight'])
  }, [localProspects])

  return (
    <TableContainer w="100%" pointerEvents={isLoading ? 'none' : 'auto'} opacity={isLoading ? 0.8 : 1}>
      <Table size="sm" fontSize="xs">
        <Tbody>
          {localProspects.map((prospect) => {
            const isLocked = prospect.unlock_state === 'locked' || prospect.unlock_state === null
            const isPhoneLocked = prospect.phone_state === 'locked' || prospect.phone_state === null
            const emailAvailable =
              prospect.email && (prospect.unlock_state === 'unlocked' || prospect.unlock_state === 'auto-unlocked')
            const phoneAvailable = prospect.phone_numbers?.mobile && prospect.phone_state === 'unlocked'

            return (
              <Tr key={prospect.id}>
                <Td fontSize="sm" px={0} py={3}>
                  <HStack alignItems="flex-start" spacing="3" flex="1 1 auto" minW="250px" isTruncated>
                    <Avatar
                      src={projectPath(`/prospects/${prospect.id}/avatar`)}
                      name={prospect.first_name + ' ' + prospect.last_name}
                      size="40px"
                    />

                    <Stack spacing="0" minWidth="100px">
                      <HStack spacing={1} mb={0.5}>
                        <Text color="gray.800" fontWeight="semibold" fontSize="sm" lineHeight="20px">
                          {prospect.first_name} {prospect.last_name}
                        </Text>

                        <ButtonGroup size="tiny" variant="ghost" spacing={0.5} py="0">
                          {prospect.linkedin_url && (
                            <IconButton
                              as={Link}
                              icon={<LinkedinBoxIcon color={'linkedin.700'} boxSize="18px" />}
                              aria-label="View LinkedIn Profile"
                              href={`https://${prospect.linkedin_url.replace(/https?:\/\//, '')}`}
                              isExternal
                            />
                          )}

                          {(prospect.salesforce_contact_cache || prospect.salesforce_lead_cache) && (
                            <Tooltip label="View in Salesforce">
                              <IconButton
                                as={Link}
                                icon={<SalesforceIcon color="salesforce" boxSize="18px" />}
                                aria-label="View in Salesforce"
                                href={
                                  prospect.salesforce_contact_cache?.permalink ??
                                  prospect.salesforce_lead_cache?.permalink
                                }
                                isExternal
                              />
                            </Tooltip>
                          )}

                          {prospect.hubspot_contact_cache &&
                            !(prospect.salesforce_contact_cache || prospect.salesforce_lead_cache) && (
                              <Tooltip label="View in HubSpot">
                                <IconButton
                                  as={Link}
                                  icon={<HubSpotIcon color="hubspot" boxSize="18px" />}
                                  aria-label="View in HubSpot"
                                  href={prospect.hubspot_contact_cache?.permalink}
                                  isExternal
                                />
                              </Tooltip>
                            )}

                          {prospect.profile && (
                            <Tooltip label="View activity in Koala">
                              <IconButton
                                as={Link}
                                icon={<KoalaIcon color="purple.500" boxSize="18px" />}
                                aria-label="View activity in Koala"
                                href={profilePath(prospect.profile)}
                                isExternal
                              />
                            </Tooltip>
                          )}
                        </ButtonGroup>
                      </HStack>

                      <TextEllipsis tooltip maxW="100%" fontSize="13px" lineHeight="20px" color="gray.800">
                        {prospect.title}
                      </TextEllipsis>

                      <HStack spacing="0" fontSize="13px" lineHeight="20px" color="gray.500">
                        <Text>{prospect.city}</Text>
                        <Text>
                          {prospect.city ? ', ' : ''}
                          {prospect.region ? `${prospect.region}, ` : ''}
                          {prospect.country_code}
                        </Text>
                        {!prospect.country_code && prospect.country && <Text pl="1.5">{prospect.country}</Text>}
                      </HStack>
                    </Stack>
                  </HStack>
                </Td>

                {hasMetadata && (
                  <Td fontSize="sm">
                    {
                      <HoverCard
                        isLazy
                        trigger="hover"
                        hasArrow
                        hoverContent={
                          <Stack maxW="280px" p="2" pt="2" spacing="2">
                            <HStack>
                              <Heading size="xs" fontWeight="semibold">
                                ✨ AI Rank
                              </Heading>
                            </HStack>

                            <Divider />

                            <Stack spacing="0.5" pt="2">
                              <HStack spacing={'0.5'} alignItems="flex-start">
                                <Text color="gray.700" fontWeight={'semibold'} fontSize="small">
                                  {prospect.first_name} {prospect.last_name}
                                </Text>
                                {prospect.linkedin_url && (
                                  <IconButton
                                    icon={<LinkedinIcon color={'linkedin.700'} />}
                                    aria-label="View LinkedIn Profile"
                                    variant={'link'}
                                    size="xs"
                                    as={Link}
                                    href={`https://${prospect.linkedin_url.replace(/https?:\/\//, '')}`}
                                    isExternal
                                  />
                                )}
                              </HStack>

                              <Text>{prospect.title}</Text>
                            </Stack>

                            <Stack pt="2">
                              <Flex flexWrap={'wrap'} gap="1" fontSize={'xs'}>
                                {prospect.rank && (
                                  <Tag
                                    colorScheme={
                                      prospect.rank === 'very high'
                                        ? 'purple'
                                        : prospect.rank === 'high'
                                          ? 'purple'
                                          : prospect.rank === 'medium'
                                            ? 'yellow'
                                            : 'gray'
                                    }
                                    size="sm"
                                  >
                                    <TagLeftIcon as={IconSparkles} />
                                    <TagLabel fontSize={'xs'}>{humanize(prospect.rank ?? '')}</TagLabel>
                                  </Tag>
                                )}
                                {prospect.highlight?.job_title_levels &&
                                  wrap(prospect.highlight.job_title_levels).map((level) => (
                                    <Tag size="sm" key={level?.toString()}>
                                      <TagLeftIcon as={IconUser} />
                                      {typeof level == 'string' && (
                                        <TagLabel fontSize={'xs'}>{humanize(level)}</TagLabel>
                                      )}
                                      {typeof level != 'string' && <TagLabel fontSize={'xs'}>{level}</TagLabel>}
                                    </Tag>
                                  ))}

                                {prospect.highlight?.job_title_roles &&
                                  wrap(prospect.highlight.job_title_roles).map((role) => (
                                    <Tag size="sm" key={role?.toString()}>
                                      <TagLeftIcon as={IconBuilding} />
                                      {typeof role == 'string' && (
                                        <TagLabel fontSize={'xs'}>{humanize(role.replace('_', ' '))}</TagLabel>
                                      )}
                                      {typeof role != 'string' && <TagLabel fontSize={'xs'}>{role}</TagLabel>}
                                    </Tag>
                                  ))}

                                {prospect.highlight?.title_keywords &&
                                  wrap(prospect.highlight.title_keywords).map((keyword) => (
                                    <Tag size="sm" key={keyword.toString()}>
                                      <TagLeftIcon as={IconHash} />
                                      {typeof keyword == 'string' && (
                                        <TagLabel fontSize={'xs'}>{humanize(keyword, false)}</TagLabel>
                                      )}
                                      {typeof keyword != 'string' && <TagLabel fontSize={'xs'}>{keyword}</TagLabel>}
                                    </Tag>
                                  ))}

                                {prospect.highlight?.location &&
                                  wrap(prospect.highlight.location).map((location) => (
                                    <Tag size="sm" key={location.toString()}>
                                      <TagLeftIcon as={IconMapPin} />
                                      {typeof location == 'string' && (
                                        <TagLabel fontSize={'xs'}>{humanize(location)}</TagLabel>
                                      )}

                                      {typeof location != 'string' && <TagLabel fontSize={'xs'}>{location}</TagLabel>}
                                    </Tag>
                                  ))}
                              </Flex>
                            </Stack>

                            <Stack spacing="1">
                              <Heading size="xs" fontWeight={'semibold'} pt="2" color="gray.700">
                                Reason
                              </Heading>
                              <Text fontSize="xs" whiteSpace={'pre-wrap'}>
                                {prospect.reason}
                              </Text>
                            </Stack>
                          </Stack>
                        }
                      >
                        <Stack
                          spacing="2"
                          cursor={'pointer'}
                          opacity={isLoading ? 0.5 : 1}
                          pointerEvents={isLoading ? 'none' : 'auto'}
                        >
                          <Flex gap="1" maxW="300px" overflow={'auto'}>
                            {prospect.rank && (
                              <Tag
                                colorScheme={
                                  prospect.rank === 'very high'
                                    ? 'purple'
                                    : prospect.rank === 'high'
                                      ? 'purple'
                                      : prospect.rank === 'medium'
                                        ? 'yellow'
                                        : 'gray'
                                }
                                size="sm"
                              >
                                <TagLeftIcon as={IconSparkles} />
                                <TagLabel fontSize={'xs'}>{humanize(prospect.rank ?? '')}</TagLabel>
                              </Tag>
                            )}
                          </Flex>
                          <Stack>
                            <TextEllipsis maxW="250px" fontSize={'xs'}>
                              {prospect.reason}
                            </TextEllipsis>
                          </Stack>
                        </Stack>
                      </HoverCard>
                    }
                  </Td>
                )}

                {actions !== false && (
                  <Td width="1px" valign="top">
                    <HStack w="100%" justifyContent={'flex-end'}>
                      <HStack pt="3">
                        <Tooltip label={emailAvailable ? 'Copy to clipboard' : undefined}>
                          <Button
                            bg="gray.50"
                            role="group"
                            leftIcon={
                              emailAvailable ? (
                                <IconAt size="14" />
                              ) : prospect.unlock_state === 'not_found' ? (
                                <IconMailX size="14" />
                              ) : (
                                <IconMailSearch size="14" />
                              )
                            }
                            size="xs"
                            variant={'ghost'}
                            _hover={isLocked ? { bg: 'purple.50', color: 'purple.500' } : undefined}
                            colorScheme={prospect.unlock_state === 'not_found' ? 'red' : 'gray'}
                            isLoading={emailLoadingState[prospect.id] || isLoading}
                            onClick={() => {
                              if (emailAvailable) {
                                navigator.clipboard.writeText(prospect.email!)
                                toast.success('Email copied to clipboard', {
                                  position: 'bottom-right'
                                })
                                window.ko?.track('Prospect Email Copied', {
                                  email: prospect.email,
                                  prospected_profile_id: prospect.id,
                                  company: domain,
                                  context: context
                                })
                                return
                              }

                              onUnlock(prospect)
                            }}
                            rightIcon={
                              emailAvailable ? (
                                <Icon
                                  as={IconCopy}
                                  boxSize={3.5}
                                  color="gray.400"
                                  _groupHover={{ color: 'gray.700' }}
                                />
                              ) : undefined
                            }
                          >
                            {emailAvailable && prospect.email}
                            {isLocked && 'Find email'}
                            {prospect.unlock_state === 'not_found' && 'Email not found'}
                          </Button>
                        </Tooltip>

                        {(prospect.unlock_state === 'unlocked' || prospect.unlock_state === 'auto-unlocked') &&
                          !prospect.profile && (
                            <HStack>
                              {prospect.email && (
                                <Tooltip
                                  label={
                                    prospect.verification_status === 'verified'
                                      ? 'Valid Email'
                                      : prospect.verification_status === 'verification_failed'
                                        ? 'Invalid Email'
                                        : 'Verify Email'
                                  }
                                >
                                  <IconButton
                                    size="xs"
                                    variant="ghost"
                                    colorScheme={
                                      prospect.verification_status === 'unverified'
                                        ? 'gray'
                                        : prospect.verification_status === 'verified'
                                          ? 'green'
                                          : 'red'
                                    }
                                    aria-label="Verify email"
                                    icon={
                                      prospect.verification_status === 'verified' ? (
                                        <Icon as={IconMailCheck} color="green.500" boxSize={4} />
                                      ) : (
                                        <Icon as={IconMailQuestion} boxSize={4} />
                                      )
                                    }
                                    isLoading={verificationLoadingState[prospect.id]}
                                    onClick={() => {
                                      if (prospect.verification_status === 'verified') {
                                        toast.info('Email has already been verified')
                                      } else {
                                        onVerifyEmail(prospect)
                                      }
                                    }}
                                  >
                                    {prospect.verification_status === 'unverified'
                                      ? 'Verify'
                                      : prospect.verification_status === 'verified'
                                        ? 'Verified'
                                        : ''}
                                  </IconButton>
                                </Tooltip>
                              )}
                              {prospect.verification_status !== 'verification_failed' && prospect.email && (
                                <Tooltip label="Enriched via Koala Waterfall">
                                  <Icon as={IconSparkles} color="gray.400" boxSize={4} />
                                </Tooltip>
                              )}
                            </HStack>
                          )}

                        <Tooltip label={phoneAvailable ? 'Copy to clipboard' : undefined}>
                          <Button
                            bg="gray.50"
                            role="group"
                            _hover={isPhoneLocked ? { bg: 'purple.50', color: 'purple.500' } : undefined}
                            leftIcon={
                              prospect.phone_state === 'unlocked' ? (
                                <IconPhoneCheck size="14" />
                              ) : prospect.phone_state === 'not_found' ? (
                                <IconPhoneX size="15" />
                              ) : (
                                <IconPhonePlus size="14" />
                              )
                            }
                            colorScheme={
                              prospect.phone_state === 'unlocked'
                                ? 'gray'
                                : prospect.phone_state === 'not_found'
                                  ? 'red'
                                  : 'gray'
                            }
                            size="xs"
                            variant={'ghost'}
                            isLoading={phoneLoadingState[prospect.id] || isLoading}
                            onClick={() => {
                              if (phoneAvailable) {
                                navigator.clipboard.writeText(prospect.phone_numbers!.mobile!)
                                toast.success('Phone number copied to clipboard', {
                                  position: 'bottom-right'
                                })
                                window.ko?.track('Prospect Phone Copied', {
                                  email: prospect.email,
                                  prospected_profile_id: prospect.id,
                                  company: domain,
                                  context: context
                                })
                                return
                              }

                              onUnlockPhone(prospect)
                            }}
                            rightIcon={
                              phoneAvailable ? (
                                <Icon
                                  as={IconCopy}
                                  boxSize={3.5}
                                  color="gray.400"
                                  _groupHover={{ color: 'gray.700' }}
                                />
                              ) : undefined
                            }
                          >
                            {phoneAvailable && prospect.phone_numbers?.mobile}
                            {isPhoneLocked && 'Find phone'}
                            {prospect.phone_state === 'not_found' && 'Phone not found'}
                          </Button>
                        </Tooltip>

                        {(isPhoneLocked || isLocked) && (
                          <HelpTooltip>
                            <Stack spacing="4" p="2">
                              <Heading size="xs" fontWeight={'semibold'}>
                                Unlock prospect
                              </Heading>
                              <Text whiteSpace={'pre-wrap'} lineHeight="1.4">
                                Use Koala Waterfall enrichment credits to unlock the email address and/or phone numbers
                                for this prospect.
                              </Text>
                              <Text
                                whiteSpace={'pre-wrap'}
                                fontSize="xs"
                                lineHeight="1.4"
                                p="4"
                                bg="purple.50"
                                color="gray.700"
                              >
                                Note: We're offering unlimited Koala Waterfall enrichment credits to all beta testers of
                                Prospecting.
                              </Text>
                              <HStack w="100%">
                                <Button
                                  w="100%"
                                  size="sm"
                                  colorScheme={'gray'}
                                  disabled={prospect.unlock_state === 'not_found'}
                                  variant={'outline'}
                                  onClick={() => onUnlock(prospect)}
                                  leftIcon={<IconMailSearch size="18" />}
                                  isLoading={emailLoadingState[prospect.id] || isLoading}
                                >
                                  Find Email
                                </Button>
                                <Button
                                  w="100%"
                                  size="sm"
                                  colorScheme={'gray'}
                                  variant={'outline'}
                                  disabled={prospect.phone_state === 'not_found'}
                                  onClick={() => {
                                    onUnlockPhone(prospect)
                                  }}
                                  leftIcon={<IconPhonePlus size="18" />}
                                  isLoading={phoneLoadingState[prospect.id] || isLoading}
                                >
                                  Find Phone
                                </Button>
                              </HStack>
                            </Stack>
                          </HelpTooltip>
                        )}
                        <ActionMenu
                          prospect={prospect}
                          domain={domain}
                          isLoading={isLoading}
                          apps={apps}
                          persona={persona}
                          context={context}
                          onEmailLoadingChange={(loading) => {
                            setEmailLoadingState((prev) => ({ ...prev, [prospect.id]: loading }))
                          }}
                          onPhoneLoadingChange={(loading) => {
                            setPhoneLoadingState((prev) => ({ ...prev, [prospect.id]: loading }))
                          }}
                          onChange={(updatedProspect) => {
                            setProspects((prev) => {
                              return prev.map((p) => {
                                if (p.id === updatedProspect.id) {
                                  return updatedProspect
                                }
                                return p
                              })
                            })
                          }}
                        />
                      </HStack>
                    </HStack>
                  </Td>
                )}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
