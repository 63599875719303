import { Box, Stack, Text } from '@chakra-ui/react'
import * as React from 'react'
import Avatar from '../../../ui/Avatar'

interface UserProps {
  image?: string | null
  name?: string | null
  anonymousName?: string | null
  email: string
}

export const User = (props: UserProps) => {
  const { image, name, anonymousName, email } = props
  return (
    <Stack direction="row" spacing="3" align="center">
      <Avatar size="sm" name={name || email} src={image} />
      <Box>
        <Text
          fontSize="xs"
          fontWeight="medium"
          maxW="52"
          wordBreak="break-word"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {name ?? anonymousName ?? 'Anonymous'}
        </Text>
        <Text fontSize="xs" color="gray.600">
          {email}
        </Text>
      </Box>
    </Stack>
  )
}
