import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Stack,
  useDisclosure
} from '@chakra-ui/react'
import { IconCalendarEvent, IconChevronDown, IconChevronLeft, IconChevronRight, IconLock } from '@tabler/icons-react'
import maxDate from 'date-fns/max'
import minDate from 'date-fns/min'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DateRange, DayPicker } from 'react-day-picker'
import dayjs from '../../../../lib/dayjs'
import { Period } from './timeseries'

interface Preset {
  label: string
  period: string
  range: DateRange
  disabledReason?: string
  lockedReason?: string
}

interface Props {
  period?: DateRange | Period | string
  onChange?: (period: string | undefined) => void
  footer?: React.ReactNode
  size?: ButtonProps['size']
  min?: Date
  max?: Date
  presets?: Preset[]
  popoverProps?: PopoverProps
}

const findPreset = (range: DateRange, presets: Preset[]) => {
  const preset = presets.find((s) => {
    if (s.range.from && s.range.to) {
      const sameFrom = range?.from && dayjs(s.range.from).isSame(range.from, 'day')
      const sameTo = range?.to && dayjs(s.range.to).isSame(range.to, 'day')
      return sameFrom && sameTo
    }

    return false
  })

  return preset || { period: 'custom', range }
}

const emptyPeriod = { period: undefined, range: undefined }

interface ParsedPeriod {
  period: string | undefined
  range: DateRange | undefined
}

/**
 * Extracts the preset label (period) and range from a string or DateRange
 */
export const parsePeriod = (period: DateRange | string | undefined, presets: Preset[]): ParsedPeriod => {
  if (!period) {
    return emptyPeriod
  }

  if (typeof period === 'object' && 'from' in period) {
    const preset = findPreset(period, presets)
    return preset ?? { period: 'custom', range: period }
  }

  if (typeof period === 'string' && period.includes('..')) {
    const [from, to] = period.split('..').map((d) => dayjs(d).toDate())
    const range = { from, to }
    const preset = findPreset(range, presets)
    return preset ?? { period: 'custom', range }
  }

  const preset = presets.find((s) => s.period === period)
  return preset || emptyPeriod
}

const formatPeriod = (period: string | undefined, range: DateRange | undefined) => {
  if (period !== 'custom') {
    return period
  } else if (period === 'custom' && range?.from && range?.to) {
    return [range.from, range.to].map((d) => dayjs(d).utc().format('YYYY-MM-DD')).join('..')
  }
}

export const getDefaultPresets = (): Preset[] => {
  return [
    {
      label: 'Today',
      period: 'day',
      range: {
        from: dayjs().startOf('day').toDate(),
        to: dayjs().toDate()
      }
    },
    {
      label: 'Yesterday',
      period: 'yesterday',
      range: {
        from: dayjs().subtract(1, 'day').startOf('day').toDate(),
        to: dayjs().subtract(1, 'day').endOf('day').toDate()
      }
    },
    {
      label: 'Last 7 days',
      period: 'week',
      range: {
        from: dayjs().subtract(7, 'days').startOf('day').toDate(),
        to: dayjs().endOf('day').toDate()
      }
    },
    {
      label: 'Last 14 days',
      period: 'two-weeks',
      range: {
        from: dayjs().subtract(14, 'days').startOf('day').toDate(),
        to: dayjs().toDate()
      }
    },
    {
      label: 'Past month',
      period: 'month',
      range: {
        from: dayjs().subtract(30, 'days').startOf('day').toDate(),
        to: dayjs().toDate()
      }
    },
    {
      label: 'Month to date',
      period: 'month-to-date',
      range: {
        from: dayjs().startOf('month').startOf('day').toDate(),
        to: dayjs().toDate()
      }
    },
    {
      label: 'Past quarter',
      period: 'quarter',
      range: {
        from: dayjs().subtract(90, 'days').startOf('day').toDate(),
        to: dayjs().toDate()
      }
    }
  ]
}

function DateRangePicker({ onChange, size, popoverProps, ...props }: Props) {
  const presets = useMemo(() => props.presets ?? getDefaultPresets(), [props.presets])

  const { period, range } = parsePeriod(props.period, presets)
  const [localPeriod, setLocalPeriod] = useState<string | undefined>(period)
  const [localRange, setLocalRange] = useState<DateRange | undefined>(range)

  const earliestDate = props.min || dayjs().subtract(90, 'days').toDate()
  const latestDate = props.max || dayjs().toDate()

  const handleChange = useCallback(
    (p: string | undefined, r?: DateRange) => {
      const updated = formatPeriod(p, r)
      if (updated && onChange) {
        onChange(updated)
      }
    },
    [onChange]
  )

  const disclosure = useDisclosure({
    onClose: () => handleChange(localPeriod, localRange)
  })

  const [previewTo, setPreviewTo] = useState<Date | undefined>(undefined)
  const [month, setMonth] = useState(localRange?.from)

  useEffect(() => {
    const updated = parsePeriod(props.period, presets)
    setLocalPeriod(updated.period)
    setLocalRange(updated.range)
    setMonth(updated.range?.from)
  }, [props.period, presets])

  const possibleMin = useMemo(() => {
    if (localRange?.from && previewTo) {
      return minDate([localRange.from, previewTo])
    }

    return localRange?.from
  }, [localRange?.from, previewTo])

  const possibleMax = useMemo(() => {
    if (localRange?.from && previewTo) {
      return maxDate([localRange.from, previewTo])
    }

    return localRange?.from
  }, [localRange?.from, previewTo])

  const onDayMouseEnter = useCallback(
    (day) => {
      if (!localRange?.from) return
      if (localRange.from && localRange.to) return
      setPreviewTo(day)
    },
    [localRange]
  )

  const onSelectDay = useCallback(
    (_range, day) => {
      let newRange: DateRange = { from: undefined, to: undefined }

      // We have to manually mess with this since we highlight the days between on hover
      if (!localRange?.from) {
        newRange = { from: day, to: undefined }
        setLocalRange(newRange)
      } else if (!localRange?.to) {
        const min = minDate([localRange.from, day])
        const max = maxDate([localRange.from, day])
        newRange = { from: min, to: max }
        setLocalRange(newRange)
      } else {
        newRange = { from: day, to: undefined }
        setLocalRange(newRange)
      }

      const preset = findPreset(newRange, presets)
      setLocalPeriod(preset?.period)
      setPreviewTo(undefined)
      handleChange(preset?.period, newRange)
    },
    [handleChange, localRange, presets]
  )

  return (
    <Popover placement="bottom-end" isLazy lazyBehavior="keepMounted" {...popoverProps} {...disclosure}>
      <PopoverTrigger>
        <Button
          variant="outline"
          size={size || 'sm'}
          flex="none"
          leftIcon={<IconCalendarEvent size={16} />}
          rightIcon={<IconChevronDown size={16} />}
        >
          {presets.find((s) => s.period === localPeriod)?.label ??
            (localRange?.from
              ? `${dayjs(localRange.from).format('MMM D, YYYY')} - ${
                  localRange.to ? dayjs(localRange.to).format('MMM D, YYYY') : ''
                }`
              : 'Pick a date')}
        </Button>
      </PopoverTrigger>
      <PopoverContent w="auto" maxW="95vw">
        <PopoverBody>
          <Flex alignItems="stretch" flexWrap="wrap-reverse" p={4} gap={6}>
            <Stack width={['100%', 'auto']}>
              <DayPicker
                mode="range"
                defaultMonth={localRange?.from}
                month={month}
                onMonthChange={setMonth}
                fromDate={earliestDate}
                toDate={latestDate}
                selected={
                  localRange?.to
                    ? localRange
                    : {
                        from: possibleMin,
                        to: possibleMax
                      }
                }
                onSelect={onSelectDay}
                components={{
                  IconLeft: () => <IconChevronLeft size={16} />,
                  IconRight: () => <IconChevronRight size={16} />
                }}
                onDayMouseEnter={onDayMouseEnter}
                numberOfMonths={1}
                max={365}
              />
            </Stack>
            <Stack spacing={1} alignItems="flex-start" px={1.5}>
              {presets.map((preset) => (
                <Button
                  key={`${preset.label}:${preset.period}`}
                  leftIcon={preset.lockedReason ? <Icon as={IconLock} /> : undefined}
                  variant="unstyled"
                  size="xs"
                  fontSize="sm"
                  textAlign="left"
                  width="full"
                  minW="120px"
                  height="7"
                  fontWeight="medium"
                  rounded="none"
                  color="gray.500"
                  isDisabled={!!preset.lockedReason || !!preset.disabledReason}
                  title={preset.lockedReason || preset.disabledReason}
                  _hover={{ color: 'gray.700' }}
                  _active={{ color: 'purple.500' }}
                  isActive={localPeriod === preset.period}
                  onClick={() => {
                    setMonth(preset.range.from)
                    setLocalRange({ from: preset.range.from, to: preset.range.to })
                    setLocalPeriod(preset.period)
                    handleChange(preset.period)
                  }}
                >
                  {preset.label}
                </Button>
              ))}
            </Stack>
          </Flex>
          <Box>{props.footer}</Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default DateRangePicker
