import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  UseDisclosureProps,
  Stack
} from '@chakra-ui/react'
import React, { useCallback } from 'react'
import { useUpdateList } from '../../../data/use-lists'
import { StaticList } from '../../../../types/StaticList'
import { toast } from 'sonner'
import { projectPath } from '../../../ui/ProjectsContext'

interface RenameListModalProps extends UseDisclosureProps {
  listId: string
  onUpdate?: (list: StaticList) => void
  onClose: () => void
}

export function RenameListModal({ listId, onUpdate, ...props }: RenameListModalProps) {
  const disclosure = useDisclosure(props)
  const onClose = disclosure.onClose
  const isModalOpen = disclosure.isOpen

  const { isLoading: submitting, mutateAsync: updateList } = useUpdateList()

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault()

      const data = new FormData(e.target)
      const name = data.get('static_list[name]') as string

      try {
        const res = await updateList({ id: listId, name })
        toast.success('List updated!')
        onClose()
        onUpdate?.(res.list)
      } catch (err: any) {
        toast.error('Failed to update list', { description: err?.message })
      }
    },
    [listId, updateList, onUpdate, onClose]
  )

  const formId = 'edit-list-name-form'

  return (
    <Modal isOpen={isModalOpen} size="md" preserveScrollBarGap onClose={props.onClose}>
      <ModalOverlay onClick={onClose} />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Edit your list name</ModalHeader>
        <ModalBody>
          <form id={formId} method="POST" action={projectPath(`/lists/${listId}`)} onSubmit={onSubmit}>
            <Stack spacing={6}>
              <FormControl isRequired>
                <FormLabel htmlFor="static_list[name]">List name</FormLabel>
                <Input
                  autoFocus
                  size="sm"
                  autoComplete="off"
                  placeholder="E.g.: Weekly prospects"
                  isRequired
                  name="static_list[name]"
                  id="static_list[name]"
                />
              </FormControl>
            </Stack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button size="md" width="100%" colorScheme="purple" type="submit" form={formId} isLoading={submitting}>
            Save list name
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
