import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react'
import {
  IconArrowForward,
  IconArrowLeft,
  IconCalendarEvent,
  IconCheck,
  IconChevronLeft,
  IconChevronRight,
  IconMathEqualGreater,
  IconMathEqualLower,
  IconX
} from '@tabler/icons-react'
import { useVirtualizer } from '@tanstack/react-virtual'
import maxDate from 'date-fns/max'
import minDate from 'date-fns/min'
import castArray from 'lodash/castArray'
import omit from 'lodash/omit'
import sortBy from 'lodash/sortBy'
import words from 'lodash/words'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DateRange, DayPicker } from 'react-day-picker'
import { useDebounce } from 'use-debounce'
import { Facet, FacetFilters, RangeFacet } from '..'
import { concurrentGET } from '../../../../lib/api'
import dayjs from '../../../../lib/dayjs'
import { pluralize } from '../../../../lib/pluralize'
import { FacetOperator, facetQueryString, getFacetOperator, getFacetValues } from '../../../data/use-facets'
import { useFieldValues } from '../../../data/use-field-values'
import { Iconify } from '../../../ui/Iconify'
import { SourceIcon } from '../../../ui/icons'
import { SearchIcon } from '../../../ui/icons/SearchIcon'
import { projectPath } from '../../../ui/ProjectsContext'
import useLatestRef from '../../../ui/useLatestRef'
import { useSearchParam } from '../../../ui/useSearchState'
import { parsePeriod } from '../../analytics/components/DateRangePicker'
import MenuItem from './menu-item'

const noop = () => {}

export const quickRanges = {
  day: 'Past Day',
  week: 'Past Week',
  month: 'Past Month',
  all: 'All time'
}

export const periodShorthands = {
  day: '24 hours ago',
  week: '1 week ago',
  month: '1 month ago',
  '3mo': '3 months ago',
  '6mo': '6 months ago',
  year: '1 year ago'
}

const formatDay = (day: Date | undefined) => {
  if (day) {
    return dayjs(day).utc().format('YYYY-MM-DD')
  }
}

const formatPeriod = (range: DateRange | undefined) => {
  if (range?.from && range?.to) {
    return [range.from, range.to].map((d) => dayjs(d).utc().format('YYYY-MM-DD')).join('..')
  }
}

const mergeQuery = (path: string, query = '') => {
  if (path.includes('?')) {
    return path + query.replace('?', '&')
  } else if (query) {
    return path + query
  } else {
    return path
  }
}

const lowerCaseWords = new Set(['and', 'the', 'of', 'in', 'with', 'a', 'an', 'to', 'for', 'on', 'at', 'by', 'from'])

export function titleize(input: string, splitWords = true) {
  const parts = splitWords ? words(input) : input.split(/\s+/)

  return parts
    .map((word, idx) => {
      return word
        .toLowerCase()
        .split('-')
        .map((segment) => {
          if (idx === 0 || !lowerCaseWords.has(segment)) {
            return segment.charAt(0).toUpperCase() + segment.slice(1)
          }

          return segment
        })
        .join('-')
    })
    .join(' ')
}

export function humanize(input: string, splitWords = true) {
  const replaced = input.split(/\.|_/).join(' ')
  return titleize(replaced, splitWords)
}

interface FilterProps {
  facetFilters: FacetFilters
  values: Record<string, any>
  filter: string
  searchQuery?: string
  range?: 'day' | 'week' | 'month' | 'all' | 'any' | null
  facetValuesPath?: string
  hideCount?: boolean
  shouldAllowRegexMatching?: boolean
  allowSubstringOperators?: boolean
}

interface FacetValuesResponse {
  stats?: {
    count: number
    min: number
    max: number
  }
  values: Array<{ key: string; doc_count: number; label?: string }>
  count?: number
}

type ValueCounts = Record<string, number>
const emptyArray = []

interface CheckboxFilterProps extends FilterProps {
  isOpen: boolean
  initialFocusRef?: React.RefObject<HTMLInputElement>
  onChange: (appliedFilters: FacetFilters) => void
  onSearch?: React.ChangeEventHandler<HTMLInputElement>
  onResetSearch?: () => void
  operators?: NonNullable<Modifiers>[]
}

const anchoredOptions = ['Current user']

export const CheckboxFilter = (props: CheckboxFilterProps) => {
  const { values, facetFilters, filter, isOpen } = props
  const [options, setOptions] = React.useState(values as ValueCounts)
  const [labels, setLabels] = useState<Record<string, string>>({})
  const [showAll, setShowAll] = useState(false)
  const [loading, setLoading] = useState(false)
  const [applying, setApplying] = useState(false)
  const [fetched, setFetched] = useState(false)
  const [localState, setLocalState] = useState(props.facetFilters)
  const selectedValues = castArray(getFacetValues(localState[filter] ?? emptyArray) as string[])

  const fetchedRef = useLatestRef(fetched)

  const operators = useMemo(() => {
    const ops = props.operators || ['any', 'all', 'not', 'exists', 'not_exists']

    if (props.allowSubstringOperators) {
      return ops.concat('prefix', 'contains')
    }

    return ops
  }, [props.operators, props.allowSubstringOperators])

  const range = useSearchParam('range')

  const zeroDocOptions = React.useMemo(() => {
    return Object.entries(options).filter(([k, v]) => {
      return v === 0 && !selectedValues.includes(k) && !anchoredOptions.includes(k)
    }).length
  }, [options, selectedValues])

  const nonZeroOptions = React.useMemo(() => {
    return Object.entries(options).filter(([_k, v]) => v > 0).length
  }, [options])

  const shouldShowAll = showAll || !nonZeroOptions || !!props.searchQuery?.trim()

  const entries = React.useMemo(() => {
    const opts = options || {}
    const optionEntries = Object.entries(opts)

    for (const value of selectedValues) {
      if (!(value in opts)) {
        optionEntries.unshift([value, 0])
      }
    }

    return sortBy(optionEntries, (i) => !anchoredOptions.includes(i[0]))
  }, [options, selectedValues])

  const filteredEntries = React.useMemo(() => {
    const search = props.searchQuery?.trim()

    const filtered = entries.filter(([key, count]) => {
      if (search) {
        return key.toLowerCase().includes(search.toLowerCase())
      }

      if (shouldShowAll) {
        return true
      }

      return count > 0 || selectedValues.includes(key) || anchoredOptions.includes(key)
    })

    if (search && !filtered.map((v) => v[0]).includes(search)) {
      filtered.push([search, 0])
    }

    return filtered
  }, [entries, props.searchQuery, selectedValues, shouldShowAll])

  const parentRef = React.useRef<HTMLDivElement | null>(null)
  const rowVirtualizer = useVirtualizer({
    count: filteredEntries.length,
    getScrollElement: () => parentRef.current,
    estimateSize: React.useCallback(() => 28, [])
  })

  useEffect(() => {
    if (!fetchedRef.current) {
      setOptions(values)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  useEffect(() => {
    setOptions({})
    setFetched(false)
  }, [range])

  useEffect(() => {
    // when the filters change, reset "fetched"
    setOptions({})
    setFetched(false)
    setLocalState(facetFilters)
  }, [facetFilters])

  useEffect(() => {
    if (isOpen) {
      setLoading(false)
      setApplying(false)
    }
  }, [isOpen])

  const [debouncedSearch] = useDebounce(props.searchQuery, 300)

  // Reset whenever the search query changes
  useEffect(() => {
    setFetched(false)
  }, [debouncedSearch])

  const facetPath = props.facetValuesPath ?? '/accounts/facet-values'

  const fetchMoreValues = React.useCallback(async () => {
    let canceled = false
    setLoading(true)

    const facetQuery = facetQueryString(omit(facetFilters, filter), 'facets')
    let path = projectPath(
      mergeQuery(facetPath, `?property=${filter}&range=${props.range ?? range ?? ''}&${facetQuery.join('&')}`)
    )

    const search = debouncedSearch?.trim()
    if (search) {
      path += `&facet_search=${search}`
    }

    try {
      const res = await concurrentGET<FacetValuesResponse>(path)
      if (canceled) {
        return
      }

      setFetched(true)

      const newLabels = {}
      for (const option of res.values ?? []) {
        newLabels[option.key] = option.label || option.key
      }

      setLabels(newLabels)

      setOptions((existing) => {
        const opts: ValueCounts = { ...existing }
        const empties: ValueCounts = {}

        for (const option of res.values ?? []) {
          if (option.doc_count === 0) {
            empties[option.key] = option.doc_count
          } else {
            opts[option.key] = option.doc_count
          }
        }

        return {
          ...opts,
          // add empties to the very end
          // exclude existing keys to retain the original ordering
          ...omit(empties, Object.keys(existing))
        }
      })

      setLoading(false)
    } catch (error) {
      if (canceled) {
        return
      }

      setLoading(false)
    }

    return () => {
      canceled = true
    }
  }, [filter, facetFilters, facetPath, range, props.range, debouncedSearch])

  const shouldFetch =
    !fetched && !loading && (!!debouncedSearch?.trim() || !nonZeroOptions || selectedValues.length === entries.length)

  // Automatically fetch more in special scenarios (when we haven't already fetched):
  // - user is searching
  // - there are only options with zero doc matches
  // - the number of items = the number of selections (this happens when the original facet cloud excludes items with doc_count < 1)
  // - the range or filter query string changes
  useEffect(() => {
    if (shouldFetch && isOpen) {
      fetchMoreValues()
    }
  }, [fetchMoreValues, shouldFetch, isOpen])

  const defaultOperator = (operators[0] || undefined) as Modifiers
  const currentOperator = getFacetOperator(localState[filter])
  const [modifier, setModifier] = useState<Modifiers | undefined>(
    currentOperator ?? (defaultOperator !== 'any' ? defaultOperator : undefined)
  )

  const [substringValue, setSubstringValue] = useState(
    (modifier === 'prefix' || modifier === 'contains') && typeof selectedValues[0] === 'string' ? selectedValues[0] : ''
  )
  const [debouncedSubstring] = useDebounce(substringValue, 300)

  const onSubstringChange = useCallback((e) => {
    setSubstringValue(e.target.value)
  }, [])

  useEffect(() => {
    if (modifier === 'prefix' || modifier === 'contains') {
      setLocalState((local) => {
        return {
          ...local,
          [filter]: { [modifier]: debouncedSubstring ? [debouncedSubstring] : [] } as Facet
        }
      })
    }
  }, [modifier, filter, debouncedSubstring])

  const onModifierChange = useCallback(
    (mod: Modifiers | null) => {
      // this happens in ValueModifier, but we need to be consistent for other call sites
      if (mod === 'any') {
        mod = null
      }

      setModifier(mod)
      setLocalState((local) => {
        let value = getFacetValues(local[filter]) as Facet
        if (!value && mod !== 'exists' && mod !== 'not_exists') {
          return local
        }

        if (mod === 'exists') {
          return {
            ...local,
            [filter]: { exists: true } as Facet
          }
        }

        if (mod === 'not_exists') {
          return {
            ...local,
            [filter]: { exists: false } as Facet
          }
        }

        if (mod === 'prefix' || mod === 'contains') {
          return {
            ...local,
            [filter]: { [mod]: [] } as Facet
          }
        }

        const prevOp = getFacetOperator(local[filter])
        if (prevOp === 'exists' || prevOp === 'not_exists') {
          value = []
        }

        if (mod) {
          value = { [mod]: value } as Facet
        }

        return {
          ...local,
          [filter]: value
        }
      })
    },
    [filter]
  )

  // use the default operator if the current one doesnt exist or isn't allowed...
  // this is required to make sure the local state is updated and can be "applied"
  useEffect(() => {
    if (!defaultOperator) return
    if (defaultOperator === currentOperator) return
    if (!operators?.length) return

    const shouldUpdateLocalState = !currentOperator || !operators.includes(currentOperator)
    if (shouldUpdateLocalState) {
      onModifierChange(defaultOperator)
    }
  }, [operators, defaultOperator, currentOperator, onModifierChange])

  const showSearch = useMemo(() => {
    return props.onSearch && [null, 'any', 'equal', 'all', 'not'].includes(modifier || null)
  }, [props.onSearch, modifier])

  return (
    <Flex
      flex="1 1 auto"
      minHeight="100px"
      flexDirection="column"
      gap={4}
      paddingY={4}
      borderWidth={0}
      bg="white"
      position="relative"
    >
      <Flex px="4">
        <ValueModifier
          onChange={onModifierChange}
          modifier={modifier ?? null}
          allowedModifiers={(operators || undefined) as Array<NonNullable<Modifiers>> | undefined}
        />
      </Flex>
      {props.shouldAllowRegexMatching && (
        <Box flex="none" paddingX={4}>
          <Text fontSize="xs" color="gray.500">
            Use * for wildcard (includes) matching. Example: /pricing* will match both /pricing and /pricing/breakdown.
            Wildcard matching is only valid in the end and/or beginning of a value.
          </Text>
        </Box>
      )}
      {modifier && ['prefix', 'contains'].includes(modifier) && (
        <FormControl size="sm" paddingX={4}>
          <HStack>
            <Icon as={IconArrowForward} color="purple.500" boxSize={5} />
            <Input size="sm" type="text" value={substringValue} onChange={onSubstringChange} />
          </HStack>
        </FormControl>
      )}
      {showSearch && (
        <Box paddingX={4}>
          <InputGroup size="sm">
            <InputLeftElement>
              <SearchIcon color="gray.400" boxSize={4} />
            </InputLeftElement>

            <Input
              ref={props.initialFocusRef}
              value={props.searchQuery}
              onChange={props.onSearch}
              autoComplete="off"
              name="facet_option_search"
            />

            {props.searchQuery && props.onResetSearch && (
              <InputRightElement>
                <IconButton
                  size="xs"
                  aria-label="Clear search"
                  variant="ghost"
                  color="gray.400"
                  _hover={{ color: 'gray.600' }}
                  onClick={props.onResetSearch}
                  icon={<IconX size={16} />}
                />
              </InputRightElement>
            )}
          </InputGroup>
        </Box>
      )}
      {modifier !== 'exists' && modifier !== 'not_exists' && modifier !== 'prefix' && modifier !== 'contains' && (
        <Box
          ref={parentRef}
          flex="1 1 auto"
          paddingX={4}
          minHeight="100px"
          maxHeight="100%"
          width="100%"
          overflowY="auto"
          scrollBehavior="smooth"
          overscrollBehavior="contain"
          opacity={loading ? 0.6 : 1}
        >
          <div style={{ height: `${rowVirtualizer.getTotalSize()}px`, width: '100%', position: 'relative' }}>
            {rowVirtualizer.getVirtualItems().map((row) => {
              const entry = filteredEntries[row.index]
              const value = entry[0]
              const count = entry[1] as number

              const label = labels[value] || value

              const hasState = localState[filter]
              const isChecked = hasState
                ? getFacetValues(localState[filter])?.includes(value)
                : getFacetValues(facetFilters[filter])?.includes(value)

              return (
                <div
                  key={`${facetFilters[filter]} ${value} ${getFacetValues(facetFilters[filter])?.includes(value)}`}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: `${row.size}px`,
                    transform: `translateY(${row.start}px)`
                  }}
                >
                  <Checkbox
                    isChecked={isChecked}
                    onChange={(e) => {
                      setLocalState((local) => {
                        const isChecked = e.target.checked
                        const oldValue = getFacetValues(local[filter] || [])

                        const op = getFacetOperator(local[filter]) ?? modifier
                        let newValue: Facet = isChecked ? oldValue.concat(value) : oldValue.filter((v) => v !== value)

                        if (op) {
                          newValue = { [op]: newValue } as Facet
                        }

                        return {
                          ...local,
                          [filter]: newValue
                        }
                      })
                    }}
                  >
                    <Flex alignItems="center" gap={2}>
                      <Text minWidth={0} flex={1} isTruncated title={label} fontSize="sm">
                        {label}
                      </Text>
                      {count > 0 && !props.hideCount && (
                        <Text color="purple.600" fontSize="sm">
                          {count.toLocaleString()}
                        </Text>
                      )}
                    </Flex>
                  </Checkbox>
                </div>
              )
            })}
          </div>

          {!shouldShowAll && zeroDocOptions > 0 && (
            <Flex
              flex="none"
              alignItems="center"
              gap={2}
              cursor="pointer"
              opacity={0.6}
              _hover={{ opacity: 1 }}
              onClick={() => setShowAll(true)}
            >
              <Icon as={IconChevronRight} boxSize={4} color="gray.500" />
              <Text fontSize="sm" color="gray.700">
                {pluralize(zeroDocOptions, 'option', 'options')} not matching any items
              </Text>
            </Flex>
          )}
        </Box>
      )}

      <HStack flex="none" justifyContent="flex-end" paddingX={4}>
        <Button
          size="sm"
          colorScheme="purple"
          isLoading={applying}
          isDisabled={loading || (selectedValues.length === 0 && modifier !== 'exists' && modifier !== 'not_exists')}
          onClick={() => {
            setApplying(false)
            props.onChange(localState)
          }}
        >
          Apply Filters {selectedValues.length > 0 ? `(${selectedValues.length})` : ''}
        </Button>
      </HStack>
    </Flex>
  )
}

function parseExactDate(facet: Facet, modifier: Modifiers) {
  if (!facet) return

  if (typeof facet === 'string') {
    const day = dayjs(facet)
    if (day.isValid()) {
      return day.toDate()
    }
  }

  if (modifier && typeof facet === 'object' && modifier in facet) {
    const day = dayjs(facet[modifier])
    if (day.isValid()) {
      return day.toDate()
    }
  }
}

export const DateFilter = (props: FilterProps & { onChange: (appliedFilters: FacetFilters) => void }) => {
  const { facetFilters, filter } = props
  const [modifier, setModifier] = useState<Modifiers>(getFacetOperator(facetFilters[filter]) ?? 'gte')
  const [includeMissing, setIncludeMissing] = useState<'true' | 'false' | undefined>(
    facetFilters[filter]?.exists as any
  )
  const [state, setState] = useState<FacetFilters>(facetFilters)

  const isLastSeenFacet = filter === 'last_seen_at' || filter === 'first_seen_at'

  const currentValue = useMemo(() => {
    return (getFacetValues(state[filter]) as Facet)?.[0]
  }, [state, filter])

  const currentPeriod = useMemo(() => getFacetValues(state[filter])?.[0], [state, filter])
  const isDirty = useMemo(() => {
    return state[filter] !== facetFilters[filter]
  }, [state, filter, facetFilters])

  const isInvalid = useMemo(() => {
    const current = state[filter]
    if (!current) {
      return true
    }

    if (typeof current === 'string' && /\d+\.\.\d+/.test(current)) {
      return false
    }

    if (
      typeof current === 'object' &&
      Object.keys(current).some((key) => ['gte', 'lte', 'exists', 'not_exists'].includes(key))
    ) {
      return false
    }
  }, [state, filter])

  // for date ranges
  const { range } = parsePeriod(state[filter], [])
  const [localRange, setLocalRange] = useState<DateRange | undefined>(range)
  const [previewTo, setPreviewTo] = useState<Date | undefined>(undefined)
  const [month, setMonth] = useState(localRange?.from)

  // for exact dates
  const day = parseExactDate(state[filter], modifier)
  const [exactDate, setExactDate] = useState(day)
  const [showExactDate, setShowExactDate] = useState(day || false)

  const possibleMin = useMemo(() => {
    if (localRange?.from && previewTo) {
      return minDate([localRange.from, previewTo])
    }

    return localRange?.from
  }, [localRange?.from, previewTo])

  const possibleMax = useMemo(() => {
    if (localRange?.from && previewTo) {
      return maxDate([localRange.from, previewTo])
    }

    return localRange?.from
  }, [localRange?.from, previewTo])

  const onDayMouseEnter = useCallback(
    (day) => {
      if (!localRange?.from) return
      if (localRange.from && localRange.to) return
      setPreviewTo(day)
    },
    [localRange]
  )

  const onSelectSingleDay = useCallback(
    (_range, day) => {
      setExactDate(day)

      const value = formatDay(day)

      setState((prev) => ({
        ...prev,
        [filter]: {
          [modifier as string]: value,
          exists: (includeMissing === 'false' ? 'false' : undefined) as any
        }
      }))
    },
    [modifier, filter, includeMissing]
  )

  const onSelectDay = useCallback(
    (_range, day) => {
      let newRange: DateRange = { from: undefined, to: undefined }

      // We have to manually mess with this since we highlight the days between on hover
      if (!localRange?.from) {
        newRange = { from: day, to: undefined }
        setLocalRange(newRange)
      } else if (!localRange?.to) {
        const min = minDate([localRange.from, day])
        const max = maxDate([localRange.from, day])
        newRange = { from: min, to: max }
        setLocalRange(newRange)
      } else {
        newRange = { from: day, to: undefined }
        setLocalRange(newRange)
      }

      setPreviewTo(undefined)

      const facetRange = formatPeriod(newRange)
      if (facetRange) {
        setState((prev) => ({
          ...prev,
          [filter]: facetRange
        }))
      } else {
        setState((prev) => omit(prev, filter))
      }
    },
    [localRange, filter]
  )

  const onModifierChange = useCallback(
    (newModifier: Modifiers) => {
      if (newModifier === 'exists') {
        setModifier('exists')
        setState((prev) => ({
          ...prev,
          [filter]: { exists: 'true' }
        }))

        return
      }

      if (newModifier === 'not_exists') {
        setModifier('not_exists')
        setState((prev) => ({
          ...prev,
          [filter]: { exists: 'false' }
        }))

        return
      }

      if (newModifier === 'between') {
        setIncludeMissing('false')
        setModifier('between')
        setState((prev) => ({
          ...prev,
          [filter]: formatPeriod(localRange) || ''
        }))

        return
      }

      // Set `Include missing` to false by default
      let localIncludeMissing = includeMissing
      if (newModifier === 'lte') {
        setIncludeMissing('false')
        localIncludeMissing = 'false'
      }

      // Set `Include missing` to true by default
      if (newModifier === 'gte') {
        setIncludeMissing('true')
        localIncludeMissing = 'true'
      }

      setModifier(newModifier)
      // changing the modifier, but no value has been set yet
      if (!currentValue) {
        return
      }

      let value = currentValue
      if (currentValue === 'false' || currentValue === 'true' || currentValue.includes('..')) {
        value = 'month'
      }

      const withModifier = {
        [newModifier as string]: value
      }

      setState((prev) => ({
        ...prev,
        [filter]: {
          ...withModifier,
          exists: (localIncludeMissing === 'false' ? 'false' : undefined) as any
        }
      }))
    },
    [currentValue, localRange, includeMissing, filter]
  )

  const showShorthands = ['gte', 'lte', null].includes(modifier) && !showExactDate
  const showIncludeMissing = ['gte', 'lte'].includes(modifier!)
  const canUseExactDate = ['gte', 'lte', 'not', 'equal', null].includes(modifier)

  return (
    <Flex flexDirection="column" alignItems="stretch" paddingY={4} paddingX={2} overflow="auto">
      <Flex pb="2" px="2">
        <ValueModifier
          allowedModifiers={['gte', 'lte', 'between', 'exists', 'not_exists']}
          modifierLabels={{
            gte: 'is after',
            lte: 'is before',
            between: 'is between',
            exists: 'anytime',
            not_exists: 'not set'
          }}
          onChange={onModifierChange}
          modifier={modifier}
        />
      </Flex>

      {modifier === 'between' && (
        <Flex flexDirection="column" alignItems="stretch" px={2}>
          <Flex gap={2} alignItems="center" py={2}>
            <InputGroup size="sm">
              <InputLeftElement width="8" pointerEvents="none" fontSize="1em" color="gray.400">
                <Icon as={IconCalendarEvent} boxSize={4} />
              </InputLeftElement>
              <Input
                value={localRange?.from ? dayjs(localRange.from).format('MMM D, YYYY') : ''}
                onChange={noop}
                data-focus={!localRange?.from || undefined}
                placeholder="From..."
              />
            </InputGroup>
            <Text color="gray.400">&ndash;</Text>
            <InputGroup size="sm">
              <InputLeftElement width="8" pointerEvents="none" fontSize="1em" color="gray.400">
                <Icon as={IconCalendarEvent} boxSize={4} />
              </InputLeftElement>
              <Input
                value={localRange?.to ? dayjs(localRange.to).format('MMM D, YYYY') : ''}
                onChange={noop}
                data-focus={(!!localRange?.from && !localRange?.to) || undefined}
                placeholder="To..."
              />
            </InputGroup>
          </Flex>

          <DayPicker
            mode="range"
            defaultMonth={localRange?.from}
            month={month}
            onMonthChange={setMonth}
            selected={
              localRange?.to
                ? localRange
                : {
                    from: possibleMin,
                    to: possibleMax
                  }
            }
            onSelect={onSelectDay}
            components={{
              IconLeft: () => <IconChevronLeft size={16} />,
              IconRight: () => <IconChevronRight size={16} />
            }}
            onDayMouseEnter={onDayMouseEnter}
            numberOfMonths={1}
          />
        </Flex>
      )}

      {showShorthands && (
        <>
          {Object.entries(periodShorthands).map(([key, label]) => {
            return (
              <MenuItem
                key={`${key}-${label}-date`}
                sectionKey={filter}
                badge={key === currentPeriod ? <Icon as={IconCheck} color="purple.600" boxSize={4} /> : undefined}
                disabled={!!modifier && ['exists', 'between', 'not_exists'].includes(modifier)}
                onClick={() => {
                  let mod = modifier

                  // in the case of "exists" or "not_exists", we need to change the modifier to "gte"
                  // otherwise, the query will be invalid
                  if (modifier === 'exists' || modifier === 'not_exists' || modifier === 'between') {
                    setModifier('gte')
                    mod = 'gte'
                  }

                  setState({
                    ...facetFilters,
                    [filter]:
                      key === 'all'
                        ? []
                        : {
                            [mod as string]: key,
                            exists: (includeMissing === 'false' ? 'false' : undefined) as any
                          }
                  })
                }}
              >
                {label}
              </MenuItem>
            )
          })}
          <MenuItem
            sectionKey={filter}
            onClick={() => {
              setShowExactDate(true)
            }}
          >
            Exact date
          </MenuItem>
        </>
      )}

      {canUseExactDate && showExactDate && (
        <Flex flexDirection="column" alignItems="stretch" flexShrink={0} px={2}>
          <Flex gap={1} alignItems="center" py={2}>
            <IconButton
              aria-label="Back"
              size="tiny"
              variant="ghost"
              icon={<Icon as={IconArrowLeft} boxSize={3.5} />}
              onClick={() => setShowExactDate(false)}
            />
            <Text fontSize="xs" fontWeight="medium" color="gray.500">
              Exact date
            </Text>
          </Flex>

          <Flex gap={2} alignItems="center" py={2}>
            <InputGroup size="sm">
              <InputLeftElement width="8" pointerEvents="none" fontSize="1em" color="gray.400">
                <Icon as={IconCalendarEvent} boxSize={4} />
              </InputLeftElement>
              <Input
                value={localRange?.from ? dayjs(localRange.from).format('MMM D, YYYY') : ''}
                onChange={noop}
                data-focus={!localRange?.from || undefined}
                placeholder="From..."
              />
            </InputGroup>
          </Flex>

          <DayPicker
            mode="single"
            defaultMonth={exactDate}
            month={month}
            onMonthChange={setMonth}
            selected={exactDate}
            onSelect={onSelectSingleDay}
            components={{
              IconLeft: () => <IconChevronLeft size={16} />,
              IconRight: () => <IconChevronRight size={16} />
            }}
            numberOfMonths={1}
          />
        </Flex>
      )}

      <Flex justifyContent={'space-between'} alignItems="center" gap={4} pt={2} px={2}>
        {!isLastSeenFacet && showIncludeMissing && (
          <Flex pl="1">
            <Checkbox
              size="md"
              fontSize="sm"
              isChecked={includeMissing === 'false'}
              isDisabled={modifier?.includes('exists')}
              onChange={(e) => {
                const checked = e.target.checked
                if (checked) {
                  setIncludeMissing('false')
                  setState({
                    ...state,
                    [filter]: {
                      ...state[filter],
                      exists: 'false'
                    }
                  })
                } else {
                  setIncludeMissing(undefined)
                  setState({
                    ...state,
                    [filter]: omit(state[filter], 'exists')
                  })
                }
              }}
            >
              Include missing
            </Checkbox>
          </Flex>
        )}

        <Button
          size="sm"
          isDisabled={!isDirty || isInvalid}
          colorScheme={'purple'}
          marginLeft="auto"
          onClick={() => {
            props.onChange(state)
          }}
        >
          Apply
        </Button>
      </Flex>
    </Flex>
  )
}

const sourceOptions = {
  Discord: { label: 'Discord', icon: <SourceIcon source="discord" /> },
  GitHub: { label: 'GitHub', icon: <SourceIcon source="github" /> },
  LinkedIn: { label: 'LinkedIn', icon: <SourceIcon source="linkedin" /> },
  Rb2b: { label: 'RB2B', icon: <SourceIcon source="rb2b" /> },
  Slack: { label: 'Slack', icon: <SourceIcon source="slack" /> },
  Snowflake: { label: 'Snowflake', icon: <SourceIcon source="snowflake" /> },
  Web: { label: 'Website', icon: <SourceIcon source="web" /> }
}

interface SourceFilterProps extends FilterProps {
  isOpen: boolean
  sources?: string[]
  onChange: (filters: FacetFilters) => void
}

export function SourceFilter(props: SourceFilterProps) {
  const { filter, facetFilters, facetValuesPath } = props

  const range = useSearchParam('range')
  const facetPath = facetValuesPath ?? '/accounts/facet-values'
  const facetQuery = facetQueryString(omit(facetFilters, filter), 'facets')
  const path = mergeQuery(facetPath, `?${facetQuery.join('&')}&range=${props.range ?? range ?? ''}`)
  const { data } = useFieldValues(filter, path)

  const [localState, setLocalState] = useState(facetFilters)

  useEffect(() => {
    setLocalState(facetFilters)
  }, [facetFilters])

  const sources = props.sources ?? Object.keys(sourceOptions)

  return (
    <Flex direction="column" alignItems="stretch" paddingTop={3} paddingBottom={4} paddingX={4} gap="1px">
      {sources.map((value) => {
        const display = sourceOptions[value]
        if (!display) return null

        const count = data?.values?.find((v) => v.key.toLowerCase() === value.toLowerCase())?.doc_count || 0
        const availableOptions = data?.values?.map((v) => v.key) || []

        const hasState = localState[filter]
        const isChecked = hasState
          ? getFacetValues(localState[filter])?.includes(value)
          : getFacetValues(facetFilters[filter])?.includes(value)

        if (!availableOptions.includes(value)) return null

        return (
          <Checkbox
            key={`${filter}-${display.label}-source`}
            isChecked={isChecked}
            py={1}
            onChange={(e) => {
              setLocalState((local) => {
                const checked = e.target.checked
                const oldValue = getFacetValues(local[filter] || [])

                const op = getFacetOperator(local[filter])
                let newValue: Facet = checked ? oldValue.concat(value) : oldValue.filter((v) => v !== value)

                if (op) {
                  newValue = { [op]: newValue } as Facet
                }

                const newState = { ...local, [filter]: newValue }

                // remove the filter if the value is an empty array
                if (Array.isArray(newValue) && newValue.length === 0) {
                  newState[filter] = undefined
                }

                props.onChange(newState)

                return newState
              })
            }}
          >
            <Flex alignItems="center" gap={2}>
              <Flex alignItems="center" gap={1}>
                {display.icon && (
                  <Box display="flex" flexShrink={0}>
                    <Iconify icon={display.icon} size={22} />
                  </Box>
                )}

                <Text minWidth={0} flex={1} fontSize="sm">
                  {display.label}
                </Text>
              </Flex>

              {count > 0 && (
                <Text color="purple.600" fontSize="sm">
                  {count.toLocaleString()}
                </Text>
              )}
            </Flex>
          </Checkbox>
        )
      })}
    </Flex>
  )
}

interface LastSeenFilterProps {
  current: string | null | undefined
  presets: Record<string, string>
  facetValuesPath?: string
  facetFilters: FacetFilters
  onChange: (range: string) => void
}

export const LastSeenFilter = (props: LastSeenFilterProps) => {
  const { current, presets, facetFilters } = props
  const facet = 'last_seen_at' as string
  const facetPath = props.facetValuesPath ?? '/accounts/facet-values'
  const facetQuery = facetQueryString(omit(facetFilters, facet), 'facets')
  const path = mergeQuery(facetPath, `?${facetQuery.join('&')}`)

  const { data } = useFieldValues(facet, path)

  return (
    <Flex direction="column" alignItems="stretch" paddingY={2} paddingX={2}>
      {Object.entries(presets).map(([key, label]) => (
        <MenuItem
          key={`${key}-${label}-range`}
          sectionKey={key}
          badge={
            key === current ? <Icon as={IconCheck} flex="none" color="purple.600" boxSize={4} ml="auto" /> : undefined
          }
          count={key === 'all' ? data?.exists : data?.values?.find((v) => v.key === key)?.doc_count}
          onClick={() => props.onChange(key)}
        >
          {label}
        </MenuItem>
      ))}
    </Flex>
  )
}

interface RadioFilterProps extends FilterProps {
  isBoolean?: boolean
  onChange: (appliedFilters: FacetFilters) => void
}

const orderedBooleanValues = (values) => {
  const initial = { true: 0, false: 0 }

  for (const key of Object.keys(values)) {
    initial[key] = values[key]
  }

  return initial
}

export const RadioFilter = (props: RadioFilterProps) => {
  const { values, facetFilters, filter, isBoolean } = props

  const [options, setOptions] = React.useState(isBoolean ? orderedBooleanValues(values) : values)
  const [loading, setLoading] = useState(false)
  const [localState, setLocalState] = useState(facetFilters)

  useEffect(() => {
    setOptions(isBoolean ? orderedBooleanValues(values) : values)
    setLoading(false)
    setLocalState(facetFilters)
  }, [values, facetFilters, isBoolean])

  const hasState = localState[filter]

  const currentValues = hasState ? getFacetValues(localState[filter]) : getFacetValues(facetFilters[filter])
  const currentValue = currentValues?.[0]

  const facetPath = props.facetValuesPath ?? '/accounts/facet-values'
  const facetQuery = facetQueryString(omit(facetFilters, filter), 'facets')
  const range = useSearchParam('range')
  const path = projectPath(
    mergeQuery(facetPath, `?property=${filter}&range=${props.range ?? range ?? ''}&${facetQuery.join('&')}`)
  )

  useEffect(() => {
    let canceled = false
    setLoading(true)

    concurrentGET<FacetValuesResponse>(path)
      .then((res) => {
        if (canceled) {
          return
        }

        setOptions((existing) => {
          const opts: ValueCounts = { ...existing }

          for (const option of res.values ?? []) {
            opts[option.key] = option.doc_count
          }

          return opts
        })

        setLoading(false)
      })
      .catch((_error) => {
        if (canceled) {
          return
        }
        setLoading(false)
      })

    return () => {
      canceled = true
    }
  }, [path])

  return (
    <Stack spacing={4} borderWidth={0} bg="white" position="relative" opacity={loading ? 0.5 : 1}>
      <RadioGroup
        name={`${filter}-radio`}
        value={currentValue}
        onChange={(nextValue) => {
          setLocalState((local) => {
            return {
              ...local,
              [filter]: [nextValue]
            }
          })
        }}
      >
        <Stack>
          {Object.entries(options).map(([value, count]) => (
            <Flex key={`${filter}:${value}:${count}`} alignItems="center" gap={2}>
              <Radio value={value} size="sm">
                {titleize(value)}
              </Radio>
              {!props.hideCount && (
                <Text color={count > 0 ? 'purple.600' : 'gray.500'} fontSize="sm">
                  {count.toLocaleString()}
                </Text>
              )}
            </Flex>
          ))}
        </Stack>
      </RadioGroup>

      <HStack justifyContent="flex-end">
        <Button
          size="sm"
          colorScheme="purple"
          isLoading={loading}
          isDisabled={Object.keys(localState).length === 0}
          onClick={() => {
            setLoading(true)
            props.onChange(localState)
          }}
        >
          Apply Filters
        </Button>
      </HStack>
    </Stack>
  )
}

export function getRangeValues(value: RangeFacet | string) {
  let start
  let end
  let eq: number | undefined

  if (typeof value === 'string') {
    const matches = value.match(/(\d+(?:\.\d+)?)\.\.(\d+(?:\.\d+)?)/)
    if (matches) {
      start = matches[1]
      end = matches[2]
    } else {
      eq = Number(value)
    }
  } else if (typeof value === 'number') {
    eq = value
  } else if (value) {
    start = value.gte
    end = value.lte
  }

  return {
    eq,
    gte: start ? parseFloat(start) : undefined,
    lte: end ? parseFloat(end) : undefined
  }
}

type Modifiers = FacetOperator | 'any' | 'equal' | 'between' | 'prefix' | 'contains'

interface ValueModifierProps<T extends NonNullable<Modifiers>> {
  onChange: (value: T | null) => void
  modifier: T | null
  allowedModifiers?: T[]
  modifierLabels?: Record<string, string>
  disabled?: boolean
}

function getModifierLabel(modifier: string) {
  switch (modifier) {
    case 'any':
      return 'is any of'
    case 'all':
      return 'is all of'
    case 'not':
      return 'is not'
    case 'exists':
      return 'exists'
    case 'not_exists':
      return 'does not exist'
    case 'equal':
      return 'is equal to'
    case 'between':
      return 'is between'
    case 'lte':
      return 'is less than or equal'
    case 'gte':
      return 'is greater than or equal'
    case 'prefix':
      return 'starts with'
    case 'contains':
      return 'contains'
    default:
      return modifier
  }
}

export function ValueModifier<T extends NonNullable<Modifiers>>(props: ValueModifierProps<T>) {
  const modifiers = props.allowedModifiers ?? ['any', 'all', 'not', 'exists', 'not_exists']

  return (
    <Select
      isDisabled={props.disabled}
      onChange={(e) => {
        if (e.target.value === 'any') {
          props.onChange(null)
        } else {
          props.onChange(e.target.value as T)
        }
      }}
      value={props.modifier ?? undefined}
      size="sm"
      rounded={'md'}
      bg="white"
      width="100%"
    >
      {modifiers.map((modifier) => (
        <option key={modifier} value={modifier as string}>
          {props.modifierLabels?.[modifier] || getModifierLabel(modifier)}
        </option>
      ))}
    </Select>
  )
}

interface UnitSelectProps {
  defaultValue?: string
  value?: string
  units: string[]
  onChange?: (value: string) => void
}

const UnitSelect = (props: UnitSelectProps) => {
  return (
    <Select
      onChange={(e) => props.onChange?.(e.target.value)}
      defaultValue={props.defaultValue}
      value={props.value}
      size="sm"
      bg="white"
      width="auto"
      flex="none"
      minW="85px"
    >
      {props.units.map((unit) => (
        <option key={unit} value={unit}>
          {unit}
        </option>
      ))}
    </Select>
  )
}

type ConversionFn = (value?: number | null, unit?: string) => number | null | undefined

interface RangeStatsFilterProps extends FilterProps {
  isOpen: boolean
  initialUnit?: string
  units?: string[]
  onlyIntegers?: boolean
  onChangeUnit?: (unit: string) => void
  convertToValue?: ConversionFn
  convertToQuery?: ConversionFn
  onChange: (filters: FacetFilters) => void
  allowedModifiers?: NonNullable<Modifiers>[]
}

const defaultConvertToValue: ConversionFn = (value?: number | null) => value
const defaultConvertToQuery: ConversionFn = (value?: number | null) => value

const getInteger = (v) => (v ? parseInt(v, 10) : undefined)
const getFloat = (v) => (v ? parseFloat(v) : undefined)

const getRangeModifier = (range: ReturnType<typeof getRangeValues>): 'equal' | 'gte' | 'lte' | 'between' => {
  if (range.gte && range.lte) {
    return 'between'
  } else if (range.gte) {
    return 'gte'
  } else if (range.lte) {
    return 'lte'
  } else {
    return 'equal'
  }
}

type RangeModifiers = 'equal' | 'gte' | 'lte' | 'between' | 'exists' | 'not_exists'

export const RangeStatsFilter = (props: RangeStatsFilterProps) => {
  const { filter, isOpen, allowedModifiers } = props

  const values: RangeFacet | null = useMemo(() => {
    return (props.facetFilters[filter] as RangeFacet) ?? null
  }, [props.facetFilters, filter])

  const [loading, setLoading] = useState(true)
  const [equal, setEqual] = useState<number | null | undefined>()
  const [lte, setLte] = useState<number | null | undefined>()
  const [gte, setGte] = useState<number | null | undefined>()
  const [min, setMin] = useState<number | null>(null)
  const [max, setMax] = useState<number | null>(null)
  const facetPath = props.facetValuesPath ?? '/accounts/facet-values'
  const range = useSearchParam('range')

  const [unit, setUnit] = useState<string | undefined>(props.initialUnit)
  const convertToLocal = useLatestRef(props.convertToValue || defaultConvertToValue)
  const convertToQuery = useLatestRef(props.convertToQuery || defaultConvertToQuery)
  const unitRef = useLatestRef(unit)

  useEffect(
    () => {
      const existing = getRangeValues(values)
      setEqual(convertToLocal.current(existing.eq, unitRef.current))
      setLte(convertToLocal.current(existing.lte, unitRef.current))
      setGte(convertToLocal.current(existing.gte, unitRef.current))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values]
  )

  useEffect(() => {
    let canceled = false

    concurrentGET<FacetValuesResponse>(
      projectPath(mergeQuery(facetPath, `?property=${filter}&range=${props.range ?? range ?? ''}&facet_type=stats`))
    ).then((res) => {
      if (canceled) {
        return
      }

      if (typeof res.stats!.max === 'number') {
        setMax(res.stats!.max)
      }

      if (typeof res.stats!.min === 'number') {
        setMin(res.stats!.min)
      }
      setLoading(false)
    })

    return () => {
      canceled = true
    }
  }, [facetPath, filter, range, props.range])

  useEffect(() => {
    if (isOpen) {
      setLoading(false)
    }
  }, [isOpen])

  // Must have at least one of these to apply filters
  const hasValues = [lte, gte, equal].some((value) => typeof value === 'number')

  const inBounds = (value: number, min: number | null, max: number | null) => {
    if (typeof min === 'number' && value < min) {
      return false
    }

    if (typeof max === 'number' && value > max) {
      return false
    }

    return true
  }

  const parseNumber = props.onlyIntegers ? getInteger : getFloat
  const [modifier, setModifier] = useState<RangeModifiers>(getRangeModifier(getRangeValues(values)))

  return (
    <VStack spacing="3" width="100%" align="center" paddingY={4}>
      <Flex w="100%" px="4">
        <ValueModifier
          allowedModifiers={allowedModifiers || ['equal', 'between', 'gte', 'lte', 'exists', 'not_exists']}
          onChange={(modifier) => {
            setModifier((modifier || 'equal') as RangeModifiers)
          }}
          modifier={modifier}
        />
      </Flex>
      {loading ? (
        <Center minHeight="150px">
          <Spinner color="purple.500" thickness="1.5px" />
        </Center>
      ) : (
        <Stack px="4" spacing="6" fontSize="sm" w="100%">
          {modifier === 'equal' && (
            <FormControl size="sm">
              <HStack>
                <Icon as={IconArrowForward} color="purple.500" boxSize={5} />
                <Input
                  size="sm"
                  type="number"
                  value={equal ?? ''}
                  onChange={(e) => {
                    setEqual(e.target.value ? parseFloat(e.target.value) : undefined)
                  }}
                />
                {(props.units ?? [].length > 0) && <UnitSelect units={props.units!} value={unit} onChange={setUnit} />}
              </HStack>
              {typeof equal === 'number' && !inBounds(equal, min, max) && (
                <Text fontSize="xs" color="orange.500">
                  Note: this value is outside the bounds of values seen so far ({min}..{max})
                </Text>
              )}
            </FormControl>
          )}
          {modifier === 'between' && (
            <HStack spacing={1}>
              <Icon as={IconArrowForward} color="purple.500" boxSize={5} />
              <Input
                type="number"
                size="sm"
                minWidth="60px"
                value={gte ?? ''}
                onChange={(e) => setGte(parseNumber(e.target.value))}
              />
              <Text fontSize="sm">and</Text>
              <Input
                type="number"
                size="sm"
                minWidth="60px"
                value={lte ?? ''}
                onChange={(e) => setLte(parseNumber(e.target.value))}
              />
              {(props.units ?? [].length > 0) && <UnitSelect units={props.units!} value={unit} onChange={setUnit} />}
            </HStack>
          )}
          {modifier === 'gte' && (
            <FormControl size="sm">
              <HStack>
                <Icon as={IconArrowForward} color="purple.500" boxSize={5} />
                <InputGroup size="sm">
                  <InputLeftElement pointerEvents="none">
                    <Icon as={IconMathEqualGreater} color="gray.400" />
                  </InputLeftElement>
                  <Input type="number" value={gte ?? ''} onChange={(e) => setGte(parseNumber(e.target.value))} />
                </InputGroup>
                {(props.units ?? [].length > 0) && <UnitSelect units={props.units!} value={unit} onChange={setUnit} />}
              </HStack>
              {typeof gte === 'number' && !inBounds(gte, min, max) && (
                <Text fontSize="xs" color="orange.500">
                  Note: this value is outside the bounds of values seen so far ({min}..{max})
                </Text>
              )}
            </FormControl>
          )}
          {modifier === 'lte' && (
            <FormControl size="sm">
              <HStack>
                <Icon as={IconArrowForward} color="purple.500" boxSize={5} />
                <InputGroup size="sm">
                  <InputLeftElement pointerEvents="none">
                    <Icon as={IconMathEqualLower} color="gray.400" />
                  </InputLeftElement>
                  <Input type="number" value={lte ?? ''} onChange={(e) => setLte(parseNumber(e.target.value))} />
                </InputGroup>
                {(props.units ?? [].length > 0) && <UnitSelect units={props.units!} value={unit} onChange={setUnit} />}
              </HStack>
              {typeof lte === 'number' && !inBounds(lte, min, max) && (
                <Text fontSize="xs" color="orange.500">
                  Note: this value is outside the bounds of values seen so far ({min}..{max})
                </Text>
              )}
            </FormControl>
          )}

          <Flex justifyContent={'flex-end'}>
            <Button
              size="sm"
              colorScheme="purple"
              isLoading={loading}
              isDisabled={!hasValues && !['exists', 'not_exists'].includes(modifier)}
              onClick={() => {
                let filterValues: null | string | number | Record<string, any> = null

                if (modifier === 'exists') {
                  filterValues = { exists: true }
                  props.onChange({
                    ...props.facetFilters,
                    [filter]: filterValues
                  })
                  return
                } else if (modifier === 'not_exists') {
                  filterValues = { exists: false }
                  props.onChange({
                    ...props.facetFilters,
                    [filter]: filterValues
                  })
                  return
                } else if (modifier === 'equal') {
                  filterValues = convertToQuery.current(equal, unit) ?? null
                } else if (modifier === 'gte' && typeof gte === 'number') {
                  filterValues = { gte: convertToQuery.current(gte, unit) }
                } else if (modifier === 'lte' && typeof lte === 'number') {
                  filterValues = { lte: convertToQuery.current(lte, unit) }
                } else if (modifier === 'between') {
                  filterValues = {}

                  if (typeof gte === 'number') {
                    filterValues.gte = convertToQuery.current(gte, unit)
                  }

                  if (typeof lte === 'number') {
                    filterValues.lte = convertToQuery.current(lte, unit)
                  }

                  // Use shorthand when both sides are present
                  if ('lte' in filterValues && 'gte' in filterValues) {
                    filterValues = `${filterValues.gte}..${filterValues.lte}`
                  }
                }

                if (filterValues !== null) {
                  setLoading(true)
                  props.onChange({
                    ...props.facetFilters,
                    [filter]: filterValues
                  })
                }
              }}
            >
              Apply Filters
            </Button>
          </Flex>
        </Stack>
      )}
    </VStack>
  )
}
