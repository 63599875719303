export function validDomain(value: string): boolean {
  if (!value) return false
  if (typeof value !== 'string') return false
  value = value.trim()

  if (value.startsWith('http://') || value.startsWith('https://')) {
    try {
      const url = new URL(value)
      return !!url.hostname
    } catch {
      return false
    }
  }

  // Check if it's a domain
  const domainPattern = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i
  return domainPattern.test(value) && !value.includes('@')
}
