import {
  Badge,
  Stack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import React from 'react'
import { AdminBreadcrumb } from '../..'
import PageLayout from '../../../../ui/PageLayout'
import PageTitle from '../../../../ui/PageTitle'
import { JSONTree } from '../../../../ui/json-tree'

interface Props {
  name: string
  stats: {
    total: number
    matches: number
    ignored: number
    errored: number
  }
  results: any[]
}

export default function Index(props: Props) {
  return (
    <PageLayout>
      <AdminBreadcrumb paths={[{ path: '/admin/reports/experiments', title: 'Code Science Experiments' }]} />

      <PageTitle>Code Science Experiments - {props.name}</PageTitle>

      <Stack spacing="12">
        <StatGroup>
          <Stat>
            <StatLabel>Total runs</StatLabel>
            <StatNumber>{props.stats.total}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Matches</StatLabel>
            <StatNumber>{props.stats.matches}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Errored</StatLabel>
            <StatNumber>{props.stats.errored}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Ignored</StatLabel>
            <StatNumber>{props.stats.ignored}</StatNumber>
          </Stat>
        </StatGroup>
        <TableContainer fontSize="sm" w="100%">
          <Table size="sm" w="100%" height="1px">
            <Thead>
              <Tr>
                <Th width={250}>Metadata</Th>
                <Th>Control</Th>
                <Th>Candidates</Th>
              </Tr>
            </Thead>
            <Tbody bg="white">
              {props.results.map((result) => (
                <Tr key={result.id}>
                  <Td>
                    <Badge colorScheme={colorForOutcome(result.outcome)}>{result.captured_at}</Badge>
                    <JSONTree
                      data={{
                        context: result.context,
                        execution_order: result.execution_order,
                        outcome: result.outcome,
                        tags: result.tags
                      }}
                    />
                  </Td>
                  <Td>
                    <JSONTree data={result.observations.control} />
                  </Td>
                  <Td>
                    {result.observations.candidates.length > 1 ? (
                      <JSONTree data={result.observations.candidates} />
                    ) : (
                      <JSONTree data={result.observations.candidates[0]} />
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </PageLayout>
  )
}

function colorForOutcome(outcome: string): string {
  switch (outcome) {
    case 'errored':
      return 'red'
    case 'mismatched':
      return 'orange'
    case 'ignored':
      return 'yellow'
    default:
      return 'green'
  }
}
