import { Button, Flex, useClipboard } from '@chakra-ui/react'
import { IconCopy } from '@tabler/icons-react'
import Highlight, { defaultProps, Language } from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/vsLight'
import React from 'react'
import { LightBgCard } from './Card'

interface Props {
  code: string
  language: Language
}

export const CodeBlock: React.FunctionComponent<Props> = (props) => {
  const { hasCopied, onCopy } = useClipboard(props.code)

  return (
    <LightBgCard p="4" rounded="sm" shadow={'none'}>
      <Highlight {...defaultProps} theme={theme} code={props.code} language={props.language}>
        {({ className, style, tokens, getLineProps, getTokenProps }) => {
          return (
            <pre className={className} style={{ ...style, overflow: 'auto' }}>
              <code>
                {tokens.map((line, idx) => {
                  return (
                    <div key={idx} {...getLineProps({ line, key: `line-${idx}` })}>
                      {line.map((token, i) => {
                        return <span key={i} {...getTokenProps({ token, key: `token-${i}` })} />
                      })}
                    </div>
                  )
                })}
              </code>
            </pre>
          )
        }}
      </Highlight>
      <Flex justifyContent={'flex-end'}>
        <Button variant={'ghost'} colorScheme="purple" size="sm" leftIcon={<IconCopy size={14} />} onClick={onCopy}>
          {hasCopied ? 'Copied!' : 'Copy'}
        </Button>
      </Flex>
    </LightBgCard>
  )
}
