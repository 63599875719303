import {
  ButtonProps,
  Flex,
  Link,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text
} from '@chakra-ui/react'
import React from 'react'
import { membersPath } from '../members/path-helpers'

export function SeatSelector(props: {
  colorScheme: ButtonProps['colorScheme']
  setSeats: (seats: number) => void
  seats: number
  minSeats: number
}) {
  return (
    <Stack spacing="4" rounded={'md'}>
      <Stack spacing="4" pb="2">
        <Text fontSize={'sm'}>How many people would you like to invite to your workspace?</Text>

        <NumberInput
          value={props.seats}
          onChange={(_, val) => {
            if (val < props.minSeats) {
              return
            }

            props.setSeats(val)
          }}
          min={0}
          max={40}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>

        <Flex direction={'column'} fontSize={'xs'} alignItems="flex-end">
          <Text>
            There are currently <strong>{props.minSeats}</strong> seats in your plan.
          </Text>
          <Link href={membersPath()}>Manage your workspace members here.</Link>
        </Flex>
      </Stack>
    </Stack>
  )
}
