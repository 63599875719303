import React, { useCallback, useState } from 'react'
import { projectPath } from '../../../ui/ProjectsContext'
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'

interface EditTemplateModalProps extends UseDisclosureProps {
  template: any
}

export function EditTemplateModal({ template, ...props }: EditTemplateModalProps) {
  const disclosure = useDisclosure(props)
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = useCallback(() => {
    setSubmitting(true)
  }, [])

  return (
    <Modal {...disclosure} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Rename your template</ModalHeader>
        <ModalBody>
          <form
            id="edit-template-form"
            method="POST"
            action={projectPath(`/mission-control/templates/${template.id}`)}
            onSubmit={onSubmit}
          >
            <AuthenticityToken />
            <input type="hidden" name="_method" value="patch" />

            <Stack spacing={6}>
              <FormControl>
                <FormLabel htmlFor="template[name]">Template name</FormLabel>
                <Input
                  autoFocus
                  size="sm"
                  defaultValue={template.name}
                  autoComplete="off"
                  placeholder="Add a descriptive name for your template"
                  name="template[name]"
                  isRequired
                  id="template[name]"
                />
              </FormControl>
            </Stack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" variant="outline" mr={3} onClick={disclosure.onClose}>
            Cancel
          </Button>
          <Button size="sm" type="submit" colorScheme="purple" form="edit-template-form" isLoading={submitting}>
            Update template
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
