import {
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Img,
  Input,
  Link,
  Select,
  Stack,
  Switch,
  Text,
  Textarea
} from '@chakra-ui/react'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import { uniq } from 'lodash'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useApp } from '../../data/use-app'
import { AccountSelector, PartialAccount } from '../../ui/AccountSelector'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import { Card } from '../../ui/Card'
import CompanyAvatar from '../../ui/CompanyAvatar'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { useCurrentUser } from '../../ui/UserContext'
import useUpdateEffect from '../../ui/useUpdateEffect'
import { FacetFilters } from '../accounts'
import { Toggle } from '../accounts/components/Toggle'
import { accountPath } from '../accounts/lib/account-path'
import { PopupConnectDialog } from '../apps/components/ConnectOauthAppDialog'
import { channelLogos } from '../follow_rules/components/delivery-setup'
import { Persona, Personas, PersonaSelector } from './personas'

interface Props {
  setting?: AutoProspectingSetting
}

export interface AutoProspectingSetting {
  include_identified?: boolean
  num_prospects?: number
  data_sources?: string[]
  persona_prompt?: string
  persona_id?: string
}

export function AutoProspectDataSources(props: {
  setting?: AutoProspectingSetting
  onChange?: (setting: AutoProspectingSetting) => void
  namespace?: string
}) {
  const namespace = props.namespace ?? 'prospect_settings'
  const apollo = useApp('apollo')

  const reconnect = useMemo(
    () => !apollo.data?.connected || !apollo.data?.valid,
    [apollo.data?.connected, apollo.data?.valid]
  )

  return (
    <Stack spacing="4" w="100%">
      <Stack>
        <Heading size="sm" fontWeight={'semibold'}>
          Data Sources
        </Heading>
        <Text fontSize="sm">Select which data sources Koala should use to find your prospects.</Text>
      </Stack>

      <Stack w="100%" fontSize={'sm'} as={Card} spacing="4" p="6" pt="5">
        <HStack w="100%" justifyContent={'space-between'}>
          <HStack spacing="2">
            <CompanyAvatar domain={'getkoala.com'} size="xs" rounded={'full'} />
            <Heading size="sm" fontWeight={'semibold'}>
              Koala Prospecting
            </Heading>
          </HStack>
          <Switch
            isChecked
            onChange={(e) => {
              const dataSources = (
                e.target.checked
                  ? uniq([...(props.setting?.data_sources ?? []), 'koala'])
                  : (props.setting?.data_sources ?? []).filter((source) => source !== 'koala')
              ) as string[]

              props.onChange?.({
                ...props.setting,
                data_sources: dataSources
              })
            }}
          />
          <input type="hidden" name={`${namespace}[data_sources][]`} value="koala" />
        </HStack>
        <Text>
          Use Koala Prospecting to find new prospects from Koala's own database and several other data providers.
        </Text>
      </Stack>

      <Stack w="100%" fontSize={'sm'} as={Card} spacing="4" p="6" pt="5">
        <HStack w="100%" justifyContent={'space-between'}>
          <HStack spacing="2">
            <CompanyAvatar domain={'apollo.io'} size="xs" rounded="full" />
            <Heading size="sm" fontWeight={'semibold'}>
              Apollo
            </Heading>
          </HStack>
          <Switch
            isChecked={props.setting?.data_sources?.includes('apollo')}
            isDisabled={reconnect}
            onChange={(e) => {
              const dataSources = (
                e.target.checked
                  ? uniq([...(props.setting?.data_sources ?? []), 'apollo'])
                  : (props.setting?.data_sources ?? []).filter((source) => source !== 'apollo')
              ) as string[]

              props.onChange?.({
                ...props.setting,
                data_sources: dataSources
              })
            }}
          />
          {props.setting?.data_sources?.includes('apollo') && (
            <input type="hidden" name={`${namespace}[data_sources][]`} value="apollo" />
          )}
        </HStack>
        <Text>
          Use your own Apollo API key to unlock prospect emails. You can prospect unlimited contacts for free using your
          Apollo account, and only use Apollo credits when unlocking an email.
        </Text>
        {reconnect && (
          <>
            <Divider />
            <PopupConnectDialog app_id={'apollo'} onConnected={() => apollo.refetch()}>
              {({ onStart }) => (
                <Stack spacing="2">
                  <Center>
                    <Button
                      leftIcon={<Img w="4" src={channelLogos.apollo} />}
                      size="sm"
                      variant={'outline'}
                      onClick={onStart}
                    >
                      Connect Apollo
                    </Button>
                  </Center>
                  <Text fontSize="xs" textAlign="center">
                    Connect your Apollo account to start prospecting with Apollo data.
                  </Text>
                </Stack>
              )}
            </PopupConnectDialog>
          </>
        )}
      </Stack>
    </Stack>
  )
}

export function AutoProspectPersona({ onChange }: { onChange?: (personas: Persona[]) => void }) {
  return (
    <Stack>
      <Stack>
        <Heading size="sm" fontWeight={'semibold'}>
          ICP Personas
        </Heading>
        <Text fontSize="sm">
          Describe what your ideal buyer looks like. Feel free to include as many personas as you'd like.
        </Text>
      </Stack>

      <Stack w="100%" py="4" pb="2">
        <Personas onChange={onChange} />
      </Stack>
    </Stack>
  )
}

export default function Settings(props: Props) {
  const [setting, setSetting] = useState<AutoProspectingSetting>(
    props.setting ?? { data_sources: [], persona_prompt: '' }
  )
  const [selectedAccount, setSelectedAccount] = useState<PartialAccount | null>(null)

  return (
    <PageLayout>
      <HStack w="100%" spacing="12" alignItems={'baseline'} position="relative">
        <Flex flex="2">
          <form action={projectPath('/settings/prospects')} method="post">
            <AuthenticityToken />
            <Stack spacing="8" w="100%">
              <SettingsBreadCrumb paths={[{ title: 'Buyer Personas', path: projectPath('/settings/prospects') }]} />

              <SettingsHeader divider={false}>
                <HStack justifyContent={'space-between'} w="100%">
                  <Stack>
                    <PageTitle>Persona Settings</PageTitle>
                    <PageDescription>
                      Define your ideal buyer personas and let Koala find prospects that match your criteria.
                    </PageDescription>
                  </Stack>
                </HStack>
              </SettingsHeader>

              <Stack w="100%" spacing="12">
                <AutoProspectPersona />

                <Divider />

                <AutoProspectDataSources setting={setting} onChange={(setting) => setSetting(setting)} />

                <Button type="submit" colorScheme="purple" w="100%">
                  Save Settings
                </Button>
              </Stack>
            </Stack>
          </form>
        </Flex>

        <ProspectorPreview
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
          autoProspectingSetting={setting}
        />
      </HStack>
    </PageLayout>
  )
}

export function asPrompt(persona?: Persona | null) {
  if (!persona) {
    return ''
  }

  if (persona.persona_type === 'ai') {
    return persona.description ?? ''
  } else {
    return `Name: ${persona.name}\n Filters: ${JSON.stringify(persona.filters, null, ' ')}`
  }
}

export function ProspectorPreview(props: {
  selectedAccount: PartialAccount | null
  setSelectedAccount: (acc: PartialAccount | null) => void
  selectedPersona?: Persona
  autoProspectingSetting: AutoProspectingSetting
  facets?: FacetFilters
}) {
  const user = useCurrentUser()
  const { selectedAccount, setSelectedAccount, autoProspectingSetting } = props
  const [isLoading, setIsLoading] = useState(false)
  const [messages, setMessages] = useState<string[]>([])
  const [model, setModel] = useState('gpt-4o')
  const [k, setK] = useState(16)
  const [temperature, setTemperature] = useState('0.5')
  const [selectedPersona, setSelectedPersona] = useState<Persona | null>(props.selectedPersona ?? null)
  const [prompt, setPrompt] = useState<string>(asPrompt(props.selectedPersona))

  const controllerRef = useRef<AbortController>()

  useUpdateEffect(() => {
    if (autoProspectingSetting.persona_prompt) {
      setPrompt(autoProspectingSetting.persona_prompt)
    }
  }, [props.autoProspectingSetting.persona_prompt])

  const onPreview = useCallback(() => {
    if (!selectedAccount) {
      return
    }

    controllerRef.current?.abort()
    controllerRef.current = new AbortController()

    setIsLoading(true)

    const urlParams = new URLSearchParams()
    if (selectedPersona?.id) {
      urlParams.append('prospect_settings[persona_id]', selectedPersona?.id ?? '')
    }

    const dataSources = autoProspectingSetting.data_sources ?? []
    dataSources.forEach((source) => {
      urlParams.append('prospect_settings[data_sources][]', source)
    })

    const path = accountPath(
      {
        domain: selectedAccount.domain
      },
      `/prospects/ai-prompt?${urlParams.toString()}&model=${model}&k=${k}&temperature=${temperature}&prompt=${prompt}`
    )

    fetchEventSource(path, {
      openWhenHidden: true,
      signal: controllerRef.current.signal,
      onopen: async (res) => {
        setMessages([])
        if (res.ok && res.status === 200) {
          console.log('Connection made ', res)
        } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
          console.log('Client side error ', res)
        }
      },
      onmessage: (e) => {
        setMessages((prev) => [...prev, e.data])
      },
      onerror: () => {
        setIsLoading(false)
        console.log('Error occurred')
      },
      onclose: () => {
        setIsLoading(false)
        console.log('Connection closed')
      }
    })
  }, [selectedAccount, autoProspectingSetting, model, k, temperature, prompt, selectedPersona?.id])

  return (
    <Stack spacing="4" w="100%" flex="1.5">
      <Stack>
        <Heading size="sm" fontWeight={'semibold'}>
          Playground
        </Heading>
        <Text fontSize="sm">
          Pick a company to try out Koala's AI to find prospects that match your ICP persona. You can use this to
          preview prospects from any company.
        </Text>
      </Stack>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          if (!selectedAccount) {
            onPreview()
          }
        }}
      >
        <Stack>
          <Stack>
            <Stack flex="1">
              <AccountSelector
                facets={props.facets}
                selectedAccount={selectedAccount}
                onChange={(acc) => setSelectedAccount(acc)}
                showClearButton
              />
              <PersonaSelector
                onSelect={(persona) => {
                  setSelectedPersona(persona)
                  setPrompt(asPrompt(persona))
                }}
                selectedPersona={selectedPersona}
              />
            </Stack>
            <HStack justifyContent={'flex-end'}>
              <Button
                px="4"
                colorScheme={'purple'}
                type="submit"
                onClick={onPreview}
                isLoading={isLoading}
                disabled={!selectedAccount || !selectedPersona || isLoading}
              >
                Preview
              </Button>
              <Button
                px="4"
                colorScheme={'gray'}
                type="submit"
                onClick={() => {
                  controllerRef.current?.abort()
                  setIsLoading(false)
                }}
                isDisabled={!isLoading}
              >
                Stop
              </Button>
            </HStack>
          </Stack>
          {user.isInternalUser && (
            <Toggle title={<Heading size="xs">Admin Settings</Heading>}>
              <Stack p="4" bg="gray.50" rounded="lg">
                <FormControl>
                  <FormLabel>Model</FormLabel>
                  <Select size="xs" bg="white" rounded="lg" value={model} onChange={(e) => setModel(e.target.value)}>
                    <option value="gpt-3.5-turbo-16k">GPT 3.5 Turbo 16k</option>
                    <option value="gpt-4o">GPT 4o</option>
                  </Select>
                </FormControl>

                <HStack>
                  <FormControl>
                    <FormLabel>k</FormLabel>
                    <Input
                      value={k}
                      onChange={(e) => setK(parseInt(e.target.value))}
                      size="xs"
                      bg="white"
                      rounded="lg"
                      type="number"
                      min={4}
                      max={36}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Temperature</FormLabel>
                    <Input
                      value={temperature}
                      onChange={(e) => setTemperature(e.target.value)}
                      size="xs"
                      bg="white"
                      rounded="lg"
                    />
                  </FormControl>
                </HStack>

                <FormControl>
                  <FormLabel>Custom Prompt</FormLabel>
                  <Textarea
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    size="xs"
                    bg="white"
                    rounded="lg"
                  />
                </FormControl>
              </Stack>
            </Toggle>
          )}
        </Stack>
      </form>
      {messages.length > 0 && (
        <Stack spacing="4" as={Card} p="6" pt="5" fontSize="xs">
          <ReactMarkdown
            components={{
              h2: ({ node, ...props }) => <Heading size="xs" {...props} />,
              li: ({ node, ...props }) => (
                <li
                  {...props}
                  style={{
                    ...props.style,
                    listStyleType: 'none'
                  }}
                />
              ),
              ul: ({ node, ...props }) => (
                <ul
                  {...props}
                  style={{
                    ...props.style
                  }}
                />
              ),
              // @ts-ignore - types are wrong
              a: ({ node, ...props }) => (
                <HStack>
                  <Link
                    {...props}
                    size="xs"
                    colorScheme={'linkedin'}
                    isExternal
                    href={props.href?.startsWith('linkedin.com') ? `https://${props.href}` : props.href}
                  />
                </HStack>
              )
            }}
          >
            {messages.join('')}
          </ReactMarkdown>
        </Stack>
      )}
    </Stack>
  )
}
