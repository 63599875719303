import { Box, Button, Flex, Link, Stack, useBreakpointValue } from '@chakra-ui/react'
import React, { useEffect, useMemo, useState } from 'react'
import router from '../../../../../lib/router'
import type { Account } from '../../../../../types/Account'
import type { App, Apps, CatalogApp } from '../../../../../types/App'
import { ProjectActions } from '../../../../../types/AppActions'
import { KQLByDay } from '../../../../../types/KQL'
import { openUpgradeFlow } from '../../../../ui/billing-banners/accounts-banner'
import { LightBgCard } from '../../../../ui/Card'
import { CardHeading } from '../../../../ui/CardHeading'
import { AiSparklesIcon } from '../../../../ui/icons'
import { ListsCard } from '../../../../ui/ListsCard'
import { useCurrentProject } from '../../../../ui/ProjectsContext'
import { SegmentedControl } from '../../../../ui/SegmentedControl'
import { AccountUpgrade, isLimitedAccount, useEntitlements } from '../../../../ui/useEntitlements'
import { useSearchParams } from '../../../../ui/useSearchState'
import { ImportAccountSideSheet } from '../../../account_views/components/ImportAccountSideSheet'
import { billingPlansPath } from '../../../billing/lib/path-helpers'
import { mergeParams } from '../../../icps/types'
import { KqlDefinition } from '../../../kql_definitions/types'
import { AutoProspectCard } from '../../../profiles/components/AutoProspectCard'
import { AccountIntentFeedV3 } from '../../../profiles/components/profile-feed'
import { ProspectorCTA } from '../../../prospector/components/ProspectorCTA'
import { accountPath } from '../../lib/account-path'
import { AccountIntegrationSidebar } from '../AccountIntegrationSidebar'
import { ActivityCard } from './ActivityCard'
import { Analytics } from './Analytics'
import { CompanyDetailsCard } from './CompanyDetailsCard'
import { GhostProfiles } from './GhostProfiles'
import { InactiveAccount } from './InactiveAccount'
import { NewsCard } from './NewsCard'
import { People } from './People'
import { SummaryCard } from './SummaryCard'
import { TechStackCard } from './TechStackCard'
import { TraitsCard } from './TraitsCard'

const tabs = [
  { tab: 'activity', label: 'Activity' },
  { tab: 'people', label: 'People' },
  { tab: 'prospects', label: 'Prospector', icon: <AiSparklesIcon color="accent.500" boxSize={4} /> },
  { tab: 'company', label: 'Company' }
]

interface Props {
  account: Account
  actions: ProjectActions
  apps: Apps
  catalog: CatalogApp[]
  signalsByDay: KQLByDay[]
  signalDefns: KqlDefinition[]
  selectedSignals?: string[]
  defaultColumns?: string[]
}

export default function DetailsPage(props: Props) {
  const { searchParams, setSearchParam } = useSearchParams({
    tab: tabs[0].tab
  })

  const tab = useMemo(() => searchParams.tab as string, [searchParams.tab])
  const setTab = (tab: string) => setSearchParam('tab', tab)

  const [importApp, setImportApp] = React.useState<App | undefined>(undefined)

  const resetImport = React.useCallback(async () => {
    setImportApp(undefined)
    // refresh current state
    router.visit(accountPath(props.account))
  }, [props.account])

  const entitlements = useEntitlements()
  const isRedacted = useMemo(() => {
    if (!entitlements) return false
    return isLimitedAccount(entitlements, props.account)
  }, [entitlements, props.account])

  useEffect(() => {
    if (isRedacted) {
      window.history.replaceState(
        {},
        'Locked Account',
        accountPath({
          company: {
            domain: props.account.company_id
          }
        })
      )
    }
  }, [isRedacted, props.account.company_id])

  const smallScreen = useBreakpointValue({ base: true, lg: false, xl: false })
  const stacked = useBreakpointValue({ base: true, sm: true, md: false, lg: false, xl: false })
  // const tabIndex = tabs.findIndex((t) => t.tab === tab)

  return (
    <Flex gap={[6, 6, 6, 6, 8]} alignItems="flex-start">
      <Stack flexGrow={1} flexShrink={1} minWidth="320px" maxWidth="100%" spacing={6}>
        <Stack spacing={4}>
          {isRedacted ? (
            <LightBgCard justifyContent={'center'} alignItems={'center'} borderColor={'orange.400'} bg="orange.50">
              <AccountUpgrade
                type="Account"
                colorScheme={'orange'}
                element={props.account}
                showLock
                entitlements={entitlements}
              />
            </LightBgCard>
          ) : (
            <SummaryCard
              account={props.account}
              apps={props.apps}
              scoringEnabled={entitlements?.icp_scoring ?? false}
            />
          )}

          {props.account.ghost && <GhostProfiles account={props.account} />}

          <ProspectorCTA account={props.account} />

          {stacked && (
            <>
              <TraitsCard object={props.account} recordType="account" initiallyCollapsed />
              <ListsCard kind="account" recordId={props.account.id} />
              <AccountIntegrationSidebar
                {...props}
                onRequestImport={setImportApp}
                isRedacted={isRedacted}
                initiallyCollapsed
              />
            </>
          )}
        </Stack>

        {/* 
        <Tabs size="sm" variant="line" index={tabIndex} onChange={(index) => setTab(tabs[index].tab)}>
          <TabList gap={4}>
            {tabs.map((t) => (
              <Tab key={t.tab} fontSize="13px" px={0}>
                {t.label}
              </Tab>
            ))}
          </TabList>
        </Tabs> 
        */}

        <SegmentedControl
          display="grid"
          width="100%"
          size={smallScreen ? 'sm' : 'md'}
          maxWidth="100%"
          alignSelf="center"
          gridTemplateColumns={tabs.map(() => `1fr`).join(' 1px ')}
        >
          {tabs.map((t) => (
            <Button
              key={t.tab}
              isActive={tab === t.tab}
              onClick={() => setTab(t.tab)}
              leftIcon={smallScreen ? undefined : t.icon}
              iconSpacing={1.5}
            >
              {t.label}
            </Button>
          ))}
        </SegmentedControl>

        <Box flex="1" paddingBottom={tab !== 'prospects' ? '50px' : undefined}>
          {tab === 'activity' ? (
            <ActivityTab
              account={props.account}
              signalDefns={props.signalDefns}
              signalsByDay={props.signalsByDay}
              selectedSignals={props.selectedSignals}
            />
          ) : tab === 'people' ? (
            <People account={props.account} defaultColumns={props.defaultColumns} />
          ) : tab === 'company' ? (
            <CompanyDetails account={props.account} isRedacted={isRedacted} />
          ) : tab === 'prospects' ? (
            <AutoProspectCard
              apps={props.apps}
              account={props.account}
              company={props.account.company}
              headerProps={{
                bg: 'gray.50'
              }}
            />
          ) : null}
        </Box>
      </Stack>

      {!stacked && (
        <Stack flex="none" width={['100%', '100%', '320px', '320px', '340px', '360px']} maxWidth="100%" spacing={4}>
          <TraitsCard object={props.account} recordType="account" initiallyCollapsed />
          <ListsCard kind="account" recordId={props.account.id} />
          <AccountIntegrationSidebar {...props} onRequestImport={setImportApp} isRedacted={isRedacted} />
        </Stack>
      )}

      {props.account.company_id && importApp && (
        <ImportAccountSideSheet
          key={props.account.company_id}
          app={importApp}
          account={props.account}
          onClose={resetImport}
        />
      )}
    </Flex>
  )
}

function CompanyDetails(props: { account: Account; isRedacted?: boolean }) {
  const project = useCurrentProject()

  if (props.isRedacted) {
    return (
      <LightBgCard>
        <CardHeading icon="https://logo.clearbit.com/clearbit.com">Company details</CardHeading>
        <Link
          href={billingPlansPath()}
          fontSize={'sm'}
          pt="2"
          onClick={(e) => {
            e.preventDefault()
            openUpgradeFlow('business')
          }}
        >
          Unlock Account to view
        </Link>
      </LightBgCard>
    )
  }

  return (
    <Stack spacing={4}>
      <CompanyDetailsCard account={props.account} />
      {props.account.company_technologies && (
        <TechStackCard
          account={{
            company_technologies: props.account.company_technologies
          }}
        />
      )}
      <Analytics account={props.account} />
      {['koala', 'vercel'].includes(project?.slug ?? '') && <NewsCard company={props.account.company} />}
    </Stack>
  )
}

function ActivityTab(props: Pick<Props, 'account' | 'signalDefns' | 'signalsByDay' | 'selectedSignals'>) {
  const [selectedSignals, setSelectedSignals] = useState<string[]>(props.selectedSignals ?? [])

  useEffect(() => {
    setSelectedSignals(props.selectedSignals ?? [])
  }, [props.selectedSignals])

  return (
    <Stack spacing={4}>
      {props.account.inactive && !props.account.ghost && <InactiveAccount account={props.account} />}

      {!props.account.inactive && (
        <ActivityCard
          focusTrend={props.account.focus_time_trend}
          pageviewTrend={props.account.page_views_trend}
          signalsByDay={props.signalsByDay}
          signalDefns={props.signalDefns}
          intent={props.account.intent}
          onSelectSignal={(id) => {
            const signalIds = Array.from(new Set([...selectedSignals, id]))
            setSelectedSignals(signalIds)
            router.visit(mergeParams(accountPath(props.account), { selected_signals: signalIds.join(',') }), {
              fetch: false
            })
          }}
        />
      )}

      <AccountIntentFeedV3
        account={props.account}
        selectedSignals={selectedSignals}
        signalDefns={props.signalDefns}
        removeSignal={(id) => {
          const signalIds = selectedSignals.filter((s) => s !== id)
          setSelectedSignals(signalIds)
          router.visit(mergeParams(accountPath(props.account), { selected_signals: signalIds.join(',') }), {
            fetch: false
          })
        }}
      />
    </Stack>
  )
}
