import { Text, TextProps } from '@chakra-ui/react'
import React from 'react'
import { Trend } from '../types'

interface TrendProps {
  stats?: Trend
  range: 'day' | 'week' | 'month' | 'all'
  format?: (val: any) => string | React.ReactElement
}

export function TrendCell(props: TrendProps & { textProps?: TextProps }) {
  const stats = props.stats
  const range = props.range
  const textProps = props.textProps
  const rangeStats = stats?.[range] || stats?.['month']

  if (!rangeStats) {
    return (
      <Text fontSize="sm" {...textProps}>
        —
      </Text>
    )
  }

  const val = rangeStats.current.value

  return (
    <Text fontSize="sm" {...textProps}>
      {props.format ? props.format(val) : val}
    </Text>
  )
}
