import { Icon, IconProps } from '@chakra-ui/react'
import React from 'react'

interface Props extends IconProps {
  size?: string | number
}

export const GitHubIcon: React.FC<Props> = ({ size, ...props }) => {
  size = size ? `${size}px` : size

  return (
    <Icon viewBox="0 0 24 24" color="#24292f" width={size} height={size} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0083 2C6.47396 2 2 6.50694 2 12.0826C2 16.5396 4.86662 20.3123 8.84338 21.6476C9.34058 21.748 9.5227 21.4306 9.5227 21.1637C9.5227 20.93 9.50631 20.1288 9.50631 19.2939C6.72225 19.895 6.14249 18.092 6.14249 18.092C5.69508 16.9235 5.03215 16.6232 5.03215 16.6232C4.12092 16.0055 5.09852 16.0055 5.09852 16.0055C6.1093 16.0723 6.63969 17.0405 6.63969 17.0405C7.53432 18.5761 8.97592 18.1422 9.55588 17.8751C9.63865 17.224 9.90394 16.7733 10.1856 16.523C7.96514 16.2893 5.62891 15.4213 5.62891 11.5484C5.62891 10.4466 6.02634 9.54523 6.65608 8.8442C6.55672 8.59386 6.20866 7.5587 6.75564 6.17322C6.75564 6.17322 7.60069 5.90608 9.5061 7.20818C10.3219 6.98747 11.1632 6.8752 12.0083 6.87426C12.8533 6.87426 13.7148 6.99123 14.5102 7.20818C16.4159 5.90608 17.2609 6.17322 17.2609 6.17322C17.8079 7.5587 17.4596 8.59386 17.3603 8.8442C18.0066 9.54523 18.3876 10.4466 18.3876 11.5484C18.3876 15.4213 16.0514 16.2725 13.8143 16.523C14.179 16.8401 14.4936 17.441 14.4936 18.3926C14.4936 19.7446 14.4773 20.8298 14.4773 21.1635C14.4773 21.4306 14.6596 21.748 15.1566 21.6478C19.1333 20.3121 22 16.5396 22 12.0826C22.0163 6.50694 17.526 2 12.0083 2Z"
        fill="currentColor"
      />
    </Icon>
  )
}
