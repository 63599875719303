import { projectPath } from '../../ui/ProjectsContext'

export function kqlDefinitionPath(definition: any) {
  return projectPath(`/signals/${definition.id}`)
}

export function kqlDefinitionsPath(part?: string) {
  return projectPath(`/signals${part ?? ''}`)
}

export function kqlDefinitionNewFromPath(from_definition?: string) {
  return projectPath(`/signals/new?from_definition=${from_definition}`)
}

export function kqlDefinitionsPathAPI() {
  return projectPath(`/signals.json?per_page=100`)
}

export function kqlDefinitionPathAPI(id: string) {
  return projectPath(`/signals/${id}.json`)
}
