import { diff } from 'deep-diff'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'

export function viewChanged(original, current, defaults = {}) {
  if (!current) {
    return []
  }

  const base = omitBy({ ...defaults, ...original }, isNil)
  return diff(base, omitBy(current, isNil))
}
