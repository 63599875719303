import {
  Badge,
  Button,
  Checkbox,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Table,
  TableColumnHeaderProps,
  TableContainer,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr
} from '@chakra-ui/react'
import {
  IconArrowDown,
  IconArrowUp,
  IconCircleCheckFilled,
  IconFilter,
  IconSelector,
  IconX,
  IconChevronDown
} from '@tabler/icons-react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  RowSelectionState,
  TableOptions,
  useReactTable
} from '@tanstack/react-table'
import React, { useCallback, useEffect, useState } from 'react'
import { useMedia } from 'react-use'
import { HelpTooltip } from './HelpTooltip'
import { useOverflow } from './useOverflow'

interface SortableHeaderProps extends TableColumnHeaderProps {
  info?: string
  sortBy?: string
  currentSort?: string
  columnKey?: string
  onSortChange?: (sortBy: string | undefined) => void
  onRemoveColumn?: (column: string) => void
  onFilterColumn?: (column: string) => void
  tooltip?: boolean
}

export function SortableHeader({
  info,
  sortBy,
  columnKey,
  currentSort,
  onSortChange,
  onRemoveColumn,
  onFilterColumn,
  children,
  ...props
}: SortableHeaderProps) {
  const sortable = sortBy && typeof onSortChange === 'function'
  const removable = columnKey && typeof onRemoveColumn === 'function'
  const filterable = columnKey && typeof onFilterColumn === 'function'
  const [sortingBy, sortDirection] = currentSort?.split(':') ?? []
  const isSorted = sortingBy && sortingBy === sortBy
  const SortIcon = isSorted ? (sortDirection === 'asc' ? IconArrowUp : IconArrowDown) : IconSelector

  const onSortAsc = useCallback(() => {
    if (isSorted && sortDirection === 'asc') {
      onSortChange?.(undefined)
    } else {
      onSortChange?.(`${sortBy}:asc`)
    }
  }, [sortBy, isSorted, sortDirection, onSortChange])

  const onSortDesc = useCallback(() => {
    if (isSorted && sortDirection === 'desc') {
      onSortChange?.(undefined)
    } else {
      onSortChange?.(`${sortBy}:desc`)
    }
  }, [sortBy, isSorted, sortDirection, onSortChange])

  if (sortable || removable) {
    return (
      <Th {...props} _hover={{ bg: 'gray.50', '& .hover-tooltip': { opacity: 0.6 } }} cursor="pointer" px={0}>
        <Menu isLazy lazyBehavior="keepMounted" offset={[4, 6]} autoSelect={false}>
          <Tooltip label={children} placement="top" hasArrow>
            <MenuButton type="button" width="full" height="100%" px={3} fontWeight="inherit">
              <Flex width="100%" height="100%" alignItems="center" justifyContent="space-between" gap={1.5}>
                <Flex alignItems="center" gap={1}>
                  <Text as="span" lineHeight={1.2} color={isSorted ? 'gray.800' : 'inherit'}>
                    {children}
                  </Text>
                  {info && (
                    <HelpTooltip
                      className="hover-tooltip"
                      opacity={0}
                      transition="opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                      variant="info"
                    >
                      {info}
                    </HelpTooltip>
                  )}
                </Flex>
                {sortable && (
                  <Icon
                    as={SortIcon}
                    boxSize={3.5}
                    color={isSorted ? 'color.800' : 'gray.500'}
                    transition="color 200ms cubic-bezier(0.4, 0, 0.2, 1)"
                  />
                )}
              </Flex>
            </MenuButton>
          </Tooltip>
          <Portal>
            <MenuList zIndex="popover">
              {sortable && (
                <>
                  <MenuItem icon={<IconArrowUp size={16} />} iconSpacing={1.5} onClick={onSortAsc}>
                    <Flex alignItems="center" gap={2} justifyContent="space-between">
                      Sort ascending
                      {isSorted && sortDirection === 'asc' && (
                        <Icon as={IconCircleCheckFilled} boxSize={4} color="purple.500" />
                      )}
                    </Flex>
                  </MenuItem>
                  <MenuItem icon={<IconArrowDown size={16} />} iconSpacing={1.5} onClick={onSortDesc}>
                    <Flex alignItems="center" gap={2} justifyContent="space-between">
                      Sort descending
                      {isSorted && sortDirection === 'desc' && (
                        <Icon as={IconCircleCheckFilled} boxSize={4} color="purple.500" />
                      )}
                    </Flex>
                  </MenuItem>
                </>
              )}

              {filterable && sortable && <MenuDivider />}

              {filterable && (
                <MenuItem icon={<IconFilter size={16} />} iconSpacing={1.5} onClick={() => onFilterColumn(columnKey)}>
                  Filter column
                </MenuItem>
              )}

              {removable && (sortable || filterable) && <MenuDivider />}

              {removable && (
                <MenuItem icon={<IconX size={14} />} iconSpacing={1.5} onClick={() => onRemoveColumn(columnKey)}>
                  Remove column
                </MenuItem>
              )}
            </MenuList>
          </Portal>
        </Menu>
      </Th>
    )
  }

  return <Th {...props}>{children}</Th>
}

const rowHover = {
  bg: [undefined, 'gray.50']
}

interface CustomTableProps<T extends object> extends Partial<TableOptions<T>> {
  data: T[]
  columns: ColumnDef<T>[]
  selectedRows?: RowSelectionState
  size?: TableProps['size']
  stickyFirstColumn?: boolean
  enableAllSelection?: boolean
  onSelectionModeChange?: (a: string) => void
  selectAllMode?: boolean
  numberOfRecords?: number
}

export function CustomTable<T extends object>({
  data,
  columns,
  selectedRows,
  onSelectionModeChange,
  size = 'sm',
  stickyFirstColumn = false,
  enableRowSelection = false,
  enableAllSelection = false,
  numberOfRecords,
  state,
  selectAllMode = false,
  ...tableOptions
}: CustomTableProps<T>) {
  const largeEnoughScreen = useMedia('(min-width: 768px) and (min-height: 600px)')
  const { scrollRef, overflowLeft, overflowTop } = useOverflow()
  const [rowSelection, setRowSelection] = useState(selectedRows || {})

  useEffect(() => {
    if (selectedRows) {
      setRowSelection(selectedRows)
    }
  }, [selectedRows])

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      ...state
    },
    enableRowSelection,
    onRowSelectionChange: setRowSelection,
    getRowId: (row, index) => (row as any).id || index,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    ...tableOptions,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <TableContainer
      ref={scrollRef}
      position="relative"
      className={`${overflowLeft ? 'scrolled' : ''} ${overflowTop ? 'scrolled-y' : ''}`.trim() || undefined}
      overflowY="auto"
      width="100%"
    >
      <Table className="koala-table" variant="bordered" size={size} height="1px">
        <Thead className="sticky-header">
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => (
                <Th
                  key={header.id}
                  colSpan={header.colSpan}
                  className={index === 0 && largeEnoughScreen && stickyFirstColumn ? 'sticky-column' : undefined}
                  {...(header.column.columnDef.meta as any)?.cellProps}
                >
                  <Flex alignItems="center" gap={2.5} height="100%">
                    {enableRowSelection && !enableAllSelection && index === 0 && (
                      <Checkbox
                        isChecked={table.getIsAllRowsSelected()}
                        isIndeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                      />
                    )}
                    {enableRowSelection && enableAllSelection && index === 0 && (
                      <Menu>
                        <MenuButton
                          as={Button}
                          paddingY="2px"
                          paddingX="4px"
                          marginLeft="-4px"
                          height="22px"
                          rightIcon={<IconChevronDown size="12px" />}
                        >
                          <Checkbox
                            isChecked={table.getIsAllRowsSelected()}
                            isIndeterminate={table.getIsSomeRowsSelected()}
                          />
                        </MenuButton>
                        <MenuList>
                          <MenuItem
                            onClick={(e) => {
                              onSelectionModeChange && onSelectionModeChange('page')
                              if (!selectAllMode || (selectAllMode && !table.getIsAllRowsSelected())) {
                                table.getToggleAllRowsSelectedHandler()(e)
                              }
                            }}
                          >
                            <Checkbox mr="4px" isChecked={table.getIsAllRowsSelected() && !selectAllMode} />
                            Select page
                          </MenuItem>
                          {onSelectionModeChange && (
                            <MenuItem
                              onClick={(e) => {
                                onSelectionModeChange && onSelectionModeChange('all')
                                if (selectAllMode || (!selectAllMode && !table.getIsAllRowsSelected())) {
                                  table.getToggleAllRowsSelectedHandler()(e)
                                }
                              }}
                            >
                              <Checkbox mr="4px" isChecked={table.getIsAllRowsSelected() && selectAllMode} />
                              Select all companies{' '}
                              {numberOfRecords && (
                                <Badge variant="pill" colorScheme="purple" marginLeft="4px">
                                  {numberOfRecords}
                                </Badge>
                              )}
                            </MenuItem>
                          )}
                        </MenuList>
                      </Menu>
                    )}
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr
              key={row.id}
              className={row.getIsSelected() ? 'selected-row' : undefined}
              bg={row.getIsSelected() ? 'purple.50' : undefined}
              _hover={row.getIsSelected() ? undefined : rowHover}
            >
              {row.getVisibleCells().map((cell, index) => {
                const width = cell.column.getSize()
                const autoWidth = (cell.column.columnDef.meta as any)?.cellProps?.width === 'auto'

                return (
                  <Td
                    key={cell.id}
                    className={index === 0 && largeEnoughScreen && stickyFirstColumn ? 'sticky-column' : undefined}
                    bg={row.getIsSelected() ? 'purple.50' : undefined}
                    width={autoWidth ? undefined : '1px'}
                    minW={width}
                    maxW={width}
                    {...(cell.column.columnDef.meta as any)?.cellProps}
                  >
                    <Flex alignItems="center" gap={2.5}>
                      {enableRowSelection && index === 0 && (
                        <Checkbox
                          isChecked={row.getIsSelected()}
                          onChange={row.getToggleSelectedHandler()}
                          isDisabled={selectAllMode && table.getIsAllRowsSelected()}
                        />
                      )}
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Flex>
                  </Td>
                )
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
