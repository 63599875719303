import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  Tooltip,
  UnorderedList,
  useDisclosure
} from '@chakra-ui/react'
import {
  IconBolt,
  IconBriefcase,
  IconCheck,
  IconChevronDown,
  IconChevronRight,
  IconMap,
  IconStar,
  IconTransform,
  IconUser,
  IconWorldBolt
} from '@tabler/icons-react'
import { omit } from 'lodash'
import React, { useMemo, useRef } from 'react'
import { SalesforceIcon } from '../../../ui/icons'
import { HubSpotIcon } from '../../../ui/icons/HubspotIcons'
import KoalaLogo, { KoalaLogoMark } from '../../../ui/KoalaLogo'
import { VirtualList } from '../../../ui/VirtualList'
import { Toggle } from '../../accounts/components/Toggle'
import { ActionCategoryItem, ActionSchema } from '../../follow_rules/components/slack-message-builder/use-action-schema'

export interface IntegrationField {
  label: string
  key: string
}

export interface ActionFieldProps {
  schema: ActionSchema
  restrictCategories?: string[]
  selectedItem: IntegrationField | null
  placeholder?: string
  type: string
  onChange: (item: IntegrationField) => void
}

function iconForCategory(category: string) {
  if (category.includes('trait')) {
    return IconTransform
  }

  if (category.includes('attribution')) {
    return IconWorldBolt
  }

  if (category.includes('geo')) {
    return IconMap
  }

  if (category.includes('company')) {
    return IconBriefcase
  }

  if (category.includes('signal') || category.includes('intent')) {
    return IconBolt
  }

  if (category.includes('score')) {
    return IconStar
  }

  if (category.includes('visitor_card')) {
    return IconUser
  }

  if (category.includes('salesforce')) {
    return SalesforceIcon
  }

  if (category.includes('hubspot')) {
    return HubSpotIcon
  }

  return KoalaLogoMark
}

export function ActionField(props: ActionFieldProps) {
  const disclosure = useDisclosure()
  const { schema } = props
  const accountCategories = useMemo(() => schema?.account_categories ?? {}, [schema])
  const visitorCategories = useMemo(() => schema?.visitor_categories ?? {}, [schema])
  const signalCategories = useMemo(() => schema?.signal_categories ?? {}, [schema])

  const allCategories: Record<
    string,
    {
      label: string
      items: ActionCategoryItem[]
    }
  > = useMemo(() => {
    // all fields by default
    let objectCategories = {
      ...schema?.account_categories,
      ...schema?.visitor_categories,
      ...schema?.signal_categories
    }

    if (props.type === 'account' || props.type === 'company') {
      // omit salesforce and hubspot categories by default, since they are not used in the account/company context
      objectCategories = omit(accountCategories, 'salesforce', 'hubspot')
    }

    if (props.type === 'profile' || props.type === 'lead' || props.type === 'contact') {
      objectCategories = visitorCategories
    }

    return {
      ...objectCategories,
      ...signalCategories
    }
  }, [accountCategories, signalCategories, visitorCategories, props.type, schema])

  const [filter, setFilter] = React.useState('')

  const matchingCategories = useMemo(() => {
    return Object.entries(allCategories).reduce((acc, [categoryKey, category]) => {
      const matchingItems = category.items.filter((item) => {
        const matchable = category.label.toLowerCase() + ' ' + item.key.toLowerCase().replace(/\./g, ' ')
        return matchable.includes(filter.toLowerCase())
      })

      if (matchingItems.length > 0) {
        acc[categoryKey] = {
          label: category.label,
          items: matchingItems
        }
      }

      return acc
    }, {} as Record<string, { label: string; items: ActionCategoryItem[] }>)
  }, [allCategories, filter])

  const selected = useMemo(() => {
    return Object.values(allCategories)
      .flatMap((category) => category.items)
      .find((item) => {
        return item.key === props.selectedItem?.key
      })
  }, [allCategories, props.selectedItem])

  const selectedCategoryKey = useMemo(() => {
    return Object.keys(allCategories).find((categoryKey) => {
      const category = allCategories[categoryKey]
      return category.items.find((item) => {
        return item.key === props.selectedItem?.key
      })
    })
  }, [allCategories, props.selectedItem])

  const initialFocusRef = useRef<HTMLInputElement>()

  return (
    <Flex w="100%">
      <Popover
        isOpen={disclosure.isOpen}
        closeOnBlur
        onClose={disclosure.onClose}
        closeOnEsc
        initialFocusRef={initialFocusRef as any}
      >
        <PopoverTrigger>
          <Button
            w="100%"
            onClick={disclosure.onOpen}
            rightIcon={<Icon as={IconChevronDown} />}
            justifyContent="space-between"
            variant="outline"
            fontWeight={'normal'}
            bg="white"
            leftIcon={
              selectedCategoryKey ? (
                <Icon as={iconForCategory(selectedCategoryKey)} color="purple.500" />
              ) : (
                <KoalaLogo size="4" kind="mark" />
              )
            }
          >
            {selected && (
              <HStack spacing="1" w="100%">
                <Text>{allCategories[selectedCategoryKey ?? '']?.label}:</Text>
                <Text fontWeight={'medium'}>{selected.label}</Text>
              </HStack>
            )}
            {!selected && (
              <Text w="100%" color="gray.500" textAlign={'left'}>
                {props.placeholder ?? 'Select a field in Koala'}
              </Text>
            )}
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody pt="3" as={Stack}>
              <Input
                ref={initialFocusRef as any}
                size="sm"
                placeholder="Search"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />

              <Stack py="2" spacing="1">
                <Text
                  fontSize="12px"
                  fontWeight="medium"
                  color="gray.500"
                  letterSpacing="0.04em"
                  textTransform="uppercase"
                  paddingX={3}
                >
                  Categories
                </Text>
                <Stack maxH="300px" overflow={'auto'} spacing="2">
                  {Object.entries(matchingCategories).map(([categoryKey, category]) => {
                    if (category.items.length === 0) {
                      return null
                    }

                    return (
                      <Toggle
                        title={(isOpen) => (
                          <Button
                            w="100%"
                            size="sm"
                            justifyContent={'space-between'}
                            variant="ghost"
                            rightIcon={<Icon as={isOpen ? IconChevronDown : IconChevronRight} />}
                            color="gray.700"
                            _hover={{ color: 'purple.500', bg: 'purple.100' }}
                            colorScheme={'purple'}
                            flex="1"
                            isActive={isOpen}
                            _active={{ bg: 'gray.200' }}
                          >
                            <HStack spacing="2">
                              <Icon as={iconForCategory(categoryKey)} size="18" />
                              <Text>{category.label}</Text>
                            </HStack>
                          </Button>
                        )}
                        key={categoryKey + filter}
                        showIcon={false}
                        defaultIsOpen={filter.length > 0 || selectedCategoryKey === categoryKey}
                      >
                        <Stack w="100%">
                          <Box borderLeftWidth="thin" pl="1" ml="0.5" mb="3" mt="2">
                            <VirtualList
                              estimateSize={() => 36}
                              maxH={'180px'}
                              items={category.items}
                              renderItem={(item, _index) => {
                                item = item as ActionCategoryItem

                                return (
                                  <Tooltip
                                    label={
                                      item.description && (
                                        <Stack p="4" spacing="4">
                                          <Stack color="white.800" spacing="1">
                                            <Heading size="xs">{item.label}</Heading>
                                            <Text color="white.600">{item.description}</Text>
                                          </Stack>
                                          {item.examples && (
                                            <Stack color="white.700" spacing="0">
                                              <Heading size="xs">Examples:</Heading>
                                              <UnorderedList px="4">
                                                {item.examples.map((example) => (
                                                  <ListItem
                                                    key={example}
                                                    color="white.500"
                                                    fontSize="sm"
                                                    whiteSpace={'pre-wrap'}
                                                  >
                                                    {example}
                                                  </ListItem>
                                                ))}
                                              </UnorderedList>
                                            </Stack>
                                          )}
                                        </Stack>
                                      )
                                    }
                                    hasArrow
                                    placement="right"
                                    // bg="gray.50"
                                  >
                                    <Button
                                      color="gray.700"
                                      justifyContent={'flex-start'}
                                      _hover={{ color: 'purple.500', bg: 'purple.100' }}
                                      _active={{ bg: 'purple.50', color: 'purple.500' }}
                                      variant="ghost"
                                      colorScheme={'purple'}
                                      rightIcon={
                                        props.selectedItem?.key === item.key ? <Icon as={IconCheck} /> : undefined
                                      }
                                      fontWeight={props.selectedItem?.key === item.key ? 'medium' : 'normal'}
                                      flex="1"
                                      size="sm"
                                      isActive={props.selectedItem?.key === item.key}
                                      onClick={() => {
                                        props.onChange(item)
                                        disclosure.onClose()
                                      }}
                                    >
                                      <HStack flex="1" fontSize={'sm'}>
                                        <HStack spacing="0">
                                          {item.key.includes('geo') ? (
                                            <Icon as={IconMap} color="purple.500" />
                                          ) : item.key.includes('utm') || item.key.includes('referrer') ? (
                                            <Icon as={IconWorldBolt} color="blue.500" />
                                          ) : item.key.includes('company') ? (
                                            <Icon as={IconBriefcase} color="purple.500" />
                                          ) : item.key.includes('trait') ? (
                                            <Icon as={IconTransform} color="purple.500" />
                                          ) : item.key.includes('signal') || item.key.includes('intent') ? (
                                            <Icon as={IconBolt} color="orange.500" />
                                          ) : item.key.includes('visitor') ? (
                                            <Icon as={IconUser} color="purple.500" />
                                          ) : item.key.includes('hubspot') ? (
                                            <Icon as={HubSpotIcon} color="orange.500" />
                                          ) : item.key.includes('salesforce') ? (
                                            <Icon as={SalesforceIcon} color="blue.500" />
                                          ) : (
                                            <KoalaLogo size="4" kind="mark" />
                                          )}
                                        </HStack>
                                        <Text>{item.label}</Text>
                                      </HStack>
                                    </Button>
                                  </Tooltip>
                                )
                              }}
                            />
                          </Box>
                        </Stack>
                      </Toggle>
                    )
                  })}
                </Stack>
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </Flex>
  )
}
