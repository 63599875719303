import keyBy from 'lodash/keyBy'
import omit from 'lodash/omit'
import uniq from 'lodash/uniq'
import React from 'react'
import { concurrentCachedGET } from '../../lib/api'
import { Facet, FacetFilters, FacetMapping, FacetMappings, FacetValue, NumericFilter } from '../pages/accounts'
import { projectPath } from '../ui/ProjectsContext'
import { InputParams, useSearchParams } from '../ui/useSearchState'
import { convertEqToGte, getFacetOperator, getFacetValues } from './use-facets'

interface Props {
  initialFacets?: FacetFilters
  initialRange?: 'day' | 'week' | 'month' | 'all' | 'any' | null
  initialFocusTime?: NumericFilter | null
  initialSortBy?: string
  initialPage?: number | string
  initialQuery?: string
  // any additional (untyped) props that we dont necessarily expect but should be managed in the url state
  extra?: InputParams
  facetCloudPath?: string
  onClearFilters?: () => void
}

interface ParsedFilterParams {
  page?: string
  range?: 'day' | 'week' | 'month' | 'all' | 'any'
  query?: string
  focus_time?: NumericFilter | null
  facets: {
    [key: string]: Facet
  }
  sort_by?: string
}

export interface FacetCloudResponse {
  mappings: FacetMapping[]
  top_filters: string[]
}

export type UrlFilterParams = ReturnType<typeof useUrlFilters>

const emptyObject = {}

/**
 * Keeps local state in sync from url params as the source of truth.
 * Updating state is to navigate to a new url.
 * There is a lot of code here for interop/backwards-compat with use-facets
 */
export function useUrlFilters(props: Props) {
  const initialFacetsRef = React.useRef(props.initialFacets ?? {})

  const { searchParams, setSearchParams, setSearchParam, onlyDefaults } = useSearchParams({
    page: props.initialPage ?? 1,
    range: props.initialRange !== null ? props.initialRange || 'all' : null,
    query: props.initialQuery,
    focus_time: convertEqToGte(props.initialFocusTime),
    facets: props.initialFacets as any,
    sort_by: props.initialSortBy,
    ...props.extra
  })

  const clearFilters = React.useCallback(() => {
    setSearchParams((prev) => {
      return {
        ...prev,
        page: undefined,
        range: undefined,
        query: undefined,
        focus_time: undefined,
        facets: undefined,
        sort_by: undefined
      }
    })
  }, [setSearchParams])

  const updateAndResetPage = React.useCallback(
    (key: string, value: any) => {
      setSearchParams((prev) => {
        const changes = {
          [key]: value
        }

        if (prev.page && Number(prev.page) > 1) {
          changes.page = 1
        }

        return {
          ...prev,
          ...changes
        }
      })
    },
    [setSearchParams]
  )

  const setRange = React.useCallback(
    (range) => {
      if (range === null) {
        updateAndResetPage('range', 'any')
      } else {
        updateAndResetPage('range', range)
      }
    },
    [updateAndResetPage]
  )

  const setQuery = React.useCallback(
    (value) => {
      updateAndResetPage('query', value)
    },
    [updateAndResetPage]
  )

  const setPage = React.useCallback(
    (value) => {
      setSearchParam('page', value)
    },
    [setSearchParam]
  )

  const setSortBy = React.useCallback(
    (value) => {
      updateAndResetPage('sort_by', value)
    },
    [updateAndResetPage]
  )

  const setFocusTime = React.useCallback(
    (value) => {
      updateAndResetPage('focus_time', value)
    },
    [updateAndResetPage]
  )

  // This only works on `facets` params (not other top-level params)
  const toggleFilterOperator = React.useCallback(
    (filter: string, value: any, inputOperator: string) => {
      // TODO accomodate more operators as our ES backend allows
      if (inputOperator === 'not') {
        if (!value.not) {
          value = {
            not: value
          }
        }
      } else {
        if (value.not) {
          value = value.not
        }
      }

      setSearchParams((prev) => ({
        ...prev,
        facets: {
          ...(prev.facets as any),
          [filter]: value
        }
      }))
    },
    [setSearchParams]
  )

  // This only works on `facets` params (not other top-level params)
  const onFilterChange = React.useCallback(
    (filter: string, value: FacetValue, action: 'add' | 'remove' | 'set') => {
      setSearchParams((prev) => {
        let facets = (prev.facets ?? {}) as FacetFilters
        let facet: Facet = Array.from(getFacetValues(facets[filter] || []))

        if (action === 'remove') {
          facet = facet.filter((v) => v !== value)
        }

        if (action === 'add' && !Array.isArray(value)) {
          facet = uniq(facet.concat(value))
        }

        if (action === 'add' && Array.isArray(value)) {
          facet.push(value)
        }

        if (action === 'set') {
          facet = [].concat(value as any)
        }

        if (facet.length > 0) {
          const operator = getFacetOperator(facets[filter])

          if (operator) {
            facet = { [operator]: facet } as Facet
          }

          facets = { ...facets, [filter]: facet }
        } else {
          facets = omit(facets, filter)
        }

        return {
          ...prev,
          page: 1,
          facets: facets as any
        }
      })
    },
    [setSearchParams]
  )

  // This only works on `facets` params (not other top-level params)
  const applyFilters = React.useCallback(
    (appliedFilters: FacetFilters) => {
      setSearchParams((prev) => {
        return {
          ...prev,
          // Only update the page in the query string if it has changed
          // This avoids unnecessarily adding to the query string
          page: prev.page && Number(prev.page) > 1 ? 1 : undefined,
          facets: {
            ...(prev.facets as any),
            ...(appliedFilters as any)
          }
        }
      })
    },
    [setSearchParams]
  )

  // This only works on `facets` params (not other top-level params)
  const setFacetFilters = React.useCallback(
    (next: FacetFilters | ((prev: FacetFilters) => FacetFilters)) => {
      setSearchParams((prev) => {
        let facets: any = typeof next === 'function' ? next(prev.facets as FacetFilters) : next

        // Send an empty param (which gets converted from `''` to `{}` in the backend)
        // This is important when the absence of facets should override the default facets
        if (Object.keys(facets).length === 0 && Object.keys(initialFacetsRef.current ?? {}).length) {
          facets = ''
        }

        return {
          ...prev,
          // Only update the page in the query string if it has changed
          // This avoids unnecessarily adding to the query string
          page: prev.page && Number(prev.page) > 1 ? 1 : undefined,
          facets
        }
      })
    },
    // we only want the initial facets param
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSearchParams]
  )

  const facetCloudPath = props.facetCloudPath ?? '/accounts/facet-cloud'
  const [facetMappings, setFacetMappings] = React.useState<FacetMappings>({})
  const [facetCloudLoading, setFacetCloudLoading] = React.useState(true)
  const [topFilters, setTopFilters] = React.useState<string[]>([])

  // Load once
  React.useEffect(() => {
    let canceled = false
    setFacetCloudLoading(true)

    concurrentCachedGET<FacetCloudResponse>(projectPath(facetCloudPath))
      .then((res) => {
        // Dont update the state if this effect has been unloaded
        if (canceled) {
          return
        }

        setFacetMappings(keyBy(res.mappings || [], 'facet'))
        setTopFilters(res.top_filters || [])
        setFacetCloudLoading(false)
      })
      .catch((_error) => {
        setFacetCloudLoading(false)
      })

    return () => {
      canceled = true
    }
  }, [facetCloudPath])

  const {
    page,
    range,
    query,
    focus_time,
    facets = emptyObject,
    sort_by
  } = searchParams as unknown as ParsedFilterParams

  const isFiltering = Object.keys(facets).length > 0 || !!range || !!query || !!focus_time

  return {
    raw: searchParams,
    page: parseInt(page ?? '1', 10),
    range,
    query,
    focusTime: convertEqToGte(focus_time) || null,
    sortBy: sort_by,
    facetFilters: facets,
    facetMappings,
    topFilters,
    facetCloudLoading,

    onlyDefaults,
    isFiltering,
    canClearFilters: !onlyDefaults,
    clearFilters,
    setRange,
    setQuery,
    setPage,
    setSortBy,
    setFocusTime,
    onFilterChange,
    applyFilters,
    setFacetFilters,
    toggleFilterOperator
  }
}
