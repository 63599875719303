import { Box, Button, Flex, HStack, Image, Link, Stack, Text } from '@chakra-ui/react'
import { IconInfoCircle, IconUserQuestion, IconWorldPin } from '@tabler/icons-react'
import startCase from 'lodash/startCase'
import React from 'react'
import { Account, AccountAssociation } from '../../../../types/Account'
import { HoverCard } from '../../../ui/HoverCard'
import { TimeAgo } from '../../../ui/TimeAgo'

export function AccountMatchMetadata(props: {
  association?: AccountAssociation
  account: Account
  isIpMatch?: boolean
  hasEmail?: boolean
  type?: 'visitor' | 'account' | 'session'
}) {
  const isIpMatch = props.isIpMatch ?? props.association?.match_type === 'ip'
  const confidenceScore = props.association?.metadata?.reveal_confidence_score

  if (!isIpMatch) {
    return null
  }

  return (
    <HStack justifyContent="space-between" textAlign="left">
      <Image w="4" src="https://logo.clearbit.com/clearbit.com" filter="grayscale(90%)" />
      <Text fontSize="sm" flex="1" fontWeight="medium">
        Clearbit Reveal
      </Text>
      <HoverCard
        trigger="hover"
        offset={[12, 12]}
        placement="bottom-end"
        hoverContent={
          <Box px={0} pt={4} pb={2} maxW="340px">
            <Flex gap={4} direction="column" overflow="none" fontSize="sm" textAlign="left">
              <HStack px="3" alignItems="flex-start">
                <Box flex="none" color="gray.600">
                  <IconWorldPin size={20} />
                </Box>
                <Stack spacing={4}>
                  <Text color="gray.600">
                    This {props.type ?? 'visitor'}'s IP was matched to{' '}
                    <Text as="span" fontWeight={'semibold'} color="gray.800">
                      {props.account.company.name ?? props.account.company.domain}
                    </Text>{' '}
                    via Clearbit Reveal.{' '}
                    <Link
                      href="https://dashboard.clearbit.com/docs#reveal-api-lookup-ip-addresses-confidence-scores"
                      isExternal
                      textDecoration="underline"
                    >
                      Learn more
                    </Link>
                  </Text>
                  {confidenceScore && (
                    <Stack spacing={3} fontSize="sm" rounded="md">
                      <Stack spacing={0}>
                        <Text fontSize="xs" color="gray.600">
                          Confidence Score
                        </Text>
                        <Text>{startCase(props.association!.metadata?.reveal_confidence_score)}</Text>
                      </Stack>
                      <Stack spacing={0}>
                        <Text fontSize="xs" color="gray.600">
                          Matched At
                        </Text>
                        <Text>
                          <TimeAgo mode="full" time={props.association!.updated_at} />
                        </Text>
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </HStack>

              {!props.hasEmail && (
                <HStack alignItems="flex-start" bg="orange.50" p="3" fontSize="sm" rounded="md">
                  <Box flex="none" color="orange.600">
                    <IconUserQuestion size={20} />
                  </Box>
                  <Text>
                    We haven't gotten an email for this visitor yet. We recommend adding Koala Identify calls to improve
                    identification.{' '}
                    <Button
                      as={Link}
                      isExternal
                      href="https://getkoala.com/docs/sdk/identify"
                      size="sm"
                      variant="link"
                      colorScheme="orange"
                      textDecoration="underline"
                    >
                      Learn more
                    </Button>
                  </Text>
                </HStack>
              )}
            </Flex>
          </Box>
        }
      >
        <Box flex="none" color="gray.500" cursor="pointer">
          <IconInfoCircle size={18} />
        </Box>
      </HoverCard>
    </HStack>
  )
}
