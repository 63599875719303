import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import React, { useCallback } from 'react'
import { toast } from 'sonner'
import { StaticList } from '../../../../types/StaticList'
import { useUpdateList } from '../../../data/use-lists'
import { AutosizedTextarea } from '../../../ui/AutosizedTextarea'
import { projectPath } from '../../../ui/ProjectsContext'

interface EditListModalProps extends UseDisclosureProps {
  list: StaticList
  onUpdate?: (list: StaticList) => void
}

export function EditListModal({ list, onUpdate, ...props }: EditListModalProps) {
  const disclosure = useDisclosure(props)
  const onClose = disclosure.onClose

  const { isLoading: submitting, mutateAsync: updateList } = useUpdateList()

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault()

      const data = new FormData(e.target)
      const name = data.get('static_list[name]') as string
      const description = data.get('static_list[description]') as string

      try {
        const res = await updateList({ id: list.id, name, description })
        toast.success('List updated!')
        onClose()
        onUpdate?.(res.list)
      } catch (err: any) {
        toast.error('Failed to update list', { description: err?.message })
      }
    },
    [list.id, updateList, onUpdate, onClose]
  )

  const formId = 'edit-list-form'

  return (
    <Modal {...disclosure} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Edit your list</ModalHeader>
        <ModalBody>
          <form id={formId} method="POST" action={projectPath(`/lists/${list.id}`)} onSubmit={onSubmit}>
            <Stack spacing={6}>
              <FormControl isRequired>
                <FormLabel htmlFor="static_list[name]">List name</FormLabel>
                <Input
                  autoFocus
                  size="sm"
                  defaultValue={list.name}
                  autoComplete="off"
                  placeholder="Add a descriptive name for your list"
                  isRequired
                  name="static_list[name]"
                  id="static_list[name]"
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="static_list[description]">Description</FormLabel>
                <AutosizedTextarea
                  size="sm"
                  defaultValue={list.description}
                  autoComplete="off"
                  name="static_list[description]"
                  id="static_list[description]"
                  maxRows={8}
                />
              </FormControl>
            </Stack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" variant="outline" mr={3} onClick={disclosure.onClose}>
            Cancel
          </Button>
          <Button size="sm" type="submit" colorScheme="purple" form={formId} isLoading={submitting}>
            Update list
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
