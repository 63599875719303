import React, { useState } from 'react'

import { projectPath } from '../../../ui/ProjectsContext'

import { ConnectOauthAppDialog } from '@app/components/pages/apps/components/ConnectOauthAppDialog'

import PageLayout from '@app/components/ui/PageLayout'
import PageTitle from '@app/components/ui/PageTitle'
import type { Project } from '@app/types/Project'
import PageDescription from '../../../ui/PageDescription'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'

import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import {
  Badge,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Image,
  Input,
  Stack
} from '@chakra-ui/react'
import { LightBgCard } from '../../../ui/Card'
import { Introduction } from '../components/Introduction'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'

interface Props {
  app_id: string
  project: Project
  title: string
  description: string
  logo: string
  valid?: boolean
  connected?: boolean
  settings: {
    company_page?: string
  }
}

export default function Show(props: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [companyPage, setCompanyPage] = useState(props.settings.company_page)

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/linkedin'),
            title: 'LinkedIn'
          }
        ]}
        offscreen
      />
      <HStack w="100%">
        <Box w="100%">
          <HStack>
            <HStack marginRight="auto" alignItems="center" spacing={2}>
              <Image src={props.logo} maxW="6" />
              <PageTitle>{props.title}</PageTitle>
              {props.connected && props.valid && <Badge colorScheme="green">Connected</Badge>}
              {props.connected && !props.valid && <Badge colorScheme="orange">Requires Reconnection</Badge>}
            </HStack>
            {props.connected && <DisconnectAppDialog appTitle="LinkedIn" showRemoveCachesOption={false} />}
          </HStack>

          <PageDescription>{props.description}</PageDescription>
        </Box>
      </HStack>

      <Introduction
        app="LinkedIn"
        icon={props.logo}
        description="Connect your LinkedIn account to access signals from your page."
      />

      {!props.connected && (
        <LightBgCard p={5}>
          <ConnectOauthAppDialog {...props} action={projectPath(`/apps/linkedin/sign-in`)} />
        </LightBgCard>
      )}

      {props.connected && (
        <form
          method="POST"
          onSubmit={(_e) => {
            setIsSubmitting(true)
          }}
        >
          <AuthenticityToken />
          <input type="hidden" name="_method" value="PUT" />

          <Stack spacing="8">
            <LightBgCard p={5} as={Stack} align="stretch">
              <FormControl>
                <FormLabel>LinkedIn Company Page</FormLabel>
                <Input
                  my="2"
                  size="sm"
                  name="app_instance_settings[company_page]"
                  placeholder="https://www.linkedin.com/company/<company-id>/admin/dashboard"
                  isRequired
                  pattern="https://www.linkedin.com/company/.*/admin/dashboard(/?.*)?"
                  value={companyPage}
                  onChange={(e) => setCompanyPage(e.target.value)}
                />
                <FormHelperText>
                  Enter the URL of your LinkedIn Company Page. We'll use this to fetch your company's profile and posts.
                  You must be an admin of the company in order to connect your company page.
                </FormHelperText>
                <FormHelperText>
                  Note: You can find your company ID by visiting your company's admin page on LinkedIn and looking at
                  the URL.
                </FormHelperText>
              </FormControl>
            </LightBgCard>

            <Button colorScheme="purple" type="submit" isLoading={isSubmitting} isDisabled={!companyPage}>
              Save
            </Button>
          </Stack>
        </form>
      )}
    </PageLayout>
  )
}
