import React from 'react'

import { projectPath } from '../../../ui/ProjectsContext'

import { ConnectOauthAppDialog } from '@app/components/pages/apps/components/ConnectOauthAppDialog'
import { ConnectionDetails, ConnectionDetail } from '@app/components/pages/apps/components/ConnectionDetails'

import PageLayout from '@app/components/ui/PageLayout'
import PageTitle from '@app/components/ui/PageTitle'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import PageDescription from '../../../ui/PageDescription'
import type { Project } from '@app/types/Project'

import { Badge, Box, HStack, Image, Stack } from '@chakra-ui/react'
import { LightBgCard } from '../../../ui/Card'
import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'

interface Props {
  app_id: string
  project: Project
  title: string
  description: string
  logo: string
  valid?: boolean
  connected?: boolean
  settings: {}
  connection_details: ConnectionDetail[]
}

export default function Show(props: Props) {
  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/salesloft'),
            title: 'Salesloft'
          }
        ]}
        offscreen
      />
      <HStack>
        <Box>
          <HStack>
            <HStack marginRight="auto" alignItems="center" spacing={2}>
              <Image src={props.logo} maxW="6" />
              <PageTitle>{props.title}</PageTitle>
              {props.connected && props.valid && <Badge colorScheme="green">Connected</Badge>}
              {props.connected && !props.valid && <Badge colorScheme="orange">Requires Reconnection</Badge>}
            </HStack>
            {props.connected && <DisconnectAppDialog appTitle="Salesloft" showRemoveCachesOption={false} />}
          </HStack>

          <PageDescription>{props.description}</PageDescription>
        </Box>
      </HStack>

      {!props.connected && (
        <LightBgCard p={5}>
          <ConnectOauthAppDialog {...props} />
        </LightBgCard>
      )}

      {props.connected && (
        <Stack spacing="12">
          <ConnectionDetails appTitle={'Salesloft'} valid={props.valid} details={props.connection_details} />
        </Stack>
      )}
    </PageLayout>
  )
}
