import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import React, { useEffect, useMemo } from 'react'
import { useInterval } from 'react-use'
import { concurrentGET, post } from '../../lib/api'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'
import useLocation from '../ui/useLocation'

export type Skips = Record<keyof HealthChecklist | 'app_website' | 'docs_website' | 'blog_website', boolean>

export type HealthChecklist = {
  docs: boolean
  blog: boolean
  app: boolean
  data_flowing: boolean
  identities: boolean
  anonymous_companies: boolean
}

export type WebsiteSettings = {
  base: string
  app: string
  docs: string
  blog: string
}

interface Instrumentation {
  checks: HealthChecklist
  website_skips: Skips | null
  website_settings: WebsiteSettings | null
}

interface InstrumentationContextType {
  setInstrumentation: (instrumentation: Instrumentation) => void
  instrumentation: Instrumentation | null
  progress: Progress
  fetch: (force?: boolean) => void
  loading: boolean
  setSkip: (key: keyof Skips, val: boolean) => Promise<void>
  skips: Skips | null
}

function taskSkips(skips: Skips) {
  const keys = Object.keys(skips).filter((s) => !s.includes('website'))
  return pick(skips, keys)
}

function completedChecks(checks: HealthChecklist) {
  return pickBy(checks, (c) => !!c)
}

type Progress = [number, number, boolean]

function todoProgress(checks: HealthChecklist, skips: Skips | null): Progress {
  const numEntries = Object.keys(checks).length
  const trueSkips = pickBy(taskSkips(skips ?? ({} as Skips)), (c) => !!c)
  const complete = completedChecks(checks)
  const uniq = new Set([...Object.keys(complete), ...Object.keys(trueSkips)])
  const done = uniq.size === numEntries

  return [uniq.size, numEntries, done]
}

export async function updateSkips(updated: Skips) {
  return post(projectPath('/instrumentation/skips'), {
    website_skips: updated
  })
}

export async function fetchInstrumentation(): Promise<Instrumentation> {
  const path = projectPath('/instrumentation.json')
  return concurrentGET<Instrumentation>(path)
}

const ONE_HOUR = 1000 * 60 * 60

export function useInstrumentation(bootstrap?: Instrumentation | null): InstrumentationContextType {
  const [instrumentation, setInstrumentation] = React.useState<Instrumentation | null>(bootstrap ?? null)
  const [loading, setLoading] = React.useState(false)
  const [skips, setSkips] = React.useState<Skips | null>(instrumentation?.website_skips ?? null)
  const project = useCurrentProject()
  const location = useLocation()

  const isDebugging = location.pathname.includes('/instrumentation')

  useEffect(() => {
    if (instrumentation || !project) {
      return
    }

    setLoading(true)

    fetchInstrumentation().then((res) => {
      setInstrumentation(res)
      setSkips(res.website_skips)
      setLoading(false)
    })
  }, [instrumentation, project])

  const progress: Progress = useMemo(() => {
    if (!instrumentation || !instrumentation.checks) {
      return [0, 0, false]
    }

    return todoProgress(instrumentation.checks, instrumentation.website_skips)
  }, [instrumentation])

  const refresh = React.useCallback(
    (force?: boolean) => {
      if (progress[2] && !force) {
        return
      }

      if (!project) {
        return
      }

      fetchInstrumentation().then(setInstrumentation)
    },
    [progress, project]
  )

  useInterval(refresh, isDebugging ? 3_000 : ONE_HOUR)

  const setSkip = React.useCallback(
    async (key: keyof Skips, val: boolean) => {
      const updated = { ...skips, [key]: val } as Skips
      setSkips(updated)

      updateSkips(updated).then(() => {
        refresh(true)
      })
    },
    [skips, refresh, setSkips]
  )

  const returnVal = useMemo(
    () => ({
      setInstrumentation,
      instrumentation,
      progress,
      fetch: refresh,
      loading,
      setSkip,
      skips
    }),
    [setInstrumentation, instrumentation, progress, refresh, loading, setSkip, skips]
  )

  return returnVal
}

export const defaultInstrumentationContext = {
  setInstrumentation: () => {},
  instrumentation: null,
  progress: [0, 0, false] as Progress,
  fetch: () => {},
  loading: false,
  setSkip: () => Promise.resolve(),
  skips: null
}

export const InstrumentationContext = React.createContext<InstrumentationContextType>(defaultInstrumentationContext)

export const useInstrumentationContext = () => {
  return React.useContext(InstrumentationContext)
}
