import { Icon, IconProps } from '@chakra-ui/react'
import React from 'react'

interface Props extends IconProps {
  size?: string | number
}

export function AiSparklesIcon({ size, ...props }: Props) {
  size = size ? `${size}px` : size
  return (
    <Icon viewBox="0 0 24 24" fill="none" width={size} height={size} color="#FF774A" {...props}>
      {/* <path
        d="M13.319 4.581a.996.996 0 0 0-1.813 0L9.75 8.373a10 10 0 0 1-4.878 4.875L1.082 15a.998.998 0 0 0-.582.912.99.99 0 0 0 .581.907l3.788 1.75a10 10 0 0 1 4.887 4.888l1.744 3.78a.996.996 0 0 0 1.813 0l1.75-3.789a10 10 0 0 1 4.885-4.885l3.79-1.75a.996.996 0 0 0 0-1.813l-3.781-1.744a10 10 0 0 1-4.889-4.887l-1.75-3.788Z"
        fill="currentColor"
      />
      <path
        opacity=".8"
        d="M20.5 6c0-.3.188-.569.469-.675A8.302 8.302 0 0 0 25.825.469.723.723 0 0 1 26.5 0c.3 0 .569.188.675.469a8.302 8.302 0 0 0 4.856 4.856A.723.723 0 0 1 32.5 6c0 .3-.188.569-.469.675a8.302 8.302 0 0 0-4.856 4.856.723.723 0 0 1-.675.469.723.723 0 0 1-.675-.469 8.302 8.302 0 0 0-4.856-4.856A.723.723 0 0 1 20.5 6Z"
        fill="currentColor"
      />
      <path
        opacity=".5"
        d="M20.5 26c0-.3.188-.569.469-.675a8.302 8.302 0 0 0 4.856-4.856A.723.723 0 0 1 26.5 20c.3 0 .569.188.675.469a8.302 8.302 0 0 0 4.856 4.856.723.723 0 0 1 .469.675c0 .3-.188.569-.469.675a8.302 8.302 0 0 0-4.856 4.856.723.723 0 0 1-.675.469.723.723 0 0 1-.675-.469 8.302 8.302 0 0 0-4.856-4.856A.723.723 0 0 1 20.5 26Z"
        fill="currentColor"
      /> */}
      <path
        d="M9.00171 5C8.59305 5 8.22779 5.23741 8.06388 5.59764L8.05228 5.62312L7.7001 6.74257C7.10415 8.63684 5.58946 10.1308 3.64227 10.7449L2.62204 11.0666L2.59441 11.0793C2.22071 11.2502 2 11.6162 2 12.0017C2 12.3905 2.22369 12.7512 2.59441 12.9207L2.62204 12.9334L3.63943 13.2542C5.58809 13.8688 7.10349 15.3645 7.69852 17.2606L8.04879 18.3767L8.06046 18.4024C8.22437 18.7626 8.58963 19 8.99829 19C9.40695 19 9.77221 18.7626 9.93612 18.4024L9.94772 18.3769L10.3008 17.2545C10.8963 15.3617 12.4091 13.8686 14.3542 13.2536L15.3781 12.9299L15.4056 12.9173C15.7778 12.7471 16 12.382 16 11.9983C16 11.6146 15.7778 11.2495 15.4056 11.0793L15.378 11.0666L14.3606 10.7458C12.4119 10.1312 10.8965 8.63552 10.3015 6.73943L9.9512 5.62327L9.93954 5.59764C9.77563 5.23741 9.41037 5 9.00171 5Z"
        fill="currentColor"
      />
      <path
        opacity="0.8"
        d="M17.5 2.5C17.8336 2.5 18.1209 2.73552 18.1864 3.06272L18.3 3.63096C18.4584 4.4227 19.0772 5.04157 19.869 5.1999L20.4373 5.31354C20.7645 5.37898 21 5.66626 21 5.99994C21 6.33362 20.7645 6.62091 20.4373 6.68636L19.869 6.80003C19.0773 6.95838 18.4584 7.57728 18.3001 8.36902L18.1865 8.93726C18.121 9.26445 17.8338 9.49999 17.5001 9.5C17.1664 9.50001 16.8791 9.2645 16.8136 8.93731L16.7 8.36896C16.5416 7.57726 15.9227 6.95843 15.131 6.8001L14.5627 6.68646C14.2355 6.62102 14 6.33374 14 6.00006C14 5.66638 14.2355 5.37909 14.5627 5.31364L15.131 5.19997C15.9227 5.04161 16.5415 4.42276 16.6999 3.63105L16.8136 3.06272C16.879 2.73552 17.1663 2.5 17.5 2.5Z"
        fill="currentColor"
      />
      <path
        opacity="0.6"
        d="M17.5 14.5C17.8336 14.5 18.1209 14.7355 18.1864 15.0627L18.3 15.631C18.4584 16.4227 19.0772 17.0416 19.869 17.1999L20.4373 17.3135C20.7645 17.379 21 17.6663 21 17.9999C21 18.3336 20.7645 18.6209 20.4373 18.6864L19.869 18.8C19.0773 18.9584 18.4584 19.5773 18.3001 20.369L18.1865 20.9373C18.121 21.2645 17.8338 21.5 17.5001 21.5C17.1664 21.5 16.8791 21.2645 16.8136 20.9373L16.7 20.369C16.5416 19.5773 15.9227 18.9584 15.131 18.8001L14.5627 18.6865C14.2355 18.621 14 18.3337 14 18.0001C14 17.6664 14.2355 17.3791 14.5627 17.3136L15.131 17.2C15.9227 17.0416 16.5415 16.4228 16.6999 15.6311L16.8136 15.0627C16.879 14.7355 17.1663 14.5 17.5 14.5Z"
        fill="currentColor"
      />
    </Icon>
  )
}
