import {
  IconCopyPlus,
  IconDashboard,
  IconDotsVertical,
  IconEdit,
  IconLayoutGrid,
  IconPlus,
  IconTrash
} from '@tabler/icons-react'
import React from 'react'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import EmptyState from '../../ui/EmptyState'
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import { projectPath } from '../../ui/ProjectsContext'
import SettingsHeader from '../../ui/SettingsHeader'
import PageDescription from '../../ui/PageDescription'
import pluralize from 'pluralize'
import { MiddotDivider } from '../../ui/Middot'
import { DeleteConfirmation } from '../../ui/DeleteConfirmation'
import { Space } from '../../../types/Space'
import Avatar from '../../ui/Avatar'
import SquareIcon from '../../ui/SquareIcon'
import { NewTemplateModal } from '../mission_control_templates/components/NewTemplateModal'
import { Template } from '../../../types/Template'
import { SlackIcon } from '../../ui/icons'
import { TemplateRow } from '../mission_control_templates'
import { EditSpaceModal } from '../spaces/components/EditSpaceModal'
import { NewMissionControlModal } from './NewMissionControlModal'
import { ApplyTemplateModal } from '../mission_control_templates/components/ApplyTemplateModal'
import router from '../../../lib/router'

interface Props {
  spaces: Space[]
  templates: Template[]
}

export default function Setup(props: Props) {
  const newTemplate = useDisclosure()
  const newMissionControl = useDisclosure()

  const params = new URLSearchParams(window.location.search)
  const tab = params.get('tab') || 'spaces'
  const index = ['spaces', 'templates'].indexOf(tab)

  return (
    <PageLayout size="sm" gap={0}>
      <SettingsHeader>
        <Flex alignItems="center" justifyContent="space-between">
          <PageTitle>Mission Control Setup</PageTitle>
          <Button
            size="sm"
            colorScheme="purple"
            leftIcon={<Icon as={IconPlus} boxSize={4} />}
            iconSpacing={1.5}
            onClick={newTemplate.onOpen}
          >
            New template
          </Button>
        </Flex>
        <PageDescription>
          Set up Mission Control for your team. You can create templates that teammates can apply to their own Mission
          Control, or inspect what's already been set up.
        </PageDescription>
      </SettingsHeader>

      <Tabs
        size="sm"
        variant="line"
        defaultIndex={index || 0}
        onChange={(index) => {
          const tab = index === 0 ? 'spaces' : 'templates'
          window.history.replaceState({}, '', projectPath(`/mission-control/setup?tab=${tab}`))
        }}
      >
        <TabList>
          <Tab>Mission Control Spaces{props.spaces.length > 0 ? ` (${props.spaces.length.toLocaleString()})` : ''}</Tab>
          <Tab>Templates{props.templates.length > 0 ? ` (${props.templates.length.toLocaleString()})` : ''}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <Stack spacing={4}>
              {props.spaces.length === 0 ? (
                <Box maxW="75%" mx="auto">
                  <EmptyState
                    size="sm"
                    icon={IconDashboard}
                    heading="Looks like there aren't any Mission Control spaces yet"
                    description="Admins can set up Mission Control for individuals on their team, or create Templates that teammates can apply for their own Mission Control."
                    ctaText="Set up someone's Mission Control"
                    onClick={newMissionControl.onOpen}
                  />
                </Box>
              ) : (
                <Button
                  size="sm"
                  rounded="md"
                  variant="unstyled"
                  border="1.5px dashed"
                  borderColor="gray.200"
                  width="full"
                  paddingY={2}
                  paddingX={3}
                  height="60px"
                  display="flex"
                  alignItems="center"
                  leftIcon={<Icon as={IconPlus} boxSize={4} />}
                  iconSpacing={1.5}
                  _hover={{ bg: 'gray.50', borderColor: 'gray.300' }}
                  onClick={newMissionControl.onOpen}
                >
                  Set up another Mission Control
                </Button>
              )}
              {props.spaces.map((space) => (
                <SpaceRow key={space.id} space={space} templates={props.templates} />
              ))}
            </Stack>
          </TabPanel>
          <TabPanel px={0}>
            <Stack spacing={4}>
              {props.templates.map((template) => (
                <TemplateRow key={template.id} template={template} />
              ))}
              {props.templates.length === 0 && (
                <Box maxW="75%" mx="auto">
                  <EmptyState
                    size="md"
                    icon={IconCopyPlus}
                    heading="Looks like there aren't any templates yet"
                    description="Create a template that others can use as a starting point for their Mission Control"
                    ctaText="Create a template"
                    onClick={newTemplate.onOpen}
                  />
                </Box>
              )}
            </Stack>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <NewTemplateModal {...newTemplate} />
      {newMissionControl.isOpen && (
        <NewMissionControlModal
          {...newMissionControl}
          existingUserIds={props.spaces.map((s) => s.owner_id).filter(Boolean) as string[]}
          existingEmails={props.spaces.map((s) => s.owner?.email).filter(Boolean) as string[]}
        />
      )}
    </PageLayout>
  )
}

function SpaceRow({ space, templates }: { space: Space; templates: Template[] }) {
  const deletion = useDisclosure()
  const editSpaceModal = useDisclosure()
  const applyTemplateModal = useDisclosure()

  return (
    <Flex
      key={space.id}
      alignItems="center"
      justifyContent="space-between"
      gap={3}
      rounded="md"
      border="1px solid"
      borderColor="gray.200"
      paddingY={2}
      paddingX={3}
    >
      <Flex alignItems="center" gap={2.5}>
        {space.owner ? (
          <Tooltip
            label={
              space.owner_type === 'MissionControlPlaceholder'
                ? 'This user is not a member of your workspace yet, but will see this Mission Control when they join'
                : undefined
            }
          >
            <Avatar
              key={space.owner.email}
              src={space.owner.image}
              name={space.owner.name || space.owner.email}
              size="sm"
              {...(space.owner_type === 'MissionControlPlaceholder'
                ? {
                    color: 'gray.400',
                    backgroundColor: 'white',
                    border: '1px dashed',
                    borderColor: 'gray.200'
                  }
                : {})}
            />
          </Tooltip>
        ) : (
          <SquareIcon icon={IconLayoutGrid} color="gray.500" padding={2} />
        )}
        <Box>
          <Link
            href={projectPath(space.owner?.email ? `/mission-control/${space.owner.email}` : `/spaces/${space.id}`)}
            fontSize="sm"
          >
            {space.name}
          </Link>
          <HStack color="gray.500" fontSize="xs" spacing={1.5} divider={<MiddotDivider />}>
            {space.created_by_user && (
              <Text isTruncated maxW="200px">
                Created by {space.created_by_user.name || space.created_by_user.email}
              </Text>
            )}
            <Text>{pluralize('list', space.account_views?.length || 0, true)}</Text>

            {space.slack_channel && (
              <Flex gap={0.5} alignItems="center">
                <SlackIcon boxSize={4} />
                <Text isTruncated maxW="100px">
                  {space.slack_channel.name || space.slack_channel.id}
                </Text>
              </Flex>
            )}
          </HStack>
        </Box>
      </Flex>
      <Flex alignItems="center" gap={2}>
        <Button
          size="sm"
          variant="outline"
          as="a"
          href={projectPath(space.owner?.email ? `/mission-control/${space.owner.email}` : `/spaces/${space.id}`)}
        >
          View
        </Button>
        <Menu>
          <MenuButton
            size="xs"
            as={IconButton}
            icon={<IconDotsVertical size={15} />}
            variant="ghost"
            borderColor="gray.200"
          />
          <MenuList fontSize="sm" zIndex="popover">
            <MenuItem icon={<IconEdit size={16} />} onClick={editSpaceModal.onOpen}>
              Edit name
            </MenuItem>
            {space.owner && (
              <MenuItem icon={<IconCopyPlus size={16} />} onClick={applyTemplateModal.onOpen}>
                Apply template
              </MenuItem>
            )}
            <MenuItem icon={<IconTrash size={16} />} color="red.500" onClick={deletion.onOpen}>
              Delete…
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      <DeleteConfirmation
        isOpen={deletion.isOpen}
        onClose={deletion.onClose}
        title={`Are you sure you want to delete this Mission Control?`}
        confirmLabel="Delete"
        deletePath={projectPath(`/spaces/${space.id}`)}
      >
        <Text fontSize="sm" color="gray.600">
          {space.owner?.name} will need to reconfigure their Mission Control.
        </Text>
      </DeleteConfirmation>

      <EditSpaceModal {...editSpaceModal} space={space} />

      {space.owner && (
        <ApplyTemplateModal
          {...applyTemplateModal}
          templates={templates}
          user={space.owner}
          onApply={() => router.visit(window.location.href)}
        />
      )}
    </Flex>
  )
}
