import { Box, Flex, FlexProps } from '@chakra-ui/react'
import React from 'react'
import { TopBar } from '../pages/navigation/SideNav'

export const sizes = {
  sm: 'container.md',
  md: ['container.lg', 'container.lg', 'container.xl', 'container.xl'],
  lg: ['container.xl', 'container.xl', 'container.2xl', 'container.2xl'],
  'semi-full': 'min(100%, var(--max-container-width))',
  full: '100%'
}

type Props = React.PropsWithChildren<FlexProps> & {
  size?: keyof typeof sizes
  flush?: boolean
}

export default function PageLayout({ size = 'md', flush, ...props }: Props) {
  return (
    <Flex
      flexDirection="column"
      flex="1 1 auto"
      width="100%"
      height="100%"
      overflow="auto"
      minHeight="var(--content-height)"
    >
      <Box className="scroll-into-view" flex="none" />
      <TopBar />

      <Flex
        flexDirection="column"
        flex="1 1 auto"
        paddingX={flush ? 0 : [4, 4, 6, 8]}
        paddingY={flush ? 0 : [4, 4, 6, 8]}
        maxW="min(100%, var(--max-container-width))"
        m="0 auto"
        w={flush ? '100%' : sizes[size] || '100%'}
        alignItems="normal"
        gap={6}
        {...props}
      >
        {props.children}
      </Flex>
    </Flex>
  )
}
