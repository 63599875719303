import { KoalaUDialog } from '@app/components/ui/KoalaUDialog'
import { Apps } from '@app/types/App'
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import {
  IconCoin,
  IconCopyPlus,
  IconDotsVertical,
  IconEye,
  IconEyeOff,
  IconPlus,
  IconTrash,
  IconUsers
} from '@tabler/icons-react'
import dayjs from 'dayjs'
import truncate from 'lodash/truncate'
import React, { useCallback, useEffect, useMemo } from 'react'
import { toast } from 'sonner'
import router from '../../../lib/router'
import { AccountView } from '../../../types/AccountView'
import { Space } from '../../../types/Space'
import { useDeleteView } from '../../data/use-delete-view'
import { MissionControlView, useMissionControlViews } from '../../data/use-mission-control-views'
import { useOnboardingSteps } from '../../data/use-onboarding-steps'
import { useUpdateOnboarding } from '../../data/use-update-onboarding'
import { useUpdateSetting } from '../../data/use-update-setting'
import { useUserSettings } from '../../data/use-user-settings'
import Avatar from '../../ui/Avatar'
import { ComboboxWithSearch } from '../../ui/ComboboxWithSearch'
import { DeleteConfirmation } from '../../ui/DeleteConfirmation'
import { BuildingIcon, SlackIcon } from '../../ui/icons'
import { SmallPageHeading } from '../../ui/PageTitle'
import { usePermission } from '../../ui/PermissionsContext'
import { projectPath } from '../../ui/ProjectsContext'
import SquareIcon from '../../ui/SquareIcon'
import { TopBarContent } from '../../ui/TopBarContext'
import { Step, Tour } from '../../ui/TourComponent'
import { useCurrentUser } from '../../ui/UserContext'
import { PartialAccountView } from '../../ui/ViewSelector'
import { NewListModal } from '../account_views/components/NewListModal'
import { ApplyTemplateModal } from '../mission_control_templates/components/ApplyTemplateModal'
import { SlackAlertSidesheet } from '../spaces/components/SlackAlertSidesheet'
import { ViewsTabList } from '../spaces/components/ViewsTabList'

interface MCHeaderProps {
  space?: Space
  viewId?: string | null
  apps?: Apps
}

export function MCHeader(props: MCHeaderProps) {
  const slack = useDisclosure()
  const currentUser = useCurrentUser()
  const { hasPermission: canManageMembers } = usePermission({ on: 'project', action: 'can_manage_members' })
  const { data, refetch } = useMissionControlViews({
    userId: props.space?.owner_id,
    views: props.space?.account_views
  })

  const views = data?.views || emptyArray

  useEffect(() => {
    const handleListRemoved = async () => {
      try {
        const res = await refetch()
        // current view can't be found
        if (!res.data?.views.find((u) => u.id === props.viewId)) {
          router.visit(projectPath('/mission-control') + window.location.search)
        }
      } catch (error) {
        console.error(error)
      }
    }

    window.addEventListener('listremoved', handleListRemoved)

    return () => {
      window.removeEventListener('listremoved', handleListRemoved)
    }
  }, [refetch, props.viewId])

  const hideMissionControl = useDisclosure()
  const newListModal = useDisclosure()
  const applyTemplateModal = useDisclosure()

  const settings = useUserSettings()
  const { mutateAsync: updateUserSetting, isLoading: updatingSetting } = useUpdateSetting()

  const slackAlertConfigClassname = 'slack-alert-config'
  const addListsClassname = 'add-lists-button'

  const name = currentUser?.id === props.space?.owner_id ? 'Mission Control' : props.space?.name
  const newPath = window.location.pathname.split('/views')[0] + '/views/new'

  return (
    <TopBarContent>
      <Flex width="100%" alignSelf="stretch" alignItems="stretch" gap={3} justifyContent="space-between">
        <Flex flex="1 1 auto" alignItems="center" gap={3}>
          <Flex alignItems="center" gap={1.5}>
            {props.space?.owner && (
              <Avatar
                size="22px"
                src={props.space.owner.image}
                name={props.space.owner.name || props.space.owner.email}
              />
            )}

            <SmallPageHeading isTruncated>{name || 'Mission Control'}</SmallPageHeading>
          </Flex>

          <Divider orientation="vertical" height="24px" />

          {props.space?.id && (
            <ViewsTabList
              spaceId={props.space.id}
              viewId={props.viewId}
              views={views}
              onAddNewList={newListModal.onOpen}
            />
          )}
        </Flex>

        <Flex alignItems="center" gap={2}>
          {props.space?.id && (
            <>
              <Button
                variant="outline"
                className={slackAlertConfigClassname}
                title={props.space.slack_alert?.slack_channel_name || undefined}
                size="sm"
                onClick={slack.onOpen}
                leftIcon={<SlackIcon boxSize={4} />}
                iconSpacing={1}
              >
                {props.space.slack_alert?.slack_channel_name
                  ? `#${truncate(props.space.slack_alert?.slack_channel_name, { length: 22 })}`
                  : 'Setup Alerts'}
              </Button>

              <SlackAlertSidesheet space={props.space} apps={props.apps} {...slack} />

              {props.viewId && (
                <MissionControlTour
                  addListSelector={`.${addListsClassname}`}
                  slackSelector={`.${slackAlertConfigClassname}`}
                  hasSlack={!!props.space.slack_alert}
                  hasMultipleLists={(props.space.account_views || []).length > 1}
                  space={props.space}
                />
              )}
            </>
          )}

          <Menu placement="bottom-end">
            <MenuButton
              size="xs"
              as={IconButton}
              icon={<IconDotsVertical size={15} />}
              variant="ghost"
              onClick={(e) => {
                e.stopPropagation()
              }}
            />
            <MenuList fontSize="sm" zIndex="popover">
              <MenuItem icon={<IconCopyPlus size={16} />} onClick={applyTemplateModal.onOpen}>
                Apply template
              </MenuItem>
              {canManageMembers && currentUser?.id == props.space?.owner_id && (
                <>
                  {settings.data?.settings?.mission_control?.display ? (
                    <MenuItem icon={<IconEyeOff size={16} />} onClick={hideMissionControl.onOpen}>
                      Hide Mission Control…
                    </MenuItem>
                  ) : (
                    <MenuItem
                      icon={<IconEye size={16} />}
                      onClick={() =>
                        updateUserSetting({ setting_type: 'mission_control', key: 'display', value: true })
                      }
                    >
                      Show Mission Control
                    </MenuItem>
                  )}
                </>
              )}
            </MenuList>
          </Menu>

          <DeleteConfirmation
            isCentered
            isOpen={hideMissionControl.isOpen}
            title={`Are you sure?`}
            confirmLabel="Hide Mission Control"
            onClose={hideMissionControl.onClose}
            onConfirm={(event) => {
              event.preventDefault()

              if (!updatingSetting) {
                updateUserSetting({
                  setting_type: 'mission_control',
                  key: 'display',
                  value: false
                })
                  .then(() => {
                    router.visit(projectPath('/accounts'))
                  })
                  .catch(() => {
                    toast.error('There was an issue hiding your Mission Control')
                  })
              }
            }}
          >
            <Text fontSize="sm" color="gray.600">
              Are you sure you want to hide Mission Control? This will remove the tab from your personal sidenav. You
              can always add it back later.
            </Text>
          </DeleteConfirmation>

          <NewListModal kind="account" newPath={newPath} {...newListModal} />

          {props.space?.owner && (
            <ApplyTemplateModal {...applyTemplateModal} user={props.space.owner} onApply={() => refetch()} />
          )}
        </Flex>
      </Flex>
    </TopBarContent>
  )
}

const emptyArray = []

interface ViewSwitcherProps {
  className?: string
  views: MissionControlView[]
  isLoading?: boolean
  selectedAccountViewId?: string | null
}

export function ViewSwitcher({ className, views, isLoading, selectedAccountViewId }: ViewSwitcherProps) {
  const newListModal = useDisclosure()

  const { mutateAsync: deleteView, isLoading: deleting } = useDeleteView()
  const [currentId, setCurrentId] = React.useState<string | null>(null)
  const deletion = useDisclosure()

  const onDeleteRequest = deletion.onOpen

  const handleDelete = useCallback(
    (item: MissionControlView) => {
      setCurrentId(item.id)
      onDeleteRequest()
    },
    [onDeleteRequest]
  )

  const wrappedItemRenderer = useCallback(
    (props) => <AccountViewRenderer {...props} onDelete={handleDelete} />,
    [handleDelete]
  )

  const selected = useMemo(() => {
    return views.find((u) => u.id === selectedAccountViewId) || null
  }, [views, selectedAccountViewId])

  const changeView = useCallback(
    (accountView: PartialAccountView | null) => {
      let path

      if (selected && accountView?.slug) {
        path = window.location.pathname.replace(selected.slug, accountView.slug)
      }

      if (path) {
        router.visit(path)
      }
    },
    [selected]
  )

  const newPath = window.location.pathname.split('/views')[0] + '/views/new'

  return (
    <Box className={className}>
      <ComboboxWithSearch
        items={views}
        selectedItem={selected || null}
        onChange={changeView}
        filterItem={(a, val) => a.name?.toLowerCase().includes(val) || a.slug?.toLowerCase().includes(val) || false}
        itemToString={(item) => item?.name || item?.slug || 'Unknown'}
        isLoading={isLoading}
        itemRenderer={wrappedItemRenderer}
        selectButtonRenderer={AccountViewRenderer}
        triggerProps={{
          py: 1,
          px: 1.5,
          gap: 1,
          maxW: '250px',
          _hover: { bg: 'gray.100' }
        }}
        popoverProps={{
          maxW: '280px'
        }}
        popperOptions={{
          matchWidth: false,
          placement: 'bottom-start'
        }}
        footer={(combobox) => (
          <Box>
            <Divider />

            <Box padding={1}>
              <Flex
                as="button"
                alignItems="center"
                gap={1.5}
                fontSize="sm"
                fontWeight="medium"
                rounded="md"
                outline="none"
                width="100%"
                userSelect="none"
                color="gray.700"
                paddingX={2}
                paddingY={1.5}
                _focus={{ bg: 'gray.100' }}
                _hover={{ bg: 'gray.100' }}
                _disabled={{
                  opacity: 0.6,
                  cursor: 'not-allowed'
                }}
                onClick={() => {
                  combobox.closeMenu()
                  newListModal.onOpen()
                }}
                disabled={views.length >= 5}
              >
                <Icon as={IconPlus} boxSize={3.5} />
                Create new list
              </Flex>
            </Box>
          </Box>
        )}
      />

      <NewListModal kind="account" newPath={newPath} {...newListModal} />

      <DeleteConfirmation
        isCentered
        isOpen={deletion.isOpen && !!currentId}
        title={`Are you sure?`}
        confirmLabel="Delete list"
        onClose={() => {
          deletion.onClose()
          setCurrentId(null)
        }}
        onConfirm={(event) => {
          event.preventDefault()

          if (!deleting && currentId) {
            deleteView({ viewId: currentId })
          }
        }}
      >
        <Text fontSize="sm" color="gray.600">
          Are you sure you want to delete this list?
        </Text>
      </DeleteConfirmation>
    </Box>
  )
}

interface AccountViewRendererProps {
  item: MissionControlView | null
  selectedItem?: MissionControlView | null
  isToggleButton?: boolean
  isLoading?: boolean
  onDelete?: (item: MissionControlView) => void
}

const colors = {
  account: 'purple',
  profile: 'blue',
  opportunity: 'orange'
}

const icons = {
  account: BuildingIcon,
  profile: IconUsers,
  opportunity: IconCoin
}

function AccountViewRenderer({ item, isToggleButton, isLoading, onDelete }: AccountViewRendererProps) {
  if (isLoading) {
    return <Spinner size="sm" thickness="1.5px" color="gray.400" />
  }

  if (!item) {
    return null
  }

  return (
    <AccountViewRow
      item={item}
      rightContent={!isToggleButton && <OptionsMenu item={item} onDelete={() => onDelete?.(item)} />}
    />
  )
}

function AccountViewRow({
  item,
  rightContent
}: {
  item: MissionControlView | AccountView
  rightContent?: React.ReactNode
}) {
  const name = item.name || item.slug || 'Unknown'

  return (
    <Flex width="100%" alignItems="center" gap={1.5} isTruncated>
      <HStack flex="1 1 auto" spacing={1.5} title={name} isTruncated>
        <SquareIcon
          colorScheme={colors[item.kind] || 'gray'}
          icon={icons[item.kind] || BuildingIcon}
          iconSize="14px"
          padding="2px"
        />
        <Text flex="1 1 auto" fontSize="sm" fontWeight="medium" isTruncated>
          {name}
        </Text>
      </HStack>
      {rightContent}
    </Flex>
  )
}

function OptionsMenu(props: { item: MissionControlView; onDelete: () => void }) {
  return (
    <Menu placement="right-start">
      <MenuButton
        size="tiny"
        as={IconButton}
        icon={<IconDotsVertical size={15} />}
        variant="ghost"
        borderColor="gray.200"
        onClick={(e) => {
          e.stopPropagation()
        }}
      />
      <MenuList fontSize="sm" zIndex="popover">
        <MenuItem
          icon={<IconTrash size={16} />}
          color="red.500"
          onClick={(e) => {
            e.stopPropagation()
            props.onDelete()
          }}
        >
          Delete list
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

interface Props {
  addListSelector: string
  slackSelector: string
  hasSlack: boolean
  hasMultipleLists: boolean
  space?: Space
}

const ONBOARDING_CATEGORY = 'mission_control'

export function MissionControlTour({ addListSelector, slackSelector, hasSlack, hasMultipleLists, space }: Props) {
  const { data, isLoading } = useOnboardingSteps(ONBOARDING_CATEGORY)
  const daysAgo = dayjs().diff(dayjs(space?.created_at), 'day')

  const finishedSteps = useMemo(() => (data?.done || []).map((s) => s.step), [data?.done])

  const tourSteps: Step[] = useMemo(() => {
    const all: Step[] = []

    if (!hasMultipleLists) {
      all.push({
        target: addListSelector,
        title: 'Add more lists',
        content:
          "If you'd like to track other lists (target accounts, accounts not yet in your CRM, ABM accounts, etc.) you can add more lists to your Mission Control.",
        disableBeacon: true,
        placement: 'right-start',
        data: { step_id: 'add-lists' }
      })
    }

    if (!hasSlack) {
      all.push({
        target: slackSelector,
        title: 'Know when your accounts are heating up',
        content:
          'Connect your Mission Control to a Slack channel to receive alerts when your accounts are showing intent.',
        disableBeacon: true,
        placement: 'bottom-end',
        data: { step_id: 'connect-slack' }
      })
    }

    return all.filter((step) => !finishedSteps.includes(step.data?.step_id))
  }, [addListSelector, slackSelector, hasSlack, hasMultipleLists, finishedSteps])

  const updateStep = useUpdateOnboarding()

  if (isLoading) {
    return null
  }

  return (
    <>
      <Tour
        steps={tourSteps}
        run={tourSteps.length > 0}
        onNextStep={(data) => {
          if (data?.step?.data?.step_id) {
            updateStep.mutateAsync({ step: data.step.data.step_id, category: ONBOARDING_CATEGORY, status: 'completed' })
          }
        }}
        onSkipped={(data) => {
          if (data?.step?.data?.step_id) {
            updateStep.mutateAsync({ step: data.step.data.step_id, category: ONBOARDING_CATEGORY, status: 'skipped' })
          }
        }}
      />

      {tourSteps.length == 0 && daysAgo > 2 && <KoalaUDialog />}
    </>
  )
}
