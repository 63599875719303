import React, { useCallback, useState } from 'react'
import { projectPath } from '../../../ui/ProjectsContext'
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'

interface EditSpaceModalProps extends UseDisclosureProps {
  space: any
}

export function EditSpaceModal({ space, ...props }: EditSpaceModalProps) {
  const disclosure = useDisclosure(props)
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = useCallback(() => {
    setSubmitting(true)
  }, [])

  return (
    <Modal {...disclosure} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Rename your space</ModalHeader>
        <ModalBody>
          <form id="edit-space-form" method="POST" action={projectPath(`/spaces/${space.id}`)} onSubmit={onSubmit}>
            <AuthenticityToken />
            <input type="hidden" name="_method" value="patch" />

            <Stack spacing={6}>
              <FormControl>
                <FormLabel htmlFor="space[name]">Space name</FormLabel>
                <Input
                  autoFocus
                  size="sm"
                  defaultValue={space.name}
                  autoComplete="off"
                  placeholder="Add a descriptive name for your space"
                  name="space[name]"
                  isRequired
                  id="space[name]"
                />
              </FormControl>
            </Stack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" variant="outline" mr={3} onClick={disclosure.onClose}>
            Cancel
          </Button>
          <Button size="sm" type="submit" colorScheme="purple" form="edit-space-form" isLoading={submitting}>
            Update space
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
