import React from 'react'

interface Props {
  hasChanges?: boolean
  message?: string
}

export default function useUnsavedChangesPrompt({ hasChanges, message }: Props) {
  const [submitting, setSubmitting] = React.useState(false)
  const skipRef = React.useRef(false)

  React.useEffect(() => {
    const handler = (event) => {
      if (!event.defaultPrevented) {
        setSubmitting(true)
      }
    }

    document.addEventListener('submit', handler)

    return () => {
      document.removeEventListener('submit', handler)
    }
  }, [])

  React.useEffect(() => {
    if (!hasChanges || submitting) {
      return
    }

    const onBeforeUnload = (event) => {
      if (!submitting && !skipRef.current) {
        event.preventDefault()
        event.returnValue = ''
      }
    }

    const onBeforeVisit = (event) => {
      const url = (event as CustomEvent<{ url: string }>).detail?.url ?? window.location.href

      if (
        !event.defaultPrevented &&
        !skipRef.current &&
        differentPath(url, window.location.href) &&
        !window.confirm(message || 'You have unsaved changed you may lose.')
      ) {
        event.preventDefault()
      }
    }

    window.addEventListener('beforeunload', onBeforeUnload)
    window.addEventListener('router:before-visit', onBeforeVisit)

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
      window.removeEventListener('router:before-visit', onBeforeVisit)
    }
  }, [hasChanges, message, submitting])

  return { willPrompt: hasChanges && !submitting, skipRef }
}

function differentPath(urlA, urlB) {
  try {
    const pathA = new URL(urlA, window.location.origin).pathname
    const pathB = new URL(urlB, window.location.origin).pathname

    return pathA !== pathB
  } catch (error) {
    return false
  }
}
