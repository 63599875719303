import { AuthenticityToken } from '@app/components/ui/AuthenticityToken'
import { useDomains } from '@app/components/ui/DomainContext'
import { usePermission } from '@app/components/ui/PermissionsContext'
import { getCurrentProject } from '@app/components/ui/ProjectsContext'
import { useCurrentUser } from '@app/components/ui/UserContext'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import React from 'react'

interface DisableDialogProps {
  appTitle: string
  showRemoveCachesOption: boolean
}

export function DisconnectAppDialog({ appTitle, showRemoveCachesOption }: DisableDialogProps) {
  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const domains = useDomains()
  const [removeCaches, setRemoveCaches] = React.useState(false)
  const [koalaAdminIsSure, setKoalaAdminIsSure] = React.useState(false)
  const cancelRef = React.useRef<HTMLButtonElement>(null)

  const currentProjectSlug = getCurrentProject()?.slug
  const isKoalaProject = currentProjectSlug === 'koala'
  const isKoalaUser = domains.findMatches(useCurrentUser().email).length > 0
  const doubleCheckForKoalaAdmin = isKoalaUser && !isKoalaProject

  return (
    <>
      <Button flex="none" colorScheme="red" variant="outline" size="sm" isDisabled={!canEditProject} onClick={onOpen}>
        Disconnect…
      </Button>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Disconnect {appTitle}
            </AlertDialogHeader>

            <AlertDialogBody paddingY={6}>
              <Text>Are you sure you want to disconnect {appTitle}?</Text>

              {showRemoveCachesOption && (
                <>
                  <Text marginTop={10} marginBottom={2}>
                    Do you want to remove all cached data from {appTitle}?
                  </Text>
                  <Checkbox isChecked={removeCaches} onChange={(e) => setRemoveCaches(e.target.checked)}>
                    Remove cached {appTitle} data
                  </Checkbox>
                </>
              )}
              {doubleCheckForKoalaAdmin && (
                <>
                  <Text marginBottom={4}>
                    You are a Koala admin but this is not a Koala workspace,{' '}
                    <b>
                      are you sure you want to disconnect {appTitle} for {currentProjectSlug}?
                    </b>
                  </Text>
                  <Checkbox checked={koalaAdminIsSure} onChange={(e) => setKoalaAdminIsSure(e.target.checked)}>
                    Yes, I want to disconnect {appTitle} for {currentProjectSlug}
                  </Checkbox>
                </>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Box as="form" method="POST">
                <AuthenticityToken />
                <input type="hidden" name="_method" value="DELETE" />

                {showRemoveCachesOption && (
                  <input type="hidden" name="remove_caches" value={removeCaches ? '1' : '0'} />
                )}

                <Button size="sm" ref={cancelRef} variant="outline" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  colorScheme="red"
                  ml={3}
                  isDisabled={!canEditProject || (doubleCheckForKoalaAdmin && !koalaAdminIsSure)}
                >
                  Disconnect
                </Button>
              </Box>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
