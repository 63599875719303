import { newImportPath } from '@app/components/pages/imports/lib/path-helper'
import { CardRadioGroup } from '@app/components/ui/CardRadioGroup'
import PageLayout from '@app/components/ui/PageLayout'
import PageTitle, { SmallPageHeading } from '@app/components/ui/PageTitle'
import { projectPath } from '@app/components/ui/ProjectsContext'
import { AccountView } from '@app/types/AccountView'
import {
  Box,
  Button,
  Center,
  Circle,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Square,
  Stack,
  Text,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import {
  IconChevronRight,
  IconLayoutColumns,
  IconLayoutGrid,
  IconLock,
  IconPlus,
  IconTransform,
  IconUpload,
  IconUsers,
  IconUsersGroup
} from '@tabler/icons-react'
import pluralize from 'pluralize'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMedia } from 'react-use'
import { useDebouncedCallback } from 'use-debounce'
import { removeParams } from '../../../lib/remove-params'
import router from '../../../lib/router'
import { Apps } from '../../../types/App'
import { Crm } from '../../../types/Crm'
import { PageMeta } from '../../../types/PageMeta'
import { Template } from '../../../types/Template'
import { useTeams } from '../../data/use-teams'
import { useUrlFilters } from '../../data/use-url-filters'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import Avatar from '../../ui/Avatar'
import { Breadcrumb } from '../../ui/Breadcrumb'
import {
  ColumnManagementPopover,
  defaultAccountColumns,
  defaultProfileColumns,
  useColumns
} from '../../ui/ColumnSelector'
import { BuildingIcon } from '../../ui/icons'
import { NumberEasing } from '../../ui/NumberEasing'
import { usePermission } from '../../ui/PermissionsContext'
import SelectInput from '../../ui/SelectInput'
import SquareIcon from '../../ui/SquareIcon'
import { TopBarContent } from '../../ui/TopBarContext'
import useLatestRef from '../../ui/useLatestRef'
import { useCurrentUser } from '../../ui/UserContext'
import useUnsavedChangesPrompt from '../../ui/useUnsavedChangesPrompt'
import useUpdateEffect from '../../ui/useUpdateEffect'
import { FilterPreview } from '../accounts/components/FilterPreview'
import { AccountList } from '../icps/icp/account-list'
import { HighlightedAccount, mergeParams } from '../icps/types'
import NewListIllustration from '../lists/components/new-list.svg'
import { NewListTour } from '../mission_control/NewListTour'
import { UserSelect } from '../mission_control/UserSelect'
import { HighlightedProfile, ProfileList } from '../profiles/components/profile-list'

interface OwnershipOption {
  label: string
  value: 'private' | 'shared' | 'team' | 'space'
  teamId?: string
  spaceId?: string
}

const defaultOwnershipOptions: OwnershipOption[] = [
  { label: 'Private', value: 'private' },
  { label: 'Everyone', value: 'shared' }
]

const ownershipIcons = {
  private: IconLock,
  shared: IconUsersGroup,
  team: IconUsers
}

const hierarchyTitle = {
  account: 'Accounts',
  profile: 'People'
}

const hierarchyPath = {
  account: '/accounts',
  profile: '/people'
}

interface Props {
  account_view?: AccountView
  accounts: Array<HighlightedAccount | HighlightedProfile>
  template?: Template
  columns: string[]
  counts: {
    seen: number
    all: number
  }
  apps: Apps
  crm?: Crm
  page_meta?: PageMeta
  facet_filters?: {
    [facet: string]: any
  }
}

export default function New(props: Props) {
  const [loading, setLoading] = useState(false)

  const viewType = props.account_view?.kind
  const profiles = props.accounts as HighlightedProfile[]
  const accounts = props.accounts as HighlightedAccount[]

  const facets = useUrlFilters({
    initialRange: props.account_view?.filters?.range ?? 'all',
    initialFacets: props.account_view?.filters?.facets,
    initialFocusTime: props.account_view?.filters?.focus_time,
    initialSortBy: props.account_view?.filters?.sort_by,
    facetCloudPath: viewType === 'profile' ? '/profiles/facet-cloud' : '/accounts/facet-cloud'
  })

  const largeScreen = useMedia('(min-width: 768px) and (min-height: 600px)')
  const hasFilters = Object.keys(props.facet_filters || {}).length > 0

  const { hasPermission: canViewAsMember } = usePermission({ on: 'project', action: 'can_view_as_member' })

  // TODO clean this up...
  const onMissionControl = window.location.pathname.includes('/mission-control/')
  const onTemplates = window.location.pathname.includes('/mission-control/templates/')
  const prevPath = onTemplates
    ? window.location.pathname
    : onMissionControl
      ? props.account_view?.owner_permalink || projectPath('/mission-control')
      : projectPath(hierarchyPath[viewType!] || '/views')

  const params = new URLSearchParams(window.location.search)

  const currentUser = useCurrentUser()
  const [selectedUserId, setSelectedUserId] = React.useState<string | null>(params.get('user'))

  useUpdateEffect(() => {
    const user = selectedUserId && selectedUserId !== currentUser.id ? selectedUserId : undefined
    const params = new URLSearchParams(window.location.search)
    const existing = params.get('user') || undefined

    if (user !== existing) {
      const path = mergeParams(onTemplates ? window.location.toString() : prevPath, { user })
      setLoading(true)
      router.visit(path)
    }
  }, [selectedUserId, currentUser.id, prevPath, onTemplates])

  const defaultColumns = viewType === 'profile' ? defaultProfileColumns : defaultAccountColumns

  const { columns, loadingColumns, onColumnChange, onColumnRemove } = useColumns({
    data: props.accounts,
    columns: props.columns?.length ? props.columns : defaultColumns.map((c) => c.key),
    initialColumns: props.columns?.length ? props.columns : defaultColumns.map((c) => c.key)
  })

  const contextualPath = useMemo(() => {
    if (onTemplates) {
      return window.location.pathname.replace(/\/new$/, '')
    }

    if (onMissionControl) {
      return props.account_view?.owner_permalink
        ? props.account_view.owner_permalink + '/views'
        : projectPath('/mission-control/views')
    }

    if (props.account_view?.space_id) {
      return projectPath(`/spaces/${props.account_view.space_id}/views`)
    }

    return projectPath('/views')
  }, [onMissionControl, onTemplates, props.account_view])

  const spaceName =
    currentUser?.id === props.account_view?.space?.owner_id ? 'Mission Control' : props.account_view?.space?.name

  return (
    <PageLayout flush gap={0} paddingX={0} paddingY={0} maxH="100%" minH="300px">
      <PageTitle skipRendering>Create a List</PageTitle>

      <TopBarContent>
        <Flex width="100%" alignItems="center" gap={3} justifyContent="space-between">
          {onTemplates ? (
            <>
              <Breadcrumb
                paths={[
                  {
                    path: projectPath('/mission-control/setup?tab=templates'),
                    title: 'Templates'
                  },
                  ...(props.template?.id
                    ? [
                        {
                          path: projectPath('/mission-control/templates/' + props.template?.id),
                          title: props.template?.name
                        }
                      ]
                    : []),
                  { path: window.location.toString(), title: 'New list' }
                ]}
              />

              {canViewAsMember && (
                <Flex flex="none" alignItems="center" justifyContent="center" gap={1}>
                  <Text fontSize="sm" color="gray.600">
                    Previewing as
                  </Text>
                  <UserSelect
                    selectedUserId={selectedUserId || currentUser!.id}
                    onChange={setSelectedUserId}
                    isReadOnly={!canViewAsMember}
                    includeCrmUsers
                  />
                </Flex>
              )}
            </>
          ) : onMissionControl ? (
            <Flex flex="1 1 auto" alignItems="center" gap={1.5}>
              {props.account_view?.space?.owner && (
                <Avatar
                  size="22px"
                  src={props.account_view.space.owner.image}
                  name={props.account_view.space.owner.name || props.account_view.space.owner.email}
                />
              )}

              <SmallPageHeading isTruncated>{spaceName || 'Mission Control'}</SmallPageHeading>
              <Icon as={IconChevronRight} color="gray.400" boxSize={4} flex="none" />
              <Breadcrumb paths={[{ path: window.location.toString(), title: 'New list' }]} />
            </Flex>
          ) : props.account_view?.space ? (
            <Flex alignItems="center" gap={1.5}>
              <Icon as={IconLayoutGrid} color="gray.500" boxSize={4} flex="none" />
              <Breadcrumb
                paths={[
                  {
                    path: projectPath(`/spaces/${props.account_view.space_id}`),
                    title: props.account_view.space?.name || 'Space'
                  },
                  { path: window.location.toString(), title: 'New list' }
                ]}
              />
            </Flex>
          ) : (
            <Breadcrumb
              paths={[
                {
                  path: prevPath,
                  title: hierarchyTitle[viewType!] || 'Lists'
                },
                { path: window.location.toString(), title: 'New list' }
              ]}
            />
          )}
        </Flex>
      </TopBarContent>

      {props.account_view && viewType ? (
        <Box display="flex" flexDirection="column" flex="1 1 auto" maxH="100%" minH="300px">
          <Box bg="white" py={4} px={4} position="sticky" top={largeScreen ? `var(--nav-height)` : '0'} zIndex={100}>
            <ListEditForm
              facetParams={facets}
              account_view={props.account_view}
              hasFilters={hasFilters}
              setLoading={setLoading}
              counts={props.counts}
              columns={columns}
              onColumnChange={onColumnChange}
              apps={props.apps}
              prevPath={prevPath}
              formPath={contextualPath}
              parentId={selectedUserId || undefined}
            />
          </Box>

          {viewType && accounts.length > 0 && (
            <Box position="relative" display="flex" flexDirection="column" flex="1 1 auto" maxH="100%" minH="300px">
              {viewType === 'profile' && profiles.length > 0 && (
                <ProfileList
                  facetParams={facets}
                  profiles={profiles}
                  range={props.account_view?.filters?.range ?? 'week'}
                  columns={columns}
                  sortingBy={facets.sortBy}
                  canAddColumns
                  onColumnChange={onColumnChange}
                  onColumnRemove={onColumnRemove}
                  onSortChange={facets.setSortBy}
                />
              )}
              {viewType === 'account' && accounts.length > 0 && (
                <AccountList
                  accounts={accounts}
                  facetParams={facets}
                  range={props.account_view?.filters?.range ?? 'week'}
                  columns={columns}
                  sortingBy={facets.sortBy}
                  showActions={false}
                  canAddColumns
                  loadingColumns={loadingColumns}
                  onColumnChange={onColumnChange}
                  onColumnRemove={onColumnRemove}
                  onSortChange={facets.setSortBy}
                />
              )}

              {loading && (
                <Box
                  position="absolute"
                  height="100%"
                  width="100%"
                  top={0}
                  bottom={0}
                  left={0}
                  right={0}
                  bg="whiteAlpha.700"
                  zIndex={100}
                >
                  <Center paddingY={10}>
                    <Spinner />
                  </Center>
                </Box>
              )}
            </Box>
          )}

          {accounts.length === 0 && (
            <Box px={4}>
              {hasFilters && <NoResults kind={viewType} onClearFilters={facets.clearFilters} />}
              {!hasFilters && <NoFilters kind={viewType} />}
            </Box>
          )}

          {accounts.length > 0 && accounts.length < (props.page_meta?.total_count || 0) && (
            <Box position="absolute" bottom="16px" left="0" right="0" margin="auto" maxWidth="400px" textAlign="center">
              <Text
                display="inline-flex"
                fontSize="sm"
                fontWeight="medium"
                color="gray.700"
                paddingX={2}
                paddingY={2}
                bg="rgba(255,255,255,0.8)"
                mx="auto"
                backdropFilter="blur(4px)"
                rounded="lg"
              >
                These are a sample of {viewType === 'account' ? 'accounts' : 'people'} based on your filters
              </Text>
            </Box>
          )}
        </Box>
      ) : (
        <ListKindSelection
          kind={viewType}
          ownership={props.account_view?.ownership ?? 'private'}
          teamId={props.account_view?.team_id}
          spaceId={props.account_view?.space_id}
          parentId={props.account_view?.space?.owner_id}
        />
      )}
    </PageLayout>
  )
}

interface ListEditFormProps {
  account_view?: Props['account_view']
  counts: Props['counts']
  columns?: string[]
  onColumnChange?: (columns: any[]) => void
  apps: Props['apps']
  prevPath?: string
  formPath: string
  parentId?: string
  facetParams: ReturnType<typeof useUrlFilters>
  hasFilters?: boolean
  setLoading: (loading: boolean) => void
}

function ListEditForm({
  account_view,
  counts,
  columns,
  onColumnChange,
  apps,
  prevPath,
  formPath,
  parentId,
  facetParams,
  hasFilters,
  setLoading
}: ListEditFormProps) {
  const moreInfoModal = useDisclosure()
  const currentUser = useCurrentUser()

  const [submitting, setSubmitting] = useState(false)
  const [viewName, setViewName] = useState(account_view?.name ?? '')
  const viewNameRef = useLatestRef(viewName)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const [ownership, setOwnership] = useState(account_view?.ownership ?? 'private')
  const [teamId, setTeamId] = useState<AccountView['team_id']>(account_view?.team_id)

  const viewType = account_view?.kind
  const facets = facetParams
  const colorScheme = viewType === 'account' ? 'purple' : 'blue'

  const debouncedNameChange = useDebouncedCallback((value) => {
    setViewName(value)
  }, 250)

  const onNameChange = useCallback(
    (event) => {
      debouncedNameChange(event.target.value)
    },
    [debouncedNameChange]
  )

  const teams = useTeams()

  const ownershipOptions = useMemo(() => {
    const teamOptions = (teams.data?.teams ?? []).map((team) => ({
      label: team.name.replace(/team/gi, '') + ' Team',
      value: 'team' as const,
      teamId: team.id
    }))

    return defaultOwnershipOptions.concat(teamOptions)
  }, [teams.data])

  useEffect(() => {
    if (account_view?.name) {
      setViewName(account_view.name)
    }

    if (account_view?.team_id !== undefined) {
      setTeamId(account_view?.team_id)
    }

    if (account_view?.ownership) {
      setOwnership(account_view.ownership)
    }

    setLoading(false)
  }, [account_view, setLoading])

  const hasChanges = useMemo(() => {
    return (
      (account_view?.name || '') != viewName ||
      (account_view?.ownership ?? 'private') != ownership ||
      account_view?.team_id != teamId ||
      hasFilters
    )
  }, [account_view, viewName, ownership, teamId, hasFilters])

  const { skipRef } = useUnsavedChangesPrompt({ hasChanges })

  const onSubmit = useCallback(
    (event: React.FormEvent) => {
      if (!viewNameRef.current) {
        event.preventDefault()
        moreInfoModal.onOpen()
        return
      }

      setSubmitting(true)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [moreInfoModal.onOpen]
  )

  const formId = `new-list-form`
  const userId = parentId !== currentUser?.id ? parentId : undefined

  // used for the guided tour
  const filterSelector = 'filter-menu-trigger'
  const columnSelector = 'column-selector'
  const showTour = formPath?.includes('/mission-control') || false

  return (
    <form
      id={formId}
      action={
        // gotta remove `account_view` params so the url params dont clobber form-encoded ones
        formPath + `?${removeParams(window.location.search, (k) => k.startsWith('account_view['))}`
      }
      method="POST"
      onSubmit={onSubmit}
    >
      <Modal {...moreInfoModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Create your list</ModalHeader>
          <ModalBody>
            <Stack spacing={4} paddingBottom={4}>
              <Text fontSize="sm" color="gray.600">
                Give your list a name so you can easily find it later. You can change this at any time.
              </Text>
              <Input
                autoFocus
                autoComplete="off"
                name="account_view[name]"
                isRequired
                id="account_view[name]"
                onChange={(e) => {
                  onNameChange(e)
                  if (inputRef.current) {
                    inputRef.current.value = e.target.value
                  }
                }}
              />
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent="stretch" gap={3}>
            <Button flex="1 1 50%" onClick={moreInfoModal.onClose} variant="outline">
              Cancel
            </Button>
            <Button
              flex="1 1 50%"
              type="submit"
              colorScheme={colorScheme}
              isDisabled={!viewName}
              form={formId}
              isLoading={submitting}
            >
              Create List
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AuthenticityToken />
      <input type="hidden" name="account_view[kind]" value={viewType} />
      <input type="hidden" name="account_view[ownership]" value={ownership} />
      <input type="hidden" name="account_view[team_id]" value={teamId || ''} />
      <input type="hidden" name="account_view[space_id]" value={account_view?.space_id || ''} />

      {columns?.map((column) => (
        <input key={column} type="hidden" name="account_view[display_settings][display_columns][]" value={column} />
      ))}

      {showTour && <NewListTour filterSelector={filterSelector} columnSelector={columnSelector} />}

      <Stack
        spacing={6}
        alignItems="stretch"
        p={6}
        rounded="xl"
        border="1px solid"
        borderColor="gray.200"
        bg="white"
        shadow="lg"
      >
        <Stack spacing={2}>
          <Flex alignItems="center" justifyContent="space-between" gap={5}>
            <Input
              ref={inputRef}
              size="lg"
              autoFocus
              autoComplete="off"
              fontWeight="medium"
              variant="unstyled"
              rounded="none"
              name="account_view[name]"
              placeholder="Name this list"
              _placeholder={{ color: 'gray.500' }}
              defaultValue={account_view?.name ?? ''}
              onChange={onNameChange}
            />

            <HStack spacing={2}>
              <Button
                as="a"
                href={
                  prevPath
                    ? mergeParams(prevPath, { user: userId || undefined })
                    : projectPath(viewType === 'account' ? '/accounts' : '/people')
                }
                onClick={() => {
                  skipRef.current = true
                }}
                size="sm"
                variant="ghost"
                color="gray.500"
                _hover={{ color: 'gray.800' }}
              >
                Cancel
              </Button>
              <Button size="sm" colorScheme={colorScheme} type="submit" isDisabled={!hasFilters} isLoading={submitting}>
                Create List
              </Button>
            </HStack>
          </Flex>
          <HStack
            alignItems="center"
            spacing={3}
            divider={
              prevPath?.includes('/mission-control') ? undefined : (
                <Divider orientation="vertical" height="14px" borderColor="gray.400" />
              )
            }
          >
            <Tooltip
              label={`Switch list type to ${viewType === 'account' ? 'People' : 'Accounts'}`}
              placement="top"
              hasArrow
              arrowSize={8}
            >
              <HStack
                role="group"
                cursor="pointer"
                alignItems="center"
                spacing={1.5}
                onClick={() => {
                  setLoading(true)
                  router.visit(
                    mergeParams(window.location.toString(), {
                      'account_view[kind]': viewType === 'account' ? 'profile' : 'account'
                    })
                  )
                }}
              >
                <Square
                  size="20px"
                  padding="3px"
                  rounded="md"
                  color="white"
                  bgGradient={
                    viewType === 'account'
                      ? 'linear(to-br, purple.300, purple.600)'
                      : 'linear(to-br, blue.300, blue.600)'
                  }
                  overflow="hidden"
                >
                  <Box
                    maxHeight="100%"
                    transition="transform 200ms cubic-bezier(0, 0, 0.2, 1)"
                    _groupHover={{ transform: 'translateY(-125%)' }}
                  >
                    {viewType === 'account' ? <BuildingIcon boxSize={3.5} display="block" /> : <IconUsers size={14} />}
                    <Icon as={IconTransform} boxSize={3.5} />
                  </Box>
                </Square>
                <Text fontSize="sm" fontWeight="medium">
                  {counts.all === 0 || !hasFilters ? (
                    'No'
                  ) : (
                    <NumberEasing
                      value={counts.all}
                      speed={1000}
                      decimals={0}
                      ease="quadOut"
                      transitionWidth
                      render={(n) => Math.floor(n).toLocaleString()}
                    />
                  )}{' '}
                  {pluralize(viewType === 'account' ? 'Account' : 'People', counts.all)}
                </Text>
              </HStack>
            </Tooltip>

            {/* don't show access control for MC or Spaces */}
            {!prevPath?.includes('/mission-control') && ownership != 'space' && !account_view?.space_id && (
              <HStack alignItems="center" spacing={1}>
                <Text fontSize="sm" fontWeight="medium" color="gray.500">
                  Access:
                </Text>
                <SelectInput
                  size="xs"
                  items={ownershipOptions}
                  itemToString={(item) => item?.label ?? 'Unknown'}
                  selectedItem={ownershipOptions.find(
                    (s) => s.value === ownership && (ownership === 'team' ? s.teamId === teamId : !s.teamId)
                  )}
                  onSelectedItemChange={({ selectedItem }) => {
                    setOwnership(selectedItem?.value ?? 'private')
                    setTeamId(selectedItem?.teamId)
                  }}
                  itemRenderer={(item) => {
                    return (
                      <Flex alignItems="center" gap={2} fontSize="sm">
                        <Icon flex="none" as={ownershipIcons[item.value]} boxSize={4} color="gray.600" />
                        <Text fontWeight="medium">{item.label}</Text>
                      </Flex>
                    )
                  }}
                />
              </HStack>
            )}
          </HStack>
        </Stack>

        <Flex gap={3} justifyContent="space-between" alignItems="flex-start">
          <FilterPreview
            {...facets}
            kind={viewType === 'account' ? 'account' : 'profile'}
            apps={apps}
            canClearFilters={false}
          >
            <Button
              className={filterSelector}
              size="sm"
              variant="ghost"
              bg="white"
              border="1px solid"
              borderColor={`gray.200`}
              _hover={{ bg: `${colorScheme}.50`, color: `${colorScheme}.700` }}
              colorScheme={colorScheme}
              leftIcon={<IconPlus size={14} />}
              iconSpacing={1.5}
            >
              Add Filter
            </Button>
          </FilterPreview>

          <ColumnManagementPopover
            audienceKind={account_view?.kind || 'account'}
            apps={Object.values(apps)}
            selectedColumns={columns}
            onChange={onColumnChange}
          >
            <IconButton
              aria-label="Edit columns"
              className={columnSelector}
              variant="outline"
              size="sm"
              flex="none"
              icon={<IconLayoutColumns size={18} />}
            />
          </ColumnManagementPopover>
        </Flex>
      </Stack>
    </form>
  )
}

export interface ListKindSelectionProps {
  kind?: 'account' | 'profile' | 'contacts-csv' | 'accounts-csv'
  ownership?: 'private' | 'shared' | 'team' | 'space'
  teamId?: string
  spaceId?: string
  parentId?: string | null
  isTemplate?: boolean
  newPath?: string
}

export function ListKindSelection(props: ListKindSelectionProps) {
  const [kind, setKind] = useState(props.kind)
  const currentUser = useCurrentUser()
  const userId = currentUser?.id !== props.parentId ? props.parentId : undefined

  const contextualPath = useMemo(() => {
    if (props.newPath) {
      return props.newPath
    }

    if (props.parentId) {
      return projectPath('/mission-control/views/new')
    }

    if (props.spaceId) {
      return projectPath(`/spaces/${props.spaceId}/views/new`)
    }

    return projectPath('/views/new')
  }, [props.newPath, props.parentId, props.spaceId])

  return (
    <Box>
      <Stack bg="white" py="8" maxWidth="500px" marginX="auto">
        <Heading size="md">Create a new list</Heading>
        <Text fontSize="sm" color="gray.600">
          Lists let you segment people or accounts to share with your team, power automations and Slack alerts. Create
          dynamic lists using filters, or create static lists by adding accounts/people from a CSV.
        </Text>
        <Box py="4">
          <CardRadioGroup
            size="md"
            direction="column"
            defaultValue={kind}
            colorScheme="purple"
            onChange={(selection) => setKind(selection as ListKindSelectionProps['kind'])}
            options={[
              {
                label: (
                  <Stack alignItems="flex-start">
                    <SquareIcon icon={BuildingIcon} iconSize={5} colorScheme="purple" padding={1.5} rounded="lg" />
                    <Text fontSize="sm" fontWeight="semibold">
                      Accounts
                    </Text>
                  </Stack>
                ),
                description: 'Create a dynamic list of accounts in Koala.',
                value: 'account'
              },
              {
                label: (
                  <Stack alignItems="flex-start">
                    <SquareIcon icon={IconUpload} iconSize={5} colorScheme="purple" padding={1.5} rounded="lg" />
                    <Text fontSize="sm" fontWeight="semibold">
                      Account CSV Upload
                    </Text>
                  </Stack>
                ),
                description: 'Create a static list of companies from a CSV.',
                value: 'accounts-csv',
                isDisabled: props.ownership === 'team' || props.isTemplate
              },
              {
                label: (
                  <Stack alignItems="flex-start">
                    <SquareIcon icon={IconUsers} iconSize={5} colorScheme="blue" padding={1.5} rounded="lg" />
                    <Text fontSize="sm" fontWeight="semibold">
                      People
                    </Text>
                  </Stack>
                ),
                description: 'Create a dynamic list of people in Koala.',
                value: 'profile'
              },
              {
                label: (
                  <Stack alignItems="flex-start">
                    <SquareIcon icon={IconUpload} iconSize={5} colorScheme="blue" padding={1.5} rounded="lg" />
                    <Text fontSize="sm" fontWeight="semibold">
                      Contact CSV Upload
                    </Text>
                  </Stack>
                ),
                description: 'Create a static list of people from a CSV.',
                value: 'profiles-csv',
                isDisabled: props.ownership === 'team' || props.isTemplate
              }
            ]}
          />
        </Box>
        <Box textAlign="right">
          {kind?.includes('csv') ? (
            <Button
              as={Link}
              minWidth="100px"
              colorScheme="purple"
              href={newImportPath(kind.split('-')[0] as 'profiles' | 'accounts')}
            >
              Continue
            </Button>
          ) : (
            <Button
              as={Link}
              minWidth="100px"
              colorScheme="purple"
              isDisabled={!kind}
              href={
                kind
                  ? mergeParams(contextualPath + window.location.search, {
                      'account_view[kind]': kind,
                      'account_view[ownership]': props.ownership,
                      'account_view[team_id]': props.teamId || undefined,
                      'account_view[space_id]': props.spaceId || undefined,
                      user: userId || undefined
                    })
                  : undefined
              }
            >
              Continue
            </Button>
          )}
        </Box>
      </Stack>
    </Box>
  )
}

function NoFilters({ kind }: { kind: 'account' | 'profile' }) {
  const params = new URLSearchParams(window.location.search)
  const onlySeen = params.get('range') === 'any' || params.get('seen') == '0' ? false : true
  const pluralKind = kind === 'account' ? 'accounts' : 'people'

  return (
    <Box position="relative">
      <Stack spacing={4}>
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
      </Stack>

      <Box
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Center maxWidth="420px" px={8} py={8} bg="white" shadow="base" rounded="lg">
          <Stack align="center" textAlign="center" maxW="500" spacing={8}>
            <Image src={NewListIllustration} alt="New list of companies" width="248px" height="152px" />
            <Stack spacing={2} px={2}>
              <Heading size="sm">Set up your list</Heading>
              <Text color="gray.500" fontSize="sm">
                Start adding filters to find {pluralKind} {onlySeen ? 'Koala has seen' : 'in Koala'}. Find {pluralKind}{' '}
                assigned to you, {pluralKind} from your target accounts, unowned accounts or more!
              </Text>
            </Stack>
          </Stack>
        </Center>
      </Box>
    </Box>
  )
}

function NoResults({ kind, onClearFilters }: { kind: 'account' | 'profile'; onClearFilters: () => void }) {
  return (
    <Box position="relative">
      <Stack spacing={4}>
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
      </Stack>

      <Box
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Center p={8} bg="white" shadow="base" rounded="lg">
          <Stack align="center" textAlign="center" maxW="500" spacing={3}>
            <Stack spacing={1.5}>
              <Heading size="sm">No results matching these filters</Heading>
              <Text color="gray.600" fontSize="sm">
                We couldn't find any {kind === 'account' ? 'Accounts' : 'People'} for the filters you have selected
              </Text>
            </Stack>

            <Button size="xs" variant="outline" onClick={onClearFilters}>
              Clear Filters
            </Button>
          </Stack>
        </Center>
      </Box>
    </Box>
  )
}

function SkeletonRow() {
  return (
    <Box rounded="xl" bg="gray.50">
      <Flex opacity={0.75} alignItems="center" gap={6} justifyContent="space-between" pl={6} pr={8} py={4}>
        <HStack spacing={3}>
          <Circle size="10" bg="gray.200" />
          <Box width="140px" height="12px" rounded="lg" bg="gray.200" />
        </HStack>
        <Box width="80px" height="12px" rounded="lg" bg="gray.200" />
        <Box width="80px" height="12px" rounded="lg" bg="gray.200" />
        <Box width="80px" height="12px" rounded="lg" bg="gray.200" />
        <Box width="80px" height="12px" rounded="lg" bg="gray.200" />
      </Flex>
    </Box>
  )
}
