import { ButtonProps, Flex, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import { IconChevronDown } from '@tabler/icons-react'
import React from 'react'
import { Facet, FacetMappings } from '../..'
import FilterPopover from '../../facets/filter-popover'
import { FilterPreviewProps } from '../FilterPreview/types'

interface Props extends FilterPreviewProps {
  children: React.ReactNode
  facet?: string
  value: Facet
  overflow?: Array<string | number | boolean> | null
  facetMappings: FacetMappings
  isRemovable?: boolean
  colorScheme: ButtonProps['colorScheme']
}

function Values(props: Props) {
  const { children, value, facet, isEditable, overflow, isRemovable, ...rest } = props
  const disclosure = useDisclosure()
  const isBooleanValue = value === 'true' || value === 'false'
  const hasValues = facet && !isBooleanValue && isEditable
  const colorScheme = props.colorScheme ?? 'purple'

  const trigger = (
    <Flex
      as="span"
      gap={1}
      paddingX={1}
      marginRight={isRemovable ? 0 : 1}
      rounded="md"
      borderWidth="1px"
      borderColor="transparent"
      alignSelf="center"
      justifyContent="center"
      alignItems="center"
      _hover={hasValues ? { background: `${colorScheme}.100`, borderColor: 'gray.200', cursor: 'pointer' } : undefined}
      _active={hasValues ? { background: `${colorScheme}.100` } : undefined}
      transition="all 200ms cubic-bezier(0, 0, 0.2, 1)"
      onClick={disclosure.onToggle}
      isTruncated
    >
      {!!children && (
        <Text as="span" isTruncated fontWeight="medium" fontSize="sm">
          {children}
        </Text>
      )}
      {hasValues && !isRemovable && <IconChevronDown size={14} />}
    </Flex>
  )

  if (hasValues) {
    return (
      <FilterPopover
        {...disclosure}
        {...rest}
        selectedFacetKey={facet}
        width={facet === 'sources' ? 'auto' : undefined}
        placement="bottom"
      >
        {trigger}
      </FilterPopover>
    )
  }

  if (overflow) {
    return (
      <Tooltip padding="12px" label={overflow.join(', ')}>
        {trigger}
      </Tooltip>
    )
  }

  return trigger
}

export default Values
