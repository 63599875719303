import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'

export default function DrawnArrowUpperLeft(props: BoxProps) {
  return (
    <Box as="svg" viewBox="0 0 235 235" fill="currentColor" {...props}>
      <g clipPath="url(#clip0_3_230)">
        <path
          d="M49.2061 28.7363C44.7581 35.8775 40.309 42.7204 35.4162 50.6055C31.9719 46.2697 32.105 41.6482 33.8817 38.0761C39.2119 27.3597 44.8403 16.6444 51.3654 6.52853C55.0726 0.726675 61.0397 1.64198 64.192 7.76586C69.5947 17.9232 74.4021 28.3766 79.5077 38.8311C79.808 39.4285 80.2579 40.1756 80.1103 40.6223C79.8183 42.4105 79.0786 44.0479 78.4884 45.835C76.9953 45.2335 74.908 45.2262 74.1588 44.1799C71.4626 40.5923 69.2132 36.857 66.8152 33.2704C65.4666 31.3275 64.4162 29.3856 61.2794 27.7346C61.1422 31.1633 60.8565 34.7407 60.7193 38.1694C60.966 109.14 106.95 169.982 175.158 190.347C183.963 192.912 192.914 194.434 201.867 196.553C205.001 197.309 207.986 198.214 211.12 198.97C211.271 199.716 211.125 200.461 211.277 201.207C209.49 202.096 207.558 203.729 205.619 203.573C199.207 203.402 192.796 203.23 186.381 202.164C122.532 191.804 69.8741 141.227 55.4824 75.7234C52.7551 63.1898 52.2627 50.2168 50.8768 37.5388C50.5693 34.854 50.7095 32.3199 50.7002 29.6361C50.5496 29.1883 49.9521 28.888 49.2061 28.7363Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_230">
          <rect width="251" height="81" fill="currentColor" transform="translate(57.3959 0.12027) rotate(45)" />
        </clipPath>
      </defs>
    </Box>
  )
}
