import { Button, Tooltip } from '@chakra-ui/react'
import { IconEdit, IconEditOff } from '@tabler/icons-react'
import React, { useState, useEffect } from 'react'

export const BypassAllToggleButton = ({ mappings, setMappings }) => {
  const [someSelected, setSomeSelected] = useState(mappings.some((mapping) => !mapping.bypass_update))

  useEffect(() => {
    setSomeSelected(mappings.some((mapping) => !mapping.bypass_update))
  }, [mappings])

  const toggleBypassUpdate = () => {
    // If all fields are selected, deselect all (set bypass_update to true)
    // If any field is deselected, select all (set bypass_update to false)
    const newBypassUpdateValue = someSelected ? true : false

    const updatedMappings = mappings.map((mapping) => ({
      ...mapping,
      bypass_update: newBypassUpdateValue
    }))
    setMappings(updatedMappings)
  }

  return (
    <Tooltip label={`${someSelected ? 'Disable' : 'Enable'} overwrite on all fields`} placement="top">
      <Button
        variant="outline"
        size="xs"
        leftIcon={someSelected ? <IconEditOff size="12" /> : <IconEdit size="12" />}
        onClick={toggleBypassUpdate}
      >
        {`${someSelected ? 'Disable' : 'Enable'} overwrite`}
      </Button>
    </Tooltip>
  )
}
