import {
  Box,
  Button,
  Center,
  Checkbox,
  Collapse,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Link,
  Stack,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { IconArrowRight, IconPlus, IconZoomQuestion } from '@tabler/icons-react'
import pluralize from 'pluralize'
import React from 'react'
import { useDebounce } from 'use-debounce'
import { post } from '../../../../lib/api'
import { Project } from '../../../../types/Project'
import { Space } from '../../../../types/Space'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { GrayCard } from '../../../ui/Card'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import KoalaLogo from '../../../ui/KoalaLogo'
import { useCurrentUser } from '../../../ui/UserContext'
import { Plan } from '../../billing/show'
import { Step } from './SetupProject'
import { SpacesModal } from './SpacesModal'
import { TeamsModal } from './TeamsModal'
import workspaceImg from './workspace.png'

type Proj = Pick<Project, 'name' | 'slug' | 'domain' | 'potential_domain' | 'member_count'>

export interface Props {
  showLogo?: boolean
  project?: Pick<Project, 'id' | 'name' | 'slug' | 'domain'>
  authorized_projects?: Proj[]
  existing_projects?: Proj[]
  suggested_authorized_domain?: string
  selected_plan: Plan
  referral_code?: string
  can_create_workspaces?: boolean
  errors?: {
    [key: string]: string[]
  }
}

function slugify(value: string, separator = '-') {
  return value.replace(/[^a-z0-9\-_]+/gi, separator).toLowerCase()
}

export function CreateProject(props: Props) {
  const hasProjects = props.authorized_projects?.length || props.existing_projects?.length
  const user = useCurrentUser()
  const [creating, setCreating] = React.useState(!!props.errors?.length || !hasProjects)
  const [submitting, setSubmitting] = React.useState(false)
  const [name, setName] = React.useState(props.project?.name ?? '')
  const [slug, setSlug] = React.useState(props.project?.slug ?? '')
  const [domain, setDomain] = React.useState(props.project?.domain ?? '')
  const [debouncedName] = useDebounce(name, 250)
  const touchedNameRef = React.useRef(false)
  const [hasReferral, setHasReferral] = React.useState(!!props.referral_code)

  const onRequestCreate = React.useCallback(() => {
    setCreating(true)
  }, [])

  const onRequestJoin = React.useCallback(() => {
    setCreating(false)
  }, [])

  React.useEffect(() => {
    if (touchedNameRef.current && debouncedName) {
      setSlug(slugify(debouncedName))
    }
  }, [debouncedName])

  const nameError = name === (props.project?.name || '') ? props.errors?.name?.join(', ') : ''
  const slugError = slug === (props.project?.slug || '') ? props.errors?.slug?.join(', ') : ''
  const domainError = domain === (props.project?.domain || '') ? props.errors?.domain?.join(', ') : ''

  const emailDomain = props.suggested_authorized_domain

  return (
    <>
      {user && (
        <HStack
          flex="0 0 auto"
          mx="auto"
          spacing={3}
          fontSize="xs"
          rounded="full"
          // bg="white"
          paddingX={4}
          paddingY={1.5}
        >
          <Text color="gray.800">
            Logged in as{' '}
            <Text as="span" fontWeight="medium">
              {user.email}
            </Text>
          </Text>
          <Button
            as={Link}
            variant="link"
            size="xs"
            fontWeight="normal"
            href="/logout"
            onClick={() => {
              window.ko?.reset()
            }}
          >
            Change
          </Button>
        </HStack>
      )}
      {creating ? (
        <Flex alignSelf="center" direction="column" gap={10} maxW="600px" mx="auto" as={Center}>
          <Step cardProps={{ maxW: '600', p: '0' }}>
            <Image src={workspaceImg} />
            <form action="/projects" method="POST" onSubmit={() => setSubmitting(true)}>
              <AuthenticityToken />
              <Flex direction="column" alignItems="center" gap={8} width="100%" marginX="auto" p="8" pt="0" pb="12">
                <Stack alignItems="center" spacing={3}>
                  <Heading size="lg" textAlign="center" fontWeight="medium">
                    Create a new workspace
                  </Heading>
                  <Text fontSize="sm" color="gray.500" textAlign="center" maxW="80%">
                    A workspace is the place for you and your teammates to collaborate in Koala. Generally, we recommend
                    companies work within a single workspace.
                  </Text>
                </Stack>
                <Box width="100%" marginX="auto">
                  <Flex direction="column" gap={6}>
                    <FormControl id="project[name]" isInvalid={!!nameError}>
                      <FormLabel>Workspace name</FormLabel>
                      <Input
                        type="text"
                        name="project[name]"
                        isRequired
                        value={name}
                        onChange={(e) => {
                          touchedNameRef.current = true
                          setName(e.target.value)
                        }}
                      />
                      <FormErrorMessage>{nameError}</FormErrorMessage>
                    </FormControl>

                    <FormControl id="project[slug]" isInvalid={!!slugError}>
                      <FormLabel>Workspace slug</FormLabel>
                      <InputGroup>
                        {/* eslint-disable-next-line react/no-children-prop */}
                        <InputLeftAddon color="gray.500" background="gray.50" children="getkoala.com/" />
                        <Input
                          type="text"
                          name="project[slug]"
                          placeholder="yourcompany"
                          isRequired
                          value={slug}
                          onChange={(e) => {
                            setSlug(e.target.value)
                          }}
                        />
                      </InputGroup>
                      <FormErrorMessage>{slugError}</FormErrorMessage>
                    </FormControl>

                    <FormControl id="project[domain]" isInvalid={!!domainError}>
                      <FormLabel>Company website</FormLabel>
                      <Input
                        type="text"
                        name="project[domain]"
                        placeholder="e.g. example.com"
                        value={domain}
                        onChange={(e) => {
                          setDomain(e.target.value)
                        }}
                        autoComplete="off"
                        pattern="[\w.-]+"
                        isRequired
                      />
                      <FormErrorMessage>{domainError}</FormErrorMessage>
                      <FormHelperText>The address for the website you'll be tracking.</FormHelperText>
                    </FormControl>

                    <Stack>
                      {emailDomain && (
                        <FormControl>
                          <Checkbox size="lg" name="authorized_domains[domain]" value={emailDomain} defaultChecked>
                            <Text fontSize={'sm'}>
                              Allow anyone with a confirmed <b>@{emailDomain}</b> email to join this workspace
                            </Text>
                          </Checkbox>
                        </FormControl>
                      )}

                      <Box>
                        <FormControl>
                          <Checkbox
                            size="lg"
                            defaultChecked={hasReferral}
                            onChange={(e) => {
                              setHasReferral(e.target.checked)
                            }}
                          >
                            <Text fontSize={'sm'}>I have a referral code</Text>
                          </Checkbox>
                        </FormControl>

                        <Collapse in={hasReferral}>
                          <Box paddingTop={2}>
                            <GrayCard borderWidth={'1px'} p="4">
                              <FormControl id="referral[code]" isInvalid={!!domainError}>
                                <FormLabel>Referral Code</FormLabel>
                                <Input
                                  type="text"
                                  name="referral[code]"
                                  bg="white"
                                  autoComplete="off"
                                  defaultValue={props.referral_code}
                                />
                                <FormHelperText>Add your referral code here if you have one</FormHelperText>
                              </FormControl>
                            </GrayCard>
                          </Box>
                        </Collapse>
                      </Box>
                    </Stack>
                  </Flex>
                </Box>
                <VStack width="100%" maxWidth="440px" marginX="auto" spacing={3}>
                  <Button
                    type="submit"
                    size="lg"
                    width="full"
                    colorScheme="purple"
                    isLoading={submitting}
                    isDisabled={props.can_create_workspaces === false}
                  >
                    Create workspace
                  </Button>
                  {hasProjects && (
                    <Button size="lg" width="full" variant="ghost" onClick={onRequestJoin}>
                      or join an existing workspace
                    </Button>
                  )}
                </VStack>
              </Flex>
            </form>
          </Step>

          {!hasProjects && (
            <Stack
              alignItems="center"
              width="100%"
              rounded="lg"
              border="1px solid"
              borderColor="gray.200"
              bg="white"
              px={6}
              py={10}
              spacing={4}
            >
              <Stack color="gray.600" alignItems="center" textAlign="center" spacing={2} maxW="320px" mx="auto">
                <Heading size="sm" fontWeight="semibold">
                  Is your team already on Koala?
                </Heading>
                <Text fontSize="sm">We couldn't find any existing workspaces for the email address {user.email}</Text>
              </Stack>
              <Button
                as={Link}
                size="sm"
                variant="outline"
                bg="gray.50"
                href="/logout"
                onClick={() => {
                  window.ko?.reset()
                }}
              >
                Try a different email
              </Button>
            </Stack>
          )}
        </Flex>
      ) : (
        <AvailableWorkspaces
          newProjects={props.authorized_projects ?? []}
          existingProjects={props.existing_projects ?? []}
          requestCreate={onRequestCreate}
          showLogo={props.showLogo}
          canCreateWorkspaces={props.can_create_workspaces ?? true}
        />
      )}
    </>
  )
}

interface AvailableWorkspacesProps {
  showLogo?: boolean
  newProjects: NonNullable<Props['authorized_projects']>
  existingProjects: NonNullable<Props['existing_projects']>
  requestCreate: () => void
  canCreateWorkspaces: boolean
}

function AvailableWorkspaces(props: AvailableWorkspacesProps) {
  const noWorkspaces = !props.newProjects.length && !props.existingProjects.length

  return (
    <Flex
      alignSelf="center"
      direction="column"
      alignItems="center"
      gap={12}
      width="100%"
      maxWidth="440px"
      paddingY="50px"
      marginX="auto"
    >
      {props.showLogo && <KoalaLogo kind="mark" size="64px" />}

      {props.existingProjects.length > 0 && (
        <Stack width="100%" alignItems="center" spacing={3}>
          <Heading size="sm" textAlign="center" fontWeight="semibold">
            Your workspaces
          </Heading>
          <VStack alignItems="stretch" spacing={3} width="100%" marginX="auto">
            {props.existingProjects.map((project) => (
              <AvailableWorkspace key={project.slug} {...project} isMember />
            ))}
          </VStack>
        </Stack>
      )}

      {props.newProjects.length > 0 && (
        <Stack width="100%" alignItems="center" spacing={3}>
          <Stack width="100%" alignItems="center" spacing={1}>
            <Heading size="sm" textAlign="center" fontWeight="semibold">
              Accept an invitation
            </Heading>
          </Stack>
          <VStack alignItems="stretch" spacing={3} width="100%" marginX="auto">
            {props.newProjects.map((project) => (
              <AvailableWorkspace key={project.slug} {...project} />
            ))}
          </VStack>
        </Stack>
      )}

      {noWorkspaces && (
        <Stack width="100%" alignItems="center" spacing={5}>
          <Heading size="sm" textAlign="center" fontWeight="semibold">
            You don't have any workspaces yet.
          </Heading>
        </Stack>
      )}

      <Stack width="100%" alignItems="center" spacing={4}>
        <NeedHelp />

        {/* no workspaces to join or available, must create or login with another email */}
        {props.canCreateWorkspaces && (
          <Box as="form" method="get" action="/" width="100%" marginX="auto">
            <Button
              variant={noWorkspaces ? 'solid' : 'link'}
              size={noWorkspaces ? 'lg' : 'md'}
              width="full"
              colorScheme="purple"
              onClick={props.requestCreate}
              leftIcon={<Icon as={IconPlus} />}
            >
              {noWorkspaces ? 'Create a new workspace' : 'or create a new workspace'}
            </Button>
          </Box>
        )}
      </Stack>
    </Flex>
  )
}

export interface Team {
  id: string
  name: string
}

interface JoinResponse {
  success: boolean
  available_teams: Team[]
  spaces: Space[]
}

interface AvailableWorkspaceProps extends Proj {
  isMember?: boolean
}

function AvailableWorkspace({ name, slug, domain, potential_domain, isMember, member_count }: AvailableWorkspaceProps) {
  const [isJoining, setIsJoining] = React.useState(false)
  const [joined, setJoined] = React.useState(isMember ?? false)
  const [teams, setTeams] = React.useState<Team[]>([])
  const [spaces, setSpaces] = React.useState<Space[]>([])
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

  const handleJoin = React.useCallback(async () => {
    setIsJoining(true)
    try {
      const res = await post<JoinResponse>(`join?slug=${slug}`)
      setJoined(true)

      if (res.spaces.length > 0) {
        setSpaces(res.spaces)
      }
      if (res.available_teams.length > 0) {
        setTeams(res.available_teams)
      }
    } finally {
      setIsJoining(false)
    }
  }, [slug])

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      paddingY={3}
      paddingX={4}
      rounded="md"
      border="1px solid"
      borderColor="gray.200"
      shadow="sm"
      transition="all 100ms ease-in-out"
      as={joined ? 'a' : undefined}
      href={joined ? `/projects/${slug}` : undefined}
      role="group"
      bg="white"
      _hover={
        joined
          ? {
              background: 'gray.50'
            }
          : undefined
      }
    >
      <HStack spacing={3}>
        <CompanyAvatar size="sm" name={name} domain={domain || potential_domain} />
        <Box>
          <Text fontSize="sm" fontWeight="medium">
            {name}
          </Text>
          <Text fontSize="xs" color="gray.600">
            {slug} &middot; {pluralize('member', member_count, true)}
          </Text>
        </Box>
      </HStack>
      {joined ? (
        <HStack
          color="gray.600"
          spacing={1}
          transition="all 150ms ease-in-out"
          _groupHover={{ color: 'purple.500', transform: 'translateX(2px)' }}
        >
          <Text
            fontSize="sm"
            fontWeight="medium"
            color="purple.500"
            visibility="hidden"
            _groupHover={{ visibility: 'visible' }}
          >
            Open
          </Text>
          <Icon as={IconArrowRight} boxSize={5} />
        </HStack>
      ) : (
        <Button size="sm" variant="outline" onClick={handleJoin} isLoading={isJoining}>
          Join
        </Button>
      )}
      {joined && (
        <>
          {spaces.length >= 1 ? (
            <SpacesModal projectSlug={slug} isOpen={isOpen} onClose={onClose} spaces={spaces} />
          ) : teams.length >= 1 ? (
            <TeamsModal projectSlug={slug} isOpen={isOpen} onClose={onClose} teams={teams} />
          ) : null}
        </>
      )}
    </Flex>
  )
}

function NeedHelp() {
  return (
    <Flex
      width="100%"
      maxWidth={440}
      alignSelf="center"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      gap={1.5}
      mx="auto"
      marginTop={10}
      paddingY={4}
      paddingX={4}
      bg="white"
      rounded="md"
    >
      <HStack color="gray.700">
        <Icon as={IconZoomQuestion} boxSize={5} />
        <Text fontSize="sm">Not seeing your workspace?</Text>
      </HStack>
      <Button
        as={Link}
        size="sm"
        variant="outline"
        bg="gray.50"
        href="/logout"
        onClick={() => {
          window.ko?.reset()
        }}
      >
        Try a different email
      </Button>
    </Flex>
  )
}
