import { FormControl, FormHelperText, FormLabel, Select, Stack, Switch } from '@chakra-ui/react'
import React from 'react'
import { useAppDep } from '../../../../data/use-app-dep'
import type { ApolloUser } from '../../../profiles/components/apollo-actions'
import { ApolloActionProps } from './apollo-setup'

export function CreateTask(props: ApolloActionProps) {
  const [enabled, setEnabled] = React.useState(Boolean(props.delivery_rules?.apollo?.create_task?.enabled))
  const { data: userData } = useAppDep<'users', ApolloUser[]>('Apollo', 'users')

  return (
    <Stack w="100%">
      <FormControl>
        <Switch
          isChecked={enabled}
          onChange={(e) => {
            setEnabled(e.target.checked)
            props.setDeliveryRules({
              ...props.delivery_rules,
              apollo: {
                ...props.delivery_rules?.apollo,
                create_task: {
                  ...props.delivery_rules?.apollo?.create_task,
                  enabled: e.target.checked
                }
              }
            })
          }}
          size="sm"
          fontSize={'sm'}
          fontWeight="semibold"
          colorScheme={'purple'}
          value={enabled ? 'true' : 'false'}
          name="follow_rule[delivery_rules][apollo][create_task][enabled]"
        >
          Enable
        </Switch>
        <FormHelperText>Creates a new Task based on the current Visitor.</FormHelperText>
      </FormControl>

      {enabled && userData?.data?.users?.length && (
        <Stack w="100%" spacing="4">
          <FormControl>
            <FormLabel>Task Type</FormLabel>
            <Select
              name="follow_rule[delivery_rules][apollo][create_task][type]"
              size="sm"
              rounded="md"
              fontWeight="normal"
              defaultValue={props.delivery_rules?.apollo?.create_task?.type}
            >
              <option value="outreach_manual_email">Email</option>
              <option value="action_item">Action Item</option>
              <option value="linkedin_step_connect">Linked In: Send Connection Request</option>
              <option value="linkedin_step_message">Linked In: Send Message</option>
              <option value="call">Call</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Priority</FormLabel>
            <Select
              size="sm"
              rounded="md"
              name="follow_rule[delivery_rules][apollo][create_task][priority]"
              defaultValue={props.delivery_rules?.apollo?.create_task?.priority}
            >
              <option value="high">High</option>
              <option value="normal">Normal</option>
              <option value="low">Low</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Due Date</FormLabel>
            <Select
              size="sm"
              rounded="md"
              name="follow_rule[delivery_rules][apollo][create_task][due_date]"
              defaultValue={props.delivery_rules?.apollo?.create_task?.due_date}
            >
              <option value="unset">Not set</option>
              <option value="day">1 day</option>
              <option value="week">1 week</option>
              <option value="two_weeks">2 weeks</option>
              <option value="month">1 month</option>
            </Select>
            <FormHelperText>How long since Task creation to set the due date for.</FormHelperText>
          </FormControl>

          <FormControl>
            <FormLabel>Assign To</FormLabel>
            <Select
              size="sm"
              rounded="md"
              fontWeight={'normal'}
              defaultValue={props.delivery_rules?.apollo?.create_task?.user_id}
              name="follow_rule[delivery_rules][apollo][create_task][user_id]"
            >
              <option>Unassigned</option>
              {userData?.data?.users?.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.first_name} {user.last_name}
                </option>
              ))}
            </Select>
          </FormControl>
        </Stack>
      )}
    </Stack>
  )
}
