import castArray from 'lodash/castArray'
import flatten from 'lodash/flatten'
import keyBy from 'lodash/keyBy'
import omit from 'lodash/omit'
import uniq from 'lodash/uniq'
import isUndefined from 'lodash/isUndefined'
import omitBy from 'lodash/omitBy'
import ms from 'ms'
import { useCallback, useEffect, useRef, useState } from 'react'
import { concurrentCachedGET } from '../../lib/api'
import { Facet, FacetFilters, FacetMappings, FacetValue, NotFacet, NumericFilter } from '../pages/accounts'
import { getItemDisplay } from '../pages/accounts/facets/categories'
import { getRangeValues, periodShorthands } from '../pages/accounts/facets/filter-cloud'
import { projectPath } from '../ui/ProjectsContext'
import useLatestRef from '../ui/useLatestRef'
import useUpdateEffect from '../ui/useUpdateEffect'
import { FacetCloudResponse } from './use-url-filters'

interface Props {
  facet_filters?: FacetFilters
  range?: 'day' | 'week' | 'month' | 'all' | 'any' | null
  focus_time?: NumericFilter
  onFilterChange?: (appliedFilters: FacetFilters, page: number) => void
  onClearFilters?: () => void
  query?: string
  sortBy?: string
  page?: number | string | null
  perPage?: number
  facetCloudPath?: string
}

export function getFacetValues(facet: Facet): FacetValue[] {
  if (typeof facet === 'string' || typeof facet === 'number' || typeof facet === 'boolean') {
    return [facet]
  }

  if (facet && 'not' in facet) return facet.not
  if (facet && 'all' in facet) return facet.all
  if (facet && 'gte' in facet) return castArray(facet.gte)
  if (facet && 'lte' in facet) return castArray(facet.lte)
  if (facet && 'exists' in facet) return castArray(facet.exists)
  if (facet && 'prefix' in facet) return castArray(facet.prefix)
  if (facet && 'contains' in facet) return castArray(facet.contains)
  if (typeof facet === 'object' && Object.keys(facet).length === 0) return []

  return facet as FacetValue[]
}

export type FacetOperator =
  | 'all'
  | 'not'
  | 'gte'
  | 'lte'
  | 'exists'
  | 'not_exists'
  | 'between'
  | 'prefix'
  | 'contains'
  | null

export function getFacetOperator(facet: Facet | undefined): FacetOperator {
  if (typeof facet === 'string') {
    if (/\d+\.\.\d+/.test(facet)) {
      return 'between'
    } else {
      return null
    }
  }

  if (typeof facet === 'number' || typeof facet === 'boolean') {
    return null
  }

  if (!facet) {
    return null
  }

  if ('not' in facet) return 'not'
  if ('all' in facet) return 'all'
  if ('gte' in facet) return 'gte'
  if ('lte' in facet) return 'lte'
  if ('not_exists' in facet) return 'not_exists'
  if ('exists' in facet && (!facet.exists || facet.exists === 'false')) return 'not_exists'
  if ('exists' in facet) return 'exists'
  if ('prefix' in facet) return 'prefix'
  if ('contains' in facet) return 'contains'

  return null
}

export function getFacetOperatorLabel(facet: Facet | undefined, dataType?: string) {
  const operator = getFacetOperator(facet)

  let label = 'is'
  switch (operator) {
    case 'all':
      label = 'contains all'
      break
    case 'not':
      label = 'is not'
      break
    case 'not_exists':
      // "is empty"
      label = 'is'
      break
    case 'exists': {
      // "is anytime" vs "is not empty"
      label = dataType === 'date' ? 'is' : 'is not'
      break
    }
    case 'between':
      label = 'between'
      break
    case 'gte':
      label = dataType === 'date' ? 'is after' : '>'
      break
    case 'lte':
      label = dataType === 'date' ? 'is before' : '<'
      break
    case 'prefix':
      label = 'starts with'
      break
    case 'contains':
      label = 'contains'
      break
  }

  if (!operator?.includes('exists') && typeof facet === 'object' && facet['exists']) {
    return `${facet['exists'] === 'true' ? 'anytime' : 'not set'} or ${label}`
  }

  return label
}

// For backwards compat with old `focus_time=1000` which meant `focus_time[gte]=1000`
export function convertEqToGte(value: any) {
  if ((typeof value === 'string' && !value.includes('..') && value) || typeof value === 'number') {
    try {
      return { gte: Number(value) }
    } catch (_e) {
      return null
    }
  }

  return value
}

export function filtersAsText(filters: any) {
  const parts: string[] = []

  if (filters?.focus_time) {
    const { eq, gte, lte } = getRangeValues(filters.focus_time)
    let operator: string
    let friendly: string

    if (gte && lte) {
      operator = 'between'
      friendly = `${ms(gte)}-${ms(lte, { long: true })}`
    } else if (gte) {
      operator = '>'
      friendly = ms(gte, { long: true })
    } else if (lte) {
      operator = '<'
      friendly = ms(lte, { long: true })
    } else {
      operator = 'is'
      friendly = ms(eq || 0, { long: true })
    }

    parts.push(`Active session time ${operator} ${friendly}`)
  }

  for (const key of Object.keys(filters?.facets || {})) {
    const display = getItemDisplay(key, [])
    const operator = getFacetOperatorLabel(filters.facets[key])

    let value: FacetValue[] | FacetValue = getFacetValues(filters.facets[key]).map((v) => {
      if (['day', 'week', 'month'].includes(v as string) && operator) {
        return periodShorthands[v] || v
      }

      return v
    })

    if (value.length === 1) {
      value = value[0]
    }

    if (Array.isArray(value)) {
      parts.push(`${display.label}: ${operator && operator !== 'is' ? operator + ' ' : ''}${value.join(' or ')}`)
    } else {
      parts.push(`${display.label}: ${operator && operator !== 'is' ? operator + ' ' : ''}${value}`)
    }
  }

  return parts.join(', ')
}

export function facetQueryString(facetFilters: FacetFilters, prefix = 'facets') {
  const facets = Object.entries(facetFilters).flatMap(([key, value]: [string, any]) => {
    const operator = getFacetOperator(value)

    const values = uniq(flatten(castArray(getFacetValues(value))))
    const [first, ...rest] = [prefix, key, operator].filter(Boolean)
    let query = first as string

    for (const part of rest) {
      if (part === 'between') {
        continue
      }

      query += `[${part}]`
    }

    // allows for overriding query params if there are merged defaults server-side, e.g. `facets[foo]=`
    if (values.length === 0) {
      return query + '='
    }

    // add `[]` the array bracket syntax to indicate this is a list of items
    // we'll loop over the values to include them
    // without this Rails will not properly parse the query as an array of values
    if (values.length > 1 || operator === 'all') {
      query += '[]'
    }

    return values.map((val) => {
      return `${query}=${encodeURIComponent(val as string)}`
    })
  })

  return uniq(facets)
}

export function toQueryString(params: Record<string, any>) {
  const { page, perPage, range, query, sortBy, focusTime, facetFilters } = params
  const parts: string[] = []

  if (page) {
    parts.push(`page=${page}`)
  }

  if (perPage) {
    parts.push(`page_size=${perPage}`)
  }

  if (range) {
    parts.push(`range=${range}`)
  }

  if (query) {
    parts.push(`query=${query}`)
  }

  if (sortBy) {
    parts.push(`sort_by=${sortBy}`)
  }

  if (focusTime) {
    const parts = facetQueryString({ focus_time: focusTime }, '')
    parts.push(...parts)
  }

  const facets = facetQueryString(facetFilters, 'facets')
  if (facets.length > 0) {
    parts.push(...facets)
  }

  return parts.length > 0 ? `?${parts.join('&')}` : ''
}

export type FacetParams = ReturnType<typeof useFacets>

export function useFacets(props: Props) {
  const [page, setPage] = useState(props.page)
  const [range, setRange] = useState(props.range)
  const [focusTime, setFocusTime] = useState(convertEqToGte(props.focus_time) || null)
  const [query, setQuery] = useState(props.query)
  const [sortBy, setSortBy] = useState<string | undefined>(props.sortBy)
  const latestOnFilterChange = useLatestRef(props.onFilterChange)

  const [facetFilters, setFacetFilters] = useState(props.facet_filters ?? {})
  const [topFilters, setTopFilters] = useState<string[]>([])
  const [facetMappings, setFacetMappings] = useState<FacetMappings>({})
  const [facetCloudLoading, setFacetCloudLoading] = useState(true)
  const facetCloudPath = props.facetCloudPath ?? '/accounts/facet-cloud'

  const resetPage = useCallback(() => {
    setPage((prev) => (typeof prev === 'undefined' ? undefined : 1))
  }, [])

  const toggleFilterOperator = useCallback((filter: string, value: Facet, inputOperator: string) => {
    // TODO accomodate more operators as our ES backend allows
    if (inputOperator === 'not') {
      if (!(value as NotFacet).not) {
        value = {
          not: value
        } as Facet
      }
    } else {
      if ((value as NotFacet).not) {
        value = (value as NotFacet).not
      }
    }

    setFacetFilters((prev) => ({
      ...prev,
      [filter]: value
    }))
  }, [])

  const onFilterChange = useCallback(
    (filter: string, value: FacetValue, action: 'add' | 'remove' | 'set') => {
      setFacetFilters((prev) => {
        let facet: Facet = Array.from(getFacetValues(prev[filter] || []))

        if (action === 'remove') {
          facet = facet.filter((v) => v !== value)
        }

        if (action === 'add' && !Array.isArray(value)) {
          facet = uniq(facet.concat(value))
        }

        if (action === 'add' && Array.isArray(value)) {
          facet.push(value)
        }

        if (action === 'set') {
          facet = [].concat(value as any)
        }

        if (facet.length > 0) {
          const operator = getFacetOperator(prev[filter])

          if (operator) {
            facet = { [operator]: facet } as Facet
          }

          return { ...prev, [filter]: facet }
        } else {
          return omit(prev, filter)
        }
      })
      resetPage()
    },
    [resetPage]
  )

  const applyFilters = useCallback(
    (appliedFilters: FacetFilters) => {
      setFacetFilters((existing) => {
        const merged = {
          ...existing,
          ...appliedFilters
        }

        return omitBy(merged, isUndefined) as FacetFilters
      })
      resetPage()
    },
    [resetPage]
  )

  const isFiltering = Object.keys(facetFilters).length > 0 || !!range
  const canClearFilters = Object.keys(facetFilters).length > 0
  const fetched = useRef(false)

  const queryString = toQueryString({
    page,
    range,
    query,
    focusTime,
    facetFilters,
    sortBy,
    perPage: props.perPage
  })

  useUpdateEffect(() => {
    latestOnFilterChange.current?.(facetFilters, Number(page) || 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facetFilters, page])

  useEffect(() => {
    if (fetched.current) {
      return
    }

    let canceled = false

    const path = projectPath(facetCloudPath)
    setFacetCloudLoading(true)
    concurrentCachedGET<FacetCloudResponse>(path)
      .then((res) => {
        // Dont update the state if this effect has been unloaded
        if (canceled) {
          return
        }

        fetched.current = true
        setFacetMappings(keyBy(res.mappings || [], 'facet'))
        setTopFilters(res.top_filters || [])
        setFacetCloudLoading(false)
      })
      .catch((_error) => {
        setFacetCloudLoading(false)
      })

    return () => {
      canceled = true
    }
  }, [facetCloudPath])

  const clearFilters = useCallback(() => {
    setFacetFilters({})
    setQuery(undefined)
    setRange(props.range !== null ? props.range || 'week' : null)
    setFocusTime(null)
    resetPage()
    props.onClearFilters?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.range, resetPage, props.onClearFilters])

  return {
    clearFilters,
    queryString,
    isFiltering,
    canClearFilters,
    toggleFilterOperator,
    setRange,
    setFocusTime,
    setQuery,
    range,
    focusTime,
    query,
    applyFilters,
    onFilterChange,
    facetFilters,
    setFacetFilters,
    page,
    setPage,
    sortBy,
    setSortBy,
    facetMappings,
    topFilters,
    facetCloudLoading
  }
}

export type Facets = ReturnType<typeof useFacets>
