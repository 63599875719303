import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { IconCopy, IconCopyPlus, IconDotsVertical, IconEdit, IconPlus, IconTrash } from '@tabler/icons-react'
import partition from 'lodash/partition'
import pluralize from 'pluralize'
import React, { useMemo } from 'react'
import { Space } from '../../../types/Space'
import { Template } from '../../../types/Template'
import Avatar from '../../ui/Avatar'
import { DeleteConfirmation } from '../../ui/DeleteConfirmation'
import EmptyState from '../../ui/EmptyState'
import { SlackIcon } from '../../ui/icons'
import { MiddotDivider } from '../../ui/Middot'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { EditSpaceModal } from './components/EditSpaceModal'
import { SpaceMembers } from './components/Members'
import { NewSpaceModal } from './components/NewSpaceModal'

interface IndexProps {
  spaces: Space[]
  templates: Template[]
}

export default function SpacesIndex(props: IndexProps) {
  const newSpace = useDisclosure()

  const [shared, _personal] = useMemo(() => {
    return partition(props.spaces, (s) => !s.owner_id)
  }, [props.spaces])

  return (
    <PageLayout size="sm" gap={0}>
      <SettingsBreadCrumb
        paths={[
          {
            path: projectPath('/spaces'),
            title: 'Spaces'
          }
        ]}
        offscreen
      />
      <SettingsHeader>
        <Flex alignItems="center" justifyContent="space-between">
          <PageTitle>Spaces</PageTitle>
          <Button
            size="sm"
            colorScheme="purple"
            leftIcon={<Icon as={IconPlus} boxSize={4} />}
            iconSpacing={1.5}
            onClick={newSpace.onOpen}
          >
            New space
          </Button>
        </Flex>
        <PageDescription>
          Spaces are collections of lists that you and your teammates can share. Multiple people can join a space. You
          can use these lists to organize your accounts and contacts.
        </PageDescription>
      </SettingsHeader>

      <Stack spacing={4}>
        {shared.map((space) => (
          <SpaceRow key={space.id} space={space} />
        ))}
        {shared.length === 0 && (
          <Box maxW="75%" mx="auto">
            <EmptyState
              size="sm"
              icon={IconCopyPlus}
              heading="Looks like there aren't any shared spaces yet"
              description="Create a space to organize types of accounts or contacts for you and your teammates, or to split out different workflows"
              ctaText="Create a space"
              onClick={newSpace.onOpen}
            />
          </Box>
        )}
      </Stack>

      <NewSpaceModal {...newSpace} />
    </PageLayout>
  )
}

function SpaceRow({ space }: { space: Space }) {
  const deletion = useDisclosure()
  const editSpaceModal = useDisclosure()

  return (
    <Flex
      key={space.id}
      alignItems="center"
      justifyContent="space-between"
      gap={3}
      rounded="md"
      border="1px solid"
      borderColor="gray.200"
      paddingY={2}
      paddingX={3}
    >
      <Flex alignItems="center" gap={2.5}>
        {space.owner ? (
          <Avatar
            key={space.owner.email}
            src={space.owner.image}
            name={space.owner.name || space.owner.email}
            size="sm"
          />
        ) : (
          <Avatar size="36px" fontSize="13px" fontWeight="semibold" rounded="lg" name={space.name.split(' ')[0]} />
        )}
        <Box>
          <Link href={projectPath(`/spaces/${space.id}`)} fontSize="sm">
            {space.name}
          </Link>
          <HStack color="gray.500" fontSize="xs" spacing={1.5} divider={<MiddotDivider />}>
            {space.created_by_user && (
              <Text isTruncated maxW="200px">
                Created by {space.created_by_user.name || space.created_by_user.email}
              </Text>
            )}
            <Text>{pluralize('list', space.account_views?.length || 0, true)}</Text>

            {space.slack_channel && (
              <Flex gap={0.5} alignItems="center">
                <SlackIcon boxSize={4} />
                <Text isTruncated maxW="100px">
                  {space.slack_channel.name || space.slack_channel.id}
                </Text>
              </Flex>
            )}
          </HStack>
        </Box>
      </Flex>
      <Flex alignItems="center" gap={2}>
        <SpaceMembers members={space.members} />

        <Button size="sm" variant="outline" as="a" href={projectPath(`/spaces/${space.id}`)}>
          View
        </Button>
        <Menu>
          <MenuButton
            size="xs"
            as={IconButton}
            icon={<IconDotsVertical size={15} />}
            variant="ghost"
            borderColor="gray.200"
          />
          <MenuList fontSize="sm" zIndex="popover">
            <MenuItem icon={<IconEdit size={16} />} onClick={editSpaceModal.onOpen}>
              Edit name
            </MenuItem>
            <MenuItem icon={<IconCopy size={16} />} as="a" href={projectPath(`/spaces/${space.id}/clone`)}>
              Clone
            </MenuItem>
            <MenuItem icon={<IconTrash size={16} />} color="red.500" onClick={deletion.onOpen}>
              Delete…
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      <DeleteConfirmation
        isOpen={deletion.isOpen}
        onClose={deletion.onClose}
        title={`Are you sure you want to delete this space?`}
        confirmLabel="Delete space"
        deletePath={projectPath(`/spaces/${space.id}`)}
      >
        <Text fontSize="sm" color="gray.600">
          Your teammates will no longer be use the lists in this space.
        </Text>
      </DeleteConfirmation>

      <EditSpaceModal space={space} {...editSpaceModal} />
    </Flex>
  )
}
