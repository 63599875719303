import { Box, Button, Flex, Heading, Link, Text } from '@chakra-ui/react'
import React from 'react'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import KoalaLogo from '../../../ui/KoalaLogo'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'

interface Props {
  email: string
}

export default function Pending(props: Props) {
  return (
    <PageLayout size="semi-full">
      <PageTitle skipRendering>Almost there</PageTitle>
      <Box>
        <Link flex="none" color="gray.500" fontSize="sm" href="/login">
          Back to login
        </Link>
        <Flex
          direction="column"
          alignItems="center"
          gap={10}
          width="100%"
          maxWidth={520}
          paddingY="120px"
          paddingX={4}
          marginX="auto"
        >
          <KoalaLogo kind="mark" size="64px" />
          <Heading size="lg" fontWeight="medium">
            Confirm your email address
          </Heading>
          <Text fontSize="sm" color="gray.600" textAlign="center">
            We sent a confirmation link to <b>{props.email}</b>. Click the link in the email or copy and paste it into
            your browser url bar to confirm your email.
          </Text>
          <form action="/users/confirmation" method="POST">
            <AuthenticityToken />
            <input type="hidden" name="email" id="email" value={props.email} />
            <Flex gap={1}>
              <Text fontSize="sm" color="gray.600" textAlign="center">
                Need a new link?
              </Text>
              <Button variant="link" type="submit" colorScheme="purple" size="sm">
                Send a new confirmation link
              </Button>
            </Flex>
          </form>
        </Flex>
      </Box>
    </PageLayout>
  )
}
