import { Circle, SquareProps } from '@chakra-ui/react'
import React from 'react'

interface Props extends SquareProps {
  step: number
  current?: boolean
  size?: number | 'sm' | 'md'
}

const sizes = {
  sm: {
    size: 4,
    fontSize: 'xx-small'
  },
  md: {
    size: 5,
    fontSize: 'xs'
  }
}

export function StepIcon({ step, current, size = 'md', ...props }: Props) {
  return (
    <Circle
      flex="none"
      bg={current ? 'purple.600' : 'gray.100'}
      size={sizes[size]?.size || size || 5}
      fontSize={sizes[size]?.fontSize || 'xs'}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      userSelect="none"
      fontWeight="semibold"
      color={current ? 'white' : 'gray.700'}
      css={{ fontVariantNumeric: 'tabular-nums' }}
      {...props}
    >
      {step}
    </Circle>
  )
}
