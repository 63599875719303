import React from 'react'

import {
  VStack,
  Button,
  Input,
  FormControl,
  FormLabel,
  Flex,
  Box,
  Textarea,
} from '@chakra-ui/react'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'

interface Props {
  id?: string
  slug?: string
  name?: string
  description?: string
  prompt?: string
}

export default function FormContent({id, slug, name, description, prompt}: Props) {
  return (
    <>
      <AuthenticityToken />
      { id && <input type="hidden" name="_method" value="patch" /> }
      <input type="hidden" name="id" value={id} /> 

      <VStack spacing="20px">
        <FormControl id="name">
          <FormLabel>Name</FormLabel>
          <Input name="prompts_db[name]" autoComplete="off" defaultValue={name}/>
        </FormControl>

        <FormControl id="slug">
          <FormLabel>Slug</FormLabel>
          <Input name="prompts_db[slug]" autoComplete="off" defaultValue={slug}/>
        </FormControl>

        <FormControl id="description">
          <FormLabel>Description</FormLabel>
          <Input name="prompts_db[description]" autoComplete="off" defaultValue={description} />
        </FormControl>

        <FormControl id="prompt">
          <FormLabel>Prompt</FormLabel>
          <Textarea name="prompts_db[prompt]" autoComplete="off" defaultValue={prompt}/>
        </FormControl>
      </VStack>

      <Flex marginTop={5}>
        <Box flex="1"></Box>
        <Button type="submit" colorScheme="purple">
          { id ? "Update" : "Create" }
        </Button>
      </Flex>
    </>
  )
}
