import { Box, HStack, Icon, Text } from '@chakra-ui/react'
import { IconCircleDashed, IconLockFilled } from '@tabler/icons-react'
import React, { useMemo } from 'react'
import { OutreachUser } from '../../types/Outreach'
import { useAppDep } from '../data/use-app-dep'
import Avatar from './Avatar'
import { ComboboxWithSearch } from './ComboboxWithSearch'

interface OutreachUserSelectorProps {
  selectedOutreachUserId?: string | number | null
  onChange: (outreachUser: OutreachUser | null) => void
}

export function OutreachUserSelector({ selectedOutreachUserId, onChange }: OutreachUserSelectorProps) {
  const { data, isLoading } = useAppDep<'users', OutreachUser[]>('Outreach', 'users')

  const users = useMemo(() => {
    return (data?.data?.users || []).filter((u) => !u.attributes.locked)
  }, [data])

  const selected = useMemo(() => {
    return users.find((u) => u.id.toString() === selectedOutreachUserId?.toString()) || null
  }, [users, selectedOutreachUserId])

  return (
    <ComboboxWithSearch
      items={users}
      selectedItem={selected}
      onChange={onChange}
      isLoading={isLoading}
      filterItem={(item, val) => {
        const name = item.attributes?.name?.toLowerCase()
        const email = item.attributes?.email?.toLowerCase()
        return name?.includes(val) || email?.includes(val) || false
      }}
      itemToString={(item) =>
        `${item?.attributes?.name || 'Unknown User'} (${item?.attributes?.email || 'unknown email'})`
      }
      itemRenderer={ItemRenderer}
      selectButtonRenderer={ItemRenderer}
    />
  )
}

interface ItemRendererProps {
  item: OutreachUser | null
  isSelected?: boolean
  isToggleButton?: boolean
}

function ItemRenderer(props: ItemRendererProps) {
  const user = props.item

  if (!user) {
    return (
      <HStack spacing={2.5}>
        <Icon as={IconCircleDashed} color="gray.300" boxSize={6} />
        <Text fontSize="sm" fontWeight="medium" color="gray.400">
          Select an Outreach user
        </Text>
      </HStack>
    )
  }

  const name = user.attributes?.name || 'Unknown User'
  const email = user.attributes?.email || 'unknown email'
  const invalidMailbox =
    user.attributes?.locked || (!user.relationships?.mailbox?.data?.id && !user.relationships?.mailboxes?.data?.length)

  return (
    <HStack spacing={2.5}>
      <Avatar size={props.isToggleButton ? 'xs' : 'sm'} name={name || email} />
      <Box flex="1 1 auto">
        <Text fontSize="sm" fontWeight="medium">
          {name || 'Unknown user'}
        </Text>
        {!props.isToggleButton && email && (
          <Text fontSize="xs" color="gray.500">
            {email}
          </Text>
        )}
      </Box>
      {!props.isToggleButton && invalidMailbox && <Icon as={IconLockFilled} color="gray.400" boxSize={4} />}
    </HStack>
  )
}
