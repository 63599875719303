import React from 'react'

import {
  Stack,
  HStack,
} from '@chakra-ui/react'

import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { AdminBreadcrumb } from '..'
import FormContent from './formContent'
import { postForm } from '@app/lib/api'
import { toast } from 'sonner'

interface Props {
  id: string
  slug: string
  name: string
  description: string
  prompt: string
}

export default function New(props: Props) {
  const formRef = React.useRef<HTMLFormElement | null>(null)

  const updatePrompt = async () => {
    if (!formRef.current) {
      return
    }

    const formData = new FormData(formRef.current)

    try {
      await postForm(`/admin/prompts-db/${props.id}`, formData)
        .then((_) => {
          window.location.href = '/admin/prompts-db'
        })
        .catch((_) => {
          toast.error('Error creating prompt')
        })
    } catch (_err) {
      toast.error('Error creating rule')
    }
  }

  return (
    <PageLayout size="md">
      <HStack>
        <PageTitle flex="1">Edit Prompt</PageTitle>
      </HStack>

      <AdminBreadcrumb
        paths={[
          { path: '/admin/prompts-db', title: 'Prompts DB' },
          { path: `/admin/prompts-db/${props.id}/edit`, title: 'Edit' }
        ]}
      />

      <Stack>
        <form ref={formRef} 
          method="POST"
          onSubmit={(e) => {
            e.preventDefault()
            updatePrompt()
          }}
        >
          <FormContent {...props} />
        </form>
      </Stack>
    </PageLayout>
  )
}
