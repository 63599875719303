import { Slack } from '@app/components/pages/account_view_subscriptions/components/Slack'
import { ConnectOauthAppDialog } from '@app/components/pages/apps/components/ConnectOauthAppDialog'
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay
} from '@chakra-ui/react'
import React from 'react'
import Router from '../../../../lib/router'
import type { AccountView, Subscription } from '../../../../types/AccountView'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { projectPath } from '../../../ui/ProjectsContext'

interface Props {
  app: {
    app_id: string
    title: string
    logo: string
    deps: { [key: string]: any }
    valid: boolean
    connected: boolean
  }
  subscription: Subscription
  view: AccountView
}

export function SubscriptionSidesheet(props: Props) {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = React.useState(false)

  const formId = `subscription-form:${props.app.title}:${props.subscription.id}`
  const deleteId = `subscription-delete-form:${props.app.title}:${props.subscription.id}`

  const onClose = React.useCallback(() => {
    Router.visit(projectPath(`/views/${props.view.slug}/subscriptions`))
  }, [props.view])

  const shouldDisableCreateSubscriptionButton = React.useMemo(() => {
    return isCreateButtonDisabled || isSubmitting || isDeleting
  }, [isCreateButtonDisabled, isSubmitting, isDeleting])

  return (
    <Drawer isOpen placement="right" onClose={onClose} size="lg" preserveScrollBarGap>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader fontSize="lg" fontWeight="medium">
          {props.app.connected
            ? `Edit your subscription for ${props.app.title}`
            : `Set up a subscription for ${props.app.title}`}
        </DrawerHeader>

        <DrawerBody background="gray.100">
          {props.subscription.id && (
            <form method="POST" id={deleteId} onSubmit={() => setIsDeleting(true)}>
              <input type="hidden" name="_method" value="DELETE" />
              <AuthenticityToken />
            </form>
          )}

          {props.app.connected && props.app.valid ? (
            <Box as="form" method="POST" id={formId} onSubmit={() => setIsSubmitting(true)}>
              <input type="hidden" name="_method" value="PUT" />
              <AuthenticityToken />
              {/* TODO: check props.app.title to decide which integration to render, for now we just have slack */}
              <Slack
                app={props.app}
                subscription={props.subscription}
                setIsCreateButtonDisabled={setIsCreateButtonDisabled}
              />
            </Box>
          ) : (
            <ConnectOauthAppDialog
              app_id={props.app.app_id}
              title={props.app.title}
              valid={props.app.valid}
              connected={props.app.connected}
              origin={encodeURIComponent(window.location.href)}
              allowNonAdmins
            />
          )}
        </DrawerBody>

        {props.app.connected && (
          <DrawerFooter>
            {props.subscription.id && (
              <Button
                type="submit"
                variant="outline"
                colorScheme="red"
                marginRight="auto"
                form={deleteId}
                isLoading={isDeleting}
                isDisabled={isDeleting || isSubmitting}
              >
                Delete
              </Button>
            )}
            <Button
              type="submit"
              colorScheme="purple"
              form={formId}
              isLoading={isSubmitting}
              isDisabled={shouldDisableCreateSubscriptionButton}
            >
              {props.subscription?.id ? 'Save Changes' : 'Create'}
            </Button>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  )
}
