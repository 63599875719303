import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Select,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconAlertCircle, IconCheck, IconCircle, IconMinus } from '@tabler/icons-react'
import capitalize from 'lodash/capitalize'
import React from 'react'
import { concurrentGET } from '../../../../lib/api'
import { projectPath } from '../../../ui/ProjectsContext'
import { User } from '../../../ui/UserContext'

export interface HealthCheck {
  title: string
  description: string
  help_message: string
  status: 'failed' | 'pending' | 'passed'
  failure?: string
}

interface Props {
  appModule: string
  healthChecks: HealthCheck[]
  hideUsers?: boolean
  isDirty?: boolean
}

function bgForStatus(status: 'failed' | 'pending' | 'passed', results: HealthCheck[], loading: boolean) {
  if (!status || results.length === 0 || loading) {
    return 'gray'
  }

  switch (status) {
    case 'failed':
      return 'red'
    case 'pending':
      return 'orange'
    case 'passed':
      return 'green'
  }
}

function iconForStatus(status: 'failed' | 'pending' | 'passed', results: HealthCheck[], loading: boolean) {
  if (!status || results.length === 0 || loading) {
    return IconCircle
  }

  switch (status) {
    case 'failed':
      return IconAlertCircle
    case 'pending':
      return IconMinus
    case 'passed':
      return IconCheck
  }
}

export const HealthChecks = (props: Props) => {
  const [results, setResults] = React.useState<HealthCheck[]>([])
  const [loading, setLoading] = React.useState(false)
  const [users, setUsers] = React.useState<User[]>([])
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null)
  const [testMode, setTestMode] = React.useState<'default' | 'myself' | 'user'>('myself')
  const showUsers = !props.hideUsers

  React.useEffect(() => {
    if (users.length > 0) {
      return
    }

    concurrentGET<{ users: User[] }>(`${projectPath()}/settings/members.json`).then((res) => {
      setUsers(res.users)
    })
  }, [users])

  const runChecks = React.useCallback(() => {
    setLoading(true)
    setResults([])

    let basePath = `/apps/${props.appModule}/health-checks?test_mode=${testMode}`
    if (testMode === 'user' && selectedUser) {
      basePath = `${basePath}&user_id=${selectedUser.id}`
    }

    concurrentGET<{ health_checks: HealthCheck[] }>(projectPath(basePath)).then((res) => {
      setResults(res.health_checks)
      setLoading(false)
    })
  }, [selectedUser, testMode, props.appModule])

  const toRender = React.useMemo(() => {
    if (results.length > 0) {
      return results
    }

    return props.healthChecks
  }, [results, props.healthChecks])

  return (
    <Stack spacing="6" fontSize={'sm'}>
      <Stack>
        <Heading size="md" fontWeight={'semibold'}>
          🏥 Health checks
        </Heading>
        <Text>Verify if your settings are correct and up to date.</Text>
      </Stack>

      {props.isDirty && (
        <Alert variant={'left-accent'} status="warning">
          <AlertIcon />
          <AlertTitle>Unsaved Changes</AlertTitle>
          <Text fontSize={'sm'}>Please save your changes before running health checks.</Text>
        </Alert>
      )}

      {showUsers && (
        <Select
          size="sm"
          rounded="md"
          bg="white"
          onChange={(e) => {
            if (e.target.value === 'default') {
              setSelectedUser(null)
              setTestMode('default')
              return
            }

            if (e.target.value === 'myself') {
              setTestMode('myself')
              setSelectedUser(null)
              return
            }

            setTestMode('user')
            setSelectedUser(users.find((u) => u.id === e.target.value) ?? null)
          }}
        >
          <option value="myself">Connect as myself</option>
          <option value="default">Default {capitalize(props.appModule)} Connection</option>
          {users.map((user) => {
            return (
              <option key={user.id} value={user.id}>
                Connected as {user.name} ({user.email})
              </option>
            )
          })}
        </Select>
      )}

      <Stack fontSize={'sm'} spacing="4">
        {toRender.map((healthCheck) => {
          const bg = bgForStatus(healthCheck.status, results, loading)
          const Icon = iconForStatus(healthCheck.status, results, loading)

          return (
            <HStack
              key={healthCheck.title}
              spacing="4"
              p="4"
              borderWidth="1px"
              rounded="md"
              borderLeftWidth="thick"
              borderColor={`${bg}.300`}
              borderTopWidth="1px"
              bg="white"
            >
              <Box mx="2">
                {loading && <Spinner size="sm" thickness="1px" />}
                {!loading && <Icon size="24" color={bg} />}
              </Box>
              <Stack spacing="4">
                <Stack spacing="0">
                  <Text fontWeight={'semibold'}>{healthCheck.title}</Text>
                  <Text fontSize={'xs'}>{healthCheck.description}</Text>
                </Stack>

                {healthCheck.status === 'pending' && results.length > 0 && (
                  <>
                    <Divider />
                    <Text>This check was not run due to a dependency on other health checks or disabled actions.</Text>
                  </>
                )}

                {healthCheck.failure && (
                  <>
                    <Divider />
                    <Stack spacing="2">
                      <HStack spacing="0.5" alignItems={'flex-start'}>
                        <Text size="xs" fontWeight={'semibold'} color={`${bg}.800`}>
                          Failure:
                        </Text>
                        <Text size="xs" color={`${bg}.800`} px="2">
                          {healthCheck.failure}
                        </Text>
                      </HStack>

                      <Text size="xs" lineHeight={'4'}>
                        {healthCheck.help_message}
                      </Text>
                    </Stack>
                  </>
                )}
              </Stack>
            </HStack>
          )
        })}
      </Stack>

      <Button isLoading={loading} colorScheme="purple" onClick={runChecks} variant="outline">
        Verify
      </Button>
    </Stack>
  )
}
