import { Heading, Stack } from '@chakra-ui/react'
import React from 'react'
import { ErrorPage } from './components/ErrorPage'

export default function TooManyRequests() {
  return (
    <ErrorPage
      title={
        <Stack>
          <Heading size="4xl">429</Heading>
          <Heading as="h1" size="lg">
            Too Many Requests
          </Heading>
        </Stack>
      }
      description="Please slow down or try again later"
    />
  )
}
