import React from 'react'

import {
  Stack,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  StatGroup,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber
} from '@chakra-ui/react'

import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { AdminBreadcrumb } from '..'
import { get } from '@app/lib/api'
import { iconStatus } from './index'
import SyncDuration from '../components/SyncDuration'

interface LoadEntry {
  id: number
  status: 'running' | 'done'
  project_slug: string | null
  task_name: string
  metadata: Record<string, any>
}

interface GroupedLoadEntries {
  task_name: string
  count: number
  last_created: string
  first_created: string
}

interface DataTaskSupervisor {
  id: number
  task_name: string
  description: string
  created_at: string
  updated_at: string
  pid: number
  status: string
  metadata: Record<string, any>
  total_tasks: number
}

interface Props {
  task: DataTaskSupervisor
  grouped_entries: GroupedLoadEntries[]
  current_load: LoadEntry
}

export default function Show(props: Props) {
  // Refresh page every 60 seconds to update the status of the tasks
  const [task, setTask] = React.useState<DataTaskSupervisor>(props.task)
  const [groupedEntries, setGroupedEntries] = React.useState<GroupedLoadEntries[]>(props.grouped_entries)
  const [currentLoad, setCurrentLoad] = React.useState<LoadEntry>(props.current_load)
  const [refreshedAt, setRefreshedAt] = React.useState(Date.now())

  const fetchData = async () => {
    const data = await get<Props>(`/admin/data-tasks/${props.task.id}`)

    setTask(data.task)
    setGroupedEntries(data.grouped_entries)
    setCurrentLoad(data.current_load)
    setRefreshedAt(Date.now())
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      fetchData()
    }, 60000)

    return () => clearInterval(interval)
  })

  return (
    <PageLayout size="md">
      <HStack>
        <PageTitle flex="1">Data Tasks</PageTitle>
      </HStack>

      <AdminBreadcrumb
        paths={[
          { path: '/admin/data-tasks', title: 'Data Tasks' },
          { path: `/admin/data-tasks/${task.id}`, title: `Data Tasks - ${task.task_name}` }
        ]}
      />

      <Stack spacing={8}>
        <StatGroup border="1px solid" borderColor="gray.200" padding={5} borderRadius={'lg'}>
          <HStack width="100%">
            <Stat>
              <StatLabel>Task</StatLabel>
              <StatHelpText>{task.task_name}</StatHelpText>
            </Stat>

            <Stat>
              <StatLabel>Status</StatLabel>
              <StatHelpText>
                <HStack>
                  {iconStatus[task.status]}
                  <Text>{task.status}</Text>
                </HStack>
              </StatHelpText>
            </Stat>

            <Stat>
              <StatLabel>Started at</StatLabel>
              <StatHelpText>{task.metadata.started_at ?? '-'}</StatHelpText>
            </Stat>

            <Stat>
              <StatLabel>Completed at</StatLabel>
              <StatHelpText>{task.metadata.completed_at ?? '-'}</StatHelpText>
            </Stat>

            <Stat>
              <StatLabel>Page refresh</StatLabel>
              <StatHelpText>{new Date(refreshedAt).toLocaleTimeString()}</StatHelpText>
            </Stat>
          </HStack>

          <HStack width="100%">
            <Stat>
              <StatLabel>Total tasks</StatLabel>
              <StatNumber>{task.total_tasks}</StatNumber>
            </Stat>

            <Stat>
              <StatLabel>PID</StatLabel>
              <StatNumber>{task.pid}</StatNumber>
            </Stat>

            <Stat>
              <StatLabel>Recovered</StatLabel>
              <StatNumber>{task.metadata.recovered ? 'Yes' : 'No'}</StatNumber>
            </Stat>

            <Stat>
              <StatLabel>Recovered at</StatLabel>
              <StatHelpText>{task.metadata.recovered_at ?? '-'}</StatHelpText>
            </Stat>
            <Stat></Stat>
          </HStack>
        </StatGroup>
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Task</Th>
                <Th>Status</Th>
                <Th>Completed</Th>
                <Th>Skipped</Th>
                <Th>Workspace</Th>
                <Th>Time</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {groupedEntries.map((entry) =>
                currentLoad.task_name == entry.task_name ? (
                  <Tr key={entry.last_created} bgColor={'gray.50'}>
                    <Td>{currentLoad.task_name}</Td>

                    <Td minWidth={100}>
                      <HStack>
                        {iconStatus[currentLoad.status]}
                        <Text>{currentLoad.status}</Text>
                      </HStack>
                    </Td>
                    <Td>{entry.count}</Td>
                    <Td>{(task.metadata?.skipped || {})[currentLoad.task_name]?.length}</Td>
                    <Td>{currentLoad.project_slug}</Td>
                    <Td>
                      <SyncDuration start={entry.first_created} stop={entry.last_created} status={currentLoad.status} />
                    </Td>
                  </Tr>
                ) : (
                  <Tr key={entry.last_created}>
                    <Td>{entry.task_name}</Td>

                    <Td minWidth={100}>
                      <HStack>
                        {iconStatus['done']}
                        <Text>{'done'}</Text>
                      </HStack>
                    </Td>
                    <Td>{entry.count}</Td>
                    <Td>{(task.metadata?.skipped || {})[entry.task_name]?.length}</Td>
                    <Td></Td>
                    <Td>
                      <SyncDuration start={entry.first_created} stop={entry.last_created} status={'done'} />
                    </Td>
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </PageLayout>
  )
}
