import { Icon, IconProps } from '@chakra-ui/react'
import React from 'react'

interface Props extends IconProps {
  size?: string | number
}

export function KoalaIcon({ size, ...props }: Props) {
  size = size ? `${size}px` : size
  return (
    <Icon viewBox="0 0 24 24" fill="#4D32E4" width={size} height={size} {...props}>
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="currentColor"
      />
      <path
        d="M12 21.9529C17.4969 21.9529 21.9529 17.4969 21.9529 12C21.9529 6.50314 17.4969 2.04706 12 2.04706C6.50314 2.04706 2.04706 6.50314 2.04706 12C2.04706 17.4969 6.50314 21.9529 12 21.9529Z"
        fill="white"
      />
      <path
        d="M13.9875 13.9542C13.8088 13.9546 13.6342 14.008 13.4857 14.1076C13.3373 14.2072 13.2218 14.3485 13.1536 14.5138C13.0855 14.679 13.0679 14.8608 13.1031 15.036C13.1382 15.2113 13.2244 15.3722 13.351 15.4984C13.4775 15.6247 13.6386 15.7106 13.814 15.7453C13.9893 15.78 14.171 15.762 14.3361 15.6935C14.5012 15.625 14.6423 15.5091 14.7415 15.3605C14.8408 15.2118 14.8937 15.0371 14.8937 14.8584C14.8937 14.7394 14.8703 14.6217 14.8247 14.5119C14.7792 14.402 14.7124 14.3023 14.6282 14.2183C14.544 14.1343 14.4441 14.0677 14.3341 14.0224C14.2242 13.9771 14.1064 13.9539 13.9875 13.9542Z"
        fill="currentColor"
      />
      <path
        d="M12 2C9.34784 2 6.80431 3.05357 4.92894 4.92894C3.05357 6.80431 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92894 19.0711C6.80431 20.9464 9.34784 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.80431 19.0711 4.92894C17.1957 3.05357 14.6522 2 12 2ZM19.9375 15.8958C19.7195 15.5058 19.3781 15.1991 18.967 15.0241C18.5559 14.8491 18.0982 14.8156 17.666 14.9288C17.2337 15.042 16.8513 15.2957 16.5788 15.6498C16.3063 16.004 16.1591 16.4386 16.1604 16.8854V19.7208C16.1604 19.75 16.1604 19.7771 16.1604 19.8063C14.9642 20.448 13.6359 20.8045 12.2792 20.8479C10.8276 19.2242 10.0784 17.0915 10.1958 14.9167C10.2887 13.478 10.7649 12.0904 11.575 10.8979C11.5875 10.881 11.5931 10.8601 11.5908 10.8392C11.5885 10.8184 11.5784 10.7992 11.5625 10.7854C11.5458 10.775 11.5261 10.7704 11.5066 10.7723C11.487 10.7742 11.4686 10.7824 11.4542 10.7958C10.2095 11.812 9.38558 13.2535 9.14167 14.8417C9.11459 14.9833 9.08334 15.225 9.06666 15.3583C9.06666 15.4167 8.98334 15.4312 8.94793 15.4208C8.27191 15.1963 7.6584 14.8155 7.15718 14.3094C6.65598 13.8032 6.28122 13.186 6.06334 12.5079C5.84548 11.8297 5.79064 11.1097 5.90327 10.4063C6.01591 9.70297 6.29285 9.03612 6.7116 8.45991C7.13035 7.88367 7.67913 7.41435 8.31332 7.09002C8.94753 6.76572 9.64927 6.59555 10.3616 6.59336C11.0739 6.5912 11.7767 6.75706 12.4128 7.07748C13.049 7.39791 13.6006 7.86386 14.0229 8.43751C14.0661 8.49849 14.1232 8.54828 14.1895 8.58271C14.2559 8.61713 14.3294 8.6352 14.4042 8.63541H20.1729C20.6523 9.79412 20.8794 11.0416 20.8392 12.2949C20.799 13.5482 20.4923 14.7786 19.9396 15.9042L19.9375 15.8958Z"
        fill="currentColor"
      />
    </Icon>
  )
}
