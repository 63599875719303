import { useQuery } from 'react-query'
import { concurrentGET } from '../../lib/api'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

export function useIcpIdeas(seed = 1) {
  const project = useCurrentProject()
  const basePath = `/ai-icp?seed=${seed}`

  const path = projectPath(basePath)

  return useQuery<{ ideas: string[] }>(
    [{ projectId: project?.id, seed }],
    () => concurrentGET<{ ideas: string[] }>(path),
    {
      enabled: Boolean(project?.id)
    }
  )
}
