import {
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { IconEdit, IconEye, IconEyeOff, IconGripHorizontal, IconPlus } from '@tabler/icons-react'
import React, { useCallback, useEffect } from 'react'
import { toast } from 'sonner'
import { Apps } from '../../../../types/App'
import {
  PersistedFieldDefinition,
  useFieldDefinitions,
  useUpdateFieldDefinitions
} from '../../../data/use-field-definitions'
import { SalesforceIcon } from '../../../ui/icons'
import { HubSpotIcon } from '../../../ui/icons/HubspotIcons'
import { FieldDefinitionMenu } from './FieldDefinitionMenu'
import { dataTypeIcons, FieldDefinitionModal } from './FieldDefinitionModal'

interface KeyFieldsModalProps extends UseDisclosureProps {
  recordType: 'account' | 'profile'
  apps: Apps
  onChange?: (fields: PersistedFieldDefinition[]) => void
}

export function KeyFieldsModal({ recordType, onChange, ...props }: KeyFieldsModalProps) {
  const { isOpen, onClose } = useDisclosure(props)
  const { data, isLoading } = useFieldDefinitions(recordType, { enabled: isOpen })
  const { isLoading: isUpdating, mutateAsync: saveUpdates } = useUpdateFieldDefinitions()
  const [fieldDefns, setFieldDefns] = React.useState<PersistedFieldDefinition[]>([])
  const [fieldToEdit, setFieldToEdit] = React.useState<null | PersistedFieldDefinition>(null)

  const editFieldModal = useDisclosure()

  useEffect(() => {
    if (data?.fields?.length) {
      setFieldDefns(data.fields) //.filter((field) => field.key_field || !field.is_custom))
    }
  }, [data])

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over, activatorEvent } = event

    // prevent default link behavior
    activatorEvent.preventDefault()
    activatorEvent.stopPropagation()

    if (active.id !== over?.id) {
      setFieldDefns((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id)
        const newIndex = items.findIndex((item) => item.id === over?.id)
        const newItems = arrayMove(items, oldIndex, newIndex)
        return newItems
      })
    }
  }, [])

  const onUpdateField = useCallback((field: PersistedFieldDefinition) => {
    setFieldDefns((prev) =>
      prev.map((prevField) => {
        if (prevField.id === field.id) return field
        return prevField
      })
    )
  }, [])

  const onFieldDeleted = useCallback((id: string) => {
    setFieldDefns((prev) => prev.filter((field) => field.id !== id))
  }, [])

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      try {
        const res = await saveUpdates({
          recordType: recordType,
          fields: fieldDefns.map((f) => ({
            id: f.id,
            key_field: f.key_field
          }))
        })
        onClose()
        onChange?.(res.fields)
      } catch (err) {
        toast.error('There was an issue saving these changes', { description: (err as any)?.message })
      }
    },
    [fieldDefns, saveUpdates, recordType, onChange, onClose]
  )

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} closeOnEsc={!isUpdating} closeOnOverlayClick={!isUpdating} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton isDisabled={isLoading} />
          <ModalHeader fontSize="lg">Edit highlighted fields</ModalHeader>
          <ModalBody py={0} px={0}>
            <Stack alignItems="flex-start" spacing={4}>
              <Stack alignItems="flex-start" spacing={4} px={6}>
                <Text fontSize="sm" color="gray.600">
                  Arrange the fields that you want to highlight in the {recordType} summary. Add fields from your CRM or{' '}
                  {recordType} traits.
                </Text>

                {isLoading && <Spinner size="sm" />}
              </Stack>

              <Box flex="none" width="100%" position="relative" maxH="min(50vh, 400px)" overflow="auto" px={4}>
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                  <SortableContext items={fieldDefns} strategy={verticalListSortingStrategy}>
                    {fieldDefns.map((field) => (
                      <FieldItem
                        key={field.id}
                        field={field}
                        onUpdateField={onUpdateField}
                        onFieldDeleted={onFieldDeleted}
                        onRequestEditDefinition={
                          field.is_custom
                            ? () => {
                                setFieldToEdit(field)
                                editFieldModal.onOpen()
                              }
                            : undefined
                        }
                      />
                    ))}
                  </SortableContext>
                </DndContext>
              </Box>
            </Stack>
          </ModalBody>
          <ModalFooter gap={2.5} borderTop="1px solid" borderColor="gray.200" py={3.5} px={4}>
            <Button
              size="sm"
              variant="ghost"
              leftIcon={<IconPlus size={16} />}
              mr="auto"
              onClick={() => {
                setFieldToEdit(null)
                editFieldModal.onOpen()
              }}
            >
              Define new field
            </Button>
            <Button size="sm" variant="outline" onClick={onClose} isDisabled={isUpdating}>
              Cancel
            </Button>
            <Button size="sm" colorScheme="purple" onClick={onSubmit} isLoading={isUpdating}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <FieldDefinitionModal
        field={fieldToEdit}
        recordType={recordType}
        apps={props.apps}
        onChange={(field) => {
          const updated = { ...field, key_field: true }

          setFieldDefns((prev) => {
            if (prev.find((item) => item.id === field.id)) {
              // update existing
              return prev.map((item) => (item.id === field.id ? updated : item))
            } else {
              // add new, default to key_field
              return prev.concat(updated)
            }
          })
          setFieldToEdit(null)
        }}
        {...editFieldModal}
      />
    </>
  )
}

interface FieldItemProps {
  field: PersistedFieldDefinition
  onUpdateField: (field: PersistedFieldDefinition) => void
  onFieldDeleted: (id: string) => void
  onRequestEditDefinition?: () => void
}

function FieldItem({ field, onUpdateField, onFieldDeleted, onRequestEditDefinition }: FieldItemProps) {
  const { attributes, listeners, transform, transition, setNodeRef, setActivatorNodeRef, active, isDragging } =
    useSortable({
      id: field.id
    })

  const isSalesforce = [
    'salesforce_data.',
    'salesforce_contact_data.',
    'salesforce_lead_data.',
    'salesforce_opportunity.'
  ].some((key) => field.data_source?.startsWith(key))

  const isHubSpot = ['hubspot_data.', 'hubspot_contact_data.'].some((key) => field.data_source?.startsWith(key))

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    outline: 'none',
    zIndex: isDragging ? 10 : 1
  }

  const toggleKeyField = useCallback(() => {
    onUpdateField({ ...field, key_field: !field.key_field })
  }, [field, onUpdateField])

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      bg={isDragging ? 'white' : 'transparent'}
      py={1.5}
      px={1.5}
      rounded="md"
      fontSize="sm"
      border="1px solid"
      borderColor={isDragging ? 'gray.200' : 'transparent'}
      shadow={isDragging ? 'lg' : 'none'}
      pointerEvents={active ? 'none' : undefined}
      position="relative"
      cursor="unset"
      role="group"
    >
      <Flex gap={2} alignItems="center" opacity={field.key_field ? 1 : 0.5}>
        <IconButton
          aria-label="Reorder"
          size="xs"
          height={5}
          minW={4}
          variant="unstyled"
          color={isDragging ? 'gray.800' : 'gray.400'}
          _hover={{ color: 'gray.700' }}
          cursor={isDragging ? 'grabbing' : 'grab'}
          icon={<IconGripHorizontal size={14} />}
          style={{ touchAction: 'none' }}
          ref={setActivatorNodeRef}
          {...listeners}
        />
        <Flex flex="1 1 auto" gap={1.5} alignItems="center">
          {dataTypeIcons[field.data_type] && <Icon as={dataTypeIcons[field.data_type]} boxSize={4} color="gray.500" />}
          <Text fontWeight="medium" isTruncated>
            {field.label}
          </Text>
          {onRequestEditDefinition && field.is_custom && (
            <IconButton
              aria-label="Edit field definition"
              size="xs"
              variant="ghost"
              icon={<IconEdit size={16} />}
              display="none"
              _groupHover={{ display: 'flex' }}
              onClick={onRequestEditDefinition}
            />
          )}
        </Flex>
        {isSalesforce ? (
          <Badge
            fontSize="x-small"
            variant="regular"
            colorScheme="blue"
            alignItems="center"
            gap={0.5}
            display="inline-flex"
          >
            <SalesforceIcon color="salesforce" boxSize={3} />
            Salesforce
          </Badge>
        ) : isHubSpot ? (
          <Badge
            fontSize="x-small"
            variant="regular"
            colorScheme="orange"
            alignItems="center"
            gap={0.5}
            display="inline-flex"
          >
            <HubSpotIcon color="hubspot" boxSize={3} />
            HubSpot
          </Badge>
        ) : (
          <Badge fontSize="x-small" variant="regular" colorScheme={field.is_custom ? 'purple' : 'gray'}>
            {field.is_custom ? 'Custom' : 'Default'}
          </Badge>
        )}
        <Flex gap={1} alignItems="center">
          {field.key_field ? (
            <Tooltip label="Remove from higlights">
              <IconButton
                aria-label="Remove from highlights"
                size="xs"
                variant="ghost"
                icon={<IconEye size={16} />}
                onClick={toggleKeyField}
              />
            </Tooltip>
          ) : (
            <Tooltip label="Add to highlights">
              <IconButton
                aria-label="Add to highlights"
                size="xs"
                variant="ghost"
                icon={<IconEyeOff size={16} />}
                onClick={toggleKeyField}
              />
            </Tooltip>
          )}
          <FieldDefinitionMenu
            field={field}
            onUpdateField={onUpdateField}
            onFieldDeleted={onFieldDeleted}
            onRequestEditDefinition={onRequestEditDefinition}
          />
        </Flex>
      </Flex>
    </Box>
  )
}
