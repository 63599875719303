import { Icon, IconProps } from '@chakra-ui/react'
import React from 'react'

interface Props extends IconProps {
  size?: string | number
  accentColor?: string
}

export const EmailCheckIcon = React.forwardRef<SVGSVGElement, Props>(
  ({ size, accentColor = 'currentColor', ...props }, ref) => {
    size = size ? `${size}px` : size
    return (
      <Icon ref={ref} viewBox="0 0 24 24" fill="none" width={size} height={size} {...props}>
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M10.5 18.5H4.5C3.96957 18.5 3.46086 18.2893 3.08579 17.9142C2.71071 17.5391 2.5 17.0304 2.5 16.5V6.5M2.5 6.5C2.5 5.96957 2.71071 5.46086 3.08579 5.08579C3.46086 4.71071 3.96957 4.5 4.5 4.5H18.5C19.0304 4.5 19.5391 4.71071 19.9142 5.08579C20.2893 5.46086 20.5 5.96957 20.5 6.5M2.5 6.5L11.5 12.5L20.5 6.5M20.5 6.5V10.5"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.184 14.9791C22.6579 14.0763 21.9051 13.3264 21.0001 12.8039C20.1023 12.2855 19.0854 12.0086 18.0487 12.0002C17.0119 11.9918 15.9907 12.2521 15.0846 12.7559C14.1784 13.2596 13.4183 13.9895 12.8782 14.8745C12.3381 15.7595 12.0366 16.7693 12.003 17.8055L12 17.9999L12.003 18.1943C12.0368 19.2388 12.3429 20.2562 12.8908 21.146C13.4387 22.0358 14.2096 22.7671 15.127 23.2675C16.0444 23.7678 17.0766 24.0199 18.1213 23.9988C19.1661 23.9776 20.1872 23.684 21.0837 23.1469C21.9801 22.6099 22.7207 21.848 23.2322 20.9368C23.7436 20.0255 24.0082 18.9965 23.9998 17.9515C23.9914 16.9066 23.7102 15.882 23.184 14.9791ZM20.6398 15.5089C20.8439 15.5218 21.0364 15.6087 21.181 15.7533C21.3257 15.8979 21.4125 16.0904 21.4254 16.2945C21.4382 16.4987 21.3761 16.7005 21.2507 16.8621L21.181 16.941L17.821 20.301L17.742 20.3708C17.5947 20.4851 17.4135 20.5471 17.2271 20.5471C17.0406 20.5471 16.8595 20.4851 16.7122 20.3708L16.6332 20.301L14.9532 18.621L14.8835 18.5421C14.7582 18.3805 14.6962 18.1787 14.709 17.9746C14.7219 17.7705 14.8088 17.5781 14.9534 17.4335C15.098 17.2889 15.2904 17.202 15.4945 17.1891C15.6986 17.1763 15.9004 17.2383 16.062 17.3636L16.141 17.4333L17.2271 18.5186L19.9932 15.7533L20.0722 15.6836C20.2338 15.5582 20.4356 15.4961 20.6398 15.5089Z"
          fill={accentColor}
        />
      </Icon>
    )
  }
)

EmailCheckIcon.displayName = 'EmailCheckIcon'
