import React from 'react'

import {
  Stack,
  HStack,
} from '@chakra-ui/react'

import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { AdminBreadcrumb } from '..'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { postForm } from '@app/lib/api'
import { toast } from 'sonner'
import FormContent from './formContent'

export default function New() {
  const formRef = React.useRef<HTMLFormElement | null>(null)

  const createPrompt = async () => {
    if (!formRef.current) {
      return
    }

    const formData = new FormData(formRef.current)

    try {
      await postForm('/admin/prompts-db', formData)
        .then((_) => {
          window.location.href = '/admin/prompts-db'
        })
        .catch((_) => {
          toast.error('Error creating prompt')
        })
    } catch (_err) {
      toast.error('Error creating prompt')
    }
  }

  return (
    <PageLayout size="md">
      <HStack>
        <PageTitle flex="1">Add Prompt</PageTitle>
      </HStack>

      <AdminBreadcrumb
        paths={[
          { path: '/admin/prompts-db', title: 'Prompts DB' },
          { path: '/admin/prompts-db/new', title: 'New' }
        ]}
      />

      <Stack>
        <form
          ref={formRef}
          method="POST"
          action="/admin/prompts-db"
          onSubmit={(e) => {
            e.preventDefault()
            createPrompt()
          }}
        >
          <AuthenticityToken />
          <FormContent />
        </form>
      </Stack>
    </PageLayout>
  )
}
