import {
  Badge,
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import {
  IconAward,
  IconDotsVertical,
  IconInfoCircle,
  IconPlus,
  IconTrash,
  IconTriangleSquareCircle
} from '@tabler/icons-react'
import { some } from 'lodash'
import startCase from 'lodash/startCase'
import React from 'react'
import type { PageMeta } from '../../../../types/PageMeta'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { DeleteConfirmation } from '../../../ui/DeleteConfirmation'
import { SfOpportunityIcon2 } from '../../../ui/icons/SalesforceIcons'
import { MiddotDivider } from '../../../ui/Middot'
import PageDescription from '../../../ui/PageDescription'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { projectPath } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import SquareIcon from '../../../ui/SquareIcon'
import { TableFooter } from '../../../ui/TableFooter'
import { TimeAgo } from '../../../ui/TimeAgo'
import { useCurrentUser } from '../../../ui/UserContext'
import { mergeParams } from '../../icps/types'
import type { ContentReport, ReportAvailability } from './types'

interface Props {
  availability: ReportAvailability[]
  reports: ContentReport[]
  page_meta: PageMeta
}

function contentReportPath(id: string) {
  return projectPath(`/reports/contents/${id}`)
}

export default function Index(props: Props) {
  const anyAvailable = some(props.availability, (a) => a.available)

  return (
    <PageLayout size="md">
      <SettingsBreadCrumb paths={[{ path: projectPath('/reports/contents'), title: 'Content Reports' }]} />

      <HStack justifyContent="space-between">
        <Box>
          <HStack>
            <PageTitle>Content Reports</PageTitle>
          </HStack>
          <PageDescription>Create and manage your team's Content Reports.</PageDescription>
        </Box>
        {anyAvailable && (
          <Button
            size="sm"
            colorScheme="purple"
            as={Link}
            href={projectPath('/reports/contents/new')}
            leftIcon={<IconPlus size={14} />}
          >
            New Content Report
          </Button>
        )}
      </HStack>
      <Stack width="100%" spacing={6}>
        <ReportsList {...props} />
      </Stack>
    </PageLayout>
  )
}

function ReportsList(props: Props) {
  const user = useCurrentUser()
  return (
    <TableContainer>
      <Table size="sm" mb={5}>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Last Refreshed</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {props.reports.map((report) => (
            <Tr key={report.id} _hover={{ bg: 'gray.50' }}>
              <Td>
                <HStack py={1}>
                  {report.kind === 'opportunities' ? (
                    <SquareIcon icon={SfOpportunityIcon2} iconSize={6} padding={2} colorScheme="orange" rounded="xl" />
                  ) : (
                    <SquareIcon
                      icon={IconTriangleSquareCircle}
                      iconSize={6}
                      padding={2}
                      colorScheme="pink"
                      rounded="xl"
                    />
                  )}
                  <Link href={contentReportPath(report.id as string)}>
                    <Stack spacing={1}>
                      <HStack>
                        <Text>{report.name || `Untitled Report`}</Text>
                        {report.promoted && (
                          <Tooltip label="This report will be used to weight suggested intent signals">
                            <Badge colorScheme="gray" variant="subtle">
                              <HStack spacing={1}>
                                <span>Primary</span>
                                <Icon as={IconInfoCircle} boxSize={3.5} color="gray.500" />
                              </HStack>
                            </Badge>
                          </Tooltip>
                        )}
                      </HStack>

                      <HStack spacing={1} wrap="wrap" divider={<MiddotDivider />} fontSize="xs" color="gray.600">
                        <Text>{startCase(report.kind)}</Text>
                        <Text color="gray.600" fontSize="xs">
                          Created <TimeAgo canToggle={false} time={report.created_at} />
                          {report.user && ` by ${report.user?.id === user.id ? 'you' : report.user?.name}`}
                        </Text>
                      </HStack>
                    </Stack>
                  </Link>
                </HStack>
              </Td>
              <Td>
                <Stack>
                  <TimeAgo time={report.finished_at} canToggle={false} />
                </Stack>
              </Td>
              <Td width="1px" isNumeric onClick={(e) => e.stopPropagation()}>
                <ReportMenu id={report.id as string} name={report.name} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <TableFooter
        pageMeta={props.page_meta}
        page={props.page_meta.current_page}
        nextPath={mergeParams(window.location.toString(), {
          page: String(props.page_meta.next_page)
        })}
        prevPath={mergeParams(window.location.toString(), {
          page: String(props.page_meta.prev_page)
        })}
      />
    </TableContainer>
  )
}

function ReportMenu(props: { id: string; name?: string }) {
  const deleteModal = useDisclosure()
  const promoteModal = useDisclosure()

  return (
    <>
      <Menu autoSelect={false} placement="bottom-end">
        <MenuButton
          as={IconButton}
          size="xs"
          variant="ghost"
          aria-label="Menu"
          icon={<Icon as={IconDotsVertical} boxSize={4} />}
        />
        <MenuList>
          <MenuItem onClick={promoteModal.onOpen} icon={<IconAward size={16} />} isDisabled={!props.name}>
            Promote Report…
          </MenuItem>
          <MenuItem color="red.500" onClick={deleteModal.onOpen} icon={<IconTrash size={16} />}>
            Delete Report…
          </MenuItem>
        </MenuList>
      </Menu>

      <DeleteConfirmation {...deleteModal} deletePath={contentReportPath(props.id)} title="Delete Content Report">
        Are you sure you want to delete the "<strong>{props.name || props.id}</strong>" content report?
      </DeleteConfirmation>

      <PromoteReportModal {...promoteModal} id={props.id} name={props.name} />
    </>
  )
}

interface PromoteReportModalProps extends UseDisclosureProps {
  id: string
  name?: string
}

function PromoteReportModal({ isOpen, onClose, id, name }: PromoteReportModalProps) {
  const [submitting, setSubmitting] = React.useState(false)

  const onSubmit = React.useCallback(() => {
    setSubmitting(true)
  }, [])

  return (
    <Modal isOpen={isOpen ?? false} onClose={onClose!}>
      <ModalOverlay />
      <ModalContent as="form" action={projectPath(`/reports/contents/${id}`)} method="POST" onSubmit={onSubmit}>
        <ModalCloseButton />
        <ModalHeader>Promote {name || `Report ${id}`}</ModalHeader>
        <ModalBody>
          <AuthenticityToken />
          <input type="hidden" value="PATCH" name="_method" />
          <input type="hidden" name="content_report[promoted]" value="true" />

          <Text fontSize="sm" color="gray.600">
            When you promote a report, it will be used to set weights for suggested intent signals. It will be
            periodically updated. You can only have one promoted report at a time.
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button size="sm" onClick={onClose} variant="outline" mr={3}>
            Cancel
          </Button>
          <Button type="submit" size="sm" colorScheme="purple" isLoading={submitting} isDisabled={!name}>
            Promote Report
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
