import { Consumer, createConsumer, Mixin, Subscription } from '@rails/actioncable'
import memoize from 'lodash/memoize'

const client = createConsumer()

type SubscriptionParams = Parameters<typeof client.subscriptions.create>[0]

export class Emitter {
  private callbacks: Record<string, Function[]> = {}

  on(event: string, callback: Function): this {
    this.callbacks[event] = [...(this.callbacks[event] ?? []), callback]
    return this
  }

  once(event: string, fn: Function): this {
    const on = (...args: unknown[]): void => {
      this.off(event, on)
      fn.apply(this, args)
    }

    this.on(event, on)
    return this
  }

  off(event: string, callback: Function): this {
    const fns = this.callbacks[event] ?? []
    const without = fns.filter((fn) => fn !== callback)
    this.callbacks[event] = without
    return this
  }

  emit(event: string, ...args: unknown[]): this {
    const callbacks = this.callbacks[event] ?? []
    callbacks.forEach((callback) => {
      callback.apply(this, args)
    })
    return this
  }

  removeListeners() {
    this.callbacks = {}
  }
}

export const channelSubscription = (params: SubscriptionParams, sub: Partial<Mixin> = {}) =>
  client.subscriptions.create(params, {
    ...sub
  })

export class SubscriptionEmitter extends Emitter {
  private subscription: Subscription<Consumer>
  private params: SubscriptionParams

  constructor(params: SubscriptionParams) {
    super()
    this.params = params
    this.subscription = this.subscribe()
  }

  get subscribed() {
    const identifier = this.subscription?.identifier

    if (!identifier) {
      return false
    }

    return client.subscriptions.subscriptions.some((s) => s.identifier === identifier)
  }

  perform(action: string, data?: any) {
    if (this.subscribed) {
      this.subscription.perform(action, data)
    }
  }

  subscribe() {
    if (this.subscribed) {
      return this.subscription
    }

    this.subscription = client.subscriptions.create(this.params, {
      received: (data) => this.emit('received', data),
      initialized: () => this.emit('initialized'),
      connected: () => this.emit('connected'),
      disconnected: () => this.emit('disconnected'),
      rejected: () => this.emit('rejected')
    })

    return this.subscription
  }

  unsubscribe = () => {
    this.subscription.unsubscribe()
  }
}

const memoizedSubscription = memoize(
  (params: SubscriptionParams) => new SubscriptionEmitter(params),
  (params) => JSON.stringify(params)
)

export const subscribeToChannel = (params: SubscriptionParams) => {
  const subscription = memoizedSubscription(params)

  // If the memoized subscription for these params was unsubscribed previously,
  // resubscribe it.
  if (!subscription.subscribed) {
    subscription.subscribe()
  }

  return subscription
}
