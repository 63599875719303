import { Icon, IconProps } from '@chakra-ui/react'
import React from 'react'

interface Props extends IconProps {
  size?: string | number
}

export const DiscordIcon: React.FC<Props> = ({ size, ...props }) => {
  size = size ? `${size}px` : size

  return (
    <Icon viewBox="0 0 24 24" color="#5865F2" width={size} height={size} {...props}>
      <rect x="2" y="2" width="20" height="20" rx="2" fill="currentColor" />
      <path
        d="M16.505 7.82895C15.6765 7.44526 14.788 7.16258 13.8589 7.00068C13.842 6.99755 13.8251 7.00536 13.8164 7.02098C13.7021 7.22609 13.5756 7.49368 13.4869 7.704C12.4877 7.55303 11.4936 7.55303 10.5149 7.704C10.4263 7.489 10.2951 7.22609 10.1803 7.02098C10.1716 7.00588 10.1547 6.99808 10.1378 7.00068C9.20928 7.16206 8.32076 7.44475 7.49167 7.82895C7.4845 7.83207 7.47834 7.83728 7.47426 7.84404C5.78892 10.3851 5.32724 12.8636 5.55372 15.3115C5.55475 15.3234 5.56141 15.3349 5.57063 15.3422C6.68257 16.1663 7.75967 16.6666 8.81678 16.9982C8.83369 17.0034 8.85162 16.9971 8.86239 16.9831C9.11244 16.6385 9.33535 16.2751 9.52647 15.893C9.53774 15.8706 9.52698 15.844 9.50393 15.8352C9.15036 15.6998 8.8137 15.5348 8.48985 15.3474C8.46424 15.3323 8.46219 15.2953 8.48575 15.2776C8.5539 15.2261 8.62207 15.1725 8.68714 15.1183C8.69892 15.1084 8.71532 15.1063 8.72916 15.1126C10.8567 16.0929 13.16 16.0929 15.2624 15.1126C15.2763 15.1058 15.2927 15.1079 15.305 15.1178C15.3701 15.1719 15.4382 15.2261 15.5069 15.2776C15.5304 15.2953 15.5289 15.3323 15.5033 15.3474C15.1794 15.5384 14.8428 15.6998 14.4887 15.8347C14.4656 15.8435 14.4554 15.8706 14.4667 15.893C14.6619 16.2745 14.8848 16.6379 15.1302 16.9826C15.1405 16.9971 15.1589 17.0034 15.1758 16.9982C16.2381 16.6666 17.3152 16.1663 18.4271 15.3422C18.4368 15.3349 18.443 15.324 18.444 15.312C18.7151 12.482 17.99 10.0238 16.5219 7.84456C16.5184 7.83728 16.5122 7.83207 16.505 7.82895ZM9.84418 13.821C9.20365 13.821 8.67586 13.2275 8.67586 12.4987C8.67586 11.7698 9.19341 11.1764 9.84418 11.1764C10.5001 11.1764 11.0227 11.7751 11.0125 12.4987C11.0125 13.2275 10.4949 13.821 9.84418 13.821ZM14.1638 13.821C13.5233 13.821 12.9955 13.2275 12.9955 12.4987C12.9955 11.7698 13.5131 11.1764 14.1638 11.1764C14.8197 11.1764 15.3424 11.7751 15.3321 12.4987C15.3321 13.2275 14.8197 13.821 14.1638 13.821Z"
        fill="white"
      />
    </Icon>
  )
}
