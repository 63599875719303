import React from 'react'

const extractLocation = () => ({ ...window.location })

export default function useLocation() {
  const [location, setLocation] = React.useState<Window['location']>(extractLocation())

  React.useEffect(() => {
    const onLocationChange = () => setLocation(extractLocation())

    window.addEventListener('locationchange', onLocationChange)
    window.addEventListener('popstate', onLocationChange)

    return () => {
      window.removeEventListener('locationchange', onLocationChange)
      window.removeEventListener('popstate', onLocationChange)
    }
  }, [])

  return location
}
