import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import React, { PropsWithChildren, useCallback, useRef, useState } from 'react'

interface Props extends UseDisclosureProps {
  onConfirm?: () => void | Promise<void>
}

export function ConfirmDowngrade({ onConfirm, children, ...props }: PropsWithChildren<Props>) {
  const disclosure = useDisclosure(props)
  const cancelRef = useRef<HTMLButtonElement | null>(null)
  const [loading, setLoading] = useState(false)

  const onClose = disclosure.onClose

  const handleConfirm = useCallback(async () => {
    setLoading(true)
    if (typeof onConfirm === 'function') {
      await onConfirm()
    }
    setLoading(false)
    onClose()
  }, [onConfirm, onClose])

  return (
    <AlertDialog leastDestructiveRef={cancelRef} {...disclosure}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="md">Confirm override</AlertDialogHeader>
          <AlertDialogBody fontSize="sm">{children}</AlertDialogBody>

          <AlertDialogFooter>
            <Button size="sm" ref={cancelRef} mr={3} variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button size="sm" colorScheme="red" isLoading={loading} onClick={handleConfirm}>
              Yes, continue
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
