import React, { FormEvent, useState } from 'react'
import CompanyAvatar from '@app/components/ui/CompanyAvatar'
import Avatar from '../../../ui/Avatar'
import {
  useDisclosure,
  UseDisclosureProps,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Icon,
  Button,
  Flex,
  HStack,
  Stack,
  FormControl,
  FormLabel,
  Spinner
} from '@chakra-ui/react'
import { toast } from 'sonner'
import { postForm } from '../../../../lib/api'
import { IconUser } from '@tabler/icons-react'
import { Prospect } from '../../../data/use-prospects'
import { useAppDep } from '../../../data/use-app-dep'
import { App } from '../../../../types/App'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { projectPath } from '../../../ui/ProjectsContext'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'

interface AddToSalesloftProps extends UseDisclosureProps {
  selectedProspects?: Prospect[] | null
  prospectsWithoutEmail?: Prospect[] | null
  app: App
  profileType?: 'Profile' | 'ProspectedProfile'
}

export type SalesloftCadence = {
  cadence_id: number
  name: string
}

export type SalesloftCadenceResponse = {
  data: {
    cadences: SalesloftCadence[]
  }
}

export function AddToSalesloftModal(props: AddToSalesloftProps) {
  const { selectedProspects, prospectsWithoutEmail } = props
  const disclosure = useDisclosure(props)
  const onClose = disclosure.onClose
  const [saving, setSaving] = React.useState(false)

  const [currentCadence, setCurrentCadence] = React.useState<SalesloftCadence | null>(null)

  const {
    data: cadenceData,
    isLoading: loadingCadences,
    isError: cadenceError,
    error: CadenceErrorData
  } = useAppDep<'cadences', SalesloftCadence[]>('Salesloft', 'cadences')

  const onSubmit = React.useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      setSaving(true)
      try {
        const form = e.target as HTMLFormElement
        const data = new FormData(form)

        const profileIds = selectedProspects?.map((p) => p.id) ?? []
        profileIds.forEach((profileId) => data.append('profile_id[]', profileId))

        postForm(projectPath(`/salesloft/actions/cadences?profile_type=${props.profileType ?? 'Profile'}`), data)
          .then(() => {
            toast.success('Contacts added to cadence!', {
              description: `${selectedProspects?.length} contact${selectedProspects && selectedProspects?.length > 1 ? 's' : ''} has been added to ${currentCadence?.name} in Salesloft.`
            })
            setSaving(false)
            onClose()
          })
          .catch((e) => {
            toast.error('Error adding contacts to cadence!', {
              description: `${e.message}: ${e.body?.error}`
            })
            setSaving(false)
          })
      } catch (error) {
        console.error(error)
      } finally {
        setSaving(false)
      }
    },
    [currentCadence?.name, onClose, props.profileType, selectedProspects]
  )

  const [showMoreProspects, setShowMoreProspects] = useState(false)
  const toggleShowMoreProspects = () => {
    setShowMoreProspects(!showMoreProspects)
  }

  return (
    <Modal {...disclosure} size="md" isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontSize="md" display="flex" gap={2} pb={1}>
          <CompanyAvatar
            size="20px"
            domain={'outreach.ai'}
            mr={2}
            src="https://asset.brandfetch.io/idLO9lFZj5/id8bueqGAT.jpeg"
          />
          Add to Salesloft cadence
        </ModalHeader>
        <ModalBody pb={6}>
          {selectedProspects && (
            <Text mb={6}>
              <Icon as={IconUser} boxSize={4} color="gray.400" /> {selectedProspects.length} contacts
            </Text>
          )}
          <form onSubmit={onSubmit}>
            <Stack fontSize="sm" spacing={4}>
              {loadingCadences && <Spinner size="sm" />}
              {cadenceError && (
                <Text p="4" bg="orange.50" m="2">
                  {' '}
                  {(CadenceErrorData as any)?.body?.error}
                </Text>
              )}
              {!cadenceError && !loadingCadences && (
                <FormControl>
                  <input type="hidden" name="salesloft[cadence_id]" value={currentCadence?.cadence_id} />
                  <FormLabel>Cadence</FormLabel>

                  <ComboboxWithSearch
                    items={cadenceData?.data?.cadences ?? []}
                    selectedItem={currentCadence}
                    onChange={(selectedItem) => {
                      setCurrentCadence(selectedItem)
                    }}
                    filterItem={(a, val) => a.name.toLowerCase().includes(val)}
                    itemToString={(item) => item?.name || ''}
                  />
                </FormControl>
              )}
            </Stack>
            <Stack fontSize="sm" spacing={2} mt={4}>
              <Text fontSize="xs" color="gray.500" fontWeight="bold">
                CONTACTS:
              </Text>
              <Flex direction="column" alignItems="space-between" gap={3}>
                {prospectsWithoutEmail && prospectsWithoutEmail.length && (
                  <Stack spacing="4" p="2">
                    <Text fontSize="sm" color="gray.600" lineHeight="1.4">
                      You've selected {prospectsWithoutEmail.length} prospect{''}
                      {prospectsWithoutEmail.length > 1 ? 's' : ''} whose emails have not been enriched yet. They will
                      be enriched if you proceed and will use enrichment credits in the future.
                    </Text>
                    <Text whiteSpace={'pre-wrap'} fontSize="xs" lineHeight="1.4" p="4" bg="purple.50" color="gray.700">
                      Note: we're currently offering unlimited Koala Waterfall enrichment credits during our open beta.
                    </Text>
                  </Stack>
                )}
                {selectedProspects && (
                  <>
                    {selectedProspects.length > 5 ? (
                      <>
                        {selectedProspects?.slice(0, 4).map((selectedProspect) => (
                          <Flex key={selectedProspect.id} justifyContent="space-between" alignItems="center">
                            <HStack>
                              <Avatar
                                size="xs"
                                name={selectedProspect.name}
                                src={projectPath(`/prospects/${selectedProspect.id}/avatar`)}
                              />
                              <Text>
                                {selectedProspect.first_name} {selectedProspect.last_name}
                              </Text>
                            </HStack>
                            <TextEllipsis fontSize="xs" color="gray.600" maxW="50%">
                              {selectedProspect.title}
                            </TextEllipsis>
                          </Flex>
                        ))}
                        {showMoreProspects &&
                          selectedProspects?.slice(4, selectedProspects.length).map((selectedProspect) => (
                            <Flex key={selectedProspect.id} justifyContent="space-between" alignItems="center">
                              <HStack>
                                <Avatar
                                  size="xs"
                                  name={selectedProspect.name}
                                  src={projectPath(`/prospects/${selectedProspect.id}/avatar`)}
                                />
                                <Text>
                                  {selectedProspect.first_name} {selectedProspect.last_name}
                                </Text>
                              </HStack>
                              <TextEllipsis fontSize="xs" color="gray.600" maxW="50%">
                                {selectedProspect.title}
                              </TextEllipsis>
                            </Flex>
                          ))}
                        <Text onClick={toggleShowMoreProspects} color="purple.500" align="center" cursor="pointer">
                          {showMoreProspects
                            ? `- Hide ${selectedProspects.length - 4} contacts`
                            : `+ Show ${selectedProspects.length - 4} more contacts`}
                        </Text>
                      </>
                    ) : (
                      <>
                        {selectedProspects.map((selectedProspect) => (
                          <Flex key={selectedProspect.id} justifyContent="space-between" alignItems="center">
                            <HStack>
                              <Avatar
                                size="xs"
                                name={selectedProspect.name}
                                src={projectPath(`/prospects/${selectedProspect.id}/avatar`)}
                              />
                              <Text>
                                {selectedProspect.first_name} {selectedProspect.last_name}
                              </Text>
                            </HStack>
                            <TextEllipsis fontSize="xs" color="gray.600" maxW="50%">
                              {selectedProspect.title}
                            </TextEllipsis>
                          </Flex>
                        ))}
                      </>
                    )}
                  </>
                )}
              </Flex>
            </Stack>
            <Flex w="100%" pt="4">
              <Button
                w="100%"
                size="sm"
                colorScheme={'purple'}
                type="submit"
                isDisabled={cadenceError || !cadenceData?.data.cadences.length}
                isLoading={saving || loadingCadences}
              >
                Add to Salesloft Cadence
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
