import {
  Box,
  Button,
  CloseButton,
  Divider,
  Heading,
  HStack,
  Input,
  Select,
  Text,
  ThemeTypings,
  VStack
} from '@chakra-ui/react'
import { IconChevronsRight, IconPlus } from '@tabler/icons-react'
import React from 'react'
import { Toggle } from '../../../accounts/components/Toggle'
import { KoalaField, SalesforceField } from '../show'
import { nanoid } from 'nanoid'

interface Props {
  salesforceLayout: SalesforceField[]
  koalaLayout: KoalaField[]
  fieldPrefix: string
  hardcodedPrefix: string
  mapping?: MappingRow[]
  hardcoded?: HardcodedRow[]
  title?: React.ReactNode
  colorScheme?: ThemeTypings['colorSchemes']
  defaultIsOpen?: boolean
}

export interface MappingRow {
  key?: string
  koala_field?: string
  salesforce_field?: string
}

export interface HardcodedRow {
  key?: string
  value?: string
  salesforce_field?: string
}

export const FieldMapping: React.FunctionComponent<Props> = (props) => {
  const [rows, setRows] = React.useState<MappingRow[]>((props.mapping ?? []).map((row) => ({ ...row, key: nanoid() })))

  const [hardcoded, setHardcoded] = React.useState<HardcodedRow[]>(
    (props.hardcoded ?? []).map((row) => ({ ...row, key: nanoid() }))
  )

  const addRow = React.useCallback(() => {
    setRows((prevRows) => [...prevRows, { key: nanoid() }])
  }, [setRows])

  const removeRow = React.useCallback(
    (key?: string) => {
      setRows((prevRows) => prevRows.filter((row) => row.key !== key))
    },
    [setRows]
  )

  const addHardcoded = React.useCallback(() => {
    setHardcoded((prevRows) => [...prevRows, { key: nanoid() }])
  }, [setHardcoded])

  const removeHardcodedRow = React.useCallback(
    (key?: string) => {
      setHardcoded((prevRows) => prevRows.filter((row) => row.key !== key))
    },
    [setHardcoded]
  )

  return (
    <VStack
      w="100%"
      alignItems={'normal'}
      p="8"
      borderLeftWidth={'medium'}
      borderLeftColor={`${props.colorScheme}.300`}
      bg="gray.50"
    >
      <Toggle title={props.title} defaultIsOpen={props.defaultIsOpen}>
        <VStack px="2" py="4" w="100%" alignItems={'normal'} spacing="4">
          <HStack w="100%" justifyContent={'space-around'}>
            <Text fontWeight={'bold'} textTransform={'uppercase'} fontSize={'xs'} color="gray.500">
              Koala
            </Text>

            <Text fontWeight={'bold'} textTransform={'uppercase'} fontSize={'xs'} color="gray.500">
              Salesforce
            </Text>
          </HStack>

          <Box>
            <Heading size="xs">Mapped Fields</Heading>
          </Box>

          <VStack>
            {rows.map((row) => {
              return (
                <HStack key={row.key} w="100%" justifyContent={'space-between'}>
                  <Select
                    size="sm"
                    bg="white"
                    defaultValue={row.koala_field}
                    rounded="sm"
                    name={`${props.fieldPrefix}[][koala_field]`}
                  >
                    {props.koalaLayout.map((field) => (
                      <option key={field.name} value={field.name}>
                        {field.label}
                      </option>
                    ))}
                  </Select>

                  <IconChevronsRight />

                  <Select
                    size="sm"
                    bg="white"
                    defaultValue={row.salesforce_field}
                    rounded={'sm'}
                    name={`${props.fieldPrefix}[][salesforce_field]`}
                  >
                    {props.salesforceLayout.map((field) => (
                      <option key={field.name} value={field.name}>
                        {field.label} ({field.type})
                      </option>
                    ))}
                  </Select>

                  <CloseButton onClick={() => removeRow(row.key)} />
                </HStack>
              )
            })}
          </VStack>

          <HStack justify={'flex-end'} py="4">
            <Button
              colorScheme={props.colorScheme}
              onClick={addRow}
              variant={'outline'}
              leftIcon={<IconPlus size="16" />}
              size="sm"
            >
              Field
            </Button>
          </HStack>

          <Divider />

          <Box>
            <Heading size="xs">Hardcoded Fields</Heading>
          </Box>

          <VStack>
            {hardcoded.length === 0 && <input type="hidden" name={`${props.hardcodedPrefix}[]`} value="" />}

            {hardcoded.map((row) => {
              return (
                <HStack key={row.key} w="100%" justifyContent={'space-between'}>
                  <Input
                    type="text"
                    size="sm"
                    required
                    bg="white"
                    defaultValue={row.value}
                    rounded="sm"
                    name={`${props.hardcodedPrefix}[][value]`}
                  />

                  <IconChevronsRight />

                  <Select
                    size="sm"
                    bg="white"
                    defaultValue={row.salesforce_field}
                    rounded={'sm'}
                    name={`${props.hardcodedPrefix}[][salesforce_field]`}
                  >
                    {props.salesforceLayout.map((field) => (
                      <option key={field.name} value={field.name}>
                        {field.label} ({field.type})
                      </option>
                    ))}
                  </Select>

                  <CloseButton onClick={() => removeHardcodedRow(row.key)} />
                </HStack>
              )
            })}
          </VStack>

          <HStack justify={'flex-end'} py="4">
            <Button
              colorScheme={props.colorScheme}
              onClick={addHardcoded}
              variant={'outline'}
              leftIcon={<IconPlus size="16" />}
              size="sm"
            >
              Field
            </Button>
          </HStack>
        </VStack>
      </Toggle>
    </VStack>
  )
}
