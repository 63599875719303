import { Button, Circle, Divider, Flex, Heading, HStack, Link, Stack, Tag, Text, Tooltip } from '@chakra-ui/react'
import { IconCash, IconChevronRight, IconClick, IconExternalLink, IconUserPlus, IconUsers } from '@tabler/icons-react'
import React, { useState } from 'react'
import { pluralize } from '../../../../lib/pluralize'
import { Card } from '../../../ui/Card'
import { BroomIcon, BuildingIcon } from '../../../ui/icons'
import { projectPath } from '../../../ui/ProjectsContext'
import { humanize } from '../../accounts/facets/filter-cloud'
import { billingManagePath } from '../lib/path-helpers'
import { KoalaSubscription } from '../show'
import { EntitlementsV2, friendlyNumber, Plan, teamPrices, Usage } from '../v2'
import { CheckoutModal } from './checkout'
import { BillingEmail } from './billing-email'

interface Props {
  plan?: Plan | 'unlimited'
  entitlements: EntitlementsV2
  usage: Usage
  subscription: KoalaSubscription
  billing_email: string
}

export function PlanFeature(props: {
  count?: number
  label: string
  price: string
  icon?: React.ReactNode
  children?: React.ReactNode
}) {
  return (
    <Stack spacing="-1">
      <HStack w="100%" justifyContent={'space-between'}>
        <HStack>
          {props.count && <Tag size="sm">{props.count}x</Tag>}
          {props.icon}
          <Text fontWeight={'500'}>{props.label}</Text>
        </HStack>
        <Text>{props.price}</Text>
      </HStack>
      {props.children}
    </Stack>
  )
}

export function totalCost(entitlements: EntitlementsV2, usage: Usage) {
  const extraAccounts = Math.max((usage.accounts ?? 0) - (entitlements.accounts ?? 0), 0)
  const accountPacks = Math.ceil(Math.max(extraAccounts / teamPrices.account_pack.unit, 0))

  const extraMembers = Math.max((usage.members ?? 0) - entitlements.members, 0)
  const paidMembers = Math.max(extraMembers, 0)

  const extraEvents = Math.max((usage.events ?? 0) - entitlements.events, 0)
  const eventPacks = Math.ceil(Math.max(extraEvents / teamPrices.event_pack.unit, 0))

  return {
    accountPacks,
    paidMembers,
    eventPacks,
    overages: extraAccounts + extraMembers + extraEvents,
    extraAccounts,
    extraMembers,
    extraEvents,
    total:
      teamPrices.base.cost +
      accountPacks * teamPrices.account_pack.cost +
      paidMembers * teamPrices.member.cost +
      eventPacks * teamPrices.event_pack.cost
  }
}

export function PricingBreakdown(props: Props) {
  const cost = totalCost(props.entitlements, props.usage)
  const [isUpgrading, setIsUpgrading] = useState(false)

  return (
    <>
      {isUpgrading && <CheckoutModal subscription={props.subscription} onClose={() => setIsUpgrading(false)} />}

      <Stack as={Card} spacing="6">
        <HStack w="100%" justifyContent={'space-between'}>
          <HStack spacing="1">
            <Heading size="sm" fontWeight={'semibold'}>
              Current Plan:
            </Heading>
            <Text fontSize={'sm'}>{humanize(props.plan ?? 'Free')} Plan</Text>
          </HStack>
          {props.plan === 'team' && (
            <Tooltip label="Manage your plan, credit card settings and view your invoices.">
              <Button
                size="sm"
                variant="outline"
                as={Link}
                isExternal
                href={billingManagePath()}
                rightIcon={<IconExternalLink size={14} />}
              >
                Manage Plan & Credit Card
              </Button>
            </Tooltip>
          )}
        </HStack>

        <Divider />

        {props.plan === 'free' && (
          <>
            <Stack fontSize="sm" spacing="4" py="2">
              <Heading size="xs">Your Plan:</Heading>
              <Stack spacing="4">
                <PlanFeature
                  label={`Free Workspace members`}
                  price={`${props.entitlements.members} Included`}
                  icon={
                    <Circle alignSelf="center" bg="gray.100" p={1.5} flex="none">
                      <IconUsers size={14} />
                    </Circle>
                  }
                >
                  <Flex color="gray.600" fontSize={'xs'} pl="8" alignItems={'center'}>
                    Unlock more members on the
                    <Button
                      color={'purple.500'}
                      size="xs"
                      iconSpacing={'1'}
                      p="1"
                      variant={'ghost'}
                      rightIcon={<IconChevronRight size={14} />}
                      onClick={() => setIsUpgrading(true)}
                    >
                      Team Plan at ${teamPrices.member.cost} / each
                    </Button>
                  </Flex>
                </PlanFeature>
                <PlanFeature
                  label={`Koala Accounts`}
                  price={`${friendlyNumber(props.entitlements.accounts)} Included`}
                  icon={
                    <Circle alignSelf="center" bg="gray.100" p={1.5} flex="none">
                      <BuildingIcon size={14} />
                    </Circle>
                  }
                >
                  <Flex color="gray.600" fontSize={'xs'} pl="8" alignItems={'center'}>
                    Unlock {friendlyNumber(teamPrices.account_pack.included)}+ Accounts on the
                    <Button
                      color={'purple.500'}
                      size="xs"
                      iconSpacing={'1'}
                      p="1"
                      variant={'ghost'}
                      rightIcon={<IconChevronRight size={14} />}
                      onClick={() => setIsUpgrading(true)}
                    >
                      Team Plan
                    </Button>
                  </Flex>
                </PlanFeature>
                <PlanFeature
                  label={`Events`}
                  price={`${friendlyNumber(props.entitlements.events)}/mo Included`}
                  icon={
                    <Circle alignSelf="center" bg="gray.100" p={1.5} flex="none">
                      <IconClick size={14} />
                    </Circle>
                  }
                >
                  <Flex color="gray.600" fontSize={'xs'} pl="8" alignItems={'center'}>
                    Unlock {friendlyNumber(teamPrices.event_pack.included)}+ Events on the
                    <Button
                      color={'purple.500'}
                      size="xs"
                      iconSpacing={'1'}
                      p="1"
                      variant={'ghost'}
                      rightIcon={<IconChevronRight size={14} />}
                      onClick={() => setIsUpgrading(true)}
                    >
                      Team Plan
                    </Button>
                  </Flex>
                </PlanFeature>
              </Stack>
            </Stack>

            <BillingEmail email={props.billing_email} />
          </>
        )}

        {props.plan === 'team' && (
          <>
            <Stack fontSize="sm" spacing="4" py="2">
              <HStack w="100%" justifyContent={'space-between'}>
                <HStack pl="1" spacing="2">
                  <IconCash size="20" />
                  <Heading size="xs">Base Price</Heading>
                </HStack>
                <Text>$ {teamPrices.base.cost}</Text>
              </HStack>

              <Stack>
                <PlanFeature
                  label={`Workspace members`}
                  price={`${teamPrices.member.included} Included`}
                  icon={
                    <Circle alignSelf="center" bg="gray.100" p={1.5} flex="none">
                      <IconUsers size={14} />
                    </Circle>
                  }
                />
                <PlanFeature
                  label={`Accounts`}
                  price={`${friendlyNumber(teamPrices.account_pack.included)} Included`}
                  icon={
                    <Circle alignSelf="center" bg="gray.100" p={1.5} flex="none">
                      <BuildingIcon size={14} />
                    </Circle>
                  }
                />
                <PlanFeature
                  label={`Events`}
                  price={`${friendlyNumber(teamPrices.event_pack.included)} Included`}
                  icon={
                    <Circle alignSelf="center" bg="gray.100" p={1.5} flex="none">
                      <IconClick size={14} />
                    </Circle>
                  }
                />

                {cost.overages > 0 && (
                  <Stack pt="4" spacing="4">
                    <Divider />
                    <Text>Overages</Text>

                    {cost.paidMembers > 0 && (
                      <PlanFeature
                        label={`Additional Workspace members`}
                        count={cost.paidMembers}
                        price={`$ ${teamPrices.member.cost} / each`}
                      />
                    )}

                    {cost.accountPacks > 0 && (
                      <PlanFeature
                        label={`Pack of ${teamPrices.account_pack.unit} Accounts`}
                        count={cost.accountPacks}
                        price={`$ ${teamPrices.account_pack.cost} / each`}
                      />
                    )}

                    {cost.eventPacks > 0 && (
                      <PlanFeature
                        label={`Pack of ${friendlyNumber(teamPrices.event_pack.unit)} Events`}
                        count={cost.eventPacks}
                        price={`$ ${teamPrices.event_pack.cost} / each`}
                      />
                    )}
                  </Stack>
                )}
              </Stack>
            </Stack>

            <Text fontSize={'sm'}>
              <strong>You pay</strong>: ${friendlyNumber(cost.total)} monthly
            </Text>
          </>
        )}

        <Divider />

        {(props.usage.hidden_accounts > 0 || Object.values(props.subscription.goodies ?? {}).length > 0) && (
          <>
            <Stack>
              <Heading size="xs">Extras</Heading>
              <Stack fontSize={'sm'} color="gray.500">
                {props.usage.hidden_accounts > 0 && (
                  <HStack>
                    <BroomIcon size="14" />
                    <Text>
                      <strong>{friendlyNumber(props.usage.hidden_accounts)}</strong> account{' '}
                      {pluralize(props.usage.hidden_accounts, 'credit', 'credits', false)} saved using{' '}
                      <Link isExternal color="purple.500" href={projectPath('/settings/excluded-accounts')}>
                        Hidden Accounts
                      </Link>
                      .
                    </Text>
                  </HStack>
                )}

                {props.subscription.goodies?.accounts && (
                  <HStack>
                    <BuildingIcon size="14" />
                    <Text>
                      <strong>{friendlyNumber(props.subscription.goodies.accounts)}</strong> extra account{' '}
                      {pluralize(props.subscription.goodies.accounts, 'pack', 'packs', false)}{' '}
                      <strong>(250 Accounts each)</strong> saved using your referral code.
                    </Text>
                  </HStack>
                )}

                {props.subscription.goodies?.member && (
                  <HStack>
                    <IconUserPlus size="14" />
                    <Text>
                      <strong>{friendlyNumber(props.subscription.goodies.member)}</strong> extra workspace{' '}
                      {pluralize(props.subscription.goodies.member, 'member', 'members', false)} saved using your
                      referral code.
                    </Text>
                  </HStack>
                )}
              </Stack>
            </Stack>
            <Divider />
          </>
        )}

        <HStack w="100%" justifyContent={'space-between'}>
          <Text fontSize={'sm'}>
            Questions about billing?{' '}
            <Button
              variant={'link'}
              rightIcon={<IconExternalLink size="14" />}
              iconSpacing={1}
              as={Link}
              isExternal
              color="purple.500"
              href="https://getkoala.com/docs/admin-guides/pricing"
            >
              View our Pricing Guide
            </Button>
          </Text>
          <HStack>
            <Button size="sm" as={Link} href={projectPath('/settings/plans')} variant={'outline'}>
              Compare Plans
            </Button>
            <Button size="sm" colorScheme={'purple'} onClick={() => setIsUpgrading(true)}>
              Upgrade
            </Button>
          </HStack>
        </HStack>
      </Stack>
    </>
  )
}
