import { Badge, Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'
import { AdminBreadcrumb } from '../..'
import PageLayout from '../../../../ui/PageLayout'
import PageTitle from '../../../../ui/PageTitle'
import { TimeAgo } from '../../../../ui/TimeAgo'
import { JSONTree } from '../../../../ui/json-tree'

interface Props {
  data: any
}

export default function Index(props: Props) {
  return (
    <PageLayout>
      <AdminBreadcrumb paths={[{ path: '/admin/reports/integrations', title: 'Integrations' }]} />

      <PageTitle>Integrations</PageTitle>

      <Stack spacing="12">
        <TableContainer fontSize="sm" w="100%">
          <Table size="sm" w="100%" height="1px">
            <Thead>
              <Tr>
                <Th>Project</Th>
                <Th>App</Th>
                <Th>Created At</Th>
                <Th>Valid?</Th>
                <Th>Metadata</Th>
              </Tr>
            </Thead>
            <Tbody bg="white">
              {props.data.map((ai) => (
                <Tr key={ai.id}>
                  <Td>{ai.project}</Td>
                  <Td>{ai.app}</Td>
                  <Td><TimeAgo time={ai.created_at} fallback="—" /></Td>
                  <Td>
                    <Badge colorScheme={ai.valid ? 'green' : 'red'}>{ai.valid ? 'Yes' : 'No'}</Badge>
                  </Td>
                  <Td><JSONTree data={ai.meta} /></Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </PageLayout>
  )
}
