import { useQuery } from 'react-query'
import { concurrentGET } from '../../lib/api'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'
import { useCurrentUser } from '../ui/UserContext'

interface Data {
  settings: Record<string, any>
}

export function useUserSettings() {
  const project = useCurrentProject()
  const user = useCurrentUser()

  return useQuery<Data>(
    ['user-settings', { projectId: project?.id, userId: user?.id }],
    () => concurrentGET<Data>(projectPath(`/user-settings`)),
    {
      enabled: Boolean(project?.id && user?.id)
    }
  )
}
