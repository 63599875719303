import { Button, ButtonProps, Icon, Link, LinkProps } from '@chakra-ui/react'
import { IconArrowRight } from '@tabler/icons-react'
import React from 'react'

type AnchoredDrawerLinkProps = ButtonProps & LinkProps

export function AnchoredDrawerLink(props: AnchoredDrawerLinkProps) {
  return (
    <Button
      position="absolute"
      top={2}
      right={10}
      flex="none"
      size="xs"
      variant="outline"
      background="white"
      zIndex="overlay"
      as={Link}
      iconSpacing={1.5}
      rightIcon={<Icon as={IconArrowRight} boxSize={4} color="gray.500" />}
      {...props}
    />
  )
}
