import { ReconnectAppDialog } from '@app/components/pages/apps/components/ReconnectAppDialog'
import { ReconnectionRequiredWarning } from '@app/components/pages/apps/components/ReconnectionRequiredWarning'
import { getCurrentProject } from '@app/components/ui/ProjectsContext'
import { Button, Divider, Heading, HStack, Link, Stack, Text, VStack } from '@chakra-ui/react'
import { startCase } from 'lodash'
import React from 'react'
import { formatDate } from '../../../../lib/dayjs'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { LightBgCard } from '../../../ui/Card'

export interface Props {
  appTitle: string
  valid?: boolean
  allowSwitchUser?: boolean
  details: ConnectionDetail[]
}

export interface ConnectionDetail {
  label: string
  value: string
  kind: string
}

export function ConnectionDetails({ children, ...props }: React.PropsWithChildren<Props>) {
  const appId = props.appTitle.toLowerCase()
  const currentProjectSlug = getCurrentProject()?.slug
  const errorDetail = props.details?.find((detail) => detail.kind === 'error')

  return (
    <LightBgCard w="100%">
      <VStack spacing="4" w="100%" alignItems={'normal'} divider={<Divider />}>
        <HStack justifyContent={'space-between'}>
          <Heading size="sm">Connection Details</Heading>
          {!props.valid && <ReconnectAppDialog appTitle={props.appTitle} />}
        </HStack>

        <VStack>
          {!props.valid && <ReconnectionRequiredWarning appTitle={props.appTitle} errorMessage={errorDetail?.value} />}

          {props.valid && props.allowSwitchUser ? (
            <HStack
              w="100%"
              justifyContent={'space-between'}
              as="form"
              action={`/auth/${appId}?project=${currentProjectSlug}&app_id=${appId}`}
              method="post"
            >
              <AuthenticityToken />
              <Stack spacing="0.5" fontSize="sm">
                <Details {...props} />
              </Stack>
              <Button colorScheme="purple" size="sm" variant="outline" type="submit">
                Switch User
              </Button>
            </HStack>
          ) : (
            <HStack w="100%" justifyContent={'space-between'}>
              <Stack spacing="0.5" fontSize="sm">
                <Details {...props} />
              </Stack>
            </HStack>
          )}
        </VStack>

        {children}
      </VStack>
    </LightBgCard>
  )
}

function Details(props: Props) {
  return (
    <>
      {props.details
        .filter((d) => d.kind !== 'error')
        .map((detail) => (
          <HStack spacing="0.5" key={detail.label}>
            <Text fontWeight={'semibold'}>{startCase(detail.label)}:</Text>
            {detail.kind == 'external_link' ? (
              <Link variant="primary" isExternal href={detail.value}>
                {detail.value.replace(/^https:\/\//, '').replace(/\/$/, '')}
              </Link>
            ) : (
              <Text>{detail.kind == 'timestamp' ? formatDate(detail.value, 'MM/DD/YYYY HH:mm:ss') : detail.value}</Text>
            )}
          </HStack>
        ))}
    </>
  )
}
