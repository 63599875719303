import { Icon, IconProps } from '@chakra-ui/react'
import React from 'react'

interface Props extends IconProps {
  size?: string | number
}

export function SlackIcon({ size, ...props }: Props) {
  size = size ? `${size}px` : size
  return (
    <Icon viewBox="0 0 24 24" width={size} height={size} {...props}>
      <path
        d="M6.65998 14.43C6.65998 15.495 5.78998 16.365 4.72498 16.365C3.65998 16.365 2.78998 15.495 2.78998 14.43C2.78998 13.365 3.65998 12.495 4.72498 12.495H6.65998V14.43Z"
        fill="#E01E5A"
      />
      <path
        d="M7.63495 14.43C7.63495 13.365 8.50495 12.495 9.56995 12.495C10.6349 12.495 11.5049 13.365 11.5049 14.43V19.275C11.5049 20.34 10.6349 21.21 9.56995 21.21C8.50495 21.21 7.63495 20.34 7.63495 19.275V14.43Z"
        fill="#E01E5A"
      />
      <path
        d="M9.56995 6.66004C8.50495 6.66004 7.63495 5.79004 7.63495 4.72504C7.63495 3.66004 8.50495 2.79004 9.56995 2.79004C10.6349 2.79004 11.5049 3.66004 11.5049 4.72504V6.66004H9.56995Z"
        fill="#36C5F0"
      />
      <path
        d="M9.56998 7.63501C10.635 7.63501 11.505 8.50501 11.505 9.57001C11.505 10.635 10.635 11.505 9.56998 11.505H4.72498C3.65998 11.505 2.78998 10.635 2.78998 9.57001C2.78998 8.50501 3.65998 7.63501 4.72498 7.63501H9.56998Z"
        fill="#36C5F0"
      />
      <path
        d="M17.34 9.57001C17.34 8.50501 18.21 7.63501 19.275 7.63501C20.34 7.63501 21.21 8.50501 21.21 9.57001C21.21 10.635 20.34 11.505 19.275 11.505H17.34V9.57001Z"
        fill="#2EB67D"
      />
      <path
        d="M16.365 9.57004C16.365 10.635 15.495 11.505 14.43 11.505C13.365 11.505 12.495 10.635 12.495 9.57004V4.72504C12.495 3.66004 13.365 2.79004 14.43 2.79004C15.495 2.79004 16.365 3.66004 16.365 4.72504V9.57004Z"
        fill="#2EB67D"
      />
      <path
        d="M14.43 17.34C15.495 17.34 16.365 18.21 16.365 19.275C16.365 20.34 15.495 21.21 14.43 21.21C13.365 21.21 12.495 20.34 12.495 19.275V17.34H14.43Z"
        fill="#ECB22E"
      />
      <path
        d="M14.43 16.365C13.365 16.365 12.495 15.495 12.495 14.43C12.495 13.365 13.365 12.495 14.43 12.495H19.275C20.34 12.495 21.21 13.365 21.21 14.43C21.21 15.495 20.34 16.365 19.275 16.365H14.43Z"
        fill="#ECB22E"
      />
    </Icon>
  )
}
