import { Box } from '@chakra-ui/react'
import dayjs from 'dayjs'
import React from 'react'

export default function Time(props: { time?: Date | number | string }) {
  const datetime = React.useMemo(() => {
    if (!props.time) {
      return null
    }

    try {
      return dayjs.utc(props.time)
    } catch (e) {
      return null
    }
  }, [props.time])

  if (!props.time) {
    return null
  }

  const format: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' }
  const formatted = new Intl.DateTimeFormat('en-US', format).format(datetime?.toDate())
  const fullTimestamp = datetime?.local().format('ddd, MMM D, YYYY h:mm:ss A z')

  return (
    <Box
      as="time"
      css={{ fontVariantNumeric: 'tabular-nums' }}
      title={fullTimestamp}
      dateTime={datetime?.toISOString()}
    >
      {formatted}
    </Box>
  )
}
