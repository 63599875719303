import React from 'react'

import {
  Stack,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  IconButton,
  Button,
  MenuList,
  MenuButton,
  Menu,
  MenuItem,
  Spinner,
} from '@chakra-ui/react'
import { TimeAgo } from '../../../ui/TimeAgo'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { IconPlus, IconPencil, IconTrashX, IconDotsVertical } from '@tabler/icons-react'
import { AdminBreadcrumb } from '..'
import { del } from '@app/lib/api'
import { toast } from 'sonner'

interface PromptDbProps {
  id: string
  name: string
  slug: string
  created_at: string
  updated_at: string
}

interface Props {
  prompts: PromptDbProps[]
}

export default function Index(props: Props) {
  const [ prompts, setPrompts ] = React.useState<PromptDbProps[]>(props.prompts)
  const [executing, setExecuting] = React.useState<string | null>(null)

  const removePrompt = async (id: string) => {
    setExecuting(id)

    try {
      await del(`/admin/prompts-db/${id}`)
      setPrompts(prompts.filter((prompt) => prompt.id !== id))
    } catch (error) {
      toast.error('An error occurred while removing the prompt')
    } finally {
      setExecuting(null)
    }
  }

  return (
    <PageLayout size="md">
      <HStack>
        <PageTitle flex="1">Prompts DB</PageTitle>

        <Button 
          leftIcon={<IconPlus size="16" />} 
          flex="none"
          colorScheme="purple"
          onClick={() => window.location.href = '/admin/prompts-db/new'}
        >
          Add prompt
        </Button>
      </HStack>

      <AdminBreadcrumb paths={[{ path: '/admin/prompt-dbs', title: 'Prompts DB' }]} />

      <Stack spacing={8}>
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Slug</Th>
                <Th>Created at</Th>
                <Th>Updated at</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {prompts.map((prompt) => (
                <Tr key={prompt.id} _hover={{ bg: 'gray.50' }}>
                  <Td w="1px">
                    <Text fontSize="sm">{prompt.name}</Text>
                  </Td>
                  <Td w="1px">
                    <Text fontSize="sm">{prompt.slug}</Text>
                  </Td>
                  <Td w="1px">
                    <TimeAgo time={prompt.created_at} />
                  </Td>
                  <Td w="1px">
                    <TimeAgo time={prompt.updated_at} />
                  </Td>

                  <Td w="1px">
                    {executing === prompt.id ? (
                      <Spinner size="xs" color="gray.500" />
                    ) : (
                      <Menu>
                        <MenuButton
                          size="sm"
                          as={IconButton}
                          icon={<IconDotsVertical size={16} />}
                          variant="outline"
                          borderColor="gray.200"
                        />
                        <MenuList fontSize="sm">
                          <MenuItem
                            aria-label="Remove"
                            icon={<IconTrashX size="14" />}
                            onClick={() => removePrompt(prompt.id)}
                          >
                            Remove
                          </MenuItem>
                          <MenuItem
                            aria-label="Edit"
                            icon={<IconPencil size="14" />}
                            onClick={() => window.location.href = `/admin/prompts-db/${prompt.id}/edit` }
                          >
                            Edit 
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </PageLayout>
  )
}
