import { Alert, AlertDescription, AlertIcon, CloseButton, Link } from '@chakra-ui/react'
import ms from 'ms'
import React, { useEffect } from 'react'
import { useMountedState } from 'react-use'
import { projectPath } from '../../../ui/ProjectsContext'
import createPersistedState from '../../../ui/usePersistedState'

const userDismissedModal = createPersistedState<boolean>('analytics-lookback-upgrade-dismissed', {
  expiresIn: ms('1 month')
})

interface Props {
  source: string
}

export function LookbackLimitUpgradeBanner(props: Props) {
  const [dismissed, setDismissed] = userDismissedModal(false)
  const mounted = useMountedState()

  useEffect(() => {
    if (!mounted() || dismissed) return
    window.ko?.track('Upgrade Banner Viewed - Dashboards Lookback', {
      source: props.source
    })
  }, [mounted, dismissed, props.source])

  if (dismissed) {
    return null
  }

  return (
    <Alert status="info" borderRadius="md" fontSize={'sm'}>
      <AlertIcon />
      <AlertDescription lineHeight={'1.4'}>
        Want to see even further back in time?{' '}
        <Link variant="dotted" fontWeight="semibold" href={projectPath('/settings/plans')}>
          Upgrade your plan
        </Link>{' '}
        to get more data retention
      </AlertDescription>
      <CloseButton ml="auto" onClick={() => setDismissed(true)} marginLeft="auto" />
    </Alert>
  )
}
