import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Link,
  Select,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast
} from '@chakra-ui/react'
import { IconPlayerPlay } from '@tabler/icons-react'
import { flatten, uniq } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { post } from '../../../lib/api'
import router from '../../../lib/router'
import { AutoOutboundRecord, AutoOutboundRule } from '../../../types/AutoOutbound'
import { PageMeta } from '../../../types/PageMeta'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { JSONTree } from '../../ui/json-tree'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { TableFooter } from '../../ui/TableFooter'
import { TextEllipsis } from '../../ui/text-ellipsis'
import { TimeAgo } from '../../ui/TimeAgo'
import { useCurrentUser } from '../../ui/UserContext'
import { useSearchParams } from '../../ui/useSearchState'
import { HField } from '../accounts/components/Field'
import { Toggle } from '../accounts/components/Toggle'
import { mergeParams } from '../icps/types'
import { automationsPath } from '../notifications/lib/path-helper'
import { profilePath } from '../profiles/lib/path'
import { autoOutboundLedgerPath, autoOutboundPath, autoOutboundRulePath } from './lib/path-helpers'

interface Props {
  records: AutoOutboundRecord[]
  rules: Pick<AutoOutboundRule, 'id' | 'name'>[]
  page_meta: PageMeta
}

export default function Index(props: Props) {
  const [running, setRunning] = useState({})
  const [checking, setChecking] = useState({})
  const toast = useToast()
  const [records, setRecords] = useState<AutoOutboundRecord[]>(props.records)
  const user = useCurrentUser()

  const params = useSearchParams()

  useEffect(() => {
    setRecords(props.records)
  }, [props])

  const recheckRecord = useCallback(
    (record: AutoOutboundRecord) => {
      setChecking((checking) => ({
        ...checking,
        [record.id]: true
      }))

      post<{ records: AutoOutboundRecord[] }>(projectPath('/auto-outbound/check'), {
        record_ids: [record.id]
      })
        .then((res) => {
          setRecords((existing) => {
            const incoming = res.records

            return existing.map((r) => {
              const updooted = incoming.find((i) => i.id === r.id)
              if (updooted) {
                return updooted
              }

              return r
            })
          })
        })
        .catch((error) => {
          toast({
            title: 'Error',
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true
          })
        })
        .finally(() => {
          setChecking((checking) => ({
            ...checking,
            [record.id]: false
          }))
        })
    },
    [toast]
  )

  const runRecord = useCallback(
    (record: AutoOutboundRecord) => {
      setRunning((running) => ({
        ...running,
        [record.id]: true
      }))

      post<{ records: AutoOutboundRecord[] }>(projectPath('/auto-outbound/run'), {
        record_ids: [record.id]
      })
        .then((res) => {
          setRecords((existing) => {
            const incoming = res.records

            return existing.map((r) => {
              const updooted = incoming.find((i) => i.id === r.id)
              if (updooted) {
                return updooted
              }

              return r
            })
          })
        })
        .catch((error) => {
          toast({
            title: 'Error',
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true
          })
        })
        .finally(() => {
          setRunning((running) => ({
            ...running,
            [record.id]: false
          }))
        })
    },
    [toast]
  )

  return (
    <PageLayout size="full">
      <Breadcrumb
        paths={[
          { path: automationsPath('/overview'), title: 'Automations' },
          { path: autoOutboundPath(), title: 'Auto Outbound' },
          { path: autoOutboundLedgerPath({}), title: 'Ledger' }
        ]}
      />
      <Stack spacing="12">
        <PageTitle skipRendering>Auto Outbound Ledger</PageTitle>

        <HStack w="100%">
          <Flex>
            <Select
              size="xs"
              rounded="md"
              defaultValue={(params.searchParams as any)?.rule_id ?? 'all'}
              onChange={(e) => {
                if (e.target.value === 'all') {
                  router.visit(
                    mergeParams(window.location.toString(), {
                      rule_id: undefined,
                      page: '1'
                    })
                  )
                } else {
                  router.visit(
                    mergeParams(window.location.toString(), {
                      rule_id: e.target.value,
                      page: '1'
                    })
                  )
                }
              }}
            >
              <option value="all">All Rules</option>
              {props.rules.map((rule) => (
                <option key={rule.id} value={rule.id}>
                  {rule.name}
                </option>
              ))}
            </Select>
          </Flex>
        </HStack>

        <FormControl>
          <HStack>
            <Stack spacing="-1">
              <FormLabel>Status</FormLabel>
              <Select
                size="sm"
                defaultValue={(params.searchParams as any)?.executed ?? 'all'}
                onChange={(e) => {
                  if (e.target.value === 'all') {
                    router.visit(
                      mergeParams(window.location.toString(), {
                        executed: undefined,
                        page: '1'
                      })
                    )
                  } else {
                    router.visit(
                      mergeParams(window.location.toString(), {
                        executed: e.target.value,
                        page: '1'
                      })
                    )
                  }
                }}
              >
                <option value="all">All</option>
                <option value="true">Executed</option>
                <option value="false">Staged</option>
              </Select>
            </Stack>
          </HStack>
        </FormControl>

        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Profile</Th>
                <Th>Playbook</Th>
                <Th>Eligible Steps</Th>
                <Th>Status</Th>
                <Th>Context</Th>
                <Th>Executed At</Th>
                <Th>Created At</Th>
                {user.isInternalUser && <Th>[Internal] Full Context</Th>}
              </Tr>
            </Thead>
            <Tbody>
              {records.map((record) => {
                const owner = record.context?.rules?.routing?.info
                const errors = record.context?.errors

                const intent = uniq(
                  flatten(record.context?.rules?.intent?.info?.signals ?? []).map((s: any) => s.kql_definition_name)
                )

                const sequence = record.context?.rules?.outreach?.info?.destination_sequences?.routed_sequences

                return (
                  <Tr key={record.id}>
                    <Td maxW={'180'}>
                      <Stack maxW="180">
                        <Link
                          href={profilePath({
                            id: record.profile_id
                          })}
                          isExternal
                        >
                          <TextEllipsis tooltip maxW="180">
                            {record.context.email ?? record.profile_id}
                          </TextEllipsis>
                        </Link>
                        {record.eligible && (
                          <Button
                            size="xs"
                            w="100%"
                            colorScheme={'green'}
                            disabled={record.executed || checking[record.id]}
                            isLoading={running[record.id]}
                            leftIcon={<IconPlayerPlay size="12" />}
                            onClick={() => runRecord(record)}
                          >
                            Run
                          </Button>
                        )}
                        <Button
                          w="100%"
                          size="xs"
                          colorScheme={'gray'}
                          disabled={record.executed || running[record.id]}
                          isLoading={checking[record.id]}
                          leftIcon={<IconPlayerPlay size="12" />}
                          onClick={() => recheckRecord(record)}
                        >
                          Recheck
                        </Button>
                      </Stack>
                    </Td>
                    <Td>
                      {record.rule && <Link href={autoOutboundRulePath(record.rule.id)}>{record.rule.name}</Link>}
                    </Td>
                    <Td>
                      <Stack>
                        {Object.entries(record.context.rules).map(([rule, status]) => (
                          <HStack key={rule}>
                            <Toggle
                              title={
                                <HStack>
                                  <Text>Step: {rule}</Text>
                                  <Text
                                    fontSize={'xs'}
                                    fontWeight="semibold"
                                    color={status.eligible ? 'green.500' : 'red.500'}
                                  >
                                    {status.eligible ? 'Yes' : 'Not Eligible'}
                                  </Text>
                                </HStack>
                              }
                            >
                              <Stack ml="1" pl="4" borderLeftWidth={'medium'} bg="gray.50" py="2" pr="2">
                                {status.eligible && <Text>All conditions have been met</Text>}

                                {rule === 'cadence' && status.eligible && (
                                  <Text>
                                    This visitor has not been contacted in the last 30 days. It is eligible for
                                    Outbounding.
                                  </Text>
                                )}

                                {rule === 'cadence' && !status.eligible && (
                                  <Text>
                                    This visitor has been contacted in the last 30 days. It is not eligible for
                                    Outbounding.
                                  </Text>
                                )}

                                {rule === 'crm' && !status.eligible && (
                                  <Text>One or more of the following conditions have not been met:</Text>
                                )}

                                {rule === 'intent' && !status.eligible && (
                                  <Text>No intent signals matching this rule were found.</Text>
                                )}

                                {record.context.rules[rule].info &&
                                  (Array.isArray(record.context.rules[rule].info) ||
                                    typeof record.context.rules[rule].info === 'object') && (
                                    <Box bg="white" p="2" rounded="md" px="4">
                                      <JSONTree
                                        data={Object.entries(record.context.rules[rule].info)
                                          .map(([key, value]) => {
                                            let newValue: any = value

                                            if (Array.isArray(value)) {
                                              newValue = {
                                                eligible: value[0] ?? false,
                                                ...value[1]
                                              }
                                            }

                                            return {
                                              [key]: newValue
                                            }
                                          })
                                          .reduce((acc, cur) => ({ ...acc, ...cur }), {})}
                                      />
                                    </Box>
                                  )}
                              </Stack>
                            </Toggle>
                          </HStack>
                        ))}
                      </Stack>
                    </Td>
                    <Td minW="30">
                      <HStack>
                        <Tag
                          size="sm"
                          colorScheme={record.executed ? 'blue' : record.status === 'failed' ? 'red' : 'gray'}
                        >
                          <Text>{record.status}</Text>
                        </Tag>
                        <Spinner
                          size="sm"
                          visibility={running[record.id] || checking[record.id] ? 'visible' : 'hidden'}
                        />
                      </HStack>
                    </Td>
                    <Td>
                      <Stack fontSize={'sm'} spacing="2">
                        {typeof owner === 'string' && (
                          <HField label="Sender:" spacing={'1'} value={JSON.stringify(owner)} />
                        )}
                        {intent.length > 0 && (
                          <HField spacing="1" label="Intent:" value={intent.map((i) => i).join(', ')} />
                        )}
                        {sequence && <HField spacing={'1'} label="Sequence:" value={JSON.stringify(sequence)} />}
                        {errors && errors.length > 0 && (
                          <HField
                            spacing={'1'}
                            label="Errors:"
                            value={errors.map((e) => e).join(', ')}
                            color="red.500"
                          />
                        )}
                      </Stack>
                    </Td>
                    <Td>{record.executed_at}</Td>
                    <Td>
                      <TimeAgo time={record.created_at} />
                    </Td>
                    {user.isInternalUser && (
                      <Td>
                        <JSONTree data={{ context: record.context }} />
                      </Td>
                    )}
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
          <TableFooter
            pageMeta={props.page_meta}
            page={props.page_meta.current_page}
            nextPath={mergeParams(window.location.toString(), {
              page: (props.page_meta.current_page + 1).toString()
            })}
            prevPath={mergeParams(window.location.toString(), {
              page: (props.page_meta.current_page - 1).toString()
            })}
          />
        </TableContainer>
      </Stack>
    </PageLayout>
  )
}
