import { Code, Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'
import { AdminBreadcrumb } from '../..'
import PageLayout from '../../../../ui/PageLayout'
import PageTitle from '../../../../ui/PageTitle'
import { JSONTree } from '../../../../ui/json-tree'

interface Activity {
  id: string
  created_at: string
  event_name: string
  project: {
    slug: string
  }
  resource: any
  resource_type: string
  user: {
    email: string
  }
  metadata: any
}

interface Props {
  activities: Activity[]
}

export default function Index(props: Props) {
  return (
    <PageLayout>
      <AdminBreadcrumb paths={[{ path: '/admin/reports/activities', title: 'Workspace Activities' }]} />

      <PageTitle>Workspace Activities</PageTitle>

      <Stack spacing="12">
        <TableContainer fontSize="sm" w="100%">
          <Table size="sm" w="100%" height="1px">
            <Thead>
              <Tr>
                <Th>Created At</Th>
                <Th>Workspace</Th>
                <Th>Event</Th>
                <Th>Other</Th>
              </Tr>
            </Thead>
            <Tbody bg="white">
              {props.activities.map((activity) => (
                <Tr key={activity.id}>
                  <Td> {activity.created_at} </Td>
                  <Td>
                    <Code>{activity.project?.slug || 'MISSING WORKSPACE'}</Code>
                  </Td>
                  <Td>
                    <Code colorScheme="green">{activity.user?.email || 'MISSING USER'}</Code> triggered{' '}
                    <Code colorScheme="yellow">{activity.event_name}</Code>
                  </Td>
                  <Td>
                    <JSONTree
                      data={{
                        resource_type: activity.resource_type,
                        resource: activity.resource,
                        metadata: activity.metadata
                      }}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </PageLayout>
  )
}
