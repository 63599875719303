import { HStack, Text, Th } from '@chakra-ui/react'
import { IconChevronDown } from '@tabler/icons-react'
import React from 'react'
import { FacetParams } from '../../../data/use-facets'
import { UrlFilterParams } from '../../../data/use-url-filters'
import { TimeAgo } from '../../../ui/TimeAgo'
import { KQLFeedEntry } from './profile-feed'

interface IntentProps {
  intentType: 'KQL' | 'PageView' | 'Last Visit'
  sortedKQLs: KQLFeedEntry[]
  facetParams: FacetParams | UrlFilterParams
}

export function KQLCell(props: IntentProps) {
  const sortedKQLs = props.sortedKQLs

  if (sortedKQLs.length === 0) {
    return null
  }

  return (
    <HStack>
      <Text fontSize={'xs'} fontWeight="semibold">
        <TimeAgo time={sortedKQLs[0]?.last_triggered_at?.toString()} />
      </Text>
      <Text fontSize="xs">({sortedKQLs[0]?.name})</Text>
    </HStack>
  )
}

interface HeaderProps {
  intentType: 'KQL' | 'PageView' | 'Last Visit'
  onSortChange?: (sortParam: string) => void
  sortingBy?: string
}

const SortIcon = () => <IconChevronDown size="14" />

export function KQLCellHeader(props: HeaderProps) {
  const isFilterinByKQL = props.intentType === 'KQL'

  return (
    <Th cursor={props.onSortChange ? 'pointer' : undefined} onClick={() => props.onSortChange?.('last_seen')}>
      {isFilterinByKQL && <Text>Latest Intent Signal</Text>}
      {!isFilterinByKQL && (
        <HStack justifyContent={'flex-start'}>
          <Text>Last Visit</Text>
          {props.sortingBy === 'last_seen' && <SortIcon />}
        </HStack>
      )}
    </Th>
  )
}
