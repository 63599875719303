import { Stack } from '@chakra-ui/react'
import React from 'react'
import { AccountView } from '../../../types/AccountView'
import { Apps } from '../../../types/App'
import { PageMeta } from '../../../types/PageMeta'
import { useUrlFilters } from '../../data/use-url-filters'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { TopBarContent } from '../../ui/TopBarContext'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { ListContainer } from '../lists/components/ListContainer'
import { Session } from '../sessions'
import { AccountFeed } from '../sessions/components/AccountFeed'
import { VisitorFeed } from '../sessions/components/Feed'
import ViewDetails from './components/ViewDetails'
import { accountViewPath } from './lib/list-paths'
import { MCHeader } from '../mission_control/Header'

interface Props {
  apps: Apps
  account_view: AccountView
  materialized_sessions: Session[]
  page_meta: PageMeta
  permissions: Record<'can_edit' | 'can_create' | 'can_destroy', boolean>
}

export default function AccountViewFeed(props: Props) {
  const onMissionControl = window.location.pathname.includes('/mission-control')

  const facets = useUrlFilters({
    initialRange: props.account_view.filters.range ?? 'month',
    initialFacets: props.account_view.filters.facets,
    initialFocusTime: props.account_view.filters.focus_time,
    facetCloudPath: props.account_view.kind === 'profile' ? '/profiles/facet-cloud' : '/accounts/facet-cloud'
  })

  return (
    <ListContainer bg="gray.50" minHeight="100%">
      <PageTitle skipRendering>{props.account_view.name}</PageTitle>

      <Stack spacing={6}>
        {onMissionControl ? (
          <MCHeader space={props.account_view.space} viewId={props.account_view.id} apps={props.apps} />
        ) : (
          <TopBarContent>
            <Breadcrumb
              paths={[
                {
                  path: projectPath(props.account_view.kind === 'profile' ? '/people' : '/accounts'),
                  title: props.account_view.kind === 'profile' ? 'People' : 'Accounts'
                },
                { path: accountViewPath(props.account_view), title: props.account_view.name },
                { path: window.location.toString(), title: 'Feed' }
              ]}
              paddingTop={1}
            />
          </TopBarContent>
        )}
        <Stack spacing={3} alignItems="stretch">
          <form
            action={accountViewPath(props.account_view) + location.search}
            method="POST"
            onSubmit={(e) => {
              if (!props.permissions.can_edit) {
                e.preventDefault()
              }
            }}
          >
            <input type="hidden" value="PATCH" name="_method" />
            <AuthenticityToken />

            <ViewDetails
              apps={props.apps}
              account_view={props.account_view}
              permissions={props.permissions}
              facets={facets}
              defaults={{
                range: 'month',
                sort_by: undefined
              }}
            />
          </form>

          {props.account_view.kind === 'profile' && <VisitorFeed {...props} />}
          {props.account_view.kind === 'account' && <AccountFeed {...props} />}
        </Stack>
      </Stack>
    </ListContainer>
  )
}
