import {
  Box,
  BreadcrumbItem,
  BreadcrumbLink,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconExternalLink, IconUser } from '@tabler/icons-react'
import pluralize from 'pluralize'
import React from 'react'
import { Account, CRMMatch } from '../../../types/Account'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { Iconify } from '../../ui/Iconify'
import { BuildingIcon } from '../../ui/icons'
import PageLayout from '../../ui/PageLayout'
import { TimeAgo } from '../../ui/TimeAgo'
import { isLimitedAccount, RedactedAccountCell, RedactedText, useEntitlements } from '../../ui/useEntitlements'
import useFuzzyCrmMatches from '../../ui/useFuzzyCrmMatches'
import { VirtualList } from '../../ui/VirtualList'
import { AccountMatchMetadata } from '../accounts/components/AccountMatchMetadata'
import { Toggle } from '../accounts/components/Toggle'
import { CrmMatchSummary } from '../account_views/components/CrmMatchSummary'
import { channelLogos } from '../follow_rules/components/delivery-setup'
import { AccountList } from '../icps/icp/account-list'
import { HighlightedAccount } from '../icps/types'
import { HighlightedProfile, ProfileList } from '../profiles/components/profile-list'
import { SessionActor } from '../sessions/components/MaterializedSession'
import { hasMatches, NotificationDebug, NotificationMatches } from './components/notification'
import { Notification } from './index'
import { automationPath, notificationsPath, slackAlertPath } from './lib/path-helper'

export interface NotificationCardProps {
  notification: Notification
  account?: HighlightedAccount
  profile?: HighlightedProfile
}

export function NotificationCard({ notification, account, profile }: NotificationCardProps) {
  const entitlements = useEntitlements()
  const matches = useFuzzyCrmMatches({ account: account as Account, fuzzy: true })
  const seenElement = {
    last_seen_at: account?.last_seen_at ?? profile?.last_seen_at,
    limited: account?.limited ?? profile?.limited
  }
  const isRedacted = isLimitedAccount(entitlements, seenElement)

  const hasIPAssociation =
    account && profile?.account_association?.match_type && profile.account_association.match_type !== 'email'

  const accountColumns = ['FitScore', 'IntentScore', 'Trend', 'Visitors', 'LastVisit']

  return (
    <>
      <RedactedAccountCell flexProps={{ gap: 2 }} element={seenElement} entitlements={entitlements}>
        <HStack justifyContent={'space-between'}>
          <Flex>{notification.session && <SessionActor {...notification.session} compact hideTimestamps />}</Flex>

          {!hasIPAssociation && account && notification.session?.fuzzy_company_match && (
            <Flex
              bg="white"
              rounded="md"
              _hover={{
                bg: 'gray.50'
              }}
            >
              <AccountMatchMetadata isIpMatch type="session" account={account} />{' '}
            </Flex>
          )}

          {hasIPAssociation && (
            <Flex
              bg="white"
              rounded="md"
              _hover={{
                bg: 'gray.50'
              }}
            >
              <AccountMatchMetadata
                hasEmail={!!profile.email}
                association={profile.account_association}
                account={account}
              />
            </Flex>
          )}
        </HStack>
      </RedactedAccountCell>

      <Divider />

      <Stack w="100%" alignItems="flex-start">
        {hasMatches(notification) && (
          <Stack pb="8" flex="1" w="100%">
            <Heading size="sm" fontWeight={'semibold'}>
              Activity Highlight
            </Heading>
            <RedactedText redacted={isRedacted}>
              <NotificationMatches {...notification} />
            </RedactedText>
          </Stack>
        )}
      </Stack>

      {account && (
        <Stack spacing="6" pb="8">
          <HStack>
            <Icon as={BuildingIcon} />
            <Heading size="sm" fontWeight={'semibold'}>
              Account Details
            </Heading>
          </HStack>

          <AccountList
            sticky={false}
            showActions={false}
            accounts={[account]}
            columns={accountColumns}
            range={'month'}
          />
        </Stack>
      )}

      {matches && (
        <>
          <Stack pb="6">
            <Heading size="xs">CRM Account Matches</Heading>
            {matches.isLoading && <Spinner />}
            {!matches.isLoading && matches.allAccounts.length === 0 && (
              <Text fontSize="xs" color="gray.500">
                No CRM matches found.
              </Text>
            )}
            {matches.strongMatches.length > 0 && (
              <Stack>
                {matches.strongMatches.map((match) => (
                  <Box
                    key={match.crm_entity_id}
                    bg={match.crm?.title?.includes('Salesforce') ? 'blue.50' : 'orange.50'}
                    p="4"
                    rounded="sm"
                  >
                    <CrmMatchSummary
                      name={match.crm_entity.name}
                      domain={match.crm_entity.domain}
                      owner={match.crm_entity.account_owner}
                      accountType={match.crm_entity.account_type}
                      permalink={match.crm_entity.permalink}
                      crm={match.crm}
                      matchType={match.match_type}
                      showCrm
                    />
                  </Box>
                ))}
              </Stack>
            )}

            {/* only show fuzzy matches if there are no strong matches! */}
            {matches.strongMatches.length === 0 && matches.fuzzyMatches.length > 0 && (
              <Stack w="100%">
                <Toggle
                  title={
                    <Stack spacing={'0'} pt="4">
                      <Text fontSize="sm" fontWeight="medium">
                        {matches.fuzzyMatches.length} other potential {pluralize('match', matches.fuzzyMatches.length)}
                      </Text>
                      <Text fontSize={'xs'} color="gray.600">
                        We found other "fuzzy" matches from your CRM based on the Account's name.
                      </Text>
                    </Stack>
                  }
                >
                  <VirtualList
                    maxH={'300px'}
                    items={matches.fuzzyMatches}
                    estimateSize={() => 80}
                    renderItem={(crmAccount: CRMMatch) => {
                      return (
                        <Stack
                          p="4"
                          bg={'gray.50'}
                          w="100%"
                          _hover={{
                            bg: 'gray.100'
                          }}
                        >
                          <CrmMatchSummary
                            name={crmAccount.crm_entity.name}
                            domain={crmAccount.crm_entity.domain}
                            owner={crmAccount.crm_entity.account_owner}
                            accountType={crmAccount.crm_entity.account_type}
                            permalink={crmAccount.crm_entity.permalink}
                            crm={crmAccount.crm}
                            matchType={crmAccount.match_type}
                            showCrm
                          />
                        </Stack>
                      )
                    }}
                  />
                </Toggle>
              </Stack>
            )}
          </Stack>
        </>
      )}

      {profile && (
        <Stack spacing="6">
          <HStack>
            <Icon as={IconUser} />
            <Heading size="sm" fontWeight={'semibold'}>
              Visitor Details
            </Heading>
          </HStack>
          <ProfileList
            sticky={false}
            columns={['SessionTime', 'Activity', 'LastVisit']}
            profiles={[profile]}
            range={'month'}
          />
        </Stack>
      )}

      <NotificationDebug {...notification} />
    </>
  )
}

export default function Show(props: NotificationCardProps) {
  const { notification } = props
  const alert = notification.follow_rule?.managed_by
  const signal = notification.follow_rule?.kql_definition?.name

  const destinations = Object.keys(notification.context?.delivery_rules || {}).filter((key) => {
    return notification.context?.delivery_rules?.[key]?.delivered === true
  })

  return (
    <PageLayout size="full" flush bg="gray.50" gap={0}>
      <Box paddingX={[4, 4, 6, 8]} paddingY={4}>
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink href={notificationsPath(`?owner_filter=workspace`)}>Notifications</BreadcrumbLink>
          </BreadcrumbItem>

          {alert && (
            <BreadcrumbItem>
              <BreadcrumbLink href={slackAlertPath(alert.id)}>
                #{alert.slack_channel_name || alert.slack_channel_id}
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}

          <BreadcrumbItem>
            <BreadcrumbLink
              href={notificationsPath(`?owner_filter=workspace&rule_filter=${notification.follow_rule.id}`)}
            >
              {signal || notification.follow_rule.name}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage isTruncated>
            <HStack alignItems={'center'}>
              <Text>{notification.id.slice(0, 10)}</Text>
              <Text>
                (<TimeAgo time={notification.triggered_at} />)
              </Text>
            </HStack>
          </BreadcrumbItem>
        </Breadcrumb>
      </Box>

      <Flex
        w="100%"
        maxW="container.md"
        margin={'0 auto'}
        p={{
          sm: '4',
          md: '8'
        }}
        marginBottom={10}
        h="100%"
        flex="1"
        direction={'column'}
        gap="2"
      >
        <Flex fontSize="xs" gap={2}>
          {destinations.length > 0 && (
            <HStack spacing={1}>
              {destinations.map((destination) => (
                <Iconify key={destination} icon={channelLogos[destination]} size={15} />
              ))}
            </HStack>
          )}

          <HStack spacing={1}>
            {notification.follow_rule.managed_by_id ? (
              <Link fontWeight="normal" isExternal href={slackAlertPath(notification.follow_rule.managed_by_id)}>
                Slack Alert to #
                {notification.context?.delivery_rules?.slack?.channel_name ||
                  notification.context?.delivery_rules?.slack?.channel_id ||
                  notification.follow_rule.slack_channel_name}
              </Link>
            ) : (
              <Link fontWeight="normal" isExternal href={automationPath(notification.follow_rule.id)}>
                {notification.follow_rule.name}
              </Link>
            )}
            <IconExternalLink size="12" />
          </HStack>
          <Text marginLeft="auto">
            <TimeAgo time={notification.triggered_at} />
          </Text>
        </Flex>

        <Stack
          spacing={5}
          bg="white"
          borderWidth="thin"
          rounded="lg"
          shadow="sm"
          px={5}
          py={4}
          w="100%"
          h="fit-content"
        >
          <NotificationCard {...props} />
        </Stack>
      </Flex>
    </PageLayout>
  )
}
