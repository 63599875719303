import { OmnisearchProject } from '../../../data/use-omnisearch'
import { Box, HStack, Icon, Text } from '@chakra-ui/react'
import { IconCircleDashed, IconLineDashed } from '@tabler/icons-react'
import { Project } from '@app/types/Project'
import CompanyAvatar from '@app/components/ui/CompanyAvatar'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import React from 'react'

interface ProjectSelectorProps {
  projects: Project[]
  selectedProject: Project | null
  onChange: (project: Project | null) => void
}

interface ProjectRendererProps {
  item: Project | null
  isSelected?: boolean
}

const none: OmnisearchProject = {
  id: '',
  domain: 'No one',
  name: 'No one',
  slug: 'no-one'
}

export default function ProjectSelector(props: ProjectSelectorProps) {
  const [inputValue, setInputValue] = React.useState<string | undefined>('koala')

  const projects = React.useMemo(() => {
    if (!inputValue) return props.projects
    return props.projects.filter(
      (project) =>
        project.name?.toLowerCase().includes(inputValue) || project.domain?.toLowerCase().includes(inputValue)
    )
  }, [inputValue, props.projects])

  return (
    <ComboboxWithSearch
      items={projects}
      selectedItem={props.selectedProject || null}
      onChange={props.onChange}
      onInputValueChange={setInputValue}
      filterItem={(a, val) => a.name?.toLowerCase().includes(val) || a.domain?.toLowerCase().includes(val) || false}
      itemToString={(item) => item?.name ?? item?.domain ?? ''}
      itemRenderer={ProjectRenderer}
      selectButtonRenderer={ProjectRenderer}
    />
  )
}

function ProjectRenderer(props: ProjectRendererProps) {
  const project = props.item

  if (!project) {
    return (
      <HStack spacing={2.5}>
        <Icon as={IconCircleDashed} color="gray.300" boxSize={6} />
        <Text fontSize="sm" fontWeight="medium" color="gray.400">
          Select a project
        </Text>
      </HStack>
    )
  }

  return (
    <HStack spacing={2.5}>
      <CompanyAvatar
        size="xs"
        domain={project.domain}
        rounded="full"
        {...(project === none
          ? {
              backgroundColor: 'transparent',
              fallbackIcon: <IconLineDashed size={20} />
            }
          : {
              name: project.name || project.domain
            })}
      />
      <Box>
        <Text fontSize="sm" fontWeight="medium">
          {project.name || project.domain || 'Anonymous'}
        </Text>
      </Box>
    </HStack>
  )
}
