import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { IconCoin, IconDotsVertical, IconEdit, IconPlus, IconTrash, IconUsers } from '@tabler/icons-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import router from '../../../../lib/router'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import { BuildingIcon, SlackIcon } from '../../../ui/icons'
import { TopBarContent } from '../../../ui/TopBarContext'
import { SmallPageHeading } from '../../../ui/PageTitle'
import SquareIcon from '../../../ui/SquareIcon'
import { PartialAccountView } from '../../../ui/ViewSelector'
import { projectPath } from '../../../ui/ProjectsContext'
import { NewListModal } from '../../account_views/components/NewListModal'
import { AccountView } from '../../../../types/AccountView'
import { Space } from '../../../../types/Space'
import { SlackAlertSidesheet } from '../../spaces/components/SlackAlertSidesheet'
import truncate from 'lodash/truncate'
import { useDeleteView } from '../../../data/use-delete-view'
import { DeleteConfirmation } from '../../../ui/DeleteConfirmation'
import { SpaceView, useSpaceViews } from '../../../data/use-space-views'
import { useCurrentUser } from '../../../ui/UserContext'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { EditSpaceModal } from './EditSpaceModal'
import { SpaceMembers } from './Members'
import { ShareSpace } from './ShareSpace'
import { usePermission } from '../../../ui/PermissionsContext'
import { ViewsTabList } from './ViewsTabList'
import { Apps } from '@app/types/App'

const emptyArray: any[] = []

interface SpaceHeaderProps {
  space: Space
  viewId?: string | null
  apps?: Apps
}

export function SpaceHeader(props: SpaceHeaderProps) {
  const { data, refetch } = useSpaceViews({ spaceId: props.space.id, views: props.space.account_views })
  const slack = useDisclosure()
  const deletion = useDisclosure()
  const editSpaceModal = useDisclosure()
  const newListModal = useDisclosure()
  const user = useCurrentUser()
  const [members, setMembers] = useState(props.space.members || emptyArray)
  const { hasPermission: canManageAccounts } = usePermission({ on: 'project', action: 'can_manage_accounts' })

  useEffect(() => {
    setMembers(props.space.members || emptyArray)
  }, [props.space.members])

  const views = data?.views || emptyArray
  const isMember = members?.some((m) => m.id === user.id) || false

  useEffect(() => {
    const handleListRemoved = async () => {
      try {
        const res = await refetch()
        // current view can't be found
        if (!res.data?.views.find((u) => u.id === props.viewId)) {
          router.visit(projectPath(`/spaces/${props.space.id}`) + window.location.search)
        }
      } catch (error) {
        console.error(error)
      }
    }

    window.addEventListener('listremoved', handleListRemoved)

    return () => {
      window.removeEventListener('listremoved', handleListRemoved)
    }
  }, [refetch, props.viewId, props.space.id])

  const newPath = window.location.pathname.split('/views')[0] + '/views/new'

  return (
    <TopBarContent>
      <Flex width="100%" alignSelf="stretch" alignItems="stretch" gap={3} justifyContent="space-between">
        <Flex flex="1 1 auto" alignItems="center" gap={3}>
          <SmallPageHeading isTruncated>{props.space.name}</SmallPageHeading>

          <Divider orientation="vertical" height="24px" />

          {props.space?.id && (
            <ViewsTabList
              spaceId={props.space.id}
              viewId={props.viewId}
              views={views}
              onAddNewList={newListModal.onOpen}
            />
          )}
        </Flex>

        <Flex alignItems="center" gap={2}>
          <SpaceMembers members={members} />

          {/* can't join personal spaces */}
          {!props.space.owner_id && (
            <>
              {isMember || canManageAccounts ? (
                <ShareSpace space={props.space} updateMembers={setMembers} />
              ) : (
                <form method="post" action={projectPath(`/spaces/${props.space.id}/members`)}>
                  <AuthenticityToken />
                  <Button type="submit" size="sm" colorScheme="purple">
                    Join space
                  </Button>
                </form>
              )}
            </>
          )}

          <Button
            variant="outline"
            title={props.space.slack_alert?.slack_channel_name || undefined}
            size="sm"
            onClick={slack.onOpen}
            leftIcon={<SlackIcon boxSize={4} />}
            iconSpacing={1}
          >
            {props.space.slack_alert?.slack_channel_name
              ? `#${truncate(props.space.slack_alert?.slack_channel_name, { length: 22 })}`
              : 'Setup Alerts'}
          </Button>

          <Menu>
            <MenuButton
              size="xs"
              as={IconButton}
              icon={<IconDotsVertical size={15} />}
              variant="ghost"
              borderColor="gray.200"
            />
            <MenuList fontSize="sm" zIndex="popover">
              <MenuItem icon={<IconEdit size={16} />} onClick={editSpaceModal.onOpen}>
                Edit name
              </MenuItem>
              {isMember && (
                <form method="post" action={projectPath(`/spaces/${props.space.id}/leave`)}>
                  <AuthenticityToken />
                  <input type="hidden" name="_method" value="delete" />
                  <MenuItem as="button" type="submit" icon={<Text fontSize="md">👋</Text>}>
                    Leave Space
                  </MenuItem>
                </form>
              )}
              <MenuItem icon={<IconTrash size={16} />} color="red.500" onClick={deletion.onOpen}>
                Delete Space…
              </MenuItem>
            </MenuList>
          </Menu>

          <EditSpaceModal space={props.space} {...editSpaceModal} />

          <DeleteConfirmation
            isOpen={deletion.isOpen}
            onClose={deletion.onClose}
            title={`Are you sure you want to delete this space?`}
            confirmLabel="Delete space"
            deletePath={projectPath(`/spaces/${props.space.id}`)}
          >
            <Text fontSize="sm" color="gray.600">
              Your teammates will no longer be use the lists in this space.
            </Text>
          </DeleteConfirmation>

          <SlackAlertSidesheet space={props.space} apps={props.apps} {...slack} />

          <NewListModal kind="account" newPath={newPath} spaceId={props.space.id} ownership="space" {...newListModal} />
        </Flex>
      </Flex>
    </TopBarContent>
  )
}

interface ViewSwitcherProps {
  views: SpaceView[]
  isLoading?: boolean
  spaceId: string
  selectedAccountViewId?: string | null
}

function _ViewSwitcher({ views, isLoading, spaceId, selectedAccountViewId }: ViewSwitcherProps) {
  const newListModal = useDisclosure()

  const { mutateAsync: deleteView, isLoading: deleting } = useDeleteView()
  const [currentId, setCurrentId] = React.useState<string | null>(null)
  const deletion = useDisclosure()

  const onDeleteRequest = deletion.onOpen

  const handleDelete = useCallback(
    (item: SpaceView) => {
      setCurrentId(item.id)
      onDeleteRequest()
    },
    [onDeleteRequest]
  )

  const wrappedItemRenderer = useCallback(
    (props) => <AccountViewRenderer {...props} onDelete={handleDelete} />,
    [handleDelete]
  )

  const selected = useMemo(() => {
    return views.find((u) => u.id === selectedAccountViewId) || null
  }, [views, selectedAccountViewId])

  const changeView = useCallback(
    (accountView: PartialAccountView | null) => {
      let path
      if (accountView?.slug) {
        path = projectPath(`/spaces/${spaceId}/views/${accountView.slug}`)
      }
      if (path) {
        router.visit(path)
      }
    },
    [spaceId]
  )

  return (
    <Box>
      <ComboboxWithSearch
        items={views}
        selectedItem={selected || null}
        onChange={changeView}
        filterItem={(a, val) => a.name?.toLowerCase().includes(val) || a.slug?.toLowerCase().includes(val) || false}
        itemToString={(item) => item?.name || item?.slug || 'Unknown'}
        isLoading={isLoading}
        itemRenderer={wrappedItemRenderer}
        selectButtonRenderer={AccountViewRenderer}
        triggerProps={{
          py: 1,
          px: 1.5,
          border: 'none',
          gap: 1,
          maxW: '250px',
          _hover: { bg: 'gray.100' }
        }}
        popoverProps={{
          maxW: '280px'
        }}
        popperOptions={{
          matchWidth: false,
          placement: 'bottom-start'
        }}
        footer={(combobox) => (
          <Box>
            <Divider />

            <Box padding={1}>
              <Flex
                as="button"
                alignItems="center"
                gap={1.5}
                fontSize="sm"
                fontWeight="medium"
                rounded="md"
                outline="none"
                width="100%"
                userSelect="none"
                color="gray.700"
                paddingX={2}
                paddingY={1.5}
                _focus={{ bg: 'gray.100' }}
                _hover={{ bg: 'gray.100' }}
                _disabled={{
                  opacity: 0.6,
                  cursor: 'not-allowed'
                }}
                onClick={() => {
                  combobox.closeMenu()
                  newListModal.onOpen()
                }}
                disabled={views.length >= 5}
              >
                <Icon as={IconPlus} boxSize={3.5} />
                Create new list
              </Flex>
            </Box>
          </Box>
        )}
      />

      <NewListModal kind="account" spaceId={spaceId} {...newListModal} />

      <DeleteConfirmation
        isCentered
        isOpen={deletion.isOpen && !!currentId}
        title={`Are you sure?`}
        confirmLabel="Delete list"
        onClose={() => {
          deletion.onClose()
          setCurrentId(null)
        }}
        onConfirm={(event) => {
          event.preventDefault()

          if (!deleting && currentId) {
            deleteView({ viewId: currentId })
          }
        }}
      >
        <Text fontSize="sm" color="gray.600">
          Are you sure you want to delete this list?
        </Text>
      </DeleteConfirmation>
    </Box>
  )
}

interface AccountViewRendererProps {
  item: SpaceView | null
  selectedItem?: SpaceView | null
  isToggleButton?: boolean
  isLoading?: boolean
  onDelete?: (item: SpaceView) => void
}

const colors = {
  account: 'purple',
  profile: 'blue',
  opportunity: 'orange'
}

const icons = {
  account: BuildingIcon,
  profile: IconUsers,
  opportunity: IconCoin
}

function AccountViewRenderer({ item, isToggleButton, isLoading, onDelete }: AccountViewRendererProps) {
  if (isLoading) {
    return <Spinner size="sm" thickness="1.5px" color="gray.400" />
  }

  if (!item) {
    return null
  }

  return (
    <AccountViewRow
      item={item}
      rightContent={!isToggleButton && <OptionsMenu item={item} onDelete={() => onDelete?.(item)} />}
    />
  )
}

function AccountViewRow({ item, rightContent }: { item: SpaceView | AccountView; rightContent?: React.ReactNode }) {
  const name = item.name || item.slug || 'Unknown'

  return (
    <Flex width="100%" alignItems="center" gap={1.5} isTruncated>
      <HStack flex="1 1 auto" spacing={1.5} title={name} isTruncated>
        <SquareIcon
          colorScheme={colors[item.kind] || 'gray'}
          icon={icons[item.kind] || BuildingIcon}
          iconSize="14px"
          padding="2px"
        />
        <Text flex="1 1 auto" fontSize="sm" fontWeight="medium" isTruncated>
          {name}
        </Text>
      </HStack>
      {rightContent}
    </Flex>
  )
}

function OptionsMenu(props: { item: SpaceView; onDelete: () => void }) {
  return (
    <Menu placement="right-start">
      <MenuButton
        size="tiny"
        as={IconButton}
        icon={<IconDotsVertical size={15} />}
        variant="ghost"
        borderColor="gray.200"
        onClick={(e) => {
          e.stopPropagation()
        }}
      />
      <MenuList fontSize="sm" zIndex="popover">
        <MenuItem
          icon={<IconTrash size={16} />}
          color="red.500"
          onClick={(e) => {
            e.stopPropagation()
            props.onDelete()
          }}
        >
          Delete list
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
