import { useQuery } from 'react-query'
import { concurrentGET } from '../../lib/api'
import { projectPath, getCurrentProject } from '../ui/ProjectsContext'

interface EventDetailsProps {
  event: Record<string, any>,
  signal_events?: Record<string, any>[]
}

export function useEventDetails<EventDetailsProps>(eventType: string, eventId: string, enabled: boolean) {
  const project = getCurrentProject()
  const path = projectPath(`/events/details/${eventType}/${eventId}`)

  return useQuery<EventDetailsProps>(['event-details', { projectId: project?.id, eventId: eventId, eventType: eventType }], () => concurrentGET<any>(path), {
    enabled: enabled
  })
}
