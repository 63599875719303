import { Box, Divider, HStack, Img, Link, Spinner, Stack, Text, useBoolean } from '@chakra-ui/react'
import { IconNews } from '@tabler/icons-react'
import React, { useEffect } from 'react'
import { concurrentCachedGET } from '../../../../../lib/api'
import { Company } from '../../../../../types/Profile'
import { DetailsCard } from '../../../../ui/Card'
import { CardHeading } from '../../../../ui/CardHeading'
import { accountPath } from '../../lib/account-path'

interface NewsItem {
  title: string
  summary: string
  source: string
  link: string
}

export function NewsCard(props: { company: Company }) {
  const company = props.company
  const [news, setNews] = React.useState<NewsItem[]>([])
  const [loading, toggle] = useBoolean()

  useEffect(() => {
    toggle.on()
    concurrentCachedGET<{ news: NewsItem[] }>(accountPath(company, '/actions/news')).then((data) => {
      setNews(data.news)
      toggle.off()
    })
  }, [toggle, company])

  return (
    <DetailsCard>
      <CardHeading icon={IconNews}>{company.name ?? company.domain} in the news</CardHeading>

      {loading ? (
        <Box>
          <Spinner size="sm" />
        </Box>
      ) : news.length === 0 ? (
        <Text fontSize="sm" color="gray.600">
          No recent news found for {company.name ?? company.domain}
        </Text>
      ) : (
        <Stack spacing={0} divider={<Divider />} maxH="300px" overflow={'auto'}>
          {news.map((item) => (
            <Stack
              color="gray.600"
              py="4"
              key={item.link}
              fontSize="sm"
              spacing={'2'}
              _hover={{
                bg: 'gray.50'
              }}
            >
              <HStack>
                <Img w="6" src={`https://logo.clearbit.com/${item.source}`} />
                <Text as={Link} isExternal href={`https://${item.link}`} fontSize="xs" fontWeight={'semibold'}>
                  {item.title}
                </Text>
              </HStack>
              <Text
                as={Link}
                isExternal
                href={`https://${item.link}`}
                fontSize={'xs'}
                _hover={{
                  textDecoration: 'none'
                }}
              >
                {item.summary}
              </Text>
              <Link isExternal href={`https://${item.link}`} fontSize="xs" color="gray.500">
                source: {item.source}
              </Link>
            </Stack>
          ))}
        </Stack>
      )}
    </DetailsCard>
  )
}
