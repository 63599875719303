import React, { useCallback, useState } from 'react'
import { Button, Flex, Heading, Spinner, Text } from '@chakra-ui/react'
import { get } from '../../../../lib/api'
import { JSONTree } from '@app/components/ui/json-tree'
import { toast } from 'sonner'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'

interface Props {
  domain: string
}

type FetchDataResponse = {
  company: object
}

export default function CompanyNotFound(props: Props) {
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState(false)
  const kdbPath = `/admin/kdb/${props.domain}`

  const fetchData = useCallback(async () => {
    setLoading(true)

    try {
      const response = await get<FetchDataResponse>(`${kdbPath}/fetch_data_from_web`)
      if (!response.company) throw new Error('Data is empty')

      setData(response.company)
    } catch (_error) {
      toast.error('Failed to fetch data')
    } finally {
      setLoading(false)
    }
  }, [kdbPath])

  return (
    <Flex flexDirection="column" alignItems="center" gridRowGap={2}>
      {data ? (
        <>
          <Text>Please review the data below:</Text>

          <JSONTree data={data} />

          <form method="POST" action={`${kdbPath}/persist_from_web`} onSubmit={() => setLoading(true)}>
            <AuthenticityToken />

            <Button type="submit" colorScheme="blue" size="lg" mt="4" disabled={loading}>
              Create company
              {loading && <Spinner size="sm" ms="2" />}
            </Button>
          </form>
        </>
      ) : (
        <>
          <Heading as="h1" size="lg">
            Company Not Found
          </Heading>
          <Button colorScheme="blue" size="sm" mt="4" onClick={fetchData} disabled={loading}>
            Scrape data from website
            {loading && <Spinner size="sm" ms="2" />}
          </Button>
        </>
      )}
    </Flex>
  )
}
