import EASES from 'eases'
import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react'

export function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef(callback)

  useLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const timer = setInterval(() => savedCallback.current(), delay)
    return () => clearInterval(timer)
  }, [delay])
}

type Ease = keyof typeof EASES
type Milliseconds = number

export interface NumberEasingOptions {
  value: number
  speed?: Milliseconds
  decimals?: number
  ease?: Ease
  transitionWidth?: boolean
  render?: (value: number, decimals: number) => string | React.ReactElement
}

function defaultRender(value, decimals) {
  return Number(value).toFixed(decimals)
}

const CLOCK_TICK_MS = 16

export function NumberEasing({
  value,
  speed = 500,
  decimals = 0,
  ease = 'quintInOut',
  transitionWidth,
  render
}: NumberEasingOptions) {
  const [renderValue, setRenderValue] = useState(value)
  const [lastTarget, setLastTarget] = useState(value)
  const [renderWidth, setRenderWidth] = useState<number>(0)
  const [lastTargetWidth, setLastTargetWidth] = useState<number>(0)
  const hiddenRef = useRef<HTMLSpanElement>(null)

  const [lastUpdateTime, setLastUpdateTime] = useState<Milliseconds>(new Date().getTime())

  useEffect(() => {
    setLastUpdateTime(new Date().getTime() - CLOCK_TICK_MS)
    setLastTarget(renderValue)
    setLastTargetWidth(renderWidth || hiddenRef.current?.offsetWidth || 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useInterval(() => {
    const currentTime = new Date().getTime()
    const absoluteProgress = (currentTime - lastUpdateTime) / speed
    const finalWidth = hiddenRef.current?.offsetWidth || 0

    if (absoluteProgress >= 1) {
      setRenderValue(value)
      setRenderWidth(finalWidth)
    } else {
      const easedProgress = EASES[ease](absoluteProgress)
      setRenderValue(lastTarget + (value - lastTarget) * easedProgress)
      setRenderWidth(lastTargetWidth + (finalWidth - lastTargetWidth) * easedProgress)
    }
  }, CLOCK_TICK_MS)

  const functionRender = render || defaultRender
  const renderedValue = functionRender(renderValue, decimals)

  return (
    <Fragment>
      {/* Hidden element to measure the next width */}
      <span
        ref={hiddenRef}
        aria-hidden="true"
        style={{
          visibility: 'hidden',
          position: 'absolute',
          pointerEvents: 'none',
          top: '-9999px',
          left: '-9999px'
        }}
      >
        {renderedValue}
      </span>

      {transitionWidth ? (
        <span
          style={{
            display: 'inline-flex',
            width: renderWidth || 'auto',
            overflow: 'visible'
          }}
        >
          {renderedValue}
        </span>
      ) : (
        renderedValue
      )}
    </Fragment>
  )
}
