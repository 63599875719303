import React from 'react'
import { Apps } from '../../../types/App'
import { AutoOutboundRule } from '../../../types/AutoOutbound'
import { Breadcrumb } from '../../ui/Breadcrumb'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { OutreachSequence } from '../follow_rules/components/outreach/add-to-sequence'
import { automationsPath } from '../notifications/lib/path-helper'
import { DefinitionForm } from './components/definition-form'
import { autoOutboundNewPath, autoOutboundPath } from './lib/path-helpers'

interface Props {
  apps: Apps
  auto_outbound_rule?: AutoOutboundRule
  outreach_sequences?: OutreachSequence[]
}

export default function New(props: Props) {
  return (
    <PageLayout size="lg">
      <PageTitle skipRendering>New Auto Outbound Playbook</PageTitle>

      <Breadcrumb
        paths={[
          { path: automationsPath('/overview'), title: 'Automations' },
          { path: autoOutboundPath(), title: 'Auto Outbound' },
          { path: autoOutboundNewPath(), title: 'New Playbook' }
        ]}
      />

      <DefinitionForm
        apps={props.apps}
        auto_outbound_rule={props.auto_outbound_rule}
        outreach_sequences={props.outreach_sequences}
      />
    </PageLayout>
  )
}
