import { CircularProgress, Flex, Icon, Link, Text, Tooltip } from '@chakra-ui/react'
import { IconArrowRight } from '@tabler/icons-react'
import React from 'react'
import { useInstrumentationContext } from '../../../context/instrumentation-context'
import { projectPath, useCurrentProject } from '../../../ui/ProjectsContext'
import useLocation from '../../../ui/useLocation'

export function InstrumentationBadge() {
  const { progress, loading } = useInstrumentationContext()
  const project = useCurrentProject()
  const loc = useLocation()

  const isInstrumentation = loc.pathname.endsWith('/instrumentation')
  const shouldShow = !progress[2] && !loading

  if (!shouldShow || isInstrumentation || !project) {
    return null
  }

  return (
    <Flex
      as={Link}
      href={projectPath('/instrumentation')}
      flex="1 0 auto"
      bg="orange.50"
      borderColor="orange.300"
      borderWidth="1px"
      rounded="full"
      px={2.5}
      height="28px"
      position="relative"
      gap={2}
      alignItems="center"
      role="group"
      _hover={{ textDecoration: 'none' }}
    >
      <Tooltip shouldWrapChildren label={`${progress[0]}/${progress[1]} steps complete`}>
        <CircularProgress
          display="flex"
          flex="none"
          value={progress[0]}
          max={progress[1]}
          color="purple.600"
          size="16px"
        />
      </Tooltip>

      <Tooltip
        alignItems="center"
        label="Your Koala instrumentation is incomplete. An admin or developer can access the implementation checklist and finish your Koala pixel instrumentation."
      >
        <Text flex="none" fontSize="sm" fontWeight="medium" lineHeight={1.25}>
          Finish setup
        </Text>
      </Tooltip>
      <Icon
        as={IconArrowRight}
        boxSize={3.5}
        color="gray.700"
        opacity={0.4}
        _groupHover={{ opacity: 1 }}
        transition="all 150ms ease-in-out"
      />
    </Flex>
  )
}
