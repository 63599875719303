import { Box, Circle, Flex } from '@chakra-ui/react'
import React, { ReactElement } from 'react'

interface TimelineItemProps {
  name?: string
  icon?: ReactElement
  isFirst?: boolean
  isLast?: boolean
}

export function TimelineItem(props: React.PropsWithChildren<TimelineItemProps>) {
  return (
    <Box>
      <Flex
        position="relative"
        alignItems="flex-start"
        paddingY={2}
        marginLeft={4}
        _before={{
          content: '""',
          position: 'absolute',
          top: props.isFirst ? '50%' : 0,
          bottom: props.isLast ? '50%' : 0,
          left: '-1px',
          display: 'block',
          width: '2px',
          backgroundColor: 'gray.200'
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          backgroundColor="white"
          justifyContent="center"
          transform="translateX(-50%)"
        >
          {props.icon ?? <Circle flex="none" size="6" backgroundColor="gray.200" />}
        </Box>
        <Box flex="1" overflow="hidden">
          {props.children}
        </Box>
      </Flex>
    </Box>
  )
}
