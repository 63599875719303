import { Button, Flex, HStack, Icon, Select, Spinner, Stack, Text, useTheme } from '@chakra-ui/react'
import { IconAlertCircle } from '@tabler/icons-react'
import { format } from 'friendly-numbers'
import get from 'lodash/get'
import React, { useCallback, useMemo } from 'react'
import { DateRange } from 'react-day-picker'
import {
  Area,
  AreaProps,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts'
import dayjs from '../../../../lib/dayjs'
import { ChartLegend } from './ChartLegend'
import { ChartTooltip } from './ChartTooltip'
import { constructCategoryColors, defaultCategoricalColors } from './colors'
import { getGranularity, prepareTimeseriesData, SmoothingOptions, TimeseriesData } from './timeseries'

const formatDay = (timestamp) => dayjs.utc(timestamp).format('ddd, MMM D, YYYY')
const formatHour = (timestamp) => dayjs.utc(timestamp).local().format('ddd, MMM D, ha')
const shortDay = (timestamp) => dayjs.utc(timestamp).format('MMM D')
const shortMonth = (timestamp) => dayjs.utc(timestamp).format('MMM YYYY')
const shortHour = (timestamp) => dayjs.utc(timestamp).local().format('ha')

const xFormatters = {
  hour: shortHour,
  day: shortDay,
  month: shortMonth
}

const tooltipFormatters = {
  hour: formatHour,
  day: formatDay,
  month: shortMonth
}

type Period =
  | 'day'
  | 'yesterday'
  | 'week'
  | 'two-weeks'
  | 'month'
  | 'month-to-date'
  | 'quarter'
  | 'quarter-to-date'
  | 'year'
  | '5 years'

type FormatterFn = (value: any, name: string, _props: unknown) => string | [string, string] | [string]

export interface LineGraphProps {
  data?: TimeseriesData[]
  label: string | { [key: string]: string }
  isLoading?: boolean
  error?: Error
  onRetry?: () => void
  dataKeys?: string[]
  formatter?: FormatterFn | { [key: string]: FormatterFn }
  xFormatter?: (value: any) => string
  yFormatter?: (value: any) => string
  period: Period | DateRange | string
  height?: number
  xAxis?: boolean
  yAxis?: boolean
  yDomain?: [number, number]
  dot?: AreaProps['dot']
  colorScheme?: string
  customTooltip?: TooltipProps<any, any>['content']
  showSmoothingOptions?: boolean
  showAnimation?: boolean
  smoothing?: SmoothingOptions
}

export function LineGraph(props: LineGraphProps) {
  const dataKeys = useMemo(() => props.dataKeys || ['value'], [props.dataKeys])
  const [smoothing, setSmoothing] = React.useState<SmoothingOptions | undefined>(props.smoothing)
  const showAnimation = props.showAnimation ?? false

  const data = useMemo(
    () => prepareTimeseriesData(props.data ?? [], dataKeys, smoothing, props.period),
    [props.data, smoothing, dataKeys, props.period]
  )

  const keysWithData = useMemo(
    () => dataKeys.filter((key) => data.some((d) => get(d, `${key}.value`))),
    [dataKeys, data]
  )

  const [lineProps, setLineProps] = React.useState<{ hover: null | string; selected: string[] }>({
    hover: null,
    selected: []
  })

  const handleLegendMouseEnter = useCallback((e) => {
    const key = e.dataKey
    setLineProps((prev) => (prev[key] ? prev : { ...prev, hover: key }))
  }, [])

  const handleLegendMouseLeave = useCallback(() => {
    setLineProps((prev) => ({ ...prev, hover: null }))
  }, [])

  const selectBar = useCallback(
    (e) => {
      setLineProps((prev) => ({
        ...prev,
        selected: prev.selected?.includes(e.dataKey)
          ? prev.selected.filter((k) => k !== e.dataKey)
          : prev.selected.length + 1 === keysWithData.length
          ? []
          : [...(prev.selected ?? []), e.dataKey],
        hover: null
      }))
    },
    [keysWithData]
  )

  const theme = useTheme()

  const formatterFn = props.formatter
  const label = props.label

  const defaultFormatter = React.useCallback(
    (value, name, _props) => {
      name = name?.split('.')?.[0]
      if (typeof formatterFn === 'function') {
        return formatterFn(value, name, _props)
      } else if (formatterFn && typeof formatterFn[name] === 'function') {
        return formatterFn[name](value, name, _props)
      } else {
        return [value.toLocaleString()] as any as [string, string]
      }
    },
    [formatterFn]
  )

  if (props.isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" width="100%" height="280px">
        <Spinner size="lg" color="gray.400" />
      </Flex>
    )
  }

  if (props.error) {
    return (
      <Stack p="1">
        <HStack spacing="1">
          <Icon as={IconAlertCircle} color="yellow.500" />
          <Text fontSize="xs">Failed to load data</Text>
        </HStack>
        {props.onRetry && (
          <Button size="xs" variant={'outline'} onClick={props.onRetry} isLoading={props.isLoading}>
            Retry
          </Button>
        )}
      </Stack>
    )
  }

  const granularity = getGranularity(props.period)
  const tooltipFormatter = tooltipFormatters[granularity] || tooltipFormatters.day
  const xFormatter = xFormatters[granularity] || xFormatters.day

  const colors = constructCategoryColors(
    dataKeys,
    props.colorScheme ? [theme.colors[props.colorScheme]['500']] : defaultCategoricalColors
  )

  return (
    <Flex flexDir={'column'} position="relative">
      <ResponsiveContainer width="100%" height={props.height || 280}>
        <ComposedChart
          key={`${JSON.stringify(props.label)}:${JSON.stringify(props.period)}:${data.length}`}
          data={data}
          margin={{
            top: 10,
            right: 0,
            bottom: 5,
            left: 0
          }}
        >
          <defs>
            {dataKeys.map((dataKey) => {
              return (
                <linearGradient
                  key={`${colors.get(dataKey)}-gradient`}
                  id={`${colors.get(dataKey)?.replace('#', '')}-gradient`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="60%" stopColor={colors.get(dataKey)} stopOpacity={0.08} />
                  <stop offset="100%" stopColor="#FFFFFF" stopOpacity={0.08} />
                </linearGradient>
              )
            })}
          </defs>
          <XAxis
            dataKey="timestamp"
            hide={props.xAxis === false}
            tickFormatter={props.xFormatter || xFormatter}
            tick={{ fontSize: 11, fill: theme.colors.gray['600'] }}
            stroke={theme.colors.gray['200']}
            padding={{ left: 4, right: 4 }}
            interval="preserveStartEnd"
            tickMargin={10}
          />
          <YAxis
            hide={props.yAxis === false}
            width={42}
            tickFormatter={
              props.yFormatter ||
              ((v) => {
                return format(v, { formattedDecimals: 1 })
              })
            }
            tickLine={false}
            domain={props.yDomain}
            axisLine={false}
            interval="preserveStartEnd"
            tick={{ fontSize: 11, fill: theme.colors.gray['600'] }}
          />
          <Tooltip
            contentStyle={{
              color: theme.colors.gray['700'],
              fontSize: 14,
              border: 'none',
              borderRadius: '4px',
              boxShadow: `0px 1px 1px 0px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 12%)`
            }}
            allowEscapeViewBox={{ y: true }}
            wrapperStyle={{ outline: 'none', zIndex: 1000 }}
            labelFormatter={tooltipFormatter}
            formatter={defaultFormatter}
            content={props.customTooltip || <ChartTooltip />}
          />
          {props.xAxis !== false && (
            <CartesianGrid strokeDasharray="4 4" vertical={false} stroke={theme.colors.gray['200']} />
          )}
          {keysWithData.map((dataKey) => (
            <React.Fragment key={dataKey}>
              <Area
                type="linear"
                dataKey={`${dataKey}.previous`}
                hide={lineProps.selected.length > 0 && !lineProps.selected.includes(`${dataKey}.value`)}
                stroke={colors.get(dataKey)}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                fillOpacity={0}
                strokeOpacity={Number(
                  lineProps.hover === `${dataKey}.value` ||
                    !lineProps.hover ||
                    (lineProps.selected.length && !lineProps.selected.includes(lineProps.hover))
                    ? 1
                    : 0.25
                )}
                dot={false}
                tooltipType="none"
                legendType="none"
                isAnimationActive={!props.dot && showAnimation}
              />
              <Area
                type="linear"
                dataKey={`${dataKey}.current`}
                hide={lineProps.selected.length > 0 && !lineProps.selected.includes(`${dataKey}.value`)}
                stroke={colors.get(dataKey)}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="5 5"
                fillOpacity={0}
                strokeOpacity={Number(
                  lineProps.hover === `${dataKey}.value` ||
                    !lineProps.hover ||
                    (lineProps.selected.length && !lineProps.selected.includes(lineProps.hover))
                    ? 1
                    : 0.25
                )}
                dot={false}
                tooltipType="none"
                legendType="none"
                isAnimationActive={!props.dot && showAnimation}
              />
              <Area
                type="linear"
                dataKey={`${dataKey}.value`}
                hide={lineProps.selected.length > 0 && !lineProps.selected.includes(`${dataKey}.value`)}
                name={typeof label === 'string' ? label : label?.[dataKey]}
                fillOpacity={Number(
                  lineProps.hover === `${dataKey}.value` ||
                    !lineProps.hover ||
                    (lineProps.selected.length && !lineProps.selected.includes(lineProps.hover))
                    ? 1
                    : 0.25
                )}
                fill={`url(#${colors.get(dataKey)?.replace('#', '')}-gradient)`}
                stroke={colors.get(dataKey)}
                strokeWidth={0}
                strokeLinecap="round"
                strokeLinejoin="round"
                isAnimationActive={!props.dot && showAnimation}
                dot={props.dot}
              />
            </React.Fragment>
          ))}

          {keysWithData.length > 1 && (
            <Legend
              verticalAlign="top"
              height={40}
              content={
                <ChartLegend
                  onClick={selectBar}
                  onMouseOver={handleLegendMouseEnter}
                  onMouseOut={handleLegendMouseLeave}
                />
              }
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>

      {props.showSmoothingOptions && (
        <>
          {/* TODO move this elsewhere since it has nothing to do with the line chart component (only relevant for saved reports) */}
          {smoothing && <input type="hidden" name="dashboard[widgets][][settings][smoothing]" value={smoothing} />}
          <Stack w="100%" alignItems={'flex-end'} spacing="1" pt="4">
            <HStack maxW="200">
              <Select
                onChange={(e) => setSmoothing(e.target.value as SmoothingOptions)}
                value={smoothing}
                size="xs"
                rounded={'md'}
              >
                <option>No smoothing</option>
                <option value="auto">Automatic</option>
                <option value="cumulative">Cumulative</option>
                <option value="moving">Moving Average</option>
                <option value="gaussian">Gaussian</option>
                <option value="exponential">Exponential Moving Average</option>
                <option value="savitzkyGolay">Savitzky-Golay</option>
              </Select>
            </HStack>
          </Stack>
        </>
      )}
    </Flex>
  )
}
