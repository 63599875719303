import { Box, Center, FlexProps, HStack, Spinner, Text, UsePopperProps } from '@chakra-ui/react'
import { IconCopyPlus } from '@tabler/icons-react'
import pluralize from 'pluralize'
import React, { useCallback, useMemo } from 'react'
import { Template } from '../../../../types/Template'
import { useTemplates } from '../../../data/use-templates'
import CircleIcon from '../../../ui/CircleIcon'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'

export interface TemplateSelectProps {
  templates?: Template[]
  selectedTemplateId?: string | null
  isLoading?: boolean
  isReadOnly?: boolean
  onChange?: (userId: string | null) => void
  triggerProps?: FlexProps
  popoverProps?: FlexProps
  popperOptions?: UsePopperProps
}

export function TemplateSelect({
  selectedTemplateId,
  templates: providedTemplates,
  isLoading,
  isReadOnly,
  onChange,
  triggerProps,
  popoverProps,
  popperOptions
}: TemplateSelectProps) {
  const templateData = useTemplates({ enabled: !providedTemplates })

  const templates = useMemo(() => {
    return providedTemplates || templateData.data?.templates || []
  }, [templateData.data?.templates, providedTemplates])

  const selected = useMemo(() => {
    return templates.find((t) => t.id === selectedTemplateId) || null
  }, [templates, selectedTemplateId])

  const handleChange = useCallback(
    (template) => {
      onChange?.(template?.id || null)
    },
    [onChange]
  )

  if (isLoading && selectedTemplateId) {
    return (
      <Center height="30px" px={1}>
        <Spinner color="gray.300" thickness="1.5px" size="sm" />
      </Center>
    )
  }

  return (
    <Box>
      <ComboboxWithSearch
        items={templates}
        selectedItem={selected}
        isReadOnly={isReadOnly}
        isLoading={isLoading || templateData.isLoading}
        onChange={handleChange}
        filterItem={(a, val) => a.name?.toLowerCase().includes(val) || a.id?.toLowerCase().includes(val) || false}
        itemToString={(item) => item?.name || ''}
        itemRenderer={TemplateRenderer}
        selectButtonRenderer={TemplateRenderer}
        triggerProps={{
          py: 1,
          px: 1.5,
          border: 'none',
          gap: 1,
          _hover: { bg: 'gray.100' },
          ...triggerProps
        }}
        popoverProps={{
          maxW: '280px',
          ...popoverProps
        }}
        popperOptions={{
          matchWidth: false,
          placement: 'bottom-start',
          ...popperOptions
        }}
      />
    </Box>
  )
}

interface TemplateRendererProps {
  item: Template | null
  selectedItem?: Template | null
  isToggleButton?: boolean
}

function TemplateRenderer(props: TemplateRendererProps) {
  const template = props.item

  return (
    <HStack spacing={1.5} isTruncated>
      <CircleIcon icon={IconCopyPlus} rounded="md" />

      <Text fontSize="sm" fontWeight="medium" isTruncated>
        {template ? template.name : props.isToggleButton ? 'Select a template' : 'Unnamed template'}
      </Text>
      {template?.template_views && (
        <Text fontSize="sm" color="gray.500">
          ({pluralize('lists', template.template_views.length, true)})
        </Text>
      )}
    </HStack>
  )
}
