import { useDisclosure, UseDisclosureProps } from '@chakra-ui/react'
import React, { useCallback, useEffect } from 'react'
import { concurrentCachedGET } from '../../../../lib/api'
import { FollowRule, Notification } from '../../notifications'
import { notificationsPath } from '../../notifications/lib/path-helper'
import { LogsDrawer } from './logs-drawer'

interface Props extends UseDisclosureProps {
  followRule: FollowRule
}

export function NotificationRuleLogs(props: Props) {
  const [notifications, setNotifications] = React.useState<Notification[]>([])
  const [loading, setIsLoading] = React.useState(false)
  const { isOpen, onClose } = useDisclosure(props)

  useEffect(() => {
    if (!isOpen) {
      return
    }

    setIsLoading(true)
    setNotifications([])

    const path = notificationsPath(`?rule_filter=${props.followRule.id}`)
    concurrentCachedGET<{ notifications: Notification[] }>(path).then((res) => {
      setNotifications(res.notifications)
      setIsLoading(false)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const handleClose = useCallback(() => {
    onClose()

    setTimeout(() => {
      setNotifications([])
      setIsLoading(false)
    }, 0)
  }, [onClose])

  return (
    <LogsDrawer
      followRule={props.followRule}
      notifications={notifications}
      loading={loading}
      isOpen={isOpen}
      onClose={handleClose}
    />
  )
}
