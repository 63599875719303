import { BoxProps, Image } from '@chakra-ui/react'
import { Icon as TablerIcon, IconProps } from '@tabler/icons-react'
import React from 'react'
import * as ReactIs from 'react-is'

export type IconifyProps = {
  icon: JSX.Element | TablerIcon | string | React.FC | undefined | null
  size?: string | number
} & IconProps

export function Iconify({ icon, size = 20, ...rest }: IconifyProps & BoxProps) {
  let iconElement: null | React.ReactElement = null
  const sizePx = typeof size === 'number' ? `${size}px` : size
  if (typeof icon === 'string') {
    iconElement = <Image src={icon} width={sizePx} maxHeight={sizePx} padding="1px" {...(rest as BoxProps)} />
  } else if (ReactIs.isValidElementType(icon)) {
    iconElement = React.createElement(icon, { size, strokeWidth: 1.5, ...rest })
  } else if (React.isValidElement(icon)) {
    iconElement = React.cloneElement(icon, { size, strokeWidth: 1.5, ...rest } as any)
  }

  return iconElement
}
