import { getMatchesByType } from '@app/components/pages/accounts/components/account-matches'
import { projectPath } from '@app/components/ui/ProjectsContext'
import { concurrentGET } from '@app/lib/api'
import { Account, CRMAccount, CRMMatch } from '@app/types/Account'
import { App } from '@app/types/App'
import React, { useEffect } from 'react'
import { useMountedState } from 'react-use'

interface FuzzyCrmMatchesProps {
  account: Account | null | undefined
  app?: App
  fuzzy?: boolean
  create?: boolean
}

type RefetchOptions = {
  shouldReset?: boolean
  hardRefresh?: boolean
}

export default function useFuzzyCrmMatches(props: FuzzyCrmMatchesProps) {
  const [allAccounts, setAllAccounts] = React.useState<CRMMatch[]>([])
  const [accountIdMatches, setAccountIdMatches] = React.useState<CRMMatch[]>([])
  const [fuzzyMatches, setFuzzyMatches] = React.useState<CRMMatch[]>([])
  const [strongMatches, setStrongMatches] = React.useState<CRMMatch[]>([])
  const [newAccount, setNewAccount] = React.useState<CRMAccount | null>(null)
  const [mainCrmRecord, setMainCrmRecord] = React.useState<CRMAccount | null>(null)
  const [mainCrmMatch, setMainCrmMatch] = React.useState<CRMMatch | null>(null)
  const [selectedCrmRecord, setSelectedCrmRecord] = React.useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const mounted = useMountedState()

  const refetch = React.useCallback(
    (options?: RefetchOptions) => {
      const domain = props.account?.domain ?? props.account?.company.domain
      if (domain === undefined) {
        return
      }

      // reset everything
      if (options?.shouldReset !== false) {
        setMainCrmRecord(null)
        setAllAccounts([])
        setAccountIdMatches([])
        setStrongMatches([])
        setFuzzyMatches([])
        setMainCrmMatch(null)
        setSelectedCrmRecord(undefined)
      }

      setIsLoading(true)
      return concurrentGET<{ matches: CRMMatch[] }>(
        projectPath(
          `/accounts/${domain}/matches?app_module=${props.app?.app_module}&create=${
            props.create
          }&fuzzy=${!!props.fuzzy}&hard_refresh=${!!options?.hardRefresh}`
        )
      )
        .then((response) => {
          if (!mounted()) {
            return
          }

          const matches = response.matches
          const accountIdMatches = getMatchesByType(matches, 'account_id', props.app)
          const domainMatches = getMatchesByType(matches, 'domain', props.app)
          const nameMatches = getMatchesByType(matches, 'name', props.app)
          const mainMatch = accountIdMatches[0] || domainMatches[0]
          setMainCrmRecord(mainMatch?.crm_entity)

          setAllAccounts(
            matches.filter((a) => {
              if (props.app) {
                return a.crm.title === props.app?.title
              }

              return true
            })
          )
          setAccountIdMatches(accountIdMatches ?? [])
          setStrongMatches((accountIdMatches ?? []).concat(...(domainMatches ?? [])))
          setFuzzyMatches(nameMatches ?? [])
          setMainCrmMatch(mainMatch)
          setSelectedCrmRecord(mainMatch?.crm_entity_id)

          setIsLoading(false)
        })
        .catch((error) => {
          // log error but move on, will behave as if there are no crm matches
          console.error(error)

          if (mounted()) {
            setIsLoading(false)
          }
        })
    },
    [mounted, props.account?.domain, props.account?.company.domain, props.app, props.create, props.fuzzy]
  )

  useEffect(() => {
    refetch()
  }, [refetch])

  return {
    allAccounts,
    accountIdMatches,
    fuzzyMatches,
    strongMatches,
    newAccount,
    setNewAccount,
    mainCrmRecord,
    mainCrmMatch,
    selectedCrmRecord,
    setSelectedCrmRecord,
    isLoading,
    refetch
  }
}
