import {
  Button,
  ButtonProps,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Stack,
  Text
} from '@chakra-ui/react'
import { intersectionBy } from 'lodash'
import React from 'react'
import { Card } from '../../../ui/Card'
import { TopListProps } from './top-list'

export interface CatalogItem {
  id: string
  name: string
  description: string
  type: 'number' | 'timeseries' | 'table'
  unit: string
  color_scheme?: ButtonProps['colorScheme']
  columns?: TopListProps['columns']
  settings?: Record<string, any>
}

export type Catalog = Record<string, CatalogItem>

export interface WidgetCatalogProps {
  visible: boolean
  catalog: CatalogItem[]
  type?: 'number' | 'timeseries' | 'table' | 'funnel'
  onClose: () => void
  onAddWidgets: (widgets: CatalogItem[]) => void
}

export function WidgetCatalog(props: WidgetCatalogProps) {
  const [widgets, setWidgets] = React.useState<CatalogItem[]>([])

  return (
    <Drawer
      isOpen={props.visible}
      onClose={() => {
        props.onClose()
      }}
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add a metric to your dashboard</DrawerHeader>
        <DrawerBody>
          <Stack spacing="4">
            {props.catalog.map((item) => {
              return (
                <Stack key={item.id}>
                  <CatalogItem
                    selected={widgets.find((w) => w.id === item.id) !== undefined}
                    onChange={(added) => {
                      if (added) {
                        setWidgets((widgets) => [...widgets, item])
                      } else {
                        setWidgets((widgets) => widgets.filter((w) => w.id !== item.id))
                      }
                    }}
                    item={item}
                  />
                </Stack>
              )
            })}
          </Stack>
        </DrawerBody>
        <DrawerFooter>
          <Button
            colorScheme={'purple'}
            w="100%"
            onClick={() => {
              props.onAddWidgets(widgets)
              props.onClose()
            }}
          >
            Add ({intersectionBy(widgets, props.catalog, 'id').length}) Widgets
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export interface CatalogItemProps {
  item: CatalogItem
  onChange: (checked: boolean) => void
  selected?: boolean
}

export function CatalogItem(props: CatalogItemProps) {
  return (
    <Checkbox onChange={(e) => props.onChange(e.target.checked)} isChecked={props.selected}>
      <Stack
        as={Card}
        rounded="md"
        _hover={{
          bg: 'purple.50',
          borderColor: 'purple.500',
          cursor: 'pointer'
        }}
      >
        <Stack spacing="0">
          <Heading size="xs">{props.item.name}</Heading>
          <Text fontSize="sm" color="gray.500">
            {props.item.description}
          </Text>
        </Stack>
      </Stack>
    </Checkbox>
  )
}
