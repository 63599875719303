import { useMutation } from 'react-query'
import { toast } from 'sonner'
import { post } from '../../lib/api'
import { projectPath } from '../ui/ProjectsContext'

interface Params {
  record_type: 'Account' | 'Profile'
  record_id: string
}

export function useReindexRecord() {
  return useMutation(
    ({ record_id, record_type }: Params) => {
      const path =
        record_type === 'Account'
          ? projectPath(`/accounts/${record_id}/reindex`)
          : projectPath(`/profiles/${record_id}/reindex`)

      return post(path, {})
    },
    {
      onSuccess: (_data, variables) => {
        toast.success(`Reindexing this ${variables.record_type} in the background...`)
      },
      onError: (_data, variables) => {
        toast.error(`There was an issue reindexing this ${variables.record_type}.`)
      }
    }
  )
}
