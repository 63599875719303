import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Text,
  useBoolean
} from '@chakra-ui/react'
import * as React from 'react'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { GoogleIcon } from '../../../ui/icons'
import KoalaLogo from '../../../ui/KoalaLogo'

interface Props {
  email?: string | null
  authMethods: string[]
  samlRequestPaths: string[]
}

export default function New(props: Props) {
  const [useEmail, setUseEmail] = useBoolean(false)
  const authMethods = props.authMethods || []
  const oauthEnabled = authMethods.includes('oauth')
  const magicLinkEnabled = authMethods.includes('magic_link')
  const passwordEnabled = authMethods.includes('password')
  const multipleSamlPaths = props.samlRequestPaths.length > 1

  return (
    <Box minHeight="100%" background="white">
      <Flex
        marginX="auto"
        paddingTop="15vh"
        paddingBottom="5vh"
        maxWidth="100%"
        width="520px"
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={8}
        paddingX={5}
      >
        <KoalaLogo kind="mark" size="64px" />
        <Heading size="lg" textAlign="center">
          Log in to Koala
        </Heading>
        <Box width="100%" maxWidth="400px" marginX="auto">
          <Stack spacing={4} direction="column" alignItems="stretch">
            {oauthEnabled && (
              <form action="/auth/google_oauth2?origin=login" method="post">
                <AuthenticityToken />

                <Button
                  colorScheme="purple"
                  leftIcon={<GoogleIcon />}
                  size="lg"
                  width="full"
                  fontSize="md"
                  type="submit"
                >
                  Continue with Google
                </Button>
              </form>
            )}

            {magicLinkEnabled && (
              <form action="/passwordless-login" method="post">
                <AuthenticityToken />
                {props.email && <input type="hidden" name="user[email]" value={props.email} />}

                <Button type="submit" size="lg" variant="outline" fontSize="md" width="full">
                  Send me a magic link ✨
                </Button>
              </form>
            )}

            {passwordEnabled && !useEmail && (
              <Button type="button" variant="outline" size="lg" fontSize="md" onClick={setUseEmail.on}>
                Log in with password
              </Button>
            )}

            {passwordEnabled && (
              <Collapse in={useEmail} style={{ margin: '3px -3px', padding: '3px' }} unmountOnExit>
                <form action="/login" method="post">
                  <AuthenticityToken />
                  <Stack spacing={6} direction="column">
                    {oauthEnabled && <Divider marginTop={4} />}

                    <FormControl id="email">
                      <FormLabel>Email address</FormLabel>
                      <Input
                        type="email"
                        name="user[email]"
                        defaultValue={props.email || ''}
                        autoFocus={!props.email}
                        isRequired
                      />
                    </FormControl>

                    <FormControl id="password">
                      <Flex justifyContent="space-between" alignItems="baseline">
                        <FormLabel>Password</FormLabel>
                        <Link fontSize="sm" variant="primary" href="/users/password/new">
                          Forgot password?{' '}
                        </Link>
                      </Flex>
                      <Input type="password" name="user[password]" autoFocus={!!props.email} isRequired />
                    </FormControl>

                    <Button type="submit" size="lg" fontSize="md">
                      Log in with password
                    </Button>
                  </Stack>
                </form>
              </Collapse>
            )}

            {props.samlRequestPaths &&
              props.samlRequestPaths.length > 0 &&
              props.samlRequestPaths.map((path, index) => (
                <form key={index} action={path} method="post">
                  <AuthenticityToken />
                  <Stack spacing={6} direction="column">
                    <Button type="submit" variant="outline" size="lg" fontSize="md">
                      {`Log in with SAML${multipleSamlPaths ? ` (Project ${index + 1})` : ''}`}
                    </Button>
                  </Stack>
                </form>
              ))}

            {props.email && (
              <form action="/forget_me" method="post">
                <AuthenticityToken />
                <Flex fontSize="sm" gap={1} alignItems="center" justifyContent="center">
                  <Text as="span" color="gray.500">
                    Not {props.email}?
                  </Text>
                  <Button type="submit" variant="link" color="purple.500" fontSize="sm">
                    Change it
                  </Button>
                </Flex>

                <Divider marginTop={4} />
              </form>
            )}

            <Flex fontSize="sm" gap={1} alignItems="center" justifyContent="center">
              <Text as="span" color="gray.500">
                Don't have an account?
              </Text>
              <Link variant="primary" href="/signup">
                Sign up
              </Link>
            </Flex>
          </Stack>
        </Box>
      </Flex>
    </Box>
  )
}
