import React from 'react'
import { LimitReason } from './useEntitlements'

interface GrayAccountContextProps {
  isGrayAccountVisible: boolean
  grayAccountReason?: LimitReason
  setIsGrayAccountVisible: (visible: boolean) => void
  setGrayAccountReason: (reason?: LimitReason) => void
}

const GrayAccountContext = React.createContext<GrayAccountContextProps>({
  isGrayAccountVisible: false,
  setIsGrayAccountVisible: () => {},
  setGrayAccountReason: () => {}
})

export default GrayAccountContext
