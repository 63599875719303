import { Button, Heading, Stack, Text } from '@chakra-ui/react'
import { IconArrowRight } from '@tabler/icons-react'
import React, { useEffect } from 'react'
import { HeroImage } from '../components/HeroImage'
import { konfetti } from '../components/konfetti'
import { Step } from '../components/SetupProject'
import { StepProps } from '../rep_onboarding'
import img from './done.png'

export function RepDone(props: StepProps) {
  useEffect(() => {
    const timeout = setTimeout(() => konfetti(), 250)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <Step cardProps={{ maxW: [800, 800, 600] }} {...props}>
      <HeroImage src={img} h="240px" />
      <Stack p="8" spacing="8" pt="0">
        <Stack>
          <Heading size="md">You're all set</Heading>
          <Text fontSize={'sm'} color="gray.500">
            Nice work! If you set everything up, you'll start seeing Intent Signals coming in through Slack and in the
            Koala app.
          </Text>
        </Stack>

        <Stack>
          <Button colorScheme={'purple'} onClick={props.onContinue} rightIcon={<IconArrowRight size="18" />}>
            Check out your workspace
          </Button>
        </Stack>
      </Stack>
    </Step>
  )
}
