import { FormControl, FormLabel, HStack, Input, Radio, RadioGroup, Select, Stack } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { DeliveryRuleTrigger } from '../../notifications'

interface Props {
  trigger?: DeliveryRuleTrigger
  disabled?: boolean
  namespace: string
}

export function deriveChoice(trigger?: DeliveryRuleTrigger) {
  if (trigger === undefined) {
    return 'immediate'
  }

  if (trigger === 'immediate') {
    return 'immediate'
  }

  if (trigger.startsWith('daily')) {
    return 'daily'
  }

  return 'weekly'
}

export function dow(trigger?: DeliveryRuleTrigger) {
  if (trigger === undefined) {
    return 'monday'
  }

  if (trigger.startsWith('daily')) {
    return 'monday'
  }

  return trigger.split('@')[0]
}

export function timeOfDay(trigger?: DeliveryRuleTrigger) {
  if (trigger === undefined || trigger === 'immediate') {
    return '09:00'
  }

  return trigger.split('@')[1]
}

export function TimeTrigger(props: Props) {
  const [choice, setChoice] = React.useState(deriveChoice(props.trigger))
  const [day, setDay] = React.useState<string>(dow(props.trigger))
  const [time, setTime] = React.useState<string>(timeOfDay(props.trigger))

  const isScheduled = useMemo(() => {
    return choice !== 'immediate'
  }, [choice])

  return (
    <Stack pt="4">
      <FormControl>
        <FormLabel>When</FormLabel>
        <Stack>
          <RadioGroup
            size="sm"
            value={choice}
            isDisabled={props.disabled}
            onChange={(e) => {
              setChoice(e)
              if (e === 'daily') {
                setDay('daily')
              }
            }}
          >
            <HStack>
              <Radio value="immediate">Immediately</Radio>
              <Radio value="daily">Daily</Radio>
              <Radio value="weekly">Weekly</Radio>
            </HStack>
          </RadioGroup>
        </Stack>
      </FormControl>
      {!isScheduled && (
        <input disabled={props.disabled} type="hidden" name={`${props.namespace}[trigger]`} value="immediate" />
      )}
      {isScheduled && (
        <>
          {choice === 'weekly' && (
            <Select
              size="sm"
              rounded="md"
              value={day}
              onChange={(e) => setDay(e.target.value)}
              isDisabled={props.disabled}
            >
              <option value="monday">Monday</option>
              <option value="tuesday">Tuesday</option>
              <option value="wednesday">Wednesday</option>
              <option value="thursday">Thursday</option>
              <option value="friday">Friday</option>
              <option value="saturday">Saturday</option>
              <option value="sunday">Sunday</option>
            </Select>
          )}
          <Input
            placeholder="Select Date and Time"
            type="time"
            size="sm"
            rounded="md"
            defaultValue="09:00"
            step="300"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            isDisabled={props.disabled}
          />
          {choice === 'daily' && (
            <input
              disabled={props.disabled}
              type="hidden"
              name={`${props.namespace}[trigger]`}
              value={`daily@${time}`}
            />
          )}
          {choice === 'weekly' && (
            <input
              disabled={props.disabled}
              type="hidden"
              name={`${props.namespace}[trigger]`}
              value={`${day}@${time}`}
            />
          )}
        </>
      )}
    </Stack>
  )
}
