import {
  Box,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Editable,
  EditableInput,
  EditablePreview,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Input,
  SkeletonText,
  Stack,
  Text,
  useTheme
} from '@chakra-ui/react'
import { IconArrowDownRight, IconArrowRight, IconPlus } from '@tabler/icons-react'
import { deepEqual } from 'fast-equals'
import { format } from 'friendly-numbers'
import { debounce, startCase } from 'lodash'
import { nanoid } from 'nanoid'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Bar, CartesianGrid, ComposedChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { Apps } from '../../../../types/App'
import { useDashboardFunnel } from '../../../data/use-dashboard-widget'
import { facetQueryString, useFacets } from '../../../data/use-facets'
import { Card, GrayCard } from '../../../ui/Card'
import SelectInput from '../../../ui/SelectInput'
import { useSearchParams } from '../../../ui/useSearchState'
import { FacetFilters } from '../../accounts'
import { FilterPreview } from '../../accounts/components/FilterPreview'

export interface FunnelStep {
  id: string
  name: string
  filters: FacetFilters
}

export interface FunnelProps {
  onSave: (steps: FunnelStep[], aggregate: 'accounts' | 'visitors') => void
  onChangeName: (name: string) => void
  facetFilters?: FacetFilters
  focusTime?: string
  period?: string
  accountIds: string[]
  steps: FunnelStep[]
  aggregateBy?: 'accounts' | 'visitors'
  apps: Apps
  name?: string
}

export function FunnelChart(props: FunnelProps) {
  const steps = useMemo(() => props.steps, [props.steps])
  const [dataKey, setDataKey] = useState(props.aggregateBy ?? 'visitors')

  useEffect(() => {
    setDataKey(props.aggregateBy ?? 'visitors')
  }, [props.aggregateBy])

  const facetQuery = useMemo(() => {
    let query = facetQueryString(props.facetFilters ?? {}).join('&')

    if (props.focusTime) {
      const ft = facetQueryString({ focus_time: props.focusTime }).join('&')
      query = [query, ft].filter(Boolean).join('&')
    }

    return query
  }, [props.focusTime, props.facetFilters])

  const funnelData = useDashboardFunnel(
    steps,
    {
      period: props.period
    },
    facetQuery,
    props.accountIds
  )

  const theme = useTheme()
  const total = useMemo(() => (funnelData.data?.funnel ?? [])[0]?.[dataKey] ?? null, [funnelData.data, dataKey])

  return (
    <Card>
      <Stack spacing="8">
        <HStack justifyContent={'space-between'}>
          <Editable defaultValue={props.name}>
            <EditablePreview fontSize={'sm'} fontWeight="semibold" />
            <EditableInput
              fontSize={'sm'}
              fontWeight="semibold"
              bg="white"
              placeholder="Name"
              onChange={(e) => props.onChangeName(e.target.value)}
            />
          </Editable>
          <ButtonGroup size="xs" variant="outline" isAttached>
            <Button onClick={() => setDataKey('accounts')} isActive={dataKey === 'accounts'} mx="-px">
              Accounts
            </Button>
            <Button onClick={() => setDataKey('visitors')} isActive={dataKey === 'visitors'}>
              Visitors
            </Button>
          </ButtonGroup>
        </HStack>
        {steps.length > 0 && funnelData.isLoading && <SkeletonText noOfLines={8} spacing="4" />}
        {steps.length > 0 && funnelData.data && (
          <ResponsiveContainer width="100%" height={280}>
            <ComposedChart
              data={funnelData.data.funnel.map((f, index) => {
                // calc percent of prev total
                const prev = funnelData.data.funnel[index - 1]
                const dropoff = prev ? prev[dataKey] - f[dataKey] : null

                return {
                  ...f,
                  dropoff,
                  dropoff_percent: getPercent(dropoff, total),
                  percent_total: getPercent(f[dataKey], total),
                  percent_previous: prev ? getPercent(f[dataKey], prev[dataKey]) : null,
                  fill: f[dataKey] === 0 ? theme.colors.gray['300'] : theme.colors.purple[600 - 100 * (index + 1)]
                }
              })}
              barGap={0}
              barCategoryGap={12}
              margin={{
                top: 36,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <Tooltip
                cursor={{ opacity: 0.25 }}
                allowEscapeViewBox={{ y: true }}
                wrapperStyle={{ outline: 'none', zIndex: 1000 }}
                content={<FunnelTooltip />}
              />
              <CartesianGrid strokeDasharray="4 4" vertical={false} stroke={theme.colors.gray['200']} />

              <XAxis
                dataKey={'name'}
                tick={{ fontSize: 11, fill: theme.colors.gray['600'] }}
                stroke={theme.colors.gray['200']}
                type="category"
                interval="preserveStartEnd"
                tickMargin={10}
              />
              <YAxis
                type="number"
                domain={[0, 'dataMax']}
                width={42}
                tickFormatter={(v) => format(v, { formattedDecimals: 1 })}
                tickLine={false}
                axisLine={false}
                interval="preserveStartEnd"
                tick={{ fontSize: 11, fill: theme.colors.gray['600'] }}
              />

              <Bar stackId="a" dataKey={dataKey} name={startCase(dataKey)} radius={4} minPointSize={2}>
                <LabelList
                  dataKey={dataKey}
                  position="top"
                  fontWeight={600}
                  fontSize={12}
                  formatter={(v: number) => v?.toLocaleString()}
                  content={({ x, y, width, offset, value }) => {
                    const percentage = getPercent(value, total)

                    return (
                      <g>
                        <text
                          x={Number(x || 0) + Number(width) / 2}
                          y={Number(y) - Number(offset || 0) - 16}
                          fill={theme.colors.purple['600']}
                          fontSize="13"
                          fontWeight="600"
                          textAnchor="middle"
                          dominantBaseline="bottom"
                        >
                          {percentage}%
                        </text>
                        <text
                          x={Number(x || 0) + Number(width) / 2}
                          y={Number(y) - Number(offset || 0)}
                          fill={theme.colors.gray['600']}
                          fontSize="12"
                          textAnchor="middle"
                          dominantBaseline="bottom"
                        >
                          {value?.toLocaleString()}
                        </text>
                      </g>
                    )
                  }}
                />
              </Bar>
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </Stack>
    </Card>
  )
}

function FunnelTooltip({ active, payload, label, labelFormatter, colors }: any) {
  if (active && payload) {
    const filteredPayload = payload.filter((item: any) => item.type !== 'none')
    const dataKey = filteredPayload.length === 1 ? filteredPayload[0].name : null

    return (
      <Card
        bg="white"
        fontSize="sm"
        p={0}
        rounded="lg"
        color="gray.700"
        border="none"
        boxShadow="0px 1px 1px 0px rgb(0 0 0 / 10%), 0px 4px 16px rgb(0 0 0 / 15%)"
      >
        <HStack
          spacing={4}
          justifyContent="space-between"
          borderBottom="1px solid"
          borderColor="gray.200"
          py={2}
          px={4}
        >
          <Text size="sm" color="gray.700" fontWeight="medium">
            {typeof labelFormatter === 'function' ? labelFormatter(label) : label}
          </Text>
          {dataKey && (
            <Text fontSize="sm" color="gray.500">
              {dataKey}
            </Text>
          )}
        </HStack>
        <Stack spacing={1} py={2} px={4}>
          {filteredPayload.map((entry) => (
            <Stack key={entry.name}>
              {!dataKey && (
                <Text
                  color="gray.500"
                  fontSize="11px"
                  fontWeight="semibold"
                  letterSpacing="wider"
                  textTransform="uppercase"
                >
                  {entry.name}
                </Text>
              )}
              <HStack spacing={4} justifyContent="space-between">
                <HStack spacing={1}>
                  <Icon as={IconArrowRight} color="green.400" boxSize={4} />
                  <Text>Converted</Text>
                </HStack>
                <Text fontWeight="medium">{entry.value?.toLocaleString()}</Text>
              </HStack>
              {typeof entry.payload.dropoff === 'number' && (
                <HStack spacing={4} justifyContent="space-between">
                  <HStack spacing={1}>
                    <Icon as={IconArrowDownRight} color="red.400" boxSize={4} />
                    <Text>Dropped off</Text>
                  </HStack>
                  <Text fontWeight="medium">{entry.payload.dropoff?.toLocaleString()}</Text>
                </HStack>
              )}
              <HStack spacing={4} justifyContent="space-between">
                <Text>Conversion (total)</Text>
                <Text fontWeight="medium">{entry.payload.percent_total?.toLocaleString()}%</Text>
              </HStack>
              <HStack spacing={4} justifyContent="space-between">
                <Text>Conversion (from previous)</Text>
                <Text fontWeight="medium">
                  {(entry.payload.percent_previous ?? entry.payload.percent_total)?.toLocaleString()}%
                </Text>
              </HStack>
            </Stack>
          ))}
        </Stack>
      </Card>
    )
  }

  return null
}

function getPercent(value, total) {
  return total > 0 && typeof value === 'number' ? Math.round((1000 * value) / total) / 10 : 100
}

interface FunnelDrawerProps {
  onClose: () => void
  onSave: (steps: FunnelStep[], aggregate: 'accounts' | 'visitors') => void
  steps: FunnelStep[]
  aggregate?: 'accounts' | 'visitors'
  apps: Apps
}

export function FunnelDrawer(props: FunnelDrawerProps) {
  const [steps, setSteps] = useState(
    props.steps?.length ? props.steps : [{ id: nanoid(), name: 'Step 1', filters: {} }]
  )
  const [aggregate, setAggregate] = useState<'accounts' | 'visitors'>(props.aggregate || 'accounts')
  const isDirty = useMemo(
    () => !deepEqual({ aggregate: props.aggregate ?? 'accounts', steps: props.steps }, { aggregate, steps }),
    [props.steps, props.aggregate, steps, aggregate]
  )

  return (
    <Drawer
      isOpen
      onClose={() => {
        if (isDirty) {
          props.onSave(steps, aggregate)
        }
        props.onClose()
      }}
      size="lg"
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Configure your funnel</DrawerHeader>
        <DrawerBody>
          <Stack spacing="8">
            <Text fontSize={'sm'}>
              Add steps to your funnel to track how many accounts or visitors are in each step.
            </Text>

            <FormControl>
              <FormLabel>Aggregate the funnel steps by unique visitors or accounts?</FormLabel>
              <Box>
                <SelectInput
                  variant="outline"
                  items={['accounts', 'visitors']}
                  itemToString={(item) => startCase(item)}
                  popperOptions={{ matchWidth: true }}
                  selectedItem={aggregate}
                  onSelectedItemChange={(changes) => setAggregate(changes.selectedItem)}
                />
              </Box>
            </FormControl>

            {steps.map((step) => {
              return (
                <Stack key={step.name}>
                  <FunnelStepBuilder
                    onRemove={(incoming) => {
                      setSteps((steps) => {
                        return steps.filter((s) => s.id !== incoming.id)
                      })
                    }}
                    onChange={(incoming) => {
                      setSteps((steps) => {
                        return steps.map((s) => {
                          if (s.id === incoming.id) {
                            return incoming
                          } else {
                            return s
                          }
                        })
                      })
                    }}
                    apps={props.apps}
                    step={step}
                  />
                </Stack>
              )
            })}
            <Button
              alignSelf="flex-start"
              size="sm"
              variant="outline"
              leftIcon={<Icon as={IconPlus} boxSize={4} />}
              onClick={() => {
                setSteps((steps) => {
                  return [
                    ...steps,
                    {
                      id: nanoid(),
                      name: `Step ${steps.length + 1}`,
                      filters: {}
                    } as FunnelStep
                  ]
                })
              }}
            >
              Add step
            </Button>
          </Stack>
        </DrawerBody>
        <DrawerFooter gap={3}>
          <Button size="sm" variant="outline" onClick={props.onClose} ml="auto">
            Cancel
          </Button>
          <Button
            size="sm"
            colorScheme={'purple'}
            onClick={() => {
              props.onSave(steps, aggregate)
              props.onClose()
            }}
            isDisabled={!isDirty}
          >
            Save Funnel
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

interface FunnelStepBuilderProps {
  onChange: (step: FunnelStep) => void
  onRemove: (step: FunnelStep) => void
  step: FunnelStep
  apps: Apps
}

function FunnelStepBuilder(props: FunnelStepBuilderProps) {
  const facets = useFacets({
    facet_filters: props.step.filters
  })

  const onChange = props.onChange

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveName = useCallback(
    debounce((name: string) => {
      props.onChange({
        ...props.step,
        name
      })
    }, 1000),
    [props]
  )

  useEffect(() => {
    if (facets.facetFilters && props.step.filters !== facets.facetFilters) {
      onChange({
        ...props.step,
        filters: facets.facetFilters
      })
    }
  }, [facets.facetFilters, props.step, onChange])

  const step = props.step

  return (
    <Stack as={GrayCard}>
      <FormControl>
        <FormLabel>Step name:</FormLabel>
        <Input
          size="sm"
          bg="white"
          placeholder="Name"
          defaultValue={step.name}
          onChange={(e) => saveName(e.target.value)}
        />
        <FormHelperText>Provide a meaningful name/label for this funnel step.</FormHelperText>
      </FormControl>
      <FormControl>
        <FormLabel>Filters:</FormLabel>
        <FilterPreview
          {...facets}
          apps={props.apps}
          usePortal={false}
          kind="account"
          hideFacetCounts
          shouldShowLastSeenFilter={false}
        />
        <FormHelperText>
          Pick one or more intent or firmographic filters to define a specific step in your funnel. Matches must meet
          all of the filter conditions.
        </FormHelperText>
      </FormControl>
      <HStack justifyContent={'flex-end'}>
        <Button size="sm" colorScheme={'red'} variant="ghost" onClick={() => props.onRemove(step)}>
          Remove
        </Button>
      </HStack>
    </Stack>
  )
}
