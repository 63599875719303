import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useUpdateSetting } from '../../data/use-update-setting'
import { useUserSettings } from '../../data/use-user-settings'

export const UserNavSettingsContext = React.createContext({
  recentNavItems: [] as any[],
  refetchItems: () => {},
  setRecentNavItems: (_items: any[]) => {}
})

export const UserNavSettingsProvider = ({ children }) => {
  const { data, refetch } = useUserSettings()
  const [recentNavItems, setRecentNavItems] = useState(data?.settings?.nav?.recent_nav_items ?? emptyArray)

  useEffect(() => {
    if (data?.settings?.nav?.recent_nav_items) {
      setRecentNavItems(data.settings.nav.recent_nav_items)
    }
  }, [data?.settings?.nav?.recent_nav_items])

  const state = useMemo(
    () => ({ recentNavItems, refetchItems: refetch, setRecentNavItems }),
    [recentNavItems, refetch, setRecentNavItems]
  )

  return <UserNavSettingsContext.Provider value={state}>{children}</UserNavSettingsContext.Provider>
}

export const useUserNavSettingsContext = () => useContext(UserNavSettingsContext)

const emptyArray: any[] = []

export function useTrackRecentNavItems() {
  const { recentNavItems, setRecentNavItems } = useUserNavSettingsContext()
  const { mutateAsync } = useUpdateSetting()

  const recentNavItemsRef = useRef(recentNavItems)

  useEffect(() => {
    if (recentNavItems) {
      recentNavItemsRef.current = recentNavItems
    }
  }, [recentNavItems])

  const trackRecentNavItem = useCallback(
    async (pageKey: string) => {
      if (!pageKey) {
        return
      }

      const newRecents = [
        { page_key: pageKey, created_at: Date.now() },
        ...recentNavItemsRef.current.filter((i) => i.page_key !== pageKey).slice(0, 3)
      ]

      mutateAsync({
        setting_type: 'nav',
        key: 'recent_nav_items',
        value: newRecents
      }).catch(() => {
        // do nothing
      })

      setRecentNavItems(newRecents)
    },
    [mutateAsync, setRecentNavItems]
  )

  return { recentNavItems, trackRecentNavItem }
}
