import { Box, HStack, Icon, Link, Table, Tbody, Td, Th, Thead, Tooltip, Tr } from '@chakra-ui/react'
import { IconExternalLink } from '@tabler/icons-react'
import { uniq } from 'lodash'
import React from 'react'
import { PageView } from '../../../../types/Profile'
import { Card } from '../../../ui/Card'
import { projectPath } from '../../../ui/ProjectsContext'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { TimeAgo } from '../../../ui/TimeAgo'
import { humanize } from '../facets/filter-cloud'

interface AccountStatsProps {
  utm_page_views: PageView[]
  hover?: boolean
}

export function TrafficSources(props: AccountStatsProps) {
  const utmKeys = uniq(
    props.utm_page_views.reduce((acc, pv) => {
      return acc.concat(Object.keys(pv.utm ?? {}))
    }, [] as string[])
  )

  return (
    <Box as={Card} p="2" overflow={'auto'} maxH="300px">
      <Table size="sm" fontSize={'xs'}>
        <Thead>
          <Tr>
            <Th>Timestamp</Th>
            <Th>Path</Th>
            {utmKeys.map((key) => (
              <Th key={key}>{humanize(key)}</Th>
            ))}
            <Th>Referring Domain</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.utm_page_views.map((entry) => (
            <Tr key={entry.id}>
              <Td fontSize="xs">
                <TimeAgo time={entry.visit_start} />
              </Td>
              <Td fontSize="xs">
                <Link href={entry.url} isExternal fontWeight={'normal'}>
                  <HStack>
                    <TextEllipsis maxW="240px" tooltip>
                      {entry.path}
                    </TextEllipsis>
                    <IconExternalLink size="12" />
                  </HStack>
                </Link>
              </Td>
              {utmKeys.map((key) => (
                <Td key={key} fontSize="xs" fontWeight={'semibold'}>
                  <Tooltip label={entry.utm?.[key] ? `View all entries for ${key}=${entry.utm?.[key]}` : undefined}>
                    <Link
                      href={entry.utm?.[key] ? projectPath(`/utm-reports/utm/${key}/${entry.utm?.[key]}`) : undefined}
                      isExternal
                      role="group"
                    >
                      <HStack>
                        <TextEllipsis maxW="80px">{entry.utm?.[key]}</TextEllipsis>
                        {entry.utm?.[key] && (
                          <Icon
                            as={IconExternalLink}
                            size="12"
                            visibility={'hidden'}
                            _groupHover={{ visibility: 'visible' }}
                          />
                        )}
                      </HStack>
                    </Link>
                  </Tooltip>
                </Td>
              ))}
              <Td fontSize={'xs'}>
                <TextEllipsis tooltip maxW="150px">
                  {entry.referrer}
                </TextEllipsis>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  )
}
