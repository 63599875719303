import {
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Select,
  SkeletonCircle,
  SkeletonText,
  Stack,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { IconHelp } from '@tabler/icons-react'
import dayjs from 'dayjs'
import { format } from 'friendly-numbers'
import ms from 'ms'
import React, { useMemo, useState } from 'react'
import { ReactLiquid, useLiquid } from 'react-liquid'
import { toHTML } from 'slack-markdown'
import CompanyAvatar from '../../../../ui/CompanyAvatar'
import KoalaLogo from '../../../../ui/KoalaLogo'
import { SlackKV, SlackMessageTemplate } from './types'
import { NotificationVariables, Variables } from './use-notification-variables'

function isNumeric(value: string) {
  return /^\d+$/.test(value)
}

function isTime(value: string) {
  return dayjs(value).isValid()
}

function friendlyValue(key: string, value: string) {
  if (Array.isArray(value)) {
    return value.map((v) => friendlyValue(key, v)).join(', ')
  }

  if (key.includes('Focus Time') && value && isNumeric(value)) {
    return <Text>{ms(Math.max(parseInt(value), 1_000), { long: true })}</Text>
  }

  if (typeof value === 'number' || isNumeric(value)) {
    return <Text>{format(value)}</Text>
  }

  if (value && isTime(value)) {
    const time = dayjs(value)
    const ago = time.fromNow()
    return (
      <Stack spacing="0">
        <Text>{`about ${ago}`}</Text>
        <Text>{time.format('MMMM D, YYYY h:mm A')}</Text>
      </Stack>
    )
  }

  if (value === null || value === undefined || value === '' || value === 'null') {
    return <Text>--</Text>
  }

  return <Text>{value}</Text>
}

function KVLine({ kv, variables, isInvalid }: { kv: SlackKV; variables: Variables; isInvalid?: boolean }) {
  const liquified = useLiquid(kv[1], variables)

  if (liquified.status === 'idle' && liquified.markup) {
    return (
      <Stack
        w="100%"
        spacing="0"
        style={{
          opacity: isInvalid ? 0.5 : 1,
          textDecoration: isInvalid ? 'line-through' : 'none'
        }}
      >
        <Text fontWeight={'semibold'}>
          {kv[0]
            ?.replace('Account Salesforce', 'Salesforce')
            ?.replace('Account Hubspot', 'HubSpot')
            ?.replace('Focus Time', 'Session Time')
            ?.replace('Account Auto Icp Account Score ', '')
            ?.replace(' Month', ' (month)')}
          :
        </Text>
        {friendlyValue(kv[0], liquified.markup)}
      </Stack>
    )
  } else {
    return null
  }
}

export function MessagePreview(
  props: SlackMessageTemplate & NotificationVariables & { targetType: 'Account' | 'Profile' }
) {
  const [selectedSample, setSelectedSample] = useState(0)
  const { isLoading, samples, accountMappings: _accountMappings, variables: _var, ...template } = props

  const variables = useMemo(() => {
    if (isLoading) {
      return {}
    }

    return samples[selectedSample]
  }, [selectedSample, isLoading, samples])

  const markdownBlocks = useMemo(() => {
    return (template.content ?? []).filter((line) => {
      return typeof line === 'string'
    })
  }, [template]) as string[]

  const kvBlocks = useMemo(() => {
    return (template.content ?? []).filter((line) => {
      return Array.isArray(line)
    })
  }, [template]) as SlackKV[]

  if (isLoading) {
    return (
      <Stack bg="gray.50" p="8" borderLeftWidth={'thick'} w="600px">
        <HStack>
          <SkeletonCircle />
          <SkeletonText w="100%" noOfLines={2} />
        </HStack>
        <HStack py="4">
          <SkeletonText w="100%" noOfLines={4} />
          <SkeletonCircle />
        </HStack>
      </Stack>
    )
  }

  return (
    <>
      <Stack bg="gray.50" p="8" borderLeftWidth={'thick'} w="600px">
        <Heading size="xs">Preview</Heading>
        <Select
          size="xs"
          rounded="md"
          bg="white"
          defaultValue={0}
          onChange={(e) => {
            setSelectedSample(parseInt(e.target.value))
          }}
        >
          {samples
            .filter((s) => s.account)
            .map((sample, idx) => {
              return (
                <option value={idx} key={JSON.stringify(sample)}>
                  {sample.account.name ?? 'Unknown'} - {sample.visitor.display_name}
                </option>
              )
            })}
        </Select>
        <Stack
          bg="white"
          w="100%"
          p="4"
          pr="8"
          rounded="md"
          shadow={'md'}
          borderTopColor="gray.100"
          borderTopWidth="thin"
        >
          <HStack w="100%" alignItems={'flex-start'} spacing="4">
            <KoalaLogo kind="mark" size="28px" />
            <Stack w="100%" spacing="5">
              <Stack spacing={1} w="100%">
                <HStack spacing={1}>
                  <Heading fontSize="0.95rem" fontWeight="bold">
                    Koala
                  </Heading>
                  <Text
                    fontSize="10px"
                    fontWeight={600}
                    height="14px"
                    lineHeight="1.25"
                    borderRadius="2px"
                    bg="rgba(29, 28, 29, 0.13)"
                    color="rgba(29, 28, 29, 0.7)"
                    padding="1px 3px"
                  >
                    APP
                  </Text>
                  <Text fontSize="xs" color="GrayText" paddingX={1}>
                    1:00 PM
                  </Text>
                </HStack>
                <Heading size="sm" fontWeight={'bold'} pt="1">
                  <ReactLiquid template={props.title} data={variables ?? {}} />
                </Heading>
              </Stack>
              <HStack w="100%" alignItems={'flex-start'}>
                <Flex direction={'column'} flex="1" gap="2" fontSize={'sm'}>
                  {kvBlocks.map((kv) => {
                    const isInvalid = kv[1].startsWith('{{visitor') && props.targetType === 'Account'

                    return (
                      <HStack key={JSON.stringify(kv)} position="relative" spacing="0">
                        {isInvalid && (
                          <Tooltip
                            label={isInvalid ? 'Visitor variables are not available for Account Notifications' : ''}
                          >
                            <IconButton
                              position={'absolute'}
                              icon={<IconHelp size={14} />}
                              left="-8"
                              variant="ghost"
                              size="xs"
                              aria-label="Help"
                            />
                          </Tooltip>
                        )}
                        <KVLine kv={kv as SlackKV} isInvalid={isInvalid} variables={(variables ?? {}) as Variables} />
                      </HStack>
                    )
                  })}

                  {markdownBlocks.map((line) => {
                    return (
                      <Flex key={line} py="1" maxW="300px">
                        <ReactLiquid template={toHTML(line)} data={variables ?? {}} html />
                      </Flex>
                    )
                  })}
                </Flex>
                {props.img && (
                  <CompanyAvatar rounded="md" size="72px" src={(variables as Variables)?.account?.logo_url} />
                )}
              </HStack>

              <Divider />
              <ButtonGroup
                variant={'outline'}
                size="sm"
                as={Flex}
                pb={2}
                flexWrap="wrap"
                rowGap="2"
                columnGap={'0'}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
              >
                {(props.buttons ?? []).map((button) => (
                  <Button key={JSON.stringify(button)} colorScheme={button.style === 'primary' ? 'green' : undefined}>
                    {button.text}
                  </Button>
                ))}
              </ButtonGroup>
            </Stack>
          </HStack>
        </Stack>
      </Stack>
    </>
  )
}
