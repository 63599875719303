import { Icon, IconProps } from '@chakra-ui/react'
import React from 'react'

interface Props extends IconProps {
  size?: string | number
}

export function UserCircleIcon({ size, ...props }: Props) {
  size = size ? `${size}px` : size
  return (
    <Icon
      viewBox="0 0 512 512"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={0}
      width={size}
      height={size}
      {...props}
    >
      <path d="M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z"></path>
    </Icon>
  )
}

export function UserCircleDashedIcon({ size, ...props }: Props) {
  size = size ? `${size}px` : size
  return (
    <Icon
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      width={size}
      height={size}
      {...props}
    >
      <path d="M7.09714 4.45999C6.1064 5.10419 5.25222 5.93721 4.58337 6.91149M3.20076 10.1391C2.95453 11.2939 2.93797 12.486 3.15203 13.6473M4.45999 16.9029C5.1042 17.8936 5.93721 18.7478 6.91149 19.4166M10.1391 20.7992C11.2939 21.0455 12.486 21.062 13.6473 20.848M16.9029 19.54C17.8936 18.8958 18.7478 18.0628 19.4166 17.0885M20.7992 13.8609C21.0455 12.7061 21.062 11.514 20.848 10.3527M19.54 7.09714C18.8958 6.1064 18.0628 5.25222 17.0885 4.58337M13.8609 3.20076C12.7061 2.95453 11.514 2.93797 10.3527 3.15203" />
      <path d="M8.55585 20.3149C9.64778 20.7672 10.8181 21 12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149M6.168 18.849C6.41551 18.0252 6.92197 17.3032 7.61225 16.79C8.30252 16.2768 9.13985 15.9997 10 16H14C14.8613 15.9997 15.6996 16.2774 16.3904 16.7918C17.0812 17.3062 17.5875 18.0298 17.834 18.855M9 10C9 10.7956 9.31607 11.5587 9.87868 12.1213C10.4413 12.6839 11.2044 13 12 13C12.7956 13 13.5587 12.6839 14.1213 12.1213C14.6839 11.5587 15 10.7956 15 10C15 9.20435 14.6839 8.44129 14.1213 7.87868C13.5587 7.31607 12.7956 7 12 7C11.2044 7 10.4413 7.31607 9.87868 7.87868C9.31607 8.44129 9 9.20435 9 10Z" />
    </Icon>
  )
}
