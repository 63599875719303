import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Stack } from '@chakra-ui/react'
import React from 'react'
import { Apps } from '../../../types/App'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import { GrayCard } from '../../ui/Card'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { usePermission } from '../../ui/PermissionsContext'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { UserPicker } from './components/user-picker'

export interface Team {
  id: string
  name: string
  emails: string[]
}

interface Props {
  apps: Apps
  team: Team
  errors?: {
    [key: string]: string[]
  }
}

export default function NewTeam(props: Props) {
  const [submitting, setSubmitting] = React.useState(false)
  const [name, setName] = React.useState(props.team?.name ?? '')
  const nameError = name === (props.team?.name || '') ? props.errors?.name?.join(', ') : ''

  const { hasPermission: canManageMembers } = usePermission({ on: 'project', action: 'can_manage_members' })

  return (
    <PageLayout size="sm">
      <Flex pb="4">
        <SettingsBreadCrumb
          paths={[
            { path: projectPath('/settings/teams'), title: 'Teams' },
            { path: window.location.toString(), title: 'New Team' }
          ]}
        />
      </Flex>

      <SettingsHeader divider={false}>
        <PageTitle>Create a new team</PageTitle>
        <PageDescription>
          Create a new team to manage territory or role-specific settings for your reps. Team members will inherit
          default lists (e.g. accounts that are assigned to them, accounts that are unowned, etc). Team members will
          still be able to adjust their own notifications based on their preferences.
        </PageDescription>
      </SettingsHeader>

      <form action={projectPath('/settings/teams')} method="POST" onSubmit={() => setSubmitting(true)}>
        <AuthenticityToken />
        <GrayCard w="100%">
          <Stack spacing={8} w="100%">
            <FormControl id="team[name]" isInvalid={!!nameError}>
              <FormLabel>Team name</FormLabel>
              <Input
                type="text"
                bg="white"
                name="team[name]"
                placeholder="e.g. SDRs, APAC"
                isRequired
                value={name}
                onChange={(e) => {
                  setName(e.target.value)
                }}
              />
              <FormErrorMessage>{nameError}</FormErrorMessage>
            </FormControl>

            <UserPicker team={props.team} />
            <Box>
              <Button
                type="submit"
                size="sm"
                colorScheme="purple"
                isLoading={submitting}
                isDisabled={!canManageMembers}
              >
                Create team
              </Button>
            </Box>
          </Stack>
        </GrayCard>
      </form>
    </PageLayout>
  )
}
