import { pick } from 'lodash'
import { useQuery, useMutation } from 'react-query'
import { concurrentGET, del, post, put } from '../../lib/api'
import { FieldDefinition, PersistedFieldDefinition } from '../../types/FieldDefinition'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

export type { PersistedFieldDefinition }

interface FieldDefinitionsResponse {
  fields: Array<PersistedFieldDefinition>
}

export function useFieldDefinitions(recordType: 'account' | 'profile', opts?: { enabled: boolean }) {
  const project = useCurrentProject()
  const path = projectPath(`${recordType}s/fields`)

  return useQuery<unknown, unknown, FieldDefinitionsResponse>(
    ['fieldDefinitions', { projectId: project?.id, recordType }],
    () => concurrentGET(path),
    {
      enabled: Boolean(project?.id && recordType) && opts?.enabled !== false
    }
  )
}

interface CreateFieldDefinitionParams {
  field: Pick<FieldDefinition, 'label' | 'description' | 'record_type' | 'data_source' | 'data_type' | 'key_field'>
}

interface CreateFieldDefinitionResponse {
  field: PersistedFieldDefinition
  errors?: unknown
}

export function useCreateFieldDefinition() {
  return useMutation<CreateFieldDefinitionResponse, unknown, CreateFieldDefinitionParams>(({ field }) => {
    return post(projectPath(`/${field.record_type}s/fields`), { field_definition: field })
  })
}

interface UpdateFieldDefinitionParams {
  id: string
  field: Pick<FieldDefinition, 'label' | 'description' | 'data_source' | 'data_type' | 'key_field'>
}

interface UpdateFieldDefinitionResponse {
  field: PersistedFieldDefinition
  errors?: unknown
}

export function useUpdateFieldDefinition() {
  return useMutation<UpdateFieldDefinitionResponse, unknown, UpdateFieldDefinitionParams>(({ id, field }) =>
    put(projectPath(`/fields/${id}`), {
      field_definition: pick(field, ['label', 'description', 'data_source', 'data_type', 'key_field'])
    })
  )
}

interface UpdateFieldDefinitionsParams {
  recordType: string
  fields: Array<Pick<FieldDefinition, 'id' | 'key_field'>>
}

interface UpdateFieldDefinitionsResponse {
  fields: PersistedFieldDefinition[]
  errors?: unknown
}

export function useUpdateFieldDefinitions() {
  return useMutation<UpdateFieldDefinitionsResponse, unknown, UpdateFieldDefinitionsParams>(({ recordType, fields }) =>
    put(projectPath(`/${recordType}s/fields/update_multiple`), {
      field_definitions: fields.map((field) => pick(field, ['id', 'key_field']))
    })
  )
}

interface DeleteFieldDefinitionParams {
  id: string
}

interface DeleteFieldDefinitionResponse {
  id?: string
  success?: boolean
  errors?: unknown
}

export function useDeleteFieldDefinition() {
  return useMutation<DeleteFieldDefinitionResponse, unknown, DeleteFieldDefinitionParams>(({ id }) =>
    del(projectPath(`/fields/${id}`))
  )
}

interface ReorderFieldDefinitionParams {
  recordType: 'account' | 'profile'
  fieldIds: string[]
}

interface ReorderFieldDefinitionResponse {}

export function useReorderFieldDefinitions() {
  return useMutation<ReorderFieldDefinitionResponse, unknown, ReorderFieldDefinitionParams>(
    ({ recordType, fieldIds }) => {
      return post(projectPath(`/${recordType}s/fields/reorder`), {
        field_ids: fieldIds
      })
    }
  )
}
