import { Flex } from '@chakra-ui/react'
import React from 'react'
import type { ConditionGroupType, ConditionType } from '../types'
import Condition from './Condition'

interface ConditionGroupProps extends ConditionGroupType {
  onChangeCondition: (index: number, condition: Partial<ConditionType>) => void
  removeCondition: (conditionIndex: number) => void
  addCondition: (condition?: ConditionType) => void
  isRemovable?: boolean
}

export default function ConditionGroup(props: ConditionGroupProps) {
  return (
    <Flex direction="column" gap={8}>
      {(props.conditions || []).map((condition, i) => (
        <Condition
          key={condition.id || `${condition.kind}:${condition.property}:${i}`}
          condition={condition}
          onChangeCondition={(condition) => props.onChangeCondition(i, condition)}
          onRemoveCondition={() => props.removeCondition(i)}
          isRemovable={props.isRemovable}
        />
      ))}
    </Flex>
  )
}
