import { Flex, Icon, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Text } from '@chakra-ui/react'
import { IconExternalLink } from '@tabler/icons-react'
import * as React from 'react'
import { openWishForm } from './FeedbackForm'
import { projectPath, useCurrentProject } from './ProjectsContext'
import StatusAvatar from './StatusAvatar'
import { useCurrentUser } from './UserContext'

interface UserMenuProps {}

export function UserMenu(_props: UserMenuProps) {
  const user = useCurrentUser()
  const project = useCurrentProject()

  return (
    <Menu placement="bottom-end">
      <MenuButton
        width="100%"
        aria-label="User Menu"
        tabIndex={0}
        rounded="full"
        _focusVisible={{
          boxShadow: 'outline',
          outline: '2px solid transparent',
          outlineOffset: '2px'
        }}
      >
        <StatusAvatar size="28px" name={user.name} email={user.email} src={user.image} hideStatus />
      </MenuButton>
      <MenuList shadow="lg" zIndex="overlay">
        <Flex paddingX={3} paddingY={2} direction="column" w="100%">
          <Text fontWeight="medium">{user.name}</Text>
          <Text fontSize="sm" color="gray.600">
            {user.email}
          </Text>
        </Flex>
        <MenuDivider />

        <MenuItem as="a" href="/me">
          Edit profile
        </MenuItem>

        <MenuDivider />

        {project && (
          <MenuItem as="a" href={projectPath('/settings')}>
            Workspace settings
          </MenuItem>
        )}
        <MenuItem as="a" href="/join">
          Create or join workspace
        </MenuItem>

        <MenuDivider />

        <MenuItem onClick={() => openWishForm()}>Message Support</MenuItem>

        <MenuItem
          as="a"
          href="https://getkoala.com/docs"
          target="_blank"
          gap={1}
          justifyContent="space-between"
          alignItems="center"
        >
          Docs <Icon as={IconExternalLink} color="gray.400" boxSize={3.5} />
        </MenuItem>
        <MenuItem
          as="a"
          href="https://getkoala.com/blog?type=product-updates"
          target="_blank"
          gap={1}
          justifyContent="space-between"
          alignItems="center"
        >
          Changelog <Icon as={IconExternalLink} color="gray.400" boxSize={3.5} />
        </MenuItem>

        <MenuDivider />

        <MenuItem
          as="a"
          href="/logout"
          onClick={() => {
            window.ko?.reset()
          }}
        >
          Log out
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
