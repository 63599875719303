import { AlternateEmailsList } from '@app/components/ui/AlternateEmailsList'
import { Box, Button, Circle, Flex, Heading, HStack, Icon, Input, Stack, Text } from '@chakra-ui/react'
import { IconAt } from '@tabler/icons-react'
import React from 'react'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { LightBgCard } from '../../../ui/Card'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { useCurrentUser } from '../../../ui/UserContext'
import { MyAccountsProps } from '../mine'
import { AlternateUser } from './AlternateUser'

export function SetupAlternateEmail(props: MyAccountsProps) {
  const user = useCurrentUser()

  const suggested = React.useMemo(() => {
    const alternateEmails = user.alternate_emails?.map((a) => a.email)
    return (props.suggested_users ?? []).filter((u) => !alternateEmails?.includes(u.email))
  }, [props.suggested_users, user])

  const [selected, setSelected] = React.useState('')
  const toggleSelection = React.useCallback((selectedEmail: string) => {
    setSelected((prev) => {
      if (prev === selectedEmail) {
        return ''
      }

      return selectedEmail
    })
  }, [])

  const [submitting, setSubmitting] = React.useState(false)
  const [altEmail, setAltEmail] = React.useState('')
  const [manualEntry, setManualEntry] = React.useState(suggested.length === 0)

  return (
    <PageLayout>
      <PageTitle skipRendering>{props.account_view.name}</PageTitle>

      <LightBgCard maxWidth="700px" px={0} margin="auto" borderColor="gray.200" shadow="xl">
        <form method="POST" action="/me" onSubmit={() => setSubmitting(true)} data-koala-collect="off">
          <AuthenticityToken />
          <input type="hidden" name="_method" value="patch" />

          <Stack alignSelf="center" spacing={8}>
            <HStack spacing={4} px={6}>
              <Circle flex="none" size={8} backgroundColor="purple.50">
                <Icon as={IconAt} boxSize={4} color="purple.600" />
              </Circle>
              <Text
                fontSize="xs"
                textTransform="uppercase"
                letterSpacing="wider"
                fontWeight="medium"
                color="purple.600"
              >
                Setup Alternate Email
              </Text>
            </HStack>

            <Stack position="relative" spacing={8} px={6} pb={1}>
              <Stack>
                <Heading size="sm" mb={2}>
                  We couldn't find an account in your connected CRMs using your <i>confirmed</i> emails.
                </Heading>
                <AlternateEmailsList user={user} showHeader={false} showPrimaryEmail={true} allowDeletion={false} />
              </Stack>
              <Stack>
                <Heading size="sm">
                  Do you use another email for your {props.crms?.map((crm) => crm.title).join(' or ')} account?
                </Heading>
                <Text fontSize="sm" color="gray.600">
                  You can add an alternate email below to make sure we link the right account.{' '}
                  {user.alternate_emails?.some((e) => !e.confirmed) &&
                    'You may need to finish confirming your alternate emails. Check your inbox or resend the confirmation link.'}
                </Text>
                {suggested.length > 0 && !manualEntry && (
                  <Box>
                    <Text
                      position="sticky"
                      top={0}
                      fontSize="xs"
                      textTransform="uppercase"
                      fontWeight="medium"
                      color="gray.500"
                      bg="white"
                      py={2}
                    >
                      Suggested
                    </Text>
                    <Stack>
                      {suggested.map((u) => (
                        <AlternateUser
                          key={u.id}
                          {...u}
                          selected={selected === u.email}
                          onSelect={() => toggleSelection(u.email)}
                        />
                      ))}
                    </Stack>
                  </Box>
                )}
                {manualEntry ? (
                  <Box>
                    <Text
                      position="sticky"
                      top={0}
                      fontSize="xs"
                      textTransform="uppercase"
                      fontWeight="medium"
                      color="gray.500"
                      bg="white"
                      py={2}
                    >
                      Enter An Alternate Email
                    </Text>
                    <Input
                      name="user[alternate_emails_attributes][][email]"
                      value={altEmail}
                      type={'email'}
                      onChange={(e) => {
                        setSelected('')
                        setAltEmail(e.target.value)
                      }}
                    />
                  </Box>
                ) : (
                  <Box textAlign="center">
                    <Button variant="link" onClick={() => setManualEntry(true)}>
                      or enter manually
                    </Button>
                  </Box>
                )}
              </Stack>
            </Stack>
            <Flex px={6} justifyContent="flex-end">
              <Button
                type="submit"
                colorScheme="purple"
                size="sm"
                isDisabled={!selected && !altEmail}
                isLoading={submitting}
              >
                Save
              </Button>
            </Flex>
          </Stack>
        </form>
      </LightBgCard>
    </PageLayout>
  )
}
