import { Apps } from '@app/types/App'
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import React from 'react'
import { Space } from '../../../../types/Space'
import { useSlack } from '../../../data/use-slack'
import { SlackIcon } from '../../../ui/icons'
import { PopupConnectDialog } from '../../apps/components/ConnectOauthAppDialog'
import { DefinitionForm } from '../../slack_alerts/components/DefinitionForm'

interface Props extends UseDisclosureProps {
  space: Space
  apps?: Apps
}

// singular slack alert per space, configuration sidesheet
export function SlackAlertSidesheet(props: Props) {
  const disclosure = useDisclosure(props)
  const slackData = useSlack()
  const disconnected = !slackData.isLoading && !slackData.data?.connected

  const onSlackConnected = React.useCallback(() => {
    slackData.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slackData.refetch])

  const name = props.space.owner_id ? 'your Mission Control' : props.space.name

  return (
    <Drawer {...disclosure} placement="right" size="lg" preserveScrollBarGap>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />

        <DrawerHeader display="flex" alignItems="center" gap={2} fontSize="lg" fontWeight="medium">
          <SlackIcon boxSize={6} />
          Configure Slack Alerts for {name}
        </DrawerHeader>

        <DrawerBody pb="50px">
          {slackData.isLoading ? (
            <Spinner />
          ) : slackData.data && disconnected ? (
            <PopupConnectDialog app_id={slackData.data.app_id} onConnected={onSlackConnected}>
              {({ onStart }) => (
                <Stack
                  alignItems="center"
                  spacing={4}
                  padding={8}
                  border="1px solid"
                  borderColor="gray.300"
                  rounded="md"
                >
                  <Stack textAlign="center" spacing={2} paddingY={2} maxW="400px">
                    <Heading size="sm">Connect your Slack Account</Heading>
                    <Text fontSize="sm" color="gray.600">
                      Once you've connected Slack, you can get real-time alerts for intent signals from {name}.
                    </Text>
                  </Stack>
                  <Button size="sm" onClick={onStart} colorScheme="purple">
                    Connect Slack…
                  </Button>
                </Stack>
              )}
            </PopupConnectDialog>
          ) : (
            <DefinitionForm
              apps={props.apps}
              slack_alert={{
                enabled: true,
                project_id: props.space.project_id!,
                space_id: props.space.id,
                channel_routing: 'channel',
                ...props.space.slack_alert
              }}
              inline
              hideAudience
            />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
