import React, { useMemo, FormEvent, useState } from 'react'
import CompanyAvatar from '@app/components/ui/CompanyAvatar'
import Avatar from '../../../ui/Avatar'
import {
  useDisclosure,
  UseDisclosureProps,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Icon,
  Button,
  Flex,
  HStack,
  Spinner,
  Stack,
  FormControl,
  FormLabel,
  Heading,
  Link
} from '@chakra-ui/react'
import { toast } from 'sonner'
import { postForm } from '../../../../lib/api'
import { IconUser } from '@tabler/icons-react'
import { Prospect } from '../../../data/use-prospects'
import { useAppDep } from '../../../data/use-app-dep'
import { App } from '../../../../types/App'
import { Sequence } from '../../../../types/Outreach'
import { Account } from '../../../../types/Account'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { projectPath } from '../../../ui/ProjectsContext'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import { useOutreachSequences } from '../../../data/use-outreach-sequences'
import { useCurrentUser } from '../../../ui/UserContext'

interface AddToOutreachProps extends UseDisclosureProps {
  selectedProspects?: Prospect[]
  prospectsWithoutEmail?: Prospect[] | null
  app: App
  account?: Account
  profileType?: 'Profile' | 'ProspectedProfile'
}

export function AddToOutreachModal(props: AddToOutreachProps) {
  const { selectedProspects, prospectsWithoutEmail } = props
  const disclosure = useDisclosure(props)
  const onClose = disclosure.onClose
  const currentUser = useCurrentUser()
  const [saving, setSaving] = React.useState(false)

  const [selectedSequence, setSelectedSequence] = React.useState<Sequence | null>(null)
  const [selectedOwnerEmail, setSelectedOwnerEmail] = React.useState<string | null>(currentUser?.email ?? null)

  const {
    data: sequenceData,
    isLoading: loadingSequences,
    isError: sequencesError,
    error: sequencesErrorData
  } = useOutreachSequences(props.app.app_instance_id)

  const { data: usersData, isLoading: loadingUsers } = useAppDep<'users', any[]>('Outreach', 'users')

  const scopes = useAppDep<'scopes', string[]>('Outreach', 'scopes')
  const hasSequenceScope = React.useMemo(() => {
    return scopes.data?.data.scopes.includes('batches.all') || scopes.data?.data.scopes.includes('batches.write')
  }, [scopes.data])

  const selectedOwner = useMemo(
    () =>
      usersData?.data?.users?.find((s) => {
        return s.attributes.email.toString() === selectedOwnerEmail?.toString()
      }) ?? null,
    [usersData, selectedOwnerEmail]
  )

  const seq = useMemo(
    () => selectedSequence ?? sequenceData?.outreach_sequences?.[0] ?? null,
    [selectedSequence, sequenceData]
  )

  const onSubmit = React.useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      if (!seq) return

      setSaving(true)

      const form = e.target as HTMLFormElement
      const data = new FormData(form)
      const profileIds = selectedProspects?.map((p) => p.id) ?? []
      profileIds.forEach((profileId) => data.append('profile_ids[]', profileId))

      postForm(projectPath(`/outreach/actions/bulk-sequences?&profile_type=${props.profileType ?? 'Profile'}`), data)
        .then(() => {
          toast.success('Prospects added to Sequence!', {
            description: `${selectedProspects?.length} contact${selectedProspects && selectedProspects?.length > 1 ? 's' : ''} has been added to ${seq.name} in Outreach.`
          })
          setSaving(false)
          onClose()
        })
        .catch((e) => {
          toast.error('Error adding prospects to sequence', {
            description: e.message
          })
          setSaving(false)
        })
    },
    [seq, selectedProspects, props.profileType, onClose]
  )

  const [showMoreProspects, setShowMoreProspects] = useState(false)
  const toggleShowMoreProspects = () => {
    setShowMoreProspects(!showMoreProspects)
  }

  return (
    <Modal {...disclosure} size="md" isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        {hasSequenceScope ? (
          <>
            <ModalHeader fontSize="md" display="flex" gap={2} pb={1}>
              <CompanyAvatar size="20px" domain={'outreach.ai'} mr={2} src={props.app?.logo} />
              Add to Outreach sequence
            </ModalHeader>
            <ModalBody pb={6}>
              {selectedProspects && (
                <Text mb={6}>
                  <Icon as={IconUser} boxSize={4} color="gray.400" /> {selectedProspects.length} contacts
                </Text>
              )}
              <form onSubmit={onSubmit}>
                <Stack fontSize="sm" spacing={4}>
                  <FormControl>
                    <FormLabel fontWeight="bold">Sequence</FormLabel>
                    {loadingSequences && <Spinner size="sm" />}
                    {sequencesErrorData && (
                      <Text p="4" bg="orange.50" m="2">
                        {' '}
                        {(sequencesErrorData as any)?.body?.error}
                      </Text>
                    )}
                    {!sequencesError && !loadingSequences && (
                      <ComboboxWithSearch
                        items={sequenceData?.outreach_sequences ?? []}
                        selectedItem={seq}
                        onChange={(selectedItem) => {
                          setSelectedSequence(selectedItem)
                        }}
                        filterItem={(a, val) => a.name.toLowerCase().includes(val)}
                        itemToString={(item) => item?.name || ''}
                      />
                    )}
                  </FormControl>

                  <FormControl>
                    <FormLabel fontWeight="bold">Send Emails From</FormLabel>
                    {loadingUsers && <Spinner size="sm" />}
                    {!loadingUsers && !usersData?.data?.users?.length && (
                      <Text p="4" bg="orange.50" m="2">
                        We couldn't find any email accounts to display. Make sure to check the API key permissions in
                        your Outreach workspace.
                      </Text>
                    )}
                    <ComboboxWithSearch
                      items={usersData?.data.users ?? ['']}
                      selectedItem={selectedOwner}
                      onChange={(selectedItem) => {
                        setSelectedOwnerEmail(selectedItem.attributes.email)
                      }}
                      filterItem={(a, val) => (a?.attributes?.email ?? '').toLowerCase().includes(val)}
                      itemToString={(item) => item?.attributes?.email || ''}
                    />
                  </FormControl>
                </Stack>
                <Stack fontSize="sm" spacing={2} mt={4}>
                  <Text fontSize="xs" color="gray.500" fontWeight="bold">
                    CONTACTS:
                  </Text>
                  <Flex direction="column" alignItems="space-between" gap={3}>
                    {prospectsWithoutEmail && prospectsWithoutEmail.length && (
                      <Stack spacing="4" p="2">
                        <Text fontSize="sm" color="gray.600" lineHeight="1.4">
                          You've selected {prospectsWithoutEmail.length} prospect{''}
                          {prospectsWithoutEmail.length > 1 ? 's' : ''} whose emails have not been enriched yet. They
                          will be enriched if you proceed and will use enrichment credits in the future.
                        </Text>
                        <Text
                          whiteSpace={'pre-wrap'}
                          fontSize="xs"
                          lineHeight="1.4"
                          p="4"
                          bg="purple.50"
                          color="gray.700"
                        >
                          Note: we're currently offering unlimited Koala Waterfall enrichment credits during our open
                          beta.
                        </Text>
                      </Stack>
                    )}
                    {selectedProspects && (
                      <>
                        {selectedProspects.length > 5 ? (
                          <>
                            {selectedProspects?.slice(0, 4).map((selectedProspect) => (
                              <Flex key={selectedProspect.id} justifyContent="space-between" alignItems="center">
                                <HStack>
                                  <Avatar
                                    size="xs"
                                    name={selectedProspect.name}
                                    src={projectPath(`/prospects/${selectedProspect.id}/avatar`)}
                                  />
                                  <Text>
                                    {selectedProspect.first_name} {selectedProspect.last_name}
                                  </Text>
                                </HStack>
                                <TextEllipsis fontSize="xs" color="gray.600" maxW="50%">
                                  {selectedProspect.title}
                                </TextEllipsis>
                              </Flex>
                            ))}
                            {showMoreProspects &&
                              selectedProspects?.slice(4, selectedProspects.length).map((selectedProspect) => (
                                <Flex key={selectedProspect.id} justifyContent="space-between" alignItems="center">
                                  <HStack>
                                    <Avatar
                                      size="xs"
                                      name={selectedProspect.name}
                                      src={projectPath(`/prospects/${selectedProspect.id}/avatar`)}
                                    />
                                    <Text>
                                      {selectedProspect.first_name} {selectedProspect.last_name}
                                    </Text>
                                  </HStack>
                                  <TextEllipsis fontSize="xs" color="gray.600" maxW="50%">
                                    {selectedProspect.title}
                                  </TextEllipsis>
                                </Flex>
                              ))}
                            <Text onClick={toggleShowMoreProspects} color="purple.500" align="center" cursor="pointer">
                              {showMoreProspects
                                ? `- Hide ${selectedProspects.length - 4} contacts`
                                : `+ Show ${selectedProspects.length - 4} more contacts`}
                            </Text>
                          </>
                        ) : (
                          <>
                            {selectedProspects.map((selectedProspect) => (
                              <Flex key={selectedProspect.id} justifyContent="space-between" alignItems="center">
                                <HStack>
                                  <Avatar
                                    size="xs"
                                    name={selectedProspect.name}
                                    src={projectPath(`/prospects/${selectedProspect.id}/avatar`)}
                                  />
                                  <Text>
                                    {selectedProspect.first_name} {selectedProspect.last_name}
                                  </Text>
                                </HStack>
                                <TextEllipsis fontSize="xs" color="gray.600" maxW="50%">
                                  {selectedProspect.title}
                                </TextEllipsis>
                              </Flex>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </Flex>
                </Stack>
                <Flex w="100%" pt="4">
                  <Button
                    w="100%"
                    size="sm"
                    colorScheme="purple"
                    type="submit"
                    isLoading={saving || loadingSequences}
                    isDisabled={sequencesError || !usersData?.data?.users?.length}
                  >
                    Add to Outreach Sequence
                  </Button>
                </Flex>
                {seq && <input type="hidden" name="sequence[sequence_id]" value={seq.outreach_id} />}
                {props.account && <input type="hidden" name="sequence[account_id]" value={props.account.id} />}
                {selectedOwnerEmail && <input type="hidden" name="sequence[owner_email]" value={selectedOwnerEmail} />}
              </form>
            </ModalBody>
          </>
        ) : (
          <Stack p={4} gap={4}>
            <Heading size="sm" fontWeight="semibold">
              Missing Sequence Management Permissions
            </Heading>
            <Text>
              Your Outreach connection is missing sequence management permissions. Please reconnect Outreach in order to
              add support for Sequence Management from within Koala.
            </Text>
            <Button
              as={Link}
              variant="outline"
              href={projectPath(`/apps/outreach?return_to=${encodeURIComponent(window.location.pathname)}`)}
            >
              Reconnect Outreach
            </Button>
          </Stack>
        )}
      </ModalContent>
    </Modal>
  )
}
