import React from 'react'
import CompanyAvatar, { CompanyAvatarProps } from '../CompanyAvatar'

interface Props extends CompanyAvatarProps {}

export const Rb2bIcon: React.FC<Props> = ({ ...props }) => {
  return (
    <CompanyAvatar
      src={'https://retention.com/wp-content/uploads/2023/03/retention-fav-ico.svg'}
      domain={'rb2b.com'}
      {...props}
    />
  )
}
