import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'sonner'
import { concurrentGET, put } from '../../lib/api'
import { ExcludedAccountsProps } from '../pages/excluded_accounts/show'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Params {
  domain: string
}

export function useHiddenAccounts() {
  const project = useCurrentProject()
  const basePath = '/settings/excluded-accounts'

  const path = projectPath(basePath)

  return useQuery<ExcludedAccountsProps>(
    ['excluded-accounts', { projectId: project?.id }],
    () => concurrentGET<ExcludedAccountsProps>(path),
    {
      enabled: Boolean(project?.id)
    }
  )
}

export function useHideAccount() {
  const onSuccess = React.useCallback(() => {
    toast.success('You hid this account!')
  }, [])

  const onError = React.useCallback(() => {
    toast.error('There was an issue hiding this account.')
  }, [])

  return useMutation(
    ({ domain }: Params) => {
      const path = projectPath(`/accounts/${domain}/actions/hide`)
      return put(path, {})
    },
    {
      onSuccess,
      onError
    }
  )
}

export function useRestoreAccount() {
  const onSuccess = React.useCallback(() => {
    toast.success('You restored this account!')
  }, [])

  const onError = React.useCallback(() => {
    toast.error('There was an issue restoring this account.')
  }, [])

  return useMutation(
    ({ domain }: Params) => {
      const path = projectPath(`/accounts/${domain}/actions/restore`)
      return put(path, {})
    },
    {
      onSuccess,
      onError
    }
  )
}
