import {
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Link,
  MenuButton,
  Stat,
  StatLabel,
  StatNumber,
  Text
} from '@chakra-ui/react'
import { IconChevronDown, IconChevronLeft, IconFileText } from '@tabler/icons-react'
import { format } from 'friendly-numbers'
import React from 'react'
import { PageMeta } from '../../../types/PageMeta'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SegmentedControl } from '../../ui/SegmentedControl'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import { TableFooter } from '../../ui/TableFooter'
import { TimeAgo } from '../../ui/TimeAgo'
import useLatestRef from '../../ui/useLatestRef'
import { formsPath } from '../forms/path-helpers'
import { ActionsCell, AutoTrackedForm } from '../forms/reports'
import { FormSubmission, SubmissionList } from '../forms/reports/submission-list'
import { Trendline } from '../icps/icp/breakdown'
import { mergeParams, Trend } from '../icps/types'

interface Props {
  submissions: FormSubmission[]
  form: AutoTrackedForm
  page_meta: PageMeta
  page_path: string
  trend: Trend
  range: 'day' | 'week' | 'month'
}

export default function Index(props: Props) {
  const form = props.form
  let formattedConversionRate = '--'
  let conversion = 0
  let conversionColor = 'gray.800'

  if (form?.views > 0) {
    conversion = Math.min((form.count * 100) / form.views, 100)
    formattedConversionRate = `${format(conversion)}%`
    if (conversion >= 5) {
      conversionColor = 'green.500'
    } else {
      conversionColor = 'orange.500'
    }
  }

  const location = useLatestRef(window.location.toString())
  const range = props.range

  return (
    <PageLayout size="full">
      <SettingsBreadCrumb
        paths={[
          { title: 'Forms', path: projectPath('/forms/reports') },
          {
            title: form?.page_path || 'Submissions',
            path: window.location.toString()
          }
        ]}
        offscreen
      />

      <PageTitle skipRendering>Forms - {form?.page_path.replace('/', '').replace(/\//g, '-')}</PageTitle>
      <HStack w="100%" justifyContent={'space-between'} py="4" spacing={4}>
        <IconButton
          as={Link}
          href={formsPath(`/reports?range=${range}`)}
          icon={<IconChevronLeft size="18" />}
          aria-label="Back"
          variant={'ghost'}
          size="sm"
        />
        <HStack w="100%">
          <IconFileText />
          <Heading
            maxW={'75%'}
            size={props.page_path.length > 50 ? 'md' : 'lg'}
            fontWeight={props.page_path.length > 50 ? 'semibold' : 'bold'}
          >
            {props.page_path}
          </Heading>
        </HStack>

        <HStack>
          <HStack w="100%" justifyContent={'space-between'}>
            <SegmentedControl size="sm">
              <Button isActive={range === 'day'} as={Link} href={mergeParams(location.current, { range: 'day' })}>
                Day
              </Button>
              <Button isActive={range === 'week'} as={Link} href={mergeParams(location.current, { range: 'week' })}>
                Week
              </Button>
              <Button isActive={range === 'month'} href={mergeParams(location.current, { range: 'month' })} as={Link}>
                Month
              </Button>
            </SegmentedControl>
          </HStack>
        </HStack>

        {form && (
          <Flex>
            <ActionsCell
              filters={{
                ignored: false,
                range: 'week'
              }}
              form={form}
            >
              <MenuButton as={Button} variant="outline" size="sm" rightIcon={<IconChevronDown size={16} />}>
                Actions
              </MenuButton>
            </ActionsCell>
          </Flex>
        )}
      </HStack>

      <HStack spacing="8" wrap={'wrap'} p="8">
        <Trendline
          color="purple"
          range="month"
          trend={props.trend}
          width={400}
          svgWidth={400}
          height={44}
          svgHeight={44}
        />
        <Stat>
          <StatLabel>Views</StatLabel>
          <StatNumber>{form ? format(form.views) : '--'}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Submissions</StatLabel>
          <StatNumber color="purple.500" fontWeight={'bold'}>
            {form ? format(form.count) : '--'}
          </StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Conversion Rate</StatLabel>
          <StatNumber color={conversionColor}>{formattedConversionRate}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Last Submitted</StatLabel>
          <StatNumber color="gray.400">
            <TimeAgo time={form?.last_submission} fallback="--" />
          </StatNumber>
        </Stat>
      </HStack>

      {props.submissions.length > 0 && <SubmissionList page_meta={props.page_meta} submissions={props.submissions} />}
      {props.submissions.length === 0 && <Text>No submissions for the selected time period.</Text>}

      <TableFooter
        pageMeta={props.page_meta}
        page={props.page_meta.current_page}
        prevPath={mergeParams(location.current, { page: props.page_meta.prev_page?.toString() })}
        nextPath={mergeParams(location.current, { page: props.page_meta.next_page?.toString() })}
        sticky
      />
    </PageLayout>
  )
}
