import { useCallback } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'sonner'
import { post } from '../../lib/api'
import type { ProfileRecord } from '../../types/Profile'
import { profilePath } from '../pages/profiles/lib/path'

interface Props {
  onSuccess?: (profile: ProfileRecord) => void
  onSettled?: () => void
}

interface Params {
  profileId: string
}

interface Response {
  profile: ProfileRecord
}

function hasLocation(profile: ProfileRecord) {
  const geoText = profile.simple_location || profile.person?.location
  return Boolean(profile.simple_geo?.state && geoText)
}

export function useGeocode({ onSuccess, onSettled }: Props = {}) {
  const handleSuccess = useCallback(
    (res: Response) => {
      if (!hasLocation(res.profile)) {
        toast.message('We could not find more geographic information for this user.')
      }
      onSuccess?.(res.profile)
    },
    [onSuccess]
  )

  const handleSettled = useCallback(() => {
    onSettled?.()
  }, [onSettled])

  return useMutation(
    ({ profileId }: Params) => {
      const path = profilePath({ id: profileId }, '/actions/geocode')
      return post<Response>(path)
    },
    {
      onSuccess: handleSuccess,
      onSettled: handleSettled
    }
  )
}
