import { Circle, Tag, TagProps, Tooltip, useTheme } from '@chakra-ui/react'
import React, { ReactElement } from 'react'
import { hashCode } from '../../lib/string-to-hash'
import { theme } from '../theme'

const colors = [
  'blue',
  'purple',
  'teal',
  'yellow',
  'red',
  'cyan',
  'orange',
  'green',
  'pink',
  'slate',
  'amber',
  'indigo',
  'fuschia'
]

export interface BubbleTagProps extends TagProps {
  label?: string
  value?: ReactElement | string
  color?: string
  tooltip?: boolean
}

export function bubbleColor(label: string) {
  const hash = hashCode(label)
  return theme.colors[colors[hash % colors.length]]
}

export function BubbleTag({
  children,
  size = 'sm',
  label,
  value,
  color,
  tooltip,
  variant = 'outline',
  ...props
}: BubbleTagProps) {
  const theme = useTheme()
  const hash = React.useMemo(() => hashCode(label || value), [label, value])
  const colorScheme = props.colorScheme || colors[hash % colors.length]
  const dotColor = color || theme.colors[colorScheme]['400']
  const spacing = size === 'sm' ? 1 : 1.5
  const tooltipLabel = tooltip
    ? [typeof value === 'string' ? value : undefined, label].filter(Boolean).join(' ')
    : undefined

  return (
    <Tooltip label={tooltipLabel}>
      <Tag
        size={size}
        flex="none"
        whiteSpace="nowrap"
        variant={variant}
        borderRadius={size === 'sm' ? 'md' : 'lg'}
        boxShadow="none"
        colorScheme={variant === 'subtleBorder' ? colorScheme : props.colorScheme}
        paddingX={spacing}
        gap={0.5}
        {...props}
      >
        {children || (
          <>
            <Circle size={size === 'lg' ? '10px' : '8px'} bg={dotColor} marginRight={spacing} />
            {value}
          </>
        )}
      </Tag>
    </Tooltip>
  )
}
