import { FormControl, FormHelperText, Stack, Switch } from '@chakra-ui/react'
import { nanoid } from 'nanoid'
import React, { useMemo } from 'react'
import { OutreachFieldMapper, OutreachMapping } from './outreach-field-mapper'
import { OutreachActionProps } from './outreach-setup'

export function ImportContact(props: OutreachActionProps) {
  const [enabled, setEnabled] = React.useState(Boolean(props.delivery_rules?.outreach?.import_contact?.enabled))

  const isPersisted = useMemo(
    () => !!props.delivery_rules?.outreach?.import_contact?.fields?.length,
    [props.delivery_rules?.outreach?.import_contact]
  )

  const suggestions: OutreachMapping[] = useMemo(() => {
    return [
      {
        id: nanoid(),
        koala: 'visitor.email',
        outreach: 'emails'
      },
      {
        id: nanoid(),
        koala: 'visitor.id',
        outreach: 'externalId'
      },
      {
        id: nanoid(),
        koala: 'visitor.first_name',
        outreach: 'firstName'
      },
      {
        id: nanoid(),
        koala: 'visitor.last_name',
        outreach: 'lastName'
      },
      {
        id: nanoid(),
        koala: 'visitor.title',
        outreach: 'title'
      }
    ]
  }, [])

  return (
    <Stack width="100%">
      <FormControl>
        <Switch
          isChecked={enabled}
          onChange={(e) => {
            setEnabled(e.target.checked)
            props.setDeliveryRules({
              ...props.delivery_rules,
              outreach: {
                ...props.delivery_rules?.outreach,
                import_contact: {
                  ...props.delivery_rules?.outreach?.import_contact,
                  enabled: e.target.checked
                }
              }
            })
          }}
          size="sm"
          fontSize={'sm'}
          fontWeight="semibold"
          colorScheme={'purple'}
          value={enabled ? 'true' : 'false'}
          name="follow_rule[delivery_rules][outreach][import_contact][enabled]"
        >
          Enable
        </Switch>
        <FormHelperText>Import the Visitor as a Prospect in Outreach if it doesn't already exist.</FormHelperText>
      </FormControl>

      {props.actionSchema && (
        <OutreachFieldMapper
          namespace="follow_rule[delivery_rules][outreach][import_contact]"
          actionSchema={props.actionSchema}
          type="prospect"
          mappings={props.delivery_rules?.outreach?.import_contact?.fields ?? (isPersisted ? suggestions : [])}
          suggestions={suggestions}
          deps={props.deps}
        />
      )}
    </Stack>
  )
}
