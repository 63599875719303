import { Button, Flex, Link, Stack, Text } from '@chakra-ui/react'
import { IconChevronRight } from '@tabler/icons-react'
import React from 'react'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { projectPath } from '../../../ui/ProjectsContext'

interface Props {
  skip: () => void
}

export function ClearbitForm(props: Props) {
  const [submitting, setSubmitting] = React.useState(false)

  return (
    <Flex direction="column" gap={4}>
      <form
        action={projectPath(`/apps/clearbit?return_to=${encodeURIComponent(window.location.pathname)}`)}
        method="POST"
        data-koala-collect="off"
        onSubmit={() => setSubmitting(true)}
      >
        <AuthenticityToken />
        <input type="hidden" name="_method" value="PUT" />
        <input type="hidden" name="app_instance_settings[use_child_key]" value="true" />

        <Stack spacing="4">
          <Button type="submit" colorScheme="purple" size="lg" fontSize="md" width="full" isLoading={submitting}>
            Continue for free
          </Button>
          <Text fontSize="xs" color="gray.600" textAlign="center" fontStyle="italic">
            By using Powered by Clearbit in Koala, you agree to Clearbit's{' '}
            <Link href="https://clearbit.com/privacy-policy" isExternal>
              Privacy Policy
            </Link>{' '}
            and{' '}
            <Link href="https://clearbit.com/legal" isExternal>
              Terms
            </Link>
            .
          </Text>
        </Stack>
      </form>

      <Button
        w="100%"
        variant="ghost"
        flex="none"
        size="md"
        marginTop={8}
        onClick={props.skip}
        rightIcon={<IconChevronRight size={14} />}
        isDisabled={submitting}
      >
        Connect later
      </Button>
    </Flex>
  )
}
