import { useEffect, useRef } from 'react'

export function RootCssVar({ property, value }: { property: string; value: string }) {
  const og = useRef<string>()

  useEffect(() => {
    const root = document.querySelector<HTMLElement>(':root')

    if (!root) {
      return
    }

    og.current = getComputedStyle(root).getPropertyValue(property)

    // set css variable
    root.style.setProperty(property, value)

    return () => {
      // reset css variable
      if (og.current) {
        root.style.setProperty(property, og.current)
      } else {
        root.style.removeProperty(property)
      }
    }
  }, [property, value])

  return null
}
