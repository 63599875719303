import {
  Button,
  Code,
  Divider,
  Heading,
  HStack,
  Icon,
  Image,
  Link,
  ListItem,
  OrderedList,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconChevronRight, IconExternalLink } from '@tabler/icons-react'
import React from 'react'
import { Card } from '../../ui/Card'
import CompanyAvatar from '../../ui/CompanyAvatar'
import { Copyable } from '../../ui/Copyable'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { Toggle } from '../accounts/components/Toggle'

export default function UTMIds() {
  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        paths={[
          {
            path: projectPath('/settings/utm-ids'),
            title: 'Koala UTM IDs'
          }
        ]}
        offscreen
      />

      <SettingsHeader>
        <PageTitle>Koala UTM IDs</PageTitle>
        <PageDescription>
          Automatically Identify prospects that are opening your outreach messages and visiting your website.
        </PageDescription>
      </SettingsHeader>

      <Stack spacing={10} divider={<Divider />}>
        <Stack spacing="4">
          <Stack spacing="1" pb="4">
            <Heading size="md">Installation</Heading>
            <Stack>
              <Text fontSize="sm" color="gray.500">
                You can integrate Koala UTM IDs with your outreach tool of choice via our plugins, or manually updating
                your sales and marketing templates. Prospects will be automatically identified when they visit your
                website from a link with a Koala UTM ID.
              </Text>
            </Stack>
          </Stack>

          <UTMCard
            domain="outreach.io"
            title="Install via Outreach Plugin"
            subtitle="Automatically track when a prospect visits your website from a link."
          >
            <HStack spacing="1" fontSize={'sm'} pt="2">
              <Text>Install from the</Text>
              <Button
                size="sm"
                variant={'link'}
                rightIcon={<IconExternalLink size="12" />}
                as={Link}
                color="purple.500"
                isExternal
                href="https://marketplace.outreach.io/apps/koala-id-links?store=unlisted"
              >
                Outreach Marketplace
              </Button>
            </HStack>

            <Text fontSize="xs" color="gray.500" pt="2">
              Note: You need to be logged in to Outreach in order to be able to install the plugin from the App
              Marketplace. No further action required after you've installed the plugin.
            </Text>
          </UTMCard>

          <UTMCard
            domain="hubspot.com"
            title="HubSpot Email Templates & Sequences"
            subtitle="Update your templates to add Koala UTM IDs to your links."
          >
            <Stack py="4">
              <Heading size="xs">Instructions:</Heading>
              <OrderedList fontSize="sm" color="gray.500" spacing="6">
                <Stack spacing="1">
                  <ListItem color="gray.800">1. Open the email template editor:</ListItem>
                  <ListItem pl="4">a) For Sales templates: Select Conversations, then Templates</ListItem>
                  <ListItem pl="4">b) For Marketing templates: Select Marketing, then Emails</ListItem>
                </Stack>

                <Stack>
                  <ListItem color="gray.800">2. Copy one of the following UTM Links:</ListItem>
                  <HStack w="100%" spacing="8">
                    <Stack spacing="1" w="100%">
                      <Code rounded="md" fontWeight="semibold" w="100%">
                        <Copyable>
                          <Text
                            as="span"
                            p="2"
                            dangerouslySetInnerHTML={{
                              __html: `?ko_e={{contact.email}}&k_is=hs`
                            }}
                          ></Text>
                        </Copyable>
                      </Code>
                      <Text>If there are no other URL params</Text>
                    </Stack>

                    <Stack spacing="1" w="100%">
                      <Code rounded="md" fontWeight="semibold" w="100%">
                        <Copyable>
                          <Text
                            as="span"
                            p="2"
                            dangerouslySetInnerHTML={{
                              __html: `&ko_e={{contact.email}}&k_is=hs`
                            }}
                          ></Text>
                        </Copyable>
                      </Code>
                      <Text>If there are other URL params</Text>
                    </Stack>
                  </HStack>
                </Stack>

                <Stack>
                  <ListItem color="gray.800">3. Add the Koala UTMs to links in your templates:</ListItem>
                  <Image src="/images/koala_utms/hubspot.jpeg" />
                </Stack>

                <ListItem color="gray.800">
                  <strong>💡 Note:</strong> Koala UTMs also work with your snippets. We recommend creating a handful of
                  snippets that you can use for one-off emails.
                </ListItem>
              </OrderedList>
            </Stack>
          </UTMCard>

          <UTMCard
            domain="apollo.io"
            title="Apollo Sequences"
            subtitle="Update your sequence templates to add Koala UTM IDs to your links."
          >
            <Stack py="4">
              <Heading size="xs">Instructions:</Heading>
              <OrderedList fontSize="sm" color="gray.500" spacing="6">
                <Stack spacing="1">
                  <ListItem color="gray.800">1. Open the Sequences email template editor:</ListItem>
                  <ListItem pl="4">a) Select Engage, then Sequences</ListItem>
                </Stack>

                <Stack>
                  <ListItem color="gray.800">2. Copy one of the following UTM Links:</ListItem>
                  <HStack w="100%" spacing="8">
                    <Stack spacing="1" w="100%">
                      <Code rounded="md" fontWeight="semibold" w="100%">
                        <Copyable>
                          <Text
                            as="span"
                            p="2"
                            dangerouslySetInnerHTML={{
                              __html: `?ko_e={{email}}&k_is=apl`
                            }}
                          ></Text>
                        </Copyable>
                      </Code>
                      <Text>If there are no other URL params</Text>
                    </Stack>

                    <Stack spacing="1" w="100%">
                      <Code rounded="md" fontWeight="semibold" w="100%">
                        <Copyable>
                          <Text
                            as="span"
                            p="2"
                            dangerouslySetInnerHTML={{
                              __html: `&ko_e={{email}}&k_is=apl`
                            }}
                          ></Text>
                        </Copyable>
                      </Code>
                      <Text>If there are other URL params</Text>
                    </Stack>
                  </HStack>
                </Stack>

                <Stack>
                  <ListItem color="gray.800">3. Add the Koala UTMs to links in your templates:</ListItem>
                  <Image src="/images/koala_utms/apollo.jpg" />
                </Stack>

                <ListItem color="gray.800">
                  <strong>💡 Note:</strong> Koala UTMs also work with your snippets. We recommend creating a handful of
                  snippets that you can use for one-off emails.
                </ListItem>
              </OrderedList>
            </Stack>
          </UTMCard>

          <UTMCard
            domain="salesloft.com"
            title="Salesloft Cadences"
            subtitle="Update your templates to add Koala UTM IDs to your links."
          >
            <Stack py="4">
              <Heading size="xs">Instructions:</Heading>
              <OrderedList fontSize="sm" color="gray.500" spacing="6">
                <Stack spacing="1">
                  <ListItem color="gray.800">1. Open the email template editor:</ListItem>
                  <ListItem pl="4">
                    a) As described in the docs{' '}
                    <Link
                      isExternal
                      href="https://help.salesloft.com/s/article/Create-a-Template?language=en_US#Create_New_Template"
                    >
                      here
                    </Link>
                    .
                  </ListItem>
                </Stack>

                <Stack>
                  <ListItem color="gray.800">2. Copy one of the following UTM Links:</ListItem>
                  <HStack w="100%" spacing="8">
                    <Stack spacing="1" w="100%">
                      <Code rounded="md" fontWeight="semibold" w="100%">
                        <Copyable>
                          <Text
                            as="span"
                            p="2"
                            dangerouslySetInnerHTML={{
                              __html: `?ko_e={{email}}&k_is=sl`
                            }}
                          ></Text>
                        </Copyable>
                      </Code>
                      <Text>If there are no other URL params</Text>
                    </Stack>

                    <Stack spacing="1" w="100%">
                      <Code rounded="md" fontWeight="semibold" w="100%">
                        <Copyable>
                          <Text
                            as="span"
                            p="2"
                            dangerouslySetInnerHTML={{
                              __html: `&ko_e={{email}}&k_is=sl`
                            }}
                          ></Text>
                        </Copyable>
                      </Code>
                      <Text>If there are other URL params</Text>
                    </Stack>
                  </HStack>
                </Stack>

                <ListItem color="gray.800">
                  <strong>💡 Note:</strong> Koala UTMs also work with your snippets. We recommend creating a handful of
                  snippets that you can use for one-off emails.
                </ListItem>
              </OrderedList>
            </Stack>
          </UTMCard>

          <UTMCard
            domain="getkoala.com"
            title="Koala Email Param"
            subtitle="Use the Koala UTM param manually to tag your own emails with ID Links."
          >
            <Stack py="4" fontSize={'sm'} spacing="4">
              <Text>
                You can tag any outbound links on any platform using the Koala Email param. All you need to is add ko_e
                or utm_id to any URL you'd like to be tracked when a prospect clicks it:
              </Text>

              <Divider />

              <Heading size="xs">Choose one of two flavors:</Heading>

              <OrderedList fontSize="sm" color="gray.500" spacing="6">
                <Stack spacing="1">
                  <ListItem color="gray.600">a) A simple `ko_e` email param:</ListItem>
                  <HStack w="100%" spacing="8">
                    <Stack spacing="1" w="100%">
                      <Code rounded="md" fontWeight="semibold" w="100%">
                        <Copyable>
                          <Text
                            as="span"
                            p="2"
                            dangerouslySetInnerHTML={{
                              __html: `?ko_e=email@example.com&k_is=dir`
                            }}
                          ></Text>
                        </Copyable>
                      </Code>
                      <Text>If there are no other URL params</Text>
                    </Stack>

                    <Stack spacing="1" w="100%">
                      <Code rounded="md" fontWeight="semibold" w="100%">
                        <Copyable>
                          <Text
                            as="span"
                            p="2"
                            dangerouslySetInnerHTML={{
                              __html: `&ko_e=email@example.com&k_is=dir`
                            }}
                          ></Text>
                        </Copyable>
                      </Code>
                      <Text>If there are other URL params</Text>
                    </Stack>
                  </HStack>
                </Stack>

                <Divider />

                <Stack>
                  <ListItem color="gray.600">b) Or use `utm_id` with a base64 email parameter:</ListItem>

                  <Code p="4">
                    <pre
                      dangerouslySetInnerHTML={{
                        __html: `let binary = Buffer.from(email);\nlet base64Encoded = binary.toString("base64");`
                      }}
                    ></pre>
                  </Code>
                  <Text>
                    Note: Option b is recommended for use cases where you're generating your URLs with a programing
                    environment where you can base64 emails into IDs
                  </Text>
                </Stack>
              </OrderedList>
            </Stack>
          </UTMCard>
        </Stack>

        <Stack>
          <Heading size="sm">Questions?</Heading>
          <Text fontSize="sm" color="gray.500">
            You can learn more about Koala UTM IDs in our{' '}
            <Link
              color="purple.500"
              isExternal
              href="https://getkoala.com/docs/identity-layer/identify-email-recipients"
            >
              documentation
            </Link>{' '}
            or send us a message at{' '}
            <Link color="purple.500" href="mailto:support@getkoala.com">
              support@getkoala.com
            </Link>{' '}
            with your questions.
          </Text>
        </Stack>
      </Stack>
    </PageLayout>
  )
}

interface UTMCardProps {
  domain: string
  title: React.ReactNode
  subtitle: React.ReactNode
  children: React.ReactNode
}
function UTMCard(props: UTMCardProps) {
  return (
    <Card paddingY={4} paddingX={6}>
      <Toggle
        showIcon={false}
        title={
          <HStack role="group" w="100%">
            <HStack w="100%" spacing="3">
              <CompanyAvatar domain={props.domain} />

              <Stack spacing="0">
                <Text fontSize="sm" fontWeight="medium" color="gray.700">
                  {props.title}
                </Text>
                <Text fontSize="xs" color="gray.500">
                  {props.subtitle}
                </Text>
              </Stack>
            </HStack>
            <Icon as={IconChevronRight} boxSize={5} color="gray.400" _groupHover={{ color: 'purple.600' }} />
          </HStack>
        }
      >
        {props.children}
      </Toggle>
    </Card>
  )
}
