import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'

import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import React, { FormEvent } from 'react'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { useAppDep } from '../../../data/use-app-dep'
import { postForm } from '../../../../lib/api'
import { toast } from 'sonner'
import { projectPath } from '../../../ui/ProjectsContext'

interface CadenceProps extends UseDisclosureProps {
  profile: {
    id: string
    email: string
  }
  profileType?: 'Profile' | 'ProspectedProfile'
}

export type SalesloftCadence = {
  cadence_id: number
  name: string
}

export type SalesloftCadenceResponse = {
  data: {
    cadences: SalesloftCadence[]
  }
}

export function AddToSalesloftCadenceModal(props: CadenceProps) {
  const disclosure = useDisclosure(props)
  const [saving, setSaving] = React.useState(false)
  const [currentCadence, setCurrentCadence] = React.useState<SalesloftCadence | null>(null)

  const {
    data: cadenceData,
    isLoading: loadingCadences
    // isError: cadenceError,
    // error: CadenceErrorData
  } = useAppDep<'cadences', SalesloftCadence[]>('Salesloft', 'cadences')

  const onClose = disclosure.onClose

  const onSubmit = React.useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      setSaving(true)
      try {
        const form = e.target as HTMLFormElement
        const data = new FormData(form)

        postForm(
          projectPath(
            `/salesloft/actions/cadences?profile_id=${props.profile.id}&profile_type=${props.profileType ?? 'Profile'}`
          ),
          data
        )
          .then(() => {
            toast.success('Person added to cadence!', {
              description: `${props.profile.email} has been added to ${currentCadence?.name} in Salesloft.`
            })
            setSaving(false)
            onClose()
          })
          .catch((e) => {
            toast.error('Error adding person to cadence!', {
              description: `${e.message}: ${e.body?.error}`
            })
            setSaving(false)
          })
      } catch (error) {
        console.error(error)
      } finally {
        setSaving(false)
      }
    },
    [currentCadence, onClose, props]
  )

  return (
    <Modal {...disclosure} size="md" isCentered>
      <ModalOverlay />
      <ModalContent p="2">
        <ModalHeader>
          <HStack>
            <CompanyAvatar src="https://asset.brandfetch.io/idLO9lFZj5/id8bueqGAT.jpeg" />
            <Stack spacing="0">
              <Heading size="sm">Add to Salesloft cadence</Heading>
              <Text fontSize={'sm'} fontWeight="normal">
                Person: {props.profile.email}
              </Text>
            </Stack>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          {loadingCadences ? (
            <Spinner />
          ) : (
            <form onSubmit={onSubmit}>
              <Stack fontSize="sm" spacing="4">
                <FormControl>
                  <input type="hidden" name="salesloft[cadence_id]" value={currentCadence?.cadence_id} />
                  <FormLabel>Cadence</FormLabel>

                  <ComboboxWithSearch
                    items={cadenceData?.data?.cadences ?? []}
                    selectedItem={currentCadence}
                    onChange={(selectedItem) => {
                      setCurrentCadence(selectedItem)
                    }}
                    filterItem={(a, val) => a.name.toLowerCase().includes(val)}
                    itemToString={(item) => item?.name || ''}
                  />
                </FormControl>
                <Flex w="100%" pt="4">
                  <Button
                    w="100%"
                    size="sm"
                    colorScheme={'purple'}
                    type="submit"
                    disabled={saving || loadingCadences}
                    isLoading={saving || loadingCadences}
                  >
                    Add to Cadence
                  </Button>
                </Flex>
              </Stack>
            </form>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
