import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Checkbox,
  Flex,
  Icon,
  List,
  ListItem,
  Portal,
  Text,
  usePopper,
  UsePopperProps
} from '@chakra-ui/react'
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import { useMultipleSelection, UseMultipleSelectionProps, useSelect, UseSelectProps } from 'downshift'
import React, { useEffect } from 'react'

interface Props extends UseSelectProps<any> {
  selectedItems?: UseMultipleSelectionProps<any>['selectedItems']
  initialSelectedItems?: UseMultipleSelectionProps<any>['initialSelectedItems']
  placeholder?: string | React.ReactNode
  size?: ButtonProps['size']
  variant?: ButtonProps['variant']
  colorScheme?: ButtonProps['colorScheme']
  isDisabled?: boolean
  itemRenderer?: (item: any, index: number) => React.ReactNode
  selectButtonRenderer?: (props: {
    selectedItems: any[]
    itemToString: UseSelectProps<any>['itemToString']
  }) => React.ReactNode
  triggerProps?: ButtonProps
  menuProps?: BoxProps
  usePortal?: boolean
  popperOptions?: UsePopperProps
  onSelectedItemsChange?: UseMultipleSelectionProps<any>['onSelectedItemsChange']
}

export default function MultipleSelectInput({
  placeholder = 'Select something',
  size,
  variant,
  triggerProps,
  menuProps,
  popperOptions,
  itemRenderer,
  selectButtonRenderer,
  usePortal,
  initialSelectedItems,
  onSelectedItemsChange,
  ...props
}: Props) {
  const { popperRef, referenceRef } = usePopper({ placement: 'bottom-start', ...popperOptions })
  const [selectedItems, setSelectedItems] = React.useState(props.selectedItems ?? initialSelectedItems ?? [])

  useEffect(() => {
    if (props.selectedItems) {
      setSelectedItems(props.selectedItems)
    }
  }, [props.selectedItems])

  const { getDropdownProps, addSelectedItem, removeSelectedItem } = useMultipleSelection({
    selectedItems: selectedItems,
    initialSelectedItems: initialSelectedItems,
    onSelectedItemsChange: (changes) => {
      setSelectedItems(changes.selectedItems ?? [])
      onSelectedItemsChange?.(changes)
    }
  })

  const { getItemProps, getMenuProps, getToggleButtonProps, highlightedIndex, isOpen } = useSelect({
    selectedItem: null,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
            isOpen: true // keep the menu open after selection.
          }
      }
      return changes
    },
    onStateChange: ({ type, selectedItem: newSelectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (newSelectedItem) {
            if (selectedItems.includes(newSelectedItem)) {
              removeSelectedItem(newSelectedItem)
            } else {
              addSelectedItem(newSelectedItem)
            }
          }
          break
        default:
          break
      }
    },
    ...props
  })

  let selection = ''
  if (selectedItems && selectedItems.length > 0) {
    selection = selectedItems
      .map((selectedItem) => itemRenderer?.(selectedItem, 0) || props.itemToString?.(selectedItem) || selectedItem)
      .join(', ')
  }

  const menu = (
    <Box
      minWidth="150px"
      maxWidth="50vw"
      overflowY="auto"
      scrollBehavior="smooth"
      overscrollBehavior="contain"
      maxHeight="340px"
      zIndex={1500}
      position="absolute"
      visibility={isOpen ? 'visible' : 'hidden'}
      background="white"
      border="1px solid"
      borderColor={isOpen ? 'gray.200' : 'transparent'}
      rounded="lg"
      shadow="lg"
      _focus={{ outline: 'none' }}
      {...menuProps}
      {...getMenuProps({ ref: popperRef }, { suppressRefError: true })}
    >
      <List width="100%" display="flex" flexDirection="column" position="relative" listStyleType="none" padding={1}>
        {isOpen &&
          props.items.map((item, index) => {
            return (
              <ListItem
                key={index}
                display="flex"
                alignItems="center"
                backgroundColor={highlightedIndex === index ? 'gray.100' : undefined}
                _hover={{ backgroundColor: 'gray.100' }}
                _focus={{ outline: 'none', backgroundColor: 'gray.100' }}
                _active={{ backgroundColor: 'gray.100' }}
                cursor="pointer"
                paddingY={1.5}
                paddingX={3}
                minH={8}
                rounded="md"
                width="100%"
                whiteSpace="nowrap"
                userSelect="none"
                textOverflow="ellipsis"
                {...getItemProps({ item, index })}
              >
                {itemRenderer ? (
                  itemRenderer(item, index)
                ) : (
                  <Flex gap={2} alignItems="center">
                    <Checkbox isChecked={selectedItems.includes(item)} pointerEvents="none" />
                    <Text color="gray.600" fontWeight="medium" fontSize="sm">
                      {props.itemToString?.(item) || (typeof item === 'string' ? item : JSON.stringify(item))}
                    </Text>
                  </Flex>
                )}
              </ListItem>
            )
          })}
      </List>
    </Box>
  )

  return (
    <Box display="flex" position="relative" ref={referenceRef}>
      <Button
        type="button"
        width="full"
        justifyContent="space-between"
        size={size}
        variant={variant}
        lineHeight={1}
        isDisabled={props.isDisabled ?? false}
        colorScheme={props.colorScheme}
        rightIcon={
          <Icon
            as={isOpen ? IconChevronUp : IconChevronDown}
            boxSize={4}
            color={isOpen ? 'gray.800' : 'gray.500'}
            marginLeft="auto"
          />
        }
        {...triggerProps}
        {...getDropdownProps(getToggleButtonProps())}
      >
        {typeof selectButtonRenderer === 'function'
          ? selectButtonRenderer({
              selectedItems,
              itemToString: props.itemToString
            })
          : selection || placeholder}
      </Button>
      {usePortal ? <Portal>{menu}</Portal> : menu}
    </Box>
  )
}
