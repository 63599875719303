import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button
} from '@chakra-ui/react'
import React, { FormEventHandler, useCallback, useRef, useState } from 'react'
import { AuthenticityToken } from './AuthenticityToken'

interface Props {
  title?: string
  confirmLabel?: string
  deletePath?: string
  isOpen: boolean
  isCentered?: boolean
  onConfirm?: FormEventHandler<HTMLFormElement>
  onClose: () => void
}

export function DeleteConfirmation({
  children,
  title = 'Delete',
  deletePath,
  confirmLabel = 'Delete',
  onConfirm,
  onClose,
  isOpen,
  isCentered
}: React.PropsWithChildren<Props>) {
  const cancelRef = useRef<HTMLButtonElement | null>(null)
  const [deleting, setDeleting] = useState(false)

  const handleConfirm: FormEventHandler<HTMLFormElement> = useCallback(
    async (event) => {
      setDeleting(true)
      if (typeof onConfirm === 'function') {
        await onConfirm(event)
        onClose()
        setDeleting(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onConfirm, onClose]
  )

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered={isCentered}>
      <AlertDialogOverlay zIndex="popover">
        <AlertDialogContent>
          <AlertDialogHeader fontSize="md" paddingBottom={2}>
            {title}
          </AlertDialogHeader>
          <AlertDialogBody fontSize="sm">{children}</AlertDialogBody>

          <AlertDialogFooter>
            <Button size="sm" ref={cancelRef} mr={3} variant="outline" onClick={onClose}>
              Cancel
            </Button>

            <form action={deletePath} method="POST" onSubmit={handleConfirm}>
              <input type="hidden" name="_method" value="DELETE" />
              <AuthenticityToken />
              <Button size="sm" colorScheme="red" type="submit" isLoading={deleting}>
                {confirmLabel}
              </Button>
            </form>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
