import { useQuery } from 'react-query'
import { concurrentGET } from '../../lib/api'
import { AccountView } from '../../types/AccountView'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

export type MissionControlView = Pick<AccountView, 'id' | 'name' | 'slug' | 'kind' | 'project_id' | 'permalink'>

interface Data {
  views: MissionControlView[]
}

interface Props {
  // you can seed the query, but it's not required
  // this will allow us to have immediate data but allow refetching
  views?: MissionControlView[]
  userId?: string | null
}

export function useMissionControlViews(props: Props = {}) {
  const project = useCurrentProject()
  const search = new URLSearchParams()

  if (props.userId) {
    search.set('user', props.userId)
  }

  const path = projectPath(`/mission-control/views.json?${search.toString()}`)

  return useQuery<Data>({
    queryKey: ['mission-control-views', { projectId: project?.id, userId: props.userId }],
    queryFn: () => concurrentGET<Data>(path),
    enabled: Boolean(project?.id),
    initialData: () => {
      return props.views ? { views: props.views } : undefined
    }
  })
}
