import { projectPath } from '@app/components/ui/ProjectsContext'
import { concurrentCachedGET } from '@app/lib/api'
import { Divider, Flex, HStack, Skeleton, Stack, useBoolean } from '@chakra-ui/react'
import orderBy from 'lodash/orderBy'
import React from 'react'
import type { Account } from '../../../../types/Account'
import { VirtualList } from '../../../ui/VirtualList'
import { EntryRow } from './EntryRow'

interface TrafficSources {
  [path: string]: number
}

export function TopTrafficSources(props: Account) {
  const [sources, setTopSources] = React.useState<TrafficSources>({})
  const [loading, setLoading] = useBoolean(true)

  React.useEffect(() => {
    concurrentCachedGET<TrafficSources>(projectPath(`/accounts/${props.id}/top_traffic_sources?limit=10`))
      .then((response) => setTopSources(response))
      .catch((error) => console.log(error))
      .finally(() => setLoading.off())
  }, [props, setLoading])

  const total = React.useMemo(() => Object.values(sources).reduce((acc, count) => acc + count, 0), [sources])
  const sorted = React.useMemo(() => orderBy(Object.entries(sources), [1], ['desc']), [sources])

  return (
    <>
      {loading && (
        <Stack spacing={0} divider={<Divider />}>
          {Array.from({ length: 8 }).map((_, index) => (
            <HStack key={index} justifyContent="space-between" px={4} height="49px">
              <Skeleton width="300px" height="12px" />
              <HStack>
                <Skeleton width="24px" height="10px" />
                <Skeleton width="100px" height="10px" />
              </HStack>
            </HStack>
          ))}
        </Stack>
      )}
      {sorted && !loading && (
        <VirtualList
          maxH={'490px'}
          items={sorted}
          estimateSize={() => 49}
          renderItem={(item, index) => {
            const [referrer, count] = item

            return (
              <Flex
                w="100%"
                borderBottomColor="gray.200"
                borderBottomStyle="solid"
                borderBottomWidth={sorted.length === index + 1 ? '0' : '1px'}
                alignItems="center"
                flex="1"
                _hover={{ bg: 'gray.50' }}
              >
                <EntryRow
                  label={referrer}
                  count={count}
                  total={total}
                  index={index}
                  account={props}
                  trackIntentProps={{
                    intentType: 'page',
                    conditions: [
                      {
                        kind: 'page_view',
                        with: {
                          value: ['5 seconds'],
                          operator: 'greater_than',
                          property: 'focus_time'
                        },
                        value: [referrer],
                        operator: 'contains',
                        property: 'referrer'
                      }
                    ]
                  }}
                />
              </Flex>
            )
          }}
        />
      )}
    </>
  )
}
