import { Heading, HStack, Link, Stack, Text } from '@chakra-ui/react'
import { IconSend } from '@tabler/icons-react'
import React, { useState } from 'react'
import { PricingForm } from '../../../ui/PricingForm'

export function BillingHelp() {
  const [showPricingForm, setShowPricingForm] = useState(false)

  return (
    <Stack>
      <Heading size="sm">Need help?</Heading>
      <HStack fontSize={'sm'} spacing="2">
        <Text>Have any questions about billing?</Text>{' '}
        <Link
          href="#"
          onClick={(e) => {
            e.preventDefault()
            setShowPricingForm(true)
          }}
        >
          <HStack spacing="1">
            <IconSend size="14" />
            <Text>Send us a message.</Text>
          </HStack>
        </Link>
      </HStack>
      {showPricingForm && <PricingForm onClose={() => setShowPricingForm(false)} />}
    </Stack>
  )
}
