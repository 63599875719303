import React from 'react'
import PageLayout from '../../ui/PageLayout'
import { ExploreCompaniesView } from './components/ExploreCompaniesView'
import { Company } from '../../../types/Profile'
import type { PageMeta } from '../../../types/PageMeta'
import { UrlFilterParams, useUrlFilters } from '../../data/use-url-filters'

interface Props {
  page_meta: PageMeta
  companies: Company[]
  list_id: string
}

export default function Index(props: Props) {
  const facets: UrlFilterParams = useUrlFilters({
    initialRange: null,
    facetCloudPath: '/companies/facet-cloud'
  })

  return (
    <PageLayout flush gap={0} maxH="100%">
      <ExploreCompaniesView
        companies={props.companies}
        page_meta={props.page_meta}
        facets={facets}
        listId={props.list_id}
      />
    </PageLayout>
  )
}
