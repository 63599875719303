import React, { useMemo, useState } from 'react'

import { projectPath } from '../../../ui/ProjectsContext'

import PageLayout from '@app/components/ui/PageLayout'
import PageTitle from '@app/components/ui/PageTitle'
import PageDescription from '../../../ui/PageDescription'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'

import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Badge,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Stack,
  useDisclosure
} from '@chakra-ui/react'
import { IconAlertCircleFilled, IconCheck } from '@tabler/icons-react'
import { useDebounce } from 'use-debounce'
import { useInstantlyKey } from '../../../data/use-instantly-key'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { LightBgCard } from '../../../ui/Card'
import { CheckboxCircleIcon } from '../../../ui/icons'
import { usePermission } from '../../../ui/PermissionsContext'

interface Props {
  title: string
  description: string
  logo: string
  settings: Record<string, string | null | undefined>
  connected?: boolean
  valid?: boolean
  deps: {}
}

export default function Show(props: Props) {
  const { hasPermission: canEditProject, isLoading: isPermissionLoading } = usePermission({
    on: 'project',
    action: 'can_edit'
  })
  const editApiKey = useDisclosure({ defaultIsOpen: !props.valid || !props.connected })
  const [apiKey, setApiKey] = useState('')

  const [debouncedNewKey] = useDebounce(apiKey, 300)
  const { isLoading, data } = useInstantlyKey(debouncedNewKey)

  const apiKeyInvalid = useMemo(
    () => (!isLoading && data?.valid === false) || (!props.valid && !apiKey && !!props.settings.api_key),
    [isLoading, data, props.valid, apiKey, props.settings.api_key]
  )

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/instantly'),
            title: 'Instantly'
          }
        ]}
        offscreen
      />
      <HStack w="100%">
        <Box w="100%">
          <HStack w="100%">
            <HStack marginRight="auto" alignItems="center" spacing={2}>
              <Image src={props.logo} maxW="6" />
              <PageTitle>{props.title}</PageTitle>
              {props.connected && props.valid && <Badge colorScheme="green">Connected</Badge>}
              {props.connected && !props.valid && <Badge colorScheme="orange">Requires Reconnection</Badge>}
            </HStack>
            {props.connected && editApiKey.isOpen && (
              <DisconnectAppDialog appTitle="Instantly" showRemoveCachesOption={false} />
            )}
          </HStack>

          <PageDescription>{props.description}</PageDescription>
        </Box>
      </HStack>

      {!canEditProject && !isPermissionLoading && (
        <>
          <Alert fontSize={'sm'} status="warning">
            <AlertIcon />
            <AlertDescription>
              You don't have enough permissions to perform this action. Please request access to an admin in your
              workspace.
            </AlertDescription>
          </Alert>
        </>
      )}

      {editApiKey.isOpen || !props.valid ? (
        <form method="POST">
          <AuthenticityToken />
          <input type="hidden" name="_method" value="PUT" />

          <Stack spacing="4" pt="8">
            <Heading size="sm" fontWeight={'semibold'}>
              🔐 Auth Settings
            </Heading>

            <FormControl id="api_key">
              <FormLabel>API Key</FormLabel>

              <InputGroup w="100%" size="sm">
                <Input
                  name="app_instance_settings[api_key]"
                  placeholder="Enter your Instantly API key"
                  defaultValue={props.settings['api_key'] ?? ''}
                  onChange={(e) => setApiKey(e.target.value)}
                  type="password"
                  autoComplete="off"
                />
                <InputRightElement color={apiKeyInvalid ? 'red.500' : 'green.400'}>
                  {isLoading ? (
                    <Spinner color="gray.400" size="sm" />
                  ) : apiKeyInvalid ? (
                    <IconAlertCircleFilled size={18} />
                  ) : debouncedNewKey ? (
                    <IconCheck size={18} />
                  ) : null}
                </InputRightElement>
              </InputGroup>

              {apiKeyInvalid && <FormErrorMessage>Please enter a valid API key</FormErrorMessage>}
              <FormHelperText>Your Instantly API key for authentication.</FormHelperText>
            </FormControl>

            <Button
              size="sm"
              colorScheme="purple"
              type="submit"
              w="100%"
              isDisabled={isLoading || !canEditProject || apiKeyInvalid}
            >
              Save
            </Button>
          </Stack>
        </form>
      ) : (
        <LightBgCard gap={6} p={4}>
          <HStack justifyContent="space-between">
            <CheckboxCircleIcon color="green.400" size={18} />
            <Heading flex="1" size="xs" fontWeight="medium">
              Your Instantly account is connected
            </Heading>
            <Button variant="outline" size="sm" onClick={editApiKey.onOpen}>
              Change key
            </Button>
            <DisconnectAppDialog appTitle="Instantly" showRemoveCachesOption={false} />
          </HStack>
        </LightBgCard>
      )}
    </PageLayout>
  )
}
