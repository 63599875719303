import { Box, HStack, Icon, Text } from '@chakra-ui/react'
import { IconCircleDashed, IconLineDashed } from '@tabler/icons-react'
import React, { useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { ProfileRecord } from '../../types/Profile'
import { OmnisearchProfile, useOmnisearch } from '../data/use-omnisearch'
import Avatar from './Avatar'
import { ComboboxWithSearch } from './ComboboxWithSearch'

export interface PartialVisitor extends OmnisearchProfile {}

const none: PartialVisitor = {
  id: '',
  display_name: 'No one'
}

interface VisitorSelectorProps {
  selectedVisitor?: PartialVisitor | null
  selectedAccountId?: string | null
  onChange: (visitor: PartialVisitor | null) => void
  showClearButton?: boolean
}

export function VisitorSelector({
  selectedVisitor,
  selectedAccountId,
  showClearButton,
  onChange
}: VisitorSelectorProps) {
  const [inputValue, setInputValue] = useState<string | undefined>()
  const [query] = useDebounce(inputValue, 300)
  const { data, isLoading } = useOmnisearch(query || '*', {
    model: 'Profile',
    page_size: 50,
    facets: {
      account_id: selectedAccountId
    }
  })

  const visitors = useMemo(() => {
    const base = showClearButton ? [] : [none]
    return base.concat(data?.results?.profiles ?? [])
  }, [data, showClearButton])

  return (
    <ComboboxWithSearch
      items={visitors}
      selectedItem={selectedVisitor || null}
      onChange={onChange}
      onInputValueChange={setInputValue}
      isLoading={isLoading}
      filterItem={(a, val) =>
        a.display_name?.toLowerCase().includes(val) || a.email?.toLowerCase().includes(val) || false
      }
      itemToString={(item) => item?.name || ''}
      itemRenderer={VisitorRenderer}
      selectButtonRenderer={VisitorRenderer}
      showClearButton={showClearButton}
    />
  )
}

interface VisitorRendererProps {
  item: PartialVisitor | ProfileRecord | null
  isSelected?: boolean
  isToggleButton?: boolean
}

function VisitorRenderer(props: VisitorRendererProps) {
  const visitor = props.item

  if (!visitor) {
    return (
      <HStack spacing={2.5}>
        <Icon as={IconCircleDashed} color="gray.300" boxSize={6} />
        <Text fontSize="sm" fontWeight="medium" color="gray.400">
          Select someone
        </Text>
      </HStack>
    )
  }

  return (
    <HStack spacing={2.5}>
      <Avatar
        size={props.isToggleButton ? 'xs' : 'sm'}
        name={visitor === none ? undefined : visitor.display_name || visitor.email}
        {...(visitor === none
          ? {
              backgroundColor: 'transparent',
              fallbackIcon: <IconLineDashed size={20} />
            }
          : {
              name: visitor.display_name || visitor.email
            })}
      />
      <Box>
        <Text fontSize="sm" fontWeight="medium">
          {visitor.display_name || visitor.email || 'Anonymous'}
        </Text>
        {!props.isToggleButton &&
          (visitor.title ? (
            <Text fontSize="xs" color="gray.500">
              {visitor.title}{' '}
              {visitor.company?.name || visitor.company?.domain
                ? `at ${visitor.company?.name || visitor.company?.domain}`
                : null}
            </Text>
          ) : visitor.email ? (
            <Text fontSize="xs" color="gray.500">
              {visitor.email}
            </Text>
          ) : null)}
      </Box>
    </HStack>
  )
}
