import { projectPath } from '@app/components/ui/ProjectsContext'
import { post } from '@app/lib/api'
import { CRMMatch } from '@app/types/Account'
import type { App } from '@app/types/App'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { toast } from 'sonner'

export interface AssignToCrmProps {
  app?: App
  account?: CRMMatch
  appModule?: string
  onClose: () => void
  onSuccess?: () => void
  isOpen: boolean
}

export function AssignCrmOwnerModal({ app, account, isOpen, onClose, appModule, onSuccess }: AssignToCrmProps) {
  const [claiming, setClaiming] = useState(false)

  const assignCrmOwner = React.useCallback(async () => {
    setClaiming(true)

    try {
      await post(projectPath(`/accounts/${account?.crm_entity.domain}/assign`), {
        app_module: appModule,
        crm_entity_id: account?.crm_entity_id
      })

      setClaiming(false)
      onClose?.()

      toast.success('Account assigned to you!')
      onSuccess?.()
    } catch (err) {
      setClaiming(false)
      toast.error('There was an issue assigning this account to you.')
    }
  }, [account?.crm_entity.domain, account?.crm_entity_id, appModule, onClose, onSuccess])

  const cancelRef = React.useRef<HTMLButtonElement>(null)
  const noun = app?.title === 'HubSpot' ? 'company' : 'account'

  return (
    <AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg">
          Claim {noun} in {app?.title}
        </AlertDialogHeader>

        <AlertDialogBody>
          {account?.crm_entity.account_owner ? (
            <Text fontSize="sm" color="gray.600">
              This {noun} is currently assigned to <b>{account?.crm_entity.account_owner}</b> in {app?.title}. Do you
              want to assign ownership to yourself?
            </Text>
          ) : (
            <Text fontSize="sm" color="gray.600">
              This {noun} is currently unowned in {app?.title}. Do you want to claim this {noun}?
            </Text>
          )}
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button size="sm" variant="outline" mr={3} onClick={onClose} ref={cancelRef}>
            Cancel
          </Button>
          <Button size="sm" colorScheme="red" onClick={assignCrmOwner} isLoading={claiming}>
            Claim account
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
