import get from 'lodash/get'

export function flatGet(data, path) {
  const parts = path.split('.')

  const value = get(data, path)
  if (typeof value !== 'undefined') {
    return value
  }

  function traverse(current, pathIndex) {
    if (pathIndex >= parts.length) return current

    const part = parts[pathIndex]
    if (Array.isArray(current)) {
      // If current part of data is an array, map over it and dive deeper
      return current.flatMap((item) => traverse(item, pathIndex))
    } else if (current && typeof current === 'object') {
      // If it's an object, dive into the next level
      return traverse(current[part], pathIndex + 1)
    }
    // If path does not exist or is at a leaf, return current value (could be undefined)
    return current
  }

  return traverse(data, 0)
}
