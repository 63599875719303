import { Box, Button, Flex, Heading, Icon, Skeleton, Stack } from '@chakra-ui/react'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import { IconSparkles } from '@tabler/icons-react'
import React from 'react'
import ReactMarkdown from 'react-markdown'

interface UseAISummaryProps {
  path: string
}

interface UseAISummaryResponse {
  messages: string[]
  isLoading: boolean
  eventSource: () => void
}

export function useAISummary({ path }: UseAISummaryProps): UseAISummaryResponse {
  const [messages, setMessages] = React.useState<string[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const eventSource = () => {
    fetchEventSource(path, {
      onopen: async (res) => {
        setMessages([])
        if (res.ok && res.status === 200) {
          console.log('Connection made ', res)
        } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
          console.log('Client side error ', res)
        }
      },
      onmessage: (e) => {
        setMessages((prev) => [...prev, e.data])
      },
      onerror: () => {
        console.log('Error occurred')
        setIsLoading(false)
      },
      onclose: () => {
        console.log('Connection closed')
        setIsLoading(false)
      }
    })
  }

  return { messages, isLoading, eventSource }
}

interface SummaryContentProps {
  messages: string[]
  isOpen: boolean
}

export function AISummaryContent({ messages, isOpen }: SummaryContentProps) {
  return (
    <Stack className={isOpen ? 'content-open' : 'content-close'} hidden={!isOpen} minH="250px">
      {messages.length == 0 ? (
        <Stack paddingY={3} paddingX={8} textColor="blackAlpha.700" borderRadius="4px" borderColor="black">
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      ) : (
        <Box paddingY={3} paddingX={8} textColor="blackAlpha.700" borderRadius="4px" borderColor="black" minH="250px">
          <ReactMarkdown
            components={{
              h2: ({ node, ...props }) => <Heading size="xs" {...props} paddingY={2} fontSize="1em" />,
              li: ({ node, ...props }) => (
                <li
                  {...props}
                  style={{
                    ...props.style
                  }}
                />
              ),
              ul: ({ node, ...props }) => (
                <ul
                  {...props}
                  style={{
                    ...props.style
                  }}
                />
              ),
              strong: ({ node, ...props }) => (
                <strong
                  {...props}
                  style={{
                    ...props.style,
                    fontSize: '.9em'
                  }}
                />
              )
            }}
          >
            {messages.join('').replace(/(\D)(\d)/g, '$1 $2')}
          </ReactMarkdown>
        </Box>
      )}
    </Stack>
  )
}

interface IaSummaryButtonProps {
  leftText?: string
  summarized?: boolean
  onValueChange: (checked: boolean) => void
}

export default function AISummaryButton({ leftText, onValueChange, summarized }: IaSummaryButtonProps) {
  return (
    <Flex paddingTop={1} paddingBottom={3} paddingRight={4} justifyContent="flex-end">
      <Button
        size="xs"
        variant="ghost"
        color="gray.400"
        _hover={{ color: 'purple.500' }}
        leftIcon={summarized ? undefined : <Icon as={IconSparkles} size={16} />}
        iconSpacing={1}
        onClick={() => {
          onValueChange(!summarized)
        }}
      >
        {summarized ? 'Hide summary' : leftText || 'Summarize'}
      </Button>
    </Flex>
  )
}
