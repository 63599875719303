import { Icon, IconProps } from '@chakra-ui/react'
import React from 'react'

interface Props extends IconProps {
  size?: string | number
}

export function ClearbitIcon({ size, ...props }: Props) {
  size = size ? `${size}px` : size
  return (
    <Icon viewBox="0 0 30 30" baseProfile="tiny-ps" fill="currentColor" width={size} height={size} {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <defs>
        <linearGradient id="appclearbit-a" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#1BA6FD" />
          <stop offset="100%" stopColor="#148EFC" />
        </linearGradient>
        <linearGradient id="appclearbit-b" x1="50%" x2="50%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#56BAFD" />
          <stop offset="100%" stopColor="#53B7FD" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="url(#appclearbit-a)"
          d="M8.01214125,0 L15,0 L15,30 L8.01214125,30 C5.22614519,30 4.21587569,29.70992 3.19735718,29.1652103 C2.17883866,28.6205006 1.37949936,27.8211613 0.834789699,26.8026428 C0.290080035,25.7841243 0,24.7738548 0,21.9878588 L0,8.01214125 C0,5.22614519 0.290080035,4.21587569 0.834789699,3.19735718 C1.37949936,2.17883866 2.17883866,1.37949936 3.19735718,0.834789699 C4.21587569,0.290080035 5.22614519,0 8.01214125,0 Z"
        />
        <path
          fill="url(#appclearbit-b)"
          d="M15,0 L21.9878588,0 C24.7738548,0 25.7841243,0.290080035 26.8026428,0.834789699 C27.8211613,1.37949936 28.6205006,2.17883866 29.1652103,3.19735718 C29.70992,4.21587569 30,5.22614519 30,8.01214125 L30,15 L15,15 L15,0 Z"
        />
        <path
          fill="#F2FAFE"
          d="M30,15 L30,21.9878588 C30,24.7738548 29.70992,25.7841243 29.1652103,26.8026428 C28.6205006,27.8211613 27.8211613,28.6205006 26.8026428,29.1652103 C25.7841243,29.70992 24.7738548,30 21.9878588,30 L15,30 L15,15 L30,15 Z"
        />
      </g>
    </Icon>
  )
}
