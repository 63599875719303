import { IconFolderPlus } from '@tabler/icons-react'
import React, { useCallback } from 'react'
import Router from '../../../../lib/router'
import UIEmptyState, { Size } from '../../../ui/EmptyState'
import { projectPath } from '../../../ui/ProjectsContext'

interface Props {
  heading?: string
  description?: string
  size?: Size
  skipCta?: boolean
}

const EmptyState = ({
  heading = 'No Lists Found',
  description = 'You currently have no lists created! You can create and save your first list by navigating to the accounts or contacts page',
  size = 'lg',
  skipCta
}: Props) => {
  const handleClick = useCallback(() => {
    Router.visit(projectPath('/accounts'))
  }, [])

  if (skipCta) {
    return <UIEmptyState icon={IconFolderPlus} size={size} heading={heading} description={description} />
  }

  return (
    <UIEmptyState
      icon={IconFolderPlus}
      size={size}
      heading={heading}
      description={description}
      onClick={handleClick}
      ctaText="Go to Accounts"
    />
  )
}

export { EmptyState }
