import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { concurrentCachedGET } from '../../../../lib/api'
import { Apps } from '../../../../types/App'
import { KQLByDay } from '../../../../types/KQL'
import type { ProfileRecord } from '../../../../types/Profile'
import { AnchoredDrawerLink } from '../../../ui/AnchoredDrawerLink'
import { KqlDefinition } from '../../kql_definitions/types'
import { profilePath } from '../lib/path'
import { VisitorDetails } from './VisitorDetails'

interface Props extends UseDisclosureProps {
  apps?: Apps
  profileId?: string
  redacted?: boolean
}

interface Res {
  profile: ProfileRecord
  apps: Apps
  kqls_by_day: KQLByDay[]
  kql_definitions: KqlDefinition[]
}

export function ProfilePanel({ profileId, redacted, ...props }: Props) {
  const disclosure = useDisclosure(props)
  const [apps, setApps] = React.useState<Apps | undefined>(props.apps)
  const [profile, setProfile] = useState<ProfileRecord | undefined>()
  const [signalsByDay, setSignalsByDay] = useState<KQLByDay[]>([])
  const [signalDefinitions, setSignalDefinitions] = useState<KqlDefinition[]>([])
  const [loading, setLoading] = useState(false)
  const isOpen = disclosure.isOpen

  profileId = profileId || profile?.id

  useEffect(() => {
    if (loading || profile || !profileId || !isOpen) {
      return
    }

    setLoading(true)
    concurrentCachedGET<Res>(profilePath({ id: profileId })).then((prof) => {
      setProfile(prof.profile)
      setApps(prof.apps)
      setSignalsByDay(prof.kqls_by_day)
      setSignalDefinitions(prof.kql_definitions)
      setLoading(false)
    })
  }, [loading, profile, profileId, isOpen])

  if (!profileId) {
    return null
  }

  const profileHref = profilePath(redacted || !profile ? { id: profileId } : profile)

  return (
    <Drawer {...disclosure} placement="right" size="md">
      <DrawerOverlay />
      <DrawerContent>
        <AnchoredDrawerLink href={profileHref}>Open Full Page</AnchoredDrawerLink>
        <DrawerCloseButton />
        <DrawerBody>
          <VisitorDetails
            profile={profile}
            variant="drawer"
            stacked
            profile_id={profileId}
            apps={apps}
            scopedTo="account"
            signalsByDay={signalsByDay}
            signalDefinitions={signalDefinitions}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
