import { FormControl, FormHelperText, FormLabel, Stack, Switch } from '@chakra-ui/react'
import React from 'react'
import { useAppDep } from '../../../../data/use-app-dep'
import { ComboboxWithSearch } from '../../../../ui/ComboboxWithSearch'
import { ApolloActionProps } from './apollo-setup'

export function AddToList(props: ApolloActionProps) {
  const [enabled, setEnabled] = React.useState(Boolean(props.delivery_rules?.apollo?.add_to_list?.enabled))

  const { data: labelData, isLoading: loadingLabels } = useAppDep<'labels', Array<{ id: string; name: string }>>(
    'Apollo',
    'labels'
  )

  const [selectedLabel, setSelectedLabel] = React.useState<string | null>(
    props.delivery_rules?.apollo?.add_to_list?.list_name ?? null
  )

  return (
    <Stack w="100%">
      <FormControl>
        <Switch
          isChecked={enabled}
          onChange={(e) => {
            setEnabled(e.target.checked)
            props.setDeliveryRules({
              ...props.delivery_rules,
              apollo: {
                ...props.delivery_rules?.apollo,
                add_to_list: {
                  ...props.delivery_rules?.apollo?.add_to_list,
                  enabled: e.target.checked
                }
              }
            })
          }}
          size="sm"
          fontSize={'sm'}
          fontWeight="semibold"
          colorScheme={'purple'}
          value={enabled ? 'true' : 'false'}
          name="follow_rule[delivery_rules][apollo][add_to_list][enabled]"
        >
          Enable
        </Switch>
        <FormHelperText>
          Adds the current visitor to a list in Apollo and creates the contact if not present.
        </FormHelperText>
      </FormControl>

      {enabled && !loadingLabels && (
        <Stack w="100%" spacing="4">
          <FormControl>
            <FormLabel>Select a List</FormLabel>
            {selectedLabel && (
              <input
                type="hidden"
                name="follow_rule[delivery_rules][apollo][add_to_list][list_name]"
                value={selectedLabel}
              />
            )}
            <ComboboxWithSearch
              items={labelData?.data?.labels ?? []}
              selectedItem={labelData?.data?.labels?.find((a) => a.name === selectedLabel) ?? null}
              onChange={(selectedItem) => {
                setSelectedLabel(selectedItem?.name ?? null)
              }}
              filterItem={(a, val) => a.name.toLowerCase().includes(val)}
              itemToString={(item) => item?.name || ''}
            />
          </FormControl>
        </Stack>
      )}
    </Stack>
  )
}
