import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text
} from '@chakra-ui/react'
import set from 'lodash/set'
import * as React from 'react'
import { toast } from 'sonner'
import { postForm } from '../../../../lib/api'
import { Project } from '../../../../types/Project'
import { projectPath } from '../../../ui/ProjectsContext'

export const roles = [
  {
    name: 'Admin',
    value: 'admin',
    description: 'Full administrative access to see and manage everything'
  },
  {
    name: 'Member',
    value: 'member',
    description: 'Full read access and limited write access'
  },
  {
    name: 'View Only',
    value: 'read_only',
    description: 'Read-only access'
  },
  {
    name: 'Developer',
    value: 'developer',
    description: 'Full write access to Instrumentation, limited access to other features at no extra charge.'
  }
]

interface InviteProps {
  project: Project
  isOpen: boolean
  onClose: () => void
}

interface UseInviteProps {
  onSuccess?: () => void
}

export function useInvite({ onSuccess }: UseInviteProps = {}) {
  const [isInviting, setIsInviting] = React.useState(false)

  const onInvite = React.useCallback(
    async (event) => {
      event.preventDefault()
      setIsInviting(true)

      const form = new FormData(event.target as HTMLFormElement)
      let data = {} as Record<string, any>

      for (const [key, value] of form.entries()) {
        data = set(data, key, value)
      }

      const invites = [data.invite?.invites ?? []].flat()
      const emails = invites
        ?.map((i) => {
          return i.email.trim()
        })
        .filter(Boolean)

      if (emails.length === 0) {
        return
      }

      try {
        await postForm(projectPath(`/invites`), form)

        toast.success('Invite sent!')

        onSuccess?.()
      } catch (error) {
        toast.error('Invite failed!', {
          description: `There was issue inviting ${data.email}`
        })
      } finally {
        setIsInviting(false)
      }
    },
    [onSuccess]
  )

  return { isInviting, onInvite }
}

export function InviteModal({ isOpen, onClose }: InviteProps) {
  const { isInviting, onInvite } = useInvite({ onSuccess: onClose })

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" preserveScrollBarGap>
      <form data-koala-collect="off" action={projectPath(`/invites`)} method="POST" onSubmit={onInvite}>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(6px)" />
        <ModalContent>
          <ModalHeader>Invite a teammate</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={8}>
              <Text fontSize="sm" color="gray.600">
                Need help getting set up? Invite your RevOps leader to connect the CRM or a developer to install the
                Koala pixel in your website and app.
              </Text>

              <FormControl id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input fontSize="sm" name="invite[invites][][email]" placeholder="Enter an email" />
              </FormControl>

              <FormControl id="role" isRequired>
                <FormLabel>Role</FormLabel>
                <RadioGroup name="invite[invites][][role]" defaultValue="read_only">
                  <Box borderTop="1px solid" borderColor="gray.200">
                    {roles.map((role) => (
                      <Flex key={role.name} paddingY={3} borderBottom="1px solid" borderColor="gray.200">
                        <Radio id={`role:${role.value}`} value={role.value} flex="0 0 150px" isRequired>
                          <Text as="label" htmlFor={`role:${role.value}`} fontSize="sm" fontWeight="medium">
                            {role.name}
                          </Text>
                        </Radio>
                        <Text flex="1 1 auto" fontSize="sm" color="gray.600">
                          {role.description}
                        </Text>
                      </Flex>
                    ))}
                  </Box>
                </RadioGroup>
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter justifyContent="stretch" gap={3}>
            <Button flex="1 1 auto" variant="outline" onClick={onClose}>
              Cancel
            </Button>

            <Button flex="1 1 auto" type="submit" colorScheme="purple" isLoading={isInviting}>
              Invite
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}
