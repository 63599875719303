import {
  Box,
  ButtonProps,
  chakra,
  Flex,
  Icon,
  Stack,
  StackDirection,
  Text,
  useId,
  useRadio,
  useRadioGroup,
  UseRadioGroupProps,
  UseRadioProps,
  VStack
} from '@chakra-ui/react'
import React from 'react'
import { CheckboxBlankCircleIcon, CheckboxCircleIcon } from './icons'

const RadioBox = chakra('div', {
  baseStyle: {
    flex: '1 1 auto',
    borderWidth: '1.5px',
    borderRadius: 'lg',
    cursor: 'pointer',
    transition: 'all 150ms cubic-bezier(0, 0, 0.2, 1)',
    _disabled: { opacity: 0.6, cursor: 'not-allowed' },
    _focusVisible: {
      boxShadow: 'outline',
      outline: '2px solid transparent',
      outlineOffset: '2px'
    }
  }
})

interface CardRadioProps extends UseRadioProps {
  label: string | React.ReactNode
  description?: string
  colorScheme?: ButtonProps['colorScheme']
  showUncheckedIcon?: boolean
  iconPlacement?: 'left' | 'right'
  size?: 'sm' | 'md' | 'lg'
}

const padding = {
  sm: 3,
  md: 4,
  lg: 5
}

function CardRadio(props: CardRadioProps) {
  const { label, isDisabled, description, size = 'md', showUncheckedIcon, iconPlacement } = props
  const { getCheckboxProps, getInputProps, getLabelProps, state } = useRadio(props)
  const id = useId()
  const colorScheme = props.colorScheme ?? 'purple'

  const checkedStyles = {
    borderColor: `${colorScheme}.500`,
    colorScheme: colorScheme
  }

  const hoverStyles = {
    borderColor: 'gray.300',
    shadow: 'sm'
  }

  return (
    <label style={{ width: '100%', display: 'flex' }} {...getLabelProps()}>
      <input {...getInputProps()} aria-labelledby={id} />
      <RadioBox
        {...getCheckboxProps()}
        _hover={isDisabled ? undefined : hoverStyles}
        _checked={checkedStyles}
        id={id}
        bg="white"
        tabIndex={0}
        padding={padding[size]}
      >
        <Flex
          gap="2"
          alignItems="flex-start"
          justifyContent="space-between"
          flexDirection={iconPlacement === 'left' ? 'row-reverse' : 'row'}
        >
          <VStack flex="1" alignItems="left" spacing={size === 'lg' ? 2 : 0.5}>
            {typeof label === 'string' ? (
              <Text
                fontWeight="medium"
                fontSize={size === 'lg' ? 'md' : 'sm'}
                lineHeight={size === 'lg' ? '20px' : '18px'}
              >
                {label}
              </Text>
            ) : (
              label
            )}
            {description && (
              <Text fontSize={size === 'lg' ? 'sm' : 'xs'} color="gray.600">
                {description}
              </Text>
            )}
          </VStack>
          {showUncheckedIcon ? (
            <Icon
              flex="none"
              as={state.isChecked ? CheckboxCircleIcon : CheckboxBlankCircleIcon}
              boxSize="18px"
              color={state.isChecked ? `${colorScheme}.600` : `gray.300`}
            />
          ) : (
            <Box
              display="flex"
              flex="none"
              visibility={state.isChecked ? 'visible' : 'hidden'}
              color={`${colorScheme}.500`}
            >
              <CheckboxCircleIcon size={20} />
            </Box>
          )}
        </Flex>
      </RadioBox>
    </label>
  )
}

export interface ButtonRadioGroupProps extends UseRadioGroupProps {
  direction?: StackDirection
  size?: 'sm' | 'md' | 'lg'
  colorScheme?: ButtonProps['colorScheme']
  showUncheckedIcon?: boolean
  iconPlacement?: 'left' | 'right'
  spacing?: string
  options: Array<{
    label: string | React.ReactNode
    value: string
    description?: string
    isDisabled?: boolean
  }>
}

export function CardRadioGroup(props: ButtonRadioGroupProps) {
  const { options, direction, size = 'lg', showUncheckedIcon, iconPlacement, ...rest } = props
  const { getRadioProps, getRootProps } = useRadioGroup(rest)
  return (
    <Stack
      justify="center"
      direction={direction || { base: 'column', md: 'row' }}
      spacing={props.spacing || '3'}
      {...getRootProps()}
    >
      {options.map((option) => {
        if (!option) {
          return null
        }
        return (
          <CardRadio
            key={option.value}
            size={size}
            iconPlacement={iconPlacement}
            showUncheckedIcon={showUncheckedIcon}
            description={option.description}
            label={option.label}
            colorScheme={props.colorScheme}
            isDisabled={option.isDisabled}
            {...getRadioProps({ value: option.value })}
          />
        )
      })}
    </Stack>
  )
}
