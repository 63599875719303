import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import React, { useEffect, useMemo } from 'react'
import { AccountView } from '../../../../types/AccountView'
import { useSpaces } from '../../../data/use-spaces'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { CardRadioGroup } from '../../../ui/CardRadioGroup'
import { projectPath } from '../../../ui/ProjectsContext'
import { accountViewPath } from '../lib/list-paths'

interface AccountViewModalProps extends UseDisclosureProps {
  view: AccountView
}

export function MoveToSpaceModal(props: AccountViewModalProps) {
  const disclosure = useDisclosure(props)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const { data, isLoading } = useSpaces({ ownership: 'shared' })
  const [spaceId, setSpaceId] = React.useState<AccountView['space_id']>(props.view?.space_id)

  useEffect(() => {
    setSpaceId(props.view?.space_id)
  }, [props.view.space_id])

  const onSubmit = React.useCallback(() => {
    setIsSubmitting(true)
  }, [])

  const spaces = useMemo(() => data?.spaces ?? [], [data?.spaces])

  return (
    <Modal {...disclosure} size="md" preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Move your list</ModalHeader>

        <ModalBody as="form" action={accountViewPath(props.view)} method="POST" id="edit-view-form" onSubmit={onSubmit}>
          <AuthenticityToken />
          <input type="hidden" name="_method" value="PATCH" />

          {isLoading ? (
            <Flex height="32px" alignItems="center">
              <Spinner color="gray.400" size="sm" thickness="1.5px" />
            </Flex>
          ) : spaces.length === 0 ? (
            <Text fontSize="sm" color="gray.600">
              You don't have any Spaces to move this list to yet.{' '}
              <Link color="purple.500" href={projectPath('/spaces')} isExternal>
                Create a Space
              </Link>{' '}
              to get started.
            </Text>
          ) : (
            <FormControl size="sm" isRequired>
              <input type="hidden" name="account_view[ownership]" value="space" />

              <FormLabel>Select a Space to move your list to:</FormLabel>
              <CardRadioGroup
                size="sm"
                name="account_view[space_id]"
                iconPlacement="left"
                direction="column"
                showUncheckedIcon
                value={spaceId || ''}
                onChange={setSpaceId}
                options={spaces.map((space) => ({
                  label: space.name,
                  value: space.id
                }))}
              />
            </FormControl>
          )}
        </ModalBody>

        <ModalFooter>
          <Button size="sm" variant="outline" mr={3} onClick={disclosure.onClose}>
            Cancel
          </Button>
          <Button
            size="sm"
            type="submit"
            colorScheme="purple"
            form="edit-view-form"
            isLoading={isSubmitting}
            isDisabled={!spaceId}
          >
            Move list
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
