import { ConnectionDetail, ConnectionDetails } from '@app/components/pages/apps/components/ConnectionDetails'
import { ConnectOauthAppDialog } from '@app/components/pages/apps/components/ConnectOauthAppDialog'
import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import { HealthCheck, HealthChecks } from '@app/components/pages/apps/components/health-checks'
import { AuthenticityToken } from '@app/components/ui/AuthenticityToken'
import { ConfigurableAppActions } from '@app/components/ui/ConfigurableAppActions'
import PageDescription from '@app/components/ui/PageDescription'
import PageLayout from '@app/components/ui/PageLayout'
import PageTitle from '@app/components/ui/PageTitle'
import { usePermission } from '@app/components/ui/PermissionsContext'
import { AppActions } from '@app/types/AppActions'
import { Project } from '@app/types/Project'
import {
  Badge,
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Image,
  List,
  ListItem,
  OrderedList,
  Stack,
  Text,
  useToast
} from '@chakra-ui/react'
import { IconPackage } from '@tabler/icons-react'
import React, { useMemo } from 'react'
import { concurrentGET } from '../../../../lib/api'
import { useBillingMetrics } from '../../../data/use-billing-metrics'
import { FeatureLockout } from '../../../ui/billing-banners/feature-lockout'
import { LightBgCard } from '../../../ui/Card'
import { StepIcon } from '../../../ui/StepIcon'
import { projectPath } from '../../../ui/ProjectsContext'
import { Toggle } from '../../accounts/components/Toggle'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import { FieldMapping, HardcodedRow, MappingRow } from './components/FieldMapping'
import {
  ManagedAccountScores,
  AccountScoresDefinitionsTable,
  AccountScoreMapping,
  AccountScoresSettings
} from '../../../ui/AccountScoresSettings'

interface Props {
  app_id: string
  project: Project
  title: string
  description: string
  logo: string
  actions: AppActions
  valid?: boolean
  connected?: boolean
  deps: HubspotDeps
  settings: {
    permissions?: string

    account_scores_sync_enabled?: boolean
    account_scores_sync_mapping?: AccountScoreMapping

    company_mapping?: MappingRow[]
    contact_mapping?: MappingRow[]

    company_hardcoded_mapping?: HardcodedRow[]
    contact_hardcoded_mapping?: HardcodedRow[]
  }
  connection_details: ConnectionDetail[]
}

export interface List {
  name: string
  listId: number
  createdAt: number
  updatedAt: number
  listType: string
  authorId: number
  archived: boolean
  readOnly: boolean
  dynamic: boolean
}

export interface HubspotOption {
  label: string
  value: string
  description: string
  displayOrder: number
  hidden: boolean
}
export interface HubspotProp {
  name: string
  label: string
  type: FieldType
  field_type: string
  calculated: boolean
  hidden: boolean
  custom: boolean
  options: HubspotOption[]
  readonly: boolean
  modification_metadata: {
    archivable: boolean
    readOnlyDefinition: boolean
    readOnlyValue: boolean
  }
}

export interface HubspotPicklistValue {
  active: boolean
  label: string
  value: string
}

export interface KoalaField {
  name: string
  label: string
  type: string
}

type FieldType = 'number' | 'string' | 'boolean' | 'datetime' | 'enumeration'

export interface HubspotDeps {
  health_checks: HealthCheck[]
  lists?: List[]
  company_layout?: {
    props: HubspotProp[]
  }
  koala_company_layout: KoalaField[]
  contact_layout?: {
    props: HubspotProp[]
  }
  koala_contact_layout: KoalaField[]
  scopes: string[]
  account_scores_sync: ManagedAccountScores
}

function safeDefaults(sourceLayout: KoalaField[], targetLayout: HubspotProp[], attemptedMapping: MappingRow[]) {
  const targetLayoutNames = targetLayout.map((f) => f.name)
  const sourceLayoutNames = sourceLayout.map((f) => f.name)

  return attemptedMapping.filter(
    (mapping) =>
      targetLayoutNames.includes(mapping.hubspot_field ?? '') && sourceLayoutNames.includes(mapping.koala_field ?? '')
  )
}

export default function Show(props: Props) {
  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })
  const hasAutomationScope = React.useMemo(() => {
    return props.deps.scopes.includes('automation')
  }, [props.deps.scopes])

  const hasImportScope = React.useMemo(() => {
    return props.deps.scopes.includes('crm.import')
  }, [props.deps.scopes])

  const metrics = useBillingMetrics()
  const crmBLocked = useMemo(() => {
    if (!metrics.data) {
      return false
    }

    if (metrics.data.entitlements?.crm_limit === undefined) {
      return false
    }

    if (metrics.data.usage?.crms === undefined) {
      return false
    }

    if (metrics.data.usage.crms >= metrics.data.entitlements.crm_limit) {
      return true
    }

    return false
  }, [metrics.data])

  const toast = useToast()
  const [creatingManagedScores, setCreatingManagedScores] = React.useState(false)
  const createManagedScores = React.useCallback(() => {
    setCreatingManagedScores(true)
    concurrentGET(projectPath('/apps/hubspot/dep/create_koala_managed_scores_fields'))
      .then((res: any) => {
        const response = res.data.create_koala_managed_scores_fields

        if (response.success) {
          toast({
            title: 'Koala Managed Scores created',
            description: 'The fields are ready to use',
            status: 'success',
            duration: 3000,
            isClosable: true
          })
        } else {
          const messages = response.messages.map((value, index) => (
            <Text key={index}>
              {index + 1}. {value}
            </Text>
          ))
          toast({
            title: 'Creating Koala Managed Scores had some issues:',
            description: messages,
            status: 'warning',
            duration: 9000,
            isClosable: true
          })
        }
      })
      .catch((err) => {
        toast({
          title: 'Error creating Koala Managed Scores',
          description: 'There was an error creating Koala Managed Scores. ' + err.message,
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      })
      .finally(() => {
        setCreatingManagedScores(false)
      })
  }, [toast])

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/hubspot'),
            title: 'HubSpot'
          }
        ]}
        offscreen
      />
      <Stack>
        <HStack>
          <HStack marginRight="auto" alignItems="center" spacing={2}>
            <Image src={props.logo} maxW="6" />
            <PageTitle>{props.title}</PageTitle>
            {props.connected && props.valid && <Badge colorScheme="green">Connected</Badge>}
            {props.connected && !props.valid && <Badge colorScheme="orange">Requires Reconnection</Badge>}
          </HStack>

          {props.connected && <DisconnectAppDialog appTitle={'HubSpot'} showRemoveCachesOption={true} />}
        </HStack>
        <PageDescription>{props.description}</PageDescription>
      </Stack>

      <FeatureLockout
        locked={crmBLocked && !props.connected}
        blockTitle="CRM Connection Limit Reached"
        upgradeTo="business"
        blockedChildren={
          <Text>
            Your plan is limited to {metrics.data?.entitlements?.crm_limit} CRM integrations. You can upgrade to unlock
            more CRM integrations, Custom Objects, and Custom Fields for Salesforce and HubSpot.
          </Text>
        }
      >
        <>
          {!props.connected && <ConnectOauthAppDialog {...props} />}

          {props.connected && (
            <Stack spacing="12" divider={<Divider />}>
              {!hasAutomationScope && (
                <LightBgCard>
                  <ConnectOauthAppDialog {...props} valid={false}>
                    <Stack fontSize={'sm'} spacing="4">
                      <Heading size="sm" fontWeight={'semibold'}>
                        Missing Permissions
                      </Heading>
                      <Text>
                        Your connection is missing some permissions necessary to support features like sequences and
                        automations with HubSpot.
                      </Text>

                      <Button colorScheme={'orange'} type="submit">
                        Reconnect HubSpot
                      </Button>
                    </Stack>
                  </ConnectOauthAppDialog>
                </LightBgCard>
              )}

              <ConnectionDetails appTitle={'HubSpot'} valid={props.valid} details={props.connection_details} />
              <HealthChecks appModule={'hubspot'} healthChecks={props.deps.health_checks} />

              <Box
                as="form"
                w="100%"
                method="POST"
                opacity={props.connected ? 1 : 0.4}
                pointerEvents={!props.connected ? 'none' : undefined}
              >
                <AuthenticityToken />
                <input type="hidden" name="_method" value="PUT" />
                <Stack w="100%" spacing="4" mb={4}>
                  <AccountScoresSettings<HubspotProp>
                    accountScore={props.deps.account_scores_sync}
                    settings={props.settings}
                    crmFields={props.deps.company_layout?.props}
                    appModule={'hubspot'}
                    canEdit={canEditProject}
                    reconnectDialog={
                      !hasImportScope && (
                        <LightBgCard>
                          <ConnectOauthAppDialog {...props} valid={false}>
                            <Stack fontSize={'sm'} spacing="4">
                              <Heading size="sm" fontWeight={'semibold'}>
                                Missing `crm.import` permissions
                              </Heading>
                              <Text>
                                Your connection is missing `crm.import` permissions necessary to support Account Scores
                                sync with HubSpot.
                              </Text>

                              <Button colorScheme={'orange'} type="submit">
                                Reconnect HubSpot
                              </Button>
                            </Stack>
                          </ConnectOauthAppDialog>
                        </LightBgCard>
                      )
                    }
                  >
                    <Stack py="4">
                      <Heading size="xs">Instructions:</Heading>
                      <HStack spacing="1" fontSize={'sm'} pt="2" pb="6">
                        <Text>
                          The Company object in HubSpot needs to have a couple of fields to receive the Account scores.
                          You can either setup them automatically with the provided install button below or manually add
                          them.
                        </Text>
                      </HStack>
                      <Heading size="xs">Automatic Setup</Heading>
                      <OrderedList fontSize="sm" color="gray.500" spacing="6">
                        <Stack spacing="1" pt="2" pb="6">
                          <ListItem color="gray.800">
                            <StepIcon step={1} />{' '}
                            <Button
                              size={'xs'}
                              variant={'solid'}
                              rightIcon={<IconPackage size="12" />}
                              colorScheme={'orange'}
                              onClick={createManagedScores}
                              isLoading={creatingManagedScores}
                              isDisabled={!canEditProject}
                            >
                              Install Koala Managed Scores
                            </Button>{' '}
                          </ListItem>
                          <ListItem pl="4">
                            <Text fontSize="xs" p="4" bg="gray.50" rounded="lg">
                              <strong>Note:</strong> The fields below will be added to the company inside a Koala
                              Managed Scores group
                            </Text>
                          </ListItem>
                          <ListItem color="gray.800">
                            <StepIcon step={2} /> Enable the sync
                          </ListItem>
                        </Stack>
                      </OrderedList>
                      <Heading size="xs">Manual Setup</Heading>
                      <OrderedList fontSize="sm" color="gray.500" spacing="6">
                        <Stack spacing="1" pt="2" pb="6">
                          <ListItem color="gray.800">
                            <StepIcon step={1} /> Manually add the fields (or compatible ones) to the Company object
                            following these definitions:
                          </ListItem>
                          <ListItem>
                            <AccountScoresDefinitionsTable {...(props.deps.account_scores_sync?.definitions as any)} />
                          </ListItem>
                          <ListItem color="gray.800">
                            <StepIcon step={2} /> Refresh the layouts
                          </ListItem>
                          <ListItem color="gray.800">
                            <StepIcon step={3} /> Enable the sync
                          </ListItem>
                          <ListItem color="gray.800">
                            <StepIcon step={4} /> Enabled and setup a custom mapping for them
                          </ListItem>
                        </Stack>
                      </OrderedList>
                    </Stack>
                  </AccountScoresSettings>

                  <Divider />

                  {Object.keys(props.actions).length > 0 && (
                    <Stack spacing={4}>
                      <Box w={'100%'}>
                        <ConfigurableAppActions appActions={props.actions} canEdit={canEditProject} />
                      </Box>
                    </Stack>
                  )}

                  <Divider />

                  <Toggle
                    title={
                      <Stack justifyContent={'space-between'} w="100%">
                        <Heading size="sm">Default Field Mappings</Heading>
                        <Text fontSize="sm">
                          Define how you map each individual Koala field to a HubSpot field when importing Koala
                          accounts and contacts into HubSpot via the `Import Account/Contact` buttons in People and
                          Account Profiles.
                        </Text>
                      </Stack>
                    }
                  >
                    <Stack w="100%" spacing="4">
                      <FieldMapping
                        hubspotLayout={props.deps.company_layout?.props ?? []}
                        koalaLayout={props.deps.koala_company_layout}
                        defaultIsOpen={!props.settings.company_mapping}
                        mapping={
                          props.settings.company_mapping ??
                          safeDefaults(props.deps.koala_company_layout, props.deps.company_layout?.props ?? [], [
                            {
                              koala_field: 'name',
                              hubspot_field: 'Name'
                            },
                            {
                              koala_field: 'domain',
                              hubspot_field: 'Website'
                            }
                          ])
                        }
                        hardcoded={props.settings.company_hardcoded_mapping}
                        fieldPrefix={'app_instance_settings[company_mapping]'}
                        hardcodedPrefix={'app_instance_settings[company_hardcoded_mapping]'}
                        colorScheme="purple"
                        title={
                          <Stack spacing={'1'} pl="2">
                            <Heading size={'sm'} fontWeight="semibold">
                              Company Mapping
                              {props.settings.company_mapping ? (
                                <Badge ml="2">
                                  {props.settings.company_mapping.length +
                                    (props.settings.company_hardcoded_mapping?.length ?? 0)}
                                </Badge>
                              ) : (
                                ''
                              )}
                            </Heading>
                            <Text fontSize={'sm'}>Define how to map Koala Company records map to HubSpot Company.</Text>
                          </Stack>
                        }
                      />

                      <FieldMapping
                        hubspotLayout={props.deps.contact_layout?.props ?? []}
                        koalaLayout={props.deps.koala_contact_layout}
                        colorScheme="orange"
                        defaultIsOpen={!props.settings.contact_mapping}
                        mapping={
                          props.settings.contact_mapping ??
                          safeDefaults(props.deps.koala_contact_layout, props.deps.contact_layout?.props ?? [], [
                            {
                              koala_field: 'email',
                              hubspot_field: 'Email'
                            },
                            {
                              koala_field: 'title',
                              hubspot_field: 'Title'
                            }
                          ])
                        }
                        hardcoded={props.settings.contact_hardcoded_mapping}
                        fieldPrefix={'app_instance_settings[contact_mapping]'}
                        hardcodedPrefix={'app_instance_settings[contact_hardcoded_mapping]'}
                        title={
                          <Stack spacing={'1'} pl="2">
                            <Heading size={'sm'} fontWeight="semibold">
                              Contact Mapping{' '}
                              {props.settings.contact_mapping ? (
                                <Badge ml="2">
                                  {props.settings.contact_mapping.length +
                                    (props.settings.contact_hardcoded_mapping?.length ?? 0)}
                                </Badge>
                              ) : (
                                ''
                              )}
                            </Heading>
                            <Text fontSize={'sm'}>
                              Define how to map Koala Profile records map to HubSpot Contacts.
                            </Text>
                          </Stack>
                        }
                      />

                      <Text fontSize="sm" p="4" bg="gray.50" rounded="lg">
                        <strong>Note:</strong> These mappings only apply for manual imports via the "Import" button. Any
                        automations will use the mappings specified in their own configuration instead.
                      </Text>
                    </Stack>
                  </Toggle>

                  {Object.keys(props.actions).length > 0 && props.connected && (
                    <Button colorScheme="purple" type="submit" w="100%" isDisabled={!canEditProject}>
                      Save
                    </Button>
                  )}
                </Stack>
              </Box>
            </Stack>
          )}
        </>
      </FeatureLockout>
    </PageLayout>
  )
}
