import {
  Box,
  Button,
  ButtonProps,
  Flex,
  FormControl,
  FormHelperText,
  Heading,
  HStack,
  IconButton,
  Input,
  Link,
  Stack,
  Tooltip,
  Wrap,
  WrapItem
} from '@chakra-ui/react'
import { IconCircleMinus, IconEditCircle, IconInfoCircle } from '@tabler/icons-react'
import { arrayMoveImmutable } from 'array-move'
import dayjs from 'dayjs'
import { format as friendlyNumber } from 'friendly-numbers'
import { uniqBy } from 'lodash'
import { nanoid } from 'nanoid'
import React, { useCallback, useEffect, useMemo } from 'react'
import SortableList, { SortableItem } from 'react-easy-sort'
import { formatNumber } from '../../../lib/number-format'
import router from '../../../lib/router'
import { Apps } from '../../../types/App'
import { useDashboardWidget } from '../../data/use-dashboard-widget'
import { facetQueryString } from '../../data/use-facets'
import { useUrlFilters } from '../../data/use-url-filters'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { Card, GrayCard } from '../../ui/Card'
import CompanyAvatar from '../../ui/CompanyAvatar'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { TextEllipsis } from '../../ui/text-ellipsis'
import { TimeAgo } from '../../ui/TimeAgo'
import { TopBarContent } from '../../ui/TopBarContext'
import { useSearchParams } from '../../ui/useSearchState'
import { FacetFilters } from '../accounts'
import { FilterPreview } from '../accounts/components/FilterPreview'
import { humanize } from '../accounts/facets/filter-cloud'
import { accountPath } from '../accounts/lib/account-path'
import DateRangePicker from '../analytics/components/DateRangePicker'
import { LineGraph } from '../analytics/components/LineGraph'
import { formatShortDuration } from '../analytics/show'
import { mergeParams } from '../icps/types'
import { profilePath } from '../profiles/lib/path'
import { AccountGrowth } from './components/AccountGrowth'
import { FunnelChart, FunnelDrawer, FunnelStep } from './components/funnel'
import { MetricSummary } from './components/MetricSummary'
import { PreviewBanner } from './components/PreviewBanner'
import { TopList, TopListProps } from './components/top-list'
import { Catalog, WidgetCatalog } from './components/widget-catalog'

export interface DashboardWidget {
  id?: string
  widget_name: string
  description: string
  widget_type: 'number' | 'timeseries' | 'table' | 'funnel'
  widget_unit: string
  catalog_id: string
  settings?: Record<string, any>
  color_scheme?: ButtonProps['colorScheme']
  columns?: TopListProps['columns']
}

export interface DashboardFunnel {
  id?: string
  name: string
  description?: string
  steps?: FunnelStep[]
  config?: {
    aggregate_by?: 'accounts' | 'visitors'
  }
}

export interface Dashboard {
  id: string
  name: string
  period: string
  filters: FacetFilters
  created_at: string
  updated_at: string
  dashboard_widgets: DashboardWidget[]
  dashboard_funnels: DashboardFunnel[]

  created_by_user?: {
    name?: string
    email: string
  }
  updated_by_user?: {
    name?: string
    email: string
  }
}

interface Props {
  apps: Apps
  account_ids: string[]
  catalog: Catalog
  dashboard?: Dashboard
  preview_ends_at?: string | null
  lookback_limit?: string | null
}

export default function New(props: Props) {
  const { searchParams: searchP } = useSearchParams()

  const period = useMemo(() => {
    return (searchP['period'] as string) ?? props.dashboard?.period ?? 'month'
  }, [searchP, props.dashboard?.period])

  const setPeriod = useCallback((period) => {
    const path = mergeParams(window.location.toString(), { period })
    router.visit(path)
  }, [])

  const facets = useUrlFilters({
    initialFacets: props.dashboard?.filters
  })

  const [accountIds, setAccountIds] = React.useState<string[]>(props.account_ids)

  useEffect(() => {
    setAccountIds(props.account_ids)
  }, [props, facets.facetFilters])

  const [widgets, setWidgets] = React.useState<DashboardWidget[]>(props.dashboard?.dashboard_widgets ?? [])
  const [funnels, setFunnels] = React.useState<DashboardFunnel[]>(props.dashboard?.dashboard_funnels ?? [])

  const [catalogVisible, setCatalogVisible] = React.useState(false)
  const [funnelVisible, setFunnelVisible] = React.useState(false)
  const [catalogPreference, setCatalogPreference] = React.useState<DashboardWidget['widget_type']>()

  const catalogItems = useMemo(() => {
    return Object.values(props.catalog).filter((c) => c.type === catalogPreference)
  }, [catalogPreference, props.catalog])

  return (
    <PageLayout size="semi-full">
      <TopBarContent>
        <Breadcrumb
          paths={[
            { path: projectPath('/dashboards'), title: 'Dashboards' },
            props.dashboard
              ? { path: projectPath(`/dashboards/${props.dashboard.id}`), title: props.dashboard.name }
              : {
                  path: projectPath('/dashboards/new'),
                  title: 'New Dashboard'
                }
          ]}
        />
      </TopBarContent>

      <PreviewBanner preview_ends_at={props.preview_ends_at} />

      <Box
        as="form"
        action={props.dashboard ? projectPath(`/dashboards/${props.dashboard.id}`) : projectPath('/dashboards')}
        method="POST"
      >
        <AuthenticityToken />
        <input type="hidden" name="_method" value={props.dashboard ? 'PATCH' : 'POST'} />

        <Stack spacing="8">
          <Flex justifyContent={'space-between'} alignItems="flex-start" w="100%" gap={3}>
            <PageTitle skipRendering>{props.dashboard?.name ?? 'New Dashboard'}</PageTitle>

            <FormControl>
              <Input
                defaultValue={props.dashboard?.name ?? 'New Dashboard'}
                fontSize={'lg'}
                fontWeight="semibold"
                maxW={'400px'}
                name="dashboard[name]"
              />
              <FormHelperText>Name your dashboard</FormHelperText>
            </FormControl>

            <HStack>
              <DateRangePicker
                period={period}
                min={props.lookback_limit ? dayjs(props.lookback_limit).toDate() : undefined}
                onChange={setPeriod}
                footer={
                  <Box paddingX={4} paddingBottom={3}>
                    <Box fontSize="xs" fontStyle="italic" color="gray.500">
                      All times are in UTC
                    </Box>
                  </Box>
                }
              />

              <Button type="submit" colorScheme={'purple'} px="4" size="sm">
                Save
              </Button>
            </HStack>
          </Flex>

          <FormControl>
            <FilterPreview
              apps={props.apps}
              {...facets}
              kind="account"
              range="all"
              shouldShowLastSeenFilter={false}
              hideFacetCounts
            />
            <FormHelperText>Select any firmographic or intent filters to filter your dashboard by.</FormHelperText>

            {period && <input type="hidden" name="dashboard[period]" value={period || 'month'} />}
            {facets.facetFilters && (
              <input type="hidden" name="dashboard[filters]" value={JSON.stringify(facets.facetFilters)} />
            )}
          </FormControl>

          <WidgetCatalog
            onAddWidgets={(catalogItems) => {
              const incomingAsWidget = catalogItems.map((catalogItem) => {
                return {
                  ...catalogItem,
                  catalog_id: catalogItem.id,
                  widget_type: catalogItem.type,
                  widget_name: catalogItem.name,
                  widget_unit: catalogItem.unit
                }
              })

              setWidgets(uniqBy([...widgets, ...incomingAsWidget], 'catalog_id'))
            }}
            type={catalogPreference}
            visible={catalogVisible}
            onClose={() => setCatalogVisible(false)}
            catalog={catalogItems}
          />

          {funnelVisible && (
            <FunnelDrawer
              onClose={() => setFunnelVisible(false)}
              apps={props.apps}
              steps={[]}
              onSave={(funnelSteps, aggregate) => {
                setFunnels([
                  ...funnels,
                  {
                    id: nanoid(),
                    name: 'New Funnel',
                    description: 'Some funnel',
                    steps: funnelSteps,
                    config: { aggregate_by: aggregate }
                  }
                ])
              }}
            />
          )}

          <Widgets
            apps={props.apps}
            funnels={funnels}
            onAddWidgetRequest={(type) => {
              if (type !== 'funnel') {
                setCatalogVisible(true)
                setCatalogPreference(type)
              }

              if (type === 'funnel') {
                setFunnelVisible(true)
              }
            }}
            onRemoveWidget={(widget) => {
              setWidgets(widgets.filter((w) => w.catalog_id !== widget.catalog_id))
            }}
            onRemoveFunnel={(funnel) => {
              setFunnels(funnels.filter((f) => f !== funnel))
            }}
            widgets={widgets.map((w) => {
              return {
                ...w,
                description: props.catalog[w.catalog_id]?.description ?? '',
                color_scheme: props.catalog[w.catalog_id]?.color_scheme ?? 'purple',
                columns: props.catalog[w.catalog_id]?.columns ?? [],
                widget_name: props.catalog[w.catalog_id]?.name ?? w.widget_name,
                widget_unit: props.catalog[w.catalog_id]?.unit ?? w.widget_unit,
                widget_type: props.catalog[w.catalog_id]?.type ?? w.widget_type,
                settings: {
                  ...props.catalog[w.catalog_id]?.settings,
                  ...w.settings
                }
              }
            })}
            accountIds={accountIds}
            facetFilters={facets.facetFilters}
            period={period}
          />

          <Button type="submit" colorScheme={'purple'}>
            Save
          </Button>
        </Stack>
      </Box>
    </PageLayout>
  )
}

function Widgets(props: {
  apps: Apps
  widgets: DashboardWidget[]
  funnels: DashboardFunnel[]
  accountIds: string[]
  facetFilters?: FacetFilters
  period: string
  onAddWidgetRequest?: (type: DashboardWidget['widget_type']) => void
  onRemoveWidget?: (widget: DashboardWidget) => void
  onRemoveFunnel?: (funnel: DashboardFunnel) => void
}) {
  const funnels = props.funnels
  const [metrics, setMetrics] = React.useState<DashboardWidget[]>(
    props.widgets.filter((w) => w.widget_type === 'number')
  )

  const [tables, setTables] = React.useState<DashboardWidget[]>(props.widgets.filter((w) => w.widget_type === 'table'))

  const [charts, setCharts] = React.useState<DashboardWidget[]>(
    props.widgets.filter((w) => w.widget_type === 'timeseries')
  )

  useEffect(() => {
    setMetrics(props.widgets.filter((w) => w.widget_type === 'number'))
    setTables(props.widgets.filter((w) => w.widget_type === 'table'))
    setCharts(props.widgets.filter((w) => w.widget_type === 'timeseries'))
  }, [props.widgets])

  return (
    <Stack w="100%" spacing="8">
      <Stack as={GrayCard} spacing="4">
        <HStack w="100%" justifyContent={'space-between'}>
          <Heading size="sm" fontWeight={'semibold'}>
            Metrics
          </Heading>
          {props.onAddWidgetRequest && (
            <Button
              size="sm"
              onClick={() => props.onAddWidgetRequest?.('number')}
              variant="outline"
              colorScheme="purple"
            >
              Add Metric
            </Button>
          )}
        </HStack>
        <SortableList
          onSortEnd={(oldIndex: number, newIndex: number) =>
            setMetrics((array) => arrayMoveImmutable(array, oldIndex, newIndex))
          }
          draggedItemClassName="dragged"
          style={{
            userSelect: 'none',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
            gridGap: '16px'
          }}
        >
          {metrics.map((widget) => {
            return (
              <SortableItem key={widget.catalog_id}>
                <Box>
                  <input type="hidden" name="dashboard[widgets][][catalog_id]" value={widget.catalog_id} />
                  <Widget
                    widget={widget}
                    accountIds={props.accountIds}
                    facetFilters={props.facetFilters ?? {}}
                    period={props.period}
                    onRemove={() => {
                      props.onRemoveWidget?.(widget)
                    }}
                  />
                </Box>
              </SortableItem>
            )
          })}
        </SortableList>
      </Stack>

      <Stack as={GrayCard} spacing="4">
        <HStack w="100%" justifyContent={'space-between'}>
          <Heading size="sm" fontWeight={'semibold'}>
            Charts
          </Heading>
          {props.onAddWidgetRequest && (
            <Button
              size="sm"
              onClick={() => props.onAddWidgetRequest?.('timeseries')}
              variant="outline"
              colorScheme="purple"
            >
              Add Chart
            </Button>
          )}
        </HStack>
        <SortableList
          onSortEnd={(oldIndex: number, newIndex: number) =>
            setCharts((array) => arrayMoveImmutable(array, oldIndex, newIndex))
          }
          draggedItemClassName="dragged"
          style={{
            userSelect: 'none',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(750px, 1fr) )',
            gridGap: '16px'
          }}
        >
          {charts.map((widget) => {
            return (
              <SortableItem key={widget.catalog_id}>
                <Box>
                  <input type="hidden" name="dashboard[widgets][][catalog_id]" value={widget.catalog_id} />
                  <Widget
                    widget={widget}
                    accountIds={props.accountIds}
                    facetFilters={props.facetFilters ?? {}}
                    period={props.period}
                    onRemove={() => {
                      props.onRemoveWidget?.(widget)
                    }}
                  />
                </Box>
              </SortableItem>
            )
          })}
        </SortableList>
      </Stack>

      <Stack as={GrayCard} spacing="4">
        <HStack w="100%" justifyContent={'space-between'}>
          <Heading size="sm" fontWeight={'semibold'}>
            Funnels
          </Heading>
          {props.onAddWidgetRequest && (
            <Button
              size="sm"
              onClick={() => props.onAddWidgetRequest?.('funnel')}
              variant="outline"
              colorScheme="purple"
            >
              Add Funnel
            </Button>
          )}
        </HStack>
        <Wrap justifyContent={'center'} alignItems="center" spacing="8">
          {funnels.map((funnel, index) => {
            return (
              <WrapItem key={funnel.name + funnel.id || index} flex="1">
                <Funnel
                  apps={props.apps}
                  funnel={funnel}
                  onRemove={() => {
                    props.onRemoveFunnel?.(funnel)
                  }}
                  period={props.period}
                  accountIds={props.accountIds}
                  facetFilters={props.facetFilters ?? {}}
                />
              </WrapItem>
            )
          })}
        </Wrap>
      </Stack>

      <Stack as={GrayCard} spacing="4">
        <HStack w="100%" justifyContent={'space-between'}>
          <Heading size="sm" fontWeight={'semibold'}>
            Tables
          </Heading>
          {props.onAddWidgetRequest && (
            <Button
              size="sm"
              onClick={() => props.onAddWidgetRequest?.('table')}
              variant="outline"
              colorScheme="purple"
            >
              Add Table
            </Button>
          )}
        </HStack>
        <SortableList
          onSortEnd={(oldIndex: number, newIndex: number) =>
            setTables((array) => arrayMoveImmutable(array, oldIndex, newIndex))
          }
          draggedItemClassName="dragged"
          style={{
            userSelect: 'none',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(700px, 1fr))',
            gridGap: '16px'
          }}
        >
          {tables.map((widget) => {
            return (
              <SortableItem key={widget.catalog_id}>
                <Box>
                  <input type="hidden" name="dashboard[widgets][][catalog_id]" value={widget.catalog_id} />
                  <Widget
                    widget={widget}
                    accountIds={props.accountIds}
                    facetFilters={props.facetFilters ?? {}}
                    period={props.period}
                    onRemove={() => {
                      props.onRemoveWidget?.(widget)
                    }}
                  >
                    {widget.catalog_id === 'account_growth' && (
                      <AccountGrowth
                        period={props.period as string}
                        accountIds={props.accountIds ?? []}
                        facetFilters={props.facetFilters ?? {}}
                      />
                    )}
                  </Widget>
                </Box>
              </SortableItem>
            )
          })}
        </SortableList>
      </Stack>
    </Stack>
  )
}

function Funnel(props: {
  funnel?: DashboardFunnel
  accountIds: string[]
  period: string
  facetFilters: FacetFilters
  focusTime?: string
  apps: Apps
  onRemove?: () => void
}) {
  const [steps, setSteps] = React.useState<FunnelStep[]>(props.funnel?.steps ?? [])
  const [aggregate, setAggregate] = React.useState(props.funnel?.config?.aggregate_by ?? 'accounts')
  const [name, setName] = React.useState(props.funnel?.name ?? 'New Funnel')
  const [editing, setEditing] = React.useState(false)

  return (
    <Box position="relative" w="100%" role="group">
      {editing && (
        <FunnelDrawer
          aggregate={aggregate}
          steps={steps}
          onClose={() => {
            setEditing(false)
          }}
          apps={props.apps}
          onSave={(funnelSteps, aggregate) => {
            setSteps(funnelSteps)
            setAggregate(aggregate)
            setEditing(false)
          }}
        />
      )}

      <IconButton
        aria-label="Delete"
        size="xs"
        icon={<IconEditCircle size="16px" />}
        position="absolute"
        right="9"
        top="2"
        visibility={'hidden'}
        colorScheme="purple"
        variant={'ghost'}
        _groupHover={{ visibility: 'visible' }}
        onClick={() => {
          setEditing(true)
        }}
      />

      <Tooltip label="Remove">
        <IconButton
          aria-label="Delete"
          size="xs"
          icon={<IconCircleMinus size="16px" />}
          position="absolute"
          right="2"
          top="2"
          visibility={'hidden'}
          colorScheme="red"
          variant={'ghost'}
          _groupHover={{ visibility: 'visible' }}
          onClick={props.onRemove}
        />
      </Tooltip>
      <input type="hidden" name="dashboard[funnels][][steps]" value={JSON.stringify(steps)} />
      <input type="hidden" name="dashboard[funnels][][config][aggregate_by]" value={aggregate} />
      <input type="hidden" name="dashboard[funnels][][name]" value={name} />

      <FunnelChart
        apps={props.apps}
        name={name}
        onChangeName={(name) => {
          setName(name)
        }}
        onSave={(funnelSteps, aggregate) => {
          setSteps(funnelSteps)
          setAggregate(aggregate)
        }}
        period={props.period}
        aggregateBy={aggregate}
        accountIds={props.accountIds}
        facetFilters={props.facetFilters ?? {}}
        steps={steps}
      />
    </Box>
  )
}

function Widget(props: {
  widget: DashboardWidget
  accountIds: string[]
  period: string
  facetFilters: FacetFilters
  focusTime?: string
  onRemove?: () => void
  children?: React.ReactNode
}) {
  const { period } = props

  const params = useMemo(() => ({ period }), [period])
  const facetQuery = useMemo(() => {
    let query = facetQueryString(props.facetFilters).join('&')

    if (props.focusTime) {
      const ft = facetQueryString({ focus_time: props.focusTime }).join('&')
      query = [query, ft].filter(Boolean).join('&')
    }

    return query
  }, [props.focusTime, props.facetFilters])

  const widgetData = useDashboardWidget(props.widget.catalog_id, params, facetQuery, props.accountIds)

  if (props.widget.widget_type === 'number') {
    return (
      <Box
        w={props.children ? '100%' : '250px'}
        flex={props.children ? '1' : undefined}
        position="relative"
        as={!props.children ? Card : undefined}
        role="group"
      >
        <Tooltip label={props.widget.description}>
          <IconButton
            aria-label="Info"
            size="xs"
            icon={<IconInfoCircle size="16px" />}
            position="absolute"
            variant={'ghost'}
            right="9"
            top="2"
            color="gray.400"
            visibility={'hidden'}
            _groupHover={{
              visibility: 'visible'
            }}
          />
        </Tooltip>

        <Tooltip label="Remove">
          <IconButton
            aria-label="Delete"
            size="xs"
            icon={<IconCircleMinus size="16px" />}
            position="absolute"
            right="2"
            top="2"
            visibility={'hidden'}
            colorScheme="red"
            variant={'ghost'}
            _groupHover={{ visibility: 'visible' }}
            onClick={props.onRemove}
          />
        </Tooltip>
        {props.children}
        {!props.children && (
          <MetricSummary
            title={props.widget.widget_name}
            isLoading={widgetData.isLoading}
            data={widgetData.data?.data}
            error={widgetData.error as Error}
            onRetry={widgetData.refetch}
            unit={props.widget.widget_unit}
          />
        )}
      </Box>
    )
  }

  if (props.widget.widget_type === 'timeseries') {
    return (
      <Box w="100%" position="relative" role="group" as={!props.children ? Card : undefined}>
        <Heading size="xs" pb="4">
          {props.widget.widget_name.replace(' Timeseries', '')}
        </Heading>

        <Tooltip label={props.widget.description}>
          <IconButton
            aria-label="Info"
            size="xs"
            icon={<IconInfoCircle size="16px" />}
            position="absolute"
            variant={'ghost'}
            right="9"
            top="2"
            color="gray.400"
            visibility={'hidden'}
            _groupHover={{
              visibility: 'visible'
            }}
          />
        </Tooltip>

        <Tooltip label="Remove">
          <IconButton
            aria-label="Delete"
            size="xs"
            icon={<IconCircleMinus size="16px" />}
            position="absolute"
            right="2"
            top="2"
            visibility={'hidden'}
            colorScheme="red"
            variant={'ghost'}
            _groupHover={{ visibility: 'visible' }}
            onClick={props.onRemove}
          />
        </Tooltip>

        {props.children}
        {!props.children && (
          <LineGraph
            isLoading={widgetData.isLoading}
            data={widgetData.data?.data?.timeseries ?? []}
            label={humanize(props.widget.widget_unit ?? props.widget.widget_name)}
            period={period}
            colorScheme={props.widget.color_scheme}
            formatter={(value) => {
              if (props.widget.widget_unit === 'currency') {
                const val = new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD'
                }).format(value)

                return [val] as any as [string, string]
              }

              const label = humanize(props.widget.widget_unit ?? props.widget.widget_name)
              return [`${formatNumber(value)} ${label}`] as any as [string, string]
            }}
            yFormatter={(value: number) => {
              if (props.widget.widget_unit === 'currency') {
                return '$' + friendlyNumber(value)
              }

              return formatNumber(value)
            }}
            error={widgetData.error as Error}
            onRetry={widgetData.refetch}
            smoothing={props.widget.settings?.smoothing}
            showSmoothingOptions
          />
        )}
      </Box>
    )
  }

  if (props.widget.widget_type === 'table') {
    const columns = Object.keys(widgetData.data?.data?.entries?.[0] ?? {})

    return (
      <Box w="100%" position="relative" role="group" as={!props.children ? Card : undefined}>
        <Tooltip label={props.widget.description}>
          <IconButton
            aria-label="Info"
            size="xs"
            icon={<IconInfoCircle size="16px" />}
            position="absolute"
            variant={'ghost'}
            right="9"
            top="2"
            color="gray.400"
            visibility={'hidden'}
            _groupHover={{
              visibility: 'visible'
            }}
          />
        </Tooltip>
        <Tooltip label="Remove">
          <IconButton
            aria-label="Delete"
            size="xs"
            icon={<IconCircleMinus size="16px" />}
            position="absolute"
            right="2"
            top="2"
            visibility={'hidden'}
            colorScheme="red"
            variant={'ghost'}
            _groupHover={{ visibility: 'visible' }}
            onClick={props.onRemove}
          />
        </Tooltip>

        {props.children}
        {!props.children && (
          <TopList
            title={props.widget.widget_name}
            isLoading={widgetData.isLoading}
            error={widgetData.error as Error}
            data={widgetData.data?.data?.entries}
            onRetry={widgetData.refetch}
            mode={props.widget.settings?.mode}
            column={props.widget.settings?.column}
            columns={
              props.widget.columns?.map((column) => {
                return {
                  ...column,
                  numeric: column.type === 'number' || column.type === 'duration' || column.type === 'currency',
                  formatter: (value: any) => {
                    if (column.type === 'text' || !column.type) {
                      return (
                        <TextEllipsis maxW={'250'} tooltip>
                          {value.toString()}
                        </TextEllipsis>
                      )
                    }

                    if (column.type === 'number') {
                      return formatNumber(value as number)
                    }

                    if (column.type === 'currency') {
                      return new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD'
                      }).format(value as number)
                    }

                    if (column.type === 'duration') {
                      return formatShortDuration(value as number)
                    }

                    if (column.type === 'email' && value) {
                      const email = value ?? ''
                      const domain = email.split('@')[1] ?? ''
                      return (
                        <HStack>
                          <CompanyAvatar domain={domain} size="xs" />
                          <Link
                            href={profilePath({
                              email,
                              id: email
                            })}
                            isExternal
                          >
                            <Heading size="xs">{email}</Heading>
                          </Link>
                        </HStack>
                      )
                    }

                    if (column.type === 'domain' && value) {
                      const domain = value ?? ''

                      return (
                        <HStack>
                          <CompanyAvatar domain={domain} size="xs" />
                          <Link
                            href={accountPath({
                              domain
                            })}
                            isExternal
                          >
                            <Heading size="xs">{domain}</Heading>
                          </Link>
                        </HStack>
                      )
                    }

                    if (column.type === 'datetime' && value) {
                      return <TimeAgo time={value} />
                    }

                    return value
                  }
                }
              }) ??
              columns.map((c) => {
                return {
                  key: c,
                  label: humanize(c),
                  numeric: parseInt(widgetData.data?.data?.entries?.[0]?.[c] ?? '') > 0
                }
              })
            }
          />
        )}
      </Box>
    )
  }

  return null
}
