import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Icon,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useClipboard
} from '@chakra-ui/react'
import {
  IconAlertTriangle,
  IconArrowDown,
  IconArrowUp,
  IconCircleXFilled,
  IconCopy,
  IconInfoCircle,
  IconPlayerPause
} from '@tabler/icons-react'
import React from 'react'
import { AdminBreadcrumb } from '..'
import router from '../../../../lib/router'
import { PageMeta } from '../../../../types/PageMeta'
import { DateTime } from '../../../../types/Profile'
import { Project } from '../../../../types/Project'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import { CheckboxCircleIcon } from '../../../ui/icons'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import SelectInput from '../../../ui/SelectInput'
import { TableFooter } from '../../../ui/TableFooter'
import { TimeAgo } from '../../../ui/TimeAgo'
import { mergeParams } from '../../icps/types'
import SyncDuration from '../components/SyncDuration'

export interface Sync {
  id: number
  status: 'running' | 'finished' | 'stopped' | 'paused' | 'failed'
  model: string
  app_module: string
  sync_type: 'full' | 'incremental'
  total: number
  pending_count: number | null
  skipped_count: number | null
  synced_count: number | null
  started_at: DateTime
  stopped_at?: DateTime | null
  results?: {
    latest_error?: string
    ref_ids?: string[]
    errors?: {
      message: string
      timestamp: string
    }[]
  }
  project?: {
    name: string
  }
}

interface Props {
  projects: Project[]
  project?: null | Project
  page_meta: PageMeta
  syncs: Sync[]
}

const statuses = [
  { label: 'All', value: undefined },
  { label: 'Failed', value: 'failed' },
  { label: 'Finished', value: 'finished' },
  { label: 'Paused', value: 'paused' },
  { label: 'Running', value: 'running' },
  { label: 'Stopped', value: 'stopped' }
]

const apps = ['All', 'Hubspot', 'Salesforce', 'Outreach', 'Salesloft', 'G2']

const models = [
  'All',
  'Accounts',
  'Account Scores',
  'Companies',
  'Contacts',
  'Leads',
  'Deals',
  'Owners',
  'Opportunities',
  'Pipelines',
  'Prospects',
  'Sequences',
  'Users',
  'Cadences',
  'G2IntentHistory',
  'G2EventStream',
  'SlackUserCache'
]

const syncTypes = [
  { label: 'All', value: undefined },
  { label: 'Full', value: 'full' },
  { label: 'Incremental', value: 'incremental' }
]

const records = [
  { label: 'All', value: undefined },
  { label: 'With Records', value: 'true' },
  { label: 'No Records', value: 'false' },
  { label: 'Big Syncs', value: 'big_syncs' }
]

export default function Index({ project, projects, syncs, page_meta }: Props) {
  const searchParams = new URLSearchParams(window.location.search)

  const latestError = (sync) => {
    return sync?.results?.latest_error || sync?.results?.errors?.toSpliced()?.pop()?.message
  }

  return (
    <PageLayout size="md">
      <AdminBreadcrumb paths={[{ path: '/admin/syncs', title: 'Integration Syncs' }]} />

      <PageTitle>Syncs</PageTitle>

      <Stack spacing={8}>
        <Grid gap={3} gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))">
          <FormControl size="sm">
            <FormLabel>Workspace</FormLabel>
            <ComboboxWithSearch
              items={projects}
              selectedItem={projects.find((s) => s.slug === project?.slug) || null}
              onChange={(item) => {
                const workspace = item?.slug || ''
                const url = mergeParams(window.location.toString(), { workspace })
                router.visit(url)
              }}
              filterItem={(a, val) => a.name.toLowerCase().includes(val) || a.slug.toLowerCase().includes(val)}
              itemToString={(item) => item?.name ?? item?.slug ?? ''}
              itemRenderer={WorkspaceRenderer}
              selectButtonRenderer={WorkspaceRenderer}
              showClearButton={true}
            />
          </FormControl>
          <FormControl size="sm">
            <FormLabel>Status</FormLabel>
            <SelectInput
              variant="outline"
              placeholder="All"
              size="sm"
              items={statuses}
              itemToString={(item) => item.label}
              popperOptions={{ matchWidth: true }}
              selectedItem={statuses.find((s) => s.value === searchParams.get('status')) || 'All'}
              onSelectedItemChange={(event) => {
                const status = event.selectedItem.value
                router.visit(mergeParams(window.location.toString(), { status }))
              }}
            />
          </FormControl>
          <FormControl size="sm">
            <FormLabel>App</FormLabel>
            <SelectInput
              variant="outline"
              placeholder="All"
              size="sm"
              items={apps}
              popperOptions={{ matchWidth: true }}
              selectedItem={searchParams.get('app') || 'All'}
              onSelectedItemChange={(event) => {
                const app = event.selectedItem === 'All' ? undefined : event.selectedItem
                router.visit(mergeParams(window.location.toString(), { app }))
              }}
            />
          </FormControl>
          <FormControl size="sm">
            <FormLabel>Model</FormLabel>
            <SelectInput
              variant="outline"
              placeholder="All"
              size="sm"
              items={models}
              popperOptions={{ matchWidth: true }}
              selectedItem={searchParams.get('model') || 'All'}
              onSelectedItemChange={(event) => {
                const model = event.selectedItem === 'All' ? undefined : event.selectedItem
                router.visit(mergeParams(window.location.toString(), { model }))
              }}
            />
          </FormControl>
          <FormControl size="sm">
            <FormLabel>Records</FormLabel>
            <SelectInput
              variant="outline"
              placeholder="All"
              size="sm"
              items={records}
              itemToString={(item) => item.label}
              popperOptions={{ matchWidth: true }}
              selectedItem={records.find((r) => r.value === searchParams.get('records')) || 'All'}
              onSelectedItemChange={(event) => {
                const records = event.selectedItem.value
                router.visit(mergeParams(window.location.toString(), { records }))
              }}
            />
          </FormControl>
          <FormControl size="sm">
            <FormLabel>Sync Type</FormLabel>
            <SelectInput
              variant="outline"
              placeholder="All"
              size="sm"
              items={syncTypes}
              itemToString={(item) => item.label}
              popperOptions={{ matchWidth: true }}
              selectedItem={syncTypes.find((r) => r.value === searchParams.get('sync_type')) || 'All'}
              onSelectedItemChange={(event) => {
                const sync_type = event.selectedItem.value
                router.visit(mergeParams(window.location.toString(), { sync_type }))
              }}
            />
          </FormControl>
        </Grid>

        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>App / Model</Th>
                <Th isNumeric>Pending</Th>
                <Th isNumeric>Skipped</Th>
                <Th isNumeric>Synced</Th>
                <Th>Sync Type</Th>
                <Th>Time</Th>
              </Tr>
            </Thead>
            <Tbody>
              {syncs.map((sync) => (
                <Tr key={sync.id} _hover={{ bg: 'gray.50' }}>
                  <Td>
                    <HStack spacing={3} alignItems="flex-start" py={2.5}>
                      <Box
                        flex="none"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        boxSize={5}
                        alignSelf="flex-start"
                      >
                        <SyncStatus status={sync.status} />
                      </Box>
                      <Stack flex="1 1 auto" spacing={0.5}>
                        <HStack alignItems="baseline" flexWrap="wrap">
                          <Text flex="none" fontSize="md" fontWeight="semibold" whiteSpace="nowrap">
                            {sync.app_module.replace(/(Apps|App|::)/gi, '')} {sync.model}
                          </Text>
                        </HStack>
                        <Text fontSize="sm" color="gray.600">
                          {sync.project?.name}
                        </Text>
                        {sync.status !== 'finished' && latestError(sync) && (
                          <Text
                            pt={1}
                            flex="1 1 auto"
                            maxWidth={420}
                            wordBreak="break-all"
                            fontSize="sm"
                            color="red.300"
                            minW="100px"
                            isTruncated
                          >
                            {latestError(sync)}
                          </Text>
                        )}
                      </Stack>
                    </HStack>
                  </Td>

                  <Td w="1px" isNumeric>
                    <Text fontSize="sm">{sync.pending_count?.toLocaleString() || 0}</Text>
                  </Td>
                  <Td w="1px" isNumeric>
                    <Text fontSize="sm">{sync.skipped_count?.toLocaleString() || 0}</Text>
                  </Td>
                  <Td w="1px" isNumeric>
                    <Text fontSize="sm">{sync.synced_count?.toLocaleString() || 0}</Text>
                  </Td>
                  <Td w="140px">
                    <HStack spacing={3} alignItems="center">
                      {(sync.model == 'Account Scores' && <Icon as={IconArrowUp} color="blue.500" boxSize={5} />) || (
                        <Icon as={IconArrowDown} color="red.500" boxSize={5} />
                      )}
                      <Text fontSize="sm" textTransform="lowercase">
                        {sync.sync_type}
                      </Text>
                      {sync?.results?.ref_ids && (
                        <Menu>
                          <MenuButton
                            alignItems="center"
                            justifyContent="center"
                            display="flex"
                            p={0}
                            title="CRM reference ids"
                          >
                            <Icon
                              boxSize={4}
                              as={IconInfoCircle}
                              verticalAlign="middle" // Ensure it's vertically aligned
                              marginBottom="1px" // Adjust this value to fine-tune alignment
                            />
                          </MenuButton>
                          <MenuList>
                            {sync?.results?.ref_ids?.map((ref_id) => <RefWithClipboard ref_id={ref_id} key={ref_id} />)}
                          </MenuList>
                        </Menu>
                      )}
                    </HStack>
                  </Td>

                  <Td w="180px" fontSize="sm">
                    <TimeAgo time={sync.started_at} />
                    <SyncDuration start={sync.started_at} stop={sync.stopped_at} status={sync.status} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        <TableFooter
          pageMeta={page_meta}
          page={page_meta.current_page}
          nextPath={mergeParams(window.location.toString(), {
            page: String(page_meta.next_page)
          })}
          prevPath={mergeParams(window.location.toString(), {
            page: String(page_meta.prev_page)
          })}
        />
      </Stack>
    </PageLayout>
  )
}

interface SyncStatusProps {
  status: Sync['status']
}

function SyncStatus(props: SyncStatusProps) {
  switch (props.status) {
    case 'running':
      return <Spinner colorScheme="purple" size="sm" />
    case 'finished':
      return <CheckboxCircleIcon color="green.500" boxSize={5} />
    case 'stopped':
      return <Icon as={IconCircleXFilled} color="red.500" boxSize={5} />
    case 'paused':
      return <Icon as={IconPlayerPause} color="yellow.500" boxSize={5} />
    case 'failed':
      return <Icon as={IconAlertTriangle} color="red.500" boxSize={5} />
    default:
      return null
  }
}

function RefWithClipboard(props: { ref_id: string }) {
  const { onCopy, hasCopied } = useClipboard(props.ref_id)

  return (
    <MenuItem icon={<Icon as={IconCopy} boxSize={5} />} key={props.ref_id} onClick={onCopy}>
      {hasCopied ? 'Copied!' : props.ref_id}
    </MenuItem>
  )
}

function WorkspaceRenderer(props: { item: Project | null }) {
  const workspace = props.item

  if (!workspace) {
    return (
      <Text lineHeight="16px" fontSize="sm">
        Select a workspace
      </Text>
    )
  }

  return (
    <Text lineHeight="16px" fontSize="sm" fontWeight="medium">
      {workspace.slug}
    </Text>
  )
}
