import React from 'react'
import { useMutation } from 'react-query'
import { toast } from 'sonner'
import { put } from '../../lib/api'
import { projectPath } from '../ui/ProjectsContext'

interface Params {
  domain: string
}

export function useUnclaimAccount() {
  const onSuccess = React.useCallback(() => {
    toast.success('You unclaimed this account!')
  }, [])

  const onError = React.useCallback(() => {
    toast.error('There was an issue unclaiming this account.')
  }, [])

  return useMutation(
    ({ domain }: Params) => {
      const path = projectPath(`/accounts/${domain}`)
      return put(path, {
        account: {
          status: null,
          assignee_id: null
        }
      })
    },
    {
      onSuccess,
      onError
    }
  )
}
