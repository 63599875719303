import { useQuery } from 'react-query'
import { concurrentGET } from '../../lib/api'
import { useCurrentProject, projectPath } from '../ui/ProjectsContext'
import type { ProfileRecord } from '../../types/Profile'

export function useIdentifiedVisitors(companyId: string | undefined) {
  const project = useCurrentProject()
  const path = projectPath(`/accounts/${companyId}/identified`)

  return useQuery(
    ['identified', { companyId, projectId: project?.id }],
    () => concurrentGET<{ identified: ProfileRecord[] }>(path),
    { enabled: Boolean(companyId && project?.id) }
  )
}
