import { Flex, HStack, IconButton, InputProps, Stack, Tag, Text, Tooltip } from '@chakra-ui/react'
import { IconCirclePlus, IconHelp } from '@tabler/icons-react'
import { format } from 'friendly-numbers'
import React, { useEffect } from 'react'
import { concurrentGET } from '../../lib/api'
import { JSONValue } from '../pages/kql_definitions/types'
import Combobox from './Combobox'
import { HoverCard } from './HoverCard'
import { projectPath } from './ProjectsContext'
import { VirtualList } from './VirtualList'

interface TopKProps {
  property?: string
  matcher?: string
  examples?: string[]
  defaultValue?: string | JSONValue
  inputValue?: string
  name?: string
  size?: InputProps['size']
  onChange?: (value: string) => void
  inputProps?: InputProps
  showMatches?: boolean
  helperContent?: React.ReactNode
  skipExamples?: boolean
}

export function TopK(props: TopKProps) {
  const [examples, setExamples] = React.useState<string[]>(props.examples ?? [])
  const [stats, setStats] = React.useState<Record<string, number>>({})
  const [isOpen, setIsOpen] = React.useState(false)
  const [val, setVal] = React.useState(props.defaultValue ?? '')

  useEffect(() => {
    if (props.inputValue) {
      setVal(props.inputValue)
    }
  }, [props.inputValue])

  useEffect(() => {
    setExamples(props.examples ?? [])
  }, [props.examples])

  React.useEffect(() => {
    let canceled = false

    if (props.property === undefined) {
      return
    }

    concurrentGET<{ top_k: Array<{ value: string; count: number }> }>(
      projectPath(`/top-k?key=${props.property}&matcher=${props.matcher || ''}`)
    ).then((res) => {
      if (canceled) return

      const incoming = res.top_k?.map((x) => x.value).filter(Boolean) ?? []
      const stats =
        res.top_k?.reduce((acc, entry) => {
          acc[entry.value] = entry.count
          return acc
        }, {} as Record<string, number>) ?? {}

      setStats(stats)
      setExamples((existing) => {
        return Array.from(new Set([...incoming, ...existing]))
      })
    })

    return () => {
      canceled = true
    }
  }, [props.property, props.matcher])

  const filtered = React.useMemo(() => {
    return examples
      .map((ex) => {
        if (typeof ex !== 'string') {
          return JSON.stringify(ex)
        }
        return ex
      })
      .filter((ex) => {
        return ex.trim().length > 0 && ex.toLowerCase().includes((val ?? '').toString().toLowerCase())
      })
  }, [val, examples])

  return (
    <HStack w="100%">
      <Flex alignItems="center" gap={1} w="100%">
        <Combobox
          w="100%"
          inputMode="search"
          inputValue={val?.toString()}
          inputProps={{
            w: '100%',
            size: props.size,
            name: props.name,
            ...props.inputProps
          }}
          menuProps={{
            marginTop: 1,
            maxW: '420px'
          }}
          isOpen={isOpen}
          items={filtered}
          onIsOpenChange={({ isOpen }) => {
            setIsOpen(!!isOpen)
          }}
          renderItem={({ item }) => {
            return (
              <HStack fontSize={'xs'} px={1} w="100%" justifyContent={'space-between'}>
                <Text overflow={'auto'}>{item}</Text>
                {props.showMatches && stats[item] && <Tag size={props.size}>{format(stats[item])}</Tag>}
              </HStack>
            )
          }}
          onInputValueChange={({ inputValue }) => {
            const val = inputValue ?? ''
            setVal(val)
            props.onChange?.(val)
          }}
        />
        {!props.skipExamples && examples.length > 0 && (
          <HoverCard
            hoverContent={
              <Stack paddingY={1}>
                <Text fontSize="sm" fontWeight="semibold">
                  Examples
                </Text>
                <VirtualList
                  items={examples}
                  maxH={'200px'}
                  estimateSize={() => 32}
                  renderItem={(item) => {
                    return (
                      <Tooltip label={item.length > 40 ? item : undefined}>
                        <HStack
                          justifyContent={'space-between'}
                          role="group"
                          w="100%"
                          px="2"
                          rounded="md"
                          cursor={'pointer'}
                          onClick={(e) => {
                            e.stopPropagation()
                            setVal(item)
                            props.onChange?.(item)
                          }}
                          _hover={{
                            bg: 'background.light'
                          }}
                        >
                          <Text
                            maxW="70%"
                            key={item}
                            fontSize="sm"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                          >
                            {item}
                          </Text>
                          <IconButton
                            size="xs"
                            variant={'ghost'}
                            color="gray.500"
                            visibility="hidden"
                            _groupHover={{
                              visibility: 'visible'
                            }}
                            aria-label="Insert"
                            icon={<IconCirclePlus size={16} />}
                          />
                        </HStack>
                      </Tooltip>
                    )
                  }}
                />
              </Stack>
            }
          >
            <IconButton
              onClick={() => {}}
              size="xs"
              variant={'ghost'}
              aria-label="Examples"
              color="gray.400"
              _hover={{ color: 'gray.600' }}
              icon={<IconHelp size={16} />}
            />
          </HoverCard>
        )}
      </Flex>

      {props.helperContent && (
        <HoverCard hoverContent={props.helperContent}>
          <IconButton
            aria-label="Help"
            color="gray.400"
            _hover={{ color: 'gray.600' }}
            icon={<IconHelp size={16} />}
            size={props.size || 'sm'}
            variant={'ghost'}
          />
        </HoverCard>
      )}
    </HStack>
  )
}
