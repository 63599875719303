import { Icon, IconProps } from '@chakra-ui/react'
import React from 'react'

interface Props extends IconProps {
  size?: string | number
}

export function SearchIcon({ size, ...props }: Props) {
  size = size ? `${size}px` : size
  return (
    <Icon fill="none" viewBox="0 0 24 24" stroke="currentColor" width={size} height={size} {...props}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
      />
    </Icon>
  )
}
