import { deepEqual } from 'fast-equals'
import isUndefined from 'lodash/isUndefined'
import omitBy from 'lodash/omitBy'
import qs, { ParsedQs } from 'qs'
import React from 'react'
import Router from '../../lib/router'
import useLocation from './useLocation'

export interface InputParams {
  [key: string]: undefined | null | number | string | string[] | InputParams | InputParams[]
}
export type SearchParamsInit = string | URLSearchParams | InputParams
type SetURLSearchParams = (nextInit?: SearchParamsInit | ((prev: ParsedQs) => SearchParamsInit)) => void

const defaultOpts = { arrayFormat: 'brackets', encodeValuesOnly: true, allowEmptyArrays: true } as const

export function createSearchParams(init: SearchParamsInit = ''): ParsedQs {
  let search: string

  if (typeof init === 'string') {
    search = init
  } else if (init instanceof URLSearchParams) {
    search = init.toString()
  } else {
    search = qs.stringify(omitBy(init, isUndefined), defaultOpts)
  }

  const parsed = qs.parse(search, { ignoreQueryPrefix: true, allowEmptyArrays: true })
  return parsed
}

export function withDefaultParams(search: SearchParamsInit = '', defaults: ParsedQs) {
  const params = createSearchParams(search)

  for (const key of Object.keys(defaults)) {
    if (!(key in params)) {
      const value = defaults[key]
      if (value === undefined || value === null || value === '') {
        continue
      }

      params[key] = value
    }
  }

  return params
}

/**
 * A convenient wrapper for accessing individual query parameters via the
 * URLSearchParams interface.
 */
export function useSearchParams(defaultInit?: SearchParamsInit) {
  const defaultSearchParamsRef = React.useRef(createSearchParams(defaultInit))
  const location = useLocation()

  // Combine the search params with the defaults
  const searchParams = React.useMemo(() => {
    return withDefaultParams(location.search, defaultSearchParamsRef.current)
  }, [location.search])

  const onlyDefaults = React.useMemo(() => {
    const search = createSearchParams(location.search)
    const defaults = defaultSearchParamsRef.current
    return Object.keys(search).length === 0 || deepEqual(search, defaults)
  }, [location.search])

  const setSearchParams = React.useCallback<SetURLSearchParams>(
    (nextInit) => {
      const newSearchParams = createSearchParams(typeof nextInit === 'function' ? nextInit(searchParams) : nextInit)
      const search = qs.stringify(newSearchParams, defaultOpts)

      let path = window.location.pathname
      if (search.length) {
        path += `?${search}`
      }

      if (path !== `${window.location.pathname}${window.location.search}`) {
        Router.visit(path)
      }
    },
    [searchParams]
  )

  // Convenience method to set a single param
  const setSearchParam = React.useCallback(
    (param: string, value: any) => {
      setSearchParams((prev) => ({
        ...prev,
        [param]: value
      }))
    },
    [setSearchParams]
  )

  return { searchParams, setSearchParams, setSearchParam, onlyDefaults }
}

export function useSearchParam(param) {
  const { searchParams } = useSearchParams()
  return searchParams[param]
}
