import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  HStack,
  Icon,
  IconButton,
  Select,
  SkeletonText,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useTheme
} from '@chakra-ui/react'
import { IconAlertCircle, IconChartBar, IconTable } from '@tabler/icons-react'
import { truncate } from 'lodash'
import React from 'react'
import { Bar, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { formatNumber } from '../../../../lib/number-format'
import { Entry } from '../../../data/use-dashboard-widget'
import { TextEllipsis } from '../../../ui/text-ellipsis'

export type TopListColumn = {
  key: string
  label: string
  type?: 'number' | 'email' | 'text' | 'domain' | 'duration' | 'datetime' | 'currency'
  numeric?: boolean
  formatter?: (value: any, row: any) => React.ReactNode
}

export interface TopListProps {
  title?: React.ReactNode
  isLoading?: boolean
  estimatedRows?: number
  data?: Entry[]
  columns: TopListColumn[]
  error?: Error
  onRetry?: () => void
  mode?: 'table' | 'chart'
  column?: string
  limit?: number
}

export function TopList(props: TopListProps) {
  const [mode, setMode] = React.useState<'table' | 'chart'>(props.mode ?? 'table')
  const [selectedColumn, setSelectedColumn] = React.useState<TopListColumn['key'] | undefined>(
    props.column ?? props.columns[1]?.key
  )

  const theme = useTheme()

  return (
    <Stack spacing="4">
      <HStack w="100%" justifyContent={'space-between'} alignItems="flex-start">
        <Heading size="xs" fontWeight={'semibold'} flex="1 1 auto" w="100%">
          {props.title}
        </Heading>

        {!props.isLoading && props.data && (
          <Box flex="none">
            <input type="hidden" name="dashboard[widgets][][settings][mode]" value={mode} />
            <input type="hidden" name="dashboard[widgets][][settings][column]" value={selectedColumn} />

            <HStack lineHeight={1}>
              {mode === 'chart' && (
                <Select
                  rounded="md"
                  onChange={(e) => setSelectedColumn(e.target.value)}
                  size="xs"
                  value={selectedColumn}
                >
                  {props.columns.map((column, index) => {
                    if (index === 0) {
                      return null
                    }

                    return (
                      <option key={column.key} value={column.key}>
                        {column.label}
                      </option>
                    )
                  })}
                </Select>
              )}

              <ButtonGroup size="xs" spacing={1} variant="ghost">
                <IconButton
                  aria-label="Toggle table"
                  icon={<IconTable size={15} />}
                  mr="-px"
                  isActive={mode === 'table'}
                  onClick={() => setMode('table')}
                />
                <IconButton
                  aria-label="Toggle chart"
                  icon={<IconChartBar size={15} />}
                  isActive={mode === 'chart'}
                  onClick={() => setMode('chart')}
                />
              </ButtonGroup>
            </HStack>
          </Box>
        )}
      </HStack>

      {props.isLoading && (
        <>
          <SkeletonText noOfLines={props.estimatedRows ?? 10} spacing="4" />
        </>
      )}

      {props.error && (
        <Stack p="1">
          <HStack spacing="1">
            <Icon as={IconAlertCircle} color="yellow.500" />
            <Text fontSize="xs">Failed to load data</Text>
          </HStack>
          {props.onRetry && (
            <Button size="xs" variant={'outline'} onClick={props.onRetry} isLoading={props.isLoading}>
              Retry
            </Button>
          )}
        </Stack>
      )}

      {mode === 'chart' && !props.isLoading && props.data && (
        <Stack>
          <ResponsiveContainer width="100%" height={280}>
            <ComposedChart
              data={(props.data ?? []).slice(0, props.limit || 10).map((f) => {
                return {
                  ...f,
                  fill: theme.colors['purple']['400']
                }
              })}
              layout="vertical"
              barCategoryGap={20}
            >
              <Tooltip
                contentStyle={{
                  color: theme.colors.gray['700'],
                  fontSize: 14,
                  border: 'none',
                  borderRadius: '4px',
                  boxShadow: `0px 1px 1px 0px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 12%)`
                }}
                allowEscapeViewBox={{ y: true }}
                formatter={(value: string) => formatNumber(value)}
                wrapperStyle={{ outline: 'none', zIndex: 1000 }}
              />
              <CartesianGrid strokeDasharray="4 4" vertical={false} stroke={theme.colors.gray['200']} />

              <YAxis
                dataKey={props.columns[0].key}
                tick={{ fontSize: 11, fill: theme.colors.gray['600'] }}
                tickFormatter={(v: string) => truncate(v, { length: 10 })}
                stroke={theme.colors.gray['200']}
                type="category"
                interval="preserveStartEnd"
                domain={['dataMin', 'dataMax']}
              />
              <XAxis
                width={42}
                dataKey={selectedColumn ?? 'accounts'}
                tickFormatter={(v) => formatNumber(v)}
                type="number"
                tickLine={false}
                axisLine={false}
                interval="preserveStartEnd"
                tick={{ fontSize: 11, fill: theme.colors.gray['600'] }}
              />

              <Bar
                stackId={'a'}
                key={selectedColumn}
                dataKey={selectedColumn ?? 'accounts'}
                label={{
                  position: 'right',
                  fontSize: 11,
                  fill: theme.colors.gray['600'],
                  formatter: (value: string) => formatNumber(value)
                }}
                minPointSize={20}
                layout="vertical"
                radius={[0, 4, 4, 0]}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </Stack>
      )}

      {!props.isLoading && props.data && mode === 'table' && (
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                {props.columns.map((column) => (
                  <Th isNumeric={column.numeric} key={column.key}>
                    {column.label}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {props.data.slice(0, props.limit || 10).map((entry) => (
                <Tr key={JSON.stringify(entry)}>
                  {props.columns?.map((column) => (
                    <Td key={column.key} isNumeric={column.numeric}>
                      {column.numeric && !column.formatter && formatNumber(entry[column.key] as number)}
                      {!column.numeric && !column.formatter && (
                        <TextEllipsis maxW={'50%'} tooltip>
                          {entry[column.key]}
                        </TextEllipsis>
                      )}
                      {column.formatter && column.formatter(entry[column.key], entry)}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Stack>
  )
}
