import {
  Box,
  Button,
  Collapse,
  Flex,
  IconButton,
  Link,
  Stack,
  Text,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import { IconChevronDown, IconChevronRight, IconChevronUp } from '@tabler/icons-react'
import React, { PropsWithChildren } from 'react'
import { Card } from './Card'
import { Iconify, IconifyProps } from './Iconify'

interface CollapsibleSectionProps extends UseDisclosureProps {
  header: string | React.ReactNode
  icon?: IconifyProps['icon']
  description?: string | React.ReactNode
  docsLink?: string
}

export function CollapsibleSection(props: PropsWithChildren<CollapsibleSectionProps>) {
  const disclosure = useDisclosure(props)

  return (
    <Card position="relative" p={0}>
      <Flex role="group" cursor="pointer" alignItems="center" gap={3} onClick={disclosure.onToggle} px={5}>
        {props.icon && <Iconify icon={props.icon} size={16} />}
        <Text flex="1" fontSize="sm" fontWeight="medium" py={3.5}>
          {props.header}
        </Text>
        <IconButton
          aria-label="Toggle section"
          size="xs"
          variant="ghost"
          color="gray.500"
          _hover={{ color: 'gray.800' }}
          _groupHover={{ color: 'gray.800' }}
          mr={-1}
          icon={disclosure.isOpen ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
        />
      </Flex>

      <Collapse in={disclosure.isOpen}>
        <Stack fontSize="sm" spacing={3} pb={4} px={5}>
          {props.description ? <Text color="gray.600">{props.description}</Text> : <Box>{props.children}</Box>}
          {props.docsLink && (
            <Box>
              <Button
                as={Link}
                variant="ghost"
                size="xs"
                ml={-1.5}
                isExternal
                href={props.docsLink}
                iconSpacing={1}
                rightIcon={<IconChevronRight size={14} />}
              >
                Learn more
              </Button>
            </Box>
          )}
        </Stack>
      </Collapse>
    </Card>
  )
}
