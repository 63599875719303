import { Icon, IconProps } from '@chakra-ui/react'
import React from 'react'

interface Props extends IconProps {
  size?: string | number
}

export function CollapseIcon({ size, ...props }: Props) {
  size = size ? `${size}px` : size
  return (
    <Icon
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      width={size}
      height={size}
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M16 5L12 9L8 5" />
      <path d="M8 19L12 15L16 19" />
    </Icon>
  )
}
