import { Avatar, AvatarGroup, Flex, HStack, Text, Tooltip, VStack } from '@chakra-ui/react'
import React from 'react'
import { Bucket } from './types'

interface BucketSummaryProps {
  buckets: Bucket[]
  totalMatching: number
  totalAccounts: number
  disqualified: any[]
}

// Add this new BucketSummary component above the graph
export default function BucketSummary({ buckets, totalMatching, totalAccounts, disqualified }: BucketSummaryProps) {
  return (
    <Flex w="full" gap={4} flexWrap="nowrap" overflowX="auto">
      {buckets.map((bucket) => (
        <VStack
          key={bucket.key}
          padding={4}
          borderWidth="1px"
          borderRadius="md"
          align="start"
          spacing={2}
          flex="1"
          maxWidth="25%"
        >
          <HStack spacing={2}>
            <Text fontWeight="bold">{`${bucket.key}`}</Text>
            <Text>{`${bucket.doc_count}`}</Text>
            <Text fontWeight="bold">
              {bucket.from && bucket.to
                ? `${bucket.from}..${bucket.max_score} points`
                : bucket.from
                  ? `${bucket.from} points up`
                  : `up to ${bucket.max_score} points`}
            </Text>
          </HStack>
          <HStack>
            <Text color="gray.500">({Math.round((bucket.doc_count / totalMatching) * 100)}% of scored)</Text>
            <Text color="gray.500">({Math.round((bucket.doc_count / totalAccounts) * 100)}% of all)</Text>
          </HStack>
          {/* Company Logos using AvatarGroup */}
          <AvatarGroup size="md" max={5}>
            {bucket.sample_accounts.map((account) => (
              <Tooltip key={account.id} label={account.company.domain} aria-label="Company name">
                <Avatar src={account.company.logo} name={account.company.domain} size="md" />
              </Tooltip>
            ))}
          </AvatarGroup>
        </VStack>
      ))}
      <VStack
        key="Disqulified"
        padding={4}
        borderWidth="1px"
        borderRadius="md"
        align="start"
        spacing={2}
        flex="1"
        maxWidth="25%"
      >
        <HStack spacing={2}>
          <Text fontWeight="bold">Disqualified</Text>
          <Text>{totalAccounts - totalMatching}</Text>
          <Text fontWeight="bold">--</Text>
        </HStack>
        <HStack>
          <Text color="gray.500">({Math.round(((totalAccounts - totalMatching) / totalAccounts) * 100)}% of all)</Text>
        </HStack>
        {/* Company Logos using AvatarGroup */}
        <AvatarGroup size="md" max={5}>
          {disqualified.map((account) => (
            <Tooltip key={account.id} label={account.company.domain} aria-label="Company name">
              <Avatar src={account.company.logo} name={account.company.domain} size="md" />
            </Tooltip>
          ))}
        </AvatarGroup>
      </VStack>
    </Flex>
  )
}
