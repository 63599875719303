import { Icon, IconProps } from '@chakra-ui/react'
import React from 'react'

interface Props extends IconProps {
  size?: string | number
}

export function TextIcon({ size, ...props }: Props) {
  size = size ? `${size}px` : size
  return (
    <Icon
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      width={size}
      height={size}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M15 12h6" />
      <path d="M15 6h6" />
      <path d="m3 13 3.553-7.724a.5.5 0 0 1 .894 0L11 13" />
      <path d="M3 18h18" />
      <path d="M4 11h6" />
    </Icon>
  )
}
