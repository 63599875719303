import React from 'react'
import { useMutation } from 'react-query'
import { toast } from 'sonner'
import { post } from '../../../../lib/api'

interface Params {
  id: string
}

export function useRejectChange() {
  const onSuccess = React.useCallback(() => {
    toast.success('Successfully rejected the change.')
  }, [])

  const onError = React.useCallback(() => {
    toast.error('There was an issue rejecting this change.')
  }, [])

  return useMutation<unknown, unknown, Params, unknown>(
    ({ id }) => {
      return post(`/admin/suggestions/${id}/reject`)
    },
    {
      onSuccess,
      onError
    }
  )
}
