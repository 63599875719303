import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import orderBy from 'lodash/orderBy'
import React, { useMemo } from 'react'
import { useSearchParams } from '../../../../ui/useSearchState'
import { ReportAvailability, ReportKind, Settings } from '../types'
import KindSelector from './KindSelector'
import ReportBuilder from './ReportBuilder'

export interface ContentReportFormProps {
  availability: ReportAvailability[]
  report_defaults: Settings
}

export default function ContentReportForm(props: ContentReportFormProps) {
  const { searchParams, setSearchParam } = useSearchParams({})
  const selectedKind = useMemo(() => searchParams.kind as ReportKind | undefined, [searchParams.kind])
  const setSelectedKind = (kind: string | undefined) => setSearchParam('kind', kind)

  const report = useMemo(() => {
    if (selectedKind === undefined) {
      return
    }

    const availability = find(props.availability, (a) => a.kind === selectedKind) as ReportAvailability
    return newReport(selectedKind, availability, props.report_defaults)
  }, [selectedKind, props.availability, props.report_defaults])

  if (selectedKind === undefined || !report) {
    return <KindSelector availability={props.availability} onSelect={setSelectedKind} />
  }

  return <ReportBuilder report={report} onReset={() => setSelectedKind(undefined)} />
}

function newReport(kind: ReportKind, availability: ReportAvailability, settings: Settings) {
  settings = cloneDeep(settings)

  if (kind === 'opportunities') {
    settings.stage_transitions = prepareStageTransitions(availability.transitions)
  } else if (kind === 'custom_events') {
    settings.custom_events = [{ event_type: 'all', event_filter: '' }]
  }

  return {
    kind,
    settings
  }
}

function prepareStageTransitions(transitions: ReportAvailability['transitions']) {
  return orderBy(
    transitions.map((entry) => ({
      from: entry.transition[0],
      to: entry.transition[1],
      count: entry.count,
      checked: !entry.transition[1].match(/lost|partner|debooked/i)
    })),
    'checked',
    'desc'
  )
}
