import { IconArrowRightSquare, IconLock, IconMailBolt, IconMessageChatbot, IconPlus } from '@tabler/icons-react'
import React, { useMemo } from 'react'
import { openWishForm } from './FeedbackForm'
import { projectPath, useCurrentProject } from './ProjectsContext'
import { useEntitlements } from './useEntitlements'
import { useCurrentUser } from './UserContext'

export interface Route {
  title: string
  href?: string
  onClick?: () => void
  icon?: React.ReactElement
  exact?: boolean
  breadcrumbs?: string[]
  tags?: string[]
  hide?: boolean
}

export function useRoutes(): Route[] {
  const project = useCurrentProject()
  const user = useCurrentUser()
  const entitlements = useEntitlements()

  const routes = useMemo(() => {
    return [
      {
        title: 'Live',
        href: projectPath('/live')
      },
      {
        title: 'My Accounts',
        breadcrumbs: ['Lists'],
        href: projectPath('/views/mine/assigned'),
        hide: !entitlements?.mission_control
      },
      {
        title: 'Mission Control',
        href: projectPath('/mission-control'),
        tags: ['rep', 'inbox', 'my accounts', 'mine', 'assigned'],
        hide: !entitlements?.mission_control
      },
      {
        title: 'Research',
        href: projectPath('/research'),
        tags: ['rep', 'research'],
        hide: !user.isInternalUser
      },
      {
        title: 'Claimed',
        breadcrumbs: ['Lists', 'My Accounts'],
        href: projectPath('/views/mine/claimed'),
        hide: !entitlements?.mission_control
      },
      {
        title: 'Live',
        breadcrumbs: ['Lists', 'My Accounts'],
        href: projectPath('/views/mine/live')
      },
      {
        title: 'Accounts',
        href: projectPath('/accounts')
      },
      {
        title: 'People',
        href: projectPath('/people')
      },
      {
        title: 'Lists',
        href: projectPath('/views')
      },
      { title: 'Spaces', href: projectPath('/spaces') },
      {
        title: 'Templates',
        href: projectPath('/mission-control/setup?tab=templates'),
        hide: !entitlements?.mission_control
      },
      {
        title: 'Tasks',
        href: projectPath('/tasks'),
        hide: !user.isInternalUser
      },
      {
        title: 'Web Analytics',
        href: projectPath('/analytics')
      },
      {
        title: 'Setup',
        href: projectPath('/setup')
      },
      {
        title: 'Workspace Settings',
        href: projectPath('/settings'),
        breadcrumbs: ['Settings']
      },
      {
        title: 'Website Tracking Setup',
        href: projectPath('/settings/install'),
        breadcrumbs: ['Settings']
      },
      {
        title: 'Members',
        href: projectPath('/settings/members'),
        breadcrumbs: ['Settings'],
        tags: ['users', 'team', 'invite']
      },
      {
        title: 'Authorized Domains',
        href: projectPath('/settings/members'),
        breadcrumbs: ['Settings', 'Members'],
        tags: ['users', 'team', 'invite']
      },
      {
        title: 'Automations',
        href: projectPath('/automations/overview'),
        hide: !project?.show_automations,
        icon: <IconArrowRightSquare size={18} />,
        tags: ['workflow', 'automation', 'outbound', 'outreach', 'integrations', 'actions', 'slack alerts']
      },
      {
        title: 'Auto Outbound',
        href: projectPath('/auto-outbound'),
        hide: !project?.show_automations,
        icon: <IconMailBolt size={18} />,
        breadcrumbs: ['Automations'],
        tags: ['workflow', 'automation', 'outbound', 'outreach', 'integrations', 'email', 'emails']
      },
      {
        title: 'Actions',
        href: projectPath('/automations'),
        hide: !project?.show_automations,
        icon: <IconArrowRightSquare size={18} />,
        breadcrumbs: ['Automations'],
        tags: ['workflow', 'automation', 'outbound', 'outreach', 'integrations', 'actions', 'slack alerts']
      },
      {
        title: 'Slack Alerts',
        href: projectPath('/slack-alerts'),
        breadcrumbs: ['Automations'],
        tags: ['workflow', 'automation', 'integrations']
      },
      {
        title: 'Create new Slack Alert',
        href: projectPath('/slack-alerts/new'),
        icon: <IconPlus size={18} />,
        breadcrumbs: ['Settings', 'Slack Alerts'],
        tags: ['workflow', 'automation', 'integrations']
      },
      {
        title: 'Intent Signals',
        href: projectPath('/signals'),
        breadcrumbs: ['Settings'],
        tags: ['data', 'signals', 'intent', 'intent signals', 'kqls']
      },
      {
        title: 'Create new Intent Signal',
        href: projectPath('/signals/new'),
        icon: <IconPlus size={18} />,
        breadcrumbs: ['Settings', 'Intent Signals']
      },
      {
        title: 'Form Reports',
        href: projectPath('/forms/reports'),
        breadcrumbs: ['Settings'],
        tags: ['data', 'events', 'submissions']
      },
      {
        title: 'Events',
        href: projectPath('/events'),
        breadcrumbs: ['Settings']
      },
      {
        title: 'Page Views',
        href: projectPath('/events/page-views'),
        breadcrumbs: ['Settings', 'Events']
      },
      {
        title: 'Custom Events',
        href: projectPath('/events/custom'),
        breadcrumbs: ['Settings', 'Events']
      },
      {
        title: 'Forms',
        href: projectPath('/events/forms'),
        breadcrumbs: ['Settings', 'Events'],
        tags: ['data', 'events', 'submissions']
      },
      {
        title: 'Person Traits',
        href: projectPath('/events/visitor-traits'),
        breadcrumbs: ['Settings', 'Events']
      },
      {
        title: 'Account Traits',
        href: projectPath('/events/account-traits'),
        breadcrumbs: ['Settings', 'Events'],
        tags: ['data']
      },
      {
        title: 'Account Scoring',
        href: projectPath('/scoring'),
        breadcrumbs: ['Settings']
      },
      {
        title: 'Import History',
        href: projectPath('/imports'),
        breadcrumbs: ['Settings'],
        tags: ['csv', 'imports', 'static lists']
      },
      {
        title: 'Developer Checklist',
        href: projectPath('/instrumentation'),
        breadcrumbs: ['Settings']
      },
      {
        title: 'Hidden Accounts',
        href: projectPath('/settings/excluded-accounts'),
        breadcrumbs: ['Settings']
      },
      {
        title: 'Content Reports',
        href: projectPath('/reports/contents'),
        breadcrumbs: ['Reports']
      },
      {
        title: 'Influence Report',
        href: projectPath('/reports/influence'),
        breadcrumbs: ['Reports'],
        tags: ['pipeline']
      },
      {
        title: 'UTM Report',
        href: projectPath('/utm-reports'),
        breadcrumbs: ['Reports'],
        tags: ['utm', 'campaign', 'tracking']
      },
      {
        title: 'Billing',
        href: projectPath('/settings/billing'),
        breadcrumbs: ['Settings'],
        tags: ['subscriptions', 'billing', 'plan', 'payment']
      },
      {
        title: 'Plans',
        href: projectPath('/settings/plans'),
        breadcrumbs: ['Settings', 'Billing'],
        tags: ['subscriptions']
      },
      {
        title: 'Koala Weekly',
        href: projectPath('/koala-weekly'),
        breadcrumbs: ['Settings'],
        tags: ['notifications', 'emails']
      },
      {
        title: 'Email & Communications',
        href: projectPath('/settings/notifications'),
        breadcrumbs: ['Settings'],
        tags: ['notifications', 'emails']
      },
      {
        title: 'Integrations',
        href: projectPath('/apps'),
        tags: ['sources', 'signals']
      },
      {
        title: 'Apollo',
        href: projectPath('/apps/apollo'),
        breadcrumbs: ['Integrations'],
        tags: ['sources', 'signals']
      },
      {
        title: 'Clearbit',
        href: projectPath('/apps/clearbit'),
        breadcrumbs: ['Integrations'],
        tags: ['sources', 'signals']
      },
      {
        title: 'Salesforce',
        href: projectPath('/apps/salesforce'),
        breadcrumbs: ['Integrations'],
        tags: ['sources', 'signals']
      },
      {
        title: 'HubSpot',
        href: projectPath('/apps/hubspot'),
        breadcrumbs: ['Integrations'],
        tags: ['sources', 'signals']
      },
      {
        title: 'Outreach',
        href: projectPath('/apps/outreach'),
        breadcrumbs: ['Integrations'],
        tags: ['sources', 'signals']
      },
      {
        title: 'Segment',
        href: projectPath('/apps/segment'),
        breadcrumbs: ['Integrations'],
        tags: ['sources', 'signals']
      },
      {
        title: 'Slack',
        href: projectPath('/apps/slack'),
        breadcrumbs: ['Integrations'],
        tags: ['sources', 'signals']
      },
      {
        title: 'ZoomInfo',
        href: projectPath('/apps/zoominfo'),
        breadcrumbs: ['Integrations'],
        tags: ['sources', 'signals']
      },
      {
        title: 'Mintlify',
        href: 'https://getkoala.com/docs/integrations/mintlify',
        breadcrumbs: ['Integrations'],
        tags: ['sources', 'signals']
      },
      {
        title: 'ReadMe',
        href: 'https://getkoala.com/docs/integrations/readme',
        breadcrumbs: ['Integrations'],
        tags: ['sources', 'signals']
      },
      {
        title: 'Your Profile',
        href: '/me',
        breadcrumbs: ['Settings']
      },
      {
        title: 'Workspaces',
        href: '/projects'
      },
      {
        title: 'Create or Join a Workspace',
        href: '/join',
        breadcrumbs: ['Workspaces']
      },
      {
        title: 'Get help',
        icon: <IconMessageChatbot size={18} />,
        tags: ['help', 'support', 'feedback', 'feature request', 'bug', 'make a wish', 'wish'],
        onClick: openWishForm
      },
      {
        title: 'Docs',
        href: 'https://getkoala.com/docs',
        tags: ['documentation', 'help', 'how', 'support']
      },
      {
        title: 'Changelog',
        href: 'https://getkoala.com/blog?type=product-updates',
        tags: ['documentation', 'features', 'new', 'updates', 'release notes']
      },
      {
        title: 'Company Database',
        href: 'https://getkoala.com/companies',
        tags: ['companies', 'database', 'company', 'search']
      },
      // internal pages
      ...(user.isInternalUser
        ? [
            {
              title: 'Company Search',
              href: '/admin/kdb',
              icon: <IconLock size={18} />,
              breadcrumbs: ['Admin'],
              tags: ['companies', 'database', 'company', 'search']
            },
            {
              title: 'Suggested Edits',
              href: '/admin/suggestions',
              icon: <IconLock size={18} />,
              breadcrumbs: ['Admin']
            },
            {
              title: 'Integration Syncs',
              href: '/admin/syncs',
              icon: <IconLock size={18} />,
              breadcrumbs: ['Admin']
            },
            {
              title: 'Emails',
              href: '/admin/reports/emails',
              icon: <IconLock size={18} />,
              breadcrumbs: ['Admin']
            },
            {
              title: 'IP Search',
              href: '/admin/ip_geo',
              icon: <IconLock size={18} />,
              breadcrumbs: ['Admin'],
              tags: ['ip', 'geo', 'address', 'lookup']
            },
            {
              title: 'Billing Subscriptions',
              href: '/admin/subscriptions',
              icon: <IconLock size={18} />,
              breadcrumbs: ['Admin']
            },
            {
              title: 'GDPR Deletion Requests',
              href: '/admin/subject-data-deletion-request/new',
              icon: <IconLock size={18} />,
              breadcrumbs: ['Admin']
            }
          ]
        : [])
    ]
  }, [project, user, entitlements])

  return routes
}
