import { Box, Link, Stack } from '@chakra-ui/react'
import React from 'react'
import type { Account } from '../../../../types/Account'
import type { App, Apps, CatalogApp } from '../../../../types/App'
import { ProjectActions } from '../../../../types/AppActions'
import { openUpgradeFlow } from '../../../ui/billing-banners/accounts-banner'
import { LightBgCard } from '../../../ui/Card'
import { CardHeading } from '../../../ui/CardHeading'
import { MoreAppsCard } from '../../apps/components/MoreAppsCard'
import { billingPlansPath } from '../../billing/lib/path-helpers'
import { ApolloCard } from './ApolloCard'
import { G2Card } from './G2Card'
import { HubSpotCard } from './HubspotCard'
import { OutreachCard } from './OutreachCard'
import { SalesforceCard } from './SalesforceCard'
import { SalesforceOpportunitiesCard } from './SalesforceOpportunitiesCard'

interface Props {
  account: Account
  actions: ProjectActions
  apps: Apps
  catalog?: CatalogApp[]
  initiallyCollapsed?: boolean
  isRedacted?: boolean
  onRequestImport?: (app: App | undefined) => void
}

export function AccountIntegrationSidebar(props: Props) {
  const salesforce = props.apps['Apps::Salesforce::App']
  const hubspot = props.apps['Apps::Hubspot::App']
  const outreach = props.apps['Apps::Outreach::App']
  const apollo = props.apps['Apps::Apollo::App']
  const g2 = props.apps['Apps::G2::App']

  const salesforceAccount = props.account.crm_accounts.find((entity) => {
    return entity.crm.title === salesforce?.title
  })

  const hubspotCompany = props.account.crm_accounts.find((entity) => {
    return entity.crm.title === hubspot?.title
  })

  const outreachAccount = props.account.outreach_accounts?.find((entity) => {
    return entity.crm.title === outreach?.title
  })?.crm_entity

  const apps = Object.values(props.apps || {})

  const missingApps: CatalogApp[] = (props.catalog ?? []).filter((app) => {
    return !props.apps?.[app.module]?.connected
  })

  const order = ['salesforce', 'hubspot', 'outreach', 'apollo', 'g2'].filter((name) => {
    if (!salesforce?.connected && !hubspot?.connected && ['salesforce', 'hubspot'].includes(name)) {
      return true
    }

    if (apps.find((app) => app.clean_module_name === name)?.connected) {
      return true
    }

    return false
  })

  if (props.isRedacted) {
    return (
      <>
        {order.map((app) => (
          <Box key={app}>
            {app === 'salesforce' && (
              <LightBgCard>
                <CardHeading icon="https://cdn.cdnlogo.com/logos/s/3/salesforce.svg">Salesforce</CardHeading>
                <Link
                  href={billingPlansPath()}
                  fontSize={'sm'}
                  pt="2"
                  onClick={(e) => {
                    e.preventDefault()
                    openUpgradeFlow('business')
                  }}
                >
                  Unlock Account to view
                </Link>
              </LightBgCard>
            )}
            {app === 'hubspot' && (
              <LightBgCard>
                <CardHeading icon="https://cdn.cdnlogo.com/logos/h/24/hubspot.svg">HubSpot</CardHeading>
                <Link
                  href={billingPlansPath()}
                  fontSize={'sm'}
                  pt="2"
                  onClick={(e) => {
                    e.preventDefault()
                    openUpgradeFlow('business')
                  }}
                >
                  Unlock Account to view
                </Link>
              </LightBgCard>
            )}
            {app === 'outreach' && (
              <LightBgCard>
                <CardHeading icon="https://www.outreach.io/_resources/img/logomark_sm.min.svg">Outreach</CardHeading>
                <Link
                  href={billingPlansPath()}
                  fontSize={'sm'}
                  pt="2"
                  onClick={(e) => {
                    e.preventDefault()
                    openUpgradeFlow('business')
                  }}
                >
                  Unlock Account to view
                </Link>
              </LightBgCard>
            )}
            {app === 'apollo' && (
              <LightBgCard>
                <CardHeading icon="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/imnotvasciiltr2dikl8">
                  Apollo
                </CardHeading>
                <Link
                  href={billingPlansPath()}
                  fontSize={'sm'}
                  pt="2"
                  onClick={(e) => {
                    e.preventDefault()
                    openUpgradeFlow('business')
                  }}
                >
                  Unlock Account to view
                </Link>
              </LightBgCard>
            )}
          </Box>
        ))}

        <MoreAppsCard otherApps={missingApps} />
      </>
    )
  }

  return (
    <>
      {order.map((app) => (
        <Box key={app}>
          {app === 'salesforce' && (
            <Stack spacing="4">
              <SalesforceCard
                account={props.account}
                app={salesforce}
                match={salesforceAccount}
                crmEntity={salesforceAccount?.crm_entity}
                actions={props.actions['Apps::Salesforce::App']}
                onRequestImport={props.onRequestImport}
                initiallyCollapsed={props.initiallyCollapsed}
              />
              <SalesforceOpportunitiesCard account={props.account} app={salesforce} match={salesforceAccount} />
            </Stack>
          )}
          {app === 'hubspot' && (
            <HubSpotCard
              app={hubspot}
              account={props.account}
              match={hubspotCompany}
              crmEntity={hubspotCompany?.crm_entity}
              actions={props.actions['Apps::Hubspot::App']}
              onRequestImport={props.onRequestImport}
              initiallyCollapsed={props.initiallyCollapsed}
            />
          )}

          {app === 'outreach' && (
            <OutreachCard
              app={outreach}
              account={props.account}
              match={outreachAccount}
              actions={props.actions['Apps::Outreach::App']}
              onRequestImport={props.onRequestImport}
              initiallyCollapsed={props.initiallyCollapsed}
            />
          )}

          {app === 'apollo' && (
            <ApolloCard app={apollo} account={props.account} initiallyCollapsed={props.initiallyCollapsed} />
          )}

          {app === 'g2' && <G2Card app={g2} account={props.account} initiallyCollapsed={props.initiallyCollapsed} />}
        </Box>
      ))}

      <MoreAppsCard otherApps={missingApps} />
    </>
  )
}
