import { AccountView } from '@app/types/AccountView'
import {
  Badge,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Link,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import { IconExternalLink, IconPlus } from '@tabler/icons-react'
import pluralize from 'pluralize'
import React, { useMemo } from 'react'
import { toast } from 'sonner'
import { postForm } from '../../../../lib/api'
import { Apps } from '../../../../types/App'
import { useIntentSignals } from '../../../data/use-intent-signals'
import { useSlack } from '../../../data/use-slack'
import { LightBgCard } from '../../../ui/Card'
import { HoverCard } from '../../../ui/HoverCard'
import { SlackIcon } from '../../../ui/icons'
import { MiddotDivider } from '../../../ui/Middot'
import { projectPath } from '../../../ui/ProjectsContext'
import { TimeAgo } from '../../../ui/TimeAgo'
import { PopupConnectDialog } from '../../apps/components/ConnectOauthAppDialog'
import { SignalType } from '../../kql_definitions/components/SignalType'
import { KqlDefinition } from '../../kql_definitions/types'
import { slackAlertPath, slackAlertsPath } from '../../notifications/lib/path-helper'
import { DefinitionForm } from '../../slack_alerts/components/DefinitionForm'
import { SlackAlert } from '../../slack_alerts/types'

const emptyArray = []

interface Props extends UseDisclosureProps {
  apps?: Apps
  view: AccountView
}

export function SlackAlertsSidesheet(props: Props) {
  const disclosure = useDisclosure(props)
  const slackData = useSlack()
  const disconnected = !slackData.isLoading && !slackData.data?.connected

  const [subscriptions, setSubscriptions] = React.useState(props.view.subscriptions ?? [])
  const hasLegacySubs = subscriptions.length > 0

  const alerts = useMemo(() => props.view.slack_alerts ?? [], [props.view.slack_alerts])
  const hasSlackAlerts = alerts.length > 0

  const signals = useIntentSignals()

  const onSlackConnected = React.useCallback(() => {
    slackData.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slackData.refetch])

  const [deleting, setDeleting] = React.useState(false)

  const handleSubscriptionDelete = React.useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const form = event.target as HTMLFormElement
    const data = new FormData(form)

    setDeleting(true)
    try {
      await postForm(form.action, data)
      setSubscriptions([])
      toast.success('Successfully removed subscription!')
    } catch (_error) {
      toast.error('Failed to remove your subscription')
    } finally {
      setDeleting(false)
    }
  }, [])

  return (
    <Drawer isOpen={disclosure.isOpen} placement="right" onClose={disclosure.onClose} size="lg" preserveScrollBarGap>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />

        <DrawerHeader display="flex" alignItems="center" gap={2} fontSize="lg" fontWeight="medium">
          <SlackIcon boxSize={6} />
          Slack Alerts
        </DrawerHeader>

        <DrawerBody pb="50px">
          {slackData.isLoading ? (
            <Spinner />
          ) : slackData.data && disconnected ? (
            <PopupConnectDialog app_id={slackData.data.app_id} onConnected={onSlackConnected}>
              {({ onStart }) => (
                <Stack
                  alignItems="center"
                  spacing={4}
                  padding={8}
                  border="1px solid"
                  borderColor="gray.300"
                  rounded="md"
                >
                  <Stack textAlign="center" spacing={2} paddingY={2} maxW="400px">
                    <Heading size="sm">Connect your Slack Account</Heading>
                    <Text fontSize="sm" color="gray.600">
                      Once you've connected Slack, you can get real-time alerts whenever this audience triggers specific
                      intent signals.
                    </Text>
                  </Stack>
                  <Button size="sm" onClick={onStart} colorScheme="purple">
                    Connect Slack…
                  </Button>
                </Stack>
              )}
            </PopupConnectDialog>
          ) : (
            <Stack spacing={8}>
              {hasLegacySubs && (
                <LightBgCard p={0}>
                  <Stack spacing={4} padding={5}>
                    <Badge alignSelf="flex-start" colorScheme="orange">
                      Deprecated
                    </Badge>

                    <Stack spacing={1}>
                      <Heading size="xs">Legacy Slack Subscription</Heading>
                      <Text fontSize="sm" color="gray.600">
                        Whenever new companies match this list, send a message to a Slack channel.
                      </Text>
                    </Stack>

                    {props.view.subscriptions!.map((sub) => (
                      <FormControl key={sub.id}>
                        <FormLabel fontSize="xs">Slack Channel</FormLabel>
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                          gap={4}
                          paddingY={2}
                          paddingLeft={4}
                          paddingRight={2}
                          rounded="md"
                          border="1px solid"
                          borderColor="gray.200"
                        >
                          <Text fontSize="sm" fontWeight="medium">
                            {sub.action?.channel?.user_email
                              ? `@${sub.action?.channel?.user_email.split('@')[0]}`
                              : `#${sub.action?.channel?.name || sub.action?.channel?.id}`}
                          </Text>
                        </Flex>
                      </FormControl>
                    ))}
                  </Stack>

                  <Flex
                    alignItems="center"
                    flexWrap="wrap"
                    gap={4}
                    width="100%"
                    roundedBottom="sm"
                    bg="orange.100"
                    paddingY={3}
                    paddingX={5}
                  >
                    <Text fontSize="xs" fontWeight="medium" color="orange.900">
                      Switch to the new Slack Alerts to get more granular control of the intent signals you want to hear
                      about for this audience. Once you remove the legacy subscription, you'll be able to create Slack
                      Alerts here.
                    </Text>

                    <form
                      method="POST"
                      action={projectPath(`/views/${props.view.slug}/subscriptions/slack`)}
                      onSubmit={handleSubscriptionDelete}
                    >
                      <input type="hidden" name="_method" value="DELETE" />
                      <Button type="submit" colorScheme="orange" size="xs" variant="outline" isLoading={deleting}>
                        Remove Subscription
                      </Button>
                    </form>
                  </Flex>
                </LightBgCard>
              )}

              {/* show the inline creation form if there isn't a legacy subscription or any Slack alerts  */}
              {!hasSlackAlerts && !hasLegacySubs && (
                <DefinitionForm
                  apps={props.apps}
                  slack_alert={{
                    enabled: true,
                    project_id: props.view.project_id,
                    account_view_id: props.view.id,
                    channel_routing: 'channel'
                  }}
                  inline
                  hideAudience
                />
              )}

              {/* always show the slack alerts */}
              {hasSlackAlerts && (
                <Stack spacing={6}>
                  <Text fontSize="sm" color="gray.600">
                    Slack Alerts deliver real-time notifications to a Slack channel whenever someone from your audience
                    triggers an intent signal.
                  </Text>

                  <Divider />

                  <Stack spacing={2}>
                    <Heading size="xs">
                      You have {alerts.length} Slack {pluralize('Alert', alerts.length)} for this audience
                    </Heading>

                    {alerts.map((alert) => (
                      <SlackAlertPreview
                        key={alert.id}
                        alert={alert}
                        signals={signals.data?.definitions ?? emptyArray}
                      />
                    ))}
                  </Stack>

                  <Button
                    as={Link}
                    href={slackAlertsPath(`/new?slack_alert[account_view_id]=${props.view.id}`)}
                    alignSelf="flex-start"
                    size="sm"
                    variant="link"
                    colorScheme="purple"
                    leftIcon={<IconPlus size={12} />}
                    iconSpacing={1}
                  >
                    New Slack Alert
                  </Button>
                </Stack>
              )}
            </Stack>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

interface SlackAlertPreviewProps {
  alert: SlackAlert
  signals: KqlDefinition[]
}

function SlackAlertPreview({ alert, signals }: SlackAlertPreviewProps) {
  return (
    <Flex
      as={Link}
      href={slackAlertPath(alert.id)}
      _hover={{ textDecoration: 'none', borderColor: 'gray.300' }}
      fontSize="sm"
      gap={4}
      alignItems="center"
      justifyContent="space-between"
      border="1px solid"
      borderColor="gray.200"
      rounded="md"
      paddingX={4}
      paddingY={2}
      cursor="pointer"
      role="group"
    >
      <Stack spacing={1}>
        <Text
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          color={alert.enabled ? undefined : 'gray.600'}
          fontStyle={alert.enabled ? undefined : 'italic'}
        >
          #{alert.slack_channel_name || alert.slack_channel_id}
        </Text>

        <HStack color="gray.500" fontSize="xs" spacing={1} divider={<MiddotDivider />}>
          {!alert.enabled && <Text>Paused</Text>}
          <Text>
            Updated <TimeAgo time={alert.updated_at} />
          </Text>
          {alert.updated_by_user ? (
            <Text>Updated by {alert.updated_by_user.name || alert.updated_by_user.email}</Text>
          ) : alert.created_by_user ? (
            <Text>Created by {alert.created_by_user.name || alert.created_by_user.email}</Text>
          ) : null}
          {(alert.intent_triggers || []).length > 0 && (
            <HoverCard
              trigger="hover"
              hoverContent={
                <Stack spacing={2} paddingY={1} color="gray.800">
                  {alert.intent_triggers?.map((trigger) => {
                    const signal = signals.find((s) => s.id === trigger)
                    return (
                      <SignalType
                        key={trigger}
                        label={signal?.name || trigger}
                        signalType={signal?.signal_type}
                        fontSize="xs"
                        compact
                      />
                    )
                  })}
                </Stack>
              }
            >
              <Text>
                {alert.any_intent ? 1 : alert.intent_triggers?.length} intent{' '}
                {pluralize('trigger', alert.any_intent ? 1 : alert.intent_triggers?.length ?? 0)}
              </Text>
            </HoverCard>
          )}
        </HStack>
      </Stack>
      <Icon
        as={IconExternalLink}
        boxSize={4}
        color="gray.400"
        transition="color 150ms ease-in-out"
        _groupHover={{ color: 'gray.700' }}
      />
    </Flex>
  )
}
