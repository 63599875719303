import * as React from 'react'
import Avatar, { AvatarProps } from './Avatar'
import { BuildingIcon } from './icons'

export interface CompanyAvatarProps extends AvatarProps {
  domain?: string | null
}

export default React.forwardRef(function CompanyAvatar(
  props: CompanyAvatarProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { domain, ...rest } = props
  let src = props.src

  if (domain && !src) {
    src = `/web/companies/${domain}/logo`
  }

  return (
    <Avatar
      rounded="md"
      ref={ref}
      monochrome
      fallbackIcon={BuildingIcon}
      {...rest}
      name={rest.name || domain}
      src={src}
    />
  )
})
