import {
  Box,
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import pluralize from 'pluralize'
import * as React from 'react'
import { toast } from 'sonner'
import { postForm } from '../../../../lib/api'
import { CheckboxBlankCircleIcon, CheckboxCircleIcon } from '../../../ui/icons'
import { projectPath } from '../../../ui/ProjectsContext'
import { Team } from './CreateProject'

interface TeamsModalProps {
  projectSlug: string
  isOpen: boolean
  onClose: () => void
  teams: Team[]
}

export function TeamsModal({ projectSlug, isOpen, onClose, teams }: TeamsModalProps) {
  const [submitting, setSubmitting] = React.useState(false)
  const [selected, setSelected] = React.useState<string[]>([])
  const toggleSelection = React.useCallback((id: string) => {
    setSelected((prev) => {
      if (prev.includes(id)) {
        return prev.filter((selection) => selection !== id)
      } else {
        return [...prev, id]
      }
    })
  }, [])

  const onSubmit = React.useCallback(
    (event) => {
      event.preventDefault()
      const formData = new FormData(event.target)

      setSubmitting(true)

      postForm(`/projects/${projectSlug}/settings/teams/join`, formData)
        .then(() => {
          toast.success('Successfully joined selected teams')
          onClose()
        })
        .catch(() => {
          toast.error('Failed to join selected teams')
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
    [projectSlug, onClose]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" preserveScrollBarGap>
      <form data-koala-collect="off" action={projectPath(`/teams/join`)} method="POST" onSubmit={onSubmit}>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(6px)" />
        <ModalContent>
          <ModalHeader>Join your team</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={8} marginBottom={8}>
              <Text fontSize="sm" color="gray.600">
                This workspace has {teams.length === 1 ? 'a team' : 'some teams'} you can join. While you don't have to
                join these teams, we recommend joining the team that matches your role/territory!
              </Text>

              <Stack spacing={2}>
                {teams.map((team) => (
                  <TeamItem
                    key={team.id}
                    {...team}
                    selected={selected.includes(team.id)}
                    onSelect={() => toggleSelection(team.id)}
                  />
                ))}
              </Stack>
            </Stack>
          </ModalBody>

          <ModalFooter justifyContent="stretch" gap={3}>
            <Button flex="1 1 50%" variant="outline" onClick={onClose}>
              No thanks
            </Button>

            <Button
              flex="1 1 50%"
              type="submit"
              colorScheme="purple"
              isLoading={submitting}
              isDisabled={selected.length === 0}
            >
              Join {selected.length} selected {pluralize('team', selected.length)}
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}

interface TeamItemProps {
  id: string
  name: string
  selected?: boolean
  onSelect: () => void
}

function TeamItem(props: TeamItemProps) {
  return (
    <HStack
      spacing={2}
      border="1px solid"
      borderColor={props.selected ? 'purple.500' : 'gray.200'}
      rounded="md"
      paddingX={3}
      paddingY={3}
      cursor="pointer"
      onClick={props.onSelect}
    >
      {props.selected ? (
        <Icon as={CheckboxCircleIcon} boxSize={5} color="purple.500" />
      ) : (
        <Icon as={CheckboxBlankCircleIcon} boxSize={5} color="gray.300" />
      )}
      <Box flex="1">
        {props.selected && <input type="hidden" name="teams[id][]" value={props.id} />}
        <Text fontSize="sm" fontWeight="medium">
          {props.name}
        </Text>
      </Box>
    </HStack>
  )
}
