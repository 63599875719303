import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import {
  Badge,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  ListItem,
  Stack,
  Textarea,
  UnorderedList
} from '@chakra-ui/react'
import React from 'react'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { LightBgCard } from '../../../ui/Card'
import PageDescription from '../../../ui/PageDescription'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { usePermission } from '../../../ui/PermissionsContext'
import { projectPath } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../../ui/SettingsHeader'
import { HealthCheck, HealthChecks } from '../components/health-checks'

interface Props {
  title: string
  description: string
  logo: string
  settings: Record<string, string | null | undefined>
  connected?: boolean
  valid?: boolean
  deps: {
    health_checks: HealthCheck[]
  }
}

export default function Show(props: Props) {
  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })
  const [isDirty, setIsDirty] = React.useState(false)

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/zoominfo'),
            title: 'ZoomInfo'
          }
        ]}
        offscreen
      />
      <SettingsHeader>
        <HStack marginRight="auto" alignItems="center" spacing={2}>
          <Image src={props.logo} maxW="6" />
          <PageTitle flex="1">{props.title}</PageTitle>
          {props.connected && props.valid && <Badge colorScheme="green">Connected</Badge>}
          {props.connected && !props.valid && <Badge colorScheme="orange">Needs attention</Badge>}
          {props.connected && <DisconnectAppDialog appTitle={'ZoomInfo'} showRemoveCachesOption={false} />}
        </HStack>
        <PageDescription>{props.description}</PageDescription>
      </SettingsHeader>

      <form method="POST">
        <AuthenticityToken />
        <input type="hidden" name="_method" value="PUT" />

        <Stack spacing="4">
          <Heading size="md">🔐 Auth Settings</Heading>

          <LightBgCard>
            <Stack w="100%" spacing="8">
              <Heading w="100%" size="sm" fontWeight={'semibold'} textAlign={'left'}>
                Private Key
              </Heading>
              <Stack spacing={'4'}>
                <FormControl id="username" isRequired>
                  <FormLabel>Username</FormLabel>
                  <Input
                    bg="gray.50"
                    w="100%"
                    name="app_instance_settings[username]"
                    placeholder="ZoomInfo Username"
                    defaultValue={props.settings['username'] ?? ''}
                    onChange={(e) => {
                      if (e.target.value !== props.settings['username']) {
                        setIsDirty(true)
                      }
                    }}
                  />
                  <FormHelperText>Your ZoomInfo Username</FormHelperText>
                </FormControl>
                <FormControl id="client_id" isRequired>
                  <FormLabel>Client Id</FormLabel>
                  <Input
                    bg="gray.50"
                    w="100%"
                    name="app_instance_settings[client_id]"
                    placeholder="ZoomInfo Client Id"
                    defaultValue={props.settings['client_id'] ?? ''}
                    onChange={(e) => {
                      if (e.target.value !== props.settings['client_id']) {
                        setIsDirty(true)
                      }
                    }}
                  />
                  <FormHelperText>The Username for the ZoomInfo integration user</FormHelperText>
                </FormControl>

                <FormControl id="private_key" isRequired>
                  <FormLabel>Secret Key</FormLabel>
                  <Textarea
                    fontSize={'sm'}
                    bg="gray.50"
                    w="100%"
                    name="app_instance_settings[private_key]"
                    placeholder="ZoomInfo Secret Key"
                    defaultValue={props.settings['private_key'] ?? ''}
                    rows={10}
                    onChange={(e) => {
                      if (e.target.value !== props.settings['private_key']) {
                        setIsDirty(true)
                      }
                    }}
                  />
                  <FormHelperText>The Secret Key used for authentication</FormHelperText>
                </FormControl>
              </Stack>

              <Heading size="sm">To find your ZoomInfo secret key:</Heading>
              <UnorderedList pl="6" fontSize={'sm'}>
                <ListItem>Log in to the ZoomInfo GUI using admin credentials with API access.</ListItem>
                <ListItem>
                  Go to Admin Portal, then API and click Generate New Key. You are provided with a Client ID and a
                  Private Key.
                </ListItem>
              </UnorderedList>
            </Stack>
          </LightBgCard>
        </Stack>

        <Stack spacing="8" mt="8">
          <Divider />

          <Box w="100%">
            <HealthChecks isDirty={isDirty} hideUsers appModule={'zoominfo'} healthChecks={props.deps.health_checks} />
          </Box>

          <Divider />

          <HStack w="100%">
            <Button colorScheme="purple" type="submit" w="100%" isDisabled={!canEditProject}>
              Save
            </Button>
          </HStack>
        </Stack>
      </form>
    </PageLayout>
  )
}
