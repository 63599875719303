import {
  Box,
  Button,
  Center,
  Circle,
  Divider,
  Flex,
  HStack,
  Link,
  ListItem,
  Radio,
  RadioGroup,
  ScaleFade,
  Select,
  Stack,
  Text,
  UnorderedList
} from '@chakra-ui/react'
import { IconArrowBarToUp, IconBell, IconDeviceTv, IconRocket } from '@tabler/icons-react'
import { uniqBy } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useWindowScroll } from 'react-use'
import { get } from '../../../lib/api'
import router from '../../../lib/router'
import { KQLCondition, KQLMatch } from '../../../types/KQL'
import type { PageMeta } from '../../../types/PageMeta'
import { DateTime } from '../../../types/Profile'
import { User } from '../../data/use-users'
import EmptyState from '../../ui/EmptyState'
import { HelpTooltip } from '../../ui/HelpTooltip'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { usePermission } from '../../ui/PermissionsContext'
import { useSearchParams } from '../../ui/useSearchState'
import { FacetFilters } from '../accounts'
import { SlackMessageTemplate } from '../follow_rules/components/slack-message-builder/types'
import { WebhookMapping } from '../follow_rules/components/webhook/webhook-setup'
import { mergeParams } from '../icps/types'
import { KqlDefinition } from '../kql_definitions/types'
import type { Session } from '../sessions/index'
import { SlackAlert } from '../slack_alerts/types'
import { Team } from '../teams/new'
import { NotificationEntry } from './components/notification'
import { slackAlertsPath } from './lib/path-helper'

type Time = `${string}:${string}`
export type DeliveryRuleTrigger =
  | 'immediate'
  | `daily@${Time}`
  | `monday@${Time}`
  | `tuesday@${Time}`
  | `wednesday@${Time}`
  | `thursday@${Time}`
  | `friday@${Time}`
  | `saturday@${Time}`
  | `sunday@${Time}`

export interface FollowRule {
  id: string
  name: string
  enabled: boolean
  updated_at: DateTime
  recipient_id: string
  target_type?: 'Profile' | 'Account'
  include_unassociated_visitors?: boolean
  deleted_at?: DateTime | null
  account_view_id?: string
  territory_rules?: {
    smart_list?: string
    facets?: FacetFilters
  }

  managed_by_id?: string
  managed_by?: SlackAlert

  created_by_user?: {
    name?: string
    email: string
  }

  cadence_rules?: {
    period?: 'day' | 'week' | 'month' | 'immediate'
  }

  slack_channel_name?: string

  delivery_rules?: {
    delay_minutes?: number

    team_member?: {
      email?: string
    }

    email?: {
      to?: string
      subject?: string
      trigger?: DeliveryRuleTrigger
      last_delivered_at?: DateTime
    }
    slack?: {
      channel_id?: string
      channel_name?: string
      channel_strategy?: 'static' | 'dynamic_routing'
      trigger?: DeliveryRuleTrigger
      last_delivered_at?: DateTime
      mentions?: boolean
      intent_summary?: boolean
      delivered?: boolean
      title?: string
      custom_message_template?: SlackMessageTemplate
    }
    webhook?: {
      url?: string
      trigger?: DeliveryRuleTrigger
      last_delivered_at?: DateTime
      delivered?: boolean
      mappings?: WebhookMapping[]
    }
    hubspot?: {
      trigger?: DeliveryRuleTrigger
      last_delivered_at?: DateTime
      delivered?: boolean
      import_company?: {
        enabled?: boolean
        fields?: Array<{ koala: string; hubspot: string; id: string; mode?: 'hardcoded' | 'mapped'; value?: string }>
      }
      import_contact?: {
        enabled?: boolean
        fields?: Array<{ koala: string; hubspot: string; id: string; mode?: 'hardcoded' | 'mapped'; value?: string }>
      }
      add_to_list?: {
        enabled?: boolean
        list_id?: number
      }
      add_timeline_entry?: {
        enabled?: boolean
        add_to_contact_timeline?: 'true' | 'false'
        add_to_company_timeline?: 'true' | 'false'
      }
      create_task?: {
        enabled?: boolean
        subject?: string
        priority?: string
        type?: string
        due_date?: string
        ownership_option?: 'account_owner' | 'specific_user' | 'unset'
        owner_id?: string
        dedupe_strategy?: 'always_create' | 'create_if_closed' | 'never_create' | 'create_if_none_since'
        dedupe_days?: number
      }
    }

    salesforce?: {
      trigger?: DeliveryRuleTrigger
      last_delivered_at?: DateTime
      delivered?: boolean
      create_record?: {
        enabled?: boolean
        sobject?: string
        unique_key?: string
        fields?: Array<{ koala: string; salesforce: string; id: string }>
      }
      import_account?: {
        enabled?: boolean
        fields?: Array<{ koala: string; salesforce: string; id: string }>
      }
      import_contact?: {
        enabled?: boolean
        fields?: Array<{ koala: string; salesforce: string; id: string }>
      }
      import_lead?: {
        enabled?: boolean
        fields?: Array<{ koala: string; salesforce: string; id: string }>
      }
      add_to_campaign?: {
        enabled?: boolean
        id?: string
        mappings?: Array<{ kql_definition_id: string; campaign_id: string }>
        strategy?: 'single' | 'custom'
      }
      create_task?: {
        enabled?: boolean
        subject?: string
        priority?: string
        due_date?: string
        reminder_date?: string
      }
    }

    outreach?: {
      trigger?: DeliveryRuleTrigger
      last_delivered_at?: DateTime
      delivered?: boolean
      import_account?: {
        enabled?: boolean
        fields?: Array<{ koala: string; outreach: string; id: string }>
      }
      import_contact?: {
        enabled?: boolean
        fields?: Array<{ koala: string; outreach: string; id: string }>
      }
      add_to_sequence?: {
        enabled?: boolean
        subject?: string
        sequence_id?: string
        owner_id?: string
        use_account_owner?: boolean
        round_robin_team_id?: string
      }
      create_task?: {
        enabled?: boolean
        subject?: string
      }
    }

    apollo?: {
      trigger?: DeliveryRuleTrigger
      last_delivered_at?: DateTime
      delivered?: boolean

      enrich_prospect?: {
        enabled?: boolean
      }

      create_contact?: {
        enabled?: boolean
      }

      create_account?: {
        enabled?: boolean
      }

      create_task?: {
        enabled?: boolean
        type?: string
        priority?: string
        due_date?: string
        user_id?: string
      }

      add_to_sequence?: {
        enabled?: boolean
        sequence_id?: string
        email_account_id?: string
        strategy?: 'single' | 'custom'
        mappings?: Array<{ kql_definition_id: string; sequence_id: string }>
      }

      add_to_list?: {
        enabled?: boolean
        list_name?: string
      }

      add_to_account_list?: {
        enabled?: boolean
        list_name?: string
      }
    }

    instantly?: {
      trigger?: DeliveryRuleTrigger
      last_delivered_at?: DateTime
      delivered?: boolean
      add_to_campaign?: {
        enabled?: boolean
        campaign_id?: string
        strategy?: 'single' | 'custom'
        mappings?: Array<{ kql_definition_id: string; campaign_id: string }>
      }
    }
  }

  intent_rules: {
    inline_conditions?: KQLCondition | KQLCondition[]
    from_kql_id?: string
    condition_type?: 'AND' | 'OR'
  }
  intent_filters?: object

  recipient?: {
    name: string
    email: string
  }

  kql_definition_id?: string
  kql_definition?: KqlDefinition

  kql_definition_ids?: string[]
  kql_definitions?: KqlDefinition[]
  any_intent?: boolean
  any_signal?: boolean
  trigger_type?: 'custom' | 'any_intent' | 'kql' | 'any_signal'
}

export interface NotificationMatch {
  match: KQLMatch
  condition: KQLCondition
}

interface NotificationContext {
  fake?: boolean
  matches?: NotificationMatch[]
  rule_name: string
  intent_rules: {
    inline_conditions: KQLCondition
  }
  delivery_rules?: FollowRule['delivery_rules']
}

export interface Notification {
  id: string
  session_id: string
  target_id: string
  target_type: 'Profile' | 'Account'
  context: NotificationContext
  team_based?: boolean
  recipient?: (User | Team) & { email?: string }
  triggered_at: DateTime
  created_at: DateTime
  updated_at: DateTime
  session_type: 'MaterializedSession' | 'DailyAccountFeed' | 'DailyProfileFeed'
  session?: Session
  follow_rule: FollowRule
}

interface Props {
  notifications: Notification[]
  follow_rules: FollowRule[]
  page_meta: PageMeta
}

export default function Index(props: Props) {
  const [notifications, setNotifications] = React.useState(props.notifications)
  const [followRules, setFollowRules] = React.useState(props.follow_rules)
  const [loading, setLoading] = React.useState(false)
  const [nextPage, setNextPage] = React.useState(props.page_meta.next_page)

  const blankNotifications = useMemo(
    () => props.notifications.length === 0 && props.follow_rules.length === 0,
    [props.notifications, props.follow_rules]
  )

  useEffect(() => {
    setFollowRules(props.follow_rules)
  }, [props.follow_rules])

  useEffect(() => {
    setNotifications(props.notifications)
  }, [props.notifications])

  useEffect(() => {
    setNextPage(props.page_meta.next_page)
  }, [props.page_meta.next_page])

  const loadMore = React.useCallback(async () => {
    if (!nextPage) {
      return
    }

    setLoading(true)

    const url = mergeParams(location.toString(), {
      page: nextPage.toString()
    })

    const res = await get<Props>(url)
    setNotifications((prev) => uniqBy(prev.concat(res.notifications), (s) => s.id))
    setNextPage(res.page_meta.next_page || null)
    setLoading(false)
  }, [nextPage])

  const { y: scrollY } = useWindowScroll()
  const scrollToTop = React.useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])

  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })
  const { searchParams } = useSearchParams()

  return (
    <PageLayout size="full">
      <Stack spacing={6} width="100%">
        <HStack w="100%" spacing="8">
          <PageTitle flex="1">Notifications</PageTitle>

          {canEditProject && (
            <RadioGroup
              as={HStack}
              size="sm"
              spacing="4"
              defaultValue={(searchParams['owner_filter'] as string) ?? 'mine'}
              onChange={(option) => {
                const path = mergeParams(location.toString(), {
                  owner_filter: option
                })
                router.visit(path)
              }}
            >
              <Radio value="mine">Mine</Radio>
              <HStack spacing="1">
                <Radio value="workspace">Workspace</Radio>
                <HelpTooltip>Show the feed for your entire workspace</HelpTooltip>
              </HStack>
            </RadioGroup>
          )}

          {!blankNotifications && (
            <Flex>
              <Select
                size="sm"
                rounded={'md'}
                defaultValue={(searchParams['rule_filter'] as string) ?? 'all'}
                w="48"
                onChange={(e) => {
                  const ruleId = e.target.value
                  const path = mergeParams(location.toString(), {
                    rule_filter: ruleId
                  })
                  router.visit(path)
                }}
              >
                <option key={'all'} value={'all'}>
                  All
                </option>
                {followRules.map((rule) => (
                  <option key={rule.id} value={rule.id}>
                    {rule.name}
                  </option>
                ))}
              </Select>
            </Flex>
          )}
        </HStack>

        {blankNotifications && (
          <EmptyState
            size="md"
            icon={IconBell}
            heading="Welcome to your feed"
            description={
              <Stack alignItems="center" maxW="600" spacing={6} paddingY={4}>
                <UnorderedList
                  spacing="2"
                  color="gray.600"
                  fontSize={'sm'}
                  padding={4}
                  border="1px solid"
                  borderColor="gray.200"
                  rounded="lg"
                  listStylePosition="inside"
                  margin={0}
                >
                  <ListItem>Subscribe to any intent performed by your prospects and customers.</ListItem>
                  <ListItem>Receive notifications in real-time or a daily digest.</ListItem>
                  <ListItem>Watch for any activity in your Territory or Target Accounts.</ListItem>
                  <ListItem>Build a custom feed with only the activity you care about.</ListItem>
                </UnorderedList>
                <HStack w="100%" maxW="420">
                  <Button
                    w="100%"
                    flex={'1'}
                    leftIcon={<IconDeviceTv size="20" />}
                    as={Link}
                    isExternal
                    variant={'outline'}
                    colorScheme={'purple'}
                    href={'https://share.cleanshot.com/QYbFajVr36vZRwvzgnbt'}
                  >
                    Watch a Demo Video
                  </Button>
                  <Button
                    leftIcon={<IconRocket size="20" />}
                    flex={'1'}
                    w="100%"
                    colorScheme={'purple'}
                    as={Link}
                    href={slackAlertsPath('/new')}
                  >
                    Get Started
                  </Button>
                </HStack>
              </Stack>
            }
          />
        )}

        {!blankNotifications && (
          <Stack spacing="12" bg="gray.50" p="8" divider={<Divider />} justifyContent="center" w="100%">
            {notifications.map((notification) => (
              <Stack key={notification.id}>
                <NotificationEntry {...notification} />
              </Stack>
            ))}
          </Stack>
        )}

        {!blankNotifications && (
          <Center paddingTop={10} paddingBottom={20}>
            {nextPage ? (
              <Button rounded="full" isLoading={loading} onClick={loadMore}>
                Load more
              </Button>
            ) : (
              <Text fontSize="sm" color="gray.400">
                That's all there is, there isn't any more
              </Text>
            )}
          </Center>
        )}
      </Stack>

      <Box position="fixed" bottom={10} right={10}>
        <ScaleFade initialScale={0.9} in={scrollY > 150}>
          <Circle
            padding={3}
            bg="gray.500"
            cursor="pointer"
            opacity={0.5}
            _hover={{ opacity: 0.8 }}
            transition="opacity 150ms cubic-bezier(0.4, 0, 0.2, 1)"
            onClick={scrollToTop}
          >
            <IconArrowBarToUp size={24} color="white" />
          </Circle>
        </ScaleFade>
      </Box>
    </PageLayout>
  )
}
