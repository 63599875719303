import { BoxProps, HStack, Text } from '@chakra-ui/react'
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import React from 'react'
import { Iconify } from '../../../../ui/Iconify'

interface ShowHideProps extends BoxProps {
  shown?: boolean
}

export function ShowHide({ shown, children, ...rest }: React.PropsWithChildren<ShowHideProps>) {
  return (
    <HStack spacing={1} color="gray.600" {...rest}>
      <Iconify icon={shown ? IconChevronUp : IconChevronDown} />
      <Text fontSize="sm" fontWeight="medium">
        {children || (shown ? 'Show less…' : 'Show more…')}
      </Text>
    </HStack>
  )
}
