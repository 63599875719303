import { Button, Link, VStack } from '@chakra-ui/react'
import * as React from 'react'
import type { Project } from '../../../types/Project'
import { ProjectCard } from '../../ui/ProjectCard'

interface ListProps {
  projects: Project[]
}

export default function List(props: ListProps) {
  return (
    <VStack py={[0, 0, 6]} spacing={8} maxW={['100%', '100%', 420]} m={['6', '10', '10', 'auto']}>
      <VStack spacing={4} w="100%">
        {props.projects.map((project) => {
          return <ProjectCard key={project.id} project={project} />
        })}
      </VStack>

      <Button size="lg" fontSize="md" w="100%" colorScheme="purple" maxWidth="400px" as={Link} href={`/join`}>
        Create or join another workspace
      </Button>
    </VStack>
  )
}
