import { Alert, AlertIcon, AlertTitle, Divider, HStack, Link, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { mergeParams } from '../../icps/types'

export function viewingKoalaWeekly(loc: Location) {
  return loc.search.includes('kow=1') || loc.pathname.endsWith('all-koala-weekly')
}

export function isEphemeralAllAccounts() {
  return window.location.pathname.endsWith('all-koala-weekly')
}

export function KoalaWeeklyPreviewAlert() {
  return (
    <Alert status="info" bg="blue.50" fontSize={'sm'}>
      <Stack spacing="2">
        <HStack spacing="0">
          <AlertIcon />
          <AlertTitle>Koala Weekly Preview</AlertTitle>
        </HStack>
        <Divider />
        <Stack spacing="1">
          <Text>
            This list updated in real time, so it includes new intent from when your Koala Weekly email was generated.
            It's possible your list will look slightly different from when your email was generated.
          </Text>
          {!isEphemeralAllAccounts() && (
            <Text>
              You can also switch to your{' '}
              <Link
                href={mergeParams(window.location.toString(), {
                  kow: undefined
                })}
              >
                live list
              </Link>{' '}
              to see all results for this list.
            </Text>
          )}
        </Stack>
      </Stack>
    </Alert>
  )
}
