import { useQuery } from 'react-query'
import { concurrentGET } from '../../lib/api'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Data {
  steps: OnboardingStep[]
  done: OnboardingStep[]
}

interface OnboardingStep {
  category: string
  step: string
  status: 'skipped' | 'completed' | 'incomplete'
  skipped_at?: string
  completed_at?: string
}

// These are user<>project specific onboarding steps
export function useOnboardingSteps(category: string, enabled?: boolean) {
  const project = useCurrentProject()
  const isEnabled = enabled === undefined ? true : enabled

  return useQuery<{}, {}, Data>(
    ['onboarding-steps', { projectId: project?.id, category }],
    () => concurrentGET<Data>(projectPath(`/onboarding-steps/${category}`)),
    { enabled: Boolean(project?.id && category && isEnabled) }
  )
}
