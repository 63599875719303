import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  Link,
  SkeletonCircle,
  SkeletonText,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import { IconExternalLink, IconFileText } from '@tabler/icons-react'
import { format } from 'friendly-numbers'
import ms from 'ms'
import React, { useEffect } from 'react'
import { concurrentCachedGET } from '../../../../lib/api'
import { Account } from '../../../../types/Account'
import { PageView } from '../../../../types/Profile'
import { CardHeader } from '../../../ui/CardHeader'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { TimeAgo } from '../../../ui/TimeAgo'
import { Trendline } from '../../icps/icp/breakdown'
import { Trend } from '../../icps/types'
import { AccountIntentFeed, AccountPageFeed } from '../../profiles/components/profile-feed'
import { accountPath } from '../lib/account-path'

type PageStats = {
  page_trend: Trend
  session_time: number
  feed: AccountPageFeed
  last_page?: PageView
  total_visitors: number
}

function PageDetails(props: { path: string; account: Account }) {
  const account = props.account
  const [data, setData] = React.useState<PageStats | null>(null)
  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
    if (loading || data) {
      return
    }

    setLoading(true)

    const apiPath =
      accountPath({
        company: {
          domain: props.account.company.domain
        }
      }) + `/page-views?page_path=${props.path}`

    concurrentCachedGET<PageStats>(apiPath).then((data) => {
      setData(data)
      setLoading(false)
    })
  }, [loading, data, props.path, props.account])

  return (
    <Stack spacing="2">
      {loading && (
        <>
          <SkeletonCircle />
          <SkeletonText noOfLines={12} spacing="4" />
        </>
      )}
      {data && (
        <>
          {account.company && (
            <>
              <Divider />
              <HStack spacing={3}>
                <CompanyAvatar size="lg" name={account.company.name} domain={account.company.domain} />
                <Stack spacing="0">
                  <Heading size="sm" fontWeight="semibold">
                    {account.company?.name}
                  </Heading>
                  {account.company.domain && (
                    <Link
                      display="inline-flex"
                      alignItems="center"
                      gap={1}
                      isExternal
                      fontSize="sm"
                      href={`https://${account.company.domain}`}
                      color="gray.500"
                    >
                      {account.company.domain}
                      <IconExternalLink size={14} />
                    </Link>
                  )}
                </Stack>
              </HStack>
              <Divider />
            </>
          )}
          <HStack p="4" justifyContent={'space-between'}>
            <Stat>
              <StatLabel>Views</StatLabel>
              <StatNumber>
                {format(
                  data.page_trend.trends.reduce((acc, t) => {
                    return acc + t.value
                  }, 0)
                )}
              </StatNumber>
            </Stat>

            <Stat>
              <StatLabel>Session Time</StatLabel>
              <StatNumber>{data.session_time ? ms(data.session_time) : '—'}</StatNumber>
            </Stat>

            <Stat>
              <StatLabel>Unique Visitors</StatLabel>
              <StatNumber>{format(data.total_visitors)}</StatNumber>
            </Stat>

            <Stat>
              <StatLabel>Last Visit</StatLabel>
              <StatNumber>
                <TimeAgo time={data.last_page?.visit_start?.toString()} />
              </StatNumber>
            </Stat>
          </HStack>
          <Trendline color="blue" range="month" trend={data.page_trend} svgWidth={400} />

          <Text py="4" fontSize={'sm'}>
            Last 30 days
          </Text>
          <Stack>
            <CardHeader>Activity</CardHeader>
            <AccountIntentFeed page_feed={data.feed} account={account} />
          </Stack>
        </>
      )}
    </Stack>
  )
}

interface Props extends UseDisclosureProps {
  label: string
  account: Account
}

export function PageViewSideSheet(props: Props) {
  const { label, account, ...disclosure } = props
  const { isOpen, onClose } = useDisclosure(disclosure)

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <HStack w="100%">
            <IconFileText />
            <Heading
              maxW={'75%'}
              size={label.length > 25 ? 'md' : 'lg'}
              fontWeight={label.length > 50 ? 'semibold' : 'bold'}
            >
              {label}
            </Heading>
          </HStack>
        </DrawerHeader>

        <DrawerBody>
          <PageDetails account={account} path={label} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
