import { Box, HStack, Icon, Text } from '@chakra-ui/react'
import { IconCircleDashed, IconLineDashed } from '@tabler/icons-react'
import React, { useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { OmnisearchAccount, useOmnisearch } from '../data/use-omnisearch'
import { FacetFilters } from '../pages/accounts'
import { ComboboxWithSearch } from './ComboboxWithSearch'
import CompanyAvatar from './CompanyAvatar'
import { Project } from '@app/types/Project'

export interface PartialAccount extends OmnisearchAccount {}

const none: PartialAccount = {
  id: '',
  domain: 'No one'
}

interface AccountSelectorProps {
  selectedAccount?: PartialAccount | null
  selectedAccountId?: string | null
  onChange: (account: PartialAccount | null) => void
  showClearButton?: boolean
  facets?: FacetFilters
  selectedProject?: Project | null
}

export function AccountSelector({
  selectedAccount,
  selectedAccountId,
  showClearButton,
  facets,
  onChange,
  selectedProject
}: AccountSelectorProps) {
  const [inputValue, setInputValue] = useState<string | undefined>()
  const [query] = useDebounce(inputValue, 300)
  const { data, isLoading } = useOmnisearch(
    query || '*',
    {
      model: 'Account',
      page_size: 50,
      facets: {
        ...facets,
        account_id: selectedAccountId
      }
    },
    {},
    selectedProject
  )

  const accounts = useMemo(() => {
    const base = showClearButton ? [] : [none]
    return base.concat(data?.results?.accounts ?? [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, showClearButton, selectedProject])

  return (
    <ComboboxWithSearch
      items={accounts}
      selectedItem={selectedAccount || null}
      onChange={onChange}
      onInputValueChange={setInputValue}
      isLoading={isLoading}
      filterItem={(a, val) => a.name?.toLowerCase().includes(val) || a.domain?.toLowerCase().includes(val) || false}
      itemToString={(item) => item?.name ?? item?.domain ?? ''}
      itemRenderer={AccountRenderer}
      selectButtonRenderer={AccountRenderer}
      showClearButton={showClearButton}
    />
  )
}

interface AccountRendererProps {
  item: PartialAccount | null
  isSelected?: boolean
  isToggleButton?: boolean
}

function AccountRenderer(props: AccountRendererProps) {
  const account = props.item

  if (!account) {
    return (
      <HStack spacing={2.5}>
        <Icon as={IconCircleDashed} color="gray.300" boxSize={6} />
        <Text fontSize="sm" fontWeight="medium" color="gray.400">
          Select a company
        </Text>
      </HStack>
    )
  }

  return (
    <HStack spacing={2.5}>
      <CompanyAvatar
        size={props.isToggleButton ? 'xs' : 'sm'}
        domain={account.domain}
        rounded="full"
        {...(account === none
          ? {
              backgroundColor: 'transparent',
              fallbackIcon: <IconLineDashed size={20} />
            }
          : {
              name: account.name || account.domain
            })}
      />
      <Box>
        <Text fontSize="sm" fontWeight="medium">
          {account.name || account.domain || 'Unknown'}
        </Text>
        {!props.isToggleButton && account.domain && (
          <Text fontSize="xs" color="gray.500">
            {account.domain}
          </Text>
        )}
      </Box>
    </HStack>
  )
}
