import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  Switch,
  Text,
  Tooltip
} from '@chakra-ui/react'
import React from 'react'
import { GrayCard } from '../../../../ui/Card'
import { HubspotActionProps } from './hubspot-setup'

export function AddTimelineEntry(props: HubspotActionProps & { targetType?: 'Profile' | 'Account' }) {
  const [enabled, setEnabled] = React.useState(Boolean(props.delivery_rules?.hubspot?.add_timeline_entry?.enabled))

  const [addToContact, setAddToContact] = React.useState(
    (props.delivery_rules?.hubspot?.add_timeline_entry?.add_to_contact_timeline ?? 'true') === 'true'
  )

  const [addToCompany, setAddToCompany] = React.useState(
    (props.delivery_rules?.hubspot?.add_timeline_entry?.add_to_company_timeline ?? 'true') === 'true'
  )

  return (
    <Stack px="6" py="2" borderLeftWidth={'thick'} borderLeftColor={`${enabled ? 'purple' : 'gray'}.400`}>
      <FormControl>
        <Switch
          isChecked={enabled}
          onChange={(e) => {
            setEnabled(e.target.checked)
          }}
          size="sm"
          fontSize={'sm'}
          fontWeight="semibold"
          colorScheme={'purple'}
          value={enabled ? 'true' : 'false'}
          name="follow_rule[delivery_rules][hubspot][add_timeline_entry][enabled]"
        >
          Add Timeline Entry
        </Switch>
        <FormHelperText>{`Create a Timeline Entry for the current ${props.targetType === 'Profile' ? 'Visitor' : 'Account'} in HubSpot.`}</FormHelperText>
      </FormControl>

      {enabled && (
        <Stack>
          <Divider />
          <FormControl pt="4" pl="1" as={Stack} spacing="4">
            <FormLabel mb="0">Timeline Event Types</FormLabel>
            <Stack>
              <Stack spacing="1" borderWidth={'thin'} p="4" rounded="md" as={GrayCard} fontSize="sm">
                <Checkbox
                  defaultChecked={addToCompany}
                  onChange={(e) => {
                    setAddToCompany(e.target.checked)
                  }}
                >
                  <Text fontSize={'sm'} fontWeight={'semibold'}>
                    Company
                  </Text>
                </Checkbox>
                <Text color="gray.600">Write an Intent Signal entry for the Company</Text>
              </Stack>

              <Stack spacing="1" borderWidth={'thin'} p="4" rounded="md" as={GrayCard} fontSize="sm">
                <Tooltip
                  label={
                    props.targetType === 'Account'
                      ? `This option is disabled because you are creating a rule for an Account. You can only create Timeline Events for Contacts when targeting Visitors in your automation rule.`
                      : undefined
                  }
                >
                  <Box>
                    <Checkbox
                      defaultChecked={addToContact}
                      disabled={props.targetType === 'Account'}
                      onChange={(e) => {
                        setAddToContact(e.target.checked)
                      }}
                    >
                      <Text fontSize={'sm'} fontWeight={'semibold'}>
                        Contact
                      </Text>
                    </Checkbox>
                    <Text color="gray.600">
                      Write an Intent Signal entry for the Contact that has performed the Intent Signal
                    </Text>
                  </Box>
                </Tooltip>
              </Stack>
            </Stack>
            <FormHelperText pt="2">
              Which type of Timeline Event should be created? If both are selected, two Timeline Events will be created.
            </FormHelperText>

            <input
              type="hidden"
              name="follow_rule[delivery_rules][hubspot][add_timeline_entry][add_to_contact_timeline]"
              value={addToContact ? 'true' : 'false'}
            />

            <input
              type="hidden"
              name="follow_rule[delivery_rules][hubspot][add_timeline_entry][add_to_company_timeline]"
              value={addToCompany ? 'true' : 'false'}
            />
          </FormControl>
        </Stack>
      )}
    </Stack>
  )
}
