import React from 'react'
import { Link, Text } from '@chakra-ui/react'
import PageLayout from '../../../ui/PageLayout'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import { FeatureLockout } from '../../../ui/billing-banners/feature-lockout'

export default function Index() {
  return (
    <PageLayout size="md">
      <SettingsBreadCrumb paths={[{ path: '', title: 'Content Reports' }]} />
      <FeatureLockout
        locked={true}
        upgradeTo="business"
        blockTitle="Content Reports are not available on your plan"
        blockedChildren={
          <>
            <Text>
              Understand your highest-impact activity with Content Reports. Explore the events that matter most in
              advancing your pipeline to Closed Won.
            </Text>

            <Link
              href="https://getkoala.com/blog/koala-content-reports-the-deep-dive"
              isExternal
              fontSize="xs"
              color="purple.500"
            >
              Learn more
            </Link>
          </>
        }
      />
    </PageLayout>
  )
}
