import merge from 'lodash/merge'
import { useCallback } from 'react'
import createPersistedState from 'use-persisted-state'

interface UIState {
  open?: {
    [key: string]: boolean | undefined
  }
}

const defaultState = {
  open: {
    salesforce: undefined,
    hubspot: undefined,
    outreach: undefined,
    apollo: undefined,
    clearbit: undefined,
    zoominfo: undefined
  }
}

const useVisitorDetailsUI = createPersistedState<UIState>('visitor-details-ui')

export function useUIState() {
  const [state, setState] = useVisitorDetailsUI(defaultState)

  const upsertState = useCallback(
    (newState: Partial<UIState>) => {
      setState((oldState) => merge({}, oldState, newState))
    },
    [setState]
  )

  return [state || defaultState, upsertState] as const
}
