import { Box } from '@chakra-ui/react'
import { IconChevronDown } from '@tabler/icons-react'
import React from 'react'

interface Props {}

export function DropdownIcon(_props: Props) {
  return (
    <Box
      flex="none"
      as="span"
      paddingRight={1.5}
      cursor="pointer"
      _hover={{ '& > svg': { opacity: 1 } }}
      _groupHover={{ '& > svg': { opacity: 1 } }}
      _active={{ '& > svg': { opacity: 1 } }}
      transition="opacity 200ms cubic-bezier(0, 0, 0.2, 1)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      alignSelf="stretch"
    >
      <IconChevronDown size={14} opacity="0.5" />
    </Box>
  )
}
