import {
  Badge,
  Box,
  Button,
  Center,
  Code,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Tab,
  Table,
  TableContainer,
  TabList,
  Tabs,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useClipboard,
  Wrap
} from '@chakra-ui/react'
import { IconArchive, IconCopy, IconDots, IconEdit, IconPlus } from '@tabler/icons-react'
import { orderBy } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { toast } from 'sonner'
import { snippet } from '..'
import { concurrentGET, post, postForm } from '../../../../lib/api'
import { formatDate } from '../../../../lib/dayjs'
import { PublicAPIKey } from '../../../../types/PublicAPIKey'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { projectPath } from '../../../ui/ProjectsContext'
import { useCopyToClipboard } from '../../../ui/useCopyToClipboard'
import { WarningMessage } from '../../../ui/WarningMessage'

function APIModal(props: {
  selectedKey: Partial<PublicAPIKey>
  onClose: () => void
  setSelectedKey: (key: PublicAPIKey) => void
  onArchive: (key: PublicAPIKey) => void
}) {
  const key = props.selectedKey.key
  const [loading, setLoading] = React.useState(false)
  const { hasCopied, onCopy } = useClipboard(snippet({ key } as PublicAPIKey))

  const onSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault()
      const form = e.target as HTMLFormElement
      const data = new FormData(form)

      try {
        setLoading(true)
        const response = await postForm<{ public_api_key: PublicAPIKey; errors: Record<string, string[]> }>(
          form.action,
          data
        )

        if (response.public_api_key && Object.keys(response.errors).length === 0) {
          props.setSelectedKey(response.public_api_key)
        }

        props.onClose()

        toast.success(`Successfully ${props.selectedKey.id ? 'updated' : 'created'} Public API Key!`)
      } catch (_error) {
        toast.error(`Failed to ${props.selectedKey.id ? 'update' : 'create'} Public API Key`)
      } finally {
        setLoading(false)
      }
    },
    [props]
  )

  return (
    <Modal
      size="2xl"
      isOpen
      onClose={() => {
        props.onClose()
      }}
    >
      <AuthenticityToken />
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={onSubmit} method="post" action={projectPath('/website-settings/public-api-keys')}>
          {props.selectedKey.id && <input type="hidden" name="public_api_key[id]" value={props.selectedKey.id} />}
          <ModalHeader px="8">
            {props.selectedKey.archived && <Badge>Disabled</Badge>}
            <Heading size="md" mb="2">
              {props.selectedKey.name ?? 'Create a public key'}
            </Heading>
            <Divider />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px="8">
            <Stack spacing="4" w="100%">
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  isDisabled={loading}
                  name="public_api_key[name]"
                  size="sm"
                  required
                  defaultValue={props.selectedKey.name}
                />
                <FormHelperText>What should we name your key?</FormHelperText>
              </FormControl>

              <FormControl>
                <FormLabel>Website</FormLabel>
                <Input
                  isDisabled={loading}
                  name="public_api_key[website]"
                  size="sm"
                  defaultValue={props.selectedKey.website}
                />
                <FormHelperText>The website this key is tracking</FormHelperText>
              </FormControl>

              {props.selectedKey.key && (
                <FormControl>
                  <FormLabel>Key</FormLabel>
                  <Input
                    name="public_api_key[key]"
                    size="sm"
                    value={key}
                    isReadOnly
                    isDisabled={loading}
                    fontFamily="mono"
                  />
                  <FormHelperText>This is generated by Koala and cannot be changed.</FormHelperText>
                  {props.selectedKey.key && (
                    <Box pt="4" position="relative" _hover={{ '.copy-code': { opacity: 1 } }} role="group">
                      <pre style={{ overflow: 'auto' }}>
                        <Code p={4}>{snippet(props.selectedKey as PublicAPIKey)}</Code>
                      </pre>
                      <Button
                        visibility={'hidden'}
                        _groupHover={{
                          visibility: 'visible'
                        }}
                        className="copy-code"
                        transition="opacity 200ms cubic-bezier(0, 0, 0.2, 1)"
                        colorScheme="purple"
                        size="sm"
                        leftIcon={<IconCopy size={14} />}
                        onClick={onCopy}
                        position="absolute"
                        right={4}
                        top={8}
                      >
                        {hasCopied ? 'Copied!' : 'Copy'}
                      </Button>
                    </Box>
                  )}
                </FormControl>
              )}

              <FormControl>
                <FormLabel>Labels</FormLabel>
                <Input
                  size="sm"
                  disabled={loading}
                  placeholder="website, production"
                  name="public_api_key[tags]"
                  defaultValue={props.selectedKey.tags?.join(',')}
                />
                <FormHelperText>
                  Apply any comma separated labels to your public api key. This can help identify it.
                </FormHelperText>
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter p="8">
            <HStack justifyContent={'space-between'} w="100%">
              {props.selectedKey.id && (
                <Button
                  size="sm"
                  variant={'outline'}
                  leftIcon={<IconArchive size="14" />}
                  onClick={() => props.onArchive(props.selectedKey as PublicAPIKey)}
                >
                  {props.selectedKey.archived ? 'Enable' : 'Disable'} this key
                </Button>
              )}
              <HStack marginLeft="auto">
                <Button size="sm" onClick={props.onClose} variant="outline">
                  Cancel
                </Button>
                <Button size="sm" type="submit" colorScheme="purple" isLoading={loading}>
                  {props.selectedKey.id ? 'Update key' : 'Create key'}
                </Button>
              </HStack>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export function PublicAPIKeys(props: { keys: PublicAPIKey[] }) {
  const [selectedKey, setSelectedKey] = React.useState<Partial<PublicAPIKey> | null>(null)
  const [keys, setKeys] = React.useState<PublicAPIKey[]>(orderBy(props.keys ?? [], 'updated_at', 'desc'))
  const [loading, setLoading] = React.useState(false)
  const [tab, setTab] = useState<'active' | 'disabled'>('active')
  const { copy } = useCopyToClipboard()

  const activeKeys = useMemo(() => keys.filter((k) => !k.archived), [keys])
  const disabledKeys = useMemo(() => keys.filter((k) => k.archived), [keys])

  const copySnippet = useCallback(
    (key: PublicAPIKey, mode: 'key' | 'snippet') => {
      if (mode === 'snippet') {
        copy(snippet(key))
      } else {
        copy(key.key)
      }

      toast(`Copied ${mode} to clipboard`)
    },
    [copy]
  )

  const refresh = useCallback(() => {
    setLoading(true)
    concurrentGET<{ public_api_keys: PublicAPIKey[] }>(projectPath('/settings/install.json')).then((response) => {
      setKeys(response.public_api_keys)
      setLoading(false)
    })
  }, [])

  const toggleArchive = useCallback(
    async (key: PublicAPIKey, changeTab?: boolean) => {
      setLoading(true)

      await post(projectPath('/website-settings/public-api-keys'), {
        public_api_key: {
          id: key.id,
          archived: !key.archived
        }
      })
        .then(() => {
          toast.success(`${key.name} has been disabled`)
          setSelectedKey(null)
          if (changeTab) {
            if (key.archived) {
              setTab('active')
            } else {
              setTab('disabled')
            }
          }
        })
        .catch((err) => {
          toast.error(`Failed to archive ${key.name}`, {
            description: err.message
          })
        })
      refresh()
    },
    [refresh]
  )

  const displayedKeys = tab === 'active' ? activeKeys : disabledKeys

  return (
    <>
      {selectedKey && (
        <APIModal
          selectedKey={selectedKey}
          onArchive={(key) => {
            toggleArchive(key, true)
          }}
          onClose={() => {
            setSelectedKey(null)
            refresh()
          }}
          setSelectedKey={(key) => {
            setSelectedKey(key)
            refresh()
          }}
        />
      )}
      <Stack gap={4}>
        <Flex w="100%" flexWrap="wrap" gap={4} alignItems="flex-start" justifyContent="space-between">
          <Stack flex="1" minW="300px" spacing={1}>
            <Heading size="sm">Public keys</Heading>
            <Text fontSize="sm" color="gray.500">
              Use a publishable public key to install the Koala pixel on your website. This is also used for
              integrations like Google Tag Manager, Segment, or Rudderstack. For server-side API access, use *private
              keys* instead.
            </Text>
          </Stack>
          <Button
            flex="none"
            size="sm"
            variant="outline"
            onClick={() => {
              setSelectedKey({})
            }}
            isDisabled={loading}
            leftIcon={<IconPlus size="16" />}
            iconSpacing={1.5}
          >
            Create public key
          </Button>
        </Flex>

        {activeKeys.length === 0 && (
          <WarningMessage>
            You don't have any active public keys. This means Koala won't be able to receive any data from your site.
            <Button
              variant="link"
              color="inherit"
              textDecoration="underline"
              size="sm"
              mx={1}
              onClick={() => {
                setSelectedKey({})
              }}
            >
              Click here
            </Button>
            to add one.
          </WarningMessage>
        )}

        <Tabs size="sm" marginTop={6}>
          <TabList>
            <Tab isSelected={tab === 'active'} onClick={() => setTab('active')}>
              Live keys ({activeKeys.length})
            </Tab>
            <Tab isSelected={tab === 'disabled'} onClick={() => setTab('disabled')}>
              Disabled keys ({disabledKeys.length})
            </Tab>
          </TabList>
        </Tabs>

        {tab === 'disabled' && disabledKeys.length > 0 && (
          <Stack w="100%" borderLeftWidth="thick" p="4" borderLeftColor="purple.500" bg="purple.50">
            <Text fontSize="sm">
              These are your disabled API keys. This means any data collected using these keys will not be processed.
              You can use this feature in case you need to rotate your keys or disable incoming traffic.
            </Text>
          </Stack>
        )}

        {displayedKeys.length === 0 ? (
          <Center padding={5}>
            <Text color="gray.500" fontSize="sm">
              Your workspace doesn't have any {tab === 'disabled' ? 'disabled' : 'active'} keys yet
            </Text>
          </Center>
        ) : (
          <TableContainer>
            <Table size={'sm'}>
              <Thead>
                <Tr>
                  <Th pl={0}>Name</Th>
                  <Th>Key</Th>
                  <Th>Modified</Th>
                  <Th>Labels</Th>
                  <Th isNumeric>{loading && <Spinner size="xs" />}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {displayedKeys.map((key) => (
                  <Tr key={key.id} role="group">
                    <Td pl={0} fontSize="xs">
                      <Link
                        href="#"
                        onClick={(e) => {
                          e.preventDefault()
                          setSelectedKey(key)
                        }}
                      >
                        {key.name}
                      </Link>
                    </Td>
                    <Td fontSize="xs">
                      <HStack w="100%">
                        <Text fontFamily="mono">{key.key}</Text>
                        {!key.archived && (
                          <IconButton
                            visibility={'hidden'}
                            onClick={() => copySnippet(key, 'key')}
                            aria-label="Copy"
                            icon={<IconCopy size="14" />}
                            size="xs"
                            variant={'ghost'}
                            _groupHover={{ visibility: 'visible' }}
                          />
                        )}
                      </HStack>
                    </Td>
                    <Td fontSize="xs">{formatDate(key.updated_at)}</Td>
                    <Td fontSize="xs">
                      <Wrap shouldWrapChildren spacing={'0.5'}>
                        {key.tags.map((tag) => (
                          <Tag fontSize={'xs'} size="sm" key={tag}>
                            {tag}
                          </Tag>
                        ))}
                      </Wrap>
                    </Td>
                    <Td textAlign="end" paddingRight={0}>
                      <Menu size="sm" placement="bottom-end" autoSelect={false}>
                        <MenuButton
                          size="xs"
                          variant="ghost"
                          as={IconButton}
                          aria-label="more"
                          icon={<IconDots size={16} />}
                        />
                        <MenuList zIndex="popover">
                          <MenuItem icon={<IconCopy size={16} />} onClick={() => copySnippet(key, 'snippet')}>
                            Copy Snippet
                          </MenuItem>
                          <MenuDivider />
                          <MenuItem
                            icon={<IconEdit size={16} />}
                            onClick={() => {
                              setSelectedKey(key)
                            }}
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            icon={<IconArchive size={16} />}
                            color={key.archived ? undefined : 'red.500'}
                            onClick={() => {
                              toggleArchive(key, false)
                            }}
                          >
                            {key.archived ? 'Enable' : 'Disable'}
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </>
  )
}
