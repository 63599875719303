import { useEffect, useState } from 'react'
import { concurrentCachedGET } from '../../../../../lib/api'
import { automationsPath } from '../../../notifications/lib/path-helper'

export interface ActionCategoryItem {
  key: string
  label: string
  description?: string
  examples?: string[]
}

export interface ActionCategory {
  [key: string]: {
    label: string
    items: ActionCategoryItem[]
  }
}

export interface ActionSchema {
  account_categories: ActionCategory
  visitor_categories: ActionCategory
  signal_categories: ActionCategory
}

export function useActionSchema(): { isLoading: boolean; schema?: ActionSchema } {
  const [isLoading, setLoading] = useState(false)
  const [schema, setSchema] = useState<ActionSchema>()

  useEffect(() => {
    setLoading(true)

    concurrentCachedGET<ActionSchema>(automationsPath('/schema')).then((data) => {
      setLoading(false)
      setSchema(data)
    })
  }, [])

  return { isLoading, schema }
}
