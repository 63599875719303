import { IconCopy, IconCopyPlus, IconDotsVertical, IconEdit, IconPlus, IconTrash } from '@tabler/icons-react'
import React from 'react'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import EmptyState from '../../ui/EmptyState'
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import PageDescription from '../../ui/PageDescription'
import pluralize from 'pluralize'
import { MiddotDivider } from '../../ui/Middot'
import { NewTemplateModal } from './components/NewTemplateModal'
import { Template } from '../../../types/Template'
import { DeleteConfirmation } from '../../ui/DeleteConfirmation'
import { EditTemplateModal } from './components/EditTemplateModal'
import { ApplyTemplateToUsersModal } from './components/ApplyTemplateToUsersModal'

interface MissionControlTemplatesIndexProps {
  templates: any[]
}

export default function MissionControlTemplatesIndex(props: MissionControlTemplatesIndexProps) {
  const disclosure = useDisclosure()

  return (
    <PageLayout size="sm" gap={0}>
      <PageTitle skipRendering>Templates</PageTitle>

      <SettingsBreadCrumb
        paths={[
          {
            path: projectPath('/mission-control/setup?tab=templates'),
            title: 'Templates'
          }
        ]}
        offscreen
      />
      <SettingsHeader>
        <Flex alignItems="center" justifyContent="space-between">
          <PageTitle>Templates</PageTitle>
          <Button
            size="sm"
            colorScheme="purple"
            leftIcon={<Icon as={IconPlus} boxSize={4} />}
            iconSpacing={1.5}
            onClick={disclosure.onOpen}
          >
            New template
          </Button>
        </Flex>
        <PageDescription>
          Use templates to create a collection of lists that can be used to set up Mission Control for your teammates.
          Templates let you define account and contact lists that are specifically tailored to your team's workflow,
          territory, or target accounts.
        </PageDescription>
      </SettingsHeader>

      {props.templates.length === 0 ? (
        <EmptyState
          size="md"
          icon={IconCopyPlus}
          heading="Looks like there aren't any templates yet"
          description="Create a template that others can use as a starting point for their Mission Control"
          ctaText="Create a template"
          onClick={disclosure.onOpen}
        />
      ) : (
        <Stack spacing={4}>
          {props.templates.map((template) => (
            <TemplateRow key={template.id} template={template} />
          ))}
        </Stack>
      )}

      <NewTemplateModal {...disclosure} />
    </PageLayout>
  )
}

export function TemplateRow({ template }: { template: Template }) {
  const deletion = useDisclosure()
  const editTemplateModal = useDisclosure()
  const applyTemplateModal = useDisclosure()

  return (
    <Flex
      key={template.id}
      alignItems="center"
      justifyContent="space-between"
      gap={3}
      rounded="md"
      border="1px solid"
      borderColor="gray.200"
      paddingY={2}
      paddingX={4}
    >
      <Box>
        <Link href={projectPath(`/mission-control/templates/${template.id}`)} fontSize="sm">
          {template.name}
        </Link>
        <HStack color="gray.500" fontSize="xs" spacing={1} divider={<MiddotDivider />}>
          {template.created_by_user && (
            <Text>Created by {template.created_by_user.name || template.created_by_user.email}</Text>
          )}
          <Text>{pluralize('list', template.template_views?.length || 0, true)}</Text>
        </HStack>
      </Box>
      <Flex alignItems="center" gap={2}>
        <Button
          size="sm"
          variant="outline"
          as="a"
          href={projectPath(`/mission-control/templates/${template.id}`) + window.location.search}
        >
          Edit
        </Button>
        <Menu>
          <MenuButton
            size="xs"
            as={IconButton}
            icon={<IconDotsVertical size={15} />}
            variant="ghost"
            borderColor="gray.200"
          />
          <MenuList fontSize="sm" zIndex="popover">
            <MenuItem icon={<IconEdit size={16} />} onClick={editTemplateModal.onOpen}>
              Edit name
            </MenuItem>
            <MenuItem icon={<IconCopyPlus size={16} />} onClick={applyTemplateModal.onOpen}>
              Apply template
            </MenuItem>
            <MenuItem
              icon={<IconCopy size={16} />}
              as="a"
              href={projectPath(`/mission-control/templates/${template.id}/clone`)}
            >
              Clone
            </MenuItem>
            <MenuItem icon={<IconTrash size={16} />} color="red.500" onClick={deletion.onOpen}>
              Delete…
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      <DeleteConfirmation
        isOpen={deletion.isOpen}
        onClose={deletion.onClose}
        title={`Are you sure you want to delete this template?`}
        confirmLabel="Delete template"
        deletePath={projectPath(`/mission-control/templates/${template.id}`)}
      >
        <Text fontSize="sm" color="gray.600">
          Your teammates will no longer be able to apply this template going forward.
        </Text>
      </DeleteConfirmation>

      <EditTemplateModal template={template} {...editTemplateModal} />
      <ApplyTemplateToUsersModal {...applyTemplateModal} template={template} />
    </Flex>
  )
}
