import { Box, Button, Flex, HStack, Icon, LinkBox, LinkOverlay, Text, Tooltip } from '@chakra-ui/react'
import { IconArrowRight, IconLock } from '@tabler/icons-react'
import pluralize from 'pluralize'
import * as React from 'react'
import type { Project } from '../../types/Project'
import CompanyAvatar from './CompanyAvatar'

interface ProjectProps {
  project: Project
  actionText?: string
  link?: string
}

export function ProjectCard(props: ProjectProps) {
  const domain = props.project.domain || props.project.potential_domain
  const auth_supported = props.project.auth_supported ?? true

  return (
    <LinkBox w={['100%', '100%', 420]}>
      {auth_supported ? (
        <Flex
          as={LinkOverlay}
          href={props.link ?? `/projects/${props.project.slug}`}
          justifyContent="space-between"
          alignItems="center"
          paddingY={3}
          paddingX={4}
          gap={4}
          rounded="md"
          border="1px solid"
          borderColor="gray.200"
          shadow="sm"
          transition="all 100ms ease-in-out"
          _hover={{
            background: 'gray.50'
          }}
        >
          <HStack spacing={3}>
            <CompanyAvatar size="sm" name={props.project.name} domain={domain} />
            <Box>
              <Text fontSize="sm" fontWeight="medium">
                {props.project.name}
              </Text>
              <Text fontSize="xs" color="gray.600">
                {props.project.slug} &middot; {pluralize('member', props.project.member_count, true)}
              </Text>
            </Box>
          </HStack>

          {props.actionText ? (
            <Button display={['none', 'flex']} size="sm" variant="outline">
              {props.actionText}
            </Button>
          ) : (
            <Icon as={IconArrowRight} boxSize={5} color="purple.500" />
          )}
        </Flex>
      ) : (
        <Tooltip
          label={props.project.unauthorized_error_message}
          arrowSize={15}
          bg="gray.100"
          border="1px solid"
          borderColor="gray.200"
          color="gray.600"
          gutter={12}
          padding={3}
          placement="top"
          shadow="sm"
          hasArrow
        >
          <Flex
            as={LinkOverlay}
            href="/reauth"
            justifyContent="space-between"
            alignItems="center"
            paddingY={3}
            paddingX={4}
            gap={4}
            rounded="md"
            border="1px solid"
            borderColor="gray.200"
            background="gray.50"
            shadow="sm"
            transition="all 100ms ease-in-out"
          >
            <HStack spacing={3}>
              <CompanyAvatar size="sm" name={props.project.name} domain={domain} />
              <Box>
                <Text fontSize="sm" fontWeight="medium">
                  {props.project.name}
                </Text>
                <Text fontSize="xs" color="gray.600">
                  {props.project.slug} &middot; {pluralize('member', props.project.member_count, true)}
                </Text>
              </Box>
            </HStack>
            <Icon as={IconLock} boxSize={5} color="gray.300" />
          </Flex>
        </Tooltip>
      )}
    </LinkBox>
  )
}
