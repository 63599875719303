import { usePermission } from '@app/components/ui/PermissionsContext'
import {
  Badge,
  Box,
  Button,
  Code,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  Link,
  Select,
  Stack,
  Switch,
  Text,
  useClipboard,
  VStack
} from '@chakra-ui/react'
import { IconArrowRight, IconCopy, IconForms } from '@tabler/icons-react'
import React from 'react'
import { Project } from '../../../types/Project'
import { SdkSettings } from '../../../types/ProjectSettings'
import { PublicAPIKey } from '../../../types/PublicAPIKey'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import { GrayCard } from '../../ui/Card'
import CompanyAvatar from '../../ui/CompanyAvatar'
import GoogleTagManagerLogo from '../../ui/GoogleTagManagerLogo.svg'
import { Iconify } from '../../ui/Iconify'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath, useCurrentProject } from '../../ui/ProjectsContext'
import Pulse from '../../ui/Pulse'
import SegmentLogo from '../../ui/SegmentLogo.svg'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { HiddenGeosForm } from '../excluded_accounts/components/hidden-geos'
import { AuthorizedReferrers } from './components/authorized-referrers'
import { PublicAPIKeys } from './components/public-keys'

export const snippet = (project: Project | PublicAPIKey) => {
  let slug: string | null = null
  if ((project as Project).slug) {
    slug = (project as Project).default_public_api_key?.key ?? (project as Project).slug
  } else {
    slug = (project as PublicAPIKey).key
  }

  if (slug === null) {
    throw new Error('Missing Public API Key')
  }

  return `<script>
!function(t){if(window.ko)return;window.ko=[],["identify","track","removeListeners","on","off","qualify","ready"].forEach(function(t){ko[t]=function(){var n=[].slice.call(arguments);return n.unshift(t),ko.push(n),ko}});var n=document.createElement("script");n.async=!0,n.setAttribute("src","https://cdn.getkoala.com/v1/${slug}/sdk.js"),(document.body || document.head).appendChild(n)}();
</script>`
}

interface Props {
  sdk_settings: SdkSettings
  data_flowing: boolean
  public_api_keys: PublicAPIKey[]
}

export default function Index(props: Props) {
  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        paths={[
          {
            path: projectPath('/settings/install'),
            title: 'Website Tracking Setup'
          }
        ]}
        offscreen
      />

      <SettingsHeader>
        <PageTitle>Website Tracking Setup</PageTitle>
        <PageDescription>
          Install the Koala pixel and configure what you want to collect about visitors on your website.
        </PageDescription>
      </SettingsHeader>

      <Stack spacing={10} divider={<Divider />}>
        <Stack as="section" spacing="2">
          <Flex alignItems="center" gap={4}>
            <Heading size="sm">Installation</Heading>
            <Flex alignItems="center" gap={2}>
              <Text fontSize="sm" color={props.data_flowing ? 'green.400' : 'orange.400'}>
                {props.data_flowing ? 'Connected!' : 'Waiting for events'}
              </Text>
              <Pulse size={2.5} background={props.data_flowing ? 'green.300' : 'orange.300'} />
            </Flex>
          </Flex>
          <ProjectSnippet />

          <Stack spacing={2.5} pt={5}>
            <Text fontSize="sm" fontWeight="medium">
              Other ways to install Koala:
            </Text>
            <InstallCard
              title="Install via Segment"
              description="Automatically send track and identify events from Segment to Koala"
              icon={<Image src={SegmentLogo} boxSize={5} />}
              href={projectPath('/apps/segment')}
            />
            <InstallCard
              title="Install via Google Tag Manager"
              description="Follow our guide for installing Koala via GTM"
              icon={<Image src={GoogleTagManagerLogo} boxSize={5} />}
              href="https://getkoala.com/docs/integrations/google-tag-manager"
            />
          </Stack>
        </Stack>

        <PublicAPIKeys keys={props.public_api_keys} />
        <AuthorizedReferrers domains={props.sdk_settings.authorized_referrers ?? []} />
        <SDKSettings sdk_settings={props.sdk_settings} />
        <HiddenGeosForm />
      </Stack>
    </PageLayout>
  )
}

interface InstallCardProps {
  title: string
  icon: React.ReactNode
  description: React.ReactNode
  href: string
}

export function InstallCard(props: InstallCardProps) {
  return (
    <Flex
      as="a"
      href={props.href}
      target="_blank"
      role="group"
      gap={3}
      alignItems="center"
      border="1px solid"
      borderColor="gray.200"
      paddingY={4}
      paddingX={6}
      rounded="md"
      transition="all 100ms ease-in-out"
      _hover={{
        shadow: 'sm',
        borderColor: 'gray.300'
      }}
    >
      <Box className="install-icon" flex="none">
        {props.icon}
      </Box>
      <Box flex="1">
        <Text fontSize="sm" fontWeight="medium" color="gray.700">
          {props.title}
        </Text>
        <Text fontSize="xs" color="gray.500">
          {props.description}
        </Text>
      </Box>
      <Icon as={IconArrowRight} boxSize={5} color="gray.400" _groupHover={{ color: 'purple.600' }} />
    </Flex>
  )
}

function SDKSettings(props: { sdk_settings: SdkSettings }) {
  const { hasPermission: canManageWebsite } = usePermission({ on: 'project', action: 'can_manage_website' })
  const project = useCurrentProject()
  const [customCookies, setCustomCookies] = React.useState<boolean>(props.sdk_settings.cookie_defaults ? true : false)
  const [autocapture, setAutocapture] = React.useState<boolean>(props.sdk_settings.autocapture)

  return (
    <form action={projectPath('/website-settings')} method="POST">
      <input type="hidden" name="_method" value="PUT" />
      <AuthenticityToken />
      <Flex flexDirection="column" gap={10}>
        <Flex gap={4}>
          <FormControl flex="1">
            <FormLabel fontSize="md">ID Links Custom Traits</FormLabel>
            <FormHelperText>If Koala should associate ID Links Custom Traits.</FormHelperText>
            <FormHelperText>
              This feature allows for the Koala SDK to detect custom traits that are passed via URL params. <br />
              New traits can be passed using the format: <Code>&ko_trait_&lt;property&gt;=value.</Code> <br />
              e.g. <Code>ko_trait_first_name=Netto&ko_trait_last_name=Farah</Code>
            </FormHelperText>
          </FormControl>
          <input name="sdk_settings[querystring_collection]" type="hidden" value="off" />
          <Switch
            name="sdk_settings[querystring_collection]"
            defaultChecked={props.sdk_settings.querystring_collection === 'on'}
            value="on"
            isDisabled={!canManageWebsite}
          />
        </Flex>

        <Flex gap={4}>
          <FormControl flex="1">
            <FormLabel fontSize="md">Websocket Connections</FormLabel>
            <FormHelperText>If Koala should use Websockets to communicate visitor statuses.</FormHelperText>
            <FormHelperText>
              Note: Turning off this feature reduces the accuracy of the Live tab and any Live list views in the Koala
              app.
            </FormHelperText>
          </FormControl>
          <input name="sdk_settings[websocket_connection]" type="hidden" value="off" />
          <Switch
            name="sdk_settings[websocket_connection]"
            defaultChecked={props.sdk_settings.websocket_connection === 'on'}
            value="on"
            isDisabled={!canManageWebsite}
          />
        </Flex>

        <Flex gap={4}>
          <FormControl flex="1">
            <FormLabel fontSize="md">Autocapture</FormLabel>
            <FormHelperText>If Koala should automatically capture page views, form submissions, etc.</FormHelperText>
            <Link fontSize="sm" color="purple.500" href="https://getkoala.com/docs/sdk/autocapture">
              Learn more
            </Link>
          </FormControl>
          <input name="sdk_settings[autocapture]" type="hidden" value="false" />
          <Switch
            name="sdk_settings[autocapture]"
            defaultChecked={props.sdk_settings.autocapture}
            value="true"
            isDisabled={!canManageWebsite}
            onChange={(e) => setAutocapture(e.target.checked)}
          />
        </Flex>

        <Flex flexDir="column" gap={8} paddingLeft={6} borderLeft="2px solid" borderColor="gray.100">
          <Flex gap={3}>
            <Iconify icon={IconForms} size={24} />
            <FormControl flex="1">
              <FormLabel fontSize="md">Form Submissions</FormLabel>
              <FormHelperText>If Koala should automatically track form submissions.</FormHelperText>
              <FormHelperText>
                You can add an attribute <Code>data-koala-collect="off"</Code> to any forms you want Koala to ignore.
              </FormHelperText>
            </FormControl>
            <input name="sdk_settings[form_collection]" type="hidden" value="off" />
            <Switch
              name="sdk_settings[form_collection]"
              defaultChecked={props.sdk_settings.form_collection === 'on'}
              value="on"
              isDisabled={!canManageWebsite}
            />
          </Flex>
          <Flex gap={3}>
            <CompanyAvatar size="xs" rounded="lg" domain="segment.com" />
            <FormControl flex="1">
              <FormLabel fontSize="md">Segment Events</FormLabel>
              <FormHelperText>If Koala should automatically collect Segment track events.</FormHelperText>
            </FormControl>
            <input name="sdk_settings[segment_auto_track]" type="hidden" value="off" />
            <Switch
              name="sdk_settings[segment_auto_track]"
              defaultChecked={props.sdk_settings.segment_auto_track === 'on'}
              value="on"
              isDisabled={!canManageWebsite}
            />
          </Flex>
          <Flex gap={3}>
            <CompanyAvatar size="xs" rounded="lg" domain="arcade.software" />
            <FormControl flex="1">
              <FormLabel fontSize="md">Arcade</FormLabel>
              <FormHelperText>If Koala should automatically track Arcade tour events.</FormHelperText>
            </FormControl>
            <input name="sdk_settings[autotrack_arcade]" type="hidden" value="false" />
            <Switch
              name="sdk_settings[autotrack_arcade]"
              defaultChecked={props.sdk_settings.autotrack_arcade}
              value="true"
              isDisabled={!canManageWebsite || !autocapture}
            />
          </Flex>
          <Flex gap={3}>
            <CompanyAvatar size="xs" rounded="lg" domain="navattic.com" />
            <FormControl flex="1">
              <FormLabel fontSize="md">Navattic</FormLabel>
              <FormHelperText>If Koala should automatically track Navattic demo events.</FormHelperText>
            </FormControl>
            <input name="sdk_settings[autotrack_navattic]" type="hidden" value="false" />
            <Switch
              name="sdk_settings[autotrack_navattic]"
              defaultChecked={props.sdk_settings.autotrack_navattic}
              value="true"
              isDisabled={!canManageWebsite || !autocapture}
            />
          </Flex>
          <Flex gap={3}>
            <CompanyAvatar size="xs" rounded="lg" domain="intercom.com" />
            <FormControl flex="1">
              <FormLabel fontSize="md">Intercom</FormLabel>
              <FormHelperText>If Koala should automatically track relevant Intercom interactions.</FormHelperText>
            </FormControl>
            <input name="sdk_settings[autotrack_intercom]" type="hidden" value="false" />
            <Switch
              name="sdk_settings[autotrack_intercom]"
              defaultChecked={props.sdk_settings.autotrack_intercom}
              value="true"
              isDisabled={!canManageWebsite || !autocapture}
            />
          </Flex>
          <Flex gap={3}>
            <CompanyAvatar size="xs" rounded="lg" domain="drift.com" />
            <FormControl flex="1">
              <FormLabel fontSize="md">Drift</FormLabel>
              <FormHelperText>If Koala should automatically track relevant Drift chat interactions.</FormHelperText>
            </FormControl>
            <input name="sdk_settings[autotrack_drift]" type="hidden" value="false" />
            <Switch
              name="sdk_settings[autotrack_drift]"
              defaultChecked={props.sdk_settings.autotrack_drift}
              value="true"
              isDisabled={!canManageWebsite || !autocapture}
            />
          </Flex>
          <Flex gap={3}>
            <CompanyAvatar size="xs" rounded="lg" domain="qualified.com" />
            <FormControl flex="1">
              <FormLabel fontSize="md">Qualified</FormLabel>
              <FormHelperText>If Koala should automatically track relevant Qualified chat interactions.</FormHelperText>
            </FormControl>
            <input name="sdk_settings[autotrack_qualified]" type="hidden" value="false" />
            <Switch
              name="sdk_settings[autotrack_qualified]"
              defaultChecked={props.sdk_settings.autotrack_qualified}
              value="true"
              isDisabled={!canManageWebsite || !autocapture}
            />
          </Flex>
          <Flex gap={3}>
            <CompanyAvatar size="xs" rounded="lg" domain="fullstory.com" />
            <FormControl flex="1">
              <FormLabel fontSize="md">FullStory</FormLabel>
              <FormHelperText>If Koala should automatically track relevant FullStory recordings.</FormHelperText>
            </FormControl>
            <input name="sdk_settings[autotrack_fullstory]" type="hidden" value="false" />
            <Switch
              name="sdk_settings[autotrack_fullstory]"
              defaultChecked={props.sdk_settings.autotrack_fullstory}
              value="true"
              isDisabled={!canManageWebsite || !autocapture}
            />
          </Flex>

          <Flex gap={3}>
            <CompanyAvatar size="xs" rounded="lg" domain="posthog.com" />
            <FormControl flex="1">
              <FormLabel fontSize="md">PostHog Session Replay</FormLabel>
              <FormHelperText>If Koala should automatically track relevant PostHog session replay.</FormHelperText>
            </FormControl>
            <input name="sdk_settings[autotrack_posthog_screen_recording]" type="hidden" value="false" />
            <Switch
              name="sdk_settings[autotrack_posthog_screen_recording]"
              defaultChecked={props.sdk_settings.autotrack_posthog_screen_recording}
              value="true"
              isDisabled={!canManageWebsite || !autocapture}
            />
          </Flex>
        </Flex>

        <Divider />

        <Stack gap={4}>
          <Stack spacing="4">
            <Stack spacing="1">
              <HStack>
                <Badge>BETA</Badge>
                <Heading size="sm" fontWeight={'semibold'}>
                  Cookie Settings
                </Heading>
              </HStack>
              <Text fontSize="sm" color="gray.500">
                Customize how Koala interacts with cookies on your website.
              </Text>
            </Stack>

            <Select
              size="sm"
              rounded={'md'}
              variant="outline"
              defaultValue={customCookies ? 'custom' : 'default'}
              onChange={(e) => setCustomCookies(e.target.value === 'custom')}
            >
              <option value="default">Default Settings</option>
              <option value="custom">Custom</option>
            </Select>
          </Stack>

          {customCookies && (
            <Stack as={GrayCard} borderWidth="1px">
              <FormControl flex="1">
                <FormLabel fontSize="sm">Website Domain</FormLabel>
                <Input
                  type="text"
                  pattern="[\w.-]+"
                  required
                  rounded="md"
                  size="sm"
                  name="sdk_settings[cookie_defaults][domain]"
                  bg="white"
                  defaultValue={props.sdk_settings.cookie_defaults?.domain ?? project?.domain}
                  isDisabled={!canManageWebsite}
                />
                <FormHelperText>
                  Define the domain to be used for your cookies. This is useful when you don't want to use dot prefixed
                  cookie domains.
                </FormHelperText>
                <FormHelperText pt="1">Note: Any protocol parts and trailing paths will be removed.</FormHelperText>
              </FormControl>
            </Stack>
          )}
        </Stack>

        <Flex justifyContent={'flex-end'}>
          <Button type="submit" colorScheme="purple" alignSelf="flex-start" isDisabled={!canManageWebsite}>
            Save SDK Settings
          </Button>
        </Flex>
      </Flex>
    </form>
  )
}

export function ProjectSnippet() {
  const project = useCurrentProject()
  const { hasCopied, onCopy } = useClipboard(snippet(project!))

  if (!project) {
    return null
  }

  return (
    <VStack alignItems="normal" spacing="4" w="100%">
      <Text fontSize="sm" color="gray.500">
        Copy the Koala snippet and paste it in the <Code fontSize="xs">{`<body>`}</Code> of your website. For more
        details about how the Koala pixel works, please see the{' '}
        <Link fontSize="sm" color="purple.500" href="https://getkoala.com/docs/get-started/quick-start" isExternal>
          Quick Start Guide
        </Link>
        .
      </Text>
      <Box position="relative" _hover={{ '.copy-code': { opacity: 1 } }}>
        <pre style={{ overflow: 'auto' }}>
          <Code p={4}>{snippet(project)}</Code>
        </pre>
        <Button
          className="copy-code"
          opacity={hasCopied ? 1 : 0}
          transition="opacity 200ms cubic-bezier(0, 0, 0.2, 1)"
          colorScheme="purple"
          variant="solid"
          size="sm"
          leftIcon={<IconCopy size={14} />}
          onClick={onCopy}
          position="absolute"
          right={4}
          top={4}
        >
          {hasCopied ? 'Copied!' : 'Copy'}
        </Button>
      </Box>
      <Text fontSize="sm" color="gray.500">
        Once you have the pixel installed on your website, you can debug everything on the{' '}
        <Link fontSize="sm" color="purple.500" href={projectPath('/instrumentation')}>
          Developer checklist
        </Link>
        .
      </Text>
    </VStack>
  )
}
