import copyToClipboard from 'copy-to-clipboard'
import React, { useCallback, useEffect } from 'react'

interface Options {
  // Desired MIME type
  format?: 'text/plain' | 'text/html'
  // Delay (in ms) to switch back to the initial state once copied
  timeout?: number
}

export function useCopyToClipboard({ format, timeout = 1500 }: Options = {}) {
  const [copied, setCopied] = React.useState(false)
  const [value, setValue] = React.useState<undefined | string>()

  const copy = useCallback(
    (value: string) => {
      if (typeof value !== 'string' && typeof value !== 'number') {
        return
      }

      if (value === '') {
        return
      }

      const success = copyToClipboard(value, {
        format: format || 'text/plain'
      })

      if (success) {
        setValue(value)
      } else {
        setValue(undefined)
      }

      setCopied(success)
    },
    [format]
  )

  useEffect(() => {
    if (copied) {
      const timeoutId = window.setTimeout(() => {
        setCopied(false)
      }, timeout)

      return () => window.clearTimeout(timeoutId)
    }
  }, [copied, timeout])

  return { copied, copy, value: value }
}
