export function getInitials(name?: string | null): string | null {
  if (!name || typeof name !== 'string') return null

  try {
    const [first, last] = name.split(' ')
    return [first, last]
      .filter(Boolean)
      .map((i) => [...i][0])
      .join('')
  } catch (e) {
    return null
  }
}
