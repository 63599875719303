import {
  Box,
  Button,
  Code,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Spinner,
  Stack,
  Switch,
  Text,
  UnorderedList
} from '@chakra-ui/react'
import {
  IconArrowRight,
  IconBulb,
  IconChevronDown,
  IconEdit,
  IconLink,
  IconPencilCode,
  IconWebhook
} from '@tabler/icons-react'
import { get, set } from 'lodash'
import { nanoid } from 'nanoid'
import React, { useMemo, useState } from 'react'
import { HelpTooltip } from '../../../../ui/HelpTooltip'
import { JSONTree } from '../../../../ui/json-tree'
import { ActionField } from '../../../actions/components/action-field'
import { FollowRule } from '../../../notifications'
import { ActionSchema, useActionSchema } from '../slack-message-builder/use-action-schema'
import { useNotificationVariables } from '../slack-message-builder/use-notification-variables'
import { TimeTrigger } from '../time-trigger'

interface WebhookSetupProps {
  delivery_rules?: FollowRule['delivery_rules']
  skipTimeTrigger?: boolean
  targetType?: 'Account' | 'Profile'
}

interface FieldPairProps {
  actionSchema: ActionSchema
  selectedItemKey?: string
  targetType?: 'Account' | 'Profile'
  webhookKey?: string
  onChange: (koala_key: string | undefined, webhook_key: string | undefined) => void
}

function FieldPair(props: FieldPairProps) {
  const schema = props.actionSchema
  const [selectedItemKey, setSelectedItemKey] = useState<string | null>(props.selectedItemKey ?? null)

  const schemaItems = useMemo(
    () =>
      Object.values({
        ...schema?.account_categories,
        // ignore visitor categories if target type is Account
        ...(props.targetType === 'Account' ? {} : schema?.visitor_categories),
        ...schema?.signal_categories
      })
        .flat()
        .flatMap((category) => category.items),
    [schema, props.targetType]
  )

  return (
    <>
      <Flex flex="1">
        <ActionField
          schema={props.actionSchema}
          selectedItem={schemaItems.find((item) => item.key === selectedItemKey) ?? null}
          type={props.targetType === 'Profile' ? 'webhook' : 'account'}
          onChange={(item) => {
            setSelectedItemKey(item.key ?? null)
            props.onChange(item.key ?? undefined, props.webhookKey ?? item.key)
          }}
        />
      </Flex>
      <Icon as={IconArrowRight} />
      <InputGroup flex="1" bg="white">
        <InputLeftAddon>
          <Icon as={IconWebhook} />
        </InputLeftAddon>
        <Input
          placeholder="Webhook field"
          value={props.webhookKey ?? ''}
          required
          pattern="[a-zA-Z_.]+"
          onBlur={(e) => {
            if (e.target.value.trim() === '') {
              e.target.checkValidity()
              e.target.reportValidity()
            }
          }}
          title="Webhook key cannot be empty and can only contain characters, underscores, and dots"
          onChange={(e) => {
            props.onChange(selectedItemKey ?? undefined, e.target.value)
          }}
          fontSize="sm"
        />
      </InputGroup>
    </>
  )
}

interface HardcodedPairProps {
  mapping: WebhookMapping
  onChange: (mapping: WebhookMapping) => void
}

function HardcodedPair(props: HardcodedPairProps) {
  return (
    <>
      <InputGroup flex="1" bg="white">
        <InputLeftAddon>
          <Icon as={IconPencilCode} />
        </InputLeftAddon>
        <Input
          placeholder="Value"
          fontSize="sm"
          value={props.mapping.koala_key ?? ''}
          onChange={(e) => {
            props.onChange({ ...props.mapping, koala_key: e.target.value })
          }}
        />
      </InputGroup>

      <Icon as={IconArrowRight} />

      <InputGroup flex="1" bg="white">
        <InputLeftAddon>
          <Icon as={IconWebhook} />
        </InputLeftAddon>
        <Input
          placeholder="Webhook field"
          fontSize="sm"
          onBlur={(e) => {
            if (e.target.value.trim() === '') {
              e.target.checkValidity()
              e.target.reportValidity()
            }
          }}
          value={props.mapping.webhook_key ?? ''}
          pattern="[a-zA-Z_.]+"
          title="Webhook key cannot be empty and can only contain characters, underscores, and dots"
          onChange={(e) => {
            props.onChange({ ...props.mapping, webhook_key: e.target.value })
          }}
        />
      </InputGroup>
    </>
  )
}

export type WebhookMapping = { id: string; mode: 'mapped' | 'hardcoded'; koala_key?: string; webhook_key?: string }

interface FieldMapperProps {
  actionSchema: ActionSchema
  targetType?: 'Account' | 'Profile'
  mappings: WebhookMapping[]
  setMappings: (mappings: WebhookMapping[]) => void
}

function FieldMapper(props: FieldMapperProps) {
  const { mappings, setMappings } = props

  const suggestions: WebhookMapping[] = useMemo(() => {
    const visitorFields = [
      {
        id: nanoid(),
        koala_key: 'visitor.email',
        webhook_key: 'visitor.email',
        mode: 'mapped'
      },
      {
        id: nanoid(),
        koala_key: 'visitor.first_name',
        webhook_key: 'visitor.first_name',
        mode: 'mapped'
      },
      {
        id: nanoid(),
        koala_key: 'visitor.last_name',
        webhook_key: 'visitor.last_name',
        mode: 'mapped'
      },
      {
        id: nanoid(),
        koala_key: 'visitor.title',
        webhook_key: 'visitor.title',
        mode: 'mapped'
      },
      {
        id: nanoid(),
        koala_key: 'visitor.linkedin_url',
        webhook_key: 'visitor.linkedin_url',
        mode: 'mapped'
      }
    ]

    return (props.targetType !== 'Account' ? visitorFields : []).concat([
      {
        id: nanoid(),
        koala_key: 'account.company.name',
        webhook_key: 'account.company.name',
        mode: 'mapped'
      },
      {
        id: nanoid(),
        koala_key: 'account.company.domain',
        webhook_key: 'account.company.domain',
        mode: 'mapped'
      },
      {
        id: nanoid(),
        koala_key: 'account.company.linkedin_url',
        webhook_key: 'account.company.linkedin_url',
        mode: 'mapped'
      },
      {
        id: nanoid(),
        koala_key: 'signal.name',
        webhook_key: 'signal.name',
        mode: 'mapped'
      },
      {
        id: nanoid(),
        koala_key: 'signal.intent_summary',
        webhook_key: 'signal.intent_summary',
        mode: 'mapped'
      },
      {
        id: nanoid(),
        koala_key: 'signal.timestamp',
        webhook_key: 'signal.timestamp',
        mode: 'mapped'
      }
    ]) as WebhookMapping[]
  }, [props.targetType])

  return (
    <FormControl>
      <Stack spacing="4">
        <Stack spacing="1">
          <HStack spacing="2">
            <FormLabel mb="0" mr="0">
              Select properties
            </FormLabel>
            <HelpTooltip size="4">
              <Stack py="2">
                <HStack>
                  <Icon as={IconBulb} color="orange.500" />
                  <Heading size="xs">Webhook mapping tips</Heading>
                </HStack>
                <UnorderedList fontSize="sm" spacing={'2'} p="2" px="4">
                  <ListItem>
                    Webhook keys cannot be empty and can only contain characters, underscores, and dots.
                  </ListItem>
                  <ListItem>Use the dot notation if you'd like for fields to be nested in your payload.</ListItem>
                  <ListItem>
                    e.g. <Code>company.name</Code> will create a <Code>company</Code> object, with a nested{' '}
                    <Code>name</Code> field.
                  </ListItem>
                </UnorderedList>
              </Stack>
            </HelpTooltip>
          </HStack>
          <FormHelperText>
            Map properties from your notification to fields in your webhook payload. You can also hardcode values.
          </FormHelperText>
        </Stack>

        <Stack>
          {mappings.map((mapping) => {
            return (
              <HStack key={mapping.id}>
                {mapping.mode === 'mapped' && (
                  <FieldPair
                    actionSchema={props.actionSchema}
                    targetType={props.targetType}
                    selectedItemKey={mapping.koala_key}
                    webhookKey={mapping.webhook_key}
                    onChange={(koala_key: string | undefined, webhook_key: string | undefined) => {
                      setMappings(
                        mappings.map((m) => {
                          if (m.id === mapping.id) {
                            return { ...m, koala_key, webhook_key }
                          }

                          return m
                        })
                      )
                    }}
                  />
                )}
                {mapping.mode === 'hardcoded' && (
                  <HardcodedPair
                    mapping={mapping}
                    onChange={(mapping) => {
                      setMappings(
                        mappings.map((m) => {
                          if (m.id === mapping.id) {
                            return mapping
                          }

                          return m
                        })
                      )
                    }}
                  />
                )}
                <Button
                  size="sm"
                  onClick={() => {
                    setMappings(mappings.filter((m) => m.id !== mapping.id))
                  }}
                  variant="ghost"
                  fontSize={'sm'}
                >
                  &times;
                </Button>
              </HStack>
            )
          })}
          <Flex justifyContent={'flex-end'} gap={1.5}>
            {mappings.length === 0 && (
              <Button
                size="xs"
                variant="outline"
                px="2"
                onClick={() => {
                  setMappings(suggestions)
                }}
              >
                Use suggested fields
              </Button>
            )}

            <Menu>
              <MenuButton size="xs" variant={'outline'} as={Button} rightIcon={<IconChevronDown size="12" />}>
                Add field
              </MenuButton>
              <MenuList zIndex="popover">
                <MenuItem
                  icon={<Icon as={IconArrowRight} />}
                  onClick={() => {
                    setMappings([...mappings, { id: nanoid(), mode: 'mapped' }])
                  }}
                >
                  Mapped Field
                </MenuItem>
                <MenuItem
                  icon={<Icon as={IconEdit} />}
                  onClick={() => {
                    setMappings([...mappings, { id: nanoid(), mode: 'hardcoded' }])
                  }}
                >
                  Hardcoded Field
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Stack>
      </Stack>

      {mappings.map((mapping) => {
        return (
          <Box key={mapping.id}>
            <input type="hidden" name={`follow_rule[delivery_rules][webhook][mappings][][id]`} value={mapping.id} />
            <input type="hidden" name={`follow_rule[delivery_rules][webhook][mappings][][mode]`} value={mapping.mode} />
            <input
              type="hidden"
              name={`follow_rule[delivery_rules][webhook][mappings][][koala_key]`}
              value={mapping.koala_key}
            />
            <input
              type="hidden"
              name={`follow_rule[delivery_rules][webhook][mappings][][webhook_key]`}
              value={mapping.webhook_key}
            />
          </Box>
        )
      })}
    </FormControl>
  )
}

export function WebhookSetup(props: WebhookSetupProps) {
  const data = useNotificationVariables()
  const [selectedSample, setSelectedSample] = useState(0)
  const [useMappings, setUseMappings] = useState((props.delivery_rules?.webhook?.mappings?.length ?? 0) > 0)
  const [mappings, setMappings] = useState(props.delivery_rules?.webhook?.mappings ?? [])
  const { schema } = useActionSchema()

  const isBatched = props.delivery_rules?.webhook?.trigger && props.delivery_rules?.webhook?.trigger !== 'immediate'

  return (
    <Stack spacing="8" pb="4">
      <FormControl>
        <FormLabel>URL</FormLabel>
        <Stack>
          <InputGroup size="sm">
            <InputLeftAddon borderLeftRadius={'md'}>
              <IconLink size="14" />
            </InputLeftAddon>
            <Input
              type="url"
              rounded="md"
              size="sm"
              required={!!props.delivery_rules?.webhook}
              disabled={!props.delivery_rules?.webhook}
              bg="white"
              placeholder="https://webhook.site/"
              name="follow_rule[delivery_rules][webhook][url]"
              defaultValue={props.delivery_rules?.webhook?.url}
            />
          </InputGroup>
          <FormHelperText>The webhook will issue a POST request to this URL with a JSON payload body</FormHelperText>
        </Stack>
      </FormControl>

      <Divider />

      <Switch
        isChecked={useMappings}
        onChange={(e) => {
          setUseMappings(e.target.checked)
        }}
        fontWeight="semibold"
        fontSize={'sm'}
        alignItems="center"
        display={'flex'}
      >
        Customize Fields
      </Switch>

      {useMappings && schema && (
        <>
          <FieldMapper
            actionSchema={schema}
            targetType={props.targetType}
            mappings={mappings}
            setMappings={(mappings) => {
              setMappings(mappings)
            }}
          />
        </>
      )}

      {data.isLoading && (
        <Flex p="4">
          <Spinner />
        </Flex>
      )}

      {!data.isLoading && (
        <Stack bg="gray.50" p="4" spacing="4">
          <Stack spacing="0">
            <Heading size="xs">Preview</Heading>
            <Text fontSize={'sm'}>Your webhook notification will include the following fields:</Text>
          </Stack>
          <Select
            size="xs"
            rounded="md"
            bg="white"
            value={selectedSample}
            onChange={(e) => {
              setSelectedSample(parseInt(e.target.value))
            }}
          >
            {data.samples
              .filter((s) => s.account)
              .map((sample, idx) => {
                return (
                  <option value={idx} key={JSON.stringify({ idx, sample })}>
                    {sample.account.name ?? 'Unknown'} - {sample.visitor.display_name}
                  </option>
                )
              })}
          </Select>
          <Flex px="4" bg="white">
            <JSONTree
              data={
                mappings.length > 0 && useMappings
                  ? mapSample(data.samples[selectedSample], mappings)
                  : { notification: { ctx: data.samples[selectedSample] } }
              }
              treeProps={{
                sortObjectKeys: false
              }}
            />
          </Flex>
        </Stack>
      )}

      {props.delivery_rules?.webhook && (
        <>
          {!props.skipTimeTrigger && isBatched ? (
            <TimeTrigger
              disabled={!props.delivery_rules?.webhook}
              namespace="follow_rule[delivery_rules][webhook]"
              trigger={props.delivery_rules?.webhook?.trigger}
            />
          ) : (
            <>
              <input type="hidden" name={'follow_rule[delivery_rules][webhook][trigger]'} value="immediate" />
            </>
          )}
        </>
      )}
    </Stack>
  )
}

function mapSample(sample: object, mappings: WebhookMapping[]) {
  return mappings.reduce((acc, mapping) => {
    if (mapping.mode === 'mapped') {
      set(acc, mapping.webhook_key ?? '', get(sample, mapping.koala_key ?? '') ?? null)
    }

    if (mapping.mode === 'hardcoded') {
      set(acc, mapping.webhook_key ?? '', mapping.koala_key)
    }

    return acc
  }, {})
}
