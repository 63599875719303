import {
  Button,
  Code,
  Divider,
  Flex,
  Heading,
  HStack,
  Link,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { IconTableDown } from '@tabler/icons-react'
import React from 'react'
import { DownloadCSVButton, UtmEntry } from '.'
import router from '../../../lib/router'
import { PageMeta } from '../../../types/PageMeta'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import { TableFooter } from '../../ui/TableFooter'
import { TimeAgo } from '../../ui/TimeAgo'
import { titleize } from '../accounts/facets/filter-cloud'
import DateRangePicker from '../analytics/components/DateRangePicker'
import { friendlyNumber } from '../billing/v3'
import { mergeParams } from '../icps/types'

interface Props {
  entries: UtmEntry[]
  property: string
  page_meta: PageMeta
  period: 'day' | 'week' | 'month'
}

export default function Utm(props: Props) {
  return (
    <PageLayout size="md">
      <SettingsBreadCrumb
        paths={[
          { title: 'UTM Reports', path: projectPath('/utm-reports') },
          {
            title: 'UTM ' + titleize(props.property) + 's',
            path: projectPath(`/utm-reports/utm/${props.property}`)
          }
        ]}
      />

      <Flex gap={3} w="100%" justifyContent={'space-between'} alignItems="flex-start">
        <Stack>
          <PageTitle>UTM {titleize(props.property)}s</PageTitle>
          <PageDescription>
            UTM {titleize(props.property)}s are used to track the performance of your marketing campaigns. This report
            shows the total number of page views for each UTM {titleize(props.property)}.
          </PageDescription>
        </Stack>

        <HStack spacing={3}>
          <DateRangePicker
            period={props.period}
            onChange={(period) => {
              router.visit(
                mergeParams(window.location.toString(), {
                  period,
                  page: '1'
                })
              )
            }}
          />

          <DownloadCSVButton
            basePath={projectPath(`/utm-reports/utm/${props.property}.csv`)}
            pageMeta={props.page_meta}
            trigger={(onClick) => (
              <Button
                size="sm"
                onClick={onClick}
                variant="outline"
                leftIcon={<IconTableDown size={14} />}
                iconSpacing={1.5}
              >
                Download CSV
              </Button>
            )}
            title={
              <Heading size="sm">
                Recent Entries for{' '}
                <Code fontWeight={'semibold'} px="2" py="1">
                  utm_{props.property}
                </Code>
              </Heading>
            }
            description={
              <Text fontSize={'sm'}>
                This CSV contains the recent entries for utm_{props.property}. CSV downloads are limited to the most
                recent 10,000 page views per download.
              </Text>
            }
            period={props.period}
          />
        </HStack>
      </Flex>

      <Divider />

      <TableContainer>
        <Table size="sm" variant={'striped'}>
          <Thead>
            <Tr>
              <Th>Property</Th>
              <Th isNumeric>Total Visits</Th>
              <Th isNumeric>Total Visitors</Th>
              <Th isNumeric>Total Accounts</Th>
              <Th isNumeric>Last Visit</Th>
            </Tr>
          </Thead>
          <Tbody>
            {props.entries.map((entry) => (
              <Tr key={entry.property}>
                <Td>
                  <Link href={projectPath(`/utm-reports/utm/${props.property}/${entry.property}`)}>
                    {entry.property}
                  </Link>
                </Td>
                <Td isNumeric>{friendlyNumber(entry.total)}</Td>
                <Td isNumeric>{friendlyNumber(entry.total_visitors)}</Td>
                <Td isNumeric>{friendlyNumber(entry.total_accounts)}</Td>
                <Td isNumeric>
                  <TimeAgo time={entry.last_visit} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <TableFooter
        scrollToTop={false}
        pageMeta={props.page_meta}
        page={props.page_meta.current_page}
        nextPath={mergeParams(window.location.toString(), {
          page: `${props.page_meta.current_page + 1}`
        })}
        prevPath={mergeParams(window.location.toString(), {
          page: `${props.page_meta.current_page - 1}`
        })}
      />
    </PageLayout>
  )
}
