import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import type { Account } from '../../../../../types/Account'
import { LightBgCard } from '../../../../ui/Card'
import { CardHeader } from '../../../../ui/CardHeader'
import { TopPageViews } from '../TopPageViews'
import { TopTrafficSources } from '../TopTrafficSources'

interface Props {
  account: Account
}

export function Analytics(props: Props) {
  return (
    <Flex gap={4} direction="column">
      <LightBgCard p={0} overflow="hidden">
        <CardHeader paddingX={4} paddingBottom={3}>
          Top Pages
          <Flex fontSize="xs" marginTop={3} fontWeight="semibold" color="gray.500" justifyContent="space-between">
            <Text>Page</Text>
            <Text>Views (30d)</Text>
          </Flex>
        </CardHeader>
        <TopPageViews {...props.account} />
      </LightBgCard>
      <LightBgCard p={0} overflow="hidden">
        <CardHeader paddingX={4} paddingBottom={3}>
          Top Sources
          <Flex fontSize="xs" marginTop={3} fontWeight="semibold" color="gray.500" justifyContent="space-between">
            <Text>Referrer / UTM Source</Text>
            <Text>Views (30d)</Text>
          </Flex>
        </CardHeader>
        <TopTrafficSources {...props.account} />
      </LightBgCard>
    </Flex>
  )
}
