import { projectPath } from '../../../ui/ProjectsContext'

export function notificationPath(id: string) {
  return projectPath(`/notifications/${id}`)
}

export function notificationsPath(rest?: string) {
  return projectPath(`/notifications/${rest ?? ''}`)
}

export function automationPath(id?: string, rest?: string) {
  return projectPath(`/automations/${id ?? ''}/${rest ?? ''}`)
}

export function automationsPath(rest?: string) {
  return projectPath(`/automations/${rest ? rest : ''}`)
}

export function slackAlertPath(id?: string, rest?: string) {
  return projectPath(`/slack-alerts/${id ?? ''}/${rest ?? ''}`)
}

export function slackAlertNewFromAlertPath(from_alert: string) {
  return projectPath(`/slack-alerts/new?from_alert=${from_alert}`)
}

export function slackAlertsPath(rest?: string) {
  return projectPath(`/slack-alerts/${rest || ''}`)
}
