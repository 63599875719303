import React, { FormEvent, useState } from 'react'
import CompanyAvatar from '@app/components/ui/CompanyAvatar'
import Avatar from '../../../ui/Avatar'
import {
  useDisclosure,
  UseDisclosureProps,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Icon,
  Button,
  Flex,
  HStack,
  Spinner,
  Stack,
  FormControl,
  FormLabel
} from '@chakra-ui/react'
import { toast } from 'sonner'
import { postForm } from '../../../../lib/api'
import { IconUser, IconCircleDashed } from '@tabler/icons-react'
import { Prospect } from '../../../data/use-prospects'
import { useAppDep } from '../../../data/use-app-dep'
import { App } from '../../../../types/App'
import { Account } from '../../../../types/Account'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { projectPath } from '../../../ui/ProjectsContext'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'

interface AddToInstantlyProps extends UseDisclosureProps {
  selectedProspects?: Prospect[]
  prospectsWithoutEmail?: Prospect[] | null
  app: App
  account?: Account
  profileType?: 'Profile' | 'ProspectedProfile'
}

interface InstantlyCampaign {
  id: string
  name: string
}

export function AddToInstantlyModal(props: AddToInstantlyProps) {
  const { selectedProspects, prospectsWithoutEmail } = props
  const disclosure = useDisclosure(props)
  const onClose = disclosure.onClose
  const [saving, setSaving] = useState(false)

  // TODO: check if we need to set any initial state here
  const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(null)

  const { data: campaignData, isLoading: loadingCampaigns } = useAppDep<'campaigns', InstantlyCampaign[]>(
    'instantly',
    'campaigns'
  )

  const onSubmit = React.useCallback(
    (e: FormEvent) => {
      e.preventDefault()

      setSaving(true)

      const form = e.target as HTMLFormElement
      const data = new FormData(form)

      const profileIds = selectedProspects?.map((p) => p.id) ?? []
      profileIds.forEach((profileId) => data.append('profile_ids[]', profileId))

      //TODO: find a way to get info about the campaign_id

      postForm(projectPath(`/instantly/actions/bulk-campaign?profile_type=${props.profileType ?? 'Profile'}`), data)
        .then(() => {
          toast.success('Prospect added to Sequence!', {
            description: `Chosen prospects has been added to campaign in Instantly.`
          })
          setSaving(false)
          onClose()
        })
        .catch((e) => {
          toast.error('Error adding prospect to sequence', {
            description: e.message
          })
          setSaving(false)
        })
    },
    [selectedProspects, props.profileType, onClose]
  )

  const [showMoreProspects, setShowMoreProspects] = useState(false)
  const toggleShowMoreProspects = () => {
    setShowMoreProspects(!showMoreProspects)
  }

  return (
    <Modal {...disclosure} size="md" isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontSize="md" display="flex" gap={2} pb={1}>
          <CompanyAvatar size="20px" domain={'instantly.ai'} mr={2} src={props.app?.logo} />
          Add to Instantly sequence
        </ModalHeader>
        <ModalBody pb={6}>
          {selectedProspects && (
            <Text mb={6}>
              <Icon as={IconUser} boxSize={4} color="gray.400" /> {selectedProspects.length} contacts
            </Text>
          )}
          <form onSubmit={onSubmit}>
            <Stack fontSize="sm" spacing={4}>
              <FormControl>
                <FormLabel>Campaign</FormLabel>
                {selectedCampaignId && <input type="hidden" name="campaign[campaign_id]" value={selectedCampaignId} />}

                {loadingCampaigns && <Spinner size="sm" />}
                {!loadingCampaigns && campaignData?.data?.campaigns && campaignData?.data?.campaigns.length < 1 && (
                  <Text p="4" bg="orange.50" m="2">
                    We couldn't find any Campaign to display. Make sure that the API key permissions in your Instantly
                    workspace are correct.
                  </Text>
                )}

                <ComboboxWithSearch
                  items={campaignData?.data?.campaigns ?? []}
                  selectedItem={campaignData?.data?.campaigns?.find((c) => c.id === selectedCampaignId) ?? null}
                  onChange={(selectedItem) => {
                    setSelectedCampaignId(selectedItem?.id ?? null)
                  }}
                  filterItem={(a, val) => a.name.toLowerCase().includes(val) || a.id.toLowerCase().includes(val)}
                  itemToString={(item) => (item ? `[${item.id}] ${item.name}` : '')}
                  itemRenderer={CampaignRenderer}
                  selectButtonRenderer={CampaignRenderer}
                />
              </FormControl>
            </Stack>
            <Stack fontSize="sm" spacing={2} mt={4}>
              <Text fontSize="xs" color="gray.500" fontWeight="bold">
                CONTACTS:
              </Text>
              <Flex direction="column" alignItems="space-between" gap={3}>
                {prospectsWithoutEmail && prospectsWithoutEmail.length && (
                  <Stack spacing="4" p="2">
                    <Text fontSize="sm" color="gray.600" lineHeight="1.4">
                      You've selected {prospectsWithoutEmail.length} prospect{''}
                      {prospectsWithoutEmail.length > 1 ? 's' : ''} whose emails have not been enriched yet. They will
                      be enriched if you proceed and will use enrichment credits in the future.
                    </Text>
                    <Text whiteSpace={'pre-wrap'} fontSize="xs" lineHeight="1.4" p="4" bg="purple.50" color="gray.700">
                      Note: we're currently offering unlimited Koala Waterfall enrichment credits during our open beta.
                    </Text>
                  </Stack>
                )}
                {selectedProspects && (
                  <>
                    {selectedProspects.length > 5 ? (
                      <>
                        {selectedProspects?.slice(0, 4).map((selectedProspect) => (
                          <Flex key={selectedProspect.id} justifyContent="space-between" alignItems="center">
                            <HStack>
                              <Avatar
                                size="xs"
                                name={selectedProspect.name}
                                src={projectPath(`/prospects/${selectedProspect.id}/avatar`)}
                              />
                              <Text>
                                {selectedProspect.first_name} {selectedProspect.last_name}
                              </Text>
                            </HStack>
                            <TextEllipsis fontSize="xs" color="gray.600" maxW="50%">
                              {selectedProspect.title}
                            </TextEllipsis>
                          </Flex>
                        ))}
                        {showMoreProspects &&
                          selectedProspects?.slice(4, selectedProspects.length).map((selectedProspect) => (
                            <Flex key={selectedProspect.id} justifyContent="space-between" alignItems="center">
                              <HStack>
                                <Avatar
                                  size="xs"
                                  name={selectedProspect.name}
                                  src={projectPath(`/prospects/${selectedProspect.id}/avatar`)}
                                />
                                <Text>
                                  {selectedProspect.first_name} {selectedProspect.last_name}
                                </Text>
                              </HStack>
                              <TextEllipsis fontSize="xs" color="gray.600" maxW="50%">
                                {selectedProspect.title}
                              </TextEllipsis>
                            </Flex>
                          ))}
                        <Text onClick={toggleShowMoreProspects} color="purple.500" align="center" cursor="pointer">
                          {showMoreProspects
                            ? `- Hide ${selectedProspects.length - 4} contacts`
                            : `+ Show ${selectedProspects.length - 4} more contacts`}
                        </Text>
                      </>
                    ) : (
                      <>
                        {selectedProspects.map((selectedProspect) => (
                          <Flex key={selectedProspect.id} justifyContent="space-between" alignItems="center">
                            <HStack>
                              <Avatar
                                size="xs"
                                name={selectedProspect.name}
                                src={projectPath(`/prospects/${selectedProspect.id}/avatar`)}
                              />
                              <Text>
                                {selectedProspect.first_name} {selectedProspect.last_name}
                              </Text>
                            </HStack>
                            <TextEllipsis fontSize="xs" color="gray.600" maxW="50%">
                              {selectedProspect.title}
                            </TextEllipsis>
                          </Flex>
                        ))}
                      </>
                    )}
                  </>
                )}
              </Flex>
            </Stack>
            <Flex w="100%" pt="4">
              <Button
                w="100%"
                size="sm"
                colorScheme={'purple'}
                type="submit"
                isLoading={saving || loadingCampaigns}
                isDisabled={(campaignData?.data?.campaigns?.length ?? 0) < 1 || !selectedCampaignId}
              >
                Add to Sequence
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

interface CampaignRendererProps {
  item: InstantlyCampaign | null
  isSelected?: boolean
  isToggleButton?: boolean
}

export function CampaignRenderer({ item }: CampaignRendererProps) {
  if (!item) {
    return (
      <HStack spacing={2.5}>
        <Icon as={IconCircleDashed} color="gray.300" boxSize={6} />
        <Text fontSize="sm" fontWeight="medium" color="gray.400">
          Select a Campaign
        </Text>
      </HStack>
    )
  }

  return (
    <HStack w="100%" key={item.id} px="1" flex="1" fontSize={'sm'}>
      <Stack spacing="-0.5">
        <Text fontSize="sm">{item.name}</Text>
        <Text fontSize={'xs'} color="gray.500">
          {item.id}
        </Text>
      </Stack>
    </HStack>
  )
}
