import React, { useMemo } from 'react'
import { useOnboardingSteps } from '../../data/use-onboarding-steps'
import { useUpdateOnboarding } from '../../data/use-update-onboarding'
import { Tour, Step } from '../../ui/TourComponent'

interface Props {
  filterSelector: string
  columnSelector: string
}

const ONBOARDING_CATEGORY = 'my_accounts'

export function NewListTour({ filterSelector, columnSelector }: Props) {
  const { data, isLoading } = useOnboardingSteps(ONBOARDING_CATEGORY)

  const tourSteps: Step[] = useMemo(() => {
    const hidden = (data?.done || []).map((s) => s.step)

    const all: Step[] = [
      {
        target: `.${filterSelector || 'filter-menu-trigger'}`,
        title: 'Use filters to find your assigned accounts',
        content:
          'You can use filters from your CRM, Koala data, and other integrations to find the accounts you care about. Try looking for "Account Owner"',
        disableBeacon: true,
        placement: 'bottom-start',
        data: { step_id: 'filters' }
      },
      {
        target: `.${columnSelector || 'column-selector'}`,
        title: 'Customize your view',
        content: 'You can choose which columns to display on any list, and can rearrange them to suit your needs.',
        disableBeacon: true,
        placement: 'bottom-start',
        data: { step_id: 'customize-view' }
      }
    ]

    return all.filter((step) => !hidden.includes(step.data?.step_id))
  }, [filterSelector, columnSelector, data?.done])

  const updateStep = useUpdateOnboarding()

  if (isLoading || tourSteps.length === 0) {
    return null
  }

  return (
    <Tour
      steps={tourSteps}
      run={tourSteps.length > 0}
      onNextStep={(data) => {
        if (data?.step?.data?.step_id) {
          updateStep.mutateAsync({ step: data.step.data.step_id, category: ONBOARDING_CATEGORY, status: 'completed' })
        }
      }}
      onSkipped={(data) => {
        if (data?.step?.data?.step_id) {
          updateStep.mutateAsync({ step: data.step.data.step_id, category: ONBOARDING_CATEGORY, status: 'skipped' })
        }
      }}
    />
  )
}
