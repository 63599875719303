// Replace original history functions so they emit events
history.pushState = ((f) =>
  function pushState(_state, _title, url) {
    // @ts-ignore fix the types
    const ret = f.apply(this, arguments)
    window.dispatchEvent(new Event('pushstate'))
    window.dispatchEvent(new CustomEvent('locationchange', { detail: { url, trigger: 'pushState' } }))
    return ret
  })(history.pushState)

history.replaceState = ((f) =>
  function replaceState(_state, _title, url) {
    // @ts-ignore fix the types
    const ret = f.apply(this, arguments as any)
    window.dispatchEvent(new Event('replacestate'))
    window.dispatchEvent(new CustomEvent('locationchange', { detail: { url, trigger: 'replaceState' } }))
    return ret
  })(history.replaceState)

export interface MetaVisit {
  fetch?: boolean
}

// Very minimalist router implementation, based on turbolinks
// We'll hook into locationchange events to trigger async page fetches
class Router {
  constructor() {
    window.addEventListener('click', this.onClickCapture, true)
  }

  onClickCapture = () => {
    window.removeEventListener('click', this.onClickBubble, false)
    window.addEventListener('click', this.onClickBubble, false)
  }

  onClickBubble = (event: MouseEvent) => {
    if (this.shouldFollowEvent(event)) {
      const link = this.getLinkForTarget(event.target)
      if (link) {
        const url = this.getLocationForLink(link)
        if (url) {
          event.preventDefault()
          this.visit(url)
        }
      }
    }
  }

  shouldFollowEvent = (event: MouseEvent): boolean => {
    return !(
      (event.target && (event.target as any).isContentEditable) ||
      event.defaultPrevented ||
      event.altKey ||
      event.ctrlKey ||
      event.metaKey ||
      event.shiftKey
    )
  }

  // Finds the nearest ancestor link element.
  getLinkForTarget = (target: EventTarget | null) => {
    if (target instanceof Element) {
      return target.closest('a[href]:not([target]):not([download])')
    }
  }

  getLocationForLink = (link: Element) => {
    const location = link.getAttribute('href')

    if (this.locationIsVisitable(location)) {
      return location as string
    }
  }

  locationIsVisitable = (location: string | null): boolean => {
    if (!location) {
      return false
    }

    // Check if it's a relative url
    const isRelative = location.startsWith('/') && !location.startsWith('//')
    if (isRelative) {
      return true
    }

    // Otherwise check if it's the same origin
    const anchor = document.createElement('a')
    anchor.href = location

    try {
      const url = new URL(anchor.href)
      return url.origin === window.location.origin
    } catch (error) {
      return false
    }
  }

  visit(url: string, meta: MetaVisit = {}) {
    if (this.allowVisit(url)) {
      if (this.locationIsVisitable(url)) {
        this.onVisit(url, meta)
        history.pushState(null, '', url)
      } else {
        window.location.href = url
      }
    }
  }

  onVisit(url: string, meta: MetaVisit = {}) {
    const event = new CustomEvent('router:visit', { bubbles: true, cancelable: true, detail: { url, meta } })
    window.dispatchEvent(event)
  }

  allowVisit(url: string) {
    const event = new CustomEvent('router:before-visit', { bubbles: true, cancelable: true, detail: { url } })
    window.dispatchEvent(event)
    return !event.defaultPrevented
  }
}

export default new Router()
