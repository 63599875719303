import { Button, HStack, IconButton, Link, Stack, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import { IconExternalLink } from '@tabler/icons-react'
import React from 'react'
import type { App } from '../../../../types/App'
import { AppActions } from '../../../../types/AppActions'
import { PersonRecord } from '../../../../types/Person'
import { ProfileRecord } from '../../../../types/Profile'
import { DetailsCard } from '../../../ui/Card'
import { CardHeading } from '../../../ui/CardHeading'
import { StackedField } from '../../../ui/StackedField'
import { TimeAgo } from '../../../ui/TimeAgo'
import { AddToListModal } from './apollo-actions'
import { useUIState } from './useUIState'

interface ApolloCardProps {
  app: App
  actions?: AppActions
  profile: ProfileRecord
  record?: PersonRecord
  permalink?: string
  recordType: 'Prospect'
  initiallyCollapsed?: boolean
  onRequestImport?: (app: App) => void
}

export function ApolloCard(props: ApolloCardProps) {
  const listDisclosure = useDisclosure()

  const [ui, setUI] = useUIState()
  const cardDisclosure = useDisclosure({
    defaultIsOpen: ui.open?.apollo ?? !props.initiallyCollapsed,
    onOpen: () => {
      setUI({ open: { apollo: true } })
    },
    onClose: () => {
      setUI({ open: { apollo: false } })
    }
  })

  return (
    <DetailsCard>
      <CardHeading icon={props.app.logo} disclosure={cardDisclosure}>
        <HStack justifyContent="space-between">
          <Text>Apollo {props.recordType}</Text>
          {props.permalink && (
            <Tooltip label="View in Apollo" placement="top" hasArrow arrowSize={6}>
              <IconButton
                aria-label="View in Apollo"
                as={Link}
                href={props.permalink}
                isExternal
                size="xs"
                variant="link"
                icon={<IconExternalLink size={16} />}
                color="gray.500"
                _hover={{ color: 'purple.600' }}
                onClick={() => {
                  window.ko?.track('Apollo Visit Action', {
                    app: 'apollo',
                    record: props.record
                  })
                }}
              />
            </Tooltip>
          )}
        </HStack>
      </CardHeading>
      {!cardDisclosure.isOpen ? null : props.record ? (
        <Stack spacing={4}>
          <StackedField label="Email">{props.record.email}</StackedField>
          <StackedField label="Name">{props.record.name?.full_name || props.record.name?.given_name}</StackedField>
          <StackedField label="Location">
            {[props.record.geo?.city, props.record.geo?.country, props.record.geo?.country].filter(Boolean).join(', ')}
          </StackedField>

          <StackedField label="Last Synced">
            <TimeAgo time={props.record.updated_at} mode="calendar" />
          </StackedField>

          <Button size="sm" variant="outline" onClick={listDisclosure.onOpen}>
            Add to List…
          </Button>
        </Stack>
      ) : props.app.connected ? (
        <Stack>
          <Text fontSize="xs" color="gray.500">
            No prospects linked yet
          </Text>
        </Stack>
      ) : null}

      <AddToListModal {...listDisclosure} profile={props.profile} />
    </DetailsCard>
  )
}
