import { Button, Center, Heading, Img, Stack } from '@chakra-ui/react'
import React, { useState } from 'react'
import router from '../../../lib/router'
import { OmnisearchCompany } from '../../data/use-company-search'
import { CompanySelector } from '../../ui/CompanySelector'
import PageLayout from '../../ui/PageLayout'
import { projectPath } from '../../ui/ProjectsContext'
import Bg from './components/background.svg'
import Sparkle from './components/sparkle.svg'

interface Props {}

export default function Index(_props: Props) {
  const [selectedCompany, setSelectedCompany] = useState<OmnisearchCompany | null>(null)

  return (
    <PageLayout>
      <Center bgPos={'center'} bgImg={Bg} w="100%" h="100%" bgRepeat={'no-repeat'}>
        <Stack
          justifyContent={'center'}
          alignItems="center"
          spacing="4"
          as={'form'}
          action={selectedCompany ? projectPath(`/research/${selectedCompany?.domain}`) : undefined}
        >
          <Img src={Sparkle} maxW="30px" />
          <Heading size="sm" fontWeight={'normal'}>
            Select a company to{' '}
            <b>
              <i>start Prospecting</i>
            </b>
          </Heading>
          <CompanySelector
            onChange={(company) => setSelectedCompany(company)}
            selectedCompany={selectedCompany}
            showClearButton
          />

          <Button
            w="100%"
            colorScheme={'purple'}
            type="submit"
            isDisabled={!selectedCompany}
            onClick={() => router.visit(projectPath(`/research/${selectedCompany?.domain}`))}
          >
            Continue
          </Button>
        </Stack>
      </Center>
    </PageLayout>
  )
}
