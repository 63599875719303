import { Button, HStack, Image, Text, Tooltip, Box, Icon, Circle, Badge } from '@chakra-ui/react'
import React from 'react'
import type { Subscription } from '@app/types/AccountView'

interface IntegrationCardProps {
  title: string
  description: string
  logo: string | React.ElementType
  subscription?: Subscription
  editUrl?: string
}

export default function IntegrationCard(props: IntegrationCardProps) {
  const [integrationState, action] = React.useMemo<['disabled' | 'enabled' | 'invalid', string]>(() => {
    if (!props.subscription) {
      return ['disabled', 'Create...']
    }
    if (!props.subscription.app_instance?.client_valid) {
      return ['invalid', 'Fix...']
    }
    return ['enabled', 'Edit...']
  }, [props.subscription])

  return (
    <HStack
      w={'100%'}
      background={'white'}
      rounded={'md'}
      border={'1px solid'}
      borderColor={'gray.200'}
      paddingY={4}
      paddingLeft={4}
      paddingRight={6}
    >
      <HStack spacing={4} flex="1">
        {typeof props.logo === 'string' ? (
          <Image flex="none" src={props.logo} width="8" />
        ) : (
          <Circle flex="none" size={8} backgroundColor="gray.100">
            <Icon as={props.logo} size={20} />
          </Circle>
        )}
        <Box flex="1">
          <Text display="flex" fontSize="md" fontWeight="semibold">
            {props.title}
            {integrationState == 'enabled' && (
              <Badge display="inline-flex" marginLeft={2} colorScheme="green" alignSelf="center">
                Enabled
              </Badge>
            )}
            {integrationState == 'invalid' && (
              <Badge display="inline-flex" marginLeft={2} colorScheme="red" alignSelf="center">
                Invalid
              </Badge>
            )}
          </Text>
          <Text fontSize="sm" color="gray.600">
            {props.description}
          </Text>
        </Box>
      </HStack>

      {props.editUrl ? (
        <Button size="sm" as="a" href={props.editUrl}>
          {action}
        </Button>
      ) : (
        <Tooltip label="Coming Soon" shouldWrapChildren>
          <Button isDisabled size="sm">
            Create…
          </Button>
        </Tooltip>
      )}
    </HStack>
  )
}
