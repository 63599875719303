import React from 'react'
import { Stack, Box, Text, Flex, HStack, Image } from '@chakra-ui/react'
import { AccountEventSession, AccountKqlEventSession } from '../'
import AccountEventFeed from './AccountEventFeed'
import AccountKqlEventFeed from './AccountKqlEventFeed'
import { LightBgCard } from '@app/components/ui/Card'

export type G2EventTypes = AccountEventSession | AccountKqlEventSession

export default function G2Session({ events }: { events: G2EventTypes[] }) {
  return (
    <LightBgCard w="100%" bg="white" p={0} borderColor="gray.50" shadow="base">
      <Box fontSize="xs" bg="g2.50" paddingY={2} paddingX={5} roundedTop="md" marginX="-1px" marginTop="-1px">
        <Flex justifyContent="space-between" alignItems="center" gap={3}>
          <HStack>
            <Image src="https://asset.brandfetch.io/idN7c_7lEQ/idMiolluN4.png" alt="G2" boxSize={5} />
            <Text fontWeight="bold">G2</Text> <Text>Activity</Text>
          </HStack>
        </Flex>
      </Box>
      <Stack p={4}>
        {events.map((event) => (
          <Box
            key={event.id}
            _hover={{
              bg: 'gray.50'
            }}
          >
            {event.record_type === 'AccountEvent' ? (
              <AccountEventFeed event={event as AccountEventSession} />
            ) : event.record_type === 'AccountKqlEvent' ? (
              <AccountKqlEventFeed event={event as AccountKqlEventSession} />
            ) : null}
          </Box>
        ))}
      </Stack>
    </LightBgCard>
  )
}
