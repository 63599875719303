import { Stack, StackProps, Text } from '@chakra-ui/react'
import React from 'react'

const getRenderedChild = (child: any): string | React.ReactNode => {
  if (typeof child === 'object' && child !== null && !React.isValidElement(child)) {
    // Convert object to JSON string because they are not supported as React children
    return JSON.stringify(child)
  }

  return child
}

export type FieldProps = StackProps & { label: string; value?: React.ReactNode | null }
export function Field(props: React.PropsWithChildren<FieldProps>) {
  const { label, value, children, ...rest } = props
  if (!value) {
    return null
  }

  return (
    <Stack fontSize="xs" color="gray.700" {...rest}>
      <Text color="gray.500" fontWeight="medium">
        {label}
      </Text>
      {children ?? <Text>{getRenderedChild(value) ?? '—'}</Text>}
    </Stack>
  )
}

export function HField(props: React.PropsWithChildren<FieldProps>) {
  return <Field direction="row" wrap={'wrap'} alignItems={'center'} {...props} />
}

export function VField(props: React.PropsWithChildren<FieldProps>) {
  return <Field direction="column" wrap={'wrap'} {...props} />
}
