import * as React from 'react'

export interface KoalaDomain {
  domain: string
  aliases: string[]
}

declare global {
  interface Window {
    domains: KoalaDomain
  }
}

export const DomainContext = React.createContext({
  domain: window.domains.domain,
  aliases: window.domains.aliases,
  findMatches: (url?: string): string[] => {
    if (url) {
      return window.domains.aliases.filter((d) => url.match(d))
    }
    return []
  }
})

export function useDomains() {
  return React.useContext(DomainContext)
}
