import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import {
  Badge,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Stack
} from '@chakra-ui/react'
import React from 'react'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { LightBgCard } from '../../../ui/Card'
import PageDescription from '../../../ui/PageDescription'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { usePermission } from '../../../ui/PermissionsContext'
import { projectPath } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'

interface Props {
  title: string
  description: string
  logo: string
  settings: Record<string, string | null | undefined>
  deps: {}
}

export default function Show(props: Props) {
  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/proxycurl'),
            title: props.title
          }
        ]}
        offscreen
      />
      <HStack>
        <Image src={props.logo} maxW="6" />
        <PageTitle>{props.title}</PageTitle>
        <Badge colorScheme={'purple'}>ALPHA</Badge>

        {Object.keys(props.settings).length > 0 && (
          <Flex flex="1" justifyContent={'flex-end'}>
            <DisconnectAppDialog appTitle={'ProxyCurl'} showRemoveCachesOption={false} />
          </Flex>
        )}
      </HStack>
      <PageDescription>{props.description}</PageDescription>

      <form method="POST">
        <AuthenticityToken />
        <input type="hidden" name="_method" value="PUT" />

        <Stack spacing="4" pt="8">
          <Heading size="sm" fontWeight={'semibold'}>
            🔐 Auth Settings
          </Heading>

          <LightBgCard as={Stack}>
            <Stack spacing={'4'}>
              <FormControl id="api_key">
                <FormLabel>API Key</FormLabel>
                <Input
                  bg="gray.50"
                  w="100%"
                  name="app_instance_settings[api_key]"
                  placeholder="ProxyCurl API Key"
                  defaultValue={props.settings['api_key'] ?? ''}
                  type="password"
                />
                <FormHelperText>An API key from your ProxyCurl workspace.</FormHelperText>
              </FormControl>
            </Stack>
          </LightBgCard>
        </Stack>

        <Stack spacing="8" mt="8">
          <HStack w="100%">
            <Button colorScheme="purple" type="submit" w="100%" isDisabled={!canEditProject}>
              Save
            </Button>
          </HStack>
        </Stack>
      </form>
    </PageLayout>
  )
}
