import { FormControl, FormHelperText, Stack, Switch } from '@chakra-ui/react'
import React from 'react'
import { OutreachActionProps } from './outreach-setup'

export function ImportAccount(props: OutreachActionProps) {
  const [enabled, setEnabled] = React.useState(Boolean(props.delivery_rules?.outreach?.import_account?.enabled))

  return (
    <Stack>
      <FormControl>
        <Switch
          isChecked={enabled}
          onChange={(e) => {
            setEnabled(e.target.checked)
            props.setDeliveryRules({
              ...props.delivery_rules,
              outreach: {
                ...props.delivery_rules?.outreach,
                import_account: {
                  ...props.delivery_rules?.outreach?.import_account,
                  enabled: e.target.checked
                }
              }
            })
          }}
          size="sm"
          fontSize={'sm'}
          fontWeight="semibold"
          colorScheme={'purple'}
          value={enabled ? 'true' : 'false'}
          name="follow_rule[delivery_rules][outreach][import_account][enabled]"
        >
          Enable
        </Switch>
        <FormHelperText>
          Import the current Account as an Account in Outreach if it doesn't already exist.
        </FormHelperText>
      </FormControl>
    </Stack>
  )
}
