import React, { useCallback, useState } from 'react'
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { projectPath } from '../../../ui/ProjectsContext'

interface NewSpaceModalProps extends UseDisclosureProps {
  includeDescription?: boolean
}

export function NewSpaceModal(props: NewSpaceModalProps) {
  const disclosure = useDisclosure(props)
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = useCallback(() => {
    setSubmitting(true)
  }, [])

  return (
    <Modal {...disclosure} size="lg" preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Create a space</ModalHeader>
        <ModalBody>
          <form id="save-space-form" method="POST" action={projectPath('/spaces')} onSubmit={onSubmit}>
            <AuthenticityToken />

            <Stack spacing={6}>
              {props.includeDescription && (
                <Text fontSize="sm" color="gray.500">
                  Spaces are collections of lists that you and your teammates can share. Multiple people can join a
                  space. You can use these lists to organize your accounts and contacts.
                </Text>
              )}
              <FormControl>
                <FormLabel htmlFor="space[name]">Space name</FormLabel>
                <Input
                  autoFocus
                  size="sm"
                  autoComplete="off"
                  placeholder="Add a descriptive name for your space"
                  name="space[name]"
                  isRequired
                  id="space[name]"
                />
              </FormControl>
            </Stack>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" variant="outline" mr={3} onClick={disclosure.onClose}>
            Cancel
          </Button>
          <Button size="sm" type="submit" colorScheme="purple" form="save-space-form" isLoading={submitting}>
            Create space
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
