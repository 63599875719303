import { Flex } from '@chakra-ui/react'
import React from 'react'
import { AccountView } from '../../../types/AccountView'
import { Apps } from '../../../types/App'
import { Crm } from '../../../types/Crm'
import { ProfileRecord } from '../../../types/Profile'
import { useUrlFilters } from '../../data/use-url-filters'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { projectPath } from '../../ui/ProjectsContext'
import { TopBarContent } from '../../ui/TopBarContext'
import useTitle from '../../ui/useTitle'
import { FacetFilters } from '../accounts'
import { ListContainer } from '../lists/components/ListContainer'
import { MCHeader } from '../mission_control/Header'
import { HighlightedProfile } from '../profiles/components/profile-list'
import { ProfileLiveList } from '../profiles/components/profile-live-list'
import ViewDetails from './components/ViewDetails'
import { accountViewPath } from './lib/list-paths'

export interface ProfilesProps {
  facet_filters: FacetFilters
  crm?: Crm
  profiles: ProfileRecord[]
  account_view: AccountView
  total_count: number
  apps: Apps
  permissions: Record<'can_edit' | 'can_create' | 'can_destroy', boolean>
}

export default function Index(props: ProfilesProps) {
  const onMissionControl = window.location.pathname.includes('/mission-control')

  const facets = useUrlFilters({
    initialRange: props.account_view.filters.range ?? 'month',
    initialFacets: props.account_view.filters.facets,
    initialFocusTime: props.account_view.filters.focus_time,
    facetCloudPath: '/profiles/facet-cloud'
  })

  useTitle(`${props.account_view.name} | Live List | Koala`)

  const [paused, setPaused] = React.useState(false)

  return (
    <ListContainer paddingX={0} paddingY={0} gap={0} maxH="100%" minH="300px">
      {onMissionControl ? (
        <MCHeader space={props.account_view.space} viewId={props.account_view.id} apps={props.apps} />
      ) : (
        <TopBarContent>
          <Flex alignItems="center" gap={1.5}>
            <Breadcrumb
              paths={[
                {
                  path: projectPath(props.account_view.kind === 'profile' ? '/people' : '/accounts'),
                  title: props.account_view.kind === 'profile' ? 'People' : 'Accounts'
                },
                { path: accountViewPath(props.account_view), title: props.account_view.name },
                { path: window.location.toString(), title: 'Live' }
              ]}
              paddingTop={1}
            />
          </Flex>
        </TopBarContent>
      )}

      <form
        action={accountViewPath(props.account_view) + location.search}
        method="POST"
        onSubmit={(e) => {
          if (!props.permissions.can_edit) {
            e.preventDefault()
          }
        }}
      >
        <input type="hidden" value="PATCH" name="_method" />
        <AuthenticityToken />
        <ViewDetails
          isLive
          apps={props.apps}
          account_view={props.account_view}
          permissions={props.permissions}
          facets={facets}
          paused={paused}
          setPaused={setPaused}
          defaults={{
            range: 'month'
          }}
        />
      </form>

      <ProfileLiveList
        profiles={props.profiles as unknown as HighlightedProfile[]}
        facetParams={facets}
        total_count={props.total_count ?? 0}
        paused={paused}
        onChangeMode={(mode) => setPaused(mode === 'paused')}
      />
    </ListContainer>
  )
}
