import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  ListItem,
  Select,
  Spinner,
  Stack,
  StackDivider,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
  useBoolean
} from '@chakra-ui/react'
import { IconAlertTriangle, IconCheck, IconExternalLink } from '@tabler/icons-react'
import React from 'react'
import { useDebounce } from 'use-debounce'
import { formatNumber } from '../../../../lib/number-format'
import { useClearbitKey } from '../../../data/use-clearbit-key'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { LightBgCard } from '../../../ui/Card'
import { CardHeader } from '../../../ui/CardHeader'
import PageDescription from '../../../ui/PageDescription'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { usePermission } from '../../../ui/PermissionsContext'
import { projectPath } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../../ui/SettingsHeader'

interface Plan {
  name: string
  count: number
  limit: number
}

interface Props {
  title: string
  description: string
  logo: string
  settings: Record<string, string | null | undefined>
  connected?: boolean
  valid?: boolean
  deps: {
    reveal_enabled?: boolean
    enrich_enabled?: boolean
    child_account: boolean
    limits?: {
      'koala-trial': Plan[]
    }
  }
}

function RateLimits(props: { limits?: Props['deps']['limits'] }) {
  // Only show if there are any koala plan subscriptions (`koala-trial`, `koala-childaccount-50k`, `koala-childaccount-100k`, `koala-childaccount-150k`, `koala-childaccount-200k`)
  const subs = Object.keys(props.limits || {}).filter((s) => s.startsWith('koala-'))

  if (subs.length === 0) {
    return null
  }

  const first = props.limits![subs[0]]
  const revealPlan = first.find((x) => x.name === 'Reveal')
  const enrichmentPlan = first.find((x) => x.name === 'Enrichment')

  return (
    <HStack>
      {revealPlan && (
        <Stat>
          <StatLabel>Reveal API calls</StatLabel>
          <StatNumber>
            {formatNumber(revealPlan.count)}/{formatNumber(revealPlan.limit)}
          </StatNumber>
        </Stat>
      )}
      {enrichmentPlan && (
        <Stat>
          <StatLabel>Enrichment API calls</StatLabel>
          <StatNumber>
            {formatNumber(enrichmentPlan.count)}/{formatNumber(enrichmentPlan.limit)}
          </StatNumber>
        </Stat>
      )}
    </HStack>
  )
}

export default function Show(props: Props) {
  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })
  const [provisioning, setProvisioning] = React.useState(false)
  const childAccountId = props.settings?.id
  const [optedOut, optOut] = useBoolean(false)
  const [newKey, setNewKey] = React.useState('')
  const [debouncedNewKey] = useDebounce(newKey, 300)

  const { isLoading, data } = useClearbitKey(debouncedNewKey)

  const ownKeyWithoutReveal =
    Boolean(props.settings.private_key && !props.deps?.reveal_enabled) || (!isLoading && data?.reveal_enabled === false)
  const offerFree = window.location.search.includes('offer=1') || ownKeyWithoutReveal

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/clearbit'),
            title: 'Clearbit'
          }
        ]}
        offscreen
      />

      <SettingsHeader>
        <HStack>
          <Image src={props.logo} boxSize="6" />
          <PageTitle flex="1">{props.title}</PageTitle>
          {props.connected && props.valid && <Badge colorScheme="green">Connected</Badge>}
          {props.connected && !props.valid && <Badge colorScheme="orange">Needs attention</Badge>}
          {props.connected && <DisconnectAppDialog appTitle={'Clearbit'} showRemoveCachesOption={false} />}
        </HStack>
        <PageDescription>{props.description}</PageDescription>
      </SettingsHeader>

      {props.deps.child_account && childAccountId ? (
        <Stack bg="blue.50" p="8" spacing="8" w="100%" rounded="md">
          <Stack spacing="1">
            <Heading size="md">Clearbit API calls</Heading>
            <Text fontSize="sm">
              You are using a Clearbit key provisioned through our Powered By Clearbit partnership. This key includes
              10,000 Reveal calls, and 250 Enrichment calls per month.
            </Text>
          </Stack>
          {props.deps.limits && <RateLimits limits={props.deps.limits} />}
        </Stack>
      ) : !props.settings.private_key ? (
        <>
          <LightBgCard padding={0}>
            <form method="POST" action={window.location.pathname + '?sync=1'} onSubmit={() => setProvisioning(true)}>
              <AuthenticityToken />
              <input type="hidden" name="_method" value="PUT" />
              <input type="hidden" name="app_instance_settings[use_child_key]" value="true" />

              <Flex justifyContent="space-between" alignItems="flex-start" gap={3} padding={4}>
                <Image src={props.logo} boxSize="4" marginY={0.5} />
                <Flex flex="1" flexWrap="wrap" gap={4}>
                  <Stack flex="1 1 auto" minWidth="50%" spacing={2}>
                    <Heading size="sm">Unlock 10,000 Clearbit Reveal monthly credits for free</Heading>
                    <Text fontSize="xs" color="gray.600">
                      Koala customers can get a free 10,000 Reveal and 250 Enrich calls each month. By using Powered by
                      Clearbit in Koala, you agree to Clearbit's{' '}
                      <Link href="https://clearbit.com/privacy-policy" isExternal>
                        Privacy Policy
                      </Link>{' '}
                      and{' '}
                      <Link href="https://clearbit.com/legal" isExternal>
                        Terms
                      </Link>
                      .
                    </Text>
                  </Stack>
                  <HStack spacing={3}>
                    <Button flex="none" type="submit" colorScheme="purple" size="sm" isLoading={provisioning}>
                      Continue
                    </Button>
                    {!optedOut && (
                      <Button size="sm" variant="link" onClick={optOut.toggle}>
                        No thanks, I already purchased Clearbit Reveal
                      </Button>
                    )}
                  </HStack>
                </Flex>
              </Flex>
            </form>
          </LightBgCard>
        </>
      ) : offerFree ? (
        <>
          <LightBgCard padding={0}>
            <form method="POST" action={window.location.pathname + '?sync=1'} onSubmit={() => setProvisioning(true)}>
              <AuthenticityToken />
              <input type="hidden" name="_method" value="PUT" />
              <input type="hidden" name="app_instance_settings[use_child_key]" value="true" />
              <input type="hidden" name="app_instance_settings[private_key]" value="" />

              <Flex justifyContent="space-between" alignItems="flex-start" gap={3} padding={4}>
                <Image src={props.logo} boxSize="4" marginY={0.5} />
                <Flex flex="1" flexWrap="wrap" gap={4}>
                  <Stack flex="1 1 auto" minWidth="50%" spacing={2}>
                    <Heading size="sm">Switch to Powered by Clearbit in Koala for free</Heading>
                    <Text fontSize="xs" color="gray.600">
                      You can unlock 10,000 Clearbit Reveal monthly credits for free by switching to Powered by Clearbit
                      in Koala. By using this, you agree to Clearbit's{' '}
                      <Link href="https://clearbit.com/privacy-policy" isExternal>
                        Privacy Policy
                      </Link>{' '}
                      and{' '}
                      <Link href="https://clearbit.com/legal" isExternal>
                        Terms
                      </Link>
                      .
                    </Text>
                  </Stack>
                  <Button flex="none" type="submit" colorScheme="purple" size="sm" isLoading={provisioning}>
                    Switch for free
                  </Button>
                </Flex>
              </Flex>
            </form>
          </LightBgCard>
        </>
      ) : null}

      <Box as="form" w="100%" method="POST">
        <AuthenticityToken />
        <input type="hidden" name="_method" value="PUT" />

        {(props.settings.private_key || optedOut) && (
          <LightBgCard as={Stack} padding="6">
            <Stack spacing="4">
              <Stack spacing="2">
                <Heading size="sm">
                  {props.settings.private_key ? 'API Settings' : 'Already purchased Clearbit Reveal?'}
                </Heading>
                <Text fontSize="xs" color="gray.600">
                  Enter your API key here to use your existing account. If you only have a free Clearbit account, we
                  recommend using our Powered by Clearbit program instead.
                </Text>
              </Stack>

              <Divider />

              <FormControl id="private_key" isRequired isInvalid={ownKeyWithoutReveal}>
                <FormLabel mb="1">Secret API Key</FormLabel>

                <InputGroup w="100%" size="sm">
                  <Input
                    defaultValue={props.settings.private_key ?? ''}
                    onChange={(e) => {
                      setNewKey(e.target.value)
                    }}
                    name="app_instance_settings[private_key]"
                    bg="white"
                    placeholder="Clearbit Secret API Key: sk_..."
                    pattern="sk_[a-zA-Z0-9]{32}"
                  />
                  <InputRightElement color={ownKeyWithoutReveal ? 'red.500' : 'green.400'}>
                    {isLoading ? (
                      <Spinner color="gray.400" size="sm" />
                    ) : ownKeyWithoutReveal ? (
                      <IconAlertTriangle size={18} />
                    ) : debouncedNewKey ? (
                      <IconCheck size={18} />
                    ) : null}
                  </InputRightElement>
                </InputGroup>

                {ownKeyWithoutReveal ? (
                  <FormErrorMessage>
                    This key does not have Clearbit Reveal enabled. Please enter a key that does, or use Koala to
                    provision a generous free key above.
                  </FormErrorMessage>
                ) : (
                  <FormHelperText fontSize={'xs'}>
                    Find your Clearbit secret key here:{' '}
                    <Link
                      href="https://dashboard.clearbit.com/api"
                      target="_blank"
                      isExternal
                      variant="primary"
                      display="inline-flex"
                      alignItems="center"
                      gap={1}
                    >
                      https://dashboard.clearbit.com/api
                      <IconExternalLink size={14} />
                    </Link>
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>
          </LightBgCard>
        )}

        <Stack spacing="8" mt="8">
          <CardHeader>Settings</CardHeader>

          <FormControl id="confidence_score">
            <FormLabel>Reveal Confidence Score</FormLabel>
            <FormHelperText>
              What's the minimum confidence score you want to require for Clearbit Reveal IP matches?
            </FormHelperText>
            <Select
              name="app_instance_settings[reveal_confidence_score]"
              size="sm"
              rounded="md"
              defaultValue={props.settings.reveal_confidence_score ?? 'medium'}
            >
              <option value="very_high">Very High</option>
              <option value="high">High</option>
              <option value="medium">Medium</option>
              <option value="low">Low</option>
            </Select>
            <Stack fontSize={'sm'}>
              <UnorderedList p="4" spacing="2">
                <ListItem>
                  <strong>Very High</strong> tends to be a high-quality result whose margin of error should be less than
                  5%.
                </ListItem>
                <ListItem>
                  <strong>High</strong> and <strong>Medium</strong> tends to be residential IPs with few users.
                </ListItem>
                <ListItem>
                  <strong>Low</strong> tends to be offices that have recently changed or shared residential locations
                  such as hotels or apartments.
                </ListItem>
              </UnorderedList>
              <Text>
                We recommend setting your minimum confidence score to <strong>Medium</strong> or higher.{' '}
                <Link
                  href="https://dashboard.clearbit.com/docs#reveal-api-lookup-ip-addresses-confidence-scores"
                  isExternal
                  color="purple.500"
                >
                  Learn more
                </Link>{' '}
                about Clearbit confidence scores from from the Clearbit API docs.
              </Text>
            </Stack>
          </FormControl>

          <HStack w="100%">
            <Button colorScheme="purple" type="submit" minW="80px" isDisabled={!canEditProject}>
              Save
            </Button>
          </HStack>
        </Stack>
      </Box>
    </PageLayout>
  )
}
