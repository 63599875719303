import { Box, Flex, FlexProps, HStack, Progress, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { Account } from '../../../../types/Account'
import { TrackIntent } from '../../kql_definitions/components/TrackIntent'

function ValueBox(props: FlexProps) {
  return (
    <Flex gap={1} rounded="md" border="1px solid" borderColor="gray.200" alignItems="center" paddingX={2} {...props} />
  )
}

export interface EntryRowProps {
  title?: string
  label: string
  count: number
  total: number
  index: number
  account: Account
  interactive?: boolean
  trackIntentProps?: React.ComponentProps<typeof TrackIntent>
  onSelect?: () => void
}

export function EntryRow(props: EntryRowProps) {
  const { label, count, title, total, interactive, onSelect } = props
  const percentage = (100 * count) / total
  const value = Math.max(3, percentage)
  const displayValue = percentage < 1 ? `<1%` : `${Math.round(percentage)}%`

  return (
    <Box w="100%" flex="1 1 auto" minWidth="100px">
      <HStack
        justifyContent={'space-between'}
        paddingX={4}
        paddingY={3}
        w="100%"
        onClick={onSelect}
        cursor={interactive ? 'pointer' : undefined}
      >
        <HStack flexShrink={1} minWidth="25px" overflow="hidden" spacing={0}>
          <Tooltip label={label.length > 25 ? label : undefined}>
            <Text fontSize="sm" flex="1" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" maxW="350px">
              {label}
            </Text>
          </Tooltip>
          {props.trackIntentProps && <TrackIntent {...props.trackIntentProps} />}
        </HStack>
        <HStack>
          <Text flex="none" fontSize="xs" css={{ fontVariantNumeric: 'tabular-nums' }}>
            {count} {title}
          </Text>
          <ValueBox flex="none">
            <Progress colorScheme="gray" rounded="sm" width="60px" value={value} />
            <Text minWidth="25px" textAlign="right" fontSize="xs" css={{ fontVariantNumeric: 'tabular-nums' }}>
              {displayValue}
            </Text>
          </ValueBox>
        </HStack>
      </HStack>
    </Box>
  )
}
