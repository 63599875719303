import {
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  LinkBox,
  LinkOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconArrowLeft, IconArrowRight, IconChevronDown, IconChevronUp, IconPlus } from '@tabler/icons-react'
import pluralize from 'pluralize'
import * as React from 'react'
import createPersistedState from 'use-persisted-state'
import { LightBgCard } from '../../ui/Card'
import { SlackIcon } from '../../ui/icons'
import { InfoBox } from '../../ui/InfoBox'
import { MiddotDivider } from '../../ui/Middot'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { TimeAgo } from '../../ui/TimeAgo'
import { LineGraph } from '../analytics/components/LineGraph'
import { slackAlertPath, slackAlertsPath } from '../notifications/lib/path-helper'
import { audienceName } from '../slack_alerts/components/audience-name'
import { SlackAlert } from '../slack_alerts/types'
import DefinitionForm, { OptionsMenu } from './components/DefinitionForm'
import { KqlDefinition } from './types'

interface Props {
  definition: KqlDefinition
  timeseries?: Record<string, number>
  slack_alerts: SlackAlert[]
}

export default function Show(props: Props) {
  const updateDefinitionPath = projectPath(`/signals/${props.definition.id}`)
  const trend = React.useMemo(
    () =>
      Object.entries(props.timeseries ?? {}).map(([date, total]) => ({
        date,
        total
      })),
    [props.timeseries]
  )

  return (
    <PageLayout size="sm">
      <PageTitle skipRendering>Intent Signal - {props.definition.name}</PageTitle>
      <Stack width="100%" p="8" maxW="container.md" marginX="auto" spacing={8}>
        <Button
          alignSelf="flex-start"
          as={Link}
          href={projectPath('/signals')}
          leftIcon={<Icon as={IconArrowLeft} boxSize={3.5} />}
          variant="link"
          size="xs"
          flex="none"
          color="gray.500"
        >
          Back to all intent signals
        </Button>

        <HStack justifyContent={'space-between'}>
          <Heading size="md">Edit your Intent Signal</Heading>
          {props.definition.id && <OptionsMenu deletePath={updateDefinitionPath} />}
        </HStack>

        {props.slack_alerts.length ? (
          <SlackAlerts id={props.definition.id!} alerts={props.slack_alerts} />
        ) : (
          <SlackAlertAd id={props.definition.id!} />
        )}

        <Stack p="4" pl="0" borderWidth={'thin'} rounded="md" spacing="4">
          <Heading pl="4" size="xs">
            Volume (90d)
          </Heading>

          <LineGraph
            period="quarter"
            label={'Signals'}
            data={trend.map((day) => {
              return {
                timestamp: day.date,
                value: day.total
              }
            })}
            height={150}
            colorScheme={'purple'}
          />
        </Stack>

        <DefinitionForm {...props} />
      </Stack>
    </PageLayout>
  )
}

interface SlackAlertAdProps {
  id: string
}

function SlackAlertAd(props: SlackAlertAdProps) {
  return (
    <InfoBox icon={false}>
      <Stack spacing={3.5}>
        <HStack>
          <SlackIcon alignSelf="flex-start" boxSize={5} flex="none" />
          <Text fontWeight="medium" fontSize="13px">
            Want to get alerted in real time?
          </Text>
        </HStack>
        <Text>
          You can subscribe to intent signals with Slack Alerts to get real-time notifications as this signal happens.
          Send to a Slack channel of your choice for each audience you want to hear about.
        </Text>
        <Button
          as={Link}
          href={slackAlertsPath(`/new?slack_alert[intent_triggers][]=${props.id}`)}
          alignSelf="flex-start"
          size="xs"
          variant="outline"
          rightIcon={<IconArrowRight size={14} />}
        >
          Create a Slack Alert
        </Button>
      </Stack>
    </InfoBox>
  )
}

interface SlackAlertsProps {
  alerts: SlackAlert[]
  id: string
}

const useSlackDrawerState = createPersistedState<boolean>('slack-alert-drawer')

function SlackAlerts(props: SlackAlertsProps) {
  const [isOpen, setSlackDrawerState] = useSlackDrawerState(true)

  const onToggle = React.useCallback(() => {
    setSlackDrawerState((prev) => !prev)
  }, [setSlackDrawerState])

  return (
    <LightBgCard bg="white" py={2} px={4}>
      <Stack width="100%" spacing={0}>
        <Flex alignItems="center" gap={2} p={4} my={-2} mx={-4} onClick={onToggle} cursor="pointer">
          <SlackIcon boxSize={5} flex="none" />
          <Text fontSize="sm">
            You have {props.alerts.length} Slack {pluralize('Alert', props.alerts.length)} subscribed to this intent
            signal.
          </Text>
          <IconButton
            aria-label="Expand/Collapse"
            icon={isOpen ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
            size="xs"
            variant="ghost"
            color="gray.500"
            flex="none"
            marginLeft="auto"
            tabIndex={0}
          />
        </Flex>
        {isOpen && (
          <Stack pl={7} pb={2} spacing={3}>
            <Stack spacing={0} divider={<Divider />}>
              {props.alerts.map((alert) => (
                <LinkBox key={alert.id}>
                  <Flex
                    as={LinkOverlay}
                    href={slackAlertPath(alert.id)}
                    width="100%"
                    gap={4}
                    alignItems="center"
                    paddingY={2}
                    role="group"
                  >
                    <HStack spacing={4}>
                      <Stack spacing={0.5}>
                        <HStack fontSize="sm">
                          <Text
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            color={alert.enabled ? undefined : 'gray.600'}
                            fontStyle={alert.enabled ? undefined : 'italic'}
                            fontWeight="medium"
                          >
                            #{alert.slack_channel_name || alert.slack_channel_id} / {audienceName(alert)}
                          </Text>
                        </HStack>
                        <HStack color="gray.600" fontSize="xs" spacing={1} divider={<MiddotDivider />}>
                          {!alert.enabled && <Text>Paused</Text>}
                          <Text>
                            Updated <TimeAgo time={alert.updated_at} />
                          </Text>
                          {alert.updated_by_user ? (
                            <Text>Updated by {alert.updated_by_user.name || alert.updated_by_user.email}</Text>
                          ) : alert.created_by_user ? (
                            <Text>Created by {alert.created_by_user.name || alert.created_by_user.email}</Text>
                          ) : null}
                          <Text>
                            {alert.any_intent ? 1 : alert.intent_triggers?.length} intent{' '}
                            {pluralize('trigger', alert.any_intent ? 1 : alert.intent_triggers?.length ?? 0)}
                          </Text>
                        </HStack>
                      </Stack>
                    </HStack>
                    <Button
                      marginLeft="auto"
                      flex="none"
                      size="xs"
                      variant="outline"
                      as={Link}
                      href={slackAlertPath(alert.id)}
                      visibility="hidden"
                      opacity={0.5}
                      _groupHover={{
                        opacity: 1,
                        visibility: 'visible'
                      }}
                      transition="opacity 150ms ease-in-out"
                    >
                      View
                    </Button>
                  </Flex>
                </LinkBox>
              ))}
            </Stack>
            <Button
              as={Link}
              href={slackAlertsPath(`/new?slack_alert[intent_triggers][]=${props.id}`)}
              alignSelf="flex-start"
              size="xs"
              variant="link"
              colorScheme="purple"
              leftIcon={<IconPlus size={12} />}
              iconSpacing={1}
            >
              New Slack Alert
            </Button>
          </Stack>
        )}
      </Stack>
    </LightBgCard>
  )
}
