import { Button, Flex, Heading, HStack, Icon, Img, Radio, RadioGroup, Stack, Tag, Text } from '@chakra-ui/react'
import { IconMap2, IconX } from '@tabler/icons-react'
import React, { useMemo } from 'react'
import { useData } from '../../../data/use-data'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { LightBgCard } from '../../../ui/Card'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import { usePermission } from '../../../ui/PermissionsContext'
import { projectPath } from '../../../ui/ProjectsContext'
import { ExcludedAccountsProps } from '../show'

export interface Country {
  code: string
  shortName: string
  region: string
}

export type GeoMode = 'all' | 'allow' | 'reject'

function CountrySelector({
  countries,
  hasPermission,
  managedCountries
}: {
  countries: Country[]
  hasPermission: boolean
  mode: GeoMode
  managedCountries?: string[]
}) {
  const [selected, setSelected] = React.useState<Country[]>(countries.filter((c) => managedCountries?.includes(c.code)))

  return (
    <Stack>
      <ComboboxWithSearch
        virtual
        estimateSize={() => 32}
        items={countries}
        filterItem={(a, val) => [a?.shortName, a?.code, a?.region].join('').toLowerCase().includes(val)}
        itemToString={(item) => item?.shortName ?? ''}
        selectedItem={null}
        selectButtonRenderer={() => (
          <HStack flex="1" fontSize={'sm'}>
            <Text fontSize="xs">Search for a Country or Region</Text>
          </HStack>
        )}
        itemRenderer={({ item }) => (
          <HStack w="100%">
            <HStack flex="1" fontSize={'sm'}>
              <Img
                w="3"
                src={`https://cdn.jsdelivr.net/gh/lipis/flag-icons/flags/4x3/${item.code.toLowerCase()}.svg`}
              />
              <Text fontSize="xs">{item.shortName}</Text>
            </HStack>
            <Tag size="sm" fontSize={'xx-small'} colorScheme="purple">
              {item.region}
            </Tag>
          </HStack>
        )}
        onChange={(e) => {
          setSelected((prev) => [...new Set(prev.concat(e ?? []))])
        }}
      />

      {selected.length > 0 && (
        <HStack flexWrap={'wrap'} gap="1.5" spacing="0">
          {selected.map((country) => (
            <Flex key={country.code}>
              <input type={'hidden'} name={'geo[country_codes][]'} value={country.code} />
              <Button
                variant="outline"
                size="xs"
                colorScheme={'gray'}
                bg="white"
                fontWeight={'normal'}
                disabled={!hasPermission}
                rightIcon={<Icon as={IconX} color="purple.500" strokeWidth={2} boxSize={3} />}
                leftIcon={
                  <Img
                    w="3"
                    src={`https://cdn.jsdelivr.net/gh/lipis/flag-icons/flags/4x3/${country.code.toLowerCase()}.svg`}
                  />
                }
                onClick={() => {
                  setSelected((prev) => prev.filter((c) => c.code !== country.code))
                }}
              >
                {country.shortName}
              </Button>
            </Flex>
          ))}
        </HStack>
      )}
    </Stack>
  )
}

export function HiddenGeosForm() {
  const props = useData<ExcludedAccountsProps>('geo', projectPath('/settings/excluded-accounts.json'))
  const { hasPermission, isLoading } = usePermission({ on: 'project', action: 'can_manage_website' })

  const countries: Country[] = useMemo(() => {
    return (props.data?.countries ?? []).map((c) => ({
      code: c[0],
      shortName: c[1],
      region: c[2]
    }))
  }, [props.data?.countries])

  if (props.isLoading || !props.data || isLoading) {
    return null
  }

  return (
    <form action={projectPath('/website-settings/geo')} method="POST">
      <AuthenticityToken />
      <HiddenGeos
        countries={countries}
        managedCountries={props.data.filters.geos?.filters.country_codes}
        hasPermission={hasPermission}
        mode={props.data.filters.geos?.filters.mode}
      />
    </form>
  )
}

export function HiddenGeos(props: {
  managedCountries?: string[]
  mode?: GeoMode
  hasPermission: boolean
  countries: Country[]
}) {
  const { hasPermission, countries } = props
  const [mode, setMode] = React.useState<GeoMode>(props.mode ?? 'all')

  return (
    <Stack p="6" spacing="4" bg="gray.50" rounded="lg" borderWidth={'1px'}>
      <Stack spacing="4">
        <HStack>
          <IconMap2 size="18" />
          <Heading size="sm" fontWeight={'semibold'}>
            Geographies
          </Heading>
        </HStack>

        <Text fontSize={'sm'}>
          Enter the countries for the companies you want to exclude. Or, choose to only allow traffic from specific
          countries.
        </Text>

        <RadioGroup
          as={LightBgCard}
          bg="white"
          value={mode}
          onChange={(e) => setMode(e as GeoMode)}
          size="sm"
          name="geo[mode]"
        >
          <Heading size="xs" pb="2">
            Geographic Restrictions
          </Heading>
          <Stack spacing="0.5" position="relative" overflow="hidden">
            <Radio bg="white" value="all">
              Allow all
            </Radio>
            <Radio bg="white" value="reject">
              Reject some
            </Radio>
            <Radio bg="white" value="allow">
              Allow some
            </Radio>
          </Stack>
        </RadioGroup>
      </Stack>

      {mode !== 'all' && (
        <CountrySelector
          countries={countries}
          hasPermission={hasPermission}
          mode={mode}
          managedCountries={props.managedCountries}
        />
      )}

      {mode === 'reject' && (
        <Text fontSize="xs">
          Note: Koala will ignore all traffic from the regions you select above. This means no intent interactions will
          be recorded for any visitor in one of the countries you select.
        </Text>
      )}

      {mode === 'allow' && (
        <Text fontSize="xs">
          Note: Koala will only allow traffic from the regions you select above. This means intent interactions will be
          recorded only for visitors in one of the countries you select.
        </Text>
      )}

      <Flex justifyContent={'flex-end'}>
        <Button colorScheme={'purple'} type="submit" disabled={!hasPermission}>
          Save
        </Button>
      </Flex>
    </Stack>
  )
}
