import React from 'react'
import { useMutation } from 'react-query'
import { toast } from 'sonner'
import { del } from '../../lib/api'
import { removeTemplateList } from '../pages/account_views/lib/remove-list-event'
import { projectPath } from '../ui/ProjectsContext'

interface Params {
  templateId: string
  viewId: string
}

export function useDeleteTemplateView() {
  const onSuccess = React.useCallback((_data: any, vars: Params) => {
    toast.success('Successfully deleted your template list.')
    removeTemplateList(vars.templateId, vars.viewId)
  }, [])

  const onError = React.useCallback(() => {
    toast.error('There was an issue deleting this template list.')
  }, [])

  return useMutation(
    ({ templateId, viewId }: Params) => {
      const path = projectPath(`/mission-control/templates/${templateId}/views/${viewId}`)
      return del(path)
    },
    {
      onSuccess,
      onError
    }
  )
}
