import { FacetFilters } from '..'
import { facetQueryString } from '../../../data/use-facets'
import { projectPath } from '../../../ui/ProjectsContext'

interface LinkableAccount {
  domain?: string
  company?: {
    domain?: string
  }
}

export function accountPath(account: LinkableAccount, path = '') {
  return accountDomainPath(account.company?.domain || account.domain, path)
}

export function accountDomainPath(domain: string | undefined | null, path = '') {
  if (domain === undefined || domain === null) {
    return ''
  }

  return projectPath(`/accounts/${domain}${path}`.replace('//', '/'))
}

export function accountsPath(facets?: FacetFilters) {
  let queryString = ''
  if (facets) {
    const parts = facetQueryString(facets)
    if (parts.length) {
      queryString = `?${parts.join('&')}`
    }
  }

  return projectPath(`/accounts${queryString}`)
}
