import { Badge, Flex, IconButton, Link, Spinner, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { IconList, IconPlus } from '@tabler/icons-react'
import React from 'react'
import { StaticList } from '../../types/StaticList'
import { useLists } from '../data/use-lists'
import { AddToListModal } from '../pages/lists/components/AddToListModal'
import Avatar from './Avatar'
import { DetailsCard } from './Card'
import { CardHeading } from './CardHeading'
import { projectPath } from './ProjectsContext'
import { TimeAgo } from './TimeAgo'
import { useUIState } from './useUIState'

interface ListsCardProps {
  kind: 'account' | 'profile'
  recordId: string
  initiallyCollapsed?: boolean
}

const emptyArray = []

export function ListsCard(props: ListsCardProps) {
  const [state, setState] = useUIState({
    stateKey: 'lists-card-state',
    defaultState: { isOpen: !props.initiallyCollapsed }
  })

  const { isLoading, data, refetch } = useLists({ kind: props.kind, recordId: props.recordId })

  const cardDisclosure = useDisclosure({
    defaultIsOpen: state.isOpen || !props.initiallyCollapsed,
    onOpen: () => {
      setState({ open: true })
    },
    onClose: () => {
      setState({ open: false })
    }
  })

  const lists = data?.lists ?? emptyArray
  const addToListModal = useDisclosure()

  return (
    <div>
      <DetailsCard>
        <CardHeading icon={IconList} disclosure={cardDisclosure}>
          <Flex flex="1" width="100%" alignItems="center" justifyContent="space-between" gap={2}>
            <Flex alignItems="center" gap={2}>
              <Text>Lists</Text>
              {lists.length > 0 && (
                <Badge variant="pill" flex="none" fontWeight="medium">
                  {lists.length.toLocaleString()}
                </Badge>
              )}
            </Flex>
            {isLoading && <Spinner size="xs" />}
            <IconButton
              variant="ghost"
              size="xs"
              marginLeft="auto"
              aria-label="Add to list"
              icon={<IconPlus size={16} />}
              color="gray.500"
              _hover={{ color: 'purple.600' }}
              onClick={(e) => {
                e.stopPropagation()
                addToListModal.onOpen()
              }}
            />
          </Flex>
        </CardHeading>

        {cardDisclosure.isOpen && lists.length > 0 && (
          <Stack fontSize="sm" spacing={2}>
            {lists.map((list: StaticList) => (
              <Stack
                rounded="md"
                spacing={1}
                py={2.5}
                px={3.5}
                border="1px solid"
                borderColor="gray.200"
                key={list.id}
                isTruncated
              >
                <Link
                  href={projectPath(`/lists/${list.id}`)}
                  fontSize="sm"
                  fontWeight="semibold"
                  // color="purple.500"
                  isTruncated
                >
                  {list.name}
                </Link>
                <Flex alignItems="center" gap={2} justifyContent="space-between">
                  <Flex alignItems="center" gap={1.5}>
                    <Avatar
                      size="16px"
                      src={list.created_by_user?.image}
                      name={list.created_by_user?.name || list.created_by_user?.email}
                    />
                    <Text fontSize="xs" fontWeight="medium" color="gray.500">
                      {list.created_by_user?.name || list.created_by_user?.email}
                    </Text>
                  </Flex>
                  <Text fontSize="xs" color="gray.500">
                    <TimeAgo time={list.created_at} />
                  </Text>
                </Flex>
              </Stack>
            ))}
          </Stack>
        )}
      </DetailsCard>

      <AddToListModal {...addToListModal} recordId={props.recordId} kind={props.kind} onSuccess={refetch} />
    </div>
  )
}
