import { Box, Grid, Heading, HStack, Stack, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import type { App } from '../../../../types/App'
import { AppActions } from '../../../../types/AppActions'
import { PersonRecord } from '../../../../types/Person'
import { Company } from '../../../../types/Profile'
import { DetailsCard } from '../../../ui/Card'
import { CardHeading } from '../../../ui/CardHeading'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { StackedField } from '../../../ui/StackedField'
import { TimeAgo } from '../../../ui/TimeAgo'
import { humanize } from '../../accounts/facets/filter-cloud'
import { useUIState } from './useUIState'

interface ClearbitCardProps {
  app: App
  actions?: AppActions
  record?: PersonRecord
  initiallyCollapsed?: boolean
  onRequestImport?: (app: App) => void
}

export function ClearbitCard(props: ClearbitCardProps) {
  const [ui, setUI] = useUIState()
  const cardDisclosure = useDisclosure({
    defaultIsOpen: ui.open?.clearbit ?? !props.initiallyCollapsed,
    onOpen: () => {
      setUI({ open: { clearbit: true } })
    },
    onClose: () => {
      setUI({ open: { clearbit: false } })
    }
  })

  return (
    <DetailsCard>
      <CardHeading icon={props.app.logo} disclosure={cardDisclosure}>
        <HStack justifyContent="space-between">
          <Text>Clearbit</Text>
        </HStack>
      </CardHeading>
      {!cardDisclosure.isOpen ? null : props.record ? (
        <Stack spacing={4}>
          <StackedField label="Email">{props.record.email}</StackedField>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <StackedField label="Name">{props.record.name?.full_name || props.record.name?.given_name}</StackedField>
            <StackedField label="Location">
              {[props.record.geo?.city, props.record.geo?.country, props.record.geo?.country]
                .filter(Boolean)
                .join(', ')}
            </StackedField>

            <StackedField label="Last Synced">
              <TimeAgo time={props.record.updated_at} mode="calendar" />
            </StackedField>
          </Grid>
        </Stack>
      ) : (
        <Stack>
          <Text fontSize="xs" color="gray.500">
            No Clearbit person found or enriched yet
          </Text>
        </Stack>
      )}
    </DetailsCard>
  )
}

export interface ClearbitRevealCache {
  ip: string
  payload: {
    confidenceScore: 'low' | 'medium' | 'high'
    role: string | null
    seniority: string | null

    geoIP: {
      city?: string
      state?: string
      stateCode?: string
      country?: string
      countryCode?: string
    }
    company?: Company
  }
  updated_at: string
}

interface ClearbitAnonymousCardProps {
  app: App
  actions?: AppActions
  record?: ClearbitRevealCache
  initiallyCollapsed?: boolean
  onRequestImport?: (app: App) => void
}

export function ClearbitAnonymousCard(props: ClearbitAnonymousCardProps) {
  const [ui, setUI] = useUIState()
  const cardDisclosure = useDisclosure({
    defaultIsOpen: ui.open?.clearbit ?? !props.initiallyCollapsed,
    onOpen: () => {
      setUI({ open: { clearbit: true } })
    },
    onClose: () => {
      setUI({ open: { clearbit: false } })
    }
  })

  const record = props.record

  return (
    <DetailsCard>
      <CardHeading icon={props.app.logo} disclosure={cardDisclosure}>
        <HStack justifyContent="space-between">
          <Text>Clearbit</Text>
        </HStack>
      </CardHeading>
      {!cardDisclosure.isOpen ? null : record ? (
        <Stack spacing={4}>
          <StackedField label="IP">{record.ip}</StackedField>
          <HStack w="100%">
            <Box w="100%" flex="1">
              <StackedField
                label={
                  <HStack spacing="1">
                    <Text>Role</Text>
                    <HelpTooltip size="12px" mode="popover" placement="right-end">
                      <Stack>
                        <Heading size="xs">Role</Heading>
                        <Text fontSize="xs">
                          Clearbit attemps to guess the person's role when deanonymizing an IP. This can be particularly
                          useful when prospecting into an account.
                        </Text>
                      </Stack>
                    </HelpTooltip>
                  </HStack>
                }
              >
                {record.payload.role && humanize(record.payload.role)}
              </StackedField>
            </Box>
            <Box w="100%" flex="1">
              <StackedField
                label={
                  <HStack spacing="1">
                    <Text>Seniority</Text>
                    <HelpTooltip size="12px" mode="popover" placement="right-end">
                      <Stack>
                        <Heading size="xs">Seniority</Heading>
                        <Text fontSize="xs">
                          Clearbit attemps to guess the person's seniority when deanonymizing an IP. This can be
                          particularly useful when prospecting into an account.
                        </Text>
                      </Stack>
                    </HelpTooltip>
                  </HStack>
                }
              >
                {record.payload.seniority && humanize(record.payload.seniority)}
              </StackedField>
            </Box>
          </HStack>

          <HStack w="100%">
            <Box w="100%" flex="1">
              <StackedField
                label={
                  <HStack spacing="1">
                    <Text>Confidence Score</Text>
                    <HelpTooltip size="12px" mode="popover" placement="right-end">
                      <Stack>
                        <Heading size="xs">Confidence Score</Heading>
                        <Text fontSize="xs">
                          This is the score Clearbit assigns to the deanonymization of the IP. The higher the score, the
                          more confident Clearbit is about the deanonymization.
                        </Text>
                      </Stack>
                    </HelpTooltip>
                  </HStack>
                }
              >
                {humanize(record.payload.confidenceScore)}
              </StackedField>
            </Box>

            <Box w="100%" flex="1">
              <StackedField label="Matched">
                <TimeAgo time={record.updated_at} />
              </StackedField>
            </Box>
          </HStack>

          <StackedField label="IP Geo Location">
            {[record.payload.geoIP?.city, record.payload.geoIP?.state, record.payload.geoIP?.country]
              .filter(Boolean)
              .join(', ')}
          </StackedField>
        </Stack>
      ) : (
        <Stack>
          <Text fontSize="xs" color="gray.500">
            No Clearbit person found or enriched yet
          </Text>
        </Stack>
      )}
    </DetailsCard>
  )
}
