import { Text, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { Account } from '../../../../types/Account'

export function AccountDescription(props: Account) {
  const overflow = useDisclosure()
  const description = props.company.description

  if (!description) {
    return null
  }

  return (
    <Text
      fontSize="sm"
      color="gray.600"
      cursor="pointer"
      onClick={overflow.onToggle}
      noOfLines={overflow.isOpen ? undefined : 3}
    >
      {description}
    </Text>
  )
}
