import React from 'react'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { AdminBreadcrumb } from '../admin'
import { CompanySearch } from './components/CompanySearch'

export default function Index(_props: any) {
  return (
    <PageLayout>
      <AdminBreadcrumb paths={[{ path: '/admin/kdb', title: 'Company Search' }]} />

      <PageTitle>Company Search</PageTitle>

      <CompanySearch />
    </PageLayout>
  )
}
