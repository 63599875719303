import {
  AvatarGroup,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalProps,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { IconDotsVertical, IconEdit, IconPlus, IconTrash } from '@tabler/icons-react'
import * as React from 'react'
import router from '../../../lib/router'
import Avatar from '../../ui/Avatar'
import { Territory } from '../../../types/Territory'
import { DeleteConfirmation } from '../../ui/DeleteConfirmation'
import EmptyState from '../../ui/EmptyState'
import { MiddotDivider } from '../../ui/Middot'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { usePermission } from '../../ui/PermissionsContext'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'

interface IndexProps {
  territories: Territory[]
}

export default function Index(props: IndexProps) {
  const { hasPermission: canManageMembers } = usePermission({ on: 'project', action: 'can_manage_members' })

  return (
    <PageLayout size="sm" gap={0}>
      <SettingsBreadCrumb paths={[{ path: projectPath('/territories'), title: 'Territories' }]} offscreen />

      <SettingsHeader>
        <Flex alignItems="center" justifyContent="space-between">
          <PageTitle>Territories</PageTitle>
          {canManageMembers && (
            <Button
              as={Link}
              href={projectPath('/territories/new')}
              iconSpacing={1}
              leftIcon={<IconPlus size={16} />}
              marginLeft="auto"
              alignSelf="center"
              size="sm"
              flex="none"
              colorScheme="purple"
            >
              New territory
            </Button>
          )}
        </Flex>
        <PageDescription>
          Create territories to make it easy to find accounts or people within specific boundaries. You can use
          ownership fields, geographies, company sizes and more to define a territory.
        </PageDescription>
      </SettingsHeader>

      {props.territories.length > 0 ? (
        <Stack spacing={4}>
          {props.territories.map((territory) => (
            <TerritoryRow key={territory.id} territory={territory} canManageMembers={canManageMembers} />
          ))}
        </Stack>
      ) : (
        <Box maxW="75%" mx="auto">
          <EmptyState
            size="md"
            heading="No territories created yet."
            description="Create a territory for you or your team to get started."
            icon={IconPlus}
            ctaText="Create a territory"
            onClick={() => router.visit(projectPath('/territories/new'))}
          />
        </Box>
      )}
    </PageLayout>
  )
}

interface TerritoryRowProps {
  canManageMembers: boolean
  territory: Territory
}

function TerritoryRow({ canManageMembers, territory }: TerritoryRowProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      gap={3}
      rounded="md"
      border="1px solid"
      borderColor="gray.200"
      paddingY={2}
      paddingX={3}
    >
      <Flex flex={1} alignItems="center" gap={2.5}>
        <Box>
          <Link href={projectPath(`/territories/${territory.id}`)} fontSize="sm">
            {territory.name}
          </Link>
          <HStack color="gray.500" fontSize="xs" spacing={1.5} divider={<MiddotDivider />}>
            {territory.created_by && (
              <Text isTruncated maxW="200px">
                Created by {territory.created_by.name || territory.created_by.email}
              </Text>
            )}
          </HStack>
        </Box>
      </Flex>

      {(territory.assignees ?? []).length > 0 && (
        <AvatarGroup size="xs" fontSize="xs" max={3} spacing={-2}>
          {territory.assignees?.map((assignee) => (
            <Avatar
              key={assignee.email}
              src={assignee.image}
              name={assignee.name || assignee.email}
              borderWidth="2px"
              boxSizing="content-box"
            />
          ))}
        </AvatarGroup>
      )}

      <Button size="sm" variant="outline" as="a" href={projectPath(`/territories/${territory.id}`)}>
        View
      </Button>

      {canManageMembers && (
        <Box textAlign="right">
          <Menu placement="bottom-end">
            <MenuButton size="xs" as={IconButton} icon={<IconDotsVertical size={16} />} variant="ghost" />
            <MenuList fontSize="sm" zIndex="popover">
              <MenuItem
                as={Link}
                href={projectPath(`/territories/${territory.id}`)}
                icon={<IconEdit size="16" />}
                onClick={() => {}}
              >
                Edit
              </MenuItem>
              <MenuItem icon={<IconTrash size="16" />} color="red.500" onClick={onOpen}>
                Delete…
              </MenuItem>
            </MenuList>
          </Menu>
          <DeleteTerritory territory={territory} isOpen={isOpen} onClose={onClose} />
        </Box>
      )}
    </Flex>
  )
}

interface DeleteTerritoryProps extends Pick<ModalProps, 'isOpen' | 'onClose'> {
  territory: Territory
}

function DeleteTerritory(props: DeleteTerritoryProps) {
  return (
    <DeleteConfirmation
      title={`Delete "${props.territory.name}" Territory?`}
      isOpen={props.isOpen}
      onClose={props.onClose}
      deletePath={projectPath(`/territories/${props.territory.id}`)}
      confirmLabel="Delete territory"
    >
      <Text fontSize="sm" color="gray.700">
        This will delete the "{props.territory.name}" territory. You will no longer be able to use this territory in
        views/lists.
      </Text>
    </DeleteConfirmation>
  )
}
