import { Button, Heading, HStack, Icon, Skeleton, Stack, Text } from '@chakra-ui/react'
import { IconAlertCircle } from '@tabler/icons-react'
import React from 'react'
import { formatNumber } from '../../../../lib/number-format'
import { AnalyticsData } from '../../../data/use-dashboard-widget'
import { formatShortDuration } from '../../analytics/show'

export interface MetricSummaryProps {
  title: string
  isLoading: boolean
  unit?: string
  data?: AnalyticsData['data']
  error?: Error
  onRetry?: () => void
}

export function MetricSummary(props: MetricSummaryProps) {
  return (
    <Stack justifyContent={'center'} alignItems="center">
      <Heading size="xs" color="gray.500">
        {props.title}
      </Heading>
      {!props.error && props.isLoading && <Skeleton height="32px" width="100%" />}
      {props.error && (
        <Stack p="1">
          <HStack spacing="1">
            <Icon as={IconAlertCircle} color="yellow.500" />
            <Text fontSize="xs">Failed to load data</Text>
          </HStack>
          {props.onRetry && (
            <Button size="xs" variant={'outline'} onClick={props.onRetry} isLoading={props.isLoading}>
              Retry
            </Button>
          )}
        </Stack>
      )}
      {props.data && (
        <>
          <Heading size="md" fontWeight={'semibold'}>
            {props.unit === 'seconds' && formatShortDuration(props.data.total!)}

            {props.unit === 'currency' &&
              new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
              }).format(props.data.total!)}

            {props.unit !== 'seconds' && props.unit !== 'currency' && formatNumber(props.data.total!)}
          </Heading>
        </>
      )}
    </Stack>
  )
}
