import { User } from '@app/components/ui/UserContext'
import { del, post } from '@app/lib/api'
import { Button, Flex, Heading, HStack, IconButton, Stack, Text } from '@chakra-ui/react'
import { IconTrash } from '@tabler/icons-react'
import * as React from 'react'
import { useMutation } from 'react-query'
import { toast } from 'sonner'

interface AlternateEmailListProps {
  user: Partial<User>
  showHeader: boolean
  showPrimaryEmail: boolean
  allowDeletion: boolean
}

export function AlternateEmailsList(props: AlternateEmailListProps) {
  const [destroying, setDestroying] = React.useState({})
  const [resent, setResent] = React.useState({})

  const resendConfirmationEmail = useMutation((email_id: string) => {
    return post('/alternate_emails/confirmation', {
      id: email_id
    })
  })

  const destroyAltEmail = React.useCallback((email_id) => {
    setDestroying((prev) => ({ ...prev, [email_id]: true }))
    return del(`/alternate_emails/${email_id}`).then(() => {
      window.location.reload()
    })
  }, [])

  const resendConfirmationEmailHandler = React.useCallback(
    (emailId: string) => {
      resendConfirmationEmail
        .mutateAsync(emailId)
        .then(() => {
          setResent((prev) => ({ ...prev, [emailId]: true }))
          toast.success('Confirmation Email re-sent', {
            description: 'Please click the link in your inbox to confirm this email.'
          })
        })
        .catch(() => {
          toast.error('Failed to send confirmation email', {
            description: 'Something is not quite right, please reach out to support at support@getkoala.com.'
          })
        })
    },
    [resendConfirmationEmail]
  )
  if (!props.user.alternate_emails || props.user.alternate_emails?.length == 0) {
    return <></>
  }

  return (
    <Stack w={'100%'} spacing={'2'}>
      {props.showHeader && <Heading size={'sm'}>Alternate Emails</Heading>}
      {props.showPrimaryEmail && (
        <Flex
          border={'1px'}
          borderRadius={'4'}
          paddingY={2}
          paddingX={3}
          bgColor={'gray.50'}
          borderColor={'gray.100'}
          fontSize={'sm'}
          alignItems={'center'}
        >
          <Text fontWeight="medium">
            {props.user.email} (
            <Text as="span" fontSize="xs">
              Primary
            </Text>
            )
          </Text>
        </Flex>
      )}
      {props.user.alternate_emails?.map((alt_email) => {
        return (
          <Flex
            borderWidth="1px"
            rounded="md"
            paddingY={2}
            paddingLeft={3}
            paddingRight={2}
            bg={alt_email.confirmed ? 'gray.50' : 'white'}
            borderColor={alt_email.confirmed ? 'gray.200' : 'gray.300'}
            borderStyle={alt_email.confirmed ? 'solid' : 'dashed'}
            key={alt_email.email}
            fontSize={'sm'}
            alignItems={'center'}
          >
            <input type="hidden" name="user[alternate_emails_attributes][][id]" value={alt_email.id} />
            <input type="hidden" name="user[alternate_emails_attributes][][email]" value={alt_email.email} />
            <Text fontWeight="medium">
              {alt_email.email}
              {!alt_email.confirmed && (
                <Text ml={2} as="span" fontSize="xs" color="orange.500">
                  Pending confirmation
                </Text>
              )}
            </Text>
            <HStack ml={'auto'}>
              {!alt_email.confirmed && (
                <Button
                  size={'xs'}
                  isDisabled={resent[alt_email.id]}
                  isLoading={resendConfirmationEmail.isLoading}
                  onClick={() => resendConfirmationEmailHandler(alt_email.id)}
                  variant="ghost"
                  colorScheme="purple"
                >
                  Resend Confirmation
                </Button>
              )}

              {props.allowDeletion && (
                <IconButton
                  size="xs"
                  variant={'ghost'}
                  icon={<IconTrash size={16} />}
                  isLoading={destroying[alt_email.id]}
                  onClick={() => destroyAltEmail(alt_email.id)}
                  aria-label={'Delete Alternate Email'}
                />
              )}
            </HStack>
          </Flex>
        )
      })}
    </Stack>
  )
}
