import * as React from 'react'

export function useFirstMountState(): boolean {
  const isFirstRender = React.useRef(true)

  if (isFirstRender.current) {
    isFirstRender.current = false
    return true
  }

  return isFirstRender.current
}
