import { useQuery } from 'react-query'
import { concurrentGET } from '../../lib/api'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'
import { KQLDefinition } from '../../types/KQL'

interface FollowRuleData {
  id: string
  kql_definition_id: string
  name: string
  target_type: string
  any_intent: boolean
  any_signal: boolean
}
interface ResponseData {
  slack_alert_id: string
  intent_signals: Record<string, KQLDefinition>
  active_follow_rules: FollowRuleData[]
  granular_stats: Record<string, number>
  granular_timestamps: Record<string, string>
}
export function useSlackAlertStats(slackAlertId: string, enabled = false) {
  const project = useCurrentProject()

  return useQuery<ResponseData>(
    ['slackAlertStats', { projectId: project?.id, slackAlertId }],
    () => concurrentGET<ResponseData>(projectPath(`/slack-alerts/${slackAlertId}/stats`)),
    { enabled: Boolean(project?.id && slackAlertId && enabled) }
  )
}
