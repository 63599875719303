import { Box, Flex, Stack } from '@chakra-ui/react'
import React from 'react'
import { HealthChecklist, Skips, WebsiteSettings } from '../../context/instrumentation-context'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { ProjectChecklist } from './components/project-checklist'
import { Message, Stream } from './components/stream'

interface Props {
  checks: HealthChecklist
  website_settings: WebsiteSettings | null
  website_skips: Skips | null

  stream: {
    messages: Message[]
    pv: Message[]
    form: Message[]
    ev: Message[]
    identify: Message[]
    assoc: Message[]
    errors: Message[]
  }
}

export default function Show(props: Props) {
  return (
    <PageLayout size="full" flush>
      <Flex w="100%" alignItems="flex-start" flexWrap="wrap">
        <Stack flex="1" maxW="container.xl" minWidth="500px" p={[6, 6, 8, 8]} marginX="auto" spacing="4">
          <SettingsBreadCrumb paths={[{ title: 'Developer', path: projectPath('/instrumentation') }]} offscreen />
          <SettingsHeader>
            <PageTitle>Developer Checklist</PageTitle>
            <PageDescription>Manage your Koala instrumentation across your web properties.</PageDescription>
          </SettingsHeader>

          <ProjectChecklist {...props} />
        </Stack>

        <Box flex="none" maxW="100%" position="sticky" top="0" height="var(--content-height)">
          <Stream {...props} />
        </Box>
      </Flex>
    </PageLayout>
  )
}
