import { Button, Grid, Heading, HStack, IconButton, Link, Stack, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import { IconExternalLink } from '@tabler/icons-react'
import React from 'react'
import type { App } from '../../../../types/App'
import { PersonRecord } from '../../../../types/Person'
import { ProfileRecord } from '../../../../types/Profile'
import { DetailsCard } from '../../../ui/Card'
import { CardHeading } from '../../../ui/CardHeading'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { StackedField } from '../../../ui/StackedField'
import { TimeAgo } from '../../../ui/TimeAgo'
import { useUIState } from './useUIState'

interface HubspotCardProps {
  app: App
  record?: PersonRecord
  profile?: ProfileRecord
  permalink?: string
  recordType: 'Contact'
  initiallyCollapsed?: boolean
  isImporting?: boolean
  onRequestImport?: (app: App) => void
}

export function HubspotCard(props: HubspotCardProps) {
  const [ui, setUI] = useUIState()
  const cardDisclosure = useDisclosure({
    defaultIsOpen: ui.open?.hubspot ?? !props.initiallyCollapsed,
    onOpen: () => {
      setUI({ open: { hubspot: true } })
    },
    onClose: () => {
      setUI({ open: { hubspot: false } })
    }
  })

  return (
    <DetailsCard>
      <CardHeading icon={props.app.logo} disclosure={cardDisclosure}>
        <HStack justifyContent="space-between">
          <Text>HubSpot {props.recordType}</Text>
          {props.permalink && (
            <Tooltip label="View in HubSpot" placement="top" hasArrow arrowSize={6}>
              <IconButton
                aria-label="View in HubSpot"
                as={Link}
                href={props.permalink}
                isExternal
                size="xs"
                variant="link"
                icon={<IconExternalLink size={16} />}
                color="gray.500"
                _hover={{ color: 'purple.600' }}
                onClick={() => {
                  window.ko?.track('HubSpot Visit Action', {
                    app: 'hubspot',
                    record: props.record
                  })
                }}
              />
            </Tooltip>
          )}
        </HStack>
      </CardHeading>
      {!cardDisclosure.isOpen ? null : props.record ? (
        <Stack spacing={4}>
          <StackedField label="Email">{props.record.email}</StackedField>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <StackedField label="Name">{props.record.name?.full_name || props.record.name?.given_name}</StackedField>
            <StackedField label="Location">
              {[props.record.geo?.city, props.record.geo?.country, props.record.geo?.country]
                .filter(Boolean)
                .join(', ')}
            </StackedField>

            <StackedField label="Owner">{props.record.crm_owner_name}</StackedField>

            <StackedField label="Last Change Detected">
              <TimeAgo time={props.record.updated_at} mode="calendar" />
            </StackedField>

            <StackedField label="Last Successful Sync">
              <TimeAgo time={props.record.last_finished_integration_sync?.stopped_at} mode="calendar" />
              {props.record.last_finished_integration_sync && (
                <HelpTooltip>
                  <Stack>
                    <Heading size="xs">Last Successful Sync</Heading>
                    <Text>
                      Koala last pulled your Outreach account for changes{' '}
                      <TimeAgo time={props.record.last_finished_integration_sync?.started_at} />.
                    </Text>
                  </Stack>
                </HelpTooltip>
              )}
            </StackedField>
          </Grid>
        </Stack>
      ) : props.app?.connected ? (
        <Stack>
          <Text fontSize="xs" color="gray.500">
            No contacts linked yet
          </Text>
          {props.profile?.email && props.app.actions?.hs_import_contacts?.enabled && props.onRequestImport && (
            <Button
              variant="outline"
              width="full"
              size="sm"
              isLoading={props.isImporting}
              onClick={() => {
                window.ko?.track('Clicked HubSpot Import Contact', {
                  app: 'hubspot',
                  record: props.record
                })
                props.onRequestImport?.(props.app!)
              }}
            >
              Create contact
            </Button>
          )}
        </Stack>
      ) : null}
    </DetailsCard>
  )
}
