import React from 'react'
import { Apps } from '../../../types/App'
import { Territory } from '../../../types/Territory'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { TerritoryForm } from './components/TerritoryForm'

interface Props {
  apps: Apps
  territory: Territory
  errors?: {
    [key: string]: string[]
  }
}

export default function NewTerritory(props: Props) {
  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        paths={[
          { path: projectPath('/territories'), title: 'Territories' },
          { path: window.location.toString(), title: 'New Territory' }
        ]}
      />

      <SettingsHeader divider={false}>
        <PageTitle size="md">Create a new territory</PageTitle>
        <PageDescription>
          Create a territory to make it easy to prospect or view accounts within your team's patch.
        </PageDescription>
      </SettingsHeader>

      <TerritoryForm {...props} />
    </PageLayout>
  )
}
