import { Badge, Box, Button, Flex, Heading, HStack, Icon, IconButton, Square, Stack, Text } from '@chakra-ui/react'
import { IconChevronRight, IconX } from '@tabler/icons-react'
import React from 'react'
import createPersistedState from 'use-persisted-state'
import { Account } from '../../../../types/Account'
import { DetailsCard } from '../../../ui/Card'
import { AiSparklesIcon } from '../../../ui/icons'
import { projectPath } from '../../../ui/ProjectsContext'

const prospectorCtaVisible = createPersistedState<boolean>('koala:prospector-cta-visible')

export function ProspectorCTA(props: { account: Account }) {
  const [visible, setVisible] = prospectorCtaVisible(true)

  if (!visible) {
    return null
  }

  return (
    <DetailsCard position="relative" p={0} gap={0}>
      <Flex
        alignItems="flex-start"
        gap={4}
        px={6}
        py={6}
        zIndex={1}
        bgGradient="linear(109.6deg, rgba(245, 239, 249, 0.6) 30.1%, rgba(207, 211, 236, 0.4) 100.2%)"
        rounded="md"
        margin="4px"
      >
        <Square padding={2} border="1px solid" borderColor="gray.200" bg="white" shadow="sm" rounded="md">
          <AiSparklesIcon color="accent.500" boxSize={6} />
        </Square>
        <Stack flex="1 1 auto" spacing={1}>
          <HStack>
            <Heading size="sm" lineHeight={1.5}>
              Prospector
            </Heading>
            <Badge colorScheme="purple">Beta</Badge>
          </HStack>

          <Text fontSize="sm" color="gray.600" maxW="550px">
            Find contact data for top prospects from {props.account.company.name ?? props.account.company.domain}.<br />{' '}
            Discover your buyer personas and find your best path to multithread the account.
          </Text>

          <Box paddingTop={5}>
            <Button
              as="a"
              href={projectPath(`/prospector?domains[]=${props.account.company.domain}`)}
              flex="none"
              alignSelf="flex-start"
              size="md"
              variant="outline"
              colorScheme="lightPurple"
              iconSpacing={1.5}
              rightIcon={<Icon as={IconChevronRight} boxSize={4} />}
            >
              Start prospecting
            </Button>
          </Box>
        </Stack>
      </Flex>

      <IconButton
        aria-label="Dismiss"
        size="xs"
        position="absolute"
        variant="ghost"
        colorScheme="purple"
        zIndex={1}
        top={3}
        right={3}
        icon={<Icon as={IconX} boxSize={4} />}
        onClick={() => setVisible(false)}
      />
    </DetailsCard>
  )
}
