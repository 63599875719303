import { Box } from '@chakra-ui/react'
import { IconCircle } from '@tabler/icons-react'
import * as React from 'react'
import { green } from '../ui/colors'
import Avatar, { AvatarProps } from './Avatar'

export interface StatusAvatarProps extends AvatarProps {
  name?: string | null
  email?: string | null
  image?: string | null
  status?: 'online' | 'offline' | 'away'
  hideStatus?: boolean
  statusPosition?: 'top' | 'bottom'
}

export const avatarColor = (status?: 'online' | 'offline' | 'away') => {
  if (status === 'online') {
    return green
  }

  if (status === 'away') {
    return 'yellow.500'
  }

  return 'gray.200'
}

export default React.forwardRef(function StatusAvatar(
  { name, email, image, borderColor = 'white', hideStatus, statusPosition, ...props }: StatusAvatarProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <Avatar
      ref={ref}
      name={name || email}
      src={image}
      {...props}
      badgePosition={statusPosition}
      badge={
        hideStatus || !props.status ? undefined : (
          <Box
            color={avatarColor(props.status)}
            backgroundColor={borderColor}
            border={`2px solid ${borderColor}`}
            borderRadius="100%"
          >
            <IconCircle size="100%" fill="currentColor" />
          </Box>
        )
      }
    />
  )
})
