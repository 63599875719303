import React from 'react'
import { useMutation, UseMutationOptions } from 'react-query'
import { toast } from 'sonner'
import { post } from '../../lib/api'
import { ProfileRecord } from '../../types/Profile'
import { profilePath } from '../pages/profiles/lib/path'

interface Params {
  id: string
  app: string
  personKey: string
}

interface Props extends Omit<UseMutationOptions<Data, unknown, Params>, 'onSuccess' | 'onError'> {
  onSuccess?: (profile: ProfileRecord) => void
  onError?: (error: unknown) => void
}

interface Data {
  profile: ProfileRecord
}

export function useEnrichProfile({ onSuccess, onError, ...rest }: Props = {}) {
  const handleSuccess: UseMutationOptions<Data, unknown, Params>['onSuccess'] = React.useCallback(
    (data, variables) => {
      onSuccess?.(data.profile)

      if (data.profile[variables.personKey]) {
        toast.success('Successfully enriched profile!')
      } else {
        toast.message('No data found', {
          description: 'We could not find enriched data for this profile.'
        })
      }
    },
    [onSuccess]
  )

  const handleError: UseMutationOptions<Data, unknown, Params>['onError'] = React.useCallback(
    (error) => {
      onError?.(error)
      toast.error('Failed to enrich profile', {
        description: error?.body && Object.keys(error.body).length > 0 ? JSON.stringify(error.body) : undefined
      })
    },
    [onError]
  )

  return useMutation<Data, unknown, Params>(
    (params) => {
      const path = profilePath(params, `/actions/enrich?app=${params.app}`)
      return post<Data>(path)
    },
    {
      ...rest,
      onSuccess: handleSuccess,
      onError: handleError
    }
  )
}
