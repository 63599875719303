import { Heading, HStack, Stack, Text } from '@chakra-ui/react'
import { IconAlertCircle } from '@tabler/icons-react'
import React from 'react'
import { GrayCard } from '../../ui/Card'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'

export default function Error() {
  return (
    <PageLayout size="md">
      <SettingsBreadCrumb paths={[{ title: 'UTM Reports', path: projectPath('/utm-reports') }]} />
      <SettingsHeader>
        <HStack justifyContent="space-between" alignItems="flex-start">
          <Stack>
            <PageTitle>UTM Reports</PageTitle>

            <PageDescription>
              Here you can see the traffic sources of your website, including all the UTM parameters.
            </PageDescription>
          </Stack>
        </HStack>
      </SettingsHeader>

      <Stack as={GrayCard}>
        <HStack>
          <IconAlertCircle />
          <Heading size="md" fontWeight={'semibold'}>
            Report not ready
          </Heading>
        </HStack>
        <Text fontSize="sm">UTM Reports are generated on an hourly basis. Please check back later.</Text>
      </Stack>
    </PageLayout>
  )
}
