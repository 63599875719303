import {
  Badge,
  Box,
  Code,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Img,
  Input,
  Link,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconArrowUpRight, IconCopy } from '@tabler/icons-react'
import React, { useCallback } from 'react'
import { toast } from 'sonner'
import { LightBgCard } from '../../../ui/Card'
import { Copyable } from '../../../ui/Copyable'
import PageDescription from '../../../ui/PageDescription'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { projectPath } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import { StepIcon } from '../../../ui/StepIcon'
import { useCopyToClipboard } from '../../../ui/useCopyToClipboard'
import { Introduction } from '../components/Introduction'
import rb2bKoalaIntegration from './assets/rb2b_koala_integration.png'

interface Props {
  title: string
  description: string
  logo: string
  settings: Record<string, string | null | undefined>
  deps: {
    api_key: string
  }
}

export default function Show(props: Props) {
  const url = `https://api.getkoala.com/apps/rb2b/ingest/${props.deps.api_key}`
  const { copy } = useCopyToClipboard()

  const copyToClipboard = useCallback(
    (url: string) => {
      copy(url)
      toast(`Copied webhook URL to clipboard`)
    },
    [copy]
  )

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/rb2b'),
            title: 'RB2B'
          }
        ]}
        offscreen
      />

      <Box>
        <HStack>
          <Image src={props.logo} maxW="6" />
          <PageTitle>{props.title}</PageTitle>
          <Badge colorScheme={'purple'}>Beta</Badge>
        </HStack>

        <PageDescription>
          {props.description} Koala will receive events from RB2B via this webhook. Identities will be merged via the
          Koala Identity Graph.
        </PageDescription>
      </Box>

      <Introduction
        app="RB2B"
        icon={props.logo}
        description="The RB2B integration allows you to send visitors identified via RB2B into Koala. Once in Koala, we'll join those visitors with the rest of our identity graph so you can see all of their activity and signal data in one place."
      />

      <LightBgCard gap={6} p={5}>
        <Heading size="sm">Connect RB2B to Koala</Heading>

        <Stack fontSize="sm">
          <HStack alignItems="flex-start">
            <StepIcon step={1} />
            <Text>
              Log in to RB2B and navigate to the{' '}
              <Link variant="dotted" href="https://app.rb2b.com/integrations" isExternal>
                integrations page
                <Icon as={IconArrowUpRight} boxSize={4} color="gray.500" />
              </Link>
            </Text>
          </HStack>
          <HStack alignItems="flex-start">
            <StepIcon step={2} />
            <Text>
              Select the <strong>Koala</strong> integration tile
            </Text>
          </HStack>
          <HStack alignItems="flex-start">
            <StepIcon step={3} />
            <Text>
              Copy your{' '}
              <Code fontSize=".9em">
                <Copyable inline copyText={url}>
                  webhook URL
                </Copyable>
              </Code>{' '}
              and paste it into the <strong>Webhook URL</strong> field in RB2B
            </Text>
          </HStack>

          <Stack spacing={2} pt={4}>
            <Text>Here's your webhook URL</Text>
            <HStack spacing={1}>
              <Input size="sm" readOnly bg="gray.50" w="100%" defaultValue={url} />
              <IconButton
                onClick={() => copyToClipboard(url)}
                aria-label="Copy"
                icon={<IconCopy size="16" />}
                size="sm"
                variant={'ghost'}
              />
            </HStack>
          </Stack>

          <Stack spacing={2} pt={4}>
            <Text>Here's what the RB2B integration page looks like:</Text>
            <Img src={rb2bKoalaIntegration} />
          </Stack>
        </Stack>
      </LightBgCard>
    </PageLayout>
  )
}
