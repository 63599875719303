import { useQuery } from 'react-query'
import { concurrentGET } from '../../lib/api'
import { useCurrentProject } from '../ui/ProjectsContext'

export function useData<Data>(id: string, path: string) {
  const project = useCurrentProject()

  return useQuery<Data>([id, { projectId: project?.id }], () => concurrentGET<Data>(path), {
    enabled: Boolean(project?.id)
  })
}
