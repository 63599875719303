import { AuthenticityToken } from '@app/components/ui/AuthenticityToken'
import { ConfigurableAppActions } from '@app/components/ui/ConfigurableAppActions'
import PageDescription from '@app/components/ui/PageDescription'
import PageTitle from '@app/components/ui/PageTitle'
import { AppActions } from '@app/types/AppActions'
import { Box, Button, Divider } from '@chakra-ui/react'
import React from 'react'
import PageLayout from '../../ui/PageLayout'

interface ProjectActions {
  [appModule: string]: AppActions
}

interface Props {
  actions: ProjectActions
  update_path: string
}

export default function Show(props: Props) {
  return (
    <PageLayout size="sm">
      <form action={props.update_path} method="POST">
        <AuthenticityToken />
        <input type="hidden" name="_method" value="PUT" />
        <PageTitle size="lg">Actions</PageTitle>
        <PageDescription>Configure what actions are enabled and visible</PageDescription>
        {Object.entries(props.actions).map(([appModule, appActions], i) => {
          if (Object.entries(appActions).length == 0) {
            return
          }
          return (
            <React.Fragment key={appModule}>
              <Box marginY={'5'}>
                <ConfigurableAppActions appActions={appActions} appModule={appModule} />
              </Box>
              {i < Object.entries(props.actions).length - 1 && <Divider />}
            </React.Fragment>
          )
        })}
        <Button type="submit" width="100%" variant="solid" colorScheme="purple">
          Save
        </Button>
      </form>
    </PageLayout>
  )
}
