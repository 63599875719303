import PageLayout from '@app/components/ui/PageLayout'
import { Button, Flex, Heading, HStack, Link, Stack, Text, useDisclosure } from '@chakra-ui/react'
import * as React from 'react'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import { openPricingForm, openUpgradeFlow } from '../../ui/billing-banners/accounts-banner'
import { projectPath, useCurrentProject } from '../../ui/ProjectsContext'
import { ConfirmDeletion } from './components/ConfirmDeletion'

function PilotEnded(props: { can_destroy: boolean }) {
  const project = useCurrentProject()
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (!project) {
    return null
  }

  return (
    <PageLayout size="full">
      <Flex
        direction="column"
        alignItems="center"
        alignSelf="center"
        gap={8}
        width="100%"
        maxWidth={500}
        paddingTop="100px"
        paddingBottom="40px"
        marginX="auto"
      >
        <Heading size="xl" fontWeight="semibold">
          Koala Pilot Ended
        </Heading>
        <Text textAlign="center" color="gray.600">
          Your Koala pilot has ended. If you'd like to continue using Koala, please send and email to{' '}
          <Link href="mailto:support@getkoala.com" isExternal fontWeight="semibold">
            support@getkoala.com
          </Link>{' '}
          with your hopes and dreams, and we'll work to get you up and running!
        </Text>
        <Stack spacing="2">
          <Button colorScheme="purple" onClick={() => openUpgradeFlow('business')}>
            Talk to Sales
          </Button>
          <HStack>
            <Button colorScheme="purple" onClick={() => openPricingForm()} variant="outline">
              Request Trial Extension
            </Button>

            {props.can_destroy && (
              <Flex>
                <Button variant={'outline'} colorScheme={'red'} onClick={onOpen}>
                  Delete Workspace…
                </Button>
                <ConfirmDeletion isOpen={isOpen} onClose={onClose} />
              </Flex>
            )}
          </HStack>
        </Stack>
      </Flex>
    </PageLayout>
  )
}

function InactiveWorkspace(props: { can_destroy: boolean }) {
  const project = useCurrentProject()
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (!project) {
    return null
  }

  return (
    <PageLayout size="full">
      <Flex
        direction="column"
        alignItems="center"
        alignSelf="center"
        gap={8}
        width="100%"
        maxWidth={500}
        paddingTop="100px"
        paddingBottom="40px"
        marginX="auto"
      >
        <Heading size="lg" fontWeight="medium">
          Your Workspace is Inactive
        </Heading>
        <Text textAlign="center" color="gray.600">
          We haven't seen any user activity or data flowing in your workspace for a while, so your workspace has been
          put on standby. You can reactivate it by re-enabling it and starting to send data again.
        </Text>
        <Flex gap={3} as="form" action={projectPath('/lockout-extension')} method="POST">
          <AuthenticityToken />
          <Button flex="1 1 50%" colorScheme="purple" type="submit">
            Enable Workspace
          </Button>
          {props.can_destroy && (
            <>
              <Button variant={'outline'} colorScheme={'red'} onClick={onOpen} px="8">
                Delete Workspace…
              </Button>
              <ConfirmDeletion isOpen={isOpen} onClose={onClose} />
            </>
          )}
        </Flex>
      </Flex>
    </PageLayout>
  )
}

export default function FullLockdown(props: { can_destroy: boolean; reason?: 'inactivity' | 'pilot_ended' }) {
  if (props.reason === 'inactivity') {
    return <InactiveWorkspace can_destroy={props.can_destroy} />
  }

  return <PilotEnded can_destroy={props.can_destroy} />
}
