import { VStack } from '@chakra-ui/react'
import React from 'react'
import type { Subscription } from '../../../types/AccountView'
import PageLayout from '../../ui/PageLayout'
import { projectPath } from '../../ui/ProjectsContext'
import ViewDetails from '../account_views/components/ViewDetails'
import { AccountViewShowProps } from '../account_views/show'
import IntegrationCard from './components/IntegrationCard'
import { SubscriptionSidesheet } from './components/SubscriptionSidesheet'

interface Props extends AccountViewShowProps {
  app?: {
    app_id: string
    title: string
    logo: string
    deps: { [key: string]: any }
    connected: boolean
    valid: boolean
  }
  subscriptions: Subscription[]
  subscription?: Subscription
}

export default function Index(props: Props) {
  return (
    <>
      <PageLayout size="full">
        <ViewDetails
          apps={props.apps}
          counts={props.counts}
          account_view={props.account_view}
          permissions={props.permissions}
        />
        <VStack align="stretch" spacing={4}>
          <IntegrationCard
            title="Slack"
            description="Post a Slack message when new companies match this list."
            logo="https://cdn.worldvectorlogo.com/logos/slack-new-logo.svg"
            editUrl={projectPath(`/views/${props.account_view.slug}/subscriptions/slack`)}
            subscription={props.subscriptions.find((s) => s.app_instance?.app_module === 'Apps::Slack::App')}
          />
        </VStack>
      </PageLayout>

      {props.permissions.can_edit && props.app && props.subscription && (
        <SubscriptionSidesheet app={props.app} subscription={props.subscription} view={props.account_view} />
      )}
    </>
  )
}
