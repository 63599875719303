import { Button, Heading, HStack, IconButton, LinkBox, LinkOverlay, Stack, Text } from '@chakra-ui/react'
import { IconExternalLink, IconNotebook } from '@tabler/icons-react'
import React from 'react'
import { LightBgCard } from '../../../ui/Card'
import { HeroImage } from '../components/HeroImage'
import { Step } from '../components/SetupProject'
import { StepProps } from '../rep_onboarding'
import img from './docs.png'

export function RepGuides(props: StepProps) {
  return (
    <Step cardProps={{ maxW: 800 }} {...props}>
      <HeroImage src={img} h="180px" />
      <Stack p="8" pt="0" spacing="8">
        <Stack>
          <Heading size="lg">Learn Koala Best Practices</Heading>
          <Text fontSize={'sm'} color="gray.500">
            Discover how to use Koala to identify the most promising accounts, track what your prospects are exploring,
            and discover the best path to closing deals.
          </Text>
          <Text fontSize={'sm'} color="gray.500">
            We've gathered our best practices here!
          </Text>
        </Stack>

        <Stack spacing={'4'} fontSize="sm" minH="250">
          <LightBgCard
            as={LinkBox}
            padding={4}
            _hover={{
              bg: 'purple.50',
              borderColor: 'purple.400'
            }}
          >
            <HStack w="100%" justifyContent={'space-between'}>
              <HStack spacing="4">
                <IconButton colorScheme={'purple'} icon={<IconNotebook />} aria-label="Warm Outbound Mindset" />
                <LinkOverlay
                  fontWeight={'500'}
                  href="https://getkoala.com/docs/rep-guides/warm-outbound-mindset"
                  isExternal
                >
                  Warm Outbound Mindset
                </LinkOverlay>
              </HStack>
              <IconButton
                size="sm"
                variant={'ghost'}
                colorScheme="purple"
                icon={<IconExternalLink size={20} />}
                aria-label="link"
              />
            </HStack>
          </LightBgCard>

          <LightBgCard
            as={LinkBox}
            padding={4}
            _hover={{
              bg: 'purple.50',
              borderColor: 'purple.400'
            }}
          >
            <HStack w="100%" justifyContent={'space-between'}>
              <HStack spacing="4">
                <IconButton
                  colorScheme={'purple'}
                  icon={<IconNotebook />}
                  aria-label="Using Koala for Account Research"
                />
                <LinkOverlay
                  fontWeight={'500'}
                  href="https://getkoala.com/docs/rep-guides/use-koala-for-research"
                  isExternal
                >
                  Using Koala for Account Research
                </LinkOverlay>
              </HStack>
              <IconButton
                size="sm"
                variant={'ghost'}
                colorScheme="purple"
                icon={<IconExternalLink size={20} />}
                aria-label="link"
              />
            </HStack>
          </LightBgCard>

          <LightBgCard
            as={LinkBox}
            padding={4}
            _hover={{
              bg: 'purple.50',
              borderColor: 'purple.400'
            }}
          >
            <HStack w="100%" justifyContent={'space-between'}>
              <HStack spacing="4">
                <IconButton colorScheme={'purple'} icon={<IconNotebook />} aria-label="Sending Value-Add Outreach" />
                <LinkOverlay
                  fontWeight={'500'}
                  href="https://getkoala.com/docs/rep-guides/send-great-sales-messages"
                  isExternal
                >
                  Sending Value-Add Outreach
                </LinkOverlay>
              </HStack>
              <IconButton
                size="sm"
                variant={'ghost'}
                colorScheme="purple"
                icon={<IconExternalLink size={20} />}
                aria-label="link"
              />
            </HStack>
          </LightBgCard>
        </Stack>

        <Stack>
          <Button colorScheme={'purple'} onClick={props.onContinue}>
            Continue
          </Button>
        </Stack>
      </Stack>
    </Step>
  )
}
