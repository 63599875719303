import { Heading, Stack } from '@chakra-ui/react'
import React from 'react'
import { ErrorPage } from './components/ErrorPage'

export default function InternalServerError() {
  return (
    <ErrorPage
      title={
        <Stack>
          <Heading size="4xl">500</Heading>
          <Heading as="h1" size="lg">
            Internal Server Error
          </Heading>
        </Stack>
      }
      description="Oops, something went wrong, please try again later"
    />
  )
}
