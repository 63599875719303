import { Grid, HStack, Stack, Text, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import type { App } from '../../../../types/App'
import { AppActions } from '../../../../types/AppActions'
import { PersonRecord } from '../../../../types/Person'
import { DetailsCard } from '../../../ui/Card'
import { CardHeading } from '../../../ui/CardHeading'
import { StackedField } from '../../../ui/StackedField'
import { TimeAgo } from '../../../ui/TimeAgo'
import { useUIState } from './useUIState'

interface ZoominfoCardProps {
  app: App
  actions?: AppActions
  record?: PersonRecord
  initiallyCollapsed?: boolean
  onRequestImport?: (app: App) => void
}

export function ZoominfoCard(props: ZoominfoCardProps) {
  const [ui, setUI] = useUIState()
  const cardDisclosure = useDisclosure({
    defaultIsOpen: ui.open?.zoominfo ?? !props.initiallyCollapsed,
    onOpen: () => {
      setUI({ open: { zoominfo: true } })
    },
    onClose: () => {
      setUI({ open: { zoominfo: false } })
    }
  })

  return (
    <DetailsCard>
      <CardHeading icon={props.app.logo} disclosure={cardDisclosure}>
        <HStack justifyContent="space-between">
          <Text>ZoomInfo</Text>
        </HStack>
      </CardHeading>
      {!cardDisclosure.isOpen ? null : props.record ? (
        <Stack spacing={4}>
          <StackedField label="Email">{props.record.email}</StackedField>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <StackedField label="Name">{props.record.name?.full_name || props.record.name?.given_name}</StackedField>
            <StackedField label="Location">
              {[props.record.geo?.city, props.record.geo?.country, props.record.geo?.country]
                .filter(Boolean)
                .join(', ')}
            </StackedField>

            <StackedField label="Last Synced">
              <TimeAgo time={props.record.updated_at} mode="calendar" />
            </StackedField>
          </Grid>
        </Stack>
      ) : (
        <Stack>
          <Text fontSize="xs" color="gray.500">
            No ZoomInfo person found or enriched yet
          </Text>
        </Stack>
      )}
    </DetailsCard>
  )
}
