import { Button, Center, Flex, Heading, Img, Spinner, Stack, Text } from '@chakra-ui/react'
import React, { useCallback, useMemo } from 'react'
import { useApp } from '../../../../data/use-app'
import { useIntentSignals } from '../../../../data/use-intent-signals'
import { PopupConnectDialog } from '../../../apps/components/ConnectOauthAppDialog'
import { ReconnectionRequiredWarning } from '../../../apps/components/ReconnectionRequiredWarning'
import { FollowRule } from '../../../notifications'
import { channelLogos, isChannelActive } from '../delivery-setup'
import { WrapTask } from '../wrap-task'
import { AddToCampaign } from './add-to-campaign'

export interface InstantlySetupProps {
  delivery_rules: FollowRule['delivery_rules']
  actions?: {
    add_to_campaign?: boolean
  }
  kqlDefinitionIds?: string[]
}

export interface InstantlyActionProps {
  deps: any
  delivery_rules: FollowRule['delivery_rules']
  setDeliveryRules: (rules: FollowRule['delivery_rules']) => void
}

export function InstantlySetup(props: InstantlySetupProps) {
  const instantly = useApp('Instantly')
  const disconnected = useMemo(() => !instantly.data?.connected, [instantly.data])
  const invalid = useMemo(() => !instantly.data?.valid, [instantly.data])
  const [deliveryRules, setDeliveryRules] = React.useState(props.delivery_rules)
  const active = isChannelActive(props.delivery_rules, 'instantly')

  const signals = useIntentSignals()

  const kqlDefinitions = useMemo(() => {
    if (!props.kqlDefinitionIds) {
      return undefined
    }

    const allDefs = signals.data?.definitions ?? []
    return allDefs.filter((def) => def.id && props.kqlDefinitionIds?.includes(def.id))
  }, [signals.data?.definitions, props.kqlDefinitionIds])

  const actions = useMemo(() => {
    if (props.actions) {
      return props.actions
    }

    return {
      add_to_campaign: true
    }
  }, [props.actions])

  const onConnected = useCallback(() => {
    instantly.refetch()
  }, [instantly])

  if (instantly.isLoading) {
    return <Spinner />
  }

  if (instantly.data && (disconnected || invalid)) {
    return (
      <Center w="100%">
        <Flex py="8">
          {instantly.data && (disconnected || invalid) && (
            <PopupConnectDialog app_id={'instantly'} onConnected={onConnected} active={active}>
              {({ onStart }) => (
                <Stack spacing="4">
                  <Stack spacing="0">
                    <Center>
                      <Heading size="sm">Connect your Instantly Account</Heading>
                    </Center>
                    <Center>
                      <Text fontSize="sm" color="gray.600">
                        Please connect your Instantly account in order to get started.
                      </Text>
                    </Center>
                  </Stack>
                  <Center>
                    <Button
                      leftIcon={<Img w="4" src={channelLogos.instantly} />}
                      size="sm"
                      variant={'outline'}
                      onClick={onStart}
                    >
                      Connect Instantly
                    </Button>
                  </Center>
                </Stack>
              )}
            </PopupConnectDialog>
          )}
        </Flex>
      </Center>
    )
  }

  if (!instantly.data) {
    return null
  }

  return (
    <Stack spacing="8" py="8">
      <Stack spacing="8">
        {invalid && <ReconnectionRequiredWarning appTitle={'Instantly'} variant="short" />}

        {actions.add_to_campaign && (
          <WrapTask
            defaultIsOpen
            name={<Text>Add to Campaign</Text>}
            enabled={deliveryRules?.instantly?.add_to_campaign?.enabled}
          >
            <AddToCampaign
              deps={instantly}
              delivery_rules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              kqlDefinitions={kqlDefinitions}
            />
          </WrapTask>
        )}
      </Stack>

      {props.delivery_rules?.instantly && (
        <input type="hidden" name="follow_rule[delivery_rules][instantly][trigger]" value="immediate" />
      )}
    </Stack>
  )
}
