import { useClickable } from '@chakra-ui/clickable'
import { Box, Flex, Icon, Image, Text } from '@chakra-ui/react'
import { Icon as TablerIcon } from '@tabler/icons-react'
import React from 'react'
import * as ReactIs from 'react-is'
import { humanize } from './filter-cloud'

interface Props {
  sectionKey: string
  icon?: JSX.Element | TablerIcon | string | typeof Icon
  badge?: JSX.Element
  count?: number
  children?: React.ReactNode
  disabled?: boolean
  onClick: React.MouseEventHandler<HTMLElement>
}

const MenuItem = ({ sectionKey, icon, badge, count, onClick, disabled, children }: Props) => {
  const isChildString = typeof children === 'string'
  const clickableProps = useClickable({ onClick, isDisabled: disabled })
  const label = isChildString ? children : humanize(sectionKey)

  let iconElement: null | React.ReactElement = null
  if (typeof icon === 'string') {
    iconElement = <Image src={icon} boxSize="18px" />
  } else if (ReactIs.isValidElementType(icon)) {
    iconElement = React.createElement(icon, { size: 18 })
  } else if (React.isValidElement(icon)) {
    iconElement = React.cloneElement(icon, { size: 18 } as any)
  }

  return (
    <Box
      {...clickableProps}
      _hover={{ backgroundColor: 'purple.50', color: 'purple.700' }}
      _focus={{ backgroundColor: 'purple.50', color: 'purple.700' }}
      _active={{ backgroundColor: 'purple.50', color: 'purple.700' }}
      _disabled={{ opacity: 0.5, cursor: 'default', _hover: { backgroundColor: 'transparent', color: 'gray.600' } }}
      transition="all 200ms cubic-bezier(0, 0, 0.2, 1)"
      paddingY={2.5}
      paddingX={3}
      rounded="md"
      outline="none"
      width="100%"
      userSelect="none"
      color="gray.600"
    >
      <Flex width="100%" gap={2.5} alignItems="center">
        {iconElement && (
          <Box display="flex" flexShrink={0}>
            {iconElement}
          </Box>
        )}
        <Flex alignItems="center" gap={2} isTruncated fontSize="sm" lineHeight={1.2}>
          <Text title={label} fontWeight="medium" isTruncated>
            {label}
          </Text>
          {count ? (
            <Text flex="none" fontSize="sm" color="purple.600">
              {count.toLocaleString()}
            </Text>
          ) : null}
        </Flex>
        {badge}
      </Flex>
    </Box>
  )
}

export default MenuItem
