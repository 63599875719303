import { ErrorPage } from '@app/components/pages/errors/components/ErrorPage'
import { Button, Heading, Link, List, ListItem, Stack } from '@chakra-ui/react'
import * as React from 'react'

interface AlternateEmailConfirmationShowProps {
  email: string
  errors: string[]
}

export default function Show(props: AlternateEmailConfirmationShowProps) {
  return (
    <ErrorPage
      title={
        <Stack>
          <Heading as="h1" size="lg">
            Errors while confirming alternate email {props.email}
          </Heading>
        </Stack>
      }
      body={
        <Stack>
          <List mb={'6'}>
            {props.errors.map((e, i) => {
              return <ListItem key={i}>{e}.</ListItem>
            })}
          </List>

          <Button as={Link} href={'/me'}>
            Go to your Koala profile
          </Button>
        </Stack>
      }
    />
  )
}
