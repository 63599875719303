import { useQuery } from 'react-query'
import { concurrentGET } from '../../lib/api'
import { RepOnboardingProps } from '../pages/welcome/rep_onboarding'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

export function useRepOnboarding() {
  const project = useCurrentProject()

  const basePath = `/welcome/rep`
  const path = projectPath(basePath)

  return useQuery<RepOnboardingProps>(
    ['rep-onboarding', { projectId: project?.id }],
    () => concurrentGET<RepOnboardingProps>(path),
    {
      enabled: Boolean(project?.id)
    }
  )
}
