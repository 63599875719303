import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Link,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react'
import { IconBolt, IconCalendarTime, IconPlus, IconSelector, IconWorldWww } from '@tabler/icons-react'
import { groupBy, orderBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import dayjs from '../../../../../lib/dayjs'
import { fillMissingDays } from '../../../../../lib/fill-missing-days'
import { KQLByDay } from '../../../../../types/KQL'
import { DetailsCard } from '../../../../ui/Card'
import { Iconify } from '../../../../ui/Iconify'
import { IntentBolt } from '../../../../ui/IntentBolt'
import { TimeAgo } from '../../../../ui/TimeAgo'
import { useOverflow } from '../../../../ui/useOverflow'
import { LineGraph } from '../../../analytics/components/LineGraph'
import { TimeseriesData } from '../../../analytics/components/timeseries'
import { IntentScore, Trend } from '../../../icps/types'
import { SignalType } from '../../../kql_definitions/components/SignalType'
import { kqlDefinitionsPath } from '../../../kql_definitions/path-helpers'
import { KqlDefinition } from '../../../kql_definitions/types'
import { IntentBarChart } from '../Intent/IntentBarChart'
import { IntentTrendLabel } from '../Intent/IntentSummary'

export const formatDuration = (value: number): string | [string, string] => {
  if (!value) {
    return ['—'] as any as [string, string]
  }

  const duration = dayjs.duration(value, 'milliseconds')
  let formatted: string
  if (duration.hours() > 0) {
    formatted = duration.format('H [hour] m[m]')
  } else if (duration.minutes() > 10) {
    formatted = duration.format('m [minutes]')
  } else if (duration.minutes() > 0) {
    formatted = duration.format('m[m] s[s]')
  } else {
    formatted = `< 1 minute`
  }

  return [formatted] as any as [string, string]
}

const formatNumber = (value: number): [string, string] => {
  return [value?.toLocaleString() || ''] as any as [string, string]
}

const formatShortDuration = (value: number) => {
  const duration = dayjs.duration(value, 'milliseconds')
  const format: string[] = []

  if (duration.hours() > 0) {
    format.push('H[h]')
  } else if (duration.minutes() > 0) {
    format.push('m[m]')
  } else {
    format.push('s[s]')
  }

  return duration.format(format.join(' '))
}

interface ActivityCardProps {
  focusTrend?: Trend
  pageviewTrend?: Trend
  intent?: IntentScore
  signalsByDay: KQLByDay[]
  signalDefns: KqlDefinition[]
  colorScheme?: string
  stacked?: boolean
  onSelectSignal?: (signalId: string) => void
}

const icons = {
  focus_time: IconCalendarTime,
  page_views: IconWorldWww,
  intent_score: IconBolt
}

const labels = {
  focus_time: 'Active Session Time',
  page_views: 'Page Views',
  intent_score: 'Intent Score'
}

export function ActivityCard(props: ActivityCardProps) {
  const [mode, setMode] = useState(props.intent ? 'intent_score' : 'page_views')
  const start = dayjs.utc().subtract(30, 'days').format('YYYY-MM-DD')
  const end = dayjs.utc().format('YYYY-MM-DD')
  const total = useMemo(() => {
    return (props.signalsByDay || []).reduce((sum, day) => sum + day.count, 0)
  }, [props.signalsByDay])

  const signalsByDay = useMemo(() => {
    return (props.signalsByDay || []).map((s) => ({
      ...s,
      definition: props.signalDefns.find((d) => d.id === s.kql_definition_id)
    }))
  }, [props.signalsByDay, props.signalDefns])

  const signalStats: IntentSignalStat[] = useMemo(() => {
    // group all the daily signals by definition
    const stats = groupBy(signalsByDay, 'kql_definition_id')

    // count the total, get the max timestamp for each definition
    return Object.entries(stats).map(([id, days]) => {
      const total = days.reduce((sum, day) => sum + day.count, 0)

      return {
        kql_definition_id: id,
        count: total,
        last_triggered_at: days[0]?.last_triggered_at
      }
    })
  }, [signalsByDay])

  const trends = useMemo(() => {
    switch (mode) {
      case 'focus_time':
        return props.focusTrend?.trends || []
      case 'page_views':
        return props.pageviewTrend?.trends || []
      default:
        return []
    }
  }, [mode, props.focusTrend, props.pageviewTrend])

  const timeseries: TimeseriesData[] = React.useMemo(
    () =>
      fillMissingDays(trends, start, end, 'value').map((data) => ({
        timestamp: data.day,
        value: data.value,
        stats: orderBy(
          signalsByDay.filter((day) => day.day === data.day),
          'count',
          'desc'
        ),
        totalSignals: total
      })),
    [trends, start, end, signalsByDay, total]
  )

  let stacked = useBreakpointValue({ base: true, xl: false })
  stacked = props.stacked ?? stacked

  return (
    <DetailsCard p={0}>
      <Flex direction={stacked ? 'column-reverse' : 'row'} alignItems="stretch" gap={0}>
        <Flex
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          flex="1 1 auto"
          width={stacked ? '100%' : '50%'}
          padding={4}
          borderRightWidth={stacked ? 0 : '1px'}
          borderTopWidth={stacked ? '1px' : 0}
        >
          <IntentSignalLegend
            signalStats={signalStats}
            total={total}
            definitions={props.signalDefns}
            onSelectSignal={props.onSelectSignal}
          />
        </Flex>

        <Flex direction="column" flex="0 1 auto" width={stacked ? '100%' : '50%'} padding={4} gap={4}>
          <Flex
            flex="none"
            justifyContent="space-between"
            alignItems="center"
            paddingX={2}
            fontSize="sm"
            fontWeight="medium"
          >
            <HStack
              spacing={1}
              onClick={() => {
                if (mode === 'intent_score') {
                  setMode('page_views')
                } else if (mode === 'page_views') {
                  setMode('focus_time')
                } else {
                  setMode(props.intent ? 'intent_score' : 'page_views')
                }
              }}
              mx={-2}
              px={2}
              py={1}
              borderWidth="1px"
              borderColor="transparent"
              rounded="lg"
              cursor="pointer"
              _hover={{ borderColor: 'gray.200', shadow: 'sm' }}
            >
              <Iconify icon={icons[mode]} size={18} />
              <Text pl={1}>{labels[mode]}</Text>
              <Box flex="none" color="gray.500">
                <IconSelector size={14} />
              </Box>
            </HStack>
            <Text fontSize="xs" color="gray.500">
              Past 30 days
            </Text>
          </Flex>

          {mode === 'intent_score' && props.intent ? (
            <Box position="relative" width="100%">
              <Box position="absolute" right={0} top={0} py={1} px={1.5}>
                <IntentTrendLabel intent={props.intent} showLabel showAnyTrend size="sm" />
              </Box>
              <IntentBarChart data={props.intent.trend ?? []} dataKey="score" height={150} xAxis yAxis tooltip />
            </Box>
          ) : (
            <LineGraph
              period="month"
              label={labels[mode]}
              data={timeseries}
              height={150}
              yAxis={mode === 'intent_score' ? false : undefined}
              formatter={mode === 'focus_time' ? formatDuration : formatNumber}
              yFormatter={mode === 'focus_time' ? formatShortDuration : undefined}
              dot={<IntentSignalDot />}
              // customTooltip={<IntentSignalTooltip colorScheme={props.colorScheme} />}
              colorScheme={props.colorScheme}
            />
          )}
        </Flex>
      </Flex>
    </DetailsCard>
  )
}

// TODO wire this back up?
function _IntentSignalTooltip({
  active,
  payload,
  label,
  contentStyle,
  wrapperStyle,
  labelFormatter,
  formatter,
  colorScheme
}: any) {
  if (!active || !payload) {
    return null
  }

  const filteredPayload = payload.filter((item: any) => item.type !== 'none')
  const total = filteredPayload.reduce((result, entry) => result + entry.value, 0)

  if (active && filteredPayload.length > 0) {
    const stats = filteredPayload.find((p) => p?.payload?.stats)?.payload?.stats || []

    return (
      <Box {...wrapperStyle}>
        <Box bg="white" p={3} maxWidth="300px" {...contentStyle}>
          <Text>{labelFormatter?.(label) || label}</Text>
          <Text color={`${colorScheme || 'purple'}.500`}>{formatter?.(total, label) || total}</Text>

          {stats.length > 0 && (
            <Box mt={4}>
              {stats.map((stat) => (
                <IntentSignalItem
                  key={`${stat.definition?.id}-${stat.day}`}
                  size="sm"
                  name={stat.definition?.name}
                  type={stat.definition?.signal_type}
                  count={stat.count}
                  id={stat.definition?.id}
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
    )
  }

  return null
}

function IntentSignalDot(props: any) {
  const { key, cx, cy, payload } = props
  let size = 16
  let half = size / 2

  const dayTotal = (payload.stats || []).reduce((sum, s) => sum + s.count, 0)

  if ((100 * dayTotal) / payload.totalSignals > 8) {
    return (
      <svg width={size} height={size} x={cx - half} y={cy - half}>
        <g>
          <circle fill="#F2994A" strokeWidth="1.5" stroke="white" cx={half} cy={half} r={half - 1} />

          <IconBolt
            key={key}
            size={size - 4}
            x={2}
            y={2}
            fill="white"
            color="white"
            strokeWidth={0}
            viewBox="0 0 24 24"
          />
        </g>
      </svg>
    )
  }

  if (dayTotal > 0) {
    size = 7
    half = size / 2

    return (
      <svg width={size} height={size} x={cx - half} y={cy - half}>
        <g>
          <circle fill="currentColor" strokeWidth="1.5" stroke="white" cx={half} cy={half} r={half - 1} />
        </g>
      </svg>
    )
  }

  return null
}

interface IntentSignalLegendProps {
  signalStats: IntentSignalStat[]
  definitions: KqlDefinition[]
  total: number
  onSelectSignal?: (signalId: string) => void
}

interface IntentSignalStat {
  kql_definition_id: string
  count: number
  last_triggered_at?: string
}

interface IntentSignalSummary {
  id: string
  name: string
  type?: string
  count: number
  last_triggered_at?: string
}

function IntentSignalLegend({ signalStats, total, definitions, onSelectSignal }: IntentSignalLegendProps) {
  const showAll = useDisclosure()
  const { scrollRef, overflowBottom } = useOverflow()

  const noSignals = definitions.length === 0

  const ordered = React.useMemo(() => {
    const summaries = signalStats.map((stat) => {
      const definition = definitions.find((defn) => defn.id === stat.kql_definition_id)

      return {
        id: stat.kql_definition_id,
        name: definition?.name || 'Unnamed Intent Signal',
        type: definition?.signal_type,
        count: stat.count,
        last_triggered_at: stat.last_triggered_at
      }
    })

    const all = {
      id: 'all',
      name: 'All Intent Signals',
      count: summaries.reduce((total, next) => total + next.count, 0)
    }

    return [all].concat(orderBy(summaries, 'count', 'desc')) as IntentSignalSummary[]
  }, [definitions, signalStats])

  return (
    <Box
      ref={scrollRef}
      position="relative"
      width="100%"
      height="100%"
      minHeight={noSignals ? '150px' : undefined}
      {...(!showAll.isOpen &&
        total > 0 && {
          overflow: 'hidden',
          maxHeight: '220px',
          marginBottom: ordered.length >= 5 ? '-16px' : undefined
        })}
    >
      {ordered.map((signal) => {
        const all = signal.id === 'all'
        const colorize = all && signal.count > 0

        return (
          <IntentSignalItem
            key={signal.id}
            id={signal.id}
            name={signal.name}
            type={signal.type}
            colorize={colorize}
            count={signal.count}
            ts={signal.last_triggered_at}
            onSelectSignal={all ? undefined : onSelectSignal}
          />
        )
      })}

      {overflowBottom && !showAll.isOpen && !noSignals && (
        <Box
          position="absolute"
          left={0}
          right={0}
          bottom={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingTop={10}
          paddingBottom={2}
          bgGradient="linear(to-b, rgba(255,255,255,0.25), #FFFFFF)"
        >
          <Button variant="outline" size="xs" paddingX={3} bg="white" onClick={showAll.onToggle}>
            Show all
          </Button>
        </Box>
      )}

      {noSignals && (
        <Center position="absolute" top={0} bottom={0} right={0} left={0} bg="rgba(255, 255, 255, 0.6)">
          <Stack textAlign="center" alignItems="center" spacing={4} maxWidth="220px" paddingTop={4}>
            <Text fontSize="sm" color="gray.600">
              Looks like you don't have any Intent Signals defined yet.
            </Text>
            <Button
              as={Link}
              href={kqlDefinitionsPath('/new')}
              leftIcon={<IconPlus size={14} />}
              size="xs"
              variant="outline"
            >
              Create Intent Signal
            </Button>
          </Stack>
        </Center>
      )}
    </Box>
  )
}

interface IntentSignalItemProps {
  id: string
  name: string
  type?: string
  ts?: string
  count: number
  size?: 'sm' | 'md'
  colorize?: boolean
  onSelectSignal?: (signalId: string) => void
}

function IntentSignalItem({ id, name, type, ts, size = 'md', count, colorize, onSelectSignal }: IntentSignalItemProps) {
  const selectable = id && typeof onSelectSignal === 'function'

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      gap={size === 'sm' ? 2 : 3}
      width="100%"
      paddingY={size === 'sm' ? 1 : 2}
      paddingX={size === 'sm' ? 0 : 2}
      rounded="lg"
      bg={colorize ? 'orange.50' : undefined}
      _hover={selectable ? { bg: 'background.light' } : undefined}
      cursor={selectable ? 'pointer' : undefined}
      onClick={selectable ? () => onSelectSignal(id) : undefined}
    >
      <Flex flex="1 1 auto" alignItems="center" fontSize="xs" gap={2} overflow="hidden">
        <SignalType
          label={name}
          signalType={type}
          colorScheme={colorize ? 'orange' : undefined}
          marginRight={1}
          compact
          color={colorize ? 'orange.700' : undefined}
          isTruncated
          fontSize="xs"
          flexShrink={1}
          minW="100px"
        />

        {ts && (
          <Text color="gray.500" whiteSpace="nowrap">
            <TimeAgo time={ts} canToggle={false} />
          </Text>
        )}
      </Flex>

      <IntentBolt count={count} variant={colorize ? 'solid' : 'subtle'} />
    </Flex>
  )
}
