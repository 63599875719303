import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  CloseButton,
  Divider,
  Flex,
  Heading,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Select,
  SkeletonText,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconFilter } from '@tabler/icons-react'
import { orderBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import { FilterAggs } from '../../data/use-prospects'
import TagsInput from '../../ui/TagInput'
import { Facet, NotFacet } from '../accounts'
import { humanize } from '../accounts/facets/filter-cloud'
import { SearchBar } from '../accounts/facets/search-bar'
import { Operator } from '../personas/persona-filters'

interface SimpleFilterProps {
  name: string | React.ReactNode
  aggs: FilterAggs
  selected: string[]
  operator?: Operator
  onChange: (selected: string[], operator: Operator) => void
  isLoading?: boolean
  triggerProps?: ButtonProps
  hideCounts?: boolean
}

interface KeywordFilterProps {
  name: string
  selected: string[]
  onChange: (selected: string[]) => void
  triggerProps?: ButtonProps
}

export function KeywordFilter(props: KeywordFilterProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [localState, setLocalState] = useState<string[]>(props.selected)

  return (
    <Box>
      <Popover isLazy isOpen={isOpen}>
        <PopoverTrigger>
          <Button
            size="xs"
            variant="ghost"
            rightIcon={<IconFilter size="12" />}
            onClick={() => setIsOpen(!isOpen)}
            {...props.triggerProps}
          >
            {props.name}
            {props.selected.length > 0 && ` (${props.selected.length})`}
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody py="3">
              <Stack w="100%" spacing="4">
                <Stack spacing="2">
                  <HStack justifyContent={'space-between'}>
                    <Heading size="xs" fontWeight={'semibold'} pl="2">
                      {props.name}
                    </Heading>
                    <CloseButton onClick={() => setIsOpen(false)} />
                  </HStack>
                  <Divider />
                </Stack>

                <Stack>
                  <Text color="gray.500" fontSize="xs">
                    Enter a list of keywords to filter by
                  </Text>

                  <TagsInput
                    initialTags={localState}
                    onChange={(tags) => {
                      setLocalState(tags)
                    }}
                    onRemove={(tag) => {
                      const tags = localState.filter((t) => t !== tag)
                      setLocalState(tags)
                    }}
                    placeholder={`Filter by ${props.name} keywords`}
                    inputProps={{
                      w: '100%'
                    }}
                  />
                </Stack>

                <HStack justifyContent="flex-end" spacing="4">
                  <Button
                    isDisabled={localState.length === 0}
                    size="sm"
                    variant={'link'}
                    colorScheme={'purple'}
                    onClick={() => setLocalState([])}
                  >
                    Clear Filters
                  </Button>

                  <Button
                    size="sm"
                    colorScheme={'purple'}
                    onClick={() => {
                      props.onChange(localState)
                      setIsOpen(false)
                    }}
                  >
                    Apply Filters
                  </Button>
                </HStack>
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  )
}

export function SimpleFilter(props: SimpleFilterProps) {
  const [filter, setFilter] = useState('')
  const [localState, setLocalState] = useState<string[]>(props.selected)
  const [localOperator, setLocalOperator] = useState<Operator>(props.operator ?? 'must')
  const [isOpen, setIsOpen] = useState(false)

  const buckets = useMemo(() => {
    const filterCandidates = filter
      .trim()
      .toLowerCase()
      .split(/[,\n\s]/)
      .map((tag) => tag.trim())
      .filter(Boolean)

    if (filterCandidates.length === 0) {
      return props.aggs?.buckets
    }

    return props.aggs?.buckets.filter((bucket) => {
      return filterCandidates.some((tag) => bucket.key.toLowerCase().includes(tag))
    })
  }, [filter, props.aggs?.buckets])

  const orderedBuckets = useMemo(() => {
    return orderBy(buckets, (b) => props.selected.includes(b.key), ['desc'])
  }, [buckets, props.selected])

  return (
    <Box>
      <Popover isLazy isOpen={isOpen}>
        <PopoverTrigger>
          <Button
            size="xs"
            variant="ghost"
            rightIcon={<IconFilter size="12" />}
            onClick={() => setIsOpen(!isOpen)}
            {...props.triggerProps}
          >
            {props.name}
            {props.selected.length > 0 && ` (${props.selected.length})`}
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody py="3">
              <Stack spacing="4">
                <Stack spacing="2">
                  <HStack justifyContent={'space-between'}>
                    <Heading size="xs" fontWeight={'semibold'} pl="2">
                      {props.name}
                    </Heading>
                    <CloseButton onClick={() => setIsOpen(false)} />
                  </HStack>
                  <Divider />
                </Stack>

                <Flex>
                  <SearchBar
                    inputProps={{
                      isDisabled: props.isLoading
                    }}
                    size="sm"
                    value={filter}
                    onChange={setFilter}
                  />
                </Flex>

                <Select
                  size="sm"
                  rounded={'md'}
                  value={localOperator}
                  isDisabled={props.isLoading}
                  onChange={(e) => {
                    setLocalOperator(e.target.value as Operator)
                  }}
                >
                  <option value={'must'}>is any of</option>
                  <option value={'must_not'}>is not</option>
                </Select>

                {props.isLoading && (
                  <Stack px="2">
                    <HStack fontSize={'sm'}>
                      <Spinner size="xs" />
                      <Text>Loading options...</Text>
                    </HStack>
                    <SkeletonText noOfLines={8} spacing="4" />
                  </Stack>
                )}

                <Stack spacing="1.5" maxH="300px" overflow={'auto'} py="2">
                  {orderedBuckets.map((bucket) => (
                    <Checkbox
                      key={bucket.key}
                      colorScheme="purple"
                      isChecked={localState.includes(bucket.key)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          return setLocalState([...localState, bucket.key])
                        }

                        setLocalState(localState.filter((key) => key !== bucket.key))
                      }}
                    >
                      <HStack fontSize={'sm'} fontWeight="normal" spacing="1">
                        <Text>{humanize(bucket.key)}</Text>
                        {!props.hideCounts && bucket.doc_count > 0 && (
                          <Text color="purple.500">{bucket.doc_count}</Text>
                        )}
                      </HStack>
                    </Checkbox>
                  ))}
                </Stack>

                <HStack justifyContent="flex-end" spacing="4">
                  <Button
                    disabled={localState.length === 0 && props.selected.length === 0}
                    size="sm"
                    variant={'link'}
                    colorScheme={'purple'}
                    onClick={() => setLocalState([])}
                  >
                    Clear Filters
                  </Button>

                  <Button
                    size="sm"
                    colorScheme={'purple'}
                    onClick={() => {
                      props.onChange(localState, localOperator)
                      setIsOpen(false)
                    }}
                  >
                    Apply Filters
                  </Button>
                </HStack>
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  )
}

const emptyArray = []

export function values(facet: Facet): string[] {
  if (!facet) {
    return emptyArray
  }

  if (Array.isArray(facet)) {
    return facet as string[]
  }

  if ('not' in (facet as NotFacet)) {
    return (facet as NotFacet).not as string[]
  }

  return emptyArray
}

export function operator(facet: Facet): Operator {
  if (!facet) {
    return 'must'
  }

  if (Array.isArray(facet)) {
    return 'must'
  }

  if ('not' in (facet as NotFacet)) {
    return 'must_not'
  }

  return 'must'
}
