import {
  Button,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Stack,
  Text,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import { IconArrowRight, IconExternalLink } from '@tabler/icons-react'
import React from 'react'
import type { Account, CRMMatch } from '../../../../types/Account'
import type { App } from '../../../../types/App'
import { AppActions } from '../../../../types/AppActions'
import { DetailsCard } from '../../../ui/Card'
import { CardHeading } from '../../../ui/CardHeading'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { projectPath } from '../../../ui/ProjectsContext'
import { StackedField } from '../../../ui/StackedField'
import { TimeAgo } from '../../../ui/TimeAgo'
import CreateOutreachTaskSideSheet from '../../account_views/components/CreateOutreachTaskSideSheet'
import { useUIState } from './DetailsV2/useUIState'

interface OutreachCardProps {
  app?: App
  account: Account
  actions?: AppActions
  match?: CRMMatch['crm_entity']
  initiallyCollapsed?: boolean
  onRequestImport?: (app: App) => void
}

export function OutreachCard(props: OutreachCardProps) {
  const [ui, setUI] = useUIState()
  const cardDisclosure = useDisclosure({
    defaultIsOpen: ui.open?.outreach ?? !props.initiallyCollapsed,
    onOpen: () => {
      setUI({ open: { outreach: true } })
    },
    onClose: () => {
      setUI({ open: { outreach: false } })
    }
  })

  const [selectedAccount, setAccount] = React.useState<Account | null>(null)

  const createTask = React.useCallback(() => {
    window.ko?.track('Outreach Create Task', {
      app: 'outreach',
      account: props.match
    })

    setAccount(props.account)
  }, [props.match, props.account])

  const resetTask = React.useCallback(() => {
    setAccount(null)
  }, [])

  return (
    <>
      <DetailsCard>
        <CardHeading icon="https://www.outreach.io/_resources/img/logomark_sm.min.svg" disclosure={cardDisclosure}>
          <HStack justifyContent="space-between">
            <Text>Outreach Account</Text>
            {props.match?.permalink && (
              <Tooltip label="View in Outreach" placement="top" hasArrow arrowSize={6}>
                <IconButton
                  aria-label="View in Outreach"
                  as={Link}
                  href={props.match.permalink}
                  isExternal
                  size="xs"
                  variant="link"
                  icon={<IconExternalLink size={16} />}
                  color="gray.500"
                  _hover={{ color: 'purple.600' }}
                  onClick={() => {
                    window.ko?.track('Outreach Visit Action', {
                      app: 'outreach',
                      account: props.match
                    })
                  }}
                />
              </Tooltip>
            )}
          </HStack>
        </CardHeading>
        {!cardDisclosure.isOpen ? null : props.match ? (
          <Stack spacing={4}>
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <StackedField label="Account Owner">{props.match.account_owner}</StackedField>

              <StackedField label="Last Change Detected">
                <TimeAgo time={props.match.updated_at} mode="calendar" />
              </StackedField>

              <StackedField label="Last Successful Sync">
                <TimeAgo time={props.match.last_finished_integration_sync?.stopped_at} mode="calendar" />
                {props.match.last_finished_integration_sync && (
                  <HelpTooltip>
                    <Stack>
                      <Heading size="xs">Last Successful Sync</Heading>
                      <Text>
                        Koala last pulled your Outreach account for changes{' '}
                        <TimeAgo time={props.match.last_finished_integration_sync?.started_at} />.
                      </Text>
                    </Stack>
                  </HelpTooltip>
                )}
              </StackedField>
            </Grid>

            {props.actions?.outreach_create_task?.enabled && (
              <Button variant="outline" width="full" size="sm" onClick={createTask}>
                Create Task…
              </Button>
            )}
          </Stack>
        ) : props.app?.connected ? (
          <Stack>
            <Text fontSize="xs" color="gray.500">
              No accounts linked yet
            </Text>
            {props.actions?.outreach_import_accounts?.enabled && props.onRequestImport && (
              <Button
                variant="outline"
                width="full"
                size="sm"
                onClick={() => {
                  props.onRequestImport?.(props.app!)
                }}
              >
                Create account…
              </Button>
            )}
          </Stack>
        ) : (
          <>
            <Text fontSize="xs" color="gray.600">
              Connect the Outreach integration to see details from Outreach or sync data from Koala.
            </Text>
            <Button
              alignSelf="flex-start"
              size="sm"
              variant="outline"
              as="a"
              href={projectPath(`/apps/outreach?return_to=${encodeURIComponent(window.location.pathname)}`)}
              iconSpacing={1}
              rightIcon={<Icon as={IconArrowRight} boxSize={4} />}
            >
              Connect
            </Button>
          </>
        )}
      </DetailsCard>
      <CreateOutreachTaskSideSheet
        onClose={resetTask}
        isOpen={Boolean(selectedAccount)}
        account={selectedAccount}
        app={props.app}
      />
    </>
  )
}
