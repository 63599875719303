import { Apps } from '@app/types/App'
import { Box, Flex, Heading, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { IconCirclePlus } from '@tabler/icons-react'
import React from 'react'
import { Space } from '../../../types/Space'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import SquareIcon from '../../ui/SquareIcon'
import { NewListModal } from '../account_views/components/NewListModal'
import { SpaceHeader } from './components/SpaceHeader'

interface ShowProps {
  space: Space
  apps: Apps
}

export default function Show(props: ShowProps) {
  const newListModal = useDisclosure()

  return (
    <PageLayout size="full" gap={0}>
      <PageTitle skipRendering>{props.space.name}</PageTitle>
      <SpaceHeader space={props.space} apps={props.apps} />

      <Stack spacing={6} alignItems="center" padding={10} maxW="650px" marginX="auto">
        <Stack w="100%" spacing={3}>
          <Box
            border="1px solid"
            borderColor="gray.200"
            cursor="pointer"
            rounded="lg"
            bg="white"
            paddingX={3.5}
            paddingY={3.5}
            transition="border-color 150ms ease-in-out"
            _hover={{ borderColor: 'gray.300', shadow: 'sm' }}
            tabIndex={0}
            onClick={newListModal.onOpen}
          >
            <Flex alignItems="flex-start" gap={3}>
              <SquareIcon icon={IconCirclePlus} colorScheme="purple" padding={2} />
              <Box>
                <Heading size="xs">Start from scratch</Heading>
                <Text fontSize="xs" color="gray.500">
                  Get started by creating a list to organize your accounts or contacts
                </Text>
              </Box>
            </Flex>
          </Box>
        </Stack>
      </Stack>

      <NewListModal kind="account" spaceId={props.space.id} ownership="space" {...newListModal} />
    </PageLayout>
  )
}
