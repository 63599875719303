import { Box, Divider, Flex, Heading, HStack, Stack, Text } from '@chakra-ui/react'
import { format } from 'friendly-numbers'
import ms from 'ms'
import React from 'react'
import { HoverCard } from '../../../../ui/HoverCard'
import { IntentScore } from '../../../icps/types'
import { getScoreLabel, getTrend, scoreLabelColor, trendColor } from './IntentSummary'

const intentInputNames = {
  PageView: 'Page Views',
  KqlEvent: 'Intent Signals',
  FocusTime: 'Active Session Time',
  Visitors: 'Visitor Sessions'
}

const impactColors = {
  KqlEvent: 'purple',
  FocusTime: 'pink',
  PageView: 'blue'
}

interface IntentHoverCardProps {
  intent?: IntentScore
  normalizedScore: number
  children: React.ReactNode
}

export function IntentHoverCard(props: IntentHoverCardProps) {
  if (!props.intent) {
    return <Box>{props.children}</Box>
  }

  // show fallbacks here
  const trend = getTrend(props.intent, true)
  const scoreLabel = getScoreLabel(props.normalizedScore)
  const trendPeriod =
    props.intent.trend_14d === trend ? '14 days' : props.intent.trend_7d === trend ? '7 days' : '14 days'

  return (
    <HoverCard
      trigger="hover"
      isPortal
      openDelay={400}
      popoverContentProps={{
        maxW: '400px',
        minW: '380px'
      }}
      hoverContent={
        <Stack p="1" py="3" fontSize="sm" spacing="3">
          <Heading size="sm">Intent Summary</Heading>

          <Text>
            <span>
              This account's current intent score is{' '}
              <strong>{format(props.normalizedScore || 0, { decimals: 0 })}</strong>.
            </span>{' '}
            {scoreLabel ? (
              <span>
                This is considered{' '}
                <Text as="span" fontWeight="semibold" color={`${scoreLabelColor[scoreLabel] || 'gray'}.500`}>
                  {scoreLabel}
                </Text>{' '}
                intent compared to your baseline.
              </span>
            ) : (
              <span>There hasn't been any meaningful activity recently.</span>
            )}
          </Text>

          {trend && trendPeriod && (
            <Text>
              Their activity has been{' '}
              <Text as="span" fontWeight="semibold" color={`${trendColor[trend.toLowerCase()]}.500`}>
                {trend}
              </Text>{' '}
              over the past {trendPeriod}.
            </Text>
          )}

          {/* <IntentSummaryMakeup summary={props.intent.summary} /> */}

          <Divider />

          {props.intent.summary?.map((s) => (
            <Stack key={s.type} spacing={1} py={1}>
              <Text fontSize="xs" fontWeight="semibold">
                {intentInputNames[s.type]} (7d)
              </Text>
              {(s.itemized || []).length > 0 ? (
                <>
                  {s.itemized?.map((i) => (
                    <Flex
                      key={`${i.id}:${i.count}`}
                      fontSize="xs"
                      alignItems="center"
                      gap={2}
                      justifyContent="space-between"
                    >
                      <Text>{i.name || '[Deleted]'}</Text>
                      <Text css={{ fontVariantNumeric: 'tabular-nums' }}>{i.count.toLocaleString()}</Text>
                    </Flex>
                  ))}
                </>
              ) : (
                <Text fontSize="xs">
                  {s.type === 'FocusTime' ? `${ms(s.count * 1000, { long: true })}` : `${s.count.toLocaleString()}`}
                </Text>
              )}
            </Stack>
          ))}
        </Stack>
      }
    >
      <Box>{props.children}</Box>
    </HoverCard>
  )
}

function _IntentSummaryMakeup(props: { summary?: IntentScore['summary'] }) {
  if (!props.summary) {
    return null
  }

  const total = props.summary.reduce((agg, next) => {
    return agg + next.weighted
  }, 0)

  return (
    <>
      <Divider />
      <Stack mt={2}>
        <Text fontWeight="medium">Breakdown</Text>
        <Box display="flex" position="relative" height="10px" rounded="sm" gap="2px" overflow="hidden">
          {props.summary.map((s) => (
            <Box key={s.type} width={`${(s.weighted / total) * 100}%`} bg={`${impactColors[s.type]}.500`} />
          ))}
        </Box>
        <Flex alignItems="center" gap={3}>
          {props.summary.map((s) => (
            <Stack key={s.type} spacing={1}>
              <HStack>
                <Box boxSize={2} rounded="sm" bg={`${impactColors[s.type]}.500`} />
                <Text fontSize="xs">{intentInputNames[s.type]}</Text>
              </HStack>
              <Text></Text>
            </Stack>
          ))}
        </Flex>
      </Stack>
    </>
  )
}
