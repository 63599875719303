import {
  Box,
  Button,
  Collapse,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import {
  IconChevronDown,
  IconChevronUp,
  IconClock,
  IconExclamationCircle,
  IconExternalLink,
  IconMapPin
} from '@tabler/icons-react'
import { uniqBy } from 'lodash'
import React from 'react'
import { Account } from '../../../../../types/Account'
import { ProfileRecord } from '../../../../../types/Profile'
import { useData } from '../../../../data/use-data'
import { openUpgradeFlow } from '../../../../ui/billing-banners/accounts-banner'
import { TimeAgo } from '../../../../ui/TimeAgo'
import { KoalaSubscription } from '../../../billing/show'
import { profilePath } from '../../../profiles/lib/path'
import { accountPath } from '../../lib/account-path'

export function InactiveAccount(props: { account: Account }) {
  const { data } = useData<{
    sample_profiles: Pick<ProfileRecord, 'id' | 'name' | 'email' | 'simple_location' | 'last_seen_at'>[]
    retention_period: number
    inactive_reason: 'empty' | 'data_retention'
    total_profiles: number
    subscription?: KoalaSubscription
  }>(props.account.id, accountPath(props.account, '/inactive-stats'))

  const [showDetails, setShowDetails] = React.useState(false)
  if (!data) return null

  return (
    <Stack fontSize="sm" bg="gray.50" rounded="md" py="4" p="6" spacing="6" borderWidth="1px">
      <Stack spacing="2" px="0">
        <Box fontSize="sm" flex="1">
          <Flex gap={2} alignItems="flex-start">
            <Icon as={IconExclamationCircle} boxSize={5} color="gray.600" />
            <Stack>
              <Heading size="sm" fontWeight="semibold">
                No recent activity
              </Heading>
              <Flex gap={4} w="100%" justifyContent="space-between" alignItems="flex-start" color="gray.600">
                {data.inactive_reason === 'data_retention' ? (
                  <Text>
                    Koala hasn't received any recent activity for this account within the {data.retention_period}-day
                    retention period for your workspace.
                  </Text>
                ) : (
                  <Text>
                    Koala hasn't received any activity for this account. Koala automatically imports all accounts from
                    your CRM, and other data sources, so as soon as there is activity you'll see it here!
                  </Text>
                )}

                {data.sample_profiles.length > 0 && data.inactive_reason === 'data_retention' && (
                  <IconButton
                    colorScheme={'gray'}
                    size="sm"
                    variant={'ghost'}
                    aria-label="Show details"
                    icon={showDetails ? <IconChevronUp size="15" /> : <IconChevronDown size="15" />}
                    onClick={() => setShowDetails(!showDetails)}
                  />
                )}
              </Flex>
            </Stack>
          </Flex>

          <Collapse in={showDetails}>
            <Stack pt="4">
              {(data.sample_profiles.length ?? 0) > 0 && (
                <>
                  <Heading size="xs" fontWeight={'semibold'}>
                    Previous Visitors
                  </Heading>
                  <TableContainer rounded="md" shadow="sm" borderWidth={'1px'}>
                    <Table size="sm" bg="white" border="none">
                      <Thead>
                        <Tr>
                          <Th>Visitor</Th>
                          <Th>Last Known Location</Th>
                          <Th>Last Activity</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {uniqBy(data.sample_profiles, (l) => l.id).map((profile) => (
                          <Tr key={profile.id} border="none">
                            <Td border="none" pt="3">
                              <Button
                                size="xs"
                                as={Link}
                                leftIcon={<IconExternalLink size="12" />}
                                key={profile.id}
                                isExternal
                                href={profilePath(profile)}
                              >
                                {profile.name ?? profile.email}
                              </Button>
                            </Td>
                            <Td fontSize="xs" border="none">
                              <HStack spacing="1">
                                <IconMapPin size="12" color={`gray`} />
                                <Text>{profile.simple_location}</Text>
                              </HStack>
                            </Td>
                            <Td fontSize="xs" border="none">
                              <HStack spacing="1">
                                <IconClock size="12" color={`gray`} />
                                <TimeAgo time={profile.last_seen_at} />
                              </HStack>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Stack>
          </Collapse>

          {data.subscription &&
            data.subscription.entitlements?.retention &&
            data.inactive_reason === 'data_retention' && (
              <HStack pt="4">
                <Text>Please get in touch with us if you'd like to increase your retention period.</Text>
                <Button size="xs" onClick={() => openUpgradeFlow('business')}>
                  Upgrade Plan
                </Button>
              </HStack>
            )}
        </Box>
      </Stack>
    </Stack>
  )
}
