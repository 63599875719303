import { Flex } from '@chakra-ui/react'
import update from 'immutability-helper'
import { nanoid } from 'nanoid'
import React, { useMemo } from 'react'
import type { ConditionGroupType, ConditionType } from '../types'
import ConditionGroup from './ConditionGroup'

export interface TriggerProps {
  trigger: ConditionGroupType
  updateTrigger: React.Dispatch<React.SetStateAction<Required<ConditionGroupType>>>
  isRemovable?: boolean
}

export function KQLTrigger({ trigger, updateTrigger, isRemovable }: TriggerProps) {
  const group = React.useMemo(() => (Array.isArray(trigger) ? trigger[0] : trigger), [trigger])
  const conditions = group?.conditions || []
  const removable = useMemo(() => isRemovable ?? true, [isRemovable])

  const addCondition = React.useCallback(
    (condition?: Partial<ConditionType>) => {
      updateTrigger((trigger) => {
        if (!trigger || !trigger.conditions) {
          trigger = { conditions: [] }
        }

        return update(trigger, {
          conditions: { $push: [{ id: nanoid(), kind: '', property: '', operator: 'is', ...condition }] }
        })
      })
    },
    [updateTrigger]
  )

  const removeCondition = React.useCallback(
    (index: number) => {
      updateTrigger((trigger) => {
        if (!trigger || !trigger.conditions) {
          trigger = { conditions: [] }
        }

        return update(trigger, {
          conditions: { $splice: [[index, 1]] }
        })
      })
    },
    [updateTrigger]
  )

  const changeCondition = React.useCallback(
    (index: number, condition: Partial<ConditionType>) => {
      updateTrigger((trigger) => {
        if (!trigger || !trigger.conditions) {
          trigger = { conditions: [] }
        }

        return update(trigger, { conditions: { [index]: { $merge: condition } } })
      })
    },
    [updateTrigger]
  )

  return (
    <Flex gap={4} direction="column">
      <ConditionGroup
        conditions={conditions}
        onChangeCondition={changeCondition}
        removeCondition={removeCondition}
        addCondition={addCondition}
        isRemovable={removable}
      />
    </Flex>
  )
}
