import { Box, Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, VStack } from '@chakra-ui/react'
import React from 'react'
import { Project } from '../../../../types/Project'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { usePermission } from '../../../ui/PermissionsContext'

interface ProjectFormProps {
  project: Project
  errors?: Record<string, string[]>
  method: 'POST' | 'PATCH'
}

export function ProjectForm(props: ProjectFormProps) {
  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })
  const isCreating = props.method === 'POST' && !props.project.slug

  return (
    <Box as="form" action={`/projects/${props.project.slug !== null ? props.project.slug : ''}`} method="POST">
      <AuthenticityToken />
      {props.method === 'PATCH' && <input type="hidden" name="_method" value="patch" />}

      <VStack spacing={6} alignItems="normal">
        <FormControl id="project[name]" isInvalid={!!props.errors?.name?.length}>
          <FormLabel>Workspace name</FormLabel>
          <Input
            type="text"
            name="project[name]"
            isRequired
            defaultValue={props.project.name}
            readOnly={!canEditProject && !isCreating}
          />
          <FormErrorMessage>{props.errors?.name?.join(', ')}</FormErrorMessage>
          <FormHelperText>
            A human friendly name for your workspace (it won't change the URLs, just the name inside the Koala app).
          </FormHelperText>
        </FormControl>

        <FormControl id="project[slug]">
          <FormLabel>Workspace slug</FormLabel>
          <Input
            type="text"
            name="project[slug]"
            value={props.project.slug}
            readOnly
            fontFamily="mono"
            background="gray.50"
          />
          <FormHelperText>Your workspace's URL identifier.</FormHelperText>
        </FormControl>

        <FormControl id="project[domain]" isInvalid={!!props.errors?.domain?.length}>
          <FormLabel>Company domain</FormLabel>
          <Input
            type="text"
            name="project[domain]"
            defaultValue={props.project.domain}
            autoComplete="off"
            pattern="[\w.-]+"
            readOnly={!canEditProject}
          />
          <FormErrorMessage>{props.errors?.domain?.join(', ')}</FormErrorMessage>
          <FormHelperText>Your company's primary website domain. E.g. example.com</FormHelperText>
        </FormControl>

        <Box>
          <Button type="submit" size="sm" colorScheme="purple" isDisabled={props.method === 'PATCH' && !canEditProject}>
            {props.method === 'POST' ? 'Create' : 'Save'}
          </Button>
        </Box>
      </VStack>
    </Box>
  )
}
