import { Icon, IconProps } from '@chakra-ui/react'
import React from 'react'

interface Props extends IconProps {
  size?: string | number
}

export function OutreachIcon({ size, ...props }: Props) {
  size = size ? `${size}px` : size
  return (
    <Icon viewBox="0 0 24 24" fill="none" width={size} height={size} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.894 2C13.957 2 18.585 2.94 20.74 5.094C21.286 5.64 22 6.709 22 8.276C22 11.062 19.755 15.336 17.277 17.816C15.996 19.096 13.957 20.772 11.215 20.772C8.535 20.772 6.488 18.951 5.895 18.358C3.626 16.088 2 12.642 2 9.43C2 7.404 2.578 5.63 4.052 4.157C5.417 2.79 7.77 2 10.894 2ZM14.418 13.13C15.452 12.095 15.731 10.82 15.731 10.12C15.731 9.611 15.597 8.866 14.961 8.23C13.985 7.255 12.906 6.98 11.731 6.98C10.242 6.98 9.242 7.45 8.576 8.116C8.053 8.638 7.602 9.442 7.602 10.582C7.602 11.722 8.21 12.824 8.872 13.487C9.485 14.098 10.446 14.552 11.415 14.552C12.385 14.552 13.384 14.164 14.418 13.13Z"
        fill="currentColor"
      />
    </Icon>
  )
}
