import { AvatarGroup, IconButton, Link, Text } from '@chakra-ui/react'
import { IconX } from '@tabler/icons-react'
import React, { useMemo } from 'react'
import createPersistedState from 'use-persisted-state'
import { CatalogApp } from '../../../../types/App'
import Avatar from '../../../ui/Avatar'
import { DetailsCard } from '../../../ui/Card'
import { projectPath } from '../../../ui/ProjectsContext'

const useMoreAppsDismissed = createPersistedState<boolean>('more-apps-dismissed')

interface Props {
  otherApps: CatalogApp[]
}

export function MoreAppsCard({ otherApps }: Props) {
  const [moreAppsDismissed, setMoreAppsDismissed] = useMoreAppsDismissed(false)

  const displayed = useMemo(() => {
    return otherApps.slice(0, 6)
  }, [otherApps]) as CatalogApp[]

  if (moreAppsDismissed || otherApps.length === 0) {
    return null
  }

  return (
    <DetailsCard position="relative">
      <IconButton
        aria-label="Dismiss"
        size="xs"
        variant="ghost"
        color="gray.500"
        _hover={{ color: 'gray.700' }}
        icon={<IconX size={14} />}
        position="absolute"
        right={2}
        top={2}
        onClick={() => setMoreAppsDismissed(true)}
      />
      <AvatarGroup size="36px" spacing={1} mx="auto" mt={2.5}>
        {displayed.map((app) => {
          return (
            <Avatar
              key={app.module}
              as={Link}
              // @ts-expect-error the types are wrong
              href={projectPath(
                `/apps/${app.clean_module_name}?return_to=${encodeURIComponent(window.location.pathname)}`
              )}
              isExternal
              src={app.logo}
              background="white"
              border="1px solid"
              borderColor="white"
              rounded="md"
              p={1}
              _hover={{
                transform: 'translateY(-1px) scale(1.05)'
              }}
            />
          )
        })}
      </AvatarGroup>
      <Text fontSize="sm" color="gray.700" textAlign="center" maxW="85%" mx="auto">
        <Link href={projectPath('/apps')} isExternal>
          Connect{' '}
          {displayed
            .map((m) => m.title)
            .slice(0, 2)
            .join(', ')}{' '}
          and more to get the most of out Koala
        </Link>
      </Text>
    </DetailsCard>
  )
}
