import * as React from 'react'
import { concurrentGET } from '../../lib/api'
import { projectPath, useCurrentProject } from './ProjectsContext'

interface Permissions {
  project?: {
    [key: string]: boolean
  }
}

interface PermissionsContextType extends Permissions {
  isLoading: boolean
}

export const PermissionsContext = React.createContext<PermissionsContextType>({ isLoading: false })

export function PermissionsProvider(props: React.PropsWithChildren<{}>) {
  const currentProject = useCurrentProject()
  const [permissions, setPermissions] = React.useState<Permissions>({})
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    let canceled = false

    if (currentProject) {
      setIsLoading(true)
      concurrentGET<{ permissions: Permissions }>(projectPath(`/me/permissions`))
        .then((data) => {
          if (canceled) return
          setIsLoading(false)
          setPermissions(data.permissions)
        })
        .catch(() => {
          if (canceled) return
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
      setPermissions({})
    }

    return () => {
      canceled = true
    }
  }, [currentProject])

  const context = React.useMemo(() => Object.assign({ isLoading }, permissions), [isLoading, permissions])

  return <PermissionsContext.Provider value={context}>{props.children}</PermissionsContext.Provider>
}

interface UsePermissionArgs {
  action: string
  on: keyof Permissions
}

interface UsePermissionOutput {
  isLoading: boolean
  hasPermission: boolean
}

export function usePermission(options: UsePermissionArgs): UsePermissionOutput {
  const permissions = React.useContext(PermissionsContext)

  const hasPermission = React.useMemo(() => {
    return permissions[options.on]?.[options.action] ?? false
  }, [permissions, options.action, options.on])

  return { hasPermission, isLoading: permissions.isLoading }
}
