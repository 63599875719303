import {
  Box,
  Center,
  Checkbox,
  Circle,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { IconArrowRight, IconInbox } from '@tabler/icons-react'
import React from 'react'
import EmptyState from '../../ui/EmptyState'
import { TopBarContent } from '../../ui/TopBarContext'
import PageLayout from '../../ui/PageLayout'
import { SmallPageHeading } from '../../ui/PageTitle'
import { Space } from '../../../types/Space'
import { accountPath } from '../accounts/lib/account-path'
import CompanyAvatar from '../../ui/CompanyAvatar'
import Avatar from '../../ui/Avatar'
import { profilePath } from '../profiles/lib/path'
import { HelpTooltip } from '../../ui/HelpTooltip'

interface Props {
  profiles: any[]
  accounts: any[]
  space?: Space
}

export default function Inbox(props: Props) {
  return (
    <PageLayout size="full" flush gap={0}>
      <TopBarContent>
        <HStack spacing={1.5}>
          <SmallPageHeading>Inbox</SmallPageHeading>
          <HelpTooltip variant="info" placement="right">
            Your inbox of leads and accounts, powered by the audiences in your Mission Control
          </HelpTooltip>
        </HStack>
      </TopBarContent>
      <Stack padding={6} spacing={10}>
        {!props.space ? (
          <Center bg="background.light" rounded="xl" paddingY={10}>
            <EmptyState
              size="md"
              heading="Your inbox hasn't been configured yet"
              description={`Once you set up your inbox, you'll be able to view and manage assigned leads for all your playbooks.`}
              icon={IconInbox}
            />
          </Center>
        ) : (
          <>
            {props.profiles.length > 0 && (
              <Stack>
                <Heading size="sm" fontWeight="semibold">
                  Top Leads
                </Heading>
                <TableContainer
                  position="relative"
                  fontSize="sm"
                  borderX="1px solid"
                  borderColor="gray.200"
                  rounded="sm"
                >
                  <Table variant="bordered" size="sm" h="1px">
                    <Thead>
                      <Tr height="38px">
                        <Th>
                          <Text pl={8}>Name</Text>
                        </Th>
                        <Th>Company</Th>
                        <Th />
                      </Tr>
                    </Thead>
                    <Tbody>
                      {props.profiles.map((profile) => {
                        const displayName = profile.name ?? profile.display_name

                        return (
                          <Tr key={profile.id}>
                            <Td width="400px">
                              <HStack>
                                <Checkbox size="md" alignSelf="stretch" paddingRight={2} />
                                <Avatar size="sm" src={profile.avatar || profile.image} name={displayName} />
                                <Stack spacing={0.5} flex="1" alignItems="flex-start" py={1}>
                                  {displayName ? (
                                    <Link
                                      href={profilePath(profile)}
                                      fontSize="sm"
                                      fontWeight="semibold"
                                      isTruncated
                                      maxWidth="100%"
                                    >
                                      {displayName}
                                    </Link>
                                  ) : (
                                    <Link href={profilePath(profile)} fontSize="sm" color="gray.500">
                                      Anonymous
                                    </Link>
                                  )}
                                  <Text fontSize="xs" color="gray.500">
                                    {profile.title || profile.email}
                                  </Text>
                                </Stack>
                              </HStack>
                            </Td>
                            <Td width="180px">
                              {profile.company && (
                                <Box width="180px">
                                  <Flex
                                    as="a"
                                    href={accountPath({ company: profile.company })}
                                    display="inline-flex"
                                    alignItems="center"
                                    gap={1}
                                    width="auto"
                                    maxW="100%"
                                    overflow="hidden"
                                    rounded="md"
                                    paddingLeft={1}
                                    paddingRight={1.5}
                                    paddingY={1}
                                    marginX={-1}
                                    title={profile.company.name ?? profile.company.domain}
                                    _hover={{
                                      background: 'white',
                                      shadow: 'sm',
                                      '& .hover-icon': {
                                        display: 'flex',
                                        opacity: 1
                                      }
                                    }}
                                  >
                                    <CompanyAvatar
                                      size="tiny"
                                      rounded="base"
                                      name={profile.company.name}
                                      domain={profile.company.domain}
                                    />
                                    <Text flex="1 1 auto" maxWidth="100%" fontSize="sm" fontWeight="medium" isTruncated>
                                      {profile.company.name ?? profile.company.domain}
                                    </Text>
                                    <Circle
                                      flex="none"
                                      className="hover-icon"
                                      display="none"
                                      opacity={0}
                                      size="18px"
                                      bg="gray.100"
                                    >
                                      <Icon as={IconArrowRight} boxSize={3.5} color="gray.600" />
                                    </Circle>
                                  </Flex>
                                </Box>
                              )}
                            </Td>
                            <Td />
                          </Tr>
                        )
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Stack>
            )}

            {props.accounts.length > 0 && (
              <Stack>
                <Heading size="sm" fontWeight="semibold">
                  Top Accounts
                </Heading>
                <TableContainer
                  position="relative"
                  fontSize="sm"
                  borderX="1px solid"
                  borderColor="gray.200"
                  rounded="sm"
                >
                  <Table variant="bordered" size="sm" h="1px">
                    <Thead>
                      <Tr height="38px">
                        <Th>
                          <Text pl={8}>Company</Text>
                        </Th>
                        <Th />
                      </Tr>
                    </Thead>
                    <Tbody>
                      {props.accounts.map((account) => (
                        <Tr key={account.id}>
                          <Td width="400px">
                            <HStack py={1}>
                              <Checkbox size="md" alignSelf="stretch" paddingRight={2} />
                              <CompanyAvatar size="sm" name={account.company.name} domain={account.company.domain} />
                              <Stack spacing={0.5} flex="1" alignItems="flex-start">
                                <Link
                                  href={accountPath(account)}
                                  fontSize="sm"
                                  fontWeight="semibold"
                                  isTruncated
                                  maxWidth="100%"
                                >
                                  {account.company.name || account.company.domain}
                                </Link>
                                {account.company.name && (
                                  <Text fontSize="xs" color="gray.500">
                                    {account.company.domain}
                                  </Text>
                                )}
                              </Stack>
                            </HStack>
                          </Td>
                          <Td />
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Stack>
            )}
          </>
        )}
      </Stack>
    </PageLayout>
  )
}
