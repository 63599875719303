import merge from 'lodash/merge'
import { useCallback } from 'react'
import createPersistedState from 'use-persisted-state'

interface Props<T extends Record<string, any>> {
  defaultState?: T
  stateKey: string
}

export function useUIState<T extends Record<string, any>>(props: Props<T>) {
  const [state, setState] = createPersistedState<T>(props.stateKey)(props.defaultState || ({} as T))

  const upsertState = useCallback(
    (newState) => {
      setState((oldState) => merge({}, oldState, newState))
    },
    [setState]
  )

  return [state || props.defaultState || ({} as T), upsertState] as const
}
