import { Heading, HeadingProps } from '@chakra-ui/react'
import React from 'react'

type CardHeaderProps = HeadingProps

export function CardHeader(props: React.PropsWithChildren<CardHeaderProps>) {
  return (
    <Heading size="sm" fontWeight="medium" py={4} borderBottom="1px solid" borderBottomColor="gray.200" {...props} />
  )
}
