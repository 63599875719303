import { Collapse, Divider, Flex, HStack, IconButton, Tag } from '@chakra-ui/react'
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import React from 'react'
import { LightBgCard } from '../../../ui/Card'

export function WrapTask(props: {
  children: React.ReactNode
  name: React.ReactNode
  enabled?: boolean
  defaultIsOpen?: boolean
}) {
  const [visible, setVisible] = React.useState(props.defaultIsOpen ?? false)

  return (
    <LightBgCard p="0" bg="white">
      <HStack
        justifyContent={'space-between'}
        onClick={() => setVisible(!visible)}
        cursor="pointer"
        px="5"
        pr="2"
        py={2}
      >
        <HStack fontWeight={'semibold'} fontSize="sm">
          {props.name}
          {props.enabled && (
            <Tag colorScheme={'purple'} size="sm">
              Enabled
            </Tag>
          )}
        </HStack>
        <IconButton
          aria-label="Toggle"
          variant="ghost"
          size="sm"
          icon={visible ? <IconChevronUp size="14" /> : <IconChevronDown size="14" />}
        ></IconButton>
      </HStack>
      {visible && <Divider />}
      <Collapse in={visible}>
        <Flex w="100%" px="6" py="4">
          {props.children}
        </Flex>
      </Collapse>
    </LightBgCard>
  )
}
