import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Switch,
  Text
} from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useAppDep } from '../../../../data/use-app-dep'
import { useTeams } from '../../../../data/use-teams'
import { ComboboxWithSearch } from '../../../../ui/ComboboxWithSearch'
import SelectInput from '../../../../ui/SelectInput'
import { OutreachActionProps } from './outreach-setup'

export type OutreachSequence = {
  id: string
  attributes: {
    name: string
  }
}

export function AddToSequence(props: OutreachActionProps) {
  const [enabled, setEnabled] = React.useState(Boolean(props.delivery_rules?.outreach?.add_to_sequence?.enabled))

  const [selectedSequenceId, setSelectedSequenceId] = React.useState<OutreachSequence['id'] | null>(
    props.delivery_rules?.outreach?.add_to_sequence?.sequence_id ?? null
  )

  const [selectedOwnerId, setSelectedOwnerId] = React.useState<string | null>(
    props.delivery_rules?.outreach?.add_to_sequence?.owner_id ?? null
  )

  const [selectedTeamId, setSelectedTeamId] = React.useState<string | null>(
    props.delivery_rules?.outreach?.add_to_sequence?.round_robin_team_id ?? null
  )

  const teamsData = useTeams()
  const teams = useMemo(() => teamsData?.data?.teams ?? [], [teamsData?.data])

  const { data: sequenceData, isLoading: loadingSequences } = useAppDep<'all_sequences!', OutreachSequence[]>(
    'Outreach',
    'all_sequences!'
  )

  const [sequenceOption, setSequenceOption] = React.useState<'account_owner' | 'specific_user' | 'round-robin'>(
    props.delivery_rules?.outreach?.add_to_sequence?.use_account_owner
      ? 'account_owner'
      : props.delivery_rules?.outreach?.add_to_sequence?.round_robin_team_id
      ? 'round-robin'
      : 'specific_user'
  )

  const { data: usersData, isLoading: loadingUsers } = useAppDep<'users', any[]>('Outreach', 'users')

  const selectedItem = useMemo(
    () =>
      sequenceData?.data?.['all_sequences!']?.find((s) => {
        return s.id.toString() === selectedSequenceId?.toString()
      }) ?? null,
    [sequenceData, selectedSequenceId]
  )

  const selectedOwner = useMemo(
    () =>
      usersData?.data?.users?.find((s) => {
        return s.id.toString() === selectedOwnerId?.toString()
      }) ?? null,
    [usersData, selectedOwnerId]
  )

  const selectedTeam = useMemo(
    () => teams.find((t) => selectedTeamId && t.id === selectedTeamId),
    [teams, selectedTeamId]
  )

  return (
    <Stack w="100%">
      <FormControl>
        <Switch
          isChecked={enabled}
          onChange={(e) => {
            setEnabled(e.target.checked)
            props.setDeliveryRules({
              ...props.delivery_rules,
              outreach: {
                ...props.delivery_rules?.outreach,
                add_to_sequence: {
                  ...props.delivery_rules?.outreach?.add_to_sequence,
                  enabled: e.target.checked
                }
              }
            })
          }}
          size="sm"
          fontSize={'sm'}
          fontWeight="semibold"
          colorScheme={'purple'}
          value={enabled ? 'true' : 'false'}
          name="follow_rule[delivery_rules][outreach][add_to_sequence][enabled]"
        >
          Enable
        </Switch>
        <FormHelperText>Add the visitor or account to an Outreach Sequence.</FormHelperText>
      </FormControl>
      {enabled && (
        <Stack w="100%" spacing="4">
          <FormControl>
            <FormLabel>Sequence</FormLabel>
            {loadingSequences && <Spinner size="sm" />}
            {selectedSequenceId && (
              <input
                type="hidden"
                name="follow_rule[delivery_rules][outreach][add_to_sequence][sequence_id]"
                value={selectedSequenceId}
              />
            )}
            <ComboboxWithSearch
              items={sequenceData?.data?.['all_sequences!'] ?? []}
              selectedItem={selectedItem}
              onChange={(selectedItem) => {
                setSelectedSequenceId(selectedItem?.id ?? null)
              }}
              filterItem={(a, val) => a.attributes.name.toLowerCase().includes(val)}
              itemToString={(item) => item?.attributes?.name || ''}
            />
          </FormControl>

          <FormControl w="100%">
            <FormLabel>Owner / Sender</FormLabel>
            <Stack w="100%">
              <RadioGroup
                value={sequenceOption}
                onChange={(value) => setSequenceOption(value as 'account_owner' | 'specific_user' | 'round-robin')}
                size="sm"
                fontSize="xs"
              >
                <HStack bg="gray.50" rounded="lg" p="4" borderWidth={'thin'} w="100%">
                  <Radio bg="white" value="account_owner">
                    Current Prospect or Account Owner
                  </Radio>
                  <Divider orientation="vertical" />
                  <Radio bg="white" value="specific_user">
                    Select a User
                  </Radio>
                  <Divider orientation="vertical" />
                  <Radio bg="white" value="round-robin">
                    Round Robin
                  </Radio>
                </HStack>
              </RadioGroup>

              {sequenceOption === 'account_owner' && (
                <>
                  <input
                    type="hidden"
                    name="follow_rule[delivery_rules][outreach][add_to_sequence][use_account_owner]"
                    value={'true'}
                  />
                  <Text fontSize="sm" pt="2">
                    The Sequence owner will be inherited from the owner of the Prospect in Outreach, then proceed to
                    look for the Account Owner as a fallback. Note: this means Unowned Accounts will not be added to a
                    Sequence.
                  </Text>
                </>
              )}

              {sequenceOption === 'specific_user' && (
                <>
                  <FormLabel pt="2">Choose an Outreach User</FormLabel>
                  {loadingUsers && <Spinner size="sm" />}
                  {selectedOwnerId && (
                    <input
                      type="hidden"
                      name="follow_rule[delivery_rules][outreach][add_to_sequence][owner_id]"
                      value={selectedOwnerId}
                    />
                  )}
                  <ComboboxWithSearch
                    items={(usersData?.data?.users ?? []).filter((u) => !u.attributes.locked)}
                    selectedItem={selectedOwner}
                    onChange={(selectedItem) => {
                      setSelectedOwnerId(selectedItem?.id ?? null)
                    }}
                    filterItem={(a, val) => a.attributes.name.toLowerCase().includes(val)}
                    itemToString={(item) =>
                      `${item?.attributes?.name || 'Unknown User'} (${item?.attributes?.email || 'unknown email'})`
                    }
                  />
                </>
              )}

              {sequenceOption === 'round-robin' && (
                <>
                  <FormLabel pt="2">Choose a Team to round robin</FormLabel>
                  {loadingUsers && <Spinner size="sm" />}
                  {selectedTeamId && (
                    <input
                      type="hidden"
                      name="follow_rule[delivery_rules][outreach][add_to_sequence][round_robin_team_id]"
                      value={selectedTeamId}
                    />
                  )}
                  {teamsData.isLoading ? (
                    <Spinner />
                  ) : (
                    <Box>
                      <SelectInput
                        size="md"
                        variant="outline"
                        items={teams}
                        itemToString={(item) => item?.name ?? 'Unknown Team'}
                        selectedItem={selectedTeam}
                        onSelectedItemChange={({ selectedItem }) => {
                          setSelectedOwnerId(null)
                          setSelectedTeamId(selectedItem?.id ?? null)
                        }}
                        popperOptions={{
                          matchWidth: true
                        }}
                      />
                      <FormHelperText>
                        Koala will round-robin the users on this team that are also in your Outreach instance for each
                        prospect added to the sequence.
                      </FormHelperText>
                    </Box>
                  )}
                </>
              )}
            </Stack>
          </FormControl>
        </Stack>
      )}
    </Stack>
  )
}
