import { useEffect, useRef } from 'react'
import { useCurrentProject } from '../components/ui/ProjectsContext'
import useLatestRef from '../components/ui/useLatestRef'
import { subscribeToChannel, SubscriptionEmitter } from './generic_channel'

export function useActionCableChannel<Data = any>(
  channel: string,
  params: Record<string, undefined | string | boolean | number>,
  callback: (data: Data) => void
) {
  const callbackRef = useLatestRef(callback)
  const subscription = useRef<SubscriptionEmitter>()
  const project = useCurrentProject()
  const diff = JSON.stringify({ channel, params, project: project?.id })

  useEffect(() => {
    if (subscription.current) {
      return
    }

    const onUpdate = (data: Data) => callbackRef.current(data)

    subscription.current = subscribeToChannel({ ...params, project_slug: project?.slug, channel })
    subscription.current?.on('received', onUpdate)

    return () => {
      subscription.current?.off('received', onUpdate)
      subscription.current?.unsubscribe()
      subscription.current = undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diff])

  return subscription.current
}
