/**
 * Removes sets of keys from a URLSearchParams object or url string or search string
 */
export function removeParams(
  params: URLSearchParams | string,
  keys: string[] | ((key: string) => boolean)
): URLSearchParams {
  const searchParams = new URLSearchParams(params)
  const keysToDelete = typeof keys === 'function' ? Array.from(searchParams.keys()).filter((k) => keys(k)) : keys

  keysToDelete.forEach((key) => searchParams.delete(key))

  return searchParams
}
