import { Button, ButtonProps, Icon, IconProps, Image, ImageProps } from '@chakra-ui/react'
import { IconArrowRight } from '@tabler/icons-react'
import React from 'react'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { useCurrentProject } from '../../../ui/ProjectsContext'

export interface IntegrationButtonProps {
  title: string
  logo: string
  clean_module_name: string
  buttonProps?: ButtonProps
  logoProps?: ImageProps
  rightLogoProps?: IconProps
  returnTo?: string
}

export function IntegrationButton(props: IntegrationButtonProps) {
  const project = useCurrentProject()

  if (!project) {
    return null
  }

  const query = new URLSearchParams({
    project: project.slug,
    app_id: props.clean_module_name,
    origin: props.returnTo ?? window.location.href
  })

  return (
    <form method="POST" action={`/auth/${props.clean_module_name}?${query.toString()}`}>
      <AuthenticityToken />
      <Button
        type="submit"
        variant="outline"
        size="lg"
        fontSize="md"
        flex="none"
        justifyContent="space-between"
        marginLeft="auto"
        width="full"
        fontWeight="medium"
        leftIcon={<Image src={props.logo} boxSize={6} {...props.logoProps} />}
        rightIcon={<Icon as={IconArrowRight} boxSize={5} color="gray.500" {...props.rightLogoProps} />}
        {...props.buttonProps}
      >
        Connect {props.title}
      </Button>
    </form>
  )
}
