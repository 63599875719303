import { Circle, Flex, FlexProps, Icon, Text } from '@chakra-ui/react'
import { IconArrowRight } from '@tabler/icons-react'
import React from 'react'
import CompanyAvatar from './CompanyAvatar'

type Props = FlexProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    domain?: string
    name?: string
    iconRight?: boolean
  }

const hoverStyle = {
  background: 'white',
  shadow: 'sm',

  '& .hover-icon': {
    display: 'flex',
    opacity: 1
  }
}

export function CompanyBubble({ domain, name, href, iconRight, minWidth = '100px', ...rest }: Props) {
  return (
    <Flex
      as="a"
      href={href}
      display="inline-flex"
      alignItems="center"
      gap={1.5}
      rounded="lg"
      paddingLeft="3px"
      paddingRight="5px"
      marginLeft="-3px"
      marginRight="-5px"
      paddingY="3px"
      maxW="100%"
      isTruncated
      fontWeight="medium"
      _hover={hoverStyle}
      title={name || domain}
      {...rest}
    >
      <CompanyAvatar size="20px" name={name} domain={domain} />

      <Text fontSize="sm" minWidth={minWidth} lineHeight="1.2" fontWeight="inherit" isTruncated>
        {name || domain}
      </Text>

      {iconRight !== false && (
        <Circle flex="none" className="hover-icon" display="none" opacity={0} size="18px" bg="gray.100">
          <Icon as={IconArrowRight} boxSize={3.5} color="gray.600" />
        </Circle>
      )}
    </Flex>
  )
}
