import { Stack, useDisclosure, Image, Heading, Text, Button, Link } from '@chakra-ui/react'
import { IconArrowUpRight } from '@tabler/icons-react'
import React, { useEffect, useMemo } from 'react'

import KoalaUBannerSvg from './koalaUBanner.svg'
import { useUpdateOnboarding } from '@app/components/data/use-update-onboarding'
import { useOnboardingSteps } from '@app/components/data/use-onboarding-steps'
import { useCurrentUser } from '@app/components/ui/UserContext'
import { useCurrentProject } from '@app/components/ui/ProjectsContext'
import { CornerDialog } from './CornerDialog'

function dialogInteracted() {
  return Boolean(window.localStorage.getItem('koala-u-dialog-interacted'))
}
export function KoalaUDialog() {
  const { data, isLoading } = useOnboardingSteps('mission_control', !dialogInteracted())
  const finishedSteps = useMemo(() => (data?.done || []).map((s) => s.step), [data?.done])
  const updateStep = useUpdateOnboarding()
  const user = useCurrentUser()
  const project = useCurrentProject()
  const defaultOpen = useMemo(() => {
    if (dialogInteracted()) {
      return false
    }

    if (!isLoading && finishedSteps.includes('best-practices')) {
      return false
    }

    return true
  }, [finishedSteps, isLoading])

  const koalaUDisclousure = useDisclosure({
    defaultIsOpen: defaultOpen
  })

  const trackKoalaUInteracted = () => {
    window.localStorage.setItem('koala-u-dialog-interacted', Date.now().toString())
    updateStep.mutateAsync({ step: 'best-practices', category: 'mission_control', status: 'completed' })
  }

  useEffect(() => {
    if (Boolean(window.localStorage.getItem('koala-u-dialog-view')) == false && defaultOpen) {
      const timer = setTimeout(() => {
        window.localStorage.setItem('koala-u-dialog-view', Date.now().toString())
        window.ko?.track('KoalaU dialog viewed', project?.slug ? { project: project.slug, user: user } : {})
      }, 2000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [project, koalaUDisclousure.isOpen, user, defaultOpen])

  if (isLoading) {
    return null
  }

  return (
    <CornerDialog
      {...koalaUDisclousure}
      isOpen={defaultOpen && koalaUDisclousure.isOpen}
      spacing="4"
      padding="10px 10px 16px 10px"
      borderRadius={15}
      width="335px"
      iconButtonOptions={{
        top: '15px',
        right: '15px',
        'aria-label': 'Close Dialog'
      }}
      slideOptions={{
        delay: {
          enter: 2,
          exit: 0
        }
      }}
      onClose={() => {
        window.ko?.track('KoalaU dialog closed', project?.slug ? { project: project.slug, user: user } : {})
        trackKoalaUInteracted()
        koalaUDisclousure.onClose()
      }}
    >
      <Image src={KoalaUBannerSvg} />
      <Stack padding="10px 16px" spacing="4">
        <Heading size="sm">Announcing Koala University</Heading>
        <Text fontSize="md" color="gray.600">
          Get tips, and strategies to maximize your Koala experience
        </Text>
        <Button
          as={Link}
          href="https://getkoala.com/university?utm_source=koala&utm_medium=onboarding&utm_campaign=koalau"
          aria-label="Go to Koala University"
          colorScheme="purple"
          background={'purple.600'}
          isExternal
          onClick={() => {
            window.ko?.track('KoalaU link clicked', project?.slug ? { project: project.slug, user: user } : {})
            trackKoalaUInteracted()
            koalaUDisclousure.onClose()
          }}
        >
          Go to Koala University
          <IconArrowUpRight size={18} />
        </Button>
      </Stack>
    </CornerDialog>
  )
}
