import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Collapse,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useDisclosure
} from '@chakra-ui/react'
import { IconAlertTriangle, IconCheck, IconChevronRight } from '@tabler/icons-react'
import React, { useCallback } from 'react'
import { toast } from 'sonner'
import { post } from '../../../../lib/api'
import { User } from '../../../../types/Invite'
import { LightBgCard } from '../../../ui/Card'
import { projectPath } from '../../../ui/ProjectsContext'
import { HeroImage } from '../components/HeroImage'
import { Step } from '../components/SetupProject'
import { StepProps } from '../rep_onboarding'
import img from './rep-needs-setup.png'

export function NeedsSetup(props: StepProps & { admins: User[] }) {
  const disclosure = useDisclosure()
  const [shouldSend, setShouldSend] = React.useState(false)
  const [sending, setSending] = React.useState(false)
  const [sent, setSent] = React.useState(false)
  const [emails, setEmails] = React.useState(props.admins.map((a) => a.email).join(', '))
  const [message, setMessage] = React.useState(`Hello! I'd like some help onboarding into Koala.`)

  const sendReminder = useCallback(() => {
    setSending(true)
    post(projectPath('/welcome/reminder'), {
      reminder: {
        emails: emails.split(',').map((e) => e.trim()),
        message
      }
    })
      .then(() => {
        setSent(true)
        toast.success('Reminder sent', {
          description: 'Your admin has been notified.'
        })

        setTimeout(() => {
          setSent(false)
          setShouldSend(false)
        }, 5000)
      })
      .catch(() => {
        toast.error('There was an error sending the reminder.')
      })
      .finally(() => {
        setSending(false)
        disclosure.onClose()
      })
  }, [disclosure, emails, message])

  return (
    <>
      <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} isCentered size="2xl" variant={'wide'}>
        <ModalOverlay bg="blackAlpha.800" />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p="8">
            <Stack spacing="6">
              <HStack>
                <Icon as={IconAlertTriangle} boxSize="10" />
                <Heading size="lg">Workspace not configured</Heading>
              </HStack>
              <Stack spacing="0">
                <Text>Your workspace does not seem to be fully configured yet.</Text>
                <Text>Functionality will be limited until your admin finishes setting it up.</Text>
              </Stack>
              <HStack w="100%">
                <Button
                  flex="1"
                  variant="outline"
                  rightIcon={<IconChevronRight size="14" />}
                  onClick={props.onSkip}
                  disabled={sending}
                >
                  Go to Workspace anyway
                </Button>
                <Button
                  flex="1"
                  colorScheme="purple"
                  isLoading={sending}
                  onClick={() => {
                    disclosure.onClose()
                    setShouldSend(true)
                  }}
                  loadingText={'Sending Email...'}
                >
                  Email Admin
                </Button>
              </HStack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Step cardProps={{ maxW: 800 }} {...props}>
        <HeroImage src={img} h="340px" />
        <Stack p="8" spacing="8" pt="0">
          <Stack>
            <Heading size="md">Your admin needs to configure your account data</Heading>
            {sent && (
              <Flex py="2">
                <Alert status="success" variant="left-accent">
                  <AlertIcon />
                  <AlertTitle>Email sent.</AlertTitle>
                  <Text fontSize="sm">
                    Your admin has been notified. We'll let you know once your workspace is fully set up.
                  </Text>
                </Alert>
              </Flex>
            )}
            {!sent && (
              <>
                <Text fontSize={'sm'} color="gray.500">
                  We're still waiting for your admin to finish setup. If you'd like, you can give them a friendly nudge
                  to let them know you're waiting!
                </Text>
              </>
            )}
          </Stack>

          <form
            onSubmit={(e) => {
              e.preventDefault()
              if (shouldSend) {
                sendReminder()
              } else {
                setShouldSend(true)
              }
            }}
          >
            <Collapse in={shouldSend}>
              <Box pb={4}>
                <LightBgCard bg="gray.50" as={Stack} p="6">
                  <FormControl>
                    <FormLabel>To</FormLabel>
                    <Input
                      rounded={'md'}
                      size="sm"
                      value={emails}
                      onChange={(e) => setEmails(e.target.value)}
                      bg="white"
                    />
                  </FormControl>
                  <FormControl p="0.5">
                    <FormLabel>Message</FormLabel>
                    <Textarea
                      rounded={'md'}
                      size="sm"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      bg="white"
                    ></Textarea>
                    <FormHelperText>
                      *We'll send your note, along with instructions on how to finish onboarding your workspace.
                    </FormHelperText>
                  </FormControl>
                </LightBgCard>
              </Box>
            </Collapse>
            <Stack>
              <Button
                colorScheme={'purple'}
                type="submit"
                isLoading={sending}
                loadingText={'Sending Email...'}
                disabled={sent}
                _disabled={{
                  cursor: 'default',
                  opacity: 0.6
                }}
                rightIcon={sent ? <IconCheck size="14" /> : undefined}
              >
                {sent ? 'Email sent' : shouldSend ? 'Send Email' : 'Send Email Reminder'}
              </Button>

              {!sent && (
                <Button
                  color="gray.500"
                  variant={'ghost'}
                  onClick={() => {
                    if (sent) {
                      props.onSkip()
                    } else {
                      disclosure.onOpen()
                    }
                  }}
                  rightIcon={<IconChevronRight size={14} />}
                  disabled={sending}
                >
                  Do it later
                </Button>
              )}
            </Stack>
          </form>
        </Stack>
      </Step>
    </>
  )
}
