import { useQuery } from 'react-query'
import { concurrentGET } from '../../lib/api'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

export interface CrmUser {
  id: string
  email: string
  name?: string
  external_id: string
  source: string
}

interface Data {
  users: CrmUser[]
}

export function useCrmUsers(options?: { enabled?: boolean }) {
  const project = useCurrentProject()
  const path = projectPath('/crm-users')

  return useQuery<Data>(['crm-users', { projectId: project?.id }], () => concurrentGET(path), {
    enabled: options?.enabled ?? Boolean(project?.id)
  })
}
