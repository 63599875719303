import { Icon, IconProps } from '@chakra-ui/react'
import React from 'react'

interface Props extends IconProps {
  size?: string | number
}

export function BroomIcon({ size, ...props }: Props) {
  size = size ? `${size}px` : size
  return (
    <Icon viewBox="0 0 16 16" fill="currentColor" width={size} height={size} {...props}>
      <path
        d="M10.6667 7.33335H10V2.00002C10 1.26669 9.4 0.666687 8.66667 0.666687H7.33333C6.6 0.666687 6 1.26669 6 2.00002V7.33335H5.33333C3.49333 7.33335 2 8.82669 2 10.6667V15.3334H14V10.6667C14 8.82669 12.5067 7.33335 10.6667 7.33335ZM7.33333 2.00002H8.66667V7.33335H7.33333V2.00002ZM12.6667 14H11.3333V12C11.3333 11.6334 11.0333 11.3334 10.6667 11.3334C10.3 11.3334 10 11.6334 10 12V14H8.66667V12C8.66667 11.6334 8.36667 11.3334 8 11.3334C7.63333 11.3334 7.33333 11.6334 7.33333 12V14H6V12C6 11.6334 5.7 11.3334 5.33333 11.3334C4.96667 11.3334 4.66667 11.6334 4.66667 12V14H3.33333V10.6667C3.33333 9.56669 4.23333 8.66669 5.33333 8.66669H10.6667C11.7667 8.66669 12.6667 9.56669 12.6667 10.6667V14Z"
        fill="#4A5568"
      />
    </Icon>
  )
}
