import { useEffect } from 'react'
import createPersistedState from 'use-persisted-state'
import { Project } from '../../types/Project'
import { useCurrentProject } from './ProjectsContext'
import useLocation from './useLocation'

export const usePersistedLayoutMode = createPersistedState<string>('koala:layout-mode')

const oldNavWorkspaces = ['segment', 'hightouch', 'retool', 'sanity']

export function shouldDefaultNewNav(project?: Project) {
  return project?.slug && !oldNavWorkspaces.includes(project.slug)
}

export function useLayoutMode() {
  const project = useCurrentProject()
  const defaultMode = shouldDefaultNewNav(project) ? 'new' : undefined
  const [mode, setLayoutMode] = usePersistedLayoutMode(defaultMode || window.layout_mode || 'old')
  const location = useLocation()

  useEffect(() => {
    const url = new URL(location.href)

    if (url.searchParams.has('layout')) {
      setLayoutMode((prev) => {
        const newMode = url.searchParams.get('layout') || prev
        window.layout_mode = newMode
        return newMode
      })

      // remove it from the URL
      url.searchParams.delete('layout')
      window.history.replaceState(null, '', url.toString())
    }
  }, [setLayoutMode, location.href])

  let compatMode = defaultMode || mode
  if (compatMode === 'rep') {
    compatMode = 'new'
  } else if (compatMode === 'default') {
    compatMode = 'old'
  }

  return compatMode
}
