import React from 'react'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import SettingsHeader from '../../ui/SettingsHeader'
import { SuggestedChange } from './components/SuggestedChange'
import { Suggestion } from './types'
interface Props {
  suggestion: Suggestion
}

export default function Show(props: Props) {
  return (
    <PageLayout>
      <SettingsHeader>
        <PageTitle>Suggested Edit</PageTitle>
        <PageDescription>Review this suggested change to company data.</PageDescription>
      </SettingsHeader>

      <SuggestedChange {...props.suggestion} />
    </PageLayout>
  )
}
