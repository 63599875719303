import { useMutation } from 'react-query'
import { post } from '../../lib/api'
import { projectPath } from '../ui/ProjectsContext'

interface AOBRecordParams {
  ruleId: string
  recordIds: Array<number | string>
}

interface RemovalResponse {
  success: boolean
  removed_record_ids: number[]
}

export function useRemoveAOBRecords() {
  return useMutation<RemovalResponse, unknown, AOBRecordParams>(({ ruleId, recordIds }) => {
    const path = projectPath(`/auto-outbound/${ruleId}/members/remove`)
    return post(path, {
      record_ids: recordIds
    })
  })
}
