import * as React from 'react'
import { Apps, CatalogApp } from '../../../types/App'
import { ProjectActions } from '../../../types/AppActions'
import { KQLByDay } from '../../../types/KQL'
import type { ProfileRecord } from '../../../types/Profile'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { TopBarContent } from '../../ui/TopBarContext'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { KqlDefinition } from '../kql_definitions/types'
import Profile from './components/Profile'

interface Props {
  profile: ProfileRecord
  apps: Apps
  catalog?: CatalogApp[]
  kqls_by_day: KQLByDay[]
  kql_definitions: KqlDefinition[]
  project_actions: ProjectActions
}

export default function Show(props: Props) {
  return (
    <PageLayout size="full" flush>
      <PageTitle skipRendering>{props.profile.display_name || 'Anonymous'}</PageTitle>

      <TopBarContent onlyNewNav>
        <Breadcrumb
          paths={[
            { path: projectPath('/people'), title: 'People' },
            { path: window.location.toString(), title: props.profile.display_name || 'Anonymous' }
          ]}
        />
      </TopBarContent>

      <Profile
        profile={props.profile}
        apps={props.apps}
        catalog={props.catalog}
        projectActions={props.project_actions}
        signalsByDay={props.kqls_by_day}
        signalDefinitions={props.kql_definitions}
      />
    </PageLayout>
  )
}
