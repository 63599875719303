import {
  Alert,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  HStack,
  Heading,
  Img,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { IconAlertCircle, IconArrowRight, IconDragDrop2 } from '@tabler/icons-react'
import { arrayMoveImmutable } from 'array-move'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import { Apps } from '../../../../types/App'
import { IntentSequenceMapping, OutreachRules } from '../../../../types/AutoOutbound'
import { useApp } from '../../../data/use-app'
import { useAppDep } from '../../../data/use-app-dep'
import { useIntentSignals } from '../../../data/use-intent-signals'
import { Card } from '../../../ui/Card'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import { PopupConnectDialog } from '../../apps/components/ConnectOauthAppDialog'
import { ReconnectionRequiredWarning } from '../../apps/components/ReconnectionRequiredWarning'
import { OutreachSequence } from '../../follow_rules/components/outreach/add-to-sequence'
import { channelLogos } from '../../follow_rules/components//delivery-setup'
import { SignalType } from '../../kql_definitions/components/SignalType'
import { KqlDefinition } from '../../kql_definitions/types'

export interface OutreachRulesProps extends OutreachRules {
  ruleId?: string
  apps: Apps
  kqlDefinitionIds: string[]
  sequences?: OutreachSequence[]
  skippingRouting?: boolean
}

export function OutreachRulesSetup(props: OutreachRulesProps) {
  const colorScheme = 'purple'
  const outreach = useApp('Outreach')
  const disconnected = useMemo(() => !outreach.data?.connected, [outreach.data])
  const invalid = useMemo(() => !outreach.data?.valid, [outreach.data])

  const [sequenceOption, setSequenceOption] = React.useState(props.strategy ?? 'single')
  const [selectedSequenceId, setSelectedSequenceId] = React.useState<OutreachSequence['id'] | null>(
    props.sequence_id ?? null
  )

  const signals = useIntentSignals()

  const kqlDefinitions = useMemo(() => {
    const allDefs = signals.data?.definitions ?? []
    return allDefs.filter((def) => def.id && props.kqlDefinitionIds.includes(def.id))
  }, [signals.data?.definitions, props.kqlDefinitionIds])

  const onOutreachConnected = useCallback(() => {
    outreach.refetch()
  }, [outreach])

  if (outreach.isLoading) {
    return <Spinner />
  }

  if (outreach.data && disconnected) {
    return (
      <Center w="100%">
        <Flex py="8">
          {outreach.data && disconnected && (
            <PopupConnectDialog app_id={'outreach'} onConnected={onOutreachConnected}>
              {({ onStart }) => (
                <Stack spacing="4">
                  <Stack spacing="0">
                    <Center>
                      <Heading size="sm">Connect your Outreach Account</Heading>
                    </Center>
                    <Center>
                      <Text fontSize="sm" color="gray.600">
                        Please connect your Outreach account in order to get started.
                      </Text>
                    </Center>
                  </Stack>
                  <Center>
                    <Button
                      leftIcon={<Img w="4" src={channelLogos.outreach} />}
                      size="sm"
                      variant={'outline'}
                      onClick={onStart}
                      colorScheme={colorScheme}
                    >
                      Connect Outreach
                    </Button>
                  </Center>
                </Stack>
              )}
            </PopupConnectDialog>
          )}
        </Flex>
      </Center>
    )
  }

  return (
    <FormControl as={Stack} spacing="4">
      {props.skippingRouting && (
        <Alert status="warning">
          <Flex alignItems="center">
            <IconAlertCircle size="16" />
            <Box ml="2">
              <Text fontSize="sm">
                You skipped the routing step. Koala won't be able to assign a sender in Outreach. You need to enable
                routing to use Outreach.
              </Text>
            </Box>
          </Flex>
        </Alert>
      )}
      {invalid && <ReconnectionRequiredWarning appTitle={'Outreach'} variant="short" />}
      <FormHelperText>Define which Outreach Sequence to use for outbounding</FormHelperText>
      <Stack w="100%" spacing="4">
        <Stack as={Card} p="4">
          <Heading size="xs">Sequence Strategy</Heading>
          <RadioGroup
            isDisabled={props.skippingRouting}
            size="sm"
            defaultValue={sequenceOption}
            onChange={(e) => setSequenceOption(e as 'single' | 'custom')}
            name="outreach_rules[strategy]"
          >
            <Stack spacing="0.5">
              <Radio bg="white" value="single">
                Single Sequence
              </Radio>
              <Radio bg="white" value="custom">
                Custom per Intent Signal
              </Radio>
            </Stack>
          </RadioGroup>
        </Stack>

        {!props.skippingRouting && (
          <>
            <Divider />

            {sequenceOption === 'single' && (
              <Stack>
                <Heading size="xs">Sequence</Heading>
                <SequenceSelector
                  sequences={props.sequences}
                  setSelectedSequenceId={setSelectedSequenceId}
                  selectedSequenceId={selectedSequenceId}
                />
                {selectedSequenceId && (
                  <input type={'hidden'} name="outreach_rules[sequence_id]" value={selectedSequenceId} />
                )}
              </Stack>
            )}
            {sequenceOption === 'custom' && (
              <>
                {signals.isLoading && <Spinner size="sm" />}
                {kqlDefinitions.length > 0 && (
                  <IntentSequencePairs
                    sequences={props.sequences}
                    mappings={props.advanced ?? []}
                    kqlDefinitions={kqlDefinitions}
                  />
                )}
              </>
            )}

            {sequenceOption === 'single' && (
              <FormHelperText>Every prospect will be added into the same sequence</FormHelperText>
            )}
            {sequenceOption === 'custom' && (
              <FormHelperText>Each prospect will be added to a specific sequence depending on intent.</FormHelperText>
            )}
          </>
        )}
      </Stack>
    </FormControl>
  )
}

interface SequenceSelectorProps {
  selectedSequenceId?: OutreachSequence['id'] | null
  setSelectedSequenceId: (id: OutreachSequence['id'] | null) => void
  sequences?: OutreachSequence[]
}

function SequenceSelector(props: SequenceSelectorProps) {
  const { data: sequenceData, isLoading: loadingSequences } = useAppDep<'all_sequences!', OutreachSequence[]>(
    'Outreach',
    'all_sequences!',
    {},
    true
  )

  const sequences = useMemo(() => {
    return props.sequences ?? sequenceData?.data?.['all_sequences!'] ?? []
  }, [props.sequences, sequenceData])

  const selectedItem = useMemo(
    () =>
      sequenceData?.data?.['all_sequences!']?.find((s) => {
        return s.id.toString() === props.selectedSequenceId?.toString()
      }) ?? null,
    [sequenceData, props.selectedSequenceId]
  )

  return (
    <>
      <HStack w="100%">
        {loadingSequences && <Spinner size="sm" />}
        <ComboboxWithSearch
          items={sequences}
          selectedItem={selectedItem}
          onChange={(selectedItem) => {
            props.setSelectedSequenceId(selectedItem?.id ?? null)
          }}
          filterItem={(a, val) => a.attributes.name.toLowerCase().includes(val)}
          itemToString={(item) => `[${item?.id}] ${item?.attributes?.name || ''}`}
        />
      </HStack>
    </>
  )
}

interface IntentSignalPairsProps {
  kqlDefinitions: KqlDefinition[]
  mappings: IntentSequenceMapping[]
  sequences?: OutreachSequence[]
}

function IntentSequencePairs(props: IntentSignalPairsProps) {
  const mappings = useMemo(() => {
    const maps = props.mappings ?? []

    const mapped = props.kqlDefinitions.reduce((acc, kqlDef) => {
      const map = maps.find((m) => m.kql_definition_id === kqlDef.id)

      acc.push({
        kql_definition_id: kqlDef.id!,
        sequence_id: map?.sequence_id
      })

      return acc
    }, [] as IntentSequenceMapping[])

    // sort mapped by the positions in props.mappings
    return mapped.sort((a, b) => {
      const aIndex = props.mappings.findIndex((m) => m.kql_definition_id === a.kql_definition_id)
      const bIndex = props.mappings.findIndex((m) => m.kql_definition_id === b.kql_definition_id)

      return aIndex - bIndex
    })
  }, [props.mappings, props.kqlDefinitions])

  const [localMappings, setLocalMappings] = useState<IntentSequenceMapping[]>(mappings)

  useEffect(() => {
    setLocalMappings(mappings)
  }, [mappings])

  return (
    <>
      <Stack>
        <Heading size="xs">Pair each Intent Signal to a Sequence</Heading>
        <SortableList
          onSortEnd={(oldIndex: number, newIndex: number) => {
            setLocalMappings((array) => arrayMoveImmutable(array, oldIndex, newIndex))
          }}
          draggedItemClassName="dragged"
          style={{
            userSelect: 'none'
          }}
        >
          <Stack>
            {localMappings.map((entry) => {
              const kqlDefinition = props.kqlDefinitions.find((def) => def.id === entry.kql_definition_id)
              if (!kqlDefinition?.id) {
                return null
              }

              const sequenceId = entry.sequence_id

              return (
                <SortableItem key={kqlDefinition.id}>
                  <HStack w="100%">
                    <SortableKnob>
                      <Box cursor={'grab'}>
                        <Tooltip label="Drag to change priorities" placement="top">
                          <IconDragDrop2 color="gray" size="14" />
                        </Tooltip>
                      </Box>
                    </SortableKnob>
                    <IntentSequencePair
                      sequences={props.sequences}
                      kqlDefinition={kqlDefinition}
                      sequenceId={sequenceId}
                    />
                  </HStack>
                </SortableItem>
              )
            })}
          </Stack>
        </SortableList>
      </Stack>
    </>
  )
}

interface IntentSignalPairProps {
  kqlDefinition: KqlDefinition
  sequenceId?: OutreachSequence['id']
  sequences?: OutreachSequence[]
}

function IntentSequencePair(props: IntentSignalPairProps) {
  const [selectedSequenceId, setSelectedSequenceId] = React.useState<OutreachSequence['id'] | null>(
    props.sequenceId ?? null
  )
  const kqlDefinition = props.kqlDefinition

  return (
    <>
      <HStack w="100%">
        <Flex bg="white" as={Card} p="2" flex="1" fontWeight={'normal'}>
          <SignalType
            label={`${kqlDefinition.name}${kqlDefinition.enabled === false ? ' (paused)' : ''}`}
            signalType={kqlDefinition.signal_type}
            compact
          />
        </Flex>
        <IconArrowRight size="14" />
        <Flex w="100%" flex="1">
          <SequenceSelector
            sequences={props.sequences}
            setSelectedSequenceId={setSelectedSequenceId}
            selectedSequenceId={selectedSequenceId}
          />
        </Flex>
      </HStack>
      {selectedSequenceId && (
        <>
          <input type={'hidden'} name={`outreach_rules[advanced][][sequence_id]`} value={selectedSequenceId} />
          <input type={'hidden'} name={`outreach_rules[advanced][][kql_definition_id]`} value={kqlDefinition.id} />
        </>
      )}
    </>
  )
}
