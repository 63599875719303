import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  Flex,
  HStack,
  IconButton,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  Tag,
  Text,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import { IconChevronDown, IconChevronRight, IconDotsVertical, IconExternalLink, IconEyeOff } from '@tabler/icons-react'
import dayjs from 'dayjs'
import { orderBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useLocalStorage } from 'react-use'
import { formatFriendlyCurrency } from '../../../../lib/number-format'
import type { Account, CRMMatch, OpportunityDetails } from '../../../../types/Account'
import type { App } from '../../../../types/App'
import { DetailsCard } from '../../../ui/Card'
import { CardHeading } from '../../../ui/CardHeading'
import { SfOpportunityIcon } from '../../../ui/icons'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { TimeAgo } from '../../../ui/TimeAgo'
import { SearchBar } from '../facets/search-bar'
import { CRMProperties } from './CrmProperties'
import { useUIState } from './DetailsV2/useUIState'

interface SalesforceOpportunitiesCardProps {
  account?: Account
  app?: App
  match?: CRMMatch
  initiallyCollapsed?: boolean
}

function OpportunityRow(props: { opportunity: OpportunityDetails; account?: Account }) {
  const { opportunity, account } = props
  const [showProperties, setShowProperties] = useState(false)

  return (
    <>
      <HStack alignItems={'flex-start'} w="100%" py={2} justifyContent="space-between" color="gray.600">
        <IconButton
          size="xs"
          variant={'ghost'}
          aria-label="Show Properties"
          icon={showProperties ? <IconChevronDown size="14" /> : <IconChevronRight size="14" />}
          onClick={() => setShowProperties(!showProperties)}
        />
        <Stack spacing="0" flex="1">
          <TextEllipsis maxW="150" tooltip fontWeight={'semibold'}>
            {opportunity.stage}
          </TextEllipsis>
          <TextEllipsis maxW="150" tooltip fontSize={'xs'}>
            {opportunity.name}
          </TextEllipsis>
          <Text fontSize="xs">
            Close Date: <TimeAgo time={opportunity.close_date} format="M/D/YY" />
          </Text>
        </Stack>

        <Stack spacing="1" alignItems={'center'}>
          <Tag size="sm" fontSize="xs" colorScheme={opportunity.is_open ? 'purple' : 'gray'}>
            {opportunity.is_open ? 'Open' : 'Closed'}
          </Tag>
          <Text>{formatFriendlyCurrency(opportunity.amount)}</Text>
        </Stack>

        <Tooltip label="View in Salesforce" placement="top" hasArrow arrowSize={6}>
          <IconButton
            aria-label="View in Salesforce"
            as={LinkOverlay}
            href={opportunity.permalink}
            isExternal
            size="xs"
            variant="link"
            icon={<IconExternalLink size={16} />}
            color="gray.500"
            _hover={{ color: 'purple.600' }}
            onClick={() => {
              window.ko?.track('Salesforce Opp Action', {
                app: 'salesforce',
                account
              })
            }}
          />
        </Tooltip>
      </HStack>

      <Box w="100%" px="4">
        <Collapse in={showProperties}>
          <CRMProperties payload={opportunity?.payload ?? {}} />
        </Collapse>
      </Box>
    </>
  )
}

export function SalesforceOpportunitiesCard(props: SalesforceOpportunitiesCardProps) {
  const [ui, setUI] = useUIState()

  const cardDisclosure = useDisclosure({
    defaultIsOpen: ui.open?.salesforce_opportunities ?? !props.initiallyCollapsed,
    onOpen: () => {
      setUI({ open: { salesforce_opportunities: true } })
    },
    onClose: () => {
      setUI({ open: { salesforce_opportunities: false } })
    }
  })

  const showAll = useMemo(() => ui.open?.all_opportunities ?? true, [ui.open?.all_opportunities])
  const displayAll = useMemo(() => ui.open?.display_all_opportunities ?? false, [ui.open?.display_all_opportunities])
  const [search, setSearch] = useLocalStorage('opp_search', '')

  const opportunities = useMemo(() => {
    let opps = orderBy(props.match?.opportunities ?? [], (op) => dayjs(op.close_date).unix(), 'desc')
    opps = opps.filter((op) => showAll || op.is_open)

    if (search) {
      opps = opps.filter((op) => {
        return (
          op.name.toLowerCase().startsWith(search.toLowerCase()) ||
          op.stage.toLowerCase().startsWith(search.toLowerCase())
        )
      })
    }

    if (displayAll) {
      return opps
    }

    return opps
  }, [props.match, showAll, displayAll, search])

  const allOpps = useMemo(() => props.match?.opportunities ?? [], [props.match])

  return (
    <DetailsCard px={0}>
      <Flex px="5" w="100%" pr="3">
        <CardHeading icon={SfOpportunityIcon} disclosure={cardDisclosure} w="100%">
          <Text flex="1">Salesforce Opportunities ({allOpps.length})</Text>
          <Menu size="sm">
            <MenuButton
              onClick={(e) => e.stopPropagation()}
              as={IconButton}
              icon={showAll ? <IconDotsVertical size="14" /> : <IconEyeOff size="14" />}
              variant="ghost"
              size="sm"
            />
            <Portal>
              <MenuList>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setUI({ open: { all_opportunities: !showAll } })
                  }}
                >
                  <Checkbox size="sm" isChecked={!showAll}>
                    Hide Closed Opportunities
                  </Checkbox>
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        </CardHeading>
      </Flex>

      {cardDisclosure.isOpen && (
        <Stack w="100%" px="4" pr="6">
          {allOpps.length > 0 && (
            <Stack divider={<Divider />} fontSize="sm" w="100%">
              <Flex pb="2">
                <SearchBar size="sm" value={search} onChange={setSearch} />
              </Flex>

              {opportunities.map((opportunity) => (
                <Stack key={opportunity.id} w="100%">
                  <OpportunityRow opportunity={opportunity} account={props.account} />
                </Stack>
              ))}
            </Stack>
          )}

          {allOpps.length > 0 && opportunities.length === 0 && (
            <Text fontSize="xs" color="gray.500">
              No opportunities to show
            </Text>
          )}

          {allOpps.length === 0 && (
            <Text fontSize="xs" color="gray.500">
              No opportunities linked yet
            </Text>
          )}
        </Stack>
      )}
    </DetailsCard>
  )
}
