import * as React from 'react'
import { useFirstMountState } from './useFirstMountState'

const useUpdateEffect: typeof React.useEffect = (effect, deps) => {
  const isFirstMount = useFirstMountState()

  React.useEffect(() => {
    if (!isFirstMount) {
      return effect()
    }
    // We know what we are doing here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export default useUpdateEffect
