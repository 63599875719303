import { projectPath } from '../../../ui/ProjectsContext'

export function billingPath() {
  return projectPath('/settings/billing')
}

export function billingPlansPath() {
  return projectPath('/settings/plans')
}

export function billingCheckoutPath(rest = '') {
  return projectPath('/settings/billing/checkout' + rest)
}

export function billingManagePath() {
  return projectPath('/settings/billing/manage')
}

export function billingPricingRequest() {
  return projectPath('/settings/billing/pricing-request')
}

export function billingMetricsPath(rest = '') {
  return projectPath('/settings/billing/metrics' + rest)
}
