import { Box, BoxProps } from '@chakra-ui/react'
import { useVirtualizer, VirtualizerOptions } from '@tanstack/react-virtual'
import React from 'react'
import { useOverflow } from './useOverflow'

export function VirtualList<T>(props: {
  items: Array<T>
  maxH: string | number | undefined
  minH?: string | number
  containerStyles?: BoxProps
  showOverflowFade?: boolean
  estimateSize: VirtualizerOptions<any, any>['estimateSize']
  renderItem: (item: T, index: number) => React.ReactNode | JSX.Element
}) {
  const { scrollRef, overflowBottom, overflowTop } = useOverflow()

  const rowVirtualizer = useVirtualizer({
    count: props.items.length,
    getScrollElement: () => scrollRef.current,
    estimateSize: props.estimateSize,
    overscan: 5
  })

  const rows = rowVirtualizer.getVirtualItems()

  return (
    <Box
      maxHeight={props.maxH}
      overflow={props.maxH ? 'auto' : undefined}
      minHeight={props.minH}
      width="100%"
      {...props.containerStyles}
      ref={scrollRef}
    >
      <Box
        position="absolute"
        left={0}
        right={0}
        top={0}
        height="40px"
        zIndex={10}
        bgGradient="linear(to-t, rgba(255,255,255,0.1), #FFFFFF)"
        opacity={overflowTop && props.showOverflowFade ? 1 : 0}
        transition="opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
        pointerEvents="none"
      />
      <Box
        position="absolute"
        left={0}
        right={0}
        bottom={0}
        height="40px"
        zIndex={10}
        bgGradient="linear(to-b, rgba(255,255,255,0.1), #FFFFFF)"
        opacity={overflowBottom && props.showOverflowFade ? 1 : 0}
        transition="opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
        pointerEvents="none"
      />
      <Box>
        <div
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
            listStyle: 'none'
          }}
        >
          {/* https://tanstack.com/virtual/v3/docs/framework/react/examples/dynamic */}
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${rows[0]?.start ?? 0}px)`
            }}
          >
            {rows.map((row) => {
              const key = props.items[row.index]

              return (
                <div
                  key={JSON.stringify(key) + row.index}
                  data-index={row.index}
                  ref={rowVirtualizer.measureElement}
                  role="group"
                  style={{
                    display: 'flex',
                    listStyle: 'none'
                  }}
                >
                  {props.renderItem(props.items[row.index], row.index)}
                </div>
              )
            })}
          </div>
        </div>
      </Box>
    </Box>
  )
}
