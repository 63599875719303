import { useQuery } from 'react-query'
import { post } from '../../lib/api'
import { TimeseriesData } from '../pages/analytics/components/timeseries'
import { FunnelStep } from '../pages/dashboards/components/funnel'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

export type Entry = Record<string, any>

export interface AnalyticsData {
  data: {
    // Total w/ trend data
    total?: number
    previous?: number
    change?: number

    // Timeseries data
    timeseries?: TimeseriesData[]
    granularity?: 'hour' | 'day'

    // Non-timeseries data
    entries?: Entry[]
  }
}

export interface DashboardFunnelStep {
  name: string
  accounts: number
  visitors: number
}

export interface DashboardFunnelData {
  funnel: DashboardFunnelStep[]
}

export function useDashboardWidget(
  id: string,
  depParams: object = {},
  facetQueryString?: string,
  accountIds?: string[],
  ignoreHidden?: string
) {
  const project = useCurrentProject()

  const params = Object.keys(depParams)
    .map((key) => `widget_params[${key}]=${encodeURIComponent(depParams[key])}`)
    .join('&')

  let basePath = `/dashboards/widget/${id}?${params}`
  if (facetQueryString) {
    basePath += `&${facetQueryString.replace('?', '')}`
  }

  if (ignoreHidden === 'true' || ignoreHidden === '1') {
    basePath += '&ignore_hidden=1'
  }

  const path = projectPath(basePath)

  return useQuery<AnalyticsData>(
    ['dashboard-widget', { projectId: project?.id, path, accountIds, ignoreHidden }],
    () => post<AnalyticsData>(path, { account_ids: accountIds }),
    {
      enabled: Boolean(project?.id)
    }
  )
}

export function useDashboardFunnel(
  steps: FunnelStep[],
  depParams: object = {},
  facetQueryString?: string,
  accountIds?: string[],
  ignoreHidden?: string
) {
  const project = useCurrentProject()

  const params = Object.keys(depParams)
    .map((key) => `widget_params[${key}]=${encodeURIComponent(depParams[key])}`)
    .join('&')

  let basePath = `/dashboards/funnel?${params}`
  if (facetQueryString) {
    basePath += `&${facetQueryString.replace('?', '')}`
  }

  if (ignoreHidden === 'true' || ignoreHidden === '1') {
    basePath += '&ignore_hidden=1'
  }

  const path = projectPath(basePath)

  return useQuery<DashboardFunnelData>(
    ['dashboards-funnel', { projectId: project?.id, path, steps, accountIds, ignoreHidden }],
    () =>
      post<DashboardFunnelData>(path, {
        funnel: {
          steps
        },
        account_ids: accountIds
      }),
    {
      enabled: Boolean(project?.id)
    }
  )
}
