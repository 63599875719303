import { projectPath } from '../../../ui/ProjectsContext'

export function accountViewPath(view: { slug: string }, extra?: string): string {
  return projectPath(`/views/${view.slug}${extra ?? ''}`)
}

export const customViewsPath = () => {
  const basePath = projectPath(`/views`)
  const search = window.location.search
  return `${basePath}${search}`
}
