import { Icon, IconProps } from '@chakra-ui/react'
import React from 'react'

interface Props extends IconProps {
  size?: string | number
}

export function TwitterIcon({ size, ...props }: Props) {
  size = size ? `${size}px` : size
  return (
    <Icon viewBox="0 0 24 24" fill="currentColor" width={size} height={size} {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M13.6756 10.6218L20.2325 3H18.6787L12.9854 9.61788L8.43821 3H3.19354L10.0698 13.0074L3.19354 21H4.74739L10.7596 14.0113L15.5618 21H20.8065L13.6753 10.6218H13.6756ZM11.5474 13.0956L10.8507 12.0991L5.30726 4.16971H7.69387L12.1675 10.5689L12.8642 11.5655L18.6794 19.8835H16.2928L11.5474 13.096V13.0956Z"
        fill="currentColor"
      />
    </Icon>
  )
}
