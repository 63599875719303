import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Select,
  Stack
} from '@chakra-ui/react'
import { IconX } from '@tabler/icons-react'
import { nanoid } from 'nanoid'
import React from 'react'
import { Toggle } from '../accounts/components/Toggle'
import { MatcherPattern } from './manager'

export interface PatternRulesProps {
  matcherPatterns: MatcherPattern[]
  setMatcherPatterns: (patterns: MatcherPattern[]) => void
  isLoading: boolean
  type: 'page' | 'event'
}

export function PatternRules(props: PatternRulesProps) {
  const { matcherPatterns, setMatcherPatterns } = props

  return (
    <FormControl bg="gray.50" rounded="md" p="6">
      <Toggle
        title={
          <FormLabel fontSize={'sm'} fontWeight="semibold" mb="0" cursor={'pointer'}>
            Disabled patterns ({matcherPatterns.length})
          </FormLabel>
        }
      >
        <Stack p="1" spacing="4">
          <Stack>
            <FormHelperText pl="2" mb="0">
              Block all {props.type}s matching the following patterns:
            </FormHelperText>
            {matcherPatterns.map((pattern) => (
              <HStack w="100%" key={pattern.id}>
                <input type="hidden" name="id" value={pattern.id} />
                <Select
                  size="sm"
                  bg="white"
                  rounded="md"
                  w="40"
                  required
                  value={pattern.operator}
                  disabled={props.isLoading}
                  onChange={(e) => {
                    const newPatterns = [...matcherPatterns]
                    const index = newPatterns.findIndex((p) => p.id === pattern.id)
                    newPatterns[index].operator = e.target.value as MatcherPattern['operator']
                    setMatcherPatterns(newPatterns)
                  }}
                >
                  <option value={'exact'}>is</option>
                  <option value={'starts_with'}>starts with</option>
                  <option value={'ends_with'}>ends with</option>
                  <option value={'contains'}>contains</option>
                  <option value={'matches'}>matches</option>
                </Select>
                <Input
                  bg="white"
                  size="sm"
                  rounded="md"
                  required
                  disabled={props.isLoading}
                  placeholder="Enter a pattern to ignore. e.g. /blog/*"
                  value={pattern.pattern}
                  onChange={(e) => {
                    const newPatterns = [...matcherPatterns]
                    const index = newPatterns.findIndex((p) => p.id === pattern.id)
                    newPatterns[index].pattern = e.target.value
                    setMatcherPatterns(newPatterns)
                  }}
                />
                <IconButton
                  aria-label="Remove"
                  icon={<IconX size={14} />}
                  size="sm"
                  onClick={() => {
                    setMatcherPatterns(matcherPatterns.filter((p) => p.id !== pattern.id))
                  }}
                  variant="ghost"
                />
              </HStack>
            ))}

            <HStack justifyContent={'flex-end'} pr="10">
              <Button
                size="sm"
                colorScheme={'purple'}
                variant="outline"
                disabled={props.isLoading}
                onClick={() => {
                  setMatcherPatterns([
                    ...matcherPatterns,
                    {
                      id: nanoid(),
                      operator: 'contains',
                      pattern: ''
                    }
                  ])
                }}
              >
                New Pattern
              </Button>
            </HStack>
          </Stack>

          <Button variant={'solid'} size="sm" colorScheme={'purple'} type="submit" isLoading={props.isLoading}>
            Save Changes
          </Button>
        </Stack>
      </Toggle>
    </FormControl>
  )
}
