import mapValues from 'lodash/mapValues'
import { Facet, FacetFilters, NotFacet } from '../accounts'
import { PersonaFilter, PersonaFilters } from '../prospects/personas'

export type Operator = 'must' | 'should' | 'must_not'

export interface FilterState {
  values: string[]
  operator: Operator
}

const emptyArray = []

export function getValues(filter: FilterState | PersonaFilter | undefined): string[] {
  return filter?.values ?? emptyArray
}

export function getOperator(filter: FilterState | PersonaFilter | undefined): Operator {
  return filter?.operator ?? 'must'
}

export function getFacetValues(facet: Facet | FilterState) {
  if (!facet) {
    return emptyArray
  }

  if (Array.isArray(facet)) {
    return facet as string[]
  }

  if ('not' in (facet as NotFacet)) {
    return (facet as NotFacet).not as string[]
  }

  return emptyArray
}

export function getFacetOperator(facet: Facet): Operator {
  if (!facet) {
    return 'must'
  }

  if (Array.isArray(facet)) {
    return 'must'
  }

  if ('not' in (facet as NotFacet)) {
    return 'must_not'
  }

  return 'must'
}

export function convertUrlFacetsToPersonaFilters(facets: FacetFilters): Record<string, FilterState> {
  const converted: Record<string, FilterState> = {}

  // convert to FilterState from URL facets
  for (const [key, facet] of Object.entries(facets)) {
    const operator = getFacetOperator(facet)
    const values = getFacetValues(facet)

    converted[key] = {
      values,
      operator
    }
  }

  return converted
}

export function convertFilterForUI({ operator, values }: PersonaFilter): Facet {
  if (operator === 'must') {
    return values
  }

  if (operator === 'must_not') {
    return { not: values }
  }

  return []
}

export function convertFiltersForUI(filters: PersonaFilters): Record<string, Facet> {
  return mapValues(filters, convertFilterForUI) as any as Record<string, Facet>
}
