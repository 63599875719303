import { Box, BoxProps, chakra, Flex, Icon, useCheckbox, UseCheckboxProps } from '@chakra-ui/react'
import { IconCheck } from '@tabler/icons-react'
import React from 'react'

type Props = UseCheckboxProps & BoxProps

export function CheckboxCard(props: React.PropsWithChildren<Props>) {
  const { state, getCheckboxProps, getInputProps, getLabelProps, getRootProps } = useCheckbox({
    isFocusable: true,
    ...props
  })

  return (
    <chakra.label
      position="relative"
      width="100%"
      display="flex"
      rounded="lg"
      padding={4}
      alignItems="center"
      gap={4}
      cursor="pointer"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Flex
        flex="none"
        alignItems="center"
        justifyContent="center"
        w={5}
        h={5}
        color="white"
        bg={state.isChecked ? 'purple.500' : 'white'}
        border="2px solid"
        borderColor={state.isChecked ? 'purple.500' : 'gray.300'}
        shadow={state.isChecked ? 'none' : 'sm'}
        borderWidth="1.5px"
        rounded="md"
        userSelect="none"
        _focus={{
          outline: 'none',
          boxShadow: 'outline'
        }}
        {...getCheckboxProps()}
      >
        {state.isChecked && <Icon flex="none" as={IconCheck} boxSize="18px" />}
      </Flex>
      <Box flex="1 1 auto" {...getLabelProps()}>
        {props.children}
      </Box>
    </chakra.label>
  )
}
