import * as Rails from '@rails/ujs'
import { Emitter } from '../channels/generic_channel'

export const arrify = (value: unknown): unknown[] => {
  if (value === undefined || value === null) return []
  if (Array.isArray(value)) return value
  return [value]
}

async function jsonResponse<T>(res: Response): Promise<T> {
  let body
  try {
    body = await res.json()
  } catch (e: any) {
    if (e.name === 'AbortError') {
      throw e
    }
    body = {} as T
  }

  if (!res.ok) {
    // Attempt to parse error messages from the response body
    let message = arrify(body?.errors).filter(String).join(',')
    if (!message) {
      message = String(res.status)
    }

    const error: any = new Error(message)
    error.statusCode = res.status
    error.body = body
    throw error
  }

  return body
}

export async function postForm<T>(url: string, data?: FormData, options?: Partial<RequestInit>): Promise<T> {
  let settings: RequestInit = {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'X-CSRF-Token': Rails.csrfToken() ?? ''
    },
    body: data
  }

  if (options) {
    settings = {
      ...settings,
      ...options
    }
  }

  return fetch(url, settings).then((res) => jsonResponse(res))
}

export async function post<T>(url: string, data: object = {}): Promise<T> {
  return request('POST', url, data)
}

export async function put<T>(url: string, data: object): Promise<T> {
  return request('PUT', url, data)
}

export async function del<T>(url: string): Promise<T> {
  return request('DELETE', url)
}

export async function request<T>(method: 'POST' | 'PUT' | 'DELETE', url: string, data?: object): Promise<T> {
  return fetch(url, {
    method: method,
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken() ?? ''
    },
    body: JSON.stringify(data)
  }).then((res) => jsonResponse(res))
}

const gets: { [url: string]: undefined | Promise<unknown> } = {}

export const concurrentGET = async <T>(url: string): Promise<T> => {
  if (gets[url]) {
    return gets[url] as unknown as Promise<T>
  }

  const request = get(url)
  gets[url] = request as unknown as Promise<T>

  return request.then((res) => {
    delete gets[url]
    return res as T
  })
}

const concurrentCache: Record<string, unknown> = {}
export const expireCachedGET = (url: string) => {
  if (concurrentCache[url]) {
    delete concurrentCache[url]
  }
}

export const concurrentCachedGET = async <T>(url: string): Promise<T> => {
  const cached = concurrentCache[url] as T

  const fetchAndUpdate = concurrentGET(url).then((res) => {
    concurrentCache[url] = res
    return res as T
  })

  if (cached) {
    return Promise.resolve(cached)
  }

  return fetchAndUpdate
}

export async function get<T>(url: string): Promise<T> {
  return fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken() ?? ''
    }
  }).then((res) => jsonResponse(res))
}

const cache: Record<string, unknown> = {}
let controller: AbortController | undefined = undefined

const requiresServerNav = (res: Response) =>
  res.url?.includes('/users/login') && res.redirected && res.headers.get('content-type')?.includes('text/html')

export function fetchURL(url: string): Emitter {
  const emitter = new Emitter()
  const cached = cache[url]

  if (cached && !controller) {
    setTimeout(() => emitter.emit('data', cached, true), 0)
  }

  if (controller) {
    controller.abort()
  }

  controller = new AbortController()
  const currentController = controller
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  fetch(url, {
    method: 'GET',
    credentials: 'same-origin',
    signal: controller.signal,
    headers: {
      'X-Client-React': 'true',
      'Content-Type': 'text/html',
      Accept: 'text/html',
      'X-CSRF-Token': Rails.csrfToken() ?? '',
      'X-Timezone': timezone
    }
  })
    .then(async (res) => {
      if (requiresServerNav(res)) {
        location.href = res.url
        return
      }

      const response = await jsonResponse(res)
      cache[url] = response

      // Follow redirects
      if (window.location.href !== res.url) {
        history.replaceState({}, '', res.url)
      }

      emitter.emit('data', response, false)
    })
    .catch((error) => {
      emitter.emit('error', error)
    })
    .then(() => {
      emitter.removeListeners()
      if (currentController === controller) {
        controller = undefined
      }
    })

  return emitter
}
