import { Box, Button, Flex, Heading, HStack, Link, useBreakpointValue, VStack } from '@chakra-ui/react'
import React from 'react'
import { Apps } from '../../../types/App'
import { PageMeta } from '../../../types/PageMeta'
import { useUrlFilters } from '../../data/use-url-filters'
import { BuildingIcon } from '../../ui/icons'
import { TopBarContent } from '../../ui/TopBarContext'
import PageTitle, { SmallPageHeading } from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SegmentedControl } from '../../ui/SegmentedControl'
import { SaveAccountView } from '../account_views/components/AccountViewForm'
import { ListContainer } from '../lists/components/ListContainer'
import { LiveButton } from '../profiles/components/visitor-tabs'
import { Session } from '../sessions'
import { AccountFeed } from '../sessions/components/AccountFeed'
import { FilterPreview } from './components/FilterPreview'

export interface AccountSession extends Session {
  sample_daily_profile_feeds?: Session[]
}

interface Props {
  apps: Apps
  materialized_sessions: AccountSession[]
  page_meta: PageMeta
  permissions?: Record<'can_edit' | 'can_create' | 'can_destroy', boolean>
}

export default function Feed(props: Props) {
  // use a modal if we are on small screens/mobile
  const isSmall = useBreakpointValue({ base: true, sm: false, md: false })

  const facets = useUrlFilters({
    initialRange: 'week'
  })

  const isDirty = !facets.onlyDefaults

  return (
    <ListContainer bg="gray.50">
      <TopBarContent onlyNewNav>
        <Flex alignItems="center" gap={1.5}>
          <SmallPageHeading size="xs" fontWeight="medium">
            Accounts
          </SmallPageHeading>
        </Flex>
      </TopBarContent>

      <VStack spacing={3} alignItems="stretch">
        <Flex
          w="100%"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          flexWrap={['wrap', 'nowrap']}
          direction={['column-reverse', 'row']}
        >
          <PageTitle skipRendering>Accounts</PageTitle>

          <HStack flex="1 1 100%" width="100%" justifyContent="space-between" spacing={3}>
            <HStack spacing={3}>
              <BuildingIcon boxSize={5} />
              <Heading display="inline-flex" gap={4} alignItems="baseline" size="md">
                Accounts
              </Heading>
            </HStack>
          </HStack>

          {!isSmall && (
            <SegmentedControl
              size="sm"
              display={['grid', 'flex']}
              width={['100%', 'auto']}
              gridTemplateColumns="1fr 1px 1fr 1px 1fr"
              marginBottom={['4', '0']}
            >
              <Button
                isActive={!location.pathname.endsWith('/feed')}
                as={Link}
                href={projectPath(`/accounts${location.search}`)}
              >
                Explore
              </Button>
              <Button
                isActive={location.pathname.endsWith('/feed')}
                as={Link}
                href={projectPath(`/accounts/feed${location.search}`)}
              >
                Feed
              </Button>
              <LiveButton />
            </SegmentedControl>
          )}
        </Flex>

        <Flex justifyContent="space-between" gap={4}>
          <FilterPreview {...facets} kind="account" apps={props.apps} range={undefined} />
          {isDirty && (
            <Box marginLeft="auto">
              <SaveAccountView type="account" isFiltering={facets.isFiltering} permissions={props.permissions} />
            </Box>
          )}
        </Flex>

        <AccountFeed {...props} />
      </VStack>
    </ListContainer>
  )
}
