import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  SkeletonCircle,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconInfoCircle } from '@tabler/icons-react'
import React from 'react'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { Notification } from '../../notifications'
import { NotificationEntry } from '../../notifications/components/notification'

interface Props {
  notifications: Notification[]
  loading: boolean
  isOpen: boolean
  onClose: () => void
  lookback: string
  error?: { error_type: string; error_code: string; description: string } | null
}

export function PreviewDrawer(props: Props) {
  const { notifications, loading, isOpen, onClose, lookback, error } = props
  const notificationsCount = notifications.filter((n) => !Boolean(n.context?.fake)).length

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader bg="gray.50">Preview</DrawerHeader>

          <DrawerBody bg="gray.50">
            {!loading && error && (
              <Stack pb="4" fontWeight={'normal'} spacing="1">
                {error.error_type === 'slack_integration' ? (
                  <Text color="red.500">
                    There was an error when creating the provided Slack channel. We received error code{' '}
                    <strong>{error.error_code}:</strong> {error.description}
                  </Text>
                ) : (
                  <Text color="red.500">
                    We received an unexpected error while loading the page. Please contact support@getkoala.com for
                    help.
                  </Text>
                )}
              </Stack>
            )}
            {notificationsCount > 0 && (
              <HStack pb="4" fontWeight={'normal'} spacing="1">
                <Text fontSize={'sm'}>
                  Showing {notificationsCount} most recent matches from {lookback}.
                </Text>
                <HelpTooltip mode="popover" variant="info">
                  <HStack spacing="4">
                    <Icon as={IconInfoCircle} color="purple.500" />
                    <Stack>
                      <Text>
                        Showing {notificationsCount} samples out of your 2,000 most recent Sessions matching your
                        territory rules.
                      </Text>
                      <Text>
                        You should expect to potentially see more matches as more sessions are evaluated going forward
                        outside of Preview Mode.
                      </Text>
                    </Stack>
                  </HStack>
                </HelpTooltip>
              </HStack>
            )}

            {loading && (
              <Stack spacing="4">
                <HStack bg="white" p="4">
                  <SkeletonCircle size={'8'} />
                </HStack>
                <HStack bg="white" p="4">
                  <SkeletonCircle size={'8'} />
                </HStack>
                <HStack bg="white" p="4">
                  <SkeletonCircle size={'8'} />
                </HStack>
              </Stack>
            )}

            {!loading && notificationsCount === 0 && !error && (
              <Stack marginBottom={4} fontSize="sm">
                <Text>
                  We couldn't find any recent sessions matching this configuration. But you can still send a test
                  message to ensure slack connection is working:
                </Text>
              </Stack>
            )}

            <Stack w="100%">
              {notifications.map((notification) => {
                return <NotificationEntry key={notification.id} {...notification} isPreview />
              })}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
