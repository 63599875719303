import { Button, Code, Flex, Link, Text, useClipboard } from '@chakra-ui/react'
import { IconCopy } from '@tabler/icons-react'
import React from 'react'
import { Project } from '../../../../types/Project'
import { snippet } from '../../sdk_settings/index'

export function PixelSnippet(props: { project: Project }) {
  const { hasCopied, onCopy } = useClipboard(snippet(props.project))

  return (
    <Flex direction="column" gap={4}>
      <Text fontSize="sm" color="gray.600">
        Copy the Koala snippet and paste it in the <Code fontSize="xs">{`<body>`}</Code> of your website. For details,
        please see the{' '}
        <Link fontSize="sm" href="https://getkoala.com/docs/get-started/quick-start" isExternal>
          quick start guide
        </Link>
        .
      </Text>
      <pre>
        <Code padding={3} fontSize="xs" rounded="md" width="100%" style={{ overflow: 'auto' }}>
          {snippet(props.project)}
        </Code>
      </pre>
      <Button colorScheme="purple" size="lg" fontSize="md" leftIcon={<IconCopy size={18} />} onClick={onCopy}>
        {hasCopied ? 'Copied!' : 'Copy JavaScript Snippet'}
      </Button>
    </Flex>
  )
}
