import { Box, Button, Center, Flex, HStack, Icon, Link, Spinner, Stack, Text, Tooltip } from '@chakra-ui/react'
import { IconBinoculars, IconBuilding, IconMapPin, IconUserUp } from '@tabler/icons-react'
import React, { useEffect, useMemo, useState } from 'react'
import { Account } from '../../../types/Account'
import { Apps } from '../../../types/App'
import { Company, ProfileRecord } from '../../../types/Profile'
import {
  ProspectFilter,
  ProspectFilterWithOperator,
  useProfileProspects,
  useProspectAggs
} from '../../data/use-prospects'
import { DetailsCard } from '../../ui/Card'
import CircleIcon from '../../ui/CircleIcon'
import { HelpTooltip } from '../../ui/HelpTooltip'
import { ClearbitIcon } from '../../ui/icons/ClearbitIcon'
import { projectPath } from '../../ui/ProjectsContext'
import { humanize } from '../accounts/facets/filter-cloud'
import { SimpleFilter } from './prospect-filters'
import { ProspectsList } from './prospects-list'

function filterValues(input?: ProspectFilter): string[] {
  if (input === undefined || input === null) {
    return []
  }
  if (Array.isArray(input)) {
    return input
  }

  return input.values
}

export function ProfileTab(props: { account: Account; company: Company; profile: ProfileRecord; apps: Apps }) {
  const [page, _setPage] = useState(1)
  const aggs = useProspectAggs({})
  const [customFilters, setCustomFilters] = useState<Record<string, ProspectFilterWithOperator> | undefined>(undefined)

  const prospects = useProfileProspects(props.company.domain!, {
    profile_id: props.profile.id,
    page: page,
    customFilters: customFilters
  })

  const [_pageMeta, setPageMeta] = useState(prospects.data?.page_meta)
  const [clearbitHints, setClearbitHints] = useState(prospects.data?.clearbit_hints)
  const [localProspects, setLocalProspects] = useState(prospects.data?.prospect_guesses ?? [])
  const [filters, setFilters] = useState<Record<string, ProspectFilterWithOperator>>(
    (prospects.data?.filters ?? {}) as Record<string, ProspectFilterWithOperator>
  )

  useEffect(() => {
    if (prospects.data && !prospects.isLoading && !prospects.isFetching) {
      setLocalProspects(prospects.data?.prospects ?? [])
      setPageMeta(prospects.data?.page_meta)
      setFilters((prospects.data?.filters ?? {}) as Record<string, ProspectFilterWithOperator>)
      setClearbitHints(prospects.data?.clearbit_hints)
    }
  }, [prospects.data, prospects.isLoading, prospects.isFetching])

  const noData = useMemo(
    () => !prospects.isLoading && !prospects.isFetching && localProspects.length === 0,
    [prospects.isLoading, prospects.isFetching, localProspects.length]
  )

  const selectedTitles = useMemo(() => {
    if (customFilters?.job_title_levels) {
      return customFilters.job_title_levels.values
    }

    if (filters['job_title_levels']?.operator === 'should' && !clearbitHints?.seniority) {
      return []
    }

    return filterValues(filters['job_title_levels'])
  }, [filters, customFilters, clearbitHints])

  const selectedRoles = useMemo(() => {
    if (customFilters?.job_title_role) {
      return customFilters.job_title_role.values
    }

    if (filters['job_title_role']?.operator === 'should' && !clearbitHints?.role) {
      return []
    }

    return filterValues(filters['job_title_role'])
  }, [filters, customFilters, clearbitHints])

  if ((prospects.isLoading || prospects.isFetching) && page === 1) {
    return (
      <DetailsCard p={0} gap={0}>
        <Stack spacing={4} px={4} py={4}>
          <Flex width="100%" alignItems="center" gap={2}>
            <CircleIcon bg="gray.100" color="inherit" icon={IconBinoculars} iconSize={4} />

            <Text fontSize="sm" fontWeight="semibold">
              Prospect matches
            </Text>
          </Flex>

          <HStack>
            <Center width={6}>
              <Spinner size="xs" color="gray.400" thickness="1.5px" />
            </Center>
            <Text fontSize="xs" color="gray.500" fontWeight="normal">
              Triangulating potential prospects based on location, title, and seniority...
            </Text>
          </HStack>
        </Stack>
      </DetailsCard>
    )
  }

  return (
    <Stack w="100%" spacing="4">
      <DetailsCard p={0} gap={0}>
        <Stack spacing={4} px={4} pt={4}>
          <Flex width="100%" alignItems="center" gap={2}>
            <CircleIcon bg="gray.100" color="inherit" icon={IconBinoculars} iconSize={4} />

            <Text fontSize="sm" fontWeight="semibold">
              Prospect matches
            </Text>

            <HelpTooltip mode="popover" placement="right" ml="auto">
              <Stack fontSize="xs">
                <Text>
                  Even though this person hasn't been directly identified, we sometimes have hints about them from their
                  ip - like the potential company, role, seniority and geo location.
                </Text>
                <Text>We use these hints to triangulate who this person might be.</Text>

                {noData && <Text>In this case, we couldn't find matches.</Text>}
              </Stack>
            </HelpTooltip>
          </Flex>

          {Object.keys(filters).length > 0 && (
            <Stack fontSize="xs">
              <HStack spacing={1}>
                <Text color="gray.500">{noData ? 'No matching' : 'Showing'} prospects based on these filters:</Text>
              </HStack>

              <Stack spacing="1" p={2} bg="background.light" rounded="md">
                {filters['job_title_levels'] && (
                  <HStack spacing="1" direction="row">
                    <SimpleFilter
                      name={`Seniority: `}
                      triggerProps={{
                        color: 'gray.600',
                        fontWeight: 'normal',
                        leftIcon: clearbitHints?.seniority ? <ClearbitIcon /> : <Icon as={IconUserUp} />
                      }}
                      selected={selectedTitles}
                      operator={
                        customFilters?.['job_title_levels']?.operator ??
                        (filters['job_title_levels'] as ProspectFilterWithOperator).operator
                      }
                      hideCounts
                      aggs={aggs?.data?.aggs?.job_title_levels ?? { buckets: [] }}
                      isLoading={aggs.isLoading}
                      onChange={(values, operator) => {
                        const filter = {
                          values,
                          operator
                        } as ProspectFilterWithOperator

                        setCustomFilters({
                          ...customFilters,
                          job_title_levels: filter
                        })
                      }}
                    />

                    {selectedTitles.length > 0 && (
                      <Text color="purple.500" fontWeight="semibold">
                        {filterValues(filters['job_title_levels'])
                          .map((f) => {
                            return humanize(f, false)
                          })
                          .join(', ')}
                      </Text>
                    )}

                    {selectedTitles.length === 0 && <Text fontSize="xs">None selected</Text>}
                  </HStack>
                )}

                {filters['job_title_role'] && (
                  <HStack spacing="1" direction="row">
                    <SimpleFilter
                      name={'Department'}
                      triggerProps={{
                        color: 'gray.600',
                        fontWeight: 'normal',
                        leftIcon: clearbitHints?.role ? <ClearbitIcon /> : <Icon as={IconBuilding} />,
                        _hover: { color: 'purple.500' }
                      }}
                      selected={selectedRoles}
                      operator={
                        customFilters?.['job_title_role']?.operator ??
                        (filters['job_title_role'] as ProspectFilterWithOperator).operator
                      }
                      hideCounts
                      aggs={aggs?.data?.aggs?.job_title_role ?? { buckets: [] }}
                      isLoading={aggs.isLoading}
                      onChange={(values, operator) => {
                        const filter = {
                          values,
                          operator
                        } as ProspectFilterWithOperator

                        setCustomFilters({
                          ...customFilters,
                          job_title_role: filter
                        })
                      }}
                    />

                    {selectedRoles.length > 0 && (
                      <Text color="purple.500" fontWeight="semibold">
                        {filterValues(filters['job_title_role'])
                          .map((f) => {
                            if (Array.isArray(f)) {
                              return f.map((v) => humanize(v)).join(', ')
                            }

                            return humanize(f)
                          })
                          .join(', ')}
                      </Text>
                    )}

                    {selectedRoles.length === 0 && <Text fontSize="xs">None selected</Text>}
                  </HStack>
                )}

                {(filters['location_region'] || filters['location_city'] || filters['location_country']) && (
                  <HStack spacing="2" direction="row">
                    <Tooltip
                      label={`Looking for prospects at ${props.company.name || props.company.domain} within a ${filters?.['lat_lng']?.['range']} radius`}
                      aria-label="Location"
                      hasArrow
                      placement="left"
                    >
                      <Icon as={IconMapPin} boxSize={3} ml={1.5} color="gray.500" />
                    </Tooltip>
                    <Text color="purple.500" fontWeight="semibold">
                      {[
                        filterValues(filters['location_city']),
                        filterValues(filters['location_region']),
                        filterValues(filters['location_country'])
                      ]
                        .filter((f) => f && f.length > 0)
                        .map((f) => {
                          if (Array.isArray(f)) {
                            return f.map((v) => humanize(v)).join(', ')
                          }

                          return humanize(f)
                        })
                        .join(', ')}
                    </Text>

                    {filters['lat_lng'] && filters['lat_lng']['range'] && (
                      <>
                        <Text color="gray.500">({filters['lat_lng']['range']} radius)</Text>
                      </>
                    )}
                  </HStack>
                )}
              </Stack>
            </Stack>
          )}

          {localProspects.length > 0 && (
            <Box>
              <Box position="relative" mt={-2} mb="-1px">
                <ProspectsList
                  isLoading={prospects.isLoading || prospects.isFetching}
                  context="triangulation"
                  prospects={localProspects}
                  domain={props.company.domain!}
                  apps={props.apps}
                />
              </Box>
            </Box>
          )}
        </Stack>

        <Stack fontSize="sm" px={1.5} py={1.5} borderTop="1px solid" borderColor="gray.200">
          <Button size="sm" variant="ghost" as={Link} href={projectPath(`/prospector?domain=${props.company.domain}`)}>
            Find other people at {props.company.name || props.company.domain}
          </Button>
        </Stack>
      </DetailsCard>
    </Stack>
  )
}
