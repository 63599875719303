import { Box, BoxProps, Flex, Image } from '@chakra-ui/react'
import { Icon as TablerIcon } from '@tabler/icons-react'
import React from 'react'
import * as ReactIs from 'react-is'
import Label from './Label'
import RemoveButton from './RemoveButton'

interface Props extends BoxProps {
  icon?: JSX.Element | TablerIcon | string
  label?: string
  onRemove?: () => void
  colorScheme?: string
}

export default function FeatureTag(props: Props) {
  const { icon, label, onRemove, ...rest } = props

  let iconElement: null | React.ReactElement = null
  if (typeof icon === 'string') {
    iconElement = <Image src={icon} boxSize="16px" />
  } else if (ReactIs.isValidElementType(icon)) {
    iconElement = React.createElement(icon, { size: 16 })
  } else if (React.isValidElement(icon)) {
    iconElement = React.cloneElement(icon, { size: 16 } as any)
  }

  const colorScheme = props.colorScheme ?? 'purple'
  const background = `${colorScheme}.50`

  return (
    <Box
      display="inline-flex"
      alignItems="stretch"
      flexGrow={0}
      height="32px"
      color={`${colorScheme}.700`}
      borderColor="gray.400"
      borderRadius={6}
      transition="opacity 200ms cubic-bezier(0, 0, 0.2, 1)"
      userSelect="none"
      __css={{
        '& > *:first-of-type': {
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6
        },
        '& > *:last-of-type': {
          borderTopRightRadius: 6,
          borderBottomRightRadius: 6
        }
      }}
      gap="1px"
      {...rest}
    >
      {(label || iconElement) && (
        <Flex flex="none" background={background}>
          {iconElement && (
            <Box
              display="flex"
              alignItems="center"
              rounded="sm"
              overflow={typeof icon === 'string' ? 'hidden' : undefined}
              flexShrink={0}
              marginLeft={2}
            >
              {iconElement}
            </Box>
          )}
          {label && <Label>{label}</Label>}
        </Flex>
      )}
      {onRemove && <RemoveButton colorScheme={props.colorScheme} onClick={onRemove} />}
    </Box>
  )
}
