export function formatNumber(value: number | string, options?: Intl.NumberFormatOptions | undefined): string {
  return new Intl.NumberFormat('en-US', options).format(typeof value === 'number' ? value : parseFloat(value))
}

export function formatPercent(value: number | string, options?: Intl.NumberFormatOptions): string {
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options
  }).format(typeof value === 'number' ? value : Number(value))
}

export function formatFriendlyCurrency(
  value: number | string | undefined,
  options?: Intl.NumberFormatOptions
): string | null {
  const units = [
    { threshold: 1, symbol: '' },
    { threshold: 1e3, symbol: 'k' },
    { threshold: 1e6, symbol: 'M' },
    { threshold: 1e9, symbol: 'B' },
    { threshold: 1e12, symbol: 'T' }
  ]

  const numby = Number(value)

  // TODO support more forgiving number parsing like `'$ 45.00'` etc
  if (isNaN(numby)) {
    return null
  }

  const unit = units
    .slice()
    .reverse()
    .find((unit) => numby >= unit.threshold)

  const truncatedNumber = numby / (unit?.threshold || 1)
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options
  })

  const formatted = formatter.format(truncatedNumber)
  return formatted + (unit?.symbol || '')
}
