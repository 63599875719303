import { Circle, Icon, Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react'
import { IconBolt } from '@tabler/icons-react'
import React, { forwardRef } from 'react'

interface Props {
  variant?: 'solid' | 'subtle'
  count: number
}

export const IntentBolt = forwardRef<HTMLSpanElement, Props>(function IntentBolt(
  { count, variant = 'solid' }: Props,
  ref
) {
  const colorize = variant === 'solid'

  return (
    <Tag
      ref={ref}
      flex="none"
      variant="solid"
      rounded="full"
      bg={colorize ? '#F2994A' : 'gray.100'}
      color={colorize ? 'white' : 'gray.600'}
      marginLeft="auto"
      size="sm"
    >
      <TagLeftIcon as={IconBolt} fill="currentColor" boxSize={3} marginInlineEnd={1} />
      <TagLabel lineHeight={1}>{count.toLocaleString()}</TagLabel>
    </Tag>
  )
})

export function IntentBoltCircle({ variant = 'solid' }: Pick<Props, 'variant'>) {
  const colorize = variant === 'solid'

  return (
    <Circle
      color={colorize ? 'white' : 'gray.600'}
      bg={colorize ? '#F2994A' : 'gray.100'}
      padding="2px"
      outline="2px solid white"
    >
      <Icon as={IconBolt} boxSize={3} fill="currentColor" />
    </Circle>
  )
}
