import { AuthenticityToken } from '@app/components/ui/AuthenticityToken'
import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea
} from '@chakra-ui/react'
import * as React from 'react'
import { projectPath, useCurrentProject } from '../../../ui/ProjectsContext'

interface ConfirmDeletionProps {
  isOpen: boolean
  onClose: () => void
}

export function ConfirmDeletion({ isOpen, onClose }: ConfirmDeletionProps) {
  const project = useCurrentProject()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [challenge, setChallenge] = React.useState('')
  const [reason, setReason] = React.useState('')

  const challengeAccepted = React.useMemo(() => {
    return project && challenge === project.name
  }, [challenge, project])

  const onSubmit = React.useCallback(
    (event) => {
      if (!challengeAccepted) {
        event.preventDefault()
        return
      }

      setIsSubmitting(true)
    },
    [challengeAccepted]
  )

  if (!project) {
    return null
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">Are you sure you want to delete the workspace?</ModalHeader>
        <ModalBody>
          <Text fontSize="sm" color="gray.600">
            This will delete the workspace and all its data. If you would like to proceed, type the workspace name{' '}
            <b>{project.name}</b> in the field below
          </Text>
          <Input
            mt={5}
            type={'text'}
            placeholder="Type the workspace name to confirm deletion"
            onChange={(e) => setChallenge(e.currentTarget.value)}
            isRequired
          />
          <Text fontSize="xs" color="gray.600" mt={5}>
            We're sad to see you go... but we would love your feedback.
          </Text>
          <Textarea
            size="xs"
            mt={2}
            onChange={(e) => setReason(e.target.value)}
            placeholder="Anything you'd like to share about why you are deleting your Koala workspace? (Optional)"
          />
        </ModalBody>

        <ModalFooter justifyContent="stretch" gap={3}>
          <Button flex="1 1 50%" onClick={onClose} variant="outline">
            Cancel
          </Button>
          <Box flex="1 1 50%" as="form" method="POST" action={projectPath()} onSubmit={onSubmit}>
            <AuthenticityToken />
            <input type="hidden" name="_method" value="DELETE" />
            <input type="hidden" name="reason" value={reason} />
            <Button
              type="submit"
              colorScheme="red"
              width="100%"
              isLoading={isSubmitting}
              isDisabled={!challengeAccepted}
            >
              Delete workspace
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
