import {
  Box,
  BoxProps,
  Button,
  Code,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Link,
  Progress,
  Select,
  Stack,
  Switch,
  Text,
  useClipboard,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { IconArrowLeft, IconArrowRight, IconChevronRight, IconCopy } from '@tabler/icons-react'
import { motion } from 'framer-motion'
import React, { useMemo } from 'react'
import { toast } from 'sonner'
import { subscribeToChannel, SubscriptionEmitter } from '../../../../channels/generic_channel'
import { del, post } from '../../../../lib/api'
import dayjs from '../../../../lib/dayjs'
import { Crm } from '../../../../types/Crm'
import { Project } from '../../../../types/Project'
import { Card } from '../../../ui/Card'
import GoogleTagManagerLogo from '../../../ui/GoogleTagManagerLogo.svg'
import { projectPath, useCurrentProject } from '../../../ui/ProjectsContext'
import Pulse from '../../../ui/Pulse'
import SegmentLogo from '../../../ui/SegmentLogo.svg'
import { StepType } from '../show'
import clearbitImg from './clearbit.png'
import { ClearbitForm } from './ClearbitForm'
import crmImg from './crm.png'
import doneImg from './done.png'
import { HeroImage } from './HeroImage'
import { IntegrationButton, IntegrationButtonProps } from './IntegrationButton'
import { InviteModal, roles, useInvite } from './InviteModal'
import { konfetti } from './konfetti'
import pixelImg from './pixel.png'
import { PixelSnippet } from './PixelSnippet'
import signalsImg from './signals.png'
import { SignalsForm } from './SignalsForm'
import slackImg from './slack.png'
import { SlackForm } from './SlackForm'

interface Props {
  crm?: Crm
  crm_connected: boolean
  clearbit_connected: boolean
  slack_connected: boolean
  sync_status?: {
    total: number
    synced: number
    percent: number
    last_synced: string
  }
  members?: number
  project?: Pick<Project, 'id' | 'name' | 'slug'>
  authorized_projects?: Array<Pick<Project, 'name' | 'slug' | 'domain' | 'potential_domain' | 'member_count'>>
  suggested_authorized_domain?: string
  data_flowing: boolean
  skipped_crm?: boolean
  skipped_pixel?: boolean
  catalog: Record<'salesforce' | 'hubspot' | 'slack', IntegrationButtonProps>

  onStepChange: (step: StepType) => void
  step: StepType
}

function syncMessage(sync: Props['sync_status']) {
  if (!sync) {
    return ''
  }

  if (sync.percent >= 100) {
    return `Synced - ${dayjs(sync.last_synced).calendar()}`
  } else {
    return `Syncing Data - ${sync.percent}%`
  }
}

function getSyncStatus(status: Props['sync_status']) {
  if (!status || status.percent === 0) {
    return 'not-started'
  }

  if (status.percent >= 100) {
    return 'complete'
  }

  return 'in-progress'
}

export function SetupProject(props: Props) {
  const step = useMemo(() => props.step, [props.step])
  const setStep = props.onStepChange
  const { hasCopied, onCopy } = useClipboard(
    (props.project as Project).default_public_api_key?.key ?? props.project?.slug ?? ''
  )

  const [dataFlowing, setDataFlowing] = React.useState(props.data_flowing)
  const [syncDetails, setSyncDetails] = React.useState(props.sync_status)
  const [syncStatus, setSyncStatus] = React.useState(getSyncStatus(props.sync_status))
  const project = useCurrentProject()
  const crmStatusSubscription = React.useRef<SubscriptionEmitter>()
  const pixelStatusSubscription = React.useRef<SubscriptionEmitter>()
  const syncProgress = syncDetails?.percent ?? 0
  const { isOpen, onClose, onOpen } = useDisclosure()
  const emailDomain = props.suggested_authorized_domain

  const { isInviting, onInvite } = useInvite()

  React.useEffect(() => {
    if (!project?.slug || !props.crm?.clean_module_name || syncStatus === 'complete') {
      return
    }

    crmStatusSubscription.current = subscribeToChannel({
      channel: 'SyncStatusChannel',
      project_slug: project.slug,
      integration: props.crm.clean_module_name
    })

    const onData = (message: any) => {
      if (message.action === 'status') {
        setSyncDetails(message.data)

        if (message.data.percent === 100) {
          // With a slight delay so users can see progress animate to 100%
          setTimeout(() => {
            setSyncStatus('complete')
          }, 500)
          crmStatusSubscription.current?.off('received', onData)
          crmStatusSubscription.current?.unsubscribe()
          crmStatusSubscription.current = undefined
        } else {
          setSyncStatus('in-progress')
        }
      }
    }

    crmStatusSubscription.current.on('received', onData)

    return () => {
      crmStatusSubscription.current?.off('received', onData)
      crmStatusSubscription.current?.unsubscribe()
      crmStatusSubscription.current = undefined
    }
  }, [project?.slug, props.crm?.clean_module_name, syncStatus])

  React.useEffect(() => {
    if (!project?.slug || syncStatus === 'not-started' || dataFlowing) {
      return
    }

    pixelStatusSubscription.current = subscribeToChannel({
      channel: 'SyncStatusChannel',
      project_slug: project.slug,
      integration: 'koala' // "koala" pixel
    })

    const onData = (message: any) => {
      if (message.action === 'status') {
        setDataFlowing(message.data.connected)

        // unsubscribe once pixel is connected
        if (message.data.connected) {
          pixelStatusSubscription.current?.off('received', onData)
          pixelStatusSubscription.current?.unsubscribe()
          pixelStatusSubscription.current = undefined
        }
      }
    }

    pixelStatusSubscription.current.on('received', onData)

    return () => {
      pixelStatusSubscription.current?.off('received', onData)
      pixelStatusSubscription.current?.unsubscribe()
      pixelStatusSubscription.current = undefined
    }
  }, [project?.slug, dataFlowing, syncStatus])

  React.useEffect(() => {
    if (step === 'done') {
      setTimeout(() => konfetti(), 250)
    }
  }, [step])

  const updateAuthorizedDomain = React.useCallback(
    async (event) => {
      try {
        if (event.target.checked) {
          await post(projectPath('/settings/domains'), {
            authorized_domains: {
              domain: emailDomain
            }
          })
        } else {
          await del(projectPath(`/settings/domains/${emailDomain}`))
        }
      } catch (err) {
        toast.error('Failed to set authorized domain!')
      }
    },
    [emailDomain]
  )

  if (!project) {
    return null
  }

  return (
    <>
      <motion.div
        layout
        transition={{
          layout: { duration: 0.3, ease: 'easeInOut' }
        }}
      >
        <Flex
          direction="column"
          alignItems="center"
          alignSelf="center"
          gap={8}
          width="100%"
          maxWidth={600}
          paddingTop="40px"
          paddingBottom="40px"
          marginX="auto"
          position="relative"
          zIndex={1}
        >
          {step === 'crm' && (
            <Step cardProps={{ p: 0, maxW: '600px' }}>
              <HeroImage src={crmImg} h="240px" objectFit="contain" objectPosition="top" />
              <Stack px="8" spacing="8" pb="8">
                <Stack>
                  <Heading size="md">Connect your CRM</Heading>
                  <Text fontSize="sm" color="gray.600" marginY={6}>
                    Koala needs <b>read-only</b> access to your CRM to build ranked lead lists and unlock account
                    exploration.
                  </Text>
                </Stack>
                {props.crm ? (
                  <>
                    <Flex direction="column" gap={4}>
                      <Flex gap={4} alignItems="center">
                        <Image src={props.crm.logo} boxSize={8} />
                        <Box>
                          <Text fontSize="sm" fontWeight="medium">
                            Connected to {props.crm.title}
                          </Text>
                          <Text fontSize="xs" color="gray.600">
                            {syncMessage(syncDetails)}
                          </Text>
                        </Box>
                      </Flex>
                      {syncStatus !== 'complete' && (
                        <Progress
                          flex="none"
                          width="100%"
                          marginX="auto"
                          colorScheme="gray"
                          value={syncProgress || 1}
                          max={100}
                          size="xs"
                          rounded="full"
                          isAnimated
                          hasStripe={syncProgress > 0 && syncProgress < 100}
                          isIndeterminate={syncStatus === 'not-started'}
                          css={{
                            '& div[role=progressbar]': {
                              transition: syncProgress > 0 ? 'width 150ms cubic-bezier(0, 0, 0.2, 1)' : undefined
                            }
                          }}
                        />
                      )}
                    </Flex>
                    <Button
                      colorScheme="purple"
                      flex="none"
                      size="lg"
                      fontSize="md"
                      width="full"
                      onClick={() => setStep('pixel')}
                    >
                      Continue
                    </Button>
                  </>
                ) : (
                  <Flex direction="column" gap={4} w="100%">
                    <HStack w="100%" justifyContent={'center'} spacing="4">
                      <IntegrationButton {...props.catalog['salesforce']} />
                      <IntegrationButton {...props.catalog['hubspot']} />
                    </HStack>
                    <Button
                      as={Link}
                      variant="link"
                      flex="none"
                      alignSelf="center"
                      size="md"
                      marginTop={4}
                      href="?skip=crm"
                    >
                      Connect later
                    </Button>
                  </Flex>
                )}
              </Stack>
            </Step>
          )}
          {step === 'pixel' && (
            <Step cardProps={{ maxW: '600px', p: 0, position: 'relative' }} onBack={() => setStep('crm')}>
              <HStack position="absolute" top="4" right="4" bg="white" px="3" py="1" rounded="full" shadow={'sm'}>
                <Flex alignItems="center" gap={4}>
                  <Text fontSize="sm" color={dataFlowing ? 'green.400' : 'orange.400'}>
                    {dataFlowing ? 'Connected!' : 'Waiting for events'}
                  </Text>
                </Flex>
                <Pulse size={2.5} background={dataFlowing ? 'green.300' : 'orange.300'} />
              </HStack>

              <HeroImage src={pixelImg} h="240px" />
              <Stack px="8" spacing="8" pb="8">
                <Stack>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Heading size="md">Install the pixel</Heading>
                  </Flex>
                  <Text fontSize="sm" color="gray.600" marginY={6}>
                    Add Koala to your website to uncover customer activity and first-party intent signals.
                  </Text>
                </Stack>
                {dataFlowing ? (
                  <>
                    <Text fontSize="sm" color="gray.600" textAlign="center" marginY={2}>
                      🤖 We detected events from your pixel! You're all set.
                    </Text>
                    <Button
                      colorScheme="purple"
                      flex="none"
                      size="lg"
                      fontSize="md"
                      width="full"
                      onClick={() => setStep('clearbit')}
                    >
                      Continue
                    </Button>
                  </>
                ) : (
                  <>
                    <PixelSnippet project={project} />

                    <Flex position="relative" justifyContent="center">
                      <Divider position="absolute" top="50%" left={0} right={0} />

                      <Text
                        display="inline-flex"
                        fontSize="xs"
                        color="gray.500"
                        textAlign="center"
                        paddingX={3}
                        background="white"
                        zIndex={1}
                      >
                        or install with your existing stack
                      </Text>
                    </Flex>

                    <Flex direction="column" gap={3}>
                      <InstallCard
                        title="Install via Segment"
                        description={
                          <>
                            Copy your Public API Key to install without code:{' '}
                            <HStack>
                              <Code fontSize="inherit">{project.default_public_api_key?.key ?? project.slug}</Code>
                              {hasCopied && (
                                <Button
                                  size="xs"
                                  aria-label="Copy"
                                  leftIcon={<IconCopy size={14} />}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    onCopy()
                                  }}
                                >
                                  Copied to clipboard
                                </Button>
                              )}
                              {!hasCopied && (
                                <IconButton
                                  size="xs"
                                  aria-label="Copy"
                                  icon={<IconCopy size={14} />}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    onCopy()
                                  }}
                                />
                              )}
                            </HStack>
                          </>
                        }
                        icon={<Image src={SegmentLogo} boxSize={5} />}
                        href="https://getkoala.com/docs/integrations/segment"
                      />
                      <InstallCard
                        title="Install via Google Tag Manager"
                        description="Follow our guide for installing Koala via GTM"
                        icon={<Image src={GoogleTagManagerLogo} boxSize={5} />}
                        href="https://getkoala.com/docs/integrations/google-tag-manager"
                      />
                      <Button
                        w="100%"
                        as={Link}
                        variant="ghost"
                        flex="none"
                        alignSelf="center"
                        size="md"
                        marginTop={4}
                        href="?skip=pixel"
                        onClick={() => {
                          setStep('clearbit')
                        }}
                        rightIcon={<IconChevronRight size={14} />}
                      >
                        Install later
                      </Button>
                    </Flex>
                  </>
                )}
              </Stack>
            </Step>
          )}
          {step === 'clearbit' && (
            <Step cardProps={{ maxW: '600px', p: 0, position: 'relative' }} onBack={() => setStep('pixel')}>
              <HeroImage src={clearbitImg} h="240px" />
              <Stack px="8" spacing="8" pb="8">
                <Flex justifyContent="space-between" alignItems="center">
                  <Heading size="md">Connect Clearbit</Heading>
                </Flex>
                <Stack spacing={4}>
                  <Text fontSize="sm" color="gray.600">
                    Koala customers can get 10,000 free Clearbit Reveal calls per month and a generous pay-as-you go
                    plan when you need it, via Powered by Clearbit. You'll be able to see which companies are on your
                    site, even if they've never submitted an email.
                  </Text>
                  <Text fontSize="sm" color="gray.600">
                    If you've already purchased Clearbit Reveal, you'll be able to enter your own key later.
                  </Text>
                </Stack>

                <Flex direction="column" gap={4}>
                  <ClearbitForm skip={() => setStep('signals')} />
                </Flex>
              </Stack>
            </Step>
          )}
          {step === 'signals' && (
            <Step cardProps={{ p: 0, maxW: '600px' }} onBack={() => setStep('clearbit')}>
              <HeroImage src={signalsImg} h="240px" />

              <Stack px="8" spacing="8" pb="8">
                <Heading size="sm">Suggested Intent Signals</Heading>
                <Text fontSize="sm" color="gray.600" marginY={6}>
                  Intent Signals highlight important moments in the customer journey, so you can react quickly. You
                  should set up Intent Signals for any pageview or event that signifies potential buying intent.
                </Text>
                <SignalsForm skip={() => setStep('slack')} />
              </Stack>
            </Step>
          )}
          {step === 'slack' && (
            <Step cardProps={{ p: 0, maxW: '600px' }} onBack={() => setStep('signals')}>
              <HeroImage src={slackImg} h="240px" />
              <Stack px="8" spacing="8" pb="8">
                <SlackForm connected={props.slack_connected} skip={() => setStep('done')} catalog={props.catalog} />
              </Stack>
            </Step>
          )}
          {step === 'invite' && (
            <Flex direction="column" gap={8} alignItems="center" width="100%" maxWidth="500px">
              <Flex direction="column" textAlign="center" gap={2}>
                <Heading size="md">Invite your team</Heading>
                <Text fontSize="sm" color="gray.600">
                  {emailDomain
                    ? 'Allow your teammates to auto-enroll with authorized domains, or invite the rest of your team individually.'
                    : 'Invite the rest of your team to Koala.'}
                </Text>
              </Flex>

              <Card shadow="lg">
                <Flex direction="column" gap={6}>
                  {emailDomain && (
                    <>
                      <Flex alignItems="center" gap={4}>
                        <Text fontSize="sm">
                          Allow anyone with a confirmed <b>@{emailDomain}</b> email to join this workspace
                        </Text>
                        <Switch
                          name="invite[authorized_domain]"
                          value={emailDomain}
                          defaultChecked={props.authorized_projects?.some((p) => p.slug === props.project?.slug)}
                          onChange={updateAuthorizedDomain}
                        />
                      </Flex>
                      <Divider />
                    </>
                  )}
                  <Flex as="form" data-koala-collect="off" onSubmit={onInvite} direction="column" gap={6}>
                    <VStack width="100%" spacing={3}>
                      <InviteEmail index={0} />
                      <InviteEmail index={1} />
                      <InviteEmail index={2} />
                    </VStack>
                    <Button type="submit" alignSelf="center" size="sm" colorScheme="purple" isLoading={isInviting}>
                      Send invites
                    </Button>
                  </Flex>
                </Flex>
              </Card>

              <Button
                variant="outline"
                flex="none"
                size="lg"
                fontSize="md"
                width="full"
                maxWidth="400px"
                onClick={() => {
                  setStep('done')
                }}
              >
                Continue
              </Button>
            </Flex>
          )}
          {step === 'done' && (
            <Step cardProps={{ p: 0 }}>
              <HeroImage src={doneImg} h="240px" />
              <Stack p="8" spacing="8" justifyContent={'center'}>
                <Heading size="lg" textAlign="center">
                  You're all set!
                </Heading>
                <Text fontSize="sm" color="gray.600" textAlign="center">
                  Nice work! Now Koala can start analyzing your CRM data and real-time visitor intent. We'll start
                  overlaying valuable insights for your existing customers and prospects.
                </Text>
                <Button
                  as={Link}
                  href={projectPath('/accounts?range=all')}
                  flex="none"
                  size="lg"
                  fontSize="md"
                  width="full"
                  colorScheme="purple"
                >
                  Open Koala
                </Button>
              </Stack>
            </Step>
          )}
          {step !== 'done' && step !== 'invite' && (
            <Text fontSize="sm" color="gray.500">
              Need a hand?{' '}
              <Button size="sm" variant="link" onClick={onOpen} color="purple.500">
                Invite a teammate
              </Button>{' '}
              or{' '}
              <Link href="mailto:support@getkoala.com" isExternal color="purple.500">
                send us a message
              </Link>
              .
            </Text>
          )}
        </Flex>
      </motion.div>
      <InviteModal project={project} isOpen={isOpen} onClose={onClose} />
    </>
  )
}

type StepProps = React.PropsWithChildren<{}>

export function Step(props: StepProps & { cardProps?: BoxProps; onBack?: () => void }) {
  return (
    <>
      <Card
        w="100%"
        maxW={600}
        p={0}
        {...props.cardProps}
        position="relative"
        shadow={'none'}
        borderWidth={['0', '0', '1px']}
        borderColor={'gray.300'}
        rounded="lg"
      >
        {props.onBack && (
          <IconButton
            position={'absolute'}
            variant={'ghost'}
            _hover={{ bg: 'none' }}
            _active={{ bg: 'none' }}
            aria-label="Back"
            icon={<IconArrowLeft size="16" />}
            onClick={props.onBack}
          />
        )}
        <Flex display="flex" flexDirection="column" gap={8}>
          {props.children}
        </Flex>
      </Card>
    </>
  )
}

interface InviteEmailProps {
  index: number
}

function InviteEmail({ index }: InviteEmailProps) {
  return (
    <Flex gap={3} width="100%">
      <Input size="sm" type="email" name={`invite[invites][${index}][email]`} placeholder="name@example.com" />
      <Select name={`invite[invites][${index}][role]`} size="sm" flex="none" width="auto" defaultValue="member">
        {roles.map((r) => (
          <option key={r.value} value={r.value}>
            {r.name}
          </option>
        ))}
      </Select>
    </Flex>
  )
}

interface InstallCardProps {
  title: string
  icon: React.ReactNode
  description: React.ReactNode
  href: string
}

function InstallCard(props: InstallCardProps) {
  return (
    <Flex
      as="a"
      href={props.href}
      target="_blank"
      gap={3}
      alignItems="center"
      border="1px solid"
      borderColor="gray.200"
      paddingY={4}
      paddingX={6}
      rounded="md"
      transition="all 100ms ease-in-out"
      _hover={{
        transform: 'translateY(-2px)',
        shadow: 'sm',
        borderColor: 'gray.300'
      }}
    >
      <Box className="install-icon" flex="none">
        {props.icon}
      </Box>
      <Box flex="1">
        <Text fontSize="sm" fontWeight="medium" color="gray.700">
          {props.title}
        </Text>
        <Text fontSize="xs" color="gray.500">
          {props.description}
        </Text>
      </Box>
      <Icon className="install-go" as={IconArrowRight} boxSize={4} color="purple.500" />
    </Flex>
  )
}
