import { Box, BoxProps, Text, TextProps } from '@chakra-ui/react'
import React from 'react'

export default function Middot(props: TextProps) {
  return (
    <Text as="span" {...props}>
      &middot;
    </Text>
  )
}

export function MiddotDivider(props: BoxProps) {
  return (
    <Box color="gray.500" border="none" lineHeight={1} {...props}>
      &middot;
    </Box>
  )
}
