import { Badge, Button, Checkbox, Divider, Flex, Heading, HStack, Icon, Skeleton, Stack, Text } from '@chakra-ui/react'
import { IconArrowDown, IconChevronRight, IconUsers } from '@tabler/icons-react'
import uniqBy from 'lodash/uniqBy'
import React, { useCallback, useMemo } from 'react'
import { toast } from 'sonner'
import { post } from '../../../../lib/api'
import { AccountView } from '../../../../types/AccountView'
import { useMyAccounts } from '../../../data/use-my-accounts'
import { BuildingIcon } from '../../../ui/icons'
import { projectPath } from '../../../ui/ProjectsContext'
import { HeroImage } from '../components/HeroImage'
import { Step } from '../components/SetupProject'
import { StepProps } from '../rep_onboarding'
import img from './audiences.png'

export type SubscribibleView = Pick<AccountView, 'id' | 'name' | 'counts' | 'kind' | 'created_by_user'> & {
  description?: string
}

export interface DigestSubscription {
  id: string
  project_id: string
  account_view_id: string
  user_id: string
  active: boolean
  kind: 'koala_weekly' | 'my_accounts_weekly' | 'all_accounts_weekly'
}

export function RepAudiences(props: StepProps & { views: SubscribibleView[]; subscriptions: DigestSubscription[] }) {
  const [selected, setSelected] = React.useState<SubscribibleView[]>(
    props.views.filter((v) => props.subscriptions.some((s) => s.account_view_id === v.id))
  )
  const [saving, setSaving] = React.useState(false)
  const myAccounts = useMyAccounts()

  const allViews: SubscribibleView[] = useMemo(() => {
    return props.views
  }, [props.views])

  const subscribe = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      if (selected.length === 0) {
        return
      }

      setSaving(true)

      const views = selected.map((view) => {
        return { account_view_id: view.id, kind: 'koala_weekly' }
      })

      post(projectPath('/subscriptions'), {
        digest: {
          subscriptions: views
        }
      })
        .then(() => {
          toast.success('Subscribed!', {
            description: 'You will receive weekly updates'
          })

          props.onContinue()
        })
        .catch((e) => {
          toast.error('Something went wrong...', {
            description: e.message
          })
        })
        .finally(() => {
          setSaving(false)
        })
    },
    [selected, props]
  )

  return (
    <Step cardProps={{ maxW: 800 }} {...props}>
      <HeroImage src={img} h="180px" />
      <Stack p="8" spacing="8" pt="0" as={'form'} onSubmit={subscribe}>
        <Stack>
          <Heading size="lg">Subscribe to Koala Weekly</Heading>
          <Text fontSize={'sm'} color="gray.500">
            Receive a weekly digest via email with the hottest accounts across your audiences. Select any lists you'd
            like to include.
          </Text>
        </Stack>

        <Stack h="400" overflow={'auto'} divider={<Divider />}>
          {myAccounts.isLoading && (
            <Stack spacing="4">
              {allViews.map((view) => (
                <Skeleton key={view.id} height="20" />
              ))}
            </Stack>
          )}
          {!myAccounts.isLoading &&
            allViews.map((view) => (
              <HStack key={view.id} justifyContent={'space-between'}>
                <Checkbox
                  isChecked={selected.includes(view)}
                  rounded="lg"
                  size="lg"
                  w="100%"
                  _hover={{
                    bg: 'purple.50'
                  }}
                  p="4"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelected([...selected, view])
                    } else {
                      setSelected(selected.filter((v) => v.id !== view.id))
                    }
                  }}
                >
                  <HStack pl="4" w="100%" justifyContent={'space-between'}>
                    <HStack>
                      <Icon as={view.kind === 'profile' ? IconUsers : BuildingIcon} />
                      <Stack spacing="0">
                        <Text fontSize={'sm'} fontWeight={'semibold'}>
                          {view.name}
                        </Text>
                        {view.description && <Text fontSize={'sm'}>{view.description}</Text>}
                        <Text fontSize={'xs'} color="gray.500">
                          Created by {view.created_by_user?.name}
                        </Text>
                      </Stack>
                    </HStack>
                    <Badge colorScheme={'purple'} px="2" rounded="full">
                      {view.counts?.all}
                    </Badge>
                  </HStack>
                </Checkbox>
              </HStack>
            ))}
          {allViews.length <= 1 && (
            <Stack bg="gray.50" p="4" borderWidth={'1px'} rounded="md">
              <Heading size="xs">No lists yet</Heading>
              <Text fontSize="sm">
                It looks like your team has not created any lists yet. Don't worry, you can create new ones later.
              </Text>
            </Stack>
          )}
        </Stack>

        {!myAccounts.isLoading && (
          <Flex justifyContent={'flex-end'}>
            <Text fontSize={'sm'}>
              {uniqBy(selected, 'id').length} / {allViews.length} selected
            </Text>
          </Flex>
        )}

        <HStack w="100%" justifyContent={'flex-end'}>
          <Button variant={'ghost'} onClick={props.onSkip} rightIcon={<IconChevronRight size={14} />} disabled={saving}>
            Subscribe later
          </Button>
          <Button type="submit" colorScheme={'purple'} disabled={selected.length === 0} isLoading={saving}>
            Subscribe and Continue
          </Button>
        </HStack>
      </Stack>
    </Step>
  )
}
