import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import { IconLock, IconUsers, IconUsersGroup } from '@tabler/icons-react'
import React, { useEffect, useMemo } from 'react'
import { AccountView } from '../../../../types/AccountView'
import { useTeams } from '../../../data/use-teams'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import SelectInput from '../../../ui/SelectInput'
import useLocation from '../../../ui/useLocation'
import { accountViewPath, customViewsPath } from '../lib/list-paths'

interface OwnershipOption {
  label: string
  value: 'private' | 'shared' | 'team'
  teamId?: string
}

const defaultOwnershipOptions: OwnershipOption[] = [
  { label: 'Private', value: 'private' },
  { label: 'Everyone', value: 'shared' }
]

const ownershipIcons = {
  private: IconLock,
  shared: IconUsersGroup,
  team: IconUsers
}

interface AccountViewModalProps {
  view?: AccountView
  kind: 'account' | 'profile'
  displayColumns?: string[]
  isOpen: boolean
  onClose: () => void
}

export function AccountViewModal(props: AccountViewModalProps) {
  const { view, displayColumns, isOpen, onClose } = props
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const teamsData = useTeams()

  const ownershipOptions = useMemo(() => {
    const teamOptions = (teamsData.data?.teams ?? []).map((team) => ({
      label: team.name.replace(/team/gi, '') + ' Team',
      value: 'team' as const,
      teamId: team.id
    }))

    return defaultOwnershipOptions.concat(teamOptions)
  }, [teamsData.data])

  const [ownership, setOwnership] = React.useState<AccountView['ownership']>(props.view?.ownership ?? 'private')
  const [teamId, setTeamId] = React.useState<AccountView['team_id']>(props.view?.team_id)

  const loc = useLocation()
  const search = useMemo(() => new URLSearchParams(loc.search), [loc.search])

  useEffect(() => {
    setTeamId(props.view?.team_id)
    setOwnership(props.view?.ownership ?? 'private')

    if (search.get('team_id')) {
      setOwnership('team')
      setTeamId(search.get('team_id') ?? undefined)
    }
  }, [props, search])

  const onSubmit = React.useCallback(() => {
    setIsSubmitting(true)
  }, [])

  const persisted = view?.id !== undefined

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{persisted ? 'Update your list' : 'Create a list'}</ModalHeader>

        <ModalBody
          as="form"
          action={persisted ? accountViewPath(view) + location.search : customViewsPath()}
          method="POST"
          id="save-view-form"
          onSubmit={onSubmit}
        >
          <AuthenticityToken />
          {persisted ? (
            <input type="hidden" name="_method" value="PATCH" />
          ) : (
            <input type="hidden" name="account_view[kind]" value={props.view?.kind ?? props.kind} />
          )}

          {displayColumns?.map((column) => (
            <input key={column} type="hidden" name="account_view[display_settings][display_columns][]" value={column} />
          ))}

          <Stack>
            <Stack spacing="6">
              {!persisted && (
                <Text fontSize="sm" color="gray.600">
                  Lists let you save targeting filters to share audiences with your team or revisit matching
                  accounts/visitors later. You can use lists to subscribe to Slack Alerts or create Automations.
                </Text>
              )}
              <FormControl>
                <FormLabel htmlFor="account_view[name]">Name</FormLabel>
                <Input
                  autoFocus
                  size="sm"
                  autoComplete="off"
                  placeholder="Enter a name for your list"
                  name="account_view[name]"
                  isRequired
                  id="account_view[name]"
                  defaultValue={props.view?.name}
                />
              </FormControl>

              {ownership !== 'space' && !props.view?.space_id && (
                <FormControl>
                  <input type="hidden" name="account_view[ownership]" value={ownership} />
                  <input type="hidden" name="account_view[team_id]" value={teamId || ''} />

                  <FormLabel>Sharing</FormLabel>
                  {teamsData.isLoading ? (
                    <Flex height="32px" alignItems="center">
                      <Spinner color="gray.400" size="sm" thickness="1.5px" />
                    </Flex>
                  ) : (
                    <Box>
                      <SelectInput
                        size="sm"
                        variant="outline"
                        items={ownershipOptions}
                        itemToString={(item) => item?.label ?? 'Unknown'}
                        selectedItem={ownershipOptions.find(
                          (s) => s.value === ownership && (ownership === 'team' ? s.teamId === teamId : !s.teamId)
                        )}
                        onSelectedItemChange={({ selectedItem }) => {
                          setOwnership(selectedItem?.value ?? 'private')
                          setTeamId(selectedItem?.teamId)
                        }}
                        itemRenderer={(item) => {
                          return (
                            <Flex alignItems="center" gap={2} fontSize="sm">
                              <Icon flex="none" as={ownershipIcons[item.value]} boxSize={4} color="gray.600" />
                              <Text fontWeight="medium">{item.label}</Text>
                            </Flex>
                          )
                        }}
                        popperOptions={{
                          matchWidth: true
                        }}
                      />
                    </Box>
                  )}
                  <FormHelperText>
                    You can share this list with everyone in your workspace, a specific team, or keep it for yourself.
                  </FormHelperText>
                </FormControl>
              )}
            </Stack>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button size="sm" variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button size="sm" type="submit" colorScheme="purple" form="save-view-form" isLoading={isSubmitting}>
            {persisted ? 'Update list' : 'Create list'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type Props = {
  isFiltering?: boolean
  type: 'account' | 'profile'
  displayColumns?: string[]
  permissions?: Record<'can_edit' | 'can_create' | 'can_destroy', boolean>
}

export const SaveAccountView = (props: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const canCreateList = props.permissions?.can_create

  return (
    <Flex>
      <Tooltip
        shouldWrapChildren
        label={
          canCreateList
            ? undefined
            : "You do not have enough permissions to update this list. Please contact your Koala Admin if you'd like to save changes to this list."
        }
      >
        <Button
          colorScheme={props.type === 'account' ? 'purple' : 'blue'}
          size="sm"
          variant="solid"
          type="submit"
          isDisabled={!props.isFiltering || !canCreateList}
          onClick={onOpen}
        >
          Save as list
        </Button>
      </Tooltip>

      <AccountViewModal kind={props.type} displayColumns={props.displayColumns} isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}
