export const defaultCategoricalColors = [
  '#7467ef', // Muted Purple
  '#7F9CF5', // Muted lighter purple/blue
  '#B794F4', // Soft lilac
  '#F687B3', // Pink with a hint of purple
  '#a7dcf9', // light blue
  '#F56565', // Soft red for contrast
  '#ED8936', // Muted orange for warmth
  '#ECC94B' // Warm muted yellow
]

export function constructCategoryColors(categories: string[], colors: string[]): Map<string, string> {
  const categoryColors = new Map<string, string>()

  categories.forEach((category, idx) => {
    categoryColors.set(category, colors[idx % colors.length])
  })

  return categoryColors
}
