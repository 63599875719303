import { Box, Flex, Link, Stack, Text } from '@chakra-ui/react'
import { truncate } from 'lodash'
import React, { useMemo, useState } from 'react'
import { CRMAccount, CRMMatch } from '../../../../types/Account'
import { JSONTree } from '../../../ui/json-tree'
import { StackedField } from '../../../ui/StackedField'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { VirtualList } from '../../../ui/VirtualList'
import { SearchBar } from '../facets/search-bar'
import { isURL, traitDataType } from './DetailsV2/TraitsCard'
import { Toggle } from './Toggle'

interface Props {
  crmEntity?: CRMAccount
  strongMatches?: CRMMatch[]
  payload: Record<string, any>
}

export function CRMDebugPanel(props: Props) {
  return (
    <Toggle
      showIcon={false}
      title={(isOpen) => (
        <Flex justifyContent={'flex-end'} w="100%">
          <Text fontSize="xs">{isOpen ? 'Show less (-)' : 'View all properties (+)'}</Text>
        </Flex>
      )}
    >
      <CRMProperties {...props} />
    </Toggle>
  )
}

export function CRMProperties(props: Props) {
  const payload = props.payload
  const [filter, setFilter] = useState('')

  const orderedKeys = useMemo(() => (payload ? Object.keys(payload).sort() : []), [payload])
  const filteredKeys = useMemo(
    () =>
      orderedKeys.filter(
        (key) =>
          key.toLowerCase().includes(filter.toLowerCase()) ||
          payload?.[key]?.toString().toLowerCase().includes(filter.toLowerCase())
      ),
    [filter, orderedKeys, payload]
  )

  if (!payload) {
    return null
  }

  return (
    <Stack spacing={4} w="100%" p="1">
      <SearchBar value={filter} onChange={setFilter} placeholder="Search properties or values" size="sm" />
      <VirtualList
        maxH="400px"
        items={filteredKeys}
        estimateSize={() => 50}
        showOverflowFade
        renderItem={(key) => {
          if (key === null || key === '') {
            return null
          }

          const value = payload[key]

          return (
            <Box w="100%" pb="4">
              <StackedField key={key} label={key} w="100%">
                {typeof value === 'object' ? (
                  <Box ml="-4" mt="-2">
                    <JSONTree data={value} />
                  </Box>
                ) : (
                  <TextEllipsis tooltip>
                    {traitDataType(value) === 'number' ? value : null}
                    {traitDataType(value) === 'string' && !isURL(value) ? value : null}
                    {traitDataType(value) === 'boolean' ? JSON.stringify(value) : null}
                    {traitDataType(value) === 'date' ? new Date(value as string).toLocaleString() : null}
                    {traitDataType(value) === 'null' ? 'null' : null}

                    {isURL(value) ? (
                      <Link href={value as string} isExternal>
                        {truncate(value as string, { length: 45 })}
                      </Link>
                    ) : null}
                  </TextEllipsis>
                )}
              </StackedField>
            </Box>
          )
        }}
      />
    </Stack>
  )
}
