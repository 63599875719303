import { projectPath } from '../../../ui/ProjectsContext'

export function newImportPath(kind: 'profiles' | 'accounts') {
  return projectPath(`/${kind || 'profiles'}/imports/new`)
}

export function importsPath() {
  return projectPath('/imports')
}

export function importPath(idOrSlug: string) {
  return projectPath(`/imports/${idOrSlug}`)
}

export function importListPath(slug: string, type: 'accounts' | 'visitors') {
  return projectPath(`/${type}?facets[import_lists.slug][]=${slug}&range=any`)
}
