import React from 'react'
import { useMutation } from 'react-query'
import { toast } from 'sonner'
import { put } from '../../lib/api'
import { projectPath } from '../ui/ProjectsContext'

interface Params {
  impact: string
  opportunityId: string
}

export function useSetAttribution() {
  const onError = React.useCallback(() => {
    toast.error('Failed to save updates', {
      description: 'There was an issue saving impact for this opportunity.'
    })
  }, [])

  return useMutation(
    ({ opportunityId, impact }: Params) => {
      const path = projectPath(`/reports/influence/${opportunityId}`)
      return put(path, {
        attribution: { impact }
      })
    },
    {
      onError
    }
  )
}
