import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { IconTrash, IconWorld } from '@tabler/icons-react'
import React from 'react'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { DeleteConfirmation } from '../../../ui/DeleteConfirmation'
import { usePermission } from '../../../ui/PermissionsContext'
import { projectPath } from '../../../ui/ProjectsContext'
import { post, postForm } from '../../../../lib/api'
import { toast } from 'sonner'

interface AuthorizedReferrersProps {
  domains?: string[]
}

export function AuthorizedReferrers(props: AuthorizedReferrersProps) {
  const { hasPermission: canManageWebsite } = usePermission({ on: 'project', action: 'can_manage_website' })
  const [selectedDomain, setSelectedDomain] = React.useState<string | null>(null)
  const { isOpen: addIsOpen, onOpen: onAdd, onClose: onAddClose } = useDisclosure()
  const { isOpen: deleteIsOpen, onOpen: onDelete, onClose: onDeleteClose } = useDisclosure()
  const [domains, setDomains] = React.useState<string[]>(props.domains ?? [])

  React.useEffect(() => {
    if (!deleteIsOpen) {
      setSelectedDomain(null)
    }
  }, [deleteIsOpen])

  const handleListChange = React.useCallback((newList) => {
    setDomains(newList)
  }, [])

  return (
    <Flex direction="column" gap={6}>
      <AddReferrerModal isOpen={addIsOpen} onClose={onAddClose} onSuccess={handleListChange} />
      <RemoveReferrerModal
        domain={selectedDomain}
        isOpen={deleteIsOpen}
        onClose={onDeleteClose}
        onSuccess={handleListChange}
      />

      <Flex gap={4} justifyContent="space-between">
        <Flex direction="column" gap={1}>
          <Heading size="sm">Authorized referrers</Heading>
          <Text fontSize="sm" color="gray.600">
            Add the domains and subdomains that you want Koala to track as Authorized Referrers. This is an extra
            security measure that will prevent loading the SDK or sending events from domains you do not want to track.
          </Text>
        </Flex>
        <Button
          flex="none"
          size="sm"
          colorScheme="purple"
          isDisabled={!canManageWebsite}
          onClick={onAdd}
          leftIcon={<IconWorld size="16" />}
        >
          Add referrer
        </Button>
      </Flex>

      {domains.length > 0 && (
        <VStack alignItems="stretch" spacing={0} borderWidth={'1px'} rounded="lg" px="6" py="2" divider={<Divider />}>
          {domains?.map((domain) => (
            <Flex h="14" key={domain} justifyContent="space-between" alignItems="center">
              <Text fontSize="sm" fontWeight="medium">
                {domain}
              </Text>
              {canManageWebsite && (
                <IconButton
                  icon={<IconTrash size={14} />}
                  aria-label="Remove domain"
                  size="sm"
                  variant="ghost"
                  onClick={() => {
                    setSelectedDomain(domain)
                    onDelete()
                  }}
                />
              )}
            </Flex>
          ))}
        </VStack>
      )}
    </Flex>
  )
}

function RemoveReferrerModal(
  props: Omit<ModalProps, 'children'> & { domain: string | null; onSuccess: (domain: string[]) => void }
) {
  if (!props.domain) {
    return null
  }

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()

    try {
      const response = await post<{ settings: Record<string, any>; errors?: string[] }>(
        projectPath(`/website-settings/delete-authorized-referrers`),
        { domain: props.domain }
      )

      if (response.settings) {
        props.onClose()
        props.onSuccess(response.settings.value)
      }

      toast.success('Referrer removed fromo authorized list')
    } catch (_error) {
      toast.error('Failed to remove referrer from authorized list')
    }
  }

  return (
    <DeleteConfirmation
      isOpen={props.isOpen}
      onClose={props.onClose}
      onConfirm={onSubmit}
      title={`Are you sure you want to remove the authorized referrer ${props.domain}?`}
      confirmLabel="Remove referrer"
    >
      <Text fontSize="sm" color="gray.600">
        Removing this referrer means that it will stop from sending data to this workspace.
      </Text>
    </DeleteConfirmation>
  )
}

function AddReferrerModal(props: Omit<ModalProps, 'children'> & { onSuccess: (domain: string) => void }) {
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const onSubmit: React.FormEventHandler<HTMLFormElement> = React.useCallback(
    async (e) => {
      e.preventDefault()
      const form = e.target as HTMLFormElement
      const data = new FormData(form)

      try {
        setIsSubmitting(true)
        const response = await postForm<{ settings: Record<string, any>; errors?: string[] }>(form.action, data)

        if (response.settings) {
          props.onClose()
          props.onSuccess(response.settings.value)
        }

        toast.success('Referrer added to authorized list')
      } catch (_error) {
        toast.error('Failed to add domain to authorized')
      } finally {
        setIsSubmitting(false)
      }
    },
    [props]
  )

  return (
    <Modal {...props}>
      <form method="POST" action={projectPath('/website-settings/authorized-referrers')} onSubmit={onSubmit}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add an authorized referrer</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AuthenticityToken />
            <FormControl id="domain">
              <FormLabel>Domain</FormLabel>
              <Input
                name="sdk_settings[authorized_referrers][domain]"
                autoComplete="off"
                pattern="[\w.-]+"
                placeholder="example.com"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter justifyContent="flex-end" gap={3}>
            <Button type="submit" size="sm" colorScheme="purple" isLoading={isSubmitting}>
              Add referrer
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}
