import { Badge, Flex, Heading, HStack, VStack } from '@chakra-ui/react'
import { IconUsers } from '@tabler/icons-react'
import React from 'react'
import { Apps } from '../../../types/App'
import { Crm } from '../../../types/Crm'
import { ProfileRecord } from '../../../types/Profile'
import { useUrlFilters } from '../../data/use-url-filters'
import { TopBarContent } from '../../ui/TopBarContext'
import PageTitle, { SmallPageHeading } from '../../ui/PageTitle'
import { FacetFilters } from '../accounts'
import { FilterPreview } from '../accounts/components/FilterPreview'
import { ListContainer } from '../lists/components/ListContainer'
import { PlayPauseButton } from './components/play-pause-button'
import { HighlightedProfile } from './components/profile-list'
import { ProfileLiveList } from './components/profile-live-list'
import { VisitorTabs } from './components/visitor-tabs'

export interface ProfilesProps {
  facet_filters: FacetFilters
  crm?: Crm
  profiles: ProfileRecord[]
  apps: Apps
  permissions?: Record<'can_edit' | 'can_create' | 'can_destroy', boolean>
  total_count: number
}

export default function Index(props: ProfilesProps) {
  const facets = useUrlFilters({
    initialRange: undefined,
    initialSortBy: undefined,
    facetCloudPath: '/profiles/facet-cloud'
  })

  const [paused, setPaused] = React.useState(false)

  return (
    <ListContainer>
      <TopBarContent onlyNewNav>
        <Flex alignItems="center" gap={1.5}>
          <SmallPageHeading size="xs" fontWeight="medium">
            Visitors
          </SmallPageHeading>
        </Flex>
      </TopBarContent>

      <VStack spacing={3} alignItems="stretch">
        <Flex
          w="100%"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          flexWrap={['wrap', 'nowrap']}
          direction={['column-reverse', 'row']}
        >
          <PageTitle skipRendering>Visitors</PageTitle>

          <HStack flex="1 1 100%" width="100%" justifyContent="space-between" spacing={3}>
            <HStack flex="none" spacing={3}>
              <IconUsers size={20} />
              <Heading display="inline-flex" gap={1} alignItems="baseline" size="md" fontWeight={'semibold'}>
                Visitors
              </Heading>
              <Badge colorScheme={paused ? 'gray' : 'green'}>Live</Badge>
            </HStack>
            <PlayPauseButton paused={paused} onToggle={(mode) => setPaused(mode === 'paused')} />
          </HStack>
          <VisitorTabs />
        </Flex>

        <FilterPreview
          {...facets}
          kind="profile"
          excludedKeys={['visitor_stats.identified.month', 'visitor_stats.visitors.month']}
          range={undefined}
          canClearFilters={false}
          apps={props.apps}
          facetValuesPath={'/people/live-facets'}
        />

        <ProfileLiveList
          profiles={props.profiles as unknown as HighlightedProfile[]}
          range={'day'}
          facetParams={facets}
          onSortChange={undefined}
          total_count={props.total_count}
          paused={paused}
          onChangeMode={(mode) => setPaused(mode === 'paused')}
        />
      </VStack>
    </ListContainer>
  )
}
