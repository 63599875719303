import { Button, Flex, HStack, Icon, IconButton, Link, Stack, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import { IconFlag, IconMailCheck, IconMapPin } from '@tabler/icons-react'
import React, { useMemo } from 'react'
import { Prospect } from '../../../data/use-prospects'
import { DetailsCard } from '../../../ui/Card'
import { CardHeading } from '../../../ui/CardHeading'
import { Copyable } from '../../../ui/Copyable'
import { LinkedinBoxIcon } from '../../../ui/icons'
import { StackedField } from '../../../ui/StackedField'
import { ProspectFeedback } from '../../prospects/action-menu'
import { useUIState } from './useUIState'

interface KoalaWaterfallCardProps {
  record?: Prospect & {
    personal_emails?: string[]
    work_emails?: string[]
    phone_numbers?: {
      phone_numbers?: string[]
      mobile?: string
    }
  }
  domain?: string
  initiallyCollapsed?: boolean
  isLoading?: boolean
  onRequestImport?: () => void
}

export function KoalaWaterfallCard(props: KoalaWaterfallCardProps) {
  const [ui, setUI] = useUIState()
  const cardDisclosure = useDisclosure({
    defaultIsOpen: ui.open?.clearbit ?? !props.initiallyCollapsed,
    onOpen: () => {
      setUI({ open: { clearbit: true } })
    },
    onClose: () => {
      setUI({ open: { clearbit: false } })
    }
  })

  const cleanLinkedinUrl = useMemo(() => {
    if (!props.record?.linkedin_url) {
      return null
    }

    let clean = props.record.linkedin_url.split('/').slice(-2).join('/')
    if (!clean.startsWith('in')) {
      clean = `in/${clean}`
    }

    return clean
  }, [props.record?.linkedin_url])

  const feedbackModal = useDisclosure()

  return (
    <>
      <DetailsCard>
        <CardHeading icon={'https://logo.clearbit.com/getkoala.com'} disclosure={cardDisclosure}>
          <HStack w="100%" justifyContent="space-between">
            <Text>Koala Waterfall</Text>

            {props.record && props.domain && (
              <Tooltip label="Report Issue">
                <IconButton
                  aria-label="Report Issue"
                  variant="ghost"
                  _hover={{
                    bg: 'red.50',
                    color: 'red.700'
                  }}
                  size="xs"
                  icon={<IconFlag size="14px" />}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    feedbackModal.onOpen()
                  }}
                />
              </Tooltip>
            )}
          </HStack>
        </CardHeading>
        {!cardDisclosure.isOpen ? null : props.record ? (
          <Stack spacing={4}>
            <StackedField label="Name">{props.record.first_name + ' ' + props.record.last_name}</StackedField>

            <StackedField label="Title">{props.record.title}</StackedField>

            <StackedField
              label={
                <HStack spacing="1">
                  <LinkedinBoxIcon color="linkedin.700" boxSize={4} />
                  <Text>LinkedIn</Text>
                </HStack>
              }
            >
              <Link variant="dotted" isExternal href={`https://linkedin.com/${cleanLinkedinUrl}`}>
                /{cleanLinkedinUrl}
              </Link>
            </StackedField>

            <Stack spacing="1">
              <HStack spacing="1">
                <Icon as={IconMailCheck} boxSize={3.5} />
                <Text fontSize="12" color="gray.500" fontWeight={'normal'}>
                  Emails
                </Text>
              </HStack>
              <Stack spacing="1">
                <Flex>
                  <Copyable fontSize={'sm'}>{props.record.email}</Copyable>
                </Flex>

                {props.record.work_emails?.map((email) => {
                  if (email === props.record?.email) {
                    return null
                  }

                  return (
                    <Flex key={email}>
                      <Copyable fontSize={'sm'}>{email}</Copyable>
                    </Flex>
                  )
                })}
                {props.record.personal_emails?.map((email) => {
                  if (email === props.record?.email) {
                    return null
                  }

                  return (
                    <Flex key={email}>
                      <Copyable fontSize={'sm'}>{email}</Copyable>
                    </Flex>
                  )
                })}
              </Stack>
            </Stack>

            <StackedField label="Phone">{props.record.phone_numbers?.mobile}</StackedField>

            <StackedField
              label={
                <HStack spacing="1">
                  <Icon as={IconMapPin} boxSize={3.5} />
                  <Text>Location</Text>
                </HStack>
              }
            >
              {[props.record.city, props.record.region, props.record.country].filter(Boolean).join(', ')}
            </StackedField>
          </Stack>
        ) : (
          <Stack>
            <Text fontSize="xs" color="gray.500">
              No person found or enriched yet
            </Text>
            <Button isLoading={props.isLoading} size="sm" colorScheme={'purple'} onClick={props.onRequestImport}>
              Enrich
            </Button>
          </Stack>
        )}
      </DetailsCard>

      {props.record && props.domain && (
        <ProspectFeedback
          context="profile_page"
          domain={props.domain}
          prospect={{
            ...props.record,
            id: props.record.external_id
          }}
          {...feedbackModal}
        />
      )}
    </>
  )
}
