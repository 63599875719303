import { Center } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React, { useCallback } from 'react'
import router from '../../../lib/router'
import { AccountView } from '../../../types/AccountView'
import { App, Apps } from '../../../types/App'
import { User } from '../../../types/Invite'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import useLocation from '../../ui/useLocation'
import { FacetFilters } from '../accounts'
import { HighlightedAccount, mergeParams } from '../icps/types'
import { NeedsSetup } from './rep/needs-setup'
import { RepAlternativeEmail } from './rep/rep-alternative-email'
import { DigestSubscription, RepAudiences } from './rep/rep-audiences'
import { RepDone } from './rep/rep-done'
import { RepGuides } from './rep/rep-guides'
import { RepMyAccounts } from './rep/rep-my-accounts'

export interface RepOnboardingProps {
  project_onboarded: boolean
  my_accounts: HighlightedAccount[]
  suggested_users: Array<{ id: string; name: string; email: string }>
  views: AccountView[]
  crms: App[]
  apps: Apps
  my_accounts_filters?: FacetFilters
  subscriptions: DigestSubscription[]
  admins: User[]
}

export interface StepProps {
  onContinue: () => void
  onSkip: () => void
  onBack?: () => void
}

export default function OnboardingLayout(props: RepOnboardingProps) {
  return (
    <PageLayout
      size="full"
      background={['white', 'white', 'gray.600']}
      flush
      css={`
        --max-container-width: 100vw;
        height: 100vh;
      `}
    >
      <PageTitle skipRendering>Welcome</PageTitle>
      <RepOnboarding {...props} />
    </PageLayout>
  )
}

export function RepOnboarding(props: RepOnboardingProps) {
  const loc = useLocation()
  const params = React.useMemo(() => new URLSearchParams(loc.search), [loc.search])

  const repNeedsSetup = !props.project_onboarded
  const step = React.useMemo(() => params.get('step') ?? 'my-accounts', [params])

  const setStep = useCallback((step: string) => {
    router.visit(mergeParams(window.location.toString(), { step }), { fetch: false })
  }, [])

  return (
    <Center display={['block', 'block', 'flex']} w="100%" h="100%" p={[0, 0, 4]}>
      <motion.div
        layout
        transition={{
          layout: { duration: 0.15, ease: 'easeInOut' }
        }}
        style={{
          maxWidth: '100vw',
          maxHeight: '100vh'
        }}
      >
        {(step === 'setup' || repNeedsSetup) && (
          <NeedsSetup
            admins={props.admins}
            onContinue={() => setStep('my-accounts')}
            onSkip={() => router.visit(projectPath('/welcome/skip_rep_onboarding'))}
          />
        )}

        {!repNeedsSetup && (
          <>
            {step === 'my-accounts' && props.my_accounts.length > 0 && (
              <RepMyAccounts
                accounts={props.my_accounts}
                onContinue={() => setStep('audiences')}
                my_accounts_filters={props.my_accounts_filters}
                onSkip={() => setStep('alternative-email')}
              />
            )}
            {(step === 'alternative-email' || (step === 'my-accounts' && props.my_accounts.length === 0)) && (
              <RepAlternativeEmail
                onBack={() => setStep('my-accounts')}
                accounts={props.my_accounts}
                my_accounts_filters={props.my_accounts_filters}
                apps={props.apps}
                onContinue={() => setStep('audiences')}
                onSkip={() => setStep('audiences')}
              />
            )}
            {step === 'audiences' && (
              <RepAudiences
                subscriptions={props.subscriptions}
                onBack={() => setStep('my-accounts')}
                views={props.views}
                onContinue={() => setStep('guides')}
                onSkip={() => setStep('guides')}
              />
            )}
            {step === 'guides' && (
              <RepGuides
                onBack={() => setStep('audiences')}
                onContinue={() => setStep('done')}
                onSkip={() => setStep('done')}
              />
            )}
            {step === 'done' && (
              <RepDone
                onBack={() => setStep('guides')}
                onContinue={() => router.visit(projectPath('/views/mine'))}
                onSkip={() => router.visit(projectPath('/accounts'))}
              />
            )}
          </>
        )}
      </motion.div>
    </Center>
  )
}
