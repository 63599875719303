import { Box, Button, Code, Heading, HStack, Icon, Image, Link, Stack, Text } from '@chakra-ui/react'
import { IconArrowRight, IconArrowUpRight } from '@tabler/icons-react'
import pluralize from 'pluralize'
import React from 'react'
import { PublicAPIKey } from '../../../../types/PublicAPIKey'
import { LightBgCard } from '../../../ui/Card'
import { Copyable } from '../../../ui/Copyable'
import { InfoBox } from '../../../ui/InfoBox'
import PageDescription from '../../../ui/PageDescription'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { projectPath } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import { StepIcon } from '../../../ui/StepIcon'
import { Introduction } from '../components/Introduction'

interface Props {
  title: string
  description: string
  logo: string
  settings: Record<string, string | null | undefined>
  deps: {
    public_api_keys: PublicAPIKey[]
  }
}

export default function Show(props: Props) {
  const keys = props.deps.public_api_keys.filter((k) => !k.archived)

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/segment'),
            title: 'Segment'
          }
        ]}
        offscreen
      />
      <Box>
        <HStack>
          <Image src={props.logo} maxW="6" />
          <PageTitle flex="1">{props.title}</PageTitle>
        </HStack>
        <PageDescription>{props.description}</PageDescription>
      </Box>

      <Introduction
        app="segment"
        icon={props.logo}
        description="With Segment you can install Koala without code. Use our client-side destination (recommended) to automatically send all your track and identify calls from Segment to Koala and start capturing active website sessions in Koala. Alternatively, you can use our server-side destination to automatically send events and identifies from Segment to Koala server-side."
        docsLink="https://getkoala.com/docs/integrations/segment"
      />

      <InfoBox colorScheme="blue">
        <Text>
          Note: If you have Segment and Koala installed alongside each other already, Koala will automatically hook into
          Segment instrumentation for you. You can disable this behavior if you don't want it by turning off Segment
          autotracking{' '}
          <Link variant="dotted" href={projectPath('/settings/install')}>
            here
          </Link>
          .
        </Text>
      </InfoBox>

      <LightBgCard gap={6}>
        <Heading size="sm">Send Segment events and customer data to Koala</Heading>

        <Stack fontSize="sm">
          <HStack alignItems="flex-start">
            <StepIcon step={1} />
            <Text>
              <Link display="inline-flex" gap={0.5} alignItems="center" href="https://app.segment.com/login" isExternal>
                Log in to Segment
                <Icon as={IconArrowUpRight} boxSize={4} color="gray.500" />
              </Link>
              {', '}
              navigate to the destination catalog and search for the <strong>Koala</strong> destination
            </Text>
          </HStack>
          <HStack alignItems="flex-start">
            <StepIcon step={2} />
            <Text>
              Click <strong>"Add destination"</strong>
            </Text>
          </HStack>
          <HStack alignItems="flex-start">
            <StepIcon step={3} />
            <Text>
              Select the Segment source you have installed on your website and click <strong>"Next"</strong>
            </Text>
          </HStack>
          <HStack alignItems="flex-start">
            <StepIcon step={4} />
            <Text>
              Enter {keys.length === 1 ? 'your' : 'one of your'} Koala public API {pluralize('key', keys.length)} and
              save:
              <br />
              <Stack py={1.5} alignItems="flex-start">
                {keys.map((apiKey) => (
                  <Text key={apiKey.key} fontSize="xs">
                    <Code paddingX={1} rounded="md" fontWeight="semibold">
                      <Copyable inline copyText={apiKey.key}>
                        <span>{apiKey.key}</span>
                      </Copyable>
                    </Code>
                    {apiKey.name && ` (${apiKey.name})`}
                  </Text>
                ))}
              </Stack>
            </Text>
          </HStack>

          <Text>
            By default, Segment will automatically send all track and identify events to Koala. If you don't want that,
            you can customize how you want Segment to send data to Koala.
          </Text>
        </Stack>

        <Button
          as={Link}
          colorScheme="purple"
          size="sm"
          href="https://app.segment.com/goto-my-workspace/destinations/catalog/actions-koala"
          isExternal
          rightIcon={<IconArrowRight size="18" />}
        >
          Connect on Segment
        </Button>
      </LightBgCard>
    </PageLayout>
  )
}
