import { projectPath } from '@app/components/ui/ProjectsContext'
import { concurrentCachedGET } from '@app/lib/api'
import { Account } from '@app/types/Account'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  BoxProps,
  Center,
  Circle,
  Divider,
  Flex,
  Heading,
  HStack,
  Link,
  ListItem,
  SkeletonText,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Tooltip,
  UnorderedList,
  useBoolean
} from '@chakra-ui/react'
import { IconExternalLink, IconId, IconTimeline } from '@tabler/icons-react'
import orderBy from 'lodash/orderBy'
import pick from 'lodash/pick'
import uniq from 'lodash/uniq'
import ms from 'ms'
import React, { useEffect, useMemo } from 'react'
import { ProfileRecord } from '../../../../types/Profile'
import { FacetParams } from '../../../data/use-facets'
import { UrlFilterParams } from '../../../data/use-url-filters'
import { BubbleTag } from '../../../ui/BubbleTag'
import { HoverCard } from '../../../ui/HoverCard'
import { IntentBolt } from '../../../ui/IntentBolt'
import { MiddotDivider } from '../../../ui/Middot'
import { TimeAgo } from '../../../ui/TimeAgo'
import { VirtualList } from '../../../ui/VirtualList'
import { TimelineItem } from '../../accounts/components/TimelineItem'
import { SearchBar } from '../../accounts/facets/search-bar'
import { SignalType } from '../../kql_definitions/components/SignalType'
import { TrackIntent } from '../../kql_definitions/components/TrackIntent'
import { KqlDefinition } from '../../kql_definitions/types'
import { AccountFeedProps } from '../../sessions/components/AccountFeed'
import { VisitorFeed } from '../../sessions/components/Feed'
import { profilePath } from '../lib/path'
import { HighlightedProfile } from './profile-list'

export type PageViewEntry = {
  profile_id: string
  path: string
  visits: number
  total_focus_time: number
  first_visit: Date
  last_visit: Date
}

export type KQLFeedEntry = {
  profile_id: string
  account_id: string
  id?: string
  name: string
  last_triggered_at: string
  signal_type?: string
}

export type FormEntry = {
  profile_id: string
  path: string
  updated_at: Date
  submissions: number
}

export type AccountPageFeed = Record<
  string,
  {
    intent: PageViewEntry[]
    profile: ProfileRecord
  }
>

export type AccountFormFeed = Record<
  string,
  {
    intent: FormEntry[]
    profile: ProfileRecord
  }
>

const emptyArray = []

export function IntentSignalCell(props: {
  feed: Array<Pick<KQLFeedEntry, 'id' | 'signal_type' | 'name' | 'last_triggered_at'>>
}) {
  const count = props.feed.length
  if (count === 0) {
    return null
  }

  const allContent = (
    <Stack>
      {props.feed.map((kql) => (
        <Flex
          key={kql.id + kql.name + kql.last_triggered_at}
          flex="1 1 auto"
          alignItems="center"
          fontSize="xs"
          gap={2}
          overflow="hidden"
        >
          <SignalType
            label={kql.name}
            signalType={kql.signal_type}
            marginRight={1}
            compact
            isTruncated
            fontSize="xs"
            flexShrink={1}
            minW="80px"
          />

          {kql.last_triggered_at && (
            <Text color="gray.500" whiteSpace="nowrap">
              <TimeAgo time={kql.last_triggered_at} canToggle={false} />
            </Text>
          )}
        </Flex>
      ))}
    </Stack>
  )

  return (
    <HoverCard
      trigger="hover"
      isPortal
      popoverContentProps={{ maxW: '400px', minW: '320px' }}
      hoverContent={allContent}
    >
      <Box display="inline-flex">
        <IntentBolt count={count} variant="subtle" />
      </Box>
    </HoverCard>
  )
}

export function KQLFeed(props: {
  feed: Array<Pick<KQLFeedEntry, 'id' | 'signal_type' | 'name' | 'last_triggered_at'>>
  facetParams?: FacetParams | UrlFilterParams
  showAll?: boolean
}) {
  const selectedKQLFilters = (props.facetParams?.facetFilters['signal.name'] ?? emptyArray) as string[]

  const first = useMemo(() => {
    if (props.feed.length === 0) {
      return null
    }

    let match

    if (selectedKQLFilters.length > 0) {
      match = props.feed.find((f) => selectedKQLFilters.includes(f.name))
    }

    return match || props.feed[0]
  }, [props.feed, selectedKQLFilters])

  if (props.feed.length === 0) {
    return null
  }

  const hasMore = props.feed.length > 1

  const allContent = (
    <Stack>
      {props.feed.map((kql) => (
        <Flex
          key={kql.id + kql.name + kql.last_triggered_at}
          flex="1 1 auto"
          alignItems="center"
          fontSize="xs"
          gap={2}
          overflow="hidden"
        >
          <SignalType
            label={kql.name}
            signalType={kql.signal_type}
            marginRight={1}
            compact
            isTruncated
            fontSize="xs"
            flexShrink={1}
            minW="80px"
          />

          {kql.last_triggered_at && (
            <Text color="gray.500" whiteSpace="nowrap">
              <TimeAgo time={kql.last_triggered_at} canToggle={false} />
            </Text>
          )}
        </Flex>
      ))}
    </Stack>
  )

  if (props.showAll) {
    return allContent
  }

  return (
    <HoverCard
      trigger="hover"
      isPortal
      popoverContentProps={{ maxW: '400px', minW: '320px' }}
      hoverContent={allContent}
    >
      <Flex wrap="nowrap" gap={1.5} alignItems="center">
        <SignalType
          label={first!.name}
          signalType={first!.signal_type}
          marginRight={1}
          compact
          isTruncated
          fontSize="xs"
          flexShrink={1}
          minW="60px"
        />
        {hasMore && <BubbleTag>+{props.feed.length - 1}</BubbleTag>}
      </Flex>
    </HoverCard>
  )
}

function PageItem(props: { entry: PageViewEntry; pageFacets: string[] }) {
  const item = props.entry
  const isPathMatch = props.pageFacets.find((facet) => item.path.replace(/\//g, '').includes(facet.replace(/\//g, '')))

  return (
    <ListItem key={item.path} fontSize="sm" listStyleType={'none'} role="group" paddingTop={1}>
      <HStack alignItems="flex-start">
        <Stack flex="1" spacing={1} minWidth="150px" overflow="hidden">
          <Tooltip label={item.path.length >= 50 ? item.path : undefined}>
            <Text
              fontWeight={isPathMatch ? 'semibold' : 'normal'}
              maxW="400px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {item.path}
            </Text>
          </Tooltip>
          <HStack spacing={1} fontSize="xs" color="gray.600" divider={<MiddotDivider />}>
            <Text>{item.total_focus_time ? ms(item.total_focus_time, { long: true }) : ''}</Text>
            {item.visits > 1 && <Text>{item.visits} visits</Text>}
          </HStack>
        </Stack>
        <HStack>
          <Stack spacing="0" alignItems={'flex-end'}>
            <Text fontSize="xs" color="gray.900">
              <TimeAgo time={item.last_visit} mode="calendar" />
            </Text>
            <Text fontSize="xs" color="gray.600">
              <TimeAgo time={item.last_visit} />
            </Text>
          </Stack>
          <TrackIntent
            intentType="page"
            conditions={[
              {
                kind: 'page_view',
                with: {
                  value: ['5 seconds'],
                  operator: 'greater_than',
                  property: 'focus_time'
                },
                value: [item.path],
                operator: 'starts_with',
                property: 'path'
              }
            ]}
          />
        </HStack>
      </HStack>
    </ListItem>
  )
}

function FormItem(props: { entry: FormEntry; pageFacets: string[] }) {
  const item = props.entry
  const isPathMatch = props.pageFacets.find((facet) => item.path.replace(/\//g, '') === facet.replace(/\//g, ''))

  return (
    <ListItem key={item.path} fontSize="sm" listStyleType={'none'} role="group" paddingTop={1}>
      <HStack alignItems="flex-start">
        <Stack flex="1" spacing={1} minWidth="150px" overflow="hidden">
          <Text fontWeight="medium" fontSize="sm">
            Form submitted
          </Text>
          <HStack spacing={2} fontSize="xs" color="gray.600">
            {item.submissions > 1 && <Text>{item.submissions} submissions</Text>}
            <Text fontWeight={isPathMatch ? 'semibold' : 'normal'}>{item.path}</Text>
          </HStack>
        </Stack>
        <HStack>
          <Stack spacing="0" alignItems={'flex-end'}>
            <Text fontSize="xs" color="gray.900">
              <TimeAgo time={item.updated_at} mode="calendar" />
            </Text>
            <Text fontSize="xs" color="gray.600">
              <TimeAgo time={item.updated_at} />
            </Text>
          </Stack>
          <TrackIntent
            intentType="form"
            conditions={[
              {
                kind: 'form_fill',
                value: [item.path],
                operator: 'starts_with',
                property: 'page_path'
              }
            ]}
          />
        </HStack>
      </HStack>
    </ListItem>
  )
}

interface AccountProfileFeedProps {
  profile: ProfileRecord
  intent: Array<PageViewEntry | FormEntry>
  search?: string
}

interface AccountIntentFeedProps {
  account: Account
  page_feed?: AccountPageFeed
  form_feed?: AccountFormFeed
}

function AccountProfileFeed(props: AccountProfileFeedProps) {
  const { profile, search, intent } = props
  const pageIntent = intent.filter((i) => (i as PageViewEntry).visits) as PageViewEntry[]
  const formIntent = intent.filter((i) => (i as FormEntry).submissions) as FormEntry[]

  return (
    <Stack w="100%">
      <Link href={profilePath({ id: profile.id })} isExternal fontSize="sm">
        <HStack>
          <Text>{profile.name ?? profile.email ?? 'Anonymous visitor'}</Text>
          <IconExternalLink size={14} />
        </HStack>
      </Link>
      <IntentFeed form_feed={formIntent} pageFacets={[search ?? '']} page_feed={pageIntent} skipHeader maxH={'unset'} />
    </Stack>
  )
}

export function AccountIntentFeedV3(props: {
  account: Account
  selectedSignals?: string[]
  signalDefns?: KqlDefinition[]
  removeSignal?: (id: string) => void
}) {
  const [feed, setFeed] = React.useState<AccountFeedProps | null>(null)

  const signalFilters = useMemo(() => {
    const selected: KqlDefinition[] = []

    for (const id of props.selectedSignals ?? []) {
      const signal = props.signalDefns?.find((s) => s.id === id)
      if (signal) {
        selected.push(signal)
      }
    }

    return selected
  }, [props.selectedSignals, props.signalDefns])

  const path = useMemo(() => {
    const basePath = projectPath(`/people/activity`)
    return `${basePath}?account_id=${props.account.id}&selected_signals=${props.selectedSignals?.join(',')}`
  }, [props.account.id, props.selectedSignals])

  useEffect(() => {
    setFeed(null)
    let canceled = false

    concurrentCachedGET<AccountFeedProps>(path).then((res) => {
      if (canceled) return
      setFeed(res)
    })

    return () => {
      canceled = true
    }
  }, [path])

  return (
    <Flex width="100%" flexDirection="column" gap={6}>
      {signalFilters.length > 0 && (
        <Stack
          position="sticky"
          top={0}
          py={4}
          px={2}
          mx={-2}
          zIndex="sticky"
          bg="white"
          borderBottom="1px solid"
          borderColor="gray.100"
        >
          <Flex gap={2}>
            <Heading flex="none" size="xs" fontWeight="medium" my={1}>
              Signal Filters:
            </Heading>

            <Flex alignItems="center" gap={2} flexWrap="wrap">
              {/* show selected signals / filters */}
              {signalFilters.map((signal) => (
                <Tag key={signal.id}>
                  <TagLabel>{signal.name}</TagLabel>
                  <TagCloseButton onClick={() => props.removeSignal?.(signal.id!)} />
                </Tag>
              ))}
            </Flex>
          </Flex>
        </Stack>
      )}
      <Flex w="100%">
        {feed ? (
          <Stack w="100%" spacing="6">
            <VisitorFeed {...feed} loadPath={path} scopedTo="account" />
          </Stack>
        ) : (
          <Center w="100%" p="8">
            <Spinner />
          </Center>
        )}
      </Flex>
    </Flex>
  )
}

export function VisitorIntentFeedV2(props: { profile: ProfileRecord; scopedTo?: 'account' | 'profile' }) {
  const [feed, setFeed] = React.useState<AccountFeedProps | null>(null)
  const path = useMemo(() => {
    const basePath = projectPath(`/people/feed`)
    return `${basePath}?facets[id]=${props.profile.id}&excluded_accounts=false`
  }, [props.profile.id])

  useEffect(() => {
    setFeed(null)
    let canceled = false

    concurrentCachedGET<AccountFeedProps>(path).then((res) => {
      if (canceled) return
      setFeed(res)
    })

    return () => {
      canceled = true
    }
  }, [path])

  return (
    <Stack w="100%" direction="column" justifyContent="center" alignItems="center" spacing="6">
      {!feed && (
        <Center w="100%" p="8">
          <Spinner />
        </Center>
      )}
      {feed && <VisitorFeed {...feed} loadPath={path} scopedTo={props.scopedTo || 'profile'} />}
    </Stack>
  )
}

export function AccountIntentFeed(props: AccountIntentFeedProps) {
  const [search, setSearch] = React.useState('')
  type FullFeed = Record<string, { intent: Array<PageViewEntry | FormEntry>; profile: ProfileRecord }>

  const [pageFeed, setPageFeed] = React.useState(props.page_feed || {})
  const [formFeed, setFormFeed] = React.useState(props.form_feed || {})
  const shouldLoad = !props.page_feed || !props.form_feed
  const [loading, setLoading] = useBoolean(shouldLoad)

  React.useEffect(() => {
    let feedPromise, formPromise
    if (!props.page_feed) {
      feedPromise = concurrentCachedGET<AccountPageFeed>(projectPath(`/accounts/${props.account.id}/page_feed`))
        .then((response) => {
          setPageFeed(response)
        })
        .catch((error) => console.error(error))
    }

    if (!props.form_feed) {
      formPromise = concurrentCachedGET<AccountFormFeed>(projectPath(`/accounts/${props.account.id}/form_feed`))
        .then((response) => {
          setFormFeed(response)
        })
        .catch((error) => console.error(error))
    }
    Promise.all([feedPromise, formPromise]).finally(() => setLoading.off())
  }, [props.account, props.form_feed, props.page_feed, setLoading])

  const fullFeed = React.useMemo(() => {
    const allProfiles = uniq([...Object.keys(pageFeed), ...Object.keys(formFeed)])
    return allProfiles.reduce((acc, profileId) => {
      const profilePageFeed = pageFeed[profileId] ?? { intent: [] }
      const profileFormFeed = formFeed[profileId] ?? { intent: [] }

      return {
        ...acc,
        [profileId]: {
          intent: [...profilePageFeed.intent, ...profileFormFeed.intent],
          profile: profilePageFeed.profile ?? profileFormFeed.profile
        }
      }
    }, {} as FullFeed)
  }, [pageFeed, formFeed])

  const filtered = React.useMemo(() => {
    if (search === '') {
      return fullFeed
    }

    const keys = Object.keys(fullFeed).filter((f) => {
      const entries = fullFeed[f]
      const identifier = entries.profile?.name ?? entries.profile?.email ?? ''

      if (identifier.toLowerCase().includes(search.toLowerCase())) {
        return true
      }

      return entries.intent.some((i) => i.path.toLowerCase().includes(search.toLowerCase()))
    })

    return pick(fullFeed, keys)
  }, [fullFeed, search])

  const feed = React.useMemo(() => {
    return filtered
  }, [filtered])
  const feedKeys = React.useMemo(() => Object.keys(feed), [feed])

  const identifiedKeys = React.useMemo(() => {
    return feedKeys.filter((k) => feed[k].profile.email)
  }, [feedKeys, feed])

  return (
    <>
      {loading && (
        <>
          <SkeletonText noOfLines={16} spacing="4" />
        </>
      )}
      {fullFeed && !loading && (
        <Tabs minH="500" variant="line" size="sm">
          <TabList>
            <Tab flex="none">
              All People
              <Text pl="1">({Object.keys(feed).length})</Text>
            </Tab>
            <Tab flex="none">
              Identified Users
              <Text pl="1">({identifiedKeys.length})</Text>
            </Tab>
            <Flex flex="1 1 auto" py="2" marginLeft={4}>
              <SearchBar
                placeholder="Search pages and emails"
                value={search}
                onChange={(v) => setSearch(v)}
                size="sm"
              />
            </Flex>
          </TabList>

          <TabPanels>
            <TabPanel>
              <VirtualList
                items={feedKeys}
                maxH="600px"
                estimateSize={(index) => {
                  const key = feedKeys[index]
                  return feed[key].intent.length * 63 + 100
                }}
                renderItem={(key, _index) => {
                  const profile = feed[key].profile
                  const intent = feed[key].intent

                  return (
                    <Stack w="100%" spacing="8">
                      <AccountProfileFeed key={key} profile={profile} intent={intent} search={search} />
                      <Divider />
                    </Stack>
                  )
                }}
              />
            </TabPanel>

            <TabPanel>
              {identifiedKeys.length === 0 && (
                <Flex w="100%" justifyContent={'center'} alignItems="center" minH="150px">
                  <Alert variant={'left-accent'} status="warning" fontSize={'sm'} maxW="600px">
                    <AlertIcon />
                    <AlertTitle>No Activity</AlertTitle>
                    <AlertDescription>We couldn't find any activity to show in this time period.</AlertDescription>
                  </Alert>
                </Flex>
              )}
              <VirtualList
                items={identifiedKeys}
                maxH="600px"
                estimateSize={(index) => {
                  const key = identifiedKeys[index]
                  return feed[key].intent.length * 63 + 100
                }}
                renderItem={(key, _index) => {
                  const profile = feed[key].profile
                  const intent = feed[key].intent

                  return (
                    <Stack w="100%" spacing="8">
                      <AccountProfileFeed key={key} profile={profile} intent={intent} search={search} />
                      <Divider />
                    </Stack>
                  )
                }}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </>
  )
}

export function IntentFeed(props: {
  profile?: HighlightedProfile
  page_feed: PageViewEntry[]
  pageFacets: string[]
  form_feed: FormEntry[]
  maxH?: BoxProps['maxH']
  skipHeader?: boolean
}) {
  const timeline = React.useMemo(() => {
    return orderBy(
      [...props.form_feed, ...props.page_feed],
      (entry) => {
        return (entry as FormEntry).updated_at ?? (entry as PageViewEntry).last_visit
      },
      'desc'
    )
  }, [props.form_feed, props.page_feed])

  if (timeline.length === 0) {
    return null
  }

  return (
    <Flex direction="column" gap={2}>
      {!props.skipHeader && <Heading size="sm">Activity</Heading>}
      <UnorderedList maxH={props.maxH ?? '200'} overflow={'auto'} p="0" m="0" w="100%">
        {timeline.map((item, index) => {
          const isPage = (item as PageViewEntry).first_visit
          const isForm = (item as FormEntry).submissions

          return (
            <TimelineItem
              key={JSON.stringify(item)}
              name={isPage ? 'Page View' : 'Form Submission'}
              icon={
                <Circle
                  flex="none"
                  size="8"
                  backgroundColor={isPage ? 'gray.200' : 'green.100'}
                  color={isPage ? 'gray.500' : 'green.800'}
                  border="2px solid"
                  borderColor="white"
                  transform="translateX(-50%)"
                >
                  {isPage ? <IconTimeline size={16} /> : <IconId size={18} />}
                </Circle>
              }
              isFirst={index === 0}
              isLast={index === timeline.length - 1}
            >
              {isPage && <PageItem pageFacets={props.pageFacets} entry={item as PageViewEntry} />}
              {isForm && <FormItem pageFacets={props.pageFacets} entry={item as FormEntry} />}
            </TimelineItem>
          )
        })}
      </UnorderedList>
    </Flex>
  )
}
