import { useClickable } from '@chakra-ui/clickable'
import { Box } from '@chakra-ui/react'
import { IconX } from '@tabler/icons-react'
import React from 'react'

interface Props {
  onClick: () => void
  borderRadius?: number
}

function RemoveButton(props: Props) {
  const clickableProps = useClickable({ onClick: props.onClick })

  return (
    <Box
      flex="none"
      as="span"
      p={1}
      paddingRight={1.5}
      cursor="pointer"
      _hover={{ '& > svg': { opacity: 1 } }}
      _active={{ '& > svg': { opacity: 1 } }}
      transition="opacity 200ms cubic-bezier(0, 0, 0.2, 1)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      alignSelf="stretch"
      borderRadius={props.borderRadius}
      {...clickableProps}
    >
      <IconX size={14} opacity="0.5" />
    </Box>
  )
}

export default RemoveButton
