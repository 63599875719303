import { Box, Button, Collapse, Flex, HStack, Icon, Text, useDisclosure } from '@chakra-ui/react'
import { IconAlertTriangle, IconChevronDown } from '@tabler/icons-react'
import React, { RefObject, useState } from 'react'
import CircleIcon from '../../../ui/CircleIcon'
import TagsInput from '../../../ui/TagInput'
import useUpdateEffect from '../../../ui/useUpdateEffect'
import { FilterState } from '../../personas/persona-filters'

interface KeywordFilterProps {
  facet: string
  included: string[]
  notFacet?: string
  excluded: string[]
  initialFocusRef: RefObject<HTMLInputElement>
  onChange: (changes: Record<string, FilterState>) => void
}

export function KeywordFilter(props: KeywordFilterProps) {
  const [localIncluded, setLocalIncluded] = useState<string[]>(props.included)
  const [localExcluded, setLocalExcluded] = useState<string[]>(props.excluded)
  const advanced = useDisclosure({ defaultIsOpen: props.excluded.length > 0 })

  useUpdateEffect(() => {
    setLocalIncluded(props.included)
    setLocalExcluded(props.excluded)
  }, [props.included, props.excluded])

  const total = localIncluded.length + localExcluded.length

  return (
    <Flex
      flex="1 1 auto"
      minHeight="100px"
      flexDirection="column"
      gap={4}
      paddingY={4}
      bg="white"
      position="relative"
      overflow="auto"
    >
      <Flex flexDirection="column" gap={1.5} paddingX={4}>
        <Text fontSize="sm" fontWeight="semibold">
          Is any of…
        </Text>

        <TagsInput
          colorScheme="gray"
          initialTags={localIncluded}
          onChange={(tags) => {
            setLocalIncluded(tags)
          }}
          onRemove={(tag) => {
            const tags = localIncluded.filter((t) => t !== tag)
            setLocalIncluded(tags)
          }}
          placeholder="Include keywords…"
          inputProps={{
            w: '100%'
          }}
        />
      </Flex>

      {props.notFacet && (
        <Flex flexDirection="column" paddingX={4}>
          <Flex alignItems="center" gap={1.5} cursor="pointer" onClick={advanced.onToggle}>
            <Text fontSize="sm" fontWeight="semibold">
              Is not any of…
            </Text>
            <Icon as={IconChevronDown} boxSize={4} />
          </Flex>

          <Collapse in={advanced.isOpen}>
            <Box paddingTop={1.5}>
              <TagsInput
                colorScheme="gray"
                initialTags={localExcluded}
                onChange={(tags) => {
                  setLocalExcluded(tags)
                }}
                onRemove={(tag) => {
                  const tags = localExcluded.filter((t) => t !== tag)
                  setLocalExcluded(tags)
                }}
                placeholder="Exclude keywords…"
                inputProps={{
                  w: '100%'
                }}
              />
            </Box>
          </Collapse>
        </Flex>
      )}

      {total >= 15 && (
        <Box paddingX={4}>
          <Flex
            gap={2.5}
            alignItems="flex-start"
            paddingX={2.5}
            paddingY={2}
            bg="orange.50"
            color="orange.900"
            fontWeight="medium"
            rounded="md"
          >
            <CircleIcon
              flex="none"
              icon={IconAlertTriangle}
              iconSize={3.5}
              padding={1}
              bg="orange.200"
              color="orange.900"
            />
            <Text fontSize="xs">
              Only the first 30 keywords will be used. You may want to try the Role or Job Function filters instead.
            </Text>
          </Flex>
        </Box>
      )}

      <HStack flex="none" justifyContent="flex-end" spacing={3} paddingX={4} paddingTop={2}>
        {localIncluded.length > 0 && (
          <Button
            size="sm"
            variant="link"
            colorScheme="purple"
            onClick={() => {
              setLocalIncluded([])
              setLocalExcluded([])
            }}
          >
            Clear Filters
          </Button>
        )}

        <Button
          size="sm"
          colorScheme="purple"
          onClick={() => {
            const updoots = {
              [props.facet]: {
                values: localIncluded,
                operator: 'must' as const
              }
            }

            if (props.notFacet) {
              updoots[props.notFacet] = {
                values: localExcluded,
                operator: 'must' as const
              }
            }

            props.onChange(updoots)
          }}
        >
          Apply Filters {total > 0 ? `(${total})` : ''}
        </Button>
      </HStack>
    </Flex>
  )
}
