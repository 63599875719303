import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import { IconArrowRight } from '@tabler/icons-react'
import React, { useCallback, useState } from 'react'
import { toast } from 'sonner'
import { Template } from '../../../../types/Template'
import { useApplyTemplate } from '../../../data/use-templates'
import { User } from '../../../data/use-users'
import { PersonBubble } from '../../../ui/PersonBubble'
import { useCurrentUser } from '../../../ui/UserContext'
import { TemplateSelect } from './TemplateSelect'

interface ApplyTemplateModalProps extends UseDisclosureProps {
  user: Partial<User>
  templates?: Template[]
  onApply?: (templateId: string) => void
}

export function ApplyTemplateModal({ user, templates, onApply, ...props }: ApplyTemplateModalProps) {
  const disclosure = useDisclosure(props)
  const [templateId, setTemplateId] = useState<string | null>(null)
  const currentUser = useCurrentUser()

  const { isLoading, mutateAsync } = useApplyTemplate()
  const onClose = disclosure.onClose

  const handleApply = useCallback(async () => {
    if (!user.id) return
    if (!templateId) return

    try {
      await mutateAsync({ templateId, userId: user.id })
      toast.success(`Applied this template to ${user.name}'s Mission Control!`)
      onClose()
      onApply?.(templateId)
    } catch (error) {
      toast.error('There was an issue applying this template...', { description: (error as Error).message })
    }
  }, [templateId, user.id, user.name, mutateAsync, onClose, onApply])

  const isYou = currentUser?.id === user.id

  return (
    <Modal {...disclosure} size="lg" preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Apply a template</ModalHeader>
        <ModalBody>
          <Stack spacing={6}>
            {isYou ? (
              <Text fontSize="sm" color="gray.600">
                You can apply a template to copy lists from the selected template to your Mission Control. Any
                pre-existing lists in your Mission Control will not be changed/removed.
              </Text>
            ) : (
              <Text fontSize="sm" color="gray.600">
                You can apply a template to copy lists from the selected template to {user.name}'s Mission Control. This
                is purely additive: any existing lists in their Mission Control will not be touched.
              </Text>
            )}
            <FormControl>
              <FormLabel>Which template do you want to apply?</FormLabel>
              <Flex alignItems="center" gap={3}>
                <Box flex="1 1 auto">
                  <TemplateSelect
                    templates={templates}
                    selectedTemplateId={templateId}
                    onChange={setTemplateId}
                    triggerProps={{
                      py: 1.5,
                      px: 2,
                      border: '1px solid',
                      borderColor: 'gray.200'
                    }}
                    popoverProps={{
                      maxW: '100%'
                    }}
                    popperOptions={{
                      matchWidth: true
                    }}
                  />
                </Box>
                <Icon as={IconArrowRight} boxSize={4} color="gray.400" />
                <PersonBubble
                  name={user.name}
                  email={user.email}
                  src={user.avatar || user.image}
                  border="1px solid"
                  borderColor="gray.200"
                  shadow="sm"
                  height="38px"
                  paddingX={2}
                  maxW="40%"
                />
              </Flex>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" variant="outline" mr={3} isDisabled={isLoading} onClick={disclosure.onClose}>
            Cancel
          </Button>
          <Button size="sm" colorScheme="purple" isLoading={isLoading} isDisabled={!templateId} onClick={handleApply}>
            Apply template
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
