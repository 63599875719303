import {
  Badge,
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import { IconChevronLeft, IconChevronRight, IconHandStop, IconX } from '@tabler/icons-react'
import keyBy from 'lodash/keyBy'
import sortBy from 'lodash/sortBy'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { Apps } from '../../../../types/App'
import { useFieldMappings } from '../../../data/use-field-mappings'
import { useFieldValues } from '../../../data/use-field-values'
import { Iconify } from '../../../ui/Iconify'
import { SearchIcon } from '../../../ui/icons/SearchIcon'
import { InfoBox } from '../../../ui/InfoBox'
import { useOverflow } from '../../../ui/useOverflow'
import { Category, getItemDisplay, grouped, shortCategory } from '../../accounts/facets/categories'
import { ExtraField } from '../types'

function removeConsecutiveWords(value: string): string {
  const words = value.split(' ')
  const deduped: string[] = []

  for (let i = 0; i < words.length; i++) {
    if (words[i] !== words[i + 1] && words[i] !== 'Attributes') {
      deduped.push(words[i])
    }
  }

  return deduped.join(' ')
}

interface Props extends UseDisclosureProps {
  apps?: Apps
  extraFields: ExtraField[]
  onChange?: (fields: ExtraField[]) => void
}

export function SelectFieldsModal(props: Props) {
  const { onChange, extraFields, ...rest } = props
  const disclosure = useDisclosure(rest)
  const mappings = useFieldMappings('/profiles/facet-cloud')
  const [category, setCategory] = useState<string | null>(null)
  const [field, setField] = useState<string | null>(null)
  const [selectedFields, setSelectedFields] = useState<string[]>((extraFields ?? []).map((f) => f.facet))
  const [searchQuery, setSearchQuery] = useState('')
  const [debouncedSearch] = useDebounce(searchQuery, 200)
  const hitFieldLimit = useMemo(() => selectedFields.length >= 10, [selectedFields])

  const apps = useMemo(() => {
    return Object.values(rest.apps || {})
  }, [rest.apps])

  const resetMenu = useCallback(() => {
    setCategory(null)
    setField(null)
    setSearchQuery('')
  }, [])

  const toggleField = useCallback((field: string) => {
    setSelectedFields((fields) => {
      if (fields.includes(field)) {
        return fields.filter((f) => f !== field)
      }
      return [...fields, field]
    })
  }, [])

  // reset category when modal is opened
  useEffect(() => {
    if (disclosure.isOpen) {
      resetMenu()
    }
  }, [disclosure.isOpen, resetMenu])

  useEffect(() => {
    setSelectedFields((extraFields ?? []).map((f) => f.facet))
  }, [extraFields])

  const onClose = disclosure.onClose

  const mappingHash = useMemo(() => keyBy(mappings.data || [], 'facet'), [mappings.data])

  const onApply = useCallback(() => {
    const selections: ExtraField[] = selectedFields.map((field) => {
      const item = getItemDisplay(field, apps, undefined, mappingHash[field])
      const label = removeConsecutiveWords([shortCategory(item.category), item.label].filter(Boolean).join(' '))
      const prefix = ['Visitor', 'User', 'Contact', 'Lead', 'Prospect'].some((word) => item.category?.includes(word))
        ? 'visitor'
        : 'account'
      return {
        label,
        facet: item.key,
        kind: prefix,
        variable: `{{${prefix}.${item.key}}}`
      }
    })
    onChange?.(selections)
    onClose()
  }, [selectedFields, apps, mappingHash, onChange, onClose])

  const onClearFields = useCallback(() => {
    onChange?.([])
    onClose()
  }, [onChange, onClose])

  const categories = useMemo(() => {
    if (!mappingHash || !Object.keys(mappingHash).length) return []

    return grouped(mappingHash, apps, undefined, ['company']).filter((c) => {
      if (c.category === 'Engagement') return false
      if (c.category === 'User Attributes') return false
      if (c.category === 'Profile Traits') return false
      return true
    })
  }, [mappingHash, apps])

  const displayedCategories = useMemo(() => {
    let matching = categories

    if (debouncedSearch.trim()) {
      // sort selected category to top
      matching = sortBy(matching, (c) => c.category !== category)

      const query = debouncedSearch.trim().toLowerCase()

      // Filter the list of options by label / key
      const results = matching
        .map((c) => {
          const filtered = c.items.filter(
            (item) => item.label.toLowerCase().includes(query) || item.key.toLowerCase().includes(query)
          )
          return {
            ...c,
            items: filtered
          }
        })
        .filter((c) => c.items.length > 0)

      return results
    }

    if (category) {
      matching = matching.filter((c) => c.category === category)
    }

    // Ensure that all categories have at least one item, otherwise remove them
    return matching.filter((c) => c.items.length >= 1)
  }, [category, categories, debouncedSearch])

  const counts = useMemo(() => {
    if (!selectedFields.length) return {}

    return categories.reduce((acc, c) => {
      acc[c.category] = c.items.filter((i) => selectedFields.includes(i.key)).length
      return acc
    }, {})
  }, [categories, selectedFields])

  return (
    <Modal size="xl" {...disclosure}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody paddingX={0} paddingTop={8} paddingBottom={0}>
          <Stack spacing={5}>
            {mappings.isLoading ? (
              <Center minHeight="150px">
                <Spinner color="purple.500" thickness="1.5px" />
              </Center>
            ) : field ? (
              <FieldMenu
                field={field}
                details={getItemDisplay(field, apps, undefined, mappingHash[field])}
                goBack={() => setField(null)}
              />
            ) : category || debouncedSearch.trim() ? (
              <CategoryMenu
                counts={counts}
                category={category}
                categories={displayedCategories}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                selectedFields={selectedFields}
                toggleField={toggleField}
                selectField={setField}
                resetMenu={resetMenu}
                hitFieldLimit={hitFieldLimit}
              />
            ) : (
              <CategoriesMenu
                counts={counts}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                categories={displayedCategories}
                selectCategory={setCategory}
              />
            )}
          </Stack>
        </ModalBody>
        <ModalFooter
          flexDirection="column"
          justifyContent="stretch"
          gap={3}
          paddingX={6}
          paddingY={4}
          borderTop="1px solid"
          borderColor="gray.200"
        >
          {hitFieldLimit && (
            <Box width="100%" flex="none">
              <InfoBox colorScheme="orange" icon={<IconHandStop size={18} />}>
                You've hit the limit of 10 additional fields. To add other fields, please deselect another one (or clear
                all).
              </InfoBox>
            </Box>
          )}
          <Flex gap={3} width="100%" justifyContent="stretch">
            <Button flex="1 1 50%" size="md" variant="outline" onClick={onClearFields}>
              Clear all fields
            </Button>
            <Button flex="1 1 50%" size="md" colorScheme="purple" onClick={onApply}>
              Save fields
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

interface CategoriesMenuProps {
  counts: Record<string, number>
  searchQuery: string
  setSearchQuery: (query: string) => void
  categories: Category[]
  selectCategory: (category: string) => void
}

function CategoriesMenu({ counts, searchQuery, setSearchQuery, categories, selectCategory }: CategoriesMenuProps) {
  return (
    <>
      <Heading size="sm" paddingX={6}>
        Select the fields you want to include in your message
      </Heading>
      <Box paddingX={6}>
        <SearchInput
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onReset={() => setSearchQuery('')}
        />
      </Box>
      <Stack maxHeight="calc(80vh - 160px)" overflow="auto" spacing={2} paddingX={6} paddingTop={3} paddingBottom={6}>
        {categories.map((c) => (
          <HStack
            key={c.category}
            spacing={2}
            padding={3}
            rounded="lg"
            border="1px solid"
            borderColor="gray.200"
            bg="white"
            cursor="pointer"
            _hover={{
              borderColor: 'gray.300',
              shadow: 'sm'
            }}
            onClick={() => selectCategory(c.category)}
          >
            {c.icon && <Iconify icon={c.icon} size={24} />}
            <Text flex="1" fontSize="sm" fontWeight="medium">
              {c.category}
            </Text>
            {counts[c.category] > 0 && (
              <Badge variant="pill" flex="none">
                {counts[c.category]}
              </Badge>
            )}
            <IconButton
              aria-label="Select category"
              size="xs"
              variant="ghost"
              colorScheme="purple"
              icon={<IconChevronRight size={18} />}
            />
          </HStack>
        ))}
      </Stack>
    </>
  )
}

interface CategoryMenuProps {
  counts: Record<string, number>
  searchQuery: string
  setSearchQuery: (query: string) => void
  category: string | null
  categories: Category[]
  selectedFields: string[]
  toggleField: (field: string) => void
  selectField: (field: string) => void
  resetMenu: () => void
  hitFieldLimit?: boolean
}

function CategoryMenu({
  counts,
  category,
  searchQuery,
  setSearchQuery,
  categories,
  selectedFields,
  toggleField,
  // selectField,
  resetMenu,
  hitFieldLimit
}: CategoryMenuProps) {
  const { scrollRef, overflowTop, overflowBottom } = useOverflow([category, searchQuery])

  return (
    <>
      <HStack cursor="pointer" onClick={resetMenu} paddingX={6}>
        <IconChevronLeft size={20} />
        <Heading size="sm">{category || 'Search Results'}</Heading>
        {category && counts[category] > 0 && (
          <Badge variant="pill" flex="none">
            {counts[category]}
          </Badge>
        )}
      </HStack>
      <Box paddingX={6}>
        <SearchInput
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onReset={() => setSearchQuery('')}
        />
      </Box>
      <Box position="relative">
        <Box
          ref={scrollRef}
          maxHeight={hitFieldLimit ? 'calc(80vh - 234px)' : 'calc(80vh - 160px)'}
          overflow="auto"
          paddingX={6}
          paddingTop={3}
          paddingBottom={8}
        >
          <Box
            position="absolute"
            left={0}
            right={0}
            top={0}
            borderTop="1px solid"
            borderColor="gray.200"
            height="80px"
            zIndex={10}
            bgGradient="linear(to-t, rgba(255,255,255,0.1), rgba(255,255,255,0.75))"
            opacity={overflowTop ? 1 : 0}
            transition="opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
            pointerEvents="none"
          />

          <Box
            position="absolute"
            left={0}
            right={0}
            bottom={0}
            height="80px"
            zIndex={10}
            bgGradient="linear(to-b, rgba(255,255,255,0.1), rgba(255,255,255,0.75))"
            opacity={overflowBottom ? 1 : 0}
            transition="opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
            pointerEvents="none"
          />
          <Stack spacing={8}>
            {categories.map((c) => (
              <Stack key={c.category} spacing={1.5}>
                {searchQuery.trim() && <CategoryHeader icon={c.icon}>{c.category}</CategoryHeader>}
                <Stack>
                  {c.items.map((item) => (
                    <HStack
                      key={item.key}
                      spacing={3}
                      rounded="lg"
                      border="1px solid"
                      borderColor="gray.200"
                      bg="white"
                      cursor="pointer"
                      _hover={{
                        borderColor: 'gray.300',
                        shadow: 'sm'
                      }}
                      role="group"
                    >
                      <Checkbox
                        flex="1"
                        size="lg"
                        padding={3}
                        value={item.key}
                        isChecked={selectedFields.includes(item.key)}
                        // disable if they've hit the limit of fields and this one is not already checked
                        isDisabled={hitFieldLimit && !selectedFields.includes(item.key)}
                        onChange={() => toggleField(item.key)}
                      >
                        <Text fontSize="sm" fontWeight="medium">
                          {item.label}
                        </Text>
                      </Checkbox>
                      {/* <Button
                        visibility="hidden"
                        _groupHover={{
                          visibility: 'visible'
                        }}
                        size="xs"
                        variant="ghost"
                        colorScheme="purple"
                        iconSpacing={1}
                        rightIcon={<IconChevronRight size={16} />}
                        onClick={() => selectField(item.key)}
                      >
                        Pick specific values
                      </Button> */}
                    </HStack>
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Box>
    </>
  )
}

interface FieldMenuProps {
  field: string
  details: ReturnType<typeof getItemDisplay>
  goBack: () => void
}

function FieldMenu({ field, details, goBack }: FieldMenuProps) {
  const [searchQuery, setSearchQuery] = useState('')
  const [debouncedSearch] = useDebounce(searchQuery, 300)
  const fieldValues = useFieldValues(field, '/profiles/facet-values', debouncedSearch)

  const values = useMemo(() => {
    if (!fieldValues.data) {
      return []
    }

    const query = debouncedSearch.trim().toLowerCase()
    if (!query) {
      return fieldValues.data.values
    } else {
      return fieldValues.data.values.filter((v) => v.key.toLowerCase().includes(query))
    }
  }, [fieldValues.data, debouncedSearch])

  return (
    <>
      <HStack cursor="pointer" onClick={goBack} paddingX={6}>
        <IconChevronLeft size={20} />
        <Heading size="sm">{details?.label}</Heading>
      </HStack>
      <Box paddingX={6}>
        <SearchInput
          placeholder="Search for values"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onReset={() => setSearchQuery('')}
        />
      </Box>
      <Stack spacing={3} maxHeight="800px" overflow="auto" paddingX={6}>
        {fieldValues.isLoading && (
          <Center minHeight="150px">
            <Spinner color="purple.500" thickness="1.5px" />
          </Center>
        )}
        {values.map((item) => (
          <Checkbox key={item.key} size="md" spacing={2} cursor="pointer">
            <Text fontSize="14px" fontWeight="medium">
              {item.label || item.key}
            </Text>
          </Checkbox>
        ))}
      </Stack>
    </>
  )
}

interface SearchInputProps {
  value: string
  placeholder?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onReset: () => void
}

function SearchInput(props: SearchInputProps) {
  return (
    <InputGroup flex="none" variant="filled" size="md">
      <InputLeftElement width="10">
        <SearchIcon color="gray.400" boxSize={4} />
      </InputLeftElement>

      <Input
        value={props.value}
        placeholder={props.placeholder || 'Search for fields'}
        onChange={props.onChange}
        autoComplete="off"
        autoFocus
        name="search"
        fontSize="sm"
        rounded="lg"
      />

      {props.value && (
        <InputRightElement>
          <IconButton
            size="xs"
            aria-label="Clear search"
            variant="ghost"
            onClick={props.onReset}
            icon={<IconX size={16} />}
          />
        </InputRightElement>
      )}
    </InputGroup>
  )
}

function CategoryHeader(props: React.PropsWithChildren<{ icon?: Category['icon'] }>) {
  return (
    <HStack spacing={2} paddingX={3.5}>
      {props.icon && <Iconify size={16} icon={props.icon} />}
      <Text
        fontSize="12px"
        fontWeight="medium"
        color="gray.500"
        letterSpacing="0.04em"
        textTransform="uppercase"
        {...props}
      />
    </HStack>
  )
}
