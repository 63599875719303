import { AdminBreadcrumb } from '..'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Switch,
  Text,
  Textarea
} from '@chakra-ui/react'
import { Project } from '@app/types/Project'
import { toast } from 'sonner'
import { post } from '../../../../lib/api'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { StepIcon } from '../../../ui/StepIcon'
import React, { FormEvent, useCallback, useState, useRef } from 'react'
import CollapsibleBucketTable from './buckets_table'
import MiniScoreDistribution from './score_distribution'
import BucketSummary from './buckets_summary'
import ProjectSelector from './project_selector'
import { Bucket } from './types'

interface Props {
  projects: Project[]
  buckets_settings: Record<string, { name: string; threshold: number }>
  sample_size: number
}

export default function Show(props: Props) {
  const [buckets, setBuckets] = useState<Bucket[]>([])
  const [disqualified, setDisqualified] = useState<[]>([])
  const [totalAccounts, setTotalAccounts] = useState<number>(0)
  const [totalMatching, setTotalMatching] = useState<number>(0)
  const [totalPossiblePoints, setTotalPossiblePoints] = useState<number>(0)
  const [pointsHistogram, setPointsHistogram] = useState<Array<{ key: number; doc_count: number }>>([])
  const [selectedProject, setSelectedProject] = useState<Project | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const controllerRef = useRef<AbortController>()

  const initialBucketsSettings = {
    B1: { name: 'Not a fit', threshold: 50 },
    B2: { name: 'Fair', threshold: 70 },
    B3: { name: 'Good', threshold: 80 },
    B4: { name: 'Fantastic', threshold: 90 }
  }

  const [bucketsSettings, setBucketsSettings] = useState<Record<string, { name: string; threshold: number }>>(
    props.buckets_settings || initialBucketsSettings
  )

  const handleBucketNameChange = (key: string, newName: string) => {
    setBucketsSettings((prev) => ({
      ...prev,
      [key]: { ...prev[key], name: newName }
    }))
  }

  const handleBucketThresholdChange = (key: string, newThreshold: number) => {
    setBucketsSettings((prev) => ({
      ...prev,
      [key]: { ...prev[key], threshold: newThreshold }
    }))
  }

  const [sampleSize, setSampleSize] = useState<number>(props.sample_size || 15)

  const [rawMode, setRawMode] = useState(false)
  const [criteria, setCriteria] = useState('')
  const [disqualify, setDisqualify] = useState('')

  const startPreview = useCallback(
    (e: FormEvent) => {
      if (!selectedProject) {
        toast.error('Please select an project')
        return
      }

      controllerRef.current?.abort()
      controllerRef.current = new AbortController()

      setIsLoading(true)
      post(`/admin/icp-playground/preview/${selectedProject.id}`, {
        criteria: criteria,
        disqualify: disqualify,
        buckets_settings: bucketsSettings,
        sample_size: sampleSize
      })
        .then((data: any) => {
          console.log(data)
          setPointsHistogram(data.points_histogram.buckets)
          const buckets = data.score_ranges.buckets
            .sort((a, b) => {
              return (b.from || 0) - (a.from || 0)
            })
            .map((bucket: any) => ({
              key: bucket.key,
              doc_count: bucket.doc_count,
              from: bucket?.from,
              to: bucket?.to,
              max_score: bucket.sample_accounts.hits.max_score,
              sample_accounts: bucket.sample_accounts.hits.hits.map((hit: any) => ({
                id: hit._id,
                company: hit._source.company,
                score: hit._score
              }))
            }))
          setBuckets(buckets)
          setDisqualified(data.disqualified)
          setTotalAccounts(data.total_accounts)
          setTotalMatching(data.total_matching)
          setTotalPossiblePoints(data.total_possible_points)
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [selectedProject, criteria, disqualify, bucketsSettings, sampleSize]
  )

  return (
    <PageLayout size="full">
      <HStack>
        <PageTitle flex="1">ICP Playground</PageTitle>
      </HStack>

      <AdminBreadcrumb paths={[{ path: '/admin/icp-playground', title: 'ICP Playground' }]} />
      <Stack>
        <HStack w="full">
          {' '}
          <ProjectSelector
            projects={props.projects}
            selectedProject={selectedProject}
            onChange={(project) => {
              setSelectedProject(project)
            }}
          />
          <FormControl flex="none" w="auto" size="sm" display="flex" alignItems="center" gap={2}>
            <FormLabel htmlFor="admin-mode" m="0">
              Raw Mode
            </FormLabel>
            <Switch id="raw-mode" onChange={(e) => setRawMode(e.target.checked)} />
          </FormControl>
        </HStack>
        <Stack onSubmit={startPreview}>
          <Box>
            <Heading size="sm" mt={10} mb={4}>
              Score criteria
            </Heading>
            <Stack>
              <Heading size="sm" lineHeight={1}>
                Build your Ideal Customer Profile (ICP)
              </Heading>
              {rawMode ? (
                <Stack>
                  <Textarea h="500px" name="criteira" value={criteria} onChange={(e) => setCriteria(e.target.value)} />
                </Stack>
              ) : (
                <Stack>
                  <HStack alignItems="flex-start">
                    <StepIcon step={1} />
                    <Stack spacing={4} maxWidth="600px" minWidth="250px">
                      <Stack spacing={1}>
                        <Heading size="sm" lineHeight={1}>
                          Start with their most important traits
                        </Heading>
                        <Text fontSize="sm" color="gray.600">
                          This will count 3 points per traits when calculating the score
                        </Text>
                      </Stack>
                    </Stack>
                  </HStack>
                  <HStack alignItems="flex-start">
                    <StepIcon step={2} />
                    <Stack spacing={4} maxWidth="600px" minWidth="250px">
                      <Stack spacing={1}>
                        <Heading size="sm" lineHeight={1}>
                          Continue with traits that differentiate them
                        </Heading>
                        <Text fontSize="sm" color="gray.600">
                          This will count 2 points per traits when calculating the score
                        </Text>
                      </Stack>
                    </Stack>
                  </HStack>
                  <HStack alignItems="flex-start">
                    <StepIcon step={3} />
                    <Stack spacing={4} maxWidth="600px" minWidth="250px">
                      <Stack spacing={1}>
                        <Heading size="sm" lineHeight={1}>
                          And finish with traits that would be good to have
                        </Heading>
                        <Text fontSize="sm" color="gray.600">
                          This will count 1 point per traits when calculating the score
                        </Text>
                      </Stack>
                    </Stack>
                  </HStack>
                </Stack>
              )}
            </Stack>
          </Box>
          <Box>
            <Heading size="sm" mt={10} mb={4}>
              Disqualify criteria
            </Heading>
            <Stack>
              <Heading size="sm" lineHeight={1}>
                Define what is outside your ICP
              </Heading>
              <HStack alignItems="flex-start">
                <StepIcon step={4} />
                <Stack spacing={4} maxWidth="600px" minWidth="250px">
                  <Stack spacing={1}>
                    <Heading size="sm" lineHeight={1}>
                      Make sure that accounts matching these remaing outside your ICP
                    </Heading>
                    <Text fontSize="sm" color="gray.600">
                      This will remove the account from the scoring
                    </Text>
                  </Stack>
                </Stack>
              </HStack>
              {rawMode && (
                <Stack>
                  <Textarea
                    h="200px"
                    name="disqualify"
                    value={disqualify}
                    onChange={(e) => setDisqualify(e.target.value)}
                  />
                </Stack>
              )}
            </Stack>
          </Box>
          <Box>
            <Heading size="sm" mt={10} mb={4}>
              Settings
            </Heading>
            <Stack spacing={4} mt={4}>
              <Heading size="sm">Bucket Definitions</Heading>
              {Object.keys(bucketsSettings).map((key, index) => (
                <HStack key={key} spacing={2} alignItems="center">
                  <FormControl>
                    <FormLabel>Bucket Name ({key})</FormLabel>
                    <Input
                      value={bucketsSettings[key].name}
                      onChange={(e) => handleBucketNameChange(key, e.target.value)}
                    />
                  </FormControl>
                  {index < Object.keys(bucketsSettings).length - 1 && (
                    <FormControl>
                      <FormLabel>%</FormLabel>
                      <Input
                        type="number"
                        value={bucketsSettings[key].threshold}
                        onChange={(e) => handleBucketThresholdChange(key, parseInt(e.target.value, 10))}
                      />
                    </FormControl>
                  )}
                </HStack>
              ))}
            </Stack>
            <Stack spacing={4} mt={4}>
              <Heading size="sm">Sample Size</Heading>
              <FormControl>
                <FormLabel>Choose Sample Size</FormLabel>
                <Slider
                  aria-label="sample-size-slider"
                  min={10}
                  max={100}
                  value={sampleSize}
                  onChange={(value) => setSampleSize(value)}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb boxSize={6}>
                    <Text fontSize="xs">{sampleSize}</Text>
                  </SliderThumb>
                </Slider>
              </FormControl>
            </Stack>
          </Box>
          <Button
            px="4"
            colorScheme={'purple'}
            type="submit"
            onClick={startPreview}
            isLoading={isLoading}
            disabled={!selectedProject || isLoading}
          >
            Preview
          </Button>
        </Stack>
      </Stack>
      <Box>
        <Heading size="sm" mt={10} mb={4}>
          Preview Results
        </Heading>
        <Stack>
          <Text fontSize="sm" fontWeight="medium">
            {totalMatching} out of {totalAccounts} scored ({((totalMatching / totalAccounts) * 100).toFixed(2)}%)
          </Text>
          <Text fontSize="sm" fontWeight="medium">
            {totalAccounts - totalMatching} out of {totalAccounts} disqualified (
            {(((totalAccounts - totalMatching) / totalAccounts) * 100).toFixed(2)}%)
          </Text>
          <Text fontSize="sm" fontWeight="medium">
            Total possible points: {totalPossiblePoints}
          </Text>
          <BucketSummary
            buckets={buckets}
            totalAccounts={totalAccounts}
            totalMatching={totalMatching}
            disqualified={disqualified}
          />
          <MiniScoreDistribution
            buckets={buckets}
            pointsHistogram={pointsHistogram}
            totalPossiblePoints={totalPossiblePoints}
          />
          <CollapsibleBucketTable buckets={buckets} disqualified={disqualified} />
        </Stack>
      </Box>
    </PageLayout>
  )
}
