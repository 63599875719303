import { Box, Button, Flex, HStack, Icon, Image, Square, StackProps, Text, useDisclosure } from '@chakra-ui/react'
import { IconBox, IconChevronDown, IconChevronUp, IconStack2 } from '@tabler/icons-react'
import orderBy from 'lodash/orderBy'
import React from 'react'
import { CompanyTechnology } from '../../../../../types/Account'
import { DetailsCard } from '../../../../ui/Card'
import { CardHeading } from '../../../../ui/CardHeading'
import { VirtualList } from '../../../../ui/VirtualList'
import { SearchBar } from '../../facets/search-bar'

export function TechStackCard(props: {
  account: {
    company_technologies: CompanyTechnology[]
  }
}) {
  const [query, setQuery] = React.useState('')
  const showAll = useDisclosure()
  const tech = React.useMemo(() => props.account.company_technologies ?? [], [props.account])

  const filtered = React.useMemo(() => {
    if (!query) {
      return orderBy(tech, (t) => t.tech, 'asc')
    }

    return orderBy(
      tech.filter((t) => {
        return (
          t.category.toLowerCase().includes(query.toLowerCase()) || t.tech.toLowerCase().includes(query.toLowerCase())
        )
      }),
      (t) => t.tech,
      'asc'
    )
  }, [tech, query])

  if (tech.length === 0) {
    return null
  }

  const firstSet = tech.slice(0, 20)

  return (
    <DetailsCard w="100%">
      <CardHeading icon={IconStack2} count={tech.length}>
        Tech they are using
      </CardHeading>

      {showAll.isOpen ? (
        <>
          <Flex py="1">
            <SearchBar value={query} size="sm" onChange={setQuery} />
          </Flex>
          <VirtualList
            items={filtered}
            maxH="400px"
            estimateSize={() => 42}
            renderItem={(item, index) => {
              return (
                <TechStackItem
                  key={item.tech}
                  item={item}
                  w="100%"
                  px={0}
                  py={2.5}
                  rounded="none"
                  borderWidth="none"
                  borderBottomWidth={filtered.length - 1 > index ? '1px' : undefined}
                />
              )
            }}
          />
        </>
      ) : (
        <Flex gap={3} flexWrap="wrap">
          {firstSet.map((item) => (
            <TechStackItem key={item.tech} item={item} />
          ))}
        </Flex>
      )}

      {tech.length > 20 && (
        <Box paddingTop={2}>
          <Button
            alignSelf="flex-start"
            variant="link"
            color="gray.700"
            size="sm"
            rightIcon={showAll.isOpen ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}
            onClick={showAll.onToggle}
          >
            {showAll.isOpen ? 'Show less' : 'See all tech'}
          </Button>
        </Box>
      )}
    </DetailsCard>
  )
}

interface TechStackItemProps {
  item: CompanyTechnology
}

function TechStackItem({ item, ...rest }: TechStackItemProps & StackProps) {
  return (
    <HStack fontSize="sm" px={2} py={1.5} borderWidth="1px" rounded="md" {...rest}>
      <Image
        w="5"
        rounded="sm"
        src={item.raw.img}
        fallback={
          <Square size="20px" color="gray.400">
            <Icon as={IconBox} boxSize={5} strokeWidth={1.5} />
          </Square>
        }
      />
      <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {item.tech}
      </Text>
    </HStack>
  )
}
