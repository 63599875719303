import { AuthenticityToken } from '@app/components/ui/AuthenticityToken'
import { usePermission } from '@app/components/ui/PermissionsContext'
import { getCurrentProject } from '@app/components/ui/ProjectsContext'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import React from 'react'

interface DisableDialogProps {
  appTitle: string
}

export function ReconnectAppDialog({ appTitle }: DisableDialogProps) {
  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const appId = appTitle.toLowerCase()
  const cancelRef = React.useRef<HTMLButtonElement>(null)
  const currentProjectSlug = getCurrentProject()?.slug

  return (
    <>
      <Button flex="none" variant="outline" size="sm" isDisabled={!canEditProject} onClick={onOpen}>
        Reconnect…
      </Button>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Reconnect {appTitle}
            </AlertDialogHeader>

            <AlertDialogBody paddingY={6}>
              <Text>Are you sure you want to reconnect {appTitle}?</Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Box as="form" method="POST" action={`/auth/${appId}?project=${currentProjectSlug}&app_id=${appId}`}>
                <AuthenticityToken />
                <Button size="sm" ref={cancelRef} variant="outline" onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit" size="sm" colorScheme="red" ml={3} isDisabled={!canEditProject}>
                  Reconnect
                </Button>
              </Box>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
