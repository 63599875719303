import { CheckboxCircleIcon } from '@app/components/ui/icons'
import {
  Badge,
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { IconExternalLink, IconLink, IconSearch, IconStatusChange, IconUnlink } from '@tabler/icons-react'
import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'sonner'
import { useDebounce } from 'use-debounce'
import { concurrentGET, del, put } from '../../../../lib/api'
import type { CRMMatch } from '../../../../types/Account'
import { TimeAgo } from '../../../ui/TimeAgo'
import { accountPath } from '../lib/account-path'

export function CRMMatchHeaderIcons(entity) {
  return (
    <>
      {entity?.match_mode === 'manual' && (
        <Tooltip label="Manual Match" placement="top" hasArrow arrowSize={6}>
          <Icon as={IconLink} size={16} color="purple.500" _hover={{ color: 'purple.600' }} />
        </Tooltip>
      )}
    </>
  )
}

export function CRMMatchMenuActions({ entity, crmMatches, account, app, docs_link }) {
  const changeMatchDisclosure = useDisclosure()
  const removeMatchDisclosure = useDisclosure()

  return (
    <>
      {entity?.match_mode === 'manual' ? (
        <>
          <Tooltip label="Change matching CRM record">
            <Button
              onClick={changeMatchDisclosure.onOpen}
              variant="outline"
              size="sm"
              width="full"
              leftIcon={<IconStatusChange size={16} />}
              justifyContent="center"
            >
              Rematch
            </Button>
          </Tooltip>
          <CRMChangeMatch
            {...changeMatchDisclosure}
            crmMatches={crmMatches}
            account={account}
            app={app}
            docs_link={docs_link}
          />

          <Tooltip label="Revert matching to automatic">
            <Button
              onClick={removeMatchDisclosure.onOpen}
              variant="outline"
              size="sm"
              width="full"
              leftIcon={<IconUnlink size={16} />}
              justifyContent="center"
            >
              Unmatch
            </Button>
          </Tooltip>
          <CRMRemoveMatch {...removeMatchDisclosure} crmMatches={crmMatches} account={account} app={app} />
        </>
      ) : (
        <>
          <Tooltip label="Find CRM record to match">
            <Button
              onClick={changeMatchDisclosure.onOpen}
              variant="outline"
              size="sm"
              width="full"
              leftIcon={<IconSearch size={16} />}
              justifyContent="center"
            >
              Find Match
            </Button>
          </Tooltip>
          <CRMChangeMatch
            {...changeMatchDisclosure}
            crmMatches={crmMatches}
            account={account}
            app={app}
            docs_link={docs_link}
          />
        </>
      )}
    </>
  )
}

export function CRMChangeMatch({ isOpen, onClose, crmMatches, account, app, docs_link }) {
  const [selectedMatch, setSelectedMatch] = useState(crmMatches?.mainCrmMatch?.crm_entity_id)

  // Identify the manual match
  const manualMatch = useMemo(() => {
    const entity = crmMatches?.mainCrmMatch?.crm_entity

    if (entity?.match_mode === 'manual') {
      return entity
    } else {
      return null
    }
  }, [crmMatches.mainCrmMatch])

  const suggestions = useMemo(() => {
    if (manualMatch) {
      return crmMatches.allAccounts.filter((entity) => entity.crm_entity_id !== manualMatch.crm_entity_id)
    }
    return crmMatches.allAccounts
  }, [manualMatch, crmMatches.allAccounts])

  const handleSelect = () => {
    if (selectedMatch) {
      put(accountPath(account, '/crm_match'), {
        crm_entity_id: selectedMatch,
        app_module: app?.app_module
      })
        .then(() => {
          crmMatches.refetch({ shouldReset: false })
          toast.success('Match changed!')
        })
        .catch(() => {
          setSelectedMatch(crmMatches.mainCrmMatch.crm_entity_id)
          toast.error('There was an error changing the match')
        })
        .finally(() => {
          onClose()
        })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{manualMatch ? 'Change' : 'Find'} match</ModalHeader>
        <ModalBody>
          <Stack spacing={6}>
            <Stack>
              <Text fontSize="sm" color="gray.500">
                By default the account is matched based in company domain and the numbers of deals or opportunities,
                number of contacts and last modified date.
              </Text>
              <Link
                alignSelf="flex-start"
                flex="none"
                href={docs_link}
                variant="dotted"
                isExternal
                fontSize="sm"
                color="purple.500"
              >
                Learn more
                <IconExternalLink size={14} />
              </Link>
              {manualMatch && (
                <Stack mt={4} spacing={3}>
                  <CompanyCard entity={manualMatch} isSelected={true} onClick={() => {}} />
                </Stack>
              )}
            </Stack>

            <Tabs size="sm" isFitted>
              <TabList>
                <Tab>
                  <HStack>
                    <Text>Suggestions</Text>
                    <Badge colorScheme="purple" borderRadius="full" px={2} transform="translateY(-1px)">
                      {suggestions.length}
                    </Badge>
                  </HStack>
                </Tab>
                <Tab>
                  <HStack>
                    <Text>Search</Text>
                    <Icon as={IconSearch} size={16} color="gray.500" />
                  </HStack>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel px={0}>
                  <Suggestions
                    suggestions={suggestions}
                    selectedMatch={selectedMatch}
                    setSelectedMatch={setSelectedMatch}
                  />
                </TabPanel>
                <TabPanel px={0}>
                  <LiveSearch
                    selectedMatch={selectedMatch}
                    setSelectedMatch={setSelectedMatch}
                    account={account}
                    app={app}
                    manualMatch={manualMatch}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Stack>
        </ModalBody>
        <ModalFooter position="sticky" bottom="0" bg="white" zIndex="1" borderBottomRadius="md">
          <Button size="sm" variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button size="sm" colorScheme="purple" onClick={handleSelect} isDisabled={!selectedMatch}>
            Select
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const LiveSearch = ({ selectedMatch, setSelectedMatch, account, app, manualMatch }) => {
  const [filterText, setFilterText] = useState('')
  const [debouncedFilter] = useDebounce(filterText, 500)
  const [filteredMatches, setFilteredMatches] = useState<CRMMatch[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (!debouncedFilter) {
      setFilteredMatches([])
      setSelectedMatch(null)
      return
    }

    setLoading(true)
    setError(null)
    concurrentGET<CRMMatch[]>(
      accountPath(account, `/crm_live_search/${app.app_module}?query=${encodeURIComponent(debouncedFilter)}`)
    )
      .then((data: CRMMatch[]) => {
        const filtered = data.filter((entity) => !manualMatch || entity.crm_entity_id !== manualMatch.crm_entity_id)
        setFilteredMatches(filtered)
      })
      .catch(() => {
        setError('Failed to fetch results.')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [debouncedFilter, setSelectedMatch, account, app, manualMatch])

  const handleClearFilter = () => {
    setFilterText('')
    setFilteredMatches([])
    setSelectedMatch(null)
  }

  return (
    <Stack>
      <InputGroup mt={4} mb={0}>
        <Input
          size="sm"
          placeholder="Filter by name, domain or id"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
      </InputGroup>
      {filterText && (
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button size="xs" colorScheme="gray" variant="outline" onClick={handleClearFilter}>
            Clear
          </Button>
        </Box>
      )}
      {loading && (
        <Box display="flex" justifyContent="center" my={4}>
          <Spinner />
        </Box>
      )}
      {error && (
        <Box display="flex" justifyContent="center" my={4}>
          <Text color="red.500">{error}</Text>
        </Box>
      )}
      <RadioGroup onChange={setSelectedMatch} value={selectedMatch}>
        <Stack spacing={3}>
          {filteredMatches.map((entity) => (
            <CompanyCard
              key={entity.crm_entity_id}
              entity={entity.crm_entity}
              isSelected={selectedMatch === entity.crm_entity_id}
              onClick={() => setSelectedMatch(entity.crm_entity_id)}
            />
          ))}
          {!loading && !error && filteredMatches.length === 0 && filterText && <Text>No results found.</Text>}
        </Stack>
      </RadioGroup>
    </Stack>
  )
}

const Suggestions = ({ suggestions, selectedMatch, setSelectedMatch }) => {
  const [filterText, setFilterText] = useState('')
  const [filteredMatches, setFilteredMatches] = useState(suggestions)

  useEffect(() => {
    const lowerCaseFilter = filterText.toLowerCase()
    const filtered = suggestions.filter((entity) => {
      const { crm_entity_id, name, website, account_owner, stage_name } = entity.crm_entity
      return (
        crm_entity_id.toString().toLowerCase().includes(lowerCaseFilter) ||
        name?.toLowerCase().includes(lowerCaseFilter) ||
        website?.toLowerCase().includes(lowerCaseFilter) ||
        stage_name?.join(', ')?.toLowerCase().includes(lowerCaseFilter) ||
        account_owner?.toLowerCase().includes(lowerCaseFilter)
      )
    })
    setFilteredMatches(filtered)
  }, [filterText, suggestions])

  const handleClearFilter = () => {
    setFilterText('')
  }

  return (
    <Stack>
      <InputGroup mt={4} mb={0}>
        <Input
          size="sm"
          placeholder="Filter by id, name, domain, stage, or owner"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
      </InputGroup>
      {filterText && (
        <Box display="flex" justifyContent="flex-end">
          <Button size="xs" colorScheme="gray" variant="outline" onClick={handleClearFilter}>
            Clear
          </Button>
        </Box>
      )}
      <RadioGroup onChange={setSelectedMatch} value={selectedMatch}>
        <Stack spacing={3}>
          {filteredMatches.map((entity) => (
            <CompanyCard
              key={entity.crm_entity_id}
              entity={entity.crm_entity}
              isSelected={selectedMatch === entity.crm_entity_id}
              onClick={() => setSelectedMatch(entity.crm_entity_id)}
            />
          ))}
        </Stack>
      </RadioGroup>
    </Stack>
  )
}

// Individual Company Card
const CompanyCard = ({ entity, isSelected, onClick }) => {
  return (
    <Box
      borderWidth="1.5px"
      borderRadius="lg"
      cursor="pointer"
      transition="all 150ms cubic-bezier(0, 0, 0.2, 1)"
      padding={3}
      _hover={
        isSelected
          ? undefined
          : {
              borderColor: 'gray.300',
              shadow: 'sm'
            }
      }
      _checked={{
        borderColor: 'purple.500',
        colorScheme: 'purple'
      }}
      _disabled={{ opacity: 0.6, cursor: 'not-allowed' }}
      _focusVisible={{
        boxShadow: 'outline',
        outline: '2px solid transparent',
        outlineOffset: '2px'
      }}
      boxShadow="sm"
      bg="white"
      borderColor={isSelected ? 'purple.500' : 'gray.200'}
      onClick={onClick}
    >
      <HStack align="start" spacing={3} justify="space-between">
        {/* Radio Button for selection */}
        <Radio value={entity.crm_entity_id.toString()} display="none" />

        {/* Company Name and Account Type */}
        <VStack align="start" spacing={0} width="50%">
          <Text fontSize="sm" noOfLines={1}>
            {entity.name}
          </Text>
          <Text fontSize="sm" noOfLines={1} color="gray.400">
            {entity.website || 'No website available'}
          </Text>
          <Text fontSize="sm" noOfLines={1} color="gray.400">
            {entity.crm_entity_id}
          </Text>
          <Text fontSize="xs" color="gray.400" isTruncated>
            <TimeAgo time={entity.external_last_modified_at} mode="relative" />
          </Text>
        </VStack>

        {/* Stages and Last Modified */}
        <VStack align="start" spacing={0} width="50%">
          <Text fontSize="sm" noOfLines={1}>
            Owner: {entity.account_owner || 'N/A'}
          </Text>
          <Text fontSize="sm" noOfLines={1} color="gray.400">
            Stages: {(entity.stage_name && entity.stage_name.join(', ')) || 'N/A'}
          </Text>
          <Text fontSize="sm" color="gray.400">
            Account Type: {entity.account_type || 'N/A'}
          </Text>
        </VStack>

        {/* Website and Owner */}
        <Box display="flex" flex="none" visibility={isSelected ? 'visible' : 'hidden'} color="purple.500">
          {entity?.match_mode === 'manual' ? <Icon as={IconLink} size={20} /> : <CheckboxCircleIcon size={20} />}
        </Box>
      </HStack>
    </Box>
  )
}

export function CRMRemoveMatch({ isOpen, onClose, crmMatches, account, app }) {
  const handleRemove = () => {
    del(accountPath(account, `/crm_match/${app?.app_module}`))
      .then((_data: any) => {
        // better would be to just set the match from the response
        // setMatch(data.crm_entity_id)
        toast.success('Match removed!')
      })
      .catch((_data: any) => {
        toast.error('There was an error removing the match')
      })
      .finally(() => {
        // not best, but good for now
        crmMatches.refetch({ shouldReset: false })
        onClose()
      })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          <Stack bg="white" py="4" maxWidth="500px" marginX="auto">
            <Heading size="md">Remove chosen match</Heading>
            <Text fontSize="sm" color="gray.500">
              This will remove manual matches for this account.
            </Text>
          </Stack>
        </ModalHeader>
        <ModalFooter position="sticky" bottom="0" bg="white" zIndex="1" borderBottomRadius="md">
          <Button size="sm" variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button size="sm" colorScheme="red" onClick={handleRemove}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
