import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { IconTrash, IconWorld } from '@tabler/icons-react'
import React from 'react'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { DeleteConfirmation } from '../../../ui/DeleteConfirmation'
import { usePermission } from '../../../ui/PermissionsContext'
import { projectPath } from '../../../ui/ProjectsContext'

interface AuthorizedDomainsProps {
  domains?: string[]
}

export function AuthorizedDomains(props: AuthorizedDomainsProps) {
  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })
  const [selectedDomain, setSelectedDomain] = React.useState<string | null>(null)
  const { isOpen: addIsOpen, onOpen: onAdd, onClose: onAddClose } = useDisclosure()
  const { isOpen: deleteIsOpen, onOpen: onDelete, onClose: onDeleteClose } = useDisclosure()

  React.useEffect(() => {
    if (!deleteIsOpen) {
      setSelectedDomain(null)
    }
  }, [deleteIsOpen])

  return (
    <Flex direction="column" gap={6}>
      <AddDomainModal isOpen={addIsOpen} onClose={onAddClose} />
      <RemoveDomainModal domain={selectedDomain} isOpen={deleteIsOpen} onClose={onDeleteClose} />

      <Flex gap={4} justifyContent="space-between">
        <Flex direction="column" gap={1}>
          <Heading size="sm">Authorized domains</Heading>
          <Text fontSize="sm" color="gray.600">
            Allow anyone with these email domains to join this workspace.
          </Text>
        </Flex>
        <Button
          flex="none"
          colorScheme="purple"
          isDisabled={!canEditProject}
          onClick={onAdd}
          leftIcon={<IconWorld size="16" />}
        >
          Add domain
        </Button>
      </Flex>

      {(props.domains ?? []).length > 0 && (
        <VStack alignItems="stretch" spacing={0} borderWidth={'1px'} rounded="lg" px="6" py="2" divider={<Divider />}>
          {props.domains?.map((domain) => (
            <Flex h="14" key={domain} justifyContent="space-between" alignItems="center">
              <Text fontSize="sm" fontWeight="medium">
                {domain}
              </Text>
              {canEditProject && (
                <IconButton
                  icon={<IconTrash size={14} />}
                  aria-label="Remove domain"
                  size="sm"
                  variant="ghost"
                  onClick={() => {
                    setSelectedDomain(domain)
                    onDelete()
                  }}
                />
              )}
            </Flex>
          ))}
        </VStack>
      )}
    </Flex>
  )
}

function RemoveDomainModal(props: Omit<ModalProps, 'children'> & { domain: string | null }) {
  if (!props.domain) {
    return null
  }

  return (
    <DeleteConfirmation
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={`Are you sure you want to remove the authorized domain ${props.domain}?`}
      confirmLabel="Remove domain"
      deletePath={projectPath(`/settings/domains/${props.domain}`)}
    >
      <Text fontSize="sm" color="gray.600">
        Removing this domain means that new teammates with a <strong>@{props.domain}</strong> email will need to be
        manually invited.
      </Text>
    </DeleteConfirmation>
  )
}

function AddDomainModal(props: Omit<ModalProps, 'children'>) {
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const onSubmit = React.useCallback((_event) => {
    setIsSubmitting(true)
  }, [])

  return (
    <Modal {...props}>
      <form method="POST" action={projectPath('/settings/domains')} onSubmit={onSubmit}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add an authorized domain</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AuthenticityToken />
            <FormControl id="domain">
              <FormLabel>Domain</FormLabel>
              <Input name="authorized_domains[domain]" autoComplete="off" pattern="[\w.-]+" placeholder="example.com" />
            </FormControl>
          </ModalBody>

          <ModalFooter justifyContent="flex-end" gap={3}>
            <Button type="submit" size="sm" colorScheme="purple" isLoading={isSubmitting}>
              Add domain
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}
