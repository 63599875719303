import { FormControl, FormHelperText, Stack, Switch } from '@chakra-ui/react'
import React from 'react'
import { ApolloActionProps } from './apollo-setup'

export function EnrichProspect(props: ApolloActionProps) {
  const [enabled, setEnabled] = React.useState(Boolean(props.delivery_rules?.apollo?.enrich_prospect?.enabled))

  return (
    <Stack>
      <FormControl>
        <Switch
          isChecked={enabled}
          onChange={(e) => {
            setEnabled(e.target.checked)
            props.setDeliveryRules({
              ...props.delivery_rules,
              apollo: {
                ...props.delivery_rules?.apollo,
                enrich_prospect: {
                  ...props.delivery_rules?.apollo?.enrich_prospect,
                  enabled: e.target.checked
                }
              }
            })
          }}
          size="sm"
          fontSize={'sm'}
          fontWeight="semibold"
          colorScheme={'purple'}
          value={enabled ? 'true' : 'false'}
          name="follow_rule[delivery_rules][apollo][enrich_prospect][enabled]"
        >
          Enable
        </Switch>
        <FormHelperText>Enrich the current visitor with data from Apollo using their email address.</FormHelperText>
      </FormControl>
    </Stack>
  )
}
