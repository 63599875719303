import {
  Box,
  Button,
  ButtonProps,
  Icon,
  List,
  ListItem,
  Portal,
  Text,
  usePopper,
  UsePopperProps
} from '@chakra-ui/react'
import { IconChevronDown } from '@tabler/icons-react'
import { useSelect, UseSelectProps } from 'downshift'
import React from 'react'

interface Props extends UseSelectProps<any> {
  placeholder?: string | React.ReactNode
  size?: ButtonProps['size']
  variant?: ButtonProps['variant']
  colorScheme?: ButtonProps['colorScheme']
  isDisabled?: boolean
  itemRenderer?: (item: any, index: number) => React.ReactNode
  triggerProps?: ButtonProps
  usePortal?: boolean
  popperOptions?: UsePopperProps
}

const iconSize = {
  xs: 3.5,
  sm: 4,
  md: 4,
  lg: 5
}

const iconSpacing = {
  xs: 1,
  sm: 1.5
}

const fontSize = {
  xs: '13px',
  sm: 'sm',
  md: 'sm'
}

export default function SelectInput({
  placeholder,
  size,
  variant,
  triggerProps,
  popperOptions,
  itemRenderer,
  usePortal,
  ...props
}: Props) {
  const { popperRef, referenceRef } = usePopper({ placement: 'bottom-start', ...popperOptions })
  const { getItemProps, getMenuProps, getToggleButtonProps, highlightedIndex, isOpen, selectedItem } = useSelect(props)
  let selection = selectedItem

  if (selection) {
    selection = itemRenderer?.(selection, 0) || props.itemToString?.(selection) || selection
  }

  const menu = (
    <Box
      minWidth="150px"
      maxWidth="50vw"
      overflowY="auto"
      scrollBehavior="smooth"
      overscrollBehavior="contain"
      maxHeight="340px"
      zIndex="popover"
      position="absolute"
      visibility={isOpen ? 'visible' : 'hidden'}
      background="white"
      border="1px solid"
      borderColor={isOpen ? 'gray.200' : 'transparent'}
      rounded="lg"
      shadow="lg"
      _focus={{ outline: 'none' }}
      {...getMenuProps({ ref: popperRef }, { suppressRefError: true })}
    >
      <List width="100%" display="flex" flexDirection="column" position="relative" listStyleType="none" padding={1}>
        {isOpen &&
          props.items.map((item, index) => (
            <ListItem
              key={index}
              display="flex"
              alignItems="center"
              backgroundColor={highlightedIndex === index ? 'gray.100' : undefined}
              _hover={{ backgroundColor: 'gray.100' }}
              _focus={{ outline: 'none', backgroundColor: 'gray.100' }}
              _active={{ backgroundColor: 'gray.100' }}
              cursor="pointer"
              paddingY={1.5}
              paddingX={3}
              minH={8}
              rounded="md"
              width="100%"
              isTruncated
              userSelect="none"
              {...getItemProps({ item, index })}
            >
              {itemRenderer ? (
                itemRenderer(item, index)
              ) : (
                <Text color="gray.700" fontWeight="medium" fontSize={fontSize[size!] || 'sm'} isTruncated>
                  {props.itemToString?.(item) || item}
                </Text>
              )}
            </ListItem>
          ))}
      </List>
    </Box>
  )

  return (
    <Box display="flex" position="relative" ref={referenceRef}>
      <Button
        type="button"
        width="full"
        justifyContent="space-between"
        size={size}
        variant={variant}
        lineHeight={1}
        isDisabled={props.isDisabled ?? false}
        colorScheme={props.colorScheme}
        rightIcon={
          <Icon as={IconChevronDown} boxSize={iconSize[size!] || 3.5} color={isOpen ? 'gray.800' : 'gray.500'} />
        }
        iconSpacing={iconSpacing[size!] || 2}
        isTruncated
        {...triggerProps}
        {...getToggleButtonProps()}
      >
        <Box isTruncated>{selection || placeholder || 'Select something'}</Box>
      </Button>
      {usePortal ? <Portal>{menu}</Portal> : menu}
    </Box>
  )
}
