import { Box, Button, HStack, Link, Stack } from '@chakra-ui/react'
import { IconThumbUp } from '@tabler/icons-react'
import React from 'react'
import { PageMeta } from '../../../types/PageMeta'
import EmptyState from '../../ui/EmptyState'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { SegmentedControl } from '../../ui/SegmentedControl'
import SettingsHeader from '../../ui/SettingsHeader'
import { TableFooter } from '../../ui/TableFooter'
import { AdminBreadcrumb } from '../admin'
import { mergeParams } from '../icps/types'
import { SuggestedChange } from './components/SuggestedChange'
import { Suggestion } from './types'

interface Props {
  suggestions: Suggestion[]
  page_meta: PageMeta
}

export default function Index(props: Props) {
  return (
    <PageLayout>
      <AdminBreadcrumb paths={[{ path: '/admin/suggestions', title: 'Suggested Edits' }]} />

      <SettingsHeader>
        <HStack justifyContent="space-between">
          <Box>
            <PageTitle>Suggested Edits</PageTitle>
            <PageDescription>
              These are suggested changes to company data from Koala users and visitors on our public website.
            </PageDescription>
          </Box>
          <SegmentedControl size="sm">
            <Button
              isActive={!location.search.includes('status=') || location.search.includes('status=pending')}
              as={Link}
              href="/admin/suggestions"
            >
              Pending
            </Button>
            <Button
              isActive={location.search.includes('status=accepted')}
              as={Link}
              href="/admin/suggestions?status=accepted"
            >
              Accepted
            </Button>
            <Button
              isActive={location.search.includes('status=rejected')}
              as={Link}
              href="/admin/suggestions?status=rejected"
            >
              Rejected
            </Button>
          </SegmentedControl>
        </HStack>
      </SettingsHeader>
      <Stack spacing={6}>
        {props.suggestions.map((suggestion) => (
          <SuggestedChange key={suggestion.id} {...suggestion} />
        ))}
        {props.suggestions.length > 0 ? (
          <TableFooter
            page={props.page_meta.current_page}
            pageMeta={props.page_meta}
            nextPath={mergeParams(window.location.toString(), {
              page: props.page_meta.next_page?.toString()
            })}
            prevPath={mergeParams(window.location.toString(), {
              page: props.page_meta.prev_page?.toString()
            })}
          />
        ) : (
          <EmptyState size="md" heading="No suggested edits" description="Nothing to see here" icon={IconThumbUp} />
        )}
      </Stack>
    </PageLayout>
  )
}
