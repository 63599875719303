import {
  Box,
  Button,
  Flex,
  FormControl,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useCheckboxGroup,
  useDisclosure
} from '@chakra-ui/react'
import { IconListSearch, IconTableOptions } from '@tabler/icons-react'
import pluralize from 'pluralize'
import React, { useCallback, useMemo, useState } from 'react'
import { ExternalToast, toast } from 'sonner'
import { Account } from '../../../../../types/Account'
import { ProfileRecord } from '../../../../../types/Profile'
import { useApps } from '../../../../data/use-apps'
import { useFacets } from '../../../../data/use-facets'
import { useImportProfilesToCrm } from '../../../../data/use-import-to-crm'
import { useProfileSearch } from '../../../../data/use-profile-search'
import { BulkActionBar } from '../../../../ui/BulkActionBar'
import { CardRadioGroup } from '../../../../ui/CardRadioGroup'
import EmptyState from '../../../../ui/EmptyState'
import { SfContactIcon, SfLeadIcon } from '../../../../ui/icons'
import { HubSpotIcon } from '../../../../ui/icons/HubspotIcons'
import { projectPath } from '../../../../ui/ProjectsContext'
import { TableFooter } from '../../../../ui/TableFooter'
import { AddToListModal } from '../../../lists/components/AddToListModal'
import { ProfileList } from '../../../profiles/components/profile-list'
import { SearchBar } from '../../facets/search-bar'
import { FilterPreview } from '../FilterPreview'
interface Props {
  defaultColumns?: string[]
  account: Account
}

const emptyArray = []
const globalDefaults = ['last_seen_at', 'page_views_trend', 'focus_time_trend', 'signal', 'sources']

export function People(props: Props) {
  const columns = (props.defaultColumns || globalDefaults).filter((k) => {
    if (['company', 'title', 'auto_icp_account_score.fit_grade_letter'].includes(k)) return false
    return true
  })

  const facets = useFacets({
    range: 'any',
    facetCloudPath: '/profiles/facet-cloud',
    sortBy: 'last_seen'
  })

  // fetch profiles for this company
  const { data, isLoading } = useProfileSearch({
    accountId: props.account.id,
    columns,
    queryString: facets.queryString
  })

  const apps = useMemo(() => Object.values(data?.apps || {}), [data?.apps])
  const profiles = data?.profiles || emptyArray

  const checkboxes = useCheckboxGroup()
  const selected = checkboxes.value as string[]

  return (
    <Box>
      <Flex justifyContent="space-between" gap={4} px={0} pb={3}>
        <FilterPreview
          {...facets}
          facetValuesPath={`/profiles/facet-values?facets[account_id]=${props.account.id}`}
          kind="profile"
          topFilters={emptyArray}
          shouldShowCompanyFilters={false}
          shouldShowICPFilters={false}
          shouldShowSourceFilter={(data?.profile_sources || emptyArray).length > 1}
          apps={data?.apps}
        />

        <Flex flexShrink={0} width="220px" gap={1}>
          <SearchBar size="sm" debounce={300} value={facets.query} onChange={facets.setQuery} />

          <Tooltip label="Manage default columns">
            <IconButton
              size="sm"
              variant="ghost"
              aria-label="Manage default columns"
              as={Link}
              href={projectPath(`profiles/layouts`)}
              isExternal
              icon={<IconTableOptions size={16} />}
            />
          </Tooltip>
        </Flex>
      </Flex>

      {isLoading || profiles.length > 0 ? (
        <>
          <ProfileList
            profiles={profiles}
            isLoading={isLoading}
            selected={checkboxes.value}
            getCheckboxProps={checkboxes.getCheckboxProps}
            onSelectAll={() => {
              checkboxes.setValue((prev) => (prev.length === profiles.length ? [] : profiles.map((p) => p.id)))
            }}
            // default page size
            rowCount={20}
            apps={apps}
            loadingColumns={isLoading ? columns : emptyArray}
            range="any"
            facetParams={facets}
            columns={columns}
            sortingBy={facets.sortBy}
            onSortChange={facets.setSortBy}
            openLinksInNewTab
            personCellMode="person-summary"
          />

          {data?.page_meta && profiles.length > 0 && (
            <TableFooter
              word="person"
              pageMeta={data?.page_meta}
              page={(facets.page ?? 1) as number}
              setPage={facets.setPage}
              px={[3, 4]}
              scrollToTop={false}
              sticky
            />
          )}
        </>
      ) : (
        <Box bg="background.light" rounded="xl">
          <EmptyState
            size="sm"
            heading={facets.isFiltering ? 'No people match these filters' : 'No people tracked for this account yet'}
            description={facets.isFiltering ? 'Adjust your filters to see other people in this account' : undefined}
            icon={IconListSearch}
            ctaText={facets.isFiltering ? 'Clear filters' : undefined}
            onClick={facets.clearFilters}
          />
        </Box>
      )}

      <BulkActionBar selectionCount={selected.length} onRemoveSelection={() => checkboxes.setValue([])}>
        <BulkAddToList selectedIds={selected} />
        <BulkAddToCrm selectedIds={selected} />
      </BulkActionBar>
    </Box>
  )
}

interface BulkAddToListProps {
  selectedIds: string[]
  onComplete?: (profiles: ProfileRecord[]) => void
}

function BulkAddToList(props: BulkAddToListProps) {
  const disclosure = useDisclosure()

  return (
    <>
      <Button size="sm" variant="outline" onClick={disclosure.onOpen}>
        Add to List
      </Button>
      <AddToListModal {...disclosure} recordIds={props.selectedIds} kind="profile" />
    </>
  )
}

const toastOptions: ExternalToast = {
  dismissible: true,
  closeButton: true,
  position: 'bottom-right'
}

const addToCrmActions = [
  {
    label: (
      <Flex gap={2} alignItems="center" justifyContent="space-between">
        <Text fontWeight="medium" fontSize="sm" lineHeight="18px">
          Add as Leads in Salesforce
        </Text>
        <SfLeadIcon boxSize={4} />
      </Flex>
    ),
    value: 'salesforce-leads'
  },
  {
    label: (
      <Flex gap={2} alignItems="center" justifyContent="space-between">
        <Text fontWeight="medium" fontSize="sm" lineHeight="18px">
          Add as Contacts in Salesforce
        </Text>
        <SfContactIcon boxSize={4} />
      </Flex>
    ),
    value: 'salesforce-contacts'
  },
  {
    label: (
      <Flex gap={2} alignItems="center" justifyContent="space-between">
        <Text fontWeight="medium" fontSize="sm" lineHeight="18px">
          Add as Contacts in HubSpot
        </Text>
        <HubSpotIcon boxSize={4} color="hubspot" />
      </Flex>
    ),

    value: 'hubspot-contacts'
  }
]

interface BulkAddToCrmProps {
  selectedIds: string[]
  onImport?: (profiles: ProfileRecord[]) => void
}

function BulkAddToCrm(props: BulkAddToCrmProps) {
  const { onImport } = props
  const disclosure = useDisclosure()
  const onClose = disclosure.onClose

  const [action, setAction] = useState<string | null>(null)
  const [submitting, setSubmitting] = useState(false)

  const { data } = useApps()

  const availableActions = useMemo(() => {
    const available: any[] = []

    for (const app of Object.values(data?.apps || {})) {
      if (!(app.valid || app.client_valid)) continue

      if (app.app_module === 'Apps::Hubspot::App' && (app.actions?.hs_import_contacts ?? true)) {
        available.push(addToCrmActions.find((a) => a.value === 'hubspot-contacts'))
      }

      if (app.app_module === 'Apps::Salesforce::App' && (app.actions?.sf_import_leads ?? true)) {
        available.push(addToCrmActions.find((a) => a.value === 'salesforce-leads'))
      }

      if (app.app_module === 'Apps::Salesforce::App' && (app.actions?.sf_import_contacts ?? true)) {
        available.push(addToCrmActions.find((a) => a.value === 'salesforce-contacts'))
      }
    }

    return available
  }, [data?.apps])

  const { mutateAsync: importProfilesToCrm } = useImportProfilesToCrm()

  const onSubmit = useCallback(async () => {
    let type: 'contact' | 'lead' | undefined
    let app_module: 'Salesforce' | 'Hubspot' | undefined

    if (action === 'salesforce-leads') {
      app_module = 'Salesforce'
      type = 'lead'
    } else if (action === 'salesforce-contacts') {
      app_module = 'Salesforce'
      type = 'contact'
    } else if (action === 'hubspot-contacts') {
      app_module = 'Hubspot'
      type = 'contact'
    }

    if (!app_module) {
      return
    }

    const count = props.selectedIds.length
    if (count === 0) return

    try {
      setSubmitting(true)
      const res = await importProfilesToCrm({ profile_ids: props.selectedIds, app_module, type })
      onImport?.(res.profiles)
      onClose()
      toast.success(`Imported ${count} ${pluralize(type || 'person', count)} to ${app_module}`, toastOptions)
    } catch (_err) {
      toast.error(`Failed to import ${type}s to CRM`, toastOptions)
    } finally {
      setSubmitting(false)
    }
  }, [props.selectedIds, action, importProfilesToCrm, onImport, onClose])

  return (
    <>
      <Button size="sm" variant="outline" onClick={disclosure.onOpen}>
        Add to CRM
      </Button>

      <Modal {...disclosure} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader fontSize="md">Add to CRM</ModalHeader>
          <ModalBody>
            <FormControl size="sm" isRequired>
              <CardRadioGroup
                size="sm"
                iconPlacement="left"
                direction="column"
                showUncheckedIcon
                value={action || ''}
                onChange={setAction}
                options={availableActions}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter gap={3}>
            <Button size="sm" variant="outline" onClick={disclosure.onClose}>
              Cancel
            </Button>
            <Button size="sm" colorScheme="purple" onClick={onSubmit} isLoading={submitting} isDisabled={!action}>
              Add to CRM
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
