import * as React from 'react'
import PageLayout from '../../ui/PageLayout'
import { CreateProject, Props } from '../welcome/components/CreateProject'

export default function New(props: Props) {
  return (
    <PageLayout
      size="full"
      bg="gray.100"
      css={`
        --max-container-width: 100vw;
      `}
    >
      <CreateProject {...props} showLogo={window.projects.length === 0} />
    </PageLayout>
  )
}
