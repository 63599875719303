import { FollowRule } from '../notifications'

// for now this is just a subset of FollowRule attributes
type FollowRuleParams = Partial<
  Pick<FollowRule, 'name' | 'enabled' | 'target_type' | 'cadence_rules' | 'delivery_rules' | 'any_intent'>
>

interface AutomationRecipeMeta {
  title: string
  description: string
  integration: 'slack' | 'email' | 'webhook' | 'hubspot' | 'salesforce' | 'outreach' | 'apollo'
  // See /app/controllers/follow_rules_controller.rb#follow_rule_params
  config?: FollowRuleParams
  // TODO which type of automation is should spin up?
  // type?: 'auto_outbound_rule' | 'follow_rule'
}

export const automationRecipes: AutomationRecipeMeta[] = [
  {
    integration: 'salesforce',
    title: 'Ensure high-intent accounts are in Salesforce',
    description:
      "Chances are half of the companies on your website aren't in your CRM. Automatically create Accounts in Salesforce when a new company is on your website.",
    config: {
      name: 'Sync high-intent accounts into Salesforce',
      target_type: 'Account',
      delivery_rules: {
        salesforce: {
          import_account: {
            enabled: true
          }
        }
      }
    }
  },
  {
    integration: 'salesforce',
    title: 'Add Salesforce Accounts with Koala data + scores',
    description:
      'When new companies visit your site, Koala can add or update them in Salesforce and enrich them with Koala data and scores.',
    config: {
      name: 'Sync accounts into Salesforce',
      target_type: 'Account',
      delivery_rules: {
        salesforce: {
          import_account: {
            enabled: true
          }
        }
      }
    }
  },
  {
    integration: 'salesforce',
    title: 'Add high-intent leads to Salesforce as Leads',
    description: 'When a visitor triggers a key buying signal, add them to Salesforce as a Lead.',
    config: {
      name: 'Sync leads into Salesforce',
      target_type: 'Profile',
      delivery_rules: {
        salesforce: {
          import_lead: {
            enabled: true
          }
        }
      }
    }
  },
  {
    integration: 'salesforce',
    title: 'Sync Koala intent signals into Salesforce',
    description:
      'When intent happens, sync it to a custom object in Salesforce so you can use it in your reporting and workflows.',
    config: {
      name: 'Sync intent signals into Salesforce',
      target_type: 'Profile',
      delivery_rules: {
        salesforce: {
          create_record: {
            enabled: true
          }
        }
      }
    }
  },
  {
    integration: 'salesforce',
    title: 'Track sales-ready leads via Salesforce Campaigns',
    description: 'Add identified visitors to Salesforce Campaigns when they take a high-intent action.',
    config: {
      name: 'Add leads/contacts to Salesforce Campaigns',
      target_type: 'Profile',
      delivery_rules: {
        salesforce: {
          add_to_campaign: {
            enabled: true
          }
        }
      }
    }
  },
  {
    integration: 'salesforce',
    title: 'Assign follow-ups with Salesforce Tasks',
    description: 'Create and assign tasks in Salesforce to follow-up on specific signals for visitors or accounts.',
    config: {
      name: 'Create Salesforce Tasks',
      target_type: 'Profile',
      delivery_rules: {
        salesforce: {
          create_task: {
            enabled: true,
            priority: 'high',
            reminder_date: 'day'
          }
        }
      }
    }
  },
  {
    integration: 'hubspot',
    title: 'Sync high-intent companies into HubSpot',
    description: 'When a new company visits your website, automatically sync them to HubSpot as a company.',
    config: {
      name: 'Sync high-intent companies into HubSpot',
      target_type: 'Account',
      delivery_rules: {
        hubspot: {
          import_company: {
            enabled: true
          }
        }
      }
    }
  },
  {
    integration: 'hubspot',
    title: 'Sync visitors into HubSpot as Contacts',
    description: 'When a visitor triggers a key buying signal, add them to HubSpot as a Contact.',
    config: {
      name: 'Sync visitors into HubSpot',
      target_type: 'Profile',
      delivery_rules: {
        hubspot: {
          import_contact: {
            enabled: true
          }
        }
      }
    }
  },
  {
    integration: 'hubspot',
    title: 'Display Koala intent signals in your HubSpot timelines',
    description:
      'When intent happens, add it to your HubSpot Contacts and Companies so you can see Koala data alongside your CRM data.',
    config: {
      name: 'Sync intent signals into HubSpot',
      target_type: 'Profile',
      delivery_rules: {
        hubspot: {
          add_timeline_entry: {
            enabled: true,
            add_to_contact_timeline: 'true',
            add_to_company_timeline: 'true'
          }
        }
      }
    }
  },
  {
    integration: 'hubspot',
    title: 'Create tasks in HubSpot',
    description: 'Create and assign tasks in HubSpot to follow-up on specific signals for contacts or companies.',
    config: {
      name: 'Create HubSpot Tasks',
      target_type: 'Profile',
      delivery_rules: {
        hubspot: {
          create_task: {
            enabled: true,
            subject: 'Koala follow-up',
            type: 'TODO',
            priority: 'MEDIUM',
            due_date: 'day',
            dedupe_strategy: 'never_create'
          }
        }
      }
    }
  },
  {
    integration: 'webhook',
    title: 'Send a webhook when intent happens',
    description:
      'Build a custom workflow with tools like Clay or Zapier by sending a webhook whenever visitors or accounts trigger an intent signal.',
    config: {
      name: 'Send a webhook',
      target_type: 'Profile',
      delivery_rules: {
        webhook: {
          trigger: 'immediate'
        }
      }
    }
  },
  {
    integration: 'apollo',
    title: 'Automatically enrich prospects with Apollo',
    description: 'When a known visitor shows up or gets identified, automatically enrich their data with Apollo.',
    config: {
      name: 'Enrich prospects with Apollo',
      target_type: 'Profile',
      delivery_rules: {
        apollo: {
          enrich_prospect: {
            enabled: true
          }
        }
      }
    }
  },
  {
    integration: 'apollo',
    title: 'Add visitors to a list in Apollo',
    description: 'When a visitor triggers a key intent signal, add them to a list in Apollo to work.',
    config: {
      name: 'Add visitors to an Apollo list',
      target_type: 'Profile',
      delivery_rules: {
        apollo: {
          create_contact: {
            enabled: true
          },
          add_to_list: {
            enabled: true
          }
        }
      }
    }
  },
  {
    integration: 'apollo',
    title: 'Add companies to a list in Apollo',
    description: 'When someone from a company triggers a key intent signal, add the company to a list in Apollo.',
    config: {
      name: 'Add companies to an Apollo list',
      target_type: 'Account',
      delivery_rules: {
        apollo: {
          create_account: {
            enabled: true
          },
          add_to_account_list: {
            enabled: true
          }
        }
      }
    }
  },
  {
    integration: 'apollo',
    title: 'Add high-intent visitors to a sequence in Apollo',
    description: 'When a visitor triggers a key intent signal, automatically add them to a sequence in Apollo.',
    config: {
      name: 'Add visitors to an Apollo sequence',
      target_type: 'Profile',
      delivery_rules: {
        apollo: {
          create_contact: {
            enabled: true
          },
          add_to_sequence: {
            enabled: true
          }
        }
      }
    }
  },
  {
    integration: 'outreach',
    title: 'Add visitors to a sequence in Outreach',
    description: 'When visitors show intent, automatically sequence them in Outreach.',
    config: {
      name: 'Add visitors to an Outreach sequence',
      target_type: 'Profile',
      delivery_rules: {
        outreach: {
          import_contact: {
            enabled: true
          },
          add_to_sequence: {
            enabled: true
          }
        }
      }
    }
  },
  {
    integration: 'outreach',
    title: 'Assign follow-ups with Outreach tasks',
    description: 'When visitors or accounts show intent, automatically create and assign tasks in Outreach.',
    config: {
      name: 'Create tasks in Outreach',
      target_type: 'Profile',
      delivery_rules: {
        outreach: {
          import_contact: {
            enabled: true
          },
          create_task: {
            enabled: true
          }
        }
      }
    }
  },
  {
    integration: 'outreach',
    title: 'Sync visitors into Outreach as Prospects',
    description: 'Automatically create or update visitors in Outreach as Prospects when someone shows intent.',
    config: {
      name: 'Create prospects in Outreach',
      target_type: 'Profile',
      delivery_rules: {
        outreach: {
          import_contact: {
            enabled: true
          }
        }
      }
    }
  },
  {
    integration: 'outreach',
    title: 'Sync accounts into Outreach as Accounts',
    description: 'Automatically create or update accounts in Outreach when they show intent.',
    config: {
      name: 'Create accounts in Outreach',
      target_type: 'Account',
      delivery_rules: {
        outreach: {
          import_account: {
            enabled: true
          }
        }
      }
    }
  }
]
