import { Button, Flex, HStack, IconButton, Input, Link, Stack, Text, Textarea } from '@chakra-ui/react'
import { IconTag, IconTransferIn } from '@tabler/icons-react'
import { flattie } from 'flattie'
import uniq from 'lodash/uniq'
import React, { useEffect, useMemo, useRef } from 'react'
import { post } from '../../../../../lib/api'
import { HoverCard } from '../../../../ui/HoverCard'
import { VirtualList } from '../../../../ui/VirtualList'
import { automationsPath } from '../../../notifications/lib/path-helper'

export interface CustomTemplateProps {
  messageTemplate?: string
  formRef?: React.RefObject<HTMLFormElement>
  onChange: (value: string) => void
}

export function CustomTemplate(props: CustomTemplateProps) {
  const [variables, setVariables] = React.useState<object>()
  const ref = useRef<HTMLTextAreaElement>(null)
  const [filter, setFilter] = React.useState<string>('')

  const keys = useMemo(() => {
    let keys = Object.keys(flattie(variables ?? {}))
    // remove array fields
    keys = uniq(keys.map((v) => v.replace(/(.\d+.?)/, '')))
    return keys.filter((v) => v.includes(filter))
  }, [variables, filter])

  useEffect(() => {
    post<{ variables: object }>(automationsPath('/sample-variables')).then((data) => {
      setVariables(data.variables)
    })
  }, [])

  return (
    <>
      <HStack w="100%" justifyContent={'space-between'} alignItems="flex-start">
        <Stack w="100%" spacing="4">
          <Textarea
            size="sm"
            rounded="md"
            fontFamily={'mono'}
            resize={'vertical'}
            rows={(props.messageTemplate?.split('\n').length ?? 16) + 1}
            onChange={(e) => props.onChange(e.target.value)}
            value={props.messageTemplate}
            ref={ref}
          />

          <HStack w="100%">
            <HoverCard
              isPortal
              isLazy
              placement="right-start"
              hoverContent={
                <Stack minW="500px" minH={'800px'}>
                  <Input
                    size="sm"
                    rounded="md"
                    value={filter}
                    placeholder="Search"
                    onChange={(e) => setFilter(e.target.value)}
                    autoFocus
                  />
                  <VirtualList
                    maxH={'800px'}
                    estimateSize={() => 32}
                    items={keys}
                    renderItem={(item) => {
                      const addItem = () => {
                        const selection = ref.current?.selectionStart ?? props.messageTemplate?.length ?? 0

                        const newMessage =
                          props.messageTemplate?.slice(0, selection) +
                          `{{${item}}}` +
                          props.messageTemplate?.slice(selection)

                        props.onChange(newMessage)
                        ref.current?.focus()
                        document.execCommand('insertText', false, `{{${item}}}`)

                        if (ref.current) {
                          ref.current.rows = (newMessage.split('\n').length ?? 0) + 1
                        }

                        setTimeout(() => {
                          ref.current?.setSelectionRange(selection, selection + item.length + 4)
                        }, 0)
                      }

                      return (
                        <HStack
                          w="100%"
                          justifyContent="space-between"
                          px="2"
                          _hover={{
                            bg: 'gray.50'
                          }}
                          onClick={addItem}
                        >
                          <Text fontSize={'sm'}>{item}</Text>
                          <IconButton
                            aria-label="Insert Variable"
                            icon={<IconTransferIn size="10" />}
                            size="xs"
                            variant={'ghost'}
                          />
                        </HStack>
                      )
                    }}
                  />
                </Stack>
              }
            >
              <Button size="sm" leftIcon={<IconTag size="14" />} colorScheme="blue" flex="1">
                Add Variable
              </Button>
            </HoverCard>
          </HStack>

          <Flex fontSize={'sm'} gap="1">
            <Text>Supports</Text>
            <Link color={'blue.500'} href="https://api.slack.com/reference/surfaces/formatting" target="_blank">
              Slack Markdown formatting
            </Link>{' '}
            <Text>and</Text>
            <Link color="blue.500" href="https://shopify.github.io/liquid/tags/control-flow/" isExternal>
              Liquid Templates
            </Link>
          </Flex>
        </Stack>
      </HStack>
    </>
  )
}
