import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import { IconUserPlus, IconX } from '@tabler/icons-react'
import { useCombobox } from 'downshift'
import * as React from 'react'
import { User } from '../../../../types/Invite'
import { Space } from '../../../../types/Space'
import { useAddToSpace, useRemoveFromSpace } from '../../../data/use-space-members'
import { useUsers } from '../../../data/use-users'
import Avatar from '../../../ui/Avatar'
import Combobox from '../../../ui/Combobox'
import { useCurrentUser } from '../../../ui/UserContext'
import useUpdateEffect from '../../../ui/useUpdateEffect'

interface ShareSpaceProps extends UseDisclosureProps {
  space: Space
  updateMembers?: (members: User[]) => void
}

const emptyArray: any[] = []

export function ShareSpace(props: ShareSpaceProps) {
  const disclosure = useDisclosure(props)
  const { data } = useUsers()
  const currentUser = useCurrentUser()
  const users: User[] = data?.users || emptyArray
  const { mutateAsync: addUser } = useAddToSpace()
  const { mutateAsync: removeUser } = useRemoveFromSpace()
  const [members, setMembers] = React.useState<User[]>(props.space.members || emptyArray)

  React.useEffect(() => {
    setMembers(props.space.members || emptyArray)
  }, [props.space.members])

  useUpdateEffect(() => {
    props.updateMembers?.(members)
  }, [props.updateMembers, members])

  const availableUsers = React.useMemo(() => {
    const existingIds = members.map((u) => u.id)
    return users.filter((user) => !existingIds.includes(user.id))
  }, [members, users])

  const addUserToSpace = React.useCallback(
    (user: User) => {
      setMembers((prev) => prev.concat(user))
      addUser({ spaceId: props.space.id, userId: user.id }).catch(() => {
        // undo the addition
        setMembers((prev) => prev.filter((u) => u.id !== user.id))
      })
    },
    [props.space, addUser]
  )

  const removeUserFromSpace = React.useCallback(
    (user: User) => {
      setMembers((prev) => prev.filter((u) => u.id !== user.id))
      removeUser({ spaceId: props.space.id, userId: user.id }).catch(() => {
        // undo the removal
        setMembers((prev) => prev.concat(user))
      })
    },
    [props.space, removeUser]
  )

  return (
    <Popover {...disclosure} placement="auto-end" isLazy lazyBehavior="keepMounted">
      <PopoverTrigger>
        <Button
          size="sm"
          variant="outline"
          iconSpacing={1.5}
          leftIcon={<Icon as={IconUserPlus} boxSize={3.5} color="gray.600" />}
        >
          Share
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <Stack>
            <Heading size="xs" py={1}>
              Add people to this space
            </Heading>
            <Stack py={1}>
              <Combobox
                items={availableUsers}
                itemToString={(item) => item?.name ?? ''}
                selectedItem={null}
                placeholder="Search for a user"
                openOnFocus
                renderItem={UserRenderer}
                itemFilter={(items, inputValue) => {
                  if (!inputValue) {
                    return items || emptyArray
                  }

                  return (items || emptyArray).filter(
                    (item) =>
                      (item.name || '').toLowerCase().includes(inputValue.toLowerCase()) ||
                      (item.email || '').toLowerCase().includes(inputValue.toLowerCase())
                  )
                }}
                inputProps={{ size: 'sm', placeholder: 'Search for users' }}
                onSelectedItemChange={({ selectedItem }) => {
                  if (selectedItem) {
                    addUserToSpace(selectedItem as User)
                  }
                }}
                stateReducer={(state, actionAndChanges) => {
                  const { changes, type } = actionAndChanges
                  switch (type) {
                    case useCombobox.stateChangeTypes.InputBlur:
                      return {
                        ...changes,
                        // dont set the input value, dont change the selected item on blur!
                        selectedItem: state.selectedItem,
                        inputValue: state.inputValue
                      }
                    case useCombobox.stateChangeTypes.ItemClick:
                      return {
                        ...changes,
                        inputValue: '' // don't add the item string as input value
                      }
                    default:
                      return changes
                  }
                }}
              />

              {members.length > 0 && (
                <Box>
                  {members.map((member) => (
                    <UserRow
                      key={member.id}
                      user={member}
                      you={member.id === currentUser.id}
                      onRemove={removeUserFromSpace}
                    />
                  ))}
                </Box>
              )}
            </Stack>

            <Divider />

            <Text fontSize="xs" fontWeight="medium" color="gray.500">
              Anyone in your workspace can join this space. You can also invite people to it.
            </Text>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

interface PartialUser {
  id?: string
  name?: string
  email?: string
  avatar?: string
}

interface UserRendererProps {
  item: PartialUser | null
}

function UserRenderer(props: UserRendererProps) {
  const user = props.item || null

  if (!user) {
    return
  }

  return (
    <HStack spacing={2.5}>
      <Avatar size="sm" name={user.name || user.email} src={user.avatar} />
      <Stack spacing="-0.5">
        <Text fontSize="sm" fontWeight="medium">
          {user.name || user.email || 'Unknown'}
        </Text>
        {user.name && (
          <Text fontSize={'xs'} color="gray.500">
            {user.email}
          </Text>
        )}
      </Stack>
    </HStack>
  )
}

function UserRow({ user, you, onRemove }: { user: User; you?: boolean; onRemove: (user: User) => void }) {
  return (
    <HStack spacing={2} px={2} py={1.5} mx={-1} rounded="md" role="group" _hover={{ bg: 'background.light' }}>
      <Avatar src={user.image} name={user.name || user.email} size="tiny" />
      <Flex flex="1 1 auto" gap={1} alignItems="baseline" fontSize="sm" isTruncated>
        <Text fontWeight="medium" isTruncated>
          {user.name || user.email}
        </Text>
        {you && <Text color="gray.500">(You)</Text>}
      </Flex>
      <IconButton
        aria-label="Remove user"
        variant="ghost"
        icon={<IconX size={14} />}
        size="tiny"
        visibility="hidden"
        _groupHover={{ visibility: 'visible' }}
        onClick={() => onRemove(user)}
      />
    </HStack>
  )
}
