import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { IconArrowRight, IconEdit } from '@tabler/icons-react'
import { flattie } from 'flattie'
import partition from 'lodash/partition'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { post } from '../../../lib/api'
import router from '../../../lib/router'
import { Card } from '../../ui/Card'
import { JSONTree } from '../../ui/json-tree'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { TimeAgo } from '../../ui/TimeAgo'
import { fields } from '../accounts/components/SuggestionsModal'
import { Toggle } from '../accounts/components/Toggle'
import { SearchBar } from '../accounts/facets/search-bar'
import { AdminBreadcrumb } from '../admin'
import { SuggestedChange } from '../suggestions/components/SuggestedChange'
import { useAcceptChange } from '../suggestions/components/useAcceptChange'
import { useRejectChange } from '../suggestions/components/useRejectChange'
import { Suggestion } from '../suggestions/types'
import { CompanySearch } from './components/CompanySearch'
import { EditDetails } from './components/EditDetails'
import CompanyNotFound from './components/CompanyNotFound'

function display(filter: string | undefined, data: any) {
  if (filter) {
    return Object.entries(flattie(data ?? {})).reduce((acc, [key, value]) => {
      if (key.includes(filter)) {
        acc[key] = value
      }
      return acc
    }, {})
  }

  return data
}

export default function Show(props: any) {
  const [company, setCompany] = useState(props.company)
  const domain = useMemo(() => props.domain, [props.domain])
  const [loading, setLoading] = useState<Record<string, boolean>>({})
  const [apiResponses, setApiResponses] = useState<Record<string, any>>({})
  const toast = useToast()

  // update company in local state when it changes from parent
  useEffect(() => {
    setCompany(props.company)
  }, [props.company])

  const lookup = useCallback(
    async (service) => {
      setLoading((prev) => ({ ...prev, [service]: true }))

      try {
        const data = await post<any>(`/admin/kdb/${domain}/lookup/${service}`)
        setApiResponses((prev) => ({ ...prev, [service]: data }))
      } catch (e) {
        toast({
          title: `Failed to enrich ${service} data`,
          status: 'error',
          duration: 9000,
          isClosable: true
        })
      }
      setLoading((prev) => ({ ...prev, [service]: false }))
    },
    [domain, toast]
  )

  const refresh = useCallback(
    async (service) => {
      setLoading((prev) => ({ ...prev, [service]: true }))

      try {
        const data = await post<any>(`/admin/kdb/${domain}/refresh/${service}`)
        setApiResponses((prev) => ({ ...prev, [service]: data }))
      } catch (e) {
        toast({
          title: `Failed to enrich ${service} data`,
          status: 'error',
          duration: 9000,
          isClosable: true
        })
      }
      setLoading((prev) => ({ ...prev, [service]: false }))
    },
    [domain, toast]
  )

  const cbCompany = useMemo(
    () => apiResponses.crunchbase ?? props.crunchbase,
    [props.crunchbase, apiResponses.crunchbase]
  )

  const ziCompany = useMemo(() => apiResponses.zi ?? props.zi, [props.zi, apiResponses.zi])
  const apCompany = useMemo(() => apiResponses.ap ?? props.ap, [props.ap, apiResponses.ap])
  const slCompany = useMemo(() => apiResponses.sl ?? props.sl, [props.sl, apiResponses.sl])
  const pdlCompany = useMemo(() => apiResponses.pdl ?? props.pdl, [props.pdl, apiResponses.pdl])
  const [filter, setFilter] = useState<string | undefined>(undefined)

  const editDrawer = useDisclosure()

  const [suggestions, setSuggestions] = useState(props.suggestions || [])

  const [edits, notes] = useMemo(() => {
    return partition(suggestions || [], (s) => s.to_content && Object.keys(s.to_content).length > 0)
  }, [suggestions])

  const onClose = useCallback(() => {
    editDrawer.onClose()
    router.visit(`/admin/kdb/${props.domain}`, {
      fetch: true
    })
  }, [editDrawer, props.domain])

  const removeSuggestion = useCallback((id: string) => {
    setSuggestions((prev) => prev.filter((s) => s.id !== id))
  }, [])

  return (
    <PageLayout size="md">
      <AdminBreadcrumb
        paths={[
          { path: '/admin/kdb', title: 'Company Search' },
          { path: `/admin/kdb/${props.domain}`, title: props.domain }
        ]}
      />

      <PageTitle skipRendering>Company Search</PageTitle>

      {company ? (
        <>
          <CompanySearch />

          <Heading>{props.company?.name || props.domain}</Heading>

          {suggestions.length > 0 && (
            <Card bg="purple.50" padding={2}>
              <Toggle title={<Heading size="sm">Suggested Edits ({suggestions.length})</Heading>}>
                <Box pr={1.5}>
                  <Stack spacing={3} pb={1.5}>
                    {edits.length > 0 && (
                      <Stack spacing={0} divider={<Divider />} rounded="lg" bg="white" overflow="hidden">
                        {edits.map((edit) => (
                          <SuggestedEdit key={edit.id} {...edit} removeSelf={removeSuggestion} />
                        ))}
                      </Stack>
                    )}

                    {notes.map((suggestion) => (
                      <SuggestedChange key={suggestion.id} {...suggestion} compact />
                    ))}
                  </Stack>
                </Box>
              </Toggle>
            </Card>
          )}

          <SearchBar placeholder="Filter specific fields. e.g. employee count" onChange={setFilter} value={filter} />

          <Stack>
            <Heading size="sm">Database Results</Heading>

            <EditDetails isOpen={editDrawer.isOpen} onClose={onClose} company={company} updateCompany={setCompany} />

            <SimpleGrid minChildWidth="400px" spacing="4">
              <Card p={4}>
                <Toggle
                  title={
                    <HStack spacing={4} w="100%">
                      <Heading flex="1" size="sm">
                        KDB
                        {company.archived_at ? (
                          <Badge colorScheme="red" ml={2}>
                            Archived
                          </Badge>
                        ) : company.published ? (
                          <Badge colorScheme="green" ml={2}>
                            Published
                          </Badge>
                        ) : (
                          <Badge colorScheme="orange" ml={2}>
                            Unlisted
                          </Badge>
                        )}
                      </Heading>
                      <TimeAgo time={company?.updated_at} />
                      <Button
                        size="sm"
                        variant="outline"
                        leftIcon={<Icon as={IconEdit} boxSize={4} />}
                        onClick={(e) => {
                          e.stopPropagation()
                          editDrawer.onOpen()
                        }}
                      >
                        Edit
                      </Button>
                    </HStack>
                  }
                >
                  <JSONTree data={display(filter, company)} />
                </Toggle>
              </Card>

              <Card p={4}>
                <Toggle
                  title={
                    <HStack justifyContent={'space-between'} w="100%">
                      <Heading size="sm">Crunchbase</Heading>
                      <TimeAgo time={cbCompany?.updated_at} />
                      {cbCompany && (
                        <Link href={`https://crunchbase.com/organization/${cbCompany?.org_id}`} isExternal>
                          Permalink
                        </Link>
                      )}
                      {!props.crunchbase && (
                        <Link
                          href={`https://www.google.com/search?q=site:crunchbase.com/organization ${company?.domain}`}
                          isExternal
                        >
                          Find
                        </Link>
                      )}
                      <Button
                        isLoading={loading.crunchbase}
                        colorScheme={'blue'}
                        size="sm"
                        onClick={() => refresh('crunchbase')}
                      >
                        Refresh Cache
                      </Button>
                    </HStack>
                  }
                >
                  <JSONTree data={display(filter, cbCompany)} />
                </Toggle>
              </Card>

              <Card p={4}>
                <Toggle
                  title={
                    <HStack justifyContent={'space-between'} w="100%">
                      <Heading size="sm">ZoomInfo</Heading>
                      <TimeAgo time={ziCompany?.updated_at} />
                      {ziCompany && (
                        <Link
                          href={`https://zoominfo.com/c/${ziCompany?.name}/${ziCompany?.payload?.companyId}`}
                          isExternal
                        >
                          Permalink
                        </Link>
                      )}
                      {!props.zi && (
                        <Link
                          href={`https://www.google.com/search?q=site:zoominfo.com/c ${company?.domain}`}
                          isExternal
                        >
                          Find
                        </Link>
                      )}
                      <Button isLoading={loading.zi} colorScheme={'blue'} size="sm" onClick={() => refresh('zi')}>
                        Refresh Cache
                      </Button>
                    </HStack>
                  }
                >
                  <JSONTree data={display(filter, ziCompany)} />
                </Toggle>
              </Card>

              <Card p={4}>
                <Toggle
                  title={
                    <HStack justifyContent={'space-between'} w="100%">
                      <Heading size="sm">Apollo Public</Heading>
                      <TimeAgo time={apCompany?.updated_at} />
                      {apCompany && (
                        <Link href={`https://apollo.io/companies/${apCompany?.name}/${apCompany?.org_id}`} isExternal>
                          Permalink
                        </Link>
                      )}
                      {!props.ap && (
                        <Link
                          href={`https://www.google.com/search?q=site:apollo.io/companies ${company?.domain}`}
                          isExternal
                        >
                          Find
                        </Link>
                      )}
                      <Button isLoading={loading.ap} colorScheme={'blue'} size="sm" onClick={() => refresh('ap')}>
                        Refresh Cache
                      </Button>
                    </HStack>
                  }
                >
                  <JSONTree data={display(filter, apCompany)} />
                </Toggle>
              </Card>

              <Card p={4}>
                <Toggle
                  title={
                    <HStack justifyContent={'space-between'} w="100%">
                      <Heading size="sm">Slintel</Heading>
                      <TimeAgo time={slCompany?.updated_at} />
                      <Link
                        href={`https://www.google.com/search?q=site:6sense.com/company ${company?.domain}`}
                        isExternal
                      >
                        {slCompany ? 'Permalink' : 'Find'}
                      </Link>
                      <Button isLoading={loading.sl} colorScheme={'blue'} size="sm" onClick={() => refresh('sl')}>
                        Refresh Cache
                      </Button>
                    </HStack>
                  }
                >
                  <JSONTree data={display(filter, slCompany)} />
                </Toggle>
              </Card>

              <Card p={4}>
                <Toggle
                  title={
                    <HStack justifyContent={'space-between'} w="100%">
                      <Heading size="sm">CoreSignal</Heading>
                      <TimeAgo time={props.coresignal?.updated_at} />
                    </HStack>
                  }
                >
                  <JSONTree data={display(filter, props.coresignal)} />
                </Toggle>
              </Card>

              <Card p={4}>
                <Toggle
                  title={
                    <HStack justifyContent={'space-between'} w="100%">
                      <Heading size="sm">PDL Free</Heading>
                      <TimeAgo time={props.pdl?.updated_at} />
                    </HStack>
                  }
                >
                  <JSONTree data={display(filter, props.pdl)} />
                </Toggle>
              </Card>
            </SimpleGrid>
          </Stack>

          <Divider />

          <Stack>
            <Heading size="sm">APIs</Heading>
            <SimpleGrid minChildWidth="400px" spacing="4">
              <Card p={4}>
                <HStack justifyContent={'space-between'}>
                  <Heading size="sm">Clearbit</Heading>
                  <Button
                    isLoading={loading.clearbit}
                    colorScheme={'blue'}
                    size="sm"
                    onClick={() => lookup('clearbit')}
                  >
                    Fetch
                  </Button>
                </HStack>
                <JSONTree data={display(filter, apiResponses.clearbit)} treeProps={{ hideRoot: false }} />
              </Card>

              <Card p={4}>
                <HStack justifyContent={'space-between'}>
                  <Heading size="sm">PDL</Heading>
                  <Button isLoading={loading.pdl} colorScheme={'blue'} size="sm" onClick={() => lookup('pdl')}>
                    Fetch
                  </Button>
                </HStack>
                <JSONTree data={display(filter, pdlCompany)} treeProps={{ hideRoot: false }} />
              </Card>

              <Card p={4}>
                <HStack justifyContent={'space-between'}>
                  <Heading size="sm">Apollo API</Heading>
                  <Button isLoading={loading.apollo} colorScheme={'blue'} size="sm" onClick={() => lookup('apollo')}>
                    Fetch
                  </Button>
                </HStack>
                <JSONTree data={display(filter, apiResponses.apollo)} treeProps={{ hideRoot: false }} />
              </Card>

              <Card p={4}>
                <HStack justifyContent={'space-between'}>
                  <Heading size="sm">ProxyCurl</Heading>
                  <Button
                    isLoading={loading.proxycurl}
                    colorScheme={'blue'}
                    size="sm"
                    onClick={() => lookup('proxycurl')}
                  >
                    Fetch
                  </Button>
                </HStack>
                <JSONTree data={display(filter, apiResponses.proxycurl)} treeProps={{ hideRoot: false }} />
              </Card>

              <Card p={4}>
                <HStack justifyContent={'space-between'}>
                  <Heading size="sm">CoreSignal</Heading>
                  <Button
                    isLoading={loading.coresignal}
                    colorScheme={'blue'}
                    size="sm"
                    onClick={() => lookup('coresignal')}
                  >
                    Fetch
                  </Button>
                </HStack>
                <JSONTree data={display(filter, apiResponses.coresignal)} treeProps={{ hideRoot: false }} />
              </Card>
            </SimpleGrid>
          </Stack>
        </>
      ) : (
        <CompanyNotFound domain={props.domain} />
      )}
    </PageLayout>
  )
}

function SuggestedEdit(edit: Suggestion & { removeSelf: (id: string) => void }) {
  const toContent = edit.to_content || {}
  const fromContent = edit.from_content || {}

  const { isLoading: rejecting, mutateAsync: rejectChange } = useRejectChange()
  const { isLoading: accepting, mutateAsync: acceptChange } = useAcceptChange()

  return (
    <Box>
      {Object.entries(toContent).map(([key, value]) => (
        <Flex key={edit.id + key} alignItems="center" fontSize="sm" gap={2} px={4} py={3} _hover={{ bg: 'gray.50' }}>
          <Stack spacing={0}>
            <HStack>
              <Text flex="none" fontWeight="medium" color="gray.800">
                {fields.find((f) => f.key === key)?.label || key}:
              </Text>
              <HStack spacing={0.5}>
                <Text paddingY={0.5} fontWeight="medium" color="gray.600" bg="gray.100" paddingX={1.5} rounded="base">
                  {String(fromContent[key] || null)}
                </Text>
                <Icon as={IconArrowRight} boxSize={4} color="gray.500" />
                <Text paddingY={0.5} fontWeight="medium" color="green.700" bg="green.50" paddingX={1.5} rounded="base">
                  {String(value || null)}
                </Text>
              </HStack>
            </HStack>
            <Text flex="none" fontSize="xs" color="gray.600">
              Suggested <TimeAgo time={edit.created_at} /> by {edit.user?.name || edit.user?.email}
            </Text>
          </Stack>
          <HStack flex="none" spacing={2} marginLeft="auto">
            <Button
              size="sm"
              variant="outline"
              color="red.500"
              onClick={() => rejectChange({ id: edit.id }).then(() => edit.removeSelf(edit.id))}
              isLoading={rejecting}
              isDisabled={accepting}
            >
              Reject
            </Button>
            <Button
              size="sm"
              colorScheme="green"
              onClick={() => acceptChange({ id: edit.id }).then(() => edit.removeSelf(edit.id))}
              isLoading={accepting}
              isDisabled={rejecting}
            >
              Apply
            </Button>
          </HStack>
        </Flex>
      ))}
    </Box>
  )
}
