import { Button, HStack, Icon, Tooltip } from '@chakra-ui/react'
import { IconPlayerPause, IconPlayerPlayFilled } from '@tabler/icons-react'
import React, { useEffect } from 'react'
import Pulse from '../../../ui/Pulse'

interface Props {
  paused?: boolean
  onToggle?: (mode: 'playing' | 'paused') => void
}

export function PlayPauseButton({ onToggle, ...props }: Props) {
  const [paused, setPaused] = React.useState(props.paused)

  useEffect(() => {
    setPaused(props.paused)
  }, [props.paused])

  const onClick = React.useCallback(() => {
    setPaused((paused) => {
      const nowPaused = !paused
      onToggle?.(nowPaused ? 'paused' : 'playing')
      return nowPaused
    })
  }, [onToggle])

  const tooltip = paused ? 'Click to Resume Live Feed' : 'Click to Pause Live Feed'
  const icon = paused ? IconPlayerPlayFilled : IconPlayerPause

  return (
    <HStack spacing={3}>
      {!paused && <Pulse size={2.5} bg={paused ? 'gray.300' : 'green.400'} />}
      <Tooltip label={tooltip}>
        <Button
          size="sm"
          variant="outline"
          color="gray.600"
          leftIcon={<Icon as={icon} color={paused ? undefined : 'gray.500'} boxSize={4} />}
          onClick={onClick}
        >
          {paused ? 'Resume' : 'Pause'}
        </Button>
      </Tooltip>
    </HStack>
  )
}
