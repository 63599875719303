import dayjs from './dayjs'

type DailyValue<T extends string = 'value'> = {
  day: string
} & {
  [K in T]: number
}

export function fillMissingDays<T extends string = 'value'>(
  values: DailyValue<T>[],
  start,
  end,
  dataKey: T = 'value' as T
): DailyValue<T>[] {
  const dates = values.map((v) => dayjs.utc(v.day))
  start = start || dayjs.min(dates)?.format('YYYY-MM-DD')
  end = end || dayjs.max(dates)?.format('YYYY-MM-DD')

  if (!start || !end) {
    return values
  }

  const days: DailyValue<T>[] = []
  let current = dayjs.utc(start)
  while (current.isBefore(end) || current.isSame(end, 'day')) {
    const formatted = current.format('YYYY-MM-DD')
    const day = values.find((v) => v.day === formatted) || {
      day: formatted,
      [dataKey || 'value']: 0
    }

    days.push(day as DailyValue<T>)
    current = current.add(1, 'day')
  }

  return days
}
