import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import React from 'react'
import { ListKindSelection, ListKindSelectionProps } from '../new'

interface NewListModalProps extends UseDisclosureProps, ListKindSelectionProps {}

export function NewListModal({ kind, ownership, teamId, spaceId, parentId, newPath, ...props }: NewListModalProps) {
  const disclosure = useDisclosure(props)

  return (
    <Modal {...disclosure} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <ListKindSelection
            kind={kind}
            ownership={ownership}
            teamId={teamId}
            spaceId={spaceId}
            parentId={parentId}
            newPath={newPath}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
