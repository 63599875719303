import { useMutation } from 'react-query'
import { post } from '../../lib/api'
import { projectPath } from '../ui/ProjectsContext'
import { Prospect } from './use-prospects'

interface EnrichProspectsParams {
  prospectIds: string[]
}

interface ResponseData {
  prospects: Array<Pick<Prospect, 'id' | 'email' | 'unlock_state'>>
}

export function useEnrichProspects() {
  return useMutation<ResponseData, unknown, EnrichProspectsParams>(({ prospectIds }) => {
    const path = projectPath(`/prospects/enrich`)
    return post(path, {
      prospect_ids: prospectIds
    })
  })
}
