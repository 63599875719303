import { Flex, IconButton } from '@chakra-ui/react'
import { IconLayoutSidebar } from '@tabler/icons-react'
import React from 'react'
import { Breadcrumb } from './Breadcrumb'
import { projectPath } from './ProjectsContext'
import { useSettingsNav } from './SettingsNav'

interface Props {
  paths?: Path[]
  rootPath?: Path
  // only show if the settings sidebar is offscreen
  offscreen?: boolean
}

interface Path {
  path: string
  title: string
}

const emptyPaths: Path[] = []

export function SettingsBreadCrumb(props: Props) {
  const rootPath = props.rootPath
  const paths = props.paths || emptyPaths
  const sidebar = useSettingsNav()

  if ((props.offscreen || paths.length === 0) && !sidebar.offScreen) {
    return null
  }

  return (
    <Flex gap={2} alignItems="center">
      {sidebar.offScreen && (
        <IconButton
          aria-label="Toggle Settings Nav"
          size="xs"
          variant="ghost"
          icon={<IconLayoutSidebar size={18} />}
          onClick={sidebar.onToggle}
        />
      )}
      <Breadcrumb
        paths={[
          {
            path: rootPath?.path || projectPath('/settings'),
            title: rootPath?.title || 'Settings'
          }
        ].concat(paths)}
      />
    </Flex>
  )
}
