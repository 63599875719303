import createPersistedStateOg from 'use-persisted-state'

export class TtlProvider {
  ttl: number

  constructor(ttl: number) {
    this.ttl = ttl
  }

  getTtl(key) {
    if (!this.ttl) {
      return null
    }
    const val = localStorage.getItem(`${key}:ttl`)
    return val ? JSON.parse(val) : null
  }

  setTtl(key) {
    if (!this.ttl) {
      return
    }
    localStorage.setItem(`${key}:ttl`, JSON.stringify(Date.now() + this.ttl))
  }

  getItem(key) {
    const ttl = this.getTtl(key)

    // it's expired
    if (ttl && Date.now() >= ttl) {
      this.removeItem(key)
      return null
    }

    return localStorage.getItem(key)
  }

  setItem(key, value) {
    this.setTtl(key)
    return localStorage.setItem(key, value)
  }

  removeItem(key) {
    localStorage.removeItem(`${key}:ttl`)
    return localStorage.removeItem(key)
  }
}

export default function createPersistedState<T>(key: string, opts: { expiresIn: number }) {
  return createPersistedStateOg<T>(key, new TtlProvider(opts.expiresIn))
}
