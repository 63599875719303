import { useOutreachSequences } from '@app/components/data/use-outreach-sequences'
import { App } from '@app/types/App'
import { Sequence } from '@app/types/Outreach'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Checkbox,
  Flex,
  Heading,
  HStack,
  Image,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useCheckboxGroup
} from '@chakra-ui/react'
import get from 'lodash/get'
import React from 'react'
import { useApp } from '../../../../data/use-app'
import { GrayCard, LightBgCard } from '../../../../ui/Card'
import { TextEllipsis } from '../../../../ui/text-ellipsis'
import { SearchBar } from '../../../accounts/facets/search-bar'

interface SequencesProps {
  updateSelectedSequences: (ids: number[]) => void
  app: App
}

export function Sequences(props: SequencesProps) {
  const sequences = useOutreachSequences(props.app.app_instance_id)
  const appData = useApp('outreach')

  if ((sequences.data?.outreach_sequences || []).length <= 0) {
    return null
  }

  return (
    <Skeleton isLoaded={!sequences.isLoading}>
      <Stack alignItems="stretch" spacing={5}>
        <Stack>
          <HStack alignItems="center" spacing={2}>
            <Image src={props.app.logo} boxSize="5" />
            <Heading size="sm" fontWeight={'semibold'}>
              Outreach Sequences
            </Heading>
          </HStack>
          <Text fontSize="sm" color="gray.600">
            Use the checkboxes to select which Sequences the contacts will be added to after being imported to Outreach.
          </Text>
        </Stack>
        <SequenceTable
          sequences={sequences.data?.outreach_sequences || []}
          setSelected={props.updateSelectedSequences}
          userInOutreach={props.app.user_in_app || (appData.data?.user_in_app ?? false)}
        />
      </Stack>
    </Skeleton>
  )
}

interface SequenceTableProps {
  sequences: Sequence[]
  setSelected: (ids: number[]) => void
  userInOutreach: boolean
}

function SequenceTable(props: SequenceTableProps) {
  const [filter, setFilter] = React.useState('')
  const { sequences, setSelected, userInOutreach } = props
  const { value, setValue, getCheckboxProps } = useCheckboxGroup({
    defaultValue: [],
    onChange: setSelected
  })

  const visibleSequences = React.useMemo(() => {
    if (!filter) {
      return sequences
    }

    return sequences.filter((p) => {
      const searchable = [p.name, p.outreach_id, p.sequenceType, p.outreach_id].filter(Boolean).join(' ')
      return searchable.toLowerCase().includes(filter.toLowerCase())
    })
  }, [filter, sequences])

  const toggleAll = React.useCallback(() => {
    setValue((prev) =>
      prev.length === sequences.length ? [] : (visibleSequences.map((p) => p.outreach_id) as number[])
    )
  }, [sequences, setValue, visibleSequences])

  return (
    <Stack position="relative" alignItems="center" as={GrayCard}>
      <SearchBar onChange={(e) => setFilter(e)} value={filter} size="sm" />
      <Flex maxH="400" overflowY={'auto'} bg="white" as={LightBgCard}>
        <Table size="sm" opacity={userInOutreach ? '1' : '0.3'}>
          <Thead>
            <Tr>
              <Th background="white" zIndex={1} position="sticky" top={0} paddingLeft={0} width="240px">
                <Checkbox
                  isChecked={sequences.length > 0 && value.length === sequences.length}
                  onChange={toggleAll}
                  isDisabled={!userInOutreach}
                />
              </Th>
              <Th background="white" zIndex={1} position="sticky" top={0} width="240px">
                Name
              </Th>
              <Th background="white" zIndex={1} position="sticky" top={0} width="240px">
                Type
              </Th>
              <Th background="white" zIndex={1} position="sticky" top={0} width="240px">
                Schedule
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {visibleSequences.map((seq) => {
              return (
                <Tr key={seq.id}>
                  <Td paddingLeft={0}>
                    <Checkbox
                      {...getCheckboxProps({ isDisabled: false, value: seq.outreach_id })}
                      isDisabled={!userInOutreach}
                    />
                  </Td>
                  <Td w="100%">
                    <Text fontSize="xs">
                      <TextEllipsis maxW={'200'}>{seq.name}</TextEllipsis>
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize="xs">{get(seq, ['payload', 'attributes', 'sequenceType'], '-')}</Text>
                  </Td>
                  <Td>
                    <Text fontSize="xs">{get(seq, ['payload', 'attributes', 'scheduleIntervalType'], '-')}</Text>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
        {!userInOutreach && (
          <Flex position="absolute" p={2} alignItems="center">
            <Alert
              status="error"
              fontSize={'sm'}
              as={Stack}
              justifyContent={'flex-start'}
              bg="red.50"
              borderWidth={'1px'}
              borderColor="red.100"
              rounded="md"
            >
              <HStack spacing={0}>
                <AlertIcon />
                <AlertTitle>Missing User</AlertTitle>
              </HStack>
              <AlertDescription lineHeight={'1.5'}>
                We couldn't find an Outreach user with your email. You will be able to import contacts, but not assign
                them to sequences.
              </AlertDescription>
            </Alert>
          </Flex>
        )}
      </Flex>
    </Stack>
  )
}
