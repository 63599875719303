import { Icon, IconProps } from '@chakra-ui/react'
import React from 'react'

interface Props extends IconProps {
  size?: string | number
}

export const G2Icon: React.FC<Props> = ({ size, ...props }) => {
  size = size ? `${size}px` : size

  return (
    <Icon viewBox="0 0 1000 1000" color="#ff492c" width={size} height={size} {...props}>
      <circle cx="500" cy="500" r="500" fill="currentColor"></circle>
      <path
        d="M716.4,383H631c2.3-13.4,10.6-20.9,27.4-29.4l15.7-8c28.1-14.4,43.1-30.7,43.1-57.3,0-16.7-6.5-29.9-19.4-39.4s-28.1-14.2-45.9-14.2A70.8,70.8,0,0,0,613,245.8c-11.7,7.2-20.4,16.5-25.8,28.1l24.7,24.8c9.6-19.4,23.5-28.9,41.8-28.9,15.5,0,25,8,25,19.1,0,9.3-4.6,17-22.4,26l-10.1,4.9c-21.9,11.1-37.1,23.8-45.9,38.2s-13.1,32.5-13.1,54.4v6H716.4Z"
        fill="#fff"
      ></path>
      <polygon
        points="705 459.2 563.6 459.2 492.9 581.6 634.3 581.6 705 704.1 775.7 581.6 705 459.2"
        fill="#fff"
      ></polygon>
      <path
        d="M505.1,663.3c-90,0-163.3-73.3-163.3-163.3s73.3-163.3,163.3-163.3L561,219.8a286.4,286.4,0,0,0-55.9-5.5c-157.8,0-285.7,127.9-285.7,285.7S347.3,785.7,505.1,785.7a283.9,283.9,0,0,0,168.2-54.8L611.5,623.7A162.8,162.8,0,0,1,505.1,663.3Z"
        fill="#fff"
      ></path>
    </Icon>
  )
}
