import { useQuery } from 'react-query'
import { concurrentGET } from '../../lib/api'
import { kqlDefinitionPathAPI, kqlDefinitionsPathAPI } from '../pages/kql_definitions/path-helpers'
import { KqlDefinition } from '../pages/kql_definitions/types'
import { useCurrentProject } from '../ui/ProjectsContext'

export function useIntentSignals() {
  const project = useCurrentProject()
  const path = kqlDefinitionsPathAPI()

  return useQuery(
    ['kql_definitions', { projectId: project?.id }],
    () => concurrentGET<{ definitions: KqlDefinition[] }>(path),
    { enabled: Boolean(project?.id) }
  )
}

export function useIntentSignal(id?: string) {
  const project = useCurrentProject()

  return useQuery(
    ['kql_definition', { projectId: project?.id, id }],
    () => concurrentGET<{ definition: KqlDefinition }>(kqlDefinitionPathAPI(id!)),
    { enabled: Boolean(project?.id && id) }
  )
}
