import { Button, Icon, Link } from '@chakra-ui/react'
import { IconArrowLeft } from '@tabler/icons-react'
import React from 'react'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { slackAlertsPath } from '../notifications/lib/path-helper'
import { DefinitionForm } from './components/DefinitionForm'

interface SlackRuleProps extends React.ComponentProps<typeof DefinitionForm> {}

export default function EditSlackRule(props: SlackRuleProps) {
  return (
    <PageLayout size="sm">
      <PageTitle skipRendering>Edit Slack Alert</PageTitle>

      <Button
        alignSelf="flex-start"
        as={Link}
        href={slackAlertsPath()}
        leftIcon={<Icon as={IconArrowLeft} boxSize={3.5} />}
        variant="link"
        size="xs"
        flex="none"
        color="gray.500"
      >
        Back to all Slack Alerts
      </Button>

      <DefinitionForm {...props} />
    </PageLayout>
  )
}
