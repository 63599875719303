import { projectPath } from '../../../ui/ProjectsContext'

export function visitorsPath(params?: { page?: number; range?: 'day' | 'week' | 'month' }) {
  const { page = 1, range = 'week' } = params || {}
  return projectPath(`/visitors?page=${page}&range=${range}`)
}

export function profilePath(profile: { id: string; email?: string | null }, path = '') {
  return projectPath(`/profiles/${profile.email || profile.id}/${path}`.replace('//', '/'))
}
