import { projectPath } from '../../../ui/ProjectsContext'

export function autoOutboundPath(): string {
  return projectPath(`/auto-outbound`)
}

export function autoOutboundRulePath(id: string, rest?: string): string {
  return projectPath(`/auto-outbound/${id}${rest || ''}`)
}

export function autoOutboundNewFromRulePath(id: string): string {
  return projectPath(`/auto-outbound/new?from_rule=${id}`)
}

export function autoOutboundLogsPath({ rule_id, status }: { rule_id?: string; status?: string } = {}): string {
  let path = projectPath(`/auto-outbound/logs`)

  if (rule_id) {
    path = `${path}&rule_id=${rule_id}`
  }

  if (status) {
    path = `${path}&status=${status}`
  }

  return path.replace('&', '?')
}

export function autoOutboundLedgerPath({ rule_id, executed }: { rule_id?: string; executed?: boolean }): string {
  let path = projectPath(`/auto-outbound/ledger`)

  if (rule_id) {
    path = `${path}&rule_id=${rule_id}`
  }

  if (executed !== undefined) {
    path = `${path}&executed=${executed}`
  }

  return path.replace('&', '?')
}

export function autoOutboundEditPath(id: string): string {
  return projectPath(`/auto-outbound/${id}/edit`)
}

export function autoOutboundNewPath(): string {
  return projectPath(`/auto-outbound/new`)
}
