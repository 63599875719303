import { useQuery } from 'react-query'
import { concurrentGET } from '../../lib/api'
import { Space } from '../../types/Space'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Data {
  spaces: Space[]
}

interface Props {
  ownership?: 'private' | 'shared'
}

export function useSpaces({ ownership }: Props = {}) {
  const project = useCurrentProject()
  const search = new URLSearchParams()

  if (Array.isArray(ownership)) {
    ownership.forEach((o) => search.append('ownership', o))
  } else if (ownership) {
    search.set('ownership', ownership)
  }

  const path = projectPath(`/spaces.json?${search.toString()}`)

  return useQuery<Data>(
    ['spaces', { projectId: project?.id, ownership }],
    () => concurrentGET<{ spaces: Space[] }>(path),
    {
      enabled: Boolean(project?.id)
    }
  )
}
