import { Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'
import { AdminBreadcrumb } from '../..'
import PageLayout from '../../../../ui/PageLayout'
import PageTitle from '../../../../ui/PageTitle'
import { TimeAgo } from '../../../../ui/TimeAgo'

interface EmailLog {
  id: string
  created_at: Date
  project: string
  mailer: string
  to: string
}

interface Report {
  emails: EmailLog[]
}

interface Props {
  report: Report
}

export default function Index(props: Props) {
  return (
    <PageLayout>
      <AdminBreadcrumb paths={[{ path: '/admin/reports/emails', title: 'Emails' }]} />

      <PageTitle>Emails</PageTitle>

      <Stack spacing="12">
        <TableContainer fontSize="sm" w="100%">
          <Table size="sm" w="100%" height="1px">
            <Thead>
              <Tr>
                <Th>Sent at</Th>
                <Th>Project</Th>
                <Th>Mailer</Th>
                <Th>To</Th>
              </Tr>
            </Thead>
            <Tbody bg="white">
              {props.report.emails.map((email) => (
                <Tr key={email.id}>
                  <Td>
                    <TimeAgo time={email.created_at} fallback="—" />
                  </Td>
                  <Td>{email.project}</Td>
                  <Td>
                    <code>{email.mailer}</code>
                  </Td>
                  <Td>{email.to}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </PageLayout>
  )
}
