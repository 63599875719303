import { Box, Icon, Flex } from '@chakra-ui/react'
import { Icon as TablerIcon } from '@tabler/icons-react'
import React from 'react'
import { Facet, FacetMappings } from '../..'
import { Iconify } from '../../../../ui/Iconify'
import FilterPopover from '../../facets/filter-popover'
import { FilterPreviewProps } from '../FilterPreview/types'
import { DropdownIcon } from './DropdownIcon'
import Label from './Label'
import Operator from './Operator'
import RemoveButton from './RemoveButton'
import Values from './Values'

interface Props extends FilterPreviewProps {
  onEditOperator?: (operator: string) => void
  onRemove?: () => void
  facetMappings: FacetMappings
  onClick?: () => void
  overflow?: Array<string | number | boolean> | null
  facet?: string
  label?: string
  operator?: string
  value: Facet
  formatter?: (value: any) => string
  icon?: JSX.Element | TablerIcon | string | typeof Icon
  isRemovable?: boolean
}

const FilterTag = (props: Props) => {
  const {
    icon,
    label,
    operator,
    value,
    onEditOperator,
    onRemove,
    facet,
    onClick,
    isEditable,
    overflow,
    formatter,
    ...rest
  } = props

  const iconElement = <Iconify icon={icon} size={16} />
  const colorScheme = 'gray'

  const hasValues = Array.isArray(value) ? value.length > 0 : !!value
  const displayDropdown = !hasValues && isEditable && !onRemove

  const content = (
    <Box
      role="group"
      display="inline-flex"
      alignItems="stretch"
      flexGrow={0}
      cursor={displayDropdown ? 'pointer' : undefined}
      _hover={displayDropdown ? { bg: 'gray.100' } : undefined}
      height="32px"
      color={`${colorScheme}.700`}
      borderColor="gray.200"
      borderRadius={6}
      borderWidth="1px"
      bg="white"
      shadow="sm"
      maxWidth="100%"
      onClick={onClick}
      userSelect="none"
      __css={{
        '& > span:first-of-type': {
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6
        },
        '& > span:last-of-type': {
          borderTopRightRadius: 6,
          borderBottomRightRadius: 6
        }
      }}
    >
      {(label || iconElement) && (
        <Flex as="span" flex="none" gap={[1, 1.5]} paddingX={[1.5, 2]}>
          <Box
            as="span"
            display="flex"
            alignItems="center"
            rounded="sm"
            overflow={typeof icon === 'string' ? 'hidden' : undefined}
            flexShrink={0}
          >
            {iconElement}
          </Box>
          {label && <Label>{label}</Label>}
        </Flex>
      )}

      {label && operator && (
        <Operator colorScheme={colorScheme} isEditable={isEditable} onEditOperator={onEditOperator}>
          {operator}
        </Operator>
      )}

      {hasValues ? (
        <Values
          isEditable={isEditable}
          isRemovable={!!onRemove}
          value={value}
          overflow={overflow}
          facet={facet}
          colorScheme={colorScheme}
          {...rest}
        >
          {formatter ? formatter(value) : value}
        </Values>
      ) : displayDropdown ? (
        <DropdownIcon />
      ) : null}

      {onRemove && isEditable && <RemoveButton onClick={onRemove} />}
    </Box>
  )

  if (displayDropdown) {
    return (
      <FilterPopover
        {...rest}
        selectedFacetKey={facet}
        width={facet === 'sources' ? 'auto' : undefined}
        placement="bottom"
      >
        {content}
      </FilterPopover>
    )
  }

  return content
}

export default FilterTag
