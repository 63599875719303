import { Heading, Stack } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { formatNumber } from '../../../../lib/number-format'
import { useDashboardWidget } from '../../../data/use-dashboard-widget'
import { facetQueryString } from '../../../data/use-facets'
import { Card } from '../../../ui/Card'
import { FacetFilters } from '../../accounts'
import { BarGraph } from '../../analytics/components/BarChart'
import { TopList } from './top-list'

export interface AccountGrowthProps {
  period: string
  accountIds: string[]
  facetFilters: FacetFilters
  focusTime?: number
}

export function AccountGrowth(props: AccountGrowthProps) {
  const { period, accountIds } = props

  const params = useMemo(() => ({ period }), [period])
  const facetQuery = useMemo(() => {
    let query = facetQueryString(props.facetFilters).join('&')

    if (props.focusTime) {
      const ft = facetQueryString({ focus_time: props.focusTime }).join('&')
      query = [query, ft].filter(Boolean).join('&')
    }

    return query
  }, [props.focusTime, props.facetFilters])

  const accountGrowth = useDashboardWidget('account_growth', params, facetQuery, accountIds)

  return (
    <Stack flex="1" w="100%" spacing="6" as={Card}>
      <Heading size="sm" fontWeight={'semibold'}>
        Account Growth (cumulative week/week)
      </Heading>
      <BarGraph
        label={{
          total_accounts: 'Total Accounts',
          existing_accounts: 'Existing Accounts',
          new_accounts: 'New Accounts'
        }}
        period={period}
        data={(accountGrowth.data?.data?.entries ?? []) as any}
        isLoading={accountGrowth.isLoading}
        xDataKey="timestamp"
        yDataKeys={['existing_accounts', 'new_accounts']}
      />
      <TopList
        isLoading={accountGrowth.isLoading}
        data={accountGrowth.data?.data?.entries ?? []}
        limit={52}
        columns={[
          {
            key: 'timestamp',
            label: 'Week',
            formatter: (val) => {
              const date = new Date(val as string)
              return date.toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric' })
            }
          },
          {
            key: 'new_accounts',
            label: 'New Accounts',
            numeric: true
          },
          {
            key: 'total_accounts',
            label: 'Total Accounts',
            numeric: true
          },
          {
            key: 'growth_rate_pct',
            label: 'Growth %',
            numeric: true,
            formatter: (val) => {
              if (val === null) {
                return '--'
              }

              return `${formatNumber(val as number)}%`
            }
          }
        ]}
      />
    </Stack>
  )
}
