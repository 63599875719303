import { Divider, Heading, HStack, Link, Stack, Text } from '@chakra-ui/react'
import Rails from '@rails/ujs'
import set from 'lodash/set'
import * as React from 'react'
import { toast } from 'sonner'
import { NotificationSettings } from '../../../types/NotificationSettings'
import { LightBgCard } from '../../ui/Card'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath, useCurrentProject } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { Description } from './components/Description'

interface ShowProps {
  notification_settings: NotificationSettings
  web_pushes: Array<{ endpoint: string }>
}

export default function Show(props: ShowProps) {
  const project = useCurrentProject()
  const [settings, setSettings] = React.useState<NotificationSettings>(props.notification_settings)
  const [isLoading, setIsLoading] = React.useState({})

  const saveChange = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const update = set({}, event.target.name, event.target.checked) as { notification_settings: NotificationSettings }
      setIsLoading({
        ...isLoading,
        [event.target.name]: true
      })

      // optimistic update
      setSettings((prev) => ({
        ...prev,
        ...update.notification_settings
      }))

      const response = await fetch(projectPath('/settings/notifications'), {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': Rails.csrfToken() ?? ''
        },
        body: JSON.stringify(update)
      })

      setIsLoading({
        ...isLoading,
        [event.target.name]: false
      })

      if (response.ok) {
        const newSettings = await response.json()
        setSettings(newSettings.notification_settings)

        toast.success('Notification settings updated')
      } else {
        toast.error('Uh oh, there was an issue saving your change')

        // reset back to previous state
        setSettings(settings)
      }
    },
    [settings, setIsLoading, isLoading]
  )

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        paths={[{ title: 'Notification Preferences', path: projectPath('/settings/notifications') }]}
      />

      <SettingsHeader>
        <PageTitle>Email & Communications</PageTitle>
        <PageDescription>Choose how you want to receive notifications for {project?.name}.</PageDescription>
      </SettingsHeader>

      <Stack spacing="12">
        <Stack spacing="4">
          <Heading size="sm">Email Subscriptions</Heading>
          <LightBgCard>
            <Stack spacing="4">
              <Description
                title={
                  <HStack>
                    <Text>Koala Weekly</Text>
                  </HStack>
                }
                field="notification_settings[koala_weekly]"
                isChecked={settings.koala_weekly}
                isLoading={isLoading['notification_settings[koala_weekly]']}
                onChange={(event) => {
                  saveChange(event)
                }}
              >
                <Stack>
                  <Text>Receive a weekly email with activity from your target Accounts and Prospects.</Text>
                  <Link href={projectPath('/subscriptions')}>See it here</Link>
                </Stack>
              </Description>
            </Stack>
          </LightBgCard>
        </Stack>

        <Divider />

        <Stack spacing="4">
          <Heading size="sm">Billing & Usage</Heading>
          <LightBgCard>
            <Stack spacing="4" divider={<Divider />}>
              <Description
                title="Free Trial Alerts"
                field="notification_settings[free_trial_alerts]"
                isChecked={settings.free_trial_alerts}
                isLoading={isLoading['notification_settings[free_trial_alerts]']}
                onChange={(event) => {
                  saveChange(event)
                }}
              >
                <Text>Receive a warning when your free trial is about to end.</Text>
              </Description>

              <Description
                title="MAA Limit Alerts"
                field="notification_settings[approaching_maa_limit]"
                isChecked={settings.approaching_maa_limit}
                isLoading={isLoading['notification_settings[approaching_maa_limit]']}
                onChange={(event) => {
                  saveChange(event)
                }}
              >
                <Text>Receive a warning when your workspace is about to exceed your MAA limit.</Text>
              </Description>

              <Description
                title="MAA Limit Exceeded Alerts"
                field="notification_settings[over_maa_limit]"
                isChecked={settings.over_maa_limit}
                isLoading={isLoading['notification_settings[over_maa_limit]']}
                onChange={(event) => {
                  saveChange(event)
                }}
              >
                <Text>Receive a warning when your workspace exceeds your MAA limit.</Text>
              </Description>
            </Stack>
          </LightBgCard>
        </Stack>
      </Stack>
    </PageLayout>
  )
}
