import { useClickable } from '@chakra-ui/clickable'
import { Box, ButtonProps } from '@chakra-ui/react'
import { IconX } from '@tabler/icons-react'
import React from 'react'

interface Props {
  onClick: () => void
  colorScheme?: ButtonProps['colorScheme']
  borderRadius?: number
}

export default function RemoveButton(props: Props) {
  const clickableProps = useClickable({ onClick: props.onClick })
  const colorScheme = props.colorScheme ?? 'purple'

  return (
    <Box
      p={1.5}
      cursor="pointer"
      background={`${colorScheme}.50`}
      _hover={{ '& > svg': { opacity: 1 }, background: `${colorScheme}.100` }}
      _active={{ '& > svg': { opacity: 1 }, background: `${colorScheme}.100` }}
      transition="all 200ms cubic-bezier(0, 0, 0.2, 1)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      alignSelf="stretch"
      borderRadius={props.borderRadius}
      {...clickableProps}
    >
      <IconX size={14} opacity="0.5" />
    </Box>
  )
}
