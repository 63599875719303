import { Flex, HStack, Icon, Text } from '@chakra-ui/react'
import { IconCircleFilled } from '@tabler/icons-react'
import React, { useCallback } from 'react'

export function ChartLegend({ payload, onClick, onMouseOver, onMouseOut, colors }: any) {
  const filteredPayload = payload.filter((item: any) => item.type !== 'none')

  return (
    <Flex maxW="100%" justifyContent="flex-end" alignItems="center" fontSize="13px" gap={1} flexWrap="wrap">
      {filteredPayload.map((entry) => (
        <LegendItem
          key={`item-${entry.value}-${entry.dataKey}`}
          name={entry.value}
          dataKey={entry.dataKey}
          color={colors?.get(entry.value) || colors?.get(entry.dataKey) || entry.color}
          activeLegend={!entry.inactive}
          onClick={onClick}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        />
      ))}
    </Flex>
  )
}

export function LegendItem({ name, dataKey, activeLegend, color, onClick, onMouseOver, onMouseOut }) {
  const onClickItem = useCallback(
    (e) => {
      e.stopPropagation()
      onClick?.({ name, dataKey })
    },
    [onClick, name, dataKey]
  )

  const onMouseOverItem = useCallback(
    (_e) => {
      onMouseOver?.({ name, dataKey })
    },
    [onMouseOver, name, dataKey]
  )

  const onMouseOutItem = useCallback(
    (_e) => {
      onMouseOut?.({ name, dataKey })
    },
    [onMouseOut, name, dataKey]
  )

  return (
    <HStack
      spacing={1}
      maxWidth="200px"
      px={1.5}
      py={0.5}
      rounded="md"
      color="gray.600"
      onClick={onClickItem}
      cursor={onClick || onMouseOver ? 'pointer' : undefined}
      _hover={onClick || onMouseOver ? { bg: 'gray.100', color: 'gray.700' } : undefined}
      onMouseOver={onMouseOverItem}
      onMouseOut={onMouseOutItem}
    >
      <Icon as={IconCircleFilled} opacity={activeLegend ? 1 : 0.4} flex="none" color={color} boxSize={2.5} />
      <Text opacity={activeLegend ? 1 : 0.4} isTruncated>
        {name}
      </Text>
    </HStack>
  )
}
