import { Box, Button, Flex, HStack, Link } from '@chakra-ui/react'
import { IconLogout } from '@tabler/icons-react'
import React, { useCallback, useMemo } from 'react'
import router from '../../../lib/router'
import { Crm } from '../../../types/Crm'
import { Project } from '../../../types/Project'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { TopNavBox } from '../../ui/TopNav'
import useLocation from '../../ui/useLocation'
import { Plan } from '../billing/show'
import { mergeParams } from '../icps/types'
import { CreateProject } from './components/CreateProject'
import { IntegrationButtonProps } from './components/IntegrationButton'
import { SetupProject } from './components/SetupProject'

type Proj = Pick<Project, 'name' | 'slug' | 'domain' | 'potential_domain' | 'member_count'>

interface Props {
  crm?: Crm
  crm_connected: boolean
  sync_status?: {
    total: number
    synced: number
    percent: number
    last_synced: string
  }
  data_flowing: boolean
  skipped_crm?: boolean
  skipped_pixel?: boolean
  clearbit_connected: boolean
  slack_connected: boolean
  intent_signals_created: boolean
  catalog: Record<'salesforce' | 'hubspot' | 'slack', IntegrationButtonProps>
  project?: Pick<Project, 'id' | 'name' | 'slug'>
  members?: number
  authorized_projects?: Proj[]
  existing_projects?: Proj[]
  suggested_authorized_domain?: string
  referral_code?: string
  errors?: {
    [key: string]: string[]
  }

  selected_plan: Plan
}

export type StepType = 'crm' | 'pixel' | 'clearbit' | 'signals' | 'slack' | 'invite' | 'done'
const allSteps = ['crm', 'pixel', 'clearbit', 'signals', 'slack']

function StepItem(props: { completed?: boolean; onClick?: () => void }) {
  return (
    <Box
      w="6"
      h="5px"
      bg={props.completed ? 'purple.500' : 'gray.200'}
      rounded="full"
      onClick={props.onClick}
      cursor={props.onClick ? 'pointer' : 'default'}
    />
  )
}

function getInitialStep(props: Props): StepType {
  if (!props.skipped_crm && !props.crm_connected) {
    return 'crm'
  }

  if (!props.skipped_pixel && !props.data_flowing) {
    return 'pixel'
  }

  if (!props.clearbit_connected && !props.slack_connected && !props.intent_signals_created) {
    return 'clearbit'
  }

  if (!props.intent_signals_created) {
    return 'signals'
  }

  if (!props.slack_connected) {
    return 'slack'
  }

  // Disabling Invite step for now until we decide what to do with it
  // if (props.members === 1 && !props.authorized_projects?.length) {
  //   return 'invite'
  // }

  return 'done'
}

export default function Show(props: Props) {
  const location = useLocation()
  const params = React.useMemo(() => new URLSearchParams(location.search), [location.search])

  // const [step, setStep] = React.useState<StepType>((params.get('step') as StepType) || getInitialStep(props))
  const step = React.useMemo<StepType>(() => (params.get('step') as StepType) || getInitialStep(props), [params, props])

  const setStep = useCallback(
    (step: StepType) => {
      router.visit(mergeParams(location.pathname, { step }), { fetch: false })
    },
    [location.pathname]
  )

  const selectedStepIndex = useMemo(() => {
    if (!step) return undefined
    return allSteps.indexOf(step)
  }, [step])

  return (
    <>
      <TopNavBox
        // hideLogo={step === 'done'}
        boxProps={{ bg: 'white' }}
        links={
          <>
            <Flex position="absolute" right="50%" transform={'translateX(50%)'}>
              {selectedStepIndex !== undefined && step !== 'done' && (
                <HStack>
                  {allSteps.map((name, i) => (
                    <StepItem
                      key={i}
                      completed={i <= selectedStepIndex}
                      onClick={
                        i <= selectedStepIndex
                          ? () => {
                              setStep(name as StepType)
                            }
                          : undefined
                      }
                    />
                  ))}
                </HStack>
              )}
            </Flex>
          </>
        }
        rightContent={
          <>
            {step !== 'done' && (
              <Button
                as={Link}
                href="/logout"
                onClick={() => {
                  window.ko?.reset()
                }}
                variant="outline"
                size="xs"
                flex="none"
                rightIcon={<IconLogout size="14" />}
              >
                Log out
              </Button>
            )}
          </>
        }
      />
      <PageLayout
        size="full"
        background={'gray.100'}
        css={`
          --max-container-width: 100vw;
        `}
      >
        <PageTitle skipRendering>Welcome</PageTitle>
        {props.project?.id ? (
          <SetupProject {...props} onStepChange={setStep} step={step} />
        ) : (
          <CreateProject {...props} showLogo={!props.existing_projects?.length} />
        )}
      </PageLayout>
    </>
  )
}
