import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBoolean
} from '@chakra-ui/react'
import React, { useCallback, useRef, useState } from 'react'
import { post } from '../../../../lib/api'
import router from '../../../../lib/router'
import { AccountView } from '../../../../types/AccountView'
import { projectPath } from '../../../ui/ProjectsContext'
import { accountViewPath } from '../lib/list-paths'

function CloneViewModal({ view, onClose }: { view: AccountView; onClose: () => void }) {
  const [isLoading, setIsLoading] = useBoolean(false)
  const [name, setName] = useState<string>(`${view.name} (copy)`)
  const initialFocusRef = useRef(null)

  const handleNameChange = useCallback((e) => {
    setName(e.target.value)
  }, [])

  const handleClone = useCallback(() => {
    const clonePath = projectPath(`/views/${view.id}/clone`)
    const payload = {
      name: name.trim()
    }
    setIsLoading.on()

    post<{ account_view: AccountView }>(clonePath, { account_view: payload }).then((res) => {
      setIsLoading.off()
      router.visit(accountViewPath(res.account_view))
      onClose()
    })
  }, [view, name, onClose, setIsLoading])

  return (
    <Modal isOpen initialFocusRef={initialFocusRef} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Clone List
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Text mb="16px">
            You are cloning the list{' '}
            <Text as="span" fontWeight={'bold'}>
              {view.name}
            </Text>
            . You can edit filters after cloning the list.
          </Text>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input ref={initialFocusRef} placeholder="Name" value={name} onChange={handleNameChange} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" onClick={onClose} variant="outline" mr={3}>
            Cancel
          </Button>
          <Button size="sm" isLoading={isLoading} colorScheme="purple" onClick={handleClone}>
            Clone
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CloneViewModal
