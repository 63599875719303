import { motion } from 'framer-motion'
import move from 'lodash-move'
import React from 'react'

const CARD_OFFSET = 22
const SCALE_FACTOR = 0.05

interface Props {
  items: any[]
  renderItem: (item: any, index: number) => React.ReactNode
  draggable?: boolean
  height: string
  itemHeight: string
}

export const CardStack: React.FC<Props> = (props) => {
  const draggable = props.draggable ?? true
  const [cards, setCards] = React.useState(props.items)
  const moveToEnd = (from) => {
    setCards(move(cards, from, cards.length - 1))
  }

  const moveToFront = (from) => {
    setCards(move(cards, from, 0))
  }

  return (
    <ul
      style={{
        position: 'relative',
        width: '100%',
        height: props.height
      }}
    >
      {cards.map((item, index) => {
        const canDrag = draggable && index === 0

        return (
          <motion.li
            key={JSON.stringify(item)}
            onClick={() => moveToFront(index)}
            style={{
              position: 'absolute',
              width: '100%',
              height: props.itemHeight,
              borderRadius: '8px',
              transformOrigin: 'top center',
              listStyle: 'none',
              cursor: canDrag ? 'grab' : 'pointer'
            }}
            animate={{
              top: index * CARD_OFFSET,
              scale: 1 - index * SCALE_FACTOR,
              zIndex: cards.length - index
            }}
            drag={canDrag ? 'y' : false}
            dragConstraints={{
              top: 0,
              bottom: 0
            }}
            onDragEnd={() => moveToEnd(index)}
          >
            {props.renderItem(item, index)}
          </motion.li>
        )
      })}
    </ul>
  )
}
