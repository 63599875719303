import { Button, Flex, Text, useTheme } from '@chakra-ui/react'
import React from 'react'
import { AuthenticityToken } from './AuthenticityToken'
import { projectPath } from './ProjectsContext'

export function ScheduledForDeletionBanner() {
  const [submitting, setSubmitting] = React.useState(false)
  const theme = useTheme()
  const red1 = theme.colors.red[600]
  const red2 = theme.colors.red[500]

  return (
    <Flex
      as="form"
      alignItems="center"
      justifyContent="center"
      color="white"
      textAlign="center"
      background={`repeating-linear-gradient(-45deg, ${red1}, ${red1} 10px, ${red2} 10px, ${red2} 20px)`}
      py={3}
      px={4}
      gap={2}
      action={projectPath('/undestroy')}
      method="POST"
      onSubmit={() => setSubmitting(true)}
    >
      <input type="hidden" name="_method" value="PUT" />
      <AuthenticityToken />
      <Text fontSize="sm" fontWeight="medium" textShadow="0 1px 5px rgba(0, 0, 0, 0.5)">
        Your workspace is slated for deletion and will begin deleting data shortly.
      </Text>
      <Button
        type="submit"
        size="sm"
        variant="unstyled"
        display="inline-flex"
        justifyContent="flex-start"
        alignItems="center"
        isLoading={submitting}
      >
        Cancel deletion?
      </Button>
    </Flex>
  )
}
