import {
  Box,
  Button,
  Collapse,
  HStack,
  Icon,
  IconButton,
  Link,
  Stack,
  Table,
  TableContainer,
  Td,
  Text,
  Tooltip,
  Tr
} from '@chakra-ui/react'
import {
  IconArrowRight,
  IconChevronDown,
  IconChevronUp,
  IconExclamationCircle,
  IconExternalLink,
  IconInfoCircle,
  IconMapPin
} from '@tabler/icons-react'
import { uniqBy } from 'lodash'
import React from 'react'
import { Account } from '../../../../../types/Account'
import { Company, ProfileRecord } from '../../../../../types/Profile'
import { useData } from '../../../../data/use-data'
import CompanyAvatar from '../../../../ui/CompanyAvatar'
import { profilePath } from '../../../profiles/lib/path'
import { accountPath } from '../../lib/account-path'

type AssociationLog = {
  profile: Pick<ProfileRecord, 'id' | 'name' | 'email' | 'simple_location'> & {
    company: Company
  }
  created_at: Date
  ip: string
  match_score: number
  match_type: string
}

export function GhostProfiles(props: { account: Account }) {
  const { data } = useData<{ association_logs: AssociationLog[] }>(
    props.account.id,
    accountPath(props.account, '/ghost-profiles')
  )

  const [showDetails, setShowDetails] = React.useState(false)

  return (
    <Stack fontSize={'sm'} bg="orange.50" rounded="sm" py="4" p="6" spacing="6">
      <Stack spacing="2" px="0">
        <Box fontSize={'xs'} flex="1">
          <HStack w="100%" justifyContent={'space-between'} alignItems="flex-start">
            <HStack>
              <IconExclamationCircle color="orange" />
              <Text>
                An <a>anonymous visitor</a> was initially identified as from{' '}
                <b>{props.account.company.name ?? props.account.company.domain}</b>, but recent information suggests
                their IP address no longer matches this account, which is why they no longer appear here.
              </Text>
            </HStack>

            <IconButton
              colorScheme={'orange'}
              size="sm"
              variant={'ghost'}
              aria-label="Show details"
              icon={showDetails ? <IconChevronUp size="15" /> : <IconChevronDown size="15" />}
              onClick={() => setShowDetails(!showDetails)}
            />
          </HStack>

          <Collapse in={showDetails}>
            <Stack pt="4">
              {(data?.association_logs.length ?? 0) > 0 && (
                <TableContainer rounded="md" shadow="sm" borderWidth={'1px'}>
                  <Table size="sm" bg="white" border="none">
                    {uniqBy(data?.association_logs, (l) => l.profile.id).map((log) => (
                      <Tr key={log.profile.id} border="none">
                        <Td border="none" pt="3">
                          <Button
                            size="xs"
                            as={Link}
                            leftIcon={<IconExternalLink size="12" />}
                            key={log.profile.id}
                            isExternal
                            href={profilePath(log.profile)}
                          >
                            {log.profile.name ?? log.profile.email}
                          </Button>
                        </Td>
                        <Td fontSize="xs" border="none">
                          <HStack spacing="1">
                            <IconMapPin size="12" color={`gray`} />
                            <Text>{log.profile.simple_location}</Text>
                          </HStack>
                        </Td>
                        <Td fontSize="xs" border="none">
                          {log.match_type === 'ip' && (
                            <Tooltip label="Company association happened via Clearbit Reveal match.">
                              <HStack spacing="1">
                                <Text>Clearbit Reveal</Text>
                                <IconInfoCircle size="12" />
                              </HStack>
                            </Tooltip>
                          )}
                          {log.match_type === 'email' && 'Direct Match'}
                        </Td>
                        <Td fontSize="xs" border="none">
                          <HStack spacing="1">
                            <Text>Re-matched to</Text>
                            <Icon as={IconArrowRight} size="14" color="purple.500" />
                          </HStack>
                        </Td>
                        <Td fontSize="xs" border="none">
                          <Button
                            size="xs"
                            as={Link}
                            isExternal
                            leftIcon={
                              <CompanyAvatar
                                size="14px"
                                name={log.profile.company.name}
                                domain={log.profile.company.domain}
                              />
                            }
                          >
                            {log.profile.company?.name ?? log.profile.company?.domain ?? 'Unknown'}
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Table>
                </TableContainer>
              )}

              <Text fontSize={'xx-small'} pt="2">
                Changes were based on an IP match from Clearbit Reveal that was later updated to another company. This
                happens most frequently when a visitor switches locations (eg., their laptop moved from {name} to a
                Starbucks), but also can happen when Clearbit Reveal updates its IP mappings.
              </Text>
            </Stack>
          </Collapse>
        </Box>
      </Stack>
    </Stack>
  )
}
