import { Box, Flex, Heading, HStack, VStack } from '@chakra-ui/react'
import { IconUsers } from '@tabler/icons-react'
import React from 'react'
import { Apps } from '../../../types/App'
import { PageMeta } from '../../../types/PageMeta'
import { useUrlFilters } from '../../data/use-url-filters'
import { TopBarContent } from '../../ui/TopBarContext'
import PageTitle, { SmallPageHeading } from '../../ui/PageTitle'
import { FilterPreview } from '../accounts/components/FilterPreview'
import { SaveAccountView } from '../account_views/components/AccountViewForm'
import { ListContainer } from '../lists/components/ListContainer'
import { Session } from '../sessions'
import { VisitorFeed } from '../sessions/components/Feed'
import { VisitorTabs } from './components/visitor-tabs'

interface Props {
  apps: Apps
  materialized_sessions: Session[]
  page_meta: PageMeta
  permissions?: Record<'can_edit' | 'can_create' | 'can_destroy', boolean>
}

export default function Index(props: Props) {
  const facets = useUrlFilters({
    initialRange: 'week',
    facetCloudPath: '/profiles/facet-cloud'
  })

  const isDirty = !facets.onlyDefaults

  return (
    <ListContainer bg="gray.50">
      <TopBarContent onlyNewNav>
        <Flex alignItems="center">
          <SmallPageHeading size="xs" fontWeight="medium">
            People
          </SmallPageHeading>
        </Flex>
      </TopBarContent>

      <VStack spacing={3} alignItems="stretch">
        <Flex
          w="100%"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          flexWrap={['wrap', 'nowrap']}
          direction={['column-reverse', 'row']}
        >
          <PageTitle skipRendering>People</PageTitle>
          <HStack flex="1 1 100%" width="100%" justifyContent="space-between" spacing={3}>
            <HStack spacing={3}>
              <IconUsers size={20} />
              <Heading display="inline-flex" gap={4} alignItems="baseline" size="md">
                People
              </Heading>
            </HStack>
          </HStack>

          <VisitorTabs />
        </Flex>

        <Flex justifyContent="space-between" gap={4}>
          <FilterPreview
            {...facets}
            kind="profile"
            excludedKeys={['visitor_stats.identified.month', 'visitor_stats.visitors.month']}
            apps={props.apps}
            range={undefined}
          />

          {isDirty && (
            <Box marginLeft="auto">
              <SaveAccountView type="profile" isFiltering={facets.isFiltering} permissions={props.permissions} />
            </Box>
          )}
        </Flex>

        <VisitorFeed {...props} />
      </VStack>
    </ListContainer>
  )
}
