import { Box } from '@chakra-ui/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import dayjs from '../../lib/dayjs'

const calendarOpts = {
  sameDay: 'h:mm A [today]',
  lastDay: '[Yesterday]',
  lastWeek: 'dddd, MMM D',
  sameElse: 'MM/DD/YYYY'
}

interface TimeAgoProps {
  time?: null | Date | number | string
  fallback?: string
  mode?: 'calendar' | 'relative' | 'full' | 'custom'
  format?: string
  canToggle?: boolean
  calendarOpts?: Partial<typeof calendarOpts>
}

const getMode = (props: TimeAgoProps) => props.mode || (props.format ? 'custom' : 'relative')

export function TimeAgo(props: TimeAgoProps) {
  const datetime = React.useMemo(() => {
    if (!props.time) {
      return null
    }

    try {
      return dayjs.utc(props.time)
    } catch (e) {
      return null
    }
  }, [props.time])

  const iso = React.useMemo(() => {
    if (!datetime) {
      return null
    }

    try {
      return datetime.toISOString()
    } catch (e) {
      return null
    }
  }, [datetime])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const modeFromProps = useMemo(() => getMode(props), [props.mode, props.format])
  const [mode, setMode] = useState(getMode(props))

  const toggleMode = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()
      e.stopPropagation()

      const og = modeFromProps
      const opposite = og === 'relative' ? 'full' : 'relative'

      setMode((prev) => {
        if (prev === og) {
          return opposite as NonNullable<TimeAgoProps['mode']>
        } else {
          return og as NonNullable<TimeAgoProps['mode']>
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modeFromProps]
  )

  useEffect(() => {
    setMode(modeFromProps)
  }, [modeFromProps])

  if (!datetime || !datetime.isValid || !iso) {
    return <span>{props.fallback || null}</span>
  }

  const fullTimestamp = datetime.local().format('ddd, MMM D, YYYY h:mm A z')
  const canToggle = props.canToggle !== false

  if (mode === 'full') {
    return (
      <Box
        as="time"
        cursor={canToggle ? 'pointer' : undefined}
        dateTime={iso}
        onClick={canToggle ? toggleMode : undefined}
      >
        {fullTimestamp}
      </Box>
    )
  }

  return (
    <Box
      as="time"
      cursor={canToggle ? 'pointer' : undefined}
      title={fullTimestamp}
      dateTime={iso}
      onClick={canToggle ? toggleMode : undefined}
    >
      {mode === 'custom' && props.format
        ? datetime.local().format(props.format)
        : mode === 'calendar'
        ? datetime.local().calendar(null, { ...calendarOpts, ...props.calendarOpts })
        : datetime.fromNow()}
    </Box>
  )
}
