import { Button, Heading, Link, Stack, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { projectPath } from '../../../ui/ProjectsContext'
import { konfetti } from './konfetti'

interface Props {}

export function DoneForm(_props: Props) {
  useEffect(() => {
    const timeout = setTimeout(() => konfetti(), 250)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <Stack p="8" spacing="8" justifyContent={'center'}>
      <Heading size="lg" textAlign="center">
        You're all set!
      </Heading>
      <Text fontSize="sm" color="gray.600" textAlign="center">
        Nice work! Now Koala can start analyzing your CRM data and real-time visitor intent. We'll start overlaying
        valuable insights for your existing customers and prospects.
      </Text>
      <Button
        as={Link}
        href={projectPath('/accounts?range=all')}
        flex="none"
        size="lg"
        fontSize="md"
        width="full"
        colorScheme="purple"
      >
        Open Koala
      </Button>
    </Stack>
  )
}
