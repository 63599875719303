import { Heading, HeadingProps } from '@chakra-ui/react'
import * as React from 'react'
import useTitle from './useTitle'

function extractText(tree: React.ReactNode | React.ReactNode[]): string {
  if (typeof tree === 'string') {
    return tree
  }

  return React.Children.toArray(tree).map(extractText).join('')
}

export default function PageTitle(props: React.PropsWithChildren<HeadingProps> & { skipRendering?: boolean }) {
  const title = extractText(props.children)
  useTitle(`${title} | Koala`)

  if (props.skipRendering) {
    return null
  }

  return <Heading size="lg" as="h1" fontWeight="semibold" {...props} />
}

export function SmallPageHeading(props: HeadingProps) {
  return <Heading size="sm" as="h1" lineHeight={1.42} fontWeight="semibold" {...props} />
}
