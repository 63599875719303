import { Flex, Heading, HStack, Icon, LinkBox, LinkOverlay, Skeleton, Stack, Text } from '@chakra-ui/react'
import { IconArrowRight } from '@tabler/icons-react'
import React from 'react'
import type { User } from '../../../../types/Invite'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { MiddotDivider } from '../../../ui/Middot'
import { useCurrentUser } from '../../../ui/UserContext'
import { accountPath } from '../lib/account-path'

type Maybe<T> = T | null

interface AccountSummaryProps {
  name?: Maybe<string>
  logo?: Maybe<string>
  domain?: Maybe<string>
  assignee?: Maybe<User>
  isLoading?: boolean
}

export function AccountSummary(props: AccountSummaryProps) {
  const user = useCurrentUser()

  return (
    <LinkBox>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        position="relative"
        role="group"
        mx={-3}
        my={-2}
        px={3}
        py={3}
        rounded="lg"
        _hover={{ bg: 'purple.50' }}
      >
        <HStack spacing="3">
          <CompanyAvatar size="md" name={props.name} domain={props.domain} />
          <LinkOverlay href={accountPath({ company: { domain: props.domain! } })}>
            <Stack spacing=".5">
              {props.name && (
                <Skeleton isLoaded={!props.isLoading}>
                  <Heading size="sm" fontWeight="semibold">
                    {props.name}
                  </Heading>
                </Skeleton>
              )}
              <Skeleton isLoaded={!props.isLoading}>
                <HStack fontSize="sm" spacing={2} divider={<MiddotDivider />}>
                  {props.domain && (
                    <Text display="inline-flex" alignItems="center" gap={1} color="gray.500" fontWeight="medium">
                      {props.domain}
                    </Text>
                  )}
                  {props.assignee && (
                    <Text color="gray.600">
                      Claimed by{' '}
                      <Text as="span" fontWeight="medium" color="gray.700">
                        {user?.id === props.assignee.id ? 'You' : props.assignee.name || props.assignee.email}
                      </Text>
                    </Text>
                  )}
                </HStack>
              </Skeleton>
            </Stack>
          </LinkOverlay>
        </HStack>

        <Icon as={IconArrowRight} boxSize={5} color="gray.400" _groupHover={{ color: 'purple.500' }} />
      </Flex>
    </LinkBox>
  )
}
