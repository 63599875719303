import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Spinner,
  Text
} from '@chakra-ui/react'
import { IconX } from '@tabler/icons-react'
import orderBy from 'lodash/orderBy'
import React, { RefObject, useCallback, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { useProspectFieldValues } from '../../../data/use-field-values'
import { SearchIcon } from '../../../ui/icons/SearchIcon'
import useUpdateEffect from '../../../ui/useUpdateEffect'
import { VirtualList } from '../../../ui/VirtualList'
import { humanize } from '../../accounts/facets/filter-cloud'

function getOptionLabel(key: string) {
  switch (key) {
    case 'cxo':
      return 'C-Level'
    case 'vp':
      return 'VP'
    default:
      return humanize(key, false)
  }
}

export type Operator = 'must' | 'should' | 'must_not'

interface CheckboxFilterProps {
  facet: string
  domain?: string
  operator?: Operator
  selected: string[]
  initialFocusRef: RefObject<HTMLInputElement>
  hideCounts?: boolean
  onChange: (selected: string[], operator: Operator) => void
  isCompaniesFilterPopover?: boolean
}

export function CheckboxFilter(props: CheckboxFilterProps) {
  const { facet, selected, operator, hideCounts, isCompaniesFilterPopover } = props
  const [localOperator, setLocalOperator] = useState<Operator>(operator ?? 'must')
  const [localState, setLocalState] = useState<string[]>(selected ?? [])
  const [searchQuery, setSearchQuery] = useState('')
  const [debouncedSearch] = useDebounce(searchQuery, 300)

  useUpdateEffect(() => {
    setLocalState(selected ?? [])
    setLocalOperator(operator ?? 'must')
    setSearchQuery('')
  }, [facet, selected, operator])

  const onSearchQueryChange = useCallback((e) => {
    setSearchQuery(e.target.value)
  }, [])

  const resetSearchQuery = useCallback(() => {
    setSearchQuery('')
  }, [])

  const pathType = isCompaniesFilterPopover ? 'companies' : 'prospect'

  const aggs = useProspectFieldValues(props.facet, pathType, debouncedSearch, props.domain)

  const buckets = useMemo(() => {
    const filterCandidates = debouncedSearch
      .trim()
      .toLowerCase()
      .split(/[,\n\s]/)
      .map((tag) => tag.trim())
      .filter(Boolean)

    const initialBuckets = aggs.data?.values ?? []

    if (filterCandidates.length === 0) {
      return initialBuckets
    }

    return initialBuckets.filter((bucket) => {
      return filterCandidates.some((tag) => bucket.key.toLowerCase().includes(tag))
    })
  }, [debouncedSearch, aggs.data?.values])

  const orderedBuckets = useMemo(() => {
    return orderBy(buckets, (b) => props.selected.includes(b.key), ['desc'])
  }, [buckets, props.selected])

  return (
    <Flex flex="1 1 auto" minHeight="100px" flexDirection="column" bg="white" position="relative" overflow="auto">
      <Flex flexDirection="column" gap={3} paddingTop={4}>
        <Box paddingX={4}>
          <Select
            size="sm"
            rounded="md"
            value={localOperator}
            onChange={(e) => {
              setLocalOperator(e.target.value as Operator)
            }}
          >
            <option value="must">is any of</option>
            <option value="must_not">is not</option>
          </Select>
        </Box>

        {(orderedBuckets.length > 12 || searchQuery || debouncedSearch) && (
          <Box paddingX={4}>
            <InputGroup size="sm">
              <InputLeftElement>
                <SearchIcon color="gray.400" boxSize={4} />
              </InputLeftElement>

              <Input
                ref={props.initialFocusRef}
                value={searchQuery}
                onChange={onSearchQueryChange}
                autoComplete="off"
              />

              {searchQuery && resetSearchQuery && (
                <InputRightElement>
                  <IconButton
                    size="xs"
                    aria-label="Clear search"
                    variant="ghost"
                    color="gray.400"
                    _hover={{ color: 'gray.600' }}
                    onClick={resetSearchQuery}
                    icon={<IconX size={16} />}
                  />
                </InputRightElement>
              )}
            </InputGroup>
          </Box>
        )}

        <Box position="relative" flex="1 1 auto" minHeight="120px" display="flex" flexDirection="column">
          {aggs.isLoading && <Spinner size="xs" color="purple.500" thickness="1.5px" mx={4} />}

          <VirtualList
            items={orderedBuckets}
            minH="120px"
            maxH="250px"
            estimateSize={() => 26}
            containerStyles={{
              paddingLeft: 4,
              paddingRight: 4,
              paddingBottom: '0.5em',
              paddingTop: '0.2em'
            }}
            showOverflowFade
            renderItem={(bucket) => (
              <Checkbox
                key={bucket.key}
                colorScheme="purple"
                isChecked={localState.includes(bucket.key)}
                onChange={(e) => {
                  if (e.target.checked) {
                    return setLocalState([...localState, bucket.key])
                  }

                  setLocalState(localState.filter((key) => key !== bucket.key))
                }}
                py={1}
                lineHeight="18px"
              >
                <HStack fontSize="sm" fontWeight="normal" spacing="1.5">
                  <Text>{getOptionLabel(bucket.key)}</Text>
                  {bucket.doc_count > 0 && !hideCounts && (
                    <Text color="purple.500">{bucket.doc_count.toLocaleString()}</Text>
                  )}
                </HStack>
              </Checkbox>
            )}
          />
        </Box>
      </Flex>

      <HStack
        flex="none"
        justifyContent="flex-end"
        spacing={3}
        paddingX={3}
        paddingY={2.5}
        borderTop="1px solid"
        borderColor="gray.200"
      >
        {localState.length > 0 && (
          <Button size="sm" variant="link" colorScheme="purple" onClick={() => setLocalState([])}>
            Clear Filters
          </Button>
        )}

        <Button
          size="sm"
          colorScheme="purple"
          onClick={() => {
            props.onChange(localState, localOperator)
          }}
        >
          Apply Filters {localState.length > 0 ? `(${localState.length})` : ''}
        </Button>
      </HStack>
    </Flex>
  )
}
