import { Image, ImageProps } from '@chakra-ui/react'
import React, { useState } from 'react'

interface Props {
  countryCode?: string
}

export function flagCdnUrl(countryCode?: string | null) {
  if (countryCode?.length === 2) {
    return `https://hatscripts.github.io/circle-flags/flags/${countryCode.toLowerCase()}.svg`
  }
}

// Image component that takes a 2-letter country code and
// renders a circular flag svg from `circle-flags` like
// https://hatscripts.github.io/circle-flags/flags/us.svg
export function CircleFlag({ countryCode, ...props }: Props & ImageProps) {
  const [error, setError] = useState(false)
  const src = flagCdnUrl(countryCode)

  if (error || !src) {
    return null
  }

  return <Image boxSize={5} src={src} onError={() => setError(true)} {...props} />
}
