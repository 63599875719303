import { useQuery } from 'react-query'
import { concurrentGET } from '../../lib/api'
import { Apps } from '../../types/App'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Data {
  apps?: Apps
}

export function useApps() {
  const project = useCurrentProject()
  const basePath = '/apps.json'

  const path = projectPath(basePath)

  return useQuery<Data>(['apps', { projectId: project?.id }], () => concurrentGET<{ apps: Apps }>(path), {
    enabled: Boolean(project?.id)
  })
}
