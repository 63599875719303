import { Icon } from '@chakra-ui/react'
import { IconSparkles } from '@tabler/icons-react'
import React from 'react'
import dayjs from '../../../../lib/dayjs'
import { BubbleTag } from '../../../ui/BubbleTag'
import { TagGroup } from '../../../ui/TagGroup'
import { HighlightedAccount } from '../../icps/types'
import { getTrend, trendColor, TrendIcon } from './Intent/IntentSummary'

interface Props {
  account: HighlightedAccount
  showAllTrends?: boolean
}

export function AccountTrends({ account, showAllTrends }: Props) {
  const trend = getTrend(account.intent, showAllTrends)
  const inPastWeek = account.first_seen_at && dayjs(account.first_seen_at).isAfter(dayjs().subtract(8, 'days'))

  return (
    <TagGroup limit={2} minWidth="80px">
      {inPastWeek ? (
        <BubbleTag size="sm" variant="outline">
          <Icon as={IconSparkles} marginRight={0.5} />
          New
        </BubbleTag>
      ) : trend ? (
        <BubbleTag
          size="sm"
          variant={trend === 'New' ? 'outline' : 'subtle'}
          colorScheme={trendColor[trend.toLowerCase()]}
        >
          <TrendIcon trend={trend} boxSize={3.5} />
          {trend}
        </BubbleTag>
      ) : null}
    </TagGroup>
  )
}
