export interface ProjectActions {
  [appModule: string]: AppActions
}

export interface AppActions {
  [action: string]: AppAction
}

export interface AppAction {
  description: string
  name: string
  enabled: boolean
  tooltip?: string
  configurable: boolean
  hotkey: string
}

export function isActionEnabled(appActions: AppActions | undefined, action: string, defaultValue = true): boolean {
  if (!appActions) {
    return defaultValue
  }
  if (!appActions[action]) {
    return defaultValue
  }
  return appActions[action].enabled
}

export function isActionDisabled(appActions: AppActions, action: string, defaultValue = false): boolean {
  return !isActionEnabled(appActions, action, defaultValue)
}
