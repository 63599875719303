import React from 'react'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { AdminBreadcrumb } from '..'
import { AccountSelector, PartialAccount } from '../../../ui/AccountSelector'
import { OmnisearchProject } from '../../../data/use-omnisearch'
import {
  HStack,
  Stack,
  Button,
  Textarea,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Select,
  Box,
  Text,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Divider,
  Icon,
  useDisclosure
} from '@chakra-ui/react'
import { Toggle } from '@app/components/pages/accounts/components/Toggle'
import { concurrentGET, post } from '@app/lib/api'
import { JSONTree } from '@app/components/ui/json-tree'
import { toast } from 'sonner'
import { AISummaryContent } from '@app/components/ui/AISummaryButton'
import * as Rails from '@rails/ujs'

import { fetchEventSource } from '@microsoft/fetch-event-source'
import { Project } from '@app/types/Project'
import CompanyAvatar from '@app/components/ui/CompanyAvatar'
import { IconCircleDashed, IconLineDashed } from '@tabler/icons-react'
import { ComboboxWithSearch } from '@app/components/ui/ComboboxWithSearch'

interface Props {
  projects: Project[]
}
export default function Show(props: Props) {
  const [selectedAccount, setSelectedAccount] = React.useState<PartialAccount | null>(null)
  const [selectedProject, setSelectedProject] = React.useState<Project | null>(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isPreviewing, setIsPreviewing] = React.useState(false)
  const [model, setModel] = React.useState('gpt-4o')
  const [temperature, setTemperature] = React.useState('0.5')
  const [context, setContext] = React.useState<any>(null)
  const [prompt, setPrompt] = React.useState('')
  const [messages, setMessages] = React.useState<string[]>([])
  const [lookback, setLookback] = React.useState('30')
  const controllerRef = React.useRef<AbortController>()

  const startPreview = async () => {
    setIsPreviewing(true)
    if (!selectedAccount) {
      toast.error('Please select an account')
      return
    }

    controllerRef.current?.abort()
    controllerRef.current = new AbortController()

    setIsLoading(true)

    fetchEventSource('/admin/account-summary-playground/preview', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': Rails.csrfToken() ?? ''
      },
      body: new URLSearchParams({
        account_id: selectedAccount.id,
        model: model,
        lookback: lookback,
        temperature: temperature,
        prompt: prompt
      }),
      openWhenHidden: true,
      signal: controllerRef.current.signal,
      onopen: async (res) => {
        setMessages([])
        if (res.ok && res.status === 200) {
          console.log('Connection made ', res)
        } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
          console.log('Client side error ', res)
        }
      },
      onmessage: (e) => {
        setMessages((prev) => [...prev, e.data])
      },
      onerror: () => {
        setIsLoading(false)
        setIsPreviewing(false)
        console.log('Error occurred')
      },
      onclose: () => {
        setIsLoading(false)
        setIsPreviewing(false)
        console.log('Connection closed')
      }
    })
  }

  const loadContext = async () => {
    controllerRef.current?.abort()
    if (!selectedProject?.slug || !selectedAccount?.domain) { return }
    setIsLoading(true)
    await concurrentGET<any>(`/projects/${selectedProject.slug}/accounts/${selectedAccount?.domain}/ai-context`)
      .then((response) => {
        setContext(response)
      })
      .catch((_) => {
        toast.error('Failed to load context')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <PageLayout size="md">
      <HStack>
        <PageTitle flex="1">Account Summarization Playground</PageTitle>
      </HStack>

      <AdminBreadcrumb
        paths={[{ path: '/admin/account-summary-playground', title: 'Account Summarization Playground' }]}
      />
      <HStack alignItems="top" spacing={6}>
        <Stack flex="1">
          <Heading size="sm" fontWeight={'semibold'}>
            <HStack>
              <Text flex="1">Prompt</Text>
              <PromptLoader onPromptSelect={(prompt) => prompt && setPrompt(prompt)} />
              {prompt && <PromptLogger prompt={prompt} />}
            </HStack>
          </Heading>
          <Textarea h="500px" value={prompt} onChange={(e) => setPrompt(e.target.value)} />
          <HStack>
            <Button
              px="4"
              colorScheme={'purple'}
              type="submit"
              onClick={startPreview}
              isLoading={isLoading}
              disabled={!selectedAccount || isLoading}
            >
              Preview
            </Button>
          </HStack>

          {(isPreviewing || messages.length > 0) && (
            <Box border="1px solid #eee" borderRadius="md">
              <AISummaryContent messages={messages} isOpen />
            </Box>
          )}
        </Stack>
        <Stack w="300px">
          <Heading size="sm" fontWeight={'semibold'}>
            Context
          </Heading>
          <Stack>
            <ProjectSelector
              projects={props.projects}
              selectedProject={selectedProject}
              onChange={(project) => {
                setSelectedProject(project)
                setSelectedAccount(null)
              }}
            />
            <HStack>
              {selectedProject && (
                <>
                  <AccountSelector
                    selectedAccount={selectedAccount}
                    onChange={(acc) => setSelectedAccount(acc)}
                    showClearButton
                    selectedProject={selectedProject}
                  />
                  <Button
                    px="4"
                    colorScheme={'purple'}
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault()
                      loadContext()
                    }}
                    isLoading={isLoading}
                    disabled={!selectedAccount || isLoading}
                  >
                    Load
                  </Button>
                </>
              )}
            </HStack>
          </Stack>
          <Toggle title={<Heading size="xs">Model settings</Heading>}>
            <Stack p="4" bg="gray.50" rounded="lg">
              <FormControl>
                <FormLabel>Model</FormLabel>
                <Select size="xs" bg="white" rounded="lg" value={model} onChange={(e) => setModel(e.target.value)}>
                  <option value="gpt-3.5-turbo-16k">GPT 3.5 Turbo 16k</option>
                  <option value="gpt-4o">GPT 4o</option>
                </Select>
              </FormControl>

              <HStack>
                <FormControl>
                  <FormLabel>Lookback</FormLabel>
                  <Input
                    value={lookback}
                    onChange={(e) => setLookback(e.target.value)}
                    size="xs"
                    bg="white"
                    rounded="lg"
                    type="number"
                    min={4}
                    max={36}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Temperature</FormLabel>
                  <Input
                    value={temperature}
                    onChange={(e) => setTemperature(e.target.value)}
                    size="xs"
                    bg="white"
                    rounded="lg"
                  />
                </FormControl>
              </HStack>
            </Stack>
          </Toggle>
          <Stack pt="4">
            {context && (
              <>
                <Heading size="sm">Data</Heading>
                <JSONTree data={context} />
              </>
            )}
          </Stack>
        </Stack>
      </HStack>
    </PageLayout>
  )
}

interface ProjectSelectorProps {
  projects: Project[]
  selectedProject: Project | null
  onChange: (project: Project | null) => void
}

export function ProjectSelector(props: ProjectSelectorProps) {
  const [inputValue, setInputValue] = React.useState<string | undefined>('koala')

  const projects = React.useMemo(() => {
    if (!inputValue) return props.projects
    return props.projects.filter((project) => project.name?.toLowerCase().includes(inputValue) || project.domain?.toLowerCase().includes(inputValue))
  }, [inputValue, props.projects])

  return (
    <ComboboxWithSearch
      items={projects}
      selectedItem={props.selectedProject || null}
      onChange={props.onChange}
      onInputValueChange={setInputValue}
      filterItem={(a, val) => a.name?.toLowerCase().includes(val) || a.domain?.toLowerCase().includes(val) || false}
      itemToString={(item) => item?.name ?? item?.domain ?? ''}
      itemRenderer={ProjectRenderer}
      selectButtonRenderer={ProjectRenderer}
    />
  )
}

interface ProjectRendererProps {
  item: Project | null
  isSelected?: boolean
}

const none: OmnisearchProject = {
  id: '',
  domain: 'No one',
  name: 'No one',
  slug: 'no-one'
}

function ProjectRenderer(props: ProjectRendererProps) {
  const project = props.item

  if (!project) {
    return (
      <HStack spacing={2.5}>
        <Icon as={IconCircleDashed} color="gray.300" boxSize={6} />
        <Text fontSize="sm" fontWeight="medium" color="gray.400">
          Select a project
        </Text>
      </HStack>
    )
  }

  return (
    <HStack spacing={2.5}>
      <CompanyAvatar
        size='sm'
        domain={project.domain}
        rounded="full"
        {...(project === none
          ? {
            backgroundColor: 'transparent',
            fallbackIcon: <IconLineDashed size={20} />
          }
          : {
            name: project.name || project.domain
          })}
      />
      <Box>
        <Text fontSize="sm" fontWeight="medium">
          {project.name || project.domain || 'Anonymous'}
        </Text>
      </Box>
    </HStack>
  )
}
interface PromptDbResponse {
  prompts: Record<string, string>[]
}

export function PromptLoader({ onPromptSelect }: { onPromptSelect: (prompt: string | undefined) => void }) {
  const [isLoading, setIsLoading] = React.useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [prompts, setPrompts] = React.useState<Record<string, string>[]>([])
  const [selectedPrompt, setSelectedPrompt] = React.useState<string | null>(null)

  const currentPrompt = React.useMemo(() => {
    return prompts.find((p) => p.id === selectedPrompt)
  }, [prompts, selectedPrompt])

  const loadPrompts = React.useCallback(async () => {
    onOpen()
    setIsLoading(true)
    await concurrentGET<PromptDbResponse>('/admin/prompts-db')
      .then((response) => {
        setPrompts(response.prompts)
      })
      .catch((_) => {
        toast.error('Failed to load prompts')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [onOpen, onClose, isOpen])

  return (
    <>
      <Link color="purple.400" fontSize="sm" onClick={loadPrompts}>Load prompt</Link>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Load prompt</ModalHeader>
          <ModalBody>
            <Stack>
              <FormControl id="action">
                <FormLabel>Select a prompt</FormLabel>
                <Select disabled={isLoading} onChange={(e) => setSelectedPrompt(e.target.value)}>
                  <option value="">Select a prompt</option>
                  {prompts.map((prompt) => (
                    <option key={prompt.id} value={prompt.id}>
                      {prompt.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" onClick={onClose} variant="outline" mr={3}>
              Cancel
            </Button>
            <Button
              size="sm"
              colorScheme="purple"
              onClick={() => {
                onPromptSelect(currentPrompt?.prompt)
                onClose()
              }}>
              Load
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export function PromptLogger({ prompt }: { prompt: string }) {
  const [isLoading, setIsLoading] = React.useState(false)
  const [name, setName] = React.useState('')
  const [description, setDescription] = React.useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()

  const savePrompt = React.useCallback(async () => {
    setIsLoading(true)
    await post('/admin/prompts-db', {
      prompts_db: {
        name: name,
        description: description,
        prompt: prompt
      }
    }).then((_) => {
      toast.success('Prompt saved')
      onClose()
    }).catch(() => {
      toast.error('Failed to save prompt')
    }).finally(() => {
      setIsLoading(false)
    })
  }, [name, description, isOpen])

  return (
    <>
      <Divider orientation='vertical' />
      <Link color="purple.400" fontSize="sm" onClick={onOpen}>
        Save prompt
      </Link>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Save prompt</ModalHeader>
          <ModalBody>
            <Stack>
              <FormControl id="name">
                <FormLabel>Name</FormLabel>
                <Input autoComplete="off" placeholder="koala" onChange={(e) => setName(e.target.value)} />
              </FormControl>
              <FormControl id="slug">
                <FormLabel>Description</FormLabel>
                <Input autoComplete="off" placeholder="koala" onChange={(e) => setDescription(e.target.value)} />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" onClick={onClose} variant="outline" mr={3}>
              Cancel
            </Button>
            <Button
              size="sm"
              colorScheme="purple"
              disabled={isLoading}
              onClick={savePrompt}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
