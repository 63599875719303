import * as React from 'react'

export default function usePreloadedImage(src?: string | null) {
  React.useLayoutEffect(() => {
    if (src) {
      const image = new Image()
      image.src = src
    }
  }, [src])

  return src
}
