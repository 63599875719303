import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import {
  IconArrowUp,
  IconBulb,
  IconHistory,
  IconMaximize,
  IconMinus,
  IconPlayerStopFilled,
  IconPlus,
  IconReload
} from '@tabler/icons-react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import createPersistedState from 'use-persisted-state'
import { Account } from '../../../types/Account'
import { Apps } from '../../../types/App'
import { Company } from '../../../types/Profile'
import { useIcpIdeas } from '../../data/use-ai-icp'
import { AutosizedTextarea } from '../../ui/AutosizedTextarea'
import { Card } from '../../ui/Card'
import CircleIcon from '../../ui/CircleIcon'
import CompanyAvatar from '../../ui/CompanyAvatar'
import { AiSparklesIcon, LinkedinBoxIcon } from '../../ui/icons'
import { useCurrentUser } from '../../ui/UserContext'
import { Toggle } from '../accounts/components/Toggle'
import { accountPath } from '../accounts/lib/account-path'

interface CustomizationDrawerProps {
  account: Account
  company: Company
  initialPrompt?: string
  apps?: Apps
}

const staticIdeas = [
  'People in Sales, Marketing, or Growth',
  'VP of Engineering in the US',
  'Anyone with a title containing "Security"'
]

const usePromptHistory = createPersistedState<string[]>('koala-ai-prompt-history')

export function AIProspecting(props: CustomizationDrawerProps) {
  const [prompt, setPrompt] = useState(props.initialPrompt)
  const [previewing, setPreviewing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [messages, setMessages] = useState<string[]>([])
  const [fullsScreen, setFullscreen] = useState(false)
  const [prompts, setPrompts] = usePromptHistory([])

  useEffect(() => {
    setPrompt(props.initialPrompt)
  }, [props.initialPrompt])

  const [ideaSeed, setIdeaSeed] = useState(1)
  const ideas = useIcpIdeas(ideaSeed)
  const controllerRef = useRef<AbortController>()
  const [currentPrompt, setCurrentPrompt] = useState<string | null>(null)

  const onPreview = useCallback(() => {
    setIsLoading(true)

    controllerRef.current?.abort()
    controllerRef.current = new AbortController()

    const urlParams = new URLSearchParams()
    if (prompt) {
      urlParams.append('prompt', prompt)
    }

    const path = accountPath(
      {
        domain: props.company.domain
      },
      `/prospects/ai-prompt?${urlParams.toString()}`
    )

    fetchEventSource(path, {
      openWhenHidden: true,
      signal: controllerRef.current.signal,
      onopen: async (res) => {
        setMessages([])
        if (res.ok && res.status === 200) {
          console.log('Connection made ', res)
        } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
          console.log('Client side error ', res)
        }
      },
      onmessage: (e) => {
        setMessages((prev) => [...prev, e.data])
      },
      onerror: () => {
        setIsLoading(false)
        console.log('Error occurred')
      },
      onclose: () => {
        setIsLoading(false)
        console.log('Connection closed')
      }
    })
  }, [props.company.domain, prompt])

  const onSubmitPrompt = useCallback(
    (prompt: string) => {
      if (prompt.trim() === '') {
        return
      }

      setPreviewing(true)
      onPreview()
      setPrompts((prev) => {
        if (prev.includes(prompt)) {
          return prev
        }

        // concat and return last 5 prompts
        return [...prev, prompt].reverse().slice(0, 4).reverse()
      })
      setPrompt('')
      setCurrentPrompt(prompt)
    },
    [onPreview, setPrompts]
  )

  const promptSuggestions = useMemo(() => {
    if (ideas.data?.ideas) {
      return ideas.data.ideas
    }

    if (ideas.error) {
      return staticIdeas
    }

    return []
  }, [ideas.data, ideas.error])

  const user = useCurrentUser()

  const [expandedPrompt, setExpandedPrompt] = useState(false)

  const content = (
    <Stack
      spacing="8"
      p={fullsScreen ? '12' : '4'}
      position={'relative'}
      pt="0"
      margin="0 auto"
      bg="white"
      rounded="md"
    >
      {!fullsScreen && (
        <Flex position={'absolute'} right="0" top="0">
          <Tooltip label="Go full screen">
            <IconButton
              size="sm"
              icon={<IconMaximize size={20} />}
              aria-label="Fullscreen"
              onClick={() => {
                setFullscreen(true)
              }}
              variant="ghost"
            />
          </Tooltip>
        </Flex>
      )}

      {(!currentPrompt || fullsScreen) && (
        <Stack spacing="1" justifyContent={'center'} alignItems="center" py="4">
          <Stack alignItems="center" spacing={4}>
            <AiSparklesIcon boxSize={10} />
            <Heading size="lg">Koala AI Prospector</Heading>
          </Stack>
          <HStack>
            <Text fontSize="sm">Find your ideal prospects at</Text>
            <HStack spacing="1" borderWidth="thin" p="1" px="1.5" rounded="md">
              <CompanyAvatar size="16px" domain={props.company.domain} />
              <Heading letterSpacing="tight" size="xs">
                {props.company.name ?? props.company.domain}
              </Heading>
            </HStack>
          </HStack>
        </Stack>
      )}

      <HStack w="100%" alignItems={'flex-start'} spacing="8">
        {previewing && (
          <Stack w="100%" spacing="4" flex="1.5">
            {currentPrompt && (
              <Flex
                gap={2.5}
                alignSelf="flex-end"
                justifyContent="flex-end"
                alignItems="flex-start"
                bg="gray.100"
                bgGradient="linear(to-b, #f0f2f4, #e8eaed)"
                p="3"
                pr="5"
                pb={currentPrompt.split('\n').length > 8 ? 8 : undefined}
                rounded="xl"
                position="relative"
              >
                <Avatar size="xs" src={user.image} />
                <Box fontSize="sm" maxH={expandedPrompt ? undefined : '100px'} overflow="auto">
                  <ReactMarkdown
                    components={{
                      h1: ({ node, ...props }) => <Heading size="sm" my="4" {...props} />,
                      h2: ({ node, ...props }) => <Heading size="xs" my="4" {...props} />,
                      h3: ({ node, ...props }) => <Heading size="xs" my="4" {...props} />,
                      p: ({ node, ...props }) => <Text my="0.5" lineHeight="20px" {...props} />,
                      li: ({ node, ...props }) => (
                        <li
                          {...props}
                          style={{
                            ...props.style
                          }}
                        />
                      ),
                      ul: ({ node, ...props }) => (
                        <ul
                          {...props}
                          style={{
                            paddingLeft: '1em',
                            ...props.style
                          }}
                        />
                      )
                    }}
                  >
                    {currentPrompt}
                  </ReactMarkdown>
                </Box>
                {currentPrompt.split('\n').length > 8 && (
                  <Flex position="absolute" right="6" bottom="2.5">
                    <Button
                      size="xs"
                      variant="link"
                      onClick={() => setExpandedPrompt(!expandedPrompt)}
                      color="gray.500"
                    >
                      {expandedPrompt ? 'Show less' : 'Show more'}
                    </Button>
                  </Flex>
                )}
              </Flex>
            )}

            <Flex>
              <CircleIcon icon={AiSparklesIcon} color="white" bg="accent.500" padding={1.5} iconSize={5} />
            </Flex>

            <Stack w="100%" spacing="4" fontSize="xs" borderWidth="thin" p="4" rounded="lg">
              <ReactMarkdown
                components={{
                  h2: ({ node, ...props }) => <Heading size="xs" {...props} />,
                  li: ({ node, ...props }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        listStyleType: 'none'
                      }}
                    />
                  ),
                  ul: ({ node, ...props }) => (
                    <ul
                      {...props}
                      style={{
                        ...props.style
                      }}
                    />
                  ),
                  // @ts-ignore - types are wrong
                  a: ({ node, ...props }) => (
                    <Box>
                      <Button
                        as={Link}
                        variant="link"
                        leftIcon={
                          props.href?.includes('linkedin.com') ? (
                            <Icon as={LinkedinBoxIcon} boxSize={4} color="linkedin.700" />
                          ) : undefined
                        }
                        iconSpacing={1}
                        {...(props as any)}
                        size="xs"
                        color="linkedin.700"
                        isExternal
                        href={props.href?.startsWith('linkedin.com') ? `https://${props.href}` : props.href}
                      />
                    </Box>
                  )
                }}
              >
                {messages.join('')}
              </ReactMarkdown>
            </Stack>
          </Stack>
        )}

        <Stack w="100%" spacing="4" flex="1" minW="350px" maxW="800px" marginX="auto">
          <Flex
            alignItems="flex-end"
            gap={2}
            py={1.5}
            pl={4}
            pr={2}
            w="100%"
            bg="white"
            position="relative"
            rounded="lg"
            border="1px solid"
            borderColor="gray.200"
            _focusWithin={{ borderColor: 'gray.300', shadow: 'sm' }}
          >
            <AutosizedTextarea
              placeholder="Enter a prompt to find prospects"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              fontSize="sm"
              width="auto"
              flex="1 1 auto"
              px={0}
              py={2}
              borderWidth="0px"
              _focus={{ boxShadow: 'none' }}
              lineHeight="1.5rem"
              maxRows={8}
            />

            {isLoading ? (
              <IconButton
                icon={<IconPlayerStopFilled size="16px" />}
                aria-label="Stop"
                size="sm"
                colorScheme="slate"
                bg="black"
                _hover={{ opacity: 0.8 }}
                isDisabled={!isLoading}
                onClick={() => {
                  controllerRef.current?.abort()
                  setIsLoading(false)
                }}
                rounded="full"
                mb={1}
              />
            ) : (
              <IconButton
                icon={<IconArrowUp size="20px" />}
                aria-label="Send"
                isDisabled={!prompt}
                size="sm"
                colorScheme={!prompt ? 'gray' : 'purple'}
                onClick={() => onSubmitPrompt(prompt ?? '')}
                rounded="full"
                isLoading={isLoading}
                mb={1}
              />
            )}
          </Flex>

          <Toggle
            defaultIsOpen={props.initialPrompt === '' || props.initialPrompt === undefined}
            toggleIcon={(isOpen) => <Icon as={isOpen ? IconMinus : IconPlus} color="purple.500" />}
            title={
              <Heading size="xs" fontSize={'xs'} fontWeight={'semibold'} color="gray.400">
                PROMPT IDEAS:
              </Heading>
            }
          >
            <Stack w="100%" fontSize="sm">
              {ideas.isLoading && (
                <Stack spacing={2}>
                  {Array.from({ length: 3 }).map((_, index) => (
                    <Card
                      key={index}
                      as={HStack}
                      _hover={{ bg: 'gray.50' }}
                      w="100%"
                      justifyContent={'space-between'}
                      borderColor="purple.200"
                      px="4"
                      py="3.5"
                    >
                      <Icon as={IconBulb} size="18px" color="gray.500" />
                      <Flex w="100%">
                        <Skeleton
                          maxWidth="80%"
                          width="600px"
                          height="16px"
                          rounded="base"
                          startColor="gray.50"
                          endColor="gray.200"
                        />
                      </Flex>
                    </Card>
                  ))}
                </Stack>
              )}
              {promptSuggestions.map((idea) => {
                if (idea.trim() === '') {
                  return null
                }

                const cleanIdea = idea.trim().replace('at a company', '')

                return (
                  <Card
                    as={HStack}
                    _hover={{ bg: 'gray.50' }}
                    key={idea}
                    w="100%"
                    justifyContent={'space-between'}
                    borderColor="purple.200"
                    px="4"
                    py="3"
                    cursor={'pointer'}
                    onClick={() => setPrompt(cleanIdea)}
                  >
                    <Icon as={IconBulb} size="18px" color="purple.500" />
                    <Flex w="100%">
                      <Text lineHeight="20px">{cleanIdea}</Text>
                    </Flex>
                    <Icon as={IconPlus} size="24px" color="purple.500" />
                  </Card>
                )
              })}
              <Flex justifyContent={'flex-end'}>
                <Button
                  size="xs"
                  variant={'ghost'}
                  onClick={() => {
                    setIdeaSeed(Math.random())
                    ideas.refetch({})
                  }}
                  color="gray.500"
                  _hover={{ color: 'gray.700' }}
                  leftIcon={<IconReload size="14px" />}
                  iconSpacing={1.5}
                  isLoading={ideas.isFetching || ideas.isLoading}
                >
                  Refresh ideas
                </Button>
              </Flex>
            </Stack>
          </Toggle>

          {(prompts ?? []).length > 0 && (
            <Toggle
              defaultIsOpen={false}
              toggleIcon={(isOpen) => <Icon as={isOpen ? IconMinus : IconPlus} color="purple.500" />}
              title={
                <Heading size="xs" fontSize={'xs'} fontWeight={'semibold'} color="gray.400">
                  PROMPT HISTORY:
                </Heading>
              }
            >
              <Stack w="100%" fontSize="sm">
                {(prompts ?? []).map((prompt) => (
                  <Card
                    as={HStack}
                    _hover={{ bg: 'gray.50' }}
                    key={prompt}
                    w="100%"
                    justifyContent={'space-between'}
                    px="4"
                    py="3"
                    cursor={'pointer'}
                    onClick={() => setPrompt(prompt)}
                  >
                    <Icon as={IconHistory} size="18px" color="gray.500" />
                    <Flex w="100%">
                      <Text>{prompt}</Text>
                    </Flex>
                  </Card>
                ))}
                <Flex justifyContent={'flex-end'}>
                  <Button size="xs" variant={'ghost'} onClick={() => setPrompts([])}>
                    Clear history
                  </Button>
                </Flex>
              </Stack>
            </Toggle>
          )}
        </Stack>
      </HStack>
    </Stack>
  )

  if (fullsScreen) {
    return (
      <Modal
        isOpen={fullsScreen}
        onClose={() => {
          setFullscreen(false)
        }}
        size="full"
        closeOnOverlayClick
        closeOnEsc
      >
        <ModalOverlay />
        <ModalContent rounded="none">
          <ModalCloseButton />
          <ModalBody pt={10}>{content}</ModalBody>
        </ModalContent>
      </Modal>
    )
  }

  return content
}
