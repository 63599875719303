import { Button, Divider, Flex, Grid, Heading, Image, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { Card } from '../../ui/Card'
import { TopBarContent } from '../../ui/TopBarContext'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import AutoOutboundSvg from './assets/auto-outbound.svg'
import AutomationsSvg from './assets/automations.svg'
import SlackAlertsSvg from './assets/slack-alerts.svg'
import ActionTemplates from './components/ActionTemplates'

interface Props {}

export default function Overview(_props: Props) {
  return (
    <PageLayout size="md">
      <PageTitle skipRendering>Automations Overview</PageTitle>

      <TopBarContent>
        <Breadcrumb
          paths={[
            { path: projectPath('/automations/overview'), title: 'Automations' },
            { path: projectPath('/automations/overview'), title: 'Overview' }
          ]}
        />
      </TopBarContent>

      <Grid templateColumns={['repeat(1, 1fr)', 'repeat(auto-fit, minmax(340px, 1fr))']} gap={[6, 6, 8, 8, 10]}>
        <Card padding={7} rounded="xl">
          <Flex flexDirection="column" gap={6} height="100%">
            <Image src={SlackAlertsSvg} alignSelf="center" maxWidth="340px" width="100%" height="auto" />
            <Stack>
              <Heading size="sm">Slack Alerts</Heading>
              <Text fontSize="sm" color="gray.600">
                Stay on top of signals that matter. Send real-time alerts to reps in Slack when prospects are showing
                intent.
              </Text>
            </Stack>
            <Button as="a" href={projectPath('/slack-alerts')} colorScheme={'purple'}>
              View Slack Alerts
            </Button>
          </Flex>
        </Card>
        <Card padding={7} rounded="xl">
          <Flex flexDirection="column" gap={6} height="100%">
            <Image src={AutoOutboundSvg} alignSelf="center" maxWidth="340px" width="100%" height="auto" />
            <Stack>
              <Heading size="sm">Auto Outbound</Heading>
              <Text fontSize="sm" color="gray.600">
                Automatically engage prospects when the moment is right, and put signal-based selling on autopilot.
              </Text>
            </Stack>
            <Button as="a" href={projectPath('/auto-outbound')} colorScheme={'purple'}>
              View Auto Outbound
            </Button>
          </Flex>
        </Card>
        <Card padding={7} rounded="xl">
          <Flex flexDirection="column" gap={6} height="100%">
            <Image src={AutomationsSvg} alignSelf="center" maxWidth="340px" width="100%" height="auto" />
            <Stack>
              <Heading size="sm">Actions</Heading>
              <Text fontSize="sm" color="gray.600">
                Sync Koala data <strong>anywhere</strong>. Trigger actions from intent signals to automate your sales
                motion whenever a signal happens.
              </Text>
            </Stack>

            <Button as="a" href={projectPath('/automations')} colorScheme={'purple'}>
              View Actions
            </Button>
          </Flex>
        </Card>
      </Grid>

      <Divider marginY={4} />

      <ActionTemplates />
    </PageLayout>
  )
}
