import { Box, HStack, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { CheckboxBlankCircleIcon, CheckboxCircleIcon } from '../../../ui/icons'

interface AlternateUserProps {
  id: string
  name: string
  email: string
  selected?: boolean
  onSelect: () => void
}

export function AlternateUser(props: AlternateUserProps) {
  return (
    <HStack
      spacing={4}
      border="1px solid"
      borderColor={props.selected ? 'purple.500' : 'gray.200'}
      rounded="md"
      paddingX={4}
      paddingY={2}
      cursor="pointer"
      onClick={props.onSelect}
    >
      <Box flex="1">
        {props.selected && (
          <input type="hidden" name="user[alternate_emails_attributes][][email]" value={props.email} />
        )}
        <Text fontSize="sm" fontWeight="medium">
          {props.name}
        </Text>
        <Text fontSize="xs" color="gray.600">
          {props.email}
        </Text>
      </Box>
      {props.selected ? (
        <Icon as={CheckboxCircleIcon} boxSize={5} color="purple.500" />
      ) : (
        <Icon as={CheckboxBlankCircleIcon} boxSize={5} color="gray.300" />
      )}
    </HStack>
  )
}
