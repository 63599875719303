import { useQuery } from 'react-query'
import { concurrentGET } from '../../lib/api'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Data {
  id: string
  name?: string
  email?: string
  title?: string
  display_name?: string
  anonymous_name?: string
  status?: 'online' | 'away' | 'offline'
  account_id?: string
  account_association?: unknown
  account?: {
    id: string
    name?: string
    domain: string
  }
  company?: {
    id: string
    name?: string
    domain: string
    logo?: string
  }
}

interface Props {
  id?: string | null
  email?: string | null
}

export function useAssociation({ id, email }: Props = {}) {
  const project = useCurrentProject()

  const path = projectPath(`/profiles/${id || email}/association`)

  return useQuery<Data>(['association', { projectId: project?.id, id, email }], () => concurrentGET<Data>(path), {
    enabled: Boolean(project?.id && (id || email))
  })
}
