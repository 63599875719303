import * as React from 'react'

const baseTitle = 'Koala'

// Restore the base title when the component unmounts
export default function useTitle(title: string) {
  React.useEffect(() => {
    if (document.title !== title) {
      document.title = title
    }

    return () => {
      document.title = baseTitle
    }
  }, [title])
}
