import { Heading, Stack } from '@chakra-ui/react'
import React from 'react'
import { ErrorPage } from './components/ErrorPage'

export default function TimeoutError() {
  return (
    <ErrorPage
      title={
        <Stack>
          <Heading size="4xl">504</Heading>
          <Heading as="h1" size="lg">
            Gateway Timeout
          </Heading>
        </Stack>
      }
      description="The server took too long to respond, please try again later."
    />
  )
}
