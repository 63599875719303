import { FormControl, FormHelperText, Stack } from '@chakra-ui/react'
import { nanoid } from 'nanoid'
import React from 'react'
import { Apps } from '../../../../types/App'
import { Card } from '../../../ui/Card'
import { HubspotFieldMapping } from '../../follow_rules/components/hubspot/field-mapper'
import { HubspotSetup } from '../../follow_rules/components/hubspot/hubspot-setup'
import { FollowRule } from '../../notifications'

export interface HubspotRulesSetupProps {
  ruleId?: string
  apps: Apps
  deliveryRules?: FollowRule['delivery_rules']
}

export const HubspotRulesSetup = (props: HubspotRulesSetupProps) => {
  const signalMappings: HubspotFieldMapping[] = [
    {
      id: nanoid(),
      koala: 'signal.name',
      mode: 'mapped'
    },
    {
      id: nanoid(),
      koala: 'signal.timestamp',
      mode: 'mapped'
    },
    {
      id: nanoid(),
      koala: 'signal.signal_type',
      mode: 'mapped'
    },
    {
      id: nanoid(),
      koala: 'signal.intent_summary',
      mode: 'mapped'
    }
  ]

  const defaultContactMappings: HubspotFieldMapping[] = [
    {
      id: nanoid(),
      hubspot: 'email',
      koala: 'visitor.email',
      mode: 'mapped'
    },
    ...signalMappings
  ]

  const defaultCompanyMappings: HubspotFieldMapping[] = [
    {
      id: nanoid(),
      hubspot: 'domain',
      koala: 'account.company.domain',
      mode: 'mapped'
    },
    ...signalMappings
  ]

  return (
    <FormControl as={Stack} spacing="4">
      <FormHelperText mb="0">Define how you want to send data into Hubspot</FormHelperText>
      <Card pt="0" pb="0">
        <HubspotSetup
          colorScheme={'orange'}
          targetType="Profile"
          delivery_rules={props.deliveryRules}
          suggestedMappings={{
            company: defaultCompanyMappings,
            contact: defaultContactMappings
          }}
          actions={{
            add_timeline_entry: false,
            add_to_list: false,
            create_task: false,
            import_company: true,
            import_contact: true
          }}
        />
      </Card>
    </FormControl>
  )
}
