import { Button, Link, useBreakpointValue } from '@chakra-ui/react'
import React from 'react'
import { AccountView } from '../../../../types/AccountView'
import { projectPath } from '../../../ui/ProjectsContext'
import { SegmentedControl } from '../../../ui/SegmentedControl'
import { LiveButton } from '../../profiles/components/visitor-tabs'

interface AccountViewTabsProps {
  view: AccountView
}

export default function AccountViewTabs(props: AccountViewTabsProps) {
  // use a modal if we are on small screens/mobile
  const isSmall = useBreakpointValue({ base: true, sm: false, md: false })
  if (isSmall) {
    return null
  }

  // We need to correctly determine the base path for the view since it can:
  // - belong to nothing
  // - belong to a space
  // - belong to someone's mission control
  const onMissionControl = window.location.pathname.includes('/mission-control')
  const pathPrefix = projectPath(onMissionControl ? '/mission-control/views' : '/views')
  const href = props.view.permalink || `${pathPrefix}/${props.view.slug}`
  const url = new URL(href, window.location.origin)

  const viewPath = `${url.pathname}` + location.search
  const feedPath = `${url.pathname}/feed` + location.search
  const livePath = `${url.pathname}/live` + location.search

  return (
    <SegmentedControl size="sm">
      <Button as={Link} href={viewPath} isActive={`${location.pathname}${location.search}` === viewPath}>
        List
      </Button>
      <Button as={Link} href={feedPath} isActive={location.pathname.endsWith('/feed')}>
        Feed
      </Button>

      <LiveButton path={livePath} isActive={location.pathname.endsWith('/live')} />
    </SegmentedControl>
  )
}
