import { Box, BoxProps, Icon, IconButton, useMediaQuery } from '@chakra-ui/react'
import { IconCheck, IconCopy } from '@tabler/icons-react'
import React, { useCallback, useEffect, useRef } from 'react'
import { toast } from 'sonner'
import { useCopyToClipboard } from './useCopyToClipboard'
import useUpdateEffect from './useUpdateEffect'

interface Props extends BoxProps {
  copyEnabled?: boolean
  copyText?: string
  inline?: boolean
  showIcon?: boolean
  showToast?: boolean
  onCopyToClipboard?: (value: string) => void
}

export function Copyable({
  children,
  copyEnabled = true,
  copyText,
  inline,
  showToast,
  onCopyToClipboard,
  ...rest
}: React.PropsWithChildren<Props>) {
  const textRef = useRef<HTMLDivElement>(null)

  const { copied, copy, value } = useCopyToClipboard()

  const onCopy = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (event.defaultPrevented || event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
        return
      }

      // ignore if it's a link
      const targetAnchor = (event.target as HTMLElement).closest('a')
      if (event.currentTarget.contains(targetAnchor)) {
        return
      }

      const text = copyText || textRef.current?.textContent || ''
      copy(text)
      onCopyToClipboard?.(text)
    },
    [copy, copyText, onCopyToClipboard]
  )

  useUpdateEffect(() => {
    if (copied && value && showToast) {
      toast(`Copied "${value}" to clipboard!`, {
        position: 'bottom-right'
      })
    }
  }, [copied, value, showToast])

  const [isSmallScreen] = useMediaQuery('(max-width: 592px)')

  const [canCopy, setCanCopy] = React.useState(Boolean(copyText || textRef.current?.textContent) && copyEnabled)

  useEffect(() => {
    const text = copyText || textRef.current?.textContent
    setCanCopy(Boolean(text) && copyEnabled)
  }, [copyEnabled, copyText, children])

  return (
    <Box
      display="inline-flex"
      ref={textRef}
      role="group"
      position="relative"
      alignItems="center"
      cursor={canCopy ? 'pointer' : 'default'}
      onClick={canCopy ? onCopy : undefined}
      {...rest}
    >
      {children}

      {canCopy && (
        <Box
          flex="none"
          display="flex"
          paddingLeft={0.5}
          position={inline ? 'static' : 'relative'}
          top={0}
          bottom={0}
          right={0}
          width={copied || inline ? 'auto' : '0'}
          _groupHover={{ width: 'auto' }}
          _groupFocus={{ width: 'auto' }}
          overflow="hidden"
        >
          <IconButton
            icon={<Icon as={copied ? IconCheck : IconCopy} boxSize="16px" />}
            size="tiny"
            marginY="auto"
            aria-label="Copy to clipboard"
            variant="unstyled"
            display="inline-flex"
            opacity={copied || inline ? 1 : 0}
            visibility={copied || inline ? 'visible' : 'hidden'}
            color={copied ? 'purple.500' : inline ? 'gray.500' : 'gray.400'}
            _hover={{ color: 'purple.500' }}
            _groupHover={{ opacity: 1, visibility: 'visible', pointerEvents: 'auto' }}
            _groupFocusWithin={{ opacity: 1, visibility: 'visible', pointerEvents: 'auto' }}
            transform={copied ? 'scale(1.2)' : 'scale(1)'}
            _active={{ transform: 'scale(0.85)' }}
            {...(!isSmallScreen && {
              transition:
                'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
            })}
          />
        </Box>
      )}
    </Box>
  )
}
