import {
  Box,
  Button,
  Center,
  Checkbox,
  Circle,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  ScaleFade,
  Select,
  Spinner,
  Stack,
  StackDivider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import { IconArrowBarToUp, IconExternalLink } from '@tabler/icons-react'
import { format } from 'friendly-numbers'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'
import ms from 'ms'
import React, { useMemo, useState } from 'react'
import { useWindowScroll } from 'react-use'
import { concurrentCachedGET, get } from '../../../../lib/api'
import { getFlagEmoji } from '../../../../lib/flag-emoji'
import type { Account } from '../../../../types/Account'
import { PageMeta } from '../../../../types/PageMeta'
import type { ProfileRecord } from '../../../../types/Profile'
import { CardHeader } from '../../../ui/CardHeader'
import { LinkedinBoxIcon } from '../../../ui/icons'
import { projectPath } from '../../../ui/ProjectsContext'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { TimeAgo } from '../../../ui/TimeAgo'
import { isLimitedAccount, RedactedText, useEntitlements } from '../../../ui/useEntitlements'
import { Trendline } from '../../icps/icp/breakdown'
import { mergeParams } from '../../icps/types'
import { ProfilePanel } from '../../profiles/components/ProfilePanel'
import { profilePath } from '../../profiles/lib/path'

type Sort = {
  field: keyof ProfileRecord
  direction: 'asc' | 'desc'
}

interface VisitorFeedProps {
  visitors: ProfileRecord[]
  page_meta: PageMeta
  loadPath?: string
  sort: Sort
}

export function VisitorsBreakdown(props: Account) {
  const [withWorkEmailFeed, setWithWorkEmailFeed] = React.useState<VisitorFeedProps | null>(null)
  const [withoutWorkEmailFeed, setWithoutWorkEmailFeed] = React.useState<VisitorFeedProps | null>(null)
  const [allFeed, setAllFeed] = React.useState<VisitorFeedProps | null>(null)
  const [sort, setSort] = useState<Sort>({ field: 'last_seen_at', direction: 'desc' })

  const with_work_email_path = useMemo(() => {
    return projectPath(`/accounts/${props.company_id}/visitors/breakdown?with_work_email=true&page_size=10`)
  }, [props.company_id])

  const without_work_email_path = useMemo(() => {
    return projectPath(`/accounts/${props.company_id}/visitors/breakdown?with_work_email=false&page_size=10`)
  }, [props.company_id])

  const all_path = useMemo(() => {
    return projectPath(`/accounts/${props.company_id}/visitors/breakdown?page_size=10`)
  }, [props.company_id])

  const updateFeed = (path, setFeedCallback) => {
    setFeedCallback(null)
    let canceled = false

    concurrentCachedGET<VisitorFeedProps>(path).then((res) => {
      if (canceled) return
      setFeedCallback(res)
    })

    return () => {
      canceled = true
    }
  }

  React.useEffect(() => {
    updateFeed(with_work_email_path, setWithWorkEmailFeed)
  }, [with_work_email_path, setWithWorkEmailFeed])

  React.useEffect(() => {
    updateFeed(without_work_email_path, setWithoutWorkEmailFeed)
  }, [without_work_email_path, setWithoutWorkEmailFeed])

  React.useEffect(() => {
    updateFeed(all_path, setAllFeed)
  }, [all_path, setAllFeed])

  return (
    <Box w="100%" paddingBottom={4}>
      <CardHeader display="flex" alignItems="center" width="100%" borderBottom="none" paddingBottom={2}>
        <HStack justifyContent={'space-between'} w="100%">
          <Text>People</Text>
          <Select
            variant="outline"
            flex="none"
            iconSize="14"
            iconColor="gray.600"
            size="xs"
            width="auto"
            value={sort.field}
            onChange={(e) => {
              setSort({
                field: e.target.value as keyof ProfileRecord,
                direction: 'desc'
              })
            }}
          >
            <option value="last_seen_at">By recency</option>
            <option value="focus_time">By session time</option>
            <option value="email">By identified</option>
          </Select>
        </HStack>
      </CardHeader>
      <Stack spacing={0} divider={<StackDivider />} width="100%" maxW="1024px" marginX="auto">
        <Tabs size="sm" variant="line">
          <TabList>
            <Tab fontSize="xs">
              Known{' '}
              {!!withWorkEmailFeed?.page_meta && (
                <Text pl="1">({format(withWorkEmailFeed?.page_meta.total_count)}) </Text>
              )}
            </Tab>
            <Tab fontSize="xs">
              Other{' '}
              {!!withoutWorkEmailFeed?.page_meta && (
                <Text pl="1">({format(withoutWorkEmailFeed?.page_meta.total_count)})</Text>
              )}
            </Tab>
            <Tab fontSize="xs">
              All {!!allFeed?.page_meta && <Text pl="1">({format(allFeed?.page_meta.total_count)})</Text>}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p="0">
              {withWorkEmailFeed ? (
                <VisitorsBreakdownFeed {...withWorkEmailFeed} loadPath={with_work_email_path} sort={sort} />
              ) : (
                <Center w="100%" p="8">
                  <Spinner />
                </Center>
              )}
            </TabPanel>
            <TabPanel p="0">
              {withoutWorkEmailFeed ? (
                <VisitorsBreakdownFeed {...withoutWorkEmailFeed} loadPath={without_work_email_path} sort={sort} />
              ) : (
                <Center w="100%" p="8">
                  <Spinner />
                </Center>
              )}
            </TabPanel>
            <TabPanel p="0">
              {allFeed ? (
                <VisitorsBreakdownFeed {...allFeed} loadPath={all_path} sort={sort} />
              ) : (
                <Center w="100%" p="8">
                  <Spinner />
                </Center>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Box>
  )
}

export function VisitorsBreakdownFeed(props: VisitorFeedProps) {
  const [visitors, setVisitors] = React.useState(props.visitors)
  const [loading, setLoading] = React.useState(false)
  const [nextPage, setNextPage] = React.useState(props.page_meta.next_page)
  const loadingRef = React.useRef(loading)
  const nextPageRef = React.useRef(nextPage)

  const sorted = useMemo(() => {
    // handle null data (otherwise it gets sorted to the top)
    return orderBy(visitors, (visitor) => visitor[props.sort.field] || '', [props.sort.direction])
  }, [visitors, props.sort])

  React.useEffect(() => {
    setVisitors(props.visitors)
  }, [props.visitors])

  React.useEffect(() => {
    setNextPage(props.page_meta.next_page)
  }, [props.page_meta.next_page])

  const loadMore = React.useCallback(async () => {
    if (!nextPageRef.current || loadingRef.current) {
      return
    }

    setLoading(true)
    loadingRef.current = true

    const url = mergeParams(props.loadPath ?? location.toString(), {
      page: nextPageRef.current.toString()
    })

    const res = await get<VisitorFeedProps>(url)

    setVisitors((prev) => uniqBy(prev.concat(res.visitors), (s) => s.id))
    const next = res.page_meta.next_page || null

    setNextPage(next)
    nextPageRef.current = next

    setLoading(false)
    loadingRef.current = false
  }, [props.loadPath])

  const { y: scrollY } = useWindowScroll()
  const scrollToTop = React.useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])

  const endOfPageRef = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0
    }

    const observer = new IntersectionObserver((entries) => {
      const target = entries[0]
      if (target.isIntersecting) {
        loadMore()
      }
    }, option)

    if (endOfPageRef.current) {
      observer.observe(endOfPageRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [loadMore])

  return (
    <Box w="100%" paddingBottom={4}>
      <Stack spacing={0} divider={<StackDivider />} width="100%" maxW="1024px" marginX="auto">
        <VisitorsPanel visitors={sorted} />

        <Center paddingTop={10} paddingBottom={20}>
          {nextPage ? (
            <Box ref={endOfPageRef} height="100px">
              {loading && (
                <Button variant="link" isLoading loadingText="Loading more…">
                  Loading more…
                </Button>
              )}
            </Box>
          ) : null}
        </Center>
      </Stack>

      <Box position="fixed" bottom={10} right={10}>
        <ScaleFade initialScale={0.9} in={scrollY > 150}>
          <Circle
            padding={3}
            bg="gray.500"
            cursor="pointer"
            opacity={0.5}
            _hover={{ opacity: 0.8 }}
            transition="opacity 150ms cubic-bezier(0.4, 0, 0.2, 1)"
            onClick={scrollToTop}
          >
            <IconArrowBarToUp size={24} color="white" />
          </Circle>
        </ScaleFade>
      </Box>
    </Box>
  )
}

function VisitorsPanel(props: { visitors: ProfileRecord[] }) {
  const [ignoreInactive, setShowInactive] = useState(false)
  const visitors = React.useMemo(() => {
    if (ignoreInactive) {
      return props.visitors.filter((v) => !!v.last_seen_at)
    }
    return props.visitors
  }, [ignoreInactive, props.visitors])

  const hidden = React.useMemo(() => {
    return props.visitors.length - visitors.length
  }, [props.visitors, visitors])

  const hasInactive = React.useMemo(() => props.visitors.some((v) => !v.last_seen_at), [props.visitors])

  return (
    <Stack spacing="0" divider={<StackDivider />} w="100%">
      {hasInactive && (
        <HStack p="4" justifyContent={'flex-start'}>
          <Checkbox isChecked={ignoreInactive} onChange={(e) => setShowInactive(e.target.checked)} size="sm">
            <Text fontSize="xs">Ignore Inactive {hidden > 0 && ` (${hidden} visitors)`}</Text>
          </Checkbox>
        </HStack>
      )}

      <Stack spacing={0}>
        {visitors.map((visitor, index) => (
          <Flex
            key={visitor.id}
            w="100%"
            borderBottomColor="gray.200"
            borderBottomStyle="solid"
            borderBottomWidth="1px"
          >
            <VisitorRow visitor={visitor} index={index} />
          </Flex>
        ))}
      </Stack>
    </Stack>
  )
}

interface VisitorProps {
  visitor: ProfileRecord
  index: number
}

function VisitorRow(props: VisitorProps) {
  const entitlements = useEntitlements()
  const { visitor } = props
  const disclosure = useDisclosure()
  const redacted = isLimitedAccount(entitlements, visitor)

  const locale = visitor.simple_location
  const geo = visitor.person?.geo || visitor.simple_geo
  const hasSessionTime = !!visitor.focus_time && visitor.focus_time > 60_000

  return (
    <Box w="100%">
      <ProfilePanel {...disclosure} profileId={visitor.id} redacted={redacted} />

      <HStack px="4" py="3.5" _hover={{ bg: 'gray.50' }} w="100%" cursor={'pointer'} onClick={disclosure.onOpen}>
        <Box flex="1" minWidth="150px" isTruncated>
          {redacted ? (
            <Flex>
              <RedactedText>{visitor.display_name || 'Anonymous visitor'}</RedactedText>
            </Flex>
          ) : (
            <>
              <Flex alignItems="center" gap={1.5}>
                <Text
                  flex="0 1 auto"
                  fontSize="sm"
                  fontWeight={visitor.display_name ? 'medium' : undefined}
                  isTruncated
                >
                  {visitor.display_name || 'Anonymous visitor'}
                </Text>
                {visitor.linkedin_url && (
                  <Tooltip label={visitor.linkedin_url}>
                    <Link
                      display="flex"
                      flex="none"
                      alignItems="center"
                      color="linkedin.700"
                      isExternal
                      href={visitor.linkedin_url}
                      onClick={(e) => {
                        e.stopPropagation()
                        window.ko?.track('LinkedIn Profile Visit Action', {
                          app: 'linkedin',
                          email: visitor.email
                        })
                      }}
                    >
                      <LinkedinBoxIcon boxSize="18px" />
                    </Link>
                  </Tooltip>
                )}
              </Flex>

              {visitor.employment_title && (
                <TextEllipsis tooltip display="block" maxW="100%" fontSize="13px" lineHeight="20px" color="gray.800">
                  {visitor.employment_title}
                </TextEllipsis>
              )}

              {locale && (
                <TextEllipsis tooltip display="block" maxW="100%" fontSize="13px" lineHeight="20px" color="gray.500">
                  {locale} {getFlagEmoji(geo?.countryCode)}
                </TextEllipsis>
              )}
            </>
          )}

          {visitor.last_seen_at ? (
            <Text fontSize="xs" color="gray.500">
              Seen <TimeAgo time={visitor.last_seen_at?.toString()} />
            </Text>
          ) : (
            <Text flex="none" fontSize="xs" color="gray.400">
              Not recently active
            </Text>
          )}
        </Box>

        <HStack spacing="6">
          {hasSessionTime && (
            <Text flex="none" fontSize="xs">
              {ms(visitor.focus_time!, { long: true })}
            </Text>
          )}

          <HStack>
            {visitor.focus_time_trend && (
              <Box flex="none" display="flex" overflow="hidden" w="100%">
                <Trendline
                  color="blue"
                  range={'month'}
                  trend={visitor.focus_time_trend}
                  width={100}
                  svgWidth={100}
                  height={24}
                  svgHeight={24}
                />
              </Box>
            )}
          </HStack>

          <HStack display={['none', 'flex']} alignItems="baseline" spacing={1} flex="none">
            <IconButton
              size="xs"
              variant="link"
              color="gray.400"
              as={Link}
              isExternal
              aria-label="Visitor details"
              // do not reveal the users email when redacted
              href={profilePath(redacted ? { id: visitor.id } : visitor)}
              height="18px"
              icon={<Icon as={IconExternalLink} boxSize={4} />}
              _hover={{
                color: 'gray.800'
              }}
              onClick={(e) => {
                e.stopPropagation()
              }}
            />
          </HStack>
        </HStack>
      </HStack>
    </Box>
  )
}
