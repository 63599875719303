import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'

export default function SettingsHeader({ divider = true, ...props }: BoxProps & { divider?: boolean }) {
  if (divider) {
    return <Box borderBottom="1px solid" borderColor="gray.100" paddingBottom={4} marginBottom={4} {...props} />
  }

  return <Box {...props} />
}
