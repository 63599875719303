import { Box, Button, FormControl, Heading, Input, Flex, Stack, Text, Link } from '@chakra-ui/react'
import React, { useState } from 'react'
import KoalaLogo from '../../../ui/KoalaLogo'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'

interface Props {
  email?: string | null
}

export default function CheckEmail(props: Props) {
  const [email, setEmail] = useState(props.email || '')

  return (
    <Box minHeight="100%" background="white">
      <Flex
        marginX="auto"
        paddingTop="15vh"
        maxWidth="100%"
        width="520px"
        direction="column"
        justifyContent="center"
        alignItems="center"
        gap={8}
        paddingX={5}
      >
        <KoalaLogo kind="mark" size="64px" />
        <Heading size="lg" textAlign="center">
          Log in to Koala
        </Heading>

        <Box width="100%" maxWidth="400px" marginX="auto">
          <form action="/login/continue" method="post" data-koala-collect="off">
            <AuthenticityToken />
            <Stack spacing={6} direction="column">
              <FormControl id="email">
                <Input
                  type="email"
                  name="user[email]"
                  placeholder="name@company.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isRequired
                  autoFocus
                />
              </FormControl>

              <Button type="submit" colorScheme="purple" size="lg" fontSize="md">
                Continue with email
              </Button>

              <Flex justifyContent="center" alignItems="center" fontSize="sm" gap={1}>
                <Text as="span" color="gray.500">
                  Don't have an account?
                </Text>
                <Link variant="primary" href="/signup">
                  Sign up
                </Link>
              </Flex>
            </Stack>
          </form>
        </Box>
      </Flex>
    </Box>
  )
}
