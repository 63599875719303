import React from 'react'
import { useMutation } from 'react-query'
import { toast } from 'sonner'
import { del } from '../../lib/api'
import { removeList } from '../pages/account_views/lib/remove-list-event'
import { projectPath } from '../ui/ProjectsContext'

interface Params {
  viewId: string
}

export function useDeleteView() {
  const onSuccess = React.useCallback((_data: any, vars: Params) => {
    toast.success('Successfully deleted your list.')
    removeList(vars.viewId)
  }, [])

  const onError = React.useCallback(() => {
    toast.error('There was an issue deleting this list.')
  }, [])

  return useMutation(
    ({ viewId }: Params) => {
      const path = projectPath(`/views/${viewId}`)
      return del(path)
    },
    {
      onSuccess,
      onError
    }
  )
}
