import { Heading, Link, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { useCurrentUser } from '../../ui/UserContext'
import useTitle from '../../ui/useTitle'
import { ErrorPage } from './components/ErrorPage'

export interface ErrorProps {
  message: string
  supported_auth_methods: string[]
}

export default function UnauthorizedAuthMethod(props: ErrorProps) {
  const user = useCurrentUser()
  useTitle('Unauthorized Authentication Method | Koala')

  return (
    <ErrorPage
      showLogIn
      title={
        <Stack>
          <Heading size="4xl">401</Heading>
          <Heading as="h1" size="lg">
            Unauthorized Authentication Method
          </Heading>
        </Stack>
      }
      description={
        <Text maxWidth="540px" fontSize="md" py={4} lineHeight={1.6}>
          🔒 {props.message}
          {user?.email && (
            <>
              {' '}
              <Link
                href="/reauth"
                textDecoration="underline"
                whiteSpace="nowrap"
                onClick={() => {
                  window.ko?.reset()
                }}
              >
                log in with another method
              </Link>
            </>
          )}
        </Text>
      }
    />
  )
}
