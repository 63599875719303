import { Box, Button, Flex, Grid, Heading, HStack, IconButton, Stack, Text } from '@chakra-ui/react'
import { IconChevronRight } from '@tabler/icons-react'
import Fuse from 'fuse.js'
import qs from 'qs'
import React, { useMemo } from 'react'
import { useDebounce } from 'use-debounce'
import { Card } from '../../../ui/Card'
import { Iconify } from '../../../ui/Iconify'
import { projectPath } from '../../../ui/ProjectsContext'
import { SearchBar } from '../../accounts/facets/search-bar'
import { channelLogos } from '../../follow_rules/components/delivery-setup'
import { automationRecipes } from '../recipes'

const integrations = Array.from(new Set(automationRecipes.map((t) => t.integration)))

interface Props {}

export default function ActionTemplates(_props: Props) {
  const [inputValue, setInputValue] = React.useState<string>('')
  const [query] = useDebounce(inputValue, 300)
  const [filteredIntegrations, setFilteredIntegrations] = React.useState<string[]>([])
  const filteredRecipes = useMemo(() => {
    if (filteredIntegrations.length === 0) {
      return automationRecipes
    }

    return automationRecipes.filter((recipe) => {
      return filteredIntegrations.includes(recipe.integration)
    })
  }, [filteredIntegrations])

  const fuse = useMemo(() => {
    return new Fuse(filteredRecipes, {
      keys: ['integration', 'title', 'description'],
      minMatchCharLength: 3,
      ignoreLocation: true,
      threshold: 0.3
    })
  }, [filteredRecipes])

  const recipes = useMemo(() => {
    if (query.length === 0) {
      return filteredRecipes
    }

    return fuse.search(query).map((result) => result.item)
  }, [fuse, query, filteredRecipes])

  return (
    <Stack spacing={4}>
      <Flex alignItems="center" flexWrap="wrap" gap={2}>
        <Heading flex="2 1 auto" size="sm">
          Get started with automation templates
        </Heading>
        <Flex flex="1 1 auto" alignItems="center">
          <Box flex="1 1 auto" marginRight={2}>
            <SearchBar size="sm" onChange={(value) => setInputValue(value)} />
          </Box>
          <HStack spacing={1}>
            {integrations.map((integration) => (
              <IconButton
                key={integration}
                aria-label={`Toggle ${integration}`}
                size="sm"
                variant="ghost"
                icon={<Iconify icon={channelLogos[integration]} size={20} />}
                isActive={filteredIntegrations.includes(integration)}
                opacity={filteredIntegrations.length === 0 ? 1 : filteredIntegrations.includes(integration) ? 1 : 0.5}
                _hover={{ opacity: 1 }}
                onClick={() => {
                  setFilteredIntegrations((prev) => {
                    if (prev.includes(integration)) {
                      return prev.filter((i) => i !== integration)
                    } else {
                      return [...prev, integration]
                    }
                  })
                }}
              />
            ))}
          </HStack>
        </Flex>
      </Flex>
      {recipes.length > 0 ? (
        <Grid templateColumns={['repeat(1, 1fr)', 'repeat(auto-fill, minmax(340px, 1fr))']} gap={[6, 6, 8, 8, 10]}>
          {recipes.map((recipe) => (
            <AutomationRecipe
              key={recipe.title + recipe.integration}
              integration={recipe.integration}
              title={recipe.title}
              description={recipe.description}
              config={recipe.config}
            />
          ))}
        </Grid>
      ) : (
        <Text fontSize="sm" color="gray.500" marginTop={4}>
          No templates found.
        </Text>
      )}
    </Stack>
  )
}

export interface AutomationRecipeProps {
  title: string
  description: string
  integration: keyof typeof channelLogos
  config: any
}

export function AutomationRecipe(props: AutomationRecipeProps) {
  const params = qs.stringify(
    { follow_rule: props.config },
    { arrayFormat: 'brackets', skipNulls: true, encodeValuesOnly: true }
  )

  return (
    <Card display="flex" flexDir="column" gap={7} padding={7} rounded="xl">
      <Stack spacing={3.5}>
        <Flex h="30px" w="30px" alignItems="center">
          <Iconify icon={channelLogos[props.integration]} size={30} />
        </Flex>
        <Heading size="sm">{props.title}</Heading>
        <Text fontSize="sm" color="gray.500">
          {props.description}
        </Text>
      </Stack>
      <Button
        as="a"
        href={projectPath(`/automations/new?${params}`)}
        variant="outline"
        rightIcon={<IconChevronRight size={16} />}
        marginTop="auto"
      >
        Try it
      </Button>
    </Card>
  )
}
