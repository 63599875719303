import { colord, Colord, extend } from 'colord'
import a11yPlugin from 'colord/plugins/a11y'
import harmoniesPlugin from 'colord/plugins/harmonies'

extend([a11yPlugin, harmoniesPlugin])

export function readableColorIsBlack(color: string | Colord) {
  // normal black text on the input bg conforms to WCAG AA
  return colord('#000000').isReadable(color)
}

export function readableColor(color: string | Colord): string {
  return readableColorIsBlack(color) ? '#000' : '#fff'
}

export function isValidColor(color: string | Colord) {
  return colord(color).isValid()
}

export { colord }
