import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { IconArrowLeft, IconPlus, IconTriangleSquareCircle } from '@tabler/icons-react'
import update from 'immutability-helper'
import { cloneDeep, find, startCase } from 'lodash'
import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'sonner'
import { post } from '../../../../../lib/api'
import Router from '../../../../../lib/router'
import { SfOpportunityIcon2 } from '../../../../ui/icons/SalesforceIcons'
import { projectPath } from '../../../../ui/ProjectsContext'
import SquareIcon from '../../../../ui/SquareIcon'
import { ContentReport, CustomEventFilter, StageTransitionFilter } from '../types'

const FILTER_PLACEHOLDERS = {
  page_view: '/docs',
  form_submission: '/signup',
  event: 'Demo Booked'
}

interface ReportBuilderProps {
  onReset: () => void
  report: ContentReport
}

function validateReport(report: ContentReport) {
  if (report.kind === 'custom_events') {
    return allCustomEventsSpecsFilled(report.settings.custom_events)
  } else if (report.kind === 'opportunities') {
    return atLeastOneStageTransitionChecked(report.settings.stage_transitions)
  }

  return true
}

function allCustomEventsSpecsFilled(customEvents: CustomEventFilter[]) {
  return !find(customEvents, (e) => e.event_filter === '')
}

function atLeastOneStageTransitionChecked(stageTransitions: StageTransitionFilter[]) {
  return !!find(stageTransitions, (t) => t.checked)
}

export default function ReportBuilderForm(props: ReportBuilderProps) {
  const [report, setReport] = useState<ContentReport>(props.report)
  const [preflightFailed, setPreflightFailed] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const isValid = validateReport(report)

  useEffect(() => {
    setReport(props.report)
  }, [props.report])

  const allChecked = report.settings.stage_transitions?.every((t) => t.checked) || false

  const updateWindow = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setReport((prev) => {
      return update(prev, {
        settings: {
          $merge: {
            window: parseInt(e.target.value, 10)
          }
        }
      })
    })
  }, [])

  const updateDaysAgo = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setReport((prev) => {
      return update(prev, {
        settings: {
          $merge: {
            from_days_ago: parseInt(e.target.value, 10)
          }
        }
      })
    })
  }, [])

  const addCustomEventSpec = useCallback(() => {
    setReport((prev) => {
      return update(prev, {
        settings: {
          custom_events: {
            $push: [{ event_type: 'all', event_filter: '' }]
          }
        }
      })
    })
  }, [])

  const removeCustomEventSpec = useCallback((index) => {
    setReport((prev) => {
      return update(prev, {
        settings: {
          custom_events: {
            $splice: [[index, 1]]
          }
        }
      })
    })
  }, [])

  const setCustomEventAttribute = useCallback((index, attr, value) => {
    setReport((prev) => {
      return update(prev, {
        settings: {
          custom_events: {
            [index]: {
              $merge: {
                [attr]: value
              }
            }
          }
        }
      })
    })
  }, [])

  const setStageTransitionChecked = useCallback((index, checked) => {
    setReport((prev) => {
      return update(prev, {
        settings: {
          stage_transitions: {
            [index]: {
              $merge: {
                checked
              }
            }
          }
        }
      })
    })
  }, [])

  const toggleAllStageTransitions = useCallback(() => {
    setReport((prev) => {
      return update(prev, {
        settings: {
          stage_transitions: {
            $set: prev.settings.stage_transitions.map((t) => ({ ...t, checked: !allChecked }))
          }
        }
      })
    })
  }, [allChecked])

  const generateReport = useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      let payload = cloneDeep(report)

      // omit the checked property and count from the payload
      // and only send selected stage transitions
      if (payload.settings.stage_transitions) {
        payload = update(payload, {
          settings: {
            stage_transitions: {
              $set: report.settings.stage_transitions
                .filter((t) => t.checked)
                .map((t) => ({ from: t.from, to: t.to })) as any
            }
          }
        })
      }
      setIsGenerating(true)
      post(projectPath('/reports/contents/preflight'), {
        content_report: payload
      })
        .then((data: any) => {
          if (!data.passed) {
            setPreflightFailed(true)
            setIsGenerating(false)
            return
          }
          setPreflightFailed(false)
          post(projectPath('/reports/contents'), {
            content_report: payload
          })
            .then((data: any) => {
              setIsGenerating(false)
              toast.success('Report generated!')
              Router.visit(data.url)
            })
            .catch(() => {
              toast.error('There was an error generating the report.')
              setIsGenerating(false)
            })
        })
        .catch(() => {
          toast.error('There was an error generating the report.')
          setIsGenerating(false)
          setPreflightFailed(false)
        })
    },
    [report]
  )

  const sortedTransitions = useMemo(
    () => (report.settings.stage_transitions ?? []).sort((a, b) => (b.count ?? 0) - (a.count ?? 0)),
    [report.settings.stage_transitions]
  )

  return (
    <Stack as="form" spacing={10} onSubmit={generateReport} w="100%">
      <HStack spacing={3}>
        <Button
          size="xs"
          variant="link"
          leftIcon={<IconArrowLeft size={14} />}
          iconSpacing={1.5}
          isDisabled={isGenerating}
          onClick={props.onReset}
        >
          Back to report type selection
        </Button>
        <Text fontSize="xs" color="gray.400">
          /
        </Text>
        <HStack spacing={1}>
          {report.kind === 'opportunities' ? (
            <SquareIcon icon={SfOpportunityIcon2} iconSize={4} padding={1} colorScheme="orange" rounded="md" />
          ) : (
            <SquareIcon icon={IconTriangleSquareCircle} iconSize={4} padding={1} colorScheme="pink" rounded="md" />
          )}
          <Text fontSize="xs" fontWeight="medium">
            {startCase(report.kind)} Report
          </Text>
        </HStack>
      </HStack>

      {report.kind === 'custom_events' && (
        <ReportBuilderSection
          header="Conversion Events"
          blurb="Pick the events you want to use to define a conversion."
        >
          <Stack paddingY={2}>
            {report.settings.custom_events.map((spec, i) => (
              <HStack key={i}>
                <FormControl size="sm">
                  <FormLabel>Event Type</FormLabel>
                  <Select
                    size="sm"
                    value={spec.event_type}
                    onChange={(e) => setCustomEventAttribute(i, 'event_type', e.target.value)}
                  >
                    <option value="all">All</option>
                    <option value="page_view">Page View</option>
                    <option value="form_submission">Form Submission</option>
                    <option value="event">Custom Event</option>
                  </Select>
                </FormControl>
                <FormControl size="sm">
                  <FormLabel>Filter</FormLabel>
                  <Input
                    size="sm"
                    width={400}
                    value={spec.event_filter}
                    placeholder={FILTER_PLACEHOLDERS[spec.event_type] && `Ex: ${FILTER_PLACEHOLDERS[spec.event_type]}`}
                    onChange={(e) => setCustomEventAttribute(i, 'event_filter', e.target.value)}
                  />
                </FormControl>
                <FormControl width="auto">
                  <FormLabel>&nbsp;</FormLabel>
                  <Button
                    size="sm"
                    isDisabled={report.settings.custom_events.length == 1}
                    onClick={() => removeCustomEventSpec(i)}
                  >
                    Remove
                  </Button>
                </FormControl>
              </HStack>
            ))}

            <Button
              size="sm"
              alignSelf="flex-start"
              isDisabled={!allCustomEventsSpecsFilled(report.settings.custom_events)}
              onClick={addCustomEventSpec}
              iconSpacing={1.5}
              leftIcon={<IconPlus size={14} />}
            >
              Add another event
            </Button>
          </Stack>
        </ReportBuilderSection>
      )}
      {report.kind === 'opportunities' && (
        <ReportBuilderSection
          header="Stage Transitions"
          blurb="Pick which opportunity stage changes to include as conversions."
        >
          <Box maxHeight="320px" overflow="auto" marginX={-2}>
            <TableContainer>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th width="1px" pl={2} pr={0}>
                      <Checkbox isChecked={allChecked} onChange={toggleAllStageTransitions} />
                    </Th>
                    <Th>From</Th>
                    <Th>To</Th>
                    <Th isNumeric>Total</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {sortedTransitions.map((spec, i) => (
                    <Tr key={i} cursor="pointer" _hover={{ bg: 'gray.50' }}>
                      <Td width="1px" pl={2} pr={0}>
                        <Checkbox
                          isChecked={spec.checked}
                          onChange={(e) => setStageTransitionChecked(i, e.target.checked)}
                        />
                      </Td>
                      <Td onClick={() => setStageTransitionChecked(i, !spec.checked)}>
                        {spec.from ?? 'New opportunity ✨'}
                      </Td>
                      <Td onClick={() => setStageTransitionChecked(i, !spec.checked)}>{spec.to}</Td>
                      <Td isNumeric color="purple.500" fontSize="sm">
                        {spec.count?.toLocaleString()}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </ReportBuilderSection>
      )}
      <ReportBuilderSection header="Date range">
        <FormControl>
          <Select size="sm" value={String(report.settings.from_days_ago || '')} onChange={updateDaysAgo}>
            <option value="91">Past 3 months</option>
            <option value="182">Past 6 months</option>
            <option value="">Past year</option>
          </Select>
          <FormHelperText lineHeight={1.4}>What date range of events and conversions should we analyze?</FormHelperText>
        </FormControl>
      </ReportBuilderSection>
      <ReportBuilderSection header="Conversion window">
        <FormControl>
          <Select size="sm" value={report.settings.window} onChange={updateWindow} isRequired>
            <option value="7">7 days</option>
            <option value="14">14 days</option>
            <option value="28">28 days</option>
            <option value="90">90 days</option>
          </Select>
          <FormHelperText lineHeight={1.4}>
            This is a "lookback window" for activity leading up to conversion. For example, if you pick 7 days, we'll
            look at all accounts that reached your conversion event/goal within 7 days of interacting with your content.
            If you're wondering about more "top of the funnel" content, we recommend 28-day windows. If you're wondering
            about "Sales-ready" signals, we recommend 7-day windows.
          </FormHelperText>
        </FormControl>
      </ReportBuilderSection>
      <Box>
        <Button
          type="submit"
          colorScheme="purple"
          isDisabled={!isValid}
          isLoading={isGenerating}
          loadingText="Generating report"
        >
          Generate Report
        </Button>
      </Box>
      {!isGenerating && preflightFailed && (
        <Alert status="warning">
          <AlertIcon />
          No data found for the settings provided, please review your report parameters try again.
        </Alert>
      )}
    </Stack>
  )
}

interface ReportBuilderSectionProps {
  header: string
  blurb?: string
  children: React.ReactNode
}

function ReportBuilderSection(props: ReportBuilderSectionProps) {
  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Heading size="xs">{props.header}</Heading>
        <Text fontSize="sm" color="gray.600">
          {props.blurb}
        </Text>
      </Stack>
      {props.children}
    </Stack>
  )
}
