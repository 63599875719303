import { Icon, IconProps } from '@chakra-ui/react'
import React from 'react'

interface Props extends IconProps {
  size?: string | number
}

export function CheckboxCircleIcon({ size, ...props }: Props) {
  size = size ? `${size}px` : size
  return (
    <Icon viewBox="0 0 24 24" fill="currentColor" width={size} height={size} {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-.997-6l7.07-7.071-1.414-1.414-5.656 5.657-2.829-2.829-1.414 1.414L11.003 16z" />
    </Icon>
  )
}
