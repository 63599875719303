import { TableFooter } from '@app/components/ui/TableFooter'
import {
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import {
  IconAlignJustified,
  IconChevronRight,
  IconDotsVertical,
  IconLayoutColumns,
  IconUsers
} from '@tabler/icons-react'
import React, { useCallback, useMemo } from 'react'
import router from '../../../lib/router'
import { Apps } from '../../../types/App'
import { Crm } from '../../../types/Crm'
import { PageMeta } from '../../../types/PageMeta'
import { FacetParams } from '../../data/use-facets'
import { useUrlFilters } from '../../data/use-url-filters'
import { ColumnManagementPopover, enabledProfileColumns, TableMode, useColumns } from '../../ui/ColumnSelector'
import DownloadCsvMenuItem from '../../ui/DownloadCsvButtons'
import PageTitle, { SmallPageHeading } from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { TopBarContent } from '../../ui/TopBarContext'
import { PartialAccountView, ViewSelector } from '../../ui/ViewSelector'
import { FacetFilters } from '../accounts'
import {
  NoPixelEmptyState,
  NoRevealEmptyState,
  VisitorEmptyState
} from '../accounts/components/empty-states/AccountEmptyState'
import { FilterPreview } from '../accounts/components/FilterPreview'
import { SaveAccountView } from '../account_views/components/AccountViewForm'
import { NewListModal } from '../account_views/components/NewListModal'
import { accountViewPath } from '../account_views/lib/list-paths'
import { ListContainer, useListsNav } from '../lists/components/ListContainer'
import { useTrackRecentNavItems } from '../navigation/useTrackRecentNavItems'
import { HighlightedProfile, ProfileList } from './components/profile-list'
import { VisitorTabs } from './components/visitor-tabs'

export interface ProfilesProps {
  page_meta: PageMeta
  total_in_workspace?: number
  total_hidden?: number
  facet_filters: FacetFilters
  columns: string[]
  sort_by?: string
  crm?: Crm
  profiles: HighlightedProfile[]
  apps: Apps
  permissions?: Record<'can_edit' | 'can_create' | 'can_destroy', boolean>
  data_flowing: boolean
  has_company_matches: boolean
  total_count?: number
  selected_range?: FacetParams['range']
  profile_sources?: string[]
}

const allPeopleList = {
  name: 'Everyone',
  slug: 'all-people',
  kind: 'profile'
} as const

export default function Index(props: ProfilesProps) {
  const navContext = useListsNav()

  const apps = useMemo(() => Object.values(props.apps), [props.apps])

  const hasCompanies = useMemo(
    () => props.has_company_matches && (props.total_in_workspace ?? 0) > 0,
    [props.has_company_matches, props.total_in_workspace]
  )

  const facets = useUrlFilters({
    initialRange: props.selected_range ?? 'any',
    initialSortBy: props.sort_by,
    facetCloudPath: '/profiles/facet-cloud'
  })

  const { columns, loadingColumns, onColumnChange, onColumnRemove } = useColumns({
    data: props.profiles,
    columns: props.columns?.length
      ? props.columns
      : enabledProfileColumns(apps, props.profile_sources ?? []).map((c) => c.key),
    initialColumns: props.columns?.length
      ? props.columns
      : enabledProfileColumns(apps, props.profile_sources ?? []).map((c) => c.key)
  })

  const isDirty = useMemo(() => !facets.onlyDefaults, [facets.onlyDefaults])

  const { trackRecentNavItem } = useTrackRecentNavItems()

  const changeView = useCallback(
    (accountView: PartialAccountView | null) => {
      if (accountView?.id && accountView?.slug) {
        const path = accountViewPath(accountView)
        trackRecentNavItem(`accountView:${accountView.id}`)
        router.visit(path)
      } else if (accountView?.id && accountView.class_name === 'StaticList') {
        const path = projectPath(`/lists/${accountView.id}`)
        trackRecentNavItem(`staticList:${accountView.id}`)
        router.visit(path)
      }
    },
    [trackRecentNavItem]
  )

  const newListModal = useDisclosure()

  return (
    <ListContainer paddingX={0} paddingY={0} gap={0} maxH="100%" minH="300px">
      <TopBarContent onlyNewNav>
        <Flex alignItems="center" gap={1.5}>
          <SmallPageHeading size="xs" fontWeight="medium">
            People
          </SmallPageHeading>

          <Icon as={IconChevronRight} color="gray.400" boxSize={4} />

          <Box ml={-1}>
            <ViewSelector
              placeholder={allPeopleList}
              kind="profile"
              ownership={['private', 'shared']}
              includePrivate
              onChange={changeView}
              onCreateAccountview={newListModal.onOpen}
            />
            <NewListModal {...newListModal} />
          </Box>
        </Flex>
      </TopBarContent>

      <Flex w="100%" alignItems="center" justifyContent="space-between" gap={2} py={3} px={4}>
        <PageTitle skipRendering>People</PageTitle>

        <HStack flex="1 1 100%" width="100%" justifyContent="space-between" spacing={3}>
          <HStack flex="none" spacing={3}>
            {navContext.offScreen ? (
              <IconAlignJustified size={20} onClick={navContext.onOpen} />
            ) : (
              <IconUsers size={20} />
            )}
            <Heading display="inline-flex" gap={1} alignItems="baseline" size="md">
              People{'  '}
              <Text as="span" marginLeft={4}>
                {props.page_meta.total_count.toLocaleString()}
              </Text>
            </Heading>
          </HStack>
        </HStack>

        <Flex gap={2} alignItems="center">
          <VisitorTabs />

          <ColumnManagementPopover
            audienceKind="profile"
            apps={apps}
            selectedColumns={columns}
            onChange={onColumnChange}
          >
            <IconButton
              aria-label="Edit list"
              variant="outline"
              size="sm"
              flex="none"
              icon={<IconLayoutColumns size={18} />}
            />
          </ColumnManagementPopover>

          <Menu>
            <MenuButton
              size="sm"
              as={IconButton}
              icon={<IconDotsVertical size={16} />}
              variant="outline"
              borderColor="gray.200"
            />
            <MenuList fontSize="sm" zIndex="popover">
              <DownloadCsvMenuItem
                url={`${window.location.pathname}/export.csv${window.location.search}`}
                isMenuDisabled={
                  window.location.pathname.endsWith('/feed') || window.location.pathname.endsWith('/live')
                }
                apps={apps}
                audienceKind="profile"
                initialColumns={columns}
                allowColumnSelection
              />

              <MenuDivider />
              <TableMode />
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      <Flex justifyContent="space-between" gap={4} px={4} pb={3}>
        <FilterPreview
          {...facets}
          kind="profile"
          excludedKeys={['visitor_stats.identified.month', 'visitor_stats.visitors.month']}
          shouldShowSourceFilter={(props.profile_sources || []).length > 1}
          range={facets.range}
          apps={props.apps}
        />

        {isDirty && (
          <Box marginLeft="auto">
            <SaveAccountView
              type="profile"
              isFiltering={facets.isFiltering}
              permissions={props.permissions}
              displayColumns={columns}
            />
          </Box>
        )}
      </Flex>

      {props.profiles.length > 0 && (
        <Box display="flex" flexDirection="column" flex="1 1 auto" maxH="100%" minH="300px">
          <ProfileList
            profiles={props.profiles as unknown as HighlightedProfile[]}
            range={facets.range ?? 'week'}
            apps={apps}
            columns={columns}
            loadingColumns={loadingColumns}
            sortingBy={facets.sortBy}
            facetParams={facets}
            canAddColumns
            onColumnChange={onColumnChange}
            onColumnRemove={onColumnRemove}
            onSortChange={facets.setSortBy}
          />

          <TableFooter
            word="person"
            pageMeta={props.page_meta}
            page={(facets.page ?? 1) as number}
            setPage={facets.setPage}
            px={[3, 4]}
            sticky
          />
        </Box>
      )}

      {props.profiles.length === 0 && props.data_flowing && hasCompanies && <VisitorEmptyState facets={facets} />}
      {props.profiles.length === 0 && !props.data_flowing && <NoPixelEmptyState />}
      {props.profiles.length === 0 && props.data_flowing && !hasCompanies && <NoRevealEmptyState />}
    </ListContainer>
  )
}
