import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Link,
  Stack,
  Text,
  Textarea,
  Tooltip
} from '@chakra-ui/react'
import { orderBy, uniqBy } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { toast } from 'sonner'
import { humanize } from '../../../accounts/facets/filter-cloud'
import { IngredientSelector } from './ingredient-selector'
import { MessagePreview } from './message-preview'
import { SlackButton, SlackKV, SlackMessageTemplate } from './types'
import { useNotificationVariables } from './use-notification-variables'

export const buttons: Record<string, SlackButton> = {
  'Open in Koala': {
    index: 0,
    id: 'notification',
    text: 'Open in Koala',
    style: 'primary',
    url: '{{notification.permalink}}'
  },
  Account: {
    index: 1,
    id: 'account',
    text: 'Account',
    url: '{{account.permalink}}'
  },
  Visitor: {
    index: 2,
    id: 'visitor',
    text: 'Visitor',
    url: '{{visitor.permalink}}'
  },
  Salesforce: {
    index: 3,
    id: 'salesforce',
    text: 'Open in Salesforce',
    url: '{{account.salesforce_permalink}}'
  },
  Hubspot: {
    index: 4,
    id: 'hubspot',
    text: 'Open in HubSpot',
    url: '{{account.hubspot_permalink}}'
  }
}

interface Props {
  formRef?: React.RefObject<HTMLFormElement>
  template?: Partial<SlackMessageTemplate>
  onChange: (template: SlackMessageTemplate) => void
  targetType: 'Account' | 'Profile'
}

export function MessageBuilder(props: Props) {
  const variables = useNotificationVariables()

  let defaultContent = [['Account', '{{account.domain}}']]
  if (props.targetType === 'Profile') {
    defaultContent = defaultContent.concat(['Visitor', '{{visitor.display_name}}'])
  }

  const [template, setTemplate] = React.useState<SlackMessageTemplate>({
    title: '{{actor}} triggered {{notification.name}}',
    content: defaultContent,
    img: '{{account.logo_url}}',
    buttons: [buttons['Open in Koala'], buttons['Account'], buttons['Visitor']],
    ...props.template
  })

  const markdownBlocks = useMemo(() => {
    return (template.content ?? []).filter((line) => {
      return typeof line === 'string'
    })
  }, [template.content])

  const kvBlocks = useMemo(() => {
    return (template.content ?? []).filter((line) => {
      return Array.isArray(line)
    })
  }, [template.content]) as SlackKV[]

  useEffect(() => {
    if (props.template?.title) {
      setTemplate((prev) => ({ ...prev, title: props.template?.title }))
    }
  }, [props.template?.title])

  useEffect(() => {
    props.onChange?.(template)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template, props.onChange])

  return (
    <Stack pt="4" spacing="2">
      <Stack px="6" spacing="4" bg="#ebeefc38" borderLeftColor="purple.100" borderLeftWidth={'thick'} py="4">
        <FormControl>
          <FormLabel mb="1">Action Buttons</FormLabel>
          <HStack flexWrap={'wrap'} gap="1" spacing="1">
            {Object.keys(buttons).map((key) => {
              if (props.targetType === 'Account' && key === 'Visitor') {
                return null
              }

              return (
                <Checkbox
                  bg="blue.50"
                  px="2"
                  py="1"
                  key={key + buttons[key].index}
                  size="sm"
                  borderColor={'blue.500'}
                  borderWidth="1px"
                  rounded="md"
                  isChecked={template.buttons?.some((b) => b.text === buttons[key].text)}
                  onChange={(e) => {
                    const isChecked = e.target.checked

                    let btns = isChecked
                      ? [...(template.buttons ?? []), buttons[key]]
                      : (template.buttons ?? []).filter((b) => b.id !== buttons[key].id)

                    btns = uniqBy(btns, (b) => b.id)
                    btns = orderBy(btns, ['index'], ['asc'])
                    setTemplate((prev) => ({ ...prev, buttons: btns }))
                  }}
                >
                  <Text fontSize={'xs'}>{key}</Text>
                </Checkbox>
              )
            })}
          </HStack>
        </FormControl>
        <FormControl>
          <FormLabel mb="1">Content Fields</FormLabel>
          <Stack spacing="4">
            <HStack fontSize={'xs'} spacing="1" columnGap="1" flexWrap={'wrap'} rowGap="2">
              {(template.content ?? []).map((row) => {
                if (Array.isArray(row)) {
                  const isInvalid = row[0].startsWith('Visitor') && props.targetType === 'Account'

                  return (
                    <HStack
                      key={row[0]}
                      bg={isInvalid ? 'orange.50' : 'purple.50'}
                      w="fit-content"
                      pl="2"
                      rounded="md"
                      borderColor={isInvalid ? 'orange.500' : 'purple.500'}
                      borderWidth="1px"
                    >
                      <Tooltip
                        hasArrow
                        label={isInvalid ? 'Visitor variables are not available for Account Notifications' : ''}
                      >
                        <Text
                          style={{
                            textDecoration: isInvalid ? 'line-through' : 'none'
                          }}
                        >
                          {row[0]}
                        </Text>
                      </Tooltip>
                      <Button
                        size="xs"
                        colorScheme={isInvalid ? 'yellow' : 'purple'}
                        onClick={() => {
                          setTemplate((prev) => ({
                            ...prev,
                            content: (prev.content ?? []).filter((r) => r !== row)
                          }))
                        }}
                        variant="ghost"
                        fontSize={'xs'}
                      >
                        &times;
                      </Button>
                    </HStack>
                  )
                }

                return null
              })}
            </HStack>

            {markdownBlocks.length > 0 && (
              <Stack>
                {markdownBlocks.map((row, index) => {
                  return (
                    <HStack key={index}>
                      <Textarea
                        fontSize={'sm'}
                        bg="white"
                        rows={8}
                        onChange={(e) => {
                          setTemplate((prev) => ({
                            ...prev,
                            content: [...kvBlocks, e.target.value]
                          }))
                        }}
                        defaultValue={row as string}
                      />
                      <Button
                        size="xs"
                        colorScheme={'purple'}
                        onClick={() => {
                          setTemplate((prev) => ({
                            ...prev,
                            content: [...kvBlocks]
                          }))
                        }}
                        variant="ghost"
                        fontSize={'xs'}
                      >
                        &times;
                      </Button>
                    </HStack>
                  )
                })}

                <Flex fontSize={'xs'} gap="1">
                  <Text>Supports</Text>
                  <Link color={'blue.500'} href="https://api.slack.com/reference/surfaces/formatting" target="_blank">
                    Slack Markdown formatting
                  </Link>{' '}
                  <Text>and</Text>
                  <Link color="blue.500" href="https://shopify.github.io/liquid/tags/control-flow/" isExternal>
                    Liquid Templates
                  </Link>
                </Flex>
              </Stack>
            )}

            <HStack>
              <IngredientSelector
                {...variables}
                targetType={props.targetType}
                onSelect={(item) => {
                  toast.message('Field Added', {
                    description: `${humanize(item.label)} added to your message template`
                  })

                  const tpl = `{{${item.key}}}`
                  if (template.content?.some((c) => c?.[1] === tpl)) {
                    return
                  }

                  setTemplate((prev) => ({
                    ...prev,
                    content: [...(prev.content ?? []), [humanize(item.label), tpl]]
                  }))
                }}
              />
              <Button
                size="xs"
                variant={'ghost'}
                colorScheme={'purple'}
                disabled={markdownBlocks.length > 0}
                onClick={() => {
                  setTemplate({
                    ...template,
                    content: [
                      ...(template.content ?? []),
                      `Markdown Content\n{{account.domain}}\n{{account.permalink}}`.trim()
                    ]
                  })
                }}
              >
                Add Mrkdwn
              </Button>
            </HStack>
          </Stack>
        </FormControl>
      </Stack>
      <MessagePreview {...template} {...variables} targetType={props.targetType} />
    </Stack>
  )
}
