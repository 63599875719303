import {
  Box,
  Button,
  Checkbox,
  Circle,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Link,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import { IconCalendarExclamation, IconCircleDashed, IconEdit, IconLineDashed, IconPlus } from '@tabler/icons-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'sonner'
import { useDebounce } from 'use-debounce'
import { postForm } from '../../../lib/api'
import dayjs from '../../../lib/dayjs'
import { PageMeta } from '../../../types/PageMeta'
import { ProfileRecord } from '../../../types/Profile'
import { Task } from '../../../types/Task'
import { useAssociation } from '../../data/use-association'
import { OmnisearchAccount, OmnisearchProfile, useOmnisearch } from '../../data/use-omnisearch'
import { useUpdateTask } from '../../data/use-update-task'
import { useUsers } from '../../data/use-users'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import Avatar from '../../ui/Avatar'
import { CardRadioGroup } from '../../ui/CardRadioGroup'
import { ComboboxWithSearch } from '../../ui/ComboboxWithSearch'
import CompanyAvatar from '../../ui/CompanyAvatar'
import { UserCircleIcon } from '../../ui/icons'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import SelectInput from '../../ui/SelectInput'
import { TableFooter } from '../../ui/TableFooter'
import { useCurrentUser } from '../../ui/UserContext'
import { mergeParams } from '../icps/types'

interface Props {
  page_meta: PageMeta
  tasks: Task[]
  task?: Task
  errors?: unknown[]
}

export default function Index({ task, errors, tasks, page_meta }: Props) {
  const disclosure = useDisclosure({ defaultIsOpen: !!task })
  const onOpen = disclosure.onOpen
  const [editTask, setEditTask] = useState<Partial<Task> | undefined>(task)
  const [localTasks, setLocalTasks] = useState<Task[]>(tasks)

  const updateTask = useUpdateTask()

  useEffect(() => {
    if (task) {
      setEditTask(task)
      onOpen()
    }
  }, [onOpen, task])

  useEffect(() => {
    setLocalTasks(tasks)
  }, [tasks])

  const updateLocalTask = useCallback((changes: { id: number } & Partial<Task>) => {
    setLocalTasks((tasks) => {
      return tasks.map((task) => {
        if (task.id === changes.id) {
          return {
            ...task,
            ...changes
          }
        }

        return task
      })
    })
  }, [])

  return (
    <>
      <PageLayout size="md">
        <HStack justifyContent="space-between">
          <PageTitle>Tasks</PageTitle>
          <Button
            size="sm"
            colorScheme="purple"
            iconSpacing={1.5}
            leftIcon={<IconPlus size={16} />}
            onClick={() => {
              setEditTask(undefined)
              disclosure.onOpen()
            }}
          >
            New Task
          </Button>
        </HStack>

        <Stack>
          {localTasks.map((task) => (
            <Box key={task.id}>
              <Flex
                role="group"
                alignItems="center"
                gap={3}
                paddingY={2}
                paddingX={3}
                marginX={-3}
                rounded="lg"
                _hover={{ bg: 'background.light' }}
              >
                <Checkbox
                  size="md"
                  defaultChecked={!!task.completed_at}
                  onChange={(e) => {
                    const completed = e.target.checked

                    // keep track of previous state
                    const prev = task.completed_at

                    // optimistic update
                    updateLocalTask({ id: task.id, completed_at: completed ? new Date().toISOString() : undefined })

                    updateTask
                      .mutateAsync({ id: task.id, task: { completed } })
                      .then((res) => {
                        updateLocalTask(res.task)
                      })
                      .catch((error: any) => {
                        // revert optimistic update
                        updateLocalTask({ id: task.id, completed_at: prev })

                        toast.error(`Failed to update this task as ${completed ? 'complete' : 'incomplete'}`, {
                          description: error?.body?.message
                        })
                      })
                  }}
                />
                <HStack
                  flex="1 1 auto"
                  spacing={1}
                  fontSize="sm"
                  fontWeight="medium"
                  color={task.completed_at ? 'gray.500' : undefined}
                >
                  <Text textTransform="capitalize" textDecoration={task.completed_at ? 'line-through' : undefined}>
                    {task.task_type}
                  </Text>
                  {task.resource && (
                    <Link
                      href={task.resource.permalink}
                      isExternal
                      fontWeight="semibold"
                      textDecoration={task.completed_at ? 'line-through' : undefined}
                    >
                      {task.resource.name}
                    </Link>
                  )}
                  <IconButton
                    aria-label="Edit task"
                    size="xs"
                    variant="ghost"
                    color="gray.500"
                    visibility="hidden"
                    _hover={{ color: 'gray.800' }}
                    _groupHover={{ visibility: 'visible' }}
                    icon={<IconEdit size={18} />}
                    onClick={() => {
                      setEditTask(task)
                      disclosure.onOpen()
                    }}
                  />
                </HStack>
                <HStack spacing={3} marginLeft="auto">
                  <DueDate task={task} />
                  <Assignee task={task} />
                </HStack>
              </Flex>
            </Box>
          ))}

          <TableFooter
            pageMeta={page_meta}
            page={page_meta.current_page}
            nextPath={mergeParams(window.location.toString(), {
              page: String(page_meta.next_page)
            })}
            prevPath={mergeParams(window.location.toString(), {
              page: String(page_meta.prev_page)
            })}
          />
        </Stack>
      </PageLayout>

      <EditTaskDrawer {...disclosure} task={editTask} errors={errors} onChange={updateLocalTask} />
    </>
  )
}

const calendarOpts = {
  sameDay: '[Today]',
  lastDay: '[Yesterday]',
  nextDay: '[Tomorrow]',
  lastWeek: 'dddd, MMM D',
  nextWeek: 'dddd, MMM D',
  sameElse: 'dddd, MMMM D, YYYY'
}

function DueDate({ task }: { task: Task }) {
  if (!task.due_at || task.completed_at) {
    return null
  }

  let color = 'gray.500'
  let icon
  let pastDue = task.past_due
  const today = dayjs()

  const due = dayjs(task.due_at)
  if (due.isBefore(today, 'day')) {
    color = 'red.500'
    icon = IconCalendarExclamation
    pastDue = true
  } else if (due.isSame(today, 'day')) {
    color = 'red.500'
  } else if (due.isSame(today.add(1, 'day'), 'day')) {
    color = 'yellow.500'
  }

  return (
    <HStack fontSize="xs" fontWeight="medium" color={color}>
      {icon && <Icon as={icon} boxSize="18px" />}
      <Text>{pastDue ? due.fromNow() : due.calendar(undefined, calendarOpts)}</Text>
    </HStack>
  )
}

function Assignee({ task }: { task: Task }) {
  const assignee = task.assignee

  if (!assignee) {
    return (
      <Circle borderWidth="1.5px" borderStyle="dashed" borderColor="gray.400" overflow="hidden">
        <Icon boxSize="21px" bg="gray.400" as={UserCircleIcon} color="white" />
      </Circle>
    )
  }

  return <Avatar size="xs" name={assignee?.name || assignee?.email} src={assignee?.avatar} />
}

interface EditTaskDrawerProps extends UseDisclosureProps {
  task?: Partial<Task> | null
  errors?: unknown[]
  onChange?: (changes: { id: number } & Partial<Task>) => void
}

function EditTaskDrawer({ task, errors, onChange, ...props }: EditTaskDrawerProps) {
  const disclosure = useDisclosure(props)
  const onClose = disclosure.onClose
  const user = useCurrentUser()
  const [taskType, setTaskType] = useState<string | undefined>(task?.task_type)
  const [resourceType, setResourceType] = useState<string | undefined>(task?.resource_type)
  const [resourceId, setResourceId] = useState<string | undefined>(task?.resource_id)
  const [assigneeId, setAssigneeId] = useState<string | null>(task ? task.assignee_id || null : user?.id || null)
  const [dueAt, setDueAt] = useState<dayjs.Dayjs | undefined>(task?.due_at ? dayjs(task.due_at) : undefined)
  const [submitting, setSubmitting] = useState(false)

  const visitorId = resourceType === 'Profile' ? resourceId : undefined
  const accountId = resourceType === 'Account' ? resourceId : undefined

  const [selectedVisitor, setSelectedVisitor] = useState<PartialVisitor | null>(null)
  const [selectedAccount, setSelectedAccount] = useState<PartialAccount | null>(null)

  const { data: visitor, isLoading: visitorIsLoading } = useAssociation({ id: visitorId })

  useEffect(() => {
    if (!visitorIsLoading && visitor) {
      setSelectedVisitor(visitor)
      setSelectedAccount(visitor.account || null)
    } else if (!visitorId) {
      setSelectedAccount(null)
    }
  }, [visitorIsLoading, visitor, visitorId, selectedVisitor])

  const resetState = useCallback(() => {
    setResourceType(task?.resource_type)
    setResourceId(task?.resource_id)
    setTaskType(task?.task_type)
    setAssigneeId(task ? task.assignee_id || null : user?.id || null)
    setDueAt(task?.due_at ? dayjs(task.due_at) : undefined)
    setSubmitting(false)
    setSelectedVisitor(null)
    setSelectedAccount(null)
  }, [task, user?.id])

  useEffect(() => {
    resetState()
  }, [task?.id, resetState])

  useEffect(() => {
    resetState()
  }, [disclosure.isOpen, resetState])

  const updateVisitorResource = useCallback((visitor: PartialVisitor | null) => {
    if (visitor?.id) {
      setResourceType('Profile')
      setResourceId(visitor.id)
      setSelectedVisitor(visitor)
    } else {
      setResourceType(undefined)
      setResourceId(undefined)
      setSelectedVisitor(null)
    }
  }, [])

  const updateAccountResource = useCallback((accountId: string | null) => {
    if (accountId) {
      setResourceType('Account')
      setResourceId(accountId)
    } else {
      setResourceType(undefined)
      setResourceId(undefined)
    }
  }, [])

  const onSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      setSubmitting(true)
      if (!task?.id) {
        return
      }

      event.preventDefault()

      const form = event.target as HTMLFormElement
      const data = new FormData(form)

      try {
        const res = await postForm<{ task: Task }>(form.action, data)
        onChange?.(res.task)
        onClose()
      } catch (error: any) {
        toast.error('Failed to update your task', {
          description: error?.body?.message
        })
      } finally {
        setSubmitting(false)
      }
    },
    [task?.id, onClose, onChange]
  )

  const formId = task?.id ? `edit-task-${task.id}` : `new-task-form`
  const formAction = task?.id ? `/tasks/${task.id}` : `/tasks`

  return (
    <Drawer
      {...disclosure}
      size="md"
      placement="right"
      preserveScrollBarGap
      closeOnOverlayClick={!submitting}
      closeOnEsc={!submitting}
    >
      <DrawerOverlay zIndex="modal" />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader fontSize="lg">{task?.id ? 'Edit Task' : 'Create Task'}</DrawerHeader>
        <DrawerBody>
          <form id={formId} action={projectPath(formAction)} method="POST" onSubmit={onSubmit}>
            {task?.id && <input type="hidden" value="PATCH" name="_method" />}

            <AuthenticityToken />
            <input type="hidden" name="task[resource_id]" value={resourceId || ''} />
            <input type="hidden" name="task[resource_type]" value={resourceId ? resourceType || '' : ''} />

            <Stack spacing={12} paddingBottom={10}>
              <Stack spacing={5}>
                <FormControl size="sm" isRequired>
                  <FormLabel>Type</FormLabel>
                  <CardRadioGroup
                    size="sm"
                    name="task[task_type]"
                    value={taskType}
                    onChange={setTaskType}
                    options={[
                      {
                        label: 'Prospect',
                        value: 'prospect'
                      },
                      {
                        label: 'Call',
                        value: 'call'
                      },
                      {
                        label: 'Email',
                        value: 'email'
                      }
                    ]}
                  />
                </FormControl>
                <FormControl size="sm">
                  <FormLabel>Add a visitor</FormLabel>
                  <VisitorSelect
                    selectedVisitor={selectedVisitor}
                    selectedVisitorId={visitorId}
                    selectedAccountId={accountId}
                    onChange={updateVisitorResource}
                  />
                </FormControl>
                <FormControl size="sm" isReadOnly={resourceType === 'Profile'}>
                  <FormLabel>Add an account</FormLabel>
                  <AccountSelect
                    selectedAccountId={accountId || selectedAccount?.id}
                    selectedVisitorId={visitorId}
                    isLoading={visitorIsLoading}
                    onChange={updateAccountResource}
                  />
                </FormControl>
              </Stack>
              <Stack spacing={5}>
                <FormControl size="sm">
                  <FormLabel>Assigned to</FormLabel>
                  <input type="hidden" name="task[assignee_id]" value={assigneeId || ''} />
                  <UserSelect selectedUserId={assigneeId} onChange={setAssigneeId} />
                </FormControl>
                <FormControl size="sm">
                  <FormLabel>Due date</FormLabel>
                  <input type="hidden" name="task[due_at]" value={dueAt?.toISOString() || ''} />
                  <SelectInput
                    variant="outline"
                    placeholder="No due date"
                    size="md"
                    selectedItem={dueAt ? { label: dayjs(dueAt).calendar(null, calendarOpts) } : null}
                    items={[
                      { label: 'No due date', value: null },
                      { label: 'Today', value: 'today' },
                      { label: 'Tomorrow', value: 'tomorrow' },
                      { label: 'In 2 days', value: 'in_2_days' },
                      { label: 'In 3 days', value: 'in_3_days' },
                      { label: 'Next week', value: 'next_week' },
                      { label: 'Next month', value: 'next_month' }
                    ]}
                    itemRenderer={(item) => (
                      <Text color="gray.600" fontWeight="medium" fontSize="sm">
                        {item?.label}
                      </Text>
                    )}
                    itemToString={(item) => item?.label || ''}
                    popperOptions={{
                      matchWidth: true
                    }}
                    onSelectedItemChange={({ selectedItem }) => {
                      setDueAt(toISODate(selectedItem?.value))
                    }}
                  />
                </FormControl>
              </Stack>
            </Stack>
          </form>
        </DrawerBody>
        <DrawerFooter gap={3} borderTop="1px solid" borderColor="gray.200">
          <Button flex="1 1 50%" variant="outline" onClick={disclosure.onClose} isDisabled={submitting}>
            Cancel
          </Button>
          <Button form={formId} flex="1 1 50%" colorScheme="purple" type="submit" isLoading={submitting}>
            {task?.id ? 'Update' : 'Create'}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

interface PartialUser {
  id?: string
  name?: string
  email?: string
  avatar?: string
  you?: boolean
}

const unassigned: PartialUser = {
  id: '',
  name: 'No assignee'
}

interface UserSelectProps {
  selectedUserId?: string | null
  onChange?: (userId: string | null) => void
}

function UserSelect({ selectedUserId, onChange }: UserSelectProps) {
  const { data, isLoading } = useUsers()
  const currentUser = useCurrentUser()

  const users: PartialUser[] = useMemo(() => {
    return [
      unassigned,
      ...(data?.users ?? []).map((u) => ({
        ...u,
        you: u.id === currentUser?.id
      }))
    ]
  }, [data, currentUser])

  const selected = useMemo(() => {
    return users.find((u) => u.id === selectedUserId) || null
  }, [users, selectedUserId])

  const handleChange = useCallback(
    (user) => {
      onChange?.(user?.id || null)
    },
    [onChange]
  )

  if (isLoading) {
    return (
      <Box minHeight="42px">
        <Spinner color="gray.400" thickness="1.5px" size="sm" />
      </Box>
    )
  }

  return (
    <ComboboxWithSearch
      items={users}
      selectedItem={selected || unassigned}
      onChange={handleChange}
      filterItem={(a, val) => a.name?.toLowerCase().includes(val) || a.email?.toLowerCase().includes(val) || false}
      itemToString={(item) => item?.name || ''}
      itemRenderer={UserRenderer}
      selectButtonRenderer={UserRenderer}
    />
  )
}

interface UserRendererProps {
  item: PartialUser | null
  selectedItem?: PartialUser | null
}

function UserRenderer(props: UserRendererProps) {
  const user = props.item || unassigned

  return (
    <HStack spacing={2.5}>
      {user === unassigned ? (
        <Circle borderWidth="1.5px" borderStyle="dashed" borderColor="gray.300" overflow="hidden">
          <Icon boxSize="21px" bg="gray.300" as={UserCircleIcon} color="white" />
        </Circle>
      ) : (
        <Avatar size="xs" name={user.name || user.email} src={user.avatar} />
      )}
      <Text fontSize="sm" fontWeight="medium">
        {user.name || user.email || 'Unknown'}
        {user.you ? ' (you)' : ''}
      </Text>
    </HStack>
  )
}

function toISODate(value) {
  const today = dayjs().endOf('day')
  const tomorrow = today.add(1, 'day').startOf('day').add(9, 'hours')
  const in2Days = today.add(2, 'day').startOf('day').add(9, 'hours')
  const in3Days = today.add(3, 'day').startOf('day').add(9, 'hours')
  const nextWeek = today.add(1, 'week').weekday(0).add(9, 'hours')
  let nextMonth = today.add(1, 'month').startOf('month').weekday(1).add(9, 'hours')
  if (nextMonth.isSame(today, 'month')) {
    nextMonth = nextMonth.weekday(8)
  }

  switch (value) {
    case 'today':
      return today
    case 'tomorrow':
      return tomorrow
    case 'in_2_days':
      return in2Days
    case 'in_3_days':
      return in3Days
    case 'next_week':
      return nextWeek
    case 'next_month':
      return nextMonth
    default:
      return undefined
  }
}

interface PartialVisitor extends OmnisearchProfile {}

const none: PartialVisitor = {
  id: '',
  display_name: 'No one'
}

interface VisitorSelectProps {
  selectedVisitor?: PartialVisitor | null
  selectedVisitorId?: string | null
  selectedAccountId?: string | null
  onChange: (visitor: PartialVisitor | null) => void
}

function VisitorSelect({ selectedVisitor, selectedVisitorId, selectedAccountId, onChange }: VisitorSelectProps) {
  const [inputValue, setInputValue] = useState<string | undefined>()
  const [query] = useDebounce(inputValue, 300)
  const { data, isLoading } = useOmnisearch(query || '*', {
    model: 'Profile',
    page_size: 50,
    facets: {
      account_id: selectedAccountId
    }
  })

  const visitors = useMemo(() => [none].concat(data?.results?.profiles ?? []), [data])

  return (
    <ComboboxWithSearch
      items={visitors}
      selectedItem={selectedVisitor || null}
      onChange={onChange}
      onInputValueChange={setInputValue}
      isLoading={isLoading}
      filterItem={(a, val) =>
        a.display_name?.toLowerCase().includes(val) || a.email?.toLowerCase().includes(val) || false
      }
      itemToString={(item) => item?.name || ''}
      itemRenderer={VisitorRenderer}
      selectButtonRenderer={VisitorRenderer}
    />
  )
}

interface VisitorRendererProps {
  item: PartialVisitor | ProfileRecord | null
  isSelected?: boolean
  isToggleButton?: boolean
}

function VisitorRenderer(props: VisitorRendererProps) {
  const visitor = props.item

  if (!visitor) {
    return (
      <HStack spacing={2.5}>
        <Icon as={IconCircleDashed} color="gray.300" boxSize={6} />
        <Text fontSize="sm" fontWeight="medium" color="gray.400">
          Select someone
        </Text>
      </HStack>
    )
  }

  return (
    <HStack spacing={2.5}>
      <Avatar
        size={props.isToggleButton ? 'xs' : 'sm'}
        name={visitor === none ? undefined : visitor.display_name || visitor.email}
        {...(visitor === none
          ? {
              backgroundColor: 'transparent',
              fallbackIcon: <IconLineDashed size={20} />
            }
          : {
              name: visitor.display_name || visitor.email
            })}
      />
      <Box>
        <Text fontSize="sm" fontWeight="medium">
          {visitor.display_name || visitor.email || 'Anonymous'}
        </Text>
        {!props.isToggleButton &&
          (visitor.title ? (
            <Text fontSize="xs" color="gray.500">
              {visitor.title}{' '}
              {visitor.company?.name || visitor.company?.domain
                ? `at ${visitor.company?.name || visitor.company?.domain}`
                : null}
            </Text>
          ) : visitor.email ? (
            <Text fontSize="xs" color="gray.500">
              {visitor.email}
            </Text>
          ) : null)}
      </Box>
    </HStack>
  )
}

interface PartialAccount extends OmnisearchAccount {}

const noAccount: PartialAccount = {
  id: '',
  name: 'No account'
}

interface AccountSelectProps {
  selectedAccountId?: string | null
  selectedVisitorId?: string | null
  isLoading?: boolean
  onChange?: (accountId: string | null) => void
}

function AccountSelect({ selectedAccountId, selectedVisitorId, isLoading, onChange }: AccountSelectProps) {
  const [inputValue, setInputValue] = useState<string | undefined>()
  const [query] = useDebounce(inputValue, 300)
  const { data, isLoading: searchIsLoading } = useOmnisearch(query || '*', {
    model: 'Account',
    page_size: 50
  })

  const { data: selectedIdMatches, isLoading: selectionIsLoading } = useOmnisearch(
    '*',
    {
      model: 'Account',
      page_size: 1,
      facets: {
        id: selectedAccountId
      }
    },
    { enabled: !!selectedAccountId }
  )

  const accounts = useMemo(() => [noAccount].concat(data?.results?.accounts ?? []), [data])
  const [selected, setSelected] = useState<PartialAccount | null>(null)

  useEffect(() => {
    if (!selectionIsLoading) {
      setSelected(selectedIdMatches?.results?.accounts?.find((v) => v.id === selectedAccountId) || null)
    }
  }, [selectedAccountId, selectedIdMatches, selectionIsLoading])

  const handleChange = useCallback(
    (account) => {
      setSelected(account || null)
      onChange?.(account?.id || null)
    },
    [onChange]
  )

  return (
    <ComboboxWithSearch
      items={accounts}
      isLoading={isLoading || searchIsLoading}
      isReadOnly={!!selectedVisitorId}
      selectedItem={selected || null}
      onChange={handleChange}
      onInputValueChange={setInputValue}
      filterItem={(a, val) => a.name?.toLowerCase().includes(val) || a.domain?.toLowerCase().includes(val) || false}
      itemToString={(item) => item?.name || item?.domain || ''}
      itemRenderer={AccountRenderer}
      selectButtonRenderer={AccountRenderer}
    />
  )
}

interface AccountRendererProps {
  item: PartialAccount | null
  selectedItem?: PartialAccount | null
}

function AccountRenderer(props: AccountRendererProps) {
  const account = props.item

  if (!account) {
    return (
      <HStack spacing={2.5}>
        <Icon as={IconCircleDashed} color="gray.300" boxSize={6} />
        <Text fontSize="sm" fontWeight="medium" color="gray.400">
          Select an account
        </Text>
      </HStack>
    )
  }

  return (
    <HStack spacing={2.5}>
      <CompanyAvatar
        size="xs"
        domain={account.domain}
        {...(account === noAccount
          ? {
              backgroundColor: 'transparent',
              fallbackIcon: <IconLineDashed size={20} />
            }
          : {
              name: account.name
            })}
      />
      <Text fontSize="sm" fontWeight="medium">
        {account.name || account.domain}
      </Text>
    </HStack>
  )
}
