import React from 'react'
import { BoxProps, LinkProps, Box } from '@chakra-ui/react'

export const TabLink = React.forwardRef(function TabLink(
  { exact, ...props }: BoxProps & Pick<LinkProps, 'href' | 'target'> & { exact?: boolean },
  ref: React.ForwardedRef<HTMLElement>
) {
  let active = false
  if (props.href) {
    if (exact) {
      active = window.location.pathname === props.href.split('?')[0]
    } else {
      active = window.location.pathname.includes(props.href.split('?')[0])
    }
  }

  return (
    <Box
      as="a"
      ref={ref as React.ForwardedRef<HTMLDivElement>}
      flex="none"
      display="flex"
      alignItems="center"
      cursor="pointer"
      tabIndex={0}
      userSelect="none"
      alignSelf="stretch"
      color={active ? 'purple.600' : 'gray.700'}
      rounded="md"
      bg={active ? 'purple.50' : 'transparent'}
      paddingX={2}
      paddingY={1}
      _hover={
        active
          ? undefined
          : {
              color: 'gray.800',
              bg: 'gray.100'
            }
      }
      _focusVisible={{
        boxShadow: 'outline',
        outline: '2px solid transparent',
        outlineOffset: '2px'
      }}
      _focus={{
        outline: 'none'
      }}
      {...props}
    />
  )
})
