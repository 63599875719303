import { Box, Flex, Heading, Spinner, Switch, SwitchProps } from '@chakra-ui/react'
import * as React from 'react'
import useLatestRef from '../../../ui/useLatestRef'

interface DescriptionProps {
  title: React.ReactNode
  field?: string
  isChecked: boolean
  isDisabled?: SwitchProps['isDisabled']
  onChange?: SwitchProps['onChange']
  children?: React.ReactNode
  isLoading?: boolean
}

export const Description = (props: DescriptionProps) => {
  const { title, field, isChecked, children, isDisabled, onChange } = props
  const [checked, setChecked] = React.useState(isChecked)
  const onChangeRef = useLatestRef(onChange)

  React.useEffect(() => {
    setChecked(isChecked)
  }, [isChecked])

  const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeRef.current?.(event)
    // optimistic update
    setChecked(event.target.checked)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex align="center" pos="relative" justify="space-between">
      <Box flex="1" maxW="xl" color={isDisabled || !checked ? 'gray.500' : 'gray.900'}>
        <Heading as="h4" size="xs" marginBottom={1}>
          {title}
        </Heading>
        {children && (
          <Box fontSize="xs" color="gray.600">
            {children}
          </Box>
        )}
      </Box>
      {!checked && <input name={field} type="hidden" value="false" />}
      {props.isLoading && <Spinner size="xs" />}
      <Switch
        size="md"
        name={field}
        value="true"
        isChecked={checked}
        isDisabled={isDisabled || props.isLoading}
        onChange={handleChange}
      />
    </Flex>
  )
}
