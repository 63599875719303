import {
  Button,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { IconChartPie, IconDotsVertical, IconEdit, IconTimeline, IconTrash } from '@tabler/icons-react'
import React from 'react'
import { toast } from 'sonner'
import { del } from '../../../lib/api'
import router from '../../../lib/router'
import { filtersAsText } from '../../data/use-facets'
import { HelpTooltip } from '../../ui/HelpTooltip'
import { TopBarContent } from '../../ui/TopBarContext'
import PageLayout from '../../ui/PageLayout'
import PageTitle, { SmallPageHeading } from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import SquareIcon from '../../ui/SquareIcon'
import { TimeAgo } from '../../ui/TimeAgo'
import { PreviewBanner } from './components/PreviewBanner'
import { Dashboard } from './new'

interface Props {
  dashboards: Dashboard[]
  preview_ends_at?: string | null
}

export default function Index(props: Props) {
  return (
    <PageLayout size="md">
      <PageTitle skipRendering>Dashboards</PageTitle>

      <TopBarContent>
        <HStack w="100%" justifyContent="space-between">
          <SmallPageHeading size="xs" fontWeight="medium">
            Dashboards
          </SmallPageHeading>

          <Button as={Link} size="sm" colorScheme={'purple'} href={projectPath('/dashboards/new')}>
            New Dashboard
          </Button>
        </HStack>
      </TopBarContent>

      <PreviewBanner preview_ends_at={props.preview_ends_at} />

      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Filters</Th>
              <Th>Last Updated</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                <HStack spacing={4} py={2}>
                  <SquareIcon icon={IconTimeline} iconSize={5} padding={2} colorScheme="purple" />
                  <Stack spacing={1}>
                    <HStack>
                      <Link href={projectPath(`/dashboards/analytics`)}>Web Analytics Dashboard</Link>
                      <HelpTooltip variant="info">
                        A built-in dashboard that shows you the most important metrics for your website traffic.
                      </HelpTooltip>
                    </HStack>
                    <Text fontSize="xs" color="gray.500">
                      Created by Koala
                    </Text>
                  </Stack>
                </HStack>
              </Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
            </Tr>
            {props.dashboards.map((dashboard) => (
              <Tr key={dashboard.id}>
                <Td>
                  <HStack spacing={4} py={2}>
                    <SquareIcon icon={IconChartPie} iconSize={5} padding={2} colorScheme="gray" />
                    <Stack spacing={1}>
                      <Link href={projectPath(`/dashboards/${dashboard.id}`)}>{dashboard.name}</Link>

                      <Text fontSize="xs" color="gray.500">
                        Created {dashboard.created_by_user && `by ${dashboard.created_by_user.name}`}{' '}
                        <TimeAgo time={dashboard.created_at} />
                      </Text>
                    </Stack>
                  </HStack>
                </Td>
                <Td>{filtersAsText(dashboard.filters ?? {})}</Td>
                <Td>
                  <TimeAgo time={dashboard.updated_at} />
                </Td>
                <Td width="1px">
                  <>
                    <Menu autoSelect={false} placement="bottom-end">
                      <MenuButton
                        as={IconButton}
                        size="xs"
                        variant="ghost"
                        aria-label="Menu"
                        icon={<Icon as={IconDotsVertical} boxSize={4} />}
                      />
                      <MenuList>
                        <MenuItem
                          as={Link}
                          icon={<IconEdit size={16} />}
                          href={projectPath(`/dashboards/${dashboard.id}/edit`)}
                        >
                          Edit Dashboard…
                        </MenuItem>
                        <MenuItem
                          color="red.500"
                          icon={<IconTrash size={16} />}
                          onClick={async () => {
                            if (confirm('Are you sure?')) {
                              del(projectPath(`/dashboards/${dashboard.id}`)).then(() => {
                                toast.success('Dashboard deleted')
                                router.visit(projectPath(`/dashboards`))
                              })
                            }
                          }}
                        >
                          Delete Dashboard…
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </PageLayout>
  )
}
