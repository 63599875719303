import { Text } from '@chakra-ui/react'
import React from 'react'

interface Props {
  children: React.ReactNode
}

function Label(props: Props) {
  return (
    <Text as="span" display="inline-flex" isTruncated fontWeight="medium" fontSize="sm" alignItems="center">
      {props.children}
    </Text>
  )
}

export default Label
