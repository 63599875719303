import { Button, Center, Flex, Heading, Img, Spinner, Stack, Text } from '@chakra-ui/react'
import React, { useCallback, useMemo } from 'react'
import { useApp } from '../../../../data/use-app'
import { useIntentSignals } from '../../../../data/use-intent-signals'
import { PopupConnectDialog } from '../../../apps/components/ConnectOauthAppDialog'
import { ReconnectionRequiredWarning } from '../../../apps/components/ReconnectionRequiredWarning'
import { KqlDefinition } from '../../../kql_definitions/types'
import { FollowRule } from '../../../notifications'
import { channelLogos, isChannelActive } from '../delivery-setup'
import { WrapTask } from '../wrap-task'
import { AddToAccountList } from './add_account_to_list'
import { AddToList } from './add_to_list'
import { AddToSequence } from './add_to_sequence'
import { CreateAccount } from './create-account'
import { CreateContact } from './create-contact'
import { CreateTask } from './create-task'
import { EnrichProspect } from './enrich-prospect'

export interface ApolloSetupProps {
  delivery_rules: FollowRule['delivery_rules']
  actions?: {
    enrich_prospect?: boolean
    create_contact?: boolean
    create_account?: boolean
    create_task?: boolean
    add_to_sequence?: boolean
    add_to_list?: boolean
    add_to_account_list?: boolean
  }
  kqlDefinitionIds?: string[]
}

export interface ApolloActionProps {
  deps: any
  delivery_rules: FollowRule['delivery_rules']
  setDeliveryRules: (rules: FollowRule['delivery_rules']) => void
  kqlDefinitions?: KqlDefinition[]
}

export function ApolloSetup(props: ApolloSetupProps) {
  const apollo = useApp('Apollo')
  const disconnected = useMemo(() => !apollo.data?.connected, [apollo.data])
  const invalid = useMemo(() => !apollo.data?.valid, [apollo.data])
  const [deliveryRules, setDeliveryRules] = React.useState(props.delivery_rules)
  const active = isChannelActive(props.delivery_rules, 'apollo')

  const signals = useIntentSignals()

  const kqlDefinitions = useMemo(() => {
    if (!props.kqlDefinitionIds) {
      return undefined
    }

    const allDefs = signals.data?.definitions ?? []
    return allDefs.filter((def) => def.id && props.kqlDefinitionIds?.includes(def.id))
  }, [signals.data?.definitions, props.kqlDefinitionIds])

  const actions = useMemo(() => {
    if (props.actions) {
      return props.actions
    }

    return {
      enrich_prospect: true,
      create_contact: true,
      create_account: true,
      create_task: true,
      add_to_sequence: true,
      add_to_list: true,
      add_to_account_list: true
    }
  }, [props.actions])

  const onConnected = useCallback(() => {
    apollo.refetch()
  }, [apollo])

  if (apollo.isLoading) {
    return <Spinner />
  }

  if (apollo.data && (disconnected || invalid)) {
    return (
      <Center w="100%">
        <Flex py="8">
          {apollo.data && (disconnected || invalid) && (
            <PopupConnectDialog app_id={'apollo'} onConnected={onConnected} active={active}>
              {({ onStart }) => (
                <Stack spacing="4">
                  <Stack spacing="0">
                    <Center>
                      <Heading size="sm">Connect your Apollo Account</Heading>
                    </Center>
                    <Center>
                      <Text fontSize="sm" color="gray.600">
                        Please connect your Apollo account in order to get started.
                      </Text>
                    </Center>
                  </Stack>
                  <Center>
                    <Button
                      leftIcon={<Img w="4" src={channelLogos.apollo} />}
                      size="sm"
                      variant={'outline'}
                      onClick={onStart}
                    >
                      Connect Apollo
                    </Button>
                  </Center>
                </Stack>
              )}
            </PopupConnectDialog>
          )}
        </Flex>
      </Center>
    )
  }

  if (!apollo.data) {
    return null
  }

  return (
    <Stack spacing="8" py="8">
      <Stack spacing="8">
        {invalid && <ReconnectionRequiredWarning appTitle={'Apollo'} variant="short" />}

        {actions.enrich_prospect && (
          <WrapTask name={<Text>Enrich Prospect</Text>} enabled={deliveryRules?.apollo?.enrich_prospect?.enabled}>
            <EnrichProspect deps={apollo} delivery_rules={deliveryRules} setDeliveryRules={setDeliveryRules} />
          </WrapTask>
        )}

        {actions.create_contact && (
          <WrapTask name={<Text>Create Contact</Text>} enabled={deliveryRules?.apollo?.create_contact?.enabled}>
            <CreateContact deps={apollo} delivery_rules={deliveryRules} setDeliveryRules={setDeliveryRules} />
          </WrapTask>
        )}

        {actions.create_account && (
          <WrapTask name={<Text>Create Account</Text>} enabled={deliveryRules?.apollo?.create_account?.enabled}>
            <CreateAccount deps={apollo} delivery_rules={deliveryRules} setDeliveryRules={setDeliveryRules} />
          </WrapTask>
        )}

        {actions.create_task && (
          <WrapTask name={<Text>Create Task</Text>} enabled={deliveryRules?.apollo?.create_task?.enabled}>
            <CreateTask deps={apollo} delivery_rules={deliveryRules} setDeliveryRules={setDeliveryRules} />
          </WrapTask>
        )}

        {actions.add_to_sequence && (
          <WrapTask name={<Text>Add to Sequence</Text>} enabled={deliveryRules?.apollo?.add_to_sequence?.enabled}>
            <AddToSequence
              deps={apollo}
              delivery_rules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              kqlDefinitions={kqlDefinitions}
            />
          </WrapTask>
        )}

        {actions.add_to_list && (
          <WrapTask name={<Text>Add Contact to List</Text>} enabled={deliveryRules?.apollo?.add_to_list?.enabled}>
            <AddToList deps={apollo} delivery_rules={deliveryRules} setDeliveryRules={setDeliveryRules} />
          </WrapTask>
        )}

        {actions.add_to_account_list && (
          <WrapTask
            name={<Text>Add Account to List</Text>}
            enabled={deliveryRules?.apollo?.add_to_account_list?.enabled}
          >
            <AddToAccountList deps={apollo} delivery_rules={deliveryRules} setDeliveryRules={setDeliveryRules} />
          </WrapTask>
        )}
      </Stack>

      {props.delivery_rules?.apollo && (
        <input type="hidden" name="follow_rule[delivery_rules][apollo][trigger]" value="every hour" />
      )}
    </Stack>
  )
}
