import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react'
import pick from 'lodash/pick'
import React from 'react'
import { CardRadioGroup } from '../../../ui/CardRadioGroup'
import { SignalType } from './SignalType'
import { ConditionKind, signalTypes } from './triggers'

interface Props extends UseDisclosureProps, SignalTypeSelectorProps {}

export function SelectSignalTypeModal(props: Props) {
  const disclosure = useDisclosure(props)

  return (
    <Modal size="2xl" {...disclosure}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select an Intent Signal</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SignalTypeSelector kind={props.kind} onSelect={props.onSelect} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

interface SignalTypeSelectorProps {
  kind?: ConditionKind | 'multiple'
  onCancel?: () => void
  onSelect?: (kind: ConditionKind | 'multiple') => void
}

export function SignalTypeSelector(props: SignalTypeSelectorProps) {
  const [type, setType] = React.useState(props.kind)

  return (
    <Stack width="100%" alignItems="stretch" spacing={8} paddingBottom={4}>
      <Stack spacing={4}>
        {props.onCancel && (
          <Button
            alignSelf="flex-start"
            leftIcon={<Icon as={IconArrowLeft} boxSize={3.5} />}
            iconSpacing={1.5}
            variant="link"
            size="xs"
            flex="none"
            color="gray.500"
            onClick={props.onCancel}
          >
            Back
          </Button>
        )}
        <CardRadioGroup
          size="sm"
          direction="column"
          value={type}
          onChange={(value) => setType(value as ConditionKind)}
          options={signalTypes
            .filter((s) => s.enabled !== false)
            .map((signal) => ({
              value: signal.kind,
              label: <SignalType {...pick(signal, ['label', 'description', 'icon', 'colorScheme'])} />
            }))}
        />
      </Stack>
      <Button
        size="sm"
        alignSelf="flex-end"
        colorScheme="purple"
        rightIcon={<Icon as={IconArrowRight} boxSize={3} />}
        isDisabled={!type}
        onClick={() => props.onSelect?.(type as ConditionKind)}
      >
        Configure
      </Button>
    </Stack>
  )
}
