import {
  Box,
  Button,
  ButtonProps,
  Center,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Img,
  Link,
  Spinner,
  Stack,
  Switch,
  Text,
  Tooltip
} from '@chakra-ui/react'
import React, { useCallback, useMemo, useState } from 'react'
import { useApp } from '../../../../data/use-app'
import { useAppDep } from '../../../../data/use-app-dep'
import { ComboboxWithSearch } from '../../../../ui/ComboboxWithSearch'
import { PopupConnectDialog } from '../../../apps/components/ConnectOauthAppDialog'
import { ReconnectionRequiredWarning } from '../../../apps/components/ReconnectionRequiredWarning'
import { HubspotDeps, List } from '../../../apps/hubspot/show'
import { FollowRule } from '../../../notifications'
import { channelLogos, isChannelActive } from '../delivery-setup'
import { ActionSchema, useActionSchema } from '../slack-message-builder/use-action-schema'
import { AddTimelineEntry } from './add-timeline-entry'
import { CreateTask } from './create-task'
import { FieldMapper, HubspotFieldMapping } from './field-mapper'

interface HubspotSetupProps {
  delivery_rules?: FollowRule['delivery_rules']
  targetType?: 'Profile' | 'Account'
  colorScheme?: ButtonProps['colorScheme']
  actions?: {
    import_contact?: boolean
    import_company?: boolean
    add_to_list?: boolean
    create_task?: boolean
    add_timeline_entry?: boolean
  }

  suggestedMappings?: {
    company?: HubspotFieldMapping[]
    contact?: HubspotFieldMapping[]
  }
}

export function HubspotSetup(props: HubspotSetupProps) {
  const hubspot = useApp<HubspotDeps>('Hubspot')
  const disconnected = useMemo(() => !hubspot.data?.connected, [hubspot.data])
  const invalid = useMemo(() => !hubspot.data?.valid, [hubspot.data])
  const active = isChannelActive(props.delivery_rules, 'hubspot')
  const { schema } = useActionSchema()

  const {
    data: depData,
    isFetching: loadingDepData,
    refetch: refetchDepsData
  } = useAppDep<'uncached_deps', HubspotDeps>('Hubspot', 'uncached_deps')

  const refetchDeps = useCallback(() => {
    refetchDepsData()
  }, [refetchDepsData])

  const availableActions = useMemo(() => {
    if (props.actions) {
      return props.actions
    }

    return {
      import_contact: true,
      import_company: true,
      add_to_list: true,
      create_task: true,
      add_timeline_entry: true
    }
  }, [props.actions])

  const onHubspotConnected = useCallback(() => {
    hubspot.refetch()
  }, [hubspot])

  if (hubspot.isLoading) {
    return <Spinner />
  }

  if (hubspot.data && disconnected) {
    return (
      <Center w="100%">
        <Flex py="8">
          {hubspot.data && disconnected && (
            <PopupConnectDialog app_id={'hubspot'} onConnected={onHubspotConnected} active={active}>
              {({ onStart }) => (
                <Stack spacing="4">
                  <Stack spacing="0">
                    <Center>
                      <Heading size="sm">Connect your HubSpot Account</Heading>
                    </Center>
                    <Center>
                      <Text fontSize="sm" color="gray.600">
                        Please connect your HubSpot account in order to get started.
                      </Text>
                    </Center>
                  </Stack>
                  <Center>
                    <Button
                      leftIcon={<Img w="4" src={channelLogos.hubspot} />}
                      size="sm"
                      variant={'outline'}
                      onClick={onStart}
                      colorScheme={props.colorScheme}
                    >
                      Connect HubSpot
                    </Button>
                  </Center>
                </Stack>
              )}
            </PopupConnectDialog>
          )}
        </Flex>
      </Center>
    )
  }

  if (!hubspot.data) {
    return null
  }

  return (
    <Stack spacing="8" py="8">
      <Stack divider={<Divider />} spacing="8">
        {invalid && <ReconnectionRequiredWarning appTitle={'HubSpot'} variant="short" />}
        {availableActions.import_company && (
          <ImportCompany
            actionSchema={schema}
            colorScheme={props.colorScheme}
            deps={depData?.data?.uncached_deps ?? hubspot.data.deps}
            delivery_rules={props.delivery_rules}
            suggestedMappings={props.suggestedMappings?.company ?? []}
            refetchDeps={refetchDeps}
            loadingDeps={loadingDepData}
          />
        )}

        {availableActions.import_contact && (
          <ImportContact
            actionSchema={schema}
            colorScheme={props.colorScheme}
            deps={depData?.data?.uncached_deps ?? hubspot.data.deps}
            delivery_rules={props.delivery_rules}
            targetType={props.targetType}
            suggestedMappings={props.suggestedMappings?.contact ?? []}
            refetchDeps={refetchDeps}
            loadingDeps={loadingDepData}
          />
        )}

        {availableActions.add_to_list && (
          <AddToList
            colorScheme={props.colorScheme}
            deps={depData?.data?.uncached_deps ?? hubspot.data.deps}
            delivery_rules={props.delivery_rules}
            targetType={props.targetType}
          />
        )}

        {availableActions.create_task && (
          <CreateTask
            colorScheme={props.colorScheme}
            deps={depData?.data?.uncached_deps ?? hubspot.data.deps}
            delivery_rules={props.delivery_rules}
            targetType={props.targetType}
          />
        )}

        {availableActions.add_timeline_entry && (
          <AddTimelineEntry
            colorScheme={props.colorScheme}
            deps={depData?.data?.uncached_deps ?? hubspot.data.deps}
            delivery_rules={props.delivery_rules}
            targetType={props.targetType}
          />
        )}
      </Stack>

      {props.delivery_rules?.hubspot && (
        <input type="hidden" name="follow_rule[delivery_rules][hubspot][trigger]" value="immediate" />
      )}
    </Stack>
  )
}

export interface HubspotActionProps {
  actionSchema?: ActionSchema
  deps: HubspotDeps
  delivery_rules?: FollowRule['delivery_rules']
  colorScheme?: ButtonProps['colorScheme']
  suggestedMappings?: HubspotFieldMapping[]
  refetchDeps?: () => void
  loadingDeps?: boolean
}

function ImportCompany(props: HubspotActionProps) {
  const [enabled, setEnabled] = React.useState(Boolean(props.delivery_rules?.hubspot?.import_company?.enabled))

  return (
    <Stack borderLeftWidth={'thick'} borderLeftColor={`${enabled ? props.colorScheme : 'gray'}.400`} px="6" py="2">
      <FormControl>
        <Switch
          isChecked={enabled}
          onChange={(e) => {
            setEnabled(e.target.checked)
          }}
          size="sm"
          fontSize={'sm'}
          fontWeight="semibold"
          colorScheme={props.colorScheme}
          value={enabled ? 'true' : 'false'}
          name="follow_rule[delivery_rules][hubspot][import_company][enabled]"
        >
          Import Company
        </Switch>
        <FormHelperText>Create or Update the current Account as a Company in HubSpot</FormHelperText>
      </FormControl>

      {enabled && props.actionSchema && (
        <Stack spacing={'8'}>
          <Divider />
          <FieldMapper
            actionSchema={props.actionSchema}
            namespace="follow_rule[delivery_rules][hubspot][import_company]"
            type="account"
            mappings={props.delivery_rules?.hubspot?.import_company?.fields ?? props.suggestedMappings ?? []}
            deps={props.deps}
            refetch={props.refetchDeps}
            loadingDeps={props.loadingDeps}
            updateSettingEnabled={true}
          />
        </Stack>
      )}
    </Stack>
  )
}

function ImportContact(
  props: HubspotActionProps & {
    targetType?: 'Profile' | 'Account'
  }
) {
  const [enabled, setEnabled] = React.useState(
    Boolean(props.targetType === 'Profile' && props.delivery_rules?.hubspot?.import_contact?.enabled)
  )

  React.useEffect(() => {
    if (props.targetType === 'Account') {
      setEnabled(false)
    }
  }, [props.targetType])

  return (
    <Stack borderLeftWidth={'thick'} borderLeftColor={`${enabled ? props.colorScheme : 'gray'}.400`} px="6" py="2">
      <FormControl>
        <Switch
          isChecked={enabled}
          disabled={props.targetType === 'Account'}
          onChange={(e) => {
            setEnabled(e.target.checked)
          }}
          size="sm"
          fontSize={'sm'}
          fontWeight="semibold"
          colorScheme={props.colorScheme}
          value={enabled ? 'true' : 'false'}
          name="follow_rule[delivery_rules][hubspot][import_contact][enabled]"
        >
          Import Contact
        </Switch>
        <FormHelperText>Create or Update the current Visitor as a Contact in HubSpot</FormHelperText>
      </FormControl>

      {props.targetType === 'Account' && (
        <Box bg="orange.50" p="4" rounded="md" borderWidth="thin" borderColor={'orange.500'}>
          <Text fontSize={'sm'}>
            <strong>Note:</strong> Your Action is set up to target Accounts. Import Contact is only available when
            targeting Visitors in your Action.
          </Text>
        </Box>
      )}

      {enabled && props.actionSchema && (
        <Stack spacing={'8'}>
          <Divider />
          <FieldMapper
            actionSchema={props.actionSchema}
            namespace="follow_rule[delivery_rules][hubspot][import_contact]"
            mappings={props.delivery_rules?.hubspot?.import_contact?.fields ?? props.suggestedMappings ?? []}
            type="contact"
            deps={props.deps}
            refetch={props.refetchDeps}
            loadingDeps={props.loadingDeps}
            updateSettingEnabled={true}
          />
        </Stack>
      )}
    </Stack>
  )
}

function AddToList(props: HubspotActionProps & { targetType?: 'Profile' | 'Account' }) {
  const [enabled, setEnabled] = React.useState(Boolean(props.delivery_rules?.hubspot?.add_to_list?.enabled))
  const list = props.deps.lists?.find(
    (l) => l.listId.toString() === props.delivery_rules?.hubspot?.add_to_list?.list_id?.toString()
  )
  const [selectedList, setSelectedList] = useState<List | null>(list ?? null)

  return (
    <Stack px="6" py="2" borderLeftWidth={'thick'} borderLeftColor={`${enabled ? props.colorScheme : 'gray'}.400`}>
      <FormControl>
        <Switch
          isChecked={enabled}
          onChange={(e) => {
            setEnabled(e.target.checked)
          }}
          disabled={props.targetType === 'Account'}
          size="sm"
          fontSize={'sm'}
          fontWeight="semibold"
          colorScheme={props.colorScheme}
          value={enabled ? 'true' : 'false'}
          name="follow_rule[delivery_rules][hubspot][add_to_list][enabled]"
        >
          Add to Contact List
        </Switch>
        <FormHelperText>Add the current visitor to a list in HubSpot.</FormHelperText>
      </FormControl>

      {props.targetType === 'Account' && enabled && (
        <Box bg="orange.50" p="4" rounded="md" borderWidth="thin" borderColor={'orange.500'}>
          <Text fontSize={'sm'}>
            <strong>Note:</strong> Account Lists are{' '}
            <Link
              href={'https://community.hubspot.com/t5/APIs-Integrations/How-to-get-Company-Lists-from-API/m-p/364334'}
              isExternal
            >
              not supported by the HubSpot API
            </Link>
            . This automation rule can only be used with Contact Lists.
          </Text>
        </Box>
      )}

      {enabled && props.targetType !== 'Account' && (
        <Stack spacing={'8'}>
          <Divider />
          <Stack>
            <FormLabel mb="0">HubSpot List</FormLabel>

            <Flex>
              <ComboboxWithSearch
                items={props.deps.lists || []}
                selectedItem={selectedList}
                onChange={setSelectedList}
                filterItem={(a, val) => a.name.toLowerCase().includes(val)}
                itemToString={(signal) => signal?.name ?? ''}
                itemRenderer={ListRenderer}
                selectButtonRenderer={ListRenderer}
              />
              {selectedList && (
                <input
                  type="hidden"
                  name="follow_rule[delivery_rules][hubspot][add_to_list][list_id]"
                  value={selectedList.listId}
                />
              )}
            </Flex>
          </Stack>

          <Text fontSize={'xs'} color="gray.500">
            <strong>Note:</strong> Company Lists are{' '}
            <Link
              href={'https://community.hubspot.com/t5/APIs-Integrations/How-to-get-Company-Lists-from-API/m-p/364334'}
              isExternal
            >
              not supported by the HubSpot API
            </Link>
            . This automation rule can only be used with Contact Lists.
          </Text>
        </Stack>
      )}
    </Stack>
  )
}

interface ListRendererProps {
  item: List | null
  selectedItem?: List | null
}

function ListRenderer(props: ListRendererProps) {
  const notSupported = props.item?.listType === 'DYNAMIC'

  return (
    <HStack
      w="100%"
      fontSize={'sm'}
      filter={notSupported ? 'grayscale(100%)' : undefined}
      justifyContent="space-between"
    >
      <HStack fontSize={'xs'}>
        <Img src={channelLogos.hubspot} w="4" filter={notSupported ? 'grayscale(100%)' : undefined} />
        <Text>{props.item?.name ?? 'Select a list from HubSpot'}</Text>
      </HStack>
      {notSupported && (
        <Tooltip label="Dynamic lists cannot be changed via external systems.">
          <Text fontSize={'xs'}>not supported</Text>
        </Tooltip>
      )}
    </HStack>
  )
}
