import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import React, { useCallback } from 'react'
import { toast } from 'sonner'
import { expireCachedGET } from '../../../../lib/api'
import { useIntentSignal } from '../../../data/use-intent-signals'
import DefinitionForm from '../../kql_definitions/components/DefinitionForm'
import { kqlDefinitionsPathAPI } from '../../kql_definitions/path-helpers'
import { KqlDefinition } from '../../kql_definitions/types'

interface Props extends UseDisclosureProps {
  id?: string
  name?: string
  conditions?: KqlDefinition['trigger']['conditions']
  onComplete?: () => void
}

export function DefinitionFormModal({ id, name, conditions, onComplete, ...props }: Props) {
  const disclosure = useDisclosure(props)

  const { data, isLoading } = useIntentSignal(id)
  const signal = data?.definition
  const onClose = disclosure.onClose

  const afterSubmit = useCallback(() => {
    expireCachedGET(kqlDefinitionsPathAPI())

    toast.success('Intent Signal saved!', {
      description: 'Intent matching this rule will be highlighted going forward.'
    })

    onComplete?.()
    onClose()
  }, [onClose, onComplete])

  if (id && isLoading) {
    return <Spinner size="sm" thickness="1px" />
  }

  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{id && signal ? `Edit ${signal.name}` : 'Create an Intent Signal'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="8">
          <DefinitionForm
            skipSwitch
            inline
            onSubmitted={afterSubmit}
            definition={{
              enabled: true,
              name: name ?? '',
              trigger: {
                conditions: conditions ?? []
              }
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
