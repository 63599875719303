import { Box, Button, Center, Circle, ScaleFade, Stack, Text } from '@chakra-ui/react'
import { IconArrowBarToUp } from '@tabler/icons-react'
import groupBy from 'lodash/groupBy'
import uniqBy from 'lodash/uniqBy'
import React, { useMemo } from 'react'
import { useWindowScroll } from 'react-use'
import { FeedActivityEntries } from '../'
import { get } from '../../../../lib/api'
import dayjs from '../../../../lib/dayjs'
import { PageMeta } from '../../../../types/PageMeta'
import { LightBgCard } from '../../../ui/Card'
import { isLimitedAccount, useEntitlements } from '../../../ui/useEntitlements'
import { mergeParams } from '../../icps/types'
import AccountKqlEventFeed from './AccountKqlEventFeed'
import G2Session from './G2Session'
import { MaterializedSession } from './MaterializedSession'

interface FeedProps {
  materialized_sessions: FeedActivityEntries[]
  page_meta: PageMeta
  loadPath?: string
  scopedTo?: 'account' | 'profile'
}

function dayLabel(day) {
  const date = dayjs(day)

  if (date.isToday()) {
    return 'Today'
  } else if (date.isYesterday()) {
    return 'Yesterday'
  } else if (date.isSame(dayjs(), 'year')) {
    // "Sunday, January 1st" (similar to Slack)
    return date.format('dddd, MMMM Do')
  } else {
    // "January 1st, 2021"
    return date.format('MMMM Do, YYYY')
  }
}

export function VisitorFeed(props: FeedProps) {
  const [sessions, setSessions] = React.useState(props.materialized_sessions)
  const [loading, setLoading] = React.useState(false)
  const [nextPage, setNextPage] = React.useState(props.page_meta.next_page)
  const loadingRef = React.useRef(loading)
  const nextPageRef = React.useRef(nextPage)

  const entitlements = useEntitlements()

  React.useEffect(() => {
    setSessions(props.materialized_sessions)
  }, [props.materialized_sessions])

  React.useEffect(() => {
    setNextPage(props.page_meta.next_page)
  }, [props.page_meta.next_page])

  const loadMore = React.useCallback(async () => {
    if (!nextPageRef.current || loadingRef.current) {
      return
    }

    setLoading(true)
    loadingRef.current = true

    const url = mergeParams(props.loadPath ?? location.toString(), {
      page: nextPageRef.current.toString()
    })

    const res = await get<FeedProps>(url)

    setSessions((prev) => uniqBy(prev.concat(res.materialized_sessions), (s) => s.id))
    const next = res.page_meta.next_page || null

    setNextPage(next)
    nextPageRef.current = next

    setLoading(false)
    loadingRef.current = false
  }, [props.loadPath])

  const { y: scrollY } = useWindowScroll()
  const scrollToTop = React.useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])

  const endOfPageRef = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0
    }

    const observer = new IntersectionObserver((entries) => {
      const target = entries[0]
      if (target.isIntersecting) {
        loadMore()
      }
    }, option)

    if (endOfPageRef.current) {
      observer.observe(endOfPageRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [loadMore])

  const groupedSessions = useMemo(() => {
    const filtered = sessions.filter((session) => {
      if (session.record_type !== 'DailyProfileFeed') return true

      return session.num_interactions > 0
    })

    // This groups sessions by day headings similar to Slack
    const grouped = groupBy(filtered, (session) => {
      return dayjs(session?.last_touched_at || session?.timestamp)
        .startOf('day')
        .format('YYYY-MM-DD')
    })

    return Object.keys(grouped)
      .sort((a, b) => dayjs(b).unix() - dayjs(a).unix())
      .map((date) => {
        let currentGroup: string | null = null
        const aggregations: Record<string, any>[] = []

        // Group event types chronically so we can display it in chunks on the UI
        // aggregate the events until we reach a new group or a new session breaks the chain
        grouped[date].forEach((session) => {
          if (session.record_type === 'DailyProfileFeed') {
            aggregations.push({ type: 'session', session, id: session.id })
            currentGroup = null
            return
          }

          if (currentGroup === null || currentGroup !== session.source) {
            currentGroup = session.source || null
            aggregations.push({ type: 'group', id: session.id, source: session.source, events: [session] })
          } else {
            aggregations[aggregations.length - 1].events?.push(session)
          }

          if (session.record_type === 'AccountKqlEvent') {
            currentGroup = 'Account Event'
            aggregations.push({ type: 'account_kql_event', event: session, id: session.id })
            return
          }
        })

        return {
          date: dayLabel(date),
          sessions: aggregations
        }
      })
  }, [sessions])

  return (
    <Box w="100%" paddingBottom={4}>
      <Stack spacing={6} width="100%" maxW="1024px" marginX="auto">
        <Stack spacing={10}>
          {groupedSessions.map(({ date, sessions }) => (
            <Stack key={date} spacing={4}>
              <Text fontSize="xs" fontWeight="semibold" color="gray.500" textTransform="uppercase">
                {date}
              </Text>
              {sessions.map((entry) => (
                <React.Fragment key={entry.id}>
                  {entry.type === 'session' ? (
                    <MaterializedSession
                      key={`ms:${entry.id}`}
                      hideCompany={props.scopedTo === 'account'}
                      redacted={isLimitedAccount(entitlements, {
                        last_seen_at: entry.session?.last_touched_at,
                        limited:
                          entry.session.limited ?? entry.session.account?.limited ?? entry.session.profile?.limited
                      })}
                      {...entry.session}
                    />
                  ) : entry.type === 'group' && entry.source === 'g2' ? (
                    <G2Session key={`g2:${entry.id}`} events={entry.events} />
                  ) : entry.type === 'account_kql_event' ? (
                    <LightBgCard key={`ae:${entry.id}`} p="3" py="2">
                      <AccountKqlEventFeed event={entry.event} />
                    </LightBgCard>
                  ) : null}
                </React.Fragment>
              ))}
            </Stack>
          ))}
        </Stack>

        <Center paddingTop={10} paddingBottom={20}>
          {nextPage ? (
            <Box ref={endOfPageRef} height="100px">
              {loading && (
                <Button variant="link" isLoading loadingText="Loading more…">
                  Loading more…
                </Button>
              )}
            </Box>
          ) : (
            <Text fontSize="sm" color="gray.400">
              That's all there is, there isn't any more
            </Text>
          )}
        </Center>
      </Stack>

      <Box position="fixed" bottom={10} right={10}>
        <ScaleFade initialScale={0.9} in={scrollY > 150}>
          <Circle
            padding={3}
            bg="gray.500"
            cursor="pointer"
            opacity={0.5}
            _hover={{ opacity: 0.8 }}
            transition="opacity 150ms cubic-bezier(0.4, 0, 0.2, 1)"
            onClick={scrollToTop}
          >
            <IconArrowBarToUp size={24} color="white" />
          </Circle>
        </ScaleFade>
      </Box>
    </Box>
  )
}
