import React from 'react'

import {
  Stack,
  HStack,
  VStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  IconButton,
  Button,
  MenuList,
  MenuButton,
  Menu,
  MenuItem,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Input,
  Select,
  FormControl,
  FormLabel,
  useDisclosure
} from '@chakra-ui/react'
import { IconPlus, IconTrafficLightsOff, IconTrafficLights, IconTrashX, IconDotsVertical } from '@tabler/icons-react'

import { TimeAgo } from '../../../ui/TimeAgo'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'

import { del, post, get, postForm } from '@app/lib/api'
import { toast } from 'sonner'
import { AdminBreadcrumb } from '..'

interface OperationsFirewallProps {
  id: string
  action: string
  rule_name: string
  project_slug?: string
  project_id: string
  record_type: string
  record_id: string
  status: string
  created_at: string
  updated_at: string
}

interface Props {
  rules: OperationsFirewallProps[]
}

const models = ['Account', 'Profile']

const actions = ['block']

const ruleNames = ['reindex', 'ingestion']

const ruleStatuses = ['enabled', 'disabled']

export default function Index(props: Props) {
  const formRef = React.useRef<HTMLFormElement | null>(null)
  const [executing, setExecuting] = React.useState<string | null>(null)
  const [rules, setRules] = React.useState<OperationsFirewallProps[]>(props.rules)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const refresh = () => {
    get<Props>(window.location.pathname).then((response) => {
      setRules(response.rules)
    })
  }

  const createRule = async () => {
    if (!formRef.current) {
      return
    }

    const formData = new FormData(formRef.current)

    try {
      await postForm('/admin/operations-firewall', formData)
      toast.success('Rule created')
      refresh()
    } catch (_err) {
      toast.error('Error creating rule')
    } finally {
      onClose()
    }
  }

  const removeRule = async (id: string) => {
    setExecuting(id)
    await del(`/admin/operations-firewall/${id}`)
      .then(() => {
        toast.success('Rule removed')
        refresh()
      })
      .catch(() => {
        toast.error('Error removing rule')
      })
      .finally(() => {
        setExecuting(null)
      })
  }
  const enableRule = async (id: string) => {
    setExecuting(id)
    await post(`/admin/operations-firewall/${id}/enable`)
      .then(() => {
        toast.success('Rule Enabled')
        refresh()
      })
      .catch(() => {
        toast.error('Error enabling rule')
      })
      .finally(() => {
        setExecuting(null)
      })
  }
  const disableRule = async (id: string) => {
    setExecuting(id)
    await post(`/admin/operations-firewall/${id}/disable`)
      .then(() => {
        toast.success('Rule Disabled')
        refresh()
      })
      .catch(() => {
        toast.error('Error disabling rule')
      })
      .finally(() => {
        setExecuting(null)
      })
  }
  return (
    <PageLayout size="md">
      <HStack>
        <PageTitle flex="1">Operations firewall</PageTitle>

        <Button leftIcon={<IconPlus size="16" />} flex="none" colorScheme="purple" onClick={onOpen}>
          Add rule
        </Button>
      </HStack>

      <AdminBreadcrumb paths={[{ path: '/admin/operatiosn-firewall', title: 'Operations firewall' }]} />

      <Stack spacing={8}>
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Project</Th>
                <Th>Model name</Th>
                <Th>ID</Th>
                <Th>Action</Th>
                <Th>Rule</Th>
                <Th>Status</Th>
                <Th>Created</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {rules.map((rule) => (
                <Tr key={rule.id} _hover={{ bg: 'gray.50' }}>
                  <Td w="1px">
                    <Text fontSize="sm">{rule.project_slug}</Text>
                  </Td>
                  <Td w="1px">
                    <Text fontSize="sm">{rule.record_type}</Text>
                  </Td>
                  <Td>
                    <Text fontSize="sm">{rule.record_id}</Text>
                  </Td>
                  <Td w="1px">
                    <Text fontSize="sm">{rule.action}</Text>
                  </Td>

                  <Td w="1px">
                    <text fontSize="sm">{rule.rule_name}</text>
                  </Td>

                  <Td w="1px">
                    <text fontSize="sm">{rule.status}</text>
                  </Td>

                  <Td w="280px">
                    <TimeAgo time={rule.created_at} />
                  </Td>

                  <Td w="1px">
                    {executing === rule.id ? (
                      <Spinner size="xs" color="gray.500" />
                    ) : (
                      <Menu>
                        <MenuButton
                          size="sm"
                          as={IconButton}
                          icon={<IconDotsVertical size={16} />}
                          variant="outline"
                          borderColor="gray.200"
                        />
                        <MenuList fontSize="sm">
                          <MenuItem
                            aria-label="Remove"
                            icon={<IconTrashX size="14" />}
                            onClick={() => removeRule(rule.id)}
                          >
                            Remove
                          </MenuItem>
                          <MenuItem
                            aria-label="Enable"
                            isDisabled={rule.status === 'enabled'}
                            icon={<IconTrafficLights color="green" size="14" />}
                            onClick={() => enableRule(rule.id)}
                          >
                            Enable
                          </MenuItem>
                          <MenuItem
                            aria-label="disable"
                            isDisabled={rule.status === 'disabled'}
                            icon={<IconTrafficLightsOff color="red" size="14" />}
                            onClick={() => disableRule(rule.id)}
                          >
                            Disable
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <form ref={formRef} method="POST" action="/admin/operatiosn-firewall" onSubmit={createRule}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>Create rule</ModalHeader>
            <ModalBody>
              <AuthenticityToken />
              <VStack spacing="20px">
                <FormControl id="project">
                  <FormLabel>Project slug</FormLabel>
                  <Input name="operation_firewall[project_slug]" autoComplete="off" placeholder="koala" />
                </FormControl>

                <FormControl id="record_type">
                  <FormLabel>Model</FormLabel>
                  <Select name="operation_firewall[record_type]">
                    {models.map((model) => (
                      <option key={model} value={model}>
                        {model}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl id="record_id">
                  <FormLabel>ID</FormLabel>
                  <Input name="operation_firewall[record_id]" />
                </FormControl>

                <FormControl id="status">
                  <FormLabel>Status</FormLabel>
                  <Select name="operation_firewall[status]">
                    {ruleStatuses.map((status) => (
                      <option key={status} value={status}>
                        {status.toUpperCase()}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl id="action">
                  <FormLabel>Rule</FormLabel>
                  <Select name="operation_firewall[rule_name]">
                    {ruleNames.map((rule_name) => (
                      <option key={rule_name} value={rule_name}>
                        {rule_name}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl id="action">
                  <FormLabel>Action</FormLabel>
                  <Select name="operation_firewall[action]">
                    {actions.map((action) => (
                      <option key={action} value={action}>
                        {action}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button size="sm" onClick={onClose} variant="outline" mr={3}>
                Cancel
              </Button>
              <Button size="sm" colorScheme="purple" onClick={createRule}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </PageLayout>
  )
}
