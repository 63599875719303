import React from 'react'
import { StaticList } from '../../../types/StaticList'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'

interface Props {
  lists: StaticList[]
}

export default function Index(_props: Props) {
  return (
    <PageLayout>
      <PageTitle>Static Lists</PageTitle>
    </PageLayout>
  )
}
