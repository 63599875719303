import { AuthenticityToken } from '@app/components/ui/AuthenticityToken'
import PageDescription from '@app/components/ui/PageDescription'
import PageTitle from '@app/components/ui/PageTitle'
import { Button, Input, Select, VStack } from '@chakra-ui/react'
import React from 'react'
import PageLayout from '../../ui/PageLayout'
import { AdminBreadcrumb } from '../admin'

interface Props {
  projects: string[]
}

export default function New(props: Props) {
  const [userEmail, setUserEmail] = React.useState('')

  return (
    <PageLayout size="sm">
      <AdminBreadcrumb paths={[{ path: '/admin/subject-data-deletion-request', title: 'Deletion Requests' }]} />

      <form data-koala-collect="off" action={'/admin/subject-data-deletion-request'} method="POST">
        <AuthenticityToken />
        <PageTitle size="lg">Subject Data Deletion Request</PageTitle>
        <PageDescription>Delete user data</PageDescription>
        <VStack spacing={'4'} margin={'0 auto'}>
          <Input
            placeholder={'user@email.com'}
            name="email"
            value={userEmail}
            type={'email'}
            variant={'flushed'}
            backgroundColor={'white'}
            p={'3'}
            onChange={(e) => setUserEmail(e.target.value)}
            required
          />
          <Select required bg={'white'} name="project" placeholder={'Workspace...'}>
            {props.projects.map((p) => {
              return (
                <option key={p} value={p}>
                  {p}
                </option>
              )
            })}
          </Select>
          <Button type={'submit'} width={'100%'} variant={'solid'} colorScheme={'red'}>
            Delete
          </Button>
        </VStack>
      </form>
    </PageLayout>
  )
}
