import { Button, ButtonProps, useDisclosure } from '@chakra-ui/react'
import React, { useCallback, useEffect, useRef } from 'react'
import { postForm } from '../../../../lib/api'
import { SearchIcon } from '../../../ui/icons/SearchIcon'
import { Notification } from '../../notifications'
import { automationsPath } from '../../notifications/lib/path-helper'
import { PreviewDrawer } from './preview-drawer'

interface Props {
  buttonProps?: ButtonProps
  formRef: React.RefObject<HTMLFormElement>
}

export function NotificationRulePreview(props: Props) {
  const [notifications, setNotifications] = React.useState<Notification[]>([])
  const [loading, setIsLoading] = React.useState(false)
  const [lookback, setLookback] = React.useState('24 hours ago')
  const { isOpen, onOpen, onClose } = useDisclosure()

  const controllerRef = useRef<AbortController>()
  useEffect(() => {
    if (!props.formRef.current) {
      return
    }

    if (!isOpen) {
      return
    }

    const data = new FormData(props.formRef.current)
    setIsLoading(true)
    setNotifications([])

    controllerRef.current = new AbortController()

    const path = automationsPath(`/preview`)
    postForm<{ notifications: Notification[]; lookback: string }>(path, data, {
      signal: controllerRef.current.signal
    })
      .then((res) => {
        setNotifications(res.notifications)
        setLookback(res.lookback)
        setIsLoading(false)
      })
      .catch(() => {})

    return () => {
      controllerRef.current?.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const handleClose = useCallback(() => {
    onClose()

    setTimeout(() => {
      controllerRef.current?.abort()
      setNotifications([])
      setIsLoading(false)
    }, 0)
  }, [onClose])

  return (
    <>
      <PreviewDrawer
        notifications={notifications}
        loading={loading}
        isOpen={isOpen}
        onClose={handleClose}
        lookback={lookback}
      />

      <Button
        isLoading={loading}
        onClick={onOpen}
        leftIcon={<SearchIcon size="14" />}
        colorScheme={'purple'}
        variant="outline"
        flex="1"
        {...props.buttonProps}
      >
        Preview
      </Button>
    </>
  )
}
