import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea
} from '@chakra-ui/react'
import React, { useCallback, useRef } from 'react'
import { toast } from 'sonner'
import { postForm } from '../../lib/api'
import { billingPricingRequest } from '../pages/billing/lib/path-helpers'

interface Props {
  onClose: () => void
}

export function PricingForm(props: Props) {
  const [message, setMessage] = React.useState('')
  const [submitting, setSubmitting] = React.useState(false)
  const initialFocusRef = useRef<HTMLTextAreaElement>(null)

  const formId = 'pricing-form'

  const onSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      setSubmitting(true)

      const data = new FormData(event.target as HTMLFormElement)
      data.append('title', document.title)

      try {
        await postForm(billingPricingRequest(), data)
        setMessage('')
        toast("🎉 Thanks, we'll be in touch soon!")

        setSubmitting(false)
        props.onClose()
      } catch (_e) {
        toast.error('There was an issue receiving your message.')
        setSubmitting(false)
      }
    },
    [props]
  )

  return (
    <>
      <Modal
        isOpen
        onClose={() => {
          props.onClose()
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Got a question about billing?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="4" fontSize={'sm'}>
              <Text>We're happy to help answer any questions you have about our plans or pricing.</Text>
              <form id={formId} onSubmit={onSubmit}>
                <Textarea
                  ref={initialFocusRef}
                  name="message"
                  width="100%"
                  resize="none"
                  fontSize="sm"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  isRequired
                  placeholder="Include any details about your question/use-case that may help us answer your questions."
                />
              </form>
            </Stack>
          </ModalBody>

          <ModalFooter gap={2}>
            <Button variant="outline" size="sm" onClick={props.onClose}>
              Cancel
            </Button>
            <Button type="submit" form={formId} colorScheme="purple" size="sm" isLoading={submitting}>
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
