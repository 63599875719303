import { AppActions } from '@app/types/AppActions'
import { Badge, Divider, Heading, HStack, Icon, Stack, Switch, Text, Tooltip, VStack } from '@chakra-ui/react'
import { IconHelp } from '@tabler/icons-react'
import React, { useState } from 'react'

interface Props {
  appActions: AppActions
  appModule?: string
  canEdit?: boolean
}

export function ConfigurableAppActions({ appActions, appModule, canEdit }: Props) {
  const [actionsData, setActionsData] = useState(appActions)
  const appName = appModule && appModule.split('::')[1]

  const actions = Object.entries(appActions).map(([key, action], i) => {
    const inputName = appModule ? `app_actions[${appModule}][${key}]` : `app_actions[${key}]`
    return (
      <VStack key={i}>
        <input type={'hidden'} name={inputName} value={actionsData[key].enabled.toString()} />
        <HStack paddingBottom={'1.5'} width={'100%'}>
          <Switch
            size="sm"
            isChecked={actionsData[key].enabled}
            isReadOnly={!action.configurable || !canEdit}
            isDisabled={!action.configurable}
            marginRight={2}
            onChange={(event) => {
              actionsData[key].enabled = event.target.checked
              setActionsData((prevState) => {
                return {
                  ...prevState,
                  [key]: {
                    description: action.description,
                    name: action.name,
                    configurable: action.configurable,
                    tooltip: action.tooltip,
                    enabled: event.target.checked,
                    hotkey: action.hotkey
                  }
                }
              })
            }}
          />
          <VStack align={'left'} spacing={'0'}>
            <HStack alignItems="center">
              <Text fontWeight={'semibold'}>{action.name}</Text>
              {action.tooltip && (
                <Tooltip placement={'right-end'} label={action.tooltip}>
                  <Icon as={IconHelp} boxSize={4} color="gray.400" />
                </Tooltip>
              )}
            </HStack>
            <HStack>
              <Text fontSize="sm" color="gray.600">
                {action.description}
              </Text>
              {!action.configurable && <Badge>configurable soon</Badge>}
            </HStack>
          </VStack>
        </HStack>
        {i < Object.entries(appActions).length - 1 && <Divider />}
      </VStack>
    )
  })
  return (
    <>
      <Stack spacing="3" fontSize={'sm'}>
        {appName && (
          <Stack>
            <Heading size="sm">{appName}</Heading>
          </Stack>
        )}
        {!appName && (
          <Stack>
            <Heading size="md" fontWeight={'semibold'}>
              ⚙️ Actions
            </Heading>
            <Text>Configure what actions are available for this integration.</Text>
          </Stack>
        )}
        <Stack paddingX={'6'} paddingY={'3'} backgroundColor={'white'}>
          {actions}
        </Stack>
      </Stack>
    </>
  )
}
