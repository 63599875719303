import * as React from 'react'
import type { Project } from '../../types/Project'
import useLocation from './useLocation'
declare global {
  interface Window {
    projects: Project[]
  }
}

export function getCurrentProject(): Project | undefined {
  const path = window.location.pathname
  // e.g. ['/projects/1/profiles/1']
  const [_root, _projects, project] = path.split('/') ?? []

  return window.projects?.find((p) => {
    return project === p.slug || p.previous_slugs?.includes(project)
  })
}

export function useCurrentProject(): Project | undefined {
  const location = useLocation()
  const [project, setProject] = React.useState<Project | undefined>(getCurrentProject())

  React.useEffect(() => {
    const project = getCurrentProject()
    setProject(project)
  }, [location])

  return project
}

export function projectPath(rest?: string) {
  const project = getCurrentProject()
  const prefix = project ? `/projects/${project.slug}` : '/goto'

  let [url, query] = `${prefix}/${rest ?? ''}`.split('?')
  url = url.replace(/\/\//g, '/').replace(/\/$/, '')
  query = query ? `?${query}` : ''

  return `${url}${query}`
}
