import { Image, ImageProps } from '@chakra-ui/react'
import React from 'react'
import logo from './logo.svg'
import mark from './logomark.svg'

interface Props extends ImageProps {
  size: number | string
  kind?: 'full' | 'mark'
}

export default function KoalaLogo({ size, kind, ...props }: Props) {
  return <Image width={size} src={kind === 'mark' ? mark : logo} {...props} />
}

export function KoalaLogoMark({ size, kind, ...props }: Props) {
  return <KoalaLogo size={size} kind="mark" {...props} />
}
