import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useCheckboxGroup,
  useDisclosure
} from '@chakra-ui/react'
import {
  IconAdjustmentsHorizontal,
  IconArrowUpRight,
  IconDoorExit,
  IconInfoCircle,
  IconLicense,
  IconTrash,
  IconUserPlus
} from '@tabler/icons-react'
import { format } from 'friendly-numbers'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import set from 'lodash/set'
import without from 'lodash/without'
import pluralize from 'pluralize'
import * as React from 'react'
import { toast } from 'sonner'
import { get, post, put } from '../../../lib/api'
import type { Invite, User } from '../../../types/Invite'
import type { Project } from '../../../types/Project'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import { BulkActionBar } from '../../ui/BulkActionBar'
import { Card } from '../../ui/Card'
import { HelpTooltip } from '../../ui/HelpTooltip'
import MultipleSelectInput from '../../ui/MultipleSelectInput'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { usePermission } from '../../ui/PermissionsContext'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import StatusAvatar from '../../ui/StatusAvatar'
import { TextEllipsis } from '../../ui/text-ellipsis'
import { TimeAgo } from '../../ui/TimeAgo'
import { useCurrentUser } from '../../ui/UserContext'
import { FacetFilters } from '../accounts'
import { humanize } from '../accounts/facets/filter-cloud'
import { SearchBar } from '../accounts/facets/search-bar'
import { accountsPath } from '../accounts/lib/account-path'
import { AllEntitlements } from '../billing/show'
import { AuthorizedDomains } from './components/AuthorizedDomains'
import { InviteModal, MemberLimitWarning, roles } from './components/InviteModal'

interface CombinedUsers {
  id: string
  email: string
  role: string
  name?: string
  image?: string
  status?: string
  pending?: boolean
  added_at?: string
  updated_at?: string
  last_app_interaction_at?: string
}

interface IndexProps {
  project: Project
  authorized_domains: string[]
  invites: Invite[]
  users: Array<User & { role: string; added_at?: string }>
  total_billable_members: number
  account_ownership_stats: Record<
    string,
    {
      counts: number
      facets: FacetFilters
    }
  >
  entitlements?: AllEntitlements
  plan_allows_new_members?: boolean
}

function MemberStat({
  label,
  members,
  billable,
  free,
  hint
}: {
  label: string
  members: User[]
  billable?: boolean
  free?: boolean
  hint?: string
}) {
  return (
    <Stat
      key={label}
      textAlign={'center'}
      borderWidth="1px"
      rounded="lg"
      p="4"
      flex={1}
      w="100%"
      shadow="sm"
      minH="100px"
      justifyContent={'center'}
      alignItems={'center'}
      display="flex"
      position="relative"
    >
      {free && (
        <Tag size="sm" rounded="lg" position="absolute" right="2" top="2">
          Free
        </Tag>
      )}
      {billable && (
        <Tag size="sm" rounded="lg" position="absolute" right="2" top="2" colorScheme={'green'}>
          Billable
        </Tag>
      )}
      <StatNumber>{format(members.length)}</StatNumber>
      <StatLabel color="gray.500" fontWeight={'normal'} position="relative">
        <Tooltip label={hint ?? undefined}>
          <HStack spacing="1">
            <Text>{label}</Text>
            {hint && (
              <Box color="gray.400">
                <IconInfoCircle size="16" />
              </Box>
            )}
          </HStack>
        </Tooltip>
      </StatLabel>
    </Stat>
  )
}

const multiSelectRoles = [
  { value: 'admin', label: 'Admin' },
  { value: 'member', label: 'Member' },
  { value: 'read_only', label: 'Read Only' },
  { value: 'developer', label: 'Developer' }
]

const emptyArray = []

export default function Index(props: IndexProps) {
  const [invites, setInvites] = React.useState<Invite[]>(props.invites ?? [])
  const [isInviting, setIsInviting] = React.useState(false)
  const [users, setUsers] = React.useState(props.users)
  const { hasPermission: canManageMembers } = usePermission({ on: 'project', action: 'can_manage_members' })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const currentUser = useCurrentUser()

  const [filter, setFilter] = React.useState('')
  const [roleFilter, setRoleFilter] = React.useState<string | undefined>()
  const selectedRoles = React.useMemo(() => roleFilter?.split(',') ?? emptyArray, [roleFilter])

  const allUsers = React.useMemo(() => {
    const all: CombinedUsers[] = Array.from(users)

    for (const invite of invites) {
      all.push({
        id: String(invite.id),
        email: invite.email,
        role: invite.project_attrs?.role,
        added_at: invite.created_at,
        pending: true
      })
    }

    if (!filter || filter.trim() === '') {
      return all
    }

    return all.filter((u) =>
      [u.email, u.name ?? '', u.role].join('').toLowerCase().includes(filter.trim().toLowerCase())
    )
  }, [users, invites, filter])

  const koalaUsers = React.useMemo(() => {
    if (props.project.slug === 'koala') {
      return []
    }
    return users.filter((u) => u.email.includes('@getkoala.com') || u.email.includes('@koala.live'))
  }, [users, props.project])

  const nonKoalaMembers = React.useMemo(() => {
    return without(users, ...koalaUsers)
  }, [users, koalaUsers])

  const byRole = React.useMemo(() => {
    return groupBy(allUsers, 'role')
  }, [allUsers])

  const adminCheckboxes = useCheckboxGroup()
  const memberCheckboxes = useCheckboxGroup()
  const readonlyCheckboxes = useCheckboxGroup()
  const developerCheckboxes = useCheckboxGroup()

  const onRemoveSelection = React.useCallback(() => {
    adminCheckboxes.setValue([])
    memberCheckboxes.setValue([])
    readonlyCheckboxes.setValue([])
    developerCheckboxes.setValue([])
  }, [adminCheckboxes, memberCheckboxes, readonlyCheckboxes, developerCheckboxes])

  const selectedMembers = React.useMemo(() => {
    const selected = [
      ...adminCheckboxes.value,
      ...memberCheckboxes.value,
      ...readonlyCheckboxes.value,
      ...developerCheckboxes.value
    ]
    return allUsers.filter(
      (u) => selected.includes(u.email) && (selectedRoles.length === 0 || selectedRoles.includes(u.role))
    )
  }, [
    allUsers,
    selectedRoles,
    adminCheckboxes.value,
    memberCheckboxes.value,
    readonlyCheckboxes.value,
    developerCheckboxes.value
  ])

  const updateUserRole = React.useCallback(
    async (id: string, role: string, prevRole: string) => {
      if (selectedRoles.length && !selectedRoles.includes(role)) {
        setRoleFilter(role)
      } else {
        setRoleFilter(undefined)
      }

      try {
        // optimistic update
        setUsers((users) => users.map((u) => (u.id === id ? { ...u, role } : u)))
        await put(projectPath(`/settings/members/${id}`), { role })
      } catch (error) {
        // unset if there was an error
        setUsers((users) => users.map((u) => (u.id === id ? { ...u, role: prevRole } : u)))
        toast.error(`Role change failed!`, {
          description: (error as Error).message
        })
      }
    },
    [selectedRoles]
  )

  const onSubmit = React.useCallback(
    async (event) => {
      event.preventDefault()
      setIsInviting(true)

      const form = new FormData(event.target as HTMLFormElement)
      let data = {} as Record<string, any>

      for (const [key, value] of form.entries()) {
        data = set(data, key, value)
      }

      const email = data.invite?.email?.trim()
      if (!email) {
        return
      }

      try {
        await post(projectPath(`/invites`), data)

        // Reload page asynchronously. This is the easier way
        // to get the invite data in a consistent shape.
        const { invites } = await get<{ invites: Invite[] }>(projectPath(`/settings/members`))
        setInvites(invites)

        toast.success('Invite sent!')

        onClose()
      } catch (error) {
        toast.error('Invite failed!', {
          description: `There was issue inviting ${email}`
        })
      } finally {
        setIsInviting(false)
      }
    },
    [onClose]
  )

  return (
    <>
      <InviteModal
        project={props.project}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        isInviting={isInviting}
        totalBillableMembers={props.total_billable_members}
        entitlements={props.entitlements}
        planAllowsNewMembers={props.plan_allows_new_members}
        suggestedRole={selectedRoles.length === 1 ? selectedRoles[0] : undefined}
      />

      <PageLayout size="md" position="relative">
        <SettingsBreadCrumb paths={[{ path: projectPath('/settings/members'), title: 'Members' }]} offscreen />

        <SettingsHeader divider={false}>
          <PageTitle>Members</PageTitle>
          <PageDescription>Manage who has access to this workspace.</PageDescription>
        </SettingsHeader>

        <Stack spacing={14}>
          <StatGroup as={HStack} gap="4" flexWrap={'wrap'}>
            <MemberStat label="Total members" members={props.users} hint="Includes all members in your workspace." />
            <MemberStat
              billable
              label="Admins"
              members={nonKoalaMembers.filter((u) => u.role === 'admin')}
              hint="Admins can manage members, billing, settings, as well as all members features."
            />
            <MemberStat
              label="Members"
              billable
              hint="Members are users who can access the workspace, receive notifications, and create lists."
              members={nonKoalaMembers.filter((u) => u.role === 'member' || u.role === 'read_only')}
            />
            {koalaUsers.length > 0 && (
              <MemberStat
                free
                label="Koala support"
                members={koalaUsers}
                hint="These are Koala employees who can view and setup your workspace."
              />
            )}
            <MemberStat
              free
              label="Developers"
              members={nonKoalaMembers.filter((u) => u.role === 'developer')}
              hint="Developer are free-of-charge users who can setup your workspace instrumentation."
            />
          </StatGroup>

          <MemberLimitWarning
            onClose={onClose}
            hideManage
            totalBillableMembers={props.total_billable_members}
            entitlements={props.entitlements}
            planAllowsNewMembers={props.plan_allows_new_members}
          />

          <AuthorizedDomains domains={props.authorized_domains} />

          <Divider />

          <Stack spacing="8">
            <Flex gap={2} justifyContent="space-between">
              <Flex direction="column" gap={1}>
                <Heading size="sm">Manage members</Heading>
                <Text fontSize="sm" color="gray.600">
                  Manage who can access this workspace and their permissions.
                </Text>
              </Flex>
            </Flex>

            <HStack w="100%">
              <SearchBar
                size="md"
                value={filter}
                onChange={(e) => {
                  setFilter(e)
                  setRoleFilter(undefined)
                }}
                placeholder="Search by name or email..."
              />
              <MultipleSelectInput
                triggerProps={{
                  leftIcon: <IconAdjustmentsHorizontal size={16} />,
                  flex: 'none',
                  width: '140px',
                  iconSpacing: 2,
                  justifyContent: 'flex-start',
                  textAlign: 'left'
                }}
                popperOptions={{ placement: 'bottom-end' }}
                menuProps={{ width: '200px' }}
                items={multiSelectRoles}
                itemToString={(item) => item?.label || item?.value || ''}
                selectedItems={
                  selectedRoles.length > 0
                    ? multiSelectRoles.filter((type) => selectedRoles.includes(type.value))
                    : emptyArray
                }
                selectButtonRenderer={({ selectedItems }) => (
                  <Text flex="1 1 auto">{selectedItems.length ? `Filter (${selectedItems.length})` : 'Filter'}</Text>
                )}
                onSelectedItemsChange={({ selectedItems }) => {
                  const included = selectedItems
                    ?.map((item) => item.value)
                    ?.filter(Boolean)
                    ?.join(',')
                  if (included) {
                    setRoleFilter(included)
                  } else {
                    setRoleFilter(undefined)
                  }
                }}
                variant="outline"
              />
              {canManageMembers && (
                <Button
                  marginLeft="auto"
                  alignSelf="center"
                  flex="none"
                  colorScheme="purple"
                  isLoading={isInviting}
                  loadingText="Sending invites"
                  leftIcon={<IconUserPlus size="15" />}
                  onClick={onOpen}
                >
                  Invite people
                </Button>
              )}
            </HStack>

            <Stack spacing="12" paddingBottom={20}>
              {Object.keys(byRole).map((role) => {
                if (selectedRoles.length && !selectedRoles.includes(role)) {
                  return null
                }

                const users = byRole[role]
                const checkboxGroup =
                  role === 'admin'
                    ? adminCheckboxes
                    : role === 'member'
                      ? memberCheckboxes
                      : role === 'read_only'
                        ? readonlyCheckboxes
                        : role === 'developer'
                          ? developerCheckboxes
                          : undefined

                let withStats = users.map((user) => ({
                  ...user,
                  stats: props.account_ownership_stats[user.id]?.counts ?? 0
                }))

                withStats = orderBy(
                  withStats,
                  ['stats', 'last_app_interaction_at', 'updated_at', 'name'],
                  ['desc', 'desc', 'desc', 'asc']
                )

                return (
                  <Stack key={role}>
                    <Badge variant={'subtle'} bg="none" color={'gray.500'} fontWeight="semibold" size={'md'}>
                      {humanize(role)}
                    </Badge>
                    <TableContainer fontSize="sm" w="100%" borderWidth={'1px'} rounded="lg">
                      <Table variant="simple" w="100%" size="sm">
                        <Thead>
                          <Tr>
                            <Th>
                              <HStack py={1}>
                                {canManageMembers && checkboxGroup && (
                                  <Checkbox
                                    size="md"
                                    marginRight={2}
                                    isChecked={checkboxGroup.value.length === users.length}
                                    onChange={() => {
                                      checkboxGroup.setValue((prev) =>
                                        prev.length === users.length ? [] : users.map((p) => p.email)
                                      )
                                    }}
                                  />
                                )}
                                <Text>Name</Text>
                              </HStack>
                            </Th>
                            <Th>Role</Th>
                            <Th>Date added</Th>
                            <Th>Last active</Th>
                            <Th isNumeric>
                              <Flex gap={1.5} justifyContent="flex-end">
                                Accounts
                                <HelpTooltip variant="info" placement="top">
                                  The number of Koala accounts this member has based on their "My Accounts" or their
                                  team's "Team Accounts" configuraiton.
                                </HelpTooltip>
                              </Flex>
                            </Th>
                            <Th />
                          </Tr>
                        </Thead>
                        <Tbody>
                          {withStats.map((user) => (
                            <Tr
                              key={user.id}
                              borderBottomWidth="1px"
                              _last={{
                                borderBottom: 'none'
                              }}
                            >
                              <Td>
                                <HStack flex="1">
                                  {canManageMembers && checkboxGroup && (
                                    <Checkbox
                                      size="md"
                                      marginRight={2}
                                      {...checkboxGroup.getCheckboxProps({ value: user.email })}
                                    />
                                  )}
                                  <StatusAvatar
                                    flex="none"
                                    marginRight={1}
                                    size="sm"
                                    name={user.name}
                                    email={user.email}
                                    src={user.image}
                                    // status={user.status as StatusAvatarProps['status']}
                                    {...(user.pending
                                      ? {
                                          hideStatus: true,
                                          color: 'gray.400',
                                          backgroundColor: 'white',
                                          border: '1px dashed',
                                          borderColor: 'gray.200'
                                        }
                                      : {})}
                                  />
                                  <Stack spacing="0.5" maxW="350px" paddingY={2} isTruncated>
                                    {user.name && (
                                      <Flex alignItems="baseline" gap={1} isTruncated>
                                        <TextEllipsis tooltip maxW="100%" fontSize="sm" fontWeight="semibold">
                                          {user.name}
                                        </TextEllipsis>
                                        {user.id == currentUser?.id && (
                                          <Text flex="none" fontSize="sm" fontWeight="medium" color="gray.500">
                                            (You)
                                          </Text>
                                        )}
                                      </Flex>
                                    )}
                                    <TextEllipsis tooltip maxW="100%" fontSize="xs" color="gray.500">
                                      {user.email}
                                    </TextEllipsis>
                                  </Stack>
                                </HStack>
                              </Td>
                              <Td>
                                <Flex>
                                  {canManageMembers && !user.pending ? (
                                    <Select
                                      name="role"
                                      size="xs"
                                      flex="none"
                                      rounded="md"
                                      minWidth="90px"
                                      width="100%"
                                      value={user.role}
                                      onChange={(event) => updateUserRole(user.id, event.target.value, user.role)}
                                    >
                                      {roles.map((r) => (
                                        <option key={r.value} value={r.value}>
                                          {r.selectName ?? r.name}
                                        </option>
                                      ))}
                                    </Select>
                                  ) : (
                                    <Flex flex="none" gap={2} alignItems="center">
                                      <Text fontSize="xs">{roles.find((r) => r.value === user.role)?.name}</Text>
                                      {user.pending && (
                                        <Badge size="xs" variant="regular">
                                          Pending
                                        </Badge>
                                      )}
                                    </Flex>
                                  )}
                                </Flex>
                              </Td>
                              <Td width="1px">{user.added_at && <TimeAgo time={user.added_at} mode="calendar" />}</Td>
                              <Td width="1px">
                                {!user.pending && <TimeAgo time={user.last_app_interaction_at ?? user.updated_at} />}
                              </Td>
                              <Td width="1px" isNumeric>
                                {!user.pending && (
                                  <Link
                                    href={accountsPath(props.account_ownership_stats[user.id]?.facets)}
                                    variant="dotted"
                                    isExternal
                                    fontWeight="normal"
                                    _hover={{
                                      '.chakra-icon': {
                                        color: 'purple.600'
                                      }
                                    }}
                                  >
                                    {format(user.stats ?? 0)}
                                    <Icon as={IconArrowUpRight} boxSize={4} color="gray.400" />
                                  </Link>
                                )}
                              </Td>
                              <Td width="1px">
                                {user.pending ? (
                                  <DeleteInvite inviteId={user.id} email={user.email} />
                                ) : (
                                  <RemoveFromTeam
                                    userId={user.id}
                                    email={user.email}
                                    name={user.name}
                                    image={user.image}
                                    role={user.role}
                                    canManageMembers={canManageMembers}
                                  />
                                )}
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Stack>
                )
              })}
            </Stack>
          </Stack>
        </Stack>

        <BulkActionBar selectionCount={selectedMembers?.length ?? 0} onRemoveSelection={onRemoveSelection}>
          <AssignRoleToMultiple selectedMembers={selectedMembers} />
          <RemoveMultiple selectedMembers={selectedMembers} />
        </BulkActionBar>
      </PageLayout>
    </>
  )
}

interface AssignRoleToMultipleProps {
  selectedMembers: CombinedUsers[]
}

function AssignRoleToMultiple(props: AssignRoleToMultipleProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [submitting, setSubmitting] = React.useState(false)
  const formId = 'assign-member-roles-form'

  const currentRole = React.useMemo(() => {
    const roles = new Set(props.selectedMembers.map((m) => m.role))
    if (roles.size === 1) {
      return roles.values().next().value
    }
  }, [props.selectedMembers])

  return (
    <>
      <Button size="sm" variant="outline" leftIcon={<IconLicense size={16} />} iconSpacing={1.5} onClick={onOpen}>
        Change role
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody paddingTop={6}>
            <form
              id={formId}
              action={projectPath(`/settings/members/change-role`)}
              method="post"
              onSubmit={() => {
                setSubmitting(true)
              }}
            >
              <AuthenticityToken />
              <input type="hidden" name="_method" value="PUT" />

              {props.selectedMembers.map((member) => (
                <input key={member.id || member.email} type="hidden" name="member_emails[]" value={member.email} />
              ))}

              <Stack>
                <Heading size="sm" fontWeight="semibold">
                  Change roles for {props.selectedMembers.length} {pluralize('member', props.selectedMembers.length)}
                </Heading>

                <FormControl size="sm">
                  <FormLabel color="gray.600">What role do you want to assign?</FormLabel>
                  <RadioGroup name="new_role" defaultValue={currentRole}>
                    <Box borderTop="1px solid" borderColor="gray.200">
                      {roles.map((role) => (
                        <Flex key={role.value} paddingY={3} borderBottom="1px solid" borderColor="gray.200" gap="2">
                          <Radio id={`change-role:${role.value}`} value={role.value} flex="0 0 150px" isRequired>
                            <Text as="label" htmlFor={`change-role:${role.value}`} fontSize="sm" fontWeight="medium">
                              {role.name}
                            </Text>
                          </Radio>
                          <Text flex="1 1 auto" fontSize="sm" color="gray.500">
                            {role.description}
                          </Text>
                        </Flex>
                      ))}
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Stack>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button type="button" variant="outline" size="sm" onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button type="submit" form={formId} size="sm" colorScheme="purple" isLoading={submitting}>
              Assign role
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

interface RemoveMultipleProps {
  selectedMembers: CombinedUsers[]
}

function RemoveMultiple(props: RemoveMultipleProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [submitting, setSubmitting] = React.useState(false)
  const formId = 'remove-members-form'

  return (
    <>
      <Button
        size="sm"
        variant="outline"
        leftIcon={<IconTrash size={16} />}
        iconSpacing={1.5}
        colorScheme="red"
        onClick={onOpen}
      >
        Remove members…
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody paddingTop={6}>
            <form
              id={formId}
              action={projectPath(`/settings/members/remove`)}
              method="post"
              onSubmit={() => {
                setSubmitting(true)
              }}
            >
              <AuthenticityToken />
              <input type="hidden" name="_method" value="delete" />

              {props.selectedMembers.map((member) => (
                <input key={member.id || member.email} type="hidden" name="member_emails[]" value={member.email} />
              ))}

              <Stack>
                <Heading size="sm" fontWeight="semibold">
                  Remove {props.selectedMembers.length} {pluralize('member', props.selectedMembers.length)}
                </Heading>
                <Text fontSize="sm" color="gray.600">
                  Are you sure? Removing members will revoke their access to this workspace.
                </Text>
              </Stack>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button type="button" variant="outline" size="sm" onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button type="submit" form={formId} size="sm" colorScheme="red" isLoading={submitting}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

interface RemoveFromTeamProps {
  canManageMembers?: boolean
  userId: string
  email: string
  name?: string
  image?: string
  role?: string
}

function RemoveFromTeam(props: RemoveFromTeamProps) {
  const currentUser = useCurrentUser()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [submitting, setSubmitting] = React.useState(false)
  const isCurrentUser = currentUser.id === props.userId
  const formId = 'remove-member-' + props.userId

  if (!props.canManageMembers && !isCurrentUser) {
    return null
  }

  return (
    <>
      <IconButton
        aria-label={isCurrentUser ? 'Leave' : 'Remove'}
        variant="ghost"
        size="sm"
        onClick={onOpen}
        colorScheme={'gray'}
        icon={isCurrentUser ? <IconDoorExit size="16" /> : <IconTrash size="16" />}
      />
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody paddingTop={6}>
            <form
              id={formId}
              action={projectPath(isCurrentUser ? `/settings/members/leave` : `/settings/members/${props.userId}`)}
              method="post"
              onSubmit={() => {
                setSubmitting(true)
              }}
            >
              <AuthenticityToken />
              <input type="hidden" name="_method" value="delete" />
              <Stack>
                <Text fontSize="md" fontWeight="semibold">
                  {isCurrentUser ? `Leave this workspace` : `Remove ${props.email}`}
                </Text>
                <Text fontSize="sm" color="gray.600">
                  Are you sure?{' '}
                  {isCurrentUser
                    ? `You won't be able to access this workspace if you leave it.`
                    : `They will not be able to access this workspace anymore.`}
                </Text>
              </Stack>
              {!isCurrentUser && (
                <HStack as={Card} px={3} py={3} mt={4}>
                  <StatusAvatar
                    flex="none"
                    marginRight={1}
                    size="sm"
                    name={props.name}
                    email={props.email}
                    src={props.image}
                  />
                  <Stack flex="1" spacing="0" isTruncated>
                    {props.name && (
                      <HStack>
                        <TextEllipsis tooltip maxW="100%" fontSize="sm" fontWeight="semibold">
                          {props.name}
                        </TextEllipsis>
                        {props.role && (
                          <Badge
                            size="xs"
                            variant="regular"
                            colorScheme={props.role !== 'developer' ? 'green' : 'gray'}
                          >
                            {roles.find((r) => r.value === props.role)?.name}
                          </Badge>
                        )}
                      </HStack>
                    )}
                    <TextEllipsis tooltip maxW="100%" fontSize="xs" color="gray.500">
                      {props.email}
                    </TextEllipsis>
                  </Stack>
                </HStack>
              )}
            </form>
          </ModalBody>
          <ModalFooter>
            <Button type="button" variant="outline" size="sm" onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button type="submit" form={formId} size="sm" colorScheme="red" isLoading={submitting}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

function DeleteInvite(props: { inviteId: string; email: string }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [submitting, setSubmitting] = React.useState(false)
  const formId = 'revoke-invite-' + props.inviteId

  return (
    <>
      <IconButton aria-label="Revoke" icon={<IconTrash size="14" />} variant="ghost" size="sm" onClick={onOpen} />
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody paddingTop={6}>
            <form
              id={formId}
              action={projectPath(`/invites/${props.inviteId}`)}
              method="post"
              onSubmit={() => {
                setSubmitting(true)
              }}
            >
              <AuthenticityToken />
              <input type="hidden" name="_method" value="delete" />
              <Text fontSize="md" fontWeight="semibold">
                Are you sure you want to revoke the invite for {props.email}?
              </Text>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button type="button" variant="outline" size="sm" onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button type="submit" form={formId} size="sm" colorScheme="red" isLoading={submitting}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
