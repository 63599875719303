import { Box, Flex, Link, Skeleton, StackDivider, Text, Tooltip, VStack } from '@chakra-ui/react'
import { format } from 'friendly-numbers'
import orderBy from 'lodash/orderBy'
import React, { useMemo } from 'react'
import CompanyAvatar from '../../../ui/CompanyAvatar'

type TopListProps<D extends string = 'value', L extends string = 'label'> = {
  items: CategoricalData<D, L>[]
  dataKey?: D | string
  labelKey?: L | string
  limit?: number
  isLoading?: boolean
  formatter?: (value: number, ...args: any[]) => string
  labelRenderer?: (item: { label: string; domain?: string; count: number; permalink?: string }) => React.ReactNode
  showBg?: boolean
}

export type CategoricalData<D extends string = 'value', L extends string = 'label'> = Record<D | L, number> & {
  extra?: Record<string, any>
}

export function TopList(props: TopListProps) {
  const dataKey = props.dataKey || 'value'
  const labelKey = props.labelKey || 'label'
  const limit = props.limit || 10
  const data = orderBy(props.items, ['value'], ['desc']).slice(0, limit)
  const max = data[0]?.[dataKey]
  const placeholders = useMemo(() => {
    return Array.from({ length: limit }, (_, i) => i)
  }, [limit])

  return (
    <VStack w="100%" alignItems="normal" spacing={0} divider={<StackDivider />}>
      {props.isLoading
        ? placeholders.map((i) => (
            <Box key={i} paddingX={3} paddingY={3}>
              <Skeleton startColor="background.light" endColor="gray.200" height="20px" width="100%" />
            </Box>
          ))
        : data.map((item) => (
            <TopListRow
              key={JSON.stringify({ label: item[labelKey], count: item[dataKey], key: item.extra?.key })}
              label={item[labelKey] || 'unknown'}
              permalink={item.extra?.permalink}
              domain={item.extra?.domain}
              count={item[dataKey]}
              formatter={props.formatter}
              labelRenderer={props.labelRenderer}
              max={props.showBg ? max : undefined}
            />
          ))}
    </VStack>
  )
}

interface TopListRowProps {
  label: string
  count: number
  permalink?: string
  domain?: string
  formatter?: TopListProps['formatter']
  labelRenderer?: (item: { label: string; domain?: string; count: number; permalink?: string }) => React.ReactNode
  max?: number
}

function TopListRow({ labelRenderer, ...props }: TopListRowProps) {
  const formatter = props.formatter || format
  return (
    <Box
      paddingX={3}
      paddingY={2}
      lineHeight="20px"
      {...(props.permalink
        ? {
            isExternal: true,
            href: props.permalink,
            as: Link,
            cursor: 'pointer',
            _hover: {
              bg: 'background.light',
              textDecoration: 'none',
              '& .top-list-bg': {
                bg: 'transparent'
              }
            }
          }
        : {
            _hover: {
              bg: 'background.light',
              '& .top-list-bg': {
                bg: 'transparent'
              }
            }
          })}
    >
      <Flex position="relative" fontSize="sm" alignItems="center" gap={2}>
        {props.max && (
          <Box
            className="top-list-bg"
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            width={`${(props.count / props.max) * 100}%`}
            bg="background.highlight"
            rounded="sm"
          />
        )}
        <Flex position="relative" overflow="hidden" gap={2} paddingLeft={props.max ? 1.5 : 0} paddingY={1}>
          {labelRenderer ? (
            labelRenderer(props)
          ) : (
            <>
              {props.domain && <CompanyAvatar domain={props.domain} size="20px" />}
              <Tooltip label={props.label.length > 50 ? props.label : undefined}>
                <Text isTruncated>{props.label}</Text>
              </Tooltip>
            </>
          )}
        </Flex>
        <Text
          position="relative"
          flex="none"
          textAlign="right"
          fontWeight="semibold"
          css={{ fontVariantNumeric: 'tabular-nums' }}
          marginLeft="auto"
          width="100px"
          paddingRight={props.max ? 1.5 : 0}
        >
          {formatter(props.count, { formattedDecimals: 1 })}
        </Text>
      </Flex>
    </Box>
  )
}
