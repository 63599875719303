import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Stack,
  Switch,
  Text
} from '@chakra-ui/react'
import { IconArrowDown, IconBell, IconBolt, IconFilter, IconTarget } from '@tabler/icons-react'
import React from 'react'
import { Apps } from '../../../../types/App'
import { theme } from '../../../theme'
import { Card } from '../../../ui/Card'
import { SegmentedControl } from '../../../ui/SegmentedControl'
import { useCurrentUser } from '../../../ui/UserContext'
import { FollowRule } from '../../notifications'
import { DeliverySetup } from './delivery-setup'
import { NotificationRulePreview } from './preview'
import { TSetup } from './territory-setup'
import { TriggerSetup } from './trigger-v2'

interface StepCardProps {
  children: React.ReactNode
  title: React.ReactNode
  logo: React.ReactNode
  wrap?: boolean
}

export function StepCard(props: StepCardProps) {
  return (
    <HStack
      position={'relative'}
      as={Card}
      gap="8"
      w="100%"
      flexWrap={
        props.wrap
          ? {
              lg: 'nowrap',
              base: 'wrap'
            }
          : undefined
      }
      justifyContent={'center'}
    >
      <Stack justifyContent={'center'} alignItems="center" spacing="2" minW="80px">
        {props.logo}
        <Text fontSize="sm" fontWeight={'semibold'}>
          {props.title}
        </Text>
      </Stack>
      <Divider
        orientation="vertical"
        h="8"
        display={{
          base: 'none',
          md: 'unset'
        }}
      />
      {props.children}
    </HStack>
  )
}

interface Props {
  follow_rule?: FollowRule
  apps: Apps
  formRef?: React.RefObject<HTMLFormElement>
  errors?: Record<string, string[]>
}

function StepDivider() {
  return (
    <Flex w="100%" justifyContent={'center'} alignItems="center">
      <IconArrowDown />
    </Flex>
  )
}

export function NotificationRuleForm(props: Props) {
  const currentUser = useCurrentUser()

  const [recipient, _setRecipient] = React.useState(props.follow_rule?.recipient_id ?? currentUser.id)
  const [targetType, setTargetType] = React.useState(props.follow_rule?.target_type ?? 'Profile')

  const [enabled, setEnabled] = React.useState(props.follow_rule?.enabled ?? true)

  const [includeUnassociatedVisitors, setIncludeUnassociatedVisitors] = React.useState(
    props.follow_rule?.include_unassociated_visitors ?? false
  )

  return (
    <Stack spacing="8" w="100%" bg="gray.50" p="8" overflowX={'auto'}>
      <StepCard title="Setup" logo={<IconBell size="22" />} wrap>
        <HStack alignItems="flex-start" justifyContent={'space-between'} spacing="12" w="100%">
          <FormControl>
            <FormLabel>Action name</FormLabel>
            <Input required size="sm" rounded={'md'} name="follow_rule[name]" defaultValue={props.follow_rule?.name} />
          </FormControl>
          <FormControl>
            <FormLabel>{enabled ? 'Active' : 'Inactive'}</FormLabel>
            <input type="hidden" name="follow_rule[enabled]" value={enabled ? 'true' : 'false'} />
            <Switch
              size="md"
              id="follow_rule[enabled]"
              isChecked={enabled}
              onChange={(e) => setEnabled(e.target.checked)}
            />
          </FormControl>
        </HStack>
      </StepCard>

      <StepDivider />

      <StepCard logo={<IconTarget size="22" color={theme.colors.pink['300']} />} title="Targeting" wrap>
        <Stack spacing={4}>
          <FormControl>
            <FormLabel>This automation will target</FormLabel>

            <Box>
              <SegmentedControl display="inline-flex" width="auto">
                <Button minWidth="180px" isActive={targetType === 'Profile'} onClick={() => setTargetType('Profile')}>
                  Visitors
                </Button>
                <Button minWidth="180px" isActive={targetType === 'Account'} onClick={() => setTargetType('Account')}>
                  Accounts
                </Button>
              </SegmentedControl>
            </Box>

            <FormHelperText>
              You can target entire Accounts or individual Visitors. Actions targeting accounts will be aggregated
              across the entire account.
            </FormHelperText>

            <input type="hidden" name="follow_rule[target_type]" value={targetType} />
            <input
              type="hidden"
              name="follow_rule[include_unassociated_visitors]"
              value={String(includeUnassociatedVisitors)}
            />
          </FormControl>

          {targetType === 'Profile' && (
            <FormControl>
              <Checkbox
                isChecked={includeUnassociatedVisitors}
                onChange={(e) => {
                  setIncludeUnassociatedVisitors(e.target.checked)
                }}
              >
                Include visitors that are not associated with a company? (e.g. gmail.com users)
              </Checkbox>
            </FormControl>
          )}
        </Stack>
      </StepCard>

      <StepDivider />

      <StepCard wrap logo={<IconBolt color="orange" size="22" />} title="Trigger">
        <Stack>
          {props.errors?.['intent_rules'] && (
            <Alert status="error">
              <AlertIcon />
              <Stack spacing="-1">
                <AlertTitle>Validation Error</AlertTitle>
                <AlertDescription>{props.errors['intent_rules'].join(', ')}</AlertDescription>
              </Stack>
            </Alert>
          )}
          <TriggerSetup follow_rule={props.follow_rule} targetType={targetType} />
        </Stack>
      </StepCard>

      <StepDivider />

      <input type="hidden" name="follow_rule[recipient_id]" value={recipient} />

      <StepCard title="Filter" logo={<IconFilter color={theme.colors.blue['300']} size="22" />} wrap>
        <FormControl>
          <FormLabel>Refine the audience...</FormLabel>
          {recipient && (
            <Box w="100%">
              <TSetup
                // needs to change with the recipient in order to reset all facet filters
                key={recipient}
                targetType={targetType}
                followRule={props.follow_rule}
                apps={props.apps}
              />
            </Box>
          )}
        </FormControl>
      </StepCard>

      <StepDivider />

      <DeliverySetup targetType={targetType} follow_rule={props.follow_rule} />

      <HStack justifyContent={'space-between'} flexWrap="wrap">
        {props.formRef && <NotificationRulePreview formRef={props.formRef} />}
        <Button type="submit" colorScheme={'purple'} flex="1">
          Save
        </Button>
      </HStack>
    </Stack>
  )
}
