import {
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Stack,
  Text,
  Tooltip
} from '@chakra-ui/react'
import {
  IconAlertTriangle,
  IconApps,
  IconArrowRight,
  IconArrowUpRight,
  IconCheck,
  IconExternalLink,
  IconLock,
  IconLockOpen,
  IconMail,
  IconMessage,
  IconPlus
} from '@tabler/icons-react'
import uniq from 'lodash/uniq'
import React, { useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { useBillingMetrics } from '../../data/use-billing-metrics'
import { openUpgradeFlow } from '../../ui/billing-banners/accounts-banner'
import { LightBgCard } from '../../ui/Card'
import EmptyState from '../../ui/EmptyState'
import { FeedbackForm, openWishForm } from '../../ui/FeedbackForm'
import { Iconify } from '../../ui/Iconify'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath, useCurrentProject } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { SearchBar } from '../accounts/facets/search-bar'
import { Plan } from '../billing/plans'

interface IntegrationCardProps {
  name: string
  logo: string
  release: 'Coming Soon' | 'Alpha' | 'Early Access' | 'Beta' | 'GA'
  description: string
  connected?: boolean
  valid?: boolean
  docsUrl?: string
  manageable?: boolean
  editUrl?: string
  categories: string[]
  locked?: boolean
  planTier?: string
  currentPlan?: Plan
}

const IntegrationCard = (props: IntegrationCardProps) => {
  const requireAccess = props.release?.toLowerCase() === 'early access'
  const comingSoon = props.release?.toLowerCase() === 'coming soon'

  return (
    <LightBgCard alignSelf="stretch" rounded="lg" p={5} opacity={comingSoon ? 0.7 : 1}>
      <Stack spacing="5" h="100%">
        <HStack spacing="2" justifyContent="space-between" alignItems="flex-start">
          {props.name === 'RB2B' ? (
            <Image src={props.logo} width={8} height={8} padding={1} rounded="sm" bg="black" objectFit="contain" />
          ) : (
            <Image src={props.logo} width="auto" height={8} rounded="sm" />
          )}

          {props.connected && props.valid ? (
            <HStack color="green.600" bg="green.50" paddingX={2} paddingY={0.5} rounded="full" spacing={1}>
              <Icon as={IconCheck} boxSize={4} />
              <Text fontSize="xs" fontWeight="semibold">
                Connected
              </Text>
            </HStack>
          ) : props.connected && !props.valid && !props.locked ? (
            <HStack color="orange.600" bg="orange.50" paddingX={2} paddingY={0.5} rounded="full" spacing={1}>
              <Icon as={IconAlertTriangle} boxSize={4} />
              <Text fontSize="xs" fontWeight="semibold">
                Needs attention
              </Text>
            </HStack>
          ) : !props.connected && props?.planTier ? (
            <Box color="purple.600" bg="purple.50" paddingX={2} paddingY={0.5} rounded="full">
              <Text fontSize="xs" fontWeight="semibold">
                {props.planTier}
              </Text>
            </Box>
          ) : props.release?.toLowerCase() === 'early access' ? (
            <Box color="pink.600" bg="pink.50" paddingX={2} paddingY={0.5} rounded="full">
              <Text fontSize="xs" fontWeight="semibold">
                Early Access
              </Text>
            </Box>
          ) : props.release?.toLowerCase() === 'coming soon' ? (
            <Box color="gray.600" bg="gray.50" paddingX={2} paddingY={0.5} rounded="full">
              <Text fontSize="xs" fontWeight="semibold">
                Coming Soon
              </Text>
            </Box>
          ) : null}
        </HStack>

        <Stack flex="1" spacing={2}>
          <HStack spacing="2">
            <Heading size="sm">{props.name}</Heading>
            <Flex>
              {(props.release?.toLowerCase() === 'beta' || props.release?.toLowerCase() === 'alpha') && (
                <Badge variant="solid" colorScheme="purple">
                  {props.release}
                </Badge>
              )}
            </Flex>
          </HStack>

          <Text fontSize="sm" color="gray.600">
            {props.description}
          </Text>
        </Stack>

        {!props.connected &&
        (props.locked || (props.planTier?.toLowerCase()?.includes('business') && props.currentPlan !== 'business')) ? (
          <HStack>
            <Tooltip label={`Upgrade your plan to unlock ${props.name} as an integration`}>
              <Button
                leftIcon={<Icon as={IconArrowUpRight} boxSize={4} />}
                colorScheme="lightPurple"
                variant="outline"
                size="sm"
                onClick={() => openUpgradeFlow('business')}
              >
                Upgrade Plan
              </Button>
            </Tooltip>
            <Tooltip label={`Request a trial of ${props.name}`}>
              <IconButton
                variant="outline"
                size="sm"
                aria-label="Request access"
                onClick={() =>
                  openWishForm({
                    title: `Request trial access to ${props.name}`,
                    placeholder: `Let us know how you would like to use the source.`,
                    variant: 'modal',
                    preamble: `Requesting access to ${props.name} integration`
                  })
                }
                icon={<Icon as={IconMail} size={16} />}
              />
            </Tooltip>
          </HStack>
        ) : (
          <Flex>
            {requireAccess && !props.connected ? (
              <Button
                variant="outline"
                size="sm"
                onClick={() =>
                  openWishForm({
                    title: `Request access to ${props.name}`,
                    placeholder: 'Let us know how you would like to use the source',
                    variant: 'modal',
                    preamble: `Requesting access to ${props.name} integration`
                  })
                }
                iconSpacing={1}
                leftIcon={<Icon as={IconLockOpen} size={16} />}
              >
                Request access
              </Button>
            ) : comingSoon ? null : props.editUrl || props.manageable ? (
              <Button
                variant="outline"
                size="sm"
                as="a"
                href={props.editUrl}
                iconSpacing={1}
                rightIcon={props.connected ? <Icon as={IconArrowRight} boxSize={4} /> : undefined}
              >
                {props.connected ? 'Manage' : 'Connect'}
              </Button>
            ) : props.docsUrl ? (
              <Button
                size="sm"
                variant="outline"
                as={Link}
                href={props.docsUrl}
                isExternal
                rightIcon={<IconExternalLink size={16} />}
              >
                Get set up
              </Button>
            ) : (
              <Tooltip label="Coming Soon" shouldWrapChildren>
                <Button size="sm">Connect</Button>
              </Tooltip>
            )}
          </Flex>
        )}
      </Stack>
    </LightBgCard>
  )
}

interface CatalogApp {
  title: string
  description: string
  logo: string
  module: string
  clean_module_name: string
  categories: string[]
  release: 'Alpha' | 'Early Access' | 'Beta' | 'GA'
  docs_url: string
  plan_tier?: string
  manageable?: boolean
}

interface App {
  id: string
  project_id: string
  app_module: string
  connected?: boolean
  client_valid?: boolean
}

interface AppsProps {
  apps: Record<string, App>
  catalog: CatalogApp[]
}

function matchesApp(app: CatalogApp, search: string): boolean {
  if (app.title.toLowerCase().includes(search)) {
    return true
  }

  if (app.categories.some((c) => c.toLowerCase().includes(search))) {
    return true
  }

  return false
}

export default function Index(props: AppsProps) {
  const project = useCurrentProject()
  const connectedApps = Object.keys(props.apps).filter((mod) => props.apps[mod].connected)
  const validApps = Object.keys(props.apps).filter((mod) => props.apps[mod].connected && props.apps[mod].client_valid)
  const crmLimit = useMemo(() => project?.koala_subscription?.entitlements?.crm_limit, [project])
  const currentPlan = useMemo(() => project?.koala_subscription?.plan, [project])

  const metrics = useBillingMetrics()
  const connectedCRMs = useMemo(() => metrics.data?.usage?.crms ?? 0, [metrics.data?.usage?.crms])

  const [searchQuery, setSearchQuery] = useState('')
  const [debouncedSearch] = useDebounce(searchQuery, 300)

  // Sort apps so that "Coming Soon" apps are at the end
  const sortedApps = React.useMemo(() => {
    return props.catalog.sort((a, b) => {
      const aComingSoon = a.release?.toLowerCase() === 'coming soon'
      const bComingSoon = b.release?.toLowerCase() === 'coming soon'

      if (aComingSoon && !bComingSoon) {
        return 1
      } else if (!aComingSoon && bComingSoon) {
        return -1
      } else {
        return 0
      }
    })
  }, [props.catalog])

  const apps = React.useMemo(() => {
    const displayed: CatalogApp[] = []
    const search = debouncedSearch?.toLowerCase()?.trim()

    for (const app of sortedApps) {
      if (search && !matchesApp(app, search)) {
        continue
      }

      displayed.push(app)
    }

    return displayed
  }, [sortedApps, debouncedSearch])

  const categories = React.useMemo(
    () =>
      uniq(apps.flatMap((app) => app.categories)).sort((a, b) => {
        const matchA = apps.filter((c) => c.categories.includes(a)).length
        const matchB = apps.filter((c) => c.categories.includes(b)).length
        // Pushes Tech Docs "apps" to the end
        if (a === 'Tech Docs') {
          return 1
        }

        if (a === 'Web & Product Data') {
          return -1
        }

        if (matchA > matchB) {
          return -1
        }

        return 0
      }),
    [apps]
  )

  const noMatches = debouncedSearch && apps.length === 0

  return (
    <PageLayout size="full">
      <SettingsBreadCrumb rootPath={{ path: projectPath('/apps'), title: 'Integrations' }} offscreen />

      <SettingsHeader mb={0}>
        <PageTitle>Sources & Integrations</PageTitle>
        <PageDescription>
          Connect sources to surface people and company signals in Koala or to send data from Koala to your team's
          favorite tools.
        </PageDescription>
      </SettingsHeader>

      <SearchBar
        size="sm"
        placeholder="Search for sources or integrations…"
        value={searchQuery}
        onChange={setSearchQuery}
      />

      <Stack spacing={10} paddingBottom="100px">
        {noMatches && (
          <EmptyState
            size="md"
            heading="No sources matching your search"
            description="Couldn't find a source or integration? Let us know what we are missing!"
            icon={IconApps}
          >
            {project?.slug && (
              <FeedbackForm
                projectSlug={project.slug}
                title="Request an integration"
                placeholder="Let us know what source or integration we are missing, and how you want to use it in Koala!"
                preamble="New integration request"
                variant="modal"
              >
                <Button
                  size="md"
                  variant="outline"
                  rounded="md"
                  leftIcon={<Icon as={IconPlus} boxSize={4} color="purple.600" />}
                  iconSpacing={1}
                >
                  Request integration
                </Button>
              </FeedbackForm>
            )}
          </EmptyState>
        )}
        {categories.map((cat) => (
          <Stack key={cat} spacing="4">
            <Heading size="sm">{cat}</Heading>
            <Grid templateColumns={['repeat(1, 1fr)', 'repeat(auto-fill, minmax(250px, 1fr))']} gap={4}>
              {apps
                .filter((app) => app.categories.includes(cat))
                .map((app) => (
                  <IntegrationCard
                    key={app.module}
                    categories={app.categories}
                    release={app.release}
                    name={app.title}
                    description={app.description}
                    connected={connectedApps.includes(app.module)}
                    valid={validApps.includes(app.module)}
                    planTier={app.plan_tier}
                    manageable={app.manageable}
                    currentPlan={currentPlan ?? undefined}
                    locked={
                      !!crmLimit &&
                      connectedCRMs >= crmLimit &&
                      app.categories.includes('CRM') &&
                      !connectedApps.includes(app.module)
                    }
                    logo={app.logo}
                    docsUrl={app.docs_url ? app.docs_url : undefined}
                    editUrl={
                      app.manageable
                        ? projectPath(`/apps/${app.clean_module_name}`)
                        : app.docs_url
                          ? undefined
                          : app.title === 'Website tracking'
                            ? projectPath('/settings/install')
                            : projectPath(`/apps/${app.clean_module_name}`)
                    }
                  />
                ))}
            </Grid>
          </Stack>
        ))}
        {!debouncedSearch && project?.slug && (
          <Stack spacing="4">
            <Heading size="sm">Other</Heading>
            <Grid templateColumns={['repeat(1, 1fr)', 'repeat(auto-fill, minmax(250px, 1fr))']} gap={4}>
              <LightBgCard alignSelf="stretch" rounded="lg" p={5}>
                <Stack spacing="5" h="100%">
                  <Iconify icon={IconApps} size={32} />

                  <Stack flex="1" spacing={2}>
                    <Heading size="sm">Request an integration</Heading>

                    <Text fontSize="sm" color="gray.600">
                      Not seeing an integration or signal source? Let us know what it is!
                    </Text>
                  </Stack>

                  <Flex>
                    <FeedbackForm
                      projectSlug={project.slug}
                      title="Request an integration"
                      preamble="New integration request"
                      placeholder="Let us know what source or integration we are missing, and how you want to use it in Koala!"
                      variant="modal"
                    >
                      <Button
                        variant="outline"
                        size="sm"
                        iconSpacing={1}
                        leftIcon={<Icon as={IconMessage} size={16} />}
                      >
                        Request integration
                      </Button>
                    </FeedbackForm>
                  </Flex>
                </Stack>
              </LightBgCard>
            </Grid>
          </Stack>
        )}
      </Stack>

      {project?.slug && <FeedbackForm projectSlug={project.slug} />}
    </PageLayout>
  )
}
