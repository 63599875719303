import {
  Button,
  Code,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  Textarea,
  UseDisclosureReturn
} from '@chakra-ui/react'
import React from 'react'
import { toast } from 'sonner'
import { post } from '../../../../../lib/api'
import type { Account } from '../../../../../types/Account'
import { useSlack } from '../../../../data/use-slack'
import { ChannelPicker } from '../../../account_view_subscriptions/components/Slack'
import { PopupConnectDialog } from '../../../apps/components/ConnectOauthAppDialog'
import { accountPath } from '../../lib/account-path'

interface Props extends UseDisclosureReturn {
  account: Account
}

export function ShareInSlackModal({ account, ...props }: Props) {
  const onClose = props.onClose
  const slackData = useSlack()

  const [slackMessage, setSlackMessage] = React.useState('')
  const [newChannelName, setNewChannelName] = React.useState<string>()
  const [selectedChannel, setSelectedChannel] = React.useState<string>()
  const [sending, setSending] = React.useState(false)

  const disconnected = !slackData.isLoading && !slackData.data?.connected
  const channels = slackData.data?.deps?.channels ?? []

  const onSlackConnected = React.useCallback(() => {
    slackData.refetch()
  }, [slackData])

  const onSend = React.useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault()
      setSending(true)

      const path = accountPath(account, '/actions/slack')
      post(path, {
        channel_id: selectedChannel,
        new_channel_name: newChannelName,
        message: slackMessage
      }).then(() => {
        toast.success(`Shared ${account.company.name ?? account.company.domain} in Slack!`)

        setSending(false)
        onClose()
      })
    },
    [account, selectedChannel, newChannelName, slackMessage, onClose]
  )

  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center" gap={2}>
          <Image src="https://cdn.worldvectorlogo.com/logos/slack-new-logo.svg" boxSize={5} />
          Share {account.company.name || account.company.domain} in Slack
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {slackData.isLoading && <Spinner />}

          {slackData.data && disconnected && (
            <PopupConnectDialog app_id={slackData.data?.app_id} onConnected={onSlackConnected}>
              {({ onStart }) => (
                <Stack spacing="2">
                  <Stack spacing="0">
                    <Heading size="xs">Connect your Slack Account</Heading>
                    <Text fontSize="xs" color="gray.600">
                      Please connect your Slack account to share links from Koala.
                    </Text>
                  </Stack>
                  <Button size="sm" onClick={onStart} colorScheme="purple">
                    Connect
                  </Button>
                </Stack>
              )}
            </PopupConnectDialog>
          )}

          <Stack spacing={4} marginBottom={4}>
            <Text fontSize="sm" color="gray.600">
              What channel do you want to send this company to? You can pick an existing channel or create a new one.
            </Text>
            <ChannelPicker
              isNewChannelEntered={!!newChannelName}
              channels={channels}
              channel={channels.find((c) => c.id === selectedChannel)}
              setChannel={(channel, inputValue) => {
                if (channel?.id) {
                  setSelectedChannel(channel?.id)
                  setNewChannelName(undefined)
                } else {
                  setNewChannelName(inputValue!)
                  setSelectedChannel(undefined)
                }
              }}
              chakraInputProps={{
                size: 'sm',
                rounded: 'md',
                isDisabled: sending || disconnected,
                isRequired: true
              }}
            />

            {newChannelName && (
              <Stack fontSize="sm" color="orange.800" bg="orange.100" rounded="md" padding={2}>
                <Text>
                  A new public channel will be created with the name{' '}
                  <Code fontSize="sm" fontWeight="semibold" color="orange.800" bg="orange.200">
                    #{newChannelName}
                  </Code>
                </Text>
              </Stack>
            )}

            <FormControl>
              <FormLabel>Message (optional)</FormLabel>
              <Textarea value={slackMessage} onChange={(e) => setSlackMessage(e.target.value)} size="sm" />
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter gap={3}>
          <Button size="sm" variant="outline" onClick={props.onClose}>
            Cancel
          </Button>

          <Button size="sm" colorScheme="purple" isLoading={sending} isDisabled={disconnected} onClick={onSend}>
            Share in Slack
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
