import { Heading, IconButton, Stack } from '@chakra-ui/react'
import { IconSearch } from '@tabler/icons-react'
import React from 'react'
import { HoverCard } from '../../../ui/HoverCard'

interface HoverContextProps {
  title: React.ReactNode
  children: React.ReactNode
  onOpen?: () => void
  iconSize?: string
}

export default function HoverContext(props: HoverContextProps) {
  return (
    <HoverCard
      trigger="click"
      isPortal
      hasCloseButton
      onOpen={props.onOpen}
      popoverContentProps={{
        maxW: '600px',
        minW: '380px'
      }}
      placement="auto"
      modifiers={[
        {
          name: 'preventOverflow',
          options: {
            boundary: 'viewport'
          }
        }
      ]}
      hoverContent={
        <Stack py="2" spacing="3">
          <Heading size="xs">{props.title}</Heading>
          <Stack px="2" spacing="3" fontWeight="normal" fontSize="xs" overflow="auto">
            {props.children}
          </Stack>
        </Stack>
      }
    >
      <IconButton
        icon={<IconSearch size={props.iconSize || '12'} />}
        size="xs"
        variant="ghost"
        aria-label="See more"
        color="gray.400"
        _hover={{ color: 'gray.700' }}
      />
    </HoverCard>
  )
}
