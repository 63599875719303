import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Switch,
  Tooltip
} from '@chakra-ui/react'
import { IconArrowRight, IconDragDrop2 } from '@tabler/icons-react'
import { arrayMoveImmutable } from 'array-move'
import React, { useEffect, useMemo, useState } from 'react'
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import { IntentSequenceMapping } from '../../../../../types/AutoOutbound'
import { useAppDep } from '../../../../data/use-app-dep'
import { Card } from '../../../../ui/Card'
import { ComboboxWithSearch } from '../../../../ui/ComboboxWithSearch'
import { SignalType } from '../../../kql_definitions/components/SignalType'
import { KqlDefinition } from '../../../kql_definitions/types'
import type { ApolloEmailAccount, ApolloSequence } from '../../../profiles/components/apollo-actions'
import { ApolloActionProps } from './apollo-setup'

export function AddToSequence(props: ApolloActionProps) {
  const [enabled, setEnabled] = React.useState(Boolean(props.delivery_rules?.apollo?.add_to_sequence?.enabled))
  const [selectedSequenceId, setSelectedSequenceId] = React.useState<ApolloSequence['id'] | null>(
    props.delivery_rules?.apollo?.add_to_sequence?.sequence_id ?? null
  )

  const { data: sequenceData, isLoading: loadingSequences } = useAppDep<'sequences', ApolloSequence[]>(
    'Apollo',
    'sequences'
  )
  const { data: emailAccountData, isLoading: loadingEmailAccounts } = useAppDep<'email_accounts', ApolloEmailAccount[]>(
    'Apollo',
    'email_accounts'
  )
  const [selectedEmailAccount, setSelectedEmailAccount] = React.useState<ApolloEmailAccount['id'] | null>(
    props.delivery_rules?.apollo?.add_to_sequence?.email_account_id ?? null
  )

  const [sequenceOption, setSequenceOption] = React.useState(
    props.delivery_rules?.apollo?.add_to_sequence?.strategy ?? 'single'
  )

  return (
    <Stack w="100%" spacing="6">
      <FormControl>
        <Switch
          isChecked={enabled}
          onChange={(e) => {
            setEnabled(e.target.checked)
            props.setDeliveryRules({
              ...props.delivery_rules,
              apollo: {
                ...props.delivery_rules?.apollo,
                add_to_sequence: {
                  ...props.delivery_rules?.apollo?.add_to_sequence,
                  enabled: e.target.checked
                }
              }
            })
          }}
          size="sm"
          fontSize={'sm'}
          fontWeight="semibold"
          colorScheme={'purple'}
          value={enabled ? 'true' : 'false'}
          name="follow_rule[delivery_rules][apollo][add_to_sequence][enabled]"
        >
          Enable
        </Switch>
        <FormHelperText>Adds the current visitor to a sequence and creates the contact if not present.</FormHelperText>
      </FormControl>

      {enabled && !loadingEmailAccounts && !loadingSequences && (
        <Stack w="100%" spacing="6">
          {props.kqlDefinitions && (
            <Stack>
              <Heading size="xs">Sequence Strategy</Heading>
              <RadioGroup
                size="sm"
                defaultValue={sequenceOption}
                onChange={(e) => setSequenceOption(e as 'single' | 'custom')}
                name="follow_rule[delivery_rules][apollo][add_to_sequence][strategy]"
              >
                <Stack spacing="0.5">
                  <Radio bg="white" value="single">
                    Single Sequence
                  </Radio>
                  <Radio bg="white" value="custom">
                    Custom per Intent Signal
                  </Radio>
                </Stack>
              </RadioGroup>
            </Stack>
          )}

          <Divider />

          {sequenceOption === 'single' && (
            <>
              <FormControl>
                <FormLabel>Sequence</FormLabel>
                {loadingSequences && <Spinner size="sm" />}
                {selectedSequenceId && (
                  <input
                    type="hidden"
                    name="follow_rule[delivery_rules][apollo][add_to_sequence][sequence_id]"
                    value={selectedSequenceId}
                  />
                )}
                <ComboboxWithSearch
                  items={sequenceData?.data?.sequences ?? []}
                  selectedItem={sequenceData?.data?.sequences?.find((s) => s.id === selectedSequenceId) ?? null}
                  onChange={(selectedItem) => {
                    setSelectedSequenceId(selectedItem?.id ?? null)
                  }}
                  filterItem={(a, val) => a.name.toLowerCase().includes(val)}
                  itemToString={(item) => (item ? `[${item.id}] ${item.name}` : '')}
                />
              </FormControl>
            </>
          )}

          {sequenceOption === 'custom' && (
            <IntentSignalPairs
              sequences={sequenceData?.data?.sequences ?? []}
              mappings={props.delivery_rules?.apollo?.add_to_sequence?.mappings ?? []}
              kqlDefinitions={props.kqlDefinitions ?? []}
            />
          )}

          {!props.kqlDefinitions && (
            <FormControl>
              <FormLabel>Send Emails From</FormLabel>
              {loadingEmailAccounts && <Spinner size="sm" />}
              {selectedEmailAccount && (
                <input
                  type="hidden"
                  name="follow_rule[delivery_rules][apollo][add_to_sequence][email_account_id]"
                  value={selectedEmailAccount}
                />
              )}
              <ComboboxWithSearch
                items={emailAccountData?.data?.email_accounts ?? []}
                selectedItem={
                  emailAccountData?.data?.email_accounts?.find((s) => s.id === selectedEmailAccount) ?? null
                }
                onChange={(selectedItem) => {
                  setSelectedEmailAccount(selectedItem?.id ?? null)
                }}
                filterItem={(a, val) => (a?.email ?? '').toLowerCase().includes(val)}
                itemToString={(item) => item?.email || ''}
              />
            </FormControl>
          )}

          {props.kqlDefinitions && sequenceOption === 'single' && (
            <FormHelperText>Every prospect will be added into the same sequence</FormHelperText>
          )}

          {sequenceOption === 'custom' && (
            <FormHelperText>Each prospect will be added to a specific sequence depending on intent.</FormHelperText>
          )}
        </Stack>
      )}
    </Stack>
  )
}

interface IntentSignalPairsProps {
  kqlDefinitions: KqlDefinition[]
  mappings: IntentSequenceMapping[]
  sequences?: ApolloSequence[]
}

function IntentSignalPairs(props: IntentSignalPairsProps) {
  const mappings = useMemo(() => {
    const maps = props.mappings ?? []

    const mapped = props.kqlDefinitions.reduce((acc, kqlDef) => {
      const map = maps.find((m) => m.kql_definition_id === kqlDef.id)

      acc.push({
        kql_definition_id: kqlDef.id!,
        sequence_id: map?.sequence_id
      })

      return acc
    }, [] as IntentSequenceMapping[])

    // sort mapped by the positions in props.mappings
    return mapped.sort((a, b) => {
      const aIndex = props.mappings.findIndex((m) => m.kql_definition_id === a.kql_definition_id)
      const bIndex = props.mappings.findIndex((m) => m.kql_definition_id === b.kql_definition_id)

      return aIndex - bIndex
    })
  }, [props.mappings, props.kqlDefinitions])

  const [localMappings, setLocalMappings] = useState<IntentSequenceMapping[]>(mappings)

  useEffect(() => {
    setLocalMappings(mappings)
  }, [mappings])

  return (
    <>
      <Stack>
        <Heading size="xs">Pair each Intent Signal to a Sequence</Heading>
        <SortableList
          onSortEnd={(oldIndex: number, newIndex: number) => {
            setLocalMappings((array) => arrayMoveImmutable(array, oldIndex, newIndex))
          }}
          draggedItemClassName="dragged"
          style={{
            userSelect: 'none'
          }}
        >
          <Stack>
            {localMappings.map((entry) => {
              const kqlDefinition = props.kqlDefinitions.find((def) => def.id === entry.kql_definition_id)
              if (!kqlDefinition?.id) {
                return null
              }

              const sequenceId = entry.sequence_id

              return (
                <SortableItem key={kqlDefinition.id}>
                  <HStack w="100%">
                    <SortableKnob>
                      <Box cursor={'grab'}>
                        <Tooltip label="Drag to change priorities" placement="top">
                          <IconDragDrop2 color="gray" size="14" />
                        </Tooltip>
                      </Box>
                    </SortableKnob>
                    <IntentSequencePair
                      sequences={props.sequences ?? []}
                      kqlDefinition={kqlDefinition}
                      sequenceId={sequenceId}
                    />
                  </HStack>
                </SortableItem>
              )
            })}
          </Stack>
        </SortableList>
      </Stack>
    </>
  )
}

interface IntentSignalPairProps {
  kqlDefinition: KqlDefinition
  sequenceId?: ApolloSequence['id']
  sequences: ApolloSequence[]
}

function IntentSequencePair(props: IntentSignalPairProps) {
  const [selectedSequenceId, setSelectedSequenceId] = React.useState<ApolloSequence['id'] | null>(
    props.sequenceId ?? null
  )
  const kqlDefinition = props.kqlDefinition

  return (
    <>
      <HStack w="100%">
        <Flex bg="white" as={Card} p="2" flex="1" fontWeight={'normal'}>
          <SignalType
            label={`${kqlDefinition.name}${kqlDefinition.enabled === false ? ' (paused)' : ''}`}
            signalType={kqlDefinition.signal_type}
            compact
          />
        </Flex>
        <IconArrowRight size="14" />
        <Flex w="100%" flex="1">
          <SequenceSelector
            sequences={props.sequences}
            setSelectedSequenceId={setSelectedSequenceId}
            selectedSequenceId={selectedSequenceId}
          />
        </Flex>
      </HStack>
      {selectedSequenceId && (
        <>
          <input
            type={'hidden'}
            name={`follow_rule[delivery_rules][apollo][add_to_sequence][mappings][][sequence_id]`}
            value={selectedSequenceId}
          />
          <input
            type={'hidden'}
            name={`follow_rule[delivery_rules][apollo][add_to_sequence][mappings][][kql_definition_id]`}
            value={kqlDefinition.id}
          />
        </>
      )}
    </>
  )
}

interface SequenceSelectorProps {
  selectedSequenceId?: ApolloSequence['id'] | null
  setSelectedSequenceId: (id: ApolloSequence['id'] | null) => void
  sequences: ApolloSequence[]
}

function SequenceSelector(props: SequenceSelectorProps) {
  const sequences = useMemo(() => {
    return props.sequences ?? []
  }, [props.sequences])

  const selectedItem = useMemo(
    () =>
      sequences.find((s) => {
        return s.id.toString() === props.selectedSequenceId?.toString()
      }) ?? null,
    [sequences, props.selectedSequenceId]
  )

  return (
    <>
      <HStack w="100%">
        <ComboboxWithSearch
          items={sequences}
          selectedItem={selectedItem}
          onChange={(selectedItem) => {
            props.setSelectedSequenceId(selectedItem?.id ?? null)
          }}
          filterItem={(a, val) => a.name.toLowerCase().includes(val)}
          itemToString={(item) => `[${item?.id}] ${item?.name || ''}`}
        />
      </HStack>
    </>
  )
}
