import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  Tab,
  TabList,
  Tabs,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useDisclosure,
  UseDisclosureProps,
  Wrap
} from '@chakra-ui/react'
import {
  IconAddressBook,
  IconBriefcaseFilled,
  IconBuildings,
  IconDots,
  IconDotsVertical,
  IconEdit,
  IconMapPinFilled,
  IconMilitaryRankFilled,
  IconPlus,
  IconTableDown,
  IconTrash,
  IconUserCircle,
  IconX
} from '@tabler/icons-react'
import { deepEqual } from 'fast-equals'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { formatNumber } from '../../../../lib/number-format'
import router from '../../../../lib/router'
import { Apps } from '../../../../types/App'
import { useSavePersona } from '../../../data/use-personas'
import { filteredProspectsPath } from '../../../data/use-prospects'
import { useUrlFilters } from '../../../data/use-url-filters'
import { Breadcrumb } from '../../../ui/Breadcrumb'
import { Card } from '../../../ui/Card'
import { CompanyMultiSelector } from '../../../ui/CompanyMultiSelector'
import { DeleteConfirmation } from '../../../ui/DeleteConfirmation'
import EmptyState from '../../../ui/EmptyState'
import { SearchIcon } from '../../../ui/icons/SearchIcon'
import { projectPath } from '../../../ui/ProjectsContext'
import useLocation from '../../../ui/useLocation'
import { useSearchParams } from '../../../ui/useSearchState'
import useUpdateEffect from '../../../ui/useUpdateEffect'
import { FacetFilters } from '../../accounts'
import FilterIllustration from '../../accounts/components/empty-states/filter-illustration.svg'
import { mergeParams } from '../../icps/types'
import { CreatePersonaModal } from '../../personas/components/CreatePersonaModal'
import { RenamePersonaModal } from '../../personas/components/RenamePersonaModal'
import { convertUrlFacetsToPersonaFilters, FilterState } from '../../personas/persona-filters'
import { Persona, PersonaFilters } from '../../prospects/personas'
import { FilterPopover, FilterPopoverProps } from './FilterPopover'
import { ProspectsTableV2 } from './ProspectsTable'

interface Props {
  personas: Persona[]
  selectedPersona: Persona | null
  savedProspectsCount?: number
  apps: Apps
  addToSequenceEnabled: boolean
}

export function ExploreProspectsView(props: Props) {
  const { searchParams, setSearchParams } = useSearchParams({})
  const searchParamsDomains = searchParams.domains as string[]
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>(searchParamsDomains)

  useEffect(() => {
    setSelectedCompanies(searchParamsDomains)
  }, [searchParamsDomains])

  const handleCompanySelectorChange = (companies) => {
    const domainsList = companies.map((company) => company.domain)
    setSearchParams((prev) => ({
      ...prev,
      domains: domainsList
    }))
    setSelectedCompanies(domainsList)
  }

  const removeSelection = useCallback(
    (value: string) => {
      if (selectedCompanies && selectedCompanies.length) {
        const index = selectedCompanies.indexOf(value)
        if (index > -1) {
          const newCompanies = [...selectedCompanies]
          newCompanies.splice(index, 1)
          setSearchParams((prev) => ({
            ...prev,
            domains: newCompanies
          }))
          setSelectedCompanies(newCompanies)
        }
      }
    },
    [selectedCompanies, setSearchParams]
  )

  const tabs = [
    { label: 'People', href: projectPath('/prospector') },
    { label: 'Companies', href: projectPath('/prospector/companies') },
    { label: 'Saved', href: projectPath('/prospector/saved') }
  ]

  const facets = useUrlFilters({ initialRange: null })
  const [personas, setPersonas] = useState(props.personas)
  const [selectedPersona, setSelectedPersona] = useState<Persona | null>(props.selectedPersona)

  const location = useLocation()
  const newPersona = location.pathname.endsWith('/new')
  const savedProspectsSelected = location.pathname.endsWith('/saved')
  const everyoneSelected = !selectedPersona && !savedProspectsSelected

  const tabIndex = savedProspectsSelected ? 2 : 0

  const personaModal = useDisclosure()
  const applyFilters = facets.applyFilters
  const clearFilters = facets.clearFilters

  const [searchQuery, setSearchQuery] = useState(facets.query || '')
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300)

  useUpdateEffect(() => {
    if (debouncedSearchQuery !== facets.query) {
      facets.setQuery(debouncedSearchQuery)
    }
  }, [debouncedSearchQuery])

  const filters = useMemo(() => {
    const converted = convertUrlFacetsToPersonaFilters(facets.facetFilters || {})

    if (selectedPersona) {
      const existing = selectedPersona.filters || {}
      const combined = { ...existing, ...converted }
      return combined
    } else {
      return converted
    }
  }, [facets.facetFilters, selectedPersona])

  useEffect(() => {
    setSelectedPersona(props.selectedPersona)
  }, [props.selectedPersona])

  useEffect(() => {
    setPersonas(props.personas)
  }, [props.personas])

  useEffect(() => {
    setPersonas(personas)
  }, [personas, selectedPersona])

  const persona: Partial<Persona> = useMemo(() => {
    const personaFilters = cloneDeep(selectedPersona?.filters || {}) as PersonaFilters

    const facetKeys = [
      'title_keywords',
      'not_keywords',
      'job_title_role',
      'job_title_levels',
      'job_title_sub_role',
      'location_country',
      'location_region',
      'location_locality'
    ]

    facetKeys.forEach((facet) => {
      if (filters[facet]) {
        personaFilters[facet] = filters[facet]
      }
    })

    return {
      persona_type: 'filter',
      filters: personaFilters
    }
  }, [filters, selectedPersona])

  const selectPersona = useCallback(
    (persona: Persona | null) => {
      const querystring = selectedCompanies?.length
        ? selectedCompanies.map((domain) => `domains[]=${domain}`).join('&')
        : ''
      const basePath = `${projectPath(`/prospector`)}?${querystring}`

      if (persona) {
        setSelectedPersona(persona)
        router.visit(basePath.concat(`&persona=${persona.id}`))
      } else {
        setSelectedPersona(null)
        router.visit(basePath)
      }
    },
    [selectedCompanies]
  )

  const startNewPersona = useCallback(() => {
    clearFilters()
    router.visit(projectPath(`/prospector/new`))
  }, [clearFilters])

  const { isLoading: saving, mutateAsync: savePersona } = useSavePersona()

  const updatePersona = useCallback(async () => {
    if (!selectedPersona) return

    const updatedPersona: Partial<Persona> = {
      ...selectedPersona,
      persona_type: 'filter',
      filters: persona.filters
    }

    const res = await savePersona(updatedPersona)
    setSelectedPersona(res.persona)
    setPersonas((personas) => {
      return personas.map((p) => {
        if (p.id === res.persona.id) {
          return res.persona
        } else {
          return p
        }
      })
    })
    clearFilters()
  }, [selectedPersona, persona, savePersona, clearFilters])

  const onRename = useCallback((updates: Partial<Persona>) => {
    setSelectedPersona((prev) => {
      if (prev?.id === updates.id) {
        return { ...prev, name: updates.name } as Persona
      } else {
        return prev
      }
    })
    setPersonas((personas) => {
      return personas.map((p) => {
        if (p.id === updates.id) {
          return { ...p, name: updates.name }
        } else {
          return p
        }
      })
    })
  }, [])

  const onFilterChange = useCallback(
    (filters: Record<string, FilterState>) => {
      // convert the filters to the format that the url filters expect
      const facetFilters: FacetFilters = {}

      for (const [facet, filter] of Object.entries(filters)) {
        if (filter.operator === 'must') {
          facetFilters[facet] = filter.values
        } else if (filter.operator === 'must_not') {
          facetFilters[facet] = { not: filter.values }
        }
      }

      applyFilters(facetFilters)
    },
    [applyFilters]
  )

  const emptyFilters = isEmpty(persona.filters)

  const hasChanges = useMemo(() => {
    if (selectedPersona) {
      return !deepEqual(selectedPersona.filters, persona.filters)
    }

    return !isEmpty(persona.filters)
  }, [selectedPersona, persona])

  return (
    <Flex flex="1 1 auto" overflow="hidden">
      <Flex flexDirection="column">
        <Flex
          flexShrink={0}
          width="100%"
          alignItems="flex-end"
          height="49px"
          borderRight="1px solid"
          borderColor="gray.200"
        >
          <Tabs
            size="sm"
            variant="line"
            isManual
            defaultIndex={tabIndex}
            paddingX={0}
            align="center"
            width="100%"
            isFitted
          >
            <TabList>
              {tabs.map((tab) => (
                <Tab key={tab.label} as="a" href={tab.href}>
                  {tab.label}
                </Tab>
              ))}
            </TabList>
          </Tabs>
        </Flex>
        <Box
          bg="white"
          borderRight="1px solid"
          borderColor="gray.200"
          padding={5}
          paddingBottom={0}
          minW="300px"
          width="340px"
          overflow="auto"
          display="flex"
          flexDir="column"
          justifyContent="space-between"
          gap={8}
          height="full"
        >
          <Box>
            {selectedCompanies && selectedCompanies.length ? (
              <Wrap spacingX={1} spacingY={2} mb={3}>
                {selectedCompanies.map((value) => (
                  <Tag key={value} size="sm" colorScheme={'purple'}>
                    <TagLabel>{value}</TagLabel>
                    <TagCloseButton onClick={() => removeSelection(value)} />
                  </Tag>
                ))}
              </Wrap>
            ) : null}
            <CompanyMultiSelector
              onChange={(companies) => {
                handleCompanySelectorChange(companies)
              }}
              selectedCompanies={selectedCompanies}
            />
          </Box>
          {!savedProspectsSelected && (
            <Box flex="none" isTruncated>
              {newPersona ? (
                <Breadcrumb
                  display="flex"
                  fontSize="13px"
                  paths={[
                    {
                      path: projectPath(`/prospector`),
                      title: 'Prospector'
                    },
                    { path: projectPath(`/prospector/new`), title: 'New Persona' }
                  ]}
                />
              ) : selectedPersona ? (
                <Breadcrumb
                  display="flex"
                  fontSize="13px"
                  paths={[
                    {
                      path: projectPath(`/prospector`),
                      title: 'Prospector'
                    },
                    {
                      path: projectPath(`/prospector`),
                      title: selectedPersona.name || 'Unnamed Persona'
                    }
                  ]}
                />
              ) : (
                <Breadcrumb
                  display="flex"
                  fontSize="13px"
                  paths={[
                    {
                      path: projectPath(`/prospector`),
                      title: 'Prospector'
                    },
                    {
                      path: projectPath(`/prospector`),
                      title: 'Explore'
                    }
                  ]}
                />
              )}
            </Box>
          )}

          {personas.length > 0 && !newPersona && (
            <Flex flexDir="column" gap={1}>
              <Text fontSize="sm" fontWeight="medium">
                Personas
              </Text>

              <Stack spacing={1}>
                <Box
                  paddingLeft={2.5}
                  paddingRight={1.5}
                  paddingY={2}
                  rounded="md"
                  cursor="pointer"
                  border="1px solid"
                  borderColor={everyoneSelected ? 'purple.300' : 'gray.200'}
                  bg={everyoneSelected ? 'purple.50' : 'transparent'}
                  color={everyoneSelected ? 'purple.600' : 'gray.600'}
                  opacity={everyoneSelected ? 0.8 : 1}
                  _hover={everyoneSelected ? { opacity: 1 } : { bg: 'gray.50', borderColor: 'gray.200' }}
                  onClick={() => selectPersona(null)}
                  isTruncated
                >
                  <HStack spacing={1.5} isTruncated>
                    <Icon as={IconBuildings} boxSize={4} />
                    <Text flex="1 1 auto" fontSize="sm" fontWeight="medium" isTruncated>
                      Everyone
                    </Text>
                  </HStack>
                </Box>
                {!!props.savedProspectsCount && !savedProspectsSelected && (
                  <Box
                    paddingLeft={2.5}
                    paddingRight={1.5}
                    paddingY={2}
                    rounded="md"
                    cursor="pointer"
                    border="1px solid"
                    borderColor={savedProspectsSelected ? 'purple.300' : 'gray.200'}
                    bg={savedProspectsSelected ? 'purple.50' : 'transparent'}
                    color={savedProspectsSelected ? 'purple.600' : 'gray.600'}
                    opacity={savedProspectsSelected ? 0.8 : 1}
                    _hover={savedProspectsSelected ? { opacity: 1 } : { bg: 'gray.50', borderColor: 'gray.200' }}
                    onClick={() => {
                      const path = mergeParams(projectPath(`/prospector/saved`) + location.search, {
                        page: '1',
                        persona: undefined
                      })
                      router.visit(path)
                    }}
                    isTruncated
                  >
                    <HStack spacing={1.5} isTruncated>
                      <Icon as={IconAddressBook} boxSize={4} />
                      <Text flex="1 1 auto" fontSize="sm" fontWeight="medium" isTruncated>
                        My Saved Prospects
                      </Text>

                      <Badge flex="none" variant="pill" colorScheme={savedProspectsSelected ? 'purple' : 'gray'}>
                        {formatNumber(props.savedProspectsCount)}
                      </Badge>
                    </HStack>
                  </Box>
                )}

                {personas.map((persona) => {
                  const isCurrent = persona.id === selectedPersona?.id
                  return (
                    <Box
                      key={persona.id}
                      role="group"
                      position="relative"
                      paddingLeft={2.5}
                      paddingRight={1.5}
                      paddingY={2}
                      rounded="md"
                      cursor="pointer"
                      border="1px solid"
                      borderColor={isCurrent ? 'purple.300' : 'gray.200'}
                      bg={isCurrent ? 'purple.50' : 'transparent'}
                      color={isCurrent ? 'purple.600' : 'gray.600'}
                      opacity={isCurrent ? 0.8 : 1}
                      _hover={isCurrent ? { opacity: 1 } : { bg: 'gray.50', borderColor: 'gray.200' }}
                      onClick={() => selectPersona(persona)}
                      isTruncated
                    >
                      <HStack spacing={1.5} isTruncated>
                        <Icon as={IconUserCircle} boxSize={4} />
                        <Text flex="1 1 auto" fontSize="sm" fontWeight="medium" isTruncated>
                          {persona.name}
                        </Text>
                        {persona.total_count_for_companies && !hasChanges && (
                          <Badge
                            flex="none"
                            variant="pill"
                            colorScheme={isCurrent ? 'purple' : 'gray'}
                            _groupHover={isCurrent ? undefined : { display: 'none' }}
                          >
                            {persona.total_count_for_companies == 10_000
                              ? '10k+'
                              : formatNumber(persona.total_count_for_companies)}
                          </Badge>
                        )}
                        <Box
                          position={isCurrent ? 'static' : 'absolute'}
                          right={1.5}
                          display="flex"
                          flex="none"
                          visibility={isCurrent ? 'visible' : 'hidden'}
                          pointerEvents={isCurrent ? 'auto' : 'none'}
                          _groupHover={{ visibility: 'visible', pointerEvents: 'auto' }}
                        >
                          <PersonaOverflowMenu persona={persona} onRename={onRename} size="tiny" variant="ghost" />
                        </Box>
                      </HStack>
                    </Box>
                  )
                })}
              </Stack>
            </Flex>
          )}

          <Stack>
            <Text fontSize="sm" fontWeight="medium">
              Filters
            </Text>

            <Card padding={0}>
              <Stack divider={<Divider />} spacing={0}>
                <FilterMenu
                  filters={filters}
                  onChange={onFilterChange}
                  facet="job_title_levels"
                  icon={IconMilitaryRankFilled}
                >
                  Seniority
                </FilterMenu>
                <FilterMenu
                  filters={filters}
                  onChange={onFilterChange}
                  facet="job_title_role"
                  icon={IconBriefcaseFilled}
                >
                  Role
                </FilterMenu>
                <FilterMenu
                  filters={filters}
                  onChange={onFilterChange}
                  facet="job_title_sub_role"
                  icon={IconBriefcaseFilled}
                >
                  Job Function
                </FilterMenu>
                <FilterMenu
                  filters={filters}
                  onChange={onFilterChange}
                  facet="title_keywords"
                  notFacet="not_keywords"
                  inputType="keyword"
                  icon={IconBriefcaseFilled}
                >
                  Title Keywords
                </FilterMenu>
                <FilterMenu
                  filters={filters}
                  onChange={onFilterChange}
                  facet="location_country"
                  icon={IconMapPinFilled}
                >
                  Country
                </FilterMenu>
                <FilterMenu filters={filters} onChange={onFilterChange} facet="location_region" icon={IconMapPinFilled}>
                  State / Region
                </FilterMenu>
                <FilterMenu
                  filters={filters}
                  onChange={onFilterChange}
                  facet="location_locality"
                  icon={IconMapPinFilled}
                >
                  City
                </FilterMenu>
              </Stack>
            </Card>
          </Stack>

          <Box marginTop="auto" paddingTop={4} position="sticky" bottom={0} paddingBottom={6} bg="white">
            <Flex gap={3} justifyContent="space-between">
              {selectedPersona?.id && hasChanges ? (
                <Button rounded="lg" colorScheme="purple" onClick={updatePersona} isLoading={saving}>
                  Save changes
                </Button>
              ) : newPersona || hasChanges ? (
                <Button
                  rounded="lg"
                  colorScheme={emptyFilters ? 'gray' : 'purple'}
                  variant={emptyFilters ? 'outline' : 'solid'}
                  leftIcon={<Icon as={IconPlus} boxSize={4} />}
                  iconSpacing={1}
                  isDisabled={emptyFilters}
                  onClick={personaModal.onOpen}
                >
                  Save Persona
                </Button>
              ) : (
                <Button
                  width={selectedPersona ? 'auto' : 'full'}
                  rounded="lg"
                  variant="outline"
                  leftIcon={<Icon as={IconPlus} boxSize={4} color="purple.600" />}
                  iconSpacing={1}
                  onClick={startNewPersona}
                >
                  Start new search
                </Button>
              )}

              {selectedPersona?.id ? (
                <Flex gap={3} justifyContent="space-between">
                  {hasChanges && (
                    <Button
                      flex="none"
                      rounded="lg"
                      variant="outline"
                      onClick={() => {
                        clearFilters()
                      }}
                    >
                      Discard
                    </Button>
                  )}
                  <PersonaOverflowMenu persona={selectedPersona} onRename={onRename} />
                </Flex>
              ) : newPersona || hasChanges ? (
                <Button
                  flex="none"
                  rounded="lg"
                  variant="outline"
                  onClick={() => {
                    selectPersona(null)
                  }}
                >
                  {emptyFilters ? 'Back' : 'Discard'}
                </Button>
              ) : null}
            </Flex>

            <CreatePersonaModal
              {...personaModal}
              persona={persona}
              onSave={(savedPersona) => {
                selectPersona(savedPersona)
              }}
            />
          </Box>
        </Box>
      </Flex>
      <Flex flexDirection="column" flex="1 1 auto" minWidth="300px">
        <HStack
          paddingY={2}
          paddingX={2}
          justifyContent="space-between"
          spacing="4"
          borderBottom="1px solid"
          borderColor="gray.200"
        >
          <Box flex="1">
            <InputGroup size="sm" flex="1 1 100px">
              <InputLeftElement width="7" pointerEvents="none" color="gray.400">
                <SearchIcon boxSize={3.5} />
              </InputLeftElement>
              <Input
                size="sm"
                fontSize="13px"
                background="white"
                outline="none"
                border="none"
                roundedBottom={0}
                focusBorderColor="transparent"
                placeholder="Search for a person"
                paddingLeft={7}
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value)
                }}
              />

              {searchQuery && (
                <InputRightElement>
                  <IconButton
                    size="xs"
                    aria-label="Clear search"
                    variant="ghost"
                    color="gray.400"
                    _hover={{ color: 'gray.600' }}
                    onClick={() => setSearchQuery('')}
                    icon={<IconX size={16} />}
                  />
                </InputRightElement>
              )}
            </InputGroup>
          </Box>

          <Flex>
            <Button
              size="sm"
              variant="outline"
              colorScheme="lightPurple"
              leftIcon={<Icon as={IconTableDown} size={16} />}
              iconSpacing={1.5}
              mr="2"
              onClick={() => {
                const url = filteredProspectsPath(selectedCompanies, {
                  filters: facets.facetFilters,
                  format: 'csv',
                  persona: selectedPersona?.id,
                  search: searchQuery,
                  only_saved: savedProspectsSelected,
                  allResults: true
                })
                window.open(url)
              }}
              isDisabled={emptyFilters && !selectedCompanies && !savedProspectsSelected}
            >
              Export
            </Button>
          </Flex>
        </HStack>
        {emptyFilters && !selectedCompanies && !savedProspectsSelected ? (
          <EmptyState size="md" heading={`Pick a company or filter to start prospecting`}>
            <Image h="64" width="auto" src={FilterIllustration} />
          </EmptyState>
        ) : (
          <ProspectsTableV2
            companies={selectedCompanies}
            persona={selectedPersona}
            apps={props.apps}
            addToSequenceEnabled={props.addToSequenceEnabled}
          />
        )}
      </Flex>
    </Flex>
  )
}

function FilterMenu(props: React.PropsWithChildren<FilterPopoverProps>) {
  return (
    <Box paddingY={1.5} paddingX={1.5}>
      <FilterPopover {...props} showPreview />
    </Box>
  )
}

interface PersonaOverflowMenu extends UseDisclosureProps {
  persona: Persona
  onRename?: (updates: Partial<Persona>) => void
  size?: 'tiny' | 'sm'
  variant?: 'outline' | 'ghost'
}

function PersonaOverflowMenu(props: PersonaOverflowMenu) {
  const menuDisclosure = useDisclosure(props)
  const renameModal = useDisclosure()
  const destroyModal = useDisclosure()

  return (
    <Box
      display="inline-flex"
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <RenamePersonaModal persona={props.persona} {...renameModal} onSave={props.onRename} />
      <DeleteConfirmation
        title={`Remove "${props.persona.name}"?`}
        confirmLabel="Yes, continue"
        deletePath={projectPath(`/personas/${props.persona.id}`)}
        isCentered
        {...destroyModal}
      >
        Are you sure you want to delete the "{props.persona.name}" persona?
      </DeleteConfirmation>

      <Menu {...menuDisclosure}>
        <MenuButton
          size={props.size || 'md'}
          rounded={props.size === 'tiny' ? 'base' : 'lg'}
          flex="none"
          as={IconButton}
          icon={props.size === 'tiny' ? <IconDotsVertical size={14} /> : <IconDots size={16} />}
          variant={props.variant || 'outline'}
          borderColor="gray.200"
        />
        <Portal>
          <MenuList fontSize="sm" zIndex="popover">
            <MenuItem icon={<IconEdit size={16} />} iconSpacing={1.5} onClick={renameModal.onOpen}>
              Rename Persona
            </MenuItem>
            <MenuItem icon={<IconTrash size={16} />} iconSpacing={1.5} color="red.500" onClick={destroyModal.onOpen}>
              Delete Persona…
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  )
}
