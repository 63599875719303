import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import {
  Badge,
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Link,
  ListItem,
  OrderedList,
  Stack,
  Text,
  Textarea,
  UnorderedList
} from '@chakra-ui/react'
import React from 'react'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { LightBgCard } from '../../../ui/Card'
import PageDescription from '../../../ui/PageDescription'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { usePermission } from '../../../ui/PermissionsContext'
import { projectPath } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'

interface Props {
  app_id: string
  title: string
  description: string
  logo: string
  settings: Record<string, string | null | undefined>
  connected?: boolean
  deps: {}
}

export default function Show(props: Props) {
  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/bigquery'),
            title: 'Google BigQuery'
          }
        ]}
        offscreen
      />
      <HStack>
        <Image src={props.logo} maxW="6" />
        <PageTitle>{props.title}</PageTitle>
        <Badge colorScheme={'purple'}>ALPHA</Badge>

        {Object.keys(props.settings).length > 0 && (
          <Flex flex="1" justifyContent={'flex-end'}>
            <DisconnectAppDialog appTitle={'Bigquery'} showRemoveCachesOption={false} />
          </Flex>
        )}
      </HStack>
      <PageDescription>{props.description}</PageDescription>

      <form method="POST">
        <AuthenticityToken />
        <input type="hidden" name="_method" value="PUT" />

        <Stack spacing="4" pt="8">
          <Heading size="sm" fontWeight={'semibold'}>
            🔐 Auth Settings
          </Heading>

          <Stack spacing="8">
            <LightBgCard as={Stack}>
              <Stack spacing={'4'}>
                <FormControl id="project_id">
                  <FormLabel>Project ID</FormLabel>
                  <Input
                    bg="gray.50"
                    w="100%"
                    name="app_instance_settings[project_id]"
                    placeholder="BigQuery Project ID"
                    defaultValue={props.settings['project_id'] ?? ''}
                  />
                  <FormHelperText>Your BigQuery Project ID.</FormHelperText>
                </FormControl>

                <FormControl id="project_id">
                  <FormLabel>JSON Key</FormLabel>
                  <Textarea
                    bg="gray.50"
                    w="100%"
                    name="app_instance_settings[json_key]"
                    placeholder="BigQuery JSON Key"
                    defaultValue={props.settings['json_key'] ?? ''}
                    noOfLines={16}
                  />
                  <FormHelperText>Your BigQuery JSON credentials.</FormHelperText>
                </FormControl>
              </Stack>
            </LightBgCard>

            <Divider />

            <Stack spacing="4">
              <Heading size="sm" fontWeight={'semibold'}>
                How to create a BigQuery JSON Key for Koala
              </Heading>
              <OrderedList fontSize={'sm'} px="4" spacing="2">
                <ListItem>
                  From the Navigation panel on the left, select <strong>IAM & admin</strong>, then{' '}
                  <strong>Service accounts</strong>.
                </ListItem>
                <ListItem>
                  Click <strong>Create Service Account</strong>.
                </ListItem>
                <ListItem>
                  Enter a name for the service account (for example, koala-bigquery) and click <strong>Create</strong>.
                </ListItem>
                <ListItem>
                  <Text pb="2">Assign the service account the following roles:</Text>
                  <UnorderedList spacing="1" fontWeight={'semibold'}>
                    <ListItem>BigQuery Data Owner</ListItem>
                    <ListItem>BigQuery Job User</ListItem>
                  </UnorderedList>
                </ListItem>
                <ListItem>
                  <Link href="https://cloud.google.com/iam/docs/keys-create-delete" isExternal fontWeight={'semibold'}>
                    Create a JSON key
                  </Link>
                  . The downloaded file will be used to connect to your BigQuery instance in the Koala app. If you have
                  trouble creating a new service account, refer to{' '}
                  <Link href="https://cloud.google.com/iam/docs/service-accounts-create" isExternal>
                    {' '}
                    Google Cloud's documentation
                  </Link>{' '}
                  about service accounts for more information.
                </ListItem>
              </OrderedList>
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing="8" mt="8">
          <HStack w="100%">
            <Button colorScheme="purple" type="submit" w="100%" isDisabled={!canEditProject}>
              Save
            </Button>
          </HStack>
        </Stack>
      </form>
    </PageLayout>
  )
}
