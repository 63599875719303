// This file is automatically compiled by Vite Rails, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import '@rails/ujs'
import React from 'react'
import ReactDOM from 'react-dom'
import App from '../components/App'

ReactDOM.render(<App />, document.getElementById('app-template'))
