import { Box, BoxProps, Flex, FlexProps } from '@chakra-ui/react'
import * as React from 'react'

export const Card = (props: BoxProps & { href?: string }) => (
  <Box width="100%" bg="white" py="6" px="8" shadow="sm" borderWidth={'1px'} rounded="lg" {...props} />
)

export const LightBgCard = React.forwardRef<HTMLDivElement, FlexProps & { href?: string }>(
  function LightBgCard(props, ref) {
    return (
      <Flex
        ref={ref}
        flexDirection="column"
        p="6"
        border="1px solid"
        borderColor="gray.200"
        shadow="sm"
        rounded="md"
        {...props}
      />
    )
  }
)

export const GrayCard = (props: FlexProps) => <Flex bg="gray.50" p="6" rounded="lg" {...props} />

export const DetailsCard = (props: FlexProps) => (
  <Flex
    flexDirection="column"
    gap={4}
    py={4}
    px={5}
    bg="white"
    border="1px solid"
    borderColor="gray.200"
    shadow="sm"
    rounded="lg"
    {...props}
  />
)
