import { Box, CircularProgress, Flex, Grid, HStack, Icon, Link, Text } from '@chakra-ui/react'
import { IconChevronRight } from '@tabler/icons-react'
import React from 'react'
import { useOnboardingChecks } from '../../../data/use-onboarding-checks'
import { projectPath, useCurrentProject } from '../../../ui/ProjectsContext'

export interface OnboardingTask {
  key: string
  name: string
  description?: string
  required?: boolean
  href?: string
  status: {
    skipped: boolean
    complete?: boolean
    progress?: [number, number]
  }
}

const emptyArray = []

export function SetupBadge({ size, variant }: { size?: string | number; variant?: 'full' | 'compact' | 'progress' }) {
  const project = useCurrentProject()
  const { data } = useOnboardingChecks()
  const checks = data?.checks || emptyArray

  const done = checks.filter((check) => check.status.complete || (!check.required && check.status.skipped)).length
  const total = checks.length
  const complete = done >= total

  if (!project || complete) {
    return null
  }

  if (variant === 'progress') {
    return (
      <CircularProgress
        size={`${size || 18}px`}
        value={Math.round((100 * done) / total)}
        color="purple.600"
        thickness="10px"
      />
    )
  }

  return (
    <Flex
      as={Link}
      display={['none', 'flex']}
      href={projectPath('/setup')}
      cursor="pointer"
      direction="column"
      gap={variant === 'full' ? 2 : 1}
      bg="white"
      py="2"
      px="2.5"
      rounded="md"
      shadow="base"
      role="group"
      width={variant === 'full' ? '100%' : undefined}
      _hover={{ textDecoration: 'none' }}
      _focus={{ shadow: 'base' }}
    >
      <HStack width={variant === 'full' ? '100%' : undefined} justifyContent="space-between" marginInlineEnd="-4px">
        <Text
          flex="none"
          whiteSpace="nowrap"
          fontSize="13px"
          fontWeight="medium"
          lineHeight={variant === 'full' ? 1.5 : 1}
        >
          Finish onboarding
        </Text>
        <Icon
          as={IconChevronRight}
          boxSize={4}
          color="gray.400"
          _groupHover={{ color: 'gray.700', transform: 'translateX(1px)' }}
          transition="all 150ms ease-in-out"
        />
      </HStack>
      <Grid width="100%" templateColumns={`repeat(${total}, 1fr)`} gap="2px">
        {new Array(total).fill(null).map((_, i) => (
          <Box
            key={i}
            height={variant === 'full' ? '4px' : '3px'}
            bg={i < done ? 'purple.500' : 'gray.300'}
            rounded="full"
          />
        ))}
      </Grid>
    </Flex>
  )
}
