import React, { useEffect, useState } from 'react'
import { postForm } from '../../../../../lib/api'
import { automationsPath } from '../../../notifications/lib/path-helper'

export type Variables = {
  account: {
    name: string
    logo_url: string
    [key: string]: string
  }

  visitor: {
    name: string
    display_name: string
    email: string
    [key: string]: string
  }

  [key: string]: object
}
export type VariablesResponse = {
  variables: Variables
  account_mappings: string[]
  visitor_mappings: string[]
  signal_mappings: string[]
  samples: Variables[]
}

export type NotificationVariables = {
  variables: object | undefined
  accountMappings: string[]
  visitorMappings: string[]
  signalMappings: string[]
  isLoading: boolean
  samples: Variables[]
}

export function useNotificationVariables(): NotificationVariables {
  const [variables, setVariables] = React.useState<object>()
  const [samples, setSamples] = useState<VariablesResponse['samples']>([])
  const [accountMappings, setAccountMappings] = React.useState<string[]>([])
  const [visitorMappings, setVisitorMappings] = React.useState<string[]>([])
  const [signalMappings, setSignalMappings] = React.useState<string[]>([])
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    const followRuleForm = document.getElementById('follow-rule')
    let data: FormData | undefined
    if (followRuleForm) {
      data = new FormData(followRuleForm as HTMLFormElement)
    }

    setLoading(true)

    postForm<VariablesResponse>(automationsPath('/sample-variables'), data).then((data) => {
      setSamples(data.samples)
      setVariables(data.variables)
      setAccountMappings(data.account_mappings)
      setVisitorMappings(data.visitor_mappings)
      setSignalMappings(data.signal_mappings)
      setLoading(false)
    })
  }, [])

  return { variables, accountMappings, isLoading, samples, visitorMappings, signalMappings }
}
