import PageLayout from '@app/components/ui/PageLayout'
import PageTitle from '@app/components/ui/PageTitle'
import { ProjectCard } from '@app/components/ui/ProjectCard'
import { Project } from '@app/types/Project'
import { Alert, AlertIcon, Button, Flex, Image, Link, VStack } from '@chakra-ui/react'
import React from 'react'

interface Props {
  projects: { project: Project; link: string }[]
  logo: string
  title: string
  action_cta: string
}

export default function Install({ projects, logo, title, action_cta }: Props) {
  return (
    <PageLayout>
      <VStack margin={'auto'} p={10}>
        <Flex justifyContent={'center'} gridGap={'2'} mb={'10'} width={'100%'}>
          {!!logo && <Image src={logo} maxW="6" />}
          <PageTitle>{title || 'Select a workspace to continue'}</PageTitle>
        </Flex>
        <VStack w={'80%'} spacing={'5'}>
          {projects.length > 1 &&
            projects.map(({ project, link }) => (
              <ProjectCard key={project.slug} project={project} actionText={action_cta} link={link} />
            ))}
          {projects.length == 0 && (
            <>
              <Alert status={'warning'}>
                <AlertIcon />
                Create a workspace to continue
              </Alert>
              <Button as={Link} href={`/projects/new`} colorScheme="purple" w="100%">
                New workspace
              </Button>
            </>
          )}
        </VStack>
      </VStack>
    </PageLayout>
  )
}
