import {
  Badge,
  Button,
  Checkbox,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  SkeletonCircle,
  Stack,
  Text
} from '@chakra-ui/react'
import React, { useCallback, useRef } from 'react'
import { postForm } from '../../../../lib/api'
import { JSONTree } from '../../../ui/json-tree'
import { PartialVisitor, VisitorSelector } from '../../../ui/VisitorSelector'
import { Toggle } from '../../accounts/components/Toggle'
import { Notification } from '../../notifications'
import { NotificationEntry } from '../../notifications/components/notification'
import { automationsPath } from '../../notifications/lib/path-helper'
import { Session } from '../../sessions'

interface Props {
  isOpen: boolean
  onClose: () => void
  isLoading?: boolean
  setIsLoading?: (isLoading: boolean) => void
  formRef: React.RefObject<HTMLFormElement>
}

type RuleStep = {
  step: string
  status: string
  msg: string
  rule_id?: string
  data: object
  ts: string
}

type NotificationCtx = {
  eligible: boolean
  subject_id: string
  subject_type: string
  engine_steps: any[]
  rule_steps: Record<string, RuleStep[]>
}

type EvaluationResult = {
  notifications: Notification[]
  session: Session
  ctx: NotificationCtx
}

export function PreviewUserDrawer(props: Props) {
  const { isOpen, onClose, isLoading } = props

  const [selectedVisitor, setSelectedVisitor] = React.useState<PartialVisitor | null>(null)
  const [evaluationResult, setEvaluationResult] = React.useState<EvaluationResult | null>(null)
  const [showFailuresOnly, setShowFailuresOnly] = React.useState<boolean>(false)

  const controllerRef = useRef<AbortController>()
  const evaluate = useCallback(() => {
    if (!props.formRef.current) {
      return
    }

    if (!isOpen || !selectedVisitor) {
      return
    }

    const data = new FormData(props.formRef.current)
    props.setIsLoading?.(true)
    setEvaluationResult(null)

    controllerRef.current = new AbortController()

    const path = automationsPath(`/evaluate?profile_id=${selectedVisitor.id}`)
    postForm<EvaluationResult>(path, data, {
      signal: controllerRef.current.signal
    })
      .then((res) => {
        props.setIsLoading?.(false)
        setEvaluationResult(res)
        setShowFailuresOnly(!res.ctx.eligible)
      })
      .catch(() => {
        props.setIsLoading?.(false)
        setEvaluationResult(null)
      })

    return () => {
      controllerRef.current?.abort()
    }
  }, [selectedVisitor, isOpen, props])

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader bg="gray.50">Preview User</DrawerHeader>

          <DrawerBody bg="gray.50">
            <Stack spacing="8">
              <HStack spacing="4">
                <VisitorSelector selectedVisitor={selectedVisitor} onChange={setSelectedVisitor} showClearButton />
                <Button
                  isLoading={props.isLoading}
                  colorScheme={'purple'}
                  px="6"
                  isDisabled={!selectedVisitor}
                  onClick={evaluate}
                >
                  Evaluate
                </Button>
              </HStack>

              {isLoading && (
                <Stack spacing="4">
                  <HStack bg="white" p="4">
                    <SkeletonCircle size={'8'} />
                  </HStack>
                  <HStack bg="white" p="4">
                    <SkeletonCircle size={'8'} />
                  </HStack>
                  <HStack bg="white" p="4">
                    <SkeletonCircle size={'8'} />
                  </HStack>
                </Stack>
              )}

              {evaluationResult?.ctx && (
                <Stack w="100%" spacing="6">
                  <Text fontWeight={'semibold'}>
                    {evaluationResult.ctx.eligible ? (
                      <Badge colorScheme={'green'}>Eligible</Badge>
                    ) : (
                      <Badge colorScheme={'orange'}>Ineligible</Badge>
                    )}
                  </Text>
                  <Divider />

                  {(evaluationResult?.notifications?.length ?? 0) > 0 && (
                    <Stack w="100%">
                      {evaluationResult?.notifications?.map((notification, index) => {
                        return <NotificationEntry key={notification.id ?? index} {...notification} isPreview />
                      })}
                    </Stack>
                  )}

                  <Divider />

                  <HStack justifyContent={'space-between'}>
                    <Text fontWeight={'semibold'}>Evaluation Steps</Text>
                    <Checkbox isChecked={showFailuresOnly} onChange={(e) => setShowFailuresOnly(e.target.checked)}>
                      Failures only
                    </Checkbox>
                  </HStack>
                  <Toggle title="Results" defaultIsOpen>
                    <Stack spacing="4">
                      {evaluationResult.ctx.rule_steps['rule'].map((step, index) => {
                        if ((showFailuresOnly && step.status === 'pass') || step.status === 'log') {
                          return null
                        }

                        return (
                          <Stack key={index} bg="white" p="4">
                            <HStack>
                              {step.status === 'pass' && <Text color="green.500">✓</Text>}
                              {step.status === 'skip' && <Text color="red.500">✗</Text>}
                              {step.status === 'log' && <Text color="gray.500">⚠</Text>}
                              <Text fontWeight={'semibold'}>{step.step}</Text>
                            </HStack>
                            <Text color="gray.600" fontSize={'sm'}>
                              {step.msg}
                            </Text>
                            {step.data && <JSONTree data={step.data} />}
                          </Stack>
                        )
                      })}
                    </Stack>
                  </Toggle>
                </Stack>
              )}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
