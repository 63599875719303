import { Heading, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import dayjs from '../../../../lib/dayjs'

interface Props {
  preview_ends_at?: string | null
}

export function PreviewBanner(props: Props) {
  if (props.preview_ends_at) {
    return (
      <Stack rounded="lg" bg="purple.50" color="purple.900" spacing={4} padding={5}>
        <Stack>
          <Heading size="xs">👋 Welcome to Koala Dashboards!</Heading>
          <Text fontSize="sm">
            Your workspace has access to a limited preview of Koala Dashboards for 90 days &ndash; which will end{' '}
            {dayjs(props.preview_ends_at).format('dddd, MMM Do, YYYY')}. Please share any feedback, questions, or if you
            are interested in continuing to use Koala Dashboards after the preview ends.
          </Text>
        </Stack>
      </Stack>
    )
  }

  return null
}
