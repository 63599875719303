import { projectPath } from '@app/components/ui/ProjectsContext'
import { concurrentCachedGET } from '@app/lib/api'
import { Divider, Flex, HStack, Skeleton, Stack, useBoolean, useDisclosure } from '@chakra-ui/react'
import orderBy from 'lodash/orderBy'
import React from 'react'
import { Account } from '../../../../types/Account'
import { VirtualList } from '../../../ui/VirtualList'
import { EntryRow, EntryRowProps } from './EntryRow'
import { PageViewSideSheet } from './PageViewDetail'

interface TopPages {
  [path: string]: number
}

export function TopPageViews(props: Account) {
  const [topPages, setTopPages] = React.useState<TopPages>({})
  const [loading, setLoading] = useBoolean(true)

  React.useEffect(() => {
    concurrentCachedGET<TopPages>(projectPath(`/accounts/${props.id}/top_pages?limit=10`))
      .then((response) => setTopPages(response))
      .catch((error) => console.log(error))
      .finally(() => setLoading.off())
  }, [props, setLoading])

  const pages = React.useMemo(() => topPages ?? {}, [topPages])
  const total = React.useMemo(() => Object.values(pages).reduce((acc, count) => acc + count, 0), [pages])
  const sorted = React.useMemo(() => orderBy(Object.entries(pages), [1], ['desc']), [pages])

  return (
    <>
      {loading && (
        <Stack spacing={0} divider={<Divider />}>
          {Array.from({ length: 8 }).map((_, index) => (
            <HStack key={index} justifyContent="space-between" px={4} height="49px">
              <Skeleton width="300px" height="12px" />
              <HStack>
                <Skeleton width="24px" height="10px" />
                <Skeleton width="100px" height="10px" />
              </HStack>
            </HStack>
          ))}
        </Stack>
      )}
      {sorted && !loading && (
        <VirtualList
          maxH={'490px'}
          items={sorted}
          estimateSize={() => 49}
          renderItem={(item, index) => {
            const path = item[0]
            const count = item[1]

            return (
              <Flex
                w="100%"
                borderBottomColor="gray.200"
                borderBottomStyle="solid"
                borderBottomWidth={sorted.length === index + 1 ? '0' : '1px'}
                alignItems="center"
                flex="1"
                _hover={{ bg: 'gray.50' }}
              >
                <PageView label={path} count={count} total={total} index={index} account={props} />
              </Flex>
            )
          }}
        />
      )}
    </>
  )
}

function PageView(props: Omit<EntryRowProps, 'onSelect' | 'interactive'>) {
  const disclosure = useDisclosure()

  return (
    <>
      <PageViewSideSheet label={props.label} account={props.account} {...disclosure} />
      <EntryRow
        {...props}
        onSelect={disclosure.onOpen}
        interactive
        trackIntentProps={{
          intentType: 'page',
          conditions: [
            {
              kind: 'page_view',
              with: {
                value: ['5 seconds'],
                operator: 'greater_than',
                property: 'focus_time'
              },
              value: [props.label],
              operator: 'starts_with',
              property: 'path'
            }
          ]
        }}
      />
    </>
  )
}
