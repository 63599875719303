import {
  Box,
  Button,
  Circle,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  List,
  ListItem,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { IconArrowRight, IconFileText } from '@tabler/icons-react'
import omit from 'lodash/omit'
import React from 'react'
import { Account } from '../../../../types/Account'
import { PageMeta } from '../../../../types/PageMeta'
import { Company, ProfileRecord } from '../../../../types/Profile'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { TimeAgo } from '../../../ui/TimeAgo'
import { titleize } from '../../accounts/facets/filter-cloud'
import { accountPath } from '../../accounts/lib/account-path'
import { profilePath } from '../../profiles/lib/path'

export interface FormContext {
  name: string
  action: string
  method: 'get' | 'post'
}

export interface FormSubmission {
  id: string
  form_id: string | null
  profile_id: string
  project_id: string
  form_type: string
  form_data: Record<string, any>
  form_context: FormContext
  form_traits: Record<string, any>
  page_path: string
  page_url: string
  referrer: string | null
  user_agent: string | null
  utm: Record<string, string>
  created_at: string
  updated_at: string
  profile?: ProfileRecord
  account?: Account
  company?: Company
}

interface Props {
  submissions: FormSubmission[]
  page_meta: PageMeta
}

function SubmissionRow(props: { submission: FormSubmission }) {
  const submission = props.submission
  const profile = submission.profile
  const displayName = profile?.name || profile?.display_name || profile?.email
  const company = submission.company
  const { isOpen, onClose, onOpen } = useDisclosure()
  const data = omit(submission.form_data, 'hs_context')
  const traits = submission.form_traits

  const Name = displayName ? (
    <Link href={profilePath(profile)} color="gray.700" fontSize="sm" maxW="300px" isTruncated>
      {displayName}
    </Link>
  ) : profile ? (
    <Link href={profilePath(profile)} color="gray.500">
      Anonymous visitor
    </Link>
  ) : (
    <Text color="gray.500">Unknown</Text>
  )

  const Email = profile?.email && (
    <Link fontSize="sm" isExternal href={`mailto:${profile.email}`} isTruncated>
      {profile.email}
    </Link>
  )

  const ProfileStack = (
    <Stack spacing="0" flex="1" alignItems="flex-start" fontWeight={'normal'}>
      {Name}

      {profile?.person && (
        <Link href={profilePath(profile)} color="gray.600" maxW="200px" isTruncated>
          {profile.person.employment?.title}
        </Link>
      )}

      {Email}
    </Stack>
  )

  const CompanyStack = (
    <Box width="180px">
      {company && (
        <Flex
          as="a"
          href={accountPath({ company })}
          display="inline-flex"
          alignItems="center"
          gap={1}
          width="auto"
          maxW="100%"
          overflow="hidden"
          rounded="md"
          paddingLeft={1}
          paddingRight={2}
          paddingY={1}
          marginX={-1}
          title={company.name ?? company.domain}
          _hover={{
            background: 'white',
            shadow: 'sm',
            '& .hover-icon': {
              display: 'flex',
              opacity: 1
            }
          }}
        >
          <CompanyAvatar size="tiny" rounded="base" name={company.name} domain={company.domain} />
          <Text flex="1 1 auto" maxWidth="100%" fontSize="sm" fontWeight="medium" isTruncated>
            {company.name ?? company.domain}
          </Text>
          <Circle flex="none" className="hover-icon" display="none" opacity={0} size="18px" bg="gray.100">
            <Icon as={IconArrowRight} boxSize={3.5} color="gray.600" />
          </Circle>
        </Flex>
      )}
    </Box>
  )

  const referrerUTM = React.useMemo(() => {
    if (!submission.referrer) {
      return {}
    }

    const url = new URL(submission.referrer)
    const params = {}
    for (const [key, val] of url.searchParams) {
      if (key.includes('utm_') && val !== '') {
        params[key.replace('utm_', '')] = val.replace('"', '')
      }
    }

    return params
  }, [submission.referrer])

  const allUTMs = React.useMemo(() => {
    return { ...referrerUTM, ...submission.utm }
  }, [referrerUTM, submission.utm])

  return (
    <Tr key={submission.id}>
      <Td width="300px" isTruncated>
        {Name}
      </Td>
      <Td width="1px">
        <Box isTruncated maxW="200px">
          {Email}
        </Box>
      </Td>
      <Td width="1px" isTruncated>
        {CompanyStack}
      </Td>

      <Td isTruncated width="1px">
        <List spacing={'0.5'} fontSize="xs" color="gray.500">
          {Object.keys(allUTMs).map((key) => (
            <ListItem key={key} as={HStack} spacing="0">
              <Text>{key}</Text>
              <Text maxW={'120px'} overflow="hidden" wordBreak={'break-all'} textOverflow="ellipsis">
                ={typeof allUTMs === 'string' ? allUTMs[key] : allUTMs[key]?.toString()}
              </Text>
            </ListItem>
          ))}
        </List>
      </Td>

      <Td width="1px">
        <Button size="sm" variant={'ghost'} leftIcon={<IconFileText size="16" />} onClick={onOpen}>
          View submission
        </Button>
        <Drawer isOpen={isOpen} placement="right" size="lg" onClose={onClose}>
          <DrawerOverlay />

          <DrawerContent>
            <DrawerHeader>Form Submission Data</DrawerHeader>
            <DrawerBody>
              <Stack spacing="6" divider={<Divider />}>
                <HStack justifyContent={'space-between'}>
                  {profile && ProfileStack}
                  {company && CompanyStack}
                </HStack>
                <Stack>
                  <Heading size="sm">Page Info</Heading>
                  <List spacing={'4'} fontSize="sm">
                    <ListItem key={'page_url'}>{submission.page_url}</ListItem>
                    {submission.referrer && (
                      <ListItem key={'referrer'}>
                        <Text fontWeight={'semibold'}>Referrer</Text>
                        <Text>{submission.referrer}</Text>
                      </ListItem>
                    )}

                    {Object.keys(allUTMs).map((key) => (
                      <ListItem key={key}>
                        <Text fontWeight={'semibold'}>{titleize(key.toString())}</Text>
                        <Text>{typeof allUTMs[key] === 'string' ? allUTMs[key] : allUTMs[key]?.toString()}</Text>
                      </ListItem>
                    ))}
                  </List>
                </Stack>

                {Object.keys(traits).length > 0 && (
                  <Stack>
                    <Heading size="sm">Koala Captured Traits</Heading>
                    <List spacing={'4'} fontSize="sm">
                      {Object.keys(traits).map((key) => (
                        <ListItem key={key}>
                          <Text fontWeight={'semibold'}>{titleize(key.toString())}</Text>
                          <Text>{typeof traits[key] === 'string' ? traits[key] : JSON.stringify(traits[key])}</Text>
                        </ListItem>
                      ))}
                    </List>
                  </Stack>
                )}

                <Stack>
                  <Heading size="sm">Form Data</Heading>
                  <List spacing={'4'} fontSize="sm">
                    {Object.keys(data).map((key) => (
                      <ListItem key={key}>
                        <Text fontWeight={'semibold'}>{titleize(key.toString())}</Text>
                        <Text>{typeof data[key] === 'string' ? data[key] : JSON.stringify(data[key])}</Text>
                      </ListItem>
                    ))}
                  </List>
                </Stack>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Td>

      <Td width="1px" fontSize="sm">
        <Text color="gray.600">
          <TimeAgo time={submission.created_at} format="MMM D, YYYY @ hh:mm a" />
        </Text>
      </Td>
    </Tr>
  )
}

export function SubmissionList(props: Props) {
  return (
    <TableContainer>
      <Table size="sm" variant="bordered">
        <Thead>
          <Tr>
            <Th>Contact</Th>
            <Th>Email</Th>
            <Th>Company</Th>
            <Th>UTMs</Th>
            <Th>Data</Th>
            <Th>Submitted On</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.submissions.map((submission) => {
            return <SubmissionRow key={submission.id} submission={submission} />
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
