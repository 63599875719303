import {
  Box,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListItem,
  Spinner,
  Text
} from '@chakra-ui/react'
import { IconX } from '@tabler/icons-react'
import { useCombobox } from 'downshift'
import React, { ChangeEvent, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import Router from '../../../../lib/router'
import { useCompanySearch } from '../../../data/use-company-search'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { SearchIcon } from '../../../ui/icons/SearchIcon'

export function CompanySearch(_props: any) {
  const [inputValue, setInputValue] = useState('')
  const [query] = useDebounce(inputValue, 300)
  const { data, isLoading } = useCompanySearch(query || '*')
  const companies = useMemo(() => data?.companies || [], [data?.companies])

  const { isOpen, getInputProps, closeMenu, highlightedIndex, getComboboxProps, getItemProps, getMenuProps } =
    useCombobox({
      isOpen: inputValue.trim().length > 0,
      items: companies,
      inputValue: inputValue,
      itemToString: (item) => item?.name || item?.domain || '',
      onSelectedItemChange: ({ selectedItem }) => {
        if (selectedItem?.domain) {
          Router.visit(`/admin/kdb/${selectedItem.domain}`)
        }

        closeMenu()
      }
    })

  return (
    <Box position="relative" {...getComboboxProps()}>
      <InputGroup>
        <InputLeftElement height={14} left={4} color="gray.400" padding="2px">
          <SearchIcon boxSize={5} />
        </InputLeftElement>

        <Input
          rounded="full"
          fontSize="md"
          paddingLeft={14}
          paddingRight={14}
          height={14}
          bg="white"
          shadow="none"
          border="1px solid"
          borderColor="gray.200"
          _focus={{ ringColor: 'purple.500', shadow: 'glow' }}
          _hover={{ shadow: 'glow' }}
          transition="all 0.2s ease-in-out"
          placeholder="Search by company domain"
          {...getInputProps({
            value: inputValue,
            onChange: (e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)
          })}
        />

        <InputRightElement height={14} right={2} padding="2px">
          {isLoading ? (
            <Spinner size="sm" color="gray.400" thickness="1.5px" />
          ) : inputValue ? (
            <IconButton
              aria-label="Clear search"
              size="sm"
              variant="ghost"
              rounded="full"
              color="gray.400"
              _hover={{ color: 'gray.800' }}
              icon={<Icon as={IconX} boxSize={5} />}
              onClick={() => setInputValue('')}
            />
          ) : null}
        </InputRightElement>
      </InputGroup>

      <List
        {...getMenuProps()}
        position="absolute"
        width="100%"
        left={0}
        right={0}
        top="100%"
        zIndex={1000}
        bg="white"
        visibility={isOpen && companies.length ? 'visible' : 'hidden'}
        border="1px solid"
        borderColor="gray.200"
        maxHeight={400}
        overflowY="auto"
        scrollBehavior="smooth"
        overscrollBehavior="contain"
        marginTop={2}
        paddingX={4}
        paddingY={4}
        rounded="xl"
        shadow={isOpen && companies.length ? '2xl' : undefined}
      >
        {isOpen &&
          companies.map((company, index) => (
            <ListItem
              key={company.domain}
              {...getItemProps({ item: company, index: index })}
              display="flex"
              alignItems="center"
              gap={3}
              bg={highlightedIndex === index ? 'gray.100' : undefined}
              padding={2}
              rounded="lg"
              cursor="pointer"
            >
              <CompanyAvatar name={company.name} domain={company.domain} size="sm" />
              <Flex gap={1.5} lineHeight="100%">
                <Text fontSize="sm" fontWeight="medium">
                  {company.name || company.domain}
                </Text>
                {company.name && company.domain && (
                  <Text color="gray.500" fontSize="xs">
                    ({company.domain})
                  </Text>
                )}
              </Flex>
            </ListItem>
          ))}
      </List>
    </Box>
  )
}
