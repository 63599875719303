import { Box, Flex, FlexProps, HStack, Icon, IconProps, Tag, Text } from '@chakra-ui/react'
import { IconArrowDownRight, IconArrowUpRight, IconBolt, IconSparkles } from '@tabler/icons-react'
import { format } from 'friendly-numbers'
import React from 'react'
import { IntentScore, IntentTrend } from '../../../icps/types'
import { IntentBarChart } from './IntentBarChart'
import { IntentHoverCard } from './IntentHoverCard'

export const trendColor = {
  heating: 'orange',
  surging: 'red',
  cooling: 'blue',
  neutral: 'gray'
}

export function normalize(value, max) {
  if (max) {
    // don't go over the max, just cap it at 100
    return (Math.min(value, max) / max) * 100
  }

  return value
}

export function getTrend(intent?: IntentScore, all?: boolean) {
  if (!intent) {
    return null
  }

  if (intent.trend_14d && ['New', 'Heating', 'Surging'].includes(intent.trend_14d)) {
    return intent.trend_14d
  }

  if (all) {
    return intent?.trend_14d || intent?.trend_7d
  }
}

export const scoreLabelColor = {
  'Very High': 'red',
  High: 'red',
  Medium: 'orange',
  Low: 'blue',
  None: 'gray'
}

export function getScoreLabel(score?: number) {
  if (typeof score !== 'number') {
    return null
  }

  if (score >= 80) {
    return 'Very High'
  } else if (score >= 50) {
    return 'High'
  } else if (score >= 20) {
    return 'Medium'
  } else if (score >= 0) {
    return 'Low'
  } else {
    return null
  }
}

export function ScoreLabel({ score, label }: { score?: number; label?: string }) {
  const scoreLabel = label || getScoreLabel(score) || 'None'

  return (
    <Text
      as="span"
      fontWeight="semibold"
      color={`${scoreLabelColor[scoreLabel] || 'gray'}.500`}
      rounded="full"
      // bg={`${scoreLabelColor[scoreLabel] || 'gray'}.50`}
      lineHeight={1}
      // paddingY={1}
      // paddingX={2}
    >
      {scoreLabel}
    </Text>
  )
}

interface IntentTrendLabelProps extends FlexProps {
  intent?: IntentScore
  showLabel?: boolean
  showAnyTrend?: boolean
  size?: 'sm' | 'md'
}

export function IntentTrendLabel({ intent, showLabel, showAnyTrend, size, ...props }: IntentTrendLabelProps) {
  const trend = getTrend(intent, showAnyTrend)
  if (!trend) {
    return null
  }

  const fontSizes = { sm: 'xs', md: 'sm' }
  const iconSizes = { sm: 3.5, md: 4 }

  return (
    <Flex
      fontSize={fontSizes[size || 'md']}
      gap={0.5}
      alignItems="center"
      color={`${trendColor[trend.toLowerCase()]}.500`}
      {...props}
    >
      <TrendIcon trend={trend} boxSize={iconSizes[size || 'md']} />
      {showLabel && <Text>{trend}</Text>}
    </Flex>
  )
}

export function TrendIcon({ trend, ...props }: { trend: IntentTrend } & IconProps) {
  switch (trend) {
    case 'Heating':
      return <Icon as={IconArrowUpRight} boxSize={5} {...props} />
    case 'Surging':
      return <Icon as={IconBolt} fill="currentColor" boxSize={5} {...props} />
    case 'Cooling':
      return <Icon as={IconArrowDownRight} boxSize={5} {...props} />
    case 'New':
      return <Icon as={IconSparkles} boxSize={5} {...props} />
    default:
      return null
  }
}

interface TrendScoreProps {
  intent?: IntentScore
  score?: number
  variant?: 'combined' | 'full' | 'trend'
}

export function TrendScore({ intent, score, variant }: TrendScoreProps) {
  if (!intent) {
    return null
  }

  if (typeof score !== 'number') {
    return <Text color="gray.400">&ndash;</Text>
  }

  if (variant === 'full') {
    return (
      <HStack>
        <Tag
          size="md"
          rounded="full"
          variant="subtle"
          backgroundColor="background.light"
          lineHeight={1}
          minHeight="1rem"
          paddingY={1}
          paddingX={1}
          justifyContent="center"
        >
          {format(score, { decimals: 0 })}
        </Tag>
        <IntentTrendLabel intent={intent} showLabel />
      </HStack>
    )
  }

  if (variant === 'trend') {
    return <IntentTrendLabel intent={intent} showLabel />
  }

  const trend = getTrend(intent)

  return (
    <HStack spacing={0.5}>
      <Text fontWeight="medium" fontSize="md" color={trend ? `${trendColor[trend.toLowerCase()]}.600` : 'gray.600'}>
        {format(score, { decimals: 0 })}
      </Text>
      <IntentTrendLabel intent={intent} />
    </HStack>
  )
}

interface IntentSummaryProps {
  accountId: string
  intent?: IntentScore
}

export function IntentSummary({ accountId, intent }: IntentSummaryProps) {
  const score = intent?.score

  return (
    <IntentHoverCard intent={intent} normalizedScore={score!}>
      <HStack key={accountId + intent?.last_scored_at} spacing={6}>
        <Box width="90px">
          <ScoreLabel score={score} label={intent?.score_label} />
        </Box>
        <IntentBarChart data={intent?.trend ?? []} dataKey="score" height={30} />
      </HStack>
    </IntentHoverCard>
  )
}

interface IntentChartProps {
  intent?: IntentScore
}

export function IntentChart({ intent }: IntentChartProps) {
  const score = intent?.score

  return (
    <IntentHoverCard intent={intent} normalizedScore={score!}>
      <IntentBarChart data={intent?.trend ?? []} dataKey="score" height={32} />
    </IntentHoverCard>
  )
}
