import {
  Box,
  Button,
  Code,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconX } from '@tabler/icons-react'
import React, { useCallback, useState } from 'react'
import { Apps } from '../../../../types/App'
import { Territory } from '../../../../types/Territory'
import { useFacets } from '../../../data/use-facets'
import { useUsers } from '../../../data/use-users'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { GrayCard } from '../../../ui/Card'
import { usePermission } from '../../../ui/PermissionsContext'
import { projectPath } from '../../../ui/ProjectsContext'
import { UserSelector } from '../../../ui/UserSelector'
import { FilterPreview } from '../../accounts/components/FilterPreview'

interface Props {
  apps: Apps
  territory: Territory
  errors?: {
    [key: string]: string[]
  }
}

const emptyObject = {}
const emptyArray = []

export function TerritoryForm(props: Props) {
  const [submitting, setSubmitting] = React.useState(false)
  const [name, setName] = React.useState(props.territory?.name ?? '')
  const [assignmentType, setAssignmentType] = React.useState(props.territory?.assignment_type ?? 'shared')
  const nameError = name === (props.territory?.name || '') ? props.errors?.name?.join(', ') : ''

  const assignees = props.territory.assignees?.map((a) => a.id) ?? []

  const { hasPermission: canManageMembers } = usePermission({ on: 'project', action: 'can_manage_members' })

  const facets = useFacets({
    facet_filters: props.territory.filters?.facets || emptyObject
  })

  const [assigneeIds, setAssigneeIds] = useState(assignees)

  const handleAssigneeChange = useCallback((index: number, userId: string | null) => {
    setAssigneeIds((prev) => {
      const newUserIds = [...prev]
      if (userId) {
        newUserIds[index] = userId
      } else {
        newUserIds.splice(index, 1)
      }
      return newUserIds
    })
  }, [])

  const handleRemoveAssignee = useCallback((index: number) => {
    setAssigneeIds((prev) => prev.filter((_, i) => i !== index))
  }, [])

  const users = useUsers()

  return (
    <form
      action={projectPath(`/territories/${props.territory.id || ''}`)}
      method="POST"
      onSubmit={() => setSubmitting(true)}
    >
      <AuthenticityToken />
      {props.territory.id && <input type="hidden" name="_method" value="PATCH" />}
      <GrayCard w="100%">
        <Stack spacing={10} w="100%">
          <FormControl id="territory[name]" isInvalid={!!nameError} isRequired>
            <FormLabel>Territory name</FormLabel>
            <Input
              type="text"
              bg="white"
              name="territory[name]"
              placeholder="e.g. SDR Territory, EMEA Mid-Market, etc"
              size="sm"
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
            <FormErrorMessage>{nameError}</FormErrorMessage>
          </FormControl>

          <FormControl id="territory[assignment_type]" isInvalid={!!nameError} isRequired>
            <FormLabel>Assignment</FormLabel>
            <RadioGroup
              defaultValue={assignmentType}
              name="territory[assignment_type]"
              onChange={(e) => setAssignmentType(e as 'shared' | 'specific_users')}
            >
              <Stack spacing={5} direction="row">
                <Radio value="shared">
                  <Text fontSize="sm">Everyone</Text>
                </Radio>
                <Radio value="specific_users">
                  <Text fontSize="sm">Specific users</Text>
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>

          {assignmentType === 'specific_users' && (
            <FormControl isRequired>
              {assigneeIds.map((id) => (
                <input type="hidden" key={id} name="assignees[ids][]" value={id} />
              ))}
              <FormLabel>Selected users</FormLabel>
              <Stack spacing={2}>
                {[...assigneeIds, ''].map((assigneeId, idx) => (
                  <Flex width="100%" key={`${assigneeId}:${idx}`} alignItems="center" gap={3}>
                    <Box flex="1 1 auto">
                      <UserSelector
                        users={users.data?.users || emptyArray}
                        selectedUserId={assigneeId}
                        onChange={(newAssigneeId) => handleAssigneeChange(idx, newAssigneeId)}
                      />
                    </Box>
                    <IconButton
                      aria-label="Remove user"
                      size="xs"
                      variant="ghost"
                      color="gray.500"
                      _hover={{ color: 'gray.800' }}
                      icon={<IconX size={16} />}
                      isDisabled={idx >= assigneeIds.length}
                      onClick={() => handleRemoveAssignee(idx)}
                    />
                  </Flex>
                ))}
              </Stack>
            </FormControl>
          )}

          <FormControl id="territory[filters]" isRequired>
            <FormLabel>Select filters</FormLabel>
            <FormHelperText>
              Define the fields that make up this territory. You can use ownership fields with the{' '}
              <Code color="pink.600">Current user</Code> value which is scoped to the current viewer, or you can filter
              by geography, industry, number of employees, estimated revenue, and more.
            </FormHelperText>

            <Box pt={2}>
              <FilterPreview
                {...facets}
                kind="account"
                apps={props.apps}
                canClearFilters={false}
                topFilters={emptyArray}
                shouldShowIntentFilters={false}
                shouldShow3rdPartyFilters={false}
                shouldShowActiveVisitorsFilters={false}
                shouldShowLastSeenFilter={false}
                shouldShowUserAttributeFilters={false}
                shouldShowTraits={false}
                range={undefined}
              />
            </Box>

            <input type="hidden" name="territory[filters]" value={facets.queryString.split('?')[1] || ''} />
          </FormControl>

          <Flex
            alignItems="center"
            justifyContent="flex-end"
            gap={3}
            pt={5}
            borderTop="1px solid"
            borderColor="gray.200"
          >
            <Button size="sm" variant="outline" as={Link} href={projectPath('/territories')}>
              Cancel
            </Button>

            <Button
              type="submit"
              size="sm"
              colorScheme="purple"
              isLoading={submitting}
              isDisabled={!canManageMembers || !facets.isFiltering}
            >
              {props.territory.id ? 'Update territory' : 'Create territory'}
            </Button>
          </Flex>
        </Stack>
      </GrayCard>
    </form>
  )
}
