import React from 'react'
import { subscribeToChannel, SubscriptionEmitter } from '../../../../channels/generic_channel'
import { useCurrentProject } from '../../../ui/ProjectsContext'
import useLatestRef from '../../../ui/useLatestRef'

export function useDebugger<T>(onData: (data: T) => void) {
  const project = useCurrentProject()
  const subscription = React.useRef<SubscriptionEmitter>()
  const onDataRef = useLatestRef(onData)

  React.useEffect(() => {
    const handler = onDataRef.current

    if (!project?.slug) {
      return
    }

    subscription.current = subscribeToChannel({ channel: 'InstrumentationChannel', project_slug: project?.slug })
    subscription.current.on('received', handler)

    return () => {
      subscription.current?.off('received', handler)
      subscription.current?.unsubscribe()
      subscription.current = undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.slug])

  return subscription
}
