import {
  Box,
  Code,
  HStack,
  Icon,
  IconButton,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useClipboard
} from '@chakra-ui/react'
import { IconCheck, IconCopy, IconExternalLink } from '@tabler/icons-react'
import React from 'react'

interface Props {
  url?: string
}

export function UrlHoverCard({ children, url }: React.PropsWithChildren<Props>) {
  const { hasCopied, onCopy } = useClipboard(url || '')

  if (!url) {
    return null
  }

  return (
    <Popover trigger="hover" isLazy lazyBehavior="keepMounted">
      <PopoverTrigger>
        {children || (
          <Code
            fontSize="xs"
            color="gray.500"
            flexWrap="nowrap"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {url}
          </Code>
        )}
      </PopoverTrigger>
      <Portal>
        <PopoverContent width="auto" minWidth="100px" maxWidth="90vw">
          <PopoverBody paddingY={1.5} paddingX={2}>
            <HStack spacing={1}>
              <HStack
                as={Link}
                spacing={1.5}
                fontSize="xs"
                href={url}
                color="gray.500"
                _hover={{ color: 'gray.800' }}
                isExternal
              >
                <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" maxWidth="300px" color="gray.800">
                  {url}
                </Text>
                <Icon flex="none" as={IconExternalLink} boxSize={4} />
              </HStack>
              <IconButton
                aria-label="Copy to clipboard"
                variant="unstyled"
                size="xs"
                display="inline-flex"
                color={hasCopied ? 'purple.500' : 'gray.500'}
                transform={hasCopied ? 'scale(1.2)' : 'scale(1)'}
                transition="all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                _hover={{ color: hasCopied ? 'purple.500' : 'gray.800' }}
                _active={{ transform: 'scale(0.85)' }}
                icon={<Icon as={hasCopied ? IconCheck : IconCopy} boxSize={4} />}
                onClick={onCopy}
              />
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export function UrlHoverControls({ children, url }: React.PropsWithChildren<Props>) {
  const { hasCopied, onCopy } = useClipboard(url || '')

  if (!url) {
    return null
  }

  return (
    <Box data-url-controls position="relative">
      {children}
      <HStack
        spacing={0}
        position="absolute"
        top="0"
        left="50%"
        right="0"
        bottom="0"
        rounded="md"
        justifyContent="flex-end"
        bgGradient="linear(to-l, rgba(255,255,255,0.25), #FFFFFF)"
        // backdropFilter="blur(4px)"
        opacity={0}
        paddingLeft={3}
        paddingRight={1}
        css={{
          '[data-url-controls]:hover &': {
            opacity: 1
          }
        }}
      >
        <IconButton
          aria-label="Open in new tab"
          variant="unstyled"
          as={Link}
          href={url}
          isExternal
          size="xs"
          display="inline-flex"
          color="gray.500"
          _hover={{ color: 'gray.800' }}
          icon={<Icon as={IconExternalLink} boxSize={4} />}
        />
        <IconButton
          aria-label="Copy to clipboard"
          variant="unstyled"
          size="xs"
          display="inline-flex"
          color={hasCopied ? 'purple.500' : 'gray.500'}
          transform={hasCopied ? 'scale(1.2)' : 'scale(1)'}
          transition="all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
          _hover={{ color: hasCopied ? 'purple.500' : 'gray.800' }}
          _active={{ transform: 'scale(0.85)' }}
          icon={<Icon as={hasCopied ? IconCheck : IconCopy} boxSize={4} />}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onCopy()
          }}
        />
      </HStack>
    </Box>
  )
}
