import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'
import _ from 'lodash'

const getDialogSize = (value: string) => {
  if (value === 'full') {
    return {
      dialog: {
        maxW: '100vw',
        minH: '100vh',
        '@supports(min-height: -webkit-fill-available)': {
          minH: '-webkit-fill-available'
        },
        my: 0
      }
    }
  }

  return {
    dialog: {
      width: value,
      maxW: '95vw'
    }
  }
}

export const theme = extendTheme(
  {
    colors: {
      background: {
        light: '#F6F7F9',
        highlight: '#f3f4f6',
        highlighter: 'rgba(255,218,121,0.35)',
        hover: '#eeeff1'
      },
      border: {
        lightest: '#ecedf0'
      },
      accent: {
        50: '#FFEFE8',
        100: '#FFDFC9',
        200: '#FFC4A4',
        300: '#FFA87F',
        400: '#FF8D5A',
        500: '#FF774A',
        600: '#E5663E',
        700: '#CC5633',
        800: '#B24528',
        900: '#99351D'
      },
      salesforce: '#00A1E0',
      outreach: '#5951FF',
      hubspot: '#FF7A59',
      g2: {
        50: '#fff3f1',
        100: '#ffe3df',
        500: '#FF492C',
        primary: '#FF492C'
      },
      // tailwind gray
      gray: {
        50: '#f9fafb',
        100: '#f3f4f6',
        200: '#e5e7eb',
        300: '#d1d5db',
        400: '#9ca3af',
        // o.g. color is a little too dark
        // 500: '#6b7280',
        500: '#6f7785',
        600: '#4b5563',
        700: '#374151',
        800: '#1f2937',
        900: '#111827'
      },
      // tailwind slate
      slate: {
        50: '#f8fafc',
        100: '#f1f5f9',
        200: '#e2e8f0',
        300: '#cbd5e1',
        400: '#94a3b8',
        500: '#64748b',
        600: '#475569',
        700: '#334155',
        800: '#1e293b',
        900: '#0f172a'
      },
      blue: {
        100: '#CFF1FD',
        200: '#A0DFFB',
        300: '#70C5F5',
        400: '#4BA9EC',
        500: '#1581e0',
        600: '#0F64C0',
        700: '#0A4AA1',
        800: '#063481',
        900: '#04256B'
      },
      purple: {
        900: '#221378',
        800: '#301C9E',
        700: '#3E26C2',
        600: '#4D32E4',
        500: '#6153f3',
        400: '#847ef6',
        300: '#a9a9f9',
        200: '#cbcdfb',
        100: '#e3e5fc',
        50: '#f0f1ff'
      },
      // tailwind colors
      amber: {
        50: '#fffbeb',
        100: '#fef3c7',
        200: '#fde68a',
        300: '#fcd34d',
        400: '#fbbf24',
        500: '#f59e0b',
        600: '#d97706',
        700: '#b45309',
        800: '#92400e',
        900: '#78350f'
      },
      fuschia: {
        50: '#fdf4ff',
        100: '#fae8ff',
        200: '#f5d0fe',
        300: '#f0abfc',
        400: '#e879f9',
        500: '#d946ef',
        600: '#c026d3',
        700: '#a21caf',
        800: '#86198f',
        900: '#701a75'
      },
      indigo: {
        50: '#eef2ff',
        100: '#e0e7ff',
        200: '#c7d2fe',
        300: '#a5b4fc',
        400: '#818cf8',
        500: '#6366f1',
        600: '#4f46e5',
        700: '#4338ca',
        800: '#3730a3',
        900: '#312e81'
      }
    },
    shadows: {
      base: '0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.1)',
      glow: '0px 8px 20px rgba(0,0,0,0.06)',
      big: '0 15px 35px -2px rgba(0, 0, 0, 0.06), 0 5px 15px -2px rgba(0, 0, 0, 0.08)'
    },
    fonts: {
      heading:
        '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol",sans-serif',
      body: '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol",sans-serif'
    },
    components: {
      Badge: {
        variants: {
          regular: ({ colorScheme }) => ({
            textTransform: 'none',
            rounded: 'md',
            bg: `${colorScheme}.100`,
            color: `${colorScheme}.800`
          }),
          pill: ({ colorScheme }) => ({
            px: 2,
            rounded: 'full',
            bg: `${colorScheme}.100`,
            color: `${colorScheme}.800`
          })
        },
        baseStyle: {
          fontWeight: 'semibold',
          fontVariantNumeric: 'tabular-nums'
        },
        sizes: {
          xs: {
            fontSize: 'xs',
            px: 1.5
          }
        }
      },
      Breadcrumb: {
        parts: ['link', 'separator', 'container'],
        baseStyle: {
          container: {
            fontSize: 'sm'
          },
          link: {
            fontWeight: 'medium',
            color: 'gray.800',

            // _hover: {
            //   color: 'gray.800'
            // },

            '&[aria-current="page"]': {
              color: 'gray.500',
              cursor: 'default',
              _hover: {
                textDecoration: 'none'
              }
            }
          },
          separator: {
            color: 'gray.400',
            display: 'inline-flex',
            alignSelf: 'center',
            mx: 1
          }
        }
      },
      Button: {
        variants: {
          segmentedControl: ({ size }) => ({
            bg: 'transparent',
            h: {
              lg: `calc(var(--chakra-sizes-12) - 3px)`,
              md: `calc(var(--chakra-sizes-10) - 3px)`,
              sm: `calc(var(--chakra-sizes-8) - 3px)`,
              xs: `calc(var(--chakra-sizes-6) - 3px)`
            }[size],
            minW: {
              lg: `calc(var(--chakra-sizes-12) - 3px)`,
              md: `calc(var(--chakra-sizes-10) - 3px)`,
              sm: `calc(var(--chakra-sizes-8) - 3px)`,
              xs: `calc(var(--chakra-sizes-6) - 3px)`
            }[size],
            _hover: {
              bg: 'whiteAlpha.500',
              shadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 1px 0 rgba(0, 0, 0, 0.1)'
            },
            _active: {
              bg: 'white',
              shadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 1px 0 rgba(0, 0, 0, 0.1)'
            }
          }),
          outline: ({ colorScheme }) => ({
            bg: colorScheme === 'lightPurple' ? 'white' : undefined,
            color: colorScheme === 'lightPurple' ? 'purple.600' : colorScheme === 'red' ? 'red.500' : undefined,
            borderColor: colorScheme === 'gray' || colorScheme === 'lightPurple' ? 'gray.200' : 'currentColor',
            boxShadow: 'sm',
            _hover: { bg: colorScheme === 'lightPurple' ? 'purple.50' : undefined },
            _active: { bg: colorScheme === 'gray' ? 'gray.100' : undefined }
          })
        },
        sizes: {
          lg: {
            fontSize: 'md',
            px: 5
          },
          md: {
            fontSize: 'sm',
            px: 3.5
          },
          sm: {
            rounded: 'md',
            px: 3
          },
          xs: {
            rounded: 'base',
            px: 1.5
          },
          tiny: {
            rounded: 'base',
            px: 1,
            h: 5,
            minW: 5
          }
        },
        baseStyle: {
          fontWeight: 'medium',
          lineHeight: 1,
          _focusVisible: {
            boxShadow: 'outline',
            outline: '2px solid transparent',
            outlineOffset: '2px'
          },
          _focus: {
            outline: 'none',
            boxShadow: 'none'
          },
          _hover: {
            textDecoration: 'none'
          }
        }
      },
      Checkbox: {
        parts: ['label', 'control'],
        baseStyle: {
          control: {
            backgroundColor: 'white',
            borderWidth: '1.5px',
            borderColor: 'gray.300',
            rounded: '4px'
          }
        },
        sizes: {
          sm: {
            label: {
              fontSize: 'xs'
            }
          },
          md: {
            label: {
              fontSize: 'sm'
            }
          },
          lg: {
            label: {
              fontSize: 'md'
            }
          }
        }
      },
      CloseButton: {
        defaultProps: {
          size: 'sm'
        }
      },
      Drawer: {
        parts: ['dialog', 'closeButton'],
        baseStyle: {
          dialog: {
            height: '99vh',
            margin: 'auto 0.5vh',
            rounded: 'lg',
            overflow: 'auto'
          },
          closeButton: {
            insetEnd: 2
          }
        }
      },
      Heading: {
        sizes: {
          lg: {
            fontSize: '2xl'
          },
          md: {
            fontWeight: 'medium',
            lineHeight: 1.6
          },
          sm: {
            fontWeight: 'medium'
          }
        },
        baseStyle: {
          fontWeight: 'semibold'
        }
      },
      FormLabel: {
        baseStyle: {
          fontSize: 'sm'
        }
      },
      Form: {
        parts: ['helperText'],
        baseStyle: {
          helperText: {
            mb: 2
          }
        }
      },
      Input: {
        parts: ['addon', 'field', 'element'],
        sizes: {
          sm: {
            field: {
              borderRadius: 'md'
            },
            addon: {
              borderRadius: 'md'
            },
            element: {
              borderRadius: 'md'
            }
          }
        }
      },
      Textarea: {
        sizes: {
          sm: {
            borderRadius: 'md'
          }
        }
      },
      Link: {
        variants: {
          primary: ({ color, colorScheme = 'purple' }) => ({
            color: color || `${colorScheme}.500`,
            _hover: {
              color: color || `${colorScheme}.400`
            }
          }),
          dotted: {
            display: 'inline-flex',
            alignItems: 'center',
            gap: 1,
            rounded: 'none',
            borderBottom: '1px dotted',
            _hover: {
              textDecoration: 'none'
            }
          }
        },
        baseStyle: {
          fontWeight: 'medium',
          _focusVisible: {
            boxShadow: 'outline',
            outline: '2px solid transparent',
            outlineOffset: '2px'
          },
          _focus: {
            outline: 'none',
            boxShadow: 'none'
          }
        }
      },
      Menu: {
        parts: ['item', 'list', 'divider'],
        baseStyle: {
          item: {
            px: 2,
            py: 1.5,
            rounded: 'md',
            fontSize: 'sm',
            fontWeight: 'medium',
            color: 'gray.700',
            _hover: {
              textDecoration: 'none'
            }
          },
          list: {
            px: 1,
            py: 1,
            boxShadow: 'big',
            borderRadius: 'lg'
          },
          divider: {
            my: 1
          }
        }
      },
      Modal: {
        parts: ['dialog', 'closeButton'],
        baseStyle: {
          closeButton: {
            insetEnd: 2
          }
        },
        sizes: {
          xs: getDialogSize('xs'),
          sm: getDialogSize('sm'),
          md: getDialogSize('md'),
          lg: getDialogSize('lg'),
          xl: getDialogSize('xl'),
          '2xl': getDialogSize('2xl'),
          '3xl': getDialogSize('3xl'),
          '4xl': getDialogSize('4xl'),
          '5xl': getDialogSize('5xl'),
          '6xl': getDialogSize('6xl'),
          full: getDialogSize('full')
        }
      },
      Popover: {
        parts: ['popper', 'content'],
        baseStyle: {
          popper: {
            zIndex: 'popover'
          },
          content: {
            shadow: 'lg',
            _focusVisible: {
              shadow: 'lg',
              outline: '2px solid transparent',
              outlineOffset: '2px'
            },
            _focus: {
              outline: 'none',
              shadow: 'lg'
            }
          }
        }
      },
      Select: {
        parts: ['addon', 'field', 'element'],
        sizes: {
          sm: {
            field: {
              borderRadius: 'md'
            }
          }
        },
        baseStyle: {
          field: {
            fontWeight: 'medium'
          }
        }
      },
      Spinner: {
        baseStyle: {
          color: 'gray.300',
          thickness: '2px'
        }
      },
      Table: {
        parts: ['th', 'tr', 'td', 'th'],
        baseStyle: {
          th: {
            color: 'gray.700',
            fontFamily: 'body',
            fontWeight: 'medium',
            textTransform: 'none',
            letterSpacing: 'normal'
          }
        },
        variants: {
          bordered: {
            tr: {
              borderY: '1px solid',
              borderColor: 'border.lightest'
            },
            th: {
              py: 0,
              height: '38px',

              '&:not(:first-of-type):not(:last-of-type)': {
                borderX: '1px solid',
                borderColor: 'border.lightest'
              },
              '&:first-of-type': {
                borderRight: '1px solid',
                borderColor: 'border.lightest'
              },
              '&:last-of-type': {
                borderLeft: '1px solid',
                borderColor: 'border.lightest'
              },
              '&[data-is-numeric=true]': {
                textAlign: 'end'
              }
            },
            td: {
              '&:not(:first-of-type):not(:last-of-type)': {
                borderX: '1px solid',
                borderColor: 'border.lightest'
              },
              '&:first-of-type': {
                borderRight: '1px solid',
                borderColor: 'border.lightest'
              },
              '&:last-of-type': {
                borderLeft: '1px solid',
                borderColor: 'border.lightest'
              },
              '&[data-is-numeric=true]': {
                textAlign: 'end'
              }
            }
          },
          base: {
            th: {
              px: 2
            },
            td: {
              px: 2
            }
          },
          sm: {
            th: {
              px: 3
            },
            td: {
              px: 3
            }
          }
        },
        sizes: {
          sm: {
            th: {
              fontSize: '13px',
              px: 3
            },
            td: {
              px: 3,
              py: 2
            }
          },
          md: {
            th: {
              fontSize: '13px',
              px: 3
            },
            td: {
              fontSize: 'sm',
              px: 3
            }
          },
          lg: {
            th: {
              fontSize: '13px',
              px: 4
            },
            td: {
              px: 4
            }
          }
        }
      },
      Tabs: {
        parts: ['tab', 'tablist'],
        variants: {
          line: ({ orientation }) => ({
            tablist: {
              [orientation === 'vertical' ? 'borderStart' : 'borderBottom']: '1px solid'
            },
            tab: {
              [orientation === 'vertical' ? 'marginStart' : 'marginBottom']: '-1px',
              _active: {
                bg: 'background.highlight'
              }
            }
          })
        },
        sizes: {
          xs: {
            tab: {
              fontSize: 'xs',
              fontWeight: 'medium',
              px: 1.5,
              py: 2
            }
          },
          sm: {
            tab: {
              fontWeight: 'medium',
              px: 2,
              py: 2
            }
          }
        },
        baseStyle: {
          tab: {
            color: 'gray.600',
            fontWeight: 'medium',

            _focusVisible: {
              boxShadow: 'outline',
              outline: '2px solid transparent',
              outlineOffset: '2px'
            },
            _focus: {
              outline: 'none',
              boxShadow: 'none'
            },
            _hover: {
              textDecoration: 'none'
            }
          }
        }
      },
      Tag: {
        parts: ['container', 'label', 'closeButton'],
        variants: {
          outline: ({ colorScheme }) => ({
            container: {
              color: `${colorScheme}.600`,
              border: '1px solid',
              borderColor: `${colorScheme}.200`
            }
          }),
          subtleBorder: ({ colorScheme }) => ({
            container: {
              bg: `${colorScheme}.50`,
              color: `${colorScheme}.800`,
              border: '1px solid',
              borderColor: `${colorScheme}.100`
            }
          })
        },
        sizes: {
          sm: {
            container: {
              fontSize: '13px'
            },
            closeButton: {
              w: '0.9rem',
              h: '0.9rem',
              fontSize: '0.9rem'
            }
          },
          md: {
            container: {
              fontSize: 'sm'
            }
          },
          lg: {
            container: {
              fontSize: 'md'
            }
          }
        }
      },
      Tooltip: {
        baseStyle: {
          borderRadius: 'md'
        },
        defaultProps: {
          size: 'sm'
        },
        sizes: {
          xs: {
            fontSize: 'xs'
          },
          sm: {
            fontSize: '13px'
          },
          md: {
            fontSize: 'sm'
          }
        }
      }
    }
  },
  withDefaultColorScheme({
    colorScheme: 'purple',
    components: ['Checkbox', 'Switch', 'RadioGroup', 'Radio', 'Slider', 'Tabs', 'Progress']
  })
)
