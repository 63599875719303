import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Img,
  SkeletonText,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { IconArrowRight, IconChevronDown, IconChevronRight, IconX } from '@tabler/icons-react'
import { difference, keyBy, set } from 'lodash'
import { nanoid } from 'nanoid'
import React, { useCallback, useMemo, useState } from 'react'
import { ComboboxWithSearch } from '../../../../ui/ComboboxWithSearch'
import { JSONTree } from '../../../../ui/json-tree'
import { useCurrentUser } from '../../../../ui/UserContext'
import { ActionField } from '../../../actions/components/action-field'
import { channelLogos } from '../delivery-setup'
import { Ingredient, variableFieldOptionsByType } from '../slack-message-builder/ingredient-selector'
import { ActionSchema } from '../slack-message-builder/use-action-schema'
import { NotificationVariables, useNotificationVariables } from '../slack-message-builder/use-notification-variables'

interface OutreachField {
  name: string
  label: string
}

interface FieldMapperProps {
  actionSchema: ActionSchema
  deps: any
  type: 'contact' | 'account' | 'lead' | string
  namespace?: string
  mappings?: OutreachMapping[]
  suggestions?: OutreachMapping[]
}

interface PropertyEntryProps {
  actionSchema: ActionSchema
  layout: OutreachField[]
  variables: NotificationVariables
  namespace?: string
  type: string
  id?: string
  koalaField?: string
  outreachField?: string
  koalaOptions: Array<{
    key: string
    label: string
    humanLabel: string
  }>
  onChange: (koala: string | undefined, outreach: string | undefined) => void
}

interface FieldPreviewProps {
  item: OutreachField | null
  selectedItem?: OutreachField | null
}

export function FieldPreview(props: FieldPreviewProps) {
  const item = props.item

  if (!item) {
    return (
      <HStack flex="1" fontSize={'sm'}>
        <Img src={channelLogos.outreach} w="4" />
        <Text color="gray.600">Select a field in Outreach</Text>
      </HStack>
    )
  }

  return (
    <HStack flex="1" fontSize={'sm'}>
      <Img src={channelLogos.outreach} w="4" />
      <Text>{item.label}</Text>
    </HStack>
  )
}

function PropertyEntry(props: PropertyEntryProps) {
  const [selected, setSelected] = useState<OutreachField | null>(
    props.layout.find((l) => l.name === props.outreachField) ?? null
  )

  const [selectedKoala, setSelectedKoala] = useState<Ingredient | null>(
    props.koalaOptions.find((o) => o.key === props.koalaField) ?? null
  )

  const { outreachField, koalaField, onChange } = props

  const onKoalaChange = useCallback(
    (koala: Ingredient | null) => {
      setSelectedKoala(koala)
      onChange(koala?.key, outreachField)
    },
    [onChange, outreachField]
  )

  const onOutreachChange = useCallback(
    (outreach: OutreachField | null) => {
      setSelected(outreach)
      onChange(koalaField, outreach?.name)
    },
    [onChange, koalaField]
  )

  return (
    <Flex w="100%">
      {selectedKoala && selected && (
        <>
          <input type="hidden" name={`${props.namespace}[fields][][koala]`} value={selectedKoala.key} />
          <input type="hidden" name={`${props.namespace}[fields][][outreach]`} value={selected.name} />
          <input type="hidden" name={`${props.namespace}[fields][][id]`} value={props.id} />
        </>
      )}
      <HStack w="100%">
        <ActionField
          schema={props.actionSchema}
          selectedItem={selectedKoala}
          type={props.type ?? 'company'}
          onChange={(item) => {
            const selected = props.koalaOptions.find((o) => o.key === item.key) ?? null
            onKoalaChange(selected)
          }}
        />

        <Icon as={IconArrowRight} color="gray.400" boxSize={4} />

        <ComboboxWithSearch
          items={props.layout}
          selectedItem={selected}
          onChange={onOutreachChange}
          filterItem={(a, val) => a.label.toLowerCase().includes(val)}
          itemToString={(item) => item?.label ?? ''}
          itemRenderer={FieldPreview}
          selectButtonRenderer={FieldPreview}
        />
      </HStack>
    </Flex>
  )
}

function toNestedObject(array: Array<{ key: string; humanLabel: string }>) {
  const result = {}

  array.forEach((item) => {
    const path = item.key
    set(result, path, item.humanLabel)
  })

  return result
}

export interface OutreachMapping {
  id?: string
  koala?: string
  outreach?: string
}

const customFields: OutreachField[] = Array(150)
  .fill(null)
  .map((_, i) => ({
    name: `custom${i + 1}`,
    label: `Custom Field ${i + 1}`
  }))

const prospect_layout: OutreachField[] = [
  { name: 'addressCity', label: 'Address City' },
  { name: 'addressCountry', label: 'Address Country' },
  { name: 'addressState', label: 'Address State' },
  { name: 'addressZip', label: 'Address Zip' },
  { name: 'availableAt', label: 'Available At' },
  { name: 'campaignName', label: 'Campaign Name' },
  { name: 'degree', label: 'Degree' },
  { name: 'emails', label: 'Emails' },
  { name: 'eventName', label: 'Event Name' },
  { name: 'externalId', label: 'External Id' },
  { name: 'externalOwner', label: 'External Owner' },
  { name: 'externalSource', label: 'External Source' },
  { name: 'facebookUrl', label: 'Facebook Url' },
  { name: 'firstName', label: 'First Name' },
  { name: 'gender', label: 'Gender' },
  { name: 'githubUrl', label: 'Github Url' },
  { name: 'githubUsername', label: 'Github Username' },
  { name: 'googlePlusUrl', label: 'Google Plus Url' },
  { name: 'graduationDate', label: 'Graduation Date' },
  { name: 'homePhones', label: 'Home Phones' },
  { name: 'jobStartDate', label: 'Job Start Date' },
  { name: 'lastName', label: 'Last Name' },
  { name: 'linkedInId', label: 'LinkedIn Id' },
  { name: 'linkedInUrl', label: 'LinkedIn Url' },
  { name: 'middleName', label: 'Middle Name' },
  { name: 'mobilePhones', label: 'Mobile Phones' },
  { name: 'nickname', label: 'Nickname' },
  { name: 'occupation', label: 'Occupation' },
  { name: 'otherPhones', label: 'Other Phones' },
  { name: 'personalNote1', label: 'Personal Note 1' },
  { name: 'personalNote2', label: 'Personal Note 2' },
  { name: 'preferredContact', label: 'Preferred Contact' },
  { name: 'quoraUrl', label: 'Quora Url' },
  { name: 'region', label: 'Region' },
  { name: 'school', label: 'School' },
  { name: 'score', label: 'Score' },
  { name: 'sharingTeamId', label: 'Sharing Team Id' },
  { name: 'source', label: 'Source' },
  { name: 'specialties', label: 'Specialties' },
  { name: 'stackOverflowId', label: 'Stack Overflow Id' },
  { name: 'stackOverflowUrl', label: 'Stack Overflow Url' },
  { name: 'tags', label: 'Tags' },
  { name: 'timeZone', label: 'Time Zone' },
  { name: 'title', label: 'Title' },
  { name: 'twitterHandle', label: 'Twitter Handle' },
  { name: 'twitterId', label: 'Twitter Id' },
  { name: 'twitterUrl', label: 'Twitter Url' },
  { name: 'website', label: 'Website' }
].concat(customFields)

const account_layout: OutreachField[] = []

export function OutreachFieldMapper(props: FieldMapperProps) {
  const variables = useNotificationVariables()

  const layout = useMemo(() => {
    if (props.type === 'prospect') {
      return prospect_layout
    } else if (props.type === 'account') {
      return account_layout
    } else {
      return []
    }
  }, [props.type])

  const [mappings, setMappings] = useState<OutreachMapping[]>(props.mappings ?? [])

  const shouldShowSuggestions = useMemo(
    () => props.suggestions && props.suggestions.length > 0 && mappings.length === 0,
    [props.suggestions, mappings]
  )

  const options = useMemo(() => {
    const byType = variableFieldOptionsByType(variables)

    if (props.type === 'prospect') {
      return (byType['visitor'] ?? []).concat(byType['signal'] ?? [])
    }

    if (props.type === 'account') {
      return (byType['company'] ?? []).concat(byType['account'] ?? []).concat(byType['signal'] ?? [])
    }

    return Object.values(byType).flat()
  }, [variables, props.type])

  const noRemainingFields = useMemo(() => {
    const used = mappings.map((m) => m.outreach)
    const available = layout.map((l) => l.name)
    return difference(available, used).length === 0
  }, [mappings, layout])

  const user = useCurrentUser()
  const debug = useDisclosure()

  return (
    <Stack fontSize={'sm'} spacing="4">
      <Stack spacing="1">
        <Heading size="xs">Field Mappings</Heading>
        <Text color="gray.500">Define how each Koala field maps to an Outreach field.</Text>
      </Stack>
      <Stack spacing={2.5}>
        {variables.isLoading ? (
          <SkeletonText noOfLines={Math.max(mappings.length, 2)} />
        ) : (
          <>
            {mappings.map((mapping) => {
              return (
                <HStack key={mapping.id}>
                  <PropertyEntry
                    actionSchema={props.actionSchema}
                    id={mapping.id}
                    namespace={props.namespace}
                    variables={variables}
                    layout={layout}
                    koalaOptions={options}
                    koalaField={mapping.koala}
                    outreachField={mapping.outreach}
                    type={props.type}
                    onChange={(koala, outreach) => {
                      setMappings((prev) =>
                        prev.map((m) => {
                          if (m.id === mapping.id) {
                            return { ...m, koala, outreach }
                          } else {
                            return m
                          }
                        })
                      )
                    }}
                  />
                  <IconButton
                    aria-label="Remove Field Mapping"
                    size="xs"
                    onClick={() => {
                      setMappings((prev) => prev.filter((m) => m.id !== mapping.id))
                    }}
                    variant="ghost"
                    color="gray.400"
                    _hover={{ color: 'gray.800' }}
                    icon={<IconX size={14} />}
                  />
                </HStack>
              )
            })}
            <Stack
              bg={shouldShowSuggestions ? 'gray.50' : undefined}
              p={shouldShowSuggestions ? '4' : undefined}
              borderWidth={shouldShowSuggestions ? '1px' : undefined}
              borderColor={shouldShowSuggestions ? 'gray.400' : undefined}
              rounded="md"
              paddingTop={2}
            >
              {shouldShowSuggestions && (
                <Stack spacing="0.5">
                  <Heading size="xs">No Fields Selected</Heading>
                  <Text color="gray.500">You don't have any fields selected.</Text>
                </Stack>
              )}
              <HStack>
                {shouldShowSuggestions && (
                  <>
                    <Button
                      size="xs"
                      colorScheme={'purple'}
                      onClick={() => {
                        setMappings(props.suggestions ?? [])
                      }}
                    >
                      Use suggested fields
                    </Button>
                  </>
                )}
                <Button
                  size="xs"
                  variant={'outline'}
                  onClick={() => {
                    setMappings((prev) => [...prev, { id: nanoid() }])
                  }}
                  isDisabled={noRemainingFields}
                >
                  Add {shouldShowSuggestions && 'Individual'} Field
                </Button>
              </HStack>
            </Stack>
          </>
        )}
      </Stack>

      {/* debug panel for admins to inspect all variables + layout */}
      {user.isInternalUser && (
        <Box>
          <Divider />
          <HStack onClick={debug.onToggle} cursor="pointer" paddingY={3}>
            <Icon as={debug.isOpen ? IconChevronDown : IconChevronRight} boxSize={3.5} />
            <Heading size="xs">Debug (Internal Only)</Heading>
          </HStack>
          <Collapse in={debug.isOpen}>
            <Box paddingY={2}>
              <HStack alignItems="flex-start">
                <Box flex="1 1 50%">
                  <Text fontSize="sm">Variables</Text>
                  <JSONTree data={toNestedObject(options)} />
                </Box>
                <Box flex="1 1 50%">
                  <Text fontSize="sm">{props.type} Schema</Text>
                  <JSONTree data={keyBy(layout, 'name')} />
                </Box>
              </HStack>
            </Box>
          </Collapse>
        </Box>
      )}
    </Stack>
  )
}
