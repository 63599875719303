import { HStack, Icon, Stack, Text } from '@chakra-ui/react'
import { IconCircleFilled } from '@tabler/icons-react'
import React from 'react'
import { Card } from '../../../ui/Card'

interface ChartToolTipRowProps {
  name: string
  value: number | string
  color: string
  formatter?: (value: number | string, name: string) => string
}

export function ChartToolTipRow({ name, value, formatter, color }: ChartToolTipRowProps) {
  return (
    <HStack spacing={4} justifyContent="space-between">
      <HStack spacing={2} minWidth="100px">
        <Icon as={IconCircleFilled} flex="none" color={color} boxSize={2.5} />
        <Text flex="1 1 auto" color="gray.600" isTruncated>
          {name}
        </Text>
      </HStack>
      <Text flex="none" color="gray.700" fontWeight="medium" css={{ fontVariantNumeric: 'tabular-nums' }}>
        {typeof formatter === 'function'
          ? formatter(value, name)
          : typeof value === 'number'
          ? value?.toLocaleString()
          : value}
      </Text>
    </HStack>
  )
}

export function ChartTooltip({ active, payload, label, formatter, labelFormatter, colors }: any) {
  if (active && payload) {
    const filteredPayload = payload.filter((item: any) => item.type !== 'none')
    const total = filteredPayload.reduce((result, entry) => result + entry.value, 0)

    return (
      <Card
        bg="white"
        fontSize="sm"
        p={0}
        rounded="lg"
        color="gray.700"
        border="none"
        boxShadow="0px 1px 1px 0px rgb(0 0 0 / 10%), 0px 4px 16px rgb(0 0 0 / 15%)"
      >
        <Text
          size="sm"
          color="gray.700"
          fontWeight="medium"
          borderBottom="1px solid"
          borderColor="gray.200"
          py={2}
          px={4}
        >
          {typeof labelFormatter === 'function' ? labelFormatter(label) : label}
        </Text>
        <Stack spacing={1} py={2} px={4}>
          {filteredPayload.length > 1 && (
            <ChartToolTipRow name="Total" value={total} formatter={formatter} color="gray.500" />
          )}
          {filteredPayload.map((entry) => (
            <ChartToolTipRow
              key={`chart-tooltip-row:${entry.name}:${entry.value}`}
              name={entry.name}
              value={entry.value}
              formatter={formatter}
              color={colors?.get(entry.name) || (entry.fill?.startsWith('url') ? entry.stroke : entry.fill)}
            />
          ))}
        </Stack>
      </Card>
    )
  }

  return null
}
