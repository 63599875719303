import * as React from 'react'

export type OnlineStatus = 'online' | 'offline' | 'away'

const defaultStatus = window.currentUser?.status || 'offline'

interface OnlineStatusValue {
  status: OnlineStatus
  updateStatus: (status: OnlineStatus) => void
}

export const OnlineStatusContext = React.createContext<OnlineStatusValue>({
  status: defaultStatus,
  updateStatus: (_status: OnlineStatus) => {}
})

export function useOnlineStatus() {
  return React.useContext(OnlineStatusContext)
}

export function OnlineStatusProvider({ children }: React.PropsWithChildren<{}>) {
  const [status, updateStatus] = React.useState<OnlineStatus>(defaultStatus)

  const context = React.useMemo(
    () => ({
      status,
      updateStatus
    }),
    [status, updateStatus]
  )

  return <OnlineStatusContext.Provider value={context}>{children}</OnlineStatusContext.Provider>
}
