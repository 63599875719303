import {
  Box,
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbProps,
  Icon
} from '@chakra-ui/react'
import { IconChevronRight } from '@tabler/icons-react'
import React from 'react'
import { useLayoutMode } from './useLayoutMode'

export interface BreadcrumbPath {
  path: string
  title: string
  isCurrentPage?: boolean
}

export function Breadcrumb({ paths, paddingTop, ...props }: BreadcrumbProps & { paths?: BreadcrumbPath[] }) {
  const layoutMode = useLayoutMode()

  const children =
    props.children ||
    paths?.map((item, index) => (
      <BreadcrumbItem
        key={item.path + index}
        isCurrentPage={paths?.length === index + 1 && item.isCurrentPage !== false}
        maxWidth="100%"
        overflow="hidden"
        title={item.title}
      >
        <BreadcrumbLink href={item.path} isTruncated>
          {item.title}
        </BreadcrumbLink>
      </BreadcrumbItem>
    ))

  return (
    <Box display="flex" alignItems="center" gap={2} paddingTop={layoutMode === 'new' ? 0 : paddingTop || 0}>
      <ChakraBreadcrumb
        separator={<Icon as={IconChevronRight} color="gray.400" boxSize={4} />}
        overflow="hidden"
        {...props}
      >
        {children}
      </ChakraBreadcrumb>
    </Box>
  )
}
