import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconEdit, IconUserEdit } from '@tabler/icons-react'
import React, { useCallback } from 'react'
import { toast } from 'sonner'
import { post, postForm } from '../../../../lib/api'
import router from '../../../../lib/router'
import { ProfileRecord } from '../../../../types/Profile'
import { CompanySelector, PartialCompany } from '../../../ui/CompanySelector'
import { BuildingIcon } from '../../../ui/icons'
import { profilePath } from '../lib/path'

export function ProfileEditor(props: { profile: ProfileRecord; onUpdateProfile?: (profile: ProfileRecord) => void }) {
  const [isIdentifyModalOpen, setIsIdentifyModalOpen] = React.useState(false)
  const [isChangeCompanyModalOpen, setIsChangeCompanyModalOpen] = React.useState(false)

  return (
    <>
      {isIdentifyModalOpen && (
        <IdentifyModal
          onClose={() => setIsIdentifyModalOpen(false)}
          onIdentify={props.onUpdateProfile}
          profile={props.profile}
        />
      )}
      {isChangeCompanyModalOpen && (
        <ChangeCompanyModal
          onClose={() => setIsChangeCompanyModalOpen(false)}
          onCompanyChange={props.onUpdateProfile}
          profile={props.profile}
        />
      )}
      <Menu>
        <MenuButton as={IconButton} size="xs" variant="ghost" icon={<IconEdit size="14" />} />
        <MenuList zIndex="popover">
          <MenuItem icon={<IconUserEdit size={14} />} onClick={() => setIsIdentifyModalOpen(true)}>
            {props.profile.email ? 'Edit details' : 'Identify'}
          </MenuItem>
          <MenuItem onClick={() => setIsChangeCompanyModalOpen(true)} icon={<BuildingIcon size={14} />}>
            Change Company
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}

interface ChangeCompanyModalProps {
  profile: ProfileRecord
  onCompanyChange?: (profile: ProfileRecord) => void
  onClose: () => void
}

function ChangeCompanyModal(props: ChangeCompanyModalProps) {
  const [company, setCompany] = React.useState<PartialCompany | null>(props.profile.company || null)

  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const associateAccount = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      setIsSubmitting(true)
      const path = profilePath(
        {
          id: props.profile.id
        },
        '/actions/account'
      )

      post<{ profile: ProfileRecord }>(path, {
        profile: {
          company_domain: company?.domain
        }
      })
        .then((res) => {
          toast('Company updated successfully')
          props.onCompanyChange?.(res.profile)

          setTimeout(() => {
            props.onClose()
            router.visit(location.toString(), {
              fetch: true
            })
          })
        })
        .catch((err) => {
          toast.error('Failed to update company: ' + err.message)
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    },
    [company, props]
  )

  return (
    <Modal isOpen onClose={props.onClose} closeOnEsc>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Change Company</ModalHeader>
        <ModalBody>
          <form onSubmit={associateAccount}>
            <Stack spacing="4" pb="4">
              <Text fontSize={'sm'}>
                Choose which company you want to associate this visitor to. This is useful when you know the visitor has
                moved to a different company than the one initially identified.
              </Text>

              <CompanySelector
                selectedCompany={company}
                onChange={(company) => {
                  setCompany(company)
                }}
                showClearButton
              />

              <Flex justifyContent={'flex-end'} pt="2">
                <Button onClick={props.onClose} size="sm" mr="2" variant="outline">
                  Cancel
                </Button>
                <Button
                  size="sm"
                  isDisabled={company === null}
                  type="submit"
                  isLoading={isSubmitting}
                  colorScheme="purple"
                >
                  Save
                </Button>
              </Flex>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

interface IdentifyModalProps {
  profile: ProfileRecord
  onClose: () => void
  onIdentify?: (profile: ProfileRecord) => void
}

function IdentifyModal(props: IdentifyModalProps) {
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const identify = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const formData = new FormData(e.currentTarget)

      setIsSubmitting(true)
      const path = profilePath(
        {
          id: props.profile.id
        },
        '/actions/identify'
      )
      postForm<{ profile: ProfileRecord }>(path, formData)
        .then((res) => {
          toast('Details updated successfully')
          props.onIdentify?.(res.profile)
          setTimeout(() => {
            props.onClose()
          })
        })
        .catch((err) => {
          toast.error('Failed to update details: ' + err.message)
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    },
    [props]
  )

  return (
    <Modal isOpen onClose={props.onClose} closeOnEsc>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Edit profile details</ModalHeader>
        <ModalBody>
          <form onSubmit={identify} data-koala-collect="off">
            <Stack spacing="4" pb="4">
              <FormControl>
                <FormLabel>Email*</FormLabel>
                <Input name="profile[email]" required size="sm" type="email" defaultValue={props.profile.email ?? ''} />
              </FormControl>

              <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input name="profile[first_name]" size="sm" defaultValue={props.profile.first_name ?? ''} />
              </FormControl>

              <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input name="profile[last_name]" size="sm" defaultValue={props.profile.last_name ?? ''} />
              </FormControl>

              <FormControl>
                <FormLabel>Title</FormLabel>
                <Input name="profile[title]" size="sm" defaultValue={props.profile.title ?? ''} />
              </FormControl>

              <Flex justifyContent={'flex-end'} pt="2">
                <Button onClick={props.onClose} size="sm" mr="2" variant="outline">
                  Cancel
                </Button>
                <Button type="submit" isLoading={isSubmitting} colorScheme="purple" size="sm">
                  Save
                </Button>
              </Flex>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
