import { Box, HStack, Icon, Spinner, Circle, Text } from '@chakra-ui/react'
import Avatar from './Avatar'
import { UserCircleIcon } from './icons'
import React, { useCallback, useMemo } from 'react'
import { useUsers } from '../data/use-users'
import { useCurrentUser } from './UserContext'
import { ComboboxWithSearch } from './ComboboxWithSearch'

interface PartialUser {
  id?: string
  name?: string
  email?: string
  avatar?: string
  you?: boolean
}

const unassigned: PartialUser = {
  id: '',
  name: 'No assignee'
}

interface UserSelectProps {
  users?: PartialUser[]
  includeUnassigned?: boolean
  selectedUserId?: string | null
  onChange?: (userId: string | null) => void
}

const emptyArray = []

export function UserSelector({ selectedUserId, includeUnassigned, onChange, users: specificUsers }: UserSelectProps) {
  const { data, isLoading } = useUsers({ cached: false, enabled: !specificUsers })
  const currentUser = useCurrentUser()

  const users: PartialUser[] = useMemo(() => {
    const all: PartialUser[] = [...(specificUsers || data?.users || emptyArray)].map((u) => ({
      ...u,
      you: u.id === currentUser?.id
    }))

    if (includeUnassigned) {
      all.unshift(unassigned)
    }

    return all
  }, [data?.users, currentUser, specificUsers, includeUnassigned])

  const selected = useMemo(() => {
    return users.find((u) => u.id === selectedUserId) || null
  }, [users, selectedUserId])

  const handleChange = useCallback(
    (user) => {
      onChange?.(user?.id || null)
    },
    [onChange]
  )

  if (isLoading) {
    return (
      <Box minHeight="42px">
        <Spinner color="gray.400" thickness="1.5px" size="sm" />
      </Box>
    )
  }

  return (
    <ComboboxWithSearch
      items={users}
      selectedItem={selected || (includeUnassigned ? unassigned : null)}
      onChange={handleChange}
      filterItem={(a, val) => a.name?.toLowerCase().includes(val) || a.email?.toLowerCase().includes(val) || false}
      itemToString={(item) => item?.name || ''}
      itemRenderer={UserRenderer}
      selectButtonRenderer={UserRenderer}
    />
  )
}

interface UserRendererProps {
  item: PartialUser | null
  selectedItem?: PartialUser | null
}

function UserRenderer(props: UserRendererProps) {
  const user = props.item

  return (
    <HStack spacing={2}>
      {user === unassigned || !user ? (
        <Circle borderWidth="1.5px" borderStyle="dashed" borderColor="gray.300" overflow="hidden">
          <Icon boxSize="15px" bg="gray.300" as={UserCircleIcon} color="white" />
        </Circle>
      ) : (
        <Avatar size="tiny" name={user.name || user.email} src={user.avatar} />
      )}
      {user ? (
        <Text fontSize="sm" fontWeight="medium">
          {user.name || user.email || 'Unknown'}
          {user.you ? ' (you)' : ''}
        </Text>
      ) : (
        <Text fontSize="sm" fontWeight="medium">
          Select a user
        </Text>
      )}
    </HStack>
  )
}
