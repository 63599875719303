import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack
} from '@chakra-ui/react'
import get from 'lodash/get'
import React from 'react'
import { toast } from 'sonner'
import { post } from '../../../../lib/api'
import { Company } from '../../../../types/Profile'
import { projectPath } from '../../../ui/ProjectsContext'
import { useCurrentUser } from '../../../ui/UserContext'

interface SuggestionsModalProps {
  isOpen: boolean
  onClose: () => void
  company: Company
}

const fieldOptions = {
  'metrics.employeesRange': ['1-10', '11-50', '51-250', '251-1K', '1K-5K', '5K-10K', '10K-50K', '50K-100K', '100K+'],
  'metrics.estimatedAnnualRevenue': [
    '$0-$1M',
    '$1M-$10M',
    '$10M-$50M',
    '$50M-$100M',
    '$100M-$250M',
    '$250M-$500M',
    '$500M-$1B',
    '$500M-$10B',
    '$1B-$10B',
    '$10B+'
  ]
}

export const fields = [
  { key: 'name', label: 'Name' },
  { key: 'description', label: 'Description' },
  { key: 'founded_year', label: 'Founded Year' },
  { key: 'logo', label: 'Logo URL' },
  { key: 'category.sector', label: 'Sector' },
  { key: 'category.industryGroup', label: 'Industry Group' },
  { key: 'category.industry', label: 'Industry' },
  { key: 'category.subIndustry', label: 'Sub Industry' },
  { key: 'metrics.raised', label: 'Amount Raised' },
  { key: 'metrics.employees', label: 'Employees' },
  { key: 'metrics.employeesRange', label: 'Employees Range' },
  { key: 'metrics.annualRevenue', label: 'Annual Revenue' },
  { key: 'metrics.estimatedAnnualRevenue', label: 'Estimated Annual Revenue' },
  { key: 'location', label: 'Location' },
  { key: 'geo.city', label: 'City' },
  { key: 'geo.state', label: 'State' },
  { key: 'geo.country', label: 'Country' },
  { key: 'linkedin.url', label: 'LinkedIn URL' },
  { key: 'twitter.url', label: 'Twitter URL' },
  { key: 'facebook.url', label: 'Facebook URL' },
  { key: 'crunchbase.url', label: 'Crunchbase URL' }
]

export function SuggestionsModal({ isOpen, onClose, company }: SuggestionsModalProps) {
  const user = useCurrentUser()
  const formRef = React.useRef<HTMLFormElement | null>(null)
  const [submitting, setSubmitting] = React.useState(false)
  const [applying, setApplying] = React.useState(false)

  const postPath = projectPath(`/accounts/${company.domain}/suggestions`)

  const submit = React.useCallback(
    async (path) => {
      if (!formRef.current) {
        return
      }

      const formData = new FormData(formRef.current)
      const changes = Object.fromEntries(formData.entries())

      try {
        await post(path, { suggestion: changes })
        toast.success('We received your suggested edits. Thanks!')
        onClose()
      } catch (_err) {
        toast.error('Failed to save your suggestions', {
          description: 'We have been notified of the error, but you can try again soon!'
        })
      }
    },
    [onClose]
  )

  const onSubmit = React.useCallback(
    async (event) => {
      event.preventDefault()
      setSubmitting(true)
      await submit(postPath)
      setSubmitting(false)
    },
    [submit, postPath]
  )

  const apply = React.useCallback(
    async (event) => {
      event.preventDefault()
      setApplying(true)
      await submit(postPath + '?apply=1')
      setApplying(false)
      window.location.reload()
    },
    [submit, postPath]
  )

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="lg" preserveScrollBarGap>
      <form ref={formRef} action={postPath} method="POST" onSubmit={onSubmit}>
        <DrawerOverlay bg="blackAlpha.300" />
        <DrawerContent>
          <DrawerHeader>Suggest updates for {company.name || company.domain}</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody>
            <Stack spacing={8} marginBottom={8}>
              {fields.map((field) => (
                <Field key={field.key} field={field} value={get(company, field.key)} />
              ))}
            </Stack>
          </DrawerBody>

          <DrawerFooter gap={3}>
            {user.isInternalUser ? (
              <>
                <Button size="sm" type="submit" variant="outline" isLoading={submitting}>
                  Submit for review
                </Button>

                <Button size="sm" type="button" colorScheme="purple" isLoading={applying} onClick={apply}>
                  Apply Changes
                </Button>
              </>
            ) : (
              <>
                <Button size="sm" variant="outline" onClick={onClose}>
                  Cancel
                </Button>

                <Button size="sm" type="submit" colorScheme="purple" isLoading={submitting}>
                  Submit
                </Button>
              </>
            )}
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  )
}

interface FieldProps {
  field: {
    key: string
    label: string
  }
  value?: string | null
}

function Field(props: FieldProps) {
  return (
    <FormControl>
      <FormLabel>{props.field.label}</FormLabel>
      {fieldOptions[props.field.key] ? (
        <Select name={props.field.key} defaultValue={props.value || ''} fontWeight="normal">
          {fieldOptions[props.field.key].map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </Select>
      ) : (
        <Input type="text" name={props.field.key} defaultValue={props.value || ''} />
      )}
    </FormControl>
  )
}
