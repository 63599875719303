import { projectPath } from '@app/components/ui/ProjectsContext'
import { Box, Button, HStack, Link, Stack, Text } from '@chakra-ui/react'
import { IconAlertTriangle, IconChevronRight } from '@tabler/icons-react'
import React from 'react'
import CircleIcon from '../../../ui/CircleIcon'

interface Props {
  variant?: 'full' | 'short'
  appTitle: string
  errorMessage?: string
}

export function ReconnectionRequiredWarning({ appTitle, errorMessage, variant = 'full' }: Props) {
  const appId = appTitle.toLowerCase()

  if (variant === 'full') {
    return (
      <Stack spacing={4} padding={4} bg="orange.50" rounded="lg">
        <Stack spacing={0.5}>
          <Text fontSize="xs" fontWeight="semibold">
            We've encountered an error with your {appTitle} integration{errorMessage && ':'}
          </Text>

          {errorMessage && (
            <Text fontSize="xs" fontWeight="semibold" fontFamily="mono" color="orange.700">
              {errorMessage}
            </Text>
          )}
        </Stack>

        <Text fontSize="xs">
          Unfortunately your {appTitle} integration is no longer functional and a reconnection is required in order to
          restore related features.
        </Text>

        <Text fontSize="xs">
          Reconnect {appTitle} to start using it again in your workspace. If you have any issues or questions, send us a
          message at <Link href="mailto:support@getkoala.com">support@getkoala.com</Link>.
        </Text>

        {appTitle == 'Salesforce' && errorMessage && (
          <Box>
            <Button
              as={Link}
              href="https://getkoala.com/docs/integrations/salesforce#troubleshooting"
              isExternal
              variant="ghost"
              size="xs"
              color="orange.700"
              _hover={{
                bg: 'orange.100'
              }}
              alignSelf="flex-start"
              ml={-1.5}
              iconSpacing={1}
              rightIcon={<IconChevronRight size={14} />}
            >
              Learn more
            </Button>
          </Box>
        )}
      </Stack>
    )
  }

  return (
    <Stack spacing={4} paddingX={4} paddingY={3} bg="orange.50" color="orange.900" fontWeight="medium" rounded="lg">
      <HStack spacing={3} fontSize="sm">
        <CircleIcon icon={IconAlertTriangle} iconSize={5} padding={2} bg="orange.200" color="orange.900" />
        <Text>
          Your {appTitle} connection needs attention.
          <Link href={projectPath(`/apps/${appId}`)} fontWeight="bold" isExternal mx={1}>
            Click here
          </Link>
          to fix it.
        </Text>
      </HStack>
    </Stack>
  )
}
