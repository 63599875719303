import { Button, Flex, Heading, Link, List, ListIcon, ListItem, Stack, Text } from '@chakra-ui/react'
import { IconCheck } from '@tabler/icons-react'
import React, { useMemo } from 'react'
import type Stripe from 'stripe'
import { formatDate } from '../../../lib/dayjs'
import { Card } from '../../ui/Card'
import { projectPath } from '../../ui/ProjectsContext'
import { humanize } from '../accounts/facets/filter-cloud'
import { Plan } from './plans'
import { PlanData, plansV3 } from './PricingTable'
import { KoalaSubscription } from './show'

export function friendlyNumber(num: number) {
  return new Intl.NumberFormat().format(num)
}

interface Props {
  live_subscription?: Stripe.Subscription & { quantity: number; plan: Stripe.Plan }
  live_customer?: Stripe.Customer
  koala_subscription: KoalaSubscription
  plan?: Plan | 'unlimited'
}

export function V3PlanBreakdown(props: Props) {
  const sub = props.live_subscription
  const currentPlan: PlanData | null = useMemo(() => (props.plan ? plansV3[props.plan] : null), [props.plan])

  return (
    <Stack spacing={2} w="100%">
      <Heading size="sm" fontWeight={'semibold'}>
        Your Plan
      </Heading>
      <Card padding={0} overflow="hidden">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          gap={4}
          padding={4}
          borderBottom="1px solid"
          borderColor="gray.200"
        >
          <Stack spacing={1}>
            <Heading size="sm">
              {humanize(props.plan || (props.koala_subscription.unlimited ? 'Unlimited' : 'Free'))} Plan
            </Heading>
            <Text fontSize="xs" color="gray.600">
              {currentPlan?.description}
            </Text>
          </Stack>

          {props.plan !== 'business' &&
            props.plan !== 'unlimited' &&
            !props.koala_subscription.product_sku.includes('invoiced') && (
              <>
                {props.plan === 'free' ? (
                  <Flex flex="none" gap={2}>
                    <Button as={Link} href={projectPath('/settings/plans')} size="sm" variant="outline">
                      Compare plans
                    </Button>
                    <Button as={Link} href={projectPath('/settings/plans')} size="sm" colorScheme="purple">
                      Upgrade
                    </Button>
                  </Flex>
                ) : (
                  <Flex flex="none" gap={2}>
                    <Button as={Link} href={projectPath('/settings/plans')} size="sm" variant="outline">
                      Manage plan
                    </Button>
                  </Flex>
                )}
              </>
            )}
        </Flex>
        <Stack
          spacing={3}
          padding={4}
          borderBottomWidth={sub?.cancel_at_period_end ? '0' : '1px'}
          borderColor="gray.200"
        >
          <List fontSize="sm" spacing="2">
            {currentPlan?.features?.map((feature) => (
              <ListItem key={feature} display="flex">
                <ListIcon as={IconCheck} boxSize={4} color="purple.500" marginY={0.5} />
                {feature}
              </ListItem>
            ))}
          </List>

          {props.plan === 'starter' && (
            <Flex flex="none" gap={2}>
              <Button as={Link} colorScheme="purple" href={projectPath('/settings/plans')} size="sm" variant="outline">
                Upgrade to unlock more features and users
              </Button>
            </Flex>
          )}
        </Stack>

        {sub?.cancel_at_period_end && (
          <Stack padding={4} bg="orange.50" borderTop="1px solid" borderColor="orange.200">
            <Text fontSize="xs" fontWeight="semibold">
              Your plan will be downgraded on {formatDate(sub.current_period_end * 1000)}
            </Text>
            <Text fontSize="xs">
              Any billing changes you make will automatically void your scheduled downgrade (including new invites to
              your workspace).
            </Text>
          </Stack>
        )}
      </Card>
    </Stack>
  )
}
