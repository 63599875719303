import React, { PropsWithChildren } from 'react'
import { Box, HStack, Stack } from '@chakra-ui/react'
import CircleIcon from './CircleIcon'
import { IconAlertTriangle } from '@tabler/icons-react'

export function WarningMessage(props: PropsWithChildren<{}>) {
  return (
    <Stack spacing={4} paddingX={4} paddingY={3} bg="orange.50" color="orange.900" fontWeight="medium" rounded="lg">
      <HStack spacing={3} fontSize="sm">
        <CircleIcon icon={IconAlertTriangle} iconSize={5} padding={2} bg="orange.200" color="orange.900" />
        <Box>{props.children}</Box>
      </HStack>
    </Stack>
  )
}
