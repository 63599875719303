import isPlainObject from 'lodash/isPlainObject'
import { nanoid } from 'nanoid'

// adds unique ids to every object so they are easier to work with
// while preserving existing ids
export function addIdentity(obj) {
  if (Array.isArray(obj)) {
    return obj.map((o) => addIdentity(o))
  } else if (isPlainObject(obj)) {
    const newObj = {
      id: nanoid()
    }

    for (const key of Object.keys(obj)) {
      newObj[key] = addIdentity(obj[key])
    }

    return newObj
  }

  return obj
}

export function removeIdentity(obj) {
  if (Array.isArray(obj)) {
    return obj.map((o) => removeIdentity(o))
  } else if (isPlainObject(obj)) {
    const newObj = {}

    for (const key of Object.keys(obj)) {
      if (key !== 'id') {
        newObj[key] = removeIdentity(obj[key])
      }
    }

    return newObj
  }

  return obj
}
