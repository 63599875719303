import { Button, Heading, Link, Stack, Text } from '@chakra-ui/react'
import { IconBolt, IconPlus } from '@tabler/icons-react'
import React from 'react'
import CircleIcon from '../../../ui/CircleIcon'
import { projectPath } from '../../../ui/ProjectsContext'

export function IntentSignalEmptyState() {
  return (
    <Stack
      width="full"
      rounded="lg"
      bg="gray.50"
      border="1px solid"
      borderColor="gray.200"
      padding="40px"
      alignItems="center"
      spacing={6}
    >
      <CircleIcon icon={IconBolt} iconSize={8} colorScheme="purple" />
      <Stack alignItems="center" spacing={1}>
        <Heading size="sm">No intent signals yet</Heading>
        <Text fontSize="sm" textAlign="center">
          Create a new intent signal from scratch or start from a suggested intent signal below
        </Text>
      </Stack>
      <Button
        as={Link}
        href={projectPath('/signals/new')}
        leftIcon={<IconPlus size={14} />}
        size="sm"
        colorScheme="purple"
      >
        Create Intent Signal
      </Button>
    </Stack>
  )
}
