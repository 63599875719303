import { Box, Flex, HStack, IconButton, Image, Link, Text, useBreakpointValue } from '@chakra-ui/react'
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import pluralize from 'pluralize'
import React, { useCallback, useMemo } from 'react'
import { duration } from '../../../lib/duration'
import { Session } from '../../../types/Session'
import { accountPath } from '../../pages/accounts/lib/account-path'
import { profilePath } from '../../pages/profiles/lib/path'
import Avatar from '../Avatar'
import { flagCdnUrl } from '../CircleFlag'
import { MiddotDivider } from '../Middot'
import { TimeAgo } from '../TimeAgo'

type Maybe<T> = T | null | undefined

interface Props {
  session: Session
  showingMore?: boolean
  onShowMore?: () => void
  hideTimestamps?: boolean
  scopedTo?: 'account' | 'profile'
}

export function FeedStoryActor(props: Props) {
  const person = props.session.profile

  const actorName = getActorName(props.session)
  const companyName = getCompanyName(props.session)
  const companyLogo = getCompanyLogo(props.session)
  const title = getTitle(props.session)
  const geo = person?.simple_location

  const signals = props.session.intent_signals ?? []
  const hasIntentSignals = signals.length > 0

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      // ignore clicks on child links
      const targetAnchor = (event.target as HTMLElement).closest('a')
      if (targetAnchor !== event.currentTarget && event.currentTarget.contains(targetAnchor)) {
        return
      }

      if (props.onShowMore && !event.defaultPrevented) {
        props.onShowMore()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onShowMore]
  )

  const secondaryLogo = useMemo(() => {
    if (person?.initial_source === 'github') {
      return 'https://github.githubassets.com/assets/GitHub-Mark-ea2971cee799.png'
    }

    if (props.scopedTo !== 'account') {
      return companyLogo
    }

    return flagCdnUrl(person?.country_code)
  }, [companyLogo, person?.country_code, person?.initial_source, props.scopedTo])

  return (
    <Flex role="group" gap={3} cursor={props.onShowMore ? 'pointer' : undefined} onClick={handleClick}>
      {actorName ? (
        <ActorImage name={person?.name} email={person?.email} image={person?.image} secondaryLogo={secondaryLogo} />
      ) : (
        <ActorImage image={companyLogo} secondaryLogo={secondaryLogo} />
      )}
      <Box flex="1 1 auto" minW="150px">
        <ActorTitle session={props.session} actor={actorName} company={companyName} scopedTo={props.scopedTo} />
        <ActorMeta
          session={props.session}
          company={props.scopedTo !== 'account' && actorName ? companyName : undefined}
          title={title}
          geo={geo}
        />
        <Box>
          <FeedStoryMeta
            time={props.session.last_touched_at}
            duration={hasIntentSignals ? props.session.duration : undefined}
            pages={props.session.num_pages}
            hideTimestamps={props.hideTimestamps}
          />
        </Box>
      </Box>
      <Box flex="none" alignSelf="center" marginLeft="auto">
        {props.onShowMore && (
          <IconButton
            aria-label={props.showingMore ? 'Show less' : 'Show more'}
            size="xs"
            variant="unstyled"
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="purple.400"
            transition="color 150ms ease-in-out"
            _groupHover={{ color: 'purple.700' }}
            icon={props.showingMore ? <IconChevronUp size={18} /> : <IconChevronDown size={18} />}
          />
        )}
      </Box>
    </Flex>
  )
}

interface ActorImageProps {
  name?: Maybe<string>
  email?: Maybe<string>
  image?: Maybe<string>
  secondaryLogo?: Maybe<string>
}

export function ActorImage(props: ActorImageProps) {
  const [error, setError] = React.useState(false)

  return (
    <Box display="flex" flex="none" alignSelf="center" position="relative">
      <Avatar size="44px" name={props.name || props.email} src={props.image} />
      {props.secondaryLogo && !error && (
        <Box position="absolute" transform="translate(18%, 18%)" right={0} bottom={0}>
          <Image
            src={props.secondaryLogo}
            boxSize="18px"
            rounded="full"
            boxShadow="0 0 0 2px white"
            background="white"
            objectFit="contain"
            onError={() => setError(true)}
          />
        </Box>
      )}
    </Box>
  )
}

function getActorName(session: Session) {
  const person = session.profile
  return person?.name || person?.email
}

function getCompanyName(session: Session) {
  const company = session.company
  return company?.name || company?.domain
}

function getCompanyLogo(session: Session) {
  const company = session.company
  if (company?.logo) {
    return company.logo
  }

  if (company?.domain) {
    return `https://unavatar.io/${company.domain}?fallback=false`
  }

  return null
}

function getTitle(session: Session) {
  const person = session.profile
  return person?.title
}

interface ActorTitleProps {
  session: Session
  actor?: Maybe<string>
  company?: Maybe<string>
  scopedTo?: 'account' | 'profile'
}

function partitionNames(names, targetLength) {
  const fittingNames: string[] = []
  const overflowNames: string[] = []
  let remainingLength = targetLength

  for (let name of names) {
    name = name.trim()
    const nameLength = name.length

    if (nameLength <= remainingLength || fittingNames.length === 0) {
      fittingNames.push(name)
      remainingLength -= nameLength + 2 // Add 2 for comma and space
    } else {
      overflowNames.push(name)
    }
  }

  return [fittingNames, overflowNames]
}

export function ActorTitle(props: ActorTitleProps) {
  const longForm = useBreakpointValue({ base: false, lg: true })
  const person = props.session.profile
  const signals = props.session.intent_signals ?? []
  const hasIntentSignals = signals.length > 0
  const [signalNames, others] = partitionNames(
    signals.map((s) => s.context.definition?.name),
    longForm ? 60 : 30
  )

  let displayName = props.actor
  if (!props.actor && !props.company) {
    displayName = 'Anonymous'
  } else if (!props.actor && props.company) {
    displayName = 'Someone'
  }

  return (
    <Flex>
      <Text display="inline" fontSize="sm" color="gray.600">
        <Link fontWeight="semibold" color="gray.900" href={profilePath(person!)}>
          {displayName}
        </Link>
        {props.scopedTo !== 'account' && props.company && !props.actor && (
          <>
            {' from '}
            <Link fontWeight="semibold" color="gray.900" href={accountPath(props.session.company!)}>
              {props.company}
            </Link>
          </>
        )}
        {hasIntentSignals ? (
          <>
            {' triggered '}
            {signalNames.map((name, i) => (
              <React.Fragment key={name}>
                <Text as="span" fontWeight="semibold" color="gray.900">
                  {name}
                </Text>
                {i !== signalNames.length - 1 && ', '}
              </React.Fragment>
            ))}
            {others.length > 0 && ` and ${others.length} more`}
          </>
        ) : props.session.duration ? (
          <>{` spent ${duration(props.session.duration, { long: true })} on the site`}</>
        ) : null}
      </Text>
    </Flex>
  )
}

interface ActorMetaProps {
  session: Session
  company?: Maybe<string>
  title?: Maybe<string>
  geo?: Maybe<string>
}

export function ActorMeta(props: ActorMetaProps) {
  const text = useMemo(() => {
    const items: string[] = []

    if (props.title) {
      items.push(props.company ? `${props.title} at ${props.company}` : props.title)
    } else if (props.company) {
      items.push(props.company)
    }

    if ((!props.session.profile || !props.company) && props.geo) {
      items.push(props.geo)
    }

    return items
  }, [props.geo, props.title, props.company, props.session.profile])

  if (!text?.length) {
    return null
  }

  return (
    <HStack
      spacing={1}
      divider={<MiddotDivider />}
      fontSize="xs"
      color="gray.600"
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      display="flex"
    >
      {text.map((t, i) => (
        <Text as="span" flex="none" key={t + i} lineHeight="16px" textOverflow="ellipsis">
          {t}
        </Text>
      ))}
    </HStack>
  )
}

interface FeedStoryMetaProps {
  hideTimestamps?: boolean
  time?: string | Date
  duration?: number
  pages?: number
}

export function FeedStoryMeta(props: FeedStoryMetaProps) {
  return (
    <HStack fontSize="xs" color="gray.600" lineHeight="16px" spacing={1} divider={<MiddotDivider />}>
      {!props.hideTimestamps && <FeedStoryTime time={props.time} />}
      {props.pages && <Text>{pluralize('page', props.pages, true)}</Text>}
      {typeof props.duration === 'number' && <Text>{duration(props.duration, { long: true })}</Text>}
    </HStack>
  )
}

interface FeedStoryTimeProps {
  time?: string | Date
}

export function FeedStoryTime(props: FeedStoryTimeProps) {
  return <TimeAgo time={props.time} />
}
