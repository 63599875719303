import { Button, Flex, Heading, Img, Stack } from '@chakra-ui/react'
import React from 'react'
import { openUpgradeFlow } from './accounts-banner'
import img from './assets/billing-lock.svg'

interface Props {
  locked?: boolean
  img?: string
  children?: React.ReactNode
  blockedChildren: React.ReactNode
  blockTitle?: React.ReactNode
  upgradeTo?: 'business'
}

export function FeatureLockout(props: Props) {
  return (
    <>
      {props.locked && (
        <Stack w="100%" rounded="16px" shadow="sm" borderWidth={'thin'} maxW="700px" mx="auto">
          <Flex
            justify={'center'}
            w="100%"
            rounded={'16px 16px 0 0'}
            background="linear-gradient(180deg, rgba(77, 50, 228, 0.08) 0%, rgba(77, 50, 228, 0.00) 65.62%)"
          >
            <Img w="100%" src={props.img || img} objectFit={'fill'} pb="4" />
          </Flex>
          <Stack
            p="8"
            pt="2"
            px="14"
            justify={'center'}
            textAlign="center"
            spacing="4"
            minH="200px"
            alignItems={'stretch'}
          >
            <Heading size="lg" fontWeight={'semibold'}>
              {props.blockTitle || 'This feature is unavailable on your plan'}
            </Heading>
            <Stack w="100%" color="gray.600" flex="1" py="8">
              {props.blockedChildren}
            </Stack>
            <Flex justify={'center'}>
              {props.upgradeTo !== 'business' && props.upgradeTo && (
                <Button size="lg" colorScheme="purple" variant="solid" onClick={() => openUpgradeFlow(props.upgradeTo)}>
                  Upgrade to unlock
                </Button>
              )}
              {props.upgradeTo === 'business' && (
                <Button size="lg" colorScheme="purple" variant="solid" onClick={() => openUpgradeFlow(props.upgradeTo)}>
                  Talk to Sales
                </Button>
              )}
            </Flex>
          </Stack>
        </Stack>
      )}
      {!props.locked && <>{props.children}</>}
    </>
  )
}
