import {
  IconButton,
  SlideFade,
  Stack,
  StackProps,
  useDisclosure,
  UseDisclosureProps,
  SlideFadeProps,
  IconButtonProps
} from '@chakra-ui/react'
import { IconX } from '@tabler/icons-react'
import React, { PropsWithChildren } from 'react'
import { Card } from './Card'

interface Props extends UseDisclosureProps, StackProps {
  slideOptions?: SlideFadeProps
  iconButtonOptions?: IconButtonProps
}

export function CornerDialog(props: PropsWithChildren<Props>) {
  const disclosure = useDisclosure(props)
  const {
    children,
    isOpen,
    defaultIsOpen,
    onClose,
    onOpen,
    onToggle,
    isControlled,
    getButtonProps,
    getDisclosureProps,
    slideOptions,
    iconButtonOptions,
    ...rest
  } = props as any

  return (
    <SlideFade
      in={disclosure.isOpen}
      unmountOnExit
      offsetY="20px"
      delay={{
        enter: 1,
        exit: 0
      }}
      style={{
        zIndex: '199',
        position: 'fixed',
        maxWidth: '500px',
        bottom: '20px',
        right: '20px'
      }}
      {...slideOptions}
    >
      <IconButton
        aria-label="Close dialog"
        icon={<IconX size={18} />}
        size="xs"
        variant="ghost"
        position="absolute"
        top="10px"
        right="10px"
        onClick={disclosure.onClose}
        {...iconButtonOptions}
      />
      <Stack as={Card} shadow="2xl" rounded="md" spacing={3} paddingX={5} paddingY={6} {...rest}>
        {children}
      </Stack>
    </SlideFade>
  )
}
